import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/ducks/agent/actions";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import AgentListing from "./AgentListing";
import AgentCreate from "./AgentCreate";
import AgentShow from "./AgentShow/AgentShow";
import AgentEdit from "./AgentEdit/AgentEdit";
import AgentSettingEdit from "./AgentSettingEdit";
import AgentDownline from "./AgentDownline";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

const initialInputState =
{
    /* form input value */
    username: '',
    status: '',
};

class Agent extends Component 
{
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            order: null,
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalRecords: 0,
            searchValue: "",
            
            /* form input value */
            filter: initialInputState,

            /* API result array */
            data: [],

            /* skeleton loading */
            loading: true,

            /* details id */
            selectId: null,
            selectUn: null,

            popupLoading: false,
            agentShowId: null,
            agentEditId: null,

            // permission: [],
        };

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() 
    {
        const listingArr = this.fetchData();
        this.props.funcGetAgents(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        if (nextProps.agentData !== this.props.agentData && nextProps.agentData!==undefined)
        {
            if(nextProps.agentData.responseCode === 200 && nextProps.agentData.msgType === 'success')
            {
                this.setState({
                    data: nextProps.agentData.data.result.row,
                    totalRecords: nextProps.agentData.data.result.pagination.total_record,
                    totalPages: nextProps.agentData.data.result.pagination.total_page,
                    loading: false,
                });
            }
        }

        if (nextProps.agentUpdateStatusResult !== this.props.agentUpdateStatusResult) 
        {
            if(nextProps.agentUpdateStatusResult.responseCode === 200 && nextProps.agentUpdateStatusResult.msgType === 'success')
            {
                const listingArr = this.fetchData();
                this.props.funcGetAgents(listingArr);
            }
        }

        if (nextProps.agentUpdateLoginPermissionResult !== this.props.agentUpdateLoginPermissionResult) 
        {
            if(nextProps.agentUpdateLoginPermissionResult.responseCode === 200 && nextProps.agentUpdateLoginPermissionResult.msgType === 'success')
            {
                const listingArr = this.fetchData();
                this.props.funcGetAgents(listingArr);
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        if(event.target.id==='search')
        {
            this.setState({
                searchValue: event.target.value
            });
        }
        else
        {
            let newFilter = Object.assign({}, this.state.filter);
            newFilter[event.target.id] = event.target.value;
            this.setState({
                filter: newFilter
            });
        }
    }

    loadingHandler = () => 
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => 
            {
                Swal.showLoading();
            },
        });
    };

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.fetchData();
            this.props.funcGetAgents(listingArr);
        });
    };

    refreshHandler = () => 
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.fetchData();
            this.props.funcGetAgents(listingArr);
        });
    };

    detailsHandler = (e, page, id, un) =>
    {
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
            selectUn: un,
        });
    };

    // showHandler = (e, id) => 
    // {
    //     // this.loadingHandler();
    //     e.preventDefault();
    //     this.setState({
    //         agentShowId: id,
    //         pageShow: "show",
    //     });
    // };

    // editHandler = (e, id) => 
    // {
    //     // this.loadingHandler();
    //     e.preventDefault();
    //     this.setState({
    //         agentEditId: id,
    //         pageShow: "edit",
    //     });
    // };

    fetchData = () => 
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) 
        {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = this.state.filter[val];
        });
        // listingArr.country_id = this.props.currentCountryId;
        return listingArr;
    };

    changePageHandler = (page) => 
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => 
    {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                // const listingArr = this.fetchData();
                // this.props.funcGetAgents(listingArr);
                this.refreshHandler();
            }
        );
    };

    onSort = (field, order) => 
    {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            // const listingArr = this.fetchData();
            // this.props.funcGetAgents(listingArr);
            this.refreshHandler();
        });
    };

    updateAgentStatusHandler = (event, agentId) => 
    {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        const updateAgentStatusData = {};
        updateAgentStatusData.id = agentId;
        updateAgentStatusData.status = Number(event.target.value);
        this.props.funcUpdateAgentStatus(updateAgentStatusData);
    };

    updateAgentLoginPermissionHandler = (event, agentId) => 
    {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        const updateAgentLoginPermissionData = {};
        updateAgentLoginPermissionData.id = agentId;
        updateAgentLoginPermissionData.login_permission = Number(event.target.value);
        this.props.funcUpdateAgentLoginPermission(updateAgentLoginPermissionData);
    };

    searchChangeHandler = (event) => 
    {
        this.setState({ searchValue: event.target.value });
    };

    /* ============ Datatables Function [END] ============ */

    render() 
    {
        // const { t } = this.props;
        // let dataListing = [];



        // if (this.state.pageShow === "listing" && this.state.data.length > 0) 
        // {
        //     let agent_val = "";
        //     let agent_cls = { 1: "primary", 2: "info" };
        //     let agent_type = { 1: t("agent.cash-mode"), 2: t("agent.credit-mode") };
        //     dataListing = this.state.data.map((val, idx) => 
        //     {
        //         agent_val = <span className={`badge-${agent_cls[val.agentType]}`}>{agent_type[val.agentType]}</span>;
        //         return {
        //             agentId: val.agentId,
        //             username: val.username,
        //             name: val.name,
        //             agentCode: val.agentCode,
        //             agentType: agent_val,
        //             loginPermission: val.loginPermission,
        //             lastLogin: val.lastLogin,
        //             status: val.status,
        //         };
        //     });
        // }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <AgentListing
                        /* filter input */
                        filter={this.state.filter}

                        /* function */
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        searchChangeHandler={this.searchChangeHandler}
                        // onSearchKeyPressHandler={(event) => this.onSearchKeyPressHandler(event)}
                        updateAgentStatusHandler={(event, id) => this.updateAgentStatusHandler(event, id)}
                        detailsHandler={(event, page, id, un) => this.detailsHandler(event, page, id, un)}
                        refreshHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        onSort={(field, order) => this.onSort(field, order)}
                        
                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        
                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        permission={this.props.currentPermission ?? []}
                    />
                )}

                {this.props.currentPermission.includes("agent_create") && this.state.pageShow === "create" && (
                    <AgentCreate popupLoading={this.state.popupLoading} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.props.currentPermission.includes("agent_view") && this.state.pageShow === "show" && (
                    (<AgentShow agentShowId={this.state.selectId} changePageHandler={(page) => this.changePageHandler(page)} />)
                )}

                {this.props.currentPermission.includes("agent_update") && this.state.pageShow === "edit" && (
                    <AgentEdit 
                        {...this.state}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                        permission={this.props.currentPermission ?? []}
                    />
                )}

                {this.props.currentPermission.includes("agent_update") && this.state.pageShow === "setting" && (
                    <AgentSettingEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.props.currentPermission.includes("agent_view") && this.state.pageShow === "downline" && (
                    <AgentDownline 
                        // id={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()} 
                        changePageHandler={(page) => this.changePageHandler(page)} 
                        {...this.state}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => 
{
    return {
        agentData: state.agent.agent_data,
        agentUpdateStatusResult: state.agent.agent_update_status_result,
        agentUpdateLoginPermissionResult: state.agent.agent_update_login_permission_result,
        // currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => 
{
    return {
        funcGetAgents: (data) => dispatch(actions.getAgents(data)),
        funcUpdateAgentStatus: (data) => dispatch(actions.updateAgentStatus(data)),
        funcUpdateAgentLoginPermission: (data) => dispatch(actions.updateAgentLoginPermission(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Agent));
