import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../store/ducks/log/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Select from "../../components/Input/Select/Select";
import { withNamespaces } from "react-i18next"
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import TransactionLog from "./TransactionLog/TransactionLog";
import TransactionLogListing from "./TransactionLog/TransactionLogListing";
import MemberLog from "./MemberLog/MemberLog";
import MemberLogListing from "./MemberLog/MemberLogListing";
import BankAccountLog from "./BankAccountLog/BankAccountLog";
import BankAccountLogListing from "./BankAccountLog/BankAccountLogListing";
import AdminLog from "./AdminLog/AdminLog";
import AdminLogListing from "./AdminLog/AdminLogListing";

class Log extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            logType: "transaction_log",
            data: [],
            showCard:false,
        };
        this.handleLogTypeChange = this.handleLogTypeChange.bind(this);
    }

    componentDidMount() {
        this.setLoadingHandler(true, true);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    /* ======================== Component Lifecycle [END] ======================== */
    handleLogTypeChange(e) {
        this.setState({
            logType: e.target.value,
        });
        this.setLoadingHandler(true, true);
    }

    setLoadingHandler = (pageLoading, dataLoading) => {
        const loadingData = {};
        loadingData.pageLoading = pageLoading;
        loadingData.dataLoading = dataLoading;
        this.props.setLoading(loadingData);
    };

    toggleCard = () =>
    {
        this.setState({
            showCard:!this.state.showCard
        })
    }
    /* ======================== Function [END] ======================== */
    render() {
        const { t } = this.props;
        let log_option = [
            { label: `1. ${t("log-type.transaction-log")}`, value: "transaction_log" },
            { label: `2. ${t("log-type.member-log")}`, value: "member_log" },
            { label: `3. ${t("log-type.bank-account-log")}`, value: "bank_account_log" },
            { label: `4. ${t("log-type.admin-log")}`, value: "admin_log" },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("log.log")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <form method="post" id="filter_member_form" className={`form-horizontal filter-function row ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                {/* <div className="row"> */}
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label htmlFor="filter_log" className="font-weight-bolder">
                                                {t("log.log-type")}
                                            </label>
                                            <Select
                                                id="filter_log"
                                                name="filter_log"
                                                options={log_option}
                                                onChange={this.handleLogTypeChange}
                                                value={this.state.logType}
                                                className="form-control filter-input"
                                            />
                                        </div>
                                    </div>
                                    {this.state.logType === "transaction_log" && <TransactionLog />}
                                    {this.state.logType === "member_log" && <MemberLog />}
                                    {this.state.logType === "bank_account_log" && <BankAccountLog />}
                                    {this.state.logType === "admin_log" && <AdminLog />}
                                {/* </div> */}
                            </form>
                            <div className="show-btn"
                            onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>

                    {this.state.logType === "transaction_log" && <TransactionLogListing />}
                    {this.state.logType === "member_log" && <MemberLogListing />}
                    {this.state.logType === "bank_account_log" && <BankAccountLogListing />}
                    {this.state.logType === "admin_log" && <AdminLogListing />}
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (data) => dispatch(setLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Log));
