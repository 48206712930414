import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const memberLevelTrackerReducer = createReducer(initialState) ({
    [type.MEMBER_LEVEL_TRACKER_LIST]: (state, action) => ({
        ...state,
        member_level_tracker_data: action.payload
    }),

    [type.MEMBER_LEVEL_TRACKER_FILTER_DATA]: (state, action) => ({
        ...state,
        member_level_tracker_filter_data: action.payload
    }),
});

export default memberLevelTrackerReducer;