import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";

import { getHistoryDetails } from "../../store/ducks/rewardItem/actions";

class RewardItemHistoryDetails extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state =
        {
            data: [],
            details: [],
            loading: true,
        };
    }

    componentDidMount() 
    {
        // console.log(this.props.detailsParam);
        if (this.props.detailsParam && this.props.detailsParam.id) 
        {
            const data = {};
            data.id = this.props.detailsParam.id;
            data.get_game_list = true;
            this.props.getHistoryDetails(data);
        }
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        if(nextProps.history_details !== undefined && nextProps.history_details !== this.props.history_details)
        {
            if(nextProps.history_details.responseCode === 200 && nextProps.history_details.msgType === "success")
            {
                // console.log(nextProps.history_details);
                this.setState({
                    loading: false,
                    data: nextProps.history_details.data.result,
                    details: nextProps.history_details.data.reference_code_details,
                });
            }
        }
        return true;
    }

    render() 
    {
        // console.log(this.state.details);
        const { t } = this.props;
        let data = this.state.data;
        let gameList = (this.state.details && this.state.details.gameList) ? this.state.details.gameList : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t("reward-item.history")}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('reward-item.history-details')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                {this.state.loading
                                ?
                                    <Skeleton count={3} />
                                :
                                    <table className='table table-bordered table-hover table-striped'>
                                        <tbody>
                                            <tr>
                                                <th style={{ width: '150px' }}>{t('global.id')}</th>
                                                <td>{data.id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.member')}</th>
                                                <td>{data.member}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.item')}</th>
                                                <td>{data.itemTitle} {(data.categorySlug === 'free-bet') ? <><b>({t(`vendor.${data.vendor}`)})</b></> : null}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.category')}</th>
                                                <td>{t(`reward-category.${data.categorySlug}`)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.status')}</th>
                                                <td><span className={`badge-pill badge-${(data.status === 1) ? 'success' : 'danger'}`}>{t(`transaction.status-desc.${data.status}`)}</span></td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.reference-code')}</th>
                                                <td>{data.referenceCode}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.date')}</th>
                                                <td>{data.createdAt}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('reward-item.update-date')}</th>
                                                <td>{data.updatedAt}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-12 col-xl-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('game-list.game-list')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                {this.state.loading
                                ?
                                    <Skeleton count={3} />
                                :        
                                    <ul id='tree1' className='tree'>
                                        <li>
                                        {
                                            gameList.map((val, idx) => 
                                            {
                                                return (
                                                    // <li key={idx}>
                                                        <><i className='fa fa-circle-notch fa-xs'></i> {val.game_title} &nbsp;</>
                                                    // </li>
                                                );
                                            })
                                        }
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => 
{
    return {
        history_details: state.rewardItem.history_details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => 
{
    return {
        getHistoryDetails: (data) => dispatch(getHistoryDetails(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RewardItemHistoryDetails));