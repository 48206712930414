import React, { Component } from "react";
import { connect } from "react-redux";
import { storeAgent } from "../../store/ducks/agent/actions";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
// import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
// import SimpleReactValidator from "simple-react-validator";
import ValidationError from "../../components/ValidationError/ValidationError";

class AgentCreate extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            name: "",
            email: "",
            contact_no: "",
            password: "",
            confirm_password: "",
            referral_code: `${this.randomString(10)}`,
            // agent_type: "",
            // pt: "",
            // cost: "",
            // fixed_cost: "",

            error_message: [],
            touched: false
        };
        // this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        formValidation("agent_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[AgentCreate] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.storeAgentResult !== this.props.storeAgentResult) {
            if (nextProps.storeAgentResult.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeAgentResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.storeAgentResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.storeAgentResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    handleChange = (event) => {
        // console.log(event);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    randomString = (length) => {
        let result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // let characters = '0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    storeAgentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                // if (this.validator.allValid()) {

                // } else {
                //     this.validator.showMessages();
                //     this.forceUpdate();
                // }
                // this.setState({ touched: true });
                let storeAgentData = {};
                storeAgentData.username = this.state.username;
                storeAgentData.name = this.state.name;
                storeAgentData.email = this.state.email;
                storeAgentData.contact_no = this.state.contact_no;
                storeAgentData.password = this.state.password;
                storeAgentData.confirm_password = this.state.confirm_password;
                storeAgentData.referral_code = this.state.referral_code;
                // storeAgentData.agent_type = this.state.agent_type;
                // storeAgentData.pt = this.state.pt;
                // storeAgentData.cost = this.state.cost;
                // storeAgentData.fixed_cost = this.state.fixed_cost;
                //storeAgentData.country_id = this.props.currentCountryId;
                this.props.storeAgent(storeAgentData);
            }
        });
    };

    render() {
        const { t } = this.props;
        // let agent_type = [
        //     { label: t("agent.agent-type"), value: "" },
        //     { label: t("agent.cash-mode"), value: 1 },
        //     { label: t("agent.credit-mode"), value: 2 },
        // ];
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("agent.agent")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.add-agent")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="agent_form" className="form-horizontal" onSubmit={this.storeAgentHandler} noValidate>
                                    <ValidationError formName="agent_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="username">{t("agent.username")}</label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    className="form-control"
                                                    placeholder={t("agent.username")}
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                {/* <input id="username" name="username" className="form-control" value={this.state.username}  onChange={this.handleChange} required/> */}
                                                {/* <small className="text-danger">{this.validator.message("username", this.state.username, "required|alpha_num|max:191")}</small> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("agent.name")}</label>
                                                <StringInput
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder={t("agent.name")}
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                {/* <small className="text-danger">{this.validator.message("name", this.state.name, "required|alpha_space|max:191")}</small> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">{t("agent.email")}</label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder={t("agent.email")}
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    required
                                                    valid_email="true"
                                                />
                                                {/* <small className="text-danger">{this.validator.message("email", this.state.email, "required|email|max:191")}</small> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="contact_no">{t("agent.contact-no")}</label>
                                                <StringInput
                                                    id="contact_no"
                                                    name="contact_no"
                                                    className="form-control"
                                                    placeholder={t("agent.contact-no")}
                                                    value={this.state.contact_no}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="password">{t("agent.password")}</label>
                                                <PasswordInput
                                                    onChange={this.handleChange}
                                                    id="password"
                                                    name="password"
                                                    placeholder={t("agent.password")}
                                                    value={this.state.password}
                                                    required
                                                    minLength="8"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="confirm_password">{t("agent.confirm-password")}</label>
                                                <PasswordInput
                                                    onChange={this.handleChange}
                                                    id="confirm_password"
                                                    name="confirm_password"
                                                    placeholder={t("agent.confirm-password")}
                                                    value={this.state.confirm_password}
                                                    required
                                                    oldPassword={this.state.password}
                                                    confirmpassword="true"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referral_code">{t("agent.referral-code")}</label>
                                                <StringInput
                                                    id="referral_code"
                                                    name="referral_code"
                                                    className="form-control"
                                                    placeholder={t("agent.agent-code")}
                                                    value={this.state.referral_code}
                                                    onChange={this.handleChange}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="agent_type">{t("agent.agent-type")}</label>
                                                <Select id="agent_type" name="agent_type" options={agent_type} onChange={this.handleChange} className="form-control" />
                                            </div>
                                        </div> */}
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="pt">{t("agent.pt")}</label>
                                                <NumberInput
                                                    id="pt"
                                                    name="pt"
                                                    className="form-control"
                                                    value={this.state.pt}
                                                    onChange={this.handleChange}
                                                    placeholder={t("agent.pt")}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="cost">{t("agent.cost")}</label>
                                                <NumberInput
                                                    id="cost"
                                                    name="cost"
                                                    className="form-control"
                                                    value={this.state.cost}
                                                    onChange={this.handleChange}
                                                    placeholder={t("agent.cost")}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="fixed_cost">{t("agent.fixed-cost")}</label>
                                                <NumberInput
                                                    id="fixed_cost"
                                                    name="fixed_cost"
                                                    className="form-control"
                                                    value={this.state.fixed_cost}
                                                    onChange={this.handleChange}
                                                    placeholder={t("agent.fixed-cost")}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("Test",state.agent.agent_create_data);
    return {
        // onInitCreateData: state.agent.agent_create_data
        storeAgentResult: state.agent.agent_store_result,
        // validationMessage: state.agent.validation,
        currentCountryId: state.currentUser.data.country_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeAgent: (storeAgentData) => dispatch(storeAgent(storeAgentData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentCreate));
