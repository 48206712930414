import { Component } from "react";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import DataTable from "../../../components/Datatable/DataTable";
import Skeleton from "react-loading-skeleton";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Button from "../../../components/Button/Button";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

const type_option = {
    "betting": "Betting", "lucky-spin-bonus": "Lucky Spin Bonus", "qna-event": "Q&A Event", "mall-reward-bonus": "Mall Reward Bonus", 
    "world-cup-event": "World Cup Event"
};

class MemberCoinTrackerListing extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { t } = this.props;
        let data = [];
        let st_cls = {
            'credit': 'success',
            'debit': 'danger'
        };
        const num_format = { maximumFractionDigits: 3, minimumFractionDigits: 3 };
        const columns = [
            { text: t('transaction.transaction-date'), dataField: 'transaction_date', sort: true, onSort: this.props.onSort },
            { text: t('transaction.transaction-type'), dataField: 'transaction_type', sort: true, onSort: this.props.onSort },
            { text: t('transaction.order-id'), dataField: 'order_id', sort: true, onSort: this.props.onSort },
            { text: t('transaction.amount'), dataField: 'coin', sort: true, onSort: this.props.onSort },
            { text: t('transaction.balance'), dataField: 'coin_balance', sort: true, onSort: this.props.onSort }
        ]

        if (this.props.data) {
            data = this.props.data.map((val, index) => {
                let orderIdVal = "";
                orderIdVal = this.props.permission.includes("transaction_details_access") ? (
                    (val.identifier > 0 && val.trx_id > 0) ? (
                        <b>
                            <a
                                href="/#"
                                onClick={(event) => this.props.detailsHandler(event, val.trx_id)}
                            >
                                {val.order_id}
                                <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                            </a>
                        </b>
                    ) : (val.order_id)
                ) : (
                    val.order_id
                );
                let className = `badge badge-pill badge-${st_cls[val.status]}`;
                let coinVal = (
                    <span className={className}>
                        {new Intl.NumberFormat('en-US', num_format).format(val.coin)}
                    </span>
                );
                return {
                    transaction_date: val.transaction_date,
                    transaction_type: val.transaction_type === "betting" ? "Betting" : t(`transaction-type.${val.transaction_type}`),
                    order_id: orderIdVal,
                    coin: coinVal,
                    coin_balance: new Intl.NumberFormat('en-US', num_format).format(val.coin_balance)
                };
            });
        }

        return (
            <Auxiliary>
                <div className='row col-sm'>
                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div>
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method='post'
                                        id="wallet_tracker_form"
                                        className={`form-horizontal filter-function row filter-wrapper-1`}
                                    >
                                        <div className='col-md-3 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('transaction.transaction-date')}</label>
                                                <div className='input-group-prepend'>
                                                    <span className='input-group-text'>
                                                        <i className='fas fa-calendar-check'></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.transaction_date}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('transaction.transaction-type')}</label>
                                                <div className='select-input'>
                                                    <select
                                                        className='form-control filter-input'
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        value={this.props.filter.transaction_type}
                                                        onChange={this.props.onInputChange}>
                                                        <option value=""></option>
                                                        <>
                                                            {(() => {
                                                                let options = [];
                                                                if(type_option){
                                                                    for(const key in type_option){
                                                                        options.push(
                                                                            <option key={key} value={key}>
                                                                                {type_option[key]}
                                                                            </option>
                                                                        );
                                                                    }
                                                                }
                                                                return options;
                                                            })()}
                                                        </>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className='col mt-auto'>
                                            <div className='d-flex'>
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-blue mr-2'
                                                    idValue="filter"
                                                    buttonName={t('global.filter')}
                                                    clicked={() => { this.props.refreshHandler() }}
                                                />
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-secondary'
                                                    idValue="reset"
                                                    buttonName={t('global.reset')}
                                                    clicked={() => { this.props.clearHandler() }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>{t('member.coin-tracker')}</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover database'>
                                    <DataTable
                                        keyField="order_id"
                                        data={data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center member__transaction"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberCoinTrackerListing);