import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from '../../components/Button/Button';
import { getDetails } from "../../store/ducks/memberPromotion/actions";

class MemberPromotionCancelDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        }
    }

    componentDidMount() {
        const data = this.getFilterList();
        this.props.getDetails(data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.details !== undefined && nextProps.details !== this.props.details) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === "success") {
                this.setState({
                    loading: false,
                    data: nextProps.details.data.result
                });
            }
        }

        return true;
    }

    getFilterList = () => {
        const listingArr = {};
        const param = this.props.promoDetailsParam;

        listingArr.id = param.id;
        listingArr.user_id = param.user_id;
        return listingArr;
    }

    render() {
        const { t } = this.props;
        const params = this.props.promoDetailsParam;
        const vtcCls = { 0: "danger", 1: "success" };

        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button typeButton="button" 
                                classValue="btn btn-back-outline" 
                                idValue="back" 
                                buttonName={<i className="angle fe fe-chevron-left"></i>} 
                                clicked={() => this.props.changePageHandler(this.props.returnPage)} 
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("member-promotion.title")}</h4>
                        </div>
                    </div>
                </div>

                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('member-promotion.details')}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <table className='table table-bordered table-hover'>
                                        <tbody>
                                            <tr>
                                                <th>{t("member-promotion.order-id")}</th>
                                                <td>{params.order_id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.p-order-id")}</th>
                                                <td>{params.promo_order_id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.promotion")}</th>
                                                <td>{params.promo_title}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.member")}</th>
                                                <td>{this.state.data.username}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.deposit-amount")}</th>
                                                <td>{this.state.data.deposit_amount}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.bonus-amount")}</th>
                                                <td>{this.state.data.bonus_amount}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.target-amount")}</th>
                                                <td>{this.state.data.target_amount}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.last-bet-date")}</th>
                                                <td>{this.state.data.last_bet_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.start-date")}</th>
                                                <td>{this.state.data.start_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.end-date")}</th>
                                                <td>{this.state.data.end_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.complete-date")}</th>
                                                <td>{this.state.data.complete_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member-promotion.valid-to-cancel")}</th>
                                                <td>
                                                    <span className={`badge-${vtcCls[this.state.data.valid_cancel]}`}>{t(`member-promotion.valid-to-cancel-desc.${this.state.data.valid_cancel}`)}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-xs text-center align-items-center mg-b-20">
                    <div className="col-md-12">
                        {this.state.loading ? (
                            <Skeleton count={2} />
                        ) : (
                            <>
                                {(permission.includes("member_promotion_update") && this.state.data.valid_cancel === 1) && 
                                    <>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-danger"
                                            idValue="reject"
                                            buttonName={t("global.reject")}
                                            clicked={(event) => this.props.cancelHandler(event, params.id)}
                                        />
                                    </>
                                }
                            </>
                        )}
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        details: state.memberPromotion.details
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDetails: (data) => dispatch(getDetails(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberPromotionCancelDetail));