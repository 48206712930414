import axios from "../../../common/config/axios";
import * as type from "./types";

export const getAdminTranslations = (data) => (dispatch) => {
    axios
        .get("translation/admin_list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
            },
        })
        .then((res) => {
            dispatch({
                type: type.TRANSLATION_ADMIN_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Translation Admin List Error", err);
            dispatch({
                type: type.TRANSLATION_ADMIN_LIST,
                payload: [],
            });
        });
};

export const getMemberTranslations = (data) => (dispatch) => {
    axios
        .get("translation/member_list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
            },
        })
        .then((res) => {
            dispatch({
                type: type.TRANSLATION_MEMBER_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Translation Member List Error", err);
            dispatch({
                type: type.TRANSLATION_MEMBER_LIST,
                payload: [],
            });
        });
};

export const getTranslationLanguages = () => (dispatch) => {
    axios
        .get("translation/languages")
        .then((res) => {
            dispatch({
                type: type.TRANSLATION_GET_LANGAUGES,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Translation Get Languages Error", err);
            dispatch({
                type: type.TRANSLATION_GET_LANGAUGES,
                payload: [],
            });
        });
};
