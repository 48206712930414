import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/whitelistIP/actions';

class WhitelistIPEdit extends Component {

	constructor(props) {
		super(props);

		this.state = {
			formFilter: {},
			errMsg: [],
			touched: false,
		};
		this.onInputChange = this.onInputChange.bind(this);
	}

	componentDidMount() {
		formValidation('whitelist_ip_form');

		if (this.props.ip && this.props.status) {
			this.setState({
				formFilter: {
					ip: this.props.ip,
					status: this.props.status
				}
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { t } = this.props;
		if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
			if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
				let newInput = {};

				for (const key in nextProps.details.data.result) {
					newInput[key] = nextProps.details.data.result[key];
				}

				this.setState({
					formFilter: newInput
				});
			}
			// Swal.close();
		}

		if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
			// console.log('shouldComponentUpdate :', nextProps.update);
			let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;

			if (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') {
				Swal.fire({
					icon: 'success',
					title: t('global.success'),
					html: t('global.record-updated'),
					showConfirmButton: true
				})
					.then(() => {
						clearFormValidation('whitelist_ip_form');
						this.props.refreshHandler();
						this.props.changePageHandler('listing');
					});
			}
			else {
				Swal.close();
			}
			this.setState({
				touched: (success) ? false : true,
				errMsg: (success) ? [] : nextProps.update.message
			});
		}
		return true;
	}

	onInputChange = (event) => {
		// console.log(event.target.id+' | '+event.target.value);
		let newInput = Object.assign({}, this.state.formFilter);
		newInput[event.target.id] = event.target.value;
		this.setState({
			formFilter: newInput
		});
	}

	submitHandler = (e) => {
		// console.log('submitHandler :', this.state);
		e.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: 'You wont be able to revert this!',
			icon: 'warning',
			confirmButtonText: 'Save',
			showDenyButton: true,
			denyButtonText: 'Cancel',
		})
			.then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false,
						didOpen: () => {
							Swal.showLoading()
						}
					});

					this.props.funcUpdate({
						ip: this.state.formFilter.ip,
						status: this.state.formFilter.status
					});
				}
			});
	}

	render() {
		const { t } = this.props;
		let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

		return (
			<React.Fragment>
				<div className='breadcrumb-header'>
					<div className='d-flex'>
						<div className='my-auto'>
							<Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
						</div>
					</div>
					<div className='my-auto'>
						<div className='d-flex'>
							<h4 className='content-title mb-0 my-auto ml-2'>{t('whitelist-ip.title')}</h4>
						</div>
					</div>
				</div>
				<div className='row row-sm'>
					<div className='col-12'>
						<div className='card box-shadow-0'>
							<div className='card-header'>
								<h4 className='card-title mb-1'>{t('role.whitelist_ip_edit')}</h4>
							</div>

							<div className='card-body pt-0'>
								<form id='whitelist_ip_form' method='post' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
									<ValidationError formName='whitelist_ip_form' touched={this.state.touched} message={this.state.errMsg} />

									<div className='row'>
										<div className=' col-md-6 form-group'>
											<label htmlFor='title'>{t('ip.ip-address')}</label>
											<StringInput
												id='ip'
												name='ip'
												placeholder={t('ip.ip-address')}
												value={this.state.formFilter.ip || ''}
												onChange={(event) => this.onInputChange(event)}
												required
												readOnly
											/>
										</div>

										<div className='col-md-6 form-group'>
											<label>{t('global.status')}</label>
											<div className='select-input'>
												<select
													id='status'
													className='form-control filter-input'
													name='status'
													value={this.state.formFilter.status || 'allowed'}
													onChange={(event) => this.onInputChange(event)}
												>
													<option value="allowed">{t('whitelist-ip.allowed')}</option>
													<option value="banned">{t('whitelist-ip.banned')}</option>
												</select>
												<img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
											</div>

										</div>

										<div className='col-md-12 form-group mb-0 justify-content-end'>
											<div>
												{(permission.includes('whitelist_ip_update')) &&
													<Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
												}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
	return {
		update: state.whitelistIP.update,
		currentUser: state.currentUser.data
	}
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
	return {
		funcUpdate: (data) => dispatch(actions.update(data))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(WhitelistIPEdit));
