import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Select from "../../components/Input/Select/Select";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import { withNamespaces } from "react-i18next";
import Select2 from "../../components/Input/Select2/Select2";
import TimeField from 'react-simple-timefield';

import { getBankNameList, getBankAccountNameList, showBankAccount, updateBankAccount, getMemberGroup } from "../../store/ducks/banksAccount/actions";
import { formValidation, filterErrorHandler } from "../../hoc/Shared/utility";

//Initialize the bank name array first
let bankNameArry = [
    {
        label: "Please Select Bank",
        value: "",
    },
];

//Initialize the next active account array first
let nextActiveAccountArry = [
    {
        label: "Please Select Next Active Bank Account",
        value: "",
    },
];

//Initialize the member group array
let memberGroupArry = [
    {
        label: "Please Select Member Group",
        value: "",
        selected: true,
    }
];

class BanksAccountEdit extends Component {
    state = {
        bankId: "",
        currentBankAccountId: "",
        currentNextActiveAccount: "",
        currentThresholdStatus: 0,
        bank_acc_name: "",
        bank_acc_no: "",
        min_deposit: 0,
        max_deposit: 0,
        sequence: "",
        error_message: [],
        touched: false,
        loading: true,
        memberGroupId: "",
        selectedActiveDay: [],
        active_start_time: "00:00:00",
        active_end_time: "23:59:59"
    };

    componentDidMount() {
        formValidation("bank_account_form");

        //Clear the bank name array
        bankNameArry = [
            {
                label: "Please Select Bank",
                value: "",
                selected: true,
            },
        ];

        //Clear the next active account array
        nextActiveAccountArry = [
            {
                label: "Please Select Next Active Bank Account",
                value: "",
                selected: true,
            },
        ];

        memberGroupArry = [
            {
                label: "Please Select Member Group",
                value: "",
                selected: true,
            },
            {
                label: "All",
                value: 0,
            }
        ];

        const showBankAccountData = {};
        showBankAccountData.bank_account_id = this.props.show_bank_account_id;

        const getBankListData = {};
        getBankListData.country_id = this.props.currentCountryId;

        this.props.getBankNameList(getBankListData);
        this.props.getBankAccountNameList(getBankListData);
        this.props.showBankAccount(showBankAccountData);
        this.props.getMemberGroup(getBankListData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.bank_name_data !== this.props.bank_name_data) {
            if (nextProps.bank_name_data.data.responseCode === 200) {
                let data = nextProps.bank_name_data.data.data;
                Object.keys(data).map((item, i) => {
                    return bankNameArry.push({
                        label: data[item].bankName,
                        value: data[item].bankId,
                    });
                });
            } else {
                this.setState({
                    loading: true
                }, () => {
                    filterErrorHandler(nextProps.bank_name_data.data.message);
                });
            }
        }

        if (nextProps.bank_account_name_data !== this.props.bank_account_name_data) {
            if (nextProps.bank_account_name_data.data.responseCode === 200) {
                let data = nextProps.bank_account_name_data.data.data;
                Object.keys(data).map((item, i) => {
                    return nextActiveAccountArry.push({
                        label: data[item].bankAccName,
                        value: data[item].bankAccName,
                    });
                });
            } else {
                this.setState({
                    loading: true
                }, () => {
                    filterErrorHandler(nextProps.bank_account_name_data.data.message);
                });
            }
        }

        if (nextProps.show_bank_account_data !== this.props.show_bank_account_data) {
            if (nextProps.show_bank_account_data.data.responseCode === 200) {
                const { t } = this.props;
                let selectedActiveDay = [];
                if (nextProps.show_bank_account_data.data.data[0].activeDay) {
                    nextProps.show_bank_account_data.data.data[0].activeDay.forEach(function (element) {
                        selectedActiveDay.push({ label: t('bank-account.' + element), value: element });
                    }, this);
                }

                this.setState({
                    loading: false,
                    currentBankAccountId: nextProps.show_bank_account_data.data.data[0].id,
                    bankId: nextProps.show_bank_account_data.data.data[0].bankId,
                    bank_acc_name: nextProps.show_bank_account_data.data.data[0].bankAccName,
                    bank_acc_no: nextProps.show_bank_account_data.data.data[0].bankAccNumber,
                    memberGroupId: nextProps.show_bank_account_data.data.data[0].memberGroupId === null ? "" : nextProps.show_bank_account_data.data.data[0].memberGroupId,
                    selectedActiveDay: selectedActiveDay,
                    active_start_time: nextProps.show_bank_account_data.data.data[0].activeStartTime,
                    active_end_time: nextProps.show_bank_account_data.data.data[0].activeEndTime
                });
            } else {
                this.setState({
                    loading: true,
                    currentBankAccountId: "",
                    bankId: "",
                    bank_acc_name: "",
                    bank_acc_no: "",
                    memberGroupId: "",
                    selectedActiveDay: [],
                    active_start_time: "00:00:00",
                    active_end_time: "23:59:59"
                }, () => {
                    filterErrorHandler(nextProps.show_bank_account_data.data.message)
                });
            }
        }

        if (nextProps.update_bank_account_result !== this.props.update_bank_account_result) {
            if (nextProps.update_bank_account_result.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_bank_account_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_bank_account_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.member_group_data !== this.props.member_group_data) {
            if (nextProps.member_group_data.data.responseCode === 200) {
                let data = nextProps.member_group_data.data.data;

                Object.keys(data).map((item, i) => {
                    return memberGroupArry.push({
                        label: data[item].memberGroupName,
                        value: data[item].memberGroupId,
                    });
                });
            } else {
                this.setState({
                    loading: true
                }, () => {
                    filterErrorHandler(nextProps.member_group_data.data.message);
                });
            }
        }

        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleBankChange = (event) => {
        this.setState({
            bankId: event.target.value,
        });
    };

    handleBankAccountPurpose = (event) => {
        this.setState({
            currentBankAccountPurpose: event.target.value,
        });
    };

    handleNextActiveBankChange = (event) => {
        this.setState({
            currentNextActiveAccount: event.target.value,
        });
    };

    handleThresholdStatusChange = (event) => {
        this.setState({
            currentThresholdStatus: event.target.value,
        });
    };

    handleMaintenanceTimeChange = (event) => {
        this.setState({
            currentMaintenanceStartTime: event[0],
            currentMaintenanceEndTime: event[1],
        });
    };

    handleMaintenanceStatusChange = (event) => {
        this.setState({
            currentMaintenanceStatus: event.target.checked,
        });
    };

    handleMemberGroupChange = (event) => {
        this.setState({
            memberGroupId: event.target.value,
        });
    };

    handleActiveDayChange = (event) => {
        this.setState({
            selectedActiveDay: event
        });
    };

    handleActiveDaySelectAll = (event, activeDayArr) => {
        this.setState({
            selectedActiveDay: activeDayArr,
        });
    };

    handleActiveDayUnSelectAll = () => {
        this.setState({
            selectedActiveDay: [],
        });
    };

    handleActiveHourChange = (event) => {
        if (event.target.id === 'active_start_time') {
            this.setState({
                active_start_time: event.target.value
            });
        } else if (event.target.id === 'active_end_time') {
            this.setState({
                active_end_time: event.target.value
            });
        }
    };

    postBankAccountHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                let deposit_threshold = 0;
                let no_of_deposit_threshold = 0;

                data.id = this.props.show_bank_account_id;
                data.bank_id = this.state.bankId;
                data.bank_acc_name = this.state.bank_acc_name;
                data.bank_acc_number = this.state.bank_acc_no;
                data.bank_code = this.state.bank_code;
                data.min_deposit = this.state.min_deposit;
                data.max_deposit = this.state.max_deposit;
                data.bank_acc_fees = this.state.bank_acc_fees;
                data.maintainance_start_time = this.state.currentMaintenanceStartTime;
                data.maintainance_end_time = this.state.currentMaintenanceEndTime;
                data.sequence = this.state.sequence;
                data.maintainance_status = this.state.currentMaintenanceStatus;
                data.bank_account_purpose = this.state.currentBankAccountPurpose;
                data.threshold_status = this.state.currentThresholdStatus;

                if (this.state.memberGroupId !== '') {
                    data.member_group_id = this.state.memberGroupId;
                }

                if (this.state.deposit_threshold) {
                    deposit_threshold = this.state.deposit_threshold;
                }

                if (this.state.no_of_deposit_threshold) {
                    no_of_deposit_threshold = this.state.no_of_deposit_threshold;
                }

                data.deposit_threshold = deposit_threshold;
                data.no_of_deposit_threshold = no_of_deposit_threshold;
                data.active_bank_acc = this.state.currentNextActiveAccount;
                data.created_by = this.props.currentUsername;

                data.selected_active_day = this.state.selectedActiveDay;
                data.active_start_time = this.state.active_start_time;
                data.active_end_time = this.state.active_end_time;

                this.props.updateBankAccount(data);
            }
        });
    };

    render() {
        const { t } = this.props;

        const activeDayArr = [
            { label: t('bank-account.1'), value: '1' },
            { label: t('bank-account.2'), value: '2' },
            { label: t('bank-account.3'), value: '3' },
            { label: t('bank-account.4'), value: '4' },
            { label: t('bank-account.5'), value: '5' },
            { label: t('bank-account.6'), value: '6' },
            { label: t('bank-account.7'), value: '7' },
        ];

        bankNameArry[0].label = t("bank-account.please_select_bank");
        nextActiveAccountArry[0].label = t("bank-account.please_select_next_active_bank_account");

        // let bankAccountPurpose = [
        //     {
        //         label: t("bank-account.non_specific"),
        //         value: "non-specific",
        //         selected: true,
        //     },
        //     {
        //         label: t("bank-account.deposit"),
        //         value: "deposit",
        //     },
        //     {
        //         label: t("bank-account.withdrawal"),
        //         value: "withdrawal",
        //     },
        // ];

        // let thresholdStatus = [
        //     {
        //         label: t("bank-account.active"),
        //         value: 1,
        //         selected: true,
        //     },
        //     {
        //         label: t("bank-account.inactive"),
        //         value: 0,
        //     },
        // ];
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <div className="btn-group">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler("listing")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("bank-account.banks-account")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("bank-account.edit_banks_account")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="bank_account_form" onSubmit={this.postBankAccountHandler} noValidate>
                                    <ValidationError formName="bank_account_form" touched={this.state.touched} message={this.state.error_message} />
                                    {this.state.loading === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank">{t("bank-account.banks")}</label>

                                                <Select
                                                    id="bank"
                                                    name="bank"
                                                    className="form-control"
                                                    options={bankNameArry}
                                                    onChange={this.handleBankChange}
                                                    value={this.state.bankId}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_acc_name">{t("bank-account.banks_account_name")}</label>

                                                <StringInput
                                                    onChange={this.handleChange}
                                                    id="bank_acc_name"
                                                    name="bank_acc_name"
                                                    placeholder={t("bank-account.banks_account_name")}
                                                    className="form-control"
                                                    value={this.state.bank_acc_name}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_acc_no">{t("bank-account.banks_account_no")}</label>
                                                <StringInput
                                                    onChange={this.handleChange}
                                                    id="bank_acc_no"
                                                    name="bank_acc_no"
                                                    className="form-control"
                                                    placeholder={t("bank-account.banks_account_no")}
                                                    value={this.state.bank_acc_no}
                                                // required
                                                />
                                            </div>

                                            {/* <div className="form-group">
                                                        <label
                                                            htmlFor="sequence">{t('bank-account.sequence')}</label>
                                                        <StringInput
                                                            onChange={this.handleChange}
                                                            id="sequence"
                                                            name="sequence"
                                                            className="form-control"
                                                            required
                                                            value={this.state.sequence}
                                                        />
                                                    </div> */}

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="member_group">{t("bank-account.member_group")}</label>

                                                <Select
                                                    id="member_group"
                                                    name="member_group"
                                                    options={memberGroupArry}
                                                    className="form-control"
                                                    onChange={this.handleMemberGroupChange}
                                                    value={this.state.memberGroupId}
                                                // required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="active_day">{t("bank-account.active-day")}</label>
                                                <Select2
                                                    id="active_day"
                                                    name="active_day"
                                                    options={activeDayArr}
                                                    className="form-control"
                                                    onChange={this.handleActiveDayChange}
                                                    value={this.state.selectedActiveDay}
                                                />
                                                <div className="d-flex align-items-center justify-content-end mt-3">
                                                    <input type="radio" id="select_all" name="vendor" value="select_all" onClick={(e) => this.handleActiveDaySelectAll(e, activeDayArr)} />
                                                    <label className="mb-0 ml-2" for="select_all">{t("global.select-all")}</label>
                                                    <input className="ml-3" type="radio" id="unselect_all" name="vendor" value="unselect_all" onClick={this.handleActiveDayUnSelectAll} />
                                                    <label className="mb-0 ml-2" for="unselect_all">{t("global.unselect-all")}</label>
                                                    {/* <button
                                                        type="button"
                                                        className="btn btn-primary mr-1"
                                                        id="selectbtn-vendor"
                                                        onClick={(e) => this.handleActiveDaySelectAll(e, activeDayArr)}>
                                                        {t("global.select-all")}
                                                    </button>
                                                    <button type="button" className="btn btn-primary" id="unselectbtn-vendor" onClick={this.handleActiveDayUnSelectAll}>
                                                        {t("global.unselect-all")}
                                                    </button> */}
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="active_hour">{t("bank-account.active-hour")}</label>
                                                <div>
                                                    <TimeField
                                                        id='active_start_time'
                                                        value={this.state.active_start_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                        onChange={this.handleActiveHourChange} // {Function} required
                                                        input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                        colon=":"                          // {String}   default: ":"
                                                        showSeconds                        // {Boolean}  default: false
                                                        style={{ width: '48%', display: 'inline' }}
                                                    />
                                                    &nbsp;
                                                    -
                                                    &nbsp;
                                                    <TimeField
                                                        id='active_end_time'
                                                        value={this.state.active_end_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                        onChange={this.handleActiveHourChange} // {Function} required
                                                        input={<input className="form-control" display='inline' />}   // {Element}  default: <input type="text" />
                                                        colon=":"                          // {String}   default: ":"
                                                        showSeconds                        // {Boolean}  default: false
                                                        style={{ width: '48%', display: 'inline' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <div>
                                                    <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* {this.state.loading === true ? <Skeleton count={4}/> :
                                        <div className="col-md-6 float-right">
                                            <h4 className="card-title mb-3">{t('bank-account.bank_threshold')}</h4>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="threshold_status">
                                                            {t('bank-account.threshold_status')}
                                                        </label>

                                                        <Select
                                                            id="threshold_status"
                                                            name="threshold_status"
                                                            options={thresholdStatus}
                                                            className="form-control"
                                                            onChange={this.handleThresholdStatusChange}
                                                            value={this.state.currentThresholdStatus}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="deposit_threshold">
                                                            {t('bank-account.deposit_threshold')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.handleChange}
                                                            id="deposit_threshold"
                                                            name="deposit_threshold"
                                                            className="form-control"
                                                            defaultValue={0}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="no_of_deposit_threshold">
                                                            {t('bank-account.no_of_deposit_threshold')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.handleChange}
                                                            id="no_of_deposit_threshold"
                                                            name="no_of_deposit_threshold"
                                                            className="form-control"
                                                            defaultValue={0}
                                                        />

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="next_active_bank_account">
                                                            {t('bank-account.next_active_bank_account')}
                                                        </label>

                                                        <Select
                                                            id="active_bank_account"
                                                            name="active_bank_account"
                                                            options={nextActiveAccountArry}
                                                            className="form-control"
                                                            onChange={this.handleNextActiveBankChange}
                                                            value={this.state.currentNextActiveAccount}
                                                        />
                                                    </div>

                                                   

                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { bankAccount, currentUser } = state;

    //Getting the state from the reducers
    return {
        bank_name_data: bankAccount.bank_name_data,
        bank_account_name_data: bankAccount.bank_account_name_data,
        show_bank_account_data: bankAccount.show_bank_account_data,
        update_bank_account_result: bankAccount.update_bank_account_result,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username,
        member_group_data: bankAccount.member_group_data,
    };
};

const mapDispatchToProps = {
    showBankAccount,
    getBankNameList,
    getBankAccountNameList,
    updateBankAccount,
    getMemberGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksAccountEdit));
