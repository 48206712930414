import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import {showWalletType, updateWalletType} from "../../store/ducks/walletType/actions";
import {formValidation} from "../../hoc/Shared/utility";

class WalletTypeEdit extends Component {
    state = {
        wallet_type: '',
        wallet_code: '',
        //status: '',
        loading: true
    };

    componentDidMount(){
        formValidation('wallet_type_form');

        //Swal.showLoading();

        const showWalletTypeData = {};
        showWalletTypeData.wallet_type_id = this.props.show_wallet_type_id;

        this.props.showWalletType(showWalletTypeData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_wallet_type_data !== this.props.show_wallet_type_data) {
            if (nextProps.show_wallet_type_data.data.responseCode === 200) {
                //Swal.close();

                this.setState({
                    loading: false,
                    wallet_type: nextProps.show_wallet_type_data.data.data[0].name,
                    wallet_code: nextProps.show_wallet_type_data.data.data[0].walletCode,
                    //status: nextProps.show_wallet_type_data.data.data[0].status
                })
            } else {
                return false;
            }
        }

        if(nextProps.update_wallet_type_result !== this.props.update_wallet_type_result){
            if(nextProps.update_wallet_type_result.responseCode === 200){
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_wallet_type_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                });
            }else{
                return false;
            }

        }

        return true;
    }

    getWalletTypeHandler = (event) => {
        this.setState({wallet_type: event.target.value});
    }

    getWalletCodeHandler = (event) => {
        this.setState({wallet_code: event.target.value});
    }

    // getStatusHandler = (event) => {
    //     this.setState({status: event.target.value});
    // }

    postWalletTypeHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};

                data.wallet_type = this.state.wallet_type;
                data.wallet_code = this.state.wallet_code;
                //data.status = this.state.status;
                data.id = this.props.show_wallet_type_id;

                this.props.updateWalletType(data);
            }
        })
    }

    render() {
        return(
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">Wallet Type</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">Edit Wallet Type</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4}/> :
                                    <form method="post" id="wallet_type_form" className="form-horizontal"
                                        onSubmit={this.postWalletTypeHandler} noValidate>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="wallet_type">
                                                    Wallet Type
                                                </label>

                                                <StringInput
                                                    onChange={this.getWalletTypeHandler}
                                                    id="wallet_type"
                                                    name="wallet_type"
                                                    className="form-control"
                                                    placeholder="Please enter wallet type"
                                                    value={this.state.wallet_type}
                                                    required
                                                />

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="wallet_code">
                                                    Wallet Code
                                                </label>

                                                <StringInput
                                                    onChange={this.getWalletCodeHandler}
                                                    id="wallet_code"
                                                    name="wallet_code"
                                                    className="form-control"
                                                    placeholder="Please enter wallet code"
                                                    value={this.state.wallet_code}
                                                />

                                            </div>

                                            {/* <div className="form-group">
                                                <label htmlFor="status">
                                                    Status
                                                </label>

                                                <StringInput
                                                    onChange={this.getStatusHandler}
                                                    id="status"
                                                    name="status"
                                                    className="form-control"
                                                    placeholder="Please enter status"
                                                    value={this.state.status}
                                                />

                                            </div> */}

                                            <div className="form-group mb-0 mt-3 justify-content-end">
                                                <div>
                                                    <Button
                                                        typeButton="submit"
                                                        classValue="btn btn-blue"
                                                        idValue="submit"
                                                        buttonName="Save"
                                                    />

                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-secondary ml-1"
                                                        idValue="back"
                                                        buttonName="Back to list"
                                                        clicked={() => this.props.changePageHandler('listing')}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {walletType} = state;

    //Getting the state from the reducers
    return {
        update_wallet_type_result: walletType.update_wallet_type_result,
        show_wallet_type_data: walletType.show_wallet_type_data
    }
};

const mapDispatchToProps = {
    updateWalletType,
    showWalletType
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletTypeEdit);