import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => (dispatch) => 
{
    axios.get('company_performance_by_day/list', {params: props})
    .then(function (response)
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        });
    })
    .catch(err =>
    {
      
    });
};