import React, { Component } from "react";
import { connect } from "react-redux";
import { getEmailBlastByID, updateEmailBlast, getMembers, excelTemplate } from "../../store/ducks/emailBlast/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
// import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import * as moment from "moment";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Select2 from "../../components/Input/Select2/Select2";
import Skeleton from "react-loading-skeleton";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

let members = [];
class EmailBlastEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            start_time: "",
            loading: true,
            error_message: [],
            touched: false,
            selectedMembers: [],
            description: "",
            type: "select",
            downloadUrl: "",
            uploadFile: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        formValidation("email_blast_form");

        let getEmailBlastData = {};
        getEmailBlastData.id = this.props.emailBlastId;
        this.props.getEmailBlastByID(getEmailBlastData);

        this.props.getMembers();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.rs !== undefined && nextProps.rs !== this.props.rs && nextState.loading === true) {
            let selectedMembers = [];
            Object.keys(nextProps.rs.data.result[0].member_id).map((item, i) => {
                return selectedMembers.push({
                    label: nextProps.rs.data.result[0].member_id[item].label,
                    value: nextProps.rs.data.result[0].member_id[item].value,
                });
            });

            this.setState({
                edit_id: nextProps.rs.data.result[0].id,
                title: nextProps.rs.data.result[0].title,
                description: nextProps.rs.data.result[0].details,
                selectedMembers: selectedMembers,
                start_time: new Date(nextProps.rs.data.result[0].start_time),
                loading: false,
            });
        }

        if (nextProps.get_members !== this.props.get_members) {
            if (nextProps.get_members.data.responseCode === 200) {
                Object.keys(nextProps.get_members.data.data.result).map((item, i) => {
                    return members.push({
                        label: nextProps.get_members.data.data.result[item].username,
                        value: nextProps.get_members.data.data.result[item].id,
                    });
                });
            } else if (nextProps.get_members.data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.get_members.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.update !== this.props.update) {
            if (nextProps.update.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.update.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.update.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.excel !== this.props.excel) {
            if (nextProps.excel.responseCode === 200) {
                this.setState({
                    downloadUrl: nextProps.excel.data.downloadUrl,
                });
            } else if (nextProps.excel.data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.excel.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleMembersChange = (event) => {
        this.setState({
            selectedMembers: event,
        });
    };

    updateEmailBlastHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                if(!this.state.uploadFile){
                    let updateEmailBlastData = {};
                    updateEmailBlastData.id = this.props.emailBlastId;
                    updateEmailBlastData.title = this.state.title;
                    updateEmailBlastData.start_time = moment(this.state.start_time).format("yyyy-MM-DD HH:mm:ss");
                    updateEmailBlastData.country_id = this.props.currentCountryId;
                    updateEmailBlastData.member_id = this.state.selectedMembers;
                    updateEmailBlastData.details = this.state.description;

                    this.props.updateEmailBlast(updateEmailBlastData);
                }else{
                    let formData = new FormData();
                    formData.append("id",this.props.emailBlastId);
                    formData.append("title",this.state.title);
                    formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm:ss"));
                    formData.append("country_id",this.props.currentCountryId);
                    formData.append("details",this.state.description);
                    formData.append("member_id[]", this.state.uploadFile);

                    this.props.updateEmailBlast(formData);
                }
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleMembersSelectAll = (event, membersArr) => {
        this.setState({
            selectedMembers: membersArr,
        });
    };

    handleMembersUnSelectAll = () => {
        this.setState({
            selectedMembers: [],
        });
    };

    handleSelectType = (event, type) => {
        this.setState({
            type: type,
        });
    };

    downloadHandler = () => {
        // fetch(this.state.downloadUrl).then((response) => {
        //     response.blob().then((blob) => {
        //         let url = window.URL.createObjectURL(blob);
        //         let a = document.createElement("a");
        //         a.href = url;
        //         a.download = "sample.csv";
        //         a.click();
        //     });
        // });

        
        const url = window.URL.createObjectURL(new Blob([this.state.downloadUrl])); 
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "sample.csv");
        document.body.appendChild(link);
        link.click();
    };

    onInputChange = (event) => {
        this.setState({
            uploadFile: event.target.files[0],
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-blast.edit-email-blast")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="email_blast_form" className="form-horizontal" onSubmit={this.updateEmailBlastHandler} noValidate>
                                    <ValidationError formName="email_blast_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.title")}</label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={t("email-blast.title")}
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.start-time")}</label>
                                                <DatePicker
                                                    name="start_time"
                                                    className="form-control"
                                                    selected={this.state.start_time}
                                                    onChange={this.handleStartTimeChange}
                                                    minDate={new Date()}
                                                    showTimeSelect
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.members")}</label>

                                                <label className="mb-0 ml-2" for="select">
                                                    {t("global.select")}
                                                </label>
                                                <input
                                                    className="ml-3 mb-0 ml-2 align-items-center"
                                                    type="radio"
                                                    id="file"
                                                    name="vendor"
                                                    value="file"
                                                    onClick={(e) => this.handleSelectType(e, "file")}
                                                />
                                                <label className="mb-0 ml-2" for="file">
                                                    {t("translation.files")}
                                                </label>

                                                {this.state.type === "select" ? (
                                                    <>
                                                        <Select2
                                                            id="members"
                                                            name="members"
                                                            options={members}
                                                            className="form-control"
                                                            onChange={this.handleMembersChange}
                                                            value={this.state.selectedMembers}
                                                            // maxSelectOption={3}
                                                            // isOptionDisabled={this.state.selectedMembers ? true : false}
                                                            selectedOptions={this.state.selectedMembers}
                                                        />

                                                        <div className="d-flex align-items-center justify-content-end mt-3">
                                                            <input
                                                                type="radio"
                                                                id="select_all"
                                                                name="vendor"
                                                                value="select_all"
                                                                onClick={(e) => this.handleMembersSelectAll(e, members)}
                                                            />
                                                            <label className="mb-0 ml-2" for="select_all">
                                                                {t("global.select-all")}
                                                            </label>
                                                            <input
                                                                className="ml-3"
                                                                type="radio"
                                                                id="unselect_all"
                                                                name="vendor"
                                                                value="unselect_all"
                                                                onClick={this.handleMembersUnSelectAll}
                                                            />
                                                            <label className="mb-0 ml-2" for="unselect_all">
                                                                {t("global.unselect-all")}
                                                            </label>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <input id="file" name="file" type="file" onChange={this.onInputChange} className="filter-input" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                                            <div>
                                                                <Button
                                                                    typeButton="button"
                                                                    classValue="btn btn-secondary ml-1"
                                                                    idValue="download"
                                                                    buttonName={t("voucher.download")}
                                                                    clicked={this.downloadHandler}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <JoditEditor
                                                value={this.state.description || ""}
                                                onBlur={(newDescription) => {
                                                    this.setState({
                                                        description: newDescription,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("gold_digger.edit_setting")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-blast.email-blast")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rs: state.emailBlast.rs,
        update: state.emailBlast.update,
        get_members: state.emailBlast.get_members,
        currentCountryId: state.currentUser.data.country_id,
        excel: state.emailBlast.excel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMembers: () => dispatch(getMembers()),
        getEmailBlastByID: (data) => dispatch(getEmailBlastByID(data)),
        updateEmailBlast: (data) => dispatch(updateEmailBlast(data)),
        excelTemplate: () => dispatch(excelTemplate()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailBlastEdit));
