import React, { Component } from "react";
import { connect } from "react-redux";
import { createEmailContent, storeEmailContent } from "../../store/ducks/emailContent/actions";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

let languageArray = [];

class EmailContentCreate extends Component {
    constructor() {
        super();
        this.state = {
            type: "",
            subject: "",
            language: "",
            content: "",
            loading: true,
            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        this.props.createEmailContent();
        formValidation("email_content_form");
    }

    storeEmailContentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let storeEmailContentData = {};
                storeEmailContentData.type = this.state.type;
                storeEmailContentData.subject = this.state.subject;
                storeEmailContentData.language = this.state.language;
                storeEmailContentData.content = this.state.content;
                this.props.storeEmailContent(storeEmailContentData);
            }
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContentCreate] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.emailContentCreateData !== this.props.emailContentCreateData && nextProps.emailContentCreateData !== undefined) {
            if (nextProps.emailContentCreateData.data.responseCode === 200 && nextProps.emailContentCreateData.data.msgType === 'success') {
                let languageData = nextProps.emailContentCreateData.data.data.language;
                languageArray = [];
                Object.keys(languageData).map((item, i) => {
                    return languageArray.push({
                        label: languageData[item].language,
                        value: languageData[item].languageId,
                    });
                });
                this.setState({
                    language: languageData[0].languageId,
                    loading: false,
                });
            }else{
                this.setState({
                    loading: true,
                }, () => {
                    filterErrorHandler(nextProps.emailContentCreateData.data.message)
                });
            }
        }

        if (nextProps.storeEmailContentResult !== this.props.storeEmailContentResult && nextProps.storeEmailContentResult !== undefined) {
            if (nextProps.storeEmailContentResult.responseCode === 200 && nextProps.storeEmailContentResult.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeEmailContentResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    loading: false,
                    error_message: nextProps.storeEmailContentResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.add-email-content")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="email_content_form" className="form-horizontal row" onSubmit={this.storeEmailContentHandler} noValidate>
                                    <ValidationError formName="email_content_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="email_type">{t("email-content.email-type")}</label>
                                            <StringInput
                                                id="type"
                                                name="type"
                                                className="form-control"
                                                placeholder={t("email-content.email-type")}
                                                value={this.state.type}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="email_subject">{t("email-content.email-subject")}</label>
                                            <StringInput
                                                id="subject"
                                                name="subject"
                                                className="form-control"
                                                placeholder={t("email-content.email-subject")}
                                                value={this.state.subject}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="language">{t("global.languages")}</label>
                                            <Select id="language" name="language" options={languageArray} onChange={this.handleChange} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="content">{t("email-content.email-content")}</label>

                                        <JoditEditor
                                            value={this.state.content}
                                            onBlur={(newContent) => {
                                                this.setState({
                                                    content: newContent,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className="col-12 form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.add-email-content")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-content.email-content")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emailContentCreateData: state.emailContent.email_content_create_data,
        storeEmailContentResult: state.emailContent.email_content_store_result,
        currentLanguageCode: state.currentUser.data.language_code,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createEmailContent: () => dispatch(createEmailContent()),
        storeEmailContent: (storeEmailContentData) => dispatch(storeEmailContent(storeEmailContentData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailContentCreate));
