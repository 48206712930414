import React, { Component } from "react";
import { connect } from "react-redux";
import { getStakingSettings } from "../../store/ducks/stakingSettings/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StakingSettingsListing from "./StakingSettingsListing";
import StakingSettingsEdit from "./StakingSettingsEdit";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

class StakingSettings extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            popupLoading: false,

            stakingEditId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getStakingSettings(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.referralSettingsData !== this.props.referralSettingsData && nextProps.referralSettingsData !== undefined && nextProps.referralSettingsData.status === 200 && this.props.currentCountryId !== nextState.country_id)
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let referralPermission = this.props.currentPermission.filter((item) => item.includes("referral_settings_"));
            // console.log(referralPermission);
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.stakingSettingsData !== this.props.stakingSettingsData && nextProps.stakingSettingsData !== undefined && nextProps.stakingSettingsData.status === 200) {
            this.setState({
                data: nextProps.stakingSettingsData.data.data.result.row,
                totalRecords: nextProps.stakingSettingsData.data.data.result.pagination.total_record,
                totalPages: nextProps.stakingSettingsData.data.data.result.pagination.total_page,
                loading: false,
            });
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getStakingSettings(listingArr);
    };

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            stakingEditId: id,
            pageShow: "edit",
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        // listingArr.country_id = this.props.currentCountryId;
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getStakingSettings(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, output;

        if (this.state.permission.includes("staking_settings_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.stakingSettingsId}`}
                    idValue={row.stakingSettingsId === undefined ? 0 : row.stakingSettingsId}
                    clicked={(event) => this.editHandler(event, row.stakingSettingsId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.fetchData();
            this.props.getStakingSettings(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.fetchData();
                this.props.getStakingSettings(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("staking-settings.slug"), dataField: "slug" },
            { text: t("staking-settings.interest-percentage"), dataField: "interest_percentage" },
            { text: t("staking-settings.referrer-interest-percentage"), dataField: "referrer_interest_percentage" },
            { text: t("staking-settings.min-payout"), dataField: "min_payout" },
            { text: t("global.updated-time"), dataField: "updatedDate" },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "65px" } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    stakingSettingsId: val.stakingSettingsId,
                    slug: val.slug,
                    interest_percentage: val.interest_percentage,
                    referrer_interest_percentage: val.referrer_interest_percentage,
                    min_payout: val.min_payout,
                    updatedDate: val.updatedDate
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <StakingSettingsListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("staking_settings_update") && this.state.pageShow === "edit" && (
                    <StakingSettingsEdit stakingEditId={this.state.stakingEditId} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        stakingSettingsData: state.stakingSettings.staking_settings_data,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getStakingSettings: (data) => dispatch(getStakingSettings(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(StakingSettings));
