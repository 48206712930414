import axios from "../../../common/config/axios";
import * as type from "../home/types";

export const getCountry = (data) => dispatch => {
    axios.get('country', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_COUNTRY,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};


export const getMemberInfo = (data) => dispatch => {
    axios.get('member/get_member_info', {
        params: {
            "email": data['email']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_INFO,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};