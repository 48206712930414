import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import { getListing } from "../../../store/ducks/event/qnaBet/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import QNABetListing from "./QNABetListing";
import Swal from "sweetalert2";
import moment from "moment";

const initialInputState =
{
    /* form input value */
    date: ''
};

class QNABet extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);

        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            settingID: null,
            loading: true,

            permission: [],
            filter: initialInputState,
            dropdown: {
                leagueTeam: [],
                status: []
            }
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getListing(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
            let rowDataArray = [];
            nextProps.rs.data.data.result.row.map((val) => {
                rowDataArray[val.id] = val;
                return null;
            });

            this.setState({
                data: nextProps.rs.data.data.result.row,
                totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                totalPages: nextProps.rs.data.data.result.pagination.total_page,
                rowDataArray: rowDataArray,
                loading: false
            });
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    onInputChange(event) {
        // const { t } = this.props;
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }

        this.setState({
            filter: newFilter
        });
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            rowData: this.state.rowDataArray[id],
            pageShow: "edit",
        });
    };

    updateAnswerHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            rowData: this.state.rowDataArray[id],
            pageShow: "updateAnswer",
        });
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        return listingArr;
    }
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getListing(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getListing(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.getFilterArray();
                this.props.getListing(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <QNABetListing
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={this.clearHandler}
                        refreshHandler={this.refreshHandler}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        dropdown={this.state.dropdown}
                        onSort={this.onSort}

                        noFormatter={this.noFormatter}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.eventQNABet.rs,
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getListing: (data) => dispatch(getListing(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(QNABet));
