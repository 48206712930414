import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { upload } from "../../store/ducks/mobileApps/actions";
// import Dropzone from "react-dropzone";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import Swal from "sweetalert2";

class MobileAppsUpload extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            touched: false,
            error_message: [],
            uploadFile: null
        };
    }

    componentDidMount() {
        formValidation("upload_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.uploadRs !== this.props.uploadRs) {
            document.getElementById('file').value = '';

            if (nextProps.uploadRs.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.uploadRs.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.uploadRs.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.uploadRs.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }
    /* ============ Component Lifecycle [END] ============ */
    onInputChange = (event) => {
        this.setState({
            uploadFile: event.target.files[0]
        });
    }

    uploadHandler = (e) => {
        const { t } = this.props;
        e.preventDefault();

        if (!(this.state.uploadFile)) {
            Swal.fire({
                title: t("global.error"),
                icon: "error",
                html: t('mobile_apps.select_file'),
                showConfirmButton: true,
                confirmButtonText: t("global.ok")
            });
        } else {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("mobile_apps.upload"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });     

                    let formData = new FormData();
                    formData.append("uploadFile[]", this.state.uploadFile);
                    formData.append("country_id", this.props.currentUser.country_id);

                    this.props.upload(formData);

                    this.setState({
                        uploadFile: null
                    });
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        
        /* ======================== datatables data assign [START] ======================== */

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header">
                        <div className="d-flex">
                            <div className="my-auto">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler("listing")}
                                />
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto ml-2">{t("mobile_apps.mobile_apps")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("mobile_apps.upload_apps")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="upload_form" className="form-horizontal" enctype="multipart/form-data" onSubmit={this.uploadHandler} noValidate>
                                    <ValidationError formName="question_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input
                                                    id='file'
                                                    name='file'
                                                    type='file'
                                                    onChange={this.onInputChange}
                                                    className="filter-input"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("mobile_apps.upload")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uploadRs: state.mobileApps.upload,
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        upload: (data) => dispatch(upload(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces("translation")(MobileAppsUpload));
