import axios from "../../../../common/config/axios";
import * as type from "./types";

export const getBetListing = (params) => (dispatch, getState) => {
    axios.get("event_bet_listing/list", {params:params})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event Bet Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getOptionData = (data) => (dispatch, getState) => {
    axios.get("event_bet_listing/get_option_data", {}).then((res) => {
            dispatch({
                type: type.OPTION_DATA,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event Bet Error", err);
            dispatch({
                type: type.OPTION_DATA,
                payload: [],
            });
        });
};