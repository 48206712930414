import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const dashboardReducer = createReducer(initialState) ({
    [type.GET_TOTAL_RECORD]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.GET_BANK_TRANSFER_RECORD]: (state, action) => ({
        ...state,
        get_bank_transfer_record: action.payload,
    }),

    [type.GET_FPAY_RECORD]: (state, action) => ({
        ...state,
        get_fpay_record: action.payload,
    }),

    [type.GET_WITHDRAW_RECORD]: (state, action) => ({
        ...state,
        get_withdraw_record: action.payload,
    }),

    [type.GET_DDATA]: (state, action) => ({
        ...state,
        get_ddata: action.payload,
    }),

    [type.GET_DASHBOARD_DATA]: (state, action) => ({
        ...state,
        get_dashboard_data: action.payload,
    })
});

export default dashboardReducer;
