import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const specialBirthdayGiftReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_SPECIAL_BIRTHDAY_GIFT]: (state = initialState, action) => ({
        ...state,
        special_birthday_gift_data: action.payload
    }),

    [type.POST_SPECIAL_BIRTHDAY_GIFT]: (state = initialState, action) => ({
        ...state,
        post_special_birthday_gift_data: action.payload
    }),

    [type.GET_SPECIAL_BIRTHDAY_GIFT_MEMBERS]: (state = initialState, action) => ({
        ...state,
        special_birthday_gift_members_data: action.payload
    }),

    [type.SHOW_SPECIAL_BIRTHDAY_GIFT]: (state = initialState, action) => ({
        ...state,
        show_special_birthday_gift_members_data: action.payload
    }),

    [type.UPDATE_SPECIAL_BIRTHDAY_GIFT]: (state = initialState, action) => ({
        ...state,
        update_special_birthday_gift_members_data: action.payload
    }),

    [type.DELETE_SPECIAL_BIRTHDAY_GIFT]: (state = initialState, action) => ({
        ...state,
        delete_special_birthday_gift_members_data: action.payload
    })
});

export default specialBirthdayGiftReducer;