import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withNamespaces} from "react-i18next";
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import {showLoginIPAddress} from "../../store/ducks/ip/actions";

class LoginIPAddressShow extends Component {
    state = {
        data: [],
        loading: true
    };

    componentDidMount() {
        const showLoginIPAddressData = {};
        showLoginIPAddressData.ip_address = this.props.show_ip_address;
        this.props.showLoginIPAddress(showLoginIPAddressData);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.show_login_ip_address_data !== this.props.show_login_ip_address_data) {
            if (nextProps.show_login_ip_address_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.show_login_ip_address_data.data.data
                })
            } else {
                this.setState({loading: false});
                Swal.fire({
                    title: nextProps.show_login_ip_address_data.data.message,
                    icon: nextProps.show_login_ip_address_data.data.msgType,
                    confirmButtonText: `OK`,
                })
            }
        }

        return true;
    }

    render() {
        const {t} = this.props;
        let data = this.state.data;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("ip.ip-address")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("ip.show-ip-address-details")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <th>{t("ip.email")}</th>
                                            <td>
                                                {data.map(item => (

                                                    <strong key={item.email}>{item.email} </strong>

                                                ))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("ip.username")}</th>
                                            <td>
                                                {data.map(item => (

                                                    <strong key={item.username}>{item.username} </strong>

                                                ))}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {ipAddress} = state;

    //Getting the state from the reducers
    return {
        show_login_ip_address_data: ipAddress.show_login_ip_address_data
    }
};

const mapDispatchToProps = {
    showLoginIPAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(LoginIPAddressShow));