import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Monthrangepicker from "react-bootstrap-monthrangepicker";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
// import Skeleton from "react-loading-skeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { AsyncPaginate } from 'react-select-async-paginate';

import Select from "../../../components/Input/Select/Select";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../../components/Input/StringInput/StringInput";
import Button from "../../../components/Button/Button";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Excel from "../../../assets/images/excel.svg";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

// import gameList from "../../../assets/locale/en/games.json";
// import * as actions from '../../../store/ducks/report/actions';
import { getGameList } from '../../../store/ducks/report/actions';


class VendorStatusReportListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            showCard: false
        };
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    getFilterHandler = (event) => {
        this.setState({ filter: event.target.value });
    }

    loadOptions = async (search, prevOptions, { page }) => {
        const options = [];
        let totalPage = 0;
        // let totalRecord = 0;
        const limit = Number(process.env.REACT_APP_DATATABLE_LIMIT);

        /* ============ get list api [START] ============*/
        let data = {};
        data.page = page;
        data.limit = limit;
        data.game_title = search;
        data.country_id = this.props.currentUser.country_id;
        // let response = await actions.getGameList(data);
        let response = await getGameList(data);
        // console.log(response);
        /* ============ get list api [END] ============*/
        if (response.data.result.row.length > 0) {
            response.data.result.row.forEach(val => {
                options.push({
                    id: val.gameCode,
                    label: `[${val.vendorSlug.toString().toUpperCase()}] ${val.gameTitle}`,
                    key: val.gameTitle,
                });
            });
            totalPage = response.data.result.pagination.total_page;
            // totalRecord = response.data.result.pagination.total_record;
        }

        const hasMore = totalPage > page;

        // console.log(totalPage+' | '+Math.ceil(totalRecord / limit)+' | '+page);

        return {
            options: options,
            hasMore: hasMore,
            additional:
            {
                page: page + 1
            }
        };
    }

    render() {
        // console.log(this.props.subData);
        const { t } = this.props;

        let filterSelection = [
            {
                label: t("company-performance-by-day.select-filter"),
                value: "",
                selected: true,
            },
            {
                label: t("company-performance-by-day.daily"),
                value: "daily",
            },
            {
                label: t("company-performance-by-day.monthly"),
                value: "monthly",
            },
            {
                label: t("company-performance-by-day.yearly"),
                value: "yearly",
            },
        ];

        const expandRow = {
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: (this.props.loading) ? [] : null,
            renderer: (row) => {
                // console.log(props.expandData);
                // console.log(`${row.date}_${row.slug}`);
                // console.log(products['2022-01-21_evo']);
                // console.log(props.expandData[`${row.date}_${row.slug}`]);
                // console.log(this.props.subLoading)
                // console.log(row);
                return (
                    <div className="font-weight-bold first-expand-row">
                        {this.props.subData[`${row.date}_${row.vendor_slug}`] ? (
                            <BootstrapTable
                                keyField='id'
                                data={(this.props.subData[`${row.date}_${row.vendor_slug}`] || [])}
                                columns={(this.props.subColumns || [])}
                                expandRow={[]}
                            />
                        ) : (
                            <Skeleton count={2} />
                        )}
                    </div>
                )
            },

            onExpand: (row, isExpand, rowIndex, e) => {
                if (isExpand) {
                    this.props.onExpandHandler(row.vendor_id, row.date, false);
                }
            },

            onExpandAll: (isExpandAll, rows, e) => {
                if (isExpandAll) {
                    this.props.onExpandHandler(null, null, true);
                }
            },

            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return (
                        <b className="row-expand-btn active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                            </svg>
                        </b>
                    );
                }

                return (
                    <b className="row-expand-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </b>
                );
            },

            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <b className="row-expand-btn active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                            </svg>
                        </b>
                    );
                }

                return (
                    <b className="row-expand-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </b>
                );
            }
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("report.vendor-win-lose-report")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            {this.props.loading ? (
                                <Skeleton count={2} />
                            ) : (
                                <div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">{t("company-performance-by-day.select-filter")}</label>
                                            <Select
                                                options={filterSelection}
                                                name="filter"
                                                className="form-control filter-input"
                                                onChange={this.getFilterHandler}
                                                required={false}
                                                value={this.state.filter}
                                            />
                                        </div>
                                    </div>

                                    {this.state.filter === "daily" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.date")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="date"
                                                        name="date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={false}
                                                        value={this.props.filter.date}
                                                        onChange={this.props.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.filter === "monthly" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.monthly")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <Monthrangepicker
                                                        monthOrQuarter={0}
                                                        containerClass="monthly w-100"
                                                        onEvent={this.props.onInputChange}
                                                    >
                                                        <button className="form-control filter-input">
                                                            {(this.props.filter.start_month && this.props.filter.end_month) 
                                                            ?
                                                                `${this.props.filter.start_month} ${t('global.to')} ${this.props.filter.end_month}` 
                                                            : 
                                                                ''
                                                            }
                                                        </button>
                                                    </Monthrangepicker>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.filter === "yearly" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.yearly")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <Monthrangepicker
                                                        monthOrQuarter={2}
                                                        containerClass="yearly w-100"
                                                        onEvent={this.props.onInputChange}
                                                    >
                                                        <button className="form-control filter-input">
                                                            {(this.props.filter.start_year && this.props.filter.end_year) 
                                                            ?
                                                                `${this.props.filter.start_year} ${t('global.to')} ${this.props.filter.end_year}` 
                                                            : 
                                                                ''
                                                            }
                                                        </button>
                                                    </Monthrangepicker>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="vendor" className="font-weight-bolder">
                                                {t("report.vendor")}
                                            </label>
                                            <div className="select-input">
                                                <select
                                                    className="form-control filter-input"
                                                    id="vendor"
                                                    name="vendor"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.vendor}>
                                                    <option value=""></option>
                                                    {this.props.ddVendor}
                                                </select>
                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="vendor" className="font-weight-bolder">
                                                {t("report.category")}
                                            </label>
                                            <div className="select-input">
                                                <select
                                                    className="form-control filter-input"
                                                    id="game_type"
                                                    name="game_type"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.game_type}>
                                                    <option value=""></option>
                                                    {this.props.ddGameType}
                                                </select>
                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="vendor" className="font-weight-bolder">
                                                {t("report.game-name")}
                                            </label>
                                            <AsyncPaginate
                                                isClearable
                                                id='game_name'
                                                name='game_name'
                                                additional={{
                                                    page: 1
                                                }}
                                                value={this.props.asyncSelectedVal}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.id}
                                                loadOptions={this.loadOptions}
                                                onChange={(value) => this.props.onInputChangeAsync(value, 'game_name')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col mt-auto">
                                        {this.props.loading ? (
                                            <Skeleton count={2} />
                                        ) : (
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.searchHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.sync-filter")}
                                                    clicked={() => this.props.syncHandler()}
                                                    disabled={this.props.disabled}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                                {(() => {
                                                    if (this.props.totalRecords > 0) {
                                                        return (
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-blue mr-2"
                                                                clicked={() => this.props.exportExcelHandler()}
                                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                                buttonName={t("report.export-excel")}
                                                            />
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <DataTable
                                        keyField="row_num"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={this.props.pageChangeHandler}
                                        sorted={this.props.sorted}
                                        sizePerPageHandler={this.props.sizePerPageHandler}
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        expandRow={expandRow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    // return state
    return {
        currentUser: state.currentUser.data,
    }
};

// Connect redux
export default connect(mapStateToProps, null)(withNamespaces('translation')(VendorStatusReportListing));