import React, {Component} from 'react';
import {connect} from 'react-redux';
// import Swal from 'sweetalert2';
import {withNamespaces} from 'react-i18next';

import InboxListing from "./InboxListing";
import InboxCreate from "./InboxCreate";
import InboxShow from "./InboxShow";
import Button from "../../components/Button/Button";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

import {getDropDown, deleteInbox, getInbox} from "../../store/ducks/inbox/actions";
import { filterErrorHandler } from "../../hoc/Shared/utility";


const initialInputState =
{
    /* form input value */
    title: '',
    category: ''
};

let inboxData = {};
class Inbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageShow: "listing",

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            sorted: null,
            totalPages: 0,
            totalRecords: 0,
            searchValue: '',

            loading: true,

            data: [],

            dropDown: [],
  
            filter: initialInputState,

            isOpen: false,
            currentInboxId: '',

            permission: []

        }
    }

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        inboxData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        inboxData.limit = this.state.limit;
        inboxData.order = JSON.stringify(this.state.sorted);
        inboxData.title = this.state.filter.title;
        inboxData.category = this.state.filter.category;
        inboxData.lang_id = this.props.currentLanguageId;
        inboxData.country_id = this.props.currentCountryId;

        this.props.getInbox(inboxData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.inbox_data !== this.props.inbox_data && nextProps.inbox_data !== undefined) {
            if(nextProps.inbox_data.data.responseCode === 200 && nextProps.inbox_data.data.msgType === 'success'){
                this.setState({
                    loading: false,
                    data: nextProps.inbox_data.data.data,
                    totalRecords: nextProps.inbox_data.data.totalRecords,
                    totalPages: nextProps.inbox_data.data.totalPages,
                })
            }else{
                this.setState({
                    loading: false,
                    totalPages: 0,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.inbox_data.data.message);
                });
            }
            
        }

        if(nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
          if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
            this.setState({
                dropDown: nextProps.dropDown.data,
            })
          } else {
            this.setState({
                loading: false,
            }, () => 
            {
                filterErrorHandler(nextProps.dropDown.message);
            });
          }
        }

        // if (nextProps.delete_inbox_result !== this.props.delete_inbox_result) {
        //     const { t } = this.props;
        //     Swal.fire({
        //         title: t("global.record-deleted"),
        //         icon: nextProps.delete_inbox_result.msgType,
        //         confirmButtonText: t("global.ok"),
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.fetchData();
        //         }
        //     });
        // }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    onInputChange(event)
    {
      let newFilter = Object.assign({}, this.state.filter);
      newFilter[event.target.id] = event.target.value;

      this.setState({
        filter: newFilter
      })
    }

    refreshHandler = () => 
    {
        this.setState({
          loading: true,
          data: [],
        }, ()=>
        {
          const payload = {
            ...inboxData, 
          ...this.state.filter
          }
          this.props.getInbox(payload)
        });
    }

    clearHandler = () => 
    {
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            sorted: null,
            filter: initialInputState,
            loading: true,
            data: [],
        }, ()=>
        
        {
            this.fetchData();
        });
    }

    // deleteInboxHandler = (e, inbox_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;

    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deleteinboxData = {};
    //             deleteinboxData.id = inbox_id;

    //             this.props.deleteInbox(deleteinboxData);
    //         }
    //     });
    // }


    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;

        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.Id}`}
                clicked={() => this.changePageHandler('show', row.Id)}
            />;

        // editButton =
        //     <Button
        //         typeButton="button"
        //         classValue="btn btn-sm btn-info mr-2"
        //         buttonName={<FontAwesomeIcon icon={faPen}/>}
        //         key={`edit-${row.Id}`}
        //         clicked={() => this.changePageHandler('edit', row.Id)}
        //     />;

        // deleteButton =
        //     <Button
        //         typeButton="button"
        //         classValue="btn btn-sm btn-danger mr-2"
        //         clicked={(event) => this.deleteInboxHandler(event, row.Id)}
        //         key={`delete-${row.Id}`}
        //         buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //     />;

        output = [viewButton, editButton];
        return output;
    }

    changePageHandler = (page, Id) => {
        this.setState({
            pageShow: page,
            currentInboxId: Id,
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    render() {

        const {t} = this.props;
        let rsListing = []
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit))
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: "Id",
                dataField: "Id",
                keyField: "Inbox_Id",
                hidden: true,
            },

            {
                text: t('inbox.title'),
                keyField: "Inbox_title",
                dataField: "title",
                sort: true,
                onSort: this.onSort
            },

            {
                text: t('inbox.category'),
                keyField: "Inbox_category",
                dataField: "category",
            },

            {
                text: t('global.action'),
                keyField: "action",
                dataField: "#",
                formatter: this.actionFormatter,
                headerStyle: { width: "65px" },
            },
        ];

        if (this.state.data) {
          rsListing = this.state.data.map((val, idx) => {
              
              return {
                  no: idx,
                  Id: val.Id,
                  title: val.title,
                  category: t(
                      `inbox-type.${val.category}`
                  ),
                  action: this.actionFormatter
              };
          });
      }

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('inbox.inbox')}</h4>
                            </div>
                        </div>
                    </div>
                    <InboxListing
                        title={this.state.filter.title}
                        category={this.state.filter.category}
                        onInputChange={(event) => this.onInputChange(event)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={rsListing}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        changePageHandler={this.changePageHandler}
                        /**filter drop down */
                        ddCategory={this.state.dropDown.category}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <InboxCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <InboxShow
                    show_inbox_id={this.state.currentInboxId || this.props.location.state.show_inbox_id}
                    changePageHandler={(page, id) => this.changePageHandler(page, id)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {inbox,currentUser} = state;

    return {
        inbox_data: inbox.inbox_data,
        delete_inbox_result: inbox.delete_inbox_result,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        dropDown: inbox.dropDown
    }
};

const mapDispatchToProps = {
    getInbox,
    getDropDown,
    deleteInbox
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Inbox));