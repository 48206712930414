import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('up_level_bonus/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getMemberLevelTracker = (props) => (dispatch) => 
{
  axios.get('member_level_tracker/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.MEMBER_LEVEL_TRACKER_LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};


export const getDropDown = () => (dispatch, getState) =>
{
    axios.get('member_level_tracker/get_filter_data',{
        params: {
            country_id: getState().currentUser.data.country_id,
        },
    })
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};