import React, { Component } from "react";
import { connect } from "react-redux";
import { getGameList, getFilterData, updateGameListStatus, updateGameListFav, updateGameListDemo } from "../../store/ducks/gameList/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import GameListing from "./GameListing";
import GameListCreate from "./GameListCreate";
import GameListEdit from "./GameListEdit";
import Swal from "sweetalert2";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

const initialInputState = {
    game_title: "",
    game_code: "",
    game_type: "",
    game_category: "",
    status: "",
    fav: "",
    vendor: "",
};

class GameList extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],
            filter: initialInputState,
            dropDown: {
                gameTypeOption: [],
                vendorOption: [],
                gameCategorytOption: [],
            },
            popupLoading: false,

            gameListId: null,
            gameListEditId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getGameList(listingArr);
        this.props.getFilterData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log("[GameList] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let gameListPermission = this.props.currentPermission.filter((item) => item.includes("game_list_"));
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.gameListData !== this.props.gameListData && nextProps.gameListData !== undefined && nextProps.gameListData.status === 200) {
            this.setState({
                data: nextProps.gameListData.data.data.result.row,
                totalRecords: nextProps.gameListData.data.data.result.pagination.total_record,
                totalPages: nextProps.gameListData.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        if (nextProps.gameListFilterData !== this.props.gameListFilterData && nextProps.gameListFilterData !== undefined) {
            if (nextProps.gameListFilterData.data && nextProps.gameListFilterData.data.responseCode === 200 && nextProps.gameListFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        gameTypeOption: nextProps.gameListFilterData.data.data.gameType,
                        vendorOption: nextProps.gameListFilterData.data.data.vendor,
                        gameCategoryOption: nextProps.gameListFilterData.data.data.gameCategory,
                    },
                });
            }
            // Swal.close();
        }

        if (nextProps.gameListUpdateStatusResult !== this.props.gameListUpdateStatusResult) {
            if(nextProps.gameListUpdateStatusResult.responseCode === 200 && nextProps.gameListUpdateStatusResult.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    const listingArr = this.getFilterArray();
                    this.props.getGameList(listingArr);
                });
            }else{
                let msg = null;
                msg = '<ul>';
                nextProps.gameListUpdateStatusResult.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        if (nextProps.gameListUpdateFavResult !== this.props.gameListUpdateFavResult) {
            const listingArr = this.getFilterArray();
            this.props.getGameList(listingArr);
        }

        if (nextProps.gameListUpdateDemoResult !== this.props.gameListUpdateDemoResult) {
            const listingArr = this.getFilterArray();
            this.props.getGameList(listingArr);
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                searchValue: "",
                loading: true,
                filter: newFilter,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getGameList(listingArr);
            }
        );
    };

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getGameList(listingArr);
        });
    };

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            gameListEditId: id,
            pageShow: "edit",
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.search = this.state.searchValue;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getGameList(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getGameList(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("game_list_update_status")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="status"
                    name="status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateProudctListStatusHandler(event, row.gameId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    updateProudctListStatusHandler = (event, gameId) => {
        event.preventDefault();

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        this.setState({ [event.target.name]: event.target.value });
        const data = {};
        data.id = gameId;
        data.status = Number(event.target.value);
        this.props.updateGameListStatus(data);
    };

    favFormatter = (cell, row) => {
        const { t } = this.props;
        if (this.state.permission.includes("game_list_update_fav")) {
            let favArray, dtClassName;
            favArray = [
                { label: t("global._yes"), value: 1 },
                { label: t("global._no"), value: 0 },
            ];
            if (row.fav === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="fav"
                    name="game_list_update"
                    options={favArray}
                    value={row.fav}
                    onChange={(event) => {
                        this.updateGameFavHandler(event, row.gameId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, fav;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                fav = t("global._no");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                fav = t("global._yes");
            }
            return <span className={badgeClassName}>{fav}</span>;
        }
    };

    updateGameFavHandler = (event, gameId) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        const data = {};
        data.id = gameId;
        data.fav = Number(event.target.value);
        this.props.updateGameListFav(data);
    };

    demoFormatter = (cell, row) => {
        const { t } = this.props;
        if (this.state.permission.includes("game_list_update_demo")) {
            let demoArray, dtClassName;
            demoArray = [
                { label: t("global._yes"), value: 1 },
                { label: t("global._no"), value: 0 },
            ];
            if (row.demo === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="demo"
                    name="game_list_update"
                    options={demoArray}
                    value={row.demo}
                    onChange={(event) => {
                        this.updateGameDemoHandler(event, row.gameId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, demo;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                demo = t("global._no");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                demo = t("global._yes");
            }
            return <span className={badgeClassName}>{demo}</span>;
        }
    };

    updateGameDemoHandler = (event, gameId) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        const data = {};
        data.id = gameId;
        data.demo = Number(event.target.value);
        this.props.updateGameListDemo(data);
    };

    actionFormatter = (cell, row) => {
        let editButton, output;
        if (this.state.permission.includes("game_list_edit")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.gameId}`}
                    idValue={row.gameId === undefined ? 0 : row.gameId}
                    clicked={(event) => this.editHandler(event, row.gameId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.getFilterArray();
            this.props.getGameList(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.getFilterArray();
                this.props.getGameList(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];
        let ddGameType = [];
        let ddGameCategory = [];
        let ddVendor = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("game-list.game-title"), dataField: "gameTitle", sort: true, onSort: this.onSort },
            { text: t("game-list.game-code"), dataField: "gameCode", sort: true, onSort: this.onSort },
            { text: t("game-list.game-type"), dataField: "gameType", sort: true, onSort: this.onSort },
            { text: t("game-list.game-category"), dataField: "gameCategory", sort: true, onSort: this.onSort },
            { text: t("global.status"), dataField: "status", formatter: this.statusFormatter, headerStyle: { width: "115px" } },
            { text: t("game-list.hot"), dataField: "fav", formatter: this.favFormatter, headerStyle: { width: "115px" } },
            { text: t("game-list.demo"), dataField: "demo", formatter: this.demoFormatter, headerStyle: { width: "115px" } },
            { text: t("game-list.vendor"), dataField: "vendorTitle", sort: true, onSort: this.onSort },
            { text: t("global.updated-time"), dataField: "updatedTime", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "65px" } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    gameId: val.gameId,
                    gameTitle: val.gameTitle,
                    gameCode: val.gameCode,
                    gameType: t(`game-type.${val.gameType}`),
                    gameCategory: (val.gameCategory) ? t(`game-list.${val.gameCategory}`) : '',
                    status: val.status,
                    fav: val.fav,
                    demo: val.demo,
                    vendorTitle: val.vendorTitle,
                    updatedTime: val.updatedTime,
                };
            });
        }

        if (this.state.pageShow === "listing" && this.state.dropDown.gameTypeOption) {
            ddGameType = this.state.dropDown.gameTypeOption.map((val, idx) => {
                return (
                    <option key={val.gameTypeId} value={val.gameTypeSlug}>
                        {t(`game-type.${val.gameTypeSlug}`)}
                    </option>
                );
            });
        }

        if (this.state.pageShow === "listing" && this.state.dropDown.gameCategoryOption) {
            ddGameCategory = this.state.dropDown.gameCategoryOption.map((val, idx) => {
                return (
                    <option key={val.gameCategoryId} value={val.gameCategoryId}>
                        {t(`game-list.${val.gameCategorySlug}`)}
                    </option>
                );
            });
        }

        if (this.state.pageShow === "listing" && this.state.dropDown.vendorOption) {
            ddVendor = this.state.dropDown.vendorOption.map((val, idx) => {
                return (
                    <option key={val.vendorId} value={val.vendorId}>
                        {t(`vendor.${val.vendorSlug}`)}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <GameListing
                        filter={this.state.filter}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        ddGameType={ddGameType}
                        ddGameCategory={ddGameCategory}
                        ddVendor={ddVendor}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("game_list_create") && this.state.pageShow === "create" && (
                    <GameListCreate
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("game_list_edit") && this.state.pageShow === "edit" && (
                    <GameListEdit
                        gameListEditId={this.state.gameListEditId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        gameListData: state.gameList.game_list_data,
        gameListFilterData: state.gameList.game_list_filter_data,
        gameListUpdateStatusResult: state.gameList.game_list_update_status_result,
        gameListUpdateFavResult: state.gameList.game_list_update_fav_result,
        gameListUpdateDemoResult: state.gameList.game_list_update_demo_result,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getGameList: (data) => dispatch(getGameList(data)),
        getFilterData: () => dispatch(getFilterData()),
        updateGameListStatus: (data) => dispatch(updateGameListStatus(data)),
        updateGameListFav: (data) => dispatch(updateGameListFav(data)),
        updateGameListDemo: (data) => dispatch(updateGameListDemo(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameList));
