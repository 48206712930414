import React, { Component } from "react";
import { connect } from "react-redux";
import { staticSEO } from "../../../../store/ducks/seo/actions";
import { Helmet } from "react-helmet";

// In componentDidMount() call the SEO function, which also check URL is have match the gs_seo_meta_tags table's url column or not,
// If match, pass data to SEO component.
// Else, use default meta tags set by developer.

let constant_tag, web_master, open_graph, result;

class StaticSEO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.staticSEO();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.seoData !== this.props.seoData) {
            if (nextProps.seoData.responseCode === 200) {
                this.setState({ loading: false });
            }
        }
        return true;
    }

    render() {
        if (this.state.loading) {
            constant_tag = (
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:locale:alternate" content="zh_CN" />
                    <meta property="og:locale:alternate" content="ml_IN" />
                    <meta property="og:locale:alternate" content="vi_VN" />
                    <meta property="og:locale:alternate" content="th_TH" />
                    <meta property="og:locale:alternate" content="hi_IN" />
                </Helmet>
            );
        } else {
            const getImageUrl = process.env.REACT_APP_API_DEVELOPMENT_URL + "images/seo/og/";
            const seo = this.props.seoData.data;

            if (seo.web_master !== null) {
                web_master = (
                    <Helmet>
                        {seo.web_master.google_web_master && <meta name="google-site-verification" content={seo.web_master.google_web_master} />}
                        {seo.web_master.bing_web_master && <meta name="msvalidate.01" content={seo.web_master.bing_web_master} />}
                        {seo.web_master.baidu_web_master && <meta name="baidu-site-verification" content={seo.web_master.baidu_web_master} />}
                    </Helmet>
                );
            }

            if (seo.open_graph) {
                let og_site_name, og_home_image, og_default_width, og_default_height, og_twitter_title , og_twitter_image;
                if (seo.open_graph.home_site_name) {
                    og_site_name = <meta property="og:site_name" content={seo.open_graph.home_site_name}/>
                } else {
                    og_site_name = <meta property="og:site_name" content="GS" />
                }

                if (seo.open_graph.home_image && seo.open_graph.default_image) {
                    og_home_image = <meta property="og:image" content={seo.open_graph.home_image} />
                    og_default_width = <meta property="og:image:width" content={seo.open_graph.default_image_width} />
                    og_default_height = <meta property="og:image:height" content={seo.open_graph.default_image_height} />
                } else if (!seo.open_graph.home_image && seo.open_graph.default_image) {
                    og_home_image = <meta property="og:image" content={seo.open_graph.default_image} />
                    og_default_width = <meta property="og:image:width" content={seo.open_graph.default_image_width} />
                    og_default_height = <meta property="og:image:height" content={seo.open_graph.default_image_height} />
                }

                if (seo.open_graph.twitter_title && seo.open_graph.home_title) {
                    og_twitter_title = <meta property="twitter:title" content={seo.open_graph.twitter_title} />
                } else if (!seo.open_graph.twitter_title && seo.open_graph.home_title) {
                    og_twitter_title = <meta property="twitter:title" content={seo.open_graph.home_title} />
                }

                if (seo.open_graph.twitter_image && seo.open_graph.default_image) {
                    og_twitter_image = <meta property="twitter:image" content={seo.open_graph.twitter_image} />
                } else if (!seo.open_graph.twitter_image && seo.open_graph.default_image) {
                    og_twitter_image = <meta property="twitter:image" content={seo.open_graph.default_image} />
                }
                open_graph = (
                    <Helmet>
                        {og_site_name}
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={window.location.href} />
                        {seo.open_graph.home_title && <meta property="og:title" content={seo.open_graph.home_title} />}
                        {seo.open_graph.home_description && <meta property="og:description" content={seo.open_graph.home_description} />}

                        <meta property="og:image" content={getImageUrl + seo.open_graph.home_image} />
                        {og_home_image}
                        {og_default_width}
                        {og_default_height}
                        {seo.open_graph.facebook_admin_id && <meta property="fb:admins" content={seo.open_graph.facebook_admin_id} />}
                        {seo.open_graph.facebook_app_id && <meta property="fb:app_id" content={seo.open_graph.facebook_app_id} />}

                        {seo.open_graph.twitter_card && <meta property="twitter:card" content={seo.open_graph.twitter_card} />}
                        {seo.open_graph.twitter_url && <meta property="twitter:url" content={seo.open_graph.twitter_url} />}
                        {og_twitter_title}
                        {seo.open_graph.twitter_description && <meta property="twitter:description" content={seo.open_graph.twitter_description} />}
                        {og_twitter_image}
                    </Helmet>
                );
            }
        }
        result = [
            <div key="static_seo">
                {constant_tag} {web_master} {open_graph}
            </div>,
        ];
        // result = [<div key="d">{default_tag}</div>, <div key="m">{meta_tag}</div>, <div key="w">{web_master}</div>, <div key="o">{open_graph}</div>];
        return result;
    }
}

const mapStateToProps = (state) => {
    return {
        seoData: state.seo.static_seo_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        staticSEO: () => dispatch(staticSEO()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticSEO);
