import React, { Component } from "react";
import { connect } from "react-redux";
import { showMetaTag } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

class MetaTagShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.showMetaTag(this.props.metaTagShowId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.metaTagShowData !== undefined && nextProps.metaTagShowData !== nextState.metaTagShowData && nextState.loading === true) {
            this.setState({
                loading: false,
            });
        }
        return true;
    }

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            let metaTagData = this.props.metaTagShowData.data.metaTag[0];
            let languageData = this.props.metaTagShowData.data.language[0];
            let metaTagRobotData = this.props.metaTagShowData.data.seoRobot;
            display = (
                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("meta-tags.meta-tag-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("meta-tags.name")}</th>
                                            <td field-key="name">{metaTagData.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.title")}</th>
                                            <td field-key="title">{metaTagData.title}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.description")}</th>
                                            <td field-key="description">{metaTagData.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.keywords")}</th>
                                            <td field-key="keywords">{metaTagData.keywords}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.url")}</th>
                                            <td field-key="url">{metaTagData.url}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.h1")}</th>
                                            <td field-key="h1">{metaTagData.h1}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.status")}</th>
                                            <td field-key="status">{metaTagData.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.language")}</th>
                                            <td field-key="language">{languageData.isoCode}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("meta-tags.robot-tags")}</th>
                                            <td field-key="robot">
                                                {(metaTagRobotData !== undefined ) ? metaTagRobotData.split(" ").map((val, idx) => (
                                                    <span key={idx} className="badge badge-primary mr-1">
                                                        {val}
                                                    </span>
                                                )) : <span className="badge badge-primary mr-1">No Robot Tag</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.created-time")}</th>
                                            <td field-key="created_date">{metaTagData.createdTime}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.updated-time")}</th>
                                            <td field-key="updated_date">{metaTagData.updatedTime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("meta-tags.meta-tag-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { seo } = state;

    return {
        metaTagShowData: seo.meta_tag_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMetaTag: (id) => dispatch(showMetaTag(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MetaTagShow));
