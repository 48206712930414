import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showBanksLevel } from "../../store/ducks/banksLevel/actions";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";

class BanksLevelShow extends Component {
    state = {
        bank_level_name: '',
        deposit_amount: 0,
        group_name: '',
        bank_account_name: '',
        created_by: '',
        status: '',
        loading: true
    }

    componentDidMount() {
        const showBankLevelData = {};
        showBankLevelData.bank_level_id = this.props.show_bank_level_id;
        this.props.showBanksLevel(showBankLevelData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_bank_level_data !== this.props.show_bank_level_data) {
            if (nextProps.show_bank_level_data.data.responseCode === 200) {

                this.setState({
                    loading: false,
                    bank_level_name: nextProps.show_bank_level_data.data.data[0].banklevelName,
                    deposit_amount: nextProps.show_bank_level_data.data.data[0].depositAmount,
                    group_name: nextProps.show_bank_level_data.data.data[0].memberGroup,
                    bank_account_name: nextProps.show_bank_level_data.data.data[0].bankAccount,
                    created_by: nextProps.show_bank_level_data.data.data[0].createdBy,
                    status: nextProps.show_bank_level_data.data.data[0].status
                })
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_bank_level_data.data.message,
                    icon: nextProps.show_bank_level_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let memberGroupSplit = this.state.group_name.split(',');
        let bankAccountSplit = this.state.bank_account_name.split(',');

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('bank-level.bank-level')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('bank-level.show-bank-level')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>{t('bank-level.bank-level-name')}</th>
                                                <td field-key='bank_level_name'>{this.state.bank_level_name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('bank-level.deposit-amount')}</th>
                                                <td field-key='deposit_amount'>{this.state.deposit_amount}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('bank-level.group')}</th>
                                                <td field-key='member_group'>
                                                    {memberGroupSplit.map((item, index) => (
                                                        <span className="badge badge-primary mr-2" key={item}>{item}</span>
                                                    ))}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('bank-level.bank-account')}</th>
                                                <td field-key='bank_account'>
                                                    {bankAccountSplit.map((item, index) => (
                                                        <span className="badge badge-primary mr-2" key={item}>{item}</span>
                                                    ))}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('bank-level.created-by')}</th>
                                                <td field-key='created_by'>{this.state.created_by}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('global.status')}</th>
                                                <td field-key='status'>{this.state.status === 1 ? "ACTIVE" : "INACTIVE"}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const { bankLevel } = state;

    return {
        show_bank_level_data: bankLevel.show_bank_level_data
    }
};

const mapDispatchToProps = {
    showBanksLevel
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksLevelShow));