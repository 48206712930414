import React, { Component } from "react";
import { connect } from "react-redux";
// import { editMember } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
// import Tab from "../../../components/Tab/Tab";
// import { default as ReactTab } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MemberShow from "../MemberShow";
import MemberAccountDetails from "./MemberAccountDetails";
import MemberWalletAdjustment from "./MemberWalletAdjustment";
import MemberResetPassword from "./MemberResetPassword";
// import MemberGameAccess from "./MemberGameAccess";

class MemberEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "account_details",
            showMember: false,
            memberEditId: null,
            memberUsername: null,
            display: true,
            initialLoading: [
                { id: "account_details", loading: true },
                { id: "wallet_adjustment", loading: true },
                { id: "reset_password", loading: true },
                // { id: "game_access", loading: true }
            ],
        };
    }

    componentDidMount() {
        // console.log("[MemberEdit] componentDidMount()", this.props);
        this.setState({
            memberEditId: this.props.memberEditId,
            memberUsername: this.props.memberUsername,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();

        this.setState((state) => {
            const initialLoading = state.initialLoading.map((item, idx) => {
                if (item.id === e.target.id) {
                    return { id: item.id, loading: false };
                } else {
                    return item;
                }
            });

            return {
                initialLoading,
                pageShow: e.target.id,
            };
        });
    };

    showHandler = (e) => {
        e.preventDefault();
        this.setState({
            showMember: true,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.showMember && this.state.pageShow && this.state.display) {
            let tabId = this.state.pageShow;
            display = (
                <>
                    {(tabId === "account_details" || tabId === "wallet_adjustment" || tabId === "reset_password" /*|| tabId === "game_access"*/) && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary tabs-style-2 mt-3">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav panel-tabs main-nav-line">
                                                {this.props.permission.includes("member_update") && (
                                                    <li>
                                                        <button
                                                            id="account_details"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "account_details" ? "active" : ""}`}>
                                                            {t("member.account-details")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.props.permission.includes("wallet_adjustment_create") && (
                                                    <li>
                                                        <button
                                                            id="wallet_adjustment"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "wallet_adjustment" ? "active" : ""}`}>
                                                            {t("member.wallet-adjustment")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_reset_password") && (
                                                    <li>
                                                        <button
                                                            id="reset_password"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "reset_password" ? "active" : ""}`}>
                                                            {t("member.reset-password")}
                                                        </button>
                                                    </li>
                                                )}

                                                {/* {this.props.permission.includes("member_game_access") && (
                                                    <li>
                                                        <button
                                                            id="game_access"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "game_access" ? "active" : ""}`}>
                                                            {t("member.game-access")}
                                                        </button>
                                                    </li>
                                                )} */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="breadcrumb-header">
                        <div className="d-flex">
                            <div>
                                <div className="btn-group">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                    <div>
                                        <div className="member-name">
                                            <FontAwesomeIcon icon={faUser} /> {this.props.memberEditUsername}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.props.permission.includes("member_update") && tabId === "account_details" && (
                        <MemberAccountDetails memberEditId={this.props.memberEditId} permission={this.props.permission} initialLoading={this.state.initialLoading} />
                    )}

                    {this.props.permission.includes("wallet_adjustment_create") && tabId === "wallet_adjustment" && (
                        <MemberWalletAdjustment memberEditId={this.props.memberEditId} initialLoading={this.state.initialLoading} />
                    )}

                    {this.props.permission.includes("member_reset_password") && tabId === "reset_password" && (
                        <MemberResetPassword memberEditId={this.props.memberEditId} initialLoading={this.state.initialLoading} />
                    )}

                    {/* {this.props.permission.includes("member_game_access") && tabId === "game_access" && (
                        <MemberGameAccess memberEditId={this.props.memberEditId} permission={this.props.permission} initialLoading={this.state.initialLoading} />
                    )} */}
                </>
            );
        } else if (this.state.showMember === true) {
            display = <MemberShow memberShowId={this.props.memberEditId} changePageHandler={() => this.props.changePageHandler("listing")} />;
        } else if (this.state.display === true) {
            let tabId = this.state.pageShow;
            display = (
                <>
                    {(tabId === "account_details" || tabId === "wallet_adjustment" || tabId === "reset_password" /*|| tabId === "game_access"*/) && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary tabs-style-2 mt-3">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav panel-tabs main-nav-line">
                                                {this.props.permission.includes("member_update") && (
                                                    <li>
                                                        <button
                                                            id="account_details"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "account_details" ? "active" : ""}`}>
                                                            {t("member.account-details")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.props.permission.includes("wallet_adjustment_create") && (
                                                    <li>
                                                        <button
                                                            id="wallet_adjustment"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "wallet_adjustment" ? "active" : ""}`}>
                                                            {t("member.wallet-adjustment")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_reset_password") && (
                                                    <li>
                                                        <button
                                                            id="reset_password"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "reset_password" ? "active" : ""}`}>
                                                            {t("member.reset-password")}
                                                        </button>
                                                    </li>
                                                )}

                                                {/* {this.props.permission.includes("member_game_access") && (
                                                    <li>
                                                        <button
                                                            id="game_access"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "game_access" ? "active" : "'"}`}>
                                                            {t("game.member-access")}
                                                        </button>
                                                    </li>
                                                )} */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            display = null;
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberEditData: member.member_edit_data,
    };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         editMember: (id) => dispatch(editMember(id)),
//     };
// };

export default connect(mapStateToProps)(withNamespaces("translation")(MemberEdit));
