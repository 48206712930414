import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/Datatable/DataTable";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Button from "../../components/Button/Button";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import Checkbox from "../../components/Input/Checkbox/Checkbox";

class MemberReferralPayoutListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false
        }
    }

    componentDidMount() {
        formValidation("referral_payout_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowIndex + 1}</span>;
    };

    checkboxColumn = (column, colIndex, permission) => {
        let val = permission.includes('member_referral_payout_submit') ? (
            <Checkbox
                id='check_all'
                name='check_all'
                onChange={(event) => this.props.checkAllHandler(event, 'formInput')}
                checked={this.props.checkAll ? true : false}
            />
        ) : (
            ''
        );
        return val;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;
        // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2};
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        let data = [];
        const columns = [
            { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
            { text: t("member-referral.member-username"), dataField: 'username', sort: true, onSort: (field, order) => this.props.onSort(field, order) },
            { text: t("member-referral.member-name"), dataField: 'name', sort: true, onSort: (field, order) => this.props.onSort(field, order) },
            { text: t("member-referral.referral-commission"), dataField: 'referral_commission' },
            { text: t("member-referral.referral-commission-tbg"), dataField: 'referral_commission_tbg' },
            { text: t("member-referral.remark"), dataField: 'remark', headerStyle: { width: '165px' } },
            { text: '', dataField: 'action', headerStyle: { width: '40px' }, headerFormatter: (column, colIndex) => this.checkboxColumn(column, colIndex, permission), }
        ];

        if (this.props.data) {
            data = this.props.data.map((val, idx) => {
                let refCommission = "";
                let refCommissionTbg = "";
                let remark = "";
                let action = "";
                if (this.props.setting) {
                    let rate = {};
                    rate.base_myr_to_idr = val.base_myr_to_idr;
                    rate.base_idr_to_myr = val.base_idr_to_myr;
                    refCommission = permission.includes("member_referral_payout_submit") ? (
                        <b>
                            <a href='/#' onClick={(event) => this.props.detailsHandler(event, val.mid, this.props.filterInput.date, rate)}>
                                {val.referral_commission}
                                <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                            </a>
                        </b>
                    ) : (
                        val.referral_commission
                    )
                    refCommissionTbg = permission.includes("member_referral_payout_submit") ? (
                        (val.referral_commission > 0 && this.props.setting.minPayout > 0 && val.referral_commission >= this.props.setting.minPayout) ? (
                            <>
                                <NumberInput
                                    id={`commission_tbg_${val.mid}`}
                                    name={`commission_tbg_${val.mid}`}
                                    value={this.props.formInput[`commission_tbg_${val.mid}`] || ''}
                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                    onChange={(event) => this.props.onInputChange(event, 'formInput')}
                                    className='form-control filter-input'
                                    min={0.01}
                                    required
                                />
                                <input type="hidden" id={`base_myr_to_idr_${val.mid}`} name={`base_myr_to_idr_${val.mid}`} value={this.props.formInput[`base_myr_to_idr_${val.mid}`]} />
                                <input type="hidden" id={`base_idr_to_myr_${val.mid}`} name={`base_idr_to_myr_${val.mid}`} value={this.props.formInput[`base_idr_to_myr_${val.mid}`]} />
                            </>
                        ) : (
                            val.referral_commission
                        )
                    ) : (
                        val.referral_commission
                    );
                    remark = permission.includes('member_referral_payout_submit') ? (
                        (val.referral_commission > 0 && this.props.setting.minPayout > 0 && val.referral_commission >= this.props.setting.minPayout) ? (
                            <textarea
                                id={`remark_${val.mid}`}
                                name={`remark_${val.mid}`}
                                value={this.props.formInput[`remark_${val.mid}`]}
                                onChange={(event) => this.props.onInputChange(event, 'formInput')}
                            ></textarea>
                        ) : ('')
                    ) : ('');
                    action = permission.includes('member_referral_payout_submit') ? (
                        (val.referral_commission > 0 && this.props.setting.minPayout > 0 && val.referral_commission >= this.props.setting.minPayout) ? (
                            <div className="yl-checkbox-container">
                                <input
                                    type='checkbox'
                                    className='editor-active'
                                    id={`cb_${val.mid}`}
                                    name={`cb_${val.mid}`}
                                    checked={this.props.formInput[`cb_${val.mid}`] ? true : false}
                                    onChange={(event) => this.props.onInputChange(event, 'formInput')}
                                />
                                <label for={`cb_${val.mid}`} />
                            </div>

                        ) : ('')
                    ) : ('');
                }
                return {
                    no: idx,
                    username: val.username,
                    name: val.name,
                    referral_commission: refCommission,
                    referral_commission_tbg: refCommissionTbg,
                    remark: remark,
                    action: action
                };
            });
        }

        return (
            <Auxiliary>
                <ValidationError touched={this.props.touched} message={this.props.errMsg} />
                <div className='row row-sm'>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member-referral.referral-payout-title")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div className=''>
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div method='post' id='filter_form' className={`form-horizontal filter-function row ${this.state.showCard ? 'open' : 'close'} filter-wrapper-2`}>
                                        <div className='col-md-6 col-6'>
                                            <div className='form-group mb-0'>
                                                <label className='font-weight-bolder'>{t('member-referral.transaction-date')}</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'>
                                                            <i className='fas fa-calendar-check'></i>
                                                        </span>
                                                    </div>
                                                    <DateTimePicker
                                                        id='date'
                                                        name='date'
                                                        className='form-control filter-input'
                                                        format='YYYY-MM-DD'
                                                        singleDatePicker={true}
                                                        autoUpdateInput={true}
                                                        value={this.props.filterInput.date}
                                                        onChange={(event) => this.props.onInputChange(event, 'filterInput')}
                                                        maxDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-6'>
                                            <div className='form-group mb-0'>
                                                <label className='font-weight-bolder'>{t('member-referral.member')}</label>
                                                <StringInput
                                                    id='username'
                                                    name='username'
                                                    value={this.props.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, 'filterInput')}
                                                    className='form-control filter-input'
                                                    onSearchPress={(evt) => evt.key === 'Enter' && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className='col mt-auto'>
                                            <div className="d-flex">
                                                {permission.includes(
                                                    'member_referral_payout_submit'
                                                ) && (
                                                        <Button
                                                            typeButton='submit'
                                                            classValue='btn btn-warning'
                                                            idValue='submit'
                                                            buttonName={t('member-referral.submit-commission')}
                                                            formValue='member_rebate_form'
                                                        />
                                                    )}
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-blue'
                                                    idValue='filter'
                                                    buttonName={t('global.filter')}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-secondary'
                                                    idValue='reset'
                                                    buttonName={t('global.reset')}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className='show-btn'
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>
                                        {t('member-referral.calculate-commission')}
                                    </h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    <form
                                        method='post'
                                        id='member_rebate_form'
                                        className='form-horizontal filter-function'
                                        onSubmit={(event) => this.props.submitHandler(event)}
                                        noValidate
                                    >
                                        <DataTable
                                            keyField='no'
                                            data={data}
                                            columns={columns}
                                            activePage={this.props.activePage}
                                            limit={this.props.limit}
                                            totalItems={this.props.totalRecords}
                                            paginationHandler={(val) => this.props.datatablesChangeHandler(val, 'activePage')}
                                            sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, 'limit')}
                                            searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, 'searchValue')}
                                            searchValue={this.props.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.props.loading}
                                            hideSearchBar={true}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

export default withNamespaces("translation")(MemberReferralPayoutListing);