import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import Button from "../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/Datatable/DataTable";
import StringInput from "../../components/Input/StringInput/StringInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

import UpdownArrow from "../../assets/images/updown-arrow.png";

class MemberLoginStatusListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        //   console.log(this.props.category)
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
                                        {/* <div className='row'> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("transaction.create-date")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="member_login_date"
                                                        name="member_login_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format="YYYY/MM/DD"
                                                        value={this.props.member_login_date}
                                                        onChange={this.props.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.email")}</label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    value={this.props.email}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.finger-print-id")}</label>
                                                <StringInput
                                                    id="fingerPrintId"
                                                    name="fingerPrintId"
                                                    value={this.props.fingerPrintId}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.ip-address")}</label>
                                                <StringInput
                                                    id="ipAddress"
                                                    name="ipAddress"
                                                    value={this.props.ipAddress}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.fingerprint-risk")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="filterRiskAssessment"
                                                        name="filterRiskAssessment"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filterRiskAssessment}
                                                    >
                                                        <option value=""></option>
                                                        <option value="Yes">{t("global._yes")}</option>
                                                        <option value="No">{t("global._no")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.ip-risk")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="filterIPAssessment"
                                                        name="filterIPAssessment"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filterIPAssessment}
                                                    >
                                                        <option value=""></option>
                                                        <option value="Yes">{t("global._yes")}</option>
                                                        <option value="No">{t("global._no")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.browser")}</label>
                                                <StringInput
                                                    id="browser"
                                                    name="browser"
                                                    value={this.props.browser}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.city")}</label>
                                                <StringInput
                                                    id="city"
                                                    name="city"
                                                    value={this.props.city}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.region")}</label>
                                                <StringInput
                                                    id="region"
                                                    name="region"
                                                    value={this.props.region}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.postal-code")}</label>
                                                <StringInput
                                                    id="postalCode"
                                                    name="postalCode"
                                                    value={this.props.postalCode}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("promotion.category")}
                                        </label>
                                        <select
                                        className="form-control filter-input"
                                        id="category"
                                        name="category"
                                        onChange={this.props.onInputChange}
                                        value={this.props.category}
                                        >
                                        <option value=""></option>
                                        {this.props.ddPromotionCategory &&
                                                this.props.ddPromotionCategory.map(
                                                    (val, idx) => {
                                                        return (
                                                            <option
                                                                key={idx}
                                                                value={val.id}
                                                            >
                                                                {
                                                                    t(`promotion-type.${val.title}`)
                                                                }
                                                            </option>
                                                        );
                                                    }
                                                )}
                                      </select>
                                    </div>
                                </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member.member-login-status-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="">
                                    {/* {this.props.permission.includes('promotion_multi_delete') &&
                                <button id="button" className="btn btn-danger mg-b-20 btn-multi-delete">
                                    {t("global.multi-delete")}
                                </button>
                                } */}

                                    <DataTable
                                        keyField="business_account_id"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={this.props.pageChangeHandler}
                                        sorted={this.props.sorted}
                                        sizePerPageHandler={this.props.sizePerPageHandler}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchValue={this.props.searchValue}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center promotion__table"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberLoginStatusListing);
