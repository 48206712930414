import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { filterErrorHandler } from '../../hoc/Shared/utility';
import RoleListing from './RoleListing';
import RoleEdit from './RoleEdit';
import RoleDetails from './RoleDetails';
import RoleStore from './RoleStore';

import * as actions from '../../store/ducks/role/actions';

const initialInputState =
{
    /* form input value */
    title: '',
    status: '',
    update_date: '',
};

class Role extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,
            search: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],

            /* details id */
            selectId: null,

            /* skeleton loading */
            loading: true,

            /* validation error */
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.updateStatusHandler = this.updateStatusHandler.bind(this);
        this.searchKeyPressHandler = this.searchKeyPressHandler.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                this.setState({
                    data: [],
                    totalRecords: 0,
                    totalPages: 0,
                    activePage: 1,
                    loading: false,
                }, () => {
                    filterErrorHandler(nextProps.rs.message);
                });
            }
        }

        if (nextProps.updateStatus !== this.props.updateStatus && nextProps.updateStatus!==undefined)
        {
            if(nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success')
            {
                const { t } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                });
            }
            else
            {
                Swal.close();
                filterErrorHandler(nextProps.updateStatus.message);
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        if(event.target.id==='search')
        {
            this.setState({
                search: event.target.value
            });
        }
        else
        {
            let newFilter = Object.assign({}, this.state.filter);
            newFilter[event.target.id] = event.target.value;
            if(event.target.id==='update_date')
            {
                const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
                newFilter['start_date'] = (date) ? date[0] : '';
                newFilter['end_date'] = (date) ? date[1] : '';
            }
            this.setState({
                filter: newFilter
            });
        }
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    searchKeyPressHandler = (event) =>
    {
        //Checking press the enter only perform search action
        if(event.key === 'Enter')
        {
            this.refreshHandler();
        }
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            search: '',
            order: null,
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    detailsHandler = (e, page, id) =>
    {
        // console.log('detailsHandler :'+page+' | '+id);
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.search;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }

    updateStatusHandler = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let data = {};
        data.id = event.target.id;
        data.status = event.target.value;

        // console.log(data);
        this.props.funcUpdateStatus(data);
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='search') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        // console.log(this.state.search);
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <RoleListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        onSort={(field, order) => this.onSort(field, order)}
                        updateStatusHandler={(event) => this.updateStatusHandler(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        search={this.state.search}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='details' &&
                    <RoleDetails
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <RoleEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='create' &&
                    <RoleStore
                        // selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.role.rs,
        updateStatus: state.role.updateStatus,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcUpdateStatus: (data) => dispatch(actions.updateStatus(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Role));