import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import MemberCoinTrackerListing from "./MemberCoinTrackerListing";
import TransactionDetails from "../../Transaction/TransactionDetails";
import { setMemberShowLocalState, setMemberShowInitialLoading, getMemberCoinTracker } from "../../../store/ducks/member/actions";

const initialInputState = {
    transaction_date: "",
    transaction_type: ""
};

class MemberCoinTracker extends Component {
    constructor(props){
        super(props);
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'coin_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            this.state = {
                currentPage: "coin_tracker",
                pageShow: "coin_tracker_listing",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: '',
                totalRecords: 0,
                searchValue: '',
                data: [],
                loading: true,
                reload: false,
                show_id: null,
                filter: initialInputState,
                detailsParam: {}
            }
            this.props.setMemberShowLocalState(this.state);
        }
        else{
            this.state = this.props.memberCoinTrackerLocalState;
        }
    }

    componentDidMount(){
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'coin_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            const listingArr = this.getFilterArray();
            this.props.getMemberCoinTracker(listingArr);
            this.props.setMemberShowInitialLoading(this.state.currentPage);
        }
        else{
            if(this.props.memberCoinTrackerData !== undefined){
                if(Number(this.props.memberShowId) !== Number(this.props.memberCoinTrackerData.data.data.memberId)){
                    const listingArr = this.getFilterArray();
                    this.props.getMemberCoinTracker(listingArr);
                }
                else{
                    this.setState({
                        loading: false,
                        reload: true
                    });
                }
            }
            else{
                const listingArr = this.getFilterArray();
                this.props.getMemberCoinTracker(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.memberShowId !== undefined && nextProps.memberCoinTrackerData !== undefined){
            if(nextProps.memberCoinTrackerData !== this.props.memberCoinTrackerData){
                if(nextProps.memberCoinTrackerData.status === 200 && nextProps.memberCoinTrackerData.data.responseCode === 200 && nextProps.memberCoinTrackerData.data.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        loading: false,
                        data: nextProps.memberCoinTrackerData.data.data.result.row,
                        totalRecords: nextProps.memberCoinTrackerData.data.data.result.pagination.total_record,
                        show_id: nextProps.memberShowId
                    });
                }
            }
        }
        
        return true;
    }

    componentWillUnmount(){
        this.props.setMemberShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        listingArr.member_id = this.props.memberShowId;
        if(this.state.totalRecords !== 0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit)+1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberCoinTracker(listingArr);
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState({
            [act]: act === "searchValue" ? val.target.value : val,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberCoinTracker(listingArr);
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberCoinTracker(listingArr);
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: "",
            filter: newFilter,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberCoinTracker(listingArr);
        });
    };

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: "details",
            detailsParam: {
                id: id
            }
        });
    }

    changePageHandler = page => {
        this.setState({
            pageShow: page
        });
    }

    render(){
        return (
            <Auxiliary>
                {this.state.pageShow === "coin_tracker_listing" && 
                    <MemberCoinTrackerListing 
                        filter={this.state.filter}
                        onSort={(field, order)=>{this.onSort(field, order)}}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        datatablesChangeHandler={(val, act)=>{this.datatablesChangeHandler(val, act)}}
                        searchValue={this.state.searchValue}
                        loading={this.state.loading}
                        refreshHandler={()=>{this.refreshHandler()}}
                        clearHandler={()=>{this.clearHandler()}}
                        onInputChange={(event)=>{this.onInputChange(event)}}
                        detailsHandler={(event,id)=>{this.detailsHandler(event, id)}}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }

                {this.state.pageShow === "details" && (
                    <TransactionDetails 
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='coin_tracker_listing'
                        currentUser={this.props.currentUser}
                    />
                )}
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { member, currentUser } = state;
    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberCoinTrackerLocalState: member.member_coin_tracker_local_state,
        memberCoinTrackerData: member.member_coin_tracker_data,
        currentCountryId: state.currentUser.data.country_id,
        currentUser: currentUser.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),
        getMemberCoinTracker: (data) => dispatch(getMemberCoinTracker(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberCoinTracker));