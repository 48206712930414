import { Component } from 'react';
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
// import Button from "../../components/Button/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen } from "@fortawesome/free-solid-svg-icons";
import RescueBonusSettingCreate from "./RescueBonusSettingCreate";
import RescueBonusSettingEdit from "./RescueBonusSettingEdit";
import RescueBonusSettingListing from "./RescueBonusSettingListing";

import * as actions from '../../store/ducks/rescueBonusSetting/actions';

class RescueBonusSetting extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: '',

            /* api result array */
            data: [],

            /* details id */
            selectId: null,

            /* skeleton loading */
            loading: true,
        }
    }

    componentDidMount()
    {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }
        return true;
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => 
            {
                Swal.showLoading();
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, ()=> 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) 
        {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        
        return listingArr;
    }

    detailsHandler = (e, page, id) =>
    {
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
        });
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            errMsg: [],
            touched: false,
        });
    }

    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    render()
    {
        const { t } = this.props;
        let statusArr = 
        [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];
        
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <RescueBonusSettingListing
                        /* filter input */
                        // filter={this.state.filter}

                        /* setstate function */
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        // deleteHandler={(event, id) => this.deleteHandler(event, id)}
                        changePageHandler={(event, page, id) => this.changePageHandler(event, page, id)}
                        onSort={(field, order) => this.onSort(field, order)}
                        // clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        // searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        // ddTransactionType={this.state.dropDown.transactionType}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==="create" && (
                    <RescueBonusSettingCreate
                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                        statusArr={statusArr}
                    />
                )}

                {this.state.pageShow==='edit' &&
                    <RescueBonusSettingEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        statusArr={statusArr}
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => 
{
    return {
        rs: state.rescueBonusSetting.rs,
        currentUser: state.currentUser.data,
    }
}

const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces("translation")(RescueBonusSetting));