import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const memberLoginStatusReducer = createReducer(initialState) ({
    [type.GET_MEMBER_LOGIN_STATUS]: (state = initialState, action) => ({
        ...state,
       member_login_status_data: action.payload
    })
});

export default memberLoginStatusReducer;