import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch =>
{
    axios.get('agent_transaction/list', {params: props})
    .then(function (response)
    {
		dispatch (
		{
			type: actionType.LIST,
			value: response.data
		});
    })
    .catch(err =>
    {
      
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('agent_transaction/drop_down')
    .then(function (response)
    {
		dispatch (
		{
			type: actionType.DROPDOWN,
			value: response.data
		})
    })
    .catch(err =>
    {

    });
};

export const getDetails = (id) => dispatch =>
{
    if(id > 0)
    {
		axios.get('agent_transaction/details',{
			params: {
				"id": id,
			}
		})
		.then(function (response)
		{
			dispatch (
			{
				type: actionType.DETAILS,
				value: response.data
			})
		})
		.catch(function (error)
		{

		});
    }
};
