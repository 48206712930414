import { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import DataTable from '../../../components/Datatable/DataTable';

class MemberBillingAddressListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;
        let st = { 0: t("global.inactive"), 1: t("global.active") };
        let stCls = {
            0: "danger",
            1: "success"
        };
        let data = [];
        const columns = [
            { text: t("member-address.name"), dataField: 'name', sort: false },
            { text: t("member-address.contact-no"), dataField: 'contact_no', sort: false },
            { text: t("member-address.default-address"), dataField: 'default_address', sort: false },           
            { text: t("member-address.full-address"), dataField: 'full_address', sort: false },
            { text: t("global.status"), dataField: "status", sort: true, onSort: this.props.onSort },
        ]
        if (this.props.data) {
            data = this.props.data.map((val, index) => {
                let default_address = val.is_default > 0 ? t('global._yes') : t('global._no');
                let st_val = "";
                st_val = <span className={`badge badge-pill badge-${stCls[val.status]}`}>{st[val.status]}</span>;
                return {
                    name: val.name,
                    contact_no: val.contact_no,
                    default_address: default_address,
                    full_address: val.full_address,
                    status: st_val
                };
            });
        }

        return (
            <Auxiliary>
                <div className='row col-sm'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>{t('member-address.subtitle-2')}</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover database'>
                                    <DataTable
                                        keyField="id"
                                        data={data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "baActivePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "baLimit")}
                                        // searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(MemberBillingAddressListing);