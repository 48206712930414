import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import UpdownArrow from "../../assets/images/updown-arrow.png";
import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";

class RoleListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
        // console.log('constructor (listing):', this.state);
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * this.props.limit));
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("role._title"),
                dataField: "title",
                sort: true,
                onSort: this.props.onSort,
            },
            { text: t("role.status"), dataField: "status", headerStyle: { width: "115px" }, },
            {
                text: t("role.create-date"),
                dataField: "created_at",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("role.update-date"),
                dataField: "updated_at",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("role.action"),
                dataField: "action",
                headerStyle: { width: "115px" },
            },
        ];
        if (
            !permission.includes("role_show") &&
            !permission.includes("role_update")
        ) {
            columns.splice(5, 1);
        }

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stCls = val.status === 1 ? "active" : "inactive";
                let actionVal =
                    permission.includes("role_show") ||
                        permission.includes("role_update") ? (
                        <div>
                            {permission.includes("role_show") && (
                                <a
                                    href="/#"
                                    onClick={(event) =>
                                        this.props.detailsHandler(
                                            event,
                                            "details",
                                            val.id
                                        )
                                    }
                                    className="btn btn-sm btn-white mr-2"
                                    data-toggle="tooltip"
                                    title={t("global.view")}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </a>
                            )}
                            {permission.includes("role_update") && (
                                <a
                                    href="/#"
                                    onClick={(event) =>
                                        this.props.detailsHandler(
                                            event,
                                            "edit",
                                            val.id
                                        )
                                    }
                                    className="btn btn-sm btn-white"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                            )}
                        </div>
                    ) : (
                        ""
                    );
                let stVal = permission.includes("role_update_status") ? (
                    <div className="select-input">
                        <select
                            className={`form-control datatable-select datatable-select-${stCls}`}
                            id={val.id}
                            name={val.id}
                            onChange={(event) =>
                                this.props.updateStatusHandler(event)
                            }
                            value={val.status === null ? 0 : val.status}
                        >
                            <option value="1">{t("global.active")}</option>
                            <option value="0">{t("global.inactive")}</option>
                        </select>
                        <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                    </div>

                ) : (
                    <span
                        className={`badge-pill badge-${val.status === 1 ? "success" : "danger"
                            }`}
                    >
                        {val.status && val.status === 1
                            ? t("global.active")
                            : t("global.inactive")}
                    </span>
                );
                return {
                    no: idx,
                    title: val.title,
                    status: stVal,
                    created_at: val.created_at,
                    updated_at: val.updated_at,
                    action: actionVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("role.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-2`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("role.update-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="update_date"
                                                        name="update_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={
                                                            this.props.filter
                                                                .update_date
                                                        }
                                                        onChange={
                                                            this.props.onInputChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(`role.title`)}
                                                </label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.filter.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(`role.status`)}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">
                                                            {t("global.active")}
                                                        </option>
                                                        <option value="0">
                                                            {t("global.inactive")}
                                                        </option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("role.listing")}
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            {permission.includes("role_store") && (
                                                <>
                                                    <AiFillPlusCircle />
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-underline"
                                                        idValue="create"
                                                        buttonName={t("role.add_role")}
                                                        clicked={() =>
                                                            this.props.changePageHandler(
                                                                "create"
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        // searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, 'search')}
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.props.search}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={
                                            this.props.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(RoleListing);
