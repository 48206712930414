import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { encryptTransform } from "redux-persist-transform-encrypt"

import thunkMiddleware from 'redux-thunk';
import * as reducers from "./ducks/index";

const persistConfig = {
  key: 'GSAdmin',
  whitelist: ['demo','currentUser'],
  storage,
  transforms: [
    encryptTransform({
        secretKey: String(process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY),
    })
  ],
}

const rootReducer = (state, action) => {
    return combineReducers(reducers)(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Enabling Redux DevTools and Tracing
const composeEnhancers = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 100,
}) : null || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));
const persistor = persistStore(store);

export { persistor, store };