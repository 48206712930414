import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const reportReducer = createReducer(initialState) ({
    [type.GET_DAY_DEPOSIT_REPORT]: (state, action) => ({
        ...state,
        get_day_deposit_report_data: action.payload
    }),

    [type.GET_MEMBER_DEPOSIT_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_member_deposit_status_report_data: action.payload
    }),

    [type.GET_VENDOR_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_vendor_status_report_data: action.payload
    }),

    [type.GET_VENDOR_SUB_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_vendor_sub_status_report_data: action.payload
    }),

    [type.GET_YEARLY_VENDOR_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_yearly_vendor_status_report_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    
    [type.MEMBER_GET_FILTER_DATA]: (state, action) => ({
        ...state,
        member_filter_data: action.payload
    }),

    [type.GET_MEMBER_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_member_status_report_data: action.payload
    }),

    [type.GET_MEMBER_SUB_STATUS_REPORT]: (state, action) => ({
        ...state,
        get_member_sub_status_report_data: action.payload
    }),

    [type.GET_MEMBER_PAYOUT_REPORT]: (state, action) => ({
        ...state,
        get_member_payout_report_data: action.payload
    }),

    [type.GET_MEMBER_HISTORY_REPORT]: (state, action) => ({
        ...state,
        get_member_history_data: action.payload
    }),
});

export default reportReducer;