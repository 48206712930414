import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class GameVendorListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            //(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
            (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    actionFormatter = (cell, row) => {
        let viewButton, /*editButton,*/ output;

        viewButton =
            // <Button
            //     typeButton="button"
            //     classValue="btn btn-sm btn-blue mr-2"
            //     buttonName={<FontAwesomeIcon icon={faSearch}/>}
            // />

            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                idValue="game-category"
                buttonName={<FontAwesomeIcon icon={faSearch} />}
                clicked={() => this.props.changePageHandler("game-category", row.id, row.slug)}
            />
        // console.log(row.id)

        output = [viewButton];
        return output;
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("game-vendor.game-vendor"),
                dataField: "title",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: "id",
                dataField: "id",
                sort: true,
                onSort: this.props.onSort,
                hidden: true,
            },
            {
                text: t("game-vendor.slug"),
                dataField: "slug",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
                headerStyle: () => {
                    return { width: '115px' };
                }
            }

            // { text: t("global.status"), dataField: "status", headerStyle: { width: "115px" }, },
            // { text: t("global.action"), dataField: "action", headerStyle: { width: "65px" }, },
        ];
        if (!permission.includes("game_vendor_update")) {
            columns.splice(4, 1);
        }

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                // let stCls = val.status === 1 ? "active" : "inactive";
                // let actionVal = permission.includes("game_vendor_update") ? (
                //     <div className="btn-group">
                //         <a
                //             href="/#"
                //             onClick={(event) =>
                //                 this.props.detailsHandler(event, "edit", val.id)
                //             }
                //             className="btn btn-sm btn-white"
                //             data-toggle="tooltip"
                //             title="edit"
                //         >
                //             <FontAwesomeIcon icon={faPen} />
                //         </a>
                //     </div>
                // ) : (
                //     ""
                // );
                // let stVal = permission.includes("game_vendor_update_status") ? (
                //     <select
                //         className={`form-control datatable-select-${stCls}`}
                //         id={val.id}
                //         name={val.id}
                //         onChange={(event) =>
                //             this.props.updateStatusHandler(event)
                //         }
                //         value={val.status === null ? 0 : val.status}
                //     >
                //         <option value="1">{t("global.active")}</option>
                //         <option value="0">{t("global.inactive")}</option>
                //     </select>
                // ) : (
                //     <span
                //         className={`badge-pill badge-${
                //             val.status === 1 ? "success" : "danger"
                //         }`}
                //     >
                //         {val.status && val.status === 1
                //             ? t("global.active")
                //             : t("global.inactive")}
                //     </span>
                // );
                return {
                    no: idx,
                    id: val.id,
                    title: t(`vendor.${val.slug}`),
                    slug: val.slug,
                    //status: stVal,
                    //action: actionVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("game-vendor.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (

                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-2`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("game-vendor._title")}
                                                </label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.filter.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t(`global.status`)}
                                        </label>
                                        <select
                                            className="form-control filter-input"
                                            id="status"
                                            name="status"
                                            onChange={this.props.onInputChange}
                                            value={this.props.filter.status}
                                        >
                                            <option value=""></option>
                                            <option value="1">
                                                {t("global.active")}
                                            </option>
                                            <option value="0">
                                                {t("global.inactive")}
                                            </option>
                                        </select>
                                    </div>
                                </div> */}
                                        {/* </div>   */}

                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("game-vendor.listing")}
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            {permission.includes("game_vendor_create") && (
                                                <>
                                                    <AiFillPlusCircle />
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-underline"
                                                        idValue="create"
                                                        buttonName={t(
                                                            "role.game_vendor_create"
                                                        )}
                                                        clicked={() =>
                                                            this.props.changePageHandler(
                                                                "create"
                                                            )
                                                        }
                                                    />
                                                </>

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        // searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, 'searchValue')}
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={
                                            this.props.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(GameVendorListing);
