import axios from "../../../common/config/axios";
import * as types from "./types";

export const getBonusSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get('vip-bonus/list', {params: data})
    .then((res) => {
        dispatch({
            type: types.MEMBER_BONUS_SETTING_LIST,
            payload: res
        });
    })
    .catch((err) => {
        console.log("Get Member Bonus Error: " + err);
        dispatch({
            type: types.MEMBER_BONUS_SETTING_LIST,
            payload: []
        });
    });
}

export const createBonusSetting = (data) => (dispatch, getState) => {
    data['country_id'] = getState().currentUser.data.country_id;
    
    axios.post('vip-bonus/create', data)
    .then((res) => {
        dispatch({
            type: types.MEMBER_BONUS_SETTING_CREATE,
            payload: res
        });
    })
    .catch((err) => {
        console.log("Create Member Bonus Error: "+ err);
    });
}

export const showBonusSetting = (data) => (dispatch) => {
    axios.get('vip-bonus/show', {params: data})
    .then((res) => {
        dispatch({
            type: types.MEMBER_BONUS_SETTING_SHOW,
            payload: res
        });
    })
    .catch((err) => {
        console.log("Get Specific Member Bonus Error: " + err);
    });
}

export const updateBonusSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('vip-bonus/update', data)
    .then((res) => {
        dispatch({
            type: types.MEMBER_BONUS_SETTING_UPDATE,
            payload: res
        });
    })
    .catch((err) => {
        console.log("Update Member Bonus Error" + err);
    });
}