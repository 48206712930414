import * as type from "./types";
import { createReducer } from "../../../util/index";

const initialState = {
    // data: [],
};

const eventBetReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.OPTION_DATA]: (state, action) => ({
        ...state,
        option_data: action.payload
    })
});

export default eventBetReducer;