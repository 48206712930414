import React, { Component } from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import AgentAccountDetails from "./AgentAccountDetails";
import AgentResetPassword from "./AgentResetPassword";
import AgentWalletAdjustment from "./AgentWalletAdjustment";

class AgentEdit extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            pageShow: "account_details",
            agentEditId: (this.props.selectId || null),
            agentUsername: (this.props.selectUn || null),
            initialLoading: [
                { id: "account_details", loading: true },
                { id: "wallet_adjustment", loading: true },
                { id: "reset_password", loading: true },
                { id: "game_access", loading: true }
            ],
        };
        // this.props.initMemberShowInitialLoading(this.state.initialLoading);
        this.changeTabHandler = this.changeTabHandler.bind(this);
    }

    componentDidMount() 
    {
        // console.log(this.props.selectUn);
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        return true;
    }

    changeTabHandler = (e) => 
    {
        e.preventDefault();
        // this.setState({
        //     pageShow: e.target.id,
        // });
        
        this.setState((state) => 
        {
            const initialLoading = state.initialLoading.map((item, idx) => 
            {
                if (item.id === e.target.id) {
                    return { id: item.id, loading: false };
                } else {
                    return item;
                }
            });

            return {
                initialLoading,
                pageShow: e.target.id,
            };
        });
    };

    showHandler = (e) => {
        e.preventDefault();
        this.setState({
            showMember: true,
        });
    };

    render() 
    {
        const { t } = this.props;
        // let display = null;
        let tabId = this.state.pageShow;
        
        // console.log(this.state.initialLoading)

        return (
            <Auxiliary>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-primary tabs-style-2 mt-3">
                            <div className="tab-menu-heading">
                                <div className="tabs-menu1">
                                    <ul className="nav panel-tabs main-nav-line">
                                        {this.props.permission.includes("agent_update") && (
                                            <li>
                                                <button
                                                    id="account_details"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "account_details" ? "active" : ""}`}>
                                                    {t("member.account-details")}
                                                </button>
                                            </li>
                                        )}
                                        {this.props.permission.includes("agent_update") && (
                                            <li>
                                                <button
                                                    id="wallet_adjustment"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "wallet_adjustment" ? "active" : ""}`}>
                                                    {t("member.wallet-adjustment")}
                                                </button>
                                            </li>
                                        )}

                                        {this.props.permission.includes("agent_update") && (
                                            <li>
                                                <button
                                                    id="reset_password"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "reset_password" ? "active" : ""}`}>
                                                    {t("member.reset-password")}
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler("listing")}
                                />
                                <div>
                                    <div className="member-name">
                                        <FontAwesomeIcon icon={faUser} /> {this.state.agentUsername}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.permission.includes("member_update") && tabId === "account_details" && (
                    <AgentAccountDetails 
                        // agentEditId={this.state.agentEditId} 
                        {...this.state}
                        permission={this.props.permission} 
                        initialLoading={this.state.initialLoading} 
                        refreshHandler={() => this.props.refreshHandler()}

                    />
                )}

                {this.props.permission.includes("wallet_adjustment_create") && tabId === "wallet_adjustment" && (
                    <AgentWalletAdjustment 
                        {...this.state}
                        initialLoading={this.state.initialLoading}
                        refreshHandler={() => this.props.refreshHandler()}
                    />
                    // <>bbbb</>
                )}

                {this.props.permission.includes("member_reset_password") && tabId === "reset_password" && (
                    <AgentResetPassword 
                        {...this.state}
                        initialLoading={this.state.initialLoading} 
                    />
                )}
                
            </Auxiliary>
        );
    }
}

// const mapStateToProps = (state) => {
//     const { member } = state;

//     return {
//         memberEditData: member.member_edit_data,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         editMember: (id) => dispatch(editMember(id)),
//     };
// };

export default withNamespaces("translation")(AgentEdit);
