import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    counter: 0,
    results: [],
};

const demoReducer = createReducer(initialState) ({
    [type.INCREMENT]: (state, action) => ({
        ...state,
        counter: state.counter + 1,
    }),

    [type.DECREMENT]: (state, action) => ({
        ...state,
        counter: state.counter - 1,
    }),

    [type.ADD]: (state, action) => ({
        ...state,
        counter: state.counter + 5,
    }),

    [type.SUBTRACT]: (state, action) => ({
        ...state,
        counter: state.counter - 5,
    }),

    [type.STORE_RESULT]: (state, action) => ({
        ...state,
        results: state.results.concat({ id: new Date(), value: action.result }),
    }),

    [type.DELETE_RESULT]: (state, action) => ({
        ...state,
        results: state.results.filter((result) => result.id !== action.resultElId),
    }),

});

export default demoReducer;
