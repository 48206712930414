import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const gameListReducer = createReducer(initialState) ({
    [type.GAME_LIST]: (state, action) => ({
        ...state,
        game_list_data: action.payload
    }),

    [type.GAME_GET_FILTER_DATA]: (state, action) => ({
        ...state,
        game_list_filter_data: action.payload
    }),

    [type.GAME_LIST_CREATE]: (state, action) => ({
        ...state,
        game_list_create_data: action.payload,
    }),

    [type.GAME_LIST_STORE]: (state, action) => ({
        ...state,
        game_list_store_result: action.payload,
    }),

    [type.GAME_LIST_EDIT]: (state, action) => ({
        ...state,
        game_list_edit_data: action.payload
    }),

    [type.GAME_LIST_UPDATE]: (state, action) => ({
        ...state,
        game_list_update_result: action.payload
    }),

    [type.GAME_LIST_UPDATE_STATUS]: (state, action) => ({
        ...state,
        game_list_update_status_result: action.payload,
    }),

    [type.GAME_LIST_UPDATE_FAV]: (state, action) => ({
        ...state,
        game_list_update_fav_result: action.payload,
    }),

    [type.GAME_LIST_UPDATE_DEMO]: (state, action) => ({
        ...state,
        game_list_update_demo_result: action.payload,
    }),
});

export default gameListReducer;