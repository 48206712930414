import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const bankAccountReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_BANK_ACCOUNT_LIST]: (state = initialState, action) => ({
        ...state,
        bank_account_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.UPDATE_STATUS_BANK_ACCOUNT]: (state, action) => ({
        ...state,
        update_status_bank_account_result: action.payload,
    }),

    [type.DELETE_BANK_ACCOUNT]: (state, action) => ({
        ...state,
        delete_bank_account_result: action.payload,
    }),

    [type.SHOW_BANK_ACCOUNT]: (state, action) => ({
        ...state,
        show_bank_account_data: action.payload,
    }),

    [type.UPDATE_STATUS_MAINTENANCE]: (state, action) => ({
        ...state,
        update_status_maintenance_result: action.payload,
    }),

    [type.CREATE_BANK_ACCOUNT]: (state, action) => ({
        ...state,
        post_bank_account_result: action.payload,
    }),

    [type.GET_BANK_NAME_LIST]: (state, action) => ({
        ...state,
        bank_name_data: action.payload,
    }),

    [type.GET_BANK_ACCOUNT_NAME_LIST]: (state, action) => ({
        ...state,
        bank_account_name_data: action.payload,
    }),

    [type.UPDATE_BANK_ACCOUNT]: (state, action) => ({
        ...state,
        update_bank_account_result: action.payload,
    }),

    [type.GET_MEMBER_GROUP]: (state, action) => ({
        ...state,
        member_group_data: action.payload,
    }),
    [type.BANK_ACCOUNT_LEDGER]: (state = initialState, action) => ({
        ...state,
        bank_account_ledger: action.payload
    }),
});

export default bankAccountReducer;