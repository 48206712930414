export const GET_BANK_ACCOUNT_LIST = "GET_BANK_ACCOUNT_LIST";
export const UPDATE_STATUS_BANK_ACCOUNT = "UPDATE_STATUS_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const SHOW_BANK_ACCOUNT = "SHOW_BANK_ACCOUNT";
export const UPDATE_STATUS_MAINTENANCE = "UPDATE_STATUS_MAINTENANCE";
export const CREATE_BANK_ACCOUNT = "CREATE_BANK_ACCOUNT";
export const GET_BANK_NAME_LIST = "GET_BANK_NAME_LIST";
export const GET_BANK_ACCOUNT_NAME_LIST = "GET_BANK_ACCOUNT_NAME_LIST";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
export const GET_MEMBER_GROUP = "GET_MEMBER_GROUP";
export const BANK_ACCOUNT_LEDGER = "BANK_ACCOUNT_LEDGER";
export const DROPDOWN = "BANK_ACCOUNT_DROPDOWN";