import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withNamespaces } from "react-i18next";
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import {showSpecialBirthdayGift} from "../../store/ducks/specialBirthdayGift/actions";

class SpecialBirthdayGiftShow extends Component {
    state = {
        date: '',
        username: '',
        description: '',
        loading: true
    };

    componentDidMount() {
        const showSpecialBirthdayGiftData = {};
        showSpecialBirthdayGiftData.special_birthday_gift_id = this.props.show_special_birthday_gift_id;
        this.props.showSpecialBirthdayGift(showSpecialBirthdayGiftData);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.show_special_birthday_gift_members_data !== this.props.show_special_birthday_gift_members_data) {
            if (nextProps.show_special_birthday_gift_members_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    date: nextProps.show_special_birthday_gift_members_data.data.data[0].date,
                    username: nextProps.show_special_birthday_gift_members_data.data.data[0].username,
                    description: nextProps.show_special_birthday_gift_members_data.data.data[0].description
                })
            } else{
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_special_birthday_gift_members_data.data.message,
                    icon: nextProps.show_special_birthday_gift_members_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("special-birthday-gift.special-birthday-gift")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("special-birthday-gift.show-special-birthday-gift")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <th>{t("special-birthday-gift.date")}</th>
                                            <td field-key='date'>{this.state.date}</td>
                                        </tr>

                                        <tr>
                                            <th>{t("special-birthday-gift.username")}</th>
                                            <td field-key='date'>{this.state.username}</td>
                                        </tr>

                                        <tr>
                                            <th>{t("special-birthday-gift.description")}</th>
                                            <td field-key='date'>{this.state.description}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {specialBirthdayGift} = state;

    //Getting the state from the reducers
    return {
        show_special_birthday_gift_members_data: specialBirthdayGift.show_special_birthday_gift_members_data,
    }
};

const mapDispatchToProps = {
    showSpecialBirthdayGift
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SpecialBirthdayGiftShow));