import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const bankBalanceAdjustmentReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LISTING]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    /*[type.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),*/
    [type.BALANCE]: (state, action) => ({
        ...state,
        bankBalc: action.value,
    }),
    [type.STORE_TRANSFER]: (state, action) => ({
        ...state,
        storeTransfer: action.value,
    }),
    [type.STORE_CASH]: (state, action) => ({
        ...state,
        storeCash: action.value,
    }),
});

export default bankBalanceAdjustmentReducer;