import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Icon } from "@iconify/react";

import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import StringInput from '../../components/Input/StringInput/StringInput';
// import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import Button from '../../components/Button/Button';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import { formValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';

class AgentPayoutListing extends Component
 {
    /* ============ component lifecycle [START] ============ */
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            showCard: false,
        };
        // console.log('constructor (listing):', this.state);
    }

    componentDidMount() 
    {
        formValidation('agent_payout_form');
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    footerFormatter(column, colIndex, { text }) 
    {
        return (
            <div>
                {/* <p className='sub-total'>{column["footer"][0]}</p> */}
                <p>( {column["footer"][0]} )</p>
            </div>
        );
    }

    toggleCard = () => 
    {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() 
    {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const cpColumn =
        [
            {
                text: t('agent-payout.game-type'),
                dataField: 'slug',
                // sort: true, 
                // onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
                formatter: (cell, row, rowIndex) => {
                    return t(`game-type.${row.slug}`);
                },
                footer: [t("global.total")],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: `${t('agent-payout.total-bet')} (${t('global.currency.usd')})`,
                dataField: 'total_bet',
                formatter: (cell, row, rowIndex) => {
                    return new Intl.NumberFormat('en-US', num_format).format(row.total_bet);
                },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format((this.props.data.cpTotal && this.props.data.cpTotal.total_bet) || 0).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer',
            },
            {
                text: `${t('agent-payout.total-bet-payout')} (${t('global.currency.usd')})`,
                dataField: 'total_bet_payout',
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat('en-US', num_format).format(row.total_bet_payout);
                },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format((this.props.data.cpTotal && this.props.data.cpTotal.total_bet_payout) || 0).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer',
            },
            {
                text: `${t('agent-payout.total-expenses')} (${t('global.currency.usd')})`,
                dataField: 'total_expenses',
                formatter: (cell, row, rowIndex) => {
                    return new Intl.NumberFormat('en-US', num_format).format(row.total_expenses);
                },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format((this.props.data.cpTotal && this.props.data.cpTotal.total_expenses) || 0).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer',
            },
            {
                text: `${t('agent-payout.total-profit')} (${t('global.currency.usd')})`,
                dataField: 'profit',
                formatter: (cell, row, rowIndex) => {
                    return new Intl.NumberFormat('en-US', num_format).format(row.profit);
                },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format((this.props.data.cpTotal && this.props.data.cpTotal.total_profit) || 0).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer',
            },
        ];

        const columns =
        [
            {
                text: '',
                dataField: 'action',
                headerStyle: { width: '120.5px' },
                headerFormatter: (column, colIndex) => 
                {
                    let val = permission.includes('agent_payout_submit') ? (
                        <input
                            type='checkbox'
                            id='check_all'
                            name='check_all'
                            onChange={(event) => this.props.checkAllHandler(event, this.props.tabName, 'formInput')}
                            checked={this.props.checkAll ? true : false}
                        />
                    ) : (
                        ''
                    );
                    return val;
                },
                formatter: (cell, row, rowIndex, extraData) => 
                {
                    let val = permission.includes('agent_payout_submit') ? (
                        (row.profit !== 0) ? (
                            <input
                                type='checkbox'
                                className='editor-active'
                                id={`cb_${row.aid}`}
                                name={`cb_${row.aid}`}
                                checked={extraData[`cb_${row.aid}`] ? true : false}
                                onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'formInput')}
                            />
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    );

                    return val;
                },
                formatExtraData: this.props.data.formInput,
            },
            {
                text: t('global.no'),
                dataField: 'no',
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = rowIndex + 1;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => 
                {
                    return { width: '120px' };
                },
            },
            {
                text: t('agent-payout.agent'),
                dataField: 'agent',
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: t('agent-payout.game-type'),
                dataField: 'slug',
                formatter: (cell, row, rowIndex) => 
                {
                    return '-';
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: t('agent-payout.share-percentage'),
                dataField: '',
                formatter: (cell, row, rowIndex) => 
                {
                    return '-';
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: `${t('agent-payout.total-bet')} (${t('global.currency.usd')})`,
                dataField: 'total_bet',
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
                formatter: (cell, row, rowIndex) => 
                {
                    let val = permission.includes(
                        'agent_payout_details'
                    ) ? (
                        <b>
                            <a href='/#' onClick={(event) => this.props.betDetailsHandler(event, row.aid, this.props.data.filterInput.confirm_date, null)}>
                                {new Intl.NumberFormat('en-US', num_format).format(row.total_bet)}
                                <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                            </a>
                        </b>
                    ) : (
                        new Intl.NumberFormat('en-US', num_format).format(row.total_bet)
                    );

                    return val;
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: `${t('agent-payout.payout')} (${t('global.currency.usd')})`,
                dataField: 'profit',
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat('en-US', num_format).format(row.profit);
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: `${t('agent-payout.payout')}`,
                dataField: 'payout_base_currency',
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat('en-US', num_format).format(row.payout_base_currency);
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
            },
            {
                text: `${t('agent-payout.net-payout')}`,
                dataField: 'payout_base_currency',
                formatter: (cell, row, rowIndex, extraData) => 
                {
                    let val = permission.includes('agent_payout_submit')
                        ?
                        (
                            (row.payout_base_currency !== 0)
                                ?
                                (
                                    <NumberInput
                                        id={`payout_tbg_${row.aid}`}
                                        name={`payout_tbg_${row.aid}`}
                                        // min={0.1}
                                        value={extraData[`payout_tbg_${row.aid}`] || ''}
                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'formInput')}
                                        className='form-control filter-input'
                                        required
                                    />
                                )
                                :
                                (
                                    new Intl.NumberFormat('en-US', num_format).format(row.payout_base_currency)
                                )
                        )
                        :
                        (
                            new Intl.NumberFormat('en-US', num_format).format(row.payout_base_currency)
                        );
                    return val;
                },
                headerStyle: () => 
                {
                    return { width: '150px' };
                },
                formatExtraData: this.props.data.formInput
            },
        ];

        const subColumns =
        [
            {
                dataField: '',
                headerAttrs: 
                {
                    hidden: true
                },
                attrs: 
                {
                    colSpan: 3,
                },
            },
            {
                dataField: 'slug',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return <span>{t(`game-type.${row.slug}`)}</span>;
                },
            },
            {
                dataField: 'percentage',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return <span>{row.percentage}%</span>;
                },
            },
            {
                dataField: 'total_bet',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    
                    let company_total_bet = '';
                    let company_total_profit = '';
                    this.props.data.cpData.forEach((item) => 
                    {
                        if(row.slug === item.slug)
                        {
                            company_total_bet = item.total_bet;
                            company_total_profit = item.profit;
                        }
                    });

                    let val = new Intl.NumberFormat('en-US', num_format).format(row.total_bet);
                    const popoverHoverFocus = (
                        <Popover id="popover-trigger-hover-focus">
                            <p className="px-3 py-2">
                                {t('agent-payout.company-total-profit')} * ({t('agent-payout.total-bet')} * 100 / {t('agent-payout.company-total-bet')} * {t('agent-payout.share-percentage')}) <br/><br/>
                                {company_total_profit} * ({row.total_bet} * 100 / {company_total_bet} * {row.percentage}%)
                            </p>
                        </Popover>
                    );

                    return (
                        <>
                            {val}
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverHoverFocus}>
                                <span 
                                    className="info-icon" 
                                    style={{
                                        display: 'flex', 
                                        alignitems: 'center', 
                                        justifycontent: 'center',
                                        width: '1rem',
                                        height: '1rem',
                                        borderradius: '100%',
                                        background: 'white',
                                        color: '$blue-500',
                                    }}
                                >
                                    <Icon icon="mdi:information-variant" />
                                </span>
                            </OverlayTrigger>
                        </>
                    );

                    // return new Intl.NumberFormat("en-US", num_format).format(row.total_bet);
                },
            },
            {
                dataField: 'profit',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.profit);
                },
            },
            {
                dataField: 'payout_base_currency',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.payout_base_currency);
                },
            },
            {
                dataField: 'payout_base_currency',
                headerAttrs: 
                {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.payout_base_currency);
                },
            },
            // {
            //     dataField: '',
            //     headerAttrs: {
            //         hidden: true
            //     },
            //     headerStyle: { width: '40px' },
            // },
        ];

        const expandRow = 
        {
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: (this.props.data.loading) ? [] : null,
            renderer: (row) => 
            {
                // console.log(props.expandData);
                // console.log(`${row.date}_${row.slug}`);
                // console.log(products['2022-01-21_evo']);
                // console.log(props.expandData[`${row.date}_${row.slug}`]);
                // console.log(this.props.subLoading)
                // console.log(row);
                return (
                    <div className="font-weight-bold first-expand-row">
                        {this.props.data.subData[`${row.agent}`] ? (
                            <BootstrapTable
                                keyField='id'
                                data={(this.props.data.subData[`${row.agent}`] || [])}
                                columns={(subColumns || [])}
                                expandRow={[]}
                            />
                        ) : (
                            <Skeleton count={2} />
                        )}
                    </div>
                )
            },

            expandHeaderColumnRenderer: ({ isAnyExpands }) => 
            {
                if (isAnyExpands) 
                {
                    return (
                        <b className="row-expand-btn active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                            </svg>
                        </b>
                    );
                }

                return (
                    <b className="row-expand-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </b>
                );
            },

            expandColumnRenderer: ({ expanded }) => 
            {
                if (expanded) 
                {
                    return (
                        <b className="row-expand-btn active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                            </svg>
                        </b>
                    );
                }

                return (
                    <b className="row-expand-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </b>
                );
            }
        }

        return (
            <Auxiliary>
                <ValidationError
                    // formName='agent_payout_form'
                    touched={this.props.touched}
                    message={this.props.errMsg}
                />
                <div className='row row-sm'>
                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div className=''>
                                {this.props.data.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div method='post' id='filter_form' className={`form-horizontal filter-function row ${this.state.showCard ? 'open' : 'close'} filter-wrapper-2`}>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('agent-payout.date')}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DatePicker
                                                        id="date"
                                                        name="date"
                                                        className="form-control filter-input"
                                                        wrapperClassName="wrapperDatePicker"
                                                        selected={this.props.data.filterInput.date_str}
                                                        onChange={this.props.handleMonthPickerChange}
                                                        popperContainer={DatePickerContainer}
                                                        dateFormat="MM/yyyy"
                                                        autoComplete="off"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>
                                                    {t('agent-payout.agent')}
                                                </label>
                                                <StringInput
                                                    id='username'
                                                    name='username'
                                                    value={this.props.data.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                    className='form-control filter-input'
                                                    onSearchPress={(evt) => evt.key === 'Enter' && this.props.refreshHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>

                                        <div className='col mt-auto'>
                                            <div className='d-flex'>
                                                {permission.includes('agent_payout_submit') && (
                                                    <Button
                                                        typeButton='submit'
                                                        classValue='btn btn-warning'
                                                        idValue='submit'
                                                        buttonName={t('agent-payout.submit-payout')}
                                                        formValue='agent_payout_form'
                                                    />
                                                )}
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-blue'
                                                    idValue='filter'
                                                    buttonName={t('global.filter')}
                                                    clicked={() => this.props.refreshHandler(this.props.tabName)}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-secondary'
                                                    idValue='reset'
                                                    buttonName={t('global.reset')}
                                                    clicked={() => this.props.clearHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='show-btn' onClick={() => this.toggleCard()}>
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='card-title mb-1'>
                                    <h4 className='card-title mg-b-0'>
                                        {t('agent-payout.company-profit')}
                                    </h4>
                                    {(this.props.data.loading)
                                    ?
                                        <Skeleton width={50} count={2} />
                                    :
                                    this.props.data.exchangeRate && 
                                        Object.keys(this.props.data.exchangeRate).map((item, i) => 
                                        {
                                            return (
                                                <><small style={{color: '#8B0000'}}><b>*{this.props.data.exchangeRate[item]}</b></small><br/></>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            
                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    {(this.props.data.loading)
                                    ?
                                        <Skeleton count={3} />
                                    :
                                        <DataTable
                                            keyField='aid'
                                            data={this.props.data.cpData}
                                            columns={cpColumn}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.props.data.loading}
                                            hideSearchBar={true}
                                            hideSizePerPage={true}
                                            hideTotalRecords={true}
                                            hidePagination={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>
                                        {t('agent-payout.agent-payout')}
                                    </h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    <form
                                        method='post'
                                        id='agent_payout_form'
                                        className='form-horizontal filter-function'
                                        onSubmit={(event) => this.props.submitHandler(event, this.props.tabName)}
                                        noValidate
                                    >
                                        <DataTable
                                            keyField='agent'
                                            data={this.props.data.data}
                                            columns={columns}
                                            activePage={this.props.data.activePage}
                                            limit={this.props.data.limit}
                                            totalItems={this.props.data.totalRecords}
                                            paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'activePage')}
                                            sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'limit')}
                                            searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'searchValue')}
                                            searchValue={this.props.data.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.props.data.loading}
                                            hideSearchBar={true}
                                            expandRow={expandRow}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(AgentPayoutListing);
