import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const ipAddressReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_LOGIN_IP_ADDRESS_LIST]: (state = initialState, action) => ({
        ...state,
        login_ip_address_data: action.payload
    }),

    [type.GET_REGISTER_IP_ADDRESS_LIST]: (state = initialState, action) => ({
        ...state,
        register_ip_address_data: action.payload
    }),

    [type.SHOW_LOGIN_IP_ADDRESS_DETAILS]: (state, action) => ({
        ...state,
        show_login_ip_address_data: action.payload,
    }),

    [type.SHOW_REGISTER_IP_ADDRESS_DETAILS]: (state, action) => ({
        ...state,
        show_register_ip_address_data: action.payload,
    }),
});

export default ipAddressReducer;