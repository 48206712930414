import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    // console.log(props);
    axios.get('bank_balance_adjustment/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LISTING,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDropDown = () => (dispatch, getState) =>
{
    axios.get('bank_balance_adjustment/drop_down', {
      params: {
        country_id: getState().currentUser.data.country_id
      },
    })
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getBankBalc = (id, tc, pt) => dispatch =>
{
  axios.get('bank_balance_adjustment/balance', {
      params: {
          "bank_acc": id,
          "transaction_channel": tc,
          "payment_type": pt,
      }
  }).then(function (response) 
  {
    // console.log(response);
    dispatch (
    {
      type: actionType.BALANCE,
      value: response.data
    })
  })
  .catch(err => 
  {

  });
};

export const storeTransfer = (data) => dispatch =>
{
  // console.log(data);
  axios.post('bank_balance_adjustment/store_transfer', data)
  .then(function (response) 
  {
    // console.log(response);
    dispatch (
    {
      type: actionType.STORE_TRANSFER,
      value: response.data
    })
  })
  .catch(err => 
  {
   
  });
};

export const storeCash = (data) => dispatch =>
{
  // console.log(data);
  axios.post('bank_balance_adjustment/store_cash', data)
  .then(function (response) 
  {
    // console.log(response);
    dispatch (
    {
      type: actionType.STORE_CASH,
      value: response.data
    })
  })
  .catch(err => 
  {
    
  });
};