import axios from "../../../common/config/axios";
import * as type from "./types";

export const getDayDepositReport = (data) => (dispatch) => {
    axios
        .get("get_day_deposit_report/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_DAY_DEPOSIT_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Get Day Deposit Report Error" + err);
            dispatch({
                type: type.GET_DAY_DEPOSIT_REPORT,
                payload: [],
            });
        });
};

export const getMemberDepositStatusReport = (data) => (dispatch) => {
    axios
        .get("member_deposit_status/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_DEPOSIT_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Get Member Deposit Status Report Error" + err);
            dispatch({
                type: type.GET_MEMBER_DEPOSIT_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getVendorStatusReport = (data) => (dispatch) => {
    axios
        .get("report/get_vendor_status_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_VENDOR_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_VENDOR_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getVendorSubStatusReport = (data) => (dispatch, getState) => {
    data.lang_id = getState().currentUser.data.language_id;
    data.country_id = getState().currentUser.data.country_id;
    axios
        .get("report/get_vendor_sub_status_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_VENDOR_SUB_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_VENDOR_SUB_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getYearlyVendorStatusReport = (data) => (dispatch) => {
    axios
        .get("report/get_yearly_vendor_status_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_YEARLY_VENDOR_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_YEARLY_VENDOR_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getDropDown = () => dispatch =>
{
    axios.get('report/yearly_vendor_status_report_drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err =>
    {
      console.warn(err);
    });
};

export const getFilterData = () => (dispatch, getState) => {
    axios
        .get("member_deposit_status/drop_down", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_GET_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Error" + err);
            dispatch({
                type: type.MEMBER_GET_FILTER_DATA,
                payload: [],
            });
        });
};

export const getGameList = async (props) =>
{
    // console.log(props);
    let response;
    try 
    {
        response = await axios.get('game_list/list', {params: props});
    } 
    catch (e) 
    {
        throw new Error(e.message)
    }

    return (response.data) ? response.data : null;
};

export const getMemberStatusReport = (data) => (dispatch) => {
    axios
        .get("report/get_member_status_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getMemberSubStatusReport = (data) => (dispatch, getState) => {
    data.lang_id = getState().currentUser.data.language_id;
    data.country_id = getState().currentUser.data.country_id;
    axios
        .get("report/get_member_sub_status_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_SUB_STATUS_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_SUB_STATUS_REPORT,
                payload: [],
            });
        });
};

export const getMemberPayoutReport = (data) => (dispatch) => {
    axios
        .get("report/get_member_payout_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_PAYOUT_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.GET_MEMBER_PAYOUT_REPORT,
                payload: [],
            });
        });
};

export const getMemberHistoryReport = (data) => (dispatch) => {
    axios
        .get("report/get_member_login_history_report", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_HISTORY_REPORT,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Get Member History Report Error" + err);
            dispatch({
                type: type.GET_MEMBER_HISTORY_REPORT,
                payload: [],
            });
        });
};