import * as type from './types';
import axios from '../../../common/config/axios';

export const getBusinessAccount = (data) => dispatch => {
    axios.get('business_account/list', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_BUSINESS_ACCOUNT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const showBusinessAccount = (data) => dispatch => {
    axios.get('business_account/show', {
        params: {
            "business_account_id": data['business_account_id']
        }
    }).then((response) => {
        dispatch({
            type: type.SHOW_BUSINESS_ACCOUNT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBusinessAccount = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('business_account/update_status',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_BUSINESS_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getMembers = (data) => dispatch => {
    axios.get('business_account/get_business_account_member', {
    }).then((response) => {
        dispatch({
            type: type.GET_BUSINESS_MEMBERS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getBanks = (data) => dispatch => {
    axios.get('business_account/get_business_account_bank', {
    }).then((response) => {
        dispatch({
            type: type.GET_BUSINESS_BANKS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const storeBusinessAccount = (data) => dispatch => {
    axios.post('business_account/store',data)
        .then( (response) => {
            dispatch ({
                type: type.STORE_BUSINESS_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};