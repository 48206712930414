import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Select from "../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";

class MemberLevelTrackerListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.log('componentDidMount (listing):', this.state);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate (listing):', nextProps);
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;

        let level_status_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("member-level-tracker.level-up"), value: 1 },
            { label: t("member-level-tracker.level-down"), value: 2 },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member-level-tracker.member-level-tracker")}</h4>
                            </div>
                        </div>
                        {this.props.permission.includes("member_create") && (
                            <div className="d-flex my-xl-auto right-content">
                                <div className="mb-3 mb-xl-0">
                                    <div className="btn-group">
                                        <Button
                                            typeButton="button"
                                            idValue="member"
                                            classValue="btn btn-blue"
                                            clicked={() => this.props.changePageHandler("create")}
                                            buttonName={t("member.add-member")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <form method="post" id="filter_member_form" className="form-horizontal filter-function">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="username" className="font-weight-bolder">
                                                {t("member.username")}
                                            </label>
                                            <StringInput
                                                id="username"
                                                name="username"
                                                type="text"
                                                value={this.props.filter.username}
                                                onChange={this.props.onInputChange}
                                                className="form-control filter-input"
                                                onSearchPress={(evt) => evt.key === 'Enter' && this.props.refreshHandler()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="level_from" className="font-weight-bolder">
                                                {t("member-level-tracker.level-from")}
                                            </label>
                                            <select
                                                className="form-control filter-input"
                                                id="level_from"
                                                name="level_from"
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.level_from}>
                                                <option value=""></option>
                                                {this.props.ddMemberGroup}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="level_to" className="font-weight-bolder">
                                                {t("member-level-tracker.level-to")}
                                            </label>
                                            <select
                                                className="form-control filter-input"
                                                id="level_to"
                                                name="level_to"
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.level_to}>
                                                <option value=""></option>
                                                {this.props.ddMemberGroup}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor="level_status" className="font-weight-bolder">
                                                {t("global.status")}
                                            </label>
                                            <Select
                                                id="level_status"
                                                name="level_status"
                                                options={level_status_option}
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.level_status}
                                                className="form-control filter-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="level_up_date" className="font-weight-bolder">
                                                {t("member-level-tracker.level-up-date")}
                                            </label>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-calendar-check"></i>
                                                </span>
                                                <DateTimePicker
                                                    id="level_up_date"
                                                    name="level_up_date"
                                                    className="form-control filter-input"
                                                    range={true}
                                                    timePicker={true}
                                                    // format="YYYY/MM/DD"
                                                    value={this.props.filter.level_up_date}
                                                    onChange={this.props.onInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 float-right">
                                    {this.props.loading ? (
                                        <Skeleton count={2} />
                                    ) : (
                                        <div className="row float-right">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member-level-tracker.member-level-tracker-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="memberLevelTrackerId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberLevelTrackerListing);
