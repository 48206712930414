import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';
import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import TransactionDetails from '../Transaction/TransactionDetails';
import AgentTransactionDetails from '../AgentTransaction/AgentTransactionDetails';
import Excel from "../../assets/images/excel.svg";
import axios from "../../common/config/axios";
import * as moment from "moment";
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { filterErrorHandler } from "../../hoc/Shared/utility";

import { getPaymentTypeLedger } from '../../store/ducks/paymentGateway/actions';

const initialInputState = {
    start_date: moment().format('yyyy/MM/DD')+" 12:00:00 AM",
    end_date: moment().format('yyyy/MM/DD')+" 11:59:59 PM",
    identifier: moment().format('yyyy/MM/DD')+" 12:00:00 AM - "+moment().format('yyyy/MM/DD')+" 11:59:59 PM",
}
class PaymentTypeLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: 'listing',
            trxPage: 'member',
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            searchValue: '',
            order: '',
            totalRecords: 0,
            data: [],
            loading: true,
            detailsParam: '',
            filterInput: initialInputState, 
        }
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getPaymentTypeLedger(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.ledger !== undefined && nextProps.ledger !== this.props.ledger) {
            if (nextProps.ledger.responseCode === 200 && nextProps.ledger.msgType === 'success') {
                this.setState({
                    data: nextProps.ledger.data.result.row,
                    totalRecords: nextProps.ledger.data.result.pagination.total_record,
                    loading: false
                });
            }
            else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.ledger.message);
                });
            }
        }

        return true;
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.payment_gateway_id = this.props.payment_gateway_id;
        listingArr.transaction_channel_id = this.props.transaction_channel_id;

        Object.keys(this.state.filterInput).forEach((val) => {
            listingArr[val] = this.state.filterInput[val];
        });
        
        return listingArr;
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit));
        return <span>{rowNumber}</span>
    }

    onSort = (field, order) => {
        const orderArray = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        if(field === "date"){
            field = "approve_date";
        }

        orderArray[0]['id'] = field;
        orderArray[0]['desc'] = orderSequence;

        this.setState({
            order: orderArray,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getPaymentTypeLedger(listingArr);
        });
    }

    detailsHandler = (e, id, trx_type) => {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            trxPage: trx_type,
            detailsParam: {
                id: id
            }
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getPaymentTypeLedger(listingArr);
        });
    }

    exportExcelHandler = () => {
        const data = {};
        data.payment_gateway_id = this.props.currentPaymentGatewayId;
        data.transaction_channel_id = this.props.show_payment_gateway_id;
        data.identifier = this.state.filterInput.identifier;
        data.order = JSON.stringify(this.state.order);

        axios
            .get("payment_gateway/export_ledger", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - PaymentTypeLedger " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - PaymentTypeLedger Error", err);
            });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                searchValue: "",
                filterInput: newFilter,
                loading: true,
                data: [],
                order: '',
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getPaymentTypeLedger(listingArr);
            }
        );
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getPaymentTypeLedger(listingArr);
            }
        );
    };

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.filterInput);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "identifier") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filterInput: newFilter,
        });
    }

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];
        let stCls = {
            'transfer': 'success',
            'cash-in': 'success',
            'deposit': 'success',
            'withdrawal': 'danger',
            'cash-out': 'danger',
            'transfer-out': 'danger'
        };
        
        let columns = [];

        if(this.props.transaction_channel_id === 4){
            columns = [
                { text: t('transaction.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '65px', textAlign: 'center' } },
                { text: t('transaction.order-id'), dataField: 'order_id', sort: true, onSort: this.onSort, headerStyle: { width: '115px' } },
                { text: t('transaction.transaction-type'), dataField: 'transaction_type' },
                { text: t('transaction.amount'), dataField: 'amount', headerStyle: { width: '120px' } },
                { text: t('transaction.usdt'), dataField: 'paid_crypto_amount', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.fees-amount'), dataField: 'fees', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.balance'), dataField: 'balance', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.usdt-fees'), dataField: 'crypto_fees', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.usdt-balance'), dataField: 'crypto_balance', headerStyle: { width: '120px' } },
                { text: t('log.date'), dataField: 'date', sort: true, onSort: this.onSort }
            ];
        }else{
             columns = [
                { text: t('transaction.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '65px', textAlign: 'center' } },
                { text: t('transaction.order-id'), dataField: 'order_id', sort: true, onSort: this.onSort, headerStyle: { width: '115px' } },
                { text: t('transaction.transaction-type'), dataField: 'transaction_type' },
                { text: t('transaction.amount'), dataField: 'amount', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.fees-amount'), dataField: 'fees', headerStyle: { width: '120px' } },
                { text: t('payment-gateway.balance'), dataField: 'balance', headerStyle: { width: '120px' } },
                { text: t('log.date'), dataField: 'date', sort: true, onSort: this.onSort }
            ];
        }
       
        rsListing = this.state.data.map((val, idx) => {
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let orderIdVal = (permission.includes('transaction_details_access')) ? <b><a href="/#" onClick={(event) => this.detailsHandler(event, val.id, val.trx_type)}>{val.order_id}<i className='fas fa-external-link-square-alt text-primary ml-1'></i></a></b> : val.order_id;
            let transactionVal = (val.transaction_type === 'transfer') ? ((val.from_quickpay_acc_id === this.props.payment_gateway_id) ? t('bank-account.transfer-out') : t('bank-account.transfer-in')) : t(`transaction-type.${val.transaction_type}`);
            let amountVal = (
                <span className={(val.transaction_type === 'transfer' && (val.from_quickpay_acc_id === this.props.payment_gateway_id)) ? `badge-${stCls['transfer-out']}` : `badge-${stCls[val.transaction_type]}`}>
                    {new Intl.NumberFormat('en-US', num_format).format((val.transaction_type === 'withdrawal' || val.transaction_type === 'cash-out') ? val.debit : val.credit)}
                </span>
            );
            let balanceVal = ((val.transaction_type === 'transfer' && val.from_quickpay_acc_id === this.props.payment_gateway_id) || val.transaction_type === 'withdrawal') ? val.from_balance : val.to_balance;
            let feeVal = new Intl.NumberFormat('en-US', num_format).format(val.fees);
            let transactionType = (val.transaction_type === 'withdrawal') ? `${transactionVal} (${t(`transaction.status-desc.${val.status}`)})` : transactionVal;
            
            if(this.props.transaction_channel_id === 4){
                return {
                    no: idx,
                    order_id: orderIdVal,
                    transaction_type: transactionType,
                    amount: amountVal,
                    paid_crypto_amount: val.paid_crypto_amount,
                    balance: new Intl.NumberFormat('en-US', num_format).format(balanceVal),
                    date: val.identifier,
                    fees: feeVal,
                    crypto_fees: val.crypto_fees,
                    crypto_balance: val.crypto_balance
                };
            }else{
                return {
                    no: idx,
                    order_id: orderIdVal,
                    transaction_type: transactionType,
                    amount: amountVal,
                    balance: new Intl.NumberFormat('en-US', num_format).format(balanceVal),
                    date: val.identifier,
                    fees: feeVal
                };
            }
           
        });

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <>
                        <div className='breadcrumb-header'>
                            <div className='d-flex'>
                                <div className='my-auto'>
                                    <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.currentPage()} />
                                </div>
                            </div>
                            <div className='my-auto'>
                                <div className='d-flex'>
                                    <h4 className="content-title mb-0 my-auto ml-2">{t('payment-gateway.ledger')}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='row row-sm'>
                            <div className="col-xl-12">
                                <div className="card card-body">
                                    <div className="col mt-auto">
                                        <div className={`form-horizontal filter-function row filter-wrapper-1`}>
                                            <div className="col-md-4 col-6">                                                
                                                <div className='form-group'>
                                                    <label className='font-weight-bolder'>{t('log.date')}</label>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'>
                                                            <i className='fas fa-calendar-check'></i>
                                                        </span>
                                                        <DateTimePicker
                                                            id="identifier"
                                                            name="identifier"
                                                            className="form-control filter-input"
                                                            range={true}
                                                            timePicker={true}
                                                            value={this.state.filterInput.identifier}
                                                            onChange={this.onInputChange}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col mt-auto'>
                                                <div className='d-flex'>
                                                    <Button
                                                        typeButton='button'
                                                        classValue='btn btn-blue mr-2'
                                                        idValue="filter"
                                                        buttonName={t('global.filter')}
                                                        clicked={() => { this.refreshHandler() }}
                                                    />
                                                    <Button
                                                        typeButton='button'
                                                        classValue='btn btn-secondary'
                                                        idValue="reset"
                                                        buttonName={t('global.reset')}
                                                        clicked={() => { this.clearHandler() }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row row-sm'>
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="card-title mg-b-0">{t('bank-account.ledger-listing')}</h4>
                                        </div>

                                        {this.props.permission.includes("payment_gateway_export_ledger") && (
                                            <div className="d-flex justify-content-between float-right">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                clicked={() => this.exportExcelHandler()}
                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                buttonName={t("report.export-excel")}
                                            />
                                        </div>
                                        )}
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-hover datatable">
                                            <DataTable
                                                keyField="no"
                                                data={rsListing}
                                                columns={columns}
                                                activePage={this.state.activePage}
                                                limit={this.state.limit}
                                                totalItems={this.state.totalRecords}
                                                paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                                sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                                searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                                searchValue={this.state.searchValue}
                                                hideSearchBar={true}
                                                classValue="dataTable table-bordered text-justify text-center"
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {(this.state.pageShow === 'details' && this.state.trxPage === 'member') &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                        currentUser={this.props.currentUser}
                    />
                }

                {(this.state.pageShow === 'details' && this.state.trxPage === 'agent') &&
                    <AgentTransactionDetails 
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                }
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        ledger: state.paymentGateway.payment_type_ledger,
        currentUser: state.currentUser.data
    };
};

const mapDispatchToProps = {
    getPaymentTypeLedger
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PaymentTypeLedger));