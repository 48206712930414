// import axios from "../../../common/config/axios";
import * as type from "./types";

export const getUserData = (data) => (dispatch) => {
    if(data) {
        dispatch({
            type: type.SET_USER,
            payload: data
        })
    }
};

export const changeCountry = (data) => (dispatch, getState) => {
    if(data) {
        let updatedData = getState().currentUser.data;
        updatedData.country_id = data.country_id;
        updatedData.country_code = data.country_code;
        // console.log("changeCountry Action",getState(), "\n", updatedData);
        dispatch({
            type: type.CHANGE_COUNTRY,
            payload: updatedData
        })
    }
};

export const changeLanguage = (data) => (dispatch, getState) => {
    if(data) {
        let updatedData = getState().currentUser.data;
        updatedData.language_id = data.language_id;
        updatedData.language_code = data.language_code;
        dispatch({
            type: type.CHANGE_LANGUAGE,
            payload: updatedData
        })
    }
};

export const getCurrentPermission = () => (dispatch, getState) => {
    return getState().currentUser.data.permission;
}

// export const checkPermissionLoaded = () => (dispatch, getState) => {
//     let permissionData = getState().currentUser.data.permission;
//     if(permissionData.length !== 0) {
//         let updatedData = getState().currentUser.data;
//         updatedData.permissionLoaded = 1;
//         dispatch({
//             type: type.CHECK_PERMISSION_LOADED,
//             payload: updatedData
//         })
//     }
// };

// export const getCurrentCountryId = () => (dispatch, getState) => {
//     const state = getState();
//     console.log(state);
//     dispatch ({
//         type: type.GET_COUNTRY_ID,
//     })
// }

// export const getCurrentUserGroup = () => dispatch => {
//     dispatch ({
//         type: type.GET_USER_GROUP,
//     })
// }

