export const GET_PAYMENT_GATEWAY_LIST = "GET_PAYMENT_GATEWAY_LIST";
export const CREATE_PAYMENT_GATEWAY = "CREATE_PAYMENT_GATEWAY";
export const SHOW_PAYMENT_GATEWAY = "SHOW_PAYMENT_GATEWAY";
export const UPDATE_PAYMENT_GATEWAY = "UPDATE_PAYMENT_GATEWAY";
export const DELETE_PAYMENT_GATEWAY = "DELETE_PAYMENT_GATEWAY";
export const UPDATE_DEPOSIT_STATUS_PAYMENT_GATEWAY = "UPDATE_DEPOSIT_STATUS_PAYMENT_GATEWAY";
export const UPDATE_WITHDRAWAL_STATUS_PAYMENT_GATEWAY = "UPDATE_WITHDRAWAL_STATUS_PAYMENT_GATEWAY";
export const GET_PAYMENT_TYPE_LIST = "GET_PAYMENT_TYPE_LIST";
export const UPDATE_DEPOSIT_STATUS_PAYMENT_TYPE = "UPDATE_DEPOSIT_STATUS_PAYMENT_TYPE";
export const UPDATE_WITHDRAWAL_STATUS_PAYMENT_TYPE = "UPDATE_WITHDRAWAL_STATUS_PAYMENT_TYPE";
export const STORE_PAYMENT_TYPE = "STORE_PAYMENT_TYPE";
export const DROPDOWN = "PAYMENT_GATEWAY_DROPDOWN";
export const SHOW_PAYMENT_TYPE = "SHOW_PAYMENT_TYPE";
export const UPDATE_PAYMENT_TYPE = "UPDATE_PAYMENT_TYPE";
export const PAYMENT_TYPE_LEDGER = "PAYMENT_TYPE_LEDGER";