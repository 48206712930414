import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const memberStakingReducer = createReducer(initialState) ({
    [type.MEMBER_STAKINGS_LISTING]: (state, action) => ({
        ...state,
        member_staking_data: action.payload
    }),
    [type.MEMBER_STAKINGS_DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.MEMBER_STAKINGS_PAYOUT_DETAIL]: (state, action) => ({
        ...state,
        member_staking_payout_detail: action.payload
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.HISTORY]: (state, action) => ({
        ...state,
        history: action.value,
    }),
});

export default memberStakingReducer;