import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { formValidation, clearFormValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";

import * as actions from '../../store/ducks/luckySpinSetting/actions';

class LuckySpinSettingEdit extends Component 
{
    constructor() 
    {
        super();
        this.state = 
        {
            id: null,
            title: null,
            slug: null,
            setting_list: [{listIndex: Math.random(), key: "", value: ""}],
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() 
    {
        formValidation("lucky_spin_setting_form");
        if(this.props.selectId)
        {
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details!==undefined)
        {
            if(nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success')
            {
                let data = nextProps.details.data.result;
                let settingData = Object.entries(JSON.parse(nextProps.details.data.result.data));
                let settingList = [];

                settingData.map((item, idx) => 
                {
                    settingList.push({listIndex: Math.random(), key: item[0], value: item[1]});
                    return null;
                });

                this.setState({
                    id: data.id,
                    title: data.title,
                    slug: data.slug,
                    setting_list: settingList,
                    loading: false,
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update!==undefined)
        {
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => 
                {
                    clearFormValidation('lucky_spin_setting_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }

        // if (nextProps.updateSystemSettingResult !== this.props.updateSystemSettingResult) {
        //     if (nextProps.updateSystemSettingResult.responseCode === 200) {
        //         this.setState({ touched: false });
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: t("global.record-updated"),
        //             icon: nextProps.updateSystemSettingResult.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.props.refreshHandler();
        //                 this.props.changePageHandler("listing");
        //             }
        //         });
        //     } else if (nextProps.updateSystemSettingResult.responseCode === 422) {
        //         this.setState({
        //             touched: true,
        //             error_message: nextProps.updateSystemSettingResult.message,
        //         });
        //         Swal.close();
        //     } else {
        //         return false;
        //     }
        // }
        return true;
    }

    submitHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
                });
                let key_array = [];
                let value_array = [];

                this.state.setting_list.forEach((item, idx) => 
                {
                    key_array.push(item.key);
                    value_array.push(item.value)
                })

                let updateSystemSettingData = {};
                updateSystemSettingData.id = this.state.id;
                updateSystemSettingData.title = this.state.title;
                updateSystemSettingData.slug = this.state.slug;
                updateSystemSettingData.key = key_array;
                updateSystemSettingData.value = value_array;
                // console.log("Form Submit:", updateSystemSettingData);
                this.props.funcUpdate(updateSystemSettingData);
            }
        });
    };

    addRecord = () => {
        if(this.state.setting_list.length < 20) {
            this.setState((prevState) => ({
                setting_list: [...prevState.setting_list, {listIndex: Math.random(), key: "", value: ""}],
            }));
        }
    };

    removeRecord = (i) => {
        this.setState({
            setting_list: this.state.setting_list.filter((item, idx) => i !== idx)
        })
    }

    handleChange = (event) => {
        // console.log(event.target.name+' | '+event.target.value);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSettingListChange = (event) => {
        let setting_list = [...this.state.setting_list];
        setting_list[event.target.dataset.id][event.target.name] = event.target.value;
        this.setState({ setting_list});
    }

    render() 
    {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("lucky-spin-setting.edit-setting")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="lucky_spin_setting_form" className="form-horizontal" onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName="lucky_spin_setting_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="title">{t("lucky-spin-setting.title")}</label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={t("lucky-spin-setting.title")}
                                                    value={this.state.title || ''}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("lucky-spin-setting.slug")}</label>
                                                <StringInput
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    placeholder={t("lucky-spin-setting.slug")}
                                                    value={this.state.slug || ''}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h3 className="card-title col-8">{t('lucky-spin-setting.setting-value')}</h3>
                                                <div className="row col-4 justify-content-end mb-2 pr-0">
                                                    <button type="button" className="btn btn-info" onClick={(e) => this.addRecord(e)}>
                                                        <i className="fa fa-plus"></i> {t('lucky-spin-setting.add')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{overflowY:'auto'}}>
                                                <table className="table" id="setting_table" style={{minWidth:500}}>
                                                    <thead className="text-center bg-primary">
                                                        <tr>
                                                            <th className="text-white p-2">{t("global.no")}</th>
                                                            <th className="text-white p-2">{t("lucky-spin-setting.key")}</th>
                                                            <th className="text-white p-2">{t("lucky-spin-setting.setting-value")}</th>
                                                            <th style={{ width: "5%" }}>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.setting_list.map((item, idx) => {
                                                            return (
                                                                <tr key={idx} className="text-center">
                                                                    <td>{idx + 1}</td>
                                                                    <td key={`key-${idx}`}>
                                                                        <input
                                                                            id="key"
                                                                            name="key"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("lucky-spin-setting.key")}
                                                                            value={item.key}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td key={`value-${idx}`}>
                                                                        <input
                                                                            id="value"
                                                                            name="value"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("lucky-spin-setting.value")}
                                                                            value={item.value}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <i className="fa fa-trash" style={{color:'red', cursor:'pointer'}} onClick={() => this.removeRecord(idx)}></i>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("lucky-spin-setting.edit-setting")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("side-bar.lucky_spin_setting")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => 
{
    return {
        details: state.luckySpinSetting.details,
        update: state.luckySpinSetting.update,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(LuckySpinSettingEdit));
