import axios from "../../../common/config/axios";
import * as type from "./types";

export const getMemberStakings = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id
    axios
        .get("member_stakings/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.MEMBER_STAKINGS_LISTING,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Stakings Error", err);
            dispatch({
                type: type.MEMBER_STAKINGS_LISTING,
                payload: [],
            });
        });
};

export const getDropDown = () => (dispatch, getState) =>
{
  let data = {};
  data.country_id = getState().currentUser.data.country_id;
    axios.get('member_rebate/drop_down', {params: data})
    .then(function (response) 
    {
      dispatch (
      {
        type: type.MEMBER_STAKINGS_DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getMemberStakingDetail = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("member_stakings/details", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_STAKINGS_PAYOUT_DETAIL,
            payload: res
        })
    })
    .catch((err) => {
        // console.log("Get Referral Payout Detail Error", err);
    })
}

export const store = (data) => dispatch =>
{
    axios.post('member_stakings/submit_stakings', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: type.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getHistory = (props) => dispatch => 
{
    axios.get('member_stakings/stakings_history?', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: type.HISTORY,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};