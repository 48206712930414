import * as type from "./types";
import axios from "../../../common/config/axios";

export const getShipments = (data) => (dispatch) => {
  axios
    .get("shipment/list", {
      params: data,
    })
    .then((response) => {
      //Dispatch the current page , current limit and search value to reducers
      dispatch({
        type: type.GET_SHIPMENT_LIST,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const getDropDown = () => (dispatch) => {
  axios
    .get("shipment/drop_down")
    .then((response) => {
      dispatch({
        type: type.DROPDOWN,
        value: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const getAreaDropDown = (stateId) => (dispatch) => {
  let data = {};
  data.state_id = stateId;
  
  axios
    .get("shipment/area_dropdown", {
      params: data
    })
    .then((response) => {
      dispatch({
        type: type.GET_AREA_DROPDOWN,
        value: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const postShipment = (data) => (dispatch) => {
  axios
    .post("shipment/store", data)
    .then((response) => {
      dispatch({
        type: type.POST_SHIPMENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const showShipment = (data) => (dispatch) => {
  axios
    .get("shipment/show", {
      params: {
        id: data["shipment_id"],
      },
    })
    .then((response) => {
      dispatch({
        type: type.SHOW_SHIPMENT,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const updateShipment = (data) => (dispatch, getState) => {
  data.country_id = getState().currentUser.data.country_id;
  axios
    .post("shipment/update", data)
    .then((response) => {
      dispatch({
        type: type.UPDATE_DETAIL_SHIPMENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const deleteShipment = (data) => (dispatch) => {
  axios
    .post("shipment/delete", data)
    .then((response) => {
      dispatch({
        type: type.DELETE_SHIPMENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const getShipmentsPayment = (data) => (dispatch) => {
  axios
    .get("shipment_payment/list", {
      params: data,
    })
    .then((response) => {
      //Dispatch the current page , current limit and search value to reducers
      dispatch({
        type: type.SHIPMENT_PAYMENT,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const showShipmentDetails = (orderCode, cusId) => (dispatch) => {
  axios
    .get("shipment_payment/details" , {
      params: {
        OrderCode: orderCode,
        cus_id: cusId
      },
    })
    .then((response) => {
      //Dispatch the current page , current limit and search value to reducers
      dispatch({
        type: type.SHOW_SHIPMENT_DETAILS,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
}

export const getShipmentsPaymentDropDown = (data) => (dispatch) => {
  axios
    .get("shipment_payment/drop_down", {
      params: data,
    })
    .then((response) => {
      //Dispatch the current page , current limit and search value to reducers
      dispatch({
        type: type.SHIPMENT_PAYMENT_DROP_DOWN,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};
