import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const homeReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_COUNTRY]: (state = initialState, action) => ({
        ...state,
        country_data: action.payload
    }),
    [type.GET_MEMBER_INFO]: (state = initialState, action) => ({
        ...state,
        member_data: action.payload
    })
});

export default homeReducer;