import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getTournament = (data) => dispatch => {
    axios.get('tournament/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "lang_id": data['lang_id'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_TOURNAMENT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const postTournament = (data) => dispatch => {
    axios.post('tournament/store',data)
        .then( (response) => {
            dispatch ({
                type: type.POST_TOURNAMENT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showTournament = (data) => dispatch => {
    axios.get('tournament/show', {
        params: {
            "tournament_id": data['tournament_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_TOURNAMENT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateTournament = (data) => dispatch => {
    axios.post('tournament/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_TOURNAMENT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateTournamentStatus = (data) => dispatch => {
    axios.post('tournament/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_TOURNAMENT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteTournament = (data) => dispatch => {
    axios.post('tournament/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_TOURNAMENT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getMemberGroup = (data) => dispatch => {
    axios.get('tournament/member_group', {
        
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getType = (data) => dispatch => {
    axios.get('tournament/type', {
        
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_TYPE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const showWinner = (data) => dispatch => {
    axios.get('tournament/show/winnerlist   ', {
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_WINNERLIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};