import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

// language file
import translationEN from './en/translation.json';
import translationZHCN from './cn/translation.json';
import translationMS from './ms/translation.json';
import gamesEN from './en/games.json';
import gamesZHCN from './cn/games.json';
import gamesMS from './ms/games.json';

const resources = {
  en: {
    translation: translationEN,
    games: gamesEN,
  },
  zh_cn: {
    translation: translationZHCN,
    games: gamesZHCN,
  },
  ms: {
    translation: translationMS,
    games: gamesMS,
  }
};

i18n
.use(reactI18nextModule) // passes i18n down to react-i18next
.init({
  resources,
  lng: localStorage.getItem('lang') || "en",
  fallbackLng: "en",
  // keySeparator: false, // if do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  react: {
    wait: false
  }
});

export default i18n;