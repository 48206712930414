import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import AgentPayoutListing from './AgentPayoutListing';
import AgentPayoutDetails from './AgentPayoutDetails';
import AgentPayoutHistory from './AgentPayoutHistory';
import {clearFormValidation} from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/agentPayout/actions';

let date = new Date(), y = date.getFullYear(), m = date.getMonth();
let makeDate = new Date(y, m, 1);
makeDate.setMonth(makeDate.getMonth() - 1);

const initialTabState =
{
    /* datatables / pagination */
    activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
    limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    order: '',
    totalRecords: 0,
    searchValue: '',

    /* api result array */
    data: [],
    cpData: [],
    subData: [],
    exchangeRate: [],

    /* filter input */
    filterInput:
    {
        // date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        // date: moment().subtract(1, 'months').format('YYYY-MM'),
        date_range: moment().subtract(7, 'days').format('YYYY-MM-DD')+' - '+moment().format('YYYY-MM-DD'),
        // confirm_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),

        date: moment().subtract(1, 'months').format('YYYY-MM'),
        date_str: makeDate,
        username: '',
        member_group: '',
        confirm_date: null,
    },

    /* form input */
    formInput: {},

    /* skeleton loading */
    loading: true,

    /* validation error */
    errMsg: [],
    touched: false,
};

class AgentPayout extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'calc_payout',

            /* both tab value */
            tab1: initialTabState,
            tab2: initialTabState,

            /* both tab drop down value */
            // dropDown: {
            //     memberGroup: [],
            // },

            /* transction details parameter */
            detailsParam: {},


            /* to store bet details page param, mid, date, id */
            betDetailsParam: {},

            /* checkbox check all */
            checkAll: false,
        }
        
        this.onInputChange = this.onInputChange.bind(this);
        this.checkAllHandler = this.checkAllHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount()
    {
        let listingArr = this.getFilterArray('tab1'); // get params
        this.props.funcGetList(listingArr); // get calculate payout list
        
        listingArr = this.getFilterArray('tab2');
        this.props.funcGetHistory(listingArr); // get payout history
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;

        /* ====================== tab1 (calculate payout) [START] ====================== */
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
                let subData = (nextState.subData || []);
                let newTab = Object.assign({}, this.state.tab1);
                
                newTab['cpData'] = nextProps.rs.data.company_profit;
                newTab['cpTotal'] = nextProps.rs.data.company_profit_total;
                newTab['exchangeRate'] = nextProps.rs.data.exchange_rate;
                newTab['data'] = nextProps.rs.data.result.row;
                newTab['totalRecords'] = nextProps.rs.data.result.pagination.total_record;
                newTab['loading'] = false;

                if(nextProps.rs.data.sub_result !== null && nextProps.rs.data.sub_result.length > 0) 
                {
                    Object.values(nextProps.rs.data.sub_result).map((val) => subData[`${val.agent}`] = [] );

                    nextProps.rs.data.sub_result.forEach((val) => 
                    {
                        subData[`${val.agent}`].push(val);
                    });
                }
                newTab['subData'] = subData;

                // console.log(newTab);

                nextProps.rs.data.result.row.forEach((val, idx) =>
                {
                    if(val.profit !== 0)
                    {
                        // let payoutAmountVal = (val.profit > 0) ? val.profit : 0;
                        let payoutAmountVal = val.payout_base_currency;
                        newTab['formInput'][`payout_tbg_${val.aid}`] = parseFloat(payoutAmountVal).toFixed(2);
                        newTab['formInput'][`cb_${val.aid}`] = false;
                        // newTab['formInput'][`remark_${val.agent}`] = '';
                    }
                });
                newTab.filterInput.confirm_date = newTab.filterInput.date;
                
                this.setState({
                    tab1: newTab
                });
                // Swal.close();
            }
            else
            {
                let newTab = Object.assign({}, this.state.tab1);
                newTab['loading'] = false;
                this.setState({
                    tab1: newTab
                });
                this.filterErrorHandler(nextProps.rs.message);
            }
        }
        /* ====================== tab1 (payout) [END] ====================== */

        /* ====================== tab2 (history) [START] ====================== */
        if (nextProps.history !== this.props.history && nextProps.history!==undefined)
        {
            if(nextProps.history.responseCode === 200 && nextProps.history.msgType === 'success')
            {
                // console.log('shouldComponentUpdate :', nextProps.history.data);
                let newTab = Object.assign({}, this.state.tab2);
                newTab['data'] = nextProps.history.data.result.row;
                newTab['totalRecords'] = nextProps.history.data.result.pagination.total_record;
                newTab['loading'] = false;
                // console.log(newTab);
                this.setState({
                    tab2: newTab,
                });
                // Swal.close();
            }
            else
            {
                let newTab = Object.assign({}, this.state.tab2);
                newTab['loading'] = false;
                this.setState({
                    tab2: newTab
                });
                this.filterErrorHandler(nextProps.rs.message);
            }
        }
        /* ====================== tab2 (history) [END] ====================== */

        /* ====================== submit payout [START] ====================== */
        if (nextProps.store !== this.props.store && nextProps.store!==undefined)
        {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if(nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler('tab1');
                    clearFormValidation('agent_payout_form');
                });
            }
            else
            {
                Swal.close();
            }
            // let newTab = Object.assign({}, this.state.tab1);
            // newTab['formInput'] = {};
            this.setState({
                // tab1: newTab,
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.store.message
            });
        }
        /* ====================== submit payout [END] ====================== */

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event, tab, inputType) =>
    {
        // console.log(event.target.id+' | '+event.target.value+ ' | '+ tab+' | '+inputType);
        let newTab = Object.assign({}, this.state[tab]);
        let newFilter = Object.assign({}, this.state[tab][inputType]);

        if(event.target.id.includes('cb_'))
        {
            let checkVal = (event.target.checked) ? true : false;
            newFilter[event.target.id] = checkVal;
        }
        else
        {
            newFilter[event.target.id] = event.target.value;
        }

        if(event.target.id.includes('payout_tbg_'))
        {
            const splitedValue = event.target.value.split('.')
            if(splitedValue[1] && splitedValue[1].length > 2)
            {
                let val = parseFloat(event.target.value).toFixed(2);
                newFilter[event.target.id] = val;
            }
        }
        newTab[inputType] = newFilter;
        this.setState({
            [tab]: newTab
        });
    }

    handleMonthPickerChange = (date) => 
    {    
        // console.log(date);
        let tab = 'tab1';
        let newTab = Object.assign({}, this.state[tab]);
        let newFilter = Object.assign({}, this.state[tab]['filterInput']);
        newFilter['date_str'] = date;
        newFilter['date'] = moment(date).format('YYYY-MM')
        newTab['filterInput'] = newFilter;
        
        this.setState({
            [tab]: newTab,
        });
    }

    changeTabHandler = (e) =>
    {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            pageShow: e.target.id
        });
    }

    searchHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        // newFilter['activePage'] = 1;
        newFilter['formInput'] = {};
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    clearHandler = (tab) =>
    {
        this.setState({
            [tab]: initialTabState,
            checkAll: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });
    }

    filterErrorHandler = (message) =>
    {
        const { t } = this.props;
        let msg = null;
        msg = '<ul>';
        message.forEach((val) => {
            msg += '<li>'+val+'</li>';
        });
        msg += '</ul>';

        Swal.fire({
            icon: 'error',
            title: t('global.error'),
            html: msg,
            showConfirmButton: true
        });
    }

    betDetailsHandler = (e, aid, date, orderId) =>
    {
        // console.log('betDetailsHandler: '+aid+' | '+date+' | '+orderId);
        e.preventDefault();
        this.setState({
            pageShow: 'betDetails',
            betDetailsParam :
            {
                aid: aid,
                date: date, 
                orderId: orderId,
                returnPage: (orderId !== null) ? 'history' : 'calc_payout'
            }
        });
    }

    orderDetailsHandler = (e, id) =>
    {
        // console.log('detailsHandler : ', id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    refreshHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['formInput'] = {};
        newFilter['data'] = [];
        newFilter['cpData'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    getFilterArray = (tab) =>
    {
        // this.loadingHandler();
        const listingArr = {};

        let availablePage = this.state[tab].activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state[tab].activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        // let dateVal = (this.state[tab].filterInput.date !== null) ? moment(this.state[tab].filterInput.date).format('YYYY-MM') : moment().subtract(1, 'months').format('YYYY-MM') ;

        // listingArr.page = this.state[tab].activePage;
        listingArr.page = (this.state[tab].activePage >= availablePage) ? availablePage : this.state[tab].activePage;
        listingArr.limit = this.state[tab].limit;
        listingArr.search = this.state[tab].searchValue;
        listingArr.order = JSON.stringify(this.state[tab].order);
        listingArr.username = this.state[tab].filterInput.username;
        listingArr.date = this.state[tab].filterInput.date;
        listingArr.date_range = this.state[tab].filterInput.date_range;
        listingArr.country_id = this.props.currentUser.country_id;

        // console.log(listingArr);
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            checkAll: false,
        });
    }

    checkAllHandler = (event, tab, inputType) =>
    {
        if(this.props.currentUser.permission.includes('agent_payout_submit'))
        {
            let checkVal = (event.target.checked) ? true : false;
            let newTab = Object.assign({}, this.state[tab]);
            let newFilter = Object.assign({}, this.state[tab][inputType]);
            // console.log(newFilter);
            for (const key in newFilter)
            {
                if(key.includes('cb_'))
                {
                    var myEle = document.getElementById(key);
                    newFilter[key] = false;
                    if(myEle)
                    {
                        newFilter[key] = checkVal;
                    }
                }
            }
            // console.log(newFilter);
            newTab[inputType] = newFilter;
            this.setState({
                [tab]: newTab,
                checkAll: checkVal,
            });
        }
    }

    submitHandler = (e, tab) =>
    {
        e.preventDefault();
        let newFilter = Object.assign({}, this.state[tab].formInput);
        let data = {};
        let length = 0;

        for (const key in newFilter)
        {
            if(key.includes('cb_'))
            {
                if(newFilter[key]===true)
                {
                    let aid = key.split('_');
                    data[`payout_tbg_${aid[1]}`] = newFilter[`payout_tbg_${aid[1]}`];
                    // data[`remark_${aid[1]}`] = newFilter[`remark_${aid[1]}`];
                    // data[`payout_lrd_${aid[1]}`] = newFilter[`payout_lrd_${aid[1]}`];
                    length++;
                }
            }
        }
        if (length > 0)
        {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You wont be able to revert this!',
                icon: 'warning',
                confirmButtonText: 'Save',
                showDenyButton: true,
                denyButtonText: 'Cancel',
            }).then((result) =>
            {
                if (result.isConfirmed)
                {
                    this.loadingHandler();
                    data['country_id'] = this.props.currentUser.country_id;
                    data['lang_id'] = this.props.currentUser.language_id;
                    data['date'] = this.state[tab].filterInput.confirm_date;
                    // console.log(data);
                    this.props.funcStore(data);
                }
            });
        }
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, tab, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+tab+' | '+act);
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter[act] = (act==='searchValue') ? val.target.value : val;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    onSort = (field, order, tab) =>
    {
        // console.log(field+' | '+order+' | '+tab);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['order'] = orderArry;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        // console.log(this.state.tab1.filterInput.confirm_date);
        const { t } = this.props;
        let tabId = this.state.pageShow;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                {(this.state.pageShow==='calc_payout' || this.state.pageShow==='history') &&
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='panel panel-primary tabs-style-2 mt-3'>
                                    <div className='tab-menu-heading'>
                                        <div className='tabs-menu1'>
                                            <ul className='nav panel-tabs main-nav-line'>
                                                <li><a href='/#' id='calc_payout' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'calc_payout') ? 'active' : ''}`}>{t('agent-payout.payout')}</a></li>
                                                    {permission.includes('agent_payout_history') && 
                                                        <li><a href='/#' id='history' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'history') ? 'active' : ''}`}>{t('agent-payout.payout-history')}</a></li>
                                                    }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>
                }

                {this.state.pageShow==='calc_payout' &&
                    <AgentPayoutListing
                        tabName='tab1'
                        data={this.state.tab1}
                        checkAll={this.state.checkAll}
                        // ddMemberGroup={this.state.dropDown.memberGroup}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        checkAllHandler={(event, tab, inputType) => this.checkAllHandler(event, tab, inputType)}
                        submitHandler={(event, tab) => this.submitHandler(event, tab)}
                        betDetailsHandler={(event, mid, date, id) => this.betDetailsHandler(event, mid, date, id)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        handleMonthPickerChange ={(date) => this.handleMonthPickerChange (date)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        touched={this.state.touched}
                        errMsg={this.state.errMsg}
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='history' &&
                    <AgentPayoutHistory
                        tabName='tab2'
                        data={this.state.tab2}
                        // ddMemberGroup={this.state.dropDown.memberGroup}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        betDetailsHandler={(event, mid, date, id) => this.betDetailsHandler(event, mid, date, id)}
                        orderDetailsHandler={(event, id) => this.orderDetailsHandler(event, id)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='betDetails' &&
                    <AgentPayoutDetails
                        betDetailsParam={this.state.betDetailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.agentPayout.rs,
        history: state.agentPayout.history,
        store: state.agentPayout.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetHistory: (filter) => dispatch(actions.getHistory(filter)),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(AgentPayout));