import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import StringInput from '../../components/Input/StringInput/StringInput';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import Button from '../../components/Button/Button';
import UpdownArrow from "../../assets/images/updown-arrow.png";
class TotalDepositTurnover extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        }
        // console.log('constructor (listing):', this.state);
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = ((this.props.data.activePage - 1) * this.props.data.limit) + (rowIndex + 1);
        //console.log(this.props.data)
        let rowNumber = (this.props.data.totalRecords - rowIndex - ((this.props.data.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard
        })
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsData = [];
        let upLvlStatus = [{ id: 0, desc: t('up-level-bonus.level-maintain') }, { id: 1, desc: t('up-level-bonus.level-up') }, { id: 2, desc: t('up-level-bonus.level-down') }];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns = [
            { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
            { text: t('up-level-bonus.member'), dataField: 'username', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.current-level'), dataField: 'current_level', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.next-level'), dataField: 'next_level', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.last-month-deposit'), dataField: 'last_1_mth_deposit', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.last-month-turnover'), dataField: 'last_1_mth_turnover', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.last-3-month-deposit'), dataField: 'last_3_mth_deposit', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.last-3-month-turnover'), dataField: 'last_3_mth_turnover', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t('up-level-bonus.up-level-status'), dataField: 'up_lvl_status', headerStyle: { width: "115px" }, },
        ];

        if (this.props.data.data) {
            rsData = this.props.data.data.map((val, idx) => {
                let stVal = (val.up_lvl_status === 0) ? val.current_lvl : ((val.up_lvl_status === 1) ? val.next_lvl : val.current_lvl - 1);
                let stCls = (val.up_lvl_status === 0) ? 'secondary' : ((val.up_lvl_status === 1) ? 'success' : 'danger');
                let stIcon = (val.up_lvl_status === 0) ? <><FontAwesomeIcon icon={faCaretLeft} /> <FontAwesomeIcon icon={faCaretRight} /></> : ((val.up_lvl_status === 1) ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />);
                let lvlStVal = <span className={`badge badge-pill badge-${stCls}`}>{stIcon}&nbsp;VIP {stVal}</span>;

                return {
                    no: idx,
                    username: val.username,
                    current_level: `VIP ${val.current_lvl}`,
                    next_level: `VIP ${val.next_lvl}`,
                    last_1_mth_deposit: new Intl.NumberFormat('en-US', num_format).format(val.last_1_mth_deposit),
                    last_1_mth_turnover: new Intl.NumberFormat('en-US', num_format).format(val.last_1_mth_turnover),
                    last_3_mth_deposit: new Intl.NumberFormat('en-US', num_format).format(val.last_3_mth_deposit),
                    last_3_mth_turnover: new Intl.NumberFormat('en-US', num_format).format(val.last_3_mth_turnover),
                    up_lvl_status: lvlStVal,
                };
            });
        }
        return (
            <Auxiliary>
                <div className='row row-sm'>
                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div>
                                {this.props.data.loading
                                    ?
                                    <Skeleton count={2} />
                                    :
                                    <div method='post' id='filter_form'
                                        className={`form-horizontal filter-function row
                            ${this.state.showCard ? 'open' : 'close'} filter-wrapper-2`}>
                                        {/* <div className='row'> */}
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('up-level-bonus.date')}</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'><i className='fas fa-calendar-check'></i></span>
                                                    </div>
                                                    <DateTimePicker
                                                        id='date'
                                                        name='date'
                                                        className='form-control filter-input'
                                                        format='YYYY-MM-DD'
                                                        singleDatePicker={true}
                                                        autoUpdateInput={true}
                                                        value={this.props.data.filterInput.date}
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('up-level-bonus.member')}</label>
                                                <StringInput
                                                    id='username'
                                                    name='username'
                                                    value={this.props.data.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                    className='form-control filter-input'
                                                    onSearchPress={(evt) => evt.key === 'Enter' && this.props.refreshHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('up-level-bonus.up-level-status')}</label>
                                                <div className='select-input'>
                                                    <select className='form-control filter-input' id='status' name='status' onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')} value={this.props.data.filterInput.status}>
                                                        <option value=''></option>
                                                        {
                                                            upLvlStatus.map((val, idx) => {
                                                                return (<option key={idx} value={val.id}>{val.desc}</option>);
                                                            })
                                                        }
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className='col mt-auto'>
                                            <div>
                                                <Button typeButton='button' classValue='btn btn-blue' idValue='filter' buttonName={t('global.filter')} clicked={() => this.props.refreshHandler(this.props.tabName)} />
                                                &nbsp;<Button typeButton='button' classValue='btn btn-secondary' idValue='reset' buttonName={t('global.reset')} clicked={() => this.props.clearHandler(this.props.tabName)} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="show-btn"
                                onClick={() => this.toggleCard()}>
                                {
                                    this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>{t('up-level-bonus.total-deposit-and-turnover')}</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    <DataTable
                                        keyField='no'
                                        data={rsData}
                                        columns={columns}
                                        activePage={this.props.data.activePage}
                                        limit={this.props.data.limit}
                                        totalItems={this.props.data.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'activePage')}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'limit')}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'searchValue')}
                                        searchValue={this.props.data.searchValue}
                                        classValue='dataTable table-bordered text-justify text-center'
                                        loading={this.props.data.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(TotalDepositTurnover);