export const MEMBER_LIST = "MEMBER_LIST";
export const MEMBER_GET_FILTER_DATA = "MEMBER_GET_FILTER_DATA";

export const MEMBER_CREATE = "MEMBER_CREATE";
export const MEMBER_STORE = "MEMBER_STORE";

/*** MEMBER VIEW ***/
export const MEMBER_SHOW = "MEMBER_SHOW";
export const MEMBER_TRANSACTION_HISTORY = "MEMBER_TRANSACTION_HISTORY";
export const MEMBER_TRANSACTION_HISTORY_FILTER_DATA = "MEMBER_TRANSACTION_HISTORY_FILTER_DATA";
export const MEMBER_BETTING_HISTORY = "MEMBER_BETTING_HISTORY";
export const MEMBER_BETTING_HISTORY_FILTER_DATA = "MEMBER_BETTING_HISTORY_FILTER_DATA";
export const MEMBER_DEPOSIT_CRYPTO = "MEMBER_DEPOSIT_CRYPTO";
export const MEMBER_WITHDRAWAL_CRYPTO = "MEMBER_WITHDRAWAL_CRYPTO";
export const MEMBER_LOGIN_INFO = "MEMBER_LOGIN_INFO";
export const MEMBER_LEVEL_TRACKER = "MEMBER_LEVEL_TRACKER";
export const MEMBER_LEVEL_TRACKER_HISTORY = "MEMBER_LEVEL_TRACKER_HISTORY";
export const MEMBER_WALLET_TRACKER = "MEMBER_WALLET_TRACKER";
export const MEMBER_COIN_TRACKER = "MEMBER_COIN_TRACKER";
export const MEMBER_ADDRESS = "MEMBER_ADDRESS";
export const MEMBER_PARCEL = "MEMBER_PARCEL";
export const MEMBER_BILLING_ADDRESS = "MEMBER_BILLING_ADDRESS";

// For Initial State for specific page
export const GET_MEMBER_INFO_LOCAL_STATE = "GET_MEMBER_INFO_LOCAL_STATE";
export const GET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE = "GET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE";
export const GET_MEMBER_BETTING_HISTORY_LOCAL_STATE = "GET_MEMBER_BETTING_HISTORY_LOCAL_STATE";
export const GET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE = "GET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE";
export const GET_MEMBER_LOGIN_INFO_LOCAL_STATE = "GET_MEMBER_LOGIN_INFO_LOCAL_STATE";
export const GET_MEMBER_LEVEL_TRACKER_LOCAL_STATE = "GET_MEMBER_LEVEL_TRACKER_LOCAL_STATE";
export const GET_MEMBER_WALLET_TRACKER_LOCAL_STATE = "GET_MEMBER_WALLET_TRACKER_LOCAL_STATE";
export const GET_MEMBER_COIN_TRACKER_LOCAL_STATE = "GET_MEMBER_COIN_TRACKER_LOCAL_STATE";
export const GET_MEMBER_ADDRESS_LOCAL_STATE = "GET_MEMBER_ADDRESS_LOCAL_STATE";

// For Update State when initial run the constructor component unmount
export const INIT_MEMBER_SHOW_INITIAL_LOADING = "INIT_MEMBER_SHOW_INITIAL_LOADING";
export const SET_MEMBER_SHOW_INITIAL_LOADING = "SET_MEMBER_SHOW_INITIAL_LOADING";

export const SET_MEMBER_INFO_LOCAL_STATE = "SET_MEMBER_INFO_LOCAL_STATE";
export const SET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE = "SET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE";
export const SET_MEMBER_BETTING_HISTORY_LOCAL_STATE = "SET_MEMBER_BETTING_HISTORY_LOCAL_STATE";
export const SET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE = "SET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE";
export const SET_MEMBER_LOGIN_INFO_LOCAL_STATE = "SET_MEMBER_LOGIN_INFO_LOCAL_STATE";
export const SET_MEMBER_LEVEL_TRACKER_LOCAL_STATE = "SET_MEMBER_LEVEL_TRACKER_LOCAL_STATE";
export const SET_MEMBER_WALLET_TRACKER_LOCAL_STATE = "SET_MEMBER_WALLET_TRACKER_LOCAL_STATE";
export const SET_MEMBER_COIN_TRACKER_LOCAL_STATE = "SET_MEMBER_COIN_TRACKER_LOCAL_STATE";
export const SET_MEMBER_ADDRESS_LOCAL_STATE = "SET_MEMBER_ADDRESS_LOCAL_STATE";
export const SET_MEMBER_PARCEL_LOCAL_STATE = "SET_MEMBER_PARCEL_LOCAL_STATE";

/*** MEMBER EDIT ***/
export const MEMBER_EDIT = "MEMBER_EDIT";
export const MEMBER_EDIT_ACCOUNT_DETAILS = "MEMBER_EDIT_ACCOUNT_DETAILS";
export const MEMBER_EDIT_BANK_DETAILS = "MEMBER_EDIT_BANK_DETAILS";
export const MEMBER_WALLET_BALANCE = "MEMBER_WALLET_BALANCE";
export const MEMBER_UPDATE_ACCOUNT_DETAILS = "MEMBER_UPDATE_ACCOUNT_DETAILS";
export const MEMBER_ADD_BANK_DETAILS = "MEMBER_ADD_BANK_DETAILS";
export const MEMBER_UPDATE_MEMBER_BANK_STATUS = "MEMBER_UPDATE_MEMBER_BANK_STATUS";
export const MEMBER_STORE_WALLET_ADJUSTMENT = "MEMBER_STORE_WALLET_ADJUSTMENT";
export const MEMBER_UPDATE_RESET_PASSWORD = "MEMBER_UPDATE_RESET_PASSWORD";

// export const MEMBER_SHOW_CLEAR_ALL = "MEMBER_SHOW_CLEAR_ALL";
// export const MEMBER_EDIT_CLEAR_ALL = "MEMBER_EDIT_CLEAR_ALL";
export const CLEAR_MEMBER_STATE = "CLEAR_MEMBER_STATE";
export const MEMBER_GAME_ACCESS = "MEMBER_GAME_ACCESS";
export const MEMBER_UPDATE_GAME_ACCESS = "MEMBER_UPDATE_GAME_ACCESS";
