import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import FloatingImageListing from "./FloatImageListing";
import FloatImageCreate from "./FloatImageCreate";
import FloatImageShow from "./FloatImageShow";
import FloatImageEdit from "./FloatImageEdit";
import {getFloatImage, updateFloatingImageStatus, deleteFloatingImage} from "../../store/ducks/floatImage/actions";
import Select from "../../components/Input/Select/Select";

class FloatImage extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        currentFloatImageId: '',
        loading: true,
        currentImage : '',

        permission: []
    }

    fetchData(){
        const floatingImageData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        floatingImageData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        floatingImageData.limit = this.state.limit;
        floatingImageData.order = JSON.stringify(this.state.sorted);
        floatingImageData.lang_id = this.props.currentLanguageId;
        floatingImageData.country_id = this.props.currentCountryId;

        this.props.getFloatImage(floatingImageData);
    }

    componentDidMount() {
       this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let floatImagePermission = this.props.currentPermission.filter((item) => item.includes("float_image_"));
            this.setState({permission: floatImagePermission});
        }

        if (nextProps.float_image_data !== this.props.float_image_data) {
            if(nextProps.float_image_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.float_image_data.data.data,
                    totalRecords: nextProps.float_image_data.data.totalRecords,
                    totalPages: nextProps.float_image_data.data.totalPages,
                })
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.float_image_data.data.message[0],
                    icon: nextProps.float_image_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.delete_floating_image_result !== this.props.delete_floating_image_result) {
            if(nextProps.delete_floating_image_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-deleted"),
                    icon: nextProps.delete_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.delete_floating_image_result.message[0],
                    icon: nextProps.delete_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.update_status_floating_image_result !== this.props.update_status_floating_image_result) {
            if(nextProps.update_status_floating_image_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_status_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_status_floating_image_result.message[0],
                    icon: nextProps.update_status_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }


        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    updateStatusFloatingImageHandler = (event, floatingImageId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updateFloatingImageStatusData = {};
                updateFloatingImageStatusData.floating_id = floatingImageId;
                updateFloatingImageStatusData.status = Number(event.target.value);

                this.props.updateFloatingImageStatus(updateFloatingImageStatusData);
            }
        });
    }

    deleteFloatingImageHandler = (e, floating_id) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deleteFloatingImageData = {};
                deleteFloatingImageData.id = floating_id;

                this.props.deleteFloatingImage(deleteFloatingImageData);
            }
        });
    }


    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("float_image_change_status")) {

            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="floatingImageStatus"
                    name="floatingImageStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.floating_id}`}
                    onChange={(event) => {
                        this.updateStatusFloatingImageHandler(event, row.floating_id)
                    }}
                    className={dtClassName}
                />
            )
        }else{
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        if (this.state.permission.includes("float_image_show")) {
            viewButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-blue mr-2"
                    buttonName={<FontAwesomeIcon icon={faSearch}/>}
                    key={`view-${row.floating_id}`}
                    clicked={() => this.changePageHandler('show', row.floating_id)}
                />;
        }

        if (this.state.permission.includes("float_image_update")) {
            editButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-info mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen}/>}
                    key={`edit-${row.floating_id}`}
                    clicked={() => this.changePageHandler('edit', row.floating_id)}
                />;
        }

        if (this.state.permission.includes("float_image_delete")) {
            deleteButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-danger mr-2"
                    clicked={(event) => this.deleteFloatingImageHandler(event, row.floating_id)}
                    key={`delete-${row.floating_id}`}
                    buttonName={<FontAwesomeIcon icon={faTrash}/>}
                />;
        }

        output = [viewButton, editButton, deleteButton];
        return output;
    }

    changePageHandler = (page, floatingImageId) => {
        this.setState({
            pageShow: page,
            currentFloatImageId: floatingImageId
        });
    }

    refreshHandler = () => {
       this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    desktopImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`desktop-image-${row.floating_id}`}
            />
        );
    }

    mobileImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`mobile-image-${row.floating_id}`}
            />
        );
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Floating Image Id",
                dataField: "floating_id",
                keyField: 'floatingImageId',
                hidden: true
            },
            {
                text: t('float-image.desktop-image'),
                dataField: "desktop_image",
                keyField: "desktopImage",
                formatter: this.desktopImageFormatter
            },
            {
                text: t('float-image.mobile-image'),
                dataField: "mobile_image",
                keyField: "mobileImage",
                formatter: this.mobileImageFormatter
            },
            {
                text: t('float-image.start-time'),
                dataField: "start_time",
                keyField: "startTime"
            },
            {
                text: t('float-image.end-time'),
                dataField: "end_time",
                keyField: "endTime"
            },
            {
                text: t('global.status'),
                dataField: "status",
                keyField: "status",
                formatter: this.statusFormatter
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    {this.state.isOpen && (
                        <Lightbox
                            mainSrc={this.state.currentImage}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )}
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('float-image.float-image')}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div className="mb-3 mb-xl-0">
                                <div className="btn-group">
                                    {this.state.permission.includes('float_image_store') &&
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="btnCreateBankAccount"
                                        buttonName={t('float-image.add-float-image')}
                                        clicked={() => this.changePageHandler('create')}/>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <FloatingImageListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <FloatImageCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <FloatImageShow
                    show_floating_image_id={this.state.currentFloatImageId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <FloatImageEdit
                    show_floating_image_id={this.state.currentFloatImageId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {floatImage, currentUser} = state;

    return {
        float_image_data: floatImage.floating_image_data,
        update_status_floating_image_result: floatImage.update_status_floating_image_result,
        delete_floating_image_result: floatImage.delete_floating_image_result,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        currentPermission: currentUser.data.permission
    }
};

const mapDispatchToProps = {
    updateFloatingImageStatus,
    getFloatImage,
    deleteFloatingImage
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(FloatImage));