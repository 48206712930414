import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
  loading: false,
  data: [],
};

const sensitiveGoodReturnReducer = createReducer(initialState)({
  [type.GET_SENSITIVE_GOOD_RETURN_LIST]: (state = initialState, action) => ({
    ...state,
    sensitive_good_return_data: action.payload,
  }),
  [type.GET_MEMBERS]: (state, action) => ({
    ...state,
    get_members: action.value,
  }),
  [type.POST_SENSITIVE_GOOD_RETURN]: (state, action) => ({
    ...state,
    store_sensitive_good_return: action.payload,
  }),
  [type.SHOW_SENSITIVE_GOOD_RETURN]: (state, action) => ({
    ...state,
    show_sensitive_good_return: action.payload,
  }),
  [type.UPDATE_SENSITIVE_GOOD_RETURN]: (state, action) => ({
    ...state,
    update_sensitive_good_return: action.payload,
  }),
  [type.DELETE_SENSITIVE_GOOD_RETURN]: (state, action) => ({
    ...state,
    delete_sensitive_good_return: action.payload,
  }),
});

export default sensitiveGoodReturnReducer;
