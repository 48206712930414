import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle } from "react-icons/ai";
import UpdownArrow from "../../assets/images/updown-arrow.png";
class EmailContentListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("email-content.email-content")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div >
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form method="post" id="filter_member_form" className="form-horizontal filter-function row">
                                        {/* <div className="row"> */}

                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label htmlFor="filter_language" className="font-weight-bolder">
                                                    {t("global.languages")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="filter_language"
                                                        name="filter_language"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.filter_language}>
                                                        <option value=""></option>
                                                        {this.props.ddLanguage}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("email-content.email-content-listing")}</h4>
                                    </div>
                                </div>
                                {this.props.permission.includes("email_content_create") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    idValue="agent"
                                                    classValue="btn btn-underline"
                                                    clicked={() => this.props.changePageHandler("create")}
                                                    buttonName={t("email-content.add-email-content")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="emailContentId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(EmailContentListing);
