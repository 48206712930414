import React, { Component } from "react";
import { connect } from "react-redux";
import { addVoucherMember, getVoucherMember, addSpecificVoucherMember, deleteMember } from "../../store/ducks/voucher/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import DataTable from "../../components/Datatable/DataTable";
import VoucherMemberSample from "../../assets/sample/voucher_member.csv";

const initialInputState = {
	email: ""
};

class VoucherMember extends Component {
    constructor(props) {
        // const { t } = props;

        super(props);
        this.state = {
            touched: false,
            error_message: [],
            uploadFile: null,
            voucherMemberArray: [],

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,
            searchValue: initialInputState,
            search: "",
            downloadUrl: "",
            loading: true
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation("voucher_form");

        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.getRs !== this.props.getRs && nextProps.getRs !== undefined) {
            if (nextProps.getRs.responseCode === 200 && nextProps.getRs.msgType === 'success'){
                this.setState({
                    loading: false,
                    voucherMemberArray: nextProps.getRs.data.voucherMemberData.row,
                    totalPages: nextProps.getRs.data.voucherMemberData.pagination.total_page,
                    activePage: nextProps.getRs.data.voucherMemberData.pagination.current_page,
                    totalRecords: nextProps.getRs.data.voucherMemberData.pagination.total_record,
                    downloadUrl: nextProps.getRs.data.downloadUrl
                });
            }else{
                this.setState({
                    loading: false,
                }, () => {
                    filterErrorHandler(nextProps.getRs.message);
                });
                Swal.close();
            }
        }

        if (nextProps.addRs !== this.props.addRs && nextProps.addRs !== undefined) {
            if (nextProps.addRs.responseCode === 200 && nextProps.addRs.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.addRs.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.addRs.message,
                });
                Swal.close();
            }
        }

        if (nextProps.add_specific_voucher_member !== this.props.add_specific_voucher_member && nextProps.add_specific_voucher_member !== undefined) {
            if (nextProps.add_specific_voucher_member.responseCode === 200 && nextProps.add_specific_voucher_member.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.add_specific_voucher_member.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            loading: true,
                            voucherMemberArray: [],
                            searchValue: {
                                ...this.state.searchValue,
                                email: ""
                            },
                        }, () => {
                            this.fetchData();
                        })  
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.add_specific_voucher_member.message,
                });
                Swal.close();
            }
        }

        if (nextProps.delete_voucher_member !== this.props.delete_voucher_member && nextProps.delete_voucher_member !== undefined) {
            if (nextProps.delete_voucher_member.responseCode === 200 && nextProps.delete_voucher_member.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.delete_voucher_member.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            loading: true,
                            voucherMemberArray: [],
                            searchValue: {
                                ...this.state.searchValue,
                                email: ""
                            },
                        }, () => {
                            this.fetchData();
                        })  
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.delete_voucher_member.message,
                });
                Swal.close();
            }
        }

        return true;
    }

    storeHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        if (!(this.state.uploadFile)) {
            Swal.fire({
                title: t("global.error"),
                icon: "error",
                html: t('voucher.select_file'),
                showConfirmButton: true,
                confirmButtonText: t("global.ok")
            });
        } else {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.save"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });              
    
                    let formData = new FormData();
                    formData.append("id",this.props.voucherID);
                    formData.append("uploadFile[]", this.state.uploadFile);

                    this.props.addVoucherMember(formData);

                    this.setState({
                        uploadFile: null
                    });
                }
            });
        }
    };

    addMemberHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });              
    
                let formData = new FormData();
                formData.append("id", this.props.voucherID);
                formData.append("email", this.state.searchValue.email);

                this.props.addSpecificVoucherMember(formData);
            }
        });
    };

    clearAllMember = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });              
    
                let formData = new FormData();
                formData.append("id",this.props.voucherID);

                this.props.deleteMember(formData);
            }
        });
    };

    onInputChange = (event) => {
        this.setState({
            uploadFile: event.target.files[0]
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
        return <span>{rowNumber}</span>;
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                voucherMemberArray: [],
            },
            () => {
                this.fetchData();
            }
        );
    };
    
    downloadHandler = () => {
        const link = document.createElement('a');
        link.href = VoucherMemberSample;
        link.setAttribute('download', 'voucher_member.csv');
        document.body.appendChild(link);
        link.click();
    }

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        listingArr.id = this.props.voucherID;
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        
        Object.keys(this.state.searchValue).forEach((val) => {
            listingArr[val] = this.state.searchValue[val];
        });

		this.props.getVoucherMember(listingArr);

        return listingArr;
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            voucherMemberArray: [],
        }, ()=> {
			this.fetchData();
        });
    };

    onFilterEmail = (e) => {
        let newFilter = Object.assign({}, this.state.searchValue);
		newFilter[e.target.id] = e.target.value;
		
		this.setState({
			searchValue: newFilter,
		});
    }

    render() {
        let dataListing = [];
        const { t } = this.props;

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('voucher.email'), dataField: "email"},
            {text: t('voucher.updated_by'), dataField: "adminUsername"}
        ];

        if (this.state.voucherMemberArray.length > 0) {
            dataListing = this.state.voucherMemberArray.map((val, idx) => {                
                return {
                    email: val.email,
                    adminUsername: val.adminUsername
                };
            });
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("voucher.voucher")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{this.props.voucherCode} - {t("voucher.add_voucher_member")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="voucher_form" className="form-horizontal" onSubmit={this.storeHandler} noValidate>
                                    <ValidationError formName="voucher_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input
                                                    id='file'
                                                    name='file'
                                                    type='file'
                                                    onChange={this.onInputChange}
                                                    className="filter-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("voucher.email")}
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <StringInput
                                                        id="email"
                                                        name="email"
                                                        value={this.state.searchValue.email}
                                                        onChange={this.onFilterEmail}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" && this.refreshHandler()
                                                        }
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-blue ml-2"
                                                        idValue="submit"
                                                        buttonName={t("global.add")}
                                                        clicked={(e) => this.addMemberHandler(e)}
                                                    />          
                                                </div>                                                                                          
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            {(()=>{
                                                if(this.state.downloadUrl){
                                                    return <a href={this.state.downloadUrl}>
                                                        <Button typeButton="button" classValue="btn btn-secondary ml-1" idValue="download" buttonName={t("voucher.download")} />
                                                    </a>
                                                }
                                                else{
                                                    return <Button typeButton="button" classValue="btn btn-secondary ml-1" idValue="download" buttonName={t("voucher.download")} clicked={this.downloadHandler} />
                                                }
                                            })()}

                                            <Button typeButton="button" classValue="btn btn-blue" idValue="filter" buttonName={t("global.filter")} clicked={() => this.refreshHandler() } />
                                            <Button typeButton="button" classValue="btn btn-blue" idValue="clear-all" buttonName={t("global.clear-all")} clicked={(e) => this.clearAllMember(e) } />
                                        </div>
                                    </div>
                                    
                                </form>

                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="systemSettingsId"
                                        data={dataListing}
                                        columns={columns}
                                        activePage={this.state.activePage}
                                        limit={this.state.limit}
                                        totalItems={this.state.totalRecords}
                                        paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.state.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getRs: state.voucher.getVoucherMember,
        addRs: state.voucher.addVoucherMember,
        delete_voucher_member: state.voucher.delete_voucher_member,
        add_specific_voucher_member: state.voucher.add_specific_voucher_member
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addVoucherMember: (data) => dispatch(addVoucherMember(data)),
        getVoucherMember: (data) => dispatch(getVoucherMember(data)),
        addSpecificVoucherMember: (data) => dispatch(addSpecificVoucherMember(data)),
        deleteMember: (data) => dispatch(deleteMember(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VoucherMember));
