import React, { Component } from "react";
import { connect } from "react-redux";
// import { getSettings, deleteSetting, updateStatus, getDropdown } from "../../store/ducks/goldDigger/actions";
import { getSettings } from "../../store/ducks/angpauSetting/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import AngpauSettingListing from "./AngpauSettingListing";
import AngpauSettingCreate from "./AngpauSettingCreate";
import AngpauSettingEdit from "./AngpauSettingEdit";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

class AngpauSetting extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            settingID: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getSettings(listingArr);
        // this.props.getDropdown();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
            this.setState({
                data: nextProps.rs.data.data.result.row,
                totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                totalPages: nextProps.rs.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        if (nextProps.dropdown !== this.props.dropdown && nextProps.dropdown !== undefined && nextProps.dropdown.status === 200) {
            let gameTypeDropdown = [];
            let allGameTypeDropdown = [];

            if (nextProps.dropdown.data.data.gameType !== undefined) {
                nextProps.dropdown.data.data.gameType.map((val) => {
                    gameTypeDropdown.push({
                        label: t('game-type.'+val),
                        value: val
                    });
                    return null;
                });
            }

            if (nextProps.dropdown.data.data.allGameType !== undefined) {
                nextProps.dropdown.data.data.allGameType.map((val) => {
                    allGameTypeDropdown.push({
                        label: t('game-type.'+val),
                        value: val
                    });
                    return null;
                });
            }
            
            this.setState({
                dropdown: {
                    gameType: gameTypeDropdown,
                    allGameType: allGameTypeDropdown,
                    settingName: nextProps.dropdown.data.data.settingName
                },
                loading: false,
            });
        }
        
        if (nextProps.updateStatus !== this.props.updateStatus && nextProps.updateStatus!==undefined)
        {
            let success = (nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success') ? true : false;
            if(nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                });
            }
            else
            {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.updateStatus.message
            });
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getSettings(listingArr);
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            settingID: id,
            pageShow: "edit",
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSettings(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSettings(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, output;

        if (this.state.permission.includes("angpau_setting_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.settingID}`}
                    idValue={row.id === undefined ? 0 : row.settingID}
                    clicked={(event) => this.editHandler(event, row.settingID)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    };

    settingValueFormatter = (cell, row) => {
        const { t } = this.props;
        let settingValue = Object.entries(row.data);
        
        if(row.slug === 'auto-rebate') {
            if(settingValue[0][0] === 'active' && (settingValue[0][1] === '0' || settingValue[0][1] === '1')) {
                let status = settingValue[0][1];
                let stCls = status === '1' ? "active" : "inactive";
                return <select
                        className={`form-control datatable-select-${stCls}`}
                        id={row.settingID}
                        name={row.settingID}
                        onChange={(event) =>
                            this.updateStatusHandler(event)
                        }
                        value={status === null ? 0 : status}
                    >
                        <option value="1">{t("global.active")}</option>
                        <option value="0">{t("global.inactive")}</option>
                    </select>
            }
        }
        return settingValue.map((item, idx) => (
            <span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>
                {item[0]} : {item[1]}
            </span>
        ));
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.fetchData();
            this.props.getSettings(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.fetchData();
                this.props.getSettings(listingArr);
            });
        }
    };

    updateStatusHandler = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let data = {};
        data.id = event.target.id;
        data.key = ['active'];
        data.value = [event.target.value];
        // console.log(data);
        this.props.updateStatus(data);
    }

    /* ============ Datatables Function [END] ============ */

    render() {
        // console.log('render (agent): ', this.state);`
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('angpau.slug'), dataField: "slug", onSort: this.onSort},
            {text: t('angpau.angpau-start-time'), dataField: "start_date", sort: true, onSort: this.onSort},
            {text: t('angpau.angpau-end-time'), dataField: "end_date", sort: true, onSort: this.onSort},
            {text: t('angpau.angpau-setting'), dataField: "value", sort: false},
            {text: t('angpau.updated-time'), dataField: "updated_at", sort: false},
            {text: t('angpau.updated-by'), dataField: "update_by", sort: false},
            {text: t('global.status'), dataField: "status", sort: false},
            {text: t('global.action'), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: '65px' }},
        ];


        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    settingID: val.id,
                    slug: val.slug,
                    start_date: val.start_date,
                    end_date: val.end_date,
                    value: val.value,
                    updated_at: val.updated_at,
                    update_by: val.update_by,
                    status: <span className={`badge-pill badge-${val.status === 1 ? "success" : "danger"}`}>{val.status && val.status === 1 ? t("global.active") : t("global.inactive")}</span>
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <AngpauSettingListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("angpau_setting_create") && this.state.pageShow === "create" && (
                    <AngpauSettingCreate
                        dropdown={this.state.dropdown}
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("angpau_setting_update") && this.state.pageShow === "edit" && (
                    <AngpauSettingEdit
                        dropdown={this.state.dropdown}                    
                        settingID={this.state.settingID}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.angpauSetting.rs,
        // dropdown: state.goldDigger.dropdown,
        // deleteResult: state.goldDigger,
        // updateStatus: state.goldDigger,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getSettings: (data) => dispatch(getSettings(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AngpauSetting));
