import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import ValidationError from '../../components/ValidationError/ValidationError';
import StringInput from '../../components/Input/StringInput/StringInput';
import Button from '../../components/Button/Button';

import Select from '../../components/Input/Select/Select';
import { formValidation } from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/whitelistIP/actions';

const initialFormInputState = {
	/* form input value */
	ip: '',
	status: 'allowed'
};

class WhitelistIPCreate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formFilter: initialFormInputState,
			loading: true,
			errMsg: [],
			touched: false
		}
		this.onInputChange = this.onInputChange.bind(this);
	}

	componentDidMount() {
		// console.log('componentDidMount (create):', this.state);
		formValidation('whitelist_ip_form');
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { t } = this.props;

		if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
			// console.log('shouldComponentUpdate (create):', nextProps.store);
			let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;

			if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
				Swal.fire({
					icon: 'success',
					title: t('global.success'),
					html: t('global.record-saved'),
					showConfirmButton: true
				}).then(() => {
					this.props.refreshHandler();
					this.props.changePageHandler('listing');
				});
			}
			else {
				this.setState({
					touched: (success) ? false : true,
					errMsg: (success) ? [] : nextProps.store.message
				});
				Swal.close();
			}
		}

		return true;
	}

	loadingHandler = () => {
		Swal.fire({
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			didOpen: () => {
				Swal.showLoading();
			}
		});
	}

	onInputChange = (event, id) => {
		// console.log(event.target.id+' | '+event.target.value);
		let newInput = Object.assign({}, this.state.formFilter);
		newInput[event.target.id] = event.target.value;
		this.setState({
			formFilter: newInput
		});
	}

	submitHandler = (e) => {
		e.preventDefault();
		const { t } = this.props;

		Swal.fire({
			title: t('validation.confirmation'),
			text: t('validation.confirmation_check'),
			icon: 'warning',
			confirmButtonText: t('global.save'),
			showDenyButton: true,
			denyButtonText: t('global.cancel'),
		})
			.then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false,
						didOpen: () => {
							Swal.showLoading()
						}
					});

					const inputArr = {};

					Object.keys(this.state.formFilter).forEach((val) => {
						inputArr[val] = this.state.formFilter[val];
					});

					this.props.funcStore(inputArr);
				}
			});
	}

	render() {
		const { t } = this.props;
		let inputArr = (this.state.formFilter) ? this.state.formFilter : [];
		let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

		let statusArray = [
			{ label: t("whitelist-ip.allowed"), value: 'allowed', selected: true },
			{ label: t("whitelist-ip.banned"), value: 'banned' }
		];

		return (
			<React.Fragment>
				<div className='breadcrumb-header'>
					<div className='d-flex'>
						<div className='my-auto'>
							<Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
						</div>
					</div>
					<div className='my-auto'>
						<div className='d-flex'>
							<h4 className='content-title mb-0 my-auto ml-2'>{t('whitelist-ip.title')}</h4>
						</div>
					</div>
				</div>
				<div className='row row-sm'>
					<div className='col-12'>
						<div className='card  box-shadow-0'>
							<div className='card-header'>
								<h4 className='card-title mb-1'>{t('role.whitelist_ip_create')}</h4>
							</div>

							<div className='card-body pt-0'>
								<form method='post' id='whitelist_ip_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
									<ValidationError formName='whitelist_ip_form' touched={this.state.touched} message={this.state.errMsg} />
									<div className='row'>
										<div className='col-md-6 col-12 form-group'>
											<label htmlFor='title'>{t('ip.ip-address')}</label>
											<StringInput
												id='ip'
												name='ip'
												placeholder={t('ip.ip-address')}
												value={inputArr.ip || ''}
												onChange={(event) => this.onInputChange(event)}
												required
											/>
										</div>

										<div className='col-md-6 col-12  form-group'>
											<label className='font-weight-bolder'>{t('global.status')}</label>

											<Select
												id="status"
												name="status"
												className='form-control filter-input'
												options={statusArray}
												onChange={(event) => this.onInputChange(event)}
												value={inputArr.status || 'allowed'}
											/>
										</div>

										<div className='col-12 form-group mb-0 justify-content-end'>
											<div>
												{(permission.includes('whitelist_ip_create')) &&
													<Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
												}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
	return {
		store: state.whitelistIP.store,
		currentUser: state.currentUser.data,
	}
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
	return {
		funcStore: (data) => dispatch(actions.store(data))
	}
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(WhitelistIPCreate));
