import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const eventLeagueReducer = createReducer(initialState) ({
    [types.EVENT_LEAGUE_LISTING]: (state, action) => ({
        ...state,
        event_league_listing: action.payload
    }),
    [types.EVENT_LEAGUE_CREATE]: (state, action) => ({
        ...state,
        event_league_create: action.payload
    }),
    [types.EVENT_LEAGUE_UPDATE]: (state, action) => ({
        ...state,
        event_league_update: action.payload
    }),
    [types.EVENT_LEAGUE_SHOW]: (state, action) => ({
        ...state,
        event_league_show: action.payload
    }),
    [types.EVENT_CATEGORY_DROPDOWN]: (state, action) => ({
        ...state,
        event_category_dropdown: action.payload
    }),
    [types.EVENT_TEAMS_DROPDOWN]: (state, action) => ({
        ...state,
        event_teams_dropdown: action.payload
    })
});

export default eventLeagueReducer;