import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MemberReferralPayoutHistoryListing from "./MemberReferralPayoutHistoryListing";
import MemberReferralPayoutDetail from "./MemberReferralPayoutDetail";
import TransactionDetails from "../Transaction/TransactionDetails";

import { getReferralPayoutHistory } from "../../store/ducks/memberReferral/actions";

const initialState = {
    date_range: moment().subtract(7, 'days').format('YYYY-MM-DD')+' - '+moment().subtract(1, 'days').format('YYYY-MM-DD'),
    username: ''
}

class MemberReferralPayoutHistory extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: [],
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            order: null,
            totalRecords: 0,
            searchValue: "",
            pageShow: "listing",
            errMsg: [],
            touched: false,
            filterInput: initialState,
            inputType: {},
            detailsParam: {},
            payoutParam: {}
        }
    }

    componentDidMount() {
        let data = this.getFilterList();
        this.props.getReferralPayoutHistory(data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.member_referral_payout_history !== undefined && nextProps.member_referral_payout_history !== this.props.member_referral_payout_history){
            if(nextProps.member_referral_payout_history.status === 200 && nextProps.member_referral_payout_history.data.responseCode === 200 && nextProps.member_referral_payout_history.data.msgType === "success"){
                this.setState({
                    loading: false,
                    data: nextProps.member_referral_payout_history.data.data.result.row,
                    totalPages: nextProps.member_referral_payout_history.data.data.result.pagination.total_page,
                    totalRecords: nextProps.member_referral_payout_history.data.data.result.pagination.total_record,
                });
            }
        }
        return true;
    }

    getFilterList = () => {
        const data = {}
        let availablePage = this.state.activePage;
        if(this.state.totalRecords > 0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        data.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        data.limit = this.state.limit;
        data.search = this.state.searchValue;
        data.order = JSON.stringify(this.state.order);
        data.username = this.state.filterInput.username;
        data.date_range = this.state.filterInput.date_range;
        data.country_id = this.props.currentUser.country_id;
        return data;
    }

    refreshHandler = () => {
        this.setState({
            errMsg: [],
            touched: false,
            loading: true,
            data: []
        }, ()=> {
            const data = this.getFilterList();
            this.props.getReferralPayoutHistory(data);
        });
    }

    clearHandler = () => {
        this.setState({
            errMsg: [],
            touched: false,
            loading: true,
            data: [],
            filterInput: initialState
        }, ()=> {
            const data = this.getFilterList();
            this.props.getReferralPayoutHistory(data);
        });
    }

    onInputChange = (event, inputType) => {
        let newFilter = Object.assign({}, this.state.filterInput);
        newFilter[event.target.id] = event.target.value;
        
        this.setState({
            filterInput: newFilter
        });
    }

    detailsHandler = (e, mid, date, rate) => {
        // console.log('detailsHandler :'+mid+' | '+date+' | '+id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            payoutParam : {
                mid: mid,
                date: moment(date).format('YYYY-MM-DD'), 
                returnPage: 'listing',
                rate: rate
            }
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        let search = "";
        search = (act==='searchValue') ? val.target.value : val;

        this.setState({
            [act]: search,
            loading: true,
            data: [],
            errMsg: [],
            touched: false
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayoutHistory(data);
        });
    }

    onSort = (field, order) => {
        // console.log(field+' | '+order);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayoutHistory(data);
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    }

    orderDetailsHandler = (e, id) => {
        // console.log('detailsHandler : ', id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'transaction',
            detailsParam : {
                id: id
            }
        });
    }

    render() {
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && 
                <MemberReferralPayoutHistoryListing 
                    refreshHandler={() => this.refreshHandler()}
                    clearHandler={() => this.clearHandler()}
                    onInputChange={(event, inputType) => this.onInputChange(event, inputType)}
                    detailsHandler={(event, mid, date, rate) => this.detailsHandler(event, mid, date, rate)}
                    orderDetailsHandler={(event, txid) => this.orderDetailsHandler(event, txid)}
                    datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                    onSort={(field, order) => this.onSort(field, order)}

                    data={this.state.data}
                    touched={this.state.touched}
                    errMsg={this.state.errMsg}
                    currentUser={this.props.currentUser}
                    loading={this.state.loading}
                    filterInput={this.state.filterInput}
                    activePage={this.state.activePage}
                    limit={this.state.limit}
                    totalRecords={this.state.totalRecords}
                    searchValue={this.state.searchValue}
                />
                }

                {this.state.pageShow === "details" &&
                    <MemberReferralPayoutDetail 
                        payoutParam={this.state.payoutParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow === 'transaction' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                }
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        member_referral_payout_history: state.memberReferral.member_referral_payout_history,
        currentUser: state.currentUser.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReferralPayoutHistory: (data) => dispatch(getReferralPayoutHistory(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberReferralPayoutHistory));