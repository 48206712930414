import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import Select from '../../components/Input/Select/Select';
import Textarea from '../../components/Input/Textarea/Textarea';
import ImageUploader from 'react-images-upload';
import ValidationError from '../../components/ValidationError/ValidationError';

import * as actions from '../../store/ducks/bankBalanceAdjustment/actions';
import { filterErrorHandler } from '../../hoc/Shared/utility';

const initialFormInputState =
{
    /* form input value */
    from_acc: '',
    to_acc: '',
    amount: '',
    fees: '',
    transaction_date: moment().format('YYYY-MM-DD h:mm A'),
    remark: '',
    fromAccBalc: 0,
    toAccBalc: 0,
    bankType: '',
    pictures: [],
    enableToAcc: false,
};

class BankBalanceAdjustmentTransfer extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: initialFormInputState,
            dropDown:
            {
                bankAccount: [],
            },
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onImageUpload = this.onImageUpload.bind(this);
        // console.log('constructor (create):', this.state);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('bba_transfer_form');
        const listingArr = {};
        listingArr.country_id = this.props.currentUser.country_id;
        this.props.funcGetDropDown(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate :', nextProps.dropDown);
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
                let fromAccArr = [];
                nextProps.dropDown.data.bank_account.forEach((val, idx) => {
                    fromAccArr.push({
                        id: `${val.id}|${val.acc_type}|${val.payment_type}`,
                        bank_acc_name: (val.swift_code !== null) ? `${val.swift_code} - ${val.bank_acc_name}` : val.bank_acc_name,
                    });
                });
                nextProps.dropDown.data.other_payment_account.forEach((val, idx) => {
                    fromAccArr.push({
                        id: `${val.id}|${val.acc_type}|${val.payment_type}`,
                        bank_acc_name: (val.swift_code !== null) ? `${val.swift_code} - ${val.bank_acc_name}` : val.bank_acc_name,
                    });
                });
                this.setState({
                    dropDown:
                    {
                        bankAccount: fromAccArr,
                    },
                });
            }
            else {
                this.setState({
                    dropDown:
                    {
                        bankAccount: [],
                    },
                }, () => {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }

        if (nextProps.bankBalc !== this.props.bankBalc && nextProps.bankBalc !== undefined) {
            if (nextProps.bankBalc.responseCode === 200) {
                let newFilter = Object.assign({}, this.state.formFilter);
                let accBalc = (this.state.formFilter.bankType === 'from_acc') ? 'fromAccBalc' : 'toAccBalc';
                newFilter['bankType'] = accBalc;
                newFilter[accBalc] = nextProps.bankBalc.data.balance;
                newFilter['enableToAcc'] = true;
                this.setState({
                    formFilter: newFilter,
                });
            }
            else {
                filterErrorHandler(nextProps.bankBalc.message);
            }
        }

        if (nextProps.storeTransfer !== this.props.storeTransfer && nextProps.storeTransfer !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.storeTransfer);
            const { t } = this.props;
            let success = (nextProps.storeTransfer.responseCode === 200 && nextProps.storeTransfer.msgType === 'success') ? true : false;
            if (nextProps.storeTransfer.responseCode === 200 && nextProps.storeTransfer.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('bba_transfer_form');
                    this.setState({
                        formFilter: initialFormInputState,
                        touched: (success) ? false : true,
                        errMsg: (success) ? [] : nextProps.storeTransfer.message,
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.storeTransfer.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    // loadingHandler = () =>
    // {
    //     Swal.fire({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });
    // }

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter['to_acc'] = (event.target.id === 'from_acc') ? '' : this.state.formFilter.to_acc;
        newFilter['fromAccBalc'] = (event.target.id === 'from_acc' && event.target.value === '') ? 0 : this.state.formFilter.fromAccBalc;
        newFilter['toAccBalc'] = (event.target.id === 'from_acc' || (event.target.id === 'to_acc' && event.target.value === '')) ? 0 : this.state.formFilter.toAccBalc;
        newFilter['bankType'] = (event.target.id === 'from_acc' || event.target.id === 'to_acc') ? event.target.id : '';
        newFilter['enableToAcc'] = (event.target.id === 'from_acc') ? false : true;
        newFilter[event.target.id] = event.target.value;
        this.setState({
            formFilter: newFilter
        }, () => {
            if ((event.target.id === 'from_acc' || event.target.id === 'to_acc') && event.target.value !== '') {
                let val = event.target.value.split('|');
                let bank_acc = val[0];
                let transaction_channel = val[1];
                let payment_type = val[2];
                // console.log(val);
                this.props.funcGetBankBalc(bank_acc, transaction_channel, payment_type);
            }
        });
    }

    onImageUpload = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter['pictures'] = event[0];
        this.setState({
            formFilter: newFilter
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state.formFilter);
        e.preventDefault();
        const { t } = this.props;
        let amt = Number(this.state.formFilter.amount);
        let toAccBalc = Number(this.state.formFilter.toAccBalc);
        let fromAccBalc = Number(this.state.formFilter.fromAccBalc);
        if ((amt > fromAccBalc) && (amt > toAccBalc)) {
            Swal.fire({
                title: t('validation.insufficient_balance'),
                icon: 'error',
                showConfirmButton: true,
            })
        }
        else {
            Swal.fire({
                title: t('validation.confirmation'),
                text: t('validation.confirmation_check'),
                icon: 'warning',
                confirmButtonText: t('global.save'),
                showDenyButton: true,
                denyButtonText: t('global.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    let formData = new FormData();
                    let tmp_from = this.state.formFilter.from_acc.split('|');
                    let from_bank_acc = tmp_from[0];
                    let from_transaction_channel = tmp_from[1];
                    let from_payment_type = tmp_from[2];

                    let tmp_to = this.state.formFilter.to_acc.split('|');
                    let to_bank_acc = tmp_to[0];
                    let to_transaction_channel = tmp_to[1];
                    let to_payment_type = tmp_to[2];

                    formData.append('from_bank_acc', from_bank_acc);
                    formData.append('from_transaction_channel', from_transaction_channel);
                    formData.append('from_payment_type', from_payment_type);
                    formData.append('to_bank_acc', to_bank_acc);
                    formData.append('to_transaction_channel', to_transaction_channel);
                    formData.append('to_payment_type', to_payment_type);
                    formData.append('amount', this.state.formFilter.amount);
                    formData.append('fees', this.state.formFilter.fees);
                    // formData.append('transaction_date',this.state.formFilter.transaction_date);
                    formData.append('transaction_date', moment(this.state.formFilter.transaction_date).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append('remark', this.state.formFilter.remark);
                    formData.append('file', this.state.formFilter.pictures);
                    formData.append('country_id', this.props.currentUser.country_id);
                    // console.log(formData);
                    // for (var pair of formData.entries())
                    // {
                    //     console.log(pair[0]+ ' = ' + pair[1]);
                    // }
                    this.props.funcStore(formData);
                }
            });
        }
    }

    render() {
        // console.log('render :', this.state.formFilter);
        const { t } = this.props;
        let toAccArr = [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        if (this.state.dropDown.bankAccount && this.state.formFilter.from_acc !== '' && this.state.formFilter.enableToAcc) {
            this.state.dropDown.bankAccount.forEach((val, idx) => {
                if (val.id !== this.state.formFilter.from_acc) {
                    toAccArr.push({
                        id: val.id,
                        bank_acc_name: val.bank_acc_name,
                    });
                }
            })
        }

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('bank-balance-adjustment.title')}</h4>
                        </div>
                    </div>
                </div>

                <div className='row row-sm'>
                    <div className='col-lg-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('bank-balance-adjustment.new-transfer')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='bba_transfer_form' name='bba_transfer_form' className='form-horizontal' encType='multipart/form-data' onSubmit={this.submitHandler} noValidate >
                                    <ValidationError formName='bba_transfer_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {/* <div className='row filter-wrapper-2'> */}
                                    {/* <div className='col-md-6'> */}
                                    <div className="row filter-wrapper-3">
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.from-bank-account')}</label>
                                                <Select
                                                    id='from_acc'
                                                    name='from_acc'
                                                    className='form-control'
                                                    getOptionValue='id'
                                                    getOptionLabel='bank_acc_name'
                                                    options={this.state.dropDown.bankAccount}
                                                    onChange={this.onInputChange}
                                                    value={this.state.formFilter.from_acc}
                                                    emptyValue={true}
                                                    required
                                                />
                                                <label className="available_balance"><b>{t('bank-balance-adjustment.balance')}: <span id='from_acc_balance'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.fromAccBalc)}</span></b></label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.to-bank-account')}</label>
                                                <Select
                                                    id='to_acc'
                                                    name='to_acc'
                                                    className='form-control'
                                                    getOptionLabel='bank_acc_name'
                                                    getOptionValue='id'
                                                    options={toAccArr}
                                                    onChange={this.onInputChange}
                                                    value={this.state.formFilter.to_acc}
                                                    emptyValue={true}
                                                    required
                                                />
                                                <label className="available_balance"><b>{t('bank-balance-adjustment.balance')}: <span id='to_acc_balance'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.toAccBalc)}</span></b></label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.amount')}</label>
                                                <NumberInput
                                                    id='amount'
                                                    name='amount'
                                                    min={0.01}
                                                    placeholder={t('bank-balance-adjustment.amount')}
                                                    value={this.state.formFilter.amount}
                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                    onChange={this.onInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.fees')}</label>
                                                {/* <input className='form-control' type='number' name='fees' id='fees' onChange={this.onInputChange} placeholder='Fees' value={this.state.formFilter.fees} /> */}
                                                <NumberInput
                                                    id='fees'
                                                    name='fees'
                                                    placeholder={t('bank-balance-adjustment.fees')}
                                                    value={this.state.formFilter.fees}
                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.transaction-date')}</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'><i className='fas fa-calendar-check'></i></span>
                                                    </div>
                                                    <DateTimePicker
                                                        id='transaction_date'
                                                        name='transaction_date'
                                                        className='form-control filter-input'
                                                        format='YYYY-MM-DD'
                                                        singleDatePicker={true}
                                                        autoUpdateInput={true}
                                                        timePicker={true}
                                                        value={this.state.formFilter.transaction_date}
                                                        onChange={this.onInputChange}
                                                        maxDate={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <div className='col-12 col-md-6'>
                                            <div className='form-group mb-0'>
                                                <label>{t('bank-balance-adjustment.receipt')}</label>
                                                <ImageUploader
                                                    withIcon={true}
                                                    withPreview={true}
                                                    singleImage={true}
                                                    onChange={this.onImageUpload}
                                                    buttonText={t('bank-balance-adjustment.choose-image')}
                                                    maxFileSize={5242880}
                                                    className='is-invalid'
                                                />
                                                {/* <span className='text-muted'><strong>*jpg, png, gif</strong></span> */}
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <div className='form-group mb-0'>
                                                <label>{t('bank-balance-adjustment.remark')}</label>
                                                <Textarea
                                                    id='remark'
                                                    name='remark'
                                                    className='form-control'
                                                    maxLength={255}
                                                    rows={3}
                                                    placeholder={t('bank-balance-adjustment.remark')}
                                                    value={this.state.formFilter.remark}
                                                    onChange={this.onInputChange}
                                                // required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-3 mb-3">

                                    </div> */}
                                    <div className='col mt-auto p-0'>
                                        <div>
                                            {/* <div className='form-group mb-0 mt-3 justify-content-end'> */}
                                            {(permission.includes('bank_balance_adjustment_store')) &&
                                                <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {/* </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        dropDown: state.bankBalanceAdjustment.dropDown,
        bankBalc: state.bankBalanceAdjustment.bankBalc,
        storeTransfer: state.bankBalanceAdjustment.storeTransfer,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDropDown: (data) => dispatch(actions.getDropDown(data)),
        funcGetBankBalc: (id, tc, pt) => dispatch(actions.getBankBalc(id, tc, pt)),
        funcStore: (data) => dispatch(actions.storeTransfer(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BankBalanceAdjustmentTransfer));