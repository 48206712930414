import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInputReadOnly from "../../components/Input/StringInputReadOnly/StringInputReadOnly";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { getUserGroup, showAdmin, updateAdmin } from "../../store/ducks/admins/actions";
import { formValidation, filterErrorHandler } from "../../hoc/Shared/utility";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";

let userGroupArry = [];

class AdminsEdit extends Component {
    state = {
        username: "",
        email: "",
        user_group_id: "",
        country_id: "",
        new_password: "",
        old_password: "",
        confirm_password: "",
        errors: {},
        loading: true,
        currentStatus: 1,
    };

    componentDidMount() {
        formValidation("edit_admin_form");

        this.props.getUserGroup();
        const showAdminData = {};
        showAdminData.id = this.props.show_admin_id;
        this.props.showAdmin(showAdminData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextProps.show_admin_data !== this.props.show_admin_data && nextProps.show_admin_data !== undefined) {
            if (nextProps.show_admin_data.data.responseCode === 200 && nextProps.show_admin_data.data.msgType === 'success') {
                let adminData = nextProps.show_admin_data.data.data[0];

                this.setState({
                    username: adminData.username,
                    email: adminData.email,
                    group_id: adminData.group_id,
                    country_id: adminData.country_id,
                    user_group_id: adminData.group_id_id,
                    currentStatus: adminData.status,
                    loading: false
                });
            } else {
                this.setState({
                    username: "",
                    email: "",
                    group_id: "",
                    user_group_id: "",
                    country_id: "",
                    currentStatus: ""
                }, () => {
                    filterErrorHandler(nextProps.show_admin_data.data.message);
                });
            }
        }

        if (nextProps.user_group_data !== this.props.user_group_data && nextProps.user_group_data !== undefined) {
            if (nextProps.user_group_data.data.responseCode === 200 && nextProps.user_group_data.data.msgType === 'success') {
                userGroupArry = [];
                let data = nextProps.user_group_data.data.data;
                Object.keys(data).map((item, i) => {
                    return userGroupArry.push({
                        label: data[item].title,
                        value: data[item].Id,
                        key: data[item].Id,
                    });
                });
            }
            else {
                userGroupArry = [];
                filterErrorHandler(nextProps.user_group_data.data.message);
            }
        }

        if (nextProps.update_admin_data !== this.props.update_admin_data && nextProps.update_admin_data !== undefined) {
            if (nextProps.update_admin_data.responseCode === 200 && nextProps.update_admin_data.msgType === 'success') {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.update_admin_data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!this.props.profile) {
                            this.props.refreshHandler();
                        }
                        this.props.changePageHandler(!this.props.profile ? 'listing' : 'show');
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.update_admin_data.message,
                });
                Swal.close();
            }
        }

        return true;
    }

    getUserGroupHandler = (event) => {
        this.setState({ user_group_id: event.target.value });
    };

    getUserNameHandler = (event) => {
        this.setState({ username: event.target.value });
    };

    // getEmailHandler = (event) => {
    //     this.setState({ email: event.target.value });
    // };

    getNewPasswordHandler = (event) => {
        this.setState({ new_password: event.target.value });
    };

    // getOldPasswordHandler = (event) => {
    //     this.setState({ old_password: event.target.value });
    // };

    // getConfirmPasswordHandler = (event) => {
    //     this.setState({ confirm_password: event.target.value });
    // };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    }

    updateAdminHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        if (this.validate()) {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.update"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};

                    data.id = this.props.show_admin_id;
                    data.username = this.state.username;
                    // data.email = this.state.email;
                    data.new_password = this.state.new_password;
                    // data.old_password = this.state.old_password;
                    // data.confirm_password = this.state.confirm_password;
                    data.user_group_id = this.state.user_group_id;
                    data.country_id = this.props.currentUser.country_id;
                    data.status = this.state.currentStatus;

                    this.props.updateAdmin(data);
                }
            });
        }
    };
    validate() {
        let errors = {};
        let isValid = true;
        // if (this.state.confirm_password !== this.state.new_password) {
        //     isValid = false;
        //     errors["new_password"] = "Password don't match.";
        // }

        this.setState({
            errors: errors,
        });

        return isValid;
    }
    render() {
        const { t } = this.props;

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler(!this.props.profile ? 'listing' : 'show')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("admin.admin")}</h4>
                        </div>
                    </div>
                </div>



                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("admin.edit-admin")}</h4>
                            </div>


                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="edit_admin_form" className="form-horizontal" onSubmit={this.updateAdminHandler} noValidate>
                                        <ValidationError formName="edit_admin_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            {/* <div className="col-md-12"> */}
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="username">{t("admin.username")}</label>
                                                    <StringInputReadOnly
                                                        onChange={this.getUserNameHandler}
                                                        id="Admin_username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Please enter username"
                                                        value={this.state.username}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="form-group">
                                                    <label htmlFor="email">{t("admin.email")}</label>
                                                    <StringInput
                                                        onChange={this.getEmailHandler}
                                                        id="Admin_email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        value={this.state.email}
                                                        required
                                                    />
                                                </div> */}

                                            {/* <div className="form-group">
                                                    <label htmlFor="password">{t("admin.old-password")}</label>
                                                    <PasswordInput
                                                        onChange={this.getOldPasswordHandler}
                                                        id="Admin_old_password"
                                                        name="old_password"
                                                        className="form-control"
                                                        value={this.state.old_password}
                                                        required
                                                    />
                                                </div> */}
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="password">{t("admin.new-password")}</label>
                                                    <PasswordInput
                                                        onChange={this.getNewPasswordHandler}
                                                        id="new_password"
                                                        name="new_password"
                                                        className="form-control"
                                                        value={this.state.new_password}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {this.props.currentUser && this.props.currentUser.user_group.toLowerCase().replace(' ', '') === process.env.REACT_APP_SUPER_ADMIN_GROUP &&
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <label>{t('promotion.status')}</label>
                                                        <Select
                                                            id="status"
                                                            name="status"
                                                            className="form-control"
                                                            options={statusArry}
                                                            onChange={this.changeStatusHandler}
                                                            value={this.state.currentStatus}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {/* <div className="form-group">
                                                    <label htmlFor="password">{t("admin.confirm-password")}</label>
                                                    <PasswordInput
                                                        onChange={this.getConfirmPasswordHandler}
                                                        id="confirm_password"
                                                        name="confirm_password"
                                                        className="form-control"
                                                        value={this.state.confirm_password}
                                                    />
                                                    <div className="text-danger">{this.state.errors.new_password}</div>
                                                </div> */}

                                            {this.props.currentUser && this.props.currentUser.user_group.toLowerCase().replace(' ', '') === process.env.REACT_APP_SUPER_ADMIN_GROUP &&
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="group">{t("admin.group")}</label>
                                                        <Select id="user_group_id" name="user_group_id" options={userGroupArry} value={this.state.user_group_id} className="form-control" onChange={this.getUserGroupHandler} />
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-12">
                                                <div className="form-group mb-0 justify-content-end">
                                                    <div>
                                                        <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;

    //Getting the state from the reducers
    return {
        user_group_data: admin.user_group_data,
        update_admin_data: admin.update_admin_result,
        show_admin_data: admin.show_admin_data,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps = {
    getUserGroup,
    updateAdmin,
    showAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AdminsEdit));
