import axios from "../../../common/config/axios";
import * as type from "./types";

export const getList = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("voucher/list", {
            params: data,
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            // dispatch({
            //     type: type.LIST,
            //     payload: [],
            // });
        });
};

export const store = (data) => (dispatch, getState) => {
    axios.post("voucher/create", data)
        .then((res) => {
            dispatch({
                type: type.STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            // dispatch({
            //     type: type.STORE,
            //     payload: err.data,
            // });
        });
};

export const getByID = (data) => (dispatch) => {
    axios.get("voucher/get_by_id", {params: data})
        .then((res) => {
            dispatch({
                type: type.EDIT,
                payload: res.data,
            });
        }).catch((err) => {
            // dispatch({
            //     type: type.LIST,
            //     payload: err.data,
            // });
        });
};

export const update = (data) => (dispatch) => {
    axios.post("voucher/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            // dispatch({
            //     type: type.UPDATE,
            //     payload: err.data,
            // });
        });
};

export const updateStatus = (data) => (dispatch) => {
    axios.post("voucher/update_status", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            // dispatch({
            //     type: type.UPDATE_STATUS,
            //     payload: err.data,
            // });
        });
};

export const addVoucherMember = (data) => (dispatch, getState) => {
    axios.post("voucher/add_voucher_member", data)
        .then((res) => {
            dispatch({
                type: type.ADD_VOUCHER_MEMBER,
                payload: res.data,
            });
        })
        .catch((err) => {
            // dispatch({
            //     type: type.ADD_VOUCHER_MEMBER,
            //     payload: err.data,
            // });
        });
};

export const getVoucherMember = (data) => (dispatch) => {
    axios.get("voucher/get_voucher_member", {params: data})
        .then((res) => {
            dispatch({
                type: type.GET_VOUCHER_MEMBER,
                payload: res.data,
            });
        }).catch((err) => {
            // dispatch({
            //     type: type.GET_VOUCHER_MEMBER,
            //     payload: err.data,
            // });
        });
};

export const showVoucher = (data) => (dispatch) => {
    axios.get("voucher/show", {params: data})
        .then((res) => {
            dispatch({
                type: type.SHOW_VOUCHER,
                payload: res.data,
            });
        }).catch((err) => {
            // dispatch({
            //     type: type.SHOW_VOUCHER,
            //     payload: err.data,
            // });
        });
};

export const deleteMember = (data) => (dispatch) => {
    axios.post("voucher/delete_voucher_member", data)
        .then((res) => {
            dispatch({
                type: type.DELETE_VOUCHER_MEMBER,
                payload: res.data,
            });
        })
        .catch((err) => {});
};

export const addSpecificVoucherMember = (data) => (dispatch) => {
    axios.post("voucher/add_specific_voucher_member", data)
        .then((res) => {
            dispatch({
                type: type.ADD_SPECIFIC_VOUCHER_MEMBER,
                payload: res.data,
            });
        })
        .catch((err) => {});
}