import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import Lightbox from 'react-image-lightbox';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import DomPurify from "../../components/Sanitize/DomPurify";

import * as actions from "../../store/ducks/transaction/actions";
import { filterErrorHandler } from '../../hoc/Shared/utility';

class TransactionDetails extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            details: {},
            loading: true,
            currentImage: '',
            isOpen: false
        };
    }

    componentDidMount() {
        // console.log(this.props.detailsParam);
        if (this.props.detailsParam && this.props.detailsParam.id) {
            this.props.funcGetDetails(this.props.detailsParam.id);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                this.setState({
                    details: nextProps.details.data.result,
                    loading: false
                });
            }
            else {
                this.setState({
                    details: {},
                    loading: true
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }
        return true;
    }

    render() {
        // console.log(this.state.details);
        const { t } = this.props;
        let rsDetails = (this.state.details[0]) ? this.state.details[0] : [];
        let stCls = { 0: 'primary', 1: 'success', 2: 'danger', 3: 'secondary' };
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        return (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            {window.location.pathname !== "/member" &&
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler(this.props.returnPage,this.props.xPosition,this.props.yPosition)}
                                />
                            }
                            {window.location.pathname === "/member" &&
                                <Button typeButton="button" 
                                classValue="btn btn-back-outline" 
                                idValue="back" 
                                buttonName={<i className="angle fe fe-chevron-left"></i>} 
                                clicked={() => this.props.changePageHandler(this.props.returnPage,this.props.xPosition,this.props.yPosition)} />
                            }
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('transaction.title')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">

                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mg-b-0">{t('transaction.transaction-details-title')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {/* <tr>
                                            <th>{t('transaction.transaction-id')}</th>
                                            <td>{rsDetails.id}</td>
                                        </tr> */}
                                            <tr>
                                                <th>{t('transaction.order-id')}</th>
                                                <td>{rsDetails.order_id}</td>
                                            </tr>
                                            {rsDetails.email &&
                                                <tr>
                                                    <th>{t('transaction.member')}</th>
                                                    <td>{rsDetails.email}</td>
                                                </tr>}
                                            <tr>
                                                <th>{t('transaction.transaction-type')}</th>
                                                <td>
                                                    {(rsDetails.transaction_type) && t(`transaction-type.${rsDetails.transaction_type}`)}
                                                </td>
                                            </tr>
                                            {rsDetails.transaction_channel &&
                                                <tr>
                                                    <th>{t('transaction.transaction-channel')}</th>
                                                    <td>{(rsDetails.transaction_channel) ? t(`transaction-channel.${rsDetails.transaction_channel}`) : '-'}</td>
                                                </tr>}
                                            <tr>
                                                <th>{t('transaction.status')}</th>
                                                <td>
                                                    <span className={`badge-${stCls[rsDetails.status]}`}>{(rsDetails.status >= 0) && t(`transaction.status-desc.${rsDetails.status}`)}</span>
                                                </td>
                                            </tr>
                                            {rsDetails.transaction_type === 'rebate' &&
                                                <tr>
                                                    <th>{t('transaction.transaction-date')}</th>
                                                    <td>{rsDetails.transaction_date}</td>
                                                </tr>}
                                            <tr>
                                                <th>{t('transaction.create-date')}</th>
                                                <td>{rsDetails.create_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('transaction.update-date')}</th>
                                                <td>{rsDetails.update_date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('transaction.update-by')}</th>
                                                <td>{rsDetails.update_by}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('transaction.debit')}</th>
                                                <td>{(rsDetails.debit) && new Intl.NumberFormat('en-US', num_format).format(rsDetails.debit)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('transaction.credit')}</th>
                                                <td>{(rsDetails.credit) && new Intl.NumberFormat('en-US', num_format).format(rsDetails.credit)}</td>
                                            </tr>
                                            {/* <tr>
                                            <th>{t('transaction.fees')}</th>
                                            <td>{(rsDetails.fees) && new Intl.NumberFormat('en-US', num_format).format(rsDetails.fees)}</td>
                                        </tr> */}
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-6">
                        {(rsDetails.transaction_type === 'deposit' ||
                            rsDetails.transaction_type === 'withdrawal' ||
                            rsDetails.transaction_type === 'transfer' ||
                            rsDetails.transaction_type === 'cash-in' ||
                            rsDetails.transaction_type === 'cash-out'
                        ) &&
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    {
                                        this.state.loading
                                            ?
                                            <Skeleton count={1} />
                                            :
                                            <h4 className="card-title mb-1"><strong>{(rsDetails.transaction_channel === 'crypto') ? t('transaction.crypto-details') : t('transaction.bank-details-title')}</strong></h4>
                                    }
                                </div>
                                <div className="card-body pt-0">
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            {
                                                (rsDetails.transaction_type === 'deposit' || rsDetails.transaction_type === 'withdrawal')
                                                    ?
                                                    <table className="table table-bordered table-hover">
                                                        {(rsDetails.transaction_channel === 'bank-transfer')
                                                            ?
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('transaction.bank-name')}</th>
                                                                    <td>{rsDetails.transaction_type_id === 1 ? rsDetails.dp_bank_name : rsDetails.wd_bank_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.bank-account-name')}</th>
                                                                    <td>{rsDetails.transaction_type_id === 1 ? rsDetails.dp_bank_acc_name : (rsDetails.name || rsDetails.wd_bank_acc_name)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.bank-account-no')}</th>
                                                                    <td>{rsDetails.transaction_type_id === 1 ? rsDetails.dp_bank_acc_number : rsDetails.wd_bank_acc_number}</td>
                                                                </tr>
                                                                {rsDetails.transaction_type === 'deposit' && <tr>
                                                                    <th>{t('transaction.upload-receipt')}</th>
                                                                    <td>
                                                                        <img
                                                                            alt=""
                                                                            style={{ "width": "20%", "cursor": "pointer" }}
                                                                            src={rsDetails.image} onClick={() => this.setState({ isOpen: true, currentImage: rsDetails.image })}
                                                                        />
                                                                    </td>
                                                                </tr>}
                                                            </tbody>
                                                            :
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('transaction.from-address')}</th>
                                                                    <td>{rsDetails.from_addr}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.to-address')}</th>
                                                                    <td>{rsDetails.to_addr}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.transaction-hash')}</th>
                                                                    <td style={{ flexDirection: 'row' }}>{rsDetails.tx_hash}</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                    :
                                                    (rsDetails.transaction_type === 'transfer' || rsDetails.transaction_type === 'cash-in' || rsDetails.transaction_type === 'cash-out')
                                                        ?
                                                        <table className="table table-bordered table-hover">
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('transaction.from-account')}</th>
                                                                    <td>{rsDetails.from_bank_acc ? rsDetails.from_bank_acc : (rsDetails.from_quickpay_acc ? rsDetails.from_quickpay_acc : '-')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.to-account')}</th>
                                                                    <td>{rsDetails.to_bank_acc ? rsDetails.to_bank_acc : (rsDetails.to_quickpay_acc ? rsDetails.to_quickpay_acc : '-')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('transaction.upload-receipt')}</th>
                                                                    <td>
                                                                        <img
                                                                            alt=""
                                                                            style={{ "width": "20%", "cursor": "pointer" }}
                                                                            src={rsDetails.image} onClick={() => this.setState({ isOpen: true, currentImage: rsDetails.image })}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <></>
                                            }
                                        </>
                                    }
                                </div>
                            </div>}

                        {/* {(rsDetails.transaction_type === 'member-referral'
                        ) && <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1"><strong>{t('transaction.referee-details')}</strong></h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th>{t('transaction.referee-email')}</th>
                                            <td>{rsDetails.referee_email}</td>
                                        </tr>
                                        <tr>
                                        <th>{t('transaction.referee-name')}</th>
                                            <td>{rsDetails.referee_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>} */}

                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1"><strong>{t('transaction.remark')}</strong></h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <DomPurify text={rsDetails.remark} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.transaction.details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(TransactionDetails));