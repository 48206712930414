import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';

import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';

import * as actions from '../../store/ducks/agentPayout/actions';

class AgentPayoutDetails extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            activePage: 1,
            limit: 10,
            totalRecords: 0,
            searchValue: '',
            order: '',

            data: [],
            agentDetails: [],
            loading: true,
            loadOnce: true,
        };
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount() {
        const listingArr = this.getFilterArray();
        if (this.props.betDetailsParam.orderId !== null) {
            this.props.funcGetPayoutHistoryDetails(listingArr);
        }
        else {
            this.props.funcGetBetDetails(listingArr);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps.betDetails);
        if (nextProps.betDetails !== this.props.betDetails && nextProps.betDetails !== undefined) {
            if (nextProps.betDetails.responseCode === 200 && nextProps.betDetails.msgType === 'success') {
                this.setState({
                    agentDetails: nextProps.betDetails.data.agent,
                    data: nextProps.betDetails.data.result.row,
                    totalRecords: nextProps.betDetails.data.result.pagination.total_record,
                    loading: false,
                    loadOnce: false,
                });
            }
            Swal.close();
        }

        if (nextProps.payoutHistoryDetails !== this.props.payoutHistoryDetails && nextProps.payoutHistoryDetails !== undefined) {
            if (nextProps.payoutHistoryDetails.responseCode === 200 && nextProps.payoutHistoryDetails.msgType === 'success') {
                // console.log(nextProps.payoutHistoryDetails);
                this.setState({
                    agentDetails: nextProps.payoutHistoryDetails.data.agent,
                    data: nextProps.payoutHistoryDetails.data.result.row,
                    totalRecords: nextProps.payoutHistoryDetails.data.result.pagination.total_record,
                    loading: false,
                    loadOnce: false,
                });
            }
            Swal.close();
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    refreshHandler = () => {
        const listingArr = this.getFilterArray();
        if (this.props.betDetailsParam.orderId !== null) {
            this.props.funcGetPayoutHistoryDetails(listingArr);
        }
        else {
            this.props.funcGetBetDetails(listingArr);
        }
    }

    // loadingHandler = () =>
    // {
    //     Swal.fire({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });
    // }

    getFilterArray = () => {
        // this.loadingHandler();
        const listingArr = {};
        const param = this.props.betDetailsParam;

        listingArr.page = this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.aid = param.aid;
        listingArr.date = param.date;
        listingArr.order_id = param.orderId;

        this.setState({
            loading: true,
            data: [],
        });
        return listingArr;
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = ((this.state.activePage - 1) * this.state.limit) + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({ order: orderArry }, () => {
            this.refreshHandler();
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val
        }, () => {
            this.refreshHandler();
        });
    }
    /* ============ datatables function [END] ============ */

    render() {
        // console.log(this.props.betDetailsParam);
        const { t } = this.props;
        let display = null;
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        if (this.state.agentDetails) {
            const columns =
                [
                    {
                        text: t('global.no'),
                        dataField: 'no',
                        formatter: (cell, row, rowIndex) => {
                            let rowNumber = rowIndex + 1;
                            return <span>{rowNumber}</span>;
                        },
                        headerStyle: { width: '60px', textAlign: 'center' }
                    },
                    {
                        text: t('agent-payout.member'),
                        dataField: 'agent',
                        sort: true,
                        onSort: this.onSort,
                        hidden: (this.props.betDetailsParam.orderId === null) ? false : true,
                    },
                    {
                        text: t('agent-payout.game-type'),
                        dataField: 'slug',
                        formatter: (cell, row, rowIndex) => {
                            return t(`game-type.${row.slug}`);
                        },
                    },
                    {
                        text: t('agent-payout.base-currency'),
                        dataField: 'currency',
                    },
                    {
                        text: t('agent-payout.total-bet'),
                        dataField: 'total_bet_base_currency',
                        formatter: (cell, row, rowIndex) => {
                            return new Intl.NumberFormat('en-US', num_format).format(row.total_bet_base_currency);
                        },
                    },
                    {
                        text: `${t('agent-payout.total-bet')} (${t('global.currency.usd')})`,
                        dataField: 'total_bet',
                        formatter: (cell, row, rowIndex) => {
                            return new Intl.NumberFormat('en-US', num_format).format(row.total_bet);
                        },
                    },
                    {
                        text: t('agent-payout.payout'),
                        dataField: 'payout',
                        formatter: (cell, row, rowIndex) => {
                            return new Intl.NumberFormat('en-US', num_format).format(row.payout);
                        },
                        hidden: (this.props.betDetailsParam.orderId !== null) ? false : true,
                    },
                ];

            display =
                <Auxiliary>
                    <div className='breadcrumb-header'>
                        {/* <div className='my-auto'>
                            <div className='d-flex'>
                                <h4 className='content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0'>{t('agent-payout.agent-payout')}</h4>
                            </div>
                        </div> */}
                        <div className='d-flex'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler(this.props.betDetailsParam.returnPage)} />
                            <div className='my-auto'>
                                <div className='member-name'>
                                    <FontAwesomeIcon icon={faUser} /> {this.state.agentDetails.agent}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row row-sm'>
                        <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mg-b-0'>{t('agent-payout.agent-details')}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {(this.state.loading && this.state.loadOnce)
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <table className='table table-bordered table-hover'>
                                            <tbody>
                                                <tr>
                                                    <th>{t('agent-payout.date')}</th>
                                                    <td>{this.props.betDetailsParam.date}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('agent-payout.agent')}</th>
                                                    <td>{this.state.agentDetails.agent}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('agent-payout.base-currency')}</th>
                                                    <td>{this.state.agentDetails.currency}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                    {this.state.loading
                                        ?
                                        <Skeleton count={1} />
                                        :
                                        <div className="mb-0 mt-3 justify-content-end d-flex my-xl-auto right-content">
                                            <Button typeButton='button' classValue='btn btn-blue' idValue='refresh' buttonName={t('global.refresh')} clicked={() => this.refreshHandler()} />
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mb-1'>{(this.props.betDetailsParam.orderId === null) ? t('agent-payout.downline') : t('agent-payout.payout-history')}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive table-hover datatable'>
                                        <DataTable
                                            keyField='no'
                                            data={this.state.data}
                                            columns={columns}
                                            activePage={this.state.activePage}
                                            limit={this.state.limit}
                                            totalItems={this.state.totalRecords}
                                            paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                            sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                            searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                            searchValue={this.state.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Auxiliary>;
        }
        return (
            <Auxiliary>
                {display}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        betDetails: state.agentPayout.betDetails,
        payoutHistoryDetails: state.agentPayout.payoutHistoryDetails,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetBetDetails: (aid, date, id) => dispatch(actions.getBetDetails(aid, date, id)),
        funcGetPayoutHistoryDetails: (aid, date, id) => dispatch(actions.getPayoutHistoryDetails(aid, date, id)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(AgentPayoutDetails));