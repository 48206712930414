import React, { Component } from "react";
import { connect } from "react-redux";
// import { editAgent, updateAgent } from "../../../store/ducks/agent/actions";
import * as actions from "../../../store/ducks/agent/actions";

import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
// import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../../components/ValidationError/ValidationError";

class AgentAccountDetails extends Component 
{
    constructor(props) 
    {
        super(props);

        // const initLoad = this.props.initialLoading.filter(item => item.id === "account_details");
        // const initialLoading = initLoad[0].loading;
        // console.log('xxxxxxxx');
        
        this.state = 
        {
            id: null,
            username: "",
            referral_code: "",
            name: "",
            contact_no: "",
            email: "",
            status: "",
            loginPermission: "",
            agent_type: "",
            pt: "",
            cost: "",
            fixed_cost: "",
            loading: true,

            error_message: [],
            touched: false,
        };
    }

    componentDidMount() 
    {
        formValidation("agent_form");
        // console.log("componentDidMount", this.props.agentEditId);
        this.props.funcEditAgent(this.props.agentEditId);
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        // console.log("[AgentEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.agentEditData !== undefined && nextProps.agentEditData !== this.props.agentEditData && nextState.loading === true) 
        {
            let agentData = nextProps.agentEditData.data.agent[0];
            this.setState({
                id: agentData.agentId,
                username: agentData.username,
                referral_code: agentData.referral_code,
                name: agentData.name,
                email: agentData.email,
                contact_no: agentData.contactNo,
                status: agentData.status,
                login_permission: agentData.loginPermission,
                agent_type: agentData.agentType,
                pt: agentData.pt,
                cost: agentData.cost,
                fixed_cost: agentData.fixedCost,
                loading: false,
            });
        }

        if (nextProps.updateAgentResult !== this.props.updateAgentResult) {
            if (nextProps.updateAgentResult.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateAgentResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        // this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateAgentResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateAgentResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateAgentHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
                });

                let updateAgentData = {};
                updateAgentData.id = this.state.id;
                updateAgentData.name = this.state.name;
                updateAgentData.email = this.state.email;
                updateAgentData.contact_no = this.state.contact_no;
                updateAgentData.status = this.state.status;
                // updateAgentData.login_permission = this.state.login_permission;
                // updateAgentData.agent_type = this.state.agent_type;
                // updateAgentData.pt = this.state.pt;
                // updateAgentData.cost = this.state.cost;
                // updateAgentData.fixed_cost = this.state.fixed_cost;
                console.log(updateAgentData);
                this.props.funcUpdateAgent(updateAgentData);
            }
        });
    };

    handleChange = (event) => 
    {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() 
    {
        const { t } = this.props;
        // let display = null;

        let status_option = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.edit-agent")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <form method="post" id="agent_form" className="form-horizontal" onSubmit={this.updateAgentHandler} noValidate>
                                    <ValidationError formName="agent_form" touched={this.state.touched} message={this.state.error_message} />
                                    {this.state.loading
                                    ?
                                        <Skeleton count={4} />
                                    :
                                    <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="username">{t("agent.username")}</label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    className="form-control"
                                                    placeholder={t("agent.username")}
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="referral_code">{t("agent.referral-code")}</label>
                                                <StringInput
                                                    id="referral_code"
                                                    name="referral_code"
                                                    className="form-control"
                                                    placeholder={t("agent.name")}
                                                    value={this.state.referral_code}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("agent.name")}</label>
                                                <StringInput
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder={t("agent.name")}
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="contact_no">{t("agent.contact-no")}</label>
                                                <StringInput
                                                    id="contact_no"
                                                    name="contact_no"
                                                    className="form-control"
                                                    placeholder={t("agent.contact-no")}
                                                    value={this.state.contact_no}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="status">{t("global.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    options={status_option}
                                                    value={this.state.status}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t("agent.email")}</label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder={t("agent.email")}
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                    </>}
                                </form>
                            </div>  
                        </div>
                    </div>
                </div>
                
                
                {/* <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.edit-agent")}</h4>
                            </div>
                            {this.state.loading
                            ?
                                <Skeleton count={4} />
                            :
                                <div className="card-body pt-0">
                                    <form method="post" id="agent_form" className="form-horizontal" onSubmit={this.updateAgentHandler} noValidate>
                                        <ValidationError formName="agent_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="username">{t("agent.username")}</label>
                                                    <StringInput
                                                        id="username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder={t("agent.username")}
                                                        value={this.state.username}
                                                        onChange={this.handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="referral_code">{t("agent.referral-code")}</label>
                                                    <StringInput
                                                        id="referral_code"
                                                        name="referral_code"
                                                        className="form-control"
                                                        placeholder={t("agent.name")}
                                                        value={this.state.referral_code}
                                                        onChange={this.handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">{t("agent.name")}</label>
                                                    <StringInput
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={t("agent.name")}
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="contact_no">{t("agent.contact-no")}</label>
                                                    <StringInput
                                                        id="contact_no"
                                                        name="contact_no"
                                                        className="form-control"
                                                        placeholder={t("agent.contact-no")}
                                                        value={this.state.contact_no}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="status">{t("global.status")}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        options={status_option}
                                                        value={this.state.status}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("agent.email")}</label>
                                                    <StringInput
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={t("agent.email")}
                                                        value={this.state.email}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div> */}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => 
{
    const { agent } = state;
    return {
        agentEditData: agent.agent_edit_data,
        updateAgentResult: agent.agent_update_result,
        validationMessage: agent.validation,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        // editAgent: (id) => dispatch(editAgent(id)),
        // updateAgent: (updateAgentData) => dispatch(updateAgent(updateAgentData)),
        funcEditAgent: (id) => dispatch(actions.editAgent(id)),
        funcUpdateAgent: (updateAgentData) => dispatch(actions.updateAgent(updateAgentData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentAccountDetails));
