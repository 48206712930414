import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const tournamentReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_TOURNAMENT]: (state = initialState, action) => ({
        ...state,
        tournament_data: action.payload
    }),

    [type.POST_TOURNAMENT]: (state, action) => ({
        ...state,
        post_tournament_result: action.payload,
    }),

    [type.SHOW_TOURNAMENT]: (state, action) => ({
        ...state,
        show_tournament_data: action.payload,
    }),

    [type.UPDATE_TOURNAMENT]: (state, action) => ({
        ...state,
        update_tournament_result: action.payload,
    }),

    [type.UPDATE_STATUS_TOURNAMENT]: (state, action) => ({
        ...state,
        update_status_tournament_result: action.payload,
    }),

    [type.DELETE_TOURNAMENT]: (state, action) => ({
        ...state,
        delete_tournament_result: action.payload,
    }),

    [type.GET_MEMBER_GROUP]: (state = initialState, action) => ({
        ...state,
        member_group_data: action.payload
    }),

    [type.GET_TYPE]: (state = initialState, action) => ({
        ...state,
        type_data: action.payload
    }),

    [type.SHOW_WINNERLIST]: (state, action) => ({
        ...state,
        show_winnerlist_data: action.payload,
    }),
});

export default tournamentReducer;