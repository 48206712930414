import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getDropDown, storeItemCategory } from "../../store/ducks/itemCategory/actions";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import StringInput from '../../components/Input/StringInput/StringInput';
import Select from "../../components/Input/Select/Select";

class ItemCategoryCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			item_name: '',
			item_name_cn: '',
			item_slug: '',
			item_type: '',
            dropDown: {
                dd_item_type: []
            },
			error_message: [],
			loading: false,
			touched: false
		}
	}

	componentDidMount() {
		formValidation("item_category_form");
		this.props.getDropDown();
	}
	
	shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {			
			if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
                const dd_item_type = nextProps.dropDown.data.item_type.map(item => ({
                    value: item.vendor_id, 
                    label: t(`item-category.${item.slug}`),
                    key: item.id     
                }));

				this.setState({
                    dropDown: {
                        dd_item_type,
                    },
                });					
			} else {
				this.setState({
                    loading: true,
                    dropDown: {
                        dd_item_type: []
                    }
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
			}
		}

		if (nextProps.store_item_category !== this.props.store_item_category && nextProps.store_item_category !== undefined) {
			if (nextProps.store_item_category.responseCode === 200 && nextProps.store_item_category.msgType === 'success') {
				Swal.fire({
					title: t("global.record-saved"),
					icon: nextProps.store_item_category.msgType,
					confirmButtonText: t("global.ok"),
				}).then((result) => {
					if (result.isConfirmed) {
					this.props.refreshHandler();
					this.props.changePageHandler("listing");
					}
				});
			} else {
				this.setState({
					touched: true,
					errMsg: nextProps.store_item_category.message,
				});
				Swal.close();
			} 
		}
		return true;
	}

	submitHandler = (e) => {        
		e.preventDefault();

		const { t } = this.props;

		Swal.fire({
			title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "",
					allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
				});

				let storeData = {};
				storeData.item_name = this.state.item_name;
				storeData.item_name_cn = this.state.item_name_cn;
				storeData.item_slug = this.state.item_slug;
                storeData.item_type = this.state.item_type;

				this.props.storeItemCategory(storeData);
			}
		})
	}

    onInputChange = (event, id) => {
        // console.log('onInputChange :', event.target.id, event.target.value);
        let newId = (event.id) ? id : event.target.id;
        let newVal = (event.id) ? event.id : event.target.value;

        this.setState({
            [newId]: newVal,
        });
    }

	render() {
		const { t } = this.props;

		return (
			<Auxiliary>
				<div className="breadcrumb-header">
					<div className="d-flex">
						<div className="my-auto">
							<Button 
								typeButton="button"
								classValue="btn btn-back-outline"
								idValue="back"
								buttonName={<i className="angle fe fe-chevron-left"></i>}
								clicked={() => this.props.changePageHandler("listing")}
							/>
						</div>
					</div>
					<div className="my-auto">
						<div className="d-flex">
							<h4 className="content-title mb-0 my-auto ml-2">
								{t("item-category.item-category-list")}
							</h4>
						</div>
					</div>
				</div>

				<div className="row row-sm">
					<div className="col-12">
						<div className="card box-shadow-0">
							<div className="card-header">
								<h4 className="card-title mb-1">
									{t("item-category.add-item-category")}
								</h4>
							</div>
							<div className="card-body pt-0">
								<form
									method="post"
									id="item_category_form"
									className="form-horizontal"
									onSubmit={this.submitHandler}
									noValidate
								>
									<div className="col-md-12">
										<ValidationError
											formName="item_category_form"
											touched={this.state.touched}
											message={this.state.errMsg}
										/>
										{this.state.loading === true ? (
											<Skeleton count={4} />
										) : (
											<div className="row">
											    <div className='col-md-4 col-12 form-group'>
                                                    <label>{t('item-category.item-name')}</label>
                                                    <div className="position-relative">
                                                        <StringInput
                                                            id='item_name'
                                                            name='item_name'
                                                            placeholder={t('item-category.item-name')}
                                                            value={this.state.item_name}
                                                            onChange={this.onInputChange}
                                                            required
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>  

												<div className='col-md-4 col-12 form-group'>
                                                    <label>{t('item-category.item-name-cn')}</label>
                                                    <div className="position-relative">
                                                        <StringInput
                                                            id='item_name_cn'
                                                            name='item_name_cn'
                                                            placeholder={t('item-category.item-name-cn')}
                                                            value={this.state.item_name_cn}
                                                            onChange={this.onInputChange}
                                                            required
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>                                            

                                                <div className='col-md-4 col-12 form-group'>
                                                    <label>{t('item-category.slug')}</label>
                                                    <div className="position-relative">
                                                        <StringInput
                                                            id='item_slug'
                                                            name='item_slug'
                                                            placeholder={t('item-category.slug')}
                                                            value={this.state.item_slug}
                                                            onChange={this.onInputChange}
                                                            required
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-4 col-12 form-group'>
                                                    <label>{t("item-category.item-type")}</label>
                                                    <Select
                                                        id="item_type"
                                                        name="item_type"
                                                        className="form-control"
                                                        getOptionValue={(option) => option.value}
                                                        getOptionLabel={(option) => option.label}
                                                        options={this.state.dropDown.dd_item_type}
                                                        onChange={this.onInputChange}
                                                        value={this.state.item_type}
                                                        emptyValue={true}
                                                        required
                                                    />
                                                </div>

												<div className="col-12 form-group mb-0 justify-content-end">
													<Button
														typeButton="submit"
														classValue="btn btn-blue"
														idValue="submit"
														buttonName={t("global.save")}
													/>
												</div>
											</div>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Auxiliary>
		)
	}
}

const mapStateToProps = (state) => {
	const { itemCategory } = state;
	return {
        store_item_category: itemCategory.store_item_category,
		dropDown: itemCategory.dropDown
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
        storeItemCategory: (data) => dispatch(storeItemCategory(data)),
		getDropDown: () => dispatch(getDropDown())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ItemCategoryCreate));
  