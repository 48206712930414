import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faDice } from "@fortawesome/free-solid-svg-icons";
import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";

class GameCategoryListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = this.props.totalRecords - rowIndex - (this.props.activePage - 1) * 10;
        //(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);

        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    actionFormatter = (cell, row) => {
        let editButton, output;
        if (this.props.permission.includes("game_vendor_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.id}`}
                    idValue={row.gameId === undefined ? 0 : row.id}
                    clicked={(event) => this.props.editHandler(event, row.id)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("game-category.game-name"),
                dataField: "slug",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("game-category.sequence"),
                dataField: "sequence",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("game-category.country-assignment"),
                dataField: "country",
                headerStyle: { width: "115px" },
            },
            { text: t("game-category.game-status"), dataField: "status", headerStyle: { width: "115px" } },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "65px" } },
        ];

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stVal = permission.includes("game_vendor_update_status") ? (
                    <div className={`select-input datatable-select-${val.status === 1 ? "active" : "inactive"}`}>
                        <select
                            className="form-control datatable-select"
                            id={val.id}
                            name={val.id}
                            onChange={(event) => this.props.updateStatusHandler(event, "game")}
                            value={val.status === null ? 0 : val.status}>
                            <option value="1">{t("global.active")}</option>
                            <option value="0">{t("global.inactive")}</option>
                        </select>
                        <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                    </div>

                ) : (
                    <span className={`badge-pill badge-${val.country_id === 1 ? "success" : "danger"}`}>
                        {val.status && val.status === 1 ? t("global.active") : t("global.inactive")}
                    </span>
                );
                let countryVal = permission.includes("game_vendor_update_status") ? (
                    <div className={`select-input datatable-select-${val.country_id === null ? "inactive" : "active"}`}>
                        <select
                            className="form-control datatable-select"
                            id={val.id}
                            name={val.id}
                            onChange={(event) => this.props.updateStatusHandler(event, "country")}
                            value={val.country_id === null ? 0 : 1}>
                            <option value="1">{t("global._yes")}</option>
                            <option value="0">{t("global._no")}</option>
                        </select>
                        <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                    </div>

                ) : (
                    <span className={`badge-pill badge-${val.country_id === 1 ? "success" : "danger"}`}>
                        {val.country_id && val.country_id === 1 ? t("global._yes") : t("global._no")}
                    </span>
                );
                return {
                    no: idx,
                    id: val.id,
                    slug: t(`game.${val.slug}`),
                    sequence: val.sequence,
                    country: countryVal,
                    status: stVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    {/* <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("game-category.title")}</h4>
                        </div>
                    </div> */}

                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler('listing')}
                                />
                                <div className="member-name">
                                    <FontAwesomeIcon icon={faDice} /> {t(`vendor.${this.props.vendorSlug}`)}
                                    {/* <Link to="/game_vendor">  */}
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-xl-12">
                        {/* <div className="card card-body card-primary"> */}
                        {/* <div className="">
                               
                                    <div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}> */}
                        {/* <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("game-category.game-vendor")}</label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="vendor"
                                                    name="vendor"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.vendor}>
                                                    <option value=""></option>
                                                    {this.props.ddVendor &&
                                                        this.props.ddVendor.map((val, idx) => {
                                                            return (
                                                                <option key={val.id} value={val.id}>
                                                                    {t(`vendor.${val.slug}`)}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div> */}
                        {/* <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("game-category.game-name")}</label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.filter.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div> */}
                        {/* <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t(`global.status`)}</label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="status"
                                                    name="status"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.status}>
                                                    <option value=""></option>
                                                    <option value="1">{t("global.active")}</option>
                                                    <option value="0">{t("global.inactive")}</option>
                                                </select>
                                            </div>
                                        </div> */}

                        {/* <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div> */}
                        {/* </div>
                            </div> */}

                        {/* <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div> */}


                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("game-category.listing")}</h4>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        // searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, 'searchValue')}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(GameCategoryListing);
