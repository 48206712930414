import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import Skeleton from "react-loading-skeleton";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import { getGameFavReport } from "../../store/ducks/gameFav/actions";

class GameFav extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data_category: [],
            data_vendor: [],
            data_game: [],
            data_player: [],
            category_details: {},
            vendor_details: {},
            game_details: [],
            player_details: {}
        }
    }

    componentDidMount(){
        this.props.getGameFavReport();
    }
    
    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.game_fav_report !== undefined && nextProps.game_fav_report !== this.props.game_fav_report){
            if(nextProps.game_fav_report.data.responseCode === 200 && nextProps.game_fav_report.data.msgType === "success"){
                this.setState({
                    loading: false,
                    data_category: nextProps.game_fav_report.data.data.byCategory,
                    data_vendor: nextProps.game_fav_report.data.data.byVendor,
                    data_game: nextProps.game_fav_report.data.data.byGame,
                    data_player: nextProps.game_fav_report.data.data.byPlayer,
                    category_details: nextProps.game_fav_report.data.data.byCategoryDetails,
                    vendor_details: nextProps.game_fav_report.data.data.byVendorDetails,
                    game_details: nextProps.game_fav_report.data.data.byGameDetails,
                    player_details: nextProps.game_fav_report.data.data.byPlayerDetails
                });
            }
        }
        return true;
    }

    render(){
        const { t } = this.props;

        const options1 = {
            legend: {
                position: "top",
                labels: {
                    boxWidth: 0
                },
                display: false
            },
            title: {
                text: t('gamefav.by-category'),
                display: true
            },
            scales: {
                yAxes: [{
                    type:'linear',
                    min: 0,
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: t('gamefav.total-fav-games'),    
                    }
                }]
            }
        };
        const options2 = {
            legend: {
                position: "top",
                display: false
            },
            title: {
                text: t('gamefav.by-vendor'),
                display: true
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 10
                    }
                }],
                yAxes: [{
                    type:'linear',
                    min: 0,
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: t('gamefav.total-fav-games')
                    }
                }]
            }
        };
        const options3 = {
            legend: {
                position: "top",
                display: false
            },
            title: {
                text: t('gamefav.by-game'),
                display: true
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 8
                    }
                }],
                yAxes: [{
                    type:'linear',
                    min: 0,
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: t('gamefav.total-fav-member')
                    }
                }]
            }
        };
        const options4 = {
            legend: {
                position: "top",
                display: false
            },
            title: {
                text: t('gamefav.by-player'),
                display: true
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 10
                    }
                }],
                yAxes: [{
                    type:'linear',
                    min: 0,
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: t('gamefav.total-fav-games')
                    }
                }]
            }
        };
          
        const data1 = {
            labels: this.state.data_category.map((val) => t(`game-type.${val.game_type}`)),
            datasets: [{
                label: t('gamefav.totals'),
                data: this.state.data_category.map((val) => val.total_fav),
                backgroundColor: this.state.data_category.map((idx) => `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)})`)
            }]
        };
        const data2 = {
            labels: this.state.data_vendor.map((val) => t(`vendor.${val.slug}`)),
            datasets: [{
                label: t('gamefav.totals'),
                data: this.state.data_vendor.map((val) => val.total_fav),
                backgroundColor: this.state.data_vendor.map((idx) => `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)})`)
            }]
        };
        const data3 = {
            labels: this.state.data_game.map((val) => val.game_title),
            datasets: [{
                label: t('gamefav.totals'),
                data: this.state.data_game.map((val) => val.total_fav),
                backgroundColor: this.state.data_game.map((idx) => `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)})`)
            }]
        }
        const data4 = {
            labels: this.state.data_player.map((val) => val.username),
            datasets: [{
                label: t('gamefav.totals'),
                data: this.state.data_player.map((val) => val.total_fav),
                backgroundColor: this.state.data_player.map((idx) => `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)})`)
            }]
        };

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("gamefav.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                {this.state.loading ? (<Skeleton count={4} />)
                : (
                    <div className="row row-sm">
                        <div className="col-6 mg-b-10">
                            <div className="card h-100 mg-b-20">
                                <div className="card-body">
                                    <div className="main-content-label mg-b-5">{t('gamefav.top-favorite-category')}</div>
                                    <MDBContainer>
                                        <Bar options={options1} data={data1} />
                                    </MDBContainer>
                                </div>
                                {Object.keys(this.state.category_details).length > 0 && (
                                <div className="card-footer">
                                    <div><u>{t('gamefav.top-fav-cat-sub-title')}</u></div>
                                    {(() => {
                                        let arr = []; 
                                        let temp = Object.assign({}, this.state.category_details);
                                        for(const key in temp){
                                            arr.push(
                                                <div>{t(`game-type.${temp[key]['category']}`)}: <b>{t(`vendor.${temp[key]['vendor']}`)}</b></div>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 mg-b-10">
                            <div className="card h-100 mg-b-20">
                                <div className="card-body">
                                    <div className="main-content-label mg-b-5">{t('gamefav.top-favorite-vendor')}</div>
                                    <MDBContainer>
                                        <Bar options={options2} data={data2} />
                                    </MDBContainer>
                                </div>
                                {Object.keys(this.state.vendor_details).length > 0 && (
                                <div className="card-footer">
                                    <div><u>{t('gamefav.top-fav-vendor-sub-title')}</u></div>
                                    {(() => {
                                        let arr = [];
                                        for(const key in this.state.vendor_details){
                                            arr.push(
                                                <div>{t(`vendor.${key}`)}: <b>{this.state.vendor_details[key]['game']}</b></div>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 mg-b-10">
                            <div className="card h-100 mg-b-20">
                                <div className="card-body">
                                    <div className="main-content-label mg-b-5">{t('gamefav.top-favorite-game')}</div>
                                    <MDBContainer>
                                        <Bar options={options3} data={data3} />
                                    </MDBContainer>
                                </div>
                                {this.state.game_details.length > 0 && (
                                <div className="card-footer">
                                    <div><u>{t('gamefav.top-fav-game-sub-title')}</u></div>
                                    {(() => {
                                        let arr = [];
                                        this.state.game_details.forEach((val) => {
                                            arr.push(
                                                <div>{t(`vendor.${val.slug}`)}: <b>{val.game_title}</b></div>
                                            );
                                        });
                                        return arr;
                                    })()}
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 mg-b-10">
                            <div className="card h-100 mg-b-20">
                                <div className="card-body">
                                    <div className="main-content-label mg-b-5">{t('gamefav.top-favorite-player')}</div>
                                    <MDBContainer>
                                        <Bar options={options4} data={data4} />
                                    </MDBContainer>
                                </div>
                                {Object.keys(this.state.player_details).length > 0 && (
                                <div className="card-footer">
                                    <div><u>{t('gamefav.top-fav-player-sub-title')}</u></div>
                                    {(() => {
                                        let arr = [];
                                        for(const key in this.state.player_details){
                                            arr.push(
                                                <div>{this.state.player_details[key]['username']}: <b>{t(`vendor.${this.state.player_details[key]['vendor']}`)}</b></div>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPermission: state.currentUser.data.permission,
        game_fav_report: state.gameFav.game_fav_report
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getGameFavReport: () => dispatch(getGameFavReport())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameFav));