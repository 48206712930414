import axios from "../../../common/config/axios";
import * as type from "./types";

export const getAgents = (data) => (dispatch, getState) => 
{
    data.country_id = getState().currentUser.data.country_id;
    axios
    .get("agent/list", {params: data})
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_LIST,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Agent Error", err);
        dispatch({
            type: type.AGENT_LIST,
            value: [],
        });
    });
};

export const storeAgent = (data) => (dispatch, getState) => 
{
    data.country_id = getState().currentUser.data.country_id;
    axios
    .post("agent/store", data)
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_STORE,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Store Agent Error", err);
        // if (err.status === 422) {
        //     dispatch({
        //         type: type.AGENT_STORE,
        //         value: err.data,
        //     });
        // }
    });
};

export const showAgent = (id) => (dispatch) => 
{
    axios
    .get("agent/show", {params: {id: id}})
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_SHOW,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Show Agent Error", err);
        dispatch({
            type: type.AGENT_SHOW,
            value: err.response,
        });
    });
};

export const editAgent = (id) => (dispatch) => 
{
    axios
    .get("agent/edit", {params: {id: id}})
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_EDIT,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Edit Agent Error", err);
        dispatch({
            type: type.AGENT_EDIT,
            value: err.data,
        });
    });
};

export const updateAgent = (data) => (dispatch, getState) => 
{
    data.country_id = getState().currentUser.data.country_id;
    axios
    .post("agent/update", data)
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_UPDATE,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Update Agent Error", err);
    });
};

export const updateAgentStatus = (data) => (dispatch) => 
{
    axios
    .post("agent/change_status", data)
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_UPDATE_STATUS,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Update Agent Status Error", err);
    });
};

export const updateAgentLoginPermission = (data) => (dispatch) => 
{
    axios
    .post("agent/change_login_permission", data)
    .then((res) => {
        dispatch({
            type: type.AGENT_UPDATE_LOGIN_PERMISSION,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Update Agent Login Permission Error", err);
    });
};

export const getDetails = (id) => dispatch =>
{   
    if(id > 0)
    {
        axios.get('agent/setting_details',{params: {"id": id}})
        .then(function (response) 
        {
            dispatch ({
                type: type.AGENT_SETTING_DETAILS,
                value: response.data
            })
        })
        .catch(function (err) 
        {
            console.log("getDetails Error", err);
        });
    }
};

export const updateSetting = (data) => dispatch =>
{
    // console.log(data);
    axios.post('agent/update_setting', data)
    .then(function (response) 
    {
        dispatch ({
            type: type.AGENT_UPDATE_SETTING,
            value: response.data
        })
    })
    .catch(err => 
    {
        console.log("updateSetting Error", err);
    });
};

export const downline = (props) => (dispatch) => 
{
    axios.get('agent/downline', {params: props})
    .then(function (response) 
    {
        dispatch ({
            type: type.AGENT_DOWNLINE,
            value: response.data
        })
    })
    .catch(function (err) 
    {
        console.log("downline Error", err);
    });
};

export const updateResetPassword = (data) => (dispatch, getState) => 
{
    data.country_id = getState().currentUser.data.country_id;
    axios
    .post("agent/update_reset_password", data)
    .then((res) => 
    {
        dispatch({
            type: type.AGENT_UPDATE_RESET_PASSWORD,
            value: res.data,
        });
    })
    .catch((err) => 
    {
        console.log("Update Agent Error", err);
    });
};

export const getAgentWalletBalance = (id) => (dispatch) => 
{
    axios
        .get("agent/get_agent_balance", {
            params: {
                agent_id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.AGENT_WALLET_BALANCE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Error" + err);
            dispatch({
                type: type.AGENT_WALLET_BALANCE,
                payload: err.response,
            });
        });
};

export const storeWalletAdjustment = (data) => (dispatch) => 
{
    axios
        .post("agent/store_wallet_adjustment", data)
        .then((res) => {
            dispatch({
                type: type.AGENT_STORE_WALLET_ADJUSTMENT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Error" + err);
        });
};

// export const clearErrors = () => (dispatch) => {
//     dispatch({ type: type.CLEAR_VALIDATION });
// };

export const initAgentShowInitialLoading = (data) => (dispatch) => {
    dispatch({
        type: type.INIT_AGENT_SHOW_INITIAL_LOADING,
        payload: data,
    });
}

export const setAgentShowInitialLoading = (data) => (dispatch) => {
    dispatch({
        type: type.SET_AGENT_SHOW_INITIAL_LOADING,
        page: data,
    });
}

export const setAgentShowLocalState = (data) => (dispatch) => {
    let currentPage = data.currentPage;
    switch (currentPage) {
        case "agent_info":
            dispatch({
                type: type.SET_AGENT_INFO_LOCAL_STATE,
                payload: data,
            });
            break;
        case "transaction_history":
            dispatch({
                type: type.SET_AGENT_TRANSACTION_HISTORY_LOCAL_STATE,
                payload: data,
            });
            break;
        case "wallet_tracker":
            dispatch({
                type: type.SET_AGENT_WALLET_TRACKER_LOCAL_STATE,
                payload: data
            });
            break;
        default:
            console.log("Warning - Kindly added local state to redux");
            return true;
    }
}

export const getAgentTransactionHistory = (props) => (dispatch) => 
{
    axios.get('agent/transaction_history', {params: props})
    .then(function (response) 
    {
        dispatch ({
            type: type.AGENT_TRANSACTION_HISTORY,
            value: response.data
        })
    })
    .catch(function (err) 
    {
        console.log("Agent Transaction History Error", err);
    });
};

export const getFilterData = (props) => (dispatch) => 
{
    axios.get('agent/transaction_history_filter_data', {params: props})
    .then(function (response) 
    {
        dispatch ({
            type: type.AGENT_FILTER_DATA,
            value: response.data
        })
    })
    .catch(function (err) 
    {
        console.log("Transaction History Filter Error", err);
    });
};

export const getAgentWalletTracker = (props) => (dispatch) => 
{
    axios.get('agent/wallet_tracker', {params: props})
    .then(function (response) 
    {
        dispatch ({
            type: type.AGENT_WALLET_TRACKER,
            value: response.data
        })
    })
    .catch(function (err) 
    {
        console.log("Transaction History Filter Error", err);
    });
};