import React, { Component } from "react";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";

class MetaTagListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                            </div>
                        </div>
                        {this.props.permission.includes("seo_meta_tags_create") && (
                            <div className="d-flex my-xl-auto right-content">
                                <div className="mb-3 mb-xl-0">
                                    <div className="btn-group">
                                        <Button
                                            typeButton="button"
                                            idValue="metaTag"
                                            classValue="btn btn-primary"
                                            clicked={() => this.props.changePageHandler("create")}
                                            buttonName={t("meta-tags.add-meta-tag")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("meta-tags.meta-tags-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="metaTagId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MetaTagListing);
