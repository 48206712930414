import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('member_promotion/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const update = (data) => dispatch =>
{
    axios.post('member_promotion/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (data) => dispatch => {
  axios.get('member_promotion/details', {params: data})
  .then(function (response) {
    dispatch({
      type: actionType.DETAILS,
      value: response.data
    })
  })
  .catch(err => {

  });
}
