import React, {Component} from 'react';
import { connect } from 'react-redux';
// import Swal from 'sweetalert2';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import BankBalanceAdjustmentListing from './BankBalanceAdjustmentListing';
import TransactionDetails from '../Transaction/TransactionDetails';
import BankBalanceAdjustmentTransfer from './BankBalanceAdjustmentTransfer';
import BankBalanceAdjustmentCash from './BankBalanceAdjustmentCash';

import * as actions from '../../store/ducks/bankBalanceAdjustment/actions';
import { filterErrorHandler } from '../../hoc/Shared/utility';

const initialInputState =
{
    /* form input value */
    bank_acc: '',
    transaction_type: '',
    transaction_date: '',
    start_date: '',
    end_date: '',
};

class BankBalanceAdjustment extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                transactionType: [],
                status: [],
                bankAccount: [],
            },

            /* transction details parameter */
            detailsParam: {},

            /* skeleton loading */
            loading: true,
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.searchKeyPressHandler = this.searchKeyPressHandler.bind(this);
    }

    componentDidMount()
    {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
        this.props.funcGetDropDown();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        // console.log('shouldComponentUpdate :', nextProps.dropDown);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {                
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                this.setState({
                    totalRecords: 0,
                    data: [],
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.rs.message);
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
                this.setState({
                    dropDown:
                    {
                        transactionType: nextProps.dropDown.data.transaction_type,
                        status: nextProps.dropDown.data.status,
                        bankAccount: nextProps.dropDown.data.bank_account,
                    }
                });
            } else{
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    // loadingHandler = () =>
    // {
    //     Swal.fire({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });
    // }
    onInputChange = (event) =>
    {
        if(event.target.id==='search')
        {
            this.setState({
                searchValue: event.target.value
            });
        }
        else
        {
            let newFilter = Object.assign({}, this.state.filter);
            newFilter[event.target.id] = event.target.value;
            if(event.target.id==='transaction_date')
            {
                const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
                newFilter['start_date'] = (date) ? date[0] : '';
                newFilter['end_date'] = (date) ? date[1] : '';
            }
            this.setState({
                filter: newFilter
            });
        }
    }

    searchKeyPressHandler = (event) =>
    {
        //Checking press the enter only perform search action
        if(event.key === 'Enter')
        {
            this.refreshHandler();
        }
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
            order: ''
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: ''
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
        });
        return listingArr;
    }

    detailsHandler = (e, id) =>
    {
        // console.log('detailsHandler :', id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <BankBalanceAdjustmentListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        ddTransactionType={this.state.dropDown.transactionType}
                        ddBankAccount={this.state.dropDown.bankAccount}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                }

                {this.state.pageShow==='transfer' &&
                    <BankBalanceAdjustmentTransfer
                        refreshHandler={() => this.refreshHandler()}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='cash' &&
                    <BankBalanceAdjustmentCash
                        refreshHandler={() => this.refreshHandler()}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.bankBalanceAdjustment.rs,
        dropDown: state.bankBalanceAdjustment.dropDown,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(BankBalanceAdjustment));