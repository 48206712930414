import React, { Component } from "react";
import { connect } from "react-redux";
import { getAdminLogs, setLoading, setDataTableFilter } from "../../../store/ducks/log/actions";
import { withNamespaces } from "react-i18next";
import DataTable from "../../../components/Datatable/DataTable";
import Swal from "sweetalert2";

class AdminLogListing extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalRecords: 0,
            totalPages: 0,
        };
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[AdminLogListing] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.adminLogData !== this.props.adminLogData && nextProps.adminLogData !== undefined) {
            if (nextProps.adminLogData.status === 200 && nextProps.adminLogData.data.msgType === "success") {
                this.setState({
                    data: nextProps.adminLogData.data.data.result.row,
                    totalRecords: nextProps.adminLogData.data.data.result.pagination.total_record,
                    totalPages: nextProps.adminLogData.data.data.result.pagination.total_page,
                });
                this.setLoadingHandler(false, false);
            } else if (nextProps.adminLogData.data && nextProps.adminLogData.data.responseCode === 422) {
                this.setState({
                    data: [],
                    totalRecords: 0,
                    totalPages: 0,
                });
                if (nextProps.adminLogData !== undefined && nextProps.adminLogData.data.hasOwnProperty("message")) {
                    const { t } = this.props;
                    let msg = null;
                    msg = "<ul>";
                    nextProps.adminLogData.data.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";
                    Swal.fire({
                        icon: "error",
                        title: t("global.error"),
                        html: msg,
                        showConfirmButton: true,
                    }).then((result) => {
                        this.setLoadingHandler(true, false);
                    });
                }
            } else {
                return false;
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    setLoadingHandler = (pageLoading, dataLoading) => {
        const data = {};
        data.pageLoading = pageLoading;
        data.dataLoading = dataLoading;
        this.props.setLoading(data);
    };

    getReduxFilter = () => {
        const listingArr = {};
        listingArr.page = this.state.page;
        listingArr.limit = this.state.limit;
        listingArr.order = this.state.order;
        Object.entries(this.props.filterList.filter).forEach((val) => {
            listingArr[val[0]] = val[1];
        });
        return listingArr;
    };

    fetchData = () => {
        const reduxFilterList = this.getReduxFilter();
        this.props.getAdminLogs(reduxFilterList);
        this.setLoadingHandler(false, true);
    };

    setDataTableFilterHandler = () => {
        const listingArr = {};
        let availablePage = this.state.page;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.page >= availablePage) && this.setState({ page: availablePage});
        }

        listingArr.page = (this.state.page >= availablePage) ? availablePage : this.state.page;
        listingArr.limit = this.state.limit;
        listingArr.order = this.state.order;
        listingArr.filter = this.props.filterList.filter;
        this.props.setDataTableFilter(listingArr);
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: val,
                data: [],
            },
            () => {
                this.setDataTableFilterHandler();
                setTimeout(() => { this.fetchData() }, 0);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.props.filterList.page - 1) * this.props.filterList.limit + (rowIndex + 1);
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.props.filterList.page - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;
        this.setState({ order: JSON.stringify(orderArry) }, () => {
            this.setDataTableFilterHandler();
            this.fetchData();
        });
    };

    render() {
        const { t } = this.props;
        let dataListing = [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("admin.username"), dataField: "username", sort: true, onSort: this.onSort },
            { text: t("admin.email"), dataField: "email", sort: true, onSort: this.onSort },
            { text: t("admin.group"), dataField: "roleTitle", sort: true, onSort: this.onSort },
            { text: t("global.created-time"), dataField: "createdTime", sort: true, onSort: this.onSort },
            { text: t("global.updated-time"), dataField: "updatedTime", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "action", sort: true, onSort: this.onSort, headerStyle: { width: "120px" } },
            // { text: t("transaction.update-by"), dataField: "updatedBy", sort: true, onSort: this.onSort },
        ];

        if (!this.props.loading.pageLoading && !this.props.loading.dataLoading && this.props.adminLogData.data.responseCode === 200) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    no: idx,
                    username: val.username,
                    email: val.email,
                    roleTitle: val.roleTitle,
                    createdTime: val.createdTime,
                    updatedTime: val.updatedTime,
                    action: val.action,
                    // updatedBy: val.updatedBy,
                };
            });
        }
        return (
            <>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex justify-content-between">
                                <h4 className="card-title mg-b-0">{t("log-type.admin-log")}</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-hover datatable">
                                {!this.props.loading.pageLoading ? (
                                    <DataTable
                                        keyField="no"
                                        data={dataListing}
                                        columns={columns}
                                        activePage={this.props.filterList.page}
                                        limit={this.props.filterList.limit}
                                        totalItems={this.state.totalRecords}
                                        paginationHandler={(val) => this.datatablesChangeHandler(val, "page")}
                                        sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading.dataLoading}
                                    />
                                ) : (
                                    <div className="text-center">{t("log.click-filter")}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { log } = state;
    return {
        adminLogData: log.admin_log_data,
        filterList: log.filterList,
        loading: log.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminLogs: (data) => dispatch(getAdminLogs(data)),
        setLoading: (data) => dispatch(setLoading(data)),
        setDataTableFilter: (data) => dispatch(setDataTableFilter(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AdminLogListing));
