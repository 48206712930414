import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { formValidation, clearFormValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";

import * as actions from '../../store/ducks/luckySpinHistory/actions';

class LuckySpinHistoryEdit extends Component 
{
    constructor() 
    {
        super();
        this.state = 
        {
            input: [],
            loading: true,
            error_message: [],
            touched: false,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() 
    {
        formValidation("lucky_spin_history_form");
        if(this.props.detailsParam && this.props.detailsParam.id)
        {
            this.props.funcGetDetails(this.props.detailsParam.id);
        }
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details!==undefined)
        {
            if(nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success')
            {
                this.setState({
                    input: nextProps.details.data.result,
                    loading: false,
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update!==undefined)
        {
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => 
                {
                    clearFormValidation('lucky_spin_history_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }

        return true;
    }

    submitHandler = (e) =>
    {
        const { t } = this.props;
        e.preventDefault();
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            confirmButtonText: t("global.update"),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};
                data.id = this.state.input.id;
                data.reference_code = this.state.input.reference_code;
                
                this.props.funcUpdate(data);
            }
        });
    }

    onInputChange = (event) => 
    {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.id] = event.target.value;
        this.setState({
            input: newInput
        });
    }

    render() 
    {
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header justify-content-between'>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0'>{t('side-bar.lucky_spin_history')}</h4>
                        </div>
                    </div>
                    <div className='d-flex my-xl-auto right-content'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-secondary' idValue='back' buttonName={t('global.back-to-list')} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.lucky_spin_history_update')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='lucky_spin_history_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='lucky_spin_history_form' touched={this.state.touched} message={this.state.errMsg}/>
                                    {this.state.loading
                                    ? 
                                    <Skeleton count={3}/> 
                                    :
                                    <>
                                        <div className='row'>
                                            <div className='col-md-4 col-12 form-group'>
                                                <label htmlFor='min_bet'>{t('lucky-spin-history.prize')}</label>
                                                <StringInput
                                                    id='prize'
                                                    name='prize'
                                                    className='form-control'
                                                    value={this.state.input.prize ? (t(`lucky-spin-prize.${this.state.input.prize}`)) : ''}
                                                    disabled
                                                />
                                            </div>
                                            <div className='col-md-4 col-12 form-group'>
                                                <label htmlFor='min_bet'>{t('lucky-spin-history.category')}</label>
                                                <StringInput
                                                    id="category"
                                                    name="category"
                                                    className='form-control'
                                                    value={this.state.input.category ? (t(`lucky-spin-prize.${this.state.input.category}`)) : ''}
                                                    disabled
                                                />
                                            </div>
                                            <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='min_bet'>{t('lucky-spin-history.reference-code')}</label>
                                                <StringInput
                                                    id="reference_code"
                                                    name="reference_code"
                                                    className='form-control'
                                                    value={this.state.input.reference_code || ''}
                                                    onChange={(event) => this.onInputChange(event)}
                                                    required={true}
                                                />
                                            </div>
                                            <div className='col-md-12 form-group mb-0 justify-content-end'>
                                                <div>
                                                    {(permission.includes('lucky_spin_history_update')) &&
                                                        <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => 
{
    return {
        details: state.luckySpinHistory.details,
        update: state.luckySpinHistory.update,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(LuckySpinHistoryEdit));
