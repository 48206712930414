import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";

import { getHistoryDetails, updateHistoryDetails } from "../../store/ducks/rewardItem/actions";

class RewardItemHistoryEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: [],
            name: "",
            contact_no: "",
            address: "",
            reference_code: "",
            touched: false,
            error_message: [],
            details: [],
        }
        this.changeInputHandler = this.changeInputHandler.bind(this);
    }

    componentDidMount(){
        const data = {};
        data.id = this.props.editId;
        this.props.getHistoryDetails(data);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.history_details !== undefined && nextProps.history_details !== this.props.history_details){
            if(nextProps.history_details.responseCode === 200 && nextProps.history_details.msgType === "success"){
                this.setState({
                    loading: false,
                    data: nextProps.history_details.data.result,
                    name: nextProps.history_details.data.address ? nextProps.history_details.data.address.name : "",
                    contact_no: nextProps.history_details.data.address ? nextProps.history_details.data.address.contact_no : "",
                    address: nextProps.history_details.data.address ? nextProps.history_details.data.address.full_address : "",
                    reference_code: nextProps.history_details.data.result.referenceCode,
                    details: nextProps.history_details.data.result,
                });
            }
        }

        if(nextProps.history_update !== undefined && nextProps.history_update !== this.props.history_update){
            if(nextProps.history_update.responseCode === 200 && nextProps.history_update.msgType === "success"){
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.history_update.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
            else if(nextProps.history_update.responseCode === 422){
                this.setState({
                    touched: true,
                    error_message: nextProps.history_update.message,
                });
                Swal.close();
            }
            else{
                return false;
            }
        }

        return true;
    }

    updateHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.id = this.props.editId;
                data.reference_code = this.state.reference_code;
                // console.log("Form Submit:", data);
                this.props.updateHistoryDetails(data);
            }
        });
    }

    changeInputHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        const { t } = this.props;
        let data = this.state.data ? this.state.data : [];
        let address = this.state.address ? this.state.address : "";
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("reward-item.history-details")}</h4>
                        </div>
                    </div>
                </div>
                {this.state.loading ? (
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("reward-item.edit-history-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("reward-item.edit-history-details")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="daily_mission_form" className="form-horizontal" onSubmit={this.updateHandler} noValidate>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ValidationError formName="daily_mission_form" touched={this.state.touched} message={this.state.error_message} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <img src={data.itemImage} alt={data.itemTitle} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="member">{t("reward-item.member")}</label>
                                                    <StringInput
                                                        id="member"
                                                        name="member"
                                                        className="form-control"
                                                        placeholder={t("reward-item.member")}
                                                        value={data.member ? data.member : ""}
                                                        onChange={this.changeInputHandler}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="item">{t("reward-item.item")}</label>
                                                    <StringInput
                                                        id="item"
                                                        name="item"
                                                        className="form-control"
                                                        placeholder={t("reward-item.item")}
                                                        value={data.itemTitle ? data.itemTitle : ""}
                                                        onChange={this.changeInputHandler}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="address">{t("reward-item.address")}</label>
                                                    <StringInput
                                                        id="address"
                                                        name="address"
                                                        className="form-control"
                                                        placeholder={t("reward-item.address")}
                                                        value={address}
                                                        onChange={this.changeInputHandler}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="item">{t("gold_digger.name")}</label>
                                                    <StringInput
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={t("gold_digger.name")}
                                                        value={this.state.name}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="item">{t("agent.contact-no")}</label>
                                                    <StringInput
                                                        id="contact_no"
                                                        name="contact_no"
                                                        className="form-control"
                                                        placeholder={t("agent.contact-no")}
                                                        value={this.state.contact_no}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="reference_code">{t("reward-item.reference-code")}</label>
                                                    <StringInput
                                                        id="reference_code"
                                                        name="reference_code"
                                                        className="form-control"
                                                        value={this.state.reference_code}
                                                        // value={
                                                        //     (Number(this.state.details.category) === 2)
                                                        //     ?
                                                        //     (this.state.reference_code === null) ? `2023-02-06_${this.state.details.id}_${this.state.details.member}` : this.state.reference_code
                                                        //     :
                                                        //     this.state.reference_code
                                                        // }
                                                        onChange={this.changeInputHandler}
                                                        disabled={Number(this.state.details.category) === 2 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                {(Number(this.state.details.category) === 2 && this.state.reference_code !== null)
                                                ?
                                                null
                                                :
                                                <Button 
                                                    typeButton="submit" 
                                                    classValue="btn btn-blue" 
                                                    idValue="submit" 
                                                    buttonName={(Number(this.state.details.category) === 2 && this.state.reference_code === null) ? t("global.approve") : t("global.save")} 
                                                />}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        history_details: state.rewardItem.history_details,
        history_update: state.rewardItem.history_update
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHistoryDetails: (data) => dispatch(getHistoryDetails(data)),
        updateHistoryDetails: (data) => dispatch(updateHistoryDetails(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RewardItemHistoryEdit));