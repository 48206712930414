import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { createDailyMissionSetting } from "../../store/ducks/dailyMission/actions";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
// import StringInput from "../../components/Input/StringInput/StringInput";
import ValidationError from "../../components/ValidationError/ValidationError";
import TimePicker from "react-time-picker";
import NumberInput from "../../components/Input/NumberInput/NumberInput";

class DailyMissionSettingCreate extends Component {
    constructor(props){
        super(props);
        this.state = {
            days_per_cycle: 0, 
            daily_start_time: "", 
            daily_end_time: "", 
            bonus_setting: [{ 
                valid_turnover: 0,
                daily_bonus: 0,
                extra_bonus: 0
            }],
            touched: false,
            error_message: []
        }
        this.changeInputHandler = this.changeInputHandler.bind(this);
        this.changeStartTimeHandler = this.changeStartTimeHandler.bind(this);
        this.changeEndTimeHandler = this.changeEndTimeHandler.bind(this);
        this.addBonusHandler = this.addBonusHandler.bind(this);
        this.changeBonusHandler = this.changeBonusHandler.bind(this);
        this.removeBonusHandler = this.removeBonusHandler.bind(this);
    }

    componentDidMount(){
        formValidation("daily_mission_form");
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.daily_mission_setting_create!==this.props.daily_mission_setting_create){
            if(nextProps.daily_mission_setting_create.data.responseCode===200){
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.daily_mission_setting_create.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
            else if(nextProps.daily_mission_setting_create.data.responseCode===422){
                this.setState({
                    touched: true,
                    error_message: nextProps.daily_mission_setting_create.data.message,
                });
                Swal.close();
            }
            else{
                return false;
            }
        }
        return true;
    }

    createSettingHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let valid_turnover_arr = [];
                let daily_bonus_arr = [];
                let extra_bonus_arr = [];

                this.state.bonus_setting.forEach((item, idx) => {
                    // bonus_setting_arr.push([item.valid_turnover, item.daily_bonus, item.extra_bonus]);
                    valid_turnover_arr.push(item.valid_turnover);
                    daily_bonus_arr.push(item.daily_bonus);
                    extra_bonus_arr.push(item.extra_bonus);
                })

                let data = {};
                data.days_per_cycle = this.state.days_per_cycle;
                data.daily_start_time = this.state.daily_start_time;
                data.daily_end_time = this.state.daily_end_time;
                data.valid_turnover_arr = valid_turnover_arr;
                data.daily_bonus_arr = daily_bonus_arr;
                data.extra_bonus_arr = extra_bonus_arr;
                // console.log("Form Submit:", data);
                this.props.createDailyMissionSetting(data);
            }
        });
    }

    changeInputHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeStartTimeHandler = (date) => {
        if(date!==null){
            this.setState({
                daily_start_time: date
            });
        }
    }

    changeEndTimeHandler = (date) => {
        if(date!==null){
            this.setState({
                daily_end_time: date
            });
        }
    }

    addBonusHandler = (e) => {
        // if(this.state.bonus_setting.length < 10){
            this.setState((prevState) => ({
                bonus_setting: [...prevState.bonus_setting, {valid_turnover: 0, daily_bonus: 0, extra_bonus: 0}]
            }));
        // }
    }

    changeBonusHandler = (e) => {
        // console.log(e);
        let bonusList = [...this.state.bonus_setting];
        bonusList[e.target.dataset.id][e.target.name] = e.target.value;
        this.setState({
            bonus_setting: bonusList
        });
    }

    removeBonusHandler = (idx) => {
        // console.log(idx);
        this.setState({
            bonus_setting: this.state.bonus_setting.filter((item, index) => idx !== index)
        });
    }

    render(){
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("daily-mission-setting.title")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("daily-mission-setting.add-daily-mission")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="daily_mission_form" className="form-horizontal" onSubmit={this.createSettingHandler} noValidate>
                                    <ValidationError formName="daily_mission_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="days_per_cycle">{t("daily-mission-setting.days-per-cycle")}</label>
                                                <NumberInput
                                                    id="days_per_cycle"
                                                    name="days_per_cycle"
                                                    min={1}
                                                    max={7}
                                                    placeholder={t("daily-mission-setting.days-per-cycle")}
                                                    value={this.state.days_per_cycle}
                                                    onChange={this.changeInputHandler}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>{t("daily-mission-setting.daily-start-time")}</label>
                                                <TimePicker
                                                    name="daily_start_time"
                                                    className="form-control"
                                                    format="HH:mm:ss"
                                                    disableClock={true}
                                                    onChange={this.changeStartTimeHandler}
                                                    value={this.state.daily_start_time}
                                                    maxDetail="second"
                                                    clearIcon={null}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>{t("daily-mission-setting.daily-end-time")}</label>
                                                <TimePicker
                                                    name="daily_end_time"
                                                    className="form-control"
                                                    format="HH:mm:ss"
                                                    disableClock={true}
                                                    onChange={this.changeEndTimeHandler}
                                                    value={this.state.daily_end_time}
                                                    maxDetail="second"
                                                    clearIcon={null}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h3 className="card-title col-8">{t("daily-mission-setting.bonus-setting")}</h3>
                                                <div className="row col-4 justify-content-end mb-2 pr-0">
                                                    <button type="button" className="btn btn-info" onClick={(e) => this.addBonusHandler(e)}>
                                                    <i className="fa fa-plus"></i> {t("daily-mission-setting.add")}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{overflowY:'auto'}}>
                                                <table className="table" id="setting_table" style={{minWidth:500}}>
                                                    <thead className="text-center bg-primary">
                                                        <tr>
                                                            <th className="text-white p-2">{t("global.no")}</th>
                                                            <th className="text-white p-2">{t("daily-mission-setting.daily-valid-turnover")}</th>
                                                            <th className="text-white p-2">{t("daily-mission-setting.daily-mission-bonus")}</th>
                                                            <th className="text-white p-2">{t("daily-mission-setting.bonus-per-cycle")}</th>
                                                            <th style={{ width: "5%" }}/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.bonus_setting.map((item, idx) => {
                                                            return (
                                                                <tr key={idx} className="text-center">
                                                                    <td>{idx + 1}</td>
                                                                    <td key={`valid-turnover-${idx}`}>
                                                                        <input
                                                                            id="valid_turnover"
                                                                            name="valid_turnover"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("daily-mission-setting.daily-valid-turnover")}
                                                                            value={item.valid_turnover}
                                                                            onChange={this.changeBonusHandler}
                                                                            autoComplete="off"
                                                                            type="number"
                                                                        />
                                                                    </td>
                                                                    <td key={`daily-bonus-${idx}`}>
                                                                        <input
                                                                            id="daily_bonus"
                                                                            name="daily_bonus"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("daily-mission-setting.daily-mission-bonus")}
                                                                            value={item.daily_bonus}
                                                                            onChange={this.changeBonusHandler}
                                                                            autoComplete="off"
                                                                            type="number"
                                                                        />
                                                                    </td>
                                                                    <td key={`extra-bonus-${idx}`}>
                                                                        <input 
                                                                            id="extra_bonus"
                                                                            name="extra_bonus"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("daily-mission-setting.bonus-per-cycle")}
                                                                            value={item.extra_bonus}
                                                                            onChange={this.changeBonusHandler}
                                                                            autoComplete="off"
                                                                            type="number"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <i className="fa fa-trash" style={{color:'red',cursor:'pointer'}} onClick={() => this.removeBonusHandler(idx)}></i>
                                                                        {/* <button type="button" name="remove" className="remove btn btn-sm btn-danger mt-1" onClick={() => this.removeRecord(idx)}>
                                                                            <i className="fas fa-minus-square fa-2x"></i>
                                                                        </button> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        daily_mission_setting_create: state.dailyMission.daily_mission_setting_create
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createDailyMissionSetting: (data) => dispatch(createDailyMissionSetting(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DailyMissionSettingCreate));