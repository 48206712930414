import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types';

const Select2 = (props) => (
    <Select
        defaultValue={[props.defaultOptions]}
        isMulti
        name={props.name}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={props.onChange}
        value={props.value}
        menuPlacement={props.menuPlacement}
        placeholder={props.placeholder}
        isOptionDisabled={(props.isOptionDisabled === true) ? () => props.selectedOptions.length >= props.maxSelectOption : false}
    >
        {props.options.map((option) =>
            <option key={option.key} value={option.value} defaultValue={option.selected}>{option.label}</option>
        )}
    </Select>
)

export default Select2;

Select2.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultOptions: PropTypes.array,
    required: PropTypes.bool,
    isMulti: PropTypes.bool
};