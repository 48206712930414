import React, { Component } from "react";
import { connect } from "react-redux";
import { getByID, update } from "../../store/ducks/voucher/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
// import moment from "moment";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import Select from "../../components/Input/Select/Select";
// import Select2 from "../../components/Input/Select2/Select2";
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';

class VoucherEdit extends Component {
    constructor(props) {
        const { t } = props;

        super(props);
        this.state = {
            code: "",
            amount: "",
            type: "",
            shipment_type: "",
            status: 1,
            set_group: 0,
            start_date: '',//moment().format("YYYY-MM-DD")+" 00:00:00",
            end_date: '',//moment().format("YYYY-MM-DD")+" 00:00:00",
            error_message: [],
            touched: false,
            voucherType: [
                // {
                //     label: t('voucher.amount'),
                //     value: 'amount'
                // },
                {
                    label: t('voucher.percentage'),
                    value: 'percentage'
                }
            ],
            voucherCategory: [
                // {
                //     label: t('voucher.deposit'),
                //     value: 'deposit'
                // },
                // {
                //     label: t('voucher.redemption'),
                //     value: 'redemption'
                // },
                {
                    label: t('voucher.discount'),
                    value: 'discount'
                }
            ],
            shipmentType: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: t('voucher.air-freight'),
                    value: 'air-freight'
                },
                {
                    label: t('voucher.sea-freight'),
                    value: 'sea-freight'
                }
            ],
            statusOptions: [
                {
                    label: t('global.active'),
                    value: 1
                },
                {
                    label: t('global.inactive'),
                    value: 0
                }
            ],
            yesNoOptions: [
                {
                    label: t('voucher.no'),
                    value: 0
                },
                {
                    label: t('voucher.yes'),
                    value: 1
                }
            ],
            selectedGroupMember: [],
            showMaxCap: false,
            max_cap: 0,
            showVoucherType: false,
            voucher_list: [{ listIndex: Math.random(), code: "", id: ""}],
            limit: "",
            batch_no: "",
            claim_limit: "",
        };
        this.handleChange = this.handleChange.bind(this);
        // this.addRecord = this.addRecord.bind(this);
        // this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() {
        formValidation("voucher_form");
        
        let params = {};
        params.id = this.props.voucherID;
        this.props.getByID(params);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.edit !== undefined && nextProps.edit !== this.props.edit) {
            if(nextProps.edit.responseCode === 200 && nextProps.edit.msgType === 'success'){
                let voucherList = [];

                nextProps.edit.data.voucherCodeData.map((item, idx) => {
                    return voucherList.push({ listIndex: Math.random(), code: item.code, id: item.id });
                })
    
                this.setState({
                    batch_no: nextProps.edit.data.voucherData.batch_no,
                    code: nextProps.edit.data.voucherCodeData,
                    amount: nextProps.edit.data.voucherData.amount,
                    category: nextProps.edit.data.voucherData.category,
                    type: nextProps.edit.data.voucherData.type,
                    shipment_type: nextProps.edit.data.voucherData.shipment_type,
                    max_cap: nextProps.edit.data.voucherData.max_cap,
                    limit: nextProps.edit.data.voucherData.limit,
                    status: nextProps.edit.data.voucherData.status,
                    start_date: nextProps.edit.data.voucherData.start_date,
                    end_date: nextProps.edit.data.voucherData.end_date,
                    loading: false,
                    showVoucherType: !!(nextProps.edit.data.voucherData.category) ? true : false,
                    showMaxCap: nextProps.edit.data.voucherData.type === "percentage" ? true : false,
                    voucherType: (nextProps.edit.data.voucherData.category === 'deposit' || nextProps.edit.data.voucherData.category === 'discount') ? 
                        [{label: t('voucher.percentage'),value: 'percentage'}] : 
                        [{label: t('voucher.amount'),value: 'amount'}],
                    voucher_list: voucherList,
                    claim_limit: nextProps.edit.data.voucherData.claim_limit,
                });                
            }else{
                this.setState({
                    loading: true,
                }, () => {
                    filterErrorHandler(nextProps.edit.message);
                });
                Swal.close();
            }
        }

        if (nextProps.updateRs !== this.props.updateRs && nextProps.updateRs !== undefined) {
            if (nextProps.updateRs.responseCode === 200 && nextProps.updateRs.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateRs.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.updateRs.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    updateHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let value_array = [];

                // Ensure that no empty codes are pushed to the value_array
                this.state.voucher_list.forEach((item) => {
                    const trimmedCode = item.code.trim();
                    if (trimmedCode) {
                        value_array.push({
                            id: item.id,
                            code: trimmedCode
                        });
                    }
                });
                
                let updateData = {};
                updateData.id = this.props.voucherID;
                updateData.code = value_array;
                updateData.amount = this.state.amount;
                updateData.category = this.state.category;
                updateData.type = this.state.type;
                updateData.shipment_type = this.state.shipment_type;
                updateData.max_cap = this.state.max_cap;
                updateData.status = this.state.status;
                updateData.start_date = this.state.start_date;
                updateData.end_date = this.state.end_date;
                if(this.state.limit !== ""){
                    updateData.limit = this.state.limit;
                }
                if(this.state.claim_limit !== ""){
                    updateData.claim_limit = this.state.claim_limit;
                }

                updateData.batch_no = this.state.batch_no;
                this.props.update(updateData);
            }
        });
    };

    handleChange = (event) => {        
        const { t } = this.props;
        const { name, value } = event.target;
    
        this.setState({ [name]: value });
    
        switch (name) {
            case 'code':
                this.setState({ code: value.toUpperCase() });
                break;
            
            case 'type':
                this.setState({ selectedVoucherType: value, showMaxCap: value === "percentage" });
                break;
            
            case 'category':
                this.setState({ selectedCategory: value });
                let voucherType = this.state.voucherType;
                let showVoucherType = this.state.showVoucherType;
                let type = this.state.type;
    
                if (value === "deposit") {
                    voucherType = [{ label: t('voucher.percentage'), value: 'percentage' }];
                    showVoucherType = true;
                    type = "percentage";
                } else if (value === "redemption") {
                    voucherType = [{ label: t('voucher.amount'), value: 'amount' }];
                    showVoucherType = true;
                    type = "amount"
                } else if (value === "discount") {
                    voucherType = [{ label: t('voucher.percentage'), value: 'percentage' }];
                    showVoucherType = true;
                    type = "percentage";
                }
    
                this.setState({
                    voucherType,
                    showVoucherType,
                    type
                });
                break;
    
            default:
                break;
        }
    };
    
    handleSettingListChange = (event, idx) => {
        let voucher_list = [...this.state.voucher_list];
        voucher_list[event.target.dataset.id][event.target.name] = event.target.value;
        this.setState({ voucher_list });
    }

    // addRecord = () => 
    // {
    //     this.setState((prevState) => ({
    //         voucher_list: [...prevState.voucher_list, { listIndex: Math.random(), code: "", id: (this.state.voucher_list.length > 0) ? this.state.voucher_list[0].id : "" }],
    //     }));
    // };

    // removeRecord = (i) => {
    //     this.setState({
    //         voucher_list: this.state.voucher_list.filter((item, idx) => i !== idx)
    //     })
    // }    

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading && this.state.code !== "") {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("voucher.edit_voucher")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="voucher_form" className="form-horizontal" onSubmit={this.updateHandler} noValidate>
                                    <ValidationError formName="voucher_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="batch_no">{t("voucher.batch-no")}</label>
                                                <StringInput
                                                    id="batch_no"
                                                    name="batch_no"
                                                    className="form-control"
                                                    placeholder={t("voucher.batch-no")}
                                                    value={this.state.batch_no}
                                                    onChange={this.handleChange}
                                                    autoComplete="off"
                                                    autocapitalize="on"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="limit">{t("voucher.voucher-limit")}</label>
                                                <StringInput
                                                    id="limit"
                                                    name="limit"
                                                    className="form-control"
                                                    placeholder={t("voucher.voucher-limit")}
                                                    value={this.state.limit}
                                                    onChange={this.handleChange}
                                                    autoComplete="off"
                                                    autocapitalize="on"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="code">{t("voucher.claim-limit")}</label>
                                                <StringInput
                                                    id="claim_limit"
                                                    name="claim_limit"
                                                    className="form-control"
                                                    placeholder={t("voucher.claim-limit")}
                                                    value={this.state.claim_limit}
                                                    onChange={this.handleChange}
                                                    autoComplete="off"
                                                    autocapitalize="on"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="category">{t("voucher.category")}</label>
                                                <Select
                                                    id="category"
                                                    name="category"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    options={this.state.voucherCategory}
                                                    emptyValue={false}
                                                    value={this.state.category}
                                                />
                                            </div>
                                        </div>
                                        {this.state.showVoucherType === true && (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="type">{t("voucher.type")}</label>
                                                    <Select
                                                        id="type"
                                                        name="type"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        options={this.state.voucherType}
                                                        emptyValue={false}
                                                        value={this.state.type}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {(!!(this.state.type) && (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="amount">{this.state.type === 'amount' ? t("voucher.amount") : t("voucher.percentage")}</label>
                                                    <StringInput
                                                        id="amount"
                                                        name="amount"
                                                        className="form-control"
                                                        placeholder={this.state.type === 'amount' ? t("voucher.amount") : t("voucher.percentage")}
                                                        type="number"
                                                        value={this.state.amount}
                                                        onChange={this.handleChange}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.showMaxCap === true && (
                                            <>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="max_cap">{t("voucher.max_cap")}</label>
                                                        <StringInput
                                                            id="max_cap"
                                                            name="max_cap"
                                                            className="form-control"
                                                            placeholder={t("voucher.max_cap")}
                                                            value={this.state.max_cap}
                                                            onChange={this.handleChange}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="shipment_type">{t("voucher.shipment_type")}</label>
                                                <Select
                                                    id="shipment_type"
                                                    name="shipment_type"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    options={this.state.shipmentType}
                                                    value={this.state.shipment_type}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="status">{t("voucher.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    options={this.state.statusOptions}
                                                    emptyValue={false}
                                                    value={this.state.status}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="start_date">{t("voucher.start_date")}</label>
                                                <DateTimePicker
                                                    id='start_date'
                                                    name='start_date'
                                                    className='form-control filter-input'
                                                    singleDatePicker={true}
                                                    timePicker={true}
                                                    value={this.state.start_date}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="end_date">{t("voucher.end_date")}</label>
                                                <DateTimePicker
                                                    id='end_date'
                                                    name='end_date'
                                                    className='form-control filter-input'
                                                    singleDatePicker={true}
                                                    timePicker={true}
                                                    value={this.state.end_date}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="row col-12 justify-content-end mb-2 pr-0">
                                            <button type="button" className="btn btn-blue" style={{minWidth:'unset'}} onClick={(e) => this.addRecord(e)}>
                                                <i className="fa fa-plus"></i> {t('global.add')}
                                            </button>
                                        </div> */}

                                        <div className="table-responsive" style={{ overflowY: 'auto' }}>
                                            <table className="table" id="setting_table" style={{ minWidth: 500 }}>
                                                <thead className="text-center">
                                                    <tr>
                                                        <th className="p-2">{t("global.no")}</th>
                                                        <th className="">{t("voucher.code")}</th>
                                                        <th style={{ width: "5%" }} />
                                                    </tr>
                                                </thead>                                                
                                                <tbody>
                                                    {this.state.voucher_list.map((item, idx) => {
                                                        return (
                                                            <tr key={idx} className="text-center">
                                                                <td>{idx + 1}</td>
                                                                <td key={`key-${idx}`}>
                                                                    <input
                                                                        id="code"
                                                                        name="code"
                                                                        data-id={idx}
                                                                        className="form-control"
                                                                        placeholder={t("voucher.code")}
                                                                        value={item.code}
                                                                        onChange={this.handleSettingListChange}
                                                                        autoComplete="off"
                                                                        // required
                                                                        disabled={true}
                                                                    />
                                                                </td>

                                                                {/* <td>
                                                                    <button type="button" name="remove" className="remove btn btn-sm btn-white delete" onClick={() => this.removeRecord(idx)}>
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </td> */}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("voucher.edit_voucher")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4} /> : <ValidationError formName="voucher_form" touched={this.state.touched} message={this.state.error_message} /> }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("voucher.voucher")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        edit: state.voucher.edit,
        updateRs: state.voucher.update,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getByID: (data) => dispatch(getByID(data)),
        update: (data) => dispatch(update(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VoucherEdit));
