import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getAdmins = (data) => dispatch => {
    axios.get('admin/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_ADMIN_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => dispatch =>
{
    axios.get('admin/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postAdmin = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('admin/store',data)
    .then( (response) => {
        dispatch ({
            type: type.POST_ADMIN,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const getUserGroup = (data) => dispatch => {
    axios.get('admin/usergroup', {

    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_USER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};


export const showAdmin = (data) => dispatch => {
    axios.get('admin/show', {
        params: {
            "id": data['id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_ADMIN,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateAdmin = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('admin/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_ADMIN,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteAdmin = (data) => dispatch => {
    axios.post('admin/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_ADMIN,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateAdminStatus = (data) => (dispatch) => {
    axios
        .post("admin/changeStatus", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_STATUS_ADMIN,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Admin Status Error" + err);
        });
};
