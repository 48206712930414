import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import routes from "../../routes";
import RequiredAuth from "../../common/middleware/authPermission";

const PageContent = () => (
    <div className="container-fluid">
        <Switch>
            {routes.map((route, index) => {
                return route.component && (
                    <Route
                        path={route.path}
                        key={index}
                        exact={route.exact}
                        component={RequiredAuth(route.component)}
                    />
                )
            })}

            <Redirect from="/" to="/login" />
            <Route render={() => <h1>Not Found</h1>} />

        </Switch>
    </div>
)

export default PageContent;