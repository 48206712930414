import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import TimePicker from 'react-time-picker';
import Button from "../../components/Button/Button";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import ValidationError from "../../components/ValidationError/ValidationError";
import { formValidation } from "../../hoc/Shared/utility";
import { createBonusSetting } from '../../store/ducks/vipBonus/actions';

class VIPBonusCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_time: "",
            end_time: "",
            no_day_expire: 0,
            // no_claim: 0,
            touched: false,
            error_message: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.createBonusHandler = this.createBonusHandler.bind(this);
    }

    componentDidMount() {
        formValidation("bonus_settings_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.member_bonus_settings_create !== this.props.member_bonus_settings_create) {
            if (nextProps.member_bonus_settings_create.data.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.member_bonus_settings_create.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
            else if (nextProps.member_bonus_settings_create.data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.member_bonus_settings_create.data.message
                });
                Swal.close();
            }
            else {
                return false;
            }
        }
        return true;
    }

    handleStartTimeChange = (date) => {
        // console.log(date);
        if (date !== null) {
            this.setState({
                start_time: date
            });
        }
    };

    handleEndTimeChange = (date) => {
        // console.log(date);
        if (date !== null) {
            this.setState({
                end_time: date,
            });
        }
    };

    handleChange = (event) => {
        let name = event.target.name;
        switch (name) {
            case "no_day_expire":
                // case "no_claim":
                if (event.target.value.match(/^\d+(\.\d+)?$/)) {
                    if (event.target.value.charAt(0) === "0" || event.target.value.charAt(0) === ".") {
                        if (event.target.value.charAt(1) === ".") {
                            this.setState({ [event.target.name]: event.target.value });
                        } else if (event.target.value.charAt(0) === "0") {
                            if (event.target.value.substring(1) !== "") {
                                this.setState({ [event.target.name]: event.target.value.substring(1) });
                            } else {
                                this.setState({ [event.target.name]: 0.0 });
                            }
                        } else {
                            this.setState({ [event.target.name]: event.target.value });
                        }
                    } else {
                        this.setState({ [event.target.name]: event.target.value });
                    }
                } else {
                    this.setState({ [event.target.name]: 0.0 });
                }
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
                break;
        }
    };

    createBonusHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel")
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let params = {};
                    params.start_time = this.state.start_time;
                    params.end_time = this.state.end_time;
                    params.no_day_expire = this.state.no_day_expire;
                    // params.no_claim = this.state.no_claim;
                    // console.log(params);
                    this.props.createBonusSetting(params);
                }
            });
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("member-bonus-setting.title")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member-bonus-setting.title")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="bonus_settings_form" className="form-horizontal" onSubmit={this.createBonusHandler} noValidate>
                                    <ValidationError formName="bonus_settings_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>{t("member-bonus-setting.start-time")}</label>
                                                <TimePicker
                                                    name="start_time"
                                                    className="form-control"
                                                    format="HH:mm:ss"
                                                    disableClock={true}
                                                    onChange={this.handleStartTimeChange}
                                                    value={this.state.start_time}
                                                    maxDetail="second"
                                                    clearIcon={null}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>{t("member-bonus-setting.end-time")}</label>
                                                <TimePicker
                                                    name="end_time"
                                                    className="form-control"
                                                    format="HH:mm:ss"
                                                    disableClock={true}
                                                    onChange={this.handleEndTimeChange}
                                                    value={this.state.end_time}
                                                    maxDetail="second"
                                                    clearIcon={null}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="no_day_expire">{t("member-bonus-setting.no-day-expire")}</label>
                                                <NumberInput
                                                    id="no_day_expire"
                                                    name="no_day_expire"
                                                    min={1}
                                                    max={7}
                                                    placeholder={t("member-bonus-setting.no-day-expire")}
                                                    value={this.state.no_day_expire}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="no_claim">{t("member-bonus-setting.no-claim")}</label>
                                                <NumberInput
                                                    id="no_claim"
                                                    name="no_claim"
                                                    min={1}
                                                    placeholder={t("referral-settings.no-claim")}
                                                    value={this.state.no_claim}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="form-group mb-0 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        member_bonus_settings_create: state.memberBonusSettings.member_bonus_settings_create
    }
}

const mapDispatchToProps = {
    createBonusSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VIPBonusCreate));