import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getBanners = (data) => dispatch => {
    axios.get('banner/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANNER_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => dispatch =>
{
    axios.get('banner/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postBanner = (data) => (dispatch, getState) => {
    data.append('country_id', getState().currentUser.data.country_id);
    axios.post('banner/store',data)
    .then( (response) => {
        dispatch ({
            type: type.POST_BANNER,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const showBanner = (data) => dispatch => {
    axios.get('banner/show', {
        params: {
            "banner_id": data['banner_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_BANNER,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBanner = (data) => (dispatch, getState) => {
    data.append('country_id', getState().currentUser.data.country_id);
    axios.post('banner/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_BANNER,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteBanner = (data) => dispatch => {
    axios.post('banner/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_BANNER,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateBannerStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('banner/changeStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_BANNER,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getCategory = (data) => dispatch => {
    axios.get('banner/category', {
        
    }).then((response) => {
        dispatch({
            type: type.GET_CATEGORY,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};