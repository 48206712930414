import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import ValidationError from '../../components/ValidationError/ValidationError';
import StringInput from '../../components/Input/StringInput/StringInput';
import Button from '../../components/Button/Button';

import Select from '../../components/Input/Select/Select';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import {formValidation} from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/luckySpinGiftAssign/actions';

const initialFormInputState =
{
    /* form input value */
    member: '',
    status: 1,
};

class LuckySpinGiftAssignCreate extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            formFilter: initialFormInputState,
            validMember: false,
            loading: true,
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount (create):', this.state);
        formValidation('lucky_spin_gift_assign_form');
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.member !== this.props.member && nextProps.member!==undefined)
        {
            if(nextProps.member.responseCode === 200 && nextProps.member.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    // title: t('global.valid-member'),
                    html: t('global.valid-member'),
                    showConfirmButton: true
                }).then(() =>
                {
                    let newInput = Object.assign({}, this.state.formFilter);
                    newInput['member'] = nextProps.member.data.member.id;
                    this.setState({
                        formFilter: newInput,
                        validMember: true,
                    });
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store!==undefined)
        {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if(nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store.message
                });
                Swal.close();
            }
        }
        return true;
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    onSearchHandler = () =>
    {
        let m = this.state.formFilter.member;
        if(m !== '')
        {
            this.setState({
                validMember: false,
            }, () =>
            {
                this.loadingHandler();
                this.props.funcGetMember(m);
            });
        }
    }

    submitHandler = (e) =>
    {
        e.preventDefault();
        const { t } = this.props;
        
        if(!this.state.validMember)
        {
            Swal.fire({
                title: t('validation.invalid_member'),
                icon: 'error',
                showConfirmButton: true,
            })
        }
        else
        {
            Swal.fire({
                title: t('validation.confirmation'), 
                text: t('validation.confirmation_check'),
                icon: 'warning',
                confirmButtonText: t('global.save'),
                showDenyButton: true,
                denyButtonText: t('global.cancel'),
            }).then((result) => 
            {
                if (result.isConfirmed) 
                {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => 
                        {
                            Swal.showLoading()
                        }
                    });
                    const inputArr = {};
                    Object.keys(this.state.formFilter).forEach((val) =>
                    {
                        inputArr[val] = this.state.formFilter[val];
                    });
                    inputArr["country_id"] = this.props.currentUser.country_id;
                    // console.log(this.state.formFilter);
                    this.props.funcStore(inputArr);
                }
            });
        }
    }

    render()
    {
        const { t } = this.props;
        let statusArr = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className="content-title mb-0 my-auto ml-2">{t("side-bar.lucky_spin_gift_assign")}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.lucky_spin_gift_assign_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='lucky_spin_gift_assign_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='lucky_spin_gift_assign_form' touched={this.state.touched} message={this.state.errMsg}/>
                                    <div className='row'>
                                        <div className='col-md-6 col-12 form-group'>
                                            <label>{t('lucky-spin-gift-assign.member')}</label>
                                            <div className="position-relative">
                                                <StringInput
                                                    id='member'
                                                    name='member'
                                                    placeholder={t('lucky-spin-gift-assign.member')}
                                                    value={this.state.member}
                                                    onChange={this.onInputChange}
                                                    // onSearchPress={(evt) => evt.key === "Enter" && this.onSearchHandler()}
                                                    // required
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue input-side-btn"
                                                    clicked={this.onSearchHandler}
                                                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                                                    
                                                />
                                            </div>
                                        </div>

                                        <div className='col-md-6 col-12  form-group'>
                                            <label>{t('global.status')}</label>
                                            <Select
                                                id="status"
                                                name="status"
                                                className="form-control"
                                                options={statusArr}
                                                onChange={(event) => this.onInputChange(event)}
                                                value={this.state.formFilter.status}
                                            />
                                        </div>

                                        <div className='col-12 form-group mb-0 justify-content-end'>
                                            <div>
                                                {(permission.includes('lucky_spin_gift_assign_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        member: state.luckySpinGiftAssign.member,
        store: state.luckySpinGiftAssign.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetMember: (m) => dispatch(actions.getMember(m)),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(LuckySpinGiftAssignCreate));