import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import { getListing, updateStatus } from "../../store/ducks/mobileApps/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MobileAppsListing from "./MobileAppsListing";
import MobileAppsUpload from "./MobileAppsUpload";
import Swal from "sweetalert2";
import moment from "moment";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const initialInputState =
{
    /* form input value */
};

class MobileApps extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        // const { t } = this.props;

        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",
            filter: initialInputState,

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,
            loading: true,
            permission: []
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getListing(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
            let rowDataArray = [];
            nextProps.rs.data.data.result.row.map((val) => {
                rowDataArray[val.id] = val;
                return null;
            });

            this.setState({
                data: nextProps.rs.data.data.result.row,
                totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                totalPages: nextProps.rs.data.data.result.pagination.total_page,
                rowDataArray: rowDataArray,
                loading: false
            });
        }

        if (nextProps.updateStatusRs !== this.props.updateStatusRs && nextProps.updateStatusRs !== undefined) {
            if (nextProps.updateStatusRs.responseCode === 200 && nextProps.updateStatusRs.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    const listingArr = this.getFilterArray();
                    this.props.getListing(listingArr);
                });
            } else {
                let msg = null;
                msg = '<ul>';
                nextProps.updateStatusRs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    onInputChange(event) {
        // const { t } = this.props;
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }

        this.setState({
            filter: newFilter
        });
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    updateStatusHandler = (event, appID) => {
        event.preventDefault();

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        this.setState({ [event.target.name]: event.target.value });
        const data = {};
        data.id = appID;
        data.status = Number(event.target.value);
        this.props.updateStatus(data);
    };

    downloadHandler = (event, downloadUrl) => {
        window.open(downloadUrl);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        return listingArr;
    }
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getListing(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };  

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("mobile_apps_upload")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];

            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="status"
                    name="status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateStatusHandler(event, row.id);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    actionFormatter = (cell, row) => {
        let downloadButton, output;

        downloadButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                key={`edit-${row.id}`}
                idValue={row.id === undefined ? 0 : row.id}
                clicked={(event) => this.downloadHandler(event, row.downloadUrl)}
                buttonName={<FontAwesomeIcon icon={faDownload} />}
            />
        );

        output = [downloadButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.getFilterArray();
                this.props.getListing(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MobileAppsListing
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={this.refreshHandler}
                        clearHandler={this.clearHandler}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        onSort={this.onSort}

                        noFormatter={this.noFormatter}
                        statusFormatter={this.statusFormatter}
                        actionFormatter={this.actionFormatter}
                    />
                )}
                {this.state.pageShow === "upload" && (
                    <MobileAppsUpload
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        refreshHandler={this.refreshHandler}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.mobileApps.rs,
        updateStatusRs: state.mobileApps.update_status,
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getListing: (data) => dispatch(getListing(data)),
        updateStatus: (data) => dispatch(updateStatus(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileApps));
