import {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/ducks/agent/actions';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import {withNamespaces} from 'react-i18next';
import Swal from 'sweetalert2';
import AgentWalletTrackerListing from './AgentWalletTrackerListing';
import TransactionDetails from '../../Transaction/TransactionDetails';

const initialInputState = {
    transaction_date: "",
    status: "",
    transaction_type: ""
};

class AgentWalletTracker extends Component{
    constructor(props){
        super(props);
        const initLoading = this.props.agentShowInitialLoading.filter(item => item.id === 'wallet_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            this.state = {
                currentPage: "wallet_tracker",
                pageShow: "wallet_tracker_listing",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: '',
                totalRecords: 0,
                searchValue: '',
                data: [],
                loading: true,
                reload: false,
                show_id: null,
                filter: initialInputState,
                detailsParam: {},
                dropDown: {
                    status: [],
                    transactionType: [],
                },
            }
            this.props.setAgentShowLocalState(this.state);
        }
        else{
            this.state = this.props.agentWalletTrackerLocalState;
        }
    }

    componentDidMount(){
        const initLoading = this.props.agentShowInitialLoading.filter(item => item.id === 'wallet_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            const listingArr = this.getFilterArray();
            this.props.getAgentWalletTracker(listingArr);
            this.props.setAgentShowInitialLoading(this.state.currentPage);
        } else{
            if(this.props.agentWalletTrackerData !== undefined){
                if(Number(this.props.agentShowId) !== Number(this.props.agentWalletTrackerData.data.data.agentId)){
                    const listingArr = this.getFilterArray();
                    this.props.getAgentWalletTracker(listingArr);
                }
                else{
                    this.setState({
                        loading: false,
                        reload: true
                    });
                }
            }
            else{
                const listingArr = this.getFilterArray();
                this.props.getAgentWalletTracker(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.agentWalletTrackerData.data.agentId !== undefined && nextProps.agentWalletTrackerData !== undefined) {
            if(nextProps.agentWalletTrackerData !== this.props.agentWalletTrackerData) {
                if(nextProps.agentWalletTrackerData.responseCode === 200 && nextProps.agentWalletTrackerData.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        loading: false,
                        data: nextProps.agentWalletTrackerData.data.result.row,
                        totalRecords: nextProps.agentWalletTrackerData.data.result.pagination.total_record,
                        show_id: nextProps.agentWalletTrackerData.data.agentId
                    });
                }
            }
        }

        if (nextProps.agentFilterData !== undefined && nextState.dropDown.status.length === 0 && nextProps.agentFilterData !== undefined) {
            this.setState({
                dropDown: {
                    status: nextProps.agentFilterData.data.status,
                    transactionType: nextProps.agentFilterData.data.transaction_type,
                },
            });
            Swal.close();
        }

        return true;
    }

    componentWillUnmount(){
        this.props.setAgentShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        listingArr.agent_id = this.props.agentShowId;
        if(this.state.totalRecords!==0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit)+1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getAgentWalletTracker(listingArr);
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentWalletTracker(listingArr);
            }
        );
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentWalletTracker(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                searchValue: "",
                filter: newFilter,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentWalletTracker(listingArr);
            }
        );
    };

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    detailsHandler = (e,id) => {
        e.preventDefault();
        this.setState({
            pageShow: "details",
            detailsParam: {
                id: id
            }
        });
    }

    changePageHandler = page => {
        this.setState({
            pageShow: page
        });
    }
    
    render(){
        return(
            <Auxiliary>
                {this.state.pageShow==='wallet_tracker_listing' && 
                    <AgentWalletTrackerListing 
                        filter={this.state.filter}
                        onSort={(field,order)=>{this.onSort(field,order)}}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        datatablesChangeHandler={(val,act)=>{this.datatablesChangeHandler(val,act)}}
                        searchValue={this.state.searchValue}
                        loading={this.state.loading}
                        refreshHandler={()=>{this.refreshHandler()}}
                        clearHandler={()=>{this.clearHandler()}}
                        onInputChange={(event)=>{this.onInputChange(event)}}
                        detailsHandler={(event,id)=>{this.detailsHandler(event,id)}}
                        permission={this.props.currentUser.permission ?? []}
                        ddStatus={this.state.dropDown.status}
                        ddTransactionType={this.state.dropDown.transactionType}
                    />
                }
                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='wallet_tracker_listing'
                        currentUser={this.props.currentUser} 
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const {agent,currentUser} = state;

    return {
        agentShowInitialLoading: agent.agent_show_initial_loading,
        agentWalletTrackerLocalState: agent.agent_wallet_tracker_local_state,
        agentWalletTrackerData: agent.agent_wallet_tracker,
        agentFilterData: agent.agent_filter_data,
        currentUser: currentUser.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAgentShowLocalState: (data) => {dispatch(actions.setAgentShowLocalState(data))},
        setAgentShowInitialLoading: (data) => {dispatch(actions.setAgentShowInitialLoading(data))},
        getAgentWalletTracker: (data) => {dispatch(actions.getAgentWalletTracker(data))},
        getFilterData: () => dispatch(actions.getFilterData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(AgentWalletTracker));