import React, {Component} from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from "moment";
import axios from "../../common/config/axios";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import CompanyPerformaceByDayListing from './CompanyPerformaceByDayListing';

import * as actions from "../../store/ducks/companyPerformanceByDay/actions";

const initialInputState =
{
    /* form input value */
    start_date: '',
    end_date: '',
    start_month: '',
    end_month: '',
    start_year: '',
    end_year: ''
};

class CompanyPerformaceByDay extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],

            /* skeleton loading */
            loading: true,

            dateRange: null,
            start_time: null,
            end_time: null,
            start_month: null,
            end_month: null
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                // let newData = [];
                // Object.keys(nextProps.rs.data.result.row).forEach((val) =>
                // {
                //     newData.push({
                //         date: nextProps.rs.data.result.row[val].date,
                //         new_member: (nextProps.rs.data.result.row[val].new_member || 0),
                //         deposit_count: (nextProps.rs.data.result.row[val].deposit_count || 0),
                //         deposit_amt: (nextProps.rs.data.result.row[val].deposit_amt || 0),
                //         deposit_avg: (nextProps.rs.data.result.row[val].deposit_avg || 0),
                //         withdrawal_count: (nextProps.rs.data.result.row[val].withdrawal_count || 0),
                //         withdrawal_amt: (nextProps.rs.data.result.row[val].withdrawal_amt || 0),
                //         withdrawal_avg: (nextProps.rs.data.result.row[val].withdrawal_avg || 0),
                //     });
                // });
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                const { t } = this.props;
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) =>
                {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange(event, picker)
    {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
        }
        if(event.target.className.includes("monthly")) {
            newFilter['start_month'] = moment(picker.startDate).format("YYYY-MM H:mm:ss");
            newFilter['end_month'] = moment(picker.endDate).format("YYYY-MM H:mm:ss");
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
        }
        if(event.target.className.includes("yearly")) {
            newFilter['start_year'] = moment(picker.startDate).format("YYYY");
            newFilter['end_year'] = moment(picker.endDate).format("YYYY");
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
        }

        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            // limit: 10,
            searchValue: '',
            // order: [{'id':'id', 'desc':'asc'}],
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }
        if(this.state.filter.start_date === "" && this.state.filter.start_month === "" && this.state.filter.start_year === ""){
            listingArr.page = 1;
            listingArr.limit = 50;

            this.setState({
                page: 1,
                limit: 50
            });
        }else{
            listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
            listingArr.limit = this.state.limit;
        }
        
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });
        listingArr.start_month = this.state.filter.start_month;
        listingArr.end_month = this.state.filter.end_month;
        listingArr.start_year = this.state.filter.start_year;
        listingArr.end_year = this.state.filter.end_year;
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    exportExcelHandler = () =>
    {
        const data = {};
        data.start_date = this.state.filter.start_date;
        data.end_date = this.state.filter.end_date;
        // data.start_date = moment(this.state.filter.start_date).format("YYYY-MM-DD HH:mm:ss");
        // data.end_date = moment(this.state.filter.end_date).format("YYYY-MM-DD HH:mm:ss");
        data.start_month = this.state.filter.start_month;
        data.end_month = this.state.filter.end_month;
        data.start_year = this.state.filter.start_year;
        data.end_year = this.state.filter.end_year;
        data.order = JSON.stringify(this.state.order);

        axios
            .get("company_performance_by_day/export_excel", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - CompanyPerformanceByDay " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - CompanyPerformanceByDay Error", err);
            });
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleDateTimeChange = (event, picker) => {
        this.setState({ 
            start_month: moment(picker.startDate).format("YYYY-MM H:mm:ss"),
            end_month:  moment(picker.endDate).format("YYYY-MM H:mm:ss")
        });
    }

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <CompanyPerformaceByDayListing
                        /* filter input */
                        filter={this.state.filter}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        onInputChange={(event, picker) => this.onInputChange(event, picker)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        exportExcelHandler={() => this.exportExcelHandler()}
                        handleDateTimeChange={(event, picker) => this.handleDateTimeChange(event, picker)}
                        /* datatables data */
                        data={this.state.data}
                        dateRange={this.state.dateRange}
                        start_time={this.state.start_time}
                        end_time={this.state.end_time}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* user session */
                        currentUser={this.props.currentUser}

                        loading={this.state.loading}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.companyPerformanceByDay.rs,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPerformaceByDay);