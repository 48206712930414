import axios from "../../../common/config/axios";
import * as types from "./types";

export const getGameFavReport = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get("game_fav/report", {params: data})
    .then((res) => {
        dispatch({
            type: types.GAME_FAV_STATS,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.GAME_FAV_STATS,
            payload: []
        });
    });
};