import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
// import Skeleton from "react-loading-skeleton";
// import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

// import StringInput from "../../components/Input/StringInput/StringInput";
// import Button from "../../components/Button/Button";
// import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class MemberPromotionApply extends Component {
    
    render() {
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                Coming Soon...
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberPromotionApply);
