import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import ValidationError from '../../components/ValidationError/ValidationError';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import Button from '../../components/Button/Button';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { formValidation } from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/coinSetting/actions';

const initialFormInputState =
{
    /* form input value */
    min_bet: '',
    coin_reward: '',
    // turnover_multiply: '',
};

class CoinSettingCreate extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: initialFormInputState,
            dropDown:
            {
                transactionType: [],
            },
            loading: true,
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('coin_setting_form');
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store.message
                });
                Swal.close();
            }
        }

        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    submitHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const inputArr = {};
                Object.keys(this.state.formFilter).forEach((val) => {
                    inputArr[val] = this.state.formFilter[val];
                });
                inputArr['country_id'] = this.props.currentUser.country_id;
                // console.log(inputArr);
                this.props.funcStore(inputArr);
            }
        });
    }

    render() {
        const { t } = this.props;
        let inputArr = (this.state.formFilter) ? this.state.formFilter : [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('side-bar.coin_setting')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.coin_setting_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='coin_setting_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='coin_setting_form' touched={this.state.touched} message={this.state.errMsg} />
                                    <div className='row'>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='min_bet'>{t('coin-setting.min-bet')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={this.onInputChange}
                                                id='min_bet'
                                                name='min_bet'
                                                min={1}
                                                // max={45000}
                                                // placeholder={t('.enter-amount')}
                                                value={inputArr.min_bet || ''}
                                                required
                                            />
                                        </div>

                                        <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='coin'>{t('coin-setting.coin')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={this.onInputChange}
                                                id='coin_reward'
                                                name='coin_reward'
                                                // min={0.001}
                                                // max={100}
                                                value={inputArr.coin_reward || ''}
                                                required
                                            />
                                        </div>

                                        {/* <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='turnover_multiply'>{t('coin-setting.turnover-multiply')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={this.onInputChange}
                                                id='turnover_multiply'
                                                name='turnover_multiply'
                                                value={inputArr.turnover_multiply || ''}
                                                // required
                                            />
                                        </div> */}
                                        <div className='col-12 form-group mb-0 justify-content-end'>
                                            <div>
                                                {(permission.includes('coin_setting_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state.coinSetting.store,
        currentUser: state.currentUser.data,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(CoinSettingCreate));