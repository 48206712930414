export const ANNOUNCEMENT_LIST = "ANNOUNCEMENT_LIST";
export const ANNOUNCEMENT_SHOW = "ANNOUNCEMENT_SHOW";
export const ANNOUNCEMENT_CREATE = "ANNOUNCEMENT_CREATE";
export const ANNOUNCEMENT_STORE = "ANNOUNCEMENT_STORE";
export const ANNOUNCEMENT_EDIT = "ANNOUNCEMENT_EDIT";
export const ANNOUNCEMENT_UPDATE = "ANNOUNCEMENT_UPDATE";
export const ANNOUNCEMENT_UPDATE_STATUS = "ANNOUNCEMENT_UPDATE_STATUS";

export const LOADING_DATA = "LOADING_DATA";
export const CLEAR_VALIDATION = "CLEAR_VALIDATION";
export const SET_VALIDATION = "SET_VALIDATION";


