import React, {Component,Fragment} from 'react';
import {required, validateDisplay} from "../../../hoc/Shared/utility";
import PropTypes from 'prop-types';

class Textarea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage)
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ) {
            let payload = {
                value: nextProps.value,
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if (nextProps.required) {
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            return payload;
        }

        return null;
    }

    render() {
        const {id, name, className, onChange, placeholder, rows, maxLength, required, disabled} = this.props;

        const {value, requiredRules} = this.state;

        //Export the validation classes and message
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);

        return (
            <Fragment>
                <textarea
                    type="text"
                    id={id}
                    name={name}
                    className={className}
                    maxLength={maxLength}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                />
                <div className="invalid-feedback">
                    {requiredClass !== 'is-valid' && requiredMsg}
                </div>
            </Fragment>
        )
    }
}

export default Textarea;

Textarea.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

Textarea.defaultProps = {
    className: ''
};
