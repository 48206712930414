import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('game_maintenance/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const updateStatus = (data) => dispatch =>
{
    // console.log(data);
    axios.post('game_maintenance/update_status', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (id) => dispatch =>
{   
    if(id > 0)
    {
      axios.get('game_maintenance/details',{
        params: {
            "id": id,
        }
      })
      .then(function (response) 
      {
        dispatch (
        {
          type: actionType.DETAILS,
          value: response.data
        })
      })
      .catch(function (error) 
      {
        
      });
    }
};

export const updateSetting = (data) => dispatch =>
{
    // console.log(data);
    axios.post('game_maintenance/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getGame = (props) => dispatch =>
{
    axios.get('game_maintenance/get_game', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.SHOW,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
}