import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };
    }

    // shouldComponentUpdate (nextProps, nextState, nextContext) {
    //     return nextProps.value !== this.props.value;
    // }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ) {
            let payload = {
                value: nextProps.value,
            };

            return payload;
        }

        return null;
    }

    render() {

        const { id, name, onChange, defaultValue, disabled, required, className, label } = this.props;

        const { value } = this.state;

        return (
            <Fragment>
                {/* <input
                    type="checkbox"
                    id={id}
                    value={value}
                    name={name}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    className={className}
                    checked={value}
                /> */}

                <div className={`yl-checkbox-container ${className}`}>
                    <input
                        type="checkbox"
                        id={id}
                        value={value}
                        name={name}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        disabled={disabled}
                        required={required}
                        className={className}
                        checked={value}
                    />
                    <label className={`${label ? "margin-r":""}`} for={name}>{label}</label>
                </div>

                {/*<div className="invalid-feedback">*/}
                {/*    {requiredClass !== "is-valid" && requiredMsg}*/}
                {/*    {(requiredClass === "is-valid" && minLengthClass === "is-invalid") && minLengthMsg}*/}
                {/*    {(requiredClass === "is-valid" && maxLengthClass === "is-invalid") && maxLengthMsg}*/}
                {/*    {(requiredClass === "is-valid" && emailClass === "is-invalid") && emailMsg}*/}
                {/*</div>*/}
            </Fragment>
        )
    }
}

export default Checkbox;

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,

    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    value: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool

};

Checkbox.defaultProps = {
    className: ''
};