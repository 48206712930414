import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const firstBetBonusReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropdown: action.payload
    }),

    [type.STORE]: (state, action) => ({
        ...state,
        store: action.payload,
    }),

    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.payload
    }),

    [type.DELETE]: (state, action) => ({
        ...state,
        delete: action.payload
    }),

    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.value,
    }),
});

export default firstBetBonusReducer;