import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const gamebetslipReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value
    }),
    
    [type.GAME_BET_SLIP_FILTER_DATA]: (state, action) => ({
        ...state,
        game_bet_slip_filter_data: action.value
    }),

    [type.GAME_BET_SLIP_BET_DETAILS]: (state, action) => ({
        ...state,
        details: action.value
    }),
});

export default gamebetslipReducer;