import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import ValidationError from '../../components/ValidationError/ValidationError';
import StringInput from '../../components/Input/StringInput/StringInput';
import Button from '../../components/Button/Button';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { formValidation } from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/gameVendor/actions';

class GameVendorCreate extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter:
            {
                status: 0,
            },
            loading: true,
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('game_vendor_form');
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store.message
                });
                Swal.close();
            }

        }
        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    submitHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const inputArr = {};
                Object.keys(this.state.formFilter).forEach((val) => {
                    inputArr[val] = this.state.formFilter[val];
                });
                // console.log(inputArr);
                this.props.funcStore(inputArr);
            }
        });
    }

    render() {
        const { t } = this.props;
        let inputArr = (this.state.formFilter) ? this.state.formFilter : [];
        // let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('game-vendor.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.game_vendor_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='game_vendor_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='game_vendor_form' touched={this.state.touched} message={this.state.errMsg} />
                                    <div className='row'>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='title'>{t('game-vendor._title')}</label>
                                            <StringInput
                                                id='title'
                                                name='title'
                                                placeholder={t('game-vendor._title')}
                                                value={inputArr.title || ''}
                                                onChange={(event) => this.onInputChange(event)}
                                                required
                                            />
                                        </div>

                                        <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='title'>{t('game-vendor.slug')}</label>
                                            <StringInput
                                                id='slug'
                                                name='slug'
                                                placeholder={t('game-vendor.slug')}
                                                value={inputArr.slug || ''}
                                                onChange={(event) => this.onInputChange(event)}
                                                required
                                            />
                                        </div>

                                        <div className='col-md-4 col-12 form-group'>
                                            <label htmlFor='status'>{t('game-vendor.status')}</label>
                                            <div className='select-input'>
                                                <select className='form-control datatable-select' id='status' name='status' value={inputArr.status} onChange={(event) => this.onInputChange(event)}>
                                                    <option value='0' >{t('global.inactive')}</option>
                                                    <option value='1' >{t('global.active')}</option>
                                                </select>
                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                            </div>

                                        </div>

                                        <div className='col-12 form-group mb-0 justify-content-end'>
                                            <div>
                                                {/* {(permission.includes('game_vendor_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-primary' idValue='save' buttonName={t('global.save')} />
                                                } */}
                                                <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        store: state.gameVendor.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(GameVendorCreate));