import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Listing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            //(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
            (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    actionFormatter = (cell, row) => {
        let viewButton, /*editButton,*/ output;

        viewButton =
            // <Button
            //     typeButton="button"
            //     classValue="btn btn-sm btn-blue mr-2"
            //     buttonName={<FontAwesomeIcon icon={faSearch}/>}
            // />

            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                idValue="show"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                clicked={() => this.props.changePageHandler("show",row.id,row.slug)}
            />

        output = [viewButton];
        return output;
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("game-maintenance.game-name"),
                dataField: "title",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: "id",
                dataField: "id",
                sort: true,
                onSort: this.props.onSort,
                hidden:true,
            },
            {
                text: t("game-maintenance.slug"),
                dataField: "slug",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("game-maintenance.start-time"),
                dataField: "start_time",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("game-maintenance.end-time"),
                dataField: "end_time",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
                headerStyle: () => {
                    return {width: '115px'};
                }
            }

            // { text: t("global.status"), dataField: "status", headerStyle: { width: "115px" }, },
            // { text: t("global.action"), dataField: "action", headerStyle: { width: "65px" }, },
        ];
        if (!permission.includes("game_vendor_update")) {
            columns.splice(4, 1);
        }

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                return {
                    no: idx,
                    id:val.id,
                    title: t(`vendor.${val.slug}`),
                    slug: val.slug,
                    start_time: val.start_time,
                    end_time: val.end_time
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("game-maintenance.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (

                            <div
                                className={`form-horizontal filter-function row ${
                                    this.state.showCard ? "open" : "close"
                                } filter-wrapper-2`}
                            >
                                {/* <div className='row'> */}
                                <div className="col-md-4 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("game-maintenance._title")}
                                        </label>
                                        <StringInput
                                            id="title"
                                            name="title"
                                            value={this.props.filter.title}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col mt-auto">
                                    <div className="d-flex">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="filter"
                                        buttonName={t("global.filter")}
                                        clicked={() =>
                                            this.props.refreshHandler()
                                        }
                                    />
                                    &nbsp;
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary"
                                        idValue="reset"
                                        buttonName={t("global.reset")}
                                        clicked={() =>
                                            this.props.clearHandler()
                                        }
                                    />
                                    </div>
                                </div>
                            </div>
                                    )}
                                </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("game-maintenance.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={
                                            this.props.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(Listing);
