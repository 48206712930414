import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Skeleton from "react-loading-skeleton";
import UpdownArrow from "../../assets/images/updown-arrow.png";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

class ShipmentsPaymentListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCard: false,
		};
	}
	
	// componentDidMount() {}

  	// shouldComponentUpdate(nextProps, nextState) {
    // 	return true;
  	// }
	
	toggleCard = () => {
		this.setState({
			showCard: !this.state.showCard,
		});
	};
	
	render() {
		const { t } = this.props;
		
		return (
			<Auxiliary>
				<div className="row row-sm">
					<div className="breadcrumb-header justify-content-between">
						<div className="my-auto">
							<div className="d-flex">
								<h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
									{t("shipment-payment.shipment-payment")}
								</h4>
							</div>
						</div>
					</div>
					<div className="col-xl-12">
						<div className="card card-body card-primary">
							<div>
								{this.props.loading ? (
									<Skeleton count={2} />
								) : (
									<div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
										  <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.create-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon
                                                            icon={faCalendarCheck}
                                                        />
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format='YYYY/MM/DD'
                                                        value={
                                                            this.props.filter
                                                                .transaction_date
                                                        }
                                                        onChange={
                                                            this.props.onInputChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

										<div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member.email")}
                                                </label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    value={this.props.filter.email}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("shipment-payment.order-code")}
												</label>
												<div className="select-input">
                                                    <StringInput
                                                        id="order_code"
                                                        name="order_code"
                                                        value={this.props.filter.order_code}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
												</div>
											</div>
										</div>

										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("shipment-payment.tracking-number")}
												</label>
												<div className="select-input">
                                                    <StringInput
                                                        id="tracking_number"
                                                        name="tracking_number"
                                                        value={this.props.filter.tracking_number}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
												</div>
											</div>
										</div>

										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("shipment-payment.parcel-number")}
												</label>
												<div className="select-input">
                                                    <StringInput
                                                        id="parcel_number"
                                                        name="parcel_number"
                                                        value={this.props.filter.parcel_number}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
												</div>
											</div>
										</div>
										
										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("price-list.courier_name")}
												</label>
                        						<div className="select-input">
													<select
														className="form-control filter-input"
														id="courier_name"
														name="courier_name"
														onChange={this.props.onInputChange}
														value={this.props.filter.courier_name}
													>
                            							<option value=""></option>
														{this.props.ddCourier && this.props.ddCourier.map((val, idx) => {
															const translatedCourier = t(`price-list.${val.courier_name}`);
															return (
																<option key={idx} value={val.slug}>
																	{translatedCourier}
																</option>
															);
														})}
													</select>
													<img
														className="updown-icon"
														src={UpdownArrow}
														alt="Up Down Arrow"
													/>
												</div>
											</div>
										</div>

										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("price-list.shipment_type")}
												</label>
												<div className="select-input">
													<select
														className="form-control filter-input"
														id="shipment_type"
														name="shipment_type"
														onChange={this.props.onInputChange}
														value={this.props.filter.shipment_type}
                          							>
														<option value=""></option>
														{this.props.ddTransport && this.props.ddTransport.map((val, idx) => {
															return (
																<option key={idx} value={val.slug}>
																	{t(`price-list.${val.type}`)}
																</option>
															);
														})}
													</select>
													<img
														className="updown-icon"
														src={UpdownArrow}
														alt="Up Down Arrow"
													/>
												</div>
											</div>
										</div>
										
										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("shipment-payment.status")}
												</label>
                        						<div className="select-input">
													<select
														className="form-control filter-input"
														id="status"
														name="status"
														onChange={this.props.onInputChange}
														value={this.props.filter.status}
													>
                            							<option value=""></option>
														{this.props.ddStatus && this.props.ddStatus.map((val, idx) => {
															return (
																<option key={idx} value={val.value}>
																	{t(`transaction.status-desc.${val.value}`)}
																</option>
															);
														})}
													</select>
													<img
														className="updown-icon"
														src={UpdownArrow}
														alt="Up Down Arrow"
													/>
												</div>
											</div>
										</div>
                    					<div className="col mt-auto">
                      						<div className="d">
												<Button
													typeButton="button"
													classValue="btn btn-blue"
													idValue="filter"
													buttonName={t("global.filter")}
													clicked={() => this.props.refreshHandler()}
												/>
                        						&nbsp;
												<Button
													typeButton="button"
													classValue="btn btn-secondary"
													idValue="reset"
													buttonName={t("global.reset")}
													clicked={() => this.props.clearHandler()}
												/>

												{(() => {
                                                    if (this.props.totalRecords > 0 && this.props.permission.includes("shipment_payment_access")) {
                                                        return (
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-blue mr-2"
                                                                clicked={() => this.props.exportExcelHandler()}
                                                                buttonName={t("transaction.export-excel")}
                                                            />
                                                        );
                                                    }
                                                })()}
											</div>
										</div>
									</div>
								)}
              				</div>
              				<div className="show-btn" onClick={() => this.toggleCard()}>
                				{this.state.showCard ? <AiFillMinusCircle /> : ( <AiFillPlusCircle />)}
							</div>
						</div>
					</div>

					<div className="col-xl-12">
						<div className="card">
							<div className="d-flex align-items-end">
								<div className="card-header pb-0 ps-0">
									<div className="d-flex justify-content-between">
										<h4 className="card-title mg-b-0">
											{t("shipment-payment.shipment-payment-listing")}
										</h4>
									</div>
								</div>

							{/* <div className="d-flex">
									<div>
										<div className="btn-group">
											{this.props.permission.includes("shipment_store") && (
												<>
													<AiFillPlusCircle />
													<Button
														typeButton="button"
														classValue="btn btn-underline"
														idValue="btnCreateShipment"
														buttonName={t("price-list.add-shipment")}
														clicked={() =>
														this.props.changePageHandler("create")}
													/>
												</>
											)}
										</div>
									</div>
								</div> */}
							</div>

							<div className="card-body">
								<div className="table-responsive table-hover datatable">
									<DataTable
										keyField="shipmentId"
										data={this.props.dataListing}
										columns={this.props.columns}
										activePage={this.props.activePage}
										limit={this.props.limit}
										totalItems={this.props.totalRecords}
										paginationHandler={(val) =>
											this.props.datatablesChangeHandler(val, "activePage")
										}
										sizePerPageHandler={(val) =>
											this.props.datatablesChangeHandler(val, "limit")
										}
										classValue="dataTable table-bordered text-justify text-center"
										loading={this.props.loading}
										hideSearchBar={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Auxiliary>
		);
  	}
}

export default withNamespaces("translation")(ShipmentsPaymentListing);
