import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch =>
{
    axios.get('transaction/list', {params: props})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      });
    })
    .catch(err =>
    {
      
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('transaction/drop_down')
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const getDetails = (id) => dispatch =>
{
    if(id > 0)
    {
      axios.get('transaction/details',{
        params: {
            "id": id,
        }
      })
      .then(function (response)
      {
        dispatch (
        {
          type: actionType.DETAILS,
          value: response.data
        })
      })
      .catch(function (error)
      {

      });
    }
};

export const getBellRingNotification = () => dispatch => {
  axios.get('system_alert/bell_ring_notification')
  .then((response) =>
  {
    dispatch (
    {
      type: actionType.NOTIFICATION,
      value: response.data
    })
  })
  .catch(err => 
  {
    console.warn(err);
  });
};