import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Textarea from "../../components/Input/Textarea/Textarea";
import {default as ReactSelect} from "react-select";
import Button from "../../components/Button/Button";
import {postSpecialBirthdayGift, getMembers} from "../../store/ducks/specialBirthdayGift/actions";
import {formValidation} from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import {withNamespaces} from "react-i18next";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";

//Initialize the bank name array first
let usernameArry = [{
    label: 'Please Select Members',
    value: '',
    selected: true
}];

class SpecialBirthdayGiftCreate extends Component {
    state = {
        date: '',
        member_id: '',
        description: '',

        error_message: [],
        touched: false,
    };

    componentDidMount() {
        formValidation('special_birthday_gift_form');

        const getMembersData = {};
        getMembersData.country_id = this.props.currentCountryId;

        this.props.getMembers(getMembersData);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.special_birthday_gift_members_data !== this.props.special_birthday_gift_members_data) {
            if (nextProps.special_birthday_gift_members_data.data.responseCode === 200) {
                let data = nextProps.special_birthday_gift_members_data.data.data;
                Object.keys(data).map((item, i) => {
                    return usernameArry.push({
                        'label': data[item].username, value: data[item].id
                    });
                })

                this.setState({loading: false});
            } else if (nextProps.special_birthday_gift_members_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.special_birthday_gift_members_data.message
                });
                Swal.close();
            } else {
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.special_birthday_gift_members_data.message[0],
                    icon: nextProps.special_birthday_gift_members_data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.post_special_birthday_gift_data !== this.props.post_special_birthday_gift_data) {
            if (nextProps.post_special_birthday_gift_data.responseCode === 200) {
                this.setState({touched: false});
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_special_birthday_gift_data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.post_special_birthday_gift_data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.post_special_birthday_gift_data.message
                });
                Swal.close();
            } else {
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_special_birthday_gift_data.message[0],
                    icon: nextProps.post_special_birthday_gift_data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }

        }

        return true;
    }

    postSpecialBirthdayGiftHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};
                data.member_id = this.state.member_id.value;
                data.date = moment(this.state.date).format('yyyy-MM-DD');
                data.description = this.state.description;
                data.created_by = this.props.currentUsername;

                this.props.postSpecialBirthdayGift(data);

            }
        })

    }

    getDateHandler = (event) => {
        this.setState({
            date: event
        })
    }

    getUsernameHandler = (event) => {
        this.setState({
            member_id: event
        });
    }

    getDescriptionHandler = (event) => {
        this.setState({
            description: event.target.value
        })
    }

    render() {
        const {t} = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("special-birthday-gift.special-birthday-gift")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("special-birthday-gift.add-special-birthday-gift")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="special_birthday_gift_form" className="form-horizontal"
                                      onSubmit={this.postSpecialBirthdayGiftHandler} noValidate>
                                    <ValidationError formName="bank_form" touched={this.state.touched}
                                                     message={this.state.error_message}/>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="date">
                                                    {t("special-birthday-gift.date")}
                                                </label>

                                                <DatePicker
                                                    name="date"
                                                    className="form-control"
                                                    wrapperClassName="wrapperDatePicker"
                                                    selected={this.state.date}
                                                    onChange={this.getDateHandler}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    dateFormat="yyyy-MM-dd"
                                                    popperContainer={DatePickerContainer}
                                                    autoComplete="off"
                                                />

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="username">
                                                    {t('special-birthday-gift.username')}
                                                </label>

                                                <ReactSelect
                                                    id="username"
                                                    name="username"
                                                    options={usernameArry}
                                                    onChange={this.getUsernameHandler}
                                                    value={this.state.member_id}
                                                    required
                                                />
                                            </div>


                                            <div className="form-group">
                                                <label htmlFor="description">
                                                    {t('special-birthday-gift.description')}
                                                </label>

                                                <Textarea
                                                    name="description"
                                                    id="description"
                                                    className="form-control"
                                                    onChange={this.getDescriptionHandler}
                                                    value={this.state.description}
                                                    rows={6}
                                                />
                                            </div>

                                            <div className="form-group mb-0 mt-3 justify-content-end">
                                                <div>
                                                    <Button
                                                        typeButton="submit"
                                                        classValue="btn btn-primary"
                                                        idValue="submit"
                                                        buttonName={t("global.save")}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {specialBirthdayGift, currentUser} = state;

    //Getting the state from the reducers
    return {
        post_special_birthday_gift_data: specialBirthdayGift.post_special_birthday_gift_data,
        special_birthday_gift_members_data: specialBirthdayGift.special_birthday_gift_members_data,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username
    }
};

const mapDispatchToProps = {
    postSpecialBirthdayGift,
    getMembers
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SpecialBirthdayGiftCreate));