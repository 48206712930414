import Dashboard from "./containers/Dashboard/Dashboard";
import Bank from "./containers/Banks/Banks";
import BanksAccount from "./containers/BanksAccount/BanksAccount";
import BanksLevel from "./containers/BanksLevel/BanksLevel";
import Transaction from "./containers/Transaction/Transaction";
import WalletAdjustment from "./containers/WalletAdjustment/WalletAdjustment";
import Deposit from "./containers/Deposit/Deposit";
import Announcement from "./containers/Announcements/Announcement";
import Agent from "./containers/Agents/Agent";
import AgentSetting from "./containers/AgentSetting/AgentSetting";
import AgentPayout from "./containers/AgentPayout/AgentPayout";
import AgentTransaction from "./containers/AgentTransaction/AgentTransaction";
import Admin from "./containers/Admins/Admin";
import Member from "./containers/Members/Member";
import MemberLevelTracker from "./containers/MemberLevelTracker/MemberLevelTracker";
import SEO from "./containers/SEO/SEO";
import SystemSettings from "./containers/SystemSettings/SystemSetting";
import EmailContent from "./containers/EmailContent/EmailContent";
import Translation from "./containers/Translation/Translation";
import ReferralSettings from './containers/ReferralSettings/ReferralSettings';
import ReferralPayout from './containers/MemberReferral/Index';
import Referral from "./containers/MemberReferral/MemberReferral";
import DemoDateTimeRange from "./containers/Demo/DemoDateTimeRange";
import DemoReduxPersist from "./containers/Demo/DemoReduxPersist/Counter";
import DemoReactHelmet from "./containers/Demo/DemoSeo/ReactHelmet";
import DemoReactHelmetAsync from "./containers/Demo/DemoSeo/ReactHelmetAsync";
import Banner from "./containers/Banners/Banners";
import Popup from "./containers/Popups/Popups";
import Withdrawal from "./containers/Withdrawal/Withdrawal";
import Promotion from "./containers/Promotion/Promotion";
import RebateSetting from "./containers/RebateSetting/RebateSetting";
import MemberRebate from "./containers/MemberRebate/MemberRebate";
import WalletType from "./containers/WalletType/WalletType";
import PaymentGateway from "./containers/PaymentGateway/PaymentGateway";
import BankBalanceAdjustment from "./containers/BankBalanceAdjustment/BankBalanceAdjustment";
import MemberGroup from "./containers/MemberGroup/MemberGroup";
import FloatImage from "./containers/FloatImage/FloatImage";
import Tournament from "./containers/Tournament/Tournament";
import GameList from "./containers/GameList/GameList";
import GameMaintenance from "./containers/GameMaintenance/GameMaintenance";
import GameBetSlip from "./containers/GameBetSlip/GameBetSlip";
import ActivityLog from "./containers/Log/ActivityLog/ActivityLog";
import Log from "./containers/Log/Log";
import Role from "./containers/Role/Role";
import IPAddress from "./containers/IPAddress/IPAddress";
import Permission from "./containers/Permission/Permission";
import Inbox from "./containers/Inbox/Inbox";
import SpecialBirthdayGift from "./containers/SpecialBirthdayGift/SpecialBirthdayGift";
import Crypto from "./containers/Crypto/Crypto";
import GameCategory from "./containers/GameCategory/GameCategory";
import GameVendor from "./containers/GameVendor/GameVendor";
import RejectReason from "./containers/RejectReason/RejectReason";
import UpLevelBonus from "./containers/UpLevelBonus/UpLevelBonus";
import MemberPromotion from "./containers/MemberPromotion/MemberPromotion";
import DepositDayReport from "./containers/Report/DepositDayReport/DepositDayReport";
import DepositStatusReport from "./containers/Report/DepositStatusReport/DepositStatusReport";
import VendorStatusReport from "./containers/Report/VendorStatusReport/VendorStatusReport";
import YearlyVendorStatusReport from "./containers/Report/YearlyVendorStatusReport/YearlyVendorStatusReport";
import MemberStatusReport from "./containers/Report/MemberStatusReport/MemberStatusReport";
import MemberPayoutReport from "./containers/Report/MemberPayoutReport/MemberPayoutReport";
import CompanyPerformaceByDay from "./containers/CompanyPerformaceByDay/CompanyPerformaceByDay";
import WhitelistIP from "./containers/WhitelistIP/WhitelistIP";
import PromotedVideo from "./containers/PromotedVideo/PromotedVideo";
import VIPBonus from "./containers/VIPBonus/VIPBonus";
import CoinSetting from "./containers/CoinSetting/CoinSetting";
import RewardItem from "./containers/RewardItem/RewardItem";
import StakingSettings from './containers/StakingSetting/StakingSettings';
import MemberStakings from "./containers/MemberStakings/MemberStakings";
import BusinessAccount from "./containers/BusinessAccount/BusinessAccount";
import LuckySpinPrize from "./containers/LuckySpinPrize/LuckySpinPrize";
import LuckySpinSetting from "./containers/LuckySpinSetting/LuckySpinSetting";
import LuckySpinHistory from "./containers/LuckySpinHistory/LuckySpinHistory";
import LuckySpinGiftAssign from "./containers/LuckySpinGiftAssign/LuckySpinGiftAssign";
import GoldDiggerSetting from "./containers/GoldDigger/GoldDiggerSetting";
import GoldDigger from "./containers/GoldDigger/GoldDigger";
import DailyMission from "./containers/DailyMission/DailyMission";
import DailyMissionSetting from "./containers/DailyMission/DailyMissionSetting";
import MemberLoginStatus from "./containers/MemberLoginStatus/MemberLoginStatus";
import EventLeague from "./containers/EventLeague/EventLeague";
import RewardItemHistory from "./containers/RewardItemHistory/RewardItemHistory";
import EventMatch from "./containers/Event/Match/EventMatch";
import Leaderboard from "./containers/Leaderboard/Leaderboard";
import EventBetListing from "./containers/Event/BetListing/EventBet";
import EventQNAQuestion from "./containers/Event/QNAQuestion/QNAQuestion";
import EventQNABet from "./containers/Event/QNABet/QNABet";
import EventQNASetting from "./containers/Event/QNASetting/QNASetting";
import RescueBonusSetting from "./containers/RescueBonusSetting/RescueBonusSetting";
import MobileApps from "./containers/MobileApps/MobileApps";
import FirstBetBonusListing from "./containers/FirstBetBonus/FirstBetBonus";
import FirstBetBonusSetting from "./containers/FirstBetBonus/FirstBetBonusSetting";
import AngpauSetting from "./containers/AngpauSetting/AngpauSetting";
import GameFav from "./containers/GameFav/GameFav";
import EmailBlast from "./containers/EmailBlast/EmailBlast";
import Voucher from "./containers/Voucher/Voucher";
import VoucherClaim from "./containers/VoucherClaim/VoucherClaim";
import SocialMediaBlast from "./containers/SocialMediaBlast/SocialMediaBlast";
import MemberLoginHistoryReport from "./containers/Report/MemberLoginHistoryReport/MemberLoginHistoryReport";
import AgentDeposit from "./containers/AgentDeposit/AgentDeposit";
import AgentWithdrawal from "./containers/AgentWithdrawal/AgentWithdrawal";

import Parcel from "./containers/Parcel/Parcel";
import Shipment from "./containers/Shipments/Shipments";
import ShipmentsPayment from "./containers/ShipmentsPayment/ShipmentsPayment";
import SensitiveGoodReturn from "./containers/SensitiveGoodReturn/SensitiveGoodReturn";
import ItemCategory from "./containers/ItemCategory/ItemCategory";

const routes = [
    // Dashboard
    { path: "/", exact: true, name: "Home" },
    { path: "/home", component: Dashboard },

    // 1. Parcel
    { path: "/parcel", component: Parcel },

    // 1. Transaction
    { path: "/transaction", component: Transaction },
    { path: "/deposit", component: Deposit },
    { path: "/wallet_adjustment", component: WalletAdjustment },
    { path: "/withdrawal", component: Withdrawal },

    // 2 Bank
    { path: "/banks", exact: true, component: Bank },
    { path: "/banks_account", exact: true, component: BanksAccount},
    { path: "/banks_level", exact: true, component: BanksLevel },
    { path: "/payment_gateway", exact: true, component: PaymentGateway },
    { path: "/bank_balance_adjustment", exact: true, component: BankBalanceAdjustment},
    { path: "/crypto", exact:true, component: Crypto},
    { path: "/staking_settings", exact: true, component: StakingSettings},

    // 3. Member
    { path: "/member", exact: true, name: "Member", component: Member },
    { path: "/member_group", exact: true, component: MemberGroup },
    { path: "/member_level_tracker", exact: true, component: MemberLevelTracker },
    { path: "/member_stakings", exact: true, component: MemberStakings},
    { path: "/business_account", exact: true, component: BusinessAccount},
    { path: "/member_login_status", exact: true, component: MemberLoginStatus},

    // 4. Admin
    { path: "/admin", exact: true, name: "Admin", component: Admin },
    { path: "/role", exact: true, name: "Role", component: Role },
    { path: "/permission", exact: true, name: "Permission", component: Permission },

    // 5. Agent
    { path: "/agent", exact: true, name: "Agent", component: Agent },
    { path: "/agent_payout", exact: true, component: AgentPayout },
    { path: "/agent_transaction", exact: true, component: AgentTransaction },
    { path: "/agent_setting", exact: true, component: AgentSetting },
    { path: "/agent_deposit", exact: true, component: AgentDeposit },
    { path: "/agent_withdrawal", exact: true, component: AgentWithdrawal },

    // 6. Marketing
    { path: "/seo_management", exact: true, name: "SEO", component: SEO},
    { path: "/banners", exact: true, component: Banner },
    { path: "/popups", exact: true, component: Popup},
    { path: "/floatimage", exact: true, component: FloatImage},
    { path: "/tournament", exact: true, component: Tournament},
    { path: "/leaderboard", exact: true, component: Leaderboard},
    { path: "/special_birthday_gift", exact: true, name: "SpecialBirthdayGift", component: SpecialBirthdayGift},
    { path: "/promoted_video", exact: true, name: "PromotedVideo", component: PromotedVideo},

    // 7. Wallet Type
    { path: "/wallet_type", exact: true, component: WalletType },

    // 8. Promotion
    { path: "/promotion", exact: true, component: Promotion },
    { path: "/rebate_setting", exact: true, component: RebateSetting },
    { path: "/member_rebate", exact: true, component: MemberRebate },

    // 9. Settings
    { path: "/system_settings", exact: true, name: "SystemSettings", component: SystemSettings },
    { path: "/ip-address", exact: true, component: IPAddress },
    { path: "/translation", exact: true, name: "Translation", component: Translation },
    { path: "/reject_reason", exact: true, component: RejectReason },
    { path: "/up_level_bonus", exact: true, component: UpLevelBonus },
    { path: "/member_promotion", exact: true, component: MemberPromotion },
    { path: "/whitelist_ip", exact: true, component: WhitelistIP },
    { path: "/vip_bonus", exact: true, component: VIPBonus },
    { path: "/coin_setting", exact: true, component: CoinSetting },
    { path: "/rescue_bonus_setting", exact: true, component: RescueBonusSetting },

    // 10. Game Management
    { path: "/game_vendor", exact: true, component: GameVendor },
    { path: "/game_list", exact: true, component: GameList },
    { path: "/game_maintenance", exact: true, component: GameMaintenance },
    { path: "/game_category", exact: true, component: GameCategory },
    { path: "/game_category/:data", exact: true, component: GameCategory },
    { path: "/game_bet_slip", exact: true, component: GameBetSlip },
    { path: "/game_fav", exact: true, component: GameFav },

    // 11. Log Management
    { path: "/activity_log", exact: true, component: ActivityLog },
    { path: "/log_management", exact: true, component: Log },

    // 12.Message Board
    { path: "/announcement", exact: true, name: "Announcement", component: Announcement },
    { path: "/email_content", exact: true, name: "EmailContent", component: EmailContent },
    { path: "/inboxs", exact: true, name: "Inbox", component: Inbox },

    // 13. Member Referral
    { path: "/referral_settings", exact: true, name: "ReferralSettings", component: ReferralSettings },
    { path: "/member_referral", exact: true, name: "Referral", component: Referral },
    { path: "/referral_payout", exact: true, name: "ReferralPayout", component: ReferralPayout },

    // 14. Report
    { path: "/deposit_status_report", exact: true, name: "DepositStatusReport", component: DepositStatusReport },
    { path: "/deposit_day_report", exact: true, name: "DepositDayReport", component: DepositDayReport },
    { path: "/company_performance_by_day", exact: true, component: CompanyPerformaceByDay },
    { path: "/vendor_status_report", exact: true, name: "VendorStatusReport", component: VendorStatusReport },
    { path: "/yearly_vendor_status_report", exact: true, name: "YearlyVendorStatusReport", component: YearlyVendorStatusReport },
    { path: "/member_status_report", exact: true, name: "MemberStatusReport", component: MemberStatusReport },
    { path: "/member_payout_report", exact: true, name: "MemberPayoutReport", component: MemberPayoutReport },

    // 15. Mall Management
    { path: "/reward_item", exact: true, component: RewardItem },
    { path: "/reward_item_history", exact: true, component: RewardItemHistory},

    // 16. Lucky Spin
    { path: "/lucky_spin_prize", exact: true, component: LuckySpinPrize },
    { path: "/lucky_spin_setting", exact: true, component: LuckySpinSetting },
    { path: "/lucky_spin_history", exact: true, component: LuckySpinHistory },
    { path: "/lucky_spin_gift_assign", exact: true, component: LuckySpinGiftAssign },
    
    // 17. Gold Digger
    { path: "/gold_digger_setting", exact: true, component: GoldDiggerSetting },    
    { path: "/gold_digger_listing", exact: true, component: GoldDigger },    
    
    // 18. Daily Mission
    { path: "/daily_mission_setting", exact: true, component: DailyMissionSetting },
    { path: "/daily_mission", exact: true, component: DailyMission },

    // 19. League Event
    { path: "/league_event", exact: true, component: EventLeague },

    // Demo
    { path: "/demo/datetimerange", exact: true, name: "DemoDateTimeRange", component: DemoDateTimeRange },
    { path: "/demo/redux_persist", exact: true, name: "DemoReduxPersist", component: DemoReduxPersist },
    { path: "/demo/react_helmet", exact: true, name: "DemoReactHelmet", component: DemoReactHelmet },
    { path: "/demo/react_helmet_async", exact: true, name: "DemoReactHelmetAsync", component: DemoReactHelmetAsync },

    // Event Match
    { path: "/match_event", exact: true, component: EventMatch },

    // Event Bet Listing
    { path: "/event_bet_listing", exact: true, component: EventBetListing },
    
    // Event QNA Question
    { path: "/event_qna_question", exact: true, component: EventQNAQuestion },
    
    // Event QNA Bet
    { path: "/event_qna_bet", exact: true, component: EventQNABet },

    // Event QNA Setting
    { path: "/event_qna_setting", exact: true, component: EventQNASetting },

    // Mobile Apps
    { path: "/mobile_apps", exact: true, component: MobileApps },
    
    // First Bet Bonus
    { path: "/first_bet_bonus_listing", exact: true, component: FirstBetBonusListing },
    { path: "/first_bet_bonus_setting", exact: true, component: FirstBetBonusSetting },

    // Angpau Setting
    { path: "/angpau_setting", exact: true, component: AngpauSetting },

    // Email Blast
    { path: "/email_blast", exact: true, component: EmailBlast },
    
    // Voucher
    { path: "/voucher", exact: true, component: Voucher },
    { path: "/voucher_claim", exact: true, component: VoucherClaim },

    // Social Media Blast
    { path: "/social_media_blast", exact: true, component: SocialMediaBlast },

    // Member Login History Report
    { path: "/member_login_history", exact: true, component: MemberLoginHistoryReport },

    //Shipments
    { path: "/shipments", exact: true, component: Shipment },

    //Shipments Payment
    { path: "/shipment_payment", exact: true, component: ShipmentsPayment },

    //Sensitive Good Return
    { path: "/sensitive_good_return", exact: true, component: SensitiveGoodReturn },

    //Item Category
    { path: "/item_category", exact: true, component: ItemCategory },
];

export default routes;
