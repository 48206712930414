import React, { Component } from "react";
import { connect } from "react-redux";
import { getMembers, storeEmailBlast, excelTemplate } from "../../store/ducks/emailBlast/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
// import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import DatePicker from "react-datepicker";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import * as moment from "moment";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Select2 from "../../components/Input/Select2/Select2";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

let members = [];
class EmailBlastCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            start_time: "",
            error_message: [],
            touched: false,
            description: "",
            selectedMembers: [],
            type: "select",
            downloadUrl: "",
            uploadFile: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        formValidation("email_blast_form");

        this.props.getMembers();
        this.props.excelTemplate();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.store !== this.props.store) {
            if (nextProps.store.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.store.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.store.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.store.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.get_members !== this.props.get_members) {
            if (nextProps.get_members.data.responseCode === 200) {
                Object.keys(nextProps.get_members.data.data.result).map((item, i) => {
                    return members.push({
                        label: nextProps.get_members.data.data.result[item].username,
                        value: nextProps.get_members.data.data.result[item].id,
                    });
                });
            } else if (nextProps.get_members.data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.get_members.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if(nextProps.excel !== this.props.excel){
            if (nextProps.excel.responseCode === 200) {
               this.setState({
                    downloadUrl: nextProps.excel.data.downloadUrl
               })
            } else if (nextProps.excel.data.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.excel.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    storeEmailBlastHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
              
                if(!this.state.uploadFile){
                    let storeData = {};
                    storeData.title = this.state.title;
                    storeData.start_time = moment(this.state.start_time).format("yyyy-MM-DD HH:mm:ss");
                    storeData.country_id = this.props.currentCountryId;
                    storeData.member_id = this.state.selectedMembers;
                    storeData.details = this.state.description;

                    this.props.storeEmailBlast(storeData);
                }else{
                    let formData = new FormData();
                    formData.append("title",this.state.title);
                    formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm:ss"));
                    formData.append("country_id",this.props.currentCountryId);
                    formData.append("details",this.state.description);
                    formData.append("member_id[]", this.state.uploadFile);

                    this.props.storeEmailBlast(formData);
                }
               

             
            }
        });
    };

    handleMembersChange = (event) => {
        this.setState({
            selectedMembers: event,
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleMembersSelectAll = (event, membersArr) => {
        this.setState({
            selectedMembers: membersArr,
        });
    };

    handleMembersUnSelectAll = () => {
        this.setState({
            selectedMembers: [],
        });
    };

    handleSelectType = (event, type) => {
        this.setState({
            type: type
        })
    };

    downloadHandler = () => {
        // fetch(this.state.downloadUrl).then(response => {
        //     response.blob().then(blob => {
        //         let url = window.URL.createObjectURL(blob);
        //         let a = document.createElement('a');
        //         a.href = url;
        //         a.download = 'sample.csv';
        //         a.click();
        //     });
		// });

        const url = window.URL.createObjectURL(new Blob([this.state.downloadUrl])); 
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "sample.csv");
        document.body.appendChild(link);
        link.click();
    }

    onInputChange = (event) => {
        this.setState({
            uploadFile: event.target.files[0]
        });
    }

    // config = {
    //     zIndex: 0,
    //     readonly: false,
    //     activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
    //     toolbarButtonSize: 'middle',
    //     theme: 'default',
    //     enableDragAndDropFileToEditor: true,
    //     saveModeInCookie: false,
    //     spellcheck: true,
    //     editorCssClass: false,
    //     triggerChangeEvent: true,
    //     height: 220,
    //     direction: 'ltr',
    //     language: 'en',
    //     debugLanguage: false,
    //     i18n: 'en',
    //     tabIndex: -1,
    //     toolbar: true,
    //     enter: 'P',
    //     useSplitMode: false,
    //     colorPickerDefaultTab: 'background',
    //     imageDefaultWidth: 100,
    //     removeButtons: ['source', 'fullsize', 'about', 'outdent', 'indent', 'video', 'print', 'table', 'fontsize', 'superscript', 'subscript', 'file', 'cut', 'selectall'],
    //     disablePlugins: ['paste', 'stat'],
    //     events: {},
    //     textIcons: false,
    //     uploader: {
    //       insertImageAsBase64URI: true
    //     },
    //     placeholder: '',
    //     showXPathInStatusbar: false
    //   };

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-blast.email-blast")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-blast.add-email-blast")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="email_blast_form" className="form-horizontal" onSubmit={this.storeEmailBlastHandler} noValidate>
                                    <ValidationError formName="email_blast_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.title")}</label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={t("email-blast.title")}
                                                    value={this.state.title}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.start-time")}</label>
                                                <DatePicker
                                                    name="start_time"
                                                    className="form-control"
                                                    selected={this.state.start_time}
                                                    onChange={this.handleStartTimeChange}
                                                    minDate={new Date()}
                                                    showTimeSelect
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("email-blast.members")}</label>

                                                <input
                                                    type="radio"
                                                    id="select"
                                                    name="vendor"
                                                    value="select"
                                                    className="mb-0 ml-2 align-items-center"
                                                    onClick={(e) => this.handleSelectType(e, "select")}
                                                />
                                                <label className="mb-0 ml-2" for="select">
                                                    {t("global.select")}
                                                </label>
                                                <input className="ml-3 mb-0 ml-2 align-items-center" type="radio" id="file" name="vendor" value="file" onClick={(e) => this.handleSelectType(e, "file")} />
                                                <label className="mb-0 ml-2" for="file">
                                                    {t("translation.files")}
                                                </label>

                                                {this.state.type === "select" ? (
                                                    <>
                                                        <Select2
                                                            id="members"
                                                            name="members"
                                                            options={members}
                                                            className="form-control"
                                                            onChange={this.handleMembersChange}
                                                            value={this.state.selectedMembers}
                                                            // maxSelectOption={30}
                                                            // isOptionDisabled={this.state.selectedMembers ? true : false}
                                                            selectedOptions={this.state.selectedMembers}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-end mt-3">
                                                            <input
                                                                type="radio"
                                                                id="select_all"
                                                                name="vendor"
                                                                value="select_all"
                                                                onClick={(e) => this.handleMembersSelectAll(e, members)}
                                                            />
                                                            <label className="mb-0 ml-2" for="select_all">
                                                                {t("global.select-all")}
                                                            </label>
                                                            <input
                                                                className="ml-3"
                                                                type="radio"
                                                                id="unselect_all"
                                                                name="vendor"
                                                                value="unselect_all"
                                                                onClick={this.handleMembersUnSelectAll}
                                                            />
                                                            <label className="mb-0 ml-2" for="unselect_all">
                                                                {t("global.unselect-all")}
                                                            </label>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                        <input
                                                            id='file'
                                                            name='file'
                                                            type='file'
                                                            onChange={this.onInputChange}
                                                            className="filter-input"
                                                        />
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                                        <div>
                                                            <Button typeButton="button" classValue="btn btn-secondary ml-1" idValue="download" buttonName={t("voucher.download")} clicked={this.downloadHandler} />
                                                        </div>
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <JoditEditor
                                                value={this.state.description || ""}
                                                onBlur={(newDescription) => {
                                                    this.setState({
                                                        description: newDescription,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state;

    return {
        get_members: state.emailBlast.get_members,
        store: state.emailBlast.store,
        currentCountryId: currentUser.data.country_id,
        excel: state.emailBlast.excel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMembers: () => dispatch(getMembers()),
        storeEmailBlast: (data) => dispatch(storeEmailBlast(data)),
        excelTemplate: () => dispatch(excelTemplate())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailBlastCreate));
