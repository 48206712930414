import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('game_vendor/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('game_vendor/drop_down')
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

// export const updateStatus = (data) => dispatch =>
// {
//     // console.log(data);
//     axios.post('game_vendor/update_status', data)
//     .then(function (response) 
//     {
//       dispatch (
//       {
//         type: actionType.UPDATE_STATUS,
//         value: response.data
//       })
//     })
//     .catch(err => 
//     {
      
//     });
// };

export const store = (data) => (dispatch, getState) =>
{
    // console.log(data);
    data.country_id = getState().currentUser.data.country_id;
    axios.post('game_vendor/store', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (id) => dispatch =>
{   
    if(id > 0)
    {
      axios.get('game_vendor/details',{
        params: {
            "id": id,
        }
      })
      .then(function (response) 
      {
        dispatch (
        {
          type: actionType.DETAILS,
          value: response.data
        })
      })
      .catch(function (error) 
      {
        
      });
    }
};

// export const update = (data) => dispatch =>
// {
//     // console.log(data);
//     axios.post('game_vendor/update', data)
//     .then(function (response) 
//     {
//       dispatch (
//       {
//         type: actionType.UPDATE,
//         value: response.data
//       })
//     })
//     .catch(err => 
//     {
      
//     });
// };