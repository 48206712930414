import React, { Component } from "react";
import { connect } from "react-redux";
import { getTranslationLanguages } from "../../store/ducks/translation/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import TranslationAdmin from "./TranslationAdmin";
import TranslationMember from "./TranslationMember";

class Translation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "translation_admin",
            loading: true,
            reload: true,
            permission: [],
            languages: [],
        };
    }

    componentDidMount() {
        this.props.getTranslationLanguages();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[Translation] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined && nextProps.currentPermission.length !== 0) {
            // let translationPermission = this.props.currentPermission.filter((item) => item.includes("translation_"));
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.languagesData !== undefined && nextProps.languagesData !== nextState.languages && nextState.loading === true) {
            this.setState({
                languages: (nextProps.languagesData.data && nextProps.languagesData.data.languages) ? nextProps.languagesData.data.languages : 'en',
                loading: false,
            });
        }

        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let tabId = this.state.pageShow;
        display = (
            <>
                {(tabId === "translation_admin" || tabId === "translation_member") && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-primary tabs-style-2 mt-3">
                                <div className="tab-menu-heading">
                                    <div className="tabs-menu1">
                                        <ul className="nav panel-tabs main-nav-line">
                                            <li>
                                                <button
                                                    id="translation_admin"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "translation_admin" ? "active" : ""}`}>
                                                    {t("translation.admin-site")}
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    id="translation_member"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "translation_member" ? "active" : ""}`}>
                                                    {t("translation.member-site")}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {tabId === "translation_admin" && <TranslationAdmin permission={this.state.permission} languages={this.state.languages} />}
                {tabId === "translation_member" && <TranslationMember permission={this.state.permission} languages={this.state.languages} />}
            </>
        );

        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { sideBar, currentUser, translation } = state;
    let permission = [];
    if (sideBar.hasOwnProperty("rs")) {
        permission = sideBar.rs.data.permission;
    }
    return {
        currentPermission: currentUser.data.permission === undefined ? permission : currentUser.data.permission,
        languagesData: translation.translation_languages_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTranslationLanguages: () => dispatch(getTranslationLanguages()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Translation));
