import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const transReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),    
    [type.NOTIFICATION]: (state, action) => ({
      ...state,
      notification: action.value,
  }),
});

export default transReducer;