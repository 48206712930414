import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const memberReferralReducer = createReducer(initialState) ({
    [type.MEMBER_REFERRAL_LISTING]: (state, action) => ({
        ...state,
        member_referral_data: action.payload
    }),

    [type.MEMBER_REFERRAL_SHOW]: (state, action) => ({
        ...state,
        member_referral_show: action.payload
    }),

    [type.MEMBER_REFERRAL_EDIT]: (state, action) => ({
        ...state,
        member_referral_edit_data: action.payload
    }),

    [type.MEMBER_REFERRAL_PENDING]: (state, action) => ({
        ...state,
        member_referral_pending_result: action.payload
    }),

    [type.REFERRAL_HISTORY_LISTING]: (state, action) => ({
        ...state,
        referral_history_data: action.payload
    }),

    [type.MEMBER_REFERRAL_PAYOUT]: (state, action) => ({
        ...state,
        member_referral_payout: action.payload
    }),

    [type.MEMBER_REFERRAL_PAYOUT_EDIT]: (state, action) => ({
        ...state,
        member_referral_payout_edit: action.payload
    }),

    [type.MEMBER_REFERRAL_PAYOUT_DETAIL]: (state, action) => ({
        ...state,
        member_referral_payout_detail: action.payload
    }),

    [type.MEMBER_REFERRAL_PAYOUT_HISTORY]: (state, action) => ({
        ...state,
        member_referral_payout_history: action.payload
    })
});

export default memberReferralReducer;