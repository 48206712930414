import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const memberBonusSettingsReducer = createReducer(initialState) ({
    [types.MEMBER_BONUS_SETTING_LIST]: (state, action) => ({
        ...state,
        member_bonus_settings: action.payload
    }),

    [types.MEMBER_BONUS_SETTING_CREATE]: (state, action) => ({
        ...state,
        member_bonus_settings_create: action.payload
    }),

    [types.MEMBER_BONUS_SETTING_SHOW]: (state, action) => ({
        ...state,
        member_bonus_settings_show: action.payload
    }),

    [types.MEMBER_BONUS_SETTING_UPDATE]: (state, action) => ({
        ...state,
        member_bonus_settings_update: action.payload
    })
});

export default memberBonusSettingsReducer;