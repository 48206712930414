import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const promotionReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_PROMOTION]: (state = initialState, action) => ({
        ...state,
        promotion_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.POST_PROMOTION]: (state, action) => ({
        ...state,
        post_promotion_result: action.payload,
    }),

    [type.SHOW_PROMOTION]: (state, action) => ({
        ...state,
        show_promotion_data: action.payload,
    }),

    [type.UPDATE_PROMOTION]: (state, action) => ({
        ...state,
        update_promotion_result: action.payload,
    }),

    [type.UPDATE_STATUS_PROMOTION]: (state, action) => ({
        ...state,
        update_status_promotion_result: action.payload,
    }),

    [type.UPDATE_SHOW_PROMOTION]: (state, action) => ({
        ...state,
        update_show_promotion_result: action.payload,
    }),

    [type.DELETE_PROMOTION]: (state, action) => ({
        ...state,
        delete_promotion_result: action.payload,
    }),

    [type.GET_TYPE]: (state = initialState, action) => ({
        ...state,
        type_data: action.payload
    }),

    [type.GET_MEMBER_GROUP]: (state = initialState, action) => ({
        ...state,
        member_group_data: action.payload
    }),

    [type.GET_CATEGORY]: (state = initialState, action) => ({
        ...state,
        category_data: action.payload
    }),
});

export default promotionReducer;