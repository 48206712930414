import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle } from "react-icons/ai";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import UpdownArrow from '../../assets/images/updown-arrow.png';
import Skeleton from "react-loading-skeleton";
import "./leaderboard.css";

class LeaderboardListing extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="row row-sm">
                <div className="col-xl-12">
                    <div className="card card-body card-primary">
                        {this.props.loading ? (
                            <Skeleton count={2} />
                        ) : (
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">{t("company-performance-by-day.date")}</label>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-calendar-check" />
                                            </span>
                                            <DateTimePicker
                                                id="date"
                                                name="date"
                                                className="form-control filter-input"
                                                range={false}
                                                timePicker={false}
                                                value={this.props.filter.date}
                                                onChange={this.props.onInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label htmlFor="vendor" className="font-weight-bolder">
                                            {t("report.vendor")}
                                        </label>
                                        <div className="select-input">
                                            <select
                                                className="form-control filter-input"
                                                id="vendor"
                                                name="vendor"
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.vendor}>
                                                <option value=""></option>
                                                {this.props.gameVendors.map((val, idx) => {
                                                    return (
                                                        <option key={val.id} value={val.id}>
                                                            {val.title}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col mt-auto">
                                    <div className="d-flex">
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-blue mr-2"
                                            idValue="filter"
                                            buttonName={t("global.filter")}
                                            clicked={() => this.props.searchHandler()}
                                        />
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-secondary"
                                            idValue="reset"
                                            buttonName={t("global.reset")}
                                            clicked={() => this.props.clearHandler()}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card">
                        <div className='d-flex align-items-end'>
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">Leaderboard</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <div className="btn-group">
                                        {this.props.permission.includes('leaderboard_create') &&
                                            <>
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-underline"
                                                    idValue="btnCreateTournament"
                                                    buttonName="Add Leaderboard"
                                                    clicked={() => this.props.changePageHandler('create')} />
                                            </>

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="leaderboard__winner card-body">
                            <div className="">
                                <DataTable
                                    keyField="leaderboard_id"
                                    data={this.props.data}
                                    columns={this.props.columns}
                                    activePage={this.props.activePage}
                                    limit={this.props.limit}
                                    totalItems={this.props.totalRecords}
                                    paginationHandler={this.props.pageChangeHandler}
                                    sorted={this.props.sorted}
                                    sizePerPageHandler={this.props.sizePerPageHandler}
                                    searchChangeHandler={this.props.searchChangeHandler}
                                    searchValue={this.props.searchValue}
                                    searchSubmitHandler={this.props.postSearchHandler}
                                    searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                    loading={this.props.loading}
                                    classValue="dataTable table-bordered text-justify text-center"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNamespaces("translation")(LeaderboardListing);
