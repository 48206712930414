import React, { Component } from 'react';
import { connect } from "react-redux";
import UserContext from './UserContext';

class UserProvider extends Component {
    render() {
        const user = {
            currentCountryId: this.props.currentCountryId,
            currentGroupId: 1
        }
        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        currentCountryId: state.currentUser.data.country_id,
    };
};

export default connect(mapStateToProps)(UserProvider);