import React, { Component } from "react";
import { connect } from "react-redux";
import { storeSetting } from "../../store/ducks/goldDigger/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";

class GoldDiggerSettingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            slug: "",
            setting_list: [{listIndex: Math.random(), key: "", value: ""}],
            error_message: [],
            touched: false,
            dropdown: props.dropdown,
            isGameType: false,
            proceedCreate: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() {
        formValidation("setting_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.storeResult !== this.props.storeResult) {
            if (nextProps.storeResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.storeResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.storeResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    storeSettingHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let valueArray = [];
                this.setState({proceedCreate: true});
                
                this.state.setting_list.forEach((item, idx) => {
                    if (this.state.isGameType === true && (valueArray.indexOf(item.value) > -1) === true) {
                        this.setState({proceedCreate: false});
                        
                        Swal.fire({
                            title: t("global.error"),
                            text: 'Duplicate value detected.',
                            icon: "error",
                            confirmButtonText: t("global.ok"),
                            didOpen: () => {
                                Swal.hideLoading();
                            }
                        });
                    }

                    if (this.state.isGameType === true && (isNaN(item.key) === true || item.key.replace(/\s/g, '') === '')) {
                        this.setState({proceedCreate: false});
                        
                        Swal.fire({
                            title: t("global.error"),
                            text: 'Key must be number.',
                            icon: "error",
                            confirmButtonText: t("global.ok"),
                            didOpen: () => {
                                Swal.hideLoading();
                            }
                        });
                    }

                    valueArray[item.key] = item.value;                                            
                });

                const valueJson = JSON.stringify(Object.assign({}, valueArray));                

                let storeData = {};
                storeData.name = this.state.name;
                storeData.slug = this.state.slug;
                storeData.game_type = this.state.game_type;
                storeData.value = valueJson;

                if (this.state.proceedCreate === true) {
                    this.props.storeSetting(storeData);
                }
            }
        });
    };

    addRecord = () => {
        if(this.state.setting_list.length < 20) {
            this.setState((prevState) => ({
                setting_list: [...prevState.setting_list, {listIndex: Math.random(), key: "", value: ""}],
            }));
        }
    };

    removeRecord = (i) => {
        this.setState({
            setting_list: this.state.setting_list.filter((item, idx) => i !== idx)
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'name') {
            if (event.target.value === 'Game Type') {
                this.setState({
                    isGameType: true
                });
            } else {
                this.setState({
                    isGameType: false
                });
            }
        }
    };

    handleSettingListChange = (event, idx) => {
        let dataID = 0;
        if (this.state.isGameType === true && event.target.name === 'value') {
            dataID = idx;
        } else {
            dataID = event.target.dataset.id;            
        }

        let setting_list = [...this.state.setting_list];
        setting_list[dataID][event.target.name] = event.target.value;
        this.setState({setting_list});
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("gold_digger.gold_digger_setting")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("gold_digger.add_setting")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="setting_form" className="form-horizontal" onSubmit={this.storeSettingHandler} noValidate>
                                    <ValidationError formName="setting_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("gold_digger.name")}</label>
                                                <StringInput
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder={t("gold_digger.name")}
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("gold_digger.slug")}</label>
                                                <StringInput
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    placeholder={t("gold_digger.slug")}
                                                    value={this.state.slug}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="game_type">{t("gold_digger.game_type")}</label>
                                                <Select
                                                    id="game_type"
                                                    name="game_type"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    options={this.state.dropdown.gameType}
                                                    emptyValue={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h3 className="card-title col-8">{t('gold_digger.value')}</h3>
                                                <div className="row col-4 justify-content-end mb-2 pr-0">
                                                    <button type="button" className="btn btn-info" onClick={(e) => this.addRecord(e)}>
                                                    <i className="fa fa-plus"></i> {t('gold_digger.add')}
                                                    </button>
                                                    
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{overflowY:'auto'}}>
                                                <table className="table" id="setting_table" style={{minWidth:500}}>
                                                    <thead className="text-center bg-primary">
                                                        <tr>
                                                            <th className="text-white p-2">{t("global.no")}</th>
                                                            <th className="text-white p-2">{t("gold_digger.key")}</th>
                                                            <th className="text-white p-2">{t("gold_digger.value")}</th>
                                                            <th style={{ width: "5%" }}/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.setting_list.map((item, idx) => {
                                                            return (
                                                                <tr key={idx} className="text-center">
                                                                    <td>{idx + 1}</td>
                                                                    <td key={`key-${idx}`} width="45%">
                                                                        <input
                                                                            id="key"
                                                                            name="key"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("gold_digger.key")}
                                                                            value={item.key}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td key={`value-${idx}`} width="45%">
                                                                        {
                                                                            this.state.isGameType ? (
                                                                                <Select
                                                                                    id="value"
                                                                                    name="value"
                                                                                    className="form-control"
                                                                                    placeholder={t("gold_digger.value")}
                                                                                    onChange={(e) => this.handleSettingListChange(e, idx)}
                                                                                    options={this.state.dropdown.allGameType}
                                                                                    value={item.value}
                                                                                    emptyValue={true}
                                                                                    required
                                                                                    />
                                                                            ) : (
                                                                                <input
                                                                                    id="value"
                                                                                    name="value"
                                                                                    data-id={idx}
                                                                                    className="form-control"
                                                                                    placeholder={t("gold_digger.value")}
                                                                                    value={item.value}
                                                                                    onChange={this.handleSettingListChange}
                                                                                    autoComplete="off"
                                                                                    required
                                                                                />
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <i className="fa fa-trash" style={{color:'red',cursor:'pointer'}} onClick={() => this.removeRecord(idx)}></i>
                                                                        {/* <button type="button" name="remove" className="remove btn btn-sm btn-danger mt-1" onClick={() => this.removeRecord(idx)}>
                                                                            <i className="fas fa-minus-square fa-2x"></i>
                                                                        </button> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        storeResult: state.goldDigger.store,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeSetting: (data) => dispatch(storeSetting(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GoldDiggerSettingCreate));
