import axios from "../../../common/config/axios";
import * as type from "./types";

export const getList = (data) => (dispatch, getState) => {
    axios.get("voucher_claim/list", {
            params: data,
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};