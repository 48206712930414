import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const bankLevelReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_BANKS_LEVEL_LIST]: (state = initialState, action) => ({
        ...state,
        bank_level_data: action.payload
    }),

    [type.GET_MEMBER_GROUP_NAME]: (state = initialState, action) => ({
        ...state,
        bank_level_member_group_data: action.payload
    }),

    [type.GET_BANKS_ACCOUNT_NAME]: (state = initialState, action) => ({
        ...state,
        bank_level_banks_account_data: action.payload
    }),

    [type.CREATE_BANKS_LEVEL]: (state, action) => ({
        ...state,
        post_bank_level_result: action.payload,
    }),

    [type.UPDATE_STATUS_BANKS_LEVEL]: (state, action) => ({
        ...state,
        update_status_bank_level_result: action.payload,
    }),

    [type.SHOW_BANKS_LEVEL]: (state, action) => ({
        ...state,
        show_bank_level_data: action.payload,
    }),

    [type.UPDATE_BANKS_LEVEL]: (state, action) => ({
        ...state,
        update_bank_level_result: action.payload,
    }),

    [type.DELETE_BANKS_LEVEL]: (state, action) => ({
        ...state,
        delete_bank_level_result: action.payload,
    }),
});

export default bankLevelReducer;