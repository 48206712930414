import * as type from "./types";
import { createReducer } from "../../util/index";
// import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    // data: [],
};

const emailContentReducer = createReducer(initialState) ({
    [type.EMAIL_CONTENT_LIST]: (state, action) => ({
        ...state,
        email_content_data: action.payload
    }),

    [type.EMAIl_CONTENT_GET_FILTER_DATA]: (state, action) => ({
        ...state,
        email_content_filter_data: action.payload
    }),

    [type.EMAIL_CONTENT_CREATE]: (state, action) => ({
        ...state,
        email_content_create_data: action.payload
    }),

    [type.EMAIL_CONTENT_STORE]: (state, action) => ({
        ...state,
        email_content_store_result: action.payload,
    }),

    [type.EMAIL_CONTENT_SHOW]: (state, action) => ({
        ...state,
        email_content_show_data: action.payload
    }),

    [type.EMAIL_CONTENT_EDIT]: (state, action) => ({
        ...state,
        email_content_edit_data: action.payload
    }),

    [type.EMAIL_CONTENT_UPDATE]: (state, action) => ({
        ...state,
        email_content_update_result: action.payload,
    }),

});

export default emailContentReducer;