import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
  loading: false,
  data: [],
};

const shipmentReducer = createReducer(initialState)({
  [type.GET_SHIPMENT_LIST]: (state = initialState, action) => ({
    ...state,
    shipment_data: action.payload,
  }),
  [type.DROPDOWN]: (state, action) => ({
    ...state,
    dropDown: action.value,
  }),

  [type.GET_AREA_DROPDOWN]: (state, action) => ({
    ...state,
    area_dropDown: action.value,
  }),

  [type.POST_SHIPMENT]: (state, action) => ({
    ...state,
    post_shipment_result: action.payload,
  }),

  [type.SHOW_SHIPMENT]: (state, action) => ({
    ...state,
    show_shipment_data: action.payload,
  }),

  [type.SHIPMENT_EDIT_DETAILS]: (state, action) => ({
    ...state,
    edit_shipment_data: action.payload
  }),

  [type.UPDATE_DETAIL_SHIPMENT]: (state, action) => ({
    ...state,
    update_shipment_result: action.payload,
  }),

  [type.DELETE_SHIPMENT]: (state, action) => ({
    ...state,
    delete_shipment: action.payload,
  }),

  [type.SHIPMENT_PAYMENT]: (state, action) => ({
    ...state,
    shipment_payment_list_result: action.payload,
  }),

  [type.SHOW_SHIPMENT_DETAILS]: (state, action) => ({
    ...state,
    show_shipment_details: action.payload,
  }),

  [type.SHIPMENT_PAYMENT_DROP_DOWN]: (state, action) => ({
    ...state,
    shipment_payment_drop_down: action.payload,
  })
});

export default shipmentReducer;
