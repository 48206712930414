import axios from "../../../common/config/axios";
import * as type from "./types";

export const getMemberReferral = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id
    axios
        .get("member_referral/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.MEMBER_REFERRAL_LISTING,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Referral Error", err);
            dispatch({
                type: type.MEMBER_REFERRAL_LISTING,
                payload: [],
            });
        });
};

export const showMemberReferral = (data) => (dispatch) => {
    axios.get("member_referral/show", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_REFERRAL_SHOW,
            payload: res
        })
    })
    .catch((err) => {
        dispatch({
            type: type.MEMBER_REFERRAL_SHOW,
            payload: []
        });
    });
};

export const editMemberReferral = (id) => (dispatch) => {
    axios
        .get("member_referral/edit", {
            params: {
                referee_id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_REFERRAL_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Member Referral Error" + err);
            dispatch({
                type: type.MEMBER_REFERRAL_EDIT,
                payload: err.response,
            });
        });
};

export const pendingMemberReferral = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member_referral/pending", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_REFERRAL_PENDING,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Pending Member Referral Error", err);
        });
};

export const getReferralHistory = (data) => (dispatch) => {
    axios
        .get("member_referral/history", {params: data})
        .then((res) => {
            dispatch({
                type: type.REFERRAL_HISTORY_LISTING,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Referral History Error", err);
            dispatch({
                type: type.REFERRAL_HISTORY_LISTING,
                payload: [],
            });
        });
};

export const getReferralPayout = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("member_referral/list_v2", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_REFERRAL_PAYOUT,
            payload: res 
        });
    })
    .catch((err) => {
        // console.log("Get Referral Payout Error", err);
    });
}

export const getReferralPayoutDetail = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("member_referral/details", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_REFERRAL_PAYOUT_DETAIL,
            payload: res
        })
    })
    .catch((err) => {
        // console.log("Get Referral Payout Detail Error", err);
    })
} 

export const getReferralPayoutHistory = (data) => (dispatch) => {
    axios.get("member_referral/history_v2", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_REFERRAL_PAYOUT_HISTORY,
            payload: res
        })
    })
    .catch((err) => {
        // console.log("Get Referral Payout History Error", err);
    })
}

export const submitReferralPayout = (data) => (dispatch) => {
    axios.post("member_referral/edit_v2", data)
    .then((res) => {
        dispatch({
            type: type.MEMBER_REFERRAL_PAYOUT_EDIT,
            payload: res
        })
    })
    .catch((err) => {
        // console.log("Submit Referral Payout Error", err);
    })
}