import React, { Component } from "react";
import { connect } from "react-redux";
import { editStakingSettings, updateStakingSettings } from "../../store/ducks/stakingSettings/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";

class StakingSettingsEdit extends Component {
    constructor(props) {
        super(props);
        // console.log(this.props);
        this.state = {
            id: null,
            slug: "",
            interest_percentage: null,
            referrer_interest_percentage: null,
            min_payout: null,
            loading: true,

            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        formValidation("referral_settings_form");
        console.log("componentDidMount", this.props);
        this.props.editStakingSettings(this.props.stakingEditId);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("componentDidUpdate",prevProps, prevState);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[ReferralSettingsEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.stakingSettingsEditData !== undefined && nextProps.stakingSettingsEditData !== this.props.stakingSettingsEditData && nextState.loading === true) {
            let stakingSettingsData = nextProps.stakingSettingsEditData.data.stakingSetting[0];

            // let selectedVendor = vendorArray.filter(x => x.gameVendorId === )

            this.setState({
                id: stakingSettingsData.stakingSettingsId,
                slug: stakingSettingsData.slug,
                interest_percentage: stakingSettingsData.interest_percentage,
                referrer_interest_percentage: stakingSettingsData.referrer_interest_percentage,
                min_payout: stakingSettingsData.min_payout,
                loading: false,
            });
        }

        if (nextProps.updateStakingSettingsResult !== this.props.updateStakingSettingsResult) {
            if (nextProps.updateStakingSettingsResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateStakingSettingsResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateStakingSettingsResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateStakingSettingsResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateReferralSettingsHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateStakingSettingsData = {};
                updateStakingSettingsData.edit_id = this.state.id;
                // updateReferralSettingsData.slug = this.state.slug;
                updateStakingSettingsData.interest_percentage = this.state.interest_percentage;
                updateStakingSettingsData.referrer_interest_percentage = this.state.referrer_interest_percentage;
                updateStakingSettingsData.min_payout = this.state.min_payout;

                this.props.updateStakingSettings(updateStakingSettingsData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("staking-settings.edit-staking-settings")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="referral_settings_form" className="form-horizontal" onSubmit={this.updateReferralSettingsHandler} noValidate>
                                    <ValidationError formName="referral_settings_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("referral-settings.slug")}</label>
                                                <StringInput
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    placeholder={t("referral-settings.slug")}
                                                    value={this.state.slug}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="interest_percentage">{t("staking-settings.interest-percentage")}</label>
                                                <NumberInput
                                                    id="interest_percentage"
                                                    name="interest_percentage"
                                                    min={1}
                                                    placeholder={t("referral-settings.deposit-requirement")}
                                                    value={this.state.interest_percentage}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="referrer_interest_percentage">{t("staking-settings.referrer-interest-percentage")}</label>
                                                <NumberInput
                                                    id="referrer_interest_percentage"
                                                    name="referrer_interest_percentage"
                                                    min={1}
                                                    placeholder={t("referral-settings.bonus-reward")}
                                                    value={this.state.referrer_interest_percentage}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="min_payout">{t("staking-settings.min-payout")}</label>
                                                <NumberInput
                                                    id="min_payout"
                                                    name="min_payout"
                                                    min={1}
                                                    placeholder={t("staking-settings.min-payout")}
                                                    value={this.state.min_payout}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("staking-settings.edit-staking-settings")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("staking-settings.staking-settings")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { stakingSettings } = state;

    return {
        stakingSettingsEditData: stakingSettings.staking_settings_edit_data,
        updateStakingSettingsResult: stakingSettings.staking_settings_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editStakingSettings: (id) => dispatch(editStakingSettings(id)),
        updateStakingSettings: (data) => dispatch(updateStakingSettings(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(StakingSettingsEdit));
