import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { updateAnswer } from "../../../store/ducks/event/qnaQuestion/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select2 from "../../../components/Input/Select2/Select2";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import ValidationError from "../../../components/ValidationError/ValidationError";

class QNAQuestionUpdateAnswer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error_message: [],
            touched: false,
            answer: "",
            selectedCountry: !!(this.props.rowData.answerArray) ? this.props.rowData.answerArray : [],
            answerLimit: props.answerLimit[props.rowData.id]
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation("question_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_answer !== this.props.update_answer) {
            if (nextProps.update_answer.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.update_answer.msgType,
                    confirmButtonText: t("global.ok"),  
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.update_answer.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.update_answer.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    updateAnswerHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let selectedAnswer = [];
                !!(this.state.selectedCountry) && this.state.selectedCountry.forEach(function(val) {
                    selectedAnswer.push(val.value);
                }, this);

                
                if (selectedAnswer.length < this.state.answerLimit) {
                    Swal.fire({
                        title: t("event_qna_question.answer_required").replace('##answerLimit##',this.state.answerLimit),
                        icon: "warning",
                        confirmButtonText: t("global.ok"),
                        didOpen: () => {
                            Swal.hideLoading();
                        }
                    })
                } else {
                    const selectedAnswerJson = JSON.stringify(Object.assign({}, selectedAnswer));

                    let updateData = {};
                    updateData.question_id = this.props.rowData.id;
                    updateData.answer = selectedAnswerJson;

                    this.props.updateAnswer(updateData);
                }
            }
        });
    };

    handleSelectCountry = (event) => {
        this.setState({
            selectedCountry: event !== null ? event : []
        });
    };

    onInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        const { t } = this.props;

        // let isEnded = false;
        // if (this.props.rowData.status === 2) {
        //     isEnded = true;
        // }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("event_qna_question.qna_question")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                        <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_qna_question.question_details")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("event_qna_question.en")}</label>
                                            <StringInput
                                                id="en"
                                                name="en"
                                                className="form-control"
                                                value={this.props.rowData.en}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("event_qna_question.cn")}</label>
                                            <StringInput
                                                id="cn"
                                                name="cn"
                                                className="form-control"
                                                value={this.props.rowData.cn}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("event_qna_question.ms")}</label>
                                            <StringInput
                                                id="ms"
                                                name="ms"
                                                className="form-control"
                                                value={this.props.rowData.ms}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_qna_question.update_answer")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="question_form" className="form-horizontal" onSubmit={this.updateAnswerHandler} noValidate>
                                    <ValidationError formName="question_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_a_score">{t("event_qna_question.answer")}</label>
                                                <Select2
                                                    id="answer"
                                                    name="answer"
                                                    options={this.props.dropdown.leagueTeam}
                                                    placeholder={t("event_qna_question.answer")}
                                                    className="form-control"
                                                    onChange={this.handleSelectCountry}
                                                    value={this.state.selectedCountry}
                                                    isOptionDisabled={true}
                                                    selectedOptions={this.state.selectedCountry}
                                                    maxSelectOption={this.state.answerLimit}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        update_answer: state.eventQNAQuestion.update_answer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAnswer: (data) => dispatch(updateAnswer(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(QNAQuestionUpdateAnswer));
