import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getBanksAccount = (data) => dispatch => {
    axios.get('bankaccount/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANK_ACCOUNT_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getDropDown = () => (dispatch, getState) =>
{
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get('bankaccount/drop_down', {params: data})
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const updateBankAccountStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bankaccount/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_BANK_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteBankAccount = (data) => dispatch => {
    axios.post('bankaccount/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_BANK_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showBankAccount = (data) => dispatch => {
    axios.get('bankaccount/show', {
        params: {
            "id": data['bank_account_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_BANK_ACCOUNT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBankAccountMaintenanceStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bankaccount/change_maintainance_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_MAINTENANCE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const postBankAccount = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;  
    axios.post('bankaccount/store', data)
        .then( (response) => {
            dispatch ({
                type: type.CREATE_BANK_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getBankNameList = (data) => dispatch => {
    axios.get('bankaccount/getBanksName', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANK_NAME_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getBankAccountNameList = (data) => dispatch => {
    axios.get('bankaccount/getBankAccountName', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANK_ACCOUNT_NAME_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBankAccount = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bankaccount/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_BANK_ACCOUNT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getMemberGroup = (data) => dispatch => {
    axios.get('bankaccount/member_group', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getBanksAccountLedger = (data) => dispatch => {
    axios.get('bankaccount/ledger', {params: data})
    .then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.BANK_ACCOUNT_LEDGER,
            payload: response.data
        })
    }).catch(err => {
        console.warn(err);
    })

};