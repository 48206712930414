import React, { Component } from "react";
import { connect } from "react-redux";
import { storeRobotsTxt } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../../components/ValidationError/ValidationError";

class RobotsTxtCreate extends Component {
    constructor() {
        super();
        this.state = {
            user_agent: "",
            rule: "Allow",
            directory_path: "",
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRuleChange = this.handleRuleChange.bind(this);
    }

    componentDidMount() {
        formValidation("robots_txt_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[RobotsTxtCreate] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.storeRobotsTxtResult !== this.props.storeRobotsTxtResult) {
            if (nextProps.storeRobotsTxtResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeRobotsTxtResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.storeRobotsTxtResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.storeRobotsTxtResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    storeRobotsTxtHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let storeRobotsTxtData = {};
                storeRobotsTxtData.user_agent = this.state.user_agent;
                storeRobotsTxtData.rule = this.state.rule;
                storeRobotsTxtData.directory_path = this.state.directory_path;
                // console.log("Form Submit:", storeRobotsTxtData);
                this.props.storeRobotsTxt(storeRobotsTxtData);
            }
        });
    };

    handleChange = (event) => {
        // console.log(event);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleRuleChange = (event) => {
        this.setState({
            rule: String(event.target.value),
        });
    };

    render() {
        const { t } = this.props;
        let rule = [
            { label: t("robots-txt.allow"), value: "Allow" },
            { label: t("robots-txt.disallow"), value: "Disallow" },
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("robots-txt.add-rule")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="robots_txt_form" className="form-horizontal" onSubmit={this.storeRobotsTxtHandler} noValidate>
                                    <ValidationError formName="robots_txt_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="user_agent">{t("robots-txt.user-agent")}</label>
                                                <StringInput
                                                    id="user_agent"
                                                    name="user_agent"
                                                    className="form-control"
                                                    placeholder={t("robots-txt.user-agent")}
                                                    value={this.state.user_agent}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="rule">{t("robots-txt.rule")}</label>
                                                <Select id="rule" name="rule" options={rule} onChange={this.handleRuleChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="directory_path">{t("robots-txt.directory-path")}</label>
                                                <StringInput
                                                    id="directory_path"
                                                    name="directory_path"
                                                    className="form-control"
                                                    placeholder={t("robots-txt.directory-path")}
                                                    value={this.state.directory_path}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-primary" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        storeRobotsTxtResult: state.seo.robots_txt_store_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeRobotsTxt: (data) => dispatch(storeRobotsTxt(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RobotsTxtCreate));
