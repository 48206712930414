import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberLoginInfo, setMemberShowLocalState, setMemberShowInitialLoading  } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
// import Select from "../../../components/Input/Select/Select";
import Swal from "sweetalert2";
import MemberLoginInfoListing from "./MemberLoginInfoListing";
import MemberShow from "../MemberShow";
import { filterErrorHandler } from "../../../hoc/Shared/utility";
// import Skeleton from "react-loading-skeleton";

const initialInputState = {
    login_time: "",
    device_type: "",
    filter_type: "collided"
};

class MemberLoginInfo extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "login_info");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                currentPage: "login_info",
                loading: true,
                reload: false,
                show_id: null,
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                totalRecords: 0,
                totalConflictRecords: 0,
                searchValue: "",
                filter: initialInputState,
                data: [],
                conflictData: [],
                pageShow: "listing",
                memberShowId: null
            };
            this.props.setMemberShowLocalState(this.state);
        } else {
            this.state = this.props.memberLoginInfoLocalState;
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log("[MemberLoginInfo] componentDidMount()");
        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "login_info");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            const listingArr = this.getFilterArray();
            this.props.getMemberLoginInfo(listingArr);

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.memberLoginInfoData !== undefined) {
                if (this.props.memberLoginInfoData.data && this.props.memberLoginInfoData.data.data 
                    && this.props.memberLoginInfoData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberLoginInfoData.data.data.memberId)) {
                    const listingArr = this.getFilterArray();
                    this.props.getMemberLoginInfo(listingArr);
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                const listingArr = this.getFilterArray();
                this.props.getMemberLoginInfo(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberLoginInfo] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);

        if (nextProps.memberShowId !== undefined && nextProps.memberLoginInfoData !== undefined) {
            if(nextProps.memberLoginInfoData !== this.props.memberLoginInfoData) {
                if (nextProps.memberLoginInfoData.data.responseCode === 200 && nextProps.memberLoginInfoData.data.msgType === "success") {
                    Swal.close();
                    this.setState({
                        show_id: nextProps.memberShowId,
                        data: nextProps.memberLoginInfoData.data.data.loginInfo.row,
                        conflictData: nextProps.memberLoginInfoData.data.data.collidedMember.row,
                        totalRecords: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_record,
                        totalConflictRecords: nextProps.memberLoginInfoData.data.data.collidedMember.pagination.total_record,
                        totalPages: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_page,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        show_id: null,
                        data: [],
                        conflictData: [],
                        conflictFingerPrintData: [],
                        totalRecords: 0,
                        totalConflictRecords: 0,
                        totalPages: 1,
                        loading: false
                    }, () => {
                        filterErrorHandler(nextProps.memberLoginInfoData.data.message);
                    });
                }
            }
        }

        // if (
        //     !nextState.reload &&
        //     nextProps.memberShowId !== undefined &&
        //     nextProps.memberLoginInfoData !== undefined &&
        //     nextProps.memberLoginInfoData !== this.props.memberLoginInfoData
        // ) {
        //     if (nextProps.memberLoginInfoData.status === 200 && nextProps.memberLoginInfoData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberLoginInfoData.data.data.loginInfo.row,
        //             conflictData: nextProps.memberLoginInfoData.data.data.collidedMember.row,
        //             totalRecords: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_record,
        //             totalPages: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberLoginInfoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";

        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // } else if (nextState.reload && nextProps.memberLoginInfoData.data.data.loginInfo.row !== nextState.data && nextProps.memberLoginInfoData !== undefined) {
        //     if (nextProps.memberLoginInfoData.status === 200 && nextProps.memberLoginInfoData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberLoginInfoData.data.data.loginInfo.row,
        //             totalRecords: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_record,
        //             totalPages: nextProps.memberLoginInfoData.data.data.loginInfo.pagination.total_page,
        //             conflictData: nextProps.memberLoginInfoData.data.data.collidedMember.row,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberLoginInfoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";
        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // }

        return true;
    }

    componentWillUnmount() {
        // console.log("[MemberLoginInfo] componentWillUnmount()", "\nstate: ", this.state);
        this.props.setMemberShowLocalState(this.state);
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: ""
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLoginInfo(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                searchValue: "",
                order: "",
                filter: newFilter,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLoginInfo(listingArr);
            }
        );
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        listingArr.member_id = this.props.memberShowId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    };

    showHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            memberShowId: id,
            pageShow: "show",
        });
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLoginInfo(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            // errMsg: [],
            // touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberLoginInfo(listingArr);
        });
    };

    render() {
        // const { t } = this.props;
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MemberLoginInfoListing
                        filter={this.state.filter}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        showHandler={(event, id) => this.showHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        data={this.state.data}
                        conflictData={this.state.conflictData}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        totalConflictRecords={this.state.totalConflictRecords}
                        searchValue={this.state.searchValue}
                        loading={this.state.loading}
                    />
                )}

                {this.state.pageShow === "show" && (
                    <MemberShow memberShowId={this.state.memberShowId} changePageHandler={(page) => this.changePageHandler(page)} returnPage="listing" fromDisplay="edit"/>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberLoginInfoLocalState: member.member_login_info_local_state,

        memberLoginInfoData: member.member_login_info_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),

        getMemberLoginInfo: (data) => dispatch(getMemberLoginInfo(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberLoginInfo));
