import React, { Component } from "react";
import { connect } from "react-redux";
import { getList, updateStatus } from "../../store/ducks/voucher/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import VoucherListing from "./VoucherListing";
import VoucherCreate from "./VoucherCreate";
import VoucherEdit from "./VoucherEdit";
import VoucherMember from "./VoucherMember";
import VoucherShow from "./VoucherShow";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale, faPen, faSearch, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";
import Select from "../../components/Input/Select/Select";
import moment from "moment";
import axios from "../../common/config/axios";
import { filterErrorHandler } from "../../hoc/Shared/utility";

const initialInputState =
{
    /* form input value */
    code: '',
    date: [],
    batchName: ''
};

class Voucher extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            voucherID: null,
            voucherCode: "",
            loading: true,

            permission: [],
            groupData: [],

            filter: initialInputState,
        };
        
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getList(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.data.responseCode === 200 && nextProps.rs.data.msgType === 'success') {
                let groupData = [];
                if (!!(nextProps.rs.data.data.groupData) && nextProps.rs.data.data.groupData !== undefined && Object.values(nextProps.rs.data.data.groupData).length > 0) {
                    Object.values(nextProps.rs.data.data.groupData).map((groupRes) => {
                        let isVoucherGroup = '';
                        let email = '';

                        groupRes.map((groupVal) => {
                            isVoucherGroup = groupVal.is_voucher_group;
                            email = email === '' ? groupVal.email : email+', '+groupVal.email;
                            return null;
                        });

                        groupData.push({isVoucherGroup: isVoucherGroup,email: email});
                        return null;
                    });
                }
            
                this.setState({
                    data: nextProps.rs.data.data.result.row,
                    totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                    totalPages: nextProps.rs.data.data.result.pagination.total_page,
                    groupData: groupData,
                    loading: false,
                });
            }
            else {
                this.setState({
                    data: [],
                    totalRecords: 0,
                    totalPages: 1,
                    groupData: [],
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.rs.data.message);
                })
            }
        }
        
        if (nextProps.updateStatusRs !== this.props.updateStatusRs && nextProps.updateStatusRs !== undefined)
        {
            if(nextProps.updateStatusRs.responseCode === 200 && nextProps.updateStatusRs.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                });
            }
            else
            {
                this.setState({
                    loading: false
                }, () => 
                {
                    filterErrorHandler(nextProps.updateStatusRs.message);
                });

                Swal.close();
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getList(listingArr);
        });
    };

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            voucherID: id,
            pageShow: "edit",
        });
    };

    addMemberHandler = (e, id, code) => {
        e.preventDefault();
        this.setState({
            voucherID: id,
            voucherCode: code,
            pageShow: "voucherMember",
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page, batchNo) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
            currentBatchNo: batchNo
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
    
        this.setState({
            filter: newFilter
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        // console.log(listingArr);
        return listingArr;
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, groupButton, viewButton, output;
        if (this.state.permission.includes("voucher_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white m-1"
                    key={`edit-${row.batch_no}`}
                    idValue={row.batch_no === undefined ? 0 : row.batch_no}
                    clicked={(event) => this.editHandler(event, row.batch_no)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        // if (this.state.permission.includes("voucher_create") && row.qty === 1) {
        if (this.state.permission.includes("voucher_create")) {
            groupButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white m-1"
                    key={`edit-${row.voucherID}`}
                    idValue={row.voucherID === undefined ? 0 : row.voucherID}
                    clicked={(event) => this.addMemberHandler(event, row.voucherID, row.code)}
                    buttonName={<FontAwesomeIcon icon={(row.total_member > 0) ? faMale : faExclamationCircle} />}
                />
            );
        }

         if (this.state.permission.includes("voucher_show")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faSearch}/>}
                    key={`view-${row.batch_no}`}
                    clicked={() => this.changePageHandler('show', row.batch_no)}
                />
            );
        }

        // downloadButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-white m-1"
        //             key={`edit-${row.batch_no}`}
        //             idValue={row.batch_no === undefined ? 0 : row.batch_no}
        //             clicked={(event) => this.exportExcelHandler(event, row.batch_no)}
        //             buttonName={<FontAwesomeIcon icon={faDownload} />}
        //         />
        // );

        output = [viewButton, editButton,groupButton];
        return output;
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("voucher_update")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];

            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="status"
                    name="status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateStatusHandler(event, row.voucherID);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };
    
    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getList(listingArr);
        });
    }

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.fetchData();
            this.props.getList(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            });
        }
    };

    updateStatusHandler = (event,voucherID) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let params = {};
        params.id = voucherID;
        params.status = event.target.value;
        this.props.updateStatus(params);
    }

    exportExcelHandler = (event, id) => {
        event.preventDefault();
        const data = {};
        data.id = id;

        axios.get("voucher/download_voucher", {
            params: data,
            responseType: "blob",
        })
        .then((res) => {
            const currentDate = new Date();
            const timestamp = moment(currentDate).format("YYMMDD HHmmss");
            let fileName = "VoucherCodeList-" + timestamp;
            const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const aElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            aElement.href = href;
            aElement.download = fileName;
            document.body.appendChild(aElement);
            aElement.click();
            document.body.removeChild(aElement);
            window.URL.revokeObjectURL(href);
        })
        .catch((err) => {
            console.log("Excel - VoucherCode Error", err);
        });
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        // console.log('render (agent): ', this.state);`
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('voucher.batch-no'), dataField: "batch_no"},
            {text: t('voucher.code'), dataField: "code"},
            {text: t('voucher.amount'), dataField: "amount"},
            {text: t('voucher.category'), dataField: "category"},
            {text: t('voucher.type'), dataField: "type"},
            {text: t('voucher.shipment_type'), dataField: "shipment_type"},
            {text: t('voucher.max_cap'), dataField: "max_cap"},
            {text: t('voucher.voucher-limit'), dataField: "limit"},
            {text: t('voucher.claim-limit'), dataField: "claim_limit"},
            // {text: t('voucher.min-deposit'), dataField: "min_deposit"},
            {text: t('voucher.total_member'), dataField: "total_member"},
            {text: t('voucher.status'), dataField: "status", formatter: this.statusFormatter},
            {text: t('voucher.start_date'), dataField: "start_date"},
            {text: t('voucher.end_date'), dataField: "end_date"},
            {text: t('voucher.updated_at'), dataField: "updated_at"},
            {text: t('voucher.updated_by'), dataField: "updated_by"},
            {text: t('global.action'), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: '200px' }},
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    voucherID: val.id,
                    code: val.code,
                    // qty: val.voucher_quantity,
                    batch_no: val.batch_no,
                    amount: val.amount,
                    category: t('voucher.'+val.category),
                    type: t('voucher.'+val.type),
                    shipment_type: (val.shipment_type) ? t('voucher.'+val.shipment_type) : '-',
                    max_cap: val.max_cap,
                    limit: (val.limit) ? val.limit : '-',
                    claim_limit: (val.claim_limit) ? val.claim_limit : '-',
                    // min_deposit: val.min_deposit,
                    total_member: val.total_member,
                    status: val.status,
                    start_date: !!(val.start_date) ? val.start_date : '-',
                    end_date: !!(val.end_date) ? val.end_date : '-',
                    updated_at: val.updated_at,
                    updated_by: val.updated_by
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <VoucherListing
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        filter={this.state.filter}
                    />
                )}

                {this.state.permission.includes("voucher_create") && this.state.pageShow === "create" && (
                    <VoucherCreate
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("voucher_update") && this.state.pageShow === "edit" && (
                    <VoucherEdit
                        voucherID={this.state.voucherID}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("voucher_create") && this.state.pageShow === "voucherMember" && (
                    <VoucherMember
                        popupLoading={this.state.popupLoading}
                        voucherID={this.state.voucherID}
                        voucherCode={this.state.voucherCode}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.pageShow === "show" && 
                    <VoucherShow 
                        show_voucher_id={this.state.currentBatchNo} 
                        changePageHandler={(page) => this.changePageHandler(page)} 
                />}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.voucher.rs,
        updateStatusRs: state.voucher.updateStatus,
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getList: (data) => dispatch(getList(data)),
        updateStatus: (data) => dispatch(updateStatus(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Voucher));
