import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageSeo from "../../../assets/images/seo/SEO.png";
import StaticSEO from './SEO/StaticSEO';
import DynamicSEO from './SEO/DynamicSEO';
import { withNamespaces } from "react-i18next";


// *****************************************
// *********** Demo React Helmet ***********
// *****************************************

class ReactHelmet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    pageSEO = () =>
    {
        const { t } = this.props;
        const data = {};
        data.title = t("seo-demo.seo-title");
        data.description = t("seo-demo.seo-description");
        data.keywords = t("seo-demo.seo-keywords");
        data.robots = "follow, index";
        return data;
    }

    render() {
        return (
            // The developer should import SEO component and a function which use pageSEO function to store the translated SEO by developers.
            <>
                <StaticSEO />
                <DynamicSEO pageSEO={this.pageSEO()} />

                <div className="main-error-wrapper page page-h ">
                    <h1>Demo SEO</h1>
                    <img src={ImageSeo} className="error-page" alt="error" />
                    <h2>Go to Inspect and check the head element</h2>
                    <Link className="btn btn-outline-primary" to={{ pathname: "/seo_management" }}>
                        Back to SEO Listing
                    </Link>
                </div>
            </>
        );
    }
}

export default (withNamespaces("translation")(ReactHelmet));
