import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const dailyMissionSettingReducer = createReducer(initialState)({
    [types.DAILY_MISSION_SETTING_LIST]: (state, action) => ({
        ...state,
        daily_mission_setting: action.payload
    }),

    [types.DAILY_MISSION_SETTING_CREATE]: (state, action) => ({
        ...state,
        daily_mission_setting_create: action.payload
    }),

    [types.DAILY_MISSION_SETTING_SHOW]: (state, action) => ({
        ...state,
        daily_mission_setting_show: action.payload
    }),

    [types.DAILY_MISSION_SETTING_UPDATE]: (state, action) => ({
        ...state,
        daily_mission_setting_update: action.payload
    }),

    [types.DAILY_MISSION_SETTING_STATUS]: (state, action) => ({
        ...state,
        daily_mission_setting_status: action.payload
    }),

    [types.DAILY_MISSION_LIST]: (state, action) => ({
        ...state,
        daily_mission: action.payload
    })
});

export default dailyMissionSettingReducer;