import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showBanner } from "../../store/ducks/banners/actions";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import Lightbox from 'react-image-lightbox';

//Initialize the language array first
let langArr = [];

class BannersShow extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        // desktop_image_name: [],
        // mobile_image_name: [],
        desktop_image_link: '',
        redirect_url: [],
        start_time: '',
        end_time: '',
        sequence: '',
        status: '',
        category: '',
        category_1: '',
        loading: true
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = this.props.currentUser.country_id;

        const showBannerData = {};
        showBannerData.banner_id = this.props.show_banner_id;
        this.props.showBanner(showBannerData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_banner_data !== this.props.show_banner_data) {
            if (nextProps.show_banner_data.data.responseCode === 200) {
                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    // desktop_image_name,
                    // mobile_image_name,
                    // desktop_image_link,
                    // mobile_image_link,
                }
                    = this.state;

                nextProps.show_banner_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    // desktop_image_name[item.lang_id] = item.desktop_image_name;
                    // mobile_image_name[item.lang_id] = item.mobile_image_name;
                    // if(item.desktop_image_link === null)
                    // {
                    //     desktop_image_link[item.lang_id] = (item.desktop_image_link);
                    // }
                    // else
                    // {
                    //     desktop_image_link[item.lang_id] = (item.desktop_image_link).replace('promos/','');
                    // }
                    // mobile_image_link[item.lang_id] = item.mobile_image_link;

                    return item;
                })
                let image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link;

                if (image_link_desktop_image === null) {
                    image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link;
                }
                else {
                    image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link.replace('promos/', '');
                }
                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    // desktop_image_name,
                    // mobile_image_name,
                    image_link_desktop_image,
                    // mobile_image_link,
                    category: nextProps.show_banner_data.data.data[0].category,
                    category_1: nextProps.show_banner_data.data.data[0].category_1,
                    start_time: nextProps.show_banner_data.data.data[0].start_time,
                    end_time: nextProps.show_banner_data.data.data[0].end_time,
                    sequence: nextProps.show_banner_data.data.data[0].sequence,
                    status: nextProps.show_banner_data.data.data[0].status,
                    redirect_url: nextProps.show_banner_data.data.data[0].redirect_url
                })
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({ loading: false });
            //Clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    'langId': data[item].languageId,
                    'langCode': data[item].languageCode,
                    'langName': data[item].languageName
                });
            })
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;


        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t("banner.desktop-image")}</label>

                        <div className="imgPreview banner-preview-image">
                            <img
                                alt=""
                                className="show-banner-size"
                                src={this.state.desktop_image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.desktop_image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t("banner.mobile-image")}</label>

                        <div className="imgPreview banner-preview-image">
                            <img
                                alt=""
                                className="show-banner-size"
                                src={this.state.mobile_image_preview[item.langId]}
                            />
                        </div>

                    </div>
                </div>

                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.desktop-image-name")}</label>
                        <p><strong>{this.state.desktop_image_name[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.mobile-image-name")}</label>
                        <p><strong>{this.state.mobile_image_name[item.langId]}</strong></p>
                    </div>
                </div> */}



                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.mobile-image-link")}</label>
                        <p><strong>{this.state.mobile_image_link[item.langId]}</strong></p>
                    </div>
                </div> */}


            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="bg-white border" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("banner.banner")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("banner.show-banner")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr className="show-banner-white">
                                                <th>{t("banner.banner-details")}</th>
                                                <td field-key='banner_details'>
                                                    {tabMessage}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>{t("banner.desktop-image-link")}</th>
                                                <td field-key='image_link_desktop_image'>{this.state.image_link_desktop_image}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.redirect-url")}</th>
                                                <td field-key='redirect_url'>{this.state.redirect_url}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.category")}</th>
                                                <td field-key='category_1'>{t(`game-type.${this.state.category}`)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.start-time")}</th>
                                                <td field-key='start_time'>{this.state.start_time}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.end-time")}</th>
                                                <td field-key='end_time'>{this.state.end_time}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.status")}</th>
                                                <td field-key='status'>
                                                    {this.state.status === 1 ?
                                                        t('global.active') :
                                                        t('global.inactive')
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("banner.sequence")}</th>
                                                <td field-key='sequence'>{this.state.sequence}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const { home, banner } = state;
    return {
        country_data: home.country_data,
        show_banner_data: banner.show_banner_data,
        currentUser: state.currentUser.data
    }
};

const mapDispatchToProps = {
    showBanner,
    getCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BannersShow));