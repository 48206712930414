import axios from "../../../common/config/axios";
import * as type from "./types";

export const getSystemSettings = (data) => (dispatch, getState) => {
    axios
        .get("system_settings/list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
                country_id: getState().currentUser.data.country_id
            },
        })
        .then((res) => {
            dispatch({
                type: type.SYSTEM_SETTING_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("System Setting Error", err);
            dispatch({
                type: type.SYSTEM_SETTING_LIST,
                payload: [],
            });
        });
};

export const storeSystemSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("system_settings/store", data)
        .then((res) => {
            dispatch({
                type: type.SYSTEM_SETTING_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store System Setting Error\n", err);
        });
};

export const editSystemSetting = (id) => (dispatch) => {
    axios
        .get("system_settings/edit", {
            params: {
                id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.SYSTEM_SETTING_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit System Setting Error", err);
            dispatch({
                type: type.SYSTEM_SETTING_EDIT,
                payload: err.data,
            });
        });
};

export const updateSystemSetting = (data) => (dispatch) => {
    axios
        .post("system_settings/update", data)
        .then((res) => {
            dispatch({
                type: type.SYSTEM_SETTING_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update System Setting Error", err);
        });
};

export const deleteSystemSetting = (data) => (dispatch) => {
    axios
        .post("system_settings/delete", data)
        .then((res) => {
            dispatch({
                type: type.SYSTEM_SETTING_DELETE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Delete System Setting Error", err);
        });
};

export const updateStatus = (data) => dispatch =>
{
    axios.post('system_settings/update_status', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: type.SYSTEM_SETTING_UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};