import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { showMemberGroup, updateMemberGroup } from "../../store/ducks/memberGroup/actions";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class MemberGroupEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            group_name: "",
            privilege: 0,
            level: 0,
            min_payment: 0,
            max_payment: 0,
            next_level_amount: 0,
            // max_withdrawal: 0,
            monthly_bonus: 0,
            up_level_rebate_percentage: 0,
            created_by: "",
            status: "",
            loading: true,
            touched: false,

            tabId: "requirements",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        formValidation("edit_member_group_form");

        const showMemberGroupData = {};
        showMemberGroupData.member_group_id = this.props.show_member_group_id;
        showMemberGroupData.member_group_setting_id = this.props.show_member_group_setting_id;
        this.props.showMemberGroup(showMemberGroupData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_member_group_data !== this.props.show_member_group_data) {
            if (nextProps.show_member_group_data.data.responseCode === 200 && nextProps.show_member_group_data.data.msgType === 'success') {
                let memberGroupData = nextProps.show_member_group_data.data.data;
                let memberGroupSettingData = nextProps.show_member_group_data.data.data.setting;

                this.setState({
                    loading: false,
                    group_name: memberGroupData.memberGroupName,
                    privilege: memberGroupData.memberGroupPrivilege,
                    level: memberGroupData.memberGroupLevel,
                    min_payment: memberGroupSettingData !== null ? memberGroupSettingData.minPayment : 0,
                    max_payment: memberGroupSettingData !== null ? memberGroupSettingData.maxPayment : 0,
                    next_level_amount: memberGroupSettingData !== null ? memberGroupSettingData.nextLevelAmount: 0,
                    up_level_rebate_percentage: memberGroupSettingData !== null ? memberGroupSettingData.upLevelRebatePercentage : 0,
                    created_by: memberGroupSettingData !== null ? memberGroupSettingData.createdBy : '',
                    status: memberGroupSettingData !== null ? memberGroupSettingData.status : ''
                });
            } else {
                this.setState({
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.show_member_group_data.data.message);
                })
            }
        }

        if (nextProps.update_member_group_result !== this.props.update_member_group_result) {
            if (nextProps.update_member_group_result.responseCode === 200 && nextProps.update_member_group_result.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_member_group_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_member_group_result.message,
                });
                Swal.close();
            } 
        }

        return true;
    }

    updateMemberGroupHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.id = this.props.show_member_group_id;
                data.setting_id = this.props.show_member_group_setting_id;
                data.group_name = this.state.group_name;
                data.privilege = this.state.privilege;
                data.level = this.state.level;
                data.status = this.state.status;

                data.min_payment = this.state.min_payment;
                data.max_payment = this.state.max_payment;

                // data.min_withdrawal = this.state.min_withdrawal;
                // data.max_daily_withdrawal = this.state.max_daily_withdrawal;
                // data.daily_withdrawal_counts = this.state.daily_withdrawal_counts;
                // // data.max_withdrawal = this.state.max_withdrawal;
                // data.daily_withdrawal_limitation = this.state.daily_withdrawal_limitation;

                // data.min_turnover = this.state.min_turnover;
                // data.max_turnover = this.state.max_turnover;

                // data.up_level_bonus = this.state.up_level_bonus;
                data.up_level_rebate_percentage = this.state.up_level_rebate_percentage;
                // data.monthly_bonus = this.state.monthly_bonus;
                // data.birthday_bonus = this.state.birthday_bonus;
                // data.birthday_bonus_turnover_multiply = this.state.birthday_bonus_turnover_multiply;
                // data.reward_point = this.state.reward_point;
                // data.deposit_amount_per_point = this.state.deposit_amount_per_point;
                // data.designed_account_manager = this.state.designed_account_manager;
                // data.special_birthday_gift = this.state.special_birthday_gift;
                // data.turnover_per_point = this.state.turnover_per_point;

                // data.next_level_amount = this.state.next_level_amount;
                // data.maintain_level_amount = this.state.maintain_level_amount;

                data.country_id = this.props.currentCountryId;
                this.props.updateMemberGroup(data);
            }
        });
    };

    handleChange = (event) => {
        let name = event.target.name;
        switch (name) {
            case "privilege":
            case "level":
            case "min_payment":
            case "max_payment":
            case "up_level_rebate_percentage":
            // case "min_withdrawal":
            // case "max_daily_withdrawal":
            // case "daily_withdrawal_counts":
            // case "up_level_bonus":
            // case "birthday_bonus":
            // case "birthday_bonus_turnover_multiply":
                if (event.target.value.match(/^\d+(\.\d+)?$/)) {
                    if (event.target.value.charAt(0) === "0" || event.target.value.charAt(0) === ".") {
                        if (event.target.value.charAt(1) === ".") {
                            this.setState({ [event.target.name]: event.target.value });
                        } else if (event.target.value.charAt(0) === "0") {
                            if (event.target.value.substring(1) !== "") {
                                this.setState({ [event.target.name]: event.target.value.substring(1) });
                            } else {
                                this.setState({ [event.target.name]: 0.00 });
                            }
                        } else {
                            this.setState({ [event.target.name]: event.target.value });
                        }
                    } else {
                        this.setState({ [event.target.name]: event.target.value });
                    }
                } else {
                    this.setState({ [event.target.name]: 0.00 });
                }
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
        }
    };

    changeTabHandler = (tab) => {
        this.setState({
            tabId: tab,
        })
    }

    render() {
        const { t } = this.props;

        // let statusArry = [
        //     { label: t("global.active"), value: 1, selected: true },
        //     { label: t("global.inactive"), value: 0 },
        // ];

        let maintainPeriodArry = [
            { label: t("member-group.lifetime"), value: 0, selected: true },
            { label: t("member-group.3-months"), value: 3 },
            { label: t("member-group.private-invitation"), value: 999 },
        ];

        // let designedAccountManagerArry = [
        //     {
        //         label: t("global._no"),
        //         value: 0,
        //     },
        //     {
        //         label: t("global._yes"),
        //         value: 1,
        //     },
        // ];

        // let specialBirthdayGiftArry = [
        //     {
        //         label: t("global._no"),
        //         value: 0,
        //     },
        //     {
        //         label: t("global._yes"),
        //         value: 1,
        //     },
        // ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-primary tabs-style-2">
                        <div className="tab-menu-heading">
                            <div className="tabs-menu1">
                                <ul className="nav panel-tabs main-nav-line">
                                    {/* <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("edit_member_group")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "edit_member_group" ? "active" : ""}`}>
                                            {t("member-group.edit-member-group")}
                                        </button>
                                    </li> */}
                                    <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("requirements")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "requirements" ? "active" : ""}`}>
                                            {t("member-group.requirement")}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("bonus_rebate")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "bonus_rebate" ? "active" : ""}`}>
                                            {t("member-group.bonus")}
                                        </button>
                                    </li>
                                    {/* <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("requirements")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "requirements" ? "active" : ""}`}>
                                            {t("member-group.requirement")}
                                        </button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <form
                            method="post"
                            id="edit_member_group_form"
                            className="form-horizontal"
                            onSubmit={this.updateMemberGroupHandler} noValidate>
                            <ValidationError formName="edit_bank_form" touched={this.state.touched} message={this.state.error_message}
                            />
                            <div className="row">
                                <div className="card card-body col-md-12">
                                    {
                                        this.state.loading === true ? (
                                            <Skeleton count={4} />
                                        ) : (
                                            <>
                                                {
                                                    // this.state.tabId === "edit_member_group" ? (
                                                    //     <Accordion allowZeroExpanded="true" className="global_accordion">
                                                    //         <AccordionItem>
                                                    //             <AccordionItemHeading>
                                                    //                 <AccordionItemButton>
                                                    //                     {t("member-group.edit-member-group")}
                                                    //                 </AccordionItemButton>
                                                    //             </AccordionItemHeading>
                                                    //             <AccordionItemPanel>
                                                    //                 <div className="form-row">
                                                    //                     <div className="form-group col-12">
                                                    //                         <div className="row">
                                                    //                             <label className="col-6" htmlFor="group_name">{t("member-group.names")}</label>
                                                    //                             <StringInput
                                                    //                                 onChange={this.handleChange}
                                                    //                                 id="group_name"
                                                    //                                 name="group_name"
                                                    //                                 className="form-control col-6"
                                                    //                                 placeholder={t("member-group.names")}
                                                    //                                 value={this.state.group_name}
                                                    //                                 required
                                                    //                             />
                                                    //                         </div>
                                                    //                     </div>
                                                    //                     <div className="form-group col-12">
                                                    //                         <div className="row">
                                                    //                             <label className="col-6" htmlFor="level">{t("member-group.lv")}</label>
                                                    //                             <NumberInput 
                                                    //                                 className="col-6"
                                                    //                                 onChange={this.handleChange}
                                                    //                                 name="level"
                                                    //                                 id="level"
                                                    //                                 placeholder={t("member-group.lv")}
                                                    //                                 value={this.state.level}
                                                    //                                 required
                                                    //                                 min={1}
                                                    //                             />
                                                    //                         </div>
                                                    //                     </div>
                                                    //                     <div className="form-group col-12">
                                                    //                         <div className="row">
                                                    //                             <label className="col-6" htmlFor="privilege">{t("member-group.privilege")}</label>
                                                    //                             <NumberInput
                                                    //                                 className="col-6"
                                                    //                                 onChange={this.handleChange}
                                                    //                                 name="privilege"
                                                    //                                 id="privilege"
                                                    //                                 placeholder={t("member-group.privilege")}
                                                    //                                 value={this.state.privilege}
                                                    //                                 required
                                                    //                                 min={0}
                                                    //                             />
                                                    //                         </div>
                                                    //                     </div>
                                                    //                     <div className="form-group col-12">
                                                    //                         <div className="row">
                                                    //                             <label className="col-6">{t("global.status")}</label>
                                                    //                             <Select
                                                    //                                 id="status"
                                                    //                                 name="status"
                                                    //                                 containerClass="col-6 p-0"
                                                    //                                 className="form-control"
                                                    //                                 options={statusArry}
                                                    //                                 onChange={this.handleChange}
                                                    //                                 value={this.state.status}
                                                    //                             />
                                                    //                         </div>
                                                    //                     </div>
                                                    //                 </div>
                                                    //             </AccordionItemPanel>
                                                    //         </AccordionItem>
                                                    //     </Accordion>
                                                    // ) : 
                                                    this.state.tabId === "requirements" ? (
                                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {t("member-group.upgrade-tier-requirement")}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="form-row align-items-center">
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="min_payment">{t("member-group.min-shipment-payment")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="min_payment"
                                                                                    id="min_payment"
                                                                                    placeholder={t("member-group.min-shipment-payment")}
                                                                                    value={this.state.min_payment}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        {/* <span className="d-none d-md-block p-2 mt-3"> - </span> */}
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="max_payment">{t("member-group.max-shipment-payment")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="max_payment"
                                                                                    id="max_payment"
                                                                                    placeholder={t("member-group.max-shipment-payment")}
                                                                                    value={this.state.max_payment}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    ) : this.state.tabId === "bonus_rebate" ? (
                                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {t("member-group.bonus")}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="up_level_rebate_percentage">{t("member-group.up-level-rebate-percentage")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="up_level_rebate_percentage"
                                                                                    id="up_level_rebate_percentage"
                                                                                    placeholder={t("member-group.up-level-rebate-percentage")}
                                                                                    value={this.state.up_level_rebate_percentage}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        {/* <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="max-withdrawal">{t("member-group.birthday-bonus")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="birthday_bonus"
                                                                                    id="birthday_bonus"
                                                                                    placeholder={t("member-group.birthday-bonus")}
                                                                                    value={this.state.birthday_bonus}
                                                                                />
                                                                            </div>

                                                                        </div> */}

                                                                        {/* <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="max-withdrawal">{t("member-group.birthday-bonus-turnover-multiply")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="birthday_bonus_turnover_multiply"
                                                                                    id="birthday_bonus_turnover_multiply"
                                                                                    placeholder={t("member-group.birthday-bonus-turnover-multiply")}
                                                                                    value={this.state.birthday_bonus_turnover_multiply}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="weekly_bonus">{t("member-group.weekly-bonus")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="weekly_bonus"
                                                                                    id="weekly_bonus"
                                                                                    placeholder={t("member-group.weekly-bonus")}
                                                                                    value={this.state.weekly_bonus}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div> */}
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    ) : this.state.tabId === "requirements" ? (
                                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {t("member-group.upgrade-tier-requirement")}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="form-row align-items-center">
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="min_deposit_tier_upgrade">{t("member-group.min-deposit")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="min_deposit_tier_upgrade"
                                                                                    id="min_deposit_tier_upgrade"
                                                                                    placeholder={t("member-group.min-deposit")}
                                                                                    value={this.state.min_deposit_tier_upgrade}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="min_turnover_tier_upgrade">{t("member-group.min-turnover")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="min_turnover_tier_upgrade"
                                                                                    id="min_turnover_tier_upgrade"
                                                                                    placeholder={t("member-group.min-turnover")}
                                                                                    value={this.state.min_turnover_tier_upgrade}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        <h4 className="card-title mb-3">{t("member-group.maintain-requirement")}</h4>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="maintain_period">{t("member-group.maint-period")}</label>
                                                                                <Select
                                                                                    id="maintain_period"
                                                                                    name="maintain_period"
                                                                                    containerClass="col-6 p-0"
                                                                                    className="form-control"
                                                                                    options={maintainPeriodArry}
                                                                                    onChange={this.handleChange}
                                                                                    value={this.state.maintain_period}
                                                                                />
                                                                            </div>

                                                                            {/* <NumberInput
                                                            onChange={this.handleChange}
                                                            name="maintain_period"
                                                            id="maintain_period"
                                                            placeholder={t("member-group.maint-period")}
                                                            value={this.state.maintain_period}
                                                            min={0}
                                                        />
                                                        <ul>
                                                            <li>
                                                                <p>
                                                                    <strong>0 = lifetime</strong>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <strong>1 = 1 Months</strong>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <strong>3 = 3 Months</strong>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <strong>6 = 6 Months</strong>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <strong>9 = 9 Months</strong>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <strong>12 = Private Invitation</strong>
                                                                </p>
                                                            </li>
                                                        </ul> */}
                                                                        </div>

                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="deposit_tier_maintain">{t("member-group.deposit-tier-maintain")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="deposit_tier_maintain"
                                                                                    id="deposit_tier_maintain"
                                                                                    placeholder={t("member-group.deposit-tier-maintain")}
                                                                                    value={this.state.deposit_tier_maintain}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="turnover_tier_maintain">{t("member-group.turnover-tier-maintain")}
                                                                                </label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="turnover_tier_maintain"
                                                                                    id="turnover_tier_maintain"
                                                                                    placeholder={t("member-group.turnover-tier-maintain")}
                                                                                    value={this.state.turnover_tier_maintain}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {t("member-group.weekly-maintain-requirement")}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-12">
                                                                            <div className="row">
                                                                                <label className="col-6" htmlFor="turnover_weekly_target">{t("member-group.turnover-weekly-target")}</label>
                                                                                <NumberInput
                                                                                    className="col-6"
                                                                                    onChange={this.handleChange}
                                                                                    name="turnover_weekly_target"
                                                                                    id="turnover_weekly_target"
                                                                                    placeholder={t("member-group.turnover-weekly-target")}
                                                                                    value={this.state.turnover_weekly_target}
                                                                                    min={0}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        {/* <div className="form-group col-md-4">
                                                        <label htmlFor="turnover_weekly_multiplier">{t("member-group.turnover-weekly-multiplier")}</label>
                                                        <NumberInput 
                                                            onChange={this.handleChange}
                                                            name="turnover_weekly_multiplier"
                                                            id="turnover_weekly_multiplier"
                                                            placeholder={t("member-group.turnover-weekly-multiplier")}
                                                            value={this.state.turnover_weekly_multiplier}
                                                            min={0}
                                                        />
                                                    </div> */}
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="min-turnover">{t("member-group.min-turnover")}</label>

                                                        <NumberInput
                                                            onChange={this.getMinTurnoverHandler}
                                                            name="min-turnover"
                                                            id="min-turnover"
                                                            placeholder={t("member-group.min-turnover")}
                                                            value={this.state.min_turnover}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="max-turnover">{t("member-group.max-turnover")}</label>

                                                        <NumberInput
                                                            onChange={this.getMaxTurnoverHandler}
                                                            name="max-turnover"
                                                            id="max-turnover"
                                                            placeholder={t("member-group.max-turnover")}
                                                            value={this.state.max_turnover}
                                                        />
                                                    </div>
                                                </div> */}

                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="next-level-amount">{t("member-group.next-level-amount")}</label>

                                                        <NumberInput
                                                            onChange={this.getNextLevelAmountHandler}
                                                            name="next-level-amount"
                                                            id="next-level-amount"
                                                            placeholder={t("member-group.next-level-amount")}
                                                            value={this.state.next_level_amount}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="maintain-level-amount">{t("member-group.maint-level-amount")}</label>

                                                        <NumberInput
                                                            onChange={this.getMaintainLevelAmountHandler}
                                                            name="maintain-level-amount"
                                                            id="maintain-level-amount"
                                                            placeholder={t("member-group.maint-level-amount")}
                                                            value={this.state.maintain_level_amount}
                                                        />
                                                    </div>
                                                </div> */}

                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-3">
                                                        <label htmlFor="vip-monthly-bonus">{t("member-group.vip-monthly-bonus")}</label>

                                                        <NumberInput
                                                            onChange={this.getMonthlyBonusHandler}
                                                            name="monthly-bonus"
                                                            id="monthly-bonus"
                                                            placeholder={t("member-group.monthly-bonus")}
                                                            value={this.state.monthly_bonus}
                                                        />
                                                    </div>
                                                   


                                                </div> */}
                                    {/*
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="reward-point">{t("member-group.reward-point")}</label>

                                                        <NumberInput
                                                            onChange={this.getRewardPointHandler}
                                                            name="reward-point"
                                                            id="reward-point"
                                                            placeholder={t("member-group.reward-point")}
                                                            value={this.state.reward_point}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="deposit-amount-per-point">{t("member-group.deposit-amount-point")}</label>

                                                        <NumberInput
                                                            onChange={this.getDepositAmountPerPointHandler}
                                                            name="deposit-amount-per-point"
                                                            id="deposit-amount-per-point"
                                                            placeholder={t("member-group.deposit-amount-point")}
                                                            value={this.state.deposit_amount_per_point}
                                                        />
                                                    </div>


                                                </div> */}

                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="reward-point">{t("member-group.designed-account-manager")}</label>

                                                        <Select
                                                            options={designedAccountManagerArry}
                                                            className="form-control"
                                                            id="designed-account-manager"
                                                            name="designed-account-manager"
                                                            onChange={this.getDesignedAccountManagerHandler}
                                                            value={this.state.designed_account_manager}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="max-daily-withdrawal">{t("member-group.special-birthday-gift")}</label>

                                                        <Select
                                                            options={specialBirthdayGiftArry}
                                                            className="form-control"
                                                            id="special-birthday-gift"
                                                            name="special-birthday-gift"
                                                            onChange={this.getSpecialBirthdayGift}
                                                            value={this.state.special_birthday_gift}
                                                        />
                                                    </div>



                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="turnover-per-point">{t("member-group.turnover-per-point")}</label>

                                                        <NumberInput
                                                            onChange={this.getTurnoverPerPoint}
                                                            name="turnover-per-point"
                                                            id="turnover-per-point"
                                                            placeholder={t("member-group.turnover-per-point")}
                                                            value={this.state.turnover_per_point}
                                                        />
                                                    </div>
                                                </div> */}

                                </div>
                                <div className="form-group mx-auto">
                                    <div>
                                        <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { memberGroup, currentUser } = state;

    //Getting the state from the reducers
    return {
        show_member_group_data: memberGroup.show_member_group_data,
        update_member_group_result: memberGroup.update_member_group_result,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username,
        currentGroupId: currentUser.data.group_id,
    };
};

const mapDispatchToProps = {
    showMemberGroup,
    updateMemberGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberGroupEdit));
