import axios from "../../../common/config/axios";
import * as type from "./types";

export const getStakingSettings = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id
    axios
        .get("staking_settings/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.STAKING_SETTINGS_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Staking Settings Error", err);
            dispatch({
                type: type.STAKING_SETTINGS_LIST,
                payload: [],
            });
        });
};

export const editStakingSettings = (id) => (dispatch) => {
    axios
        .get("staking_settings/edit", {
            params: {
                id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.STAKING_SETTINGS_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Staking Settings Error" + err);
            dispatch({
                type: type.STAKING_SETTINGS_EDIT,
                payload: err.response,
            });
        });
};

export const updateStakingSettings = (data) => (dispatch) => {
    axios
        .post("staking_settings/update", data)
        .then((res) => {
            dispatch({
                type: type.STAKING_SETTINGS_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Staking Settings Error", err);
        });
};