import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
// import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {showBankAccount} from "../../store/ducks/banksAccount/actions";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler } from '../../hoc/Shared/utility';

class BanksAccountShow extends Component {
    state = {
        bank_name: '',
        bank_account_name: '',
        bank_account_no: '',
        bank_code: '',
        min_deposit: 0,
        max_deposit: 0,
        bank_acc_fees: 0,
        maintenance_start_time: '',
        maintenance_end_time: '',
        sequence: 0,
        maintenance_status: 0,
        bank_account_purpose: '',
        bank_threshold: 0,
        deposit_threshold: 0,
        no_of_deposit_threshold: 0,
        next_active_bank_acc: '',
        status: 0,
        loading: true,
        group_name: ''

    }

    componentDidMount() {
        const showBankAccountData = {};
        showBankAccountData.bank_account_id = this.props.show_bank_account_id;

        this.props.showBankAccount(showBankAccountData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_bank_account_data !== this.props.show_bank_account_data) {
            if (nextProps.show_bank_account_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    bank_name: nextProps.show_bank_account_data.data.data[0].bankName,
                    bank_account_name: nextProps.show_bank_account_data.data.data[0].bankAccName,
                    bank_account_no: nextProps.show_bank_account_data.data.data[0].bankAccNumber,
                    bank_code: nextProps.show_bank_account_data.data.data[0].bankCode,
                    min_deposit: nextProps.show_bank_account_data.data.data[0].minDeposit,
                    max_deposit: nextProps.show_bank_account_data.data.data[0].maxDeposit,
                    bank_acc_fees: nextProps.show_bank_account_data.data.data[0].bankAccFees,
                    maintenance_start_time: nextProps.show_bank_account_data.data.data[0].maintainanceStartTime,
                    maintenance_end_time: nextProps.show_bank_account_data.data.data[0].maintainanceEndTime,
                    sequence: nextProps.show_bank_account_data.data.data[0].sequence,
                    maintenance_status: nextProps.show_bank_account_data.data.data[0].maintainanceStatus,
                    bank_account_purpose: nextProps.show_bank_account_data.data.data[0].bankAccountPurpose,
                    bank_threshold: nextProps.show_bank_account_data.data.data[0].thresholdStatus,
                    deposit_threshold: nextProps.show_bank_account_data.data.data[0].depositThreshold,
                    no_of_deposit_threshold: nextProps.show_bank_account_data.data.data[0].no_of_deposit_threshold,
                    next_active_bank_acc: nextProps.show_bank_account_data.data.data[0].active_bank_acc,
                    status: nextProps.show_bank_account_data.data.data[0].status,
                    group_name: nextProps.show_bank_account_data.data.data[0].memberGroupName
                })
            } else{
                this.setState({loading: true}, () => {
                    filterErrorHandler(nextProps.show_bank_account_data.data.message);
                });
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('bank-account.banks-account')}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('bank-account.show_bank_account')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <th>{t('bank-account.banks')}</th>
                                            <td field-key='bank_name'>{this.state.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.banks_account_name')}</th>
                                            <td field-key='bank_account_name'>{this.state.bank_account_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.banks_account_no')}</th>
                                            <td field-key='bank_account_no'>{this.state.bank_account_no}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.bank_code')}</th>
                                            <td field-key='bank_code'>{this.state.bank_code}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.mins_deposit')}</th>
                                            <td field-key='min_deposit'>{this.state.min_deposit}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.maxs_deposit')}</th>
                                            <td field-key='max_deposit'>{this.state.max_deposit}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.bank_acc_fees')}</th>
                                            <td field-key='bank_acc_fees'>{this.state.bank_acc_fees}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.maintenance_start_time')}</th>
                                            <td field-key='maintenance_start_time'>{this.state.maintenance_start_time}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.maintenance_end_time')}</th>
                                            <td field-key='maintenance_end_time'>{this.state.maintenance_end_time}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.sequence')}</th>
                                            <td field-key='sequence'>{this.state.sequence}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.maintenance_status')}</th>
                                            <td field-key='maitenance_status'>{this.state.maintenance_status === 1 ? "YES" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.bank_account_purpose')}</th>
                                            <td field-key='bank_account_purpose'>{this.state.bank_account_purpose}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.bank_threshold')}</th>
                                            <td field-key='bank_threshold'>{this.state.bank_threshold === 1 ? "YES" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.deposit_threshold')}</th>
                                            <td field-key='deposit_threshold'>{this.state.deposit_threshold}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.no_of_deposit_threshold')}</th>
                                            <td field-key='no_of_deposit_threshold'>{this.state.no_of_deposit_threshold}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.next_active_bank_account')}</th>
                                            <td field-key='next_active_bank_account'>{this.state.next_active_bank_acc}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('bank-account.member_group')}</th>
                                            <td field-key='member_group'>{this.state.group_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('global.status')}</th>
                                            <td field-key='status'>{this.state.status === 1 ? "ACTIVE" : "INACTIVE"}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const {bankAccount} = state;

    return {
        show_bank_account_data: bankAccount.show_bank_account_data
    }
};

const mapDispatchToProps = {
    showBankAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BanksAccountShow));