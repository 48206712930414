import React, { Component } from "react";

import PageHeader from "../../components/PageHeader/PageHeader";
import PageSidebar from "../../components/PageSidebar/PageSidebar";
import PageFooter from "../../components/PageFooter/PageFooter";
import PageContent from "../../components/PageContent/PageContent";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import UserProvider from "../../context/User/UserProvider";

class Layout extends Component {

    constructor(props)
    {
        super(props);
        this.state =
        {
            sideBar:false,
        }
    }

    toggleSideBar = () => {
        this.setState({
            sideBar: !this.state.sideBar
        })
    }

    getFullScreenElement = () => {
        return document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullscreenElement ||
                document.mozFullscreenElement;
    }

    toggleFullScreen = () => {
        if(this.getFullScreenElement()){
            document.exitFullscreen();
        }else {
            document.documentElement.requestFullscreen();
        }
    }

    render() {
        return (
            <UserProvider>
                <Auxiliary>
                    <div className="master-container">
                        <PageSidebar sideBar={this.state.sideBar} toggleSideBar={this.toggleSideBar}/>
                        <div className="main-content app-content">
                            <div style={{ paddingTop: "63px" }}>
                                <PageHeader toggleSideBar={this.toggleSideBar} toggleFullScreen = {this.toggleFullScreen}/>
                            </div>
                            <PageContent />
                        </div>
                        <PageFooter />
                    </div>
                </Auxiliary>
            </UserProvider>
        );
    }
}

export default Layout;
