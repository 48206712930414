import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import TimeField from 'react-simple-timefield';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { postPaymentGateway } from "../../store/ducks/paymentGateway/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import { withNamespaces } from "react-i18next";
class PaymentGatewayCreate extends Component {
    state = {
        payment_gateway_name: "",
        payment_gateway_slug: "",
        sequence: "",
        currentDepositStatus: 1,
        currentWithdrawalStatus: 1,
        deposit_est_time: "00:00",
        withdrawal_est_time: "00:00",
        loading: true,
        error_message: [],
        touched: false,
    };

    componentDidMount() {
        formValidation("payment_gateway_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_payment_gateway_result !== this.props.post_payment_gateway_result) {
            if (nextProps.post_payment_gateway_result.responseCode === 200) {
                this.setState({ touched: false, loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_payment_gateway_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_payment_gateway_result.message,
                });
                Swal.close();
            }
        }

        return true;
    }

    getPaymentGatewayNameHandler = (event) => {
        this.setState({ payment_gateway_name: event.target.value });
    };

    getPaymentGatewaySlugHandler = (event) => {
        this.setState({ payment_gateway_slug: event.target.value });
    }

    getPaymentGatewaySequenceHandler = (event) => {
        this.setState({ sequence: event.target.value });
    }

    getDepositStatusHandler = (event) => {
        this.setState({
            currentDepositStatus: event.target.value,
        });
    };

    getWithdrawalStatusHandler = (event) => {
        this.setState({
            currentWithdrawalStatus: event.target.value
        })
    }

    getPaymentGatewayDepositEstTimeHandler = (event) => {
        this.setState({
            deposit_est_time: event.target.value
        });
    }

    getPaymentGatewayWithdrawalEstTimeHandler = (event) => {
        this.setState({
            withdrawal_est_time: event.target.value
        });
    }

    postPaymentGatewayHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });


                let data = {};

                data.title = this.state.payment_gateway_name;
                data.slug = this.state.payment_gateway_slug;
                data.sequence = this.state.sequence;
                data.deposit_est_time = this.state.deposit_est_time;
                data.withdrawal_est_time = this.state.withdrawal_est_time;
                data.dp_status = Number(this.state.currentDepositStatus);
                data.wd_status = Number(this.state.currentWithdrawalStatus);
                data.country_id = this.props.currentCountryId;
                this.props.postPaymentGateway(data);
            }
        });
    };

    render() {
        const { t } = this.props;

        let statusArray = [
            {
                label: t("global.active"),
                value: 1,
                selected: true,
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("payment-gateway.payment-gateway")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("payment-gateway.add-payment-gateway")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="payment_gateway_form" className="form-horizontal" onSubmit={this.postPaymentGatewayHandler} noValidate>
                                    <ValidationError formName="payment_gateway_form" touched={this.state.touched} message={this.state.error_message} />

                                    <div className="row">
                                        {/* <div className="col-md-12"> */}
                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="payment_gateway_name">{t("payment-gateway.payment-gateway-name")}</label>

                                            <StringInput
                                                onChange={this.getPaymentGatewayNameHandler}
                                                id="payment_gateway_name"
                                                name="payment_gateway_name"
                                                className="form-control"
                                                placeholder={t("payment-gateway.payment-gateway-name")}
                                                value={this.state.payment_gateway_name}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="payment_gateway_slug">{t("payment-gateway.slug")}</label>

                                            <StringInput
                                                onChange={this.getPaymentGatewaySlugHandler}
                                                id="payment_gateway_slug"
                                                name="payment_gateway_slug"
                                                className="form-control"
                                                placeholder={t("payment-gateway.slug")}
                                                value={this.state.payment_gateway_slug}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="sequence">{t("payment-gateway.sequence")}</label>

                                            <NumberInput
                                                onChange={this.getPaymentGatewaySequenceHandler}
                                                id="sequence"
                                                name="sequence"
                                                className="form-control"
                                                placeholder={t("payment-gateway.sequence")}
                                                value={this.state.sequence}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="sequence">{t("payment-gateway.deposit-est-time")}</label>

                                            <TimeField
                                                id='deposit_est_time'
                                                value={this.state.deposit_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                onChange={this.getPaymentGatewayDepositEstTimeHandler} // {Function} required
                                                input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                colon=":"                          // {String}   default: ":"
                                                showSeconds                        // {Boolean}  default: false
                                                //style={{ width: '48%', display: 'inline' }}
                                            />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="sequence">{t("payment-gateway.withdrawal-est-time")}</label>

                                            <TimeField
                                                id='withdrawal_est_time'
                                                value={this.state.withdrawal_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                onChange={this.getPaymentGatewayWithdrawalEstTimeHandler} // {Function} required
                                                input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                colon=":"                          // {String}   default: ":"
                                                showSeconds                        // {Boolean}  default: false
                                                //style={{ width: '48%', display: 'inline' }}
                                            />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="depositStatus">{t("payment-gateway.deposit-status")}</label>
                                            <Select id="depositStatus" options={statusArray} className="form-control" name="depositStatus" onChange={this.getDepositStatusHandler} />
                                        </div>

                                        <div className="col-md-4 col-12 form-group">
                                            <label htmlFor="withdrawalStatus">{t("payment-gateway.withdrawal-status")}</label>
                                            <Select id="withdrawalStatus" options={statusArray} className="form-control" name="withdrawalStatus" onChange={this.getWithdrawalStatusHandler} />
                                        </div>

                                        <div className="col-12 form-group mb-0 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { paymentGateway, currentUser } = state;

    //Getting the state from the reducers
    return {
        post_payment_gateway_result: paymentGateway.post_payment_gateway_result,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    postPaymentGateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PaymentGatewayCreate));
