import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import * as moment from "moment";

import { getMemberDepositStatusReport, getFilterData } from "../../../store/ducks/report/actions";
// import { getFilterData } from "../../../store/ducks/member/actions";
import axios from "../../../common/config/axios";

import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import DepositStatusListing from "./DepositStatusReportListing";

const initialInputState = {
    filter_register_date: "",
    filter_deposit_status: "",
    filter_member_group: "",
};

class DepositStatusReport extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",

        data: [],

        currentMemberId: "",
        loading: true,

        filter: initialInputState,

        dropDown: {
            member_group_option: [],
        },

        permission: [],
    };

    fetchData() {
        const reportMemberDepositStatusListingData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        reportMemberDepositStatusListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        reportMemberDepositStatusListingData.limit = this.state.limit;
        reportMemberDepositStatusListingData.order = JSON.stringify(this.state.sorted);
        reportMemberDepositStatusListingData.country_id = this.props.currentCountryId;
        reportMemberDepositStatusListingData.search = this.state.searchValue;

        Object.keys(this.state.filter).forEach((val) => {
            reportMemberDepositStatusListingData[val] = this.state.filter[val];
        });

        this.props.getMemberDepositStatusReport(reportMemberDepositStatusListingData);
    }

    componentDidMount() {
        this.props.getFilterData();
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_"));
            this.setState({ permission: reportPermission });
        }

        if (nextProps.reportData !== this.props.reportData) {
            if (nextProps.reportData.data.responseCode === 200) {
                this.setState({
                    data: nextProps.reportData.data.data.result.row,
                    totalRecords: nextProps.reportData.data.data.result.pagination.total_record,
                    totalPages: nextProps.reportData.data.data.result.pagination.total_page,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: nextProps.reportData.data.message[0],
                    icon: nextProps.reportData.data.msgType,
                    confirmButtonText: `OK`,
                });
            }
        }

        if (nextProps.memberFilterData !== this.props.memberFilterData && nextProps.memberFilterData !== undefined) {
            if (nextProps.memberFilterData.data.responseCode === 200 && nextProps.memberFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        member_group_option: nextProps.memberFilterData.data.data.memberGroup,
                    },
                });
            }
            Swal.close();
        }

        return true;
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                dataLoading: true,
                data: [],
            },
            () => {
                if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getMemberDepositStatusReport(listingArr);
                }
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                totalRecords: 0,
                loading: false,
                dataLoading: false,
                filter: newFilter,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberDepositStatusReport(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    depositStatusFormatter = (cell, row) => {
        const { t } = this.props;

        let status;
        let classStatus;
        if (row.deposit_status === "no deposit") {
            classStatus = "danger";
            status = t("member.no-deposit");
        } else {
            classStatus = "success";
            status = t("member.have-deposit");
        }
        return <span className={`badge-${classStatus}`}>{status}</span>;
    };

    memberStatusFormatter = (cell, row) => {
        const { t } = this.props;

        let status;
        let classStatus;
        if (row.status === 0) {
            classStatus = "danger";
            status = t("global.inactive");
        } else {
            classStatus = "success";
            status = t("global.active");
        }
        return <span className={`badge-${classStatus}`}>{status}</span>;
    };

    exportExcelHandler = () => {
        const data = {};
        data.country_id = this.props.currentCountryId;
        data.filter_register_date = this.state.filter.filter_register_date;
        data.filter_member_group = this.state.filter.filter_member_group;
        data.filter_deposit_status = this.state.filter.filter_deposit_status;
        data.order = JSON.stringify(this.state.sorted);

        axios
            .get("member_deposit_status/export", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - MemberDepositStatus " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - MemberDepositStatus Error", err);
            });
    };

    render() {
        const { t } = this.props;
        let ddMemberGroup = [];
        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: t("member.register-date"),
                dataField: "registration_date",
                keyField: "registration_date",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("member.username"),
                dataField: "username",
                keyField: "username",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("member.contact-no"),
                dataField: "contact_no",
                keyField: "contact_no",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("member.email"),
                dataField: "email",
                keyField: "email",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("member.deposit-status"),
                dataField: "depositStatus",
                keyField: "depositStatus",
                formatter: this.depositStatusFormatter,
            },
            {
                text: t("member.member-status"),
                dataField: "status",
                keyField: "status",
                formatter: this.memberStatusFormatter,
            },
        ];

        if (this.state.dropDown.member_group_option) {
            ddMemberGroup = this.state.dropDown.member_group_option.map((val, idx) => {
                return (
                    <option key={val.memberGroupId} value={val.memberGroupId}>
                        {val.groupName}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <DepositStatusListing
                        exportExcelHandler={() => this.exportExcelHandler()}
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        ddMemberGroup={ddMemberGroup}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_member_deposit_status_report_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
        memberFilterData: state.report.member_filter_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberDepositStatusReport: (filter) => dispatch(getMemberDepositStatusReport(filter)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DepositStatusReport));
