import * as type from './types';
import { createReducer } from "../../util/index";
const initialState = {
    loading: false,
    data: [],
};

const itemCategoryReducer = createReducer(initialState) ({
    [type.ITEM_CATEGORY_LIST]: (state, action) => ({
        ...state,
        item_category_list: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value
    }),

    [type.ITEM_CATEGORY_STORE]: (state, action) => ({
        ...state,
        store_item_category: action.payload
    }),
});

export default itemCategoryReducer;