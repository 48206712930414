import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import WalletTypeListing from "./WalletTypeListing";
import WalletTypeCreate from "./WalletTypeCreate";
import WalletTypeShow from "./WalletTypeShow";
import WalletTypeEdit from "./WalletTypeEdit";
import { deleteWalletType, getWalletType, updateWalletTypeStatus } from "../../store/ducks/walletType/actions";
import Select from "../../components/Input/Select/Select";

class WalletType extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        order: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",

        data: [],

        currentWalletTypeId: "",
        loading: true,
    };

    componentDidMount() {
        const walletTypeListingData = this.fetchData();
        this.props.getWalletType(walletTypeListingData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.wallet_type !== this.props.wallet_type) {
            //Swal.close();
            this.setState({
                loading: false,
                data: nextProps.wallet_type.data.data,
                totalRecords: nextProps.wallet_type.data.totalRecords,
                totalPages: nextProps.wallet_type.data.totalPages,
            });
        }

        if (nextProps.delete_wallet_type_result !== this.props.delete_wallet_type_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: nextProps.delete_wallet_type_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    const walletTypeListingData = this.fetchData();
                    this.props.getWalletType(walletTypeListingData);
                }
            });
        }

        if (nextProps.update_status_wallet_type_result !== this.props.update_status_wallet_type_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_wallet_type_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    const walletTypeListingData = this.fetchData();
                    this.props.getWalletType(walletTypeListingData);
                }
            });
        }
        return true;
    }

    fetchData() {
        const walletTypeListingData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        walletTypeListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        walletTypeListingData.limit = this.state.limit;
        walletTypeListingData.order = JSON.stringify(this.state.order);
        walletTypeListingData.search = this.state.searchValue;
        this.props.getWalletType(walletTypeListingData);
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        const walletTypeListingData = this.fetchData();
        this.props.getWalletType(walletTypeListingData);
    };

    updateStatusWalletTypeHandler = (event, walletTypeId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                const updateWalletTypeStatusData = {};
                updateWalletTypeStatusData.id = walletTypeId;
                updateWalletTypeStatusData.status = Number(event.target.value);

                this.props.updateWalletTypeStatus(updateWalletTypeStatusData);
            }
        });
    };

    deleteWalletTypeHandler = (e, wallet_type_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                const deleteWalletTypeData = {};
                deleteWalletTypeData.id = wallet_type_id;

                this.props.deleteWalletType(deleteWalletTypeData);
            }
        });
    };

    statusFormatter = (cell, row) => {
        let statusArray, dtClassName;

        statusArray = [
            {
                label: "Active",
                value: 1,
                selected: true,
            },
            {
                label: "Inactive",
                value: 0,
            },
        ];
        if (row.status === 0) {
            dtClassName = "form-control datatable-select-inactive";
        } else {
            dtClassName = "form-control datatable-select-active";
        }

        return (
            <Select
                id="walletTypeStatus"
                name="walletTypeStatus"
                options={statusArray}
                defaultValue={row.status}
                key={`status-${row.walletTypeId}`}
                onChange={(event) => {
                    this.updateStatusWalletTypeHandler(event, row.walletTypeId);
                }}
                className={dtClassName}
            />
        );
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;
        viewButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-blue mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch} />}
                key={`view-${row.walletTypeId}`}
                clicked={() => this.changePageHandler("show", row.walletTypeId)}
            />
        );
        editButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-info mr-2"
                buttonName={<FontAwesomeIcon icon={faPen} />}
                key={`edit-${row.walletTypeId}`}
                clicked={() => this.changePageHandler("edit", row.walletTypeId)}
            />
        );
        deleteButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-danger mr-2"
                clicked={(event) => this.deleteWalletTypeHandler(event, row.walletTypeId)}
                key={`delete-${row.walletTypeId}`}
                buttonName={<FontAwesomeIcon icon={faTrash} />}
            />
        );
        output = [viewButton, editButton, deleteButton];
        return output;
    };

    changePageHandler = (page, walletTypeId) => {
        this.setState({
            pageShow: page,
            currentWalletTypeId: walletTypeId,
        });
    };

    refreshHandler = (id) => {
        const walletTypeListingData = this.fetchData();
        this.props.getWalletType(walletTypeListingData);
    };

    pageChangeHandler = (activePage) => {
        this.setState({ activePage: activePage, loading: true, data: [] }, () => {
            const walletTypeListingData = this.fetchData();
            this.props.getWalletType(walletTypeListingData);
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            const walletTypeListingData = this.fetchData();
            this.props.getWalletType(walletTypeListingData);
        });
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const walletTypeListingData = this.fetchData();
            this.props.getWalletType(walletTypeListingData);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const walletTypeListingData = this.fetchData();
                this.props.getWalletType(walletTypeListingData);
            });
        }
    };

    render() {
        const columns = [
            {
                text: "No",
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "50px" };
                },
            },
            {
                text: "Wallet Type Id",
                dataField: "walletTypeId",
                keyField: "walletTypeId",
                hidden: true,
            },
            {
                text: "Wallet Type",
                dataField: "name",
                keyField: "name",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: "Wallet Code",
                dataField: "walletCode",
                keyField: "walletCode",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: "Status",
                dataField: "status",
                keyField: "status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return { width: "100px" };
                },
            },
            {
                text: "Action",
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">Wallet Type</h4>
                                </div>
                            </div>
                            <div className="d-flex my-xl-auto right-content">
                                <div className="mb-3 mb-xl-0">
                                    <div className="btn-group">
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-blue"
                                            idValue="btnCreateWalletType"
                                            buttonName="Add Wallet Type"
                                            clicked={() => this.changePageHandler("create")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <WalletTypeListing
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            searchValue={this.state.searchValue}
                            data={this.state.data}
                            order={this.state.order}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            searchChangeHandler={this.searchChangeHandler}
                            postSearchHandler={this.postSearchHandler}
                            refreshHandler={() => this.refreshHandler()}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                        />
                    </div>
                )}

                {this.state.pageShow === "create" && <WalletTypeCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.pageShow === "show" && (
                    <WalletTypeShow show_wallet_type_id={this.state.currentWalletTypeId} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.pageShow === "edit" && (
                    <WalletTypeEdit
                        show_wallet_type_id={this.state.currentWalletTypeId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { walletType } = state;
    //console.log(walletType);
    return {
        wallet_type: walletType.wallet_type_data,
        update_status_wallet_type_result: walletType.update_status_wallet_type_result,
        delete_wallet_type_result: walletType.delete_wallet_type_result,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getWalletType,
    updateWalletTypeStatus,
    deleteWalletType,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletType);
