import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageSeo from "../../../assets/images/seo/SEO.png";

class ReactHelmetAsync extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    // ON PROGRESS
    render() {
        return (
            <>
                <div className="main-error-wrapper page page-h ">
                    <h1>(ON PROGRESS)</h1>
                    <img src={ImageSeo} className="error-page" alt="error" />
                    <h2>Go to Inspect and check the meta tags</h2>
                    <Link className="btn btn-outline-primary" to={{ pathname: "/seo_management" }}>
                        Back to SEO Listing
                    </Link>
                </div>
            </>
        );
    }
}

export default ReactHelmetAsync;
