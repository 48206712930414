import React from "react";

const PageFooter = () => (
    <div className="main-footer mt-2 pos-relative">
        <div className="container-fluid pd-t-0-f ht-100p pos-relative" style={{paddingLeft: '12%'}}>
            <span>Copyright © {(new Date().getFullYear())} GS. All rights reserved.</span>
        </div>
    </div>
)

export default PageFooter;