import { Component } from "react";
// import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import Skeleton from "react-loading-skeleton";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DataTable from "../../components/Datatable/DataTable";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Button from "../../components/Button/Button";
// import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import { formValidation } from "../../hoc/Shared/utility";
// import ValidationError from "../../components/ValidationError/ValidationError";

class MemberReferralPayoutHistoryListing extends Component{
    constructor(props){
        super(props);
        this.state = {
            showCard: false
        }
    }

    componentDidMount(){}

    shouldComponentUpdate(nextProps, nextState){
        return true;
    }

    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        return <span>{rowIndex+1}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render(){
        const { t } = this.props;
        let data = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        const columns = [
            {text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' }},
            {text: t("member-referral.member-username"), dataField: 'username', sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.member-name"), dataField: 'name', sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.order-id"), dataField: 'order_id', sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.referral-commission-given"), dataField: 'payout_amt'}, 
            {text: t("member-referral.transaction-date"), dataField: "transaction_date", sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.create-date"), dataField: "create_date", sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.update-date"), dataField: "update_date", sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.update-by"), dataField: "update_by", sort: true, onSort: (field, order) => this.props.onSort(field, order)},
            {text: t("member-referral.remark"), dataField: "remark"}
        ];

        if (this.props.data) {
            data = this.props.data.map((val, idx) => {
                // const num_format = {
                //     maximumFractionDigits: 2,
                //     minimumFractionDigits: 2,
                // };
                let rate = {};
                let decode_rate = JSON.parse(val.exchange_rate);
                rate.base_myr_to_idr = val.exchange_rate && decode_rate.base_myr_to_idr ? decode_rate.base_myr_to_idr : 0;
                rate.base_idr_to_myr = val.exchange_rate && decode_rate.base_idr_to_myr ? decode_rate.base_idr_to_myr : 0;
                let orderIdVal = permission.includes(
                    "transaction_details_access"
                ) ? (
                    <b>
                        <a href="/#" onClick={(event) => this.props.orderDetailsHandler(event, val.transaction_id)}>
                            {val.order_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                );
                let payoutAmt = permission.includes("member_referral_payout_access") ? (
                    <b>
                        <a href='/#' onClick={(event) => this.props.detailsHandler(event, val.member_id, val.transaction_date, rate)}>
                            {val.payout_amt}
                            <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                        </a>
                    </b>
                ) : (
                    val.payout_amt
                )

                return {
                    no: idx,
                    username: val.username,
                    name: val.name,
                    order_id: orderIdVal,
                    payout_amt: payoutAmt,
                    transaction_date: val.transaction_date,
                    create_date: val.create_date,
                    update_date: val.update_date,
                    update_by: val.admin,
                    remark: val.remark
                };
            });
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member-referral.referral-payout-history-title")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                <form
                                    method="post" id="filter_form"
                                    className={`form-horizontal filter-function row ${
                                        this.state.showCard ? "open" : "close"
                                    } filter-wrapper-2`}
                                >
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">{t("member-referral.transaction-date")}</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                </div>
                                                <DateTimePicker
                                                    id="date_range"
                                                    name="date_range"
                                                    className="form-control filter-input"
                                                    format="YYYY-MM-DD"
                                                    range={true}
                                                    value={this.props.filterInput.date_range}
                                                    onChange={(event) => this.props.onInputChange(event,"filterInput")}
                                                    maxDate={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">{t("member-referral.member")}</label>
                                            <StringInput
                                                id="username"
                                                name="username"
                                                value={this.props.filterInput.username}
                                                onChange={(event) => this.props.onInputChange(event,"filterInput")}
                                                className="form-control filter-input"
                                                onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col mt-auto">
                                        <div className="d-flex">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                        </div>
                                    </div>
                                </form>
                                )}
                            </div>
                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("member-referral.referral-payout-history")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val,"activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val,"limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val,"searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberReferralPayoutHistoryListing);