import axios from "../../../../common/config/axios";
import * as type from "./types";

export const getMatchListing = (data) => (dispatch, getState) => {
    axios.get("event_match/list", {params: data})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event Match Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getOptionData = (data) => (dispatch, getState) => {
    axios.get("event_match/get_option_data", {}).then((res) => {
            dispatch({
                type: type.OPTION_DATA,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event Match Error", err);
            dispatch({
                type: type.OPTION_DATA,
                payload: [],
            });
        });
};

export const create = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_match/create", data)
        .then((res) => {
            dispatch({
                type: type.CREATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Create Event Match Error\n", err);
            dispatch({
                type: type.CREATE,
                payload: err.data,
            });
        });
};

export const update = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_match/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event Match Error\n", err);
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};

export const updateStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_match/update_status", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event Match Status Error\n", err);
            dispatch({
                type: type.UPDATE_STATUS,
                payload: err.data,
            });
        });
};

export const updateScore = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_match/update_score", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_SCORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event Match SCORE Error\n", err);
            dispatch({
                type: type.UPDATE_SCORE,
                payload: err.data,
            });
        });
};