import * as type from "./types";
import { createReducer } from "../../util/index";
// import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    // data: [],
};

const announcementReducer = createReducer(initialState) ({
    [type.ANNOUNCEMENT_LIST]: (state, action) => ({
        ...state,
        announcement_data: action.payload
    }),

    [type.ANNOUNCEMENT_CREATE]: (state, action) => ({
        ...state,
        announcement_create_data: action.payload
    }),

    [type.ANNOUNCEMENT_STORE]: (state, action) => ({
        ...state,
        announcement_store_result: action.payload,
    }),

    [type.ANNOUNCEMENT_SHOW]: (state, action) => ({
        ...state,
        announcement_show_data: action.payload
    }),

    [type.ANNOUNCEMENT_EDIT]: (state, action) => ({
        ...state,
        announcement_edit_data: action.payload
    }),

    [type.ANNOUNCEMENT_UPDATE]: (state, action) => ({
        ...state,
        announcement_update_result: action.payload,
    }),

    [type.ANNOUNCEMENT_UPDATE_STATUS]: (state, action) => ({
        ...state,
        announcement_update_status_result: action.payload,
    }),
});

export default announcementReducer;