export const AGENT_LIST = "AGENT_LIST";
export const AGENT_SHOW = "AGENT_SHOW";
export const AGENT_CREATE = "AGENT_CREATE";
export const AGENT_STORE = "AGENT_STORE";
export const AGENT_EDIT = "AGENT_EDIT";
export const AGENT_UPDATE = "AGENT_UPDATE";
export const AGENT_UPDATE_STATUS = "AGENT_UPDATE_STATUS";
export const AGENT_UPDATE_LOGIN_PERMISSION = "AGENT_UPDATE_LOGIN_PERMISSION";
export const AGENT_SETTING_DETAILS = "AGENT_SETTING_DETAILS";
export const AGENT_UPDATE_SETTING = "AGENT_UPDATE_SETTING";
export const AGENT_DOWNLINE = "AGENT_DOWNLINE";
export const AGENT_UPDATE_RESET_PASSWORD = "AGENT_UPDATE_RESET_PASSWORD";
export const AGENT_WALLET_BALANCE = "AGENT_WALLET_BALANCE";
export const AGENT_STORE_WALLET_ADJUSTMENT = "AGENT_STORE_WALLET_ADJUSTMENT";
export const INIT_AGENT_SHOW_INITIAL_LOADING = "INIT_AGENT_SHOW_INITIAL_LOADING";
export const SET_AGENT_SHOW_INITIAL_LOADING = "SET_AGENT_SHOW_INITIAL_LOADING";
export const SET_AGENT_INFO_LOCAL_STATE = "SET_AGENT_INFO_LOCAL_STATE";
export const SET_AGENT_TRANSACTION_HISTORY_LOCAL_STATE = "SET_AGENT_TRANSACTION_HISTORY_LOCAL_STATE";
export const SET_AGENT_WALLET_TRACKER_LOCAL_STATE = "SET_AGENT_WALLET_TRACKER_LOCAL_STATE";
export const AGENT_TRANSACTION_HISTORY = "AGENT_TRANSACTION_HISTORY";
export const AGENT_FILTER_DATA = "AGENT_FILTER_DATA";
export const AGENT_WALLET_TRACKER = "AGENT_WALLET_TRACKER";
