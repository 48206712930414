import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import LuckySpinSettingListing from './LuckySpinSettingListing';
import LuckySpinSettingCreate from './LuckySpinSettingCreate';
import LuckySpinSettingEdit from './LuckySpinSettingEdit';

import * as actions from '../../store/ducks/luckySpinSetting/actions';

class LuckySpinSetting extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: '',

            /* api result array */
            data: [],

            /* skeleton loading */
            loading: true,
        }
        // this.onInputChange = this.onInputChange.bind(this);
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount()
    {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                // console.log(nextProps.rs.data.result.row)
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    detailsHandler = (e, page, id) =>
    {
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.lang_id = this.props.currentUser.language_id;
        // Object.keys(this.state.filter).forEach((val) =>
        // {
        //     listingArr[val] = this.state.filter[val];
        // });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            errMsg: [],
            touched: false,
        });
    }


    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <LuckySpinSettingListing
                    
                        // setstate function
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        changePageHandler={(event, page, id) => this.changePageHandler(event, page, id)}
                        onSort={(field, order) => this.onSort(field, order)}

                        // datatables data
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        // skeleton loading
                        loading={this.state.loading}

                        // user session
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='create' &&
                    <LuckySpinSettingCreate
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <LuckySpinSettingEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.luckySpinSetting.rs,
        // dropDown: state.luckySpinSetting.dropDown,
        // updateStatus: state.luckySpinSetting.updateStatus,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        // funcGetDropDown: () => dispatch(actions.getDropDown()),
        // funcUpdateStatus: (data) => dispatch(actions.updateStatus(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(LuckySpinSetting));