import * as type from './types';
import axios from '../../../common/config/axios';

export const postIsAuthenticated = (authStatus) => dispatch => {
    dispatch ({
        type: type.POST_IS_AUTHENTICATED,
        payload: {
            "data": authStatus
        }
    });
};

//Call the api for example call the login api
export const postLogin = (res_data) => dispatch => {
    axios.post('login', res_data)
    .then( (response) => {
        //Dispatch to the reducers
        dispatch ({
            //Type is based on types.js name you created
            type: type.POST_LOGIN,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.POST_LOGIN,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

//Call the api for example call the login api
export const postLogOut = (props) => dispatch => {
    axios.post('logout', {
    }).then((response) => {
        dispatch({
            type: type.POST_LOG_OUT,
            payload: response.data
        })
    }).catch(err => {
        console.warn(err);
        localStorage.clear();
        props.history.push('/login');
    })
};

export const postChangePassword = (res_data) => dispatch => {
    axios.post('change_password', res_data)
    .then( (response) => {
        //Dispatch to the reducers
        dispatch ({
            //Type is based on types.js name you created
            type: type.CHANGE_PASSWORD,
            payload: response.data
        })
    }).catch (err => {
        dispatch ({
            type: type.CHANGE_PASSWORD,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const postIsAuthorized = (data) => dispatch => {
    axios.get('/authorization')
    .then((response) => {
        dispatch({
            type: type.POST_IS_AUTHORIZED,
            payload: response.data
        });
    })
    .catch((err) => {

    });
}

// export const getUserData = (data) => (dispatch) => {
//     if(data) {
//         dispatch({
//             type: type.SET_USER,
//             payload: data
//         })
//     }
// };

// export const changeCountry = (data) => (dispatch, getState) => {
//     if(data) {
//         let updatedData = getState().currentUser.data;
//         updatedData.country_id = data.country_id;
//         updatedData.country_code = data.country_code;
//         // console.log("changeCountry Action",getState(), "\n", updatedData);
//         dispatch({
//             type: type.CHANGE_COUNTRY,
//             payload: updatedData
//         })
//     }
// };