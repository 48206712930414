import React, { Component } from "react";
import { connect } from "react-redux";
import { editAnnouncement, updateAnnouncement } from "../../store/ducks/announcement/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Checkbox from "../../components/Input/Checkbox/Checkbox";
import MultipleCheckbox from "../../components/Input/Checkbox/MultipleCheckbox";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";
//import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";

class AnnouncementEdit extends Component {
    constructor(props) {
        super(props);
        var dt = new Date();
        this.state = {
            id: null,
            title: "",
            message: {},
            member_group: [],
            status: 1,
            show: 1,
            select_all: false,
            start_time: dt.setHours(dt.getHours() + 2, 0),
            end_time: dt.setHours(dt.getHours() + 3, 0),
            lang_id: [],
            sequence: 1,
            country_id: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        // console.log("[AnnouncementEdit] constructor() ", props);
        this.handleChange = this.handleChange.bind(this);
        this.handleTextareaChange = this.handleTextareaChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleShowChange = this.handleShowChange.bind(this);
        this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
    }

    componentDidMount() {
        this.props.editAnnouncement(this.props.announcementEditId);
        formValidation("announcement_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[AnnouncementEdit] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);

        if (nextProps.announcementEditData !== undefined && this.props.announcementEditData !== nextProps.announcementEditData && nextState.loading === true) {
            if(nextProps.announcementEditData.responseCode === 200 && nextProps.announcementEditData.msgType === 'success'){
                // var dt = new Date();
                let announcementEditData = nextProps.announcementEditData.data.announcement[0];
                let announcementDetailsData = nextProps.announcementEditData.data.announcementDetails;
                let languagesData = nextProps.announcementEditData.data.languages;
                let languageId = languagesData.map((item) => item.langId);
                let memberGroupData = nextProps.announcementEditData.data.memberGroup;
                let announcementMemberGroupData = nextProps.announcementEditData.data.announcementMemberGroup;
                let array = [];
                let arrayMessage = [];
                let object = {};

                languageId.forEach((languageItem) => {
                    let addEmptyCheck = true;
                    announcementDetailsData.forEach((detailItem) => {
                        if (languageItem === detailItem.langId) {
                            addEmptyCheck = false;
                            array.push("message" + [detailItem.langId]);
                            arrayMessage.push(detailItem.message);
                        }
                    });
                    if (addEmptyCheck === true) {
                        array.push("message" + [languageItem]);
                        arrayMessage.push("");
                    }
                });

                for (let i = 0; i < array.length; i++) {
                    object[array[i]] = arrayMessage[i];
                }

                // Member Group
                memberGroupData.forEach((element) => {
                    let check = true;
                    announcementMemberGroupData.forEach((announcementItem) => {
                        if (announcementItem.memberGroupId === element.memberGroupId) {
                            check = false;
                            element.isChecked = true;
                        }
                    });
                    if (check === true) {
                        element.isChecked = false;
                    }
                });

                this.setState({
                    id: announcementEditData.announcementId,
                    title: announcementEditData.title,
                    message: object,
                    member_group: memberGroupData,
                    status: announcementEditData.status,
                    show: announcementEditData.show,
                    // start_time: dt.setHours(dt.getHours() + 2, 0),
                    // end_time: dt.setHours(dt.getHours() + 3, 0),
                    start_time: new Date(announcementEditData.startTime),
                    end_time: new Date(announcementEditData.endTime),
                    sequence: announcementEditData.sequence,
                    loading: false,
                });
            }else{
                this.setState({
                    loading: true,
                }, () => {
                    filterErrorHandler(nextProps.announcementEditData.message)
                });
                // Swal.close();
            }
            
        }

        if (nextProps.announcementUpdateResult !== this.props.announcementUpdateResult) {
            if (nextProps.announcementUpdateResult.responseCode === 200 && nextProps.announcementUpdateResult.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.announcementUpdateResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.announcementUpdateResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    handleNumberChange = (event) => {
        this.setState({
            [event.target.name]: Number(event.target.value),
        });
    };

    handleTextareaChange = (event) => {
        const { message } = this.state;
        message[event.target.name] = event.target.value;
        this.setState({ message });
    };

    handleShowChange = (event) => {
        this.setState({ show: Number(event.target.value) });
        if (Number(event.target.value) === 1) {
            let memberGroup = this.state.member_group;
            memberGroup.forEach((mg) => (mg.isChecked = false));
            this.setState({ member_group: memberGroup });
        }
    };

    handleAllChecked = (event) => {
        let memberGroup = this.state.member_group;
        memberGroup.forEach((mg) => (mg.isChecked = event.target.checked));
        this.setState({ member_group: memberGroup });
    };

    handleCheckChildElement = (event) => {
        let memberGroup = this.state.member_group;
        memberGroup.forEach((mg) => {
            if (Number(mg.memberGroupId) === Number(event.target.value)) {
                mg.isChecked = event.target.checked;
            }
        });
        this.setState({ member_group: memberGroup });
    };

    updateAnnouncementHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let start_time = moment(this.state.start_time).format("yyyy-MM-DD HH:mm");
                let end_time = moment(this.state.end_time).format("yyyy-MM-DD HH:mm");
                let languageIdArray = Object.keys(this.state.message).map((val) => Number(val.substr(7)));
                let messageArray = Object.values(this.state.message);
                let memberGroupData = this.state.member_group.filter((item) => item.isChecked === true).map((item) => item.memberGroupId);
                let updateAnnouncementData = {};

                updateAnnouncementData.edit_id = this.state.id;
                updateAnnouncementData.title = this.state.title;
                updateAnnouncementData.lang_id = languageIdArray;
                updateAnnouncementData.message = messageArray;
                updateAnnouncementData.start_time = start_time;
                updateAnnouncementData.end_time = end_time;
                updateAnnouncementData.status = this.state.status;
                updateAnnouncementData.show = this.state.show;
                updateAnnouncementData.sequence = this.state.sequence;
                updateAnnouncementData.member_group = memberGroupData;
                updateAnnouncementData.country_id = 1;
                // console.log("Form Submit:", updateAnnouncementData);
                this.props.updateAnnouncement(updateAnnouncementData);
            }
        });
    };
    /* ======================== Function [END] ======================== */

    render() {
        const { t } = this.props;
        let display = null;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = null;

        if (!this.state.loading) {
            let status = [
                { label: t("global.active"), value: 1 },
                { label: t("global.inactive"), value: 0 },
            ];
            let show = [
                { label: t("announcement.news"), value: 1 },
                { label: t("announcement.member"), value: 2 },
            ];

            let languages = this.props.announcementEditData.data.languages;
            tabTitleMessage = (languages) ? languages.map((language) => language.lang) : [];
            tabContentMessage = Object.keys(this.state.message).map((item, key) => (
                <div key={key}>
                    <label htmlFor="message">{t("announcement.message")}</label>
                    <textarea name={`${item}`} className="form-control" placeholder="Message" value={this.state.message[`${item}`]} onChange={this.handleTextareaChange} rows={5} />                </div>
            ));
            // console.log(tabContentMessage, tabContentMessage);
            if (tabTitleMessage.length && tabContentMessage.length) {
                tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border" />;
            } else {
                tabMessage = null;
            }

            let memberGroup = this.state.member_group.map((val, idx) => (
                <label className="checkbox-inline mr-1" key={idx}>
                    <MultipleCheckbox
                        id={val.memberGroupId}
                        value={val.memberGroupId}
                        label={val.name}
                        isChecked={val.isChecked}
                        handleCheckChildElement={this.handleCheckChildElement}
                    />
                </label>
            ));

            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("announcement.edit-announcement")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <form method="post" id="announcement_form" className="form-horizontal" onSubmit={this.updateAnnouncementHandler} noValidate>
                                        <ValidationError formName="announcement_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t("announcement.title")}</label>
                                                    <StringInput
                                                        id="title"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        value={this.state.title}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">{tabMessage}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("announcement.start-time")}</label>
                                                    <DatePicker
                                                        name="start_time"
                                                        className="form-control"
                                                        wrapperClassName="wrapperDatePicker"
                                                        selected={this.state.start_time}
                                                        onChange={this.handleStartTimeChange}
                                                        minDate={new Date()}
                                                        showTimeSelect
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption={t("global.time")}
                                                        popperContainer={DatePickerContainer}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("announcement.end-time")}</label>
                                                    <DatePicker
                                                        name="end_time"
                                                        className="form-control"
                                                        wrapperClassName="wrapperDatePicker"
                                                        selected={this.state.end_time}
                                                        onChange={this.handleEndTimeChange}
                                                        minDate={new Date()}
                                                        showTimeSelect
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption={t("global.time")}
                                                        popperContainer={DatePickerContainer}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="from-group">
                                                    <label>{t("announcement.show")}</label>
                                                </div>
                                                <Select id="show" name="show" options={show} onChange={this.handleShowChange} value={this.state.show} className="form-control" />
                                            </div>
                                            <div className="col-md-4">
                                                <div className="from-group">
                                                    <label>{t("global.status")}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        options={status}
                                                        onChange={this.handleNumberChange}
                                                        value={this.state.status}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="from-group">
                                                    <label>{t("announcement.sequence")}</label>
                                                    <NumberInput
                                                        id="sequence"
                                                        name="sequence"
                                                        className="form-control"
                                                        value={this.state.sequence}
                                                        onChange={this.handleNumberChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.show === 2 && (
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="member_group">{t("announcement.member-group")}</label>
                                                        <div className="form-group">
                                                            <label className="checkbox-inline mr-1">
                                                                <Checkbox onChange={this.handleAllChecked} name="select_all" id="select_all" defaultValue={this.state.select_all} />{" "}
                                                                {t("global.select-all")}
                                                            </label>
                                                            {memberGroup}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button
                                                    typeButton="submit"
                                                    classValue="btn btn-blue"
                                                    idValue="submit"
                                                    clicked={this.updateAnnouncementHandler}
                                                    buttonName={t("global.save")}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("announcement.edit-announcement")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("announcement.announcement")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("Test",state.announcement.announcement_create_data);
    const { announcement } = state;
    return {
        announcementEditData: announcement.announcement_edit_data,
        announcementUpdateResult: announcement.announcement_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editAnnouncement: (id) => dispatch(editAnnouncement(id)),
        updateAnnouncement: (updateAnnouncementData) => dispatch(updateAnnouncement(updateAnnouncementData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AnnouncementEdit));
