import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import BannersListing from "./BannersListing";
import BannersCreate from "./BannersCreate";
import BannersShow from "./BannersShow";
import BannersEdit from "./BannersEdit";
import Select from "../../components/Input/Select/Select";
import { getBanners, getDropDown, updateBannerStatus, deleteBanner } from "../../store/ducks/banners/actions";


const initialInputState =
{
    /* form input value */
    status: '',
    category: ''
};

let bannerData = {};
class Banners extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        dropDown: [],

        filter: initialInputState,

        currentBannerId: '',
        loading: true,
        currentImage: '',

        permission: []

    }

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }

        bannerData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        bannerData.limit = this.state.limit;
        bannerData.order = JSON.stringify(this.state.sorted);
        bannerData.lang_id = this.props.currentLanguageId;
        bannerData.country_id = this.props.currentCountryId;
        bannerData.status = this.state.filter.status;
        bannerData.category = this.state.filter.category;

        this.props.getBanners(bannerData);
        this.props.getDropDown()
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let bannerPermission = this.props.currentPermission.filter((item) => item.includes("banner_"));
            this.setState({ permission: bannerPermission });
        }

        if (nextProps.banner_data !== this.props.banner_data) {
            this.setState({
                loading: false,
                data: nextProps.banner_data.data.data,
                totalRecords: nextProps.banner_data.data.totalRecords,
                totalPages: nextProps.banner_data.data.totalPages,
            })
        }

        if (nextProps.dropDown !== this.props.dropDown) {
            if (nextProps.dropDown.responseCode === 200) {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                })
            } else {
                this.setState({ loading: false });
            }
        }

        if (nextProps.delete_banner_result !== this.props.delete_banner_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: nextProps.delete_banner_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fetchData();
                }
            });
        }

        if (nextProps.update_status_banner_result !== this.props.update_status_banner_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_banner_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fetchData();
                }
            });
        }


        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    // updateStatusBannerHandler = (event, banner_id) => {
    //     event.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.update"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const updateBannerStatusData = {};
    //             updateBannerStatusData.banner_id = banner_id;
    //             updateBannerStatusData.status = Number(event.target.value);

    //             this.props.updateBannerStatus(updateBannerStatusData);
    //         }
    //     });
    // }

    updateStatusBannerHandler = (event, banner_id) => {
        event.preventDefault();
        Swal.fire({
            title: '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        });

        const updateBannerStatusData = {};
        updateBannerStatusData.banner_id = banner_id;
        updateBannerStatusData.status = Number(event.target.value);

        this.props.updateBannerStatus(updateBannerStatusData);
    }

    deleteBannerHandler = (e, banner_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deleteBannerData = {};
                deleteBannerData.id = banner_id;

                this.props.deleteBanner(deleteBannerData);
            }
        });
    }

    statusFormatter = (cell, row) => {

        const { t } = this.props;
        if (this.state.permission.includes("banner_edit")) {

            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="bannerStatus"
                    name="bannerStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.banner_id}`}
                    onChange={(event) => {
                        this.updateStatusBannerHandler(event, row.banner_id)
                    }}
                    className={dtClassName}
                />
            )
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }


    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        if (this.state.permission.includes("banner_view")) {
            viewButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                    key={`view-${row.banner_id}`}
                    clicked={() => this.changePageHandler('show', row.banner_id)}
                />;
        }

        if (this.state.permission.includes("banner_edit")) {
            editButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.banner_id}`}
                    clicked={() => this.changePageHandler('edit', row.banner_id)}
                />;
        }
        if (this.state.permission.includes("banner_delete")) {
            deleteButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white delete"
                    clicked={(event) => this.deleteBannerHandler(event, row.banner_id)}
                    key={`delete-${row.banner_id}`}
                    buttonName={<FontAwesomeIcon icon={faTrash} />}
                />;
        }
        output = [viewButton, editButton, deleteButton];
        return output;
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, () => {
            const payload = {
                ...bannerData,
                ...this.state.filter
            }
            this.props.getBanners(payload)
        });
    }

    clearHandler = () => {
        this.setState({
            activePage: 1,
            filter: initialInputState,
            loading: true,
            data: [],
        }, () => {
            let payload = {
                ...bannerData,
                page: 1,
                ...this.state.filter
            }
            this.props.getBanners(payload)
        });
    }

    changePageHandler = (page, bannerId) => {
        this.setState({
            pageShow: page,
            currentBannerId: bannerId
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    }

    desktopImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{ "width": "170px", "cursor": "pointer" }}
                src={cell} onClick={() => this.setState({ isOpen: true, currentImage: cell })}
                key={`desktop-image-${row.banner_id}`}
            />
        );
    }

    mobileImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                className='tb-img'
                src={cell}
                onClick={() => this.setState({ isOpen: true, currentImage: cell })}
                key={`mobile-image-${row.banner_id}`}
            />
        );
    }

    // mobileImageFormatter = (cell, row) => {
    //     return (
    //         <img
    //             alt=""
    //             style={{"width": "50%", "cursor": "pointer"}}
    //             src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
    //             key={`mobile-image-${row.banner_id}`}
    //         />
    //     );
    // }

    render() {
        const { t } = this.props;
        let rsListing = [];
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: '60px' };
                }
            },
            {
                text: t("banner.sequence"),
                dataField: "sequence",
                keyField: 'sequence',
                sort: true,
                onSort: this.onSort
            },
            {
                text: "Banner Id",
                dataField: "banner_id",
                keyField: 'bannerId',
                hidden: true
            },
            {
                text: t("banner.desktop-image"),
                dataField: "desktop_image",
                keyField: "desktopImage",
                formatter: this.desktopImageFormatter
            },
            {
                text: t("banner.mobile-image"),
                dataField: "mobile_image",
                keyField: "mobileImage",
                formatter: this.mobileImageFormatter
            },
            {
                text: t("banner.category"),
                dataField: "category_1",
                keyField: "BannerCategory",
            },
            {
                text: t('global.status'),
                dataField: "status",
                keyField: "Status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return { width: '115px' };
                }
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return { width: '155px' };
                }
            }
        ];

        if (this.state.data) {
            rsListing = this.state.data.map((val, idx) => {
                return {
                    no: idx,
                    sequence: val.sequence,
                    // id: val.id,
                    banner_id: val.banner_id,
                    desktop_image: val.desktop_image,
                    mobile_image: val.mobile_image,
                    status: val.status,
                    category_1: val.category ? t(
                        `game-type.${val.category}`
                    ) : '-',
                    action: this.actionFormatter
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <div>
                        {this.state.isOpen && (
                            <Lightbox
                                mainSrc={this.state.currentImage}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                            />
                        )}
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("banner.banner")}</h4>
                                </div>
                            </div>
                        </div>
                        <BannersListing
                            status={this.state.filter.status}
                            category={this.state.filter.category}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={rsListing}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}

                            /**filter drop down*/
                            ddCategory={this.state.dropDown.category}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                }


                {this.state.pageShow === 'create' &&
                    <BannersCreate
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow === 'show' &&
                    <BannersShow
                        show_banner_id={this.state.currentBannerId}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow === 'edit' &&
                    <BannersEdit
                        show_banner_id={this.state.currentBannerId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const { banner, currentUser } = state;

    return {
        banner_data: banner.banner_data,
        update_status_banner_result: banner.update_status_banner_result,
        delete_banner_result: banner.delete_banner_result,
        currentPermission: currentUser.data.permission,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        dropDown: banner.dropDown
    }
};

const mapDispatchToProps = {
    updateBannerStatus,
    getBanners,
    getDropDown,
    deleteBanner
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Banners));