import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
// import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

class MobileAppsListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            touched: false,
            error_message: [],
            uploadFile: null
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */
    uploadHandler = (e) => {
        const { t } = this.props;
        e.preventDefault();

        let testData = new FormData();
        for (let i = 0; i < this.state.uploadFile.length; i++) {
            testData.append("file[]", this.state.uploadFile[i]);
            
            console.log(testData);
        }

        if (!(this.state.uploadFile)) {
            Swal.fire({
                title: t("global.error"),
                icon: "error",
                html: t('mobile_apps.select_file'),
                showConfirmButton: true,
                confirmButtonText: t("global.ok")
            });
        } else {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("mobile_apps.upload"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });     

                    let insertData = {};
                    insertData.uploadFile = this.state.uploadFile;

                    this.props.upload(insertData);

                    this.setState({
                        uploadFile: null
                    });
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        let rsListing = [];
        
        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.props.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("mobile_apps.file_name"),
                dataField: "file_name"
            },
            {
                text: t("mobile_apps.status"),
                dataField: "status",
                formatter:this.props.statusFormatter,
                sort: false,
            },
            {
                text: t("mobile_apps.upload_date"),
                dataField: "upload_date"
            },
            {
                text: t("mobile_apps.upload_by"),
                dataField: "upload_by"
            },
            {
                text: t("global.action"),
                dataField: "#",
                formatter: this.props.actionFormatter,
                headerStyle: { width: "100px" },
            }
        ];
        
        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                return {
                    id: val.id,
                    no: idx+1,
                    file_name: val.name,
                    status: val.status,
                    upload_date: val.created_at,
                    upload_by: val.username,
                    downloadUrl: val.path
                };
            });
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("mobile_apps.mobile_apps")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                            <div
                            className={`form-horizontal filter-function row
                            ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("event_qna_question.date")}
                                        </label>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-calendar-check" />
                                            </span>
                                            <DateTimePicker
                                                id="date"
                                                name="date"
                                                className="form-control filter-input"
                                                range={true}
                                                timePicker={true}
                                                value={this.props.filter.date}
                                                onChange={this.props.onInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col mt-auto">
                                    <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="filter"
                                        buttonName={t("global.filter")}
                                        clicked={() =>
                                            this.props.refreshHandler()
                                        }
                                    />
                                    &nbsp;
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary"
                                        idValue="reset"
                                        buttonName={t("global.reset")}
                                        clicked={() =>
                                            this.props.clearHandler()
                                        }
                                    />
                                    </div>
                                </div>
                            </div>
                                    )}
                                </div>

                            <div className="show-btn"
                            onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("mobile_apps.mobile_apps_listing")}
                                        </h4>
                                    </div>
                                </div>
                                {this.props.permission.includes("mobile_apps_upload") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    idValue="event_match"
                                                    classValue="btn btn-underline"
                                                    clicked={() => this.props.changePageHandler("upload")}
                                                    buttonName={t("mobile_apps.upload")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces("translation")(MobileAppsListing));
