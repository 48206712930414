import React, { Component } from "react";
import { connect } from "react-redux";
import { getXMLSitemap, generateXMLSitemap } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Textarea from "../../../components/Input/Textarea/Textarea";
import Button from "../../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import StringInput from "../../../components/Input/StringInput/StringInput";
import ValidationError from "../../../components/ValidationError/ValidationError";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

class XMLSitemap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xmlSitemap: "",
            lastModified: "",
            url: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.getXMLSitemap();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[XMLSitemap] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.xmlSitemap !== null && nextState.xmlSitemap === "") {
            this.setState({
                xmlSitemap: nextProps.xmlSitemapData.data.sitemap_xml,
                lastModified: nextProps.xmlSitemapData.data.last_modified,
                loading: false,
            });
        }

        if (nextProps.generateXMLSitemapResult !== this.props.generateXMLSitemapResult) {
            if (nextProps.generateXMLSitemapResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.generateXMLSitemapResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            xmlSitemap: nextProps.generateXMLSitemapResult.data.sitemap_xml,
                            lastModified: nextProps.generateXMLSitemapResult.data.last_modified
                        })
                    }
                });
            } else if (nextProps.generateXMLSitemapResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.generateXMLSitemapResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    generateSitemapHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("xml-sitemap.generate-sitemap"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                let xmlSitemapData = {};
                xmlSitemapData.url = this.state.url;
                this.props.generateXMLSitemap(xmlSitemapData);
            }
        });
    };

    downloadSitemapHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global._yes"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const element = document.createElement("a");
                const file = new Blob([this.state.xmlSitemap], {type: 'text/plain'});
                element.href = URL.createObjectURL(file);
                element.download = "sitemap.xml";
                document.body.appendChild(element);
                element.click();
                Swal.close();
            }
        });
    };

    render() {
        const { t } = this.props;
        dayjs.extend(relativeTime);
        let display = null;
        let xmlSitemap = this.state.xmlSitemap === null ? "" : this.state.xmlSitemap;

        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("seo.xml-sitemap")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <form method="post" id="xml_sitemap_form" className="form-horizontal" onSubmit={this.generateSitemapHandler} noValidate>
                                    <ValidationError formName="xml_sitemap_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p>{t("xml-sitemap.description")}</p>
                                            <Textarea
                                                id="xml_sitemap"
                                                name="xml_sitemap"
                                                className="form-control"
                                                placeholder={t("robots-txt.robots-txt-file")}
                                                value={xmlSitemap}
                                                rows={25}
                                                cols={40}
                                                disabled={true}
                                            />
                                            <p>{t("xml-sitemap.last-modified")}: {dayjs.unix(this.state.lastModified).fromNow()}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="url">{t("xml-sitemap.url")}</label>
                                                <StringInput
                                                    id="url"
                                                    name="url"
                                                    className="form-control"
                                                    placeholder={t("xml-sitemap.url")}
                                                    value={this.state.url}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group mb-0 mt-3 justify-content-end">
                                                <div>
                                                    <Button typeButton="submit" classValue="btn btn-primary mr-2" buttonName={t("xml-sitemap.generate-sitemap")} />
                                                    <Button typeButton="button" classValue="btn btn-secondary" clicked={this.downloadSitemapHandler} idValue="download" buttonName={t("xml-sitemap.download-sitemap")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("seo.xml-sitemap")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        xmlSitemapData: state.seo.xml_sitemap_data,
        generateXMLSitemapResult: state.seo.xml_sitemap_generate_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getXMLSitemap: () => dispatch(getXMLSitemap()),
        generateXMLSitemap: (data) => dispatch(generateXMLSitemap(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(XMLSitemap));
