import React, { Component } from "react";
// import { connect } from 'react-redux';

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import StringInput from "../../components/Input/StringInput/StringInput";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import Excel from "../../assets/images/excel.svg";

class VoucherListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;
        
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("voucher.voucher")}</h4>
                            </div>
                        </div>
                        {this.props.permission.includes("voucher_create") && (
                            <div className="d-flex my-xl-auto right-content">
                                <div>
                                    <div className="btn-group">
                                        <Button
                                            typeButton="button"
                                            idValue="add_voucher"
                                            classValue="btn btn-blue hover__btn rounded"
                                            clicked={() => this.props.changePageHandler("create")}
                                            buttonName={t("voucher.add_voucher")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                            <div
                            className={`form-horizontal filter-function row
                            ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("voucher.code")}
                                        </label>
                                        <StringInput
                                            id="code"
                                            name="code"
                                            value={this.props.filter.code}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("voucher.email")}
                                        </label>
                                        <StringInput
                                            id="email"
                                            name="email"
                                            value={this.props.filter.email}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col mt-auto">
                                    <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="filter"
                                        buttonName={t("global.filter")}
                                        clicked={() =>
                                            this.props.refreshHandler()
                                        }
                                    />
                                    &nbsp;
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary"
                                        idValue="reset"
                                        buttonName={t("global.reset")}
                                        clicked={() =>
                                            this.props.clearHandler()
                                        }
                                    />
                                     {(() => {
                                        return (
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                clicked={() => this.props.exportExcelHandler()}
                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                buttonName={t("report.export-excel")}
                                            />
                                        );
                                    })()}
                                    </div>
                                </div>

                            </div>
                                    )}
                                </div>

                            <div className="show-btn"
                            onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("voucher.voucher_listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="systemSettingsId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(VoucherListing);
