import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { postShipment, getDropDown, getAreaDropDown } from "../../store/ducks/shipments/actions";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import ReactSelect from "react-select";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Swal from "sweetalert2";

class ShipmentsCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shipment_type: "",
			courier: "",
			warehouse: "",
			state: "",
			area: "",
			parcel_type: "",
			price_setting: [{listIndex: Math.random(), firstPrice: "", nextPrice: "", secondPrice: "", nextSecondPrice: ""}],

			error_message: [],
			loading: true,
			touched: false
		}
		this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
	}

	componentDidMount() {
		formValidation("shipment_form");
		this.props.getDropDown();
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
			if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
				this.setState({
					loading: false,
					shipment_type_options: this.mapOptions(nextProps.dropDown.data.shipment_transport, 'type'),
					courier_options: this.mapOptions(nextProps.dropDown.data.shipment_courier, 'courier_name'),
					warehouse_options: this.mapOptions(nextProps.dropDown.data.shipment_warehouse, 'warehouse'),
					state_options: this.mapOptions(nextProps.dropDown.data.shipment_state, 'state'),
					parcel_type_options: this.mapOptions(nextProps.dropDown.data.shipment_parcel_type, 'title'),
				});
			} else {
				this.setState({
					loading: true
				}, () => {
					filterErrorHandler(nextProps.dropDown.message);
				})
			}
		}

		if (nextProps.getAreaDropdown !== this.props.getAreaDropdown && nextProps.getAreaDropdown !== undefined) {
			if (nextProps.getAreaDropdown.responseCode === 200 && nextProps.getAreaDropdown.msgType === 'success') {
				this.setState({
					area_options: this.mapOptions(nextProps.getAreaDropdown.data.shipment_area, 'area'),
				});
			} else {
				filterErrorHandler(nextProps.getAreaDropDown.message);
			}
		}

		if (nextProps.store_price_result !== this.props.store_price_result && nextProps.store_price_result !== undefined) {
			if (nextProps.store_price_result.responseCode === 200 && nextProps.store_price_result.msgType === 'success') {
				const { t } = this.props;
				Swal.fire({
					title: t("global.record-saved"),
					icon: nextProps.store_price_result.msgType,
					confirmButtonText: t("global.ok"),
				}).then((result) => {
					if (result.isConfirmed) {
					this.props.refreshHandler();
					this.props.changePageHandler("listing");
					}
				});
			} else {
				this.setState({
					touched: true,
					error_message: nextProps.store_price_result.message,
				});
				Swal.close();
			} 
		}
		return true;
	}

	submitHandler = (e) => {
		e.preventDefault();

		const { t } = this.props;

		Swal.fire({
			title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "",
					allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
				});

				let value_array = [];
				this.state.price_setting.forEach((item, idx) => {
                    let priceData  = {};
					const { shipment_type, state } = this.state;

                    if (shipment_type === 1 && [1, 4].includes(state)) {
						priceData = {
							firstPrice: item.firstPrice,
							nextPrice: item.nextPrice
						};
					} else if (shipment_type === 2 && [2, 3, 4].includes(state)) {
						priceData = {
							firstPrice: item.firstPrice,
							nextPrice: item.nextPrice,
							secondPrice: item.secondPrice,
							nextSecondPrice: item.nextSecondPrice
						};
					} else {
						return;
					}

                    value_array.push(priceData);
                });

				value_array = value_array.filter(item => item !== null);

				let storePriceData = {};
				storePriceData.state = this.state.state;
				storePriceData.courier = this.state.courier;
				storePriceData.transport = this.state.shipment_type;
				storePriceData.parcel_type = this.state.parcel;
				storePriceData.warehouse = this.state.warehouse;
				storePriceData.area = this.state.area;
				storePriceData.country_id = this.props.currentUser.country_id;
				storePriceData.data = value_array;
				
				this.props.postShipment(storePriceData);
			}
		})
	}

	mapOptions = (data, labelField) => {
		return data.map(item => ({
			label: this.props.t(`price-list.${item[labelField]}`),
			value: item.id,
		}));
	};
	
	handleDropdownChange = (name) => (selectedOption) => {
		this.setState({
			[name]: selectedOption.value,
		}, () => {
			const { state, shipment_type } = this.state;
			
			if (shipment_type === 2 && (state === 2 || state === 3)) {
				this.props.getAreaDropDown(state);
			}
		});
	};
	
	addRecord = () => 
	{
		if(this.state.price_setting.length < 20) 
		{
			this.setState((prevState) => ({
				price_setting: [...prevState.price_setting, {listIndex: Math.random(), firstPrice: "", nextPrice: "", secondPrice: "", nextSecondPrice: ""}],
			}));
		}
	};

	removeRecord = (i) => 
	{
		this.setState({
			price_setting: this.state.price_setting.filter((item, idx) => i !== idx)
		})
	}

	handleSettingListChange = (event, key) => 
	{		
		let price_setting = [...this.state.price_setting];
		price_setting[key][event.target.id] = event.target.value;
		this.setState({price_setting});
	}

	render() {
		const { t } = this.props;

		return (
			<Auxiliary>
				<div className="breadcrumb-header">
					<div className="d-flex">
						<div className="my-auto">
							<Button 
								typeButton="button"
								classValue="btn btn-back-outline"
								idValue="back"
								buttonName={<i className="angle fe fe-chevron-left"></i>}
								clicked={() => this.props.changePageHandler("listing")}
							/>
						</div>
					</div>
					<div className="my-auto">
						<div className="d-flex">
							<h4 className="content-title mb-0 my-auto ml-2">
								{t("price-list.shipments_pricelist")}
							</h4>
						</div>
					</div>
				</div>

				<div className="row row-sm">
					<div className="col-12">
						<div className="card box-shadow-0">
							<div className="card-header">
								<h4 className="card-title mb-1">
									{t("price-list.add-shipment-price-setting")}
								</h4>
							</div>
							<div className="card-body pt-0">
								<form
									method="post"
									id="shipment_form"
									className="form-horizontal"
									onSubmit={this.submitHandler}
									noValidate
								>
									<div className="col-md-12">
										<ValidationError
											formName="shipment_form"
											touched={this.state.touched}
											message={this.state.error_message}
										/>
										{this.state.loading === true ? (
											<Skeleton count={4} />
										) : (
											<div className="row">
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="shipment_type">
															{t("price-list.shipment_type")}
														</label>

														<ReactSelect
															name="shipment_type"
															options={this.state.shipment_type_options}
															onChange={this.handleDropdownChange('shipment_type')}
															placeholder={(t("global.select"))}
														/>
													</div>
												</div>
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="courier">
															{t("price-list.courier_name")}
														</label>

														<ReactSelect
															name="courier"
															options={this.state.courier_options}
															onChange={this.handleDropdownChange('courier')}
															placeholder={(t("global.select"))}
														/>
													</div>
												</div>
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="warehouse">
															{t("price-list.warehouse")}
														</label>

														<ReactSelect
															name="warehouse"
															options={this.state.warehouse_options}
															onChange={this.handleDropdownChange('warehouse')}
															placeholder={(t("global.select"))}
														/>
													</div>
												</div>
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="state">
															{t("price-list.state")}
														</label>

														<ReactSelect
															name="state"
															options={this.state.state_options}
															onChange={this.handleDropdownChange('state')}
															placeholder={(t("global.select"))}
														/>
													</div>
												</div>
												{this.state.shipment_type === 2 && (this.state.state === 2 || this.state.state === 3) && 
													<div className="col-md-4 col-12">
														<div className="form-group">
															<label htmlFor="area">
																{t("price-list.area")}
															</label>

															<ReactSelect
																name="area"
																options={this.state.area_options}
																onChange={this.handleDropdownChange('area')}
																placeholder={(t("global.select"))}
															/>
														</div>
													</div>
												}																								
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="parcel-type">
															{t("price-list.parcel_type")}
														</label>

														<ReactSelect
															name="parcel"
															options={this.state.parcel_type_options}
															onChange={this.handleDropdownChange('parcel')}
															placeholder={(t("global.select"))}
														/>
													</div>
												</div>

												{this.state.shipment_type && this.state.state && !(this.state.shipment_type === 2 && this.state.state === 1)  
													&& !(this.state.shipment_type === 1 && (this.state.state === 2 || this.state.state === 3)) && (
													<>
														<div className="col-12 text-right mb-3">
															<button type="button" className="btn btn-info" onClick={(e) => this.addRecord(e)}>
																<i className="fa fa-plus"></i> {t('price-list.add')}
															</button>
														</div>
														<div className="table-responsive" style={{ overflowY: 'auto' }}>
															<table className="table" id="setting_table" style={{ minWidth: 500 }}>
																<thead className="text-center bg-primary">
																	<tr>
																		<th className="text-white p-2">{t("global.no")}</th>
																		{this.state.shipment_type === 1 && (
																			<>
																				<th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
																				<th className="text-white p-2">{t("price-list.next_0_5kg_fee")}</th>
																			</>
																		)}
																		{this.state.shipment_type === 2 && this.state.state === 4 && (
																			<>
																				<th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
																				<th className="text-white p-2">{t("price-list.next_1kg_fee")}</th>
																				<th className="text-white p-2">{t("price-list.first_0_3m³_fee")}</th>
																				<th className="text-white p-2">{t("price-list.next_0_01m³_fee")}</th>
																			</>
																		)}
																		{this.state.shipment_type === 2 && (this.state.state === 2 || this.state.state === 3) && (
																			<>
																				<th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
																				<th className="text-white p-2">{t("price-list.next_1kg_fee")}</th>
																				<th className="text-white p-2">{t("price-list.first_0_5m³_fee")}</th>
																				<th className="text-white p-2">{t("price-list.next_0_01m³_fee")}</th>
																			</>
																		)}
																		<th style={{ width: "5%" }}>&nbsp;</th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.price_setting.map((item, idx) => (
																		<tr key={idx} className="text-center">
																			<td>{idx + 1}</td>
																			{this.state.shipment_type === 1 && (
																				<>
																					<td key={`firstPrice-${idx}`}>
																						<NumberInput
																							id='firstPrice'
																							name='firstPrice'
																							data-id={idx}
																							placeholder={t("price-list.first_kg_fee")}
																							value={item.firstPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`nextPrice-${idx}`}>
																						<NumberInput
																							id='nextPrice'
																							name='nextPrice'
																							data-id={idx}
																							placeholder={t("price-list.next_0_5kg_fee")}
																							value={item.nextPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																				</>
																			)}
																			{this.state.shipment_type === 2 && this.state.state === 4 && (
																				<>
																					<td key={`firstPrice-${idx}`}>
																						<NumberInput
																							id='firstPrice'
																							name='firstPrice'
																							data-id={idx}
																							placeholder={t("price-list.first_kg_fee")}
																							value={item.firstPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`nextPrice-${idx}`}>
																						<NumberInput
																							id='nextPrice'
																							name='nextPrice'
																							data-id={idx}
																							placeholder={t("price-list.next_1kg_fee")}
																							value={item.nextPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`secondPrice-${idx}`}>
																						<NumberInput
																							id='secondPrice'
																							name='secondPrice'
																							data-id={idx}
																							placeholder={t("price-list.first_0_3m³_fee")}
																							value={item.secondPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`nextSecondPrice-${idx}`}>
																						<NumberInput
																							id='nextSecondPrice'
																							name='nextSecondPrice'
																							data-id={idx}
																							placeholder={t("price-list.next_0_01m³_fee")}
																							value={item.nextSecondPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																				</>
																			)}
																			{this.state.shipment_type === 2 && (this.state.state === 2 || this.state.state === 3) && (
																				<>
																					<td key={`firstPrice-${idx}`}>
																						<NumberInput
																							id='firstPrice'
																							name='firstPrice'
																							data-id={idx}
																							placeholder={t("price-list.first_kg_fee")}
																							value={item.firstPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`nextPrice-${idx}`}>
																						<NumberInput
																							id='nextPrice'
																							name='nextPrice'
																							data-id={idx}
																							placeholder={t("price-list.next_1kg_fee")}
																							value={item.nextPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`secondPrice-${idx}`}>
																						<NumberInput
																							id='secondPrice'
																							name='secondPrice'
																							data-id={idx}
																							placeholder={t("price-list.first_0_5m³_fee")}
																							value={item.secondPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																					<td key={`nextSecondPrice-${idx}`}>
																						<NumberInput
																							id='nextSecondPrice'
																							name='nextSecondPrice'
																							data-id={idx}
																							placeholder={t("price-list.next_0_01m³_fee")}
																							value={item.nextSecondPrice}
																							onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
																							onChange={(event) => this.handleSettingListChange(event, idx)}
																							className='form-control'
																							autoComplete="off"
																							required
																						/>
																					</td>
																				</>
																			)}
																			<td>
                                                                            	<i className="fa fa-trash" style={{color:'red', cursor:'pointer'}} onClick={() => this.removeRecord(idx)}></i>
                                                                        	</td>
																		</tr>
																	))}																	
																</tbody>
															</table>
														</div>
													</>
												)}
												<div className="col-12 form-group mb-0 justify-content-end">
													<Button
														typeButton="submit"
														classValue="btn btn-blue"
														idValue="submit"
														buttonName={t("global.save")}
													/>
												</div>
											</div>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Auxiliary>
		)
	}
}

const mapStateToProps = (state) => {
	const { shipment } = state;
  
	return {
		dropDown: shipment.dropDown,
		getAreaDropdown: shipment.area_dropDown,
	  	store_price_result: shipment.post_shipment_result,
		currentUser: state.currentUser.data,
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		getDropDown: () => dispatch(getDropDown()),
		getAreaDropDown: (stateId) => dispatch(getAreaDropDown(stateId)),
	  	postShipment: (data) => dispatch(postShipment(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ShipmentsCreate));
  