import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { postIsAuthorized } from "../../store/ducks/auth/actions";

class Forbidden extends Component {
    state = {
        display: false
    }

    componentDidMount(){
        if(localStorage.getItem("checkForbidden")){
            localStorage.removeItem("checkForbidden");
            this.props.postIsAuthorized();
        }
        else{
            localStorage.setItem("checkForbidden", true);
            this.setState({ display: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.authorized_data!==undefined && nextProps.authorized_data!==this.props.authorized_data){
            if(localStorage.getItem("accessToken")){
                window.location.href = "/";
            }
            else{
                window.location.href = "/login";
            }
        }
        return true;
    }

    render(){
        const { t } = this.props;
        return(
            <>
                {this.state.display && (
                    <div className="container-fluid">
                        <div className="row no-gutter bg-white">
                            <div className="col-12 p-0 login__background">
                                <div className="login d-flex align-items-center">
                                    <div className="container p-0">
                                        <div className="row justify-content-center">
                                            <div className="col-10 col-md-6 bg-white login__container p-0 rounded">
                                                <div className="card-sigin ">
                                                    <div className="card-sigin p-0">
                                                        <div className="main-signup-header">
                                                            <h2>{t("global.forbidden")}</h2>
                                                            <h5 className="font-weight-semibold mb-4">{t("global.forbidden-desc")}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const { auth } = state;
    return {
        authorized_data: auth.authorized_data
    }
}

const mapDispatchToProps = {
    postIsAuthorized
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Forbidden));