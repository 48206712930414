import React, { Component } from "react";
import { connect } from "react-redux";
import { updateRobotsTxt, editRobotsTxt } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";

class RobotsTxtEdit extends Component {
    constructor() {
        super();
        this.state = {
            edit_id: null,
            edit_user_agent: "",
            edit_rule: "Allow",
            edit_directory_path: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRuleChange = this.handleRuleChange.bind(this);
    }

    componentDidMount() {
        this.props.editRobotsTxt(this.props.robotsTxtEditId);
        formValidation("robots_txt_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[RobotsTxtEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.robotsTxtEditData !== this.props.robotsTxtEditData) {
            if (nextProps.robotsTxtEditData.responseCode === 200) {
                let robotData = nextProps.robotsTxtEditData.data.robot[0];

                this.setState({
                    edit_id: robotData.robotsTxtId,
                    edit_user_agent: robotData.userAgent,
                    edit_rule: robotData.rule,
                    edit_directory_path:  robotData.directoryPath,
                    loading: false,
                });
            } else {
                return false;
            }
        }

        if (nextProps.updateRobotsTxtResult !== this.props.updateRobotsTxtResult) {
            if (nextProps.updateRobotsTxtResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateRobotsTxtResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateRobotsTxtResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateRobotsTxtResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateRobotsTxtHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateRobotsTxtData = {};
                updateRobotsTxtData.edit_id = this.state.edit_id;
                updateRobotsTxtData.edit_user_agent = this.state.edit_user_agent;
                updateRobotsTxtData.edit_rule = this.state.edit_rule;
                updateRobotsTxtData.edit_directory_path = this.state.edit_directory_path;
                this.props.updateRobotsTxt(updateRobotsTxtData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleRuleChange = (event) => {
        this.setState({
            rule: String(event.target.value),
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let rule = [
            { label: t("robots-txt.allow"), value: "Allow" },
            { label: t("robots-txt.disallow"), value: "Disallow" },
        ];

        if(!this.state.loading) {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("robots-txt.edit-rule")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="robots_txt_form" className="form-horizontal" onSubmit={this.updateRobotsTxtHandler} noValidate>
                                        <ValidationError formName="robots_txt_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="edit_user_agent">{t("robots-txt.user-agent")}</label>
                                                    <StringInput
                                                        id="edit_user_agent"
                                                        name="edit_user_agent"
                                                        className="form-control"
                                                        placeholder={t("robots-txt.user-agent")}
                                                        value={this.state.edit_user_agent}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="edit_rule">{t("robots-txt.rule")}</label>
                                                    <Select id="edit_rule" name="edit_rule" options={rule} onChange={this.handleRuleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="edit_directory_path">{t("robots-txt.directory-path")}</label>
                                                    <StringInput
                                                        id="edit_directory_path"
                                                        name="edit_directory_path"
                                                        className="form-control"
                                                        placeholder={t("robots-txt.directory-path")}
                                                        value={this.state.edit_directory_path}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-primary" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
            <div className="row row-sm">
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                    <div className="card box-shadow-0">
                        <div className="card-header">
                            <h4 className="card-title mb-1">{t("robots-txt.edit-rule")}</h4>
                        </div>
                        <div className="card-body pt-0">
                            <Skeleton count={4} />
                        </div>
                    </div>
                </div>
            </div>
            );
        }



        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        robotsTxtEditData: state.seo.robots_txt_edit_data,
        updateRobotsTxtResult: state.seo.robots_txt_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editRobotsTxt: (id) => dispatch(editRobotsTxt(id)),
        updateRobotsTxt: (data) => dispatch(updateRobotsTxt(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RobotsTxtEdit));
