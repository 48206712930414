import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Swal from 'sweetalert2';
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import GoldDiggerListing from './GoldDiggerListing';

import * as actions from "../../store/ducks/goldDigger/actions";

const initialInputState =
{
    /* form input value */
    order_id: '',
    username: '',
    status: '',
    date: []
};

class GoldDigger extends Component{
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropdown: {
                gameType: [],
                settingName: [],
                status: []
            },

            /* skeleton loading */
            loading: true
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray(); 
        this.props.getListing(listingArr); 
        this.props.getDropdown(); 
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.rs !== this.props.rs && (nextProps.rs!==undefined && Object.keys(nextProps.rs).length > 0)) {
            if(nextProps.rs.data.responseCode === 200 && nextProps.rs.data.msgType === 'success') {
                this.setState({
                    data: nextProps.rs.data.data.result.row,
                    totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                    loading: false,
                });
            } else {
                const { t } = this.props;
                let msg = null;
                msg = '<ul>';
                nextProps.rs.data.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t("global.error"),
                    html: msg,
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        activePage: 1,
                        data: [],
                        totalRecords: 0,
                        loading: false,
                    });
                });
            }
        }

        if (nextProps.dropdown !== this.props.dropdown && (nextProps.dropdown!==undefined && Object.keys(nextProps.dropdown).length > 0)) {
            const { t } = this.props;
            if(nextProps.dropdown.data.responseCode === 200 && nextProps.dropdown.data.msgType === 'success') {
                let gameTypeDropdown = [];

                if (!!(nextProps.dropdown.data.data.gameType) && nextProps.dropdown.data.data.gameType !== undefined) {
                    nextProps.dropdown.data.data.gameType.map((row) => {
                        gameTypeDropdown.push({
                            label: t('game-type.'+row),
                            value: row
                        });
                        return null;
                    });
                }
                
                let statusDropdown = [];
                statusDropdown.push({
                    label: t('gold_digger.claimed'),
                    value: 'claimed'
                });
                statusDropdown.push({
                    label: t('gold_digger.unclaimed'),
                    value: 'unclaimed'
                });

                this.setState({
                    dropdown: {
                        gameType: gameTypeDropdown,
                        settingName: nextProps.dropdown.data.data.settingName,
                        status: statusDropdown
                    },
                    loading: false,
                });
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange(event) { 
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
    
        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        // console.log(listingArr);
        return listingArr;
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) => {
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        if (field === 'date') field = 'created_at';
        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.getListing(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect to member profile
    redirectHandler = (e, id, name)=> {
        e.preventDefault();
        this.setState({
            pageShow: "redirect",
            redirectParam: {
                id: id,
                name: name
            }
        });
    }

    render() {
        return (
            <Auxiliary>
                <GoldDiggerListing
                    /* filter input */
                    filter={this.state.filter}

                    /* setstate function */
                    changePageHandler={(page) => this.changePageHandler(page)}
                    clearHandler={() => this.clearHandler()}
                    refreshHandler={() => this.refreshHandler()}
                    detailsHandler={(event, id) => this.detailsHandler(event, id)}
                    onInputChange={(event) => this.onInputChange(event)}
                    datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                    onSort={(field, order) => this.onSort(field, order)}
                    redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}
                    exportExcelHandler={() => this.exportExcelHandler()}

                    /* datatables data */
                    data={this.state.data}
                    activePage={this.state.activePage}
                    limit={this.state.limit}
                    totalRecords={this.state.totalRecords}
                    searchValue={this.state.searchValue}

                    /* filter drop down */
                    dropdown={this.state.dropdown}   

                    /* skeleton loading */
                    loading={this.state.loading}
                />
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        rs: state.goldDigger.rs,
        dropdown: state.goldDigger.dropdown,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        getListing: (filter) => dispatch(actions.getListing(filter)),
        getDropdown: () => dispatch(actions.getDropdown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GoldDigger));
