import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/ducks/announcement/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Checkbox from "../../components/Input/Checkbox/Checkbox";
import MultipleCheckbox from "../../components/Input/Checkbox/MultipleCheckbox";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";

class AnnouncementCreate extends Component {
    constructor(props) {
        super(props);
        var dt = new Date();
        //var now = moment().add(2, 'hours').format('yyyy-MM-DD HH:mm');
        this.state = {
            title: "",
            message: {},
            member_group: [],
            status: 1,
            show: 1,
            select_all: false,
            start_time: dt.setHours(dt.getHours() + 2, 0),
            end_time: dt.setHours(dt.getHours() + 3, 0),
            lang_id: [],
            sequence: 1,
            country_id: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextareaChange = this.handleTextareaChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.handleShowChange = this.handleShowChange.bind(this);
        this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.storeAnnouncementHandler = this.storeAnnouncementHandler.bind(this);
    }

    componentDidMount() {
        formValidation("announcement_form");
        this.props.createAnnouncement();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.announcementCreateData !== this.props.announcementCreateData && nextProps.announcementCreateData !== undefined) {
            if (nextProps.announcementCreateData.data.responseCode === 200 && nextProps.announcementCreateData.data.msgType === 'success') {
                if (Object.keys(nextState.message).length === 0) {
                    let languageData = nextProps.announcementCreateData.data.data.language;
                    let memberGroupData = nextProps.announcementCreateData.data.data.memberGroup;
                    let array = [];
                    let object = {};

                    // Languages Message Tab
                    languageData.map((item) => {
                        return array.push("message" + [item.langId]);
                    });
                    for (let i = 0; i < array.length; i++) {
                        object[array[i]] = "";
                    }

                    // Member Group
                    memberGroupData.forEach((element) => {
                        element.isChecked = false;
                    });

                    this.setState({
                        message: object,
                        member_group: memberGroupData,
                        loading: false,
                    });
                }
            } else {
                this.setState({
                    loading: true,
                }, () => {
                    filterErrorHandler(nextProps.announcementCreateData.data.message);
                });
            }
        }

        if (nextProps.announcementStoreResult !== this.props.announcementStoreResult && nextProps.announcementStoreResult !== undefined) {
            if (nextProps.announcementStoreResult.responseCode === 200 && nextProps.announcementStoreResult.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.announcementStoreResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.announcementStoreResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    handleStatusChange = (event) => {
        this.setState({
            status: Number(event.target.value),
        });
    };

    handleSequenceChange = (event) => {
        this.setState({
            sequence: Number(event.target.value),
        });
    };

    handleTextareaChange = (event) => {
        const { message } = this.state;
        message[event.target.name] = event.target.value;
        this.setState({ message });
    };

    handleShowChange = (event) => {
        this.setState({ show: Number(event.target.value) });
        if (Number(event.target.value) === 1) {
            let memberGroup = this.state.member_group;
            memberGroup.forEach((mg) => (mg.isChecked = false));
            this.setState({ member_group: memberGroup });
        }
    };

    // handleMemberGroupChange = (event) => {
    //     let newArray = [...this.state.member_group, Number(event.target.defaultValue)];
    //     if (this.state.member_group.includes(event.target.defaultValue)) {
    //         newArray = newArray.filter((mem) => mem !== event.target.defaultValue);
    //     }
    //     this.setState({ member_group: newArray.sort() });
    // };

    handleAllChecked = (event) => {
        let memberGroup = this.state.member_group;
        memberGroup.forEach((mg) => (mg.isChecked = event.target.checked));
        this.setState({ member_group: memberGroup });
    };

    handleCheckChildElement = (event) => {
        let memberGroup = this.state.member_group;
        memberGroup.forEach((mg) => {
            if (Number(mg.memberGroupId) === Number(event.target.value)) {
                mg.isChecked = event.target.checked;
            }
        });
        this.setState({ member_group: memberGroup });
    };

    storeAnnouncementHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let start_time = moment(this.state.start_time).format("yyyy-MM-DD HH:mm");
                let end_time = moment(this.state.end_time).format("yyyy-MM-DD HH:mm");
                let languageIdArray = Object.keys(this.state.message).map((val) => Number(val.substr(7)));
                let messageArray = Object.values(this.state.message);

                // let memberGroupData = this.state.member_group.filter((item) => {
                //     return item.isChecked === true;
                // }).map((item) => {
                //     return item.memberGroupId;
                // });
                let memberGroupData = this.state.member_group.filter((item) => item.isChecked === true).map((item) => item.memberGroupId);
                let storeAnnouncementData = {};
                storeAnnouncementData.title = this.state.title;
                storeAnnouncementData.lang_id = languageIdArray;
                storeAnnouncementData.message = messageArray;
                storeAnnouncementData.start_time = start_time;
                storeAnnouncementData.end_time = end_time;
                storeAnnouncementData.status = this.state.status;
                storeAnnouncementData.show = this.state.show;
                storeAnnouncementData.sequence = this.state.sequence;
                storeAnnouncementData.member_group = memberGroupData;
                storeAnnouncementData.country_id = 1;
                // console.log("Form Submit:", storeAnnouncementData);
                this.props.storeAnnouncement(storeAnnouncementData);
            }
        });
    };
    /* ======================== Function [END] ======================== */

    render() {
        const { t } = this.props;

        let memberGroup = null;
        let languages = null;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = null;

        let status = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];
        let show = [
            { label: t("announcement.news"), value: 1 },
            { label: t("announcement.member"), value: 2 },
        ];

        if (!this.state.loading) {
            languages = this.props.announcementCreateData.data.data.language;
            tabTitleMessage = (languages) ? languages.map((language) => language.lang) : [];            
            tabContentMessage = Object.keys(this.state.message).map((item, key) => (
                <div key={key}>
                    <label htmlFor="message">{t("announcement.message")}</label>
                    <textarea name={`${item}`} className="form-control" placeholder="Message" value={this.state.message[`${item}`]} onChange={this.handleTextareaChange} rows={5} />
                </div>
            ));
            // console.log(tabContentMessage, tabContentMessage);
            if (tabTitleMessage.length && tabContentMessage.length) {
                tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border"/>;
            } else {
                tabMessage = null;
            }

            memberGroup = this.state.member_group.map((val, idx) => (
                <label className="checkbox-inline mr-1" key={idx}>
                    <MultipleCheckbox
                        id={val.memberGroupId}
                        name={val.name}
                        value={val.memberGroupId}
                        label={val.name}
                        isChecked={val.isChecked}
                        handleCheckChildElement={this.handleCheckChildElement}
                    />
                </label>
            ));
            // console.log("member_group", this.state.member_group);
            // console.log("MemberGroup",memberGroup);
        }
        // console.log("[AnnouncementCreate] Render() Display",display);
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("announcement.announcement")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("announcement.add-announcement")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <form method="post" id="announcement_form" name="announcement_form" className="form-horizontal" onSubmit={this.storeAnnouncementHandler} noValidate>
                                    <ValidationError formName="announcement_form" touched={this.state.touched} message={this.state.error_message} />
                                    {this.state.loading === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>{t("announcement.title")} *</label>
                                                        <StringInput
                                                            id="title"
                                                            name="title"
                                                            className="form-control"
                                                            placeholder={t("announcement.title")}
                                                            value={this.state.title}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">{tabMessage}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t("announcement.start-time")}</label>
                                                        <DatePicker
                                                            name="start_time"
                                                            className="form-control"
                                                            wrapperClassName="wrapperDatePicker"
                                                            selected={this.state.start_time}
                                                            onChange={this.handleStartTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption={t("global.time")}
                                                            popperContainer={DatePickerContainer}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t("announcement.end-time")}</label>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            wrapperClassName="wrapperDatePicker"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption={t("global.time")}
                                                            popperContainer={DatePickerContainer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="from-group">
                                                        <label>{t("announcement.show")}</label>
                                                    </div>
                                                    <Select id="show" name="show" options={show} onChange={this.handleShowChange} className="form-control" />
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="from-group">
                                                        <label>{t("global.status")}</label>
                                                        <Select id="status" name="status" options={status} onChange={this.handleStatusChange} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="from-group">
                                                        <label>{t("announcement.sequence")}</label>
                                                        <NumberInput
                                                            id="sequence"
                                                            name="sequence"
                                                            className="form-control"
                                                            value={this.state.sequence}
                                                            onChange={this.handleSequenceChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.show === 2 && (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="member_group">{t("announcement.member-group")}</label>
                                                            <div className="form-group">
                                                                <label className="checkbox-inline mr-1">
                                                                    {/* <Checkbox
                                                                        onChange={this.handleAllChecked}
                                                                        name="select_all"
                                                                        id="select_all"
                                                                        defaultValue={this.state.select_all}
                                                                    />{" "}
                                                                    {t("global.select-all")} */}
                                                                    <Checkbox
                                                                        onChange={this.handleAllChecked}
                                                                        name="select_all"
                                                                        id="select_all"
                                                                        defaultValue={this.state.select_all}
                                                                        label={t("global.select-all")}
                                                                    />
                                                                </label>
                                                                {memberGroup}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="form-group mb-0 mt-3 justify-content-end">
                                                <div>
                                                    <Button
                                                        typeButton="submit"
                                                        classValue="btn btn-blue"
                                                        idValue="submit"
                                                        buttonName={t("global.save")}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("Test",state.announcement.announcement_create_data);
    const { announcement } = state;

    return {
        announcementCreateData: announcement.announcement_create_data,
        announcementStoreResult: announcement.announcement_store_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAnnouncement: () => dispatch(actions.createAnnouncement()),
        storeAnnouncement: (storeAnnouncementData) => dispatch(actions.storeAnnouncement(storeAnnouncementData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AnnouncementCreate));
