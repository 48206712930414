import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { postMemberGroup } from "../../store/ducks/memberGroup/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class MemberGroupCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member_group_name: "",
            privilege: 0,
            level: 0,
            min_payment: 0,
            max_payment: 0,
            next_level_amount: 0,
            up_level_rebate_percentage: 0,
            status: 1,
            currentCountryId: this.props.currentCountryId,
            loading: true,
            error_message: [],
            touched: false,

            tabId: "add_member_group",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        formValidation("member_group_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_member_group_result !== this.props.post_member_group_result) {
            if (nextProps.post_member_group_result.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_member_group_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.post_member_group_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_member_group_result.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_member_group_result.message[0],
                    icon: nextProps.post_member_group_result.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    handleChange = (event) => {
        let name = event.target.name;
        switch (name) {
            case "privilege":
            case "level":
            case "min_payment":
            case "max_payment":
            case "up_level_rebate_percentage":
            if (event.target.value.match(/^\d+(\.\d+)?$/)) {
                if (event.target.value.charAt(0) === "0" || event.target.value.charAt(0) === ".") {
                    if (event.target.value.charAt(1) === ".") {
                        this.setState({ [event.target.name]: event.target.value });
                    } else if (event.target.value.charAt(0) === "0") {
                    if (event.target.value.substring(1) !== "") {
                        this.setState({ [event.target.name]: event.target.value.substring(1) });
                    } else {
                        this.setState({ [event.target.name]: 0.0 });
                    }
                    } else {
                        this.setState({ [event.target.name]: event.target.value });
                    }
                    } else {
                        this.setState({ [event.target.name]: event.target.value });
                    }
                } else {
                    this.setState({ [event.target.name]: 0.0 });
                }
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
        }
    };

    storeMemberGroupHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};

                data.group_name = this.state.member_group_name;
                data.privilege = this.state.privilege;
                data.level = this.state.level;
                data.status = this.state.status;

                data.min_payment = this.state.min_payment;
                data.max_payment = this.state.max_payment;

                // data.min_withdrawal = this.state.min_withdrawal;
                // data.max_daily_withdrawal = this.state.max_daily_withdrawal;
                // data.daily_withdrawal_counts = this.state.daily_withdrawal_counts;
                // data.max_withdrawal = this.state.max_withdrawal;

                // data.min_turnover = this.state.min_turnover;
                // data.max_turnover = this.state.max_turnover;
                data.up_level_rebate_percentage = this.state.up_level_rebate_percentage;
                // data.up_level_bonus = this.state.up_level_bonus;
                // data.monthly_bonus = this.state.monthly_bonus;
                // data.birthday_bonus = this.state.birthday_bonus;
                // data.birthday_bonus_turnover_multiply = this.state.birthday_bonus_turnover_multiply;
                // data.reward_point = this.state.reward_point;
                // data.deposit_amount_per_point = this.state.deposit_amount_per_point;
                // data.designed_account_manager = this.state.designed_account_manager;
                // data.daily_withdrawal_limitation = this.state.daily_withdrawal_limitation;
                // data.special_birthday_gift = this.state.special_birthday_gift;
                // data.turnover_per_point = this.state.turnover_per_point;

                // data.sport_rebate = this.state.sport_rebate;
                // data.live_casino_rebate = this.state.live_casino_rebate;
                // data.slot_rebate = this.state.slot_rebate;
                // data.virtual_rebate = this.state.virtual_rebate;

                // data.maintain_period = this.state.maintain_period;
                // data.deposit_tier_maintain = this.state.deposit_tier_maintain;
                // data.turnover_tier_maintain = this.state.turnover_tier_maintain;
                // data.next_level_amount = this.state.next_level_amount;
                // data.maintain_level_amount = this.state.maintain_level_amount;

                // data.min_deposit_tier_upgrade = this.state.min_deposit_tier_upgrade;
                // data.min_turnover_tier_upgrade = this.state.min_turnover_tier_upgrade;

                data.created_by = this.props.currentUsername;

                // data.weekly_bonus = this.state.weekly_bonus;
                // data.turnover_weekly_target = this.state.turnover_weekly_target;
                // data.turnover_weekly_multiplier = this.state.turnover_weekly_multiplier;
                data.country_id = this.props.currentCountryId;
                this.props.postMemberGroup(data);
            }
        });
    };

    changeTabHandler = (tab) => {
        this.setState({
            tabId: tab,
        });
    };

    render() {
        const { t } = this.props;

        let statusArry = [
            { label: t("global.active"), value: 1, selected: true },
            { label: t("global.inactive"), value: 0 },
        ];

        // let maintainPeriodArry = [
        //     { label: t("member-group.lifetime"), value: 0, selected: true },
        //     { label: t("member-group.3-months"), value: 3 },
        //     { label: t("member-group.private-invitation"), value: 999 },
        // ];

        // let designedAccountManagerArry = [
        //     {
        //         label: t("global._no"),
        //         value: 0,
        //     },
        //     {
        //         label: t("global._yes"),
        //         value: 1,
        //     },
        // ];

        // let specialBirthdayGiftArry = [
        //     {
        //         label: t("global._no"),
        //         value: 0,
        //     },
        //     {
        //         label: t("global._yes"),
        //         value: 1,
        //     },
        // ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-primary tabs-style-2">
                        <div className="tab-menu-heading">
                            <div className="tabs-menu1">
                                <ul className="nav panel-tabs main-nav-line">
                                    <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("add_member_group")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "add_member_group" ? "active" : ""}`}
                                        >
                                            {t("member-group.add-member-group")}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("requirements")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "requirements" ? "active" : ""}`}
                                        >
                                          {t("member-group.requirement")}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("bonus_rebate")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "bonus_rebate" ? "active" : ""}`}
                                        >
                                            {t("member-group.bonus")}
                                        </button>
                                    </li>
                                    {/* <li>
                                        <button
                                            id="member_referral_payout"
                                            onClick={() => this.changeTabHandler("requirements")}
                                            style={{ borderTopWidth: 0 }}
                                            className={`nav-link bg-white ${this.state.tabId === "requirements" ? "active" : ""}`}
                                        >
                                            {t("member-group.requirement")}
                                        </button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <form method="post" id="member_group_form" className="form-horizontal" onSubmit={this.storeMemberGroupHandler} noValidate>
                            <ValidationError formName="member_group_form" touched={this.state.touched} message={this.state.error_message} />
                            <div className="row">
                                <div className="card card-body col-md-12">
                                    {this.state.tabId === "add_member_group" ? (
                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>{t("member-group.add-member-group")}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="form-row mt-3">
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="member_group_name">
                                                                    {t("member-group.names")}
                                                                </label>
                                                                <StringInput
                                                                    onChange={this.handleChange}
                                                                    id="member_group_name"
                                                                    name="member_group_name"
                                                                    className="form-control col-6"
                                                                    placeholder={t("member-group.names")}
                                                                    value={this.state.member_group_name}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="level">
                                                                    {t("member-group.lv")}
                                                                </label>
                                                                <NumberInput 
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="level"
                                                                    id="level"
                                                                    placeholder={t("member-group.lv")}
                                                                    value={this.state.level}
                                                                    required
                                                                    min={1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="privilege">
                                                                    {t("member-group.privilege")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="privilege"
                                                                    id="privilege"
                                                                    placeholder={t("member-group.privilege")}
                                                                    value={this.state.privilege}
                                                                    required
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6">{t("global.status")}</label>
                                                                <Select
                                                                    id="status"
                                                                    name="status"
                                                                    containerClass="col-6 p-0"
                                                                    className="form-control"
                                                                    options={statusArry}
                                                                    onChange={this.getStatusHandler}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    ) : this.state.tabId === "deposit_withdrawal" ? (
                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>{t("member-group.deposit")}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="min_deposit">
                                                                    {t("member-group.min-deposit")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="min_deposit"
                                                                    id="min_deposit"
                                                                    placeholder={t("member-group.min-deposit")}
                                                                    value={this.state.min_deposit}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="max_deposit">
                                                                    {t("member-group.max-deposit")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="max_deposit"
                                                                    id="max_deposit"
                                                                    placeholder={t("member-group.max-deposit")}
                                                                    value={this.state.max_deposit}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>{t("member-group.withdrawal-title")}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="min_withdrawal">
                                                                    {t("member-group.min-withdrawal")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="min_withdrawal"
                                                                    id="min_withdrawal"
                                                                    placeholder={t("member-group.min-withdrawal")}
                                                                    value={this.state.min_withdrawal}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="daily_withdrawal_limitation">
                                                                    {t("member-group.daily-withdrawal-limitation")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="daily_withdrawal_limitation"
                                                                    id="daily_withdrawal_limitation"
                                                                    placeholder={t("member-group.daily-withdrawal-limitation")}
                                                                    value={this.state.daily_withdrawal_limitation}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            {/* <label htmlFor="max_daily_withdrawal">{t("member-group.max-daily-withdrawal")}</label> */}
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="max_daily_withdrawal">
                                                                    {t("member-group.daily-withdrawal-limitation")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="max_daily_withdrawal"
                                                                    id="max_daily_withdrawal"
                                                                    placeholder={t("member-group.max-daily-withdrawal")}
                                                                    value={this.state.max_daily_withdrawal}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="daily_withdrawal_counts">
                                                                    {t("member-group.daily-withdrawal-counts")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="daily_withdrawal_counts"
                                                                    id="daily_withdrawal_counts"
                                                                    placeholder={t("member-group.daily-withdrawal-counts")}
                                                                    value={this.state.daily_withdrawal_counts}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    ) : this.state.tabId === "bonus_rebate" ? (
                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>{t("member-group.bonus")}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="up_level_rebate_percentage">
                                                                    {t("member-group.up-level-rebate-percentage")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="up_level_rebate_percentage"
                                                                    id="up_level_rebate_percentage"
                                                                    placeholder={t("member-group.up-level-rebate-percentage")}
                                                                    value={this.state.up_level_rebate_percentage}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    ) : this.state.tabId === "requirements" ? (
                                        <Accordion allowZeroExpanded="true" className="global_accordion">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>{t("member-group.upgrade-tier-requirement")}</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="min_payment">
                                                                    {t("member-group.min-shipment-payment")}
                                                                </label>
                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="min_payment"
                                                                    id="min_payment"
                                                                    placeholder={t("member-group.min-shipment-payment")}
                                                                    value={this.state.min_payment}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <div className="row">
                                                                <label className="col-6" htmlFor="max_payment">
                                                                    {t("member-group.max-shipment-payment")}
                                                                </label>

                                                                <NumberInput
                                                                    className="col-6"
                                                                    onChange={this.handleChange}
                                                                    name="max_payment"
                                                                    id="max_payment"
                                                                    placeholder={t("member-group.max-shipment-payment")}
                                                                    value={this.state.max_payment}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    ) : (
                                        <></>
                                    )}

                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="min-turnover">
                                                            {t('member-group.min-turnover')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getMinTurnoverHandler}
                                                            name="min-turnover"
                                                            id="min-turnover"
                                                            placeholder={t('member-group.min-turnover')}
                                                            value={this.state.min_turnover}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="max-turnover">
                                                            {t('member-group.max-turnover')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getMaxTurnoverHandler}
                                                            name="max-turnover"
                                                            id="max-turnover"
                                                            placeholder={t('member-group.max-turnover')}
                                                            value={this.state.max_turnover}
                                                        />

                                                    </div>

                                                </div> */}

                                    {/* <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="next-level-amount">
                                                            {t('member-group.next-level-amount')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getNextLevelAmountHandler}
                                                            name="next-level-amount"
                                                            id="next-level-amount"
                                                            placeholder={t('member-group.next-level-amount')}
                                                            value={this.state.next_level_amount}
                                                        />

                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="maintain-level-amount">
                                                            {t('member-group.maint-level-amount')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getMaintainLevelAmountHandler}
                                                            name="maintain-level-amount"
                                                            id="maintain-level-amount"
                                                            placeholder={t('member-group.maint-level-amount')}
                                                            value={this.state.maintain_level_amount}
                                                        />

                                                    </div>
                                                </div> */}

                                    {/* <div className="form-row"> */}
                                    {/* <div className="form-group col-md-3">
                                                        <label htmlFor="vip-monthly-bonus">
                                                            {t('member-group.vip-monthly-bonus')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getMonthlyBonusHandler}
                                                            name="monthly-bonus"
                                                            id="monthly-bonus"
                                                            placeholder={t('member-group.monthly-bonus')}
                                                            value={this.state.monthly_bonus}
                                                        />
                                                    </div> */}

                                    {/* </div> */}

                                    {/* <div className="form-row">
                                                <div className="form-group col-md-4">
                                                        <label htmlFor="reward-point">
                                                            {t('member-group.reward-point')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getRewardPointHandler}
                                                            name="reward-point"
                                                            id="reward-point"
                                                            placeholder={t('member-group.reward-point')}
                                                            value={this.state.reward_point}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="deposit-amount-per-point">
                                                            {t('member-group.deposit-amount-point')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getDepositAmountPerPointHandler}
                                                            name="deposit-amount-per-point"
                                                            id="deposit-amount-per-point"
                                                            placeholder={t('member-group.deposit-amount-point')}
                                                            value={this.state.deposit_amount_per_point}
                                                        />

                                                    </div>
                                            </div> */}

                                    {/* <div className="form-row">
                                                 <div className="form-group col-md-4">
                                                        <label htmlFor="reward-point">
                                                            {t('member-group.designed-account-manager')}
                                                        </label>

                                                        <Select
                                                            options={designedAccountManagerArry}
                                                            className="form-control"
                                                            id="designed-account-manager"
                                                            name="designed-account-manager"
                                                            onChange={this.getDesignedAccountManagerHandler}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="max-daily-withdrawal">
                                                            {t('member-group.special-birthday-gift')}
                                                        </label>

                                                        <Select
                                                            options={specialBirthdayGiftArry}
                                                            className="form-control"
                                                            id="special-birthday-gift"
                                                            name="special-birthday-gift"
                                                            onChange={this.getSpecialBirthdayGift}
                                                        />
                                                    </div>

                                                <div className="form-group col-md-4">
                                                        <label htmlFor="turnover-per-point">
                                                            {t('member-group.turnover-per-point')}
                                                        </label>

                                                        <NumberInput
                                                            onChange={this.getTurnoverPerPoint}
                                                            name="turnover-per-point"
                                                            id="turnover-per-point"
                                                            placeholder={t('member-group.turnover-per-point')}
                                                            value={this.state.turnover_per_point}
                                                        />

                                                    </div>
                                            </div> */}
                                </div>
                                <div className="form-group mx-auto">
                                    <div>
                                        <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { memberGroup, currentUser } = state;

    //Getting the state from the reducers
    return {
        post_member_group_result: memberGroup.post_member_group_result,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username,
        currentGroupId: currentUser.data.group_id,
    };
};

const mapDispatchToProps = {
    postMemberGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberGroupCreate));
