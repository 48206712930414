import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import Button from "../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/Datatable/DataTable";
import StringInput from "../../components/Input/StringInput/StringInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class InboxListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            showCard: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-1`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("inbox.title")}
                                                </label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("inbox.category")}
                                                </label>
                                                <div className='select-input'>
                                                    <select
                                                        className="form-control filter-input"
                                                        id="category"
                                                        name="category"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.category}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddCategory &&
                                                            this.props.ddCategory.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={idx}
                                                                            value={val.categories_id}
                                                                        >
                                                                            {t(`inbox-type.${val.title}`)}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col mt-auto">
                                            <div className='d-flex'>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {
                                    this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t('inbox.inbox')}</h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            <AiFillPlusCircle />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-underline"
                                                idValue="btnCreateInbox"
                                                buttonName={t('inbox.add-inbox')}
                                                clicked={() => this.props.changePageHandler('create')} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">
                                <div className="">

                                    {/* <button id="button" className="btn btn-danger mg-b-20 btn-multi-delete">
                                        {t("global.multi-delete")}
                                    </button> */}

                                    <DataTable
                                        keyField="Id"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={this.props.pageChangeHandler}
                                        sorted={this.props.sorted}
                                        sizePerPageHandler={this.props.sizePerPageHandler}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchValue={this.props.searchValue}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Auxiliary>
        )
    }
}

export default withNamespaces("translation")(InboxListing);