import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import Button from "../../../components/Button/Button";
// import NumberInput from "../../../components/Input/NumberInput/NumberInput";
// import StringInput from "../../../components/Input/StringInput/StringInput";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../../components/Datatable/DataTable";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import Excel from "../../../assets/images/excel.svg";
import UpdownArrow from '../../../assets/images/updown-arrow.png'
// import Select from "../../../components/Input/Select/Select";

class YearlyVendorStatusReportListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(this.props)
        return true;
    }
    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="row row-sm">

                <div className="col-xl-12">
                    <div className="card card-body card-primary">
                        {this.props.loading ? (
                            <Skeleton count={2} />
                        ) : (
                            <form method="post" id="filter_member_referral_form" className={`form-horizontal filter-function row
                                ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                {/* <div className="row"> */}
                                <div className="col-md-4 col-6">
                                    {/* <div className="form-group">
                                            <label htmlFor="year" className="font-weight-bolder">
                                                {t("report.year")}
                                            </label>
                                            <NumberInput
                                                id="year"
                                                name="year"
                                                value={this.props.filter.year}
                                                onChange={this.props.onInputChange}
                                                className="form-control filter-input"
                                                autoComplete="off"
                                                onKeyPress={(evt) => (evt.key === "e" && evt.preventDefault()) || (evt.key === "Enter" && this.props.refreshHandler())}
                                            />
                                        </div> */}

                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("report.year")}
                                        </label>
                                        <div className='select-input'>
                                            <select
                                                className="form-control filter-input"
                                                id="year"
                                                name="year"
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.year}
                                            >
                                                <option value=""></option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                            </select>
                                            <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                        </div>

                                    </div>

                                </div>

                                <div className="col mt-auto">
                                    {this.props.loading ? (
                                        <Skeleton count={2} />
                                    ) : (
                                        <div className='d-flex'>
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                            {(() => {
                                                if (this.props.totalRecords > 0) {
                                                    return (
                                                        <Button
                                                            typeButton="button"
                                                            classValue="btn btn-blue mr-2"
                                                            clicked={() => this.props.exportExcelHandler()}
                                                            img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                            buttonName={t("report.export-excel")}
                                                        />
                                                    );
                                                }
                                            })()}
                                        </div>
                                    )}
                                </div>
                            </form>
                        )}
                        <div className="show-btn" onClick={() => this.toggleCard()}>
                            {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                        </div>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card">




                        {/* <div className="card-header pb-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="card-title mg-b-0">{t('report.yearly-vendor-win-lose-report')}</h4>
                                <div className="row float-right">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue mr-2"
                                        clicked={() => this.props.exportExcelHandler()}
                                        buttonName={t("report.export-excel")}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="card-body">
                            <div className="">

                                <DataTable
                                    keyField="id"
                                    data={this.props.data}
                                    columns={this.props.columns}
                                    activePage={this.props.activePage}
                                    limit={this.props.limit}
                                    totalItems={this.props.totalRecords}
                                    paginationHandler={this.props.pageChangeHandler}
                                    sorted={this.props.sorted}
                                    sizePerPageHandler={this.props.sizePerPageHandler}
                                    searchValue={this.props.searchValue}
                                    searchSubmitHandler={this.props.postSearchHandler}
                                    searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                    loading={this.props.loading}
                                    classValue="dataTable table-bordered text-justify text-center"
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withNamespaces("translation")(YearlyVendorStatusReportListing);