import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { withNamespaces } from "react-i18next";

import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import DatePicker from "react-datepicker";
import { getCountry } from "../../store/ducks/home/actions";
import { create } from "../../store/ducks/leaderboard/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";

//Initialize the language array first
let langArr = [];

class LeaderboardCreate extends Component {
    state = {
        // title: [],
        game_vendor: [],
        min_win: [],
        prize1: 33333,
        prize2: 23333,
        prize3: 13333,
        prize4: 9333,
        prize5: 7333,
        prize6: 5333,
        prize7: 3333,
        prize8: 1333,
        prize9: 933,
        prize10: 533,
        prize11_20: 133,
        prize21_30: 33,
        start_time: "",
        end_time: "",
        currentStatus: 1,
        loading: true,
        error_message: [],
        touched: false,
        show: false,
    };

    componentDidMount() {
        formValidation("leaderboard_form");
        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log('shouldComponentUpdate :', nextProps);

        if (nextProps.leaderboard_create !== this.props.leaderboard_create) {
            if (nextProps.leaderboard_create.responseCode === 200) {
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.leaderboard_create.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.leaderboard_create.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.leaderboard_create.message[0],
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: nextProps.leaderboard_create.message[0],
                    icon: nextProps.leaderboard_create.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        langId: data[item].languageId,
                        langCode: data[item].languageCode,
                        langName: data[item].languageName,
                    });
                });
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message[0],
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    createHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let formData = new FormData();

                formData.append("start_date", moment(this.state.start_time).format("yyyy-MM-DD 00:00:00"));
                formData.append("end_date", moment(this.state.end_time).format("yyyy-MM-DD 23:59:59"));
                formData.append("status", this.state.currentStatus);
                formData.append("game_vendor_id", this.state.game_vendor);
                formData.append("min_win", this.state.min_win);
                formData.append("prize1", this.state.prize1);
                formData.append("prize2", this.state.prize2);
                formData.append("prize3", this.state.prize3);
                formData.append("prize4", this.state.prize4);
                formData.append("prize5", this.state.prize5);
                formData.append("prize6", this.state.prize6);
                formData.append("prize7", this.state.prize7);
                formData.append("prize8", this.state.prize8);
                formData.append("prize9", this.state.prize9);
                formData.append("prize10", this.state.prize10);
                formData.append("prize11_20", this.state.prize11_20);
                formData.append("prize21_30", this.state.prize21_30);
                formData.append("country_id", this.props.currentCountryId);

                this.props.create(formData);
            }
        });
    };

    minWinHandler = (event) => {
        this.setState({ min_win: event.target.value });
    };

    prizeHandler = (event) => {
        const { id, value } = event.target;

        this.setState({ [id]: value });
    };

    gameVendorHandler = (event) => {
        this.setState({ game_vendor: event.target.value });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    statusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    };

    render() {
        const { t } = this.props;

        let gameVendorsOptions = [{
            label: "--- Select Game Vendor ---",
            value: "",
            selected: true,
        }];

        this.props.gameVendors.map((item) => {
            return gameVendorsOptions.push({
                label: item.title,
                value: item.id,
            });
        });

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="btn-group">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">Leaderboard</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">Add Leaderboard</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="leaderboard_form" className="form-horizontal" onSubmit={this.createHandler} noValidate>
                                        <ValidationError formName="leaderboard_form" touched={this.state.touched} message={this.state.error_message} />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.start-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                // showTimeSelect
                                                                dateFormat="yyyy-MM-dd"
                                                                // dateFormat="yyyy-MM-dd HH:mm"
                                                                // timeFormat="HH:mm"
                                                                // timeIntervals={15}
                                                                // timeCaption="time"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.end-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                            <DatePicker
                                                                name="end_time"
                                                                className="form-control"
                                                                selected={this.state.end_time}
                                                                onChange={this.handleEndTimeChange}
                                                                minDate={new Date()}
                                                                // showTimeSelect
                                                                dateFormat="yyyy-MM-dd"
                                                                // dateFormat="yyyy-MM-dd HH:mm"
                                                                // timeFormat="HH:mm"
                                                                // timeIntervals={15}
                                                                // timeCaption="time"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Game Vendor</label>
                                                    <Select
                                                        id="game_vendor"
                                                        name="game_vendor"
                                                        className="form-control"
                                                        options={gameVendorsOptions}
                                                        onChange={this.gameVendorHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Min Win</label>
                                                    <NumberInput
                                                        onChange={(event) => this.minWinHandler(event)}
                                                        id="min_win"
                                                        name="min_win"
                                                        value={this.state.min_win}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <h3 className="card-title col-12">Prizes</h3>

                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>1st</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize1"
                                                        name="prize1"
                                                        value={this.state.prize1}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>2nd</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize2"
                                                        name="prize2"
                                                        value={this.state.prize2}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>3rd</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize3"
                                                        name="prize3"
                                                        value={this.state.prize3}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>4th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize4"
                                                        name="prize4"
                                                        value={this.state.prize4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>5th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize5"
                                                        name="prize5"
                                                        value={this.state.prize5}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>6th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize6"
                                                        name="prize6"
                                                        value={this.state.prize6}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>7th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize7"
                                                        name="prize7"
                                                        value={this.state.prize7}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>8th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize8"
                                                        name="prize8"
                                                        value={this.state.prize8}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>9th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize9"
                                                        name="prize9"
                                                        value={this.state.prize9}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label>10th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize10"
                                                        name="prize10"
                                                        value={this.state.prize10}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>11th ~ 20th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize11_20"
                                                        name="prize11_20"
                                                        value={this.state.prize11_20}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>21th ~ 30th</label>
                                                    <NumberInput
                                                        onChange={(event) => this.prizeHandler(event)}
                                                        id="prize21_30"
                                                        name="prize21_30"
                                                        value={this.state.prize21_30}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button id="submit" classValue="btn btn-blue" typeButton="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, leaderboard, currentUser } = state;

    return {
        country_data: home.country_data,
        leaderboard_create: leaderboard.create,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    getCountry,
    create,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(LeaderboardCreate));
