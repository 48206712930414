import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const referralSettingsReducer = createReducer(initialState) ({
    [type.REFERRAL_SETTINGS_LIST]: (state, action) => ({
        ...state,
        referral_settings_data: action.payload
    }),

    [type.REFERRAL_SETTINGS_EDIT]: (state, action) => ({
        ...state,
        referral_settings_edit_data: action.payload,
    }),

    [type.REFERRAL_SETTINGS_UPDATE]: (state, action) => ({
        ...state,
        referral_settings_update_result: action.payload,
    }),
});

export default referralSettingsReducer;