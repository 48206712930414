import axios from "../../../common/config/axios";
import * as types from "./types";

export const getEventLeagueList = (data) => (dispatch) => {
    axios.get("event_league/list", {params: data})
    .then((res) => {
        dispatch({
            type: types.EVENT_LEAGUE_LISTING,
            payload: res
        })
    })
    .catch((err) => {

    })
};

export const createEventLeague = (data) => (dispatch) => {
    axios.post("event_league/create", data)
    .then((res) => {
        dispatch({
            type: types.EVENT_LEAGUE_CREATE,
            payload: res
        })
    })
    .catch((err) => {

    })
};

export const showEventLeague = (data) => (dispatch) => {
    axios.get("event_league/show", {params: data})
    .then((res) => {
        dispatch({
            type: types.EVENT_LEAGUE_SHOW,
            payload:  res
        })
    })
    .catch((err) => {
 
    })
};

export const updateEventLeague = (data) => (dispatch) => {
    axios.post("event_league/update", data)
    .then((res) => {
        dispatch({
            type: types.EVENT_LEAGUE_UPDATE,
            payload: res
        })
    })
    .catch((err) => {

    })
};

export const getCategoryDropdown = () => (dispatch) => {
    axios.get("event_league/category_drop_down")
    .then((res) => {
        dispatch({
            type: types.EVENT_CATEGORY_DROPDOWN,
            payload: res
        })
    })
    .catch((err) => {

    })
};

export const getTeamsDropdown = (data) => (dispatch) => {
    axios.get("event_league/team_drop_down", {params: data})
    .then((res) => {
        dispatch({
            type: types.EVENT_TEAMS_DROPDOWN,
            payload: res
        })
    })
    .catch((err) => {

    })
};