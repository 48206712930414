import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faScroll } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";

import BanksAccountListing from "./BanksAccountListing";
import BanksAccountCreate from "./BanksAccountCreate";
import BanksAccountShow from "./BanksAccountShow";
import BanksAccountEdit from "./BanksAccountEdit";
import BanksAccountLedger from "./BanksAccountLedger";

import { getBanksAccount, getDropDown, updateBankAccountStatus, deleteBankAccount, updateBankAccountMaintenanceStatus } from "../../store/ducks/banksAccount/actions";

import Select from "../../components/Input/Select/Select";
import { filterErrorHandler } from "../../hoc/Shared/utility";

const initialInputState = {
    /* form input value */
    bankName: "",
    bankAccName: "",
    memberGroupName: "",
    status: "",
};

let bankAccountListingData = {};

class BanksAccount extends Component {
    state = {
        pageShow: "listing",

        activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        totalRecords: 0,

        data: [],

        dropDown: [],

        filter: initialInputState,

        currentBankAccountId: "",
        currentBankSwiftCode: "",
        loading: true,

        permission: [],
    };

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        bankAccountListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        bankAccountListingData.limit = this.state.limit;
        bankAccountListingData.order = JSON.stringify(this.state.sorted);
        bankAccountListingData.country_id = this.props.currentCountryId;
        bankAccountListingData.bankName = this.state.filter.bankName;
        bankAccountListingData.bankAccName = this.state.filter.bankAccName;
        bankAccountListingData.memberGroupName = this.state.filter.memberGroupName;
        bankAccountListingData.status = this.state.filter.status;

        this.props.getBanksAccount(bankAccountListingData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let bankAccountPermission = this.props.currentPermission.filter((item) => item.includes("bank_account_"));
            this.setState({ permission: bankAccountPermission });
        }

        if (nextProps.bank_account !== this.props.bank_account) {
            if (nextProps.bank_account.data.responseCode === 200) {
                nextProps.bank_account.data.data.forEach(function(element, key) {
                    if (element.activeDay) {
                        const { t } = this.props;
                        let activeDay = [];
                        element.activeDay.forEach(function(element) {
                            if (activeDay.length > 0) {
                                activeDay.push(', ');
                            }
    
                            activeDay.push(t('bank-account.'+element+'-short'));
                        });
                        
                        nextProps.bank_account.data.data[key].activeDay = activeDay;
                    }
                }, this);
                
                this.setState({
                    loading: false,
                    data: nextProps.bank_account.data.data,
                    totalRecords: nextProps.bank_account.data.totalRecords,
                    totalPages: nextProps.bank_account.data.totalPages,
                });
            } else {
                this.setState({ 
                    loading: false,
                    data: [],
                    totalRecords: 0,
                    totalPages: 0
                }, () => {
                    filterErrorHandler(nextProps.bank_account.data.message);
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown) {
            if (nextProps.dropDown.responseCode === 200) {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                });
            } else {
                this.setState({ loading: false }, () => {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }

        // if (nextProps.delete_bank_account_result !== this.props.delete_bank_account_result) {
        //     if (nextProps.delete_bank_account_result.responseCode === 200) {
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_bank_account_result.message,
        //             icon: nextProps.delete_bank_account_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.fetchData();
        //             }
        //         });
        //     } else {
        //         this.setState({loading: false});
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_bank_account_result.message[0],
        //             icon: nextProps.delete_bank_account_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         })
        //     }
        // }

        if (nextProps.update_status_bank_account_result !== this.props.update_status_bank_account_result) {
            if (nextProps.update_status_bank_account_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_status_bank_account_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                filterErrorHandler(nextProps.update_status_bank_account_result.message);
            }
        }

        if (nextProps.update_status_maintenance_result !== this.props.update_status_maintenance_result) {
            if (nextProps.update_status_maintenance_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_status_maintenance_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                filterErrorHandler(nextProps.update_status_maintenance_result.message);
            }
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    updateStatusBankAccountHandler = (event, bankAccountId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updateBankAccountStatusData = {};
        updateBankAccountStatusData.id = bankAccountId;
        updateBankAccountStatusData.status = Number(event.target.value);

        this.props.updateBankAccountStatus(updateBankAccountStatusData);
    };

    updateStatusBankAccountMaintenanceHandler = (event, bankAccountId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updateBankAccountMaintenanceStatusData = {};
        updateBankAccountMaintenanceStatusData.id = bankAccountId;
        updateBankAccountMaintenanceStatusData.maintainance_status = Number(event.target.value);

        this.props.updateBankAccountMaintenanceStatus(updateBankAccountMaintenanceStatusData);
    };

    // deleteBankAccountHandler = (e, bank_account_id) => {
    //     e.preventDefault();

    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: 'You wont be able to revert this!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: `Delete`,
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deleteBankAccountData = {};
    //             deleteBankAccountData.id = bank_account_id;

    //             this.props.deleteBankAccount(deleteBankAccountData);
    //         }
    //     });
    // }

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("bank_account_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="bankAccountStatus"
                    name="bankAccountStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.walletTypeId}`}
                    onChange={(event) => {
                        this.updateStatusBankAccountHandler(event, row.bankAccountId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    memberGroupFormatter = (cell, row) => {
        let memberGroupName;
        
        if (row.memberGroupId === 0) {
            memberGroupName = "All";
        } else {
           memberGroupName = row.memberGroupName;
        }

        return memberGroupName;
    }

    maintenanceStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("bank_account_change_maintainance_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.maintainanceStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="maintenanceStatus"
                    name="maintenanceStatus"
                    options={statusArray}
                    defaultValue={row.maintainanceStatus}
                    key={`maint-status-${row.bankAccountId}`}
                    onChange={(event) => {
                        this.updateStatusBankAccountMaintenanceHandler(event, row.bankAccountId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.maintainanceStatus === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, ledgerButton, output;
        // if (this.state.permission.includes('bank_account_show')) {
        //     viewButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-primary mr-2"
        //             buttonName={<FontAwesomeIcon icon={faSearch}/>}
        //             key={`view-${row.bankAccountId}`}
        //             clicked={() => this.changePageHandler('show', row.bankAccountId)}
        //         />
        //     );
        // }

        if (this.state.permission.includes("bank_account_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.bankAccountId}`}
                    clicked={() => this.changePageHandler("edit", row.bankAccountId)}
                />
            );
        }

        // if (this.state.permission.includes('bank_account_delete')) {
        //     deleteButton = (

        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-danger mr-2"
        //             clicked={(event) => this.deleteBankAccountHandler(event, row.bankAccountId)}
        //             key={`delete-${row.bankAccountId}`}
        //             buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //         />
        //     )
        // }

        if (this.state.permission.includes("bank_account_ledger")) {
            ledgerButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-warning"
                    buttonName={<FontAwesomeIcon icon={faScroll} />}
                    key={`ledger-${row.bankAccountId}`}
                    clicked={() => this.changePageHandler("ledger", row.bankAccountId, row.bankSwiftCode)}
                />
            );
        }

        output = [viewButton, editButton, ledgerButton];
        return output;
    };

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                sorted: null
            },
            () => {
                this.fetchData();
            }
        );
    };

    clearHandler = () => {
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                filter: initialInputState,
                loading: true,
                data: [],
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                sorted: null,
            },
            () => {
                this.fetchData();
            }
        );
    };

    changePageHandler = (page, bankAccountId, bankSwiftCode=null) => {
        this.setState({
            pageShow: page,
            currentBankAccountId: bankAccountId,
            currentBankSwiftCode: bankSwiftCode
        });
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: "Bank Account Id",
                dataField: "bankAccountId",
                keyField: "bankAccountId",
                hidden: true,
            },
            {
                text: t("bank-account.banks"),
                dataField: "bankName",
                keyField: "bankName",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("bank-account.bank_account_name"),
                dataField: "bankAccName",
                keyField: "bankAccName",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("bank-account.bank_account_no"),
                dataField: "bankAccNumber",
                keyField: "bankAccNumber",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("bank-account.member_group"),
                dataField: "memberGroupName",
                keyField: "memberGroupName",
                formatter: this.memberGroupFormatter
            },
            {
                text: t("bank-account.balance"),
                dataField: "bankAccountBalance",
                keyField: "bankAccountBalance",
            },
            {
                text: t("bank-account.active-day"),
                dataField: "activeDay",
                keyField: "activeDay"
            },
            {
                text: t("global.status"),
                dataField: "status",
                keyField: "status",
                formatter: this.statusFormatter,
                headerStyle: { width: "115px" },
            },
            {
                text: t("bank-account.created_by"),
                dataField: "createdBy",
                keyField: "createdBy",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("global.action"),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return { width: "115px" };
                },
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("bank-account.banks-account")}</h4>
                                </div>
                            </div>
                        </div>

                        <BanksAccountListing
                            bankName={this.state.filter.bankName}
                            bankAccName={this.state.filter.bankAccName}
                            memberGroupName={this.state.filter.memberGroupName}
                            status={this.state.filter.status}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            /**filter drop down */
                            ddMemberGroup={this.state.dropDown.member_group}
                            ddBankName={this.state.dropDown.bank_name}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                )}

                {this.state.pageShow === "create" && <BanksAccountCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.pageShow === "show" && (
                    <BanksAccountShow show_bank_account_id={this.state.currentBankAccountId} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.pageShow === "edit" && (
                    <BanksAccountEdit
                        show_bank_account_id={this.state.currentBankAccountId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.pageShow === "ledger" && <BanksAccountLedger id={this.state.currentBankAccountId} changePageHandler={(page) => this.changePageHandler(page)} swiftCode={this.state.currentBankSwiftCode} />}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { bankAccount, currentUser } = state;
    return {
        bank_account: bankAccount.bank_account_data,
        update_status_bank_account_result: bankAccount.update_status_bank_account_result,
        delete_bank_account_result: bankAccount.delete_bank_account_result,
        update_status_maintenance_result: bankAccount.update_status_maintenance_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission,
        dropDown: bankAccount.dropDown,
    };
};

const mapDispatchToProps = {
    getBanksAccount,
    getDropDown,
    updateBankAccountStatus,
    deleteBankAccount,
    updateBankAccountMaintenanceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksAccount));
