import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Select from "../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../assets/images/updown-arrow.png'

class MemberListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    componentDidMount() {
        // console.log('componentDidMount (listing):', this.state);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate (listing):', nextProps);
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;

        let status_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member.member")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="filter_member_form"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="customer_id" className="font-weight-bolder">
                                                    {t("member.cusID")}
                                                </label>
                                                <StringInput
                                                    id="customer_id"
                                                    name="customer_id"
                                                    type="number"
                                                    value={this.props.filter.customer_id}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="name" className="font-weight-bolder">
                                                    {t("member.name")}
                                                </label>
                                                <StringInput
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    value={this.props.filter.name}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="email" className="font-weight-bolder">
                                                    {t("member.email")}
                                                </label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    type="text"
                                                    value={this.props.filter.email}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="contact" className="font-weight-bolder">
                                                    {t("member.contact-no")}
                                                </label>
                                                <StringInput
                                                    id="contact"
                                                    name="contact"
                                                    type="text"
                                                    value={this.props.filter.contact}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="member_group" className="font-weight-bolder">
                                                    {t("member.member-group")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="member_group"
                                                        name="member_group"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.member_group}>
                                                        <option value=""></option>
                                                        {this.props.ddMemberGroup}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="status" className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    options={status_option}
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.status}
                                                    className="form-control filter-input"
                                                />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="row"> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="created_date" className="font-weight-bolder">
                                                    {t("member.account-created-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="created_date"
                                                        name="created_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format="YYYY/MM/DD"
                                                        value={this.props.filter.created_date}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="last_login" className="font-weight-bolder">
                                                    {t("member.last-login")}
                                                </label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check"></i>
                                                        </span>
                                                    </div>
                                                    <DateTimePicker
                                                        id="last_login"
                                                        name="last_login"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.last_login}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="parcel_number" className="font-weight-bolder">
                                                    {t("member.parcel-number")}
                                                </label>
                                                <StringInput
                                                    id="parcel_number"
                                                    name="parcel_number"
                                                    type="text"
                                                    value={this.props.filter.parcel_number}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="crypto_address" className="font-weight-bolder">
                                                    {t("member.crypto-address")}
                                                </label>
                                                <StringInput
                                                    id="crypto_address"
                                                    name="crypto_address"
                                                    type="text"
                                                    value={this.props.filter.crypto_address}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div> */}
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0 ps-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("member.member-listing")}</h4>
                                    </div>
                                </div>
                                {this.props.permission.includes("member_create") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    idValue="member"
                                                    // classValue="btn btn-blue hover__btn rounded"
                                                    classValue="btn btn-underline"
                                                    clicked={() => this.props.changePageHandler("create")}
                                                    buttonName={t("member.add-member")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="memberId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberListing);
