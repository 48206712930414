import axios from "../../../../common/config/axios";
import * as type from "./types";

export const getListing = (data) => (dispatch, getState) => {
    axios.get("event_qna_question/get_listing", {params: data})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event QNA Question Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getOptionData = (data) => (dispatch, getState) => {
    axios.get("event_qna_question/get_option_data", {}).then((res) => {
            dispatch({
                type: type.OPTION_DATA,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event QNA Question Error", err);
            dispatch({
                type: type.OPTION_DATA,
                payload: [],
            });
        });
};

export const create = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_question/create", data)
        .then((res) => {
            dispatch({
                type: type.CREATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Create Event QNA Question Error\n", err);
            dispatch({
                type: type.CREATE,
                payload: err.data,
            });
        });
};

export const update = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_question/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event QNA Question Error\n", err);
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};

export const updateStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_question/update_status", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event QNA Question Status Error\n", err);
            dispatch({
                type: type.UPDATE_STATUS,
                payload: err.data,
            });
        });
};

export const updateAnswer = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_question/update_answer", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_ANSWER,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event QNA Question Answer Error\n", err);
            dispatch({
                type: type.UPDATE_ANSWER,
                payload: err.data,
            });
        });
};

export const payout = () => (dispatch, getState) => {
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_payout/payout", data)
        .then((res) => {
            dispatch({
                type: type.PAYOUT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("QNA Payout Error\n", err);
            dispatch({
                type: type.PAYOUT,
                payload: err.data,
            });
        });
};