import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import UpdownArrow from '../../assets/images/updown-arrow.png'

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Excel from "../../../src/assets/images/excel.svg";

class TransactionListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard
        })
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let stCls = {
            0: "primary",
            1: "success",
            2: "danger",
            3: "secondary",
            4: "danger",
        };
        // let permission = this.props.currentUser.permission
        //     ? this.props.currentUser.permission
        //     : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("transaction.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            // {text: "trans.id", dataField: "id"},
            {
                text: t("transaction.order-id"),
                dataField: "order_id",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "115px" },
            },
            {
                text: t("member.email"),
                dataField: "email",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("transaction.transaction-type"),
                dataField: "transaction_type",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("transaction.transaction-channel"),
                dataField: "transaction_channel",
                sort: true,
                onSort: this.props.onSort,
            },
            // {text: t('transaction.bank-account-no'), dataField: "bank_acc"},
            {
                text: t("transaction.debit"),
                dataField: "debit",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "120px" },
            },
            {
                text: t("transaction.credit"),
                dataField: "credit",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "120px" },
            },
            { text: t('transaction.fees'), dataField: "fees", sort: true, onSort: this.props.onSort, headerStyle: { width: '120px' } },
            {
                text: t("transaction.status"),
                dataField: "status",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "96px" },
            },
            // {text: t('transaction.transaction-date'), dataField: "transaction_date", sort: true, onSort: this.props.onSort},
            { text: t('transaction.create-date'), dataField: "create_date", sort: true, onSort: this.props.onSort },
            // {text: t('transaction.update-date'), dataField: "update_date", sort: true, onSort: this.props.onSort},
            {
                text: t("transaction.update-by"),
                dataField: "update_by",
                sort: true,
                onSort: this.props.onSort,
            },
        ];

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stVal = "";
                let orderIdVal = "";
                let emailVal = "";
                // let bankAccVal = '';
                const num_format = {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                };

                stVal = (
                    <span
                        className={`badge-${stCls[val.status]
                            }`}
                    >
                        {t(`transaction.status-desc.${val.status}`)}
                    </span>
                );
                orderIdVal = this.props.permission.includes(
                    "transaction_details_access"
                ) ? (
                    <b>
                        <a
                            href="/#"
                            onClick={(event) =>
                                this.props.detailsHandler(event, val.id)
                            }
                        >
                            {val.order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                );
                // if(val.transaction_type_id === 1 || val.transaction_type_id === 2)
                // {
                //     bankAccVal = (val.transaction_channel_id === 1) ? ((val.transaction_type_id === 1) ? `${val.dp_bank_name}(${val.dp_swift_code}) - ${val.dp_bank_acc_number}` : `${val.wd_bank_name}(${val.wd_swift_code}) - ${val.wd_bank_acc_number}`) : '-';
                // }
                // else
                // {
                //     bankAccVal = '-';
                // }
                emailVal = this.props.permission.includes("member_view") ? (
                    <b><a href="/#" onClick={event => this.props.redirectHandler(event, val.member_id, val.email)}>
                        {val.email}
                    </a></b>
                ) : (val.email);

                return {
                    no: idx,
                    // id: val.id,
                    order_id: orderIdVal,
                    email: emailVal,
                    transaction_type: t(
                        `transaction-type.${val.transaction_type}`
                    ),
                    transaction_channel: val.transaction_channel
                        ? t(`transaction-channel.${val.transaction_channel}`)
                        : "-",
                    // bank_acc: bankAccVal,
                    status: stVal,
                    // transaction_date: (val.transaction_date || '-'),
                    create_date: val.create_date,
                    // update_date: val.update_date,
                    update_by: val.update_by,
                    debit: new Intl.NumberFormat("en-US", num_format).format(
                        val.debit
                    ),
                    credit: new Intl.NumberFormat("en-US", num_format).format(
                        val.credit
                    ),
                    fees: new Intl.NumberFormat('en-US', num_format).format(val.fees),
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("transaction.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row
                            ${this.state.showCard ? 'open' : 'close'} filter-wrapper-1`}>
                                        {/* <div className="col-6"> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.create-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format="YYYY/MM/DD"
                                                        value={
                                                            this.props.filter
                                                                .transaction_date
                                                        }
                                                        onChange={
                                                            this.props.onInputChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member.email")}
                                                </label>
                                                <StringInput
                                                    id="email"
                                                    name="email"
                                                    value={this.props.filter.email}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.order-id")}
                                                </label>
                                                <StringInput
                                                    id="order_id"
                                                    name="order_id"
                                                    value={this.props.filter.order_id}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.status")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddStatus &&
                                                            this.props.ddStatus.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction.status-desc.${val.id}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>


                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="col-6"> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.transaction-type")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        onChange={this.props.onInputChange}
                                                        value={
                                                            this.props.filter
                                                                .transaction_type
                                                        }
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddTransactionType &&
                                                            this.props.ddTransactionType.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction-type.${val.slug}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "transaction.transaction-channel"
                                                    )}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_channel"
                                                        name="transaction_channel"
                                                        onChange={this.props.onInputChange}
                                                        value={
                                                            this.props.filter
                                                                .transaction_channel
                                                        }
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddTransactionChannel &&
                                                            this.props.ddTransactionChannel.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction-channel.${val.slug}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.debit")}
                                                </label>
                                                <div className="input-group">
                                                    <NumberInput
                                                        id="min_debit"
                                                        name="min_debit"
                                                        value={this.props.filter.min_debit}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.min")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />{" "}
                                                    <span style={{ margin: "auto 5px" }}>
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <NumberInput
                                                        id="max_debit"
                                                        name="max_debit"
                                                        value={this.props.filter.max_debit}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.max")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.credit")}
                                                </label>
                                                <div className="input-group">
                                                    <NumberInput
                                                        id="min_credit"
                                                        name="min_credit"
                                                        value={this.props.filter.min_credit}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.min")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />{" "}
                                                    <span style={{ margin: "auto 5px" }}>
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <NumberInput
                                                        id="max_credit"
                                                        name="max_credit"
                                                        value={this.props.filter.max_credit}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.max")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}


                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                                &nbsp;
                                                {(() => {
                                                    if (this.props.totalRecords > 0 && this.props.permission.includes("transaction_export")) {
                                                        return (
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-blue mr-2"
                                                                clicked={() => this.props.exportExcelHandler()}
                                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                                buttonName={t("transaction.export-excel")}
                                                            />
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div className="show-btn"
                                onClick={() => this.toggleCard()}>
                                {
                                    this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("transaction.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(TransactionListing);
