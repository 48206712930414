import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/ducks/member/actions';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import MemberParcelListing from './MemberParcelListing';
import { filterErrorHandler } from '../../../hoc/Shared/utility';

const initialInputState = {
    parcel_type: 0,
    tracking_no: ""
};

class MemberParcel extends Component{
    constructor(props){
        super(props);
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'member_parcel');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            this.state = {
                currentPage: "member_parcel",
                pageShow: "member_parcel_listing",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: '',
                totalRecords: 0,
                searchValue: '',
                data: [],
                loading: true,
                filter: initialInputState,
            }
            this.props.setMemberShowLocalState(this.state);
        }
        else{
            this.state = this.props.memberParcelLocalState;
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount(){
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'member_parcel');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            const listingArr = this.getFilterArray();
            this.props.getMemberParcel(listingArr);
            this.props.setMemberShowInitialLoading(this.state.currentPage);
        }
        else{
            if(this.props.memberParcelData !== undefined){
                if(this.props.memberParcelData.data && this.props.memberParcelData.data.data
                    && this.props.memberParcelData.data.data.length > 0
                    && Number(this.props.memberShowId) !== Number(this.props.memberParcelData.data.data.memberId)){
                    const listingArr = this.getFilterArray();
                    this.props.getMemberParcel(listingArr);
                }
                else{
                    this.setState({
                        loading: false
                    });
                }
            }
            else{
                const listingArr = this.getFilterArray();
                this.props.getMemberParcel(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log(nextProps);
        if(nextProps.memberShowId !== undefined && nextProps.memberParcelData !== undefined){
            if(nextProps.memberParcelData !== this.props.memberParcelData){
                if(nextProps.memberParcelData.data.responseCode === 200 && nextProps.memberParcelData.data.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        loading: false,
                        data: nextProps.memberParcelData.data.data.result.row,
                        totalRecords: nextProps.memberParcelData.data.data.result.pagination.total_record
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        data: [],
                        totalRecords: 0
                    }, () => {
                        filterErrorHandler(nextProps.memberParcelData.data.message);
                    });
                }
            }
        }

        return true;
    }

    componentWillUnmount(){
        this.props.setMemberShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        listingArr.member_id = this.props.memberShowId;
        if(this.state.totalRecords!==0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit)+1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        listingArr.status = this.state.filter.parcel_type;
        listingArr.language_code = localStorage.getItem('lang') || 'en';
        listingArr.selectTrackingNo = this.state.filter.tracking_no;
        return listingArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberParcel(listingArr);
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberParcel(listingArr);
            }
        );
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: ''
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberParcel(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                searchValue: "",
                loading: true,
                data: [],
                order: "",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                filter: newFilter
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberParcel(listingArr);
            }
        );
    };

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        // if (event.target.id === "transaction_date") {
        //     const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
        //     newFilter["start_date"] = date ? date[0] : "";
        //     newFilter["end_date"] = date ? date[1] : "";
        // }
        this.setState({
            filter: newFilter,
        });
    }
    
    render(){
        return(
            <Auxiliary>
                {this.state.pageShow==='member_parcel_listing' && 
                    <MemberParcelListing 
                        filter={this.state.filter}
                        onSort={(field,order)=>{this.onSort(field,order)}}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        datatablesChangeHandler={(val,act)=>{this.datatablesChangeHandler(val,act)}}
                        searchValue={this.state.searchValue}
                        loading={this.state.loading}
                        refreshHandler={()=>{this.refreshHandler()}}
                        clearHandler={()=>{this.clearHandler()}}
                        onInputChange={(event) => this.onInputChange(event)}
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member, currentUser } = state;
    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberParcelLocalState: member.member_parcel_local_state,
        memberParcelData: member.member_parcel_data,
        currentCountryId: currentUser.data.country_id,
        currentUser: currentUser.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => {dispatch(actions.setMemberShowLocalState(data))},
        setMemberShowInitialLoading: (data) => {dispatch(actions.setMemberShowInitialLoading(data))},
        getMemberParcel: (data) => {dispatch(actions.getMemberParcel(data))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberParcel));