import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const gameFavReducer = createReducer(initialState)({
    [types.GAME_FAV_STATS]: (state, action) => ({
        ...state,
        game_fav_report: action.payload
    })
});

export default gameFavReducer;