import { Component } from "react";
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DataTable from "../../components/Datatable/DataTable";
import Button from "../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import StringInput from "../../components/Input/StringInput/StringInput";

class RewardItemHistoryListing extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("reward-item.history")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                <div className={`form-horizontal filter-function row filter-wrapper-1`}>
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("reward-item.date")}
                                            </label>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-calendar-check" />
                                                </span>
                                                <DateTimePicker
                                                    id='transaction_date'
                                                    name='transaction_date'
                                                    className='form-control filter-input'
                                                    range={true}
                                                    timePicker={true}
                                                    value={this.props.filterInput.date}
                                                    onChange={this.props.onInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("reward-item.member")}
                                            </label>
                                            <StringInput
                                                id="member"
                                                name="member"
                                                value={this.props.filterInput.member}
                                                onChange={this.props.onInputChange}
                                                className="filter-input"
                                                onSearchPress={(evt) =>
                                                    evt.key === "Enter" &&
                                                    this.props.refreshHandler()
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("reward-item.item")}
                                            </label>
                                            <StringInput
                                                id="title"
                                                name="title"
                                                value={this.props.filterInput.title}
                                                onChange={this.props.onInputChange}
                                                className="filter-input"
                                                onSearchPress={(evt) =>
                                                    evt.key === "Enter" &&
                                                    this.props.refreshHandler()
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("reward-item.category")}
                                            </label>
                                            <select
                                                className="form-control filter-input"
                                                id="category"
                                                name="category"
                                                onChange={this.props.onInputChange}
                                                value={this.props.filterInput.category}
                                            >
                                                <option value=""></option>
                                                {this.props.ddCategory &&
                                                    this.props.ddCategory.map(
                                                        (val, idx) => {
                                                            return (
                                                                <option key={val.id} value={val.id}>{t(`reward-category.${val.slug}`)}</option>
                                                            );
                                                        }
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("reward-item.reference-code")}
                                            </label>
                                            <StringInput
                                                id="reference_code"
                                                name="reference_code"
                                                value={this.props.filterInput.reference_code}
                                                onChange={this.props.onInputChange}
                                                className="filter-input"
                                                onSearchPress={(evt) =>
                                                    evt.key === "Enter" &&
                                                    this.props.refreshHandler()
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col mt-auto">
                                        <div>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-blue"
                                            idValue="filter"
                                            buttonName={t("global.filter")}
                                            clicked={() =>
                                                this.props.refreshHandler()
                                            }
                                        />
                                        &nbsp;
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-secondary"
                                            idValue="reset"
                                            buttonName={t("global.reset")}
                                            clicked={() =>
                                                this.props.clearHandler()
                                            }
                                        />
                                        </div>
                                    </div>
                                </div> )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("reward-item.history-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

export default withNamespaces("translation")(RewardItemHistoryListing);
