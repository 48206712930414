import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberWalletBalance, storeWalletAdjustment } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { formValidation, filterErrorHandler } from "../../../hoc/Shared/utility";
import Select from "../../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../../components/ValidationError/ValidationError";

class MemberWalletAdjustment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            edit_id: null,
            transaction_type: 0,
            amount: "",
            available_balance: 0,
            adjustment_balance: 0,
            remark: "",
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // console.log("[MemberWalletAdjustment] componentDidMount()");
        formValidation("member_wallet_adjustment_form");
        this.props.getMemberWalletBalance(this.props.memberEditId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberWalletAdjustment] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.memberEditId !== undefined && nextProps.memberEditId !== nextState.edit_id) {
            if (nextProps.memberWalletBalanceData !== undefined && nextProps.memberWalletBalanceData !== this.props.memberWalletBalanceData) {
                if (nextProps.memberWalletBalanceData.responseCode === 200) {
                    this.setState(
                        {
                            loading: false,
                            edit_id: nextProps.memberEditId,
                            available_balance: nextProps.memberWalletBalanceData.data.balance,
                        },
                        () => {
                            this.calculateBalance();
                        }
                    );
                }
                else {
                    this.setState({
                        loading: true,
                        edit: null,
                        available_balance: 0
                    }, () => {
                        filterErrorHandler(nextProps.memberWalletBalanceData.message);
                    });
                }
            }
            Swal.close();
        }

        if (nextProps.memberStoreAdjustmentWalletResult !== this.props.memberStoreAdjustmentWalletResult) {
            if (nextProps.memberStoreAdjustmentWalletResult.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.memberStoreAdjustmentWalletResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState(
                            {
                                amount: "",
                                available_balance: this.state.adjustment_balance,
                            },
                            () => {
                                if (this.props.memberEditId > 0) {
                                    // this.calculateBalance();
                                }
                            }
                        );
                        this.props.getMemberWalletBalance(this.props.memberEditId);
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.memberStoreAdjustmentWalletResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    calculateBalance = () => {
        let amt = this.state.amount === null ? 0 : this.state.amount;
        let balc = this.state.available_balance;
        let available_balance = balc !== undefined && balc !== "" ? balc : 0;
        // var balance = Number(this.state.transaction_type) === 0 ? Number(available_balance) - Number(amt) : Number(available_balance) + Number(amt);
        var balance = (Number(this.state.transaction_type) === 1) ? (Number(available_balance)-Number(amt)) : (Number(available_balance)+Number(amt));

        // console.log("STATE amount:",this.state.amount, "\nSTATE available_balance", this.state.available_balance);
        // console.log("AVAILABLE BALANCE",available_balance,"\nBALANCE",balance);

        this.setState({
            available_balance: available_balance,
            adjustment_balance: balance,
        });
    };

    handleChange = (event) => {
        this.setState(
            {
                amount: event.target.name === "amount" ? event.target.value : this.state.amount,
                transaction_type: event.target.name === "transaction_type" ? event.target.value : this.state.transaction_type,
                remark: event.target.name === "remark" ? event.target.value : this.state.remark
            },
            () => {
                if (this.props.memberEditId > 0) {
                    this.calculateBalance();
                }
            }
        );
    };

    updateMemberWalletBalanceHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;
        if (this.state.adjustment_balance < 0) {
            Swal.fire({
                title: t("member.insufficient-balance"),
                icon: "error",
                showConfirmButton: true,
            });
        } else {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.save"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.member_id = this.props.memberEditId;
                    data.amount = this.state.amount;
                    data.transaction_type = this.state.transaction_type;
                    data.remark = this.state.remark;
                    this.props.storeWalletAdjustment(data);
                }
            });
        }
    };

    render() {
        const { t } = this.props;
        let display = null;
        let transaction_type_option = [
            { label: t("member.credit"), value: 0 },
            { label: t("member.debit"), value: 1 },
        ];
        if (!this.state.loading) {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.wallet-adjustment")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="member_wallet_adjustment_form" className="form-horizontal" onSubmit={this.updateMemberWalletBalanceHandler} noValidate>
                                        <ValidationError formName="member_wallet_adjustment_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="col-12 p-0">
                                            <div className="row">
                                                <div className="col-md-6 col-12 form-group">
                                                    <label htmlFor="transaction_type">{t("member.transaction-type")}</label>
                                                    <Select
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        options={transaction_type_option}
                                                        value={this.state.transaction_type}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12 form-group">
                                                    <label htmlFor="amount">{t("member.amount")}</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="amount"
                                                        id="amount"
                                                        value={this.state.amount}
                                                        placeholder={t("member.amount")}
                                                        onChange={this.handleChange}
                                                        onKeyDown={(event) => event.key === "e" && event.preventDefault()}
                                                        required
                                                    />
                                                </div>
                                                <div className='col-md-6 col-12 form-group'>
                                                    <label>{t('wallet-adjustment.remark')}</label>
                                                    <div className="position-relative">
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="remark"
                                                            id="remark"
                                                            maxLength="255"
                                                            rows="3"
                                                            placeholder={t("wallet-adjustment.remark")}
                                                            value={this.state.remark}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-12">
                                                    <div className="card card-img-holder overflow-hidden sales-card bg-primary-gradient">
                                                        <div className="card-body list-icons">
                                                            <div className="float-right  mt-2">
                                                                <span className="text-primary">
                                                                    <i className="si si-credit-card tx-50 text-white"></i>
                                                                </span>
                                                            </div>
                                                            <div className="float-left text-white">
                                                                <label htmlFor="available_balance">{t("member.available-balance")}</label>
                                                                <h2 id="available_balance">
                                                                    {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                        this.state.available_balance
                                                                    )}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                {/* <div className="col-md-6 col-12 form-group">
                                                    <div className="card card-img-holder overflow-hidden sales-card bg-primary-gradient mb-0">
                                                        <div className="card-body list-icons">
                                                            <div className="float-right  mt-2">
                                                                <span className="text-primary">
                                                                    <i className="si si-credit-card tx-50 text-white"></i>
                                                                </span>
                                                            </div>
                                                            <div className="float-left text-white">
                                                                <label htmlFor="available_balance">{t("member.available-balance")}</label>
                                                                <h2 id="available_balance">
                                                                    {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                        this.state.available_balance
                                                                    )}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="col-lg-6 col-12">
                                                    <div className="card card-img-holder overflow-hidden sales-card bg-danger-gradient">
                                                        <div className="card-body list-icons">
                                                            <div className="float-right  mt-2">
                                                                <span className="text-primary">
                                                                    <i className="si si-credit-card tx-50 text-white"></i>
                                                                </span>
                                                            </div>
                                                            <div className="float-left text-white">
                                                                <label htmlFor="after_adjustment_balance">{t("member.after-adjustment-balance")}</label>
                                                                <h2 id="after_adjustment_balance">
                                                                    {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                        this.state.adjustment_balance
                                                                    )}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 justify-content-end">
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="update_wallet_adjustment" buttonName={t("global.update")} />
                                            </div>
                                        </div>                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member.wallet-adjustment")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberWalletBalanceData: member.member_wallet_balance_data,
        memberStoreAdjustmentWalletResult: member.member_store_wallet_adjustment_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberWalletBalance: (id) => dispatch(getMemberWalletBalance(id)),
        storeWalletAdjustment: (data) => dispatch(storeWalletAdjustment(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberWalletAdjustment));
