import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import DomPurify from "../../components/Sanitize/DomPurify";
import Lightbox from 'react-image-lightbox';

import * as actions from '../../store/ducks/rewardItem/actions';

class RewardItemShow extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            details: {},
            loading: true,
            langArr: [],
            tabArr: [],
            currentImage : null,
            isOpen: false,
        };
    }

    componentDidMount()
    {
        this.props.funcGetDetails(this.props.selectId);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details!==undefined)
        {
            if(nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success')
            {
                let tabArr = [];

                if(nextProps.details.data.result.reward_details)
                {
                    nextProps.details.data.result.reward_details.forEach((val) =>
                    {
                        tabArr[val.lang_id] = val;
                    });
                }

                this.setState({
                    details: nextProps.details.data.result,
                    langArr: nextProps.details.data.language,
                    tabArr: tabArr,
                    loading: false,
                });

            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }

    render()
    {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];
        let details = this.state.details;
        let tabArr = this.state.tabArr;
        let languages = this.state.langArr;

        tabTitleMessage = languages.map((language) => language.lang);
        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('reward-item.image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={tabArr[item.langId] && tabArr[item.langId].image}
                                onClick={() => this.setState({isOpen: true, currentImage: tabArr[item.langId] && tabArr[item.langId].image})}
                                style={{"cursor": "pointer"}}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-md-12">
                    <div className="form-group">
                        <label><strong>{t('reward-item.title')}:</strong></label>
                        <p>{tabArr[item.langId] && tabArr[item.langId].title}</p>
                    </div>
                </div>
                <hr/>
                <div className="col-md-12">
                    <div className="form-group">
                        <label><strong>{t('reward-item.content')}:</strong></label>
                        <DomPurify text={tabArr[item.langId] && tabArr[item.langId].content} />
                    </div>
                </div>
                <hr/>
            </Auxiliary>
        ));
        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="bg-white"/>;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('reward-item.show-reward')}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('reward-item.show-reward')}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr className="show-promotion-white">
                                            <th>{t('reward-item.details')}:</th>
                                            <td>
                                                {tabMessage}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('reward-item.coin')}:</th>
                                            <td>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(details.coin)}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('reward-item.qty')}:</th>
                                            <td>{details.qty}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('reward-item.limit')}:</th>
                                            <td>{details.limit === 0 ? t('reward-item.no-limit') : details.limit}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('reward-item.deposit-amount')}:</th>
                                            <td>{details.deposit === 0 ? t('reward-item.not-required') : new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(details.deposit)}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('reward-item.category')}:</th>
                                            <td>{t(`reward-category.${details.category}`)}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('global.status')}:</th>
                                            <td field-key='status'>{details.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state =>
{
    return {
        details: state.rewardItem.details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RewardItemShow));