import axios from "../../../common/config/axios";
import * as type from "./types";

export const getMetaTags = (data) => (dispatch) => {
    axios
        .get("meta_tag/list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
            },
        })
        .then((res) => {
            dispatch({
                type: type.META_TAG_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Meta Tags Error", err);
            dispatch({
                type: type.META_TAG_LIST,
                payload: [],
            });
        });
};

export const showMetaTag = (id) => (dispatch) => {
    axios
        .get("meta_tag/show", {
            params: {
                id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.META_TAG_SHOW,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Meta Tag Error", err);
            dispatch({
                type: type.META_TAG_SHOW,
                payload: err.response,
            });
        });
};

export const createMetaTag = () => (dispatch) => {
    axios.get("meta_tag/create")
    .then((res) => {
        dispatch({
            type: type.META_TAG_CREATE,
            payload: res,
        });
    })
    .catch((err) => {
        console.log("Create Meta Tag Error" + err)
        dispatch({
            type: type.META_TAG_CREATE,
            payload: [],
        });
    });
}

export const storeMetaTag = (data) => (dispatch, getState) => {
    axios
        .post("meta_tag/store", data)
        .then((res) => {
            dispatch({
                type: type.META_TAG_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Meta Tag Error\n", err);
        });
};

export const updateMetaTagStatus = (data) => (dispatch) => {
    axios
        .post("meta_tag/update_status", data)
        .then((res) => {
            dispatch({
                type: type.META_TAG_UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Meta Tag Status Error", err);
        });
};

export const editMetaTag = (id) => (dispatch) => {
    axios
        .get("meta_tag/edit", {
            params: {
                id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.META_TAG_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Meta Tag Error", err);
            dispatch({
                type: type.META_TAG_EDIT,
                payload: err.data,
            });
        });
};

export const updateMetaTag = (data) => (dispatch) => {
    axios
        .post("meta_tag/update", data)
        .then((res) => {
            dispatch({
                type: type.META_TAG_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Meta Tag Error", err);
        });
};

export const deleteMetaTag = (data) => (dispatch) => {
    axios
        .post("meta_tag/delete", data)
        .then((res) => {
            dispatch({
                type: type.META_TAG_DELETE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Delete Meta Tag Error", err);
        });
};

export const getXMLSitemap = () => (dispatch) => {
    axios.get("xml_sitemap/sitemap")
    .then((res) => {
        dispatch({
            type: type.XML_SITEMAP,
            payload: res.data,
        });
    })
    .catch((err) => {
        console.log("Get XML Sitemap Error" + err)
        dispatch({
            type: type.XML_SITEMAP,
            payload: [],
        });
    });
}

export const generateXMLSitemap = (data) => (dispatch) => {
    axios.post("xml_sitemap/generate", data)
    .then((res) => {
        dispatch({
            type: type.XML_SITEMAP_GENERATE,
            payload: res.data,
        });
    })
    .catch((err) => {
        console.log("Generate XML Sitemap Error" + err)
        dispatch({
            type: type.XML_SITEMAP_GENERATE,
            payload: [],
        });
    });
}

export const getRobotsTxt = (data) => (dispatch) => {
    axios
        .get("robots_txt/list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
            },
        })
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Robots Txt Error", err);
            dispatch({
                type: type.ROBOTS_TXT_LIST,
                payload: [],
            });
        });
};

export const storeRobotsTxt = (data) => (dispatch) => {
    axios
        .post("robots_txt/store", data)
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Robots Txt Error\n", err);
        });
};

export const editRobotsTxt = (id) => (dispatch) => {
    axios
        .get("robots_txt/edit", {
            params: {
                id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Robots Txt Error", err);
            dispatch({
                type: type.ROBOTS_TXT_EDIT,
                payload: err.data,
            });
        });
};

export const updateRobotsTxt = (data) => (dispatch) => {
    axios
        .post("robots_txt/update", data)
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Robots Txt Error", err);
        });
};

export const updateRobotsTxtRule = (data) => (dispatch) => {
    axios
        .post("robots_txt/update_rule", data)
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_UPDATE_RULE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Robots Txt Rule Error", err);
        });
};

export const deleteRobotsTxt = (data) => (dispatch) => {
    axios
        .post("robots_txt/delete", data)
        .then((res) => {
            dispatch({
                type: type.ROBOTS_TXT_DELETE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Delete Robots Txt Error", err);
        });
};

export const getWebMaster = (data) => (dispatch) => {
    axios
        .get("web_master/list")
        .then((res) => {
            dispatch({
                type: type.WEB_MASTER_LIST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Web Master Error", err);
            dispatch({
                type: type.WEB_MASTER_LIST,
                payload: [],
            });
        });
};

export const updateWebMaster = (data) => (dispatch) => {
    axios
        .post("web_master/update", data)
        .then((res) => {
            dispatch({
                type: type.WEB_MASTER_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Web Master Error", err);
        });
};

export const getOpenGraph = (data) => (dispatch) => {
    axios
        .get("open_graph/list")
        .then((res) => {
            dispatch({
                type: type.OPEN_GRAPH_LIST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Open Graph Error", err);
            dispatch({
                type: type.OPEN_GRAPH_LIST,
                payload: [],
            });
        });
};

export const updateOpenGraph = (data) => (dispatch) => {
    axios
        .post("open_graph/update", data)
        .then((res) => {
            dispatch({
                type: type.OPEN_GRAPH_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Open Graph Error", err);
        });
};

export const demoSEO = () => (dispatch, getState) => {
    let data = {};
    data.url = window.location.pathname;
    data.languages = getState().currentUser.data.language_id;
    axios
        .post("demo_seo", data)
        .then((res) => {
            dispatch({
                type: type.DEMO_SEO,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Demo SEO Error", err);
        });
};

export const staticSEO = () => (dispatch) => {
    axios
        .get("static_seo")
        .then((res) => {
            dispatch({
                type: type.STATIC_SEO,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Static SEO Error", err);
        });
};

export const dynamicSEO = () => (dispatch, getState) => {
    let data = {};
    data.url = window.location.pathname;
    data.languages = getState().currentUser.data.language_id;
    axios
        .post("dynamic_seo", data)
        .then((res) => {
            dispatch({
                type: type.DYNAMIC_SEO,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Dynamic SEO Error", err);
        });
};