import React, { Component } from "react";
import { connect } from "react-redux";
import { createGameList, storeGameList } from "../../store/ducks/gameList/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import { default as CustomSelect } from "../../components/Input/Select/Select";

import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";
import UpdownArrow from "../../assets/images/updown-arrow.png";

let vendorArray = [];

class GameListCreate extends Component {
    constructor() {
        super();
        this.state = {
            vendor_id: "",
            game_list: [{ listIndex: Math.random(), game_title: "", game_code: "", game_type: "", game_category: "", status: 1, fav: 1, demo: 1 }],
            dropDown: {
                game_type_option: [],
                game_category_option: [],
            },
            error_message: [],
            loading: true,
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleGameListChange = this.handleGameListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() {
        this.props.createGameList();
        formValidation("game_list_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[GameListCreate] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.gameListCreateData !== this.props.gameListCreateData) {
            if (nextProps.gameListCreateData.responseCode === 200) {
                const { t } = this.props;
                let vendorData = nextProps.gameListCreateData.data.vendor;
                let gameTypeData = nextProps.gameListCreateData.data.gameType;
                let gameCategory = nextProps.gameListCreateData.data.gameCategory;

                vendorArray = [{ label: "", value: "" }];
                Object.keys(vendorData).map((item, i) => {
                    return vendorArray.push({
                        label: t(`vendor.${vendorData[item].vendorSlug}`),
                        value: vendorData[item].vendorId,
                    });
                });

                this.setState({
                    dropDown: {
                        game_type_option: gameTypeData,
                        game_category_option: gameCategory,
                    },
                    loading: false,
                });
            }
        }

        if (nextProps.storeGameListResult !== this.props.storeGameListResult) {
            if (nextProps.storeGameListResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeGameListResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.storeGameListResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.storeGameListResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    storeGameListHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                let game_title_array = [];
                let game_code_array = [];
                let game_type_array = [];
                let game_category_array = [];
                let status_array = [];
                let fav_array = [];
                let demo_array = [];

                this.state.game_list.forEach((item, idx) => {
                    game_title_array.push(item.game_title);
                    game_code_array.push(item.game_code);
                    game_type_array.push(item.game_type);
                    game_category_array.push(item.game_category);
                    status_array.push(item.status);
                    fav_array.push(item.fav);
                    demo_array.push(item.demo);
                });

                let storeGameListData = {};
                storeGameListData.vendor_id = this.state.vendor_id;
                storeGameListData.game_title = game_title_array;
                storeGameListData.game_code = game_code_array;
                storeGameListData.game_type = game_type_array;
                storeGameListData.game_category = game_category_array;
                storeGameListData.status = status_array;
                storeGameListData.fav = fav_array;
                storeGameListData.demo = demo_array;

                // console.log("Form Submit:", storeGameListData);
                this.props.storeGameList(storeGameListData);
            }
        });
    };

    addRecord = () => {
        if (this.state.game_list.length < 20) {
            this.setState((prevState) => ({
                game_list: [...prevState.game_list, { listIndex: Math.random(), game_title: "", game_code: "", game_type: "", status: 1, fav: 1, demo: 1 }],
            }));
        }
    };

    removeRecord = (i) => {
        // const list = [...this.state.game_list];
        // list.splice(idx, 1);
        // this.setState({game_list: list});
        this.setState({
            game_list: this.state.game_list.filter((item, idx) => i !== idx),
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleGameListChange = (event) => {
        let game_list = [...this.state.game_list];
        game_list[event.target.dataset.id][event.target.name] = event.target.value;
        this.setState({ game_list });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            let ddGameType;
            ddGameType = this.state.dropDown.game_type_option.map((val, idx) => {
                return (
                    <option key={val.gameTypeId} value={val.gameTypeSlug}>
                        {t(`game-type.${val.gameTypeSlug}`)}
                    </option>
                );
            });
            let ddGameCategory = this.state.dropDown.game_category_option.map((val, idx) => {
                return (
                    <option key={val.gameCategoryId} value={val.gameCategoryId}>
                        {t(`game-list.${val.gameCategorySlug}`)}
                    </option>
                );
            });

            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("game-list.add-game-list")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="game_list_form" className="form-horizontal" onSubmit={this.storeGameListHandler} noValidate>
                                        <ValidationError formName="game_list_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="vendor_id">{t("game-list.vendor")}</label>
                                                    <CustomSelect id="vendor_id" name="vendor_id" options={vendorArray} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <h3 className="card-title col-8">{t('game-list.game-list')}</h3>
                                                    <div className="row col-4 justify-content-end mb-2 pr-0">
                                                        <button type="button" className="btn btn-blue" style={{minWidth:'unset'}} onClick={(e) => this.addRecord(e)}>
                                                            <i className="fa fa-plus"></i> {t('global.add')}
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className="table-responsive" style={{ overflowY: 'auto' }}>
                                                    <table className="table" id="game_list_table" style={{ minWidth: 968 }}>
                                                        <thead className="text-center">
                                                            <tr>
                                                                <th className="p-2">{t("global.no")}</th>
                                                                <th className="p-2">{t("game-list.game-title")}</th>
                                                                <th className="p-2">{t("game-list.game-code")}</th>
                                                                <th className="p-2">{t("game-list.game-type")}</th>
                                                                <th className="p-2">{t("game-list.game-category")}</th>
                                                                <th className="p-2">{t("global.status")}</th>
                                                                <th className="p-2">{t("game-list.hot")}</th>
                                                                <th className="p-2">{t("game-list.demo")}</th>
                                                                <th style={{ width: "5%" }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.game_list.map((item, idx) => {
                                                                return (
                                                                    <tr key={idx} className="text-center">
                                                                        <td>{idx + 1}</td>
                                                                        <td key={`game-title-${idx}`}>
                                                                            <input
                                                                                id="game_title"
                                                                                name="game_title"
                                                                                data-id={idx}
                                                                                className="form-control"
                                                                                placeholder={t("game-list.game-title")}
                                                                                value={item.game_title}
                                                                                onChange={this.handleGameListChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </td>
                                                                        <td key={`game-code-${idx}`}>
                                                                            <input
                                                                                id="game_code"
                                                                                name="game_code"
                                                                                data-id={idx}
                                                                                className="form-control"
                                                                                placeholder={t("game-list.game-code")}
                                                                                value={item.game_code}
                                                                                onChange={this.handleGameListChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </td>
                                                                        <td key={`game-type-${idx}`}>
                                                                            <div className="select-input">
                                                                                <select
                                                                                    className="form-control datatable-select"
                                                                                    id="game_type"
                                                                                    name="game_type"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleGameListChange}
                                                                                    value={item.game_type}>
                                                                                    <option value=""></option>
                                                                                    {ddGameType}
                                                                                </select>
                                                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                                            </div>

                                                                        </td>
                                                                        <td key={`game-list-category-${idx}`}>
                                                                            <div className="select-input">
                                                                                <select
                                                                                    className="form-control datatable-select"
                                                                                    id="game_category"
                                                                                    name="game_category"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleGameListChange}
                                                                                    value={item.game_category}>
                                                                                    <option value=""></option>
                                                                                    {ddGameCategory}
                                                                                </select>
                                                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                                            </div>

                                                                        </td>
                                                                        <td key={`status-${idx}`}>
                                                                            <div className={`select-input ${item.status === 1 ? "datatable-select-active" : "datatable-select-inactive"}`}>
                                                                                {console.log(item.status)}
                                                                                <select
                                                                                    className="form-control datatable-select"
                                                                                    id="status"
                                                                                    name="status"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleGameListChange}
                                                                                    value={item.status}>
                                                                                    <option value={1}>{t("global.active")}</option>
                                                                                    <option value={0}>{t("global.inactive")}</option>
                                                                                </select>
                                                                                <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                                                                            </div>

                                                                        </td>
                                                                        <td key={`fav-${idx}`}>
                                                                            <div className={`select-input ${item.status === 1 ? "datatable-select-active" : "datatable-select-inactive"}`}>
                                                                                <select
                                                                                    className="form-control datatable-select"
                                                                                    id="fav"
                                                                                    name="fav"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleGameListChange}
                                                                                    value={item.fav}>
                                                                                    <option value={1}>{t("global._yes")}</option>
                                                                                    <option value={0}>{t("global._no")}</option>
                                                                                </select>
                                                                                <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                                                                            </div>

                                                                        </td>
                                                                        <td key={`demo-${idx}`}>
                                                                            <div className={`select-input ${item.status === 1 ? "datatable-select-active" : "datatable-select-inactive"}`}>
                                                                                <select
                                                                                    className="form-control datatable-select"
                                                                                    id="demo"
                                                                                    name="demo"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleGameListChange}
                                                                                    value={item.demo}>
                                                                                    <option value={1}>{t("global._yes")}</option>
                                                                                    <option value={0}>{t("global._no")}</option>
                                                                                </select>
                                                                                <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            {/* <i className="fa fa-trash" style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.removeRecord(idx)}></i> */}
                                                                            <button
                                                                                type="button"
                                                                                name="remove"
                                                                                className="remove btn btn-sm btn-white delete"
                                                                                onClick={() => this.removeRecord(idx)}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("game-list.add-game-list")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex ">
                        <div className='my-auto'>
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("game-list.game-list")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gameListCreateData: state.gameList.game_list_create_data,
        storeGameListResult: state.gameList.game_list_store_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createGameList: () => dispatch(createGameList()),
        storeGameList: (data) => dispatch(storeGameList(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameListCreate));
