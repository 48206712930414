import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const memberGroupReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_MEMBER_GROUP]: (state = initialState, action) => ({
        ...state,
        member_group_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.UPDATE_MEMBER_GROUP]: (state, action) => ({
        ...state,
        update_member_group_result: action.payload,
    }),

    [type.CREATE_MEMBER_GROUP]: (state, action) => ({
        ...state,
        post_member_group_result: action.payload,
    }),

    [type.SHOW_MEMBER_GROUP]: (state, action) => ({
        ...state,
        show_member_group_data: action.payload,
    }),

    [type.UPDATE_STATUS_MEMBER_GROUP]: (state, action) => ({
        ...state,
        update_status_member_group_result: action.payload,
    }),

    [type.DELETE_MEMBER_GROUP]: (state, action) => ({
        ...state,
        delete_member_group_result: action.payload,
    }),
});

export default memberGroupReducer;