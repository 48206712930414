export const GET_PROMOTION = "GET_PROMOTION";
export const POST_PROMOTION = "POST_PROMOTION";
export const SHOW_PROMOTION = "SHOW_PROMOTION";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const UPDATE_STATUS_PROMOTION = "UPDATE_STATUS_PROMOTION";
export const UPDATE_SHOW_PROMOTION = "UPDATE_SHOW_PROMOTION";
export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const GET_TYPE = "GET_TYPE";
export const GET_MEMBER_GROUP = "GET_MEMBER_GROUP";
export const GET_CATEGORY = "GET_CATEGORY";
export const DROPDOWN = "PROMOTION_DROPDOWN";