import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const uploadApkReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.UPLOAD]: (state, action) => ({
        ...state,
        upload: action.payload
    }),

    [type.DOWNLOAD]: (state, action) => ({
        ...state,
        download: action.payload
    }),

    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        update_status: action.payload
    }),
});

export default uploadApkReducer;