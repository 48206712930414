import React, { Component } from "react";
import { connect } from "react-redux";
import { editSystemSetting, updateSystemSetting } from "../../store/ducks/systemSettings/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";

class SystemSettingEdit extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            slug: "",
            setting_list: [{ listIndex: Math.random(), key: "", value: "" }],
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() {
        formValidation("system_setting_form");
        this.props.editSystemSetting(this.props.systemSettingEditId);

    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[SystemSettingEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.systemSettingEditData !== undefined && nextProps.systemSettingEditData !== this.props.systemSettingEditData && nextState.loading === true) {
            let systemSettingEditData = nextProps.systemSettingEditData.data.systemSetting[0];
            let settingData = Object.entries(nextProps.systemSettingEditData.data.systemSetting[0].data);
            let settingList = [];

            settingData.map((item, idx) => {
                return settingList.push({ listIndex: Math.random(), key: item[0], value: item[1] });
            })

            this.setState({
                edit_id: systemSettingEditData.systemSettingsId,
                name: systemSettingEditData.name,
                slug: systemSettingEditData.slug,
                setting_list: settingList,
                loading: false,
            });
        }

        if (nextProps.updateSystemSettingResult !== this.props.updateSystemSettingResult) {
            if (nextProps.updateSystemSettingResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateSystemSettingResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateSystemSettingResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateSystemSettingResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateSystemSettingHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                let key_array = [];
                let value_array = [];

                this.state.setting_list.forEach((item, idx) => {
                    key_array.push(item.key);
                    value_array.push(item.value)
                })

                let updateSystemSettingData = {};
                updateSystemSettingData.edit_id = this.state.edit_id;
                updateSystemSettingData.name = this.state.name;
                updateSystemSettingData.slug = this.state.slug;
                updateSystemSettingData.key = key_array;
                updateSystemSettingData.value = value_array;
                // console.log("Form Submit:", updateSystemSettingData);
                this.props.updateSystemSetting(updateSystemSettingData);
            }
        });
    };

    addRecord = () => {
        if (this.state.setting_list.length < 20) {
            this.setState((prevState) => ({
                setting_list: [...prevState.setting_list, { listIndex: Math.random(), key: "", value: "" }],
            }));
        }
    };

    removeRecord = (i) => {
        this.setState({
            setting_list: this.state.setting_list.filter((item, idx) => i !== idx)
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSettingListChange = (event) => {
        let setting_list = [...this.state.setting_list];
        setting_list[event.target.dataset.id][event.target.name] = event.target.value;
        this.setState({ setting_list });
    }

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("system-settings.edit-setting")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="system_setting_form" className="form-horizontal" onSubmit={this.updateSystemSettingHandler} noValidate>
                                    <ValidationError formName="system_setting_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("system-settings.setting-name")}</label>
                                                <StringInput
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder={t("system-settings.setting-name")}
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("system-settings.setting-slug")}</label>
                                                <StringInput
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    placeholder={t("system-settings.setting-slug")}
                                                    value={this.state.slug}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h3 className="card-title col-8">Setting Value</h3>
                                                <div className="row col-4 justify-content-end mb-2 pr-0">
                                                    <button type="button" className="btn btn-blue" style={{minWidth:'unset'}} onClick={(e) => this.addRecord(e)}>
                                                        <i className="fa fa-plus"></i> Add
                                                    </button>

                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{ overflowY: 'auto' }}>
                                                <table className="table" id="setting_table" style={{ minWidth: 500 }}>
                                                    <thead className="text-center">
                                                        <tr>
                                                            <th className="p-2">{t("global.no")}</th>
                                                            <th className="p-2">{t("system-settings.key")}</th>
                                                            <th className="p-2">{t("system-settings.setting-value")}</th>
                                                            <th style={{ width: "5%" }}>
                                                                {/* <button
                                                                    type="button"
                                                                    name="add"
                                                                    className="add btn btn-sm btn-success text-center mt-1"
                                                                    onClick={(e) => this.addRecord(e)}>
                                                                    <i className="fas fa-plus-square fa-2x"></i>
                                                                </button> */}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.setting_list.map((item, idx) => {
                                                            return (
                                                                <tr key={idx} className="text-center">
                                                                    <td>{idx + 1}</td>
                                                                    <td key={`key-${idx}`}>
                                                                        <input
                                                                            id="key"
                                                                            name="key"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("system-settings.key")}
                                                                            value={item.key}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td key={`value-${idx}`}>
                                                                        <input
                                                                            id="value"
                                                                            name="value"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("system-settings.value")}
                                                                            value={item.value}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {/* <i className="fa fa-trash" style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.removeRecord(idx)}></i> */}
                                                                        <button type="button" name="remove" className="remove btn btn-sm btn-white delete" onClick={() => this.removeRecord(idx)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("system-settings.edit-setting")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("system-settings.system-settings")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { systemSettings } = state;

    return {
        systemSettingEditData: systemSettings.system_setting_edit_data,
        updateSystemSettingResult: systemSettings.system_setting_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editSystemSetting: (id) => dispatch(editSystemSetting(id)),
        updateSystemSetting: (data) => dispatch(updateSystemSetting(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SystemSettingEdit));
