import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const getPromotion = (data) => dispatch => {
    axios.get('promotion/list', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_PROMOTION,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => dispatch =>
{
    axios.get('promotion/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postPromotion = (data) => dispatch => {
    axios.post('promotion/store', data)
        .then( (response) => {
            dispatch ({
                type: type.POST_PROMOTION,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
  };

  export const showPromotion = (data) => dispatch => {
    axios.get('promotion/show', {
        params: {
            "promotion_id": data['promotion_id']
        }
    }).then((response) => {
        dispatch({
            type: type.SHOW_PROMOTION,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updatePromotion = (data) => dispatch => {
    axios.post('promotion/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_PROMOTION,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePromotionStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('promotion/changeStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_PROMOTION,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePromotionShow = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('promotion/changeShow', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_SHOW_PROMOTION,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deletePromotion = (data) => dispatch => {
    axios.post('promotion/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_PROMOTION,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getType = (data) => dispatch => {
    axios.get('promotion/type', {
        
    }).then((response) => {
        dispatch({
            type: type.GET_TYPE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getMemberGroup = (data) => dispatch => {
    axios.get('promotion/member_group', {
        
    }).then((response) => {
        dispatch({
            type: type.GET_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getCategory = (data) => dispatch => {
    axios.get('promotion/category', {
        
    }).then((response) => {
        dispatch({
            type: type.GET_CATEGORY,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};