import * as actionType from "./types";
import axios from "../../../common/config/axios";

export const getMenu = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get("menu", {
                params: {
                    id: id,
                },
            })
            .then((response) => {
                dispatch({
                    type: actionType.LIST,
                    value: response.data,
                });
            })
            .catch((err) => {
                reject(err);
            });
    });
    // axios.get('menu',{
    //   params: {
    //       "id": id,
    //   }
    // })
    // .then(function (response)
    // {
    //   dispatch (
    //   {
    //     type: actionType.LIST,
    //     value: response.data
    //   })
    // })
    // .catch(err =>
    // {

    // });
};
