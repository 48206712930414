import React, { Component } from "react";
import { connect } from "react-redux";
import { editGameCategory, updateGameCategory } from "../../store/ducks/gameCategory/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";

class GameCategoryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            status: "",
            country_assignment: "",
            sequence: "",
            loading: true,

            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        formValidation("game_category_form");
        this.props.editGameCategory(this.props.gameCategoryEditId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[GameCategoryEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.gameCategoryEditData !== undefined && nextProps.gameCategoryEditData !== this.props.gameCategoryEditData && nextState.loading === true) {
            let gameCategoryData = nextProps.gameCategoryEditData.data.gameCategory;

            this.setState({
                id: gameCategoryData.gameId,
                status: gameCategoryData.status,
                country_assignment: gameCategoryData.countryAssignmentId === null ? 0 : 1,
                sequence: gameCategoryData.sequence,
                loading: false,
            });
        }

        if (nextProps.updateGameCategoryResult !== this.props.updateGameCategoryResult) {
            if (nextProps.updateGameCategoryResult.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateGameCategoryResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateGameCategoryResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateGameCategoryResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateGameCategoryHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateGameCategoryData = {};
                updateGameCategoryData.id = this.state.id;
                updateGameCategoryData.status = this.state.status;
                updateGameCategoryData.country_assignment = this.state.country_assignment;
                updateGameCategoryData.country_id = this.props.currentUser.country_id;
                updateGameCategoryData.sequence = this.state.sequence;
                // console.log(updateGameCategoryData);
                this.props.updateGameCategory(updateGameCategoryData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleNumberChange = (event) => {
        this.setState({
            [event.target.name]: Number(event.target.value),
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            let status_option = [
                { label: t("global.active"), value: 1 },
                { label: t("global.inactive"), value: 0 },
            ];

            let country_option = [
                { label: t("global._yes"), value: 1 },
                { label: t("global._no"), value: 0 },
            ];

            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("game-category.edit-game-category")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="game_category_form" className="form-horizontal" onSubmit={this.updateGameCategoryHandler} noValidate>
                                    <ValidationError formName="game_category_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="status">{t("global.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    options={status_option}
                                                    value={this.state.status}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="country_assignment">{t("game-category.country-assignment")}</label>
                                                <Select
                                                    id="country_assignment"
                                                    name="country_assignment"
                                                    options={country_option}
                                                    value={this.state.country_assignment}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="from-group">
                                                <label>{t("game-category.sequence")}</label>
                                                <NumberInput
                                                    id="sequence"
                                                    name="sequence"
                                                    className="form-control"
                                                    value={this.state.sequence}
                                                    onChange={this.handleNumberChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 form-group mb-0 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("game-category.edit-game-category")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("game-category.title")}</h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { gameCategory, currentUser } = state;

    return {
        gameCategoryEditData: gameCategory.editGameCategory,
        updateGameCategoryResult: gameCategory.updateGameCategory,
        currentUser: currentUser.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editGameCategory: (id) => dispatch(editGameCategory(id)),
        updateGameCategory: (updateGameCategoryData) => dispatch(updateGameCategory(updateGameCategoryData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameCategoryEdit));
