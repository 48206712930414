import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getShipmentsPayment, getShipmentsPaymentDropDown } from "../../store/ducks/shipments/actions";
import ShipmentsPaymentListing from "./ShipmentsPaymentListing";
import ShipmentsPaymentShow from "./ShipmentsPaymentShow";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from 'sweetalert2';
import axios from "../../common/config/axios";
// import Button from "../../components/Button/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen } from "@fortawesome/free-solid-svg-icons";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import moment from 'moment';

const initialInputState = {
	order_code: "",
    status: "",
	transaction_date: '',
	start_date: '',
    end_date: '',
	email: '',
	tracking_number: '',
	parcel_number: '',
	courier_name: '',
	shipment_type: ''
};

let shipmentListingData = {};
class ShipmentsPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageShow: "listing",
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			totalPages: 0,
			totalRecords: 0,
			data: [],
			dropDown: [],
			status: [
                {
                    "value": 0,
                    // "label": "Pending"
                },
                {
                    "value": 1,
                    // "label": "Success"
                },
				{
					"value": 4, // Cancelled
				}
            ],
			filter: initialInputState,
			currentShipmentId: "",
			loading: true,
			permission: [],
			currentOrderCode: "",
			currentCusId: "",
			totalCost: 0,
			discountAmount: 0,
		};
		this.onInputChange = this.onInputChange.bind(this);
  	}

	fetchData() {
		let availablePage = this.state.activePage;
		if (this.state.totalRecords !== 0) {
			availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
			this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
		}

		shipmentListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
		shipmentListingData.limit = this.state.limit;
		shipmentListingData.order = JSON.stringify(this.state.order);
		shipmentListingData.totalRecord = this.state.totalRecords;
		Object.keys(this.state.filter).forEach((val) =>
		{
			// listingArr[val] = this.state.filter[val];
			shipmentListingData[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
		});
		this.props.getShipmentsPayment(shipmentListingData);
  	}

	componentDidMount() {
		this.fetchData();
		this.props.getShipmentsPaymentDropDown();
	}

  	shouldComponentUpdate(nextProps, nextState) {
    	// const { t } = this.props;

		if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
			let shipmentPermission = this.props.currentPermission.filter((item) => item.includes("shipment_"));
			this.setState({ permission: shipmentPermission });
		}
		
		if (nextProps.shipment_payment !== this.props.shipment_payment && nextProps.shipment_payment !== undefined) {
			if (nextProps.shipment_payment.data.responseCode === 200 && nextProps.shipment_payment.data.msgType === 'success') {
				this.setState({
					data: nextProps.shipment_payment.data.data.result.row,
					totalRecords: nextProps.shipment_payment.data.data.result.pagination.total_record,
					totalPages: nextProps.shipment_payment.data.data.result.pagination.total_page,
					loading: false,
				});
				// Swal.close();
			} else {
				this.setState({
                    loading: true,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.shipment_payment.data.message);
                });
			}
		}

		if (nextProps.shipment_payment_drop_down !== this.props.shipment_payment_drop_down && nextProps.shipment_payment_drop_down !== undefined) {
			if (nextProps.shipment_payment_drop_down.data.responseCode === 200 && nextProps.shipment_payment_drop_down.data.msgType === 'success') {
				this.setState({
					dropDown: nextProps.shipment_payment_drop_down.data.data,
				});
			} else {
				this.setState({
                    loading: true,
                    dropDown: []
                }, () => 
                {
                    filterErrorHandler(nextProps.shipment_payment_drop_down.message);
                });
			}
		}
		
		return true;
	}
	
	onInputChange(event) {
		let newFilter = Object.assign({}, this.state.filter);
		newFilter[event.target.id] = event.target.value;

		if(event.target.id==='transaction_date')
		{
			const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
			newFilter['start_date'] = (date) ? date[0] : '';
			newFilter['end_date'] = (date) ? date[1] : '';
		}
		this.setState({
			filter: newFilter,
		});
	}

  	refreshHandler = () => {
    	this.setState({
			loading: true,
			data: [],
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null
      	}, () => {
			this.fetchData();
		});
	};

  	clearHandler = () => {
    	let newFilter = initialInputState;
    	this.setState({
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			searchValue: "",
			loading: true,
			filter: newFilter,
			data: [],
		}, () => {
			this.fetchData();
		});
	};

  	pageChangeHandler = (activePage) => {
    	this.setState(
			{ 
				page: activePage, 
				activePage: activePage, 
				loading: true, 
				data: [] 
			}, () => {
				this.fetchData();
			}
		);
	};
	
	changePageHandler = (e,page, orderId, cusId, totalCost, discountAmount) => {
		e.preventDefault();
		this.setState({
			pageShow: page,
			currentOrderCode: orderId,
			currentCusId: cusId,
			totalCost: totalCost,
			discountAmount
		});
	};
	
	datatablesChangeHandler = (val, act) => {
		this.setState({
			[act]: act === "searchValue" ? val.target.value : val,
			loading: true,
			data: [],
      	}, () => {
			this.fetchData();
      	});
  	};

  	onSort = (field, order) => {
    	const orderArry = [{}];
    	let orderSequence = order === "asc" ? true : false;

		orderArry[0]["id"] = field;
		orderArry[0]["desc"] = orderSequence;

    	this.setState(
      	{
			order: orderArry,
			loading: false,
			data: [],
			// errMsg: [],
			// touched: false,
      	}, () => {
			this.fetchData();
      	});
  	};

  	actionFormatter = (cell, row) => {
    	let editButton, output;

    	if (this.state.permission.includes("shipment_payment_access")) {
			if(row.checkStatus === 0 || row.checkStatus === 4) {
				editButton = (
					<p>
						{row.reference_code}
					</p>
					// <Button
					// typeButton="button"
					// classValue="btn btn-sm btn-white"
					// key={`edit-${row.shipmentId}`}
					// //idValue={row.shipmentId === undefined ? 0 : row.shipmentId}
					// clicked={() => this.changePageHandler("edit", row.shipmentId)}
					// buttonName={<FontAwesomeIcon icon={faPen} />}
					// />
				  );
			}else{
				editButton = (
					<a href="/#" className="badgeBtn" onClick={(event) => this.changePageHandler(event, "show", row.reference_code, row.cusId, row.totalCost, row.discountAmount)}>
						{row.reference_code}
					</a>
					// <Button
					// typeButton="button"
					// classValue="btn btn-sm btn-white"
					// key={`edit-${row.shipmentId}`}
					// //idValue={row.shipmentId === undefined ? 0 : row.shipmentId}
					// clicked={() => this.changePageHandler("edit", row.shipmentId)}
					// buttonName={<FontAwesomeIcon icon={faPen} />}
					// />
				  );
			}
      		
    	}
		output = [editButton];
		return output;
  	};

	  exportExcelHandler = () => 
		{
			Swal.fire({
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				didOpen: () => 
				{
					Swal.showLoading();
				}
			});
	
			let availablePage = this.state.activePage;
			if (this.state.totalRecords !== 0) {
				availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
				this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
			}
	
			shipmentListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
			shipmentListingData.limit = this.state.limit;
			shipmentListingData.order = JSON.stringify(this.state.order);
			shipmentListingData.totalRecord = this.state.totalRecords;
			Object.keys(this.state.filter).forEach((val) =>
			{
				// listingArr[val] = this.state.filter[val];
				shipmentListingData[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
			});
			// data.order = JSON.stringify(this.state.order);
	
			axios
				.get("shipment_payment/export_excel", {
					params: shipmentListingData,
					responseType: "blob",
				})
				.then((res) => {
					const currentDate = new Date();
					const timestamp = moment(currentDate).format("YYMMDD HHmmss");
					let fileName = "100Express Report - Shipment Payment - " + timestamp;
					const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
					const aElement = document.createElement("a");
					const href = window.URL.createObjectURL(blob);
					aElement.href = href;
					aElement.download = fileName;
					document.body.appendChild(aElement);
					aElement.click();
					document.body.removeChild(aElement);
					window.URL.revokeObjectURL(href);
					Swal.close();
				})
				.catch((err) => {
					console.log("Excel - Shipment Payment Error", err);
				});
		};
	
	render() {
		// console.log(this.state.dropDown)
		const { t } = this.props;
        let stCls = {
            0: "primary",
            1: "success",
			4: "danger"
        };
    	let dataListing = [];
    	const columns = [
			{
				text: t("global.no"),
				dataField: "",
				keyField: "no",
				formatter: (cell, row, rowIndex) => {
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
			},
			{
				text: t("shipment-payment.date"),
				dataField: "create_date",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("member.email"),
				dataField: "email",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("shipment-payment.courier"),
				dataField: "courier",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.shipment_type"),
				dataField: "freight",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("shipment-payment.order-code"),
				dataField: "reference_code",
				sort: true,
				onSort: this.onSort,
				formatter: this.actionFormatter
			},
            {
				text: t("shipment-payment.amount"),
				dataField: "debit",
				sort: true,
				onSort: this.onSort,
			},
            {
				text: t("shipment-payment.status"),
				dataField: "status",
				sort: true,
				onSort: this.onSort,
			},
		];
		
		if (this.state.pageShow === "listing") {
			if (this.state.data.length > 0) {
				dataListing = this.state.data.map((val, idx) => {
                    let stVal = "";
                    stVal = (
                        <span
                            className={`badge-${stCls[val.status]
                                }`}
                        >
                            {t(`transaction.status-desc.${val.status}`)}
                        </span>
                    );
					

					return {
                        id: val.id,
						create_date: val.create_date,
						email: val.email,
						courier: (val.courier) && t(`shipment-payment.${val.courier.toUpperCase()}`),
						freight: (val.freight) && t(`shipment-payment.${val.freight}`),
						reference_code: val.reference_code,
                        debit: val.debit,
                        status: stVal,
						checkStatus: val.status,
						cusId: val.cus_id,
						totalCost: val.debit,
						discountAmount: val.discount_amount
          			};
        		});
      		}
    	}
		
		return (
			<Auxiliary>
				{this.state.pageShow === "listing" && (
					<ShipmentsPaymentListing
						filter={this.state.filter}
						clearHandler={() => this.clearHandler()}
						refreshHandler={() => this.refreshHandler()}
						onInputChange={(event) => this.onInputChange(event)}
						datatablesChangeHandler={(val, act) =>
						this.datatablesChangeHandler(val, act)
						}
						columns={columns}
						activePage={this.state.activePage}
						dataListing={dataListing}
						limit={this.state.limit}
						totalRecords={this.state.totalRecords}
						loading={this.state.loading}
						permission={this.state.permission}
						pageChangeHandler={this.pageChangeHandler}
						ddStatus={this.state.status}
						ddCourier={this.state.dropDown.shipment_courier}
						ddTransport={this.state.dropDown.shipment_transport}
						changePageHandler={this.changePageHandler}
						exportExcelHandler={this.exportExcelHandler}
					/>
        		)}

				{this.state.pageShow === "show" && (
					<ShipmentsPaymentShow 
						orderCode={this.state.currentOrderCode}
						cusId={this.state.currentCusId}
						totalCost={this.state.totalCost}
						discountAmount={this.state.discountAmount}
						changePageHandler={(event, page) => this.changePageHandler(event, page)} 
					/>
				)}
      		</Auxiliary>
    	);
  	}
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
	const { currentUser, shipment } = state;
	// console.log(shipment)
	//Get the state from reducers current limit, current activePage and search value
	return {
		currentPermission: currentUser.data.permission,
		shipment_payment: shipment.shipment_payment_list_result,
		shipment_payment_drop_down: shipment.shipment_payment_drop_down
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getShipmentsPayment: (filter) => dispatch(getShipmentsPayment(filter)),
		getShipmentsPaymentDropDown: () => dispatch(getShipmentsPaymentDropDown())
  	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ShipmentsPayment));
