import React from "react";
import PropTypes from "prop-types";

const ValidationError = (props) => {
    let display = null;
    let touched = props.touched === undefined ? false : props.touched;
    if (touched && props.message !== undefined && props.message !== []) {
        display = (
            <div id={props.formName} className="alert alert-solid-danger rounded detail__request" role="alert">
                <ul>
                    {props.message.map((m, index) => {
                        return <li key={index}>{m}</li>;
                    })}
                </ul>
            </div>
        );
    }
    return display;
};

export default ValidationError;

ValidationError.propTypes = {
    formName: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    touched: PropTypes.bool,
};
