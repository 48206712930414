import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    // data: [],
};

const permissionReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),
    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
});

export default permissionReducer;