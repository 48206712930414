import axios from "../../../common/config/axios";
import * as type from "./types";

export const getAnnouncements = (data) => (dispatch, getState) => {
    axios
        .get("announcement/list", {
            params: {
                ...data,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Announcement Error" + err);
            dispatch({
                type: type.ANNOUNCEMENT_LIST,
                payload: [],
            });
        });
};

export const createAnnouncement = () => (dispatch, getState) => {
    axios
        .get("announcement/create", {
            params: {
                country_shortcode: getState().currentUser.data.country_code,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_CREATE,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Create Announcement Error" + err);
            dispatch({
                type: type.ANNOUNCEMENT_CREATE,
                payload: [],
            });
        });
};

export const storeAnnouncement = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("announcement/store", data)
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Announcement Error: " + err);
            dispatch({
                type: type.SET_VALIDATION,
                payload: [],
            });
        });
};

export const showAnnouncement = (id) => (dispatch, getState) => {
    axios
        .get("announcement/show", {
            params: {
                id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_SHOW,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Announcement Error" + err);
            dispatch({
                type: type.ANNOUNCEMENT_SHOW,
                payload: err.response,
            });
        });
};

export const editAnnouncement = (id) => (dispatch, getState) => {
    axios
        .get("announcement/edit", {
            params: {
                id: id,
                country_shortcode: getState().currentUser.data.country_code,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Announcement Error" + err);
            dispatch({
                type: type.ANNOUNCEMENT_EDIT,
                payload: err.response,
            });
        });
};

export const updateAnnouncement = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;

    axios
        .post("announcement/update", data)
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Announcement Error" + err);
        });
};

export const updateAnnouncementStatus = (data) => (dispatch) => {
    axios
        .post("announcement/changeStatus", data)
        .then((res) => {
            dispatch({
                type: type.ANNOUNCEMENT_UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Announcement Status Error" + err);
        });
};
