import React, {Component} from 'react';
import {connect} from 'react-redux';
//import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import {showWalletType} from "../../store/ducks/walletType/actions";

class WalletTypeShow extends Component {
    state = {
        id: '',
        wallet_type: '',
        wallet_code: '',
        status: '',
        loading: true
    };

    componentDidMount(){
        //Swal.showLoading();

        const showWalletTypeData = {};
        showWalletTypeData.wallet_type_id = this.props.show_wallet_type_id;
        this.props.showWalletType(showWalletTypeData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_wallet_type_data !== this.props.show_wallet_type_data) {
            if (nextProps.show_wallet_type_data.data.responseCode === 200) {
                //Swal.close();

                this.setState({
                    loading: false,
                    wallet_type: nextProps.show_wallet_type_data.data.data[0].name,
                    wallet_code: nextProps.show_wallet_type_data.data.data[0].walletCode,
                    status: nextProps.show_wallet_type_data.data.data[0].status
                })
            } else {
                return false;
            }
        }

        return true;
    }

    render() {
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">Wallet Type</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">Show Wallet Type</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                    <tbody>
                                    <tr>
                                        <th>Wallet Type:</th>
                                        <td field-key='wallet_type'>{this.state.wallet_type}</td>
                                    </tr>

                                    <tr>
                                        <th>Wallet Code:</th>
                                        <td field-key='wallet_code'>{this.state.wallet_code}</td>
                                    </tr>

                                    <tr>
                                        <th>Status: </th>
                                        <td field-key='status'>{this.state.status === 1 ? 'Active' : 'Inactive'}</td>
                                    </tr>

                                    </tbody>
                                </table>
                                }

                                <Button
                                    typeButton="button"
                                    classValue="btn btn-secondary"
                                    idValue="back"
                                    buttonName="Back to list"
                                    clicked={() => this.props.changePageHandler('listing')}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const {walletType} = state;

    return {
        show_wallet_type_data: walletType.show_wallet_type_data
    }
};

const mapDispatchToProps = {
    showWalletType
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletTypeShow);