import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
// import moment from "moment";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../assets/images/updown-arrow.png'

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

class WithdrawalListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            //(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
            (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let stCls = {
            0: "primary",
            1: "success",
            2: "danger",
            3: "secondary",
            4: "danger",
        };
        // let permission = this.props.currentUser.permission
        //     ? this.props.currentUser.permission
        //     : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("transaction.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            // {text: 'trans.id', dataField: 'id'},
            {
                text: t("transaction.order-id"),
                dataField: "order_id",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "115px" },
            },
            {
                text: t("transaction.agent"),
                dataField: "username",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("deposit.name"),
                dataField: "name",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("transaction.transaction-channel"),
                dataField: "transaction_channel",
                sort: true,
                onSort: this.props.onSort,
            },
            // {text: t('transaction.bank-details'), dataField: 'bank_acc_number', sort: true, onSort: this.props.onSort},
            {
                text: t("transaction.amount"),
                dataField: "debit",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "120px" },
            },
            {
                text: t("transaction.status"),
                dataField: "status",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "96px" },
            },
            { text: t('transaction.approval-time'), dataField: 'timediff', sort: true, onSort: this.props.onSort },
            { text: t('transaction.create-date'), dataField: 'create_date', sort: true, onSort: this.props.onSort },
            // {text: t('transaction.update-date'), dataField: 'update_date', sort: true, onSort: this.props.onSort},
            {
                text: t("transaction.update-by"),
                dataField: "update_by",
                sort: true,
                onSort: this.props.onSort,
            },
            // {text: t('transaction.debit'), dataField: 'debit', sort: true, onSort: this.props.onSort, headerStyle: { width: '120px' }},
        ];

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stVal = "";
                let orderIdVal = "";
                let userNameVal = "";
                // let bankDetails = '';
                const num_format = {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                };

                // var startDate = moment(val.create_date, "YYYY-MM-DD HH:mm:ss");
                // var endDate = moment(val.approve_date, "YYYY-MM-DD HH:mm:ss");
                // let approvalTime = endDate.diff(startDate, "seconds");
                // let approvalTimeCls = "";
                // if (val.status !== 0) {
                //     approvalTimeCls =
                //         approvalTime <= 60
                //             ? "light"
                //             : approvalTime > 60 && approvalTime <= 180
                //             ? "warning"
                //             : "dark";
                // }
                // approvalTimeCls =
                //     approvalTimeCls !== "" ? (
                //         <span className={`badge badge-${approvalTimeCls}`}>
                //             &nbsp;
                //         </span>
                //     ) : (
                //         ""
                //     );

                stVal = (
                    <span className={`badge-${stCls[val.status]}`}>
                        {/* &nbsp;{approvalTimeCls}{" "} */}
                        {t(`transaction.status-desc.${val.status}`)}{" "}
                    </span>
                );
                orderIdVal = this.props.permission.includes(
                    "withdrawal_details_access"
                ) ? (
                    <b>
                        <a
                            href="/#"
                            onClick={(event) =>
                                this.props.detailsHandler(event, val.id)
                            }
                        >
                            {val.order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                );
                // bankDetails = (val.bank_name!==null) ? `${val.bank_name}(${val.swift_code}) - ${val.bank_acc_number}` : '-';
                userNameVal = this.props.permission.includes("agent_view") ? (
                    <b>
                        <a href="/#" onClick={(event) => this.props.redirectHandler(event, val.agent_id, val.username)}>
                            {val.username}
                        </a>
                    </b>
                ) : (
                    val.username
                );

                return {
                    no: idx,
                    order_id: orderIdVal,
                    // username: val.username,
                    username: userNameVal,
                    name:
                        val.name.length > 15
                            ? val.name.substring(0, 15) + "..."
                            : val.name,
                    transaction_channel: t(
                        `transaction-channel.${val.transaction_channel}`
                    ),
                    // bank_acc_number: bankDetails,
                    debit: new Intl.NumberFormat("en-US", num_format).format(
                        val.debit
                    ),
                    status: stVal,
                    timediff: val.timediff,
                    create_date: val.create_date,
                    // update_date: val.update_date,
                    update_by: val.update_by,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("withdrawal.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            {/* <form method='post' id='filter_transaction_form' className='form-horizontal filter-function'> */}
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-1`}
                                    >
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.create-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon
                                                            icon={faCalendarCheck}
                                                        />
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format='YYYY/MM/DD'
                                                        value={
                                                            this.props.filter
                                                                .transaction_date
                                                        }
                                                        onChange={
                                                            this.props.onInputChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.agent")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.order-id")}
                                                </label>
                                                <StringInput
                                                    id="order_id"
                                                    name="order_id"
                                                    value={this.props.filter.order_id}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.status")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddStatus &&
                                                            this.props.ddStatus.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction.${val.desc}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.bank-account-no")}
                                                </label>
                                                <NumberInput
                                                    id="bank_acc_no"
                                                    name="bank_acc_no"
                                                    value={
                                                        this.props.filter.bank_acc_no
                                                    }
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onKeyPress={(evt) =>
                                                        (evt.key === "e" &&
                                                            evt.preventDefault()) ||
                                                        (evt.key === "Enter" &&
                                                            this.props.refreshHandler())
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.amount")}
                                                </label>
                                                <div className="input-group">
                                                    <NumberInput
                                                        id="min_amount"
                                                        name="min_amount"
                                                        value={this.props.filter.min_amount}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.min")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />{" "}
                                                    <span style={{ margin: "auto 5px" }}>
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <NumberInput
                                                        id="max_amount"
                                                        name="max_amount"
                                                        value={this.props.filter.max_amount}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.max")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.transaction-channel")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_channel"
                                                        name="transaction_channel"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.transaction_channel}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddTransactionChannel &&
                                                            this.props.ddTransactionChannel.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction-channel.${val.slug}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="">
                                    <div className="d-flex pb-3">
                                        <h4 className="card-title mg-b-0">
                                            {t("withdrawal.listing")}
                                        </h4>
                                    </div>
                                    {/* <div className="d-flex my-xl-auto right-content">
                                        <div className="my-auto">
                                            <u>
                                                {t(
                                                    "transaction.audit-guideline"
                                                )}
                                            </u>
                                            <br />
                                            <span
                                                className={`badge badge-light`}
                                            >
                                                &nbsp;
                                            </span>{" "}
                                            {t("transaction.audit-guideline-1")}
                                            <span
                                                className={`badge badge-warning ml-3`}
                                            >
                                                &nbsp;
                                            </span>{" "}
                                            {t("transaction.audit-guideline-2")}
                                            <span
                                                className={`badge badge-dark ml-3`}
                                            >
                                                &nbsp;
                                            </span>{" "}
                                            {t("transaction.audit-guideline-3")}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(WithdrawalListing);
