import React, { Component } from "react";
import { connect } from "react-redux";
import { editGameList, updateGameList } from "../../store/ducks/gameList/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class GameListEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            vendor: "",
            game_type: "",
            game_category: "",
            game_title: "",
            game_code: "",
            status: "",
            fav: "",
            demo: "",
            dropDown: {
                game_type_option: [],
                game_category_option: [],
            },
            loading: true,

            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        formValidation("game_list_form");
        this.props.editGameList(this.props.gameListEditId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[GameListEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.gameListEditData !== undefined && nextProps.gameListEditData !== this.props.gameListEditData && nextState.loading === true) {
            let gameListData = nextProps.gameListEditData.data.gameList;
            let gameTypeData = nextProps.gameListEditData.data.gameType;
            let gameCategoryData = nextProps.gameListEditData.data.gameCategory;

            this.setState({
                id: gameListData.gameListId,
                game_type: gameListData.gameType,
                game_category: gameListData.gameCategory,
                game_title: gameListData.gameTitle,
                game_code: gameListData.gameCode,
                status: gameListData.status,
                fav: gameListData.fav,
                demo: gameListData.demo,
                vendor: gameListData.vendorSlug,
                dropDown: {
                    game_type_option: gameTypeData,
                    game_category_option: gameCategoryData,
                },
                loading: false,
            });
        }

        if (nextProps.updateGameListResult !== this.props.updateGameListResult) {
            if (nextProps.updateGameListResult.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateGameListResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateGameListResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateGameListResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateGameListHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateGameListData = {};
                updateGameListData.id = this.state.id;
                updateGameListData.game_type = this.state.game_type;
                updateGameListData.game_category = this.state.game_category;
                updateGameListData.game_title = this.state.game_title;
                updateGameListData.game_code = this.state.game_code;
                updateGameListData.status = this.state.status;
                updateGameListData.fav = this.state.fav;
                updateGameListData.demo = this.state.demo;

                this.props.updateGameList(updateGameListData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            let ddGameType;
            ddGameType = this.state.dropDown.game_type_option.map((val, idx) => {
                return (
                    <option key={val.gameTypeId} value={val.gameTypeSlug}>
                        {t(`game-type.${val.gameTypeSlug}`)}
                    </option>
                );
            });

            let ddGameCategory;
            ddGameCategory = this.state.dropDown.game_category_option.map((val, idx) => {
                return (
                    <option key={idx} value={val.gameCategoryId}>
                        {t(`game-list.${val.gameCategorySlug}`)}
                    </option>
                );
            });

            let status_option = [
                { label: t("global.active"), value: 1 },
                { label: t("global.inactive"), value: 0 },
            ];

            let fav_option = [
                { label: t("global._yes"), value: 1 },
                { label: t("global._no"), value: 0 },
            ];

            let demo_option = [
                { label: t("global._yes"), value: 1 },
                { label: t("global._no"), value: 0 },
            ];

            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("game-list.edit-game-list")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="game_list_form" className="form-horizontal" onSubmit={this.updateGameListHandler} noValidate>
                                    <ValidationError formName="game_list_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="vendor">{t("game-list.vendor")}</label>
                                                <StringInput
                                                    id="vendor"
                                                    name="vendor"
                                                    className="form-control"
                                                    placeholder={t("game-list.vendor")}
                                                    value={t(`vendor.${this.state.vendor}`)}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="game_type">{t("game-list.game-type")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control datatable-select"
                                                        id="game_type"
                                                        name="game_type"
                                                        onChange={this.handleChange}
                                                        value={this.state.game_type}>
                                                        {ddGameType}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="game_category">{t("game-list.game-category")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control datatable-select"
                                                        id="game_category"
                                                        name="game_category"
                                                        onChange={this.handleChange}
                                                        value={this.state.game_category}>
                                                        <option value=''>&nbsp;</option>
                                                        {ddGameCategory}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="game_title">{t("game-list.game-title")}</label>
                                                <StringInput
                                                    id="game_title"
                                                    name="game_title"
                                                    className="form-control"
                                                    placeholder={t("game-list.game-title")}
                                                    value={this.state.game_title}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="game_code">{t("game-list.game-code")}</label>
                                                <StringInput
                                                    id="game_code"
                                                    name="game_code"
                                                    className="form-control"
                                                    placeholder={t("game-list.game-code")}
                                                    value={this.state.game_code}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="status">{t("global.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    options={status_option}
                                                    value={this.state.status}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="pt">{t("game-list.hot")}</label>
                                                <Select id="fav" name="fav" options={fav_option} value={this.state.fav} onChange={this.handleChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="demo">{t("game-list.demo")}</label>
                                                <Select
                                                    id="demo"
                                                    name="demo"
                                                    options={demo_option}
                                                    value={this.state.demo}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 form-group mb-0 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("game-list.edit-game-list")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("game-list.game-list")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { gameList } = state;

    return {
        gameListEditData: gameList.game_list_edit_data,
        updateGameListResult: gameList.game_list_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editGameList: (id) => dispatch(editGameList(id)),
        updateGameList: (updateGameListData) => dispatch(updateGameList(updateGameListData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameListEdit));
