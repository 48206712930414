import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('reward_item/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const getDropDown = () => (dispatch, getState) =>
{
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get('reward_item/drop_down', {params: data})
    .then(function (response)
    {
        dispatch (
        {
            type: actionType.DROPDOWN,
            value: response.data
        })
    })
    .catch(err =>
    {

    });
};

export const getDetails = (id) => (dispatch, getState) =>
{   
    if(id > 0)
    {
        axios.get('reward_item/details',{
            params: {
                "id": id,
                "country_id": getState().currentUser.data.country_id
            }
        })
        .then(function (response) 
        {
            dispatch (
            {
                type: actionType.DETAILS,
                value: response.data
            })
        })
        .catch(function (error) 
        {
        
        });
    }
};

export const updateStatus = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    axios.post('reward_item/update_status', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const update = (data) => dispatch =>
{
    // console.log(data);
    axios.post('reward_item/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const store = (data) => dispatch =>
{
    // console.log(data);
    axios.post('reward_item/store', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getHistory = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    data.lang_id = getState().currentUser.data.language_id;
    axios.get("reward_item_history/list", {params: data})
    .then((res) => {
        dispatch({
            type: actionType.HISTORY,
            value: res.data
        })
    })
    .catch((err) => {

    })
};

export const getHistoryDetails = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    data.lang_id = getState().currentUser.data.language_id;
    data.language = getState().currentUser.data.language_code;
    axios.get("reward_item_history/details" , {params: data})
    .then((res) => {
        dispatch({
            type: actionType.HISTORY_DETAILS,
            value: res.data
        })
    })
    .catch((err) => {

    })
};

export const updateHistoryDetails = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    data.lang_id = getState().currentUser.data.language_id;
    axios.post("reward_item_history/update", data)
    .then((res) => {
        dispatch({
            type: actionType.HISTORY_UPDATE,
            value: res.data
        })
    })
    .catch((err) => {

    })
};

export const refundHistory = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("reward_item_history/refund", data)
    .then((res) => {
        dispatch({
            type: actionType.HISTORY_REFUND,
            value: res.data
        });
    })
    .catch((err) => {

    })
}