import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getSpecialBirthdayGift = (data) => dispatch => {
    axios.get('special-birthday-gift/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "country_id": data['country_id'],
            "search": data['search']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_SPECIAL_BIRTHDAY_GIFT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const postSpecialBirthdayGift = (data) => dispatch => {
    axios.post('special-birthday-gift/store',data)
        .then( (response) => {
            dispatch ({
                type: type.POST_SPECIAL_BIRTHDAY_GIFT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

//Call the api for example call the login api
export const getMembers = (data) => dispatch => {
    axios.get('special-birthday-gift/members', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_SPECIAL_BIRTHDAY_GIFT_MEMBERS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const showSpecialBirthdayGift = (data) => dispatch => {
    axios.get('special-birthday-gift/show', {
        params: {
            "id": data['special_birthday_gift_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_SPECIAL_BIRTHDAY_GIFT,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateSpecialBirthdayGift = (data) => dispatch => {
    axios.post('special-birthday-gift/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_SPECIAL_BIRTHDAY_GIFT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteSpecialBirthdayGift = (data) => dispatch => {
    axios.post('special-birthday-gift/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_SPECIAL_BIRTHDAY_GIFT,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};