import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AiFillPlusCircle } from "react-icons/ai";

import Select from "../../components/Input/Select/Select";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class LuckySpinGiftAssignListing extends Component 
{
    /* ============ component lifecycle [START] ============ */
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            isOpen: false,
            currentImage: null,
        };
    }
    /* ============ component lifecycle [END] ============ */

    render() 
    {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: t('lucky-spin-gift-assign.member'),
                dataField: "username",
                sort: true,
                onSort: this.props.onSort,
                // hidden: true
            },
            { 
                text: t("global.status"), 
                dataField: "status", 
                formatter: (cell, row, rowIndex) => 
                {
                    const { t } = this.props;
                    let statusArray = [
                        { label: t("global.active"), value: 1, selected: true },
                        { label: t("global.inactive"), value: 0 },
                    ];
                    let stCls = row.status === 1 ? "active" : "inactive";
                    let stVal = permission.includes("lucky_spin_gift_assign_update") ? (
                        <Select
                            id={row.id}
                            name={row.id}
                            options={statusArray}
                            defaultValue={row.status}
                            onChange={(event) => {
                                this.props.updateStatusHandler(event)
                            }}
                            className={`form-control datatable-select-${stCls}`}
                        />
                    ) : (
                        <span className={`badge-pill badge-${row.status === 1 ? "success" : "danger"}`}>
                            {row.status && row.status === 1 ? t("global.active") : t("global.inactive")}
                        </span>
                    );
                    return stVal;
                },
                headerStyle: { width: "115px" } 
            },
            { 
                text: t("global.action"), 
                dataField: "", 
                headerStyle: { width: "115px" }, 
                formatter: (cell, row, rowIndex) => 
                {
                    let actionVal = permission.includes("lucky_spin_gift_assign_delete") ? (
                        <div className="btn-group">
                            <a
                                href="/#"
                                onClick={(event) =>
                                    this.props.deleteHandler(event, row.id)
                                }
                                className="btn btn-sm btn-danger rounded"
                                data-toggle="tooltip"
                                title={t('global.delete')}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </a>
                        </div>
                    ) : (
                        ""
                    );
                    return actionVal;
                },
            },
        ];
        /* ======================== datatables data assign [END] ======================== */
        
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("side-bar.lucky_spin_gift_assign")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                           <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row filter-wrapper-2`}>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("lucky-spin-gift-assign.member")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="status"
                                                    name="status"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.status}
                                                >
                                                    <option value=""></option>
                                                    <option value="1">{t("global.active")}</option>
                                                    <option value="0">{t("global.inactive")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div>
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() =>
                                                    this.props.refreshHandler()
                                                }
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() =>
                                                    this.props.clearHandler()
                                                }
                                            />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("lucky-spin-gift-assign.listing")}
                                        </h4>
                                    </div>
                                </div>
                                {permission.includes("lucky_spin_gift_assign_create") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-underline"
                                                    idValue="create"
                                                    buttonName={t("role.lucky_spin_gift_assign_create")}
                                                    clicked={() =>this.props.changePageHandler("create")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val,"activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val,"limit")}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(LuckySpinGiftAssignListing);
