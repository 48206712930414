import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import * as actions from "../../store/ducks/agent/actions";

class AgentDownline extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            activePage: 1,
            limit: 10,
            totalRecords: 0,
            searchValue: '',
            order: '',

            data: [],
            agentDetails: [],
            loading: true,
        }
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount()
    {
        if (this.props.selectId) 
        {
            const listingArr = this.getFilterArray();
            this.props.funcDownline(listingArr);
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.downline !== this.props.downline && nextProps.downline !== undefined) 
        {
            if (nextProps.downline.responseCode === 200 && nextProps.downline.msgType === 'success') 
            {
                this.setState({
                    agentDetails: nextProps.downline.data.agent,
                    data: nextProps.downline.data.result.row,
                    totalRecords: nextProps.downline.data.result.pagination.total_record,
                    loading: false,
                });
            }
        }

        return true;
    }
    /* ============ component lifecycle [END] ============ */


    /* ============ function [START] ============ */

    refreshHandler = () => 
    {
        const listingArr = this.getFilterArray();
        this.props.funcDownline(listingArr);
    }

    getFilterArray = () => 
    {
        // this.loadingHandler();
        const listingArr = {};

        listingArr.page = this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.id = this.props.selectId;

        this.setState({
            loading: true,
            data: [],
        });

        return listingArr;
    }
    /* ============ function [END] ============ */


    /* ============ datatables function [START] ============ */
    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({ order: orderArry }, () => {
            this.refreshHandler();
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val
        }, () => {
            this.refreshHandler();
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        const { t } = this.props;

        const columns = [
            { 
                text: t('global.no'), 
                dataField: 'no', 
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = ((this.state.activePage - 1) * this.state.limit) + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                }, 
                headerStyle: { width: '60px', textAlign: 'center' } 
            },
            { 
                text: t('member.member'), 
                dataField: 'username', 
                sort: true, onSort: this.onSort,
            },
            { 
                text: t('member.country'), 
                dataField: 'shortcode', 
                sort: true, onSort: this.onSort,
                formatter: (cell, row, rowIndex) => 
                {
                    return t(`global.country_shortcode.${row.shortcode}`);
                }, 
            },
        ];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div>
                            <div className='btn-group'>
                                <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                                <div className="member-name">
                                    <FontAwesomeIcon icon={faUser} /> {this.state.agentDetails.username}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className='row row-sm'>
                        <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mg-b-0'>{t('agent.agent-details')}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {(this.state.loading)
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <table className='table table-bordered table-hover'>
                                            <tbody>
                                                <tr>
                                                    <th>{t('agent.agent')}</th>
                                                    <td>{this.state.agentDetails.username}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('agent.referral-code')}</th>
                                                    <td>{this.state.agentDetails.referral_code}</td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                        {this.state.loading
                                            ?
                                            <Skeleton count={1} />
                                            :
                                            <div className="mb-0 mt-3 justify-content-end d-flex my-xl-auto right-content">
                                                <Button typeButton='button' classValue='btn btn-blue' idValue='refresh' buttonName={t('global.refresh')} clicked={() => this.refreshHandler()} />
                                            </div>
                                        }
                                </div>
                            </div>


                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mb-1'>{t('agent-payout.downline')}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive table-hover datatable'>
                                        <DataTable
                                            keyField='no'
                                            data={this.state.data}
                                            columns={columns}
                                            activePage={this.state.activePage}
                                            limit={this.state.limit}
                                            totalItems={this.state.totalRecords}
                                            paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                            sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                            searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                            searchValue={this.state.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => 
{
    return {
        downline: state.agent.downline,
    }
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        funcDownline: (filter) => dispatch(actions.downline(filter)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentDownline));