import React, { Component } from "react";
import { connect } from "react-redux";
// import Swal from "sweetalert2";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import { showAdmin } from "../../store/ducks/admins/actions";
import Skeleton from "react-loading-skeleton";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
// import ProfileIcon from '../../assets/images/profile/profileIcon.jpg';
// import { HiOutlineUserCircle } from 'react-icons/hi';    

class AdminsShow extends Component {
    state = {
        pageShow: "show",
        activePage: 1,
        id: "",
        username: "",
        email: "",
        group_id: "",
        country_id: "",
        currentCountryImage: "",
        loading: true,

        permission: [],
    };

    componentDidMount() {
        const showAdminData = {};
        showAdminData.id = this.props.show_admin_id || this.props.location.state.show_admin_id;

        this.props.showAdmin(showAdminData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let adminPermission = this.props.currentPermission.filter((item) => item.includes("admin_"));
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.show_admin_data !== this.props.show_admin_data && nextProps.show_admin_data !== undefined) {
            if (nextProps.show_admin_data.data.responseCode === 200 && nextProps.show_admin_data.data.msgType === 'success') {
                let adminData = nextProps.show_admin_data.data.data[0];
                this.setState({
                    Id: adminData.Id,
                    username: adminData.username,
                    email: adminData.email,
                    group_id: adminData.group_id,
                    country_id: adminData.country_id,
                    loading: false
                });
            }
            else {
                this.setState({
                    Id: "",
                    username: "",
                    email: "",
                    group_id: "",
                    country_id: "",
                }, () => {
                    filterErrorHandler(nextProps.show_admin_data.data.message);
                });
            }
        }
        return true;
    }

    pageChangeHandler = (activePage) => {
        this.setState({ activePage }, () => {
            const adminListingData = {};
            adminListingData.page = activePage;
            this.props.getAdmins(adminListingData);
        });
    };

    // changePageHandler = (page, Id) => {
    //     this.setState({
    //         pageShow: page,
    //         currentAdminId: Id,
    //     });

    //     if (page === "listing") {
    //         this.props.history.push("/admin");
    //     }
    // };

    // flag() {
    //     const country = this.state.country_id;
    //     if (country === "MY") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">Malaysia</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    //     if (country === "SG") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">Singapore</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    //     if (country === "TH") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">Thailand</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    //     if (country === "ID") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">Indonesia</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    //     if (country === "VN") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">Vietnam</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    //     if (country === "CN") {
    //         return (
    //             <div className=" p-t-10">
    //                 <p className="m-b-5">China</p>
    //                 <img style={{ width: "100px" }} src={this.state.currentCountryImage} alt="" />
    //             </div>
    //         );
    //     }
    // }

    render() {
        // let visible = false;
        const { t } = this.props;

        // if (this.props.show_admin_id) {
        //     // visible = true;
        // }
        return (
            <Auxiliary>
                {this.state.pageShow === "show" && (
                    <div>
                        <div className="breadcrumb-header">
                            {!this.props.profile &&
                                <div className="d-flex">
                                    <div className="my-auto">
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-back-outline"
                                            idValue="back"
                                            buttonName={<i className="angle fe fe-chevron-left"></i>}
                                            clicked={() => this.props.changePageHandler("listing")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="content-title mb-0 my-auto ml-2">{t("admin.profile")}</h4>
                                </div>
                            </div>
                        </div>
                        <div>

                            <div className="row row-sm justify-content-center">
                                {/* <div className="col-lg-4">
                                        <div className="card mg-b-20">
                                            <div className="card-body">
                                                <div className="pl-0">
                                                    <div className="main-profile-overview">
                                                        <div className="main-img-user profile-user">
                                                            <img
                                                                alt=""
                                                                src="https://icons-for-free.com/iconfiles/png/512/avatar+person+profile+user+icon-1320086059654790795.png"
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-between mg-b-20">
                                                            <div>
                                                                <h5 className="main-profile-name" field-key="username">
                                                                    {this.state.username}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                <div className="col-md-8 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tabs-menu ">
                                                <ul className="nav nav-tabs profile navtab-custom panel-tabs">
                                                    <li className="active">
                                                        <span className="visible-xs"></span> <span className="hidden-xs">{t("admin.about-me")}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {this.state.loading === true ? (
                                                <Skeleton count={4} />
                                            ) : (
                                                //     <div className="tab-content border-left border-bottom border-right border-top-0 p-4">
                                                //         <div className="tab-pane active" id="home">
                                                //             {/* <h4 className="tx-15 text-uppercase mb-3">{t("admin.email")}</h4>
                                                //             <p className="m-b-5" field-key="email">
                                                //                 {this.state.email}
                                                //             </p> */}
                                                //             <div className="row justify-content-center align-items-center main-profile-menu">
                                                //                 <span className="profile-user d-flex">
                                                //                     <img alt="" src={ProfileIcon} style={{height:90, width:90}}/>
                                                //                 </span>
                                                //             </div>
                                                //             <div className="row text-center">
                                                //                 <div className="col-md-6 m-t-30">
                                                //                     <h4 className="tx-15 text-uppercase mt-3">{t("admin.username")}</h4>
                                                //                     <p className="m-b-5" field-key="username">
                                                //                         {this.state.username}
                                                //                     </p>
                                                //                 </div>

                                                //                 <div className="col-md-6 m-t-30">
                                                //                     {/* <h4 className="tx-15 text-uppercase mt-3">{t("admin.country")}</h4>
                                                //                     {this.flag()} */}
                                                //                     <h4 className="tx-15 text-uppercase mt-3">{t("admin.group")}</h4>
                                                //                     <p className="m-b-5" field-key="group_id">
                                                //                         {this.state.group_id}
                                                //                     </p>
                                                //                 </div>

                                                //                 {this.state.permission.includes("admin_update") && (
                                                //                     <div className="col-12">
                                                //                         <input
                                                //                             type="button"
                                                //                             value={t("global.edit")}
                                                //                             className="btn btn-md btn-info mr-2"
                                                //                             onClick={() => this.props.changePageHandler("edit", this.props.show_admin_id)}
                                                //                         />
                                                //                     </div>
                                                //                 )}
                                                //                 {/* <input
                                                //                     type="button"
                                                //                     value={t("global.back-to-list")}
                                                //                     className="btn btn-secondary ml-1"
                                                //                     onClick={() => this.props.changePageHandler("listing")}
                                                //                 /> */}

                                                //                 {/* <Button
                                                //     typeButton="button"
                                                //     classValue="btn btn-secondary ml-1"
                                                //     idValue="back"
                                                //     buttonName={t("global.back-to-list")}
                                                //     clicked={() => this.props.changePageHandler('listing')}
                                                // /> */}
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                <div>
                                                    <div className="profile__user py-4">
                                                        <div className="col-md-6 col-12 profile__user__content">
                                                            <h6>{t("admin.username")} :</h6>
                                                            <h3 className="mb-0">{this.state.username}</h3>
                                                        </div>
                                                        {/* <div className="col-md-6 col-12 profile__user__content">
                                                        <h6>{t("admin.group")} :</h6>
                                                        <h3 className="mb-0">{this.state.group_id}</h3>
                                                    </div> */}
                                                    </div>

                                                    {/* {(this.state.permission.includes("admin_update") || this.state.permission.includes("profile_access")) && ( */}
                                                    <div className="profile__edit__btn">
                                                        <input
                                                            type="button"
                                                            value={t("global.edit")}
                                                            className="btn btn-md btn-blue mr-2"
                                                            onClick={() => this.props.changePageHandler("edit", this.props.show_admin_id)}
                                                        />
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { admin, currentUser } = state;

    //Getting the state from the reducers
    return {
        show_admin_data: admin.show_admin_data,
        currentPermission: currentUser.data.permission,
    };
};

const mapDispatchToProps = {
    showAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AdminsShow));
