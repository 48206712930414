import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import Button from "../../components/Button/Button";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class PaymentGatewayListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            showCard: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard
        })
    }



    render() {
        const { t } = this.props;

        const Filter = () => {
            if (this.props.filterShow !== false) {
                return (
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-1`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("payment-gateway.payment-gateway")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="paymentGatewayName"
                                                    name="paymentGatewayName"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.paymentGatewayName}
                                                >
                                                    <option value=""></option>
                                                    {this.props.ddtransactionChannel &&
                                                        this.props.ddtransactionChannel.map(
                                                            (val, idx) => {
                                                                return (
                                                                    <option
                                                                        key={idx}
                                                                        value={val.id}
                                                                    >
                                                                        {t(
                                                                            `transaction-channel.${val.slug}`
                                                                        )}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("payment-gateway.deposit-status")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="depositStatus"
                                                    name="depositStatus"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.depositStatus}
                                                >
                                                    <option value=""></option>
                                                    <option value="1">{t("global.active")}</option>
                                                    <option value="0">{t("global.inactive")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("payment-gateway.withdrawal-status")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="withdrawalStatus"
                                                    name="withdrawalStatus"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.withdrawalStatus}
                                                >
                                                    <option value=""></option>
                                                    <option value="1">{t("global.active")}</option>
                                                    <option value="0">{t("global.inactive")}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {
                                    this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />
                                }
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <Filter />
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        {this.props.payment_type === false ? (
                                            <h4 className="card-title mg-b-0">{t("payment-gateway.payment-gateway-listing")}</h4>
                                        ) : (
                                            <h4 className="card-title mg-b-0">{t("payment-gateway.payment-type-listing")}</h4>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="">
                                    {/* {this.props.permission.includes(
                                        "payment_gateway_multi_delete"
                                    ) && (
                                            <button
                                                id="button"
                                                className="btn btn-danger mg-b-20 btn-multi-delete"
                                            >
                                                {t("global.multi-delete")}
                                            </button>
                                        )} */}
                                    <DataTable
                                        keyField="paymentGatewayId"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={
                                            this.props.pageChangeHandler
                                        }
                                        sorted={this.props.sorted}
                                        sizePerPageHandler={
                                            this.props.sizePerPageHandler
                                        }
                                        searchChangeHandler={
                                            this.props.searchChangeHandler
                                        }
                                        searchValue={this.props.searchValue}
                                        searchSubmitHandler={
                                            this.props.postSearchHandler
                                        }
                                        searchPressSubmitHandler={
                                            this.props.searchPressSubmitHandler
                                        }
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(PaymentGatewayListing);
