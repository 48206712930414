import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import PopupsListing from "./PopupsListing";
import PopupsCreate from "./PopupsCreate";
import PopupsShow from "./PopupsShow";
import PopupsEdit from "./PopupsEdit";
import Select from "../../components/Input/Select/Select";
import {getPopups,updatePopupStatus,deletePopup} from "../../store/ducks/popups/actions";

class Popups extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        currentPopupId: '',
        loading: true,
        currentImage : '',

        permission: []
    }

    fetchData(){
        const popupData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        popupData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        popupData.limit = this.state.limit;
        popupData.order = JSON.stringify(this.state.sorted);
        popupData.lang_id = this.props.currentLanguageId;
        popupData.country_id = this.props.currentCountryId;
        this.props.getPopups(popupData);
    }

    componentDidMount() {
       this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let popupPermission = this.props.currentPermission.filter((item) => item.includes("popup_"));
            this.setState({permission: popupPermission});
        }
        if (nextProps.popup_data !== this.props.popup_data) {
            this.setState({
                loading: false,
                data: nextProps.popup_data.data.data,
                totalRecords: nextProps.popup_data.data.totalRecords,
                totalPages: nextProps.popup_data.data.totalPages,
            })
        }

        if (nextProps.delete_popup_result !== this.props.delete_popup_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: nextProps.delete_popup_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                   this.fetchData();
                }
            });
        }

        if (nextProps.update_status_popup_result !== this.props.update_status_popup_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_popup_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                   this.fetchData();
                }
            });
        }


        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    updateStatusPopupHandler = (event, popup_id) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updatePopupStatusData = {};
                updatePopupStatusData.popup_id = popup_id;
                updatePopupStatusData.status = Number(event.target.value);

                this.props.updatePopupStatus(updatePopupStatusData);
            }
        });
    }

    deletePopupHandler = (e, popup_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deletePopupData = {};
                deletePopupData.id = popup_id;

                this.props.deletePopup(deletePopupData);
            }
        });
    }

    statusFormatter = (cell, row) => {
        let statusArray, dtClassName;

        statusArray = [
            {
                label: "Active",
                value: 1,
                selected: true,
            },
            {
                label: "Inactive",
                value: 0,
            },
        ];
        if (row.status === 0) {
            dtClassName = "form-control datatable-select-inactive";
        } else {
            dtClassName = "form-control datatable-select-active";
        }

        return (
            <Select
                id="popupStatus"
                name="Status"
                options={statusArray}
                defaultValue={row.status}
                key={`status-${row.popup_id}`}
                onChange={(event) => {
                    this.updateStatusPopupHandler(event, row.popup_id)
                }}
                className={dtClassName}
            />
        )
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        if (this.state.permission.includes("popup_view")) {
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-blue mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.popup_id}`}
                clicked={() => this.changePageHandler('show', row.popup_id)}
            />;
        }

        if (this.state.permission.includes("popup_edit")) {
        editButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-info mr-2"
                buttonName={<FontAwesomeIcon icon={faPen}/>}
                key={`edit-${row.popup_id}`}
                clicked={() => this.changePageHandler('edit', row.popup_id)}
            />;
        }

        if (this.state.permission.includes("popup_delete")) {
        deleteButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-danger mr-2"
                clicked={(event) => this.deletePopupHandler(event, row.popup_id)}
                key={`delete-${row.popup_id}`}
                buttonName={<FontAwesomeIcon icon={faTrash}/>}
            />;
        }
        output = [viewButton, editButton, deleteButton];
        return output;
    }

    changePageHandler = (page, popupId) => {
        this.setState({
            pageShow: page,
            currentPopupId: popupId
        });
    }

    refreshHandler = () => {
       this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    desktopImageFormatter = (cell, row) => {

        console.log(cell);

        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`desktop-image-${row.popup_id}`}
            />
        );
    }

    mobileImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`mobile-image-${row.popup_id}`}
            />
        );
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Popup Id",
                dataField: "popup_id",
                keyField: 'popupId',
                hidden: true
            },
            {
                text: t('popup.desktop-image'),
                dataField: "desktop_image",
                keyField: "desktopImage",
                formatter: this.desktopImageFormatter
            },
            {
                text: t('popup.mobile-image'),
                dataField: "mobile_image",
                keyField: "mobileImage",
                formatter: this.mobileImageFormatter
            },
            {
                text: t('popup.title'),
                dataField: "title",
                keyField: "popup_title",
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    {this.state.isOpen && (
                        <Lightbox
                            mainSrc={this.state.currentImage}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )}
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('popup.popup')}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div className="mb-3 mb-xl-0">
                                <div className="btn-group">
                                {this.state.permission.includes('popup_create') &&
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="btnCreatePopup"
                                        buttonName={t('popup.add-popup')}
                                        clicked={() => this.changePageHandler('create')}/>
                                }
                                </div>
                            </div>

                        </div>
                    </div>
                    <PopupsListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <PopupsCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <PopupsShow
                    show_popup_id={this.state.currentPopupId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <PopupsEdit
                    show_popup_id={this.state.currentPopupId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {popup, currentUser} = state;

    return {
        popup_data: popup.popup_data,
        update_status_popup_result: popup.update_status_popup_result,
        delete_popup_result: popup.delete_popup_result,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        currentPermission: currentUser.data.permission
    }
};

const mapDispatchToProps = {
    updatePopupStatus,
    getPopups,
    deletePopup
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Popups));