import * as type from "./types";
import { createReducer } from "../../../util/index";

const initialState = {
    // data: [],
};

const eventMatchReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.OPTION_DATA]: (state, action) => ({
        ...state,
        option_data: action.payload
    }),

    [type.CREATE]: (state, action) => ({
        ...state,
        create: action.payload,
    }),

    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.payload
    }),

    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        update_status: action.payload
    }),

    [type.UPDATE_SCORE]: (state, action) => ({
        ...state,
        update_score: action.payload
    })
});

export default eventMatchReducer;