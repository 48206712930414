import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import SpecialBirthdayGiftListing from "./SpecialBirthdayGiftListing";
import SpecialBirthdayGiftCreate from "./SpecialBirthdayGiftCreate";
import SpecialBirthdayGiftShow from "./SpecialBirthdayGiftShow";
import SpecialBirthdayGiftEdit from "./SpecialBirthdayGiftEdit";
import {getSpecialBirthdayGift, deleteSpecialBirthdayGift} from "../../store/ducks/specialBirthdayGift/actions";

class SpecialBirthdayGift extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        currentSpecialBirthdayGiftId: '',
        loading: true,
        currentImage : '',
        permission: []

    }

    fetchData(){
        const specialBirthdayData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        specialBirthdayData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        specialBirthdayData.limit = this.state.limit;
        specialBirthdayData.order = JSON.stringify(this.state.sorted);
        specialBirthdayData.country_id = this.props.currentCountryId;
        specialBirthdayData.search = this.state.searchValue;

        this.props.getSpecialBirthdayGift(specialBirthdayData);
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let specialBirthdayGiftPermission = this.props.currentPermission.filter((item) => item.includes("special_birthday_"));
            this.setState({permission: specialBirthdayGiftPermission});
        }

        if (nextProps.special_birthday_gift_data !== this.props.special_birthday_gift_data) {
            this.setState({
                loading: false,
                data: nextProps.special_birthday_gift_data.data.data,
                totalRecords: nextProps.special_birthday_gift_data.data.totalRecords,
                totalPages: nextProps.special_birthday_gift_data.data.totalPages,
            })
        }

        if (nextProps.delete_special_birthday_gift_members_data !== this.props.delete_special_birthday_gift_members_data) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: nextProps.delete_special_birthday_gift_members_data.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fetchData();
                }
            });
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    deleteSpecialBirthdayGiftHandler = (e, special_birthday_gift_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deleteSpecialBirthdayGiftData = {};
                deleteSpecialBirthdayGiftData.id = special_birthday_gift_id;

                this.props.deleteSpecialBirthdayGift(deleteSpecialBirthdayGiftData);
            }
        });
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton,output;

        if (this.state.permission.includes("special_birthday_view")) {
            viewButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-primary mr-2"
                    buttonName={<FontAwesomeIcon icon={faSearch}/>}
                    key={`view-${row.id}`}
                    clicked={() => this.changePageHandler('show', row.id)}
                />;
        }

        if (this.state.permission.includes("special_birthday_edit")) {
            editButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-info mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen}/>}
                    key={`edit-${row.id}`}
                    clicked={() => this.changePageHandler('edit', row.id)}
                />;
        }

        if (this.state.permission.includes("special_birthday_delete")) {
            deleteButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-danger mr-2"
                    clicked={(event) => this.deleteSpecialBirthdayGiftHandler(event, row.id)}
                    key={`delete-${row.id}`}
                    buttonName={<FontAwesomeIcon icon={faTrash}/>}
                />;
        }

        output = [viewButton, editButton, deleteButton];
        return output;
    }

    changePageHandler = (page, specialBirthdayGiftId) => {
        this.setState({
            pageShow: page,
            currentSpecialBirthdayGiftId: specialBirthdayGiftId
        });
    }

    refreshHandler = () => {
        this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Special Birthday Id",
                dataField: "id",
                keyField: 'specialBirthdayId',
                hidden: true
            },
            {
                text: t('special-birthday-gift.date'),
                dataField: "date",
                keyField: "specialBirthdayGiftDate"
            },
            {
                text: t('special-birthday-gift.username'),
                dataField: "username",
                keyField: "specialBirthdayGiftUsername"
            },
            {
                text: t('special-birthday-gift.description'),
                dataField: "description",
                keyField: "specialBirthdayGiftDescription",
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    {this.state.isOpen && (
                        <Lightbox
                            mainSrc={this.state.currentImage}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )}
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('special-birthday-gift.special-birthday-gift')}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div className="mb-3 mb-xl-0">
                                <div className="btn-group">
                                    {this.state.permission.includes('special_birthday_create') &&
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-primary"
                                        idValue="btnCreateSpecialBirthdayGift"
                                        buttonName={t('special-birthday-gift.add-special-birthday-gift')}
                                        clicked={() => this.changePageHandler('create')}/>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <SpecialBirthdayGiftListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <SpecialBirthdayGiftCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <SpecialBirthdayGiftShow
                    show_special_birthday_gift_id={this.state.currentSpecialBirthdayGiftId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <SpecialBirthdayGiftEdit
                    show_special_birthday_gift_id={this.state.currentSpecialBirthdayGiftId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {specialBirthdayGift,currentUser} = state;

    return {
        special_birthday_gift_data: specialBirthdayGift.special_birthday_gift_data,
        delete_special_birthday_gift_members_data: specialBirthdayGift.delete_special_birthday_gift_members_data,
        currentPermission: currentUser.data.permission,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
    }
};

const mapDispatchToProps = {
    getSpecialBirthdayGift,
    deleteSpecialBirthdayGift
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(SpecialBirthdayGift));