import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { default as ReactSelect } from "react-select";
import Select from "../../components/Input/Select/Select";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import axios from "../../common/config/axios";
import * as moment from "moment";
import Dropzone from "react-dropzone";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import Excel from "../../../src/assets/images/excel.svg";

let languageArray = [];
class TranslationAdminListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.onDrop = (excel_file) => {
            this.setState({ excel_file });
        };
        this.state = {
            excel_file: [],
            progress: 0,
            languages: "",
            loading: true,
            json_spacer: 4,
            json_option: [],
            showCard:false,
        };
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.languages.length !== 0 && nextState.loading === true) {
            let languageData = nextProps.languages;
            languageArray = [];
            Object.keys(languageData).map((item, i) => {
                return languageArray.push({
                    label: languageData[item].countryShortcode + " " + languageData[item].language,
                    value: languageData[item].langId,
                });
            });

            const json_convert_option = [
                { label: t("translation.normal"), value: 4 },
                { label: t("translation.minify"), value: 0 },
            ];
            this.setState({
                loading: false,
                json_option: json_convert_option,
            });
        }

        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    exportExcelHandler = () => {
        axios
            .get("translation/admin_export", {
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Admin Translation " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Translation Admin List Error", err);
            });
    };

    importExcelHandler = () => {
        const { t } = this.props;
        Swal.showLoading();
        let formData = new FormData();
        formData.append("excel_file", this.state.excel_file[0]);
        axios
            .post("translation/admin_import", formData)
            .then((res) => {
                this.removeExcelFileHandler();
                Swal.fire({
                    title: t("translation.import-success"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                });
            })
            .catch((err) => {
                this.removeExcelFileHandler();
                Swal.fire({
                    title: t("translation.import-failure"),
                    icon: "error",
                    confirmButtonText: t("global.ok"),
                });
            });
    };

    removeExcelFileHandler = () => {
        this.setState({
            excel_file: [],
            progress: 0,
        });
    };

    getTranslationFileHandler = () => {
        let languageId = this.state.languages.value;
        let jsonSpacer = this.state.json_spacer;
        axios
            .get("translation/admin_json_data", {
                params: { lang_id: languageId },
            })
            .then((res) => {
                let fileName = null;
                let translationResult = {};
                let objectResult = {};
                let translationData = res.data.data.translation;
                translationData.forEach((item) => {
                    objectResult[item.key] = item.value;
                });
                translationResult = this.classifyObject(objectResult);

                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD_HHmmss");
                if (jsonSpacer === 4) {
                    fileName = "Translation_" + timestamp + ".json";
                } else {
                    fileName = "Translation_" + timestamp + "min.json";
                }
                let contentType = "application/json;charset=utf-8;";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(translationResult, null, jsonSpacer)))], { type: contentType });
                    navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    var a = document.createElement("a");
                    a.download = fileName;
                    a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(translationResult, null, jsonSpacer));
                    a.target = "_blank";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    classifyObject = (object) => {
        const result = {};
        for (const objectPath in object) {
            const parts = objectPath.split(".");
            let target = result;
            while (parts.length > 1) {
                const part = parts.shift();
                target = target[part] = target[part] || {};
            }
            target[parts[0]] = object[objectPath];
        }
        return result;
    };

    handleLanguageChange = (event) => {
        this.setState({
            languages: event,
        });
    };

    handleSpacerChange = (event) => {
        this.setState({
            json_spacer: Number(event.target.value),
        });
    };

    toggleCard = () =>
    {
        this.setState({
            showCard:!this.state.showCard
        })
    }

    render() {
        const { t } = this.props;
        let actionDisplay = null;
        const files = this.state.excel_file.map((file) => (
            <li key={file.name}>
                {file.name} - {file.size} bytes{" "}
                <button className="btn btn-sm btn-danger" onClick={this.removeExcelFileHandler}>
                    {t("translation.remove-file")}
                </button>
            </li>
        ));

        if (!this.state.loading) {
            actionDisplay = (
                <div className={`filter-function ${this.state.showCard?'open':'close'}`}>
                    <div className="card-header p-0">
                        <div className="d-flex justify-content-between pb-4">
                            <h4 className="card-title mg-b-0">{t("global.action")}</h4>
                        </div>
                    </div>
                    <div className="row pb-md-1 pb-4">
                        <div className="col-md-6 col-12 mb-3">
                            <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps({ className: "dropzone bd bd-3 bd-primary-900 rounded" })}>
                                            <input {...getInputProps()} />
                                            <p className="text-center mt-2 mb-2">{t("translation.drag-excel")}</p>
                                        </div>
                                        {this.state.excel_file.length !== 0 && (
                                            <aside>
                                                {/* <h5>{t("translation.files")}</h5> */}
                                                <ul className="mb-0">{files}</ul>
                                            </aside>
                                        )}
                                        {this.state.progress !== 0 && (
                                            <div className="progress mg-b-20">
                                                <div
                                                    className="progress-bar progress-bar-striped wd-25p"
                                                    role="progressbar"
                                                    aria-valuenow={this.state.progress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                    {this.state.progress}
                                                </div>
                                            </div>
                                        )}
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <div className="col-md-6 col-12">
                            <Button
                                typeButton="button"
                                classValue="btn btn-blue mr-2"
                                clicked={(event) => this.exportExcelHandler(event)}
                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                buttonName={t("translation.export-excel")}
                            />
                            <Button
                                typeButton="button"
                                classValue="btn btn-secondary mr-2"
                                clicked={(event) => this.importExcelHandler(event)}
                                buttonName={t("translation.import-excel")}
                            />
                        </div>
                    </div>
                    {/* <div className="row mt-2">
                        <div className="col-md-12">
                            <Button
                                typeButton="button"
                                classValue="btn btn-primary mr-2"
                                clicked={(event) => this.exportExcelHandler(event)}
                                buttonName={t("translation.export-excel")}
                            />
                            <Button
                                typeButton="button"
                                classValue="btn btn-primary mr-2"
                                clicked={(event) => this.importExcelHandler(event)}
                                buttonName={t("translation.import-excel")}
                            />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label htmlFor="language">{t("global.languages")}</label>
                                <ReactSelect options={languageArray} isSearchable={true} name="language" onChange={this.handleLanguageChange} placeholder={t("global.select")} />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <label htmlFor="json_option">{t("translation.convert-option")}</label>
                            <Select id="json_option" name="json_option" options={this.state.json_option} onChange={this.handleSpacerChange} className="form-control" />
                        </div>
                        {this.state.languages !== "" && (
                            <div className="col-md-6 col-6" style={{margin:'auto 0'}}>
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-info mt-2"
                                    clicked={(event) => this.getTranslationFileHandler(event)}
                                    buttonName={t("translation.get-translation-file")}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("translation.translation")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                {actionDisplay}
                                <div className="show-btn"
                                onClick={()=>this.toggleCard()}>
                                    {
                                        this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("translation.admin-site")} {t("translation.translation-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="translationId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(TranslationAdminListing);
