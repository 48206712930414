import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberLevelTracker, getMemberLevelTrackerHistory, setMemberShowLocalState, setMemberShowInitialLoading } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import MemberLevelTrackerListing from "./MemberLevelTrackerListing";
import Skeleton from "react-loading-skeleton";
import { FaArrowUp, FaArrowsAltH } from "react-icons/fa";
import { filterErrorHandler } from "../../../hoc/Shared/utility";

class MemberLevelTracker extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.memberShowInitialLoading.filter((item) => item.id === "level_tracker");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                currentPage: "level_tracker",
                pageShow: "member_level_tracker",
                // Member Level Tracker
                ltShow_id: null,
                ltLoading: true,
                ltReload: false,

                // Member Level Tracker History
                loading: true,
                reload: false,
                activePage: 1,
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                show_id: null,
                totalRecords: 0,
                data: [],
            };
            this.props.setMemberShowLocalState(this.state);
        } else {
            this.state = this.props.memberLevelTrackerLocalState;
        }
    }

    componentDidMount() {
        // console.log("[MemberLevelTracker] componentDidMount()");
        const initLoad = this.props.memberShowInitialLoading.filter((item) => item.id === "level_tracker");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.props.getMemberLevelTracker(this.props.memberShowId);
            const listingArr = this.getFilterArray();
            this.props.getMemberLevelTrackerHistory(listingArr);

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.memberLevelTrackerData !== undefined) {
                if (this.props.memberLevelTrackerData.data && this.props.memberLevelTrackerData.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberLevelTrackerData.data.memberId)) {
                    this.props.getMemberLevelTracker(this.props.memberShowId);
                } else {
                    this.setState({
                        ltLoading: false,
                        ltReload: true,
                    });
                }
            } else {
                this.props.getMemberLevelTracker(this.props.memberShowId);
            }
        
            if (this.props.memberLevelTrackerHistoryData !== undefined) {
                if (this.props.memberLevelTrackerHistoryData.data && this.props.memberLevelTrackerHistoryData.data.data 
                    && this.props.memberLevelTrackerHistoryData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberLevelTrackerHistoryData.data.data.memberId)) {
                    const listingArr = this.getFilterArray();
                    this.props.getMemberLevelTrackerHistory(listingArr);
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTrackerHistory(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberLevelTracker] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        let levelTrackerLoadOff = false;
        // if (nextProps.memberShowId !== undefined && nextProps.memberShowId !== nextState.show_id && nextProps.memberLevelTrackerData !== undefined) {
        if (nextProps.memberShowId !== undefined && nextProps.memberLevelTrackerData !== undefined) {    
            if (nextProps.memberLevelTrackerData !== this.props.memberLevelTrackerData) {
                if (nextProps.memberLevelTrackerData.responseCode === 200 && nextProps.memberLevelTrackerData.msgType === "success") {
                    levelTrackerLoadOff = true;
                    // Swal.close();
                    this.setState({
                        ltShow_id: nextProps.memberShowId,
                        // ltLoading: false
                    });
                }
                else {
                    this.setState({
                        ltShow_id: null
                    }, () => {
                        filterErrorHandler(nextProps.memberLevelTrackerData.message)
                    })
                }
            }
        }

        if (nextProps.memberShowId !== undefined && nextProps.memberLevelTrackerHistoryData !== undefined) {
            if (nextProps.memberLevelTrackerHistoryData !== this.props.memberLevelTrackerHistoryData) {
                if (nextProps.memberLevelTrackerHistoryData.data.responseCode === 200 && nextProps.memberLevelTrackerHistoryData.data.msgType === "success") {
                    Swal.close();
                    this.setState({
                        show_id: nextProps.memberShowId,
                        data: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.row,
                        totalRecords: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_record,
                        totalPages: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_page,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        show_id: null,
                        data: [],
                        totalRecords: 0,
                        totalPages: 1,
                        loading: false
                    }, () => {
                        filterErrorHandler(nextProps.memberLevelTrackerHistoryData.data.message)
                    })
                }
            }
        }

        if(levelTrackerLoadOff){
            this.setState({ltLoading: false});
        }
        // if (
        //     !nextState.reload &&
        //     nextProps.memberShowId !== undefined &&
        //     nextProps.memberLevelTrackerHistoryData !== undefined &&
        //     nextProps.memberLevelTrackerHistoryData !== this.props.memberLevelTrackerHistoryData
        // ) {
        //     if (nextProps.memberLevelTrackerHistoryData.status === 200 && nextProps.memberLevelTrackerHistoryData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.row,
        //             totalRecords: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_record,
        //             totalPages: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberLevelTrackerHistoryData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";

        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // } else if (
        //     nextState.reload &&
        //     nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.row !== nextState.data &&
        //     nextProps.memberLevelTrackerHistoryData !== undefined
        // ) {
        //     if (nextProps.memberLevelTrackerHistoryData.status === 200 && nextProps.memberLevelTrackerHistoryData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.row,
        //             totalRecords: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_record,
        //             totalPages: nextProps.memberLevelTrackerHistoryData.data.data.memberLevelTrackerHistory.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberLevelTrackerHistoryData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";
        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // }

        return true;
    }

    componentWillUnmount() {
        // console.log("[MemberLevelTracker] componentWillUnmount()", "\nstate: ", this.state);
        this.props.setMemberShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        listingArr.member_id = this.props.memberShowId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    // Deposit
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTrackerHistory(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTrackerHistory(listingArr);
            }
        );
    };

    render() {
        // console.log(this.state.ltLoading);
        const { t } = this.props;
        let memberLevelTrackerData = null;
        let memberLevelUpStatus = null;        
        if (!this.state.ltLoading && this.props.memberLevelTrackerData && this.props.memberLevelTrackerData.data && this.props.memberLevelTrackerData.data.memberLevelTracker) {
            memberLevelTrackerData = this.props.memberLevelTrackerData.data.memberLevelTracker;
            if (memberLevelTrackerData !== null) {
                switch (memberLevelTrackerData.upLvlStatus) {
                    case 0:
                        memberLevelUpStatus = (
                            <span>
                                {t("member.maintain")} <FaArrowsAltH />
                            </span>
                        );
                        break;
                    case 1:
                        memberLevelUpStatus = (
                            <span className="text-success">
                                {t("member.level-up")} <FaArrowUp />
                            </span>
                        );
                        break;
                    // case 2:
                    //     memberLevelUpStatus = (
                    //         <span className="text-danger">
                    //             {t("member.level-down")} <FaArrowDown />
                    //         </span>
                    //     );
                    //     break;
                    default:
                        memberLevelUpStatus = (
                            <span>
                                {t("member.maintain")} <FaArrowsAltH />
                            </span>
                        );
                }
            }
        }
        return (
            <Auxiliary>
                {this.state.pageShow === "member_level_tracker" && (
                    <>
                        <div className="row row-sm">
                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="card-title mg-b-0">{t("member.level-tracker")}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {memberLevelTrackerData !== null ? (
                                            <>
                                                <table className="table table-bordered table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <th className="table-secondary text-center" colSpan={4}>
                                                                {t("member.current-status")}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>{t("member.current-level")}</th>
                                                            <td field-key="current_level">
                                                                {t(`global.level.${memberLevelTrackerData.mg_current_level}`, memberLevelTrackerData.groupName)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>{t("member.level-up-status")}</th>
                                                            <td field-key="level_up_status">{memberLevelUpStatus}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="table-secondary text-center" colSpan={4}>
                                                                {t("member.level-up-requirement")}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>{t("member.total-payment")}</th>
                                                            <td
                                                                field-key="total_payment"
                                                                className={` ${
                                                                    Number(memberLevelTrackerData.total_payment) >= Number(memberLevelTrackerData.minPaymentUpgrade)
                                                                        ? "text-success"
                                                                        : ""
                                                                }`}>
                                                                {memberLevelTrackerData.total_payment !== null ? (memberLevelTrackerData.total_payment) : "0.00"} /{" "}
                                                                {memberLevelTrackerData.minPaymentUpgrade !== null
                                                                    ? (memberLevelTrackerData.minPaymentUpgrade)
                                                                    : t("global.incomplete-setup")}
                                                            </td>                                               

                                                            {/* <th>{t("member.last-month-turnover")}</th>
                                                            <td
                                                                field-key="last_month_turnover"
                                                                className={` ${
                                                                    Number(memberLevelTrackerData.last_1_mth_turnover) >= Number(memberLevelTrackerData.minTurnoverTierUpgrade)
                                                                        ? "text-success"
                                                                        : ""
                                                                }`}>
                                                                {memberLevelTrackerData.last_1_mth_turnover !== null ? (memberLevelTrackerData.last_1_mth_turnover) : "0.00"} /{" "}
                                                                {memberLevelTrackerData.minTurnoverTierUpgrade !== null
                                                                    ? (memberLevelTrackerData.minTurnoverTierUpgrade)
                                                                    : t("global.incomplete-setup")}
                                                            </td> */}
                                                        </tr>
                                                        {/* <tr>
                                                            <th className="table-secondary text-center" colSpan={4}>
                                                                {t("member.level-maintain-requirement")}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>{t("member.last-3-months-deposit")}</th>
                                                            <td
                                                                field-key="last_3_months_deposit"
                                                                className={` ${
                                                                    Number(memberLevelTrackerData.last_3_mth_deposit) >= Number(memberLevelTrackerData.depositTierMaintain)
                                                                        ? "text-success"
                                                                        : ""
                                                                }`}>
                                                                {memberLevelTrackerData.last_3_mth_deposit !== null ? (memberLevelTrackerData.last_3_mth_deposit) : "0.00"} /{" "}
                                                                {memberLevelTrackerData.depositTierMaintain !== null
                                                                    ? (memberLevelTrackerData.depositTierMaintain)
                                                                    : t("global.incomplete-setup")}
                                                            </td>
                                                            <th>{t("member.last-3-months-turnover")}</th>
                                                            <td
                                                                field-key="last_3_months_turnover"
                                                                className={` ${
                                                                    Number(memberLevelTrackerData.last_3_mth_turnover) >= Number(memberLevelTrackerData.turnoverTierMaintain)
                                                                        ? "text-success"
                                                                        : ""
                                                                }`}>
                                                                {memberLevelTrackerData.last_3_mth_turnover !== null ? (memberLevelTrackerData.last_3_mth_turnover) : "0.00"} /{" "}
                                                                {memberLevelTrackerData.turnoverTierMaintain !== null
                                                                    ? (memberLevelTrackerData.turnoverTierMaintain)
                                                                    : t("global.incomplete-setup")}
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <Skeleton count={4} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MemberLevelTrackerListing
                            datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                            onSort={(field, order) => this.onSort(field, order)}
                            data={this.state.data}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            loading={this.state.loading}
                        />
                    </>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberLevelTrackerLocalState: member.member_level_tracker_local_state,

        memberLevelTrackerData: member.member_level_tracker_data,
        memberLevelTrackerHistoryData: member.member_level_tracker_history_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),

        getMemberLevelTracker: (data) => dispatch(getMemberLevelTracker(data)),
        getMemberLevelTrackerHistory: (data) => dispatch(getMemberLevelTrackerHistory(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberLevelTracker));
