import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { formValidation, clearFormValidation, filterErrorHandler } from '../../hoc/Shared/utility';
import Select from '../../components/Input/Select/Select';
import Textarea from '../../components/Input/Textarea/Textarea';
import ImageUploader from 'react-images-upload';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/bankBalanceAdjustment/actions';

const initialFormInputState =
{
    /* form input value */
    bank_acc: '',
    transaction_type: 0,
    amount: '',
    fees: '',
    transaction_date: moment().format('YYYY-MM-DD h:mm A'),
    remark: '',
    avBalc: 0,
    adjBalc: 0,
    pictures: [],
};

class BankBalanceAdjustmentCash extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: initialFormInputState,
            dropDown:
            {
                bankAccount: [],
            },
            errMsg: [],
            touched: false,
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onImageUpload = this.onImageUpload.bind(this);
        // console.log('constructor (create):', this.state);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('bba_cash_form');
        const listingArr = {};
        listingArr.country_id = this.props.currentUser.country_id;
        this.props.funcGetDropDown(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate :', nextProps.dropDown);
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
                let bankAccArr = [];
                nextProps.dropDown.data.bank_account.forEach((val, idx) => {
                    bankAccArr.push({
                        id: `${val.id}|${val.acc_type}|${val.payment_type}`,
                        bank_acc_name: (val.swift_code !== null) ? `${val.swift_code} - ${val.bank_acc_name}` : val.bank_acc_name,
                    });
                });
                nextProps.dropDown.data.other_payment_account.forEach((val, idx) => {
                    bankAccArr.push({
                        id: `${val.id}|${val.acc_type}|${val.payment_type}`,
                        bank_acc_name: (val.swift_code !== null) ? `${val.swift_code} - ${val.bank_acc_name}` : val.bank_acc_name,
                    });
                });
                this.setState({
                    dropDown:
                    {
                        bankAccount: bankAccArr,
                    },
                });
            }
            else {
                this.setState({
                    dropDown:
                    {
                        bankAccount: [],
                    },
                }, () => {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }

        if (nextProps.bankBalc !== this.props.bankBalc && nextProps.bankBalc !== undefined) {
            if (nextProps.bankBalc.responseCode === 200) {
                let newFilter = Object.assign({}, this.state.formFilter);
                newFilter['avBalc'] = nextProps.bankBalc.data.balance;
                this.setState({
                    formFilter: newFilter,
                }, () => {
                    this.calculateBalance();
                });
            }
            else {
                filterErrorHandler(nextProps.bankBalc.message);
            }
        }

        if (nextProps.storeCash !== this.props.storeCash && nextProps.storeCash !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.storeCash);
            const { t } = this.props;
            let success = (nextProps.storeCash.responseCode === 200 && nextProps.storeCash.msgType === 'success') ? true : false;
            if (nextProps.storeCash.responseCode === 200 && nextProps.storeCash.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('bba_cash_form');
                    this.setState({
                        formFilter: initialFormInputState,
                        touched: (success) ? false : true,
                        errMsg: (success) ? [] : nextProps.storeCash.message,
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.storeCash.message
                });
                Swal.close();
            }
        }
        return true;
    }

    // loadingHandler = () =>
    // {
    //     Swal.fire({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });
    // }

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter[event.target.id] = event.target.value;
        this.setState({
            formFilter: newFilter
        }, () => {
            const bid = this.state.formFilter.bank_acc;
            if (event.target.id === 'bank_acc' && bid !== '') {
                let val = event.target.value.split('|');
                let bank_acc = val[0];
                let transaction_channel = val[1];
                let payment_type = val[2];
                this.props.funcGetBankBalc(bank_acc, transaction_channel, payment_type);
            }
            else {
                if (bid !== '') {
                    this.calculateBalance();
                }
            }
        });
    }

    onImageUpload = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter['pictures'] = event[0];
        this.setState({
            formFilter: newFilter
        });
    }

    calculateBalance = () => {
        let newFilter = Object.assign({}, this.state.formFilter);
        let amt = (this.state.formFilter.amount === null) ? 0 : Number(this.state.formFilter.amount);
        let fees = (this.state.formFilter.fees === null) ? 0 : Number(this.state.formFilter.fees);
        let balc = Number(this.state.formFilter.avBalc);
        let avBalc = (balc !== undefined && balc !== '') ? balc : 0;
        var bal = (Number(this.state.formFilter.transaction_type) === 1) ? (Number(avBalc) - Number(amt) - Number(fees)) : (Number(avBalc) + Number(amt) - Number(fees));
        // console.log('calculateBalance : '+avBalc+' | '+bal+' | '+avBalc+' | '+amt+' | '+this.state.transaction_type);

        newFilter['avBalc'] = avBalc;
        newFilter['adjBalc'] = bal;

        this.setState({
            formFilter: newFilter
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state.formFilter);
        e.preventDefault();
        const { t } = this.props;
        if (this.state.formFilter.adjBalc < 0) {
            Swal.fire({
                title: t('validation.insufficient_balance'),
                icon: 'error',
                showConfirmButton: true,
            })
        }
        else {
            Swal.fire({
                title: t('validation.confirmation'),
                text: t('validation.confirmation_check'),
                icon: 'warning',
                confirmButtonText: t('global.save'),
                showDenyButton: true,
                denyButtonText: t('global.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    let formData = new FormData();
                    let tmp = this.state.formFilter.bank_acc.split('|');
                    let bank_acc = tmp[0];
                    let transaction_channel = tmp[1];
                    let payment_type = tmp[2];

                    // formData.append('bank_acc',this.state.formFilter.bank_acc);
                    formData.append('bank_acc', bank_acc);
                    formData.append('transaction_channel', transaction_channel);
                    formData.append('payment_type', payment_type);
                    formData.append('transaction_type', this.state.formFilter.transaction_type);
                    formData.append('amount', this.state.formFilter.amount);
                    // formData.append('transaction_date',this.state.formFilter.transaction_date);
                    formData.append('transaction_date', moment(this.state.formFilter.transaction_date).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append('fees', this.state.formFilter.fees);
                    formData.append('remark', this.state.formFilter.remark);
                    formData.append('file', this.state.formFilter.pictures);
                    formData.append('country_id', this.props.currentUser.country_id);
                    // for (var pair of formData.entries())
                    // {
                    //     console.log(pair[0]+ ' = ' + pair[1]);
                    // }
                    this.props.funcStore(formData);
                }
            });
        }
    }

    render() {
        // console.log('render :', this.state.formFilter);
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('bank-balance-adjustment.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('bank-balance-adjustment.cash-in-out')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='bba_cash_form' name='bba_cash_form' className='form-horizontal' encType='multipart/form-data' onSubmit={this.submitHandler} noValidate >
                                    <ValidationError formName='bba_cash_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {/* <div className='row'> */}
                                    {/* <div className='col-md-6'> */}
                                    <div className="row filter-wrapper-3">
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.bank-account')}</label>
                                                <Select
                                                    id='bank_acc'
                                                    name='bank_acc'
                                                    className='form-control'
                                                    getOptionValue='id'
                                                    getOptionLabel='bank_acc_name'
                                                    options={this.state.dropDown.bankAccount}
                                                    onChange={this.onInputChange}
                                                    value={this.state.formFilter.bank_acc}
                                                    emptyValue={true}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.transaction-type')}</label>
                                                <div className='select-input'>
                                                    <select className='form-control filter-input' name='transaction_type' id='transaction_type' onChange={this.onInputChange} value={this.state.formFilter.transaction_type} required >
                                                        <option value='0'>{t('transaction-type.cash-in')}</option>
                                                        <option value='1'>{t('transaction-type.cash-out')}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.amount')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' placeholder='Amount' min='1' onChange={this.onInputChange} value={this.state.formFilter.amount} required /> */}
                                                <NumberInput
                                                    id='amount'
                                                    name='amount'
                                                    min={0.01}
                                                    placeholder={t('bank-balance-adjustment.amount')}
                                                    value={this.state.formFilter.amount}
                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                    onChange={this.onInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.fees')}</label>
                                                {/* <input className='form-control' type='number' name='fees' id='fees' onChange={this.onInputChange} placeholder='Fees' value={this.state.formFilter.fees} /> */}
                                                <NumberInput
                                                    id='fees'
                                                    name='fees'
                                                    placeholder={t('bank-balance-adjustment.fees')}
                                                    value={this.state.formFilter.fees}
                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.transaction-date')}</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'><i className='fas fa-calendar-check'></i></span>
                                                    </div>
                                                    <DateTimePicker
                                                        id='transaction_date'
                                                        name='transaction_date'
                                                        className='form-control filter-input'
                                                        format='YYYY-MM-DD'
                                                        singleDatePicker={true}
                                                        autoUpdateInput={true}
                                                        timePicker={true}
                                                        value={this.state.formFilter.transaction_date}
                                                        onChange={this.onInputChange}
                                                        maxDate={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className='form-group mb-0'>
                                                <label>{t('bank-balance-adjustment.receipt')}</label>
                                                {/* <input type='file' name='file' id='file' className='form-control dropify' data-height='200' data-max-file-size-preview='5M' data-max-width='1500' data-max-height='1500' accept='image/png,image/gif,image/jpeg'/>
                                                <span className='text-muted'><strong>*jpg, png, gif</strong></span> */}
                                                <ImageUploader
                                                    withIcon={true}
                                                    withPreview={true}
                                                    singleImage={true}
                                                    onChange={this.onImageUpload}
                                                    buttonText={t('bank-balance-adjustment.choose-image')}
                                                    maxFileSize={5242880}
                                                    className='is-invalid'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='form-group mb-0'>
                                                <label>{t('bank-balance-adjustment.remark')}</label>
                                                <Textarea
                                                    id='remark'
                                                    name='remark'
                                                    className='form-control'
                                                    maxLength={255}
                                                    rows={3}
                                                    placeholder={t('bank-balance-adjustment.remark')}
                                                    value={this.state.formFilter.remark}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.available-balance')}</label>
                                                <h2>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.avBalc)}</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className='form-group'>
                                                <label>{t('bank-balance-adjustment.after-adjustment-balance')}</label>
                                                <h2>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.adjBalc)}</h2>
                                            </div>
                                        </div> */}

                                        <div className="col-lg-6 col-12">
                                            <div className="card card-img-holder overflow-hidden sales-card bg-primary-gradient">
                                                <div className="card-body list-icons">
                                                    <div className="clearfix ">
                                                        <div className="float-right  mt-2">
                                                            <span className="text-primary">
                                                                <i className="si si-credit-card tx-50 text-white"></i>
                                                            </span>
                                                        </div>
                                                        <div className="float-left text-white">
                                                            <p className="card-text mb-1">{t('bank-balance-adjustment.available-balance')}</p>
                                                            <h3>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.avBalc)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-12">
                                            <div className="card card-img-holder overflow-hidden sales-card bg-danger-gradient">
                                                <div className="card-body list-icons">
                                                    <div className="clearfix ">
                                                        <div className="float-right  mt-2">
                                                            <span className="text-primary">
                                                                <i className="si si-credit-card tx-50 text-white"></i>
                                                            </span>
                                                        </div>
                                                        <div className="float-left text-white">
                                                            <p className="card-text mb-1">{t('bank-balance-adjustment.after-adjustment-balance')}</p>
                                                            <h3>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.formFilter.adjBalc)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col p-0">
                                        <div className='form-group mb-0 justify-content-end'>
                                            {(permission.includes('bank_balance_adjustment_cash_store')) &&
                                                <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                        </div>
                                    </div>

                                    {/* </div> */}
                                    {/* </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        dropDown: state.bankBalanceAdjustment.dropDown,
        bankBalc: state.bankBalanceAdjustment.bankBalc,
        storeCash: state.bankBalanceAdjustment.storeCash,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDropDown: (data) => dispatch(actions.getDropDown(data)),
        funcGetBankBalc: (id, tc, pt) => dispatch(actions.getBankBalc(id, tc, pt)),
        funcStore: (data) => dispatch(actions.storeCash(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BankBalanceAdjustmentCash));