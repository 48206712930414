import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    loading: {
        pageLoading: true,
        dataLoading: true,
    }
};

const logReducer = createReducer(initialState) ({
    [type.GET_LOG_LIST]: (state = initialState, action) => ({
        ...state,
        log_data: action.payload
    }),

    [type.SET_LOADING]: (state, action) => ({
        ...state,
        loading: action.payload,
    }),

    [type.SET_DATATABLE_FILTER]: (state, action) => ({
        ...state,
        filterList: action.filterList
    }),

    [type.TRANSACTION_LOG_LIST]: (state, action) => ({
        ...state,
        transaction_log_data: action.payload
    }),

    [type.BANK_ACCOUNT_LOG_LIST]: (state, action) => ({
        ...state,
        bank_account_log_data: action.payload
    }),

    [type.MEMBER_LOG_LIST]: (state, action) => ({
        ...state,
        member_log_data: action.payload
    }),

    [type.ADMIN_LOG_LIST]: (state, action) => ({
        ...state,
        admin_log_data: action.payload
    }),
});

export default logReducer;