import React, { Component } from "react";
import { connect } from "react-redux";
import { showAnnouncement } from "../../store/ducks/announcement/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { filterErrorHandler } from "../../hoc/Shared/utility";

class AnnouncementShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.showAnnouncement(this.props.announcementShowId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.announcementShowData !== this.props.announcementShowData && nextProps.announcementShowData !== undefined) {
            if(nextProps.announcementShowData.responseCode === 200 && nextProps.announcementShowData.msgType === 'success'){
                this.setState({
                    loading: false,
                });
            }else{
                this.setState({
                    loading: true,
                }, () => 
                {
                    filterErrorHandler(nextProps.announcementShowData.message);
                });
            }
            
        }
        return true;
    }

    languagesContentMerge = (languages = [], contents = []) => {
        const mergeItem = languages.map((language) => ({
            ...contents.find((content) => content.languageId === language.langId) || {},
            ...language,
        }));
        return mergeItem;
    };

    render() {
        const { t } = this.props;
        // console.log("AnnouncementShow render()", this.props.announcementShowData);
        let display = null;
        if (!this.state.loading && this.props.announcementShowData.data) {
            let announcementData = this.props.announcementShowData.data.announcement[0];
            let languages = this.props.announcementShowData.data.languages;
            let contents = this.props.announcementShowData.data.announcementDetails;
            let memberGroup = this.props.announcementShowData.data.announcementMemberGroup;
            let languageContent = this.languagesContentMerge(languages, contents);
            let tabTitleMessage = [];
            let tabContentMessage = [];
            languageContent.map((item) => {
                if (item.message) {
                    tabTitleMessage.push(item.lang);
                    tabContentMessage.push(item.message);
                } else {
                    tabTitleMessage.push(item.lang);
                    tabContentMessage.push("");
                }
                return [tabTitleMessage, tabContentMessage];
            });

            // console.log(tabTitleMessage, tabContentMessage);
            let tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border"/>;

            if (memberGroup !== undefined) {
                memberGroup = memberGroup.map((val, idx) => (
                    <span key={idx} className="badge-primary mr-1">
                        {val}
                    </span>
                ));
            } else {
                memberGroup = null;
            }

            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("announcement.announcement-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>{t("announcement.title")}</th>
                                                <td field-key="title">{announcementData.title}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.message")}</th>
                                                <td field-key="message">{tabMessage}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.sequence")}</th>
                                                <td field-key="sequence">{announcementData.sequence}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.start-time")}</th>
                                                <td field-key="start_time">{announcementData.startTime}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.end-time")}</th>
                                                <td field-key="end_time">{announcementData.endTime}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.status")}</th>
                                                <td field-key="status">{announcementData.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.show")}</th>
                                                <td field-key="announcement_type">{announcementData.show === 1 ? t("announcement.news") : t("announcement.member")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("announcement.member-group")}</th>
                                                <td field-key="member_group">
                                                    {memberGroup}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.created-time")}</th>
                                                <td field-key="created_date">{moment(announcementData.createdTime).format("DD-MM-YYYY hh:mm:ss A")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.updated-time")}</th>
                                                <td field-key="updated_date">{moment(announcementData.updatedTime).format("DD-MM-YYYY hh:mm:ss A")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("announcement.announcement-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading === true && <Skeleton count={4} /> }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("announcement.announcement")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { announcement } = state;

    return {
        announcementShowData: announcement.announcement_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAnnouncement: (id) => dispatch(showAnnouncement(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AnnouncementShow));
