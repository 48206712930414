import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const promotedVideoReducer = createReducer(initialState) ({
    [type.POST_PROMOTED_VIDEO]: (state, action) => ({
        ...state,
        post_promoted_video_result: action.payload,
    }),
    [type.GET_PROMOTED_VIDEO]: (state = initialState, action) => ({
        ...state,
        promoted_video_data: action.payload
    }),
    [type.SHOW_PROMOTED_VIDEO]: (state, action) => ({
        ...state,
        show_promoted_video_data: action.payload,
    }),
    [type.UPDATE_PROMOTED_VIDEO]: (state, action) => ({
        ...state,
        update_promoted_video_result: action.payload,
    }),
    [type.UPDATE_STATUS_PROMOTED_VIDEO]: (state, action) => ({
        ...state,
        update_status_promoted_video_result: action.payload,
    })
});

export default promotedVideoReducer;