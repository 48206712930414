import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { update } from "../../../store/ducks/event/match/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import ValidationError from "../../../components/ValidationError/ValidationError";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import moment from "moment";
class EventMatchEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error_message: [],
            touched: false,
            dropdown: props.dropdown,
            filter: {
                match_id: props.rowData.id,
                match_date: moment(props.rowData.match_date).format("YYYY/MM/DD hh:mm:ss A"),
                league: props.rowData.league_slug,
                team_a: props.rowData.team_a_slug,
                team_b: props.rowData.team_b_slug,
                status: props.rowData.status,
                slug: props.rowData.slug,
            },
            hasBet: (this.props.rowData.bet_count > 0) ? true : false
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation("match_form");

        if (this.state.filter.league !== undefined && this.state.filter.league !== '') {
            this.setState({
                dropdown: {
                    team: this.state.dropdown.leagueTeam[this.state.filter.league],
                    matchSlug: this.state.dropdown.matchSlug[this.state.filter.league]
                }
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.rs !== this.props.rs) {
            if (nextProps.rs.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.rs.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.rs.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.rs.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    editMatchHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });     

                let updateData = {};
                updateData.matchID = this.state.filter.match_id;
                updateData.matchDate = this.state.filter.match_date;
                updateData.league = this.state.filter.league;
                updateData.teamA = this.state.filter.team_a;
                updateData.teamB = this.state.filter.team_b;
                updateData.status = this.state.filter.status;
                updateData.slug = this.state.filter.slug;

                this.props.update(updateData);
            }
        });
    };

    onInputChange(event) {
        // const { t } = this.props;
        
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        if (event.target.name === 'league') {
            if (event.target.value !== '') {
                this.setState({
                    dropdown: {
                        team: this.props.dropdown.leagueTeam[event.target.value],
                        matchSlug: this.props.dropdown.matchSlug[event.target.value]
                    }
                });
            } else {
                this.setState({
                    dropdown: {
                        team: [],
                        matchSlug: []
                    }
                });

                newFilter['team_a'] = '';
                newFilter['team_b'] = '';
                newFilter['slug'] = '';
            }
        }

        this.setState({
            filter: newFilter
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("event_match.event_match")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_match.update_match")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="match_form" className="form-horizontal" onSubmit={this.editMatchHandler} noValidate>
                                    <ValidationError formName="match_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("event_match.match_date")}</label>
                                                <DateTimePicker
                                                    id="match_date"
                                                    name="match_date"
                                                    className="form-control filter-input"
                                                    singleDatePicker={true}
                                                    timePicker={true}
                                                    value={this.state.filter.match_date}
                                                    onChange={this.onInputChange}
                                                    required={true}
                                                    disabled={this.state.hasBet}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="league">{t("event_match.league")}</label>
                                                <Select
                                                    id="league"
                                                    name="league"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.props.dropdown.league}
                                                    emptyValue={true}
                                                    value={this.state.filter.league}
                                                    required={true}
                                                    disabled={this.state.hasBet}
                                                />
                                            </div>
                                        </div>
                                        {!!(this.state.filter.league) && (<div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("event_match.slug")}</label>
                                                <Select
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.state.dropdown.matchSlug}
                                                    emptyValue={true}
                                                    value={this.state.filter.slug}
                                                    required={true}
                                                />
                                            </div>
                                        </div>)}
                                        {!!(this.state.filter.league) && (<div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_a">{t("event_match.team_a")}</label>
                                                <Select
                                                    id="team_a"
                                                    name="team_a"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.state.dropdown.team}
                                                    emptyValue={true}
                                                    value={this.state.filter.team_a}
                                                    required={true}
                                                    disabled={this.state.hasBet}
                                                />
                                            </div>
                                        </div>)}
                                        {!!(this.state.filter.league) && (<div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_b">{t("event_match.team_b")}</label>
                                                <Select
                                                    id="team_b"
                                                    name="team_b"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.state.dropdown.team}
                                                    emptyValue={true}
                                                    value={this.state.filter.team_b}
                                                    required={true}
                                                    disabled={this.state.hasBet}
                                                />
                                            </div>
                                        </div>)}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_b">{t("event_match.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.props.dropdown.status}
                                                    emptyValue={true}
                                                    value={this.state.filter.status}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rs: state.eventMatch.update,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (data) => dispatch(update(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EventMatchEdit));
