import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FaRegistered } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle } from "react-icons/ai";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DataTable from "../../components/Datatable/DataTable";
import Button from "../../components/Button/Button";
import Select from "../../components/Input/Select/Select";
import StringInput from "../../components/Input/StringInput/StringInput";
import UpdownArrow from "../../assets/images/updown-arrow.png";


class AgentListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;
        let permission = this.props.permission ? this.props.permission : [];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns =
            [
                {
                    text: t("global.no"),
                    dataField: "no",
                    formatter: (cell, row, rowIndex) => {
                        let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                        return <span>{rowNumber}</span>;
                    },
                    headerStyle: { width: "60px" }
                },
                {
                    text: t("agent.username"),
                    dataField: "username",
                    sort: true,
                    onSort: this.props.onSort,
                },
                {
                    text: t("agent.name"),
                    dataField: "name",
                    sort: true,
                    onSort: this.props.onSort,
                },
                {
                    text: t("agent.email"),
                    dataField: "email",
                    sort: true,
                    onSort: this.props.onSort,
                },
                {
                    text: t("agent.contact-no"),
                    dataField: "contact_no",
                    sort: true,
                    onSort: this.props.onSort,
                },
                {
                    text: t("agent-payout.downline"),
                    dataField: "downline",
                    sort: true,
                    onSort: this.props.onSort,
                    formatter: (cell, row, rowIndex) => 
                    { 
                        let val = row.downline;
                        if(row.downline > 0)
                        {
                            val =<b>
                                    <a href="/#" onClick={(event) => this.props.detailsHandler(event, "downline", row.id)}>
                                        {row.downline}
                                        <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                                    </a>
                                </b>;
                        }
                        return val;
                    }
                },
                {
                    text: t("agent.last-login"),
                    dataField: "last_login",
                    sort: true,
                    onSort: this.onSort
                },
                {
                    text: t("agent.balance"),
                    dataField: "balance",
                    sort: true,
                    onSort: this.onSort,
                    formatter: (cell, row, rowIndex) => {
                        return new Intl.NumberFormat('en-US', num_format).format(row.balance);
                    },
                },
                {
                    text: t("global.status"),
                    dataField: "status",
                    formatter: (cell, row) => {
                        const { t } = this.props;
                        let stCls = row.status === 1 ? "active" : "inactive";
                        let statusArray =
                            [
                                { label: t("global.active"), value: 1, selected: true },
                                { label: t("global.inactive"), value: 0 },
                            ];
                        let stVal = permission.includes("agent_update") ? (
                            <Select
                                id="status"
                                name="status"
                                options={statusArray}
                                defaultValue={row.status}
                                onChange={(event) => {
                                    this.props.updateAgentStatusHandler(event, row.id);
                                }}
                                className={`form-control datatable-select-${stCls}`}
                            />
                        ) : (
                            <span className={`badge-pill badge-${row.status === 1 ? "success" : "danger"}`}>
                                {row.status && row.status === 1 ? t("global.active") : t("global.inactive")}
                            </span>
                        );
                        return stVal;
                    },
                    headerStyle: { width: "115px" }
                },
                {
                    text: t("global.action"),
                    dataField: "#",
                    formatter: (cell, row) => {
                        let viewButton, editButton, rebateButton, output;

                        if (permission.includes("agent_access")) {
                            viewButton = (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event, "show", row.id)}
                                    className="btn btn-sm btn-white mr-2"
                                    data-toggle="tooltip"
                                    title={t("global.view")}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </a>
                            );
                        }

                        if (permission.includes("agent_update")) {
                            editButton = (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event, "edit", row.id, row.username)}
                                    className="btn btn-sm btn-white"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                            );
                        }

                        // if (permission.includes('agent_update_setting')) {
                        //     rebateButton = (
                        //         <>&nbsp;&nbsp;&nbsp;<Button
                        //             typeButton="button"
                        //             classValue="btn btn-sm btn-rebate"
                        //             buttonName={<FaRegistered />}
                        //             key={`rebate-${row.agent_id}`}
                        //             clicked={(event) => this.props.detailsHandler(event, "setting", row.id)}
                        //         /></>
                        //     );
                        // }

                        output = [viewButton, editButton, rebateButton];
                        return output;
                    },
                    headerStyle: { width: "155px" }
                },
            ];
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("side-bar.agent")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row filter-wrapper-2`}>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("agent.username")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">{t("global.active")}</option>
                                                        <option value="0">{t("global.inactive")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("agent.agent-listing")}</h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            {permission.includes(
                                                "agent_create"
                                            ) && (
                                                    <>
                                                        <AiFillPlusCircle />
                                                        <Button
                                                            typeButton="button"
                                                            idValue="agent"
                                                            classValue="btn btn-underline"
                                                            clicked={() => this.props.changePageHandler("create")}
                                                            buttonName={t("agent.add-agent")}
                                                        />
                                                    </>

                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchValue={this.props.searchValue}
                                        // searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(AgentListing);
