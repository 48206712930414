import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import axios from "../../../common/config/axios";
import * as moment from "moment";

import { getMemberPayoutReport } from "../../../store/ducks/report/actions";
import { getFilterData } from "../../../store/ducks/gameList/actions";

import MemberPayoutReportListing from "./MemberPayoutReportListing";


const initialInputState =
{
    /* form input value */
    date: '',
    start_date: '',
    end_date: '',
    start_month: '',
    end_month: '',
    start_year: '',
    end_year: '',
    date_filter: '',
};

class MemberPayoutReport extends Component {

    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        order: '',

        data: [],
        sum_promo_bonus: 0,
        sum_rebate_bonus: 0,
        sum_up_level_bonus: 0,
        sum_vip_weekly_bonus: 0,
        sum_birthday_bonus: 0,
        sum_gold_digger_bonus: 0,
        sum_daily_mission_bonus: 0,
        sum_subtotal: 0,

        loading: true,
        hideColumn: false,
        dateFilter : '',

        permission: [],
        filter: initialInputState,
        asyncSelectedVal: null,
    }

    componentDidMount() {
        this.props.getFilterData();
        this.refreshHandler();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_"));
            this.setState({permission: this.props.currentPermission});
        }

        if (nextProps.reportData !== this.props.reportData) {
            if (nextProps.reportData.data && nextProps.reportData.data.responseCode === 200) {
                let multiple_totals = nextProps.reportData.data.data.multiple_totals;
                this.setState({
                    data: nextProps.reportData.data.data.result.row,
                    totalRecords: nextProps.reportData.data.data.result.pagination.total_record,
                    totalPages: nextProps.reportData.data.data.result.pagination.total_page,
                    loading: false,
                    hideColumn: (nextState.filter.date_filter === 'monthly' || nextState.filter.date_filter === 'yearly') ? true : false,
                    dateFilter: nextState.filter.date_filter,
                    sum_promo_bonus: multiple_totals[0]!==undefined ? multiple_totals[0] : 0,
                    sum_rebate_bonus: multiple_totals[1]!==undefined ? multiple_totals[1] : 0,
                    sum_up_level_bonus: multiple_totals[2]!==undefined ? multiple_totals[2] : 0,
                    sum_vip_weekly_bonus: multiple_totals[3]!==undefined ? multiple_totals[3] : 0,
                    sum_birthday_bonus: multiple_totals[4]!==undefined ? multiple_totals[4] : 0,
                    sum_gold_digger_bonus: multiple_totals[5]!==undefined ? multiple_totals[5] : 0,
                    sum_daily_mission_bonus: multiple_totals[6]!==undefined ? multiple_totals[6] : 0,
                    sum_subtotal: multiple_totals[7]!==undefined ? multiple_totals[7] : 0,
                });
            }
            else {
                this.setState({ loading: false });
            }
        }

        return true;
    }

    onInputChange(event, picker) {
        // console.log(event.target.id+' | '+event.target.value+' | '+event.target.className);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        if(event.target.id==='date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0].replace(/\//g, "-").toString() : '';
            newFilter['end_date'] = (date) ? date[1].replace(/\//g, "-").toString() : '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
            newFilter['date_filter'] = 'daily';
        }

        if(event.target.className.includes("monthly")) {
            newFilter['start_month'] = moment(picker.startDate).format("YYYY-MM");
            newFilter['end_month'] = moment(picker.endDate).format("YYYY-MM");
            newFilter['date'] = '';
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
            newFilter['date_filter'] = 'monthly';
        }

        if(event.target.className.includes("yearly")) {
            newFilter['start_year'] = moment(picker.startDate).format("YYYY");
            newFilter['end_year'] = moment(picker.endDate).format("YYYY");
            newFilter['date'] = '';
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
            newFilter['date_filter'] = 'yearly';
        }

        this.setState({
            filter: newFilter,
        });
    }

    onInputChangeAsync = (event, id) =>
    {
        // console.log(event);
        let newVal = (event) ? event.id : '';
        let newFilter = Object.assign({}, this.state.filter);

        newFilter[id] = newVal;

        this.setState({
            filter: newFilter,
            asyncSelectedVal: event,
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });

        return listingArr;
    }

    refreshHandler = () => {
        this.setState({
            sum_promo_bonus: 0,
            sum_rebate_bonus: 0,
            sum_up_level_bonus: 0,
            sum_vip_weekly_bonus: 0,
            sum_birthday_bonus: 0,
            sum_gold_digger_bonus: 0,
            sum_daily_mission_bonus: 0,
            sum_subtotal: 0,
        });

        const listingArr = this.getFilterArray();
        // console.log(listingArr);
        this.props.getMemberPayoutReport(listingArr);
    }

    searchHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1,
        }, () => {
            this.refreshHandler();
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.refreshHandler();
        });
    }

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            totalRecords: 0,
            loading: true,
            filter: newFilter,
            data: [],
        },() => {
            this.refreshHandler();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({
            limit: limit,
            loading: true,
            data: []},
        () => {
            this.refreshHandler();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.refreshHandler();
            });
        }
    }

    exportExcelHandler = () => {
        Swal.fire({
            // allowOutsideClick: false,
            // allowEscapeKey: false,
            // allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });

        const data = this.getFilterArray();
        // console.log(data);

        axios.get("report/export_member_payout_report", {
            params: data,
            responseType: "blob",
        }).then((res) => {
            const currentDate = new Date();
            const timestamp = moment(currentDate).format("YYMMDD HHmmss");
            let fileName = "100Express Report - Member Payout " + timestamp;
            const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const aElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            aElement.href = href;
            aElement.download = fileName;
            document.body.appendChild(aElement);
            aElement.click();
            document.body.removeChild(aElement);
            window.URL.revokeObjectURL(href);
            Swal.close();
        })
        .catch((err) => {
            console.log("Excel - MemberPayoutReport Error", err);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({order: orderArry, loading: true, data: []}, () => {
            this.refreshHandler();
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
        (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    }

    footerFormatter (column, colIndex, {text}) {
        return (
            <div>
                <p className='sub-total'>{column["footer"][0]}</p>
                <p>( {column["footer"][1]} )</p>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        const num_format = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };

        let page_total_promo_bonus = 0;
        let page_total_rebate_bonus = 0;
        let page_total_up_level_bonus = 0;
        let page_total_vip_weekly_bonus = 0;
        let page_total_birthday_bonus = 0;
        let page_total_gold_digger_bonus = 0;
        let page_total_daily_mission_bonus = 0;
        let page_subtotal = 0;

        this.state.data.forEach((val,idx) => {
            page_total_promo_bonus += parseFloat(val.total_promo_bonus!==null ? val.total_promo_bonus : 0);
            page_total_rebate_bonus += parseFloat(val.total_rebate_bonus!==null ? val.total_rebate_bonus : 0);
            page_total_up_level_bonus += parseFloat(val.total_up_level_bonus!==null ? val.total_up_level_bonus: 0);
            page_total_vip_weekly_bonus += parseFloat(val.total_vip_weekly_bonus!==null ? val.total_vip_weekly_bonus : 0);
            page_total_birthday_bonus += parseFloat(val.total_birthday_bonus!==null ? val.total_birthday_bonus : 0);
            page_total_gold_digger_bonus += parseFloat(val.total_gold_digger_bonus!==null ? val.total_gold_digger_bonus : 0);
            page_total_daily_mission_bonus += parseFloat(val.total_daily_mission_bonus!==null ? val.total_daily_mission_bonus : 0);
            page_subtotal += parseFloat(val.subtotal!==null ? val.subtotal : 0);
        });

        const columns = [
            {
                text: t('report.date'),
                dataField: "date",
                // keyField: "no",
                // formatter: (cell, row, rowIndex) => {
                //     let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10));
                //     return <span>{rowNumber}</span>;
                // },
                headerStyle: () => {
                    return {width: '90.5px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [t("global.sub-total"),t("global.total")],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("report.username"),
                dataField: "username",
                formatter: (cell, row, rowIndex) =>
                {
                    return <span>{row.username}</span>;
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                footer: ""
            },
            {
                text: t("game-result.promo"),
                dataField: "total_promo_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_promo_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_promo_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_promo_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("transaction.rebate"),
                dataField: "total_rebate_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_rebate_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_rebate_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_rebate_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("member-group.up-level-bonus"),
                dataField: "total_up_level_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_up_level_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_up_level_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_up_level_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("transaction-type.vip-weekly-bonus"),
                dataField: "total_vip_weekly_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_vip_weekly_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_vip_weekly_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_vip_weekly_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("transaction-type.birthday-bonus"),
                dataField: "total_birthday_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_birthday_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_birthday_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_birthday_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("transaction-type.gold-digger-bonus"),
                dataField: "total_gold_digger_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_gold_digger_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_gold_digger_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_gold_digger_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("transaction-type.daily-mission"),
                dataField: "total_daily_mission_bonus",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_daily_mission_bonus);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_daily_mission_bonus).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_daily_mission_bonus).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("global.total"),
                dataField: "subtotal",
                formatter: (cell, row, rowIndex) =>
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.subtotal);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_subtotal).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_subtotal).toString()
                ],
                footerFormatter: this.footerFormatter,
                footerClasses: 'table-footer'
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <MemberPayoutReportListing

                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        data={this.state.data}

                        searchHandler={this.searchHandler}
                        pageChangeHandler={this.pageChangeHandler}
                        clearHandler={() => this.clearHandler()}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        exportExcelHandler={(() => this.exportExcelHandler())}
                        onInputChangeAsync={(event, id) => this.onInputChangeAsync(event, id)}

                        loading={this.state.loading}
                        permission={this.state.permission}
                        filter={this.state.filter}
                        onInputChange={(event, picker) => this.onInputChange(event, picker)}

                        {...this.state}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_member_payout_report_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberPayoutReport: (filter) => dispatch(getMemberPayoutReport(filter)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberPayoutReport));
