import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class BankBalanceAdjustmentListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
        // console.log('constructor (listing):', this.state);
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        // console.log('render :', this.props.ddStatus);
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("bank-balance-adjustment.order-id"),
                dataField: "order_id",
                sort: true,
                onSort: this.props.onSort,
                headerStyle: { width: "115px" },
            },
            {
                text: t("bank-balance-adjustment.from-bank-account"),
                dataField: "from_bank_acc",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.from-bank-swift-code"),
                dataField: "from_bank_swift_code",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.to-bank-account"),
                dataField: "to_bank_acc",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.to-bank-swift-code"),
                dataField: "to_bank_swift_code",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.transaction-type"),
                dataField: "transaction_type",
            },
            {
                text: t("bank-balance-adjustment.amount"),
                dataField: "amount",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.fees"),
                dataField: "fees",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.transaction-date"),
                dataField: "transaction_date",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.create-date"),
                dataField: "create_date",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.update-date"),
                dataField: "update_date",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("bank-balance-adjustment.update-by"),
                dataField: "update_by",
                sort: true,
                onSort: this.props.onSort,
            },
        ];

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let orderIdVal = "";
                let numFormat = {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                };

                orderIdVal = permission.includes(
                    "transaction_details_access"
                ) ? (
                    <b>
                        <a
                            href="/#"
                            onClick={(event) =>
                                this.props.detailsHandler(event, val.id)
                            }
                        >
                            {val.order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                );

                return {
                    no: idx,
                    order_id: orderIdVal,
                    from_bank_acc: `${val.from_bank_acc ? val.from_bank_acc : (val.from_quickpay_acc ? val.from_quickpay_acc : '')}`,
                    from_bank_swift_code: val.from_bank_swift_code,
                    to_bank_acc: `${val.to_bank_acc ? val.to_bank_acc : (val.to_quickpay_acc ? val.to_quickpay_acc : '')}`,
                    to_bank_swift_code: val.to_bank_swift_code,
                    transaction_type: t(
                        `transaction-type.${val.transaction_type}`
                    ),
                    amount: new Intl.NumberFormat("en-US", numFormat).format(
                        val.amount
                    ),
                    fees: new Intl.NumberFormat("en-US", numFormat).format(
                        val.fees
                    ),
                    transaction_date: val.transaction_date,
                    create_date: val.create_date,
                    update_date: val.update_date,
                    update_by: val.update_by,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("bank-balance-adjustment.title")}
                            </h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div>
                            <div className="btn-group">
                                {permission.includes(
                                    "bank_balance_adjustment_cash_store"
                                ) && (
                                        <>
                                            &nbsp;&nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-white rounded"
                                                idValue="create"
                                                buttonName={t(
                                                    "bank-balance-adjustment.cash-in-out"
                                                )}
                                                clicked={() =>
                                                    this.props.changePageHandler(
                                                        "cash"
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-2`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "bank-balance-adjustment.transaction-date"
                                                    )}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.transaction_date}
                                                        onChange={this.props.onInputChange}
                                                        maxDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "bank-balance-adjustment.transaction-type"
                                                    )}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        onChange={this.props.onInputChange}
                                                        value={
                                                            this.props.filter
                                                                .transaction_type
                                                        }
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddTransactionType &&
                                                            this.props.ddTransactionType.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={idx}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction-type.${val.slug}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "bank-balance-adjustment.bank-account"
                                                    )}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="bank_acc"
                                                        name="bank_acc"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.bank_acc}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddBankAccount &&
                                                            this.props.ddBankAccount.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={idx}
                                                                            value={val.id}
                                                                        >
                                                                            {
                                                                                val.bank_acc_name
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("bank-balance-adjustment.listing")}
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            {permission.includes(
                                                "bank_balance_adjustment_store"
                                            ) && (
                                                    <>
                                                        <AiFillPlusCircle />
                                                        <Button
                                                            typeButton="button"
                                                            classValue="btn btn-underline"
                                                            idValue="create"
                                                            buttonName={t(
                                                                "bank-balance-adjustment.new-transfer"
                                                            )}
                                                            clicked={() =>
                                                                this.props.changePageHandler(
                                                                    "transfer"
                                                                )
                                                            }
                                                        />
                                                    </>

                                                )}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={
                                            this.props.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(BankBalanceAdjustmentListing);
