import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../../components/Input/StringInput/StringInput";
// import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Button from "../../../components/Button/Button";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

class MemberLoginInfoListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = this.props.totalRecords - rowIndex - (this.props.activePage - 1) * 10;
        // let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;
        let dataListing = [];
        let collidedDataListing = [];
        // let collidedRecordLength = 0;

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("member.ip-address"), dataField: "ipAddress", sort: true, onSort: this.props.onSort },
            { text: t("member.device-type"), dataField: "deviceType" },
            { text: t("member.login-time"), dataField: "loginTime", sort: true, desc: true, onSort: this.props.onSort },
            { text: t("member.finger-print-id"), dataField: "fingerPrintId", desc: true },
            { text: t("member.browser"), dataField: "browser", desc: true },
            { text: t("member.browser-version"), dataField: "browserVersion", desc: true },
            { text: t("member.operating"), dataField: "operatingSystem", desc: true },
            { text: t("member.operating-version"), dataField: "operatingSystemVersion", desc: true },
            { text: t("member.city"), dataField: "city" },
            { text: t("member.region"), dataField: "region" },
            { text: t("member.postal-code"), dataField: "postal_code" }
        ];

        const collidedColumns = [
            { text: t("global.no"), dataField: "collidedNo", headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("member.ip-address"), dataField: "ipAddress" },
            { text: t("member.device-type"), dataField: "deviceType" },
            { text: t("member.login-time"), dataField: "loginTime", sort: true, desc: true, onSort: this.props.onSort },
            { text: t("member.finger-print-id"), dataField: "fingerPrintId", desc: true },
            { text: t("member.browser"), dataField: "browser", desc: true },
            { text: t("member.browser-version"), dataField: "browserVersion", desc: true },
            { text: t("member.operating"), dataField: "operatingSystem", desc: true },
            { text: t("member.operating-version"), dataField: "operatingSystemVersion", desc: true },
            { text: t("member.city"), dataField: "city" },
            { text: t("member.region"), dataField: "region" },
            { text: t("member.postal-code"), dataField: "postal_code" },
            { text: t("member.ip-collided-member"), dataField: "collided_member", headerStyle: { width: "200px", textAlign: "center" } },
        ];

        if (this.props.data) {
            let memberData = this.props.data;
            let conflictData = this.props.conflictData;

            dataListing = memberData.map((val, idx) => {
                return {
                    no: idx,
                    ipAddress: val.ipAddress,
                    deviceType: val.deviceType,
                    loginTime: val.loginTime,
                    fingerPrintId: val.fingerPrintId,
                    browser: val.browser,
                    browserVersion: val.browserVersion,
                    operatingSystem: val.operatingSystem,
                    operatingSystemVersion: val.operatingSystemVersion,
                    city: val.city,
                    region: val.region,
                    postal_code: val.postal_code

                };
            });

            if (this.props.conflictData.length > 0) {
                // memberData.map(item => item.ipAddress).filter((value, index, self) => self.indexOf(value) === index);
                const uniqueIPArray = [...new Map(memberData.map((item) => [item["ipAddress"], item])).values()];

                uniqueIPArray.forEach((val, idx) => {
                    uniqueIPArray[idx]["collidedMember"] = [];
                    conflictData.forEach((cVal) => {
                        // if (val.ipAddress !== cVal.collidedIPAddress) {
                        uniqueIPArray[idx]["collidedMember"].push({ collidedId: cVal.collidedId, collidedEmail: cVal.collidedEmail });
                        // }
                    });
                });

                let uniqueIPArrayLength = uniqueIPArray.length;
                collidedDataListing = uniqueIPArray.map((val, idx) => {
                    let collided_val = val.collidedMember.map((val, idx) => {
                        return (
                            <span key={idx} onClick={(event) => this.props.showHandler(event, val.collidedId)} className="badge badge-pill badge-primary ml-1 mb-1 badgeBtn">
                                {val.collidedEmail}
                            </span>
                        );
                    });

                    let rowNo = uniqueIPArrayLength - (idx);
                    return {
                        collidedNo: rowNo,
                        ipAddress: val.ipAddress,
                        deviceType: val.deviceType,
                        loginTime: val.loginTime,
                        fingerPrintId: val.fingerPrintId,
                        browser: val.browser,
                        browserVersion: val.browserVersion,
                        operatingSystem: val.operatingSystem,
                        operatingSystemVersion: val.operatingSystemVersion,
                        city: val.city,
                        region: val.regionName,
                        postal_code: val.postal_code,
                        collided_member: collided_val,
                    };
                });
                // collidedRecordLength = uniqueIPArray.length;
            }

        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="transaction_form"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.login-time")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="login_time"
                                                        name="login_time"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.login_time}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.device-type")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="device_type"
                                                        name="device_type"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.device_type}>
                                                        <option value=""></option>
                                                        <option value="DESKTOP">{t("member.desktop")}</option>
                                                        <option value="MOBILE">{t("member.mobile")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member.filter-type")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="filter_type"
                                                        name="filter_type"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.filter_type}>
                                                        <option value=""></option>
                                                        <option value="collided">{t("member.collided")}</option>
                                                        <option value="all">{t("member.all")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>


                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member.login-info")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    {this.props.filter.filter_type === "all" ? (
                                        <DataTable
                                            keyField="no"
                                            data={dataListing}
                                            columns={columns}
                                            activePage={this.props.activePage}
                                            limit={this.props.limit}
                                            totalItems={this.props.totalRecords}
                                            paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                            sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                            searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                            searchValue={this.props.searchValue}
                                            classValue="dataTable table-bordered text-justify text-center"
                                            loading={this.props.loading}
                                            hideSearchBar={true}
                                        />
                                    ) : (
                                        <DataTable
                                            keyField="collidedNo"
                                            data={collidedDataListing}
                                            columns={collidedColumns}
                                            activePage={this.props.activePage}
                                            limit={this.props.limit}
                                            totalItems={this.props.totalConflictRecords}
                                            paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                            sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                            searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                            searchValue={this.props.searchValue}
                                            classValue="dataTable table-bordered text-justify text-center"
                                            loading={this.props.loading}
                                            hideSearchBar={true}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member.ip-collided-member")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="collidedNo"
                                        data={collidedDataListing}
                                        columns={collidedColumns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={collidedRecordLength}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                        hidePagination={true}
                                        hideSizePerPage={true}
                                        hideTotalRecords={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberLoginInfoListing);
