import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ReactLoader = (props) => {
    return (
        <div id="global-loader">
            <div className="loader-img">
                <Loader type={props.type} color={"#0162E8"} secondaryColor={"#00ADEF"} height={150} width={150} />
            </div>
        </div>
    );
};

export default ReactLoader;
