import axios from "../../../common/config/axios";
import * as type from "./types";

export const getSettings = (data) => (dispatch, getState) => {
    axios.get("gold_digger_setting/get_setting", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
                country_id: getState().currentUser.data.country_id
            },
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getDropdown = (data) => (dispatch, getState) => {
    let params = {};
    params.country_id = getState().currentUser.data.country_id;
    axios.get("gold_digger_setting/get_dropdown", {params: params}).then((res) => {
            dispatch({
                type: type.DROPDOWN,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.DROPDOWN,
                payload: [],
            });
        });
};

export const storeSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("gold_digger_setting/add_setting", data)
        .then((res) => {
            dispatch({
                type: type.STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.STORE,
                payload: err.data,
            });
        });
};

export const getSettingByID = (data) => (dispatch) => {
    axios.get("gold_digger_setting/get_setting_by_id", {params: data})
        .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res.data,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: err.data,
            });
        });
};

export const updateSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("gold_digger_setting/update_setting", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};

export const deleteSetting = (data) => (dispatch) => {
    axios
        .post("gold_digger_setting/delete", data)
        .then((res) => {
            dispatch({
                type: type.DELETE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.DELETE,
                payload: err.data,
            });
        });
};

export const updateStatus = (data) => dispatch =>
{
    axios.post('gold_digger_setting/update_status', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: type.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err =>  {
        dispatch({
            type: type.UPDATE_STATUS,
            payload: err.data,
        });   
    });
};

export const getListing = (data) => (dispatch, getState) => {
    axios.get("gold_digger_listing/get_listing", { 
        params: data,
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};