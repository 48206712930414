import React, {Component} from 'react';
import Modal from "react-modal";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary"
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar from 'react-avatar';
// import {faSearch, faAlignLeft, faCross, faUserCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
// import { IoIosArrowDown, IoMdClose } from 'react-icons/io';

import {faAlignLeft, faSearch} from '@fortawesome/free-solid-svg-icons';
import { BiLogOut} from 'react-icons/bi';
import { FaWallet } from 'react-icons/fa';
import { AiFillBank } from 'react-icons/ai';
import Swal from "sweetalert2";

// import Logo from '../../assets/images/logo/logo.png';
// import LogoWhite from '../../assets/images/logo/logo-white.png';
// import Favicon from '../../assets/images/logo/favicon.png';
import ProfileIcon from '../../assets/images/profile/profileIcon.jpg';
// import DefaultCountryFlag from '../../assets/images/flags/MY_flag.jpg';

import StringInput from "../Input/StringInput/StringInput";
import {postLogOut} from "../../store/ducks/auth/actions";
import {getCountry} from "../../store/ducks/home/actions";
import {getBellRingNotification} from '../../store/ducks/transaction/actions';
import {changeCountry, changeLanguage, getUserData} from "../../store/ducks/currentUser/actions";
import {getMemberInfo} from "../../store/ducks/home/actions";
import { filterErrorHandler } from '../../hoc/Shared/utility';

//Initialize the language and country array first
let langArr = [];
let countryArr = [];

class PageHeader extends Component {
    container = React.createRef();

    state = {
        desktopSearchValue: '',
        mobileSearchValue: '',
        open: false,
        openNotification:false,
        openLang: false,
        openCountry: false,
        currentCountryImage: 'MY',
        currentCountryId: 1,
        lang: localStorage.getItem('lang') || 'en',
        openCountryModal: false,
        openLangModal:false,
        // intervalTime: 60,
        depositNotification: '',
        withdrawalNotification: '',
        alertSoundUrl: '',
        musicPlay: false,

        pageShow: '',
        permission: []

    };

    componentDidMount() {
        if(localStorage.getItem('accessToken')){

            const countryData = {};

            countryData.country_id = this.props.currentUser.country_id || this.state.currentCountryId;

            if(this.props.currentUser.country_code !== null){
                import(`../../assets/images/flags/${this.props.currentUser.country_code}.png`)
                    .then(image => {
                        this.setState({
                            currentCountryImage: image.default
                        })
                    });
            }else{
                import(`../../assets/images/flags/${this.state.currentCountryImage}.png`)
                    .then(image => {
                        this.setState({
                            currentCountryImage: image.default
                        })
                    });
            }

            this.props.getCountry(countryData);

            document.addEventListener("mousedown", this.handleClickOutside);

            this.props.getBellRingNotification();
        }
    }

    toggleAlert() {
        var alertSound = new Audio(this.state.alertSoundUrl)
        alertSound.play()
        .catch(err => {
            // play() failed because the user didn't interact with the document first.
            // console.log(err.message)
        })
    }

    componentWillUnmount()
    {
        clearInterval(this.intervalId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextState.permission.length === 0 && this.props.currentUser.permission !== undefined){
            // let memberPermission = this.props.currentPermission.filter((item) => item.includes("member_"));
            this.setState({ permission: this.props.currentUser.permission });
        }

        if (nextProps.logout_data !== this.props.logout_data) {
            if (nextProps.logout_data.responseCode === 200) {

                langArr = [];
                countryArr = [];

                localStorage.clear();
                this.props.history.push('/login');
            } else {
                return false;
            }
        }

        if(nextProps.bell_ring_notification !== this.props.bell_ring_notification) {
            if (nextProps.bell_ring_notification.responseCode === 200) {
                let refreshTime = nextProps.bell_ring_notification.data.refreshTime;
                
                this.setState({
                    depositNotification: nextProps.bell_ring_notification.data.depositRecord,
                    withdrawalNotification: nextProps.bell_ring_notification.data.withdrawalRecord,
                    alertSoundUrl: nextProps.bell_ring_notification.data.alertSoundUrl,
                    // intervalTime: refreshTime,
                });

                if(nextProps.bell_ring_notification.data.depositRecord > 0 || nextProps.bell_ring_notification.data.withdrawalRecord > 0) {
                    this.toggleAlert();
                }

                clearInterval(this.intervalId);
                this.intervalId = setInterval(() => {
                    this.props.getBellRingNotification();
                }, refreshTime*1000);

            } else {
            this.setState({loading: false});
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let countryData = nextProps.country_data.data.country;
            let languageData = nextProps.country_data.data.language;

            //Clear the country array
            countryArr = [];
            langArr = [];

            Object.keys(countryData).map((item, i) => {
                import(`../../assets/images/flags/${countryData[item].countryShortCode}.png`)
                    .then(image => {
                         countryArr.push({
                            'image': image.default,
                            'countryId': countryData[item].countryId,
                            'countryName': countryData[item].countryName,
                            'countryCode': countryData[item].countryShortCode,
                            'languageId': countryData[item].defaultLanguageId,
                            'languageCode': countryData[item].defaultLanguageCode
                        });
                    })

                   return countryArr;
            })

                Object.keys(languageData).map((item, i) => {
                    return langArr.push({
                        'id': languageData[item].languageId,
                        'code': languageData[item].languageCode,
                        'desc': languageData[item].languageName
                    });
                })
        }

        if (nextProps.member_data !== this.props.member_data) {
            if (nextProps.member_data.data.responseCode === 200) {
                Swal.close();

                this.props.history.push({
                    pathname: '/member',
                    state: {
                        show_user_id: nextProps.member_data.data.data.id,
                        pageShow: "show",
                        memberShowUsername: this.state.desktopSearchValue
                    }
                });
            } else  {
                Swal.close();
                this.setState({
                    touched: true,
                    error_message: nextProps.member_data.data.message,
                }, () => {
                    filterErrorHandler(nextProps.member_data.data.message);
                });
            }
        }

        return true;
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
                open: false,
                openLang: false,
                openCountry: false,
                openNotification:false,
            });
        }
    };

    getDesktopSearchValueHandler = (event) => {
        this.setState({
            desktopSearchValue: event.target.value
        });
    }

    getMobileSearchValueHandler = (event) => {
        this.setState({
            mobileSearchValue: event.target.value
        });
    }

    openProfileHandler = () => {
        this.setState({
            open: !this.state.open,
            openLang: false,
            openCountry: false,
            openNotification: false,
        });
    }

    openLangHandler = (e) => {
        e.preventDefault();
        this.setState({
            open: false,
            openLang: !this.state.openLang,
            openCountry: false,
            openNotification: false,
        });
    }

    openCountryHandler = (e) => {
        e.preventDefault();
        this.setState({
            open: false,
            openLang: false,
            openCountry: !this.state.openCountry,
            openNotification: false,
        });
    }

    changeLangHandler = (e, lng, id) => {
        e.preventDefault();
        let la = lng.replace("-", "_").toString().toLowerCase();
        localStorage.setItem('lang', la);

        const loggedData = this.props.currentUser;
        loggedData.language_code = la;
        loggedData.language_id = id;
        this.props.getUserData(loggedData);
        this.props.i18n.changeLanguage(la);
        this.setState({
            openLang: !this.state.openLang,
            lang: la
        });

        // Redux Persist
        let languageData = {};
        languageData.language_id = id;
        languageData.language_code = lng.toLowerCase();
        this.props.changeLanguage(languageData);

        this.props.history.push(this.props.location.pathname);
    }

    changeCountryHandler = (e, countryCode, countryId, languageId, languageCode) => {
        e.preventDefault();

        const countryData = {};
        countryData.country_id = countryId;
        countryData.country_code = countryCode;
        this.props.changeCountry(countryData); // Redux Persist Current User
        this.props.getCountry(countryData);

        const loggedData = this.props.currentUser;
        loggedData.country_code = countryCode;
        loggedData.country_id = countryId;
        loggedData.language_code = languageCode;
        loggedData.language_id = languageId;
        this.props.getUserData(loggedData);

        let la = languageCode.replace("-", "_").toString().toLowerCase();

        localStorage.setItem('lang', languageCode);
        this.props.i18n.changeLanguage(la);

        import(`../../assets/images/flags/${countryCode}.png`)
            .then(image => {
                console.log(image.default);
                this.setState({
                    currentCountryImage: image.default,
                    openCountry: !this.state.openCountry,
                    lang: la
                })
            });

       this.props.history.push(this.props.location.pathname);
    }

    signOutHandler = () => {
        this.props.postLogOut(this.props);
    }

    openAdminProfileHandler = () => {
        this.setState({
            open: false
        });

        this.props.history.push({
            pathname: '/admin',
            state: {
                show_admin_id: this.props.currentUser.id,
                pageShow: "show"
            }
        });
    }

    switchCountryModal = () =>
    {
        this.setState({
            openCountryModal: !this.state.openCountryModal,
            open: false,
            openLang: false,
            openCountry: !this.state.openCountry,
            openNotification: false,
        });
    }

    closeCountryModal = () =>
    {
        this.setState({
            openCountryModal: false,
        });
    }

    switchLangModal = () =>
    {
        this.setState({
            openLangModal: !this.state.openLangModal,
        });
    }

    closeLangModal = () =>
    {
        this.setState({
            openLangModal: false,
        });
    }

    openNotificationAction = () => {
        this.setState({
            openNotification: !this.state.openNotification,
            openLang: false,
            openCountry: false,
            open: false,
        })
    }

    imageImport = (r) =>
    {
        let images = {};
        r.keys().map((item, index) =>
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    onSearchMemberUsername = (event) => {
        event.preventDefault();

        if(this.state.desktopSearchValue === ""){
            return false;
        }

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const data = {};
        data.email = this.state.desktopSearchValue;

        this.props.getMemberInfo(data);
    }

    render() {
        const { t } = this.props;
        let currentUser = (this.props.currentUser) ? this.props.currentUser : [];
        // const flag = this.imageImport(require.context("../../assets/images/flags", false, /\.(png|jpe?g|svg)$/));
        // let permission = this.state.permission ? this.state.permission : [];

        return (
            <Auxiliary>
                {/* {this.state.pageShow === "show" && (
                    <MemberShow 
                        memberShowId={this.state.memberShowId}
                        memberShowUsername={this.state.memberShowUsername}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.state.permission}
                        memberShowCount={this.state.memberShowCount}
                    />
                )} */}

                <Modal isOpen={this.state.openCountryModal} className="modal-select-country" ariaHideApp={false} onRequestClose={(event) => this.closeCountryModal(event) } >
                    <div className="modal-dialog modal-md modal-dialog-centered modal-sm">
                        <div className="modal-content" onClick={this.closeCountryModal}>
                            <div className="modal-header">
                                <h5 className="text-center">{t("header.select-your-country")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCountryModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <IoMdClose size={28} onClick={this.closeCountryModal} className="float-right text-white"></IoMdClose> */}
                            </div>
                            <div className="modal-body px-4 p-4 ">

                                {<div className="row justify-content-center">
                                    {countryArr.map((val, idx) => {
                                        if(currentUser.country_code !== val.countryCode) {
                                        return (
                                            <div className="col-12 py-3 row align-items-center" key={idx} onClick={(event) => {this.changeCountryHandler(event, val.countryCode, val.countryId, val.languageId, val.languageCode);this.closeCountryModal()}}>
                                                <div className="modal-country d-flex justify-content-center " key={idx}>
                                                    <img className="modal-country-flag me-2" src={val.image} alt="img"/>
                                                </div>
                                                <h6 className="text-center mb-0 px-4">{val.countryName}</h6>
                                            </div>)
                                        } else {
                                            return null;
                                        }

                                    })}
                                </div>}
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <Modal isOpen={this.state.openLangModal} className="modal-select-country" ariaHideApp={false} onRequestClose={(event) => this.closeLangModal(event) } >
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5>Select your Language</h5>
                                <IoMdClose size={28} onClick={this.closeLangModal} className="float-right text-white"></IoMdClose>
                            </div>
                            <div className="modal-body px-4 p-4">

                                {<div className="modal-country justify-content-center">
                                    <div className="row modal-country-languages">
                                    {langArr.map((val, idx) => {
                                        if(currentUser.language_code !== val.code) {
                                            return (
                                                (val.code.replace("-", "_").toString().toLowerCase() !== this.state.lang)
                                                    ?
                                                    <div className="modal-country-language 2" key={idx}>
                                                        <div className="modal-country-language-link "
                                                            onClick={(event) => {this.changeLangHandler(event, val.code, val.id); this.closeLangModal()}}>
                                                            <span>{val.code} - {val.desc}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </Modal> */}
                <div className="container" ref={this.container}>
                    <div className="main-header sticky side-header nav nav-item">
                        <div className="container-fluid">
                            <div className="main-header-left">
                                {/* <div className="responsive-logo">
                                    <a href="/"><img
                                        src={Logo} className="logo-1" alt="logo"/></a>
                                    <a href="/"><img
                                        src={LogoWhite} className="dark-logo-1"
                                        alt="logo"/></a>
                                    <a href="/"><img
                                        src={Favicon} className="logo-2" alt="logo"/></a>
                                    <a href="/"><img
                                        src={Favicon} className="dark-logo-2" alt="logo"/></a>
                                </div> */}
                                <div className="app-sidebar__toggle" data-toggle="sidebar">
                                    {/* <a className="open-toggle" href="/"> */}
                                    <span className="header-icon" onClick={this.props.toggleSideBar}>
                                        <FontAwesomeIcon icon={faAlignLeft}/>
                                    </span>
                                    {/* </a> */}
                                    {/* <a className="close-toggle" href="/">
                                    <span className="header-icon">
                                        <FontAwesomeIcon icon={faCross}/>
                                    </span>
                                    </a> */}
                                </div>

                                {this.state.permission.includes("member_view") && (
                                    <div className="main-header-center ml-3 d-sm-none d-md-none d-lg-block">
                                        <form onSubmit={this.onSearchMemberUsername}>
                                            <StringInput
                                                onChange={this.getDesktopSearchValueHandler}
                                                id="desktopSearch"
                                                name="desktopSearch"
                                                placeholder={t("header.search-email")}
                                            />
                                            <button className="btn"><FontAwesomeIcon icon={faSearch}/></button>
                                            {/* {this.state.desktopSearchValue !== "" ? <button className="btn"><FontAwesomeIcon icon={faSearch}/></button> : null } */}
                                            
                                       </form>
                                   </div>
                                )}
                            </div>

                            <div className="main-header-right">
                                {/* <div role="button" className="btn-text-icon-gray d-flex align-items-center nav">
                                    <Auxiliary>
                                        <div className="btn">
                                            <img src={this.state.currentCountryImage} width={30} height={20} className="iconify iconify--ri flag" alt="Country" onClick={this.switchCountryModal}/>
                                        </div>
                                        <p className="mb-0 pl-2 language__selected" onClick={this.switchLangModal}>{this.state.lang.replace("_", "-").toString().toUpperCase()}</p>
                                    </Auxiliary>
                                </div> */}
                                <ul className="nav">
                                    <li className="">
                                        <div className="dropdown nav-itemd-none d-flex">
                                            <div className="d-flex nav-item nav-link country-flag1"
                                            data-toggle="dropdown" aria-expanded="false"
                                            // onClick={(event) => this.openCountryHandler(event)}
                                            onClick={this.switchCountryModal}
                                            >
                                                <span
                                                    className="avatar country-Flag mr-0 align-self-center bg-transparent">
                                                    <img src={this.state.currentCountryImage} alt="img"/>
                                                </span>

                                                <div className="my-auto">
                                                    <strong className="mr-2 ml-2 my-auto"></strong>
                                                </div>
                                            </div>

                                            {/* {this.state.openCountry && (<div
                                                className="dropdown-menu dropdown-menu-center dropdown-menu-arrow show">
                                                {countryArr.map((val, idx) => {
                                                    if(currentUser.country_code !== val.countryCode) {
                                                    return (
                                                        <a href="/#"
                                                        className="dropdown-item d-flex"
                                                        key={idx}
                                                        onClick={(event) => this.changeCountryHandler(event, val.countryCode, val.countryId, val.languageId, val.languageCode)}>
                                                        <span className="avatar  mr-3 align-self-center bg-transparent"><img
                                                            src={val.image}
                                                            alt="img"/></span>
                                                            <div className="d-flex" value={val.countryCode}>
                                                                <span className="mt-2 dropright">{val.countryName}</span>
                                                            </div>
                                                        </a>)
                                                    } else {
                                                        return null;
                                                    }

                                                })}
                                            </div>)} */}
                                        </div>
                                    </li>

                                    <li className="">
                                        <div className="dropdown  nav-itemd-none d-flex">
                                            <a href="/#" className="d-flex nav-item nav-link px-0 country-flag1 mt-2"
                                            data-toggle="dropdown" aria-expanded="false"
                                            onClick={(event) => this.openLangHandler(event)}>
                                                {this.state.lang.replace("_", "-").toString().toUpperCase()}
                                            </a>

                                            {this.state.openLang && (<div
                                                className="dropdown-menu dropdown-menu-center dropdown-menu-arrow show">
                                                {langArr.map((val, idx) => {
                                                    if(currentUser.language_code !== val.code) {
                                                        return (
                                                            (val.code.replace("-", "_").toString().toLowerCase() !== this.state.lang)
                                                                ?
                                                                <a href="/#" key={idx}>
                                                                    <div className="dropdown-item d-flex d-flex"
                                                                        onClick={(event) => this.changeLangHandler(event, val.code, val.id)}>
                                                                        <span
                                                                            className="mt-2">{val.code} - {val.desc}</span>
                                                                    </div>
                                                                </a>
                                                                :
                                                                null
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>)}
                                        </div>
                                    </li>
                                </ul>

                                <div className="nav nav-item navbar-nav-right ml-auto">
                                    <div className="nav-link" id="bs-example-navbar-collapse-1">
                                        <form className="navbar-form" role="search">
                                            <div className="input-group">
                                                <StringInput
                                                    onChange={this.getMobileSearchValueHandler}
                                                    id="mobileSearch"
                                                    name="mobileSearch"
                                                    placeholder="Search for anything...."
                                                />
                                                <span className="input-group-btn">
                                                <button type="reset" className="btn btn-default">
                                                    <i className="fas fa-times"></i>
                                                </button>
                                                <button type="submit" className="btn btn-default nav-link resp-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-search header-icon-svgs"><circle cx="11" cy="11" r="8"></circle><line
                                                        x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                </button>
                                            </span>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className="dropdown nav-item main-header-message ">
                                        <a className="new nav-link" href="/">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                strokeLinecap="round" strokeLinejoin="round"
                                                className="feather feather-mail header-icon-svgs">
                                                <path
                                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                <polyline points="22,6 12,13 2,6"></polyline>
                                            </svg>
                                            <span className=" pulse-danger"></span>
                                        </a>
                                            <div className="dropdown-menu">
                                                <div className="menu-header-content bg-primary text-left">
                                                    <div className="d-flex">
                                                        <h6 className="dropdown-title mb-1 tx-15 text-white font-weight-semibold">Messages</h6>
                                                    </div>
                                                    <p className="dropdown-title-text subtext mb-0 text-white op-6 pb-0 tx-12 ">You
                                                        have 4 unread messages</p>
                                                </div>
                                                <div className="main-message-list chat-scroll">
                                                    <a href="/" className="p-3 d-flex border-bottom">
                                                        <div className="  drop-img cover-image"
                                                            data-image-src={ProfileIcon}>
                                                            <span className="avatar-status bg-teal"></span>
                                                        </div>
                                                        <div className="wd-90p">
                                                            <div className="d-flex">
                                                                <h5 className="mb-1 name">Petey Cruiser</h5>
                                                            </div>
                                                            <p className="mb-0 desc">I'm sorry but i'm not sure how to help you
                                                                with
                                                                that......</p>
                                                            <p className="time mb-0 text-left float-left ml-2 mt-2">Mar 15 3:55
                                                                PM</p>
                                                        </div>
                                                    </a>
                                                    <a href="/" className="p-3 d-flex border-bottom">
                                                        <div className="drop-img cover-image"
                                                            data-image-src={ProfileIcon}>
                                                            <span className="avatar-status bg-teal"></span>
                                                        </div>
                                                        <div className="wd-90p">
                                                            <div className="d-flex">
                                                                <h5 className="mb-1 name">Jimmy Changa</h5>
                                                            </div>
                                                            <p className="mb-0 desc">All set ! Now, time to get to you
                                                                now......</p>
                                                            <p className="time mb-0 text-left float-left ml-2 mt-2">Mar 06 01:12
                                                                AM</p>
                                                        </div>
                                                    </a>
                                                    <a href="/" className="p-3 d-flex border-bottom">
                                                        <div className="drop-img cover-image"
                                                            data-image-src={ProfileIcon}>
                                                            <span className="avatar-status bg-teal"></span>
                                                        </div>
                                                        <div className="wd-90p">
                                                            <div className="d-flex">
                                                                <h5 className="mb-1 name">Graham Cracker</h5>
                                                            </div>
                                                            <p className="mb-0 desc">Are you ready to pickup your
                                                                Delivery...</p>
                                                            <p className="time mb-0 text-left float-left ml-2 mt-2">Feb 25 10:35
                                                                AM</p>
                                                        </div>
                                                    </a>
                                                    <a href="/" className="p-3 d-flex border-bottom">
                                                        <div className="drop-img cover-image"
                                                            data-image-src={ProfileIcon}>
                                                            <span className="avatar-status bg-teal"></span>
                                                        </div>
                                                        <div className="wd-90p">
                                                            <div className="d-flex">
                                                                <h5 className="mb-1 name">Donatella Nobatti</h5>
                                                            </div>
                                                            <p className="mb-0 desc">Here are some products ...</p>
                                                            <p className="time mb-0 text-left float-left ml-2 mt-2">Feb 12 05:12
                                                                PM</p>
                                                        </div>
                                                    </a>
                                                    <a href="/" className="p-3 d-flex border-bottom">
                                                        <div className="drop-img cover-image"
                                                            data-image-src={ProfileIcon}>
                                                            <span className="avatar-status bg-teal"></span>
                                                        </div>
                                                        <div className="wd-90p">
                                                            <div className="d-flex">
                                                                <h5 className="mb-1 name">Anne Fibbiyon</h5>
                                                            </div>
                                                            <p className="mb-0 desc">I'm sorry but i'm not sure how...</p>
                                                            <p className="time mb-0 text-left float-left ml-2 mt-2">Jan 29 03:16
                                                                PM</p>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="text-center dropdown-footer">
                                                    <a href="/">VIEW ALL</a>
                                                </div>
                                            </div>
                                    </div> */}

                                    <div className="dropdown nav-item main-header-notification">
                                        <div className="new nav-link header__notification position-relative" onClick={this.openNotificationAction}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                strokeLinecap="round" strokeLinejoin="round"
                                                className="feather feather-bell header-icon-svgs">
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            {this.state.depositNotification > 0 || this.state.withdrawalNotification > 0 ?
                                                <span className="pulse"></span> : null
                                            }
                                        </div>
                                        {
                                            this.state.openNotification &&(
                                            <div className="dropdown-menu d-block" style={{top:46}}>
                                                <div className="menu-header-content bg-primary text-left">
                                                    <div className="d-flex">
                                                        <h6 className="dropdown-title mb-1 tx-15 text-white font-weight-semibold">{t("header.notifications")}</h6>
                                                    </div>
                                                    {/* <p className="dropdown-title-text subtext mb-0 text-white op-6 pb-0 tx-12 ">You
                                                        have 4 unread Notifications</p> */}
                                                </div>
                                                <div className="main-notification-list Notification-scroll" style={{overflowY:'auto'}}>
                                                    {/* <a className="d-flex p-3 border-bottom" href="/transaction">
                                                        <div className="notifyimg bg-pink">
                                                            <i className="la la-file-alt text-white"><BiTransferAlt className="text-white" size={24}/></i>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h5 className="notification-label mb-1">New Transaction</h5>
                                                            <div className="notification-subtext">10 hour ago</div>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <i className="las la-angle-right text-right text-muted">2</i>
                                                        </div>
                                                    </a> */}
                                                    <a className="d-flex p-3 align-items-center" href="/deposit">
                                                        <div className="notifyimg bg-purple">
                                                            <i className="la la-gem text-white"><FaWallet className="text-white mb-1"/></i>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h5 className="notification-label mb-1">{t("header.new-deposit")}</h5>
                                                            {/* <div className="notification-subtext">2 days ago</div> */}
                                                        </div>
                                                        <div className="ml-auto">
                                                            <i className="las la-angle-right text-right text-muted">{this.state.depositNotification}</i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3 border-bottom align-items-center" href="/withdrawal">
                                                        <div className="notifyimg bg-success">
                                                            <i className="la la-shopping-basket text-white"><AiFillBank className="text-white mb-1" size={24}/></i>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h5 className="notification-label mb-1">{t("header.new-withdrawal")}</h5>
                                                            {/* <div className="notification-subtext">1 hour ago</div> */}
                                                        </div>
                                                        <div className="ml-auto">
                                                            <i className="las la-angle-right text-right text-muted">{this.state.withdrawalNotification}</i>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="dropdown-footer">
                                                    <a href="/transaction">{t("header.view-all")}</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="dropdown main-profile-menu nav nav-item nav-link">
                                    <span className="profile-user d-flex" onClick={this.openProfileHandler}>
                                        <Avatar name={currentUser.username} round="100px" size="40" color="#0162e8"/>
                                    </span>

                                        {this.state.open && (
                                            <div className="main-profile-menu show dropdown-menu">
                                                <div className="main-header-profile bg-primary p-3">
                                                    <div className="d-flex wd-100p">
                                                        <div className="main-img-user">
                                                            <img alt="img" src={ProfileIcon}
                                                                className=""/></div>
                                                        <div className="ml-3 my-auto">
                                                            <h6>{currentUser.username}</h6>
                                                            {/* <span>{currentUser.user_group}</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {(permission.includes('admin_show') || permission.includes('profile_access')) && ( */}
                                                <span className="dropdown-item profile-user" onClick={this.openAdminProfileHandler}>
                                                    <i className="far fa-user-circle"></i>{t("header.profile")}
                                                </span>
                                                {/* )} */}
                                                {/*<a className="dropdown-item" href="/"><i className="bx bxs-inbox"></i>Inbox</a>*/}
                                                {/*<a className="dropdown-item" href="/"><i className="bx bx-envelope"></i>Messages</a>*/}
                                                {/*<a className="dropdown-item" href="/"><i className="bx bx-slider-alt"></i> Account*/}
                                                {/*    Settings</a>*/}
                                                <span className="dropdown-item profile-user" onClick={this.signOutHandler}>
                                                    <i className="far fa-log-out"><BiLogOut/></i>
                                                    {t("header.sign-out")}
                                                </span>
                                            </div>
                                        )}

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    const {auth, home, transaction} = state;

    return {
        logout_data: auth.logout_data,
        languages_data: home.languages_data,
        country_data: home.country_data,
        bell_ring_notification: transaction.notification,
        currentUser: state.currentUser.data,
        member_data: home.member_data
        // currentPermission: state.currentUser.data.permission
    }
};

const mapDispatchToProps = {
    postLogOut,
    getBellRingNotification,
    getCountry,
    changeCountry,
    changeLanguage,
    getUserData,
    getMemberInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces('translation')(PageHeader)));