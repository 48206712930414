import axios from '../../../common/config/axios';
import * as type from "../walletType/types";

//Call the api for example call the login api
export const getWalletType = (data) => dispatch => {
    axios.get('wallet/list', { params: data})
    .then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_WALLET_TYPE_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const postWalletType = (data) => dispatch => {
    axios.post('wallet/store',data)
    .then( (response) => {
        dispatch ({
            type: type.CREATE_WALLET_TYPE,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const showWalletType = (data) => dispatch => {
    axios.get('wallet/show', {
        params: {
            "id": data['wallet_type_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_WALLET_TYPE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateWalletType = (data) => dispatch => {
    axios.post('wallet/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_WALLET_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteWalletType = (data) => dispatch => {
    axios.post('wallet/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_WALLET_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateWalletTypeStatus = (data) => dispatch => {
    axios.post('wallet/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_WALLET_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};