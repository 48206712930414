import { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { getHistory, getDropDown, refundHistory } from "../../store/ducks/rewardItem/actions";

import RewardItemHistoryListing from "./RewardItemHistoryListing";
import RewardItemHistoryEdit from "./RewardItemHistoryEdit";
import RewardItemHistoryDetails from "./RewardItemHistoryDetails";

const initialFilteringState = {
    start_date: "",
    end_date: "",
    date: "",
    member: "",
    title: "",
    category: "",
    reference_code: "",
    id: ""
};

class RewardItemHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pageShow: "listing",
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            data: [],
            filterInput: initialFilteringState,
            detailsParam: {},
            editId: "",
            permission: [],
            dropDown: []
        }
    }

    componentDidMount(){
        this.props.getDropDown();
        const listingArr = this.fetchData();
        this.props.getHistory(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.permission.length===0 && this.props.currentPermission!==undefined){
            this.setState({ permission: this.props.currentPermission });
        }

        if(nextProps.dropDown !== undefined && nextProps.dropDown !== this.props.dropDown){
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success"){
                this.setState({
                    dropDown: nextProps.dropDown.data.category
                });
            }
        }

        if(nextProps.history !== undefined && nextProps.history !== this.props.history){
            if(nextProps.history.responseCode === 200 && nextProps.history.msgType === "success"){
                this.setState({
                    loading: false,
                    data: nextProps.history.data.result.row,
                    totalRecords: nextProps.history.data.result.pagination.total_record
                });
            }
        }

        if(nextProps.history_refund !== undefined && nextProps.history_refund !== this.props.history_refund){
            if(nextProps.history_refund.responseCode === 200 && nextProps.history_refund.msgType === "success"){
                const { t } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ loading: true });
                        this.refreshHandler();
                    }
                });
            }
            else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.something-wrong"),
                    icon: nextProps.daily_mission_setting_status.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filterInput).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filterInput[val]!=='') ? moment(this.state.filterInput[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filterInput[val];
        });
        return listingArr;
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filterInput);
        if(event.target.id==='transaction_date'){
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
            newFilter['date'] = event.target.value;
        }
        else{
            newFilter[event.target.id] = event.target.value;
        }
        this.setState({
            filterInput: newFilter
        });
    }

    clearHandler = () => {
        let newFilter = initialFilteringState;
        this.setState({
            activePage: 1,
            limit: 10,
            order: '',
            filterInput: newFilter,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getHistory(listingArr);
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getHistory(listingArr);
        });
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam : {
                id: id
            }
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    }

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            editId: id,
            pageShow: "edit",
        });
    }

    refundHandler = (event, id) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.refund"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const data = {};
                data.id = id;
                // console.log("Refund ",data);
                this.props.refundHistory(data);
            }
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getHistory(listingArr);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getHistory(listingArr);
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, output, refundButton;

        if(this.state.permission.includes("reward_item_history_update")){ // only a processing/confirmed transaction can edit ref code
            if(row.statusId === 3 || row.statusId === 1){
                editButton = (
                    <Button
                        typeButton="button"
                        classValue="btn btn-sm btn-white mr-2"
                        key={`edit-${row.Id}`}
                        idValue={row.Id === undefined ? 0 : `edit-id-${row.Id}`}
                        clicked={(event) => this.editHandler(event, row.Id)}
                        buttonName={<FontAwesomeIcon icon={faPen} />}
                    />
                );
            }

            if(row.statusId === 0 || row.statusId === 3){
                refundButton = (
                    <Button 
                        typeButton="button"
                        classValue="btn btn-sm btn-white"
                        key={`refund-${row.Id}`}
                        idValue={row.Id === undefined ? 0 : `refund-id-${row.Id}`}
                        clicked={(event) => this.refundHandler(event, row.Id)}
                        buttonName={<FontAwesomeIcon icon={faTimes} />}
                    />
                )
            }
        }

        output = [editButton, refundButton];
        return output;
    }

    render(){
        const { t } = this.props;
        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("global.id"), dataField: "Id", sort: false },
            { text: t("reward-item.member"), dataField: "member", sort: true, onSort: this.onSort },
            { text: t("reward-item.item"), dataField: "itemTitle", sort: true, onSort: this.onSort },
            { text: t("reward-item.category"), dataField: "category", sort: true, onSort: this.onSort },
            { text: t("reward-item.credit"), dataField: "credit", sort: true, onSort: this.onSort },
            { text: t("reward-item.debit"), dataField: "debit", sort: true, onSort: this.onSort },
            { text: t("reward-item.status"), dataField: "status", sort: true, onSort: this.onSort },
            { text: t("reward-item.reference-code"), dataField: "referenceCode", sort: true, onSort: this.onSort },
            { text: t('reward-item.date'), dataField: "createdAt", sort: true, onSort: this.onSort },
            { text: t('reward-item.update-date'), dataField: "updatedAt", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "130px" } }
        ];
        
        let data = this.state.data.map((val, idx) => {
            let statusStr = "";
            if(val.status === 5){
                //refunded
                statusStr = <>
                    <span>{t(`transaction.status-desc.${val.status}`)}</span>
                    <br />
                    <span>({t('reward-item.refer-to')} {val.parent})</span>
                </>;
            }
            else{
                statusStr = <span>{t(`transaction.status-desc.${val.status}`)}</span>;
            }

            let referenceCodeVal = (val.categorySlug === 'free-bet' && val.referenceCode) 
            ?
                <b><a href="/#" onClick={(event) => this.detailsHandler(event, val.id)}>
                    {val.referenceCode}
                    <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                </a></b>
            :
                (val.referenceCode ? val.referenceCode : '-')
            ;

            return {
                no: idx,
                Id: val.id,
                member: val.member,
                itemTitle: val.itemTitle,
                category: t(`reward-category.${val.categorySlug}`),
                credit: val.credit > 0 ? val.credit : '-',
                debit: val.debit > 0 ? val.debit : '-',
                status: statusStr,
                referenceCode: referenceCodeVal,
                createdAt: val.createdAt,
                updatedAt: val.updatedAt,
                statusId: val.status
            }
        });

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && 
                <RewardItemHistoryListing
                    filterInput={this.state.filterInput}

                    changePageHandler={(page)=>this.changePageHandler(page)}
                    clearHandler={() => this.clearHandler()}
                    refreshHandler={() => this.refreshHandler()}
                    onInputChange={(event) => this.onInputChange(event)}
                    datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                    onSort={(field, order) => this.onSort(field, order)}

                    data={data}
                    columns={columns}
                    activePage={this.state.activePage}
                    limit={this.state.limit}
                    totalRecords={this.state.totalRecords}
                    ddCategory={this.state.dropDown}

                    loading={this.state.loading}
                />}

                {this.state.permission.includes("reward_item_history_update") && this.state.pageShow === "edit" && 
                <RewardItemHistoryEdit 
                    editId={this.state.editId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />}

                {this.state.pageShow==='details' &&
                <RewardItemHistoryDetails
                    detailsParam={this.state.detailsParam}
                    changePageHandler={(page,xPosition,yPosition) => this.changePageHandler(page,xPosition,yPosition)}
                    returnPage='listing'
                />}
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPermission: state.currentUser.data.permission,
        history: state.rewardItem.history,
        dropDown: state.rewardItem.dropDown,
        history_refund: state.rewardItem.history_refund
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHistory: (data) => dispatch(getHistory(data)),
        getDropDown: () => dispatch(getDropDown()),
        refundHistory: (data) => dispatch(refundHistory(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RewardItemHistory));

