import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import LeaderboardListing from "./LeaderboardListing";
import LeaderboardCreate from "./LeaderboardCreate";
import LeaderboardShow from "./LeaderboardShow";
import LeaderboardEdit from "./LeaderboardEdit";
// import LeaderboardWinnerListing from "./LeaderboardWinnerListing";
import {list,create,deleteLeaderboard} from "../../store/ducks/leaderboard/actions";

const initialInputState =
{
    /* form input value */
    vendor: '',
    date: '',
    start_date: '',
    end_date: '',
    date_filter: '',
};

class Leaderboard extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: 100,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        gameVendors: [],
        searchValue: '',
        isOpen: false,

        data: [],

        filter: initialInputState,
        currentLeaderboardId: '',
        loading: true,
        permission: []
    }

    fetchData(){
        const data = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        data.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        data.limit = this.state.limit;
        data.country_id = this.props.currentCountryId;
        data.lang_id = this.props.currentLanguageId;

        this.props.list(data);
    }

    componentDidMount() {
       this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let tournamentPermission = this.props.currentPermission.filter((item) => item.includes("leaderboard_"));
            this.setState({permission: tournamentPermission});
        }

        if (nextProps.leaderboard_data !== this.props.leaderboard_data) {
            // console.log('shouldComponentUpdate:', nextProps.leaderboard_data);
            this.setState({
                loading: false,
                data: nextProps.leaderboard_data.data.result.row,
                totalRecords: nextProps.leaderboard_data.data.result.pagination.total_record,
                totalPages: nextProps.leaderboard_data.data.result.pagination.total_page,
                gameVendors: nextProps.leaderboard_data.data.game_vendors,
            });
        }

        if (nextProps.leaderboard_delete !== this.props.leaderboard_delete) {
            if (nextProps.leaderboard_delete.responseCode === 200) {
                Swal.fire({
                    title: t("global.record-deleted"),
                    icon: nextProps.leaderboard_delete.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.fetchData();
                    }
                });
            }
            else {
                Swal.fire({
                    title: nextProps.leaderboard_delete.message[0],
                    icon: nextProps.leaderboard_delete.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.update_leaderboard !== this.props.update_leaderboard) {
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_leaderboard.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                   this.fetchData();
                }
            });
        }

        return true;
    }

    updateStatusTournamentHandler = (event, tournament_id) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updateTournamentStatusData = {};
                updateTournamentStatusData.tournament_id = tournament_id;
                updateTournamentStatusData.status = Number(event.target.value);

                this.props.updateTournamentStatus(updateTournamentStatusData);
            }
        });
    }

    deleteLeaderboardHandler = (e, leaderboard_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                this.props.deleteLeaderboard({
                    leaderboard_id: leaderboard_id
                });
            }
        });
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton;

        if (this.state.permission.includes("leaderboard_view")) {
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.id}`}
                clicked={() => this.changePageHandler('show', row.id)}
            />;
        }

        if (this.state.permission.includes("leaderboard_edit")) {
        editButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white mr-2"
                buttonName={<FontAwesomeIcon icon={faPen}/>}
                key={`edit-${row.id}`}
                clicked={() => this.changePageHandler('edit', row.id)}
            />;
        }

        if (this.state.permission.includes("leaderboard_delete")) {
        deleteButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white delete"
                clicked={(event) => this.deleteLeaderboardHandler(event, row.id)}
                key={`delete-${row.id}`}
                buttonName={<FontAwesomeIcon icon={faTrash}/>}
            />;
        }

        return [viewButton, editButton, deleteButton];
    }

    winnerFormatter = (cell, row) => {
        let winnerButton,output;

        if (this.state.permission.includes("leaderboard_view_winner_list")) {
        winnerButton =
        <Button
            typeButton="button"
            classValue="btn btn-sm btn-primary mr-2"
            buttonName={<FontAwesomeIcon icon={faSearch}/>}
            key={`winner-${row.leaderboard_id}`}
            clicked={() => this.changePageHandler('winner', row.leaderboard_id)}
        />
        }
        output = [winnerButton];
        return output;
    }

    changePageHandler = (page, leaderboardId) => {
        this.setState({
            pageShow: page,
            currentLeaderboardId: leaderboardId
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.country_id = this.props.currentCountryId;
        listingArr.lang_id = this.props.currentLanguageId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });

        return listingArr;
    }

    refreshHandler = () => {
        const listingArr = this.getFilterArray();
        // console.log(listingArr);
        this.props.list(listingArr);
    }

    searchHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1,
        }, () => {
            this.refreshHandler();
        });
    }

    clearHandler = () => {
        let newFilter = initialInputState;

        this.setState({
            activePage: 1,
            totalRecords: 0,
            loading: true,
            filter: newFilter,
            data: [],
        },() => {
            this.refreshHandler();
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    onInputChange(event, picker) {
        // console.log(event.target.id+' | '+event.target.value+' | '+event.target.className);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        if (event.target.id === 'date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;

            newFilter['start_date'] = (date) ? date[0].replace(/\//g, "-").toString() : '';
            newFilter['end_date'] = (date) ? date[1].replace(/\//g, "-").toString() : '';
            newFilter['date_filter'] = 'daily';
        }

        this.setState({
            filter: newFilter,
        });
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Leaderboard Id",
                dataField: "id",
                keyField: 'leaderboardId',
                hidden: true
            },
            {
                text: "Game",
                dataField: "game_vendor",
                keyField: "gameVendor",
            },
            {
                text: "Min Win",
                dataField: "min_win",
                keyField: "minWin",
            },
            {
                text: "Start Date",
                dataField: "start_date",
                keyField: "startDate"
            },
            {
                text: "End Date",
                dataField: "end_date",
                keyField: "endDate"
            },
            // {
            //     text: t('tournament.view-winner-list'),
            //     dataField: "@",
            //     keyField: "winnerlist",
            //     formatter: this.winnerFormatter
            // },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <div>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('side-bar.leaderboard')}</h4>
                                </div>
                            </div>
                        </div>
                        <LeaderboardListing
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            searchValue={this.state.searchValue}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            searchHandler={this.searchHandler}
                            clearHandler={() => this.clearHandler()}
                            postSearchHandler={this.postSearchHandler}
                            refreshHandler={() => this.refreshHandler()}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            changePageHandler={this.changePageHandler}
                            permission={this.state.permission}
                            filter={this.state.filter}
                            onInputChange={(event, picker) => this.onInputChange(event, picker)}
                            gameVendors={this.state.gameVendors}
                        />
                    </div>
                }

                {this.state.pageShow === 'create' &&
                <LeaderboardCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                    gameVendors={this.state.gameVendors}
                />
                }

                {this.state.pageShow === 'show' &&
                <LeaderboardShow
                    show_leaderboard_id={this.state.currentLeaderboardId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <LeaderboardEdit
                    show_leaderboard_id={this.state.currentLeaderboardId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                    gameVendors={this.state.gameVendors}
                />
                }

                {/* {this.state.pageShow === 'winner' &&
                <WinnerListing
                    show_tournament_id={this.state.currentLeaderboardId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                } */}
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {leaderboard,currentUser} = state;

    return {
        leaderboard_data: leaderboard.leaderboard_data,
        leaderboard_delete: leaderboard.delete,
        currentPermission: currentUser.data.permission,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
    }
};

const mapDispatchToProps = {
    list,
    create,
    deleteLeaderboard
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Leaderboard));
