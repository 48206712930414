import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getEventLeagueList } from "../../store/ducks/eventLeague/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";

import EventLeagueCreate from "./EventLeagueCreate";
import EventLeagueEdit from "./EventLeagueEdit";
import EventLeagueListing from "./EventLeagueListing";

const initialFilteringState = {
    search: ""
};

class EventLeague extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pageShow: "listing",
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            totalPages: 0,
            searchValue: '',
            data: [],
            filterInput: initialFilteringState,
            permission: [],
            editId: null
        }
    }

    componentDidMount(){
        const data = this.fetchData();
        this.props.getEventLeagueList(data);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.permission.length===0 && this.props.currentPermission!==undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if(nextProps.eventLeague !== undefined && nextProps.eventLeague !== this.props.eventLeague) {
            if(nextProps.eventLeague.data.responseCode === 200 && nextProps.eventLeague.data.msgType === "success"){
                this.setState({
                    data: nextProps.eventLeague.data.data.result.row,
                    totalRecords: nextProps.eventLeague.data.data.result.pagination.total_record,
                    totalPages: nextProps.eventLeague.data.data.result.pagination.total_page,
                    loading: false
                })
            }
        }

        return true;
    }

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filterInput).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filterInput[val]!=='') ? moment(this.state.filterInput[val]).format("YYYY-MM-DD") : this.state.filterInput[val];
        });
        return listingArr;
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1,
            limit: 10
        }, () => {
            const data = this.fetchData();
            this.props.getEventLeagueList(data);
        });
    }

    clearHandler = () => {
        this.setState({
            loading: true,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            totalPages: 0,
            searchValue: '',
            data: [],
            filterInput: initialFilteringState
        }, ()=> {
            const data = this.fetchData();
            this.props.getEventLeagueList(data);
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    }

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            editId: id,
            pageShow: "edit",
        });
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filterInput);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filterInput: newFilter
        });
    }

    // datatable function
    datatablesChangeHandler = (val, act) => {
        this.setState({
            [act]: act === "searchValue" ? val.target.value : val,
            loading: true,
            data: []
        }, () => {
            const data = this.fetchData();
            this.props.getEventLeagueList(data);
        });
    }
    
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    }

    actionFormatter = (cell, row) => {
        let editButton, output;

        if (this.state.permission.includes("league_event_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.Id}`}
                    idValue={row.Id === undefined ? 0 : row.Id}
                    clicked={(event) => this.editHandler(event, row.Id)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            activePage: 1,
            limit: 10
        }, () => {
            const data = this.fetchData();
            this.props.getEventLeagueList(data);
        });
    }

    render(){
        const { t } = this.props;
        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("event-league.title"), dataField: "title", sort: true, onSort: this.onSort },
            { text: t("event-league.slug"), dataField: "slug", sort: true, onSort: this.onSort },
            { text: t("event-league.total-teams"), dataField: "total_teams" },
            { text: t("event-league.cdate"), dataField: "cdate", sort: true, onSort: this.onSort },
            { text: t("event-league.udate"), dataField: "udate", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "65px" } }
        ];

        let dataListing = this.state.data.map((val) => {
            return {
                Id: val.Id,
                title: val.title,
                slug: val.slug,
                total_teams: val.total_teams,
                cdate: val.cdate,
                udate: val.udate
            }
        });

        return (
            <Auxiliary>
                {this.state.pageShow==="listing" && (
                    <EventLeagueListing
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onInputChange={(e) => this.onInputChange(e)}
                        refreshHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}

                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        filterInput={this.state.filterInput}
                    />
                )}

                {this.state.permission.includes("league_event_create") && this.state.pageShow==="create" && (
                    <EventLeagueCreate
                        permission={this.state.permission}

                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                    />
                )}

                {this.state.permission.includes("league_event_update") && this.state.pageShow==="edit" && (
                    <EventLeagueEdit
                        permission={this.state.permission}
                        editId={this.state.editId}

                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                    />
                )}
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPermission: state.currentUser.data.permission,
        eventLeague: state.eventLeague.event_league_listing
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventLeagueList: (data) => dispatch(getEventLeagueList(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EventLeague));