import React, { Component } from "react";
import { connect } from "react-redux";
import { getAgentTransactionHistory, getFilterData, setAgentShowLocalState, setAgentShowInitialLoading } from "../../../store/ducks/agent/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import AgentTransactionHistoryListing from "./AgentTransactionHistoryListing";
import TransactionDetails from "../../Transaction/TransactionDetails";

const initialInputState = {
    transaction_date: "",
    status: "",
    transaction_type: "",
};

class AgentTransactionHistory extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.agentShowInitialLoading.filter(item => item.id === "transaction_history");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                currentPage: "transaction_history",
                pageShow: "agent_transaction_listing",
                loading: true,
                reload: false,
                show_id: null,
                activePage: 1,
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                totalRecords: 0,
                searchValue: "",
                filter: initialInputState,
                data: [],
                dropDown: {
                    status: [],
                    transactionType: [],
                },
                detailsParam: {},
            };
            this.props.setAgentShowLocalState(this.state);
        } else {
            this.state = this.props.agentTransactionHistoryLocalState;
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const initLoad = this.props.agentShowInitialLoading.filter(item => item.id === "transaction_history");
        const initialLoading = initLoad[0].loading;

        if(initialLoading) {
            const listingArr = this.getFilterArray();
            this.props.getAgentTransactionHistory(listingArr);
            this.props.getFilterData();

            this.props.setAgentShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.agentTransactionHistoryData !== undefined) {
                if (Number(this.props.agentShowId) !== Number(this.props.agentTransactionHistoryData.data.agentId)) {
                    const listingArr = this.getFilterArray();
                    this.props.getAgentTransactionHistory(listingArr);
                    this.props.getFilterData();
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                const listingArr = this.getFilterArray();
                this.props.getAgentTransactionHistory(listingArr);
                this.props.getFilterData();
            }
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.agentShowId !== undefined && nextProps.agentTransactionHistoryData !== undefined) {
            if(nextProps.agentTransactionHistoryData !== this.props.agentTransactionHistoryData) {
                if (nextProps.agentTransactionHistoryData.responseCode === 200 && nextProps.agentTransactionHistoryData.msgType === "success") {
                    Swal.close();
                    this.setState({
                        show_id: nextProps.agentShowId,
                        data: nextProps.agentTransactionHistoryData.data.result.row,
                        totalRecords: nextProps.agentTransactionHistoryData.data.result.pagination.total_record,
                        totalPages: nextProps.agentTransactionHistoryData.data.result.pagination.total_page,
                        loading: false,
                    });
                }
            }
        }

        if (nextProps.agentTransactionHistoryFilterData !== undefined && nextState.dropDown.status.length === 0 && nextProps.agentTransactionHistoryFilterData !== undefined) {
            this.setState({
                dropDown: {
                    status: nextProps.agentTransactionHistoryFilterData.data.status,
                    transactionType: nextProps.agentTransactionHistoryFilterData.data.transaction_type,
                },
            });
            Swal.close();
        }
        return true;
    }

    componentWillUnmount() {
        this.props.setAgentShowLocalState(this.state);
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: "agent_transaction_details",
            detailsParam: {
                id: id,
            },
        });
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentTransactionHistory(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                // limit: 10,
                searchValue: "",
                // order: [{ id: "id", desc: "asc" }],
                filter: newFilter,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentTransactionHistory(listingArr);
            }
        );
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        listingArr.agent_id = this.props.agentShowId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getAgentTransactionHistory(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getAgentTransactionHistory(listingArr);
        });
    };

    render() {
        // const { t } = this.props;
        return (
            <Auxiliary>
                {this.state.pageShow === "agent_transaction_listing" && (
                    <AgentTransactionHistoryListing
                        filter={this.state.filter}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        ddStatus={this.state.dropDown.status}
                        ddTransactionType={this.state.dropDown.transactionType}
                        loading={this.state.loading}
                    />
                )}

                {this.state.pageShow === "agent_transaction_details" && (
                    <TransactionDetails detailsParam={this.state.detailsParam} changePageHandler={(page) => this.changePageHandler(page)} returnPage="agent_transaction_listing" />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { agent } = state;

    return {
        agentShowInitialLoading: agent.agent_show_initial_loading,
        agentTransactionHistoryLocalState: agent.agent_transaction_history_local_state,
        agentTransactionHistoryData: agent.agent_transaction_history,
        agentTransactionHistoryFilterData: agent.agent_filter_data,
        currentCountryId: state.currentUser.data.country_id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAgentShowLocalState: (data) => dispatch(setAgentShowLocalState(data)),
        setAgentShowInitialLoading: (data) => dispatch(setAgentShowInitialLoading(data)),
        getAgentTransactionHistory: (data) => dispatch(getAgentTransactionHistory(data)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentTransactionHistory));
