import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const agentPayoutReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.HISTORY]: (state, action) => ({
        ...state,
        history: action.value,
    }),
    [type.BET_DETAILS]: (state, action) => ({
        ...state,
        betDetails: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.HISTORY_DETAILS]: (state, action) => ({
        ...state,
        payoutHistoryDetails: action.value,
    }),
});

export default agentPayoutReducer;