import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import axios from "../../../common/config/axios";
import * as moment from "moment";

import { getVendorStatusReport, getVendorSubStatusReport } from "../../../store/ducks/report/actions";
import { getFilterData } from "../../../store/ducks/gameList/actions";

import VendorStatusReportListing from "./VendorStatusReportListing";


const initialInputState =
{
    /* form input value */
    vendor: '',
    date: '',
    start_date: '',
    end_date: '',
    start_month: '',
    end_month: '',
    start_year: '',
    end_year: '',
    game_type: '',
    game_name: '',
    date_filter: '',
};

class VendorStatusReport extends Component {

    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        order: '',

        data: [],
        subData: [],
        sum_total_bet: 0,
        sum_total_payout: 0,
        sum_total_turnover: 0,
        sum_total_win_lose: 0,

        loading: true,
        hideColumn: false,
        dateFilter : '',

        permission: [],
        filter: initialInputState,
        dropDown: {
            gameTypeOption: [],
            vendorOption: [],
        },
        asyncSelectedVal: null,
        disabled: true
    }

    componentDidMount() {
        this.props.getFilterData();
        this.refreshHandler();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_"));
            this.setState({permission: this.props.currentPermission});
        }

        if (nextProps.reportData !== this.props.reportData) {
            if (nextProps.reportData.data && nextProps.reportData.data.responseCode === 200) {
                let multipleTotals = nextProps.reportData.data.data.multiple_totals;
                this.setState({
                    data: nextProps.reportData.data.data.result.row,
                    totalRecords: nextProps.reportData.data.data.result.pagination.total_record,
                    totalPages: nextProps.reportData.data.data.result.pagination.total_page,
                    loading: false,
                    hideColumn: (nextState.filter.date_filter === 'monthly' || nextState.filter.date_filter === 'yearly') ? true : false,
                    dateFilter: nextState.filter.date_filter,
                    sum_total_bet: multipleTotals[0] !== undefined ? multipleTotals[0] : 0,
                    sum_total_payout: multipleTotals[1] !== undefined ? multipleTotals[1] : 0,
                    sum_total_turnover: multipleTotals[2] !== undefined ? multipleTotals[2] : 0,
                    sum_total_win_lose: multipleTotals[3] !== undefined ? multipleTotals[3] : 0
                });
            } else {
                this.setState({ loading: false });
                // Swal.fire({
                //     title: nextProps.reportData.data.message[0],
                //     icon: nextProps.reportData.data.msgType,
                //     confirmButtonText: `OK`,
                // });
            }
        }

        if (nextProps.reportSubData !== this.props.reportSubData) {
            if (nextProps.reportSubData.data && nextProps.reportSubData.data.responseCode === 200) {                
                let subData = (nextState.subData || []);
                
                Object.values(nextProps.reportSubData.data.data.sub_result).map((val) => subData[`${val.date}_${val.vendor_slug}`] = [] );
                
                if(nextProps.reportSubData.data.data.sub_result.length > 0) {
                    nextProps.reportSubData.data.data.sub_result.forEach((val) => {
                        subData[`${val.date}_${val.vendor_slug}`].push(val);
                    });
                }
                
                // console.log(subData);

                this.setState({
                    subData: subData,
                });
            }
        }


        if (nextProps.gameListFilterData !== this.props.gameListFilterData && nextProps.gameListFilterData !== undefined) {
            if (nextProps.gameListFilterData.data && nextProps.gameListFilterData.data.responseCode === 200 && nextProps.gameListFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        gameTypeOption: nextProps.gameListFilterData.data.data.gameType,
                        vendorOption: nextProps.gameListFilterData.data.data.vendor,
                    },
                    subLoading: false,
                });
            }
            // Swal.close();
        }

        return true;
    }

    onInputChange(event, picker) {
        // console.log(event.target.id+' | '+event.target.value+' | '+event.target.className);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        let disabledSyncFilterBtn = true;
        if(event.target.id==='date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0].replace(/\//g, "-").toString() : '';
            newFilter['end_date'] = (date) ? date[1].replace(/\//g, "-").toString() : '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
            newFilter['date_filter'] = 'daily';
        }

        if(event.target.className.includes("monthly")) {
            newFilter['start_month'] = moment(picker.startDate).format("YYYY-MM");
            newFilter['end_month'] = moment(picker.endDate).format("YYYY-MM");
            newFilter['date'] = '';
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_year'] = '';
            newFilter['end_year'] = '';
            newFilter['date_filter'] = 'monthly';
        }

        if(event.target.className.includes("yearly")) {
            newFilter['start_year'] = moment(picker.startDate).format("YYYY");
            newFilter['end_year'] = moment(picker.endDate).format("YYYY");
            newFilter['date'] = '';
            newFilter['start_date'] = '';
            newFilter['end_date'] = '';
            newFilter['start_month'] = '';
            newFilter['end_month'] = '';
            newFilter['date_filter'] = 'yearly';
        }

        if(newFilter['date_filter'] === 'daily' && newFilter['start_date'] === newFilter['end_date']){
            disabledSyncFilterBtn = false;
        }
        
        this.setState({
            filter: newFilter,
            disabled: disabledSyncFilterBtn
        });
    }

    onInputChangeAsync = (event, id) => 
    { 
        // console.log(event);
        let newVal = (event) ? event.id : ''; 
        let newFilter = Object.assign({}, this.state.filter); 
        
        newFilter[id] = newVal; 
 
        this.setState({ 
            filter: newFilter, 
            asyncSelectedVal: event,
        }); 
    } 

    getFilterArray = (sync = 0) => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        listingArr.synchronize = sync;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });

        return listingArr;
    }

    refreshHandler = () => {
        const listingArr = this.getFilterArray();
        // console.log(listingArr);
        this.props.getVendorStatusReport(listingArr);
    }

    searchHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1,
            disabled: true
        }, () => {
            this.refreshHandler();
        });
    }

    syncHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: 1,
            disabled: true
        }, () => {
            const listingArr = this.getFilterArray(1);
            this.props.getVendorStatusReport(listingArr);
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.refreshHandler();
        });
    }

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            totalRecords: 0,
            loading: true,
            filter: newFilter,
            data: [],
            disabled: true
        },() => {
            this.refreshHandler();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({
            limit: limit, 
            loading: true, 
            data: []}, 
        () => {
            this.refreshHandler();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.refreshHandler();
            });
        }
    }

    exportExcelHandler = () => {
        Swal.fire({
            // allowOutsideClick: false,
            // allowEscapeKey: false,
            // allowEnterKey: false,
            didOpen: () => 
            {
                Swal.showLoading();
            }
        });

        const data = this.getFilterArray();
        // console.log(data);

        axios.get("report/export_vendor_status_report", {
            params: data,
            responseType: "blob",
        }).then((res) => {
            const currentDate = new Date();
            const timestamp = moment(currentDate).format("YYMMDD HHmmss");
            let fileName = "100Express Report - Vendor Total Win Lose " + timestamp;
            const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const aElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            aElement.href = href;
            aElement.download = fileName;
            document.body.appendChild(aElement);
            aElement.click();
            document.body.removeChild(aElement);
            window.URL.revokeObjectURL(href);
            Swal.close();
        })
        .catch((err) => {
            console.log("Excel - MemberDepositStatus Error", err);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({order: orderArry, loading: true, data: []}, () => {
            this.refreshHandler();
        });
    }

    onExpandHandler = (vid, date, expandAll) => {
        const listingArr = {};

        listingArr.vendor = vid;
        listingArr.date = date;
        listingArr.game_type = this.state.filter.game_type;
        listingArr.game_name = this.state.filter.game_name;
        listingArr.date_filter = this.state.dateFilter;
        listingArr.expand_all = (expandAll) ? '1' : '0';
        listingArr.order = JSON.stringify(this.state.order);

        if(expandAll) {
            Object.keys(this.state.filter).forEach((val) => {
                listingArr[val] = this.state.filter[val];
            });
        }
        
        // console.log(listingArr);
        this.props.getVendorSubStatusReport(listingArr);
    }
    
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
        (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    }

    footerFormatter (column, colIndex, {text}) {
        return (
            <div>
                <p className='sub-total'>{column["footer"][0]}</p>
                <p>( {column["footer"][1]} )</p>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        let ddGameType = [];
        let ddVendor = [];
        const num_format = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };

        let page_total_bet = 0;
        let page_total_payout = 0;
        let page_total_turnover = 0;
        let page_total_win_lose = 0;

        this.state.data.forEach((val,idx) => {
            page_total_bet += parseFloat(val.total_bet!==null ? val.total_bet : 0);
            page_total_payout += parseFloat(val.total_payout!==null ? val.total_payout : 0);
            page_total_turnover += parseFloat(val.total_turnover!==null ? val.total_turnover: 0);
            page_total_win_lose += parseFloat(val.total_win_lose!==null ? val.total_win_lose : 0);
        });

        const columns = [
            {
                text: t('report.date'),
                dataField: "date",
                // keyField: "no",
                // formatter: (cell, row, rowIndex) => {
                //     let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10));
                //     return <span>{rowNumber}</span>;
                // },
                headerStyle: () => {
                    return {width: '90.5px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [t("global.sub-total"),t("global.total")], 
                footerFormatter: this.footerFormatter, 
                footerClasses: 'table-footer'
            },
            {
                text: t("report.vendor"),
                dataField: "vendor_slug",
                formatter: (cell, row, rowIndex) => 
                {
                    return <span>{t(`vendor.${row.vendor_slug}`)}</span>;
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                // sort: true,
                // onSort: this.onSort,
                footer: ""
            },
            {
                text: t("report.category"),
                dataField: "category",
                formatter: (cell, row, rowIndex) => 
                {
                    let categoryList = '';
                    let category = (row.category && row.category.split(','));
                    
                    if(category)
                    {
                        category.forEach((val) => {
                            let tmpSplit = val.split('_');
                            categoryList += `${t(`game-type.${tmpSplit[1]}`)}, `;
                        });
                    }
                    return <span>{categoryList.slice(0, -2)}</span>;
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                footer: ""
            },
            {
                text: t("report.game-name"),
                dataField: "",
                formatter: (cell, row, rowIndex) => 
                {
                    return '-';
                },
                hidden: this.state.hideColumn,
                headerAttrs: {
                    hidden: this.state.hideColumn,
                },
                attrs: {
                    hidden: this.state.hideColumn,
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                footer: "",
                footerAttrs: {
                    hidden: this.state.hideColumn
                }
            },
            {
                text: t("report.total-bet-amount"),
                dataField: "total_bet",
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_bet);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_bet).toString(), 
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_total_bet).toString()
                ],
                footerFormatter: this.footerFormatter, 
                footerClasses: 'table-footer'
            },
            {
                text: t("report.total-payout-amount"),
                dataField: "total_payout",
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_payout);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_payout).toString(), 
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_total_payout).toString()
                ],
                footerFormatter: this.footerFormatter, 
                footerClasses: 'table-footer'
            },
            {
                text: t("report.total-win-lose"),
                dataField: "total_win_lose",
                formatter: (cell, row, rowIndex) => 
                {
                    return <span className={(row.total_win_lose < 0 && "text-danger")}>{new Intl.NumberFormat("en-US", num_format).format(row.total_win_lose)}</span>;
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_win_lose).toString(), 
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_total_win_lose).toString()
                ],
                footerFormatter: this.footerFormatter, 
                footerClasses: 'table-footer'
            },
            {
                text: t("report.total-turnover"),
                dataField: "total_turnover",
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_turnover);
                },
                headerStyle: () => {
                    return {width: '150px'};
                },
                sort: true,
                onSort: this.onSort,
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(page_total_turnover).toString(), 
                    new Intl.NumberFormat("en-US", num_format).format(this.state.sum_total_turnover).toString()
                ],
                footerFormatter: this.footerFormatter, 
                footerClasses: 'table-footer'
            },
        ];

        const subColumns = 
        [
            {
                dataField: '',
                attrs:{
                    colSpan: 2,
                },
            },
            {
                dataField: 'game_slug',
                headerAttrs: {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    let tmpSplit = (row.game_slug && row.game_slug.split('_'));
                    return <span>{t(`game-type.${(tmpSplit && tmpSplit[1]) || ''}`)}</span>;
                },
            },
            {
                dataField: 'table_id',
                headerAttrs: {
                    hidden: true
                },
                hidden: this.state.hideColumn,
                formatter: (cell, row, rowIndex) => 
                {
                    let gameName = (row.table_id === null) ? '-' : (row.game_title === null) ? t(`games:${row.vendor_slug}.${row.table_id}`) : row.game_title;
                    return <span>{gameName}</span>;
                },
            },
            {
                dataField: 'total_bet',
                headerAttrs: {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_bet);
                },
            },
            {
                dataField: 'total_payout',
                headerAttrs: {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_payout);
                },
            },
            {
                dataField: 'total_win_lose',
                headerAttrs: {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return <span className={(row.total_win_lose < 0 && "text-danger")}>{new Intl.NumberFormat("en-US", num_format).format(row.total_win_lose)}</span>;
                },
            },
            {
                dataField: 'total_turnover',
                headerAttrs: {
                    hidden: true
                },
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.total_turnover);
                },
            },
        ];
        
        if (this.state.pageShow === "listing" && this.state.dropDown.gameTypeOption) {
            ddGameType = this.state.dropDown.gameTypeOption.map((val, idx) => {
                return (
                    <option key={val.gameTypeId} value={val.gameTypeId}>
                        {t(`game-type.${val.gameTypeSlug}`)}
                    </option>
                );
            });
        }

        if (this.state.pageShow === "listing" && this.state.dropDown.vendorOption) {
            ddVendor = this.state.dropDown.vendorOption.map((val, idx) => {
                return (
                    <option key={val.vendorId} value={val.vendorId}>
                        {t(`vendor.${val.vendorSlug}`)}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <VendorStatusReportListing
                        
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        data={this.state.data}
                        ddGameType={ddGameType}
                        ddVendor={ddVendor}

                        searchHandler={this.searchHandler}
                        syncHandler={this.syncHandler}
                        pageChangeHandler={this.pageChangeHandler}
                        clearHandler={() => this.clearHandler()}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        exportExcelHandler={(() => this.exportExcelHandler())}
                        onExpandHandler={((vid, date, expandAll) => this.onExpandHandler(vid, date, expandAll))}
                        onInputChangeAsync={(event, id) => this.onInputChangeAsync(event, id)} 
                        
                        loading={this.state.loading}
                        permission={this.state.permission}
                        filter={this.state.filter}
                        onInputChange={(event, picker) => this.onInputChange(event, picker)}

                        subColumns={subColumns}
                        subData={this.state.subData}
                        {...this.state}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_vendor_status_report_data,
        reportSubData: state.report.get_vendor_sub_status_report_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,

        gameListFilterData: state.gameList.game_list_filter_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVendorStatusReport: (filter) => dispatch(getVendorStatusReport(filter)),
        getVendorSubStatusReport: (filter) => dispatch(getVendorSubStatusReport(filter)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(VendorStatusReport));