import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getMemberLogs,
    setLoading,
    setDataTableFilter,
} from "../../../store/ducks/log/actions";
import { withNamespaces } from "react-i18next";
import StringInput from "../../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Skeleton from "react-loading-skeleton";
import Button from "../../../components/Button/Button";

const initialInputState = {
    username: "",
    updated_date: "",
};

class MemberLog extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalRecords: 0,
            filter: initialInputState,
            dropDown: {
                transactionType: [],
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const defaultState = this.getDefaultFilter();
        this.props.setDataTableFilter(defaultState);
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("[MemberLog] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    handleChange = (event) => {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "updated_date") {
            const date =
                event.target.value !== ""
                    ? event.target.value.split(" - ")
                    : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    };

    /* ======================== Datatables Function [START] ======================== */
    // Get Initial State For Redux.
    getDefaultFilter = () => {
        const listingArr = {};
        listingArr.page = this.state.page;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.filter = this.state.filter;
        return listingArr;
    };

    setDataTableFilterHandler = () => {
        const listingArr = {};
        listingArr.page = 1;
        listingArr.limit = this.props.filterList.limit;
        listingArr.order = this.props.filterList.order;
        listingArr.filter = this.state.filter;
        this.props.setDataTableFilter(listingArr);
    };

    getReduxFilter = () => {
        const listingArr = {};
        listingArr.page = 1;
        listingArr.limit = this.props.filterList.limit;
        listingArr.order = this.props.filterList.order;
        listingArr.filter = this.props.filterList.filter;
        Object.keys(this.props.filterList.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });

        return listingArr;
    };

    fetchData = () => {
        const reduxFilterList = this.getReduxFilter();
        this.props.getMemberLogs(reduxFilterList);
        this.setLoadingHandler(false, true);
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                this.setDataTableFilterHandler();
                this.fetchData();
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                filter: newFilter,
            },
            () => {
                this.setLoadingHandler(true, true);
            }
        );
    };

    setLoadingHandler = (pageLoading, dataLoading) => {
        const loadingData = {};
        loadingData.pageLoading = pageLoading;
        loadingData.dataLoading = dataLoading;
        this.props.setLoading(loadingData);
    };
    /* ======================== Function [END] ======================== */

    render() {
        const { t } = this.props;

        return (
            <>
                <div className="col-md-3 col-6">
                    <div className="form-group">
                        <label className="font-weight-bolder">
                            {t("member.username")} *
                        </label>
                        <StringInput
                            id="username"
                            name="username"
                            value={this.state.filter.username}
                            onChange={this.handleChange}
                            className="filter-input"
                            onSearchPress={(evt) =>
                                evt.key === "Enter" && this.refreshHandler()
                            }
                        />
                    </div>
                </div>

                <div className="col-md-3 col-6">
                    <div className="form-group">
                        <label
                            htmlFor="updated_date"
                            className="font-weight-bolder"
                        >
                            {t("log.updated-date")}
                        </label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fas fa-calendar-check"></i>
                                </span>
                            </div>
                            <DateTimePicker
                                id="updated_date"
                                name="updated_date"
                                className="form-control filter-input"
                                range={true}
                                timePicker={true}
                                value={this.state.filter.updated_date}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="col mt-auto">
                    {!this.props.loading.pageLoading &&
                    this.props.loading.dataLoading ? (
                        <div className="col-md-2 float-right">
                            <Skeleton count={2} />
                        </div>
                    ) : (
                        <div className="d-flex">
                            <Button
                                typeButton="button"
                                classValue="btn btn-blue mr-2"
                                idValue="filter"
                                buttonName={t("global.filter")}
                                clicked={() => this.refreshHandler()}
                            />
                            <Button
                                typeButton="button"
                                classValue="btn btn-secondary"
                                idValue="reset"
                                buttonName={t("global.reset")}
                                clicked={() => this.clearHandler()}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { log } = state;
    return {
        filterList: log.filterList,
        loading: log.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberLogs: (data) => dispatch(getMemberLogs(data)),
        setLoading: (data) => dispatch(setLoading(data)),
        setDataTableFilter: (data) => dispatch(setDataTableFilter(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces("translation")(MemberLog));
