import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import '../../assets/css/simple-line-icons.scss';
import '../../assets/css/treeview.css';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
// import Textarea from '../../components/Input/Textarea/Textarea';
import StringInput from '../../components/Input/StringInput/StringInput';
// import NumberInput from '../../components/Input/NumberInput/NumberInput';

import { formValidation, clearFormValidation, filterErrorHandler } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/role/actions';

const initialFormInputState =
{
    title: '',
    // description: '',
    // color: '#00000',
    // priority: '',
    status: 1,
};

class RoleStore extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: initialFormInputState,
            module: [],
            loading: true,
            errMsg: [],
            touched: false,
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.checkAllHandler = this.checkAllHandler.bind(this);
    }

    componentDidMount() {
        formValidation('role_form');
        this.props.funcGetDetails('');
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newFilter = Object.assign({}, this.state.formFilter);
                nextProps.details.data.module.mainModule.forEach((main) => {
                    if (nextProps.details.data.module.subModule[main.id]) {
                        nextProps.details.data.module.subModule[main.id].forEach((sub) => {
                            if (nextProps.details.data.module.sonModule[sub.id]) {
                                nextProps.details.data.module.sonModule[sub.id].forEach((son) => {
                                    newFilter[`cb_${main.id}_${sub.id}_${son.id}`] = (son.role_id) ? true : false;
                                });
                            }
                        });
                    }
                });

                this.setState({
                    formFilter: newFilter,
                    module: nextProps.details.data.module,
                    loading: false
                });
            }
            else {
                this.setState({
                    formFilter: initialFormInputState,
                    module: []
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('role_form');
                    this.setState({
                        formFilter: initialFormInputState,
                        touched: false,
                        errMsg: [],
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.store.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput,
        });
    }

    onCheckBoxChange = (event, type, id) => {
        // console.log(this.state.formFilter[`main_${id}`]);
        let checkVal = '';
        let newModule = Object.assign({}, this.state.formFilter);

        for (const key in newModule) {
            if (key.includes('cb_')) {
                let tmp = key.split('_');

                if (type === 'main' && (tmp[1] === id.toString())) {
                    checkVal = (newModule[`cb_${id}_${tmp[2]}_${tmp[3]}`]) ? false : true;
                    if (checkVal) { break; }
                }

                if (type === 'sub' && (tmp[2] === id.toString())) {
                    checkVal = (newModule[`cb_${tmp[1]}_${id}_${tmp[3]}`]) ? false : true;
                    if (checkVal) { break; }
                }

                if (type === 'son' && (tmp[3] === id.toString())) {
                    newModule[`cb_${tmp[1]}_${tmp[2]}_${id}`] = event.target.checked;
                }
            }
        }

        if (type === 'main' || type === 'sub') {
            for (const key in newModule) {
                if (key.includes('cb_')) {
                    let tmp = key.split('_');

                    if (tmp[1] === id.toString()) {
                        newModule[`cb_${id}_${tmp[2]}_${tmp[3]}`] = checkVal;
                    }

                    if (tmp[2] === id.toString()) {
                        newModule[`cb_${tmp[1]}_${id}_${tmp[3]}`] = checkVal;
                    }
                }
            }
        }

        this.setState({
            formFilter: newModule,
        });
    }

    checkAllHandler = (event) => {
        let checkVal = (event.target.checked) ? true : false;
        let newFilter = Object.assign({}, this.state.formFilter);
        // console.log(newFilter);
        for (const key in newFilter) {
            if (key.includes('cb_')) {
                newFilter[key] = checkVal;
            }
        }
        this.setState({
            formFilter: newFilter,
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('global.confirmation'),
            text: t('global.are-you-sure'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};
                let newModule = Object.assign({}, this.state.formFilter);
                let permissionArr = [];

                data.title = this.state.formFilter.title;
                // data.color = this.state.formFilter.color;
                // data.description = this.state.formFilter.description;
                // data.priority = this.state.formFilter.priority;
                data.status = this.state.formFilter.status;
                data.country_id = this.props.currentUser.country_id;

                for (const key in newModule) {
                    if (key.includes('cb_')) {
                        if (newModule[key] === true) {
                            let tmp = key.split('_');
                            tmp.forEach((val) => {
                                if (!permissionArr.includes(val) && val !== 'cb') {
                                    permissionArr.push(val);
                                }
                            });
                        }
                    }
                }
                data.permission = permissionArr;
                // console.log(data);
                this.props.funcStore(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        let inputArr = (this.state.formFilter) ? this.state.formFilter : [];
        let mainModule = (this.state.module.mainModule) ? this.state.module.mainModule : [];
        let subModule = (this.state.module.subModule) ? this.state.module.subModule : [];
        let sonModule = (this.state.module.sonModule) ? this.state.module.sonModule : [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('role.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('role.add_role')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='role_form' className='form-horizontal row' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='role_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            {/* <div className='row'> */}
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label htmlFor='title'>{t('role._title')}</label>
                                                    <StringInput
                                                        id='title'
                                                        name='title'
                                                        placeholder={t('role._title')}
                                                        value={inputArr.title}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label htmlFor='title'>{t('role.color')}</label>
                                                    <input
                                                        className='form-control'
                                                        type='color'
                                                        name='color'
                                                        id='color'
                                                        value={inputArr.color}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        {/* </div> */}
                                            {/* <div className='row'> */}
                                            {/* <div className='col-md-12'>
                                                <div className='form-group'>
                                                    <label htmlFor='description'>{t('role.description')}</label>
                                                    <Textarea
                                                        id='description'
                                                        name='description'
                                                        className='form-control'
                                                        maxLength={255}
                                                        rows={5}
                                                        placeholder={t('role.description')}
                                                        value={inputArr.description}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                                            {/* </div> */}
                                            {/* <div className='row'> */}
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label htmlFor='status'>{t('role.status')}</label>
                                                    <div className='select-input'>
                                                        <select className='form-control datatable-select' id='status' name='status' value={inputArr.status} onChange={(event) => this.onInputChange(event)}>
                                                            <option value='0' >{t('global.inactive')}</option>
                                                            <option value='1' >{t('global.active')}</option>
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label htmlFor='priority'>{t('role.priority')}</label>
                                                    <NumberInput
                                                        id='priority'
                                                        name='priority'
                                                        min={1}
                                                        max={100}
                                                        placeholder={t('role.priority')}
                                                        value={inputArr.priority || ''}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        {/* </div> */}
                                            {/* <div className='row'> */}
                                            <div className='col-md-12'>
                                                <div className="yl-checkbox-container">
                                                    <input type='checkbox' id='check_all' name='check_all' onClick={(event) => this.checkAllHandler(event)} />
                                                    <label className="margin-r" for='check_all'>{t('role.permission')}&nbsp;</label>
                                                </div>
                                                <div className='col-md-12 form-group border rounded'>
                                                    <ul id='tree1' className='tree'>
                                                        {
                                                            mainModule.map((main, idx) => {
                                                                let subMenuContentUl = [];
                                                                let subMenuContentLi = [];

                                                                if (subModule[main.id]) {
                                                                    subModule[main.id].forEach((sub, idx1) => {
                                                                        let sonMenuContentLi = [];
                                                                        let sonMenuContentUl = [];
                                                                        if (sonModule[sub.id]) {
                                                                            let count = 0;
                                                                            let br = '';
                                                                            sonModule[sub.id].forEach((son, idx2) => {
                                                                                br = (count >= 5) ? <br /> : '';
                                                                                sonMenuContentLi.push(
                                                                                    <span key={idx2}>
                                                                                        <div className="yl-checkbox-container">
                                                                                            <input
                                                                                                id={`cb_${main.id}_${sub.id}_${son.id}`}
                                                                                                name={`cb_${main.id}_${sub.id}_${son.id}`}
                                                                                                type='checkbox'
                                                                                                className='child'
                                                                                                checked={(inputArr[`cb_${main.id}_${sub.id}_${son.id}`]) ? inputArr[`cb_${main.id}_${sub.id}_${son.id}`] : false}
                                                                                                onChange={(event) => this.onCheckBoxChange(event, 'son', son.id)}
                                                                                            />
                                                                                            <label className="margin-r" for={`cb_${main.id}_${sub.id}_${son.id}`}>{t(`role.${son.title}`)}</label>
                                                                                        </div>
                                                                                    </span>
                                                                                );
                                                                                count = (count >= 5) ? 0 : count + 1;
                                                                            });
                                                                            sonMenuContentUl.push(<ul key={idx1}><li key={idx1}>{sonMenuContentLi}</li></ul>)
                                                                        }
                                                                        subMenuContentLi.push(
                                                                            <li key={idx1}>
                                                                                <span onClick={(event) => this.onCheckBoxChange(event, 'sub', sub.id)} style={{ cursor: 'pointer' }}>
                                                                                    <i className='fas fa-angle-right'></i> {t(`side-bar.${sub.title}`)}&nbsp;
                                                                                </span>
                                                                                {sonMenuContentUl}
                                                                            </li>);
                                                                    });
                                                                    subMenuContentUl.push(<ul key={idx}>{subMenuContentLi}</ul>);
                                                                }
                                                                return (
                                                                    <li key={idx}>
                                                                        <span onClick={(event) => this.onCheckBoxChange(event, 'main', main.id)} style={{ cursor: 'pointer' }}>
                                                                            <i className='fas fa-angle-right'></i> {t(`side-bar.${main.title}`)}
                                                                        </span>
                                                                        {subMenuContentUl}
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            <div className='col-12 form-group mb-0 justify-content-end'>
                                                <div>
                                                    {(permission.includes('role_store')) &&
                                                        <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.role.details,
        store: state.role.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RoleStore));