import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import { withNamespaces } from 'react-i18next';
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { showPromotion, updatePromotion } from "../../store/ducks/promotion/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import Select2 from "../../components/Input/Select2/Select2";
import { getType, getMemberGroup, getCategory } from "../../store/ducks/promotion/actions";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import Lightbox from 'react-image-lightbox';

//import Checkbox from "../../components/Input/Checkbox/Checkbox";

let langArr = [];
let typeArry = [];
//let userMemberArry = [];
let categoryArry = [];

// let formData = new FormData(); 

class PromotionEdit extends Component {
    state = {
        image_preview: [],
        featured_image_preview: [],
        // promo_details_image_preview: [],
        promo_banner_image_preview: [],
        mobile_image_preview: [],
        mobile_banner_image_preview: [],
        image: [],
        featured_image: [],
        promo_details_image: [],
        promo_banner_image: [],
        mobile_image: [],
        mobile_banner_image: [],
        title: [],
        content: [],
        sub_content: [],
        start_time: '',
        end_time: '',
        sequence: '',
        // extra: '',
        valid_period: '',
        type: '',
        category: [],
        min_deposit: '',
        max_payout: '',
        percentage: '',
        turnover_multiply: '',
        entitle_rebate: '',
        currentStatus: 1,
        currentShow: 1,
        currentGroupName: [],
        group_name: [],
        loading: true,
        events_redirect_url: ''
    };

    componentDidMount() {
        formValidation('promotion_form');

        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        const showPromotionData = {};
        showPromotionData.promotion_id = this.props.show_promotion_id;

        this.props.getMemberGroup();
        this.props.getType();
        this.props.getCategory();
        this.props.showPromotion(showPromotionData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_promotion_result !== this.props.update_promotion_result) {
            if (nextProps.update_promotion_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_promotion_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.update_promotion_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_promotion_result.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_promotion_result.message[0],
                    icon: nextProps.update_promotion_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }

        }

        if (nextProps.show_promotion_data !== this.props.show_promotion_data) {
            if (nextProps.show_promotion_data.data.responseCode === 200) {
                const promotionData = nextProps.show_promotion_data.data.data[0];
                // const getCurrentMemberGroupName = promotionData.member_group;
                // const getCurrentMemberGroupID = promotionData.member_group_id;
                // let userMemberArry = [];
                const getCurrentCategoryName = promotionData.category;
                const getCurrentCategoryID = promotionData.category_id;
                let categoryArry = [];

                // if (getCurrentMemberGroupName !== "") {
                //     const getCurrentMemberGroupNameArry = getCurrentMemberGroupName;
                //     const getCurrentMemberGroupIDArry = getCurrentMemberGroupID;
                //     // console.log(getCurrentMemberGroupNameArry,getCurrentMemberGroupIDArry);
                //     Object.keys(getCurrentMemberGroupNameArry).map((item, i) => {
                //         return userMemberArry.push({
                //             'label': getCurrentMemberGroupNameArry[item],
                //             'value': getCurrentMemberGroupIDArry[item],
                //             'key': getCurrentMemberGroupIDArry[item],
                //         });
                //     });
                // }

                if (getCurrentCategoryName !== "") {
                    const getCurrentCategoryNameArry = getCurrentCategoryName;
                    const getCurrentCategoryIDArry = getCurrentCategoryID;
                    const { t } = this.props;

                    Object.keys(getCurrentCategoryNameArry).map((item, i) => {
                        return categoryArry.push({
                            'label': t('promotion-type.' + getCurrentCategoryNameArry[item]),
                            'value': getCurrentCategoryIDArry[item],
                            'key': getCurrentCategoryIDArry[item],
                        });
                    });
                }

                const {
                    image_preview,
                    // image,
                    title,
                    content,
                    promo_details_image,
                    sub_content,
                    featured_image_preview,
                    // promo_details_image_preview,
                    promo_banner_image_preview,
                    mobile_image_preview,
                    mobile_banner_image_preview
                } = this.state;

                // console.log(promotionData.image);

                nextProps.show_promotion_data.data.data.map((item, i) => {
                    image_preview[item.lang_id] = item.image;
                    featured_image_preview[item.lang_id] = item.featured_image;
                    promo_details_image[item.lang_id] = item.promo_details_image;
                    promo_banner_image_preview[item.lang_id] = item.promo_banner_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    mobile_banner_image_preview[item.lang_id] = item.mobile_banner_image;
                    title[item.lang_id] = item.title;
                    content[item.lang_id] = item.content;
                    sub_content[item.lang_id] = item.sub_content;
                    return item;
                })

                this.setState({
                    loading: false,
                    image_preview,
                    featured_image_preview,
                    promo_details_image,
                    promo_banner_image_preview,
                    mobile_image_preview,
                    mobile_banner_image_preview,
                    // image,
                    title,
                    content,
                    sub_content,
                    sequence: promotionData.sequence,
                    // extra: promotionData.extra,
                    turnover_multiply: promotionData.turnover_multiply,
                    valid_period: promotionData.valid_period,
                    min_deposit: promotionData.min_deposit,
                    max_payout: promotionData.max_payout,
                    type: promotionData.type_id,
                    category: categoryArry.length > 0 ? categoryArry : '',
                    // group_name: userMemberArry.length > 0 ? userMemberArry : '',
                    entitle_rebate: promotionData.entitle_rebate,
                    percentage: promotionData.percentage,
                    start_time: new Date(promotionData.start_time),
                    end_time: new Date(promotionData.end_time),
                    currentStatus: promotionData.status,
                    currentShow: promotionData.show,
                    events_redirect_url: promotionData.events_redirect_url
                })
            } else if (nextProps.show_promotion_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.show_promotion_data.data.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_promotion_data.data.message,
                    icon: nextProps.show_promotion_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        // if(nextProps.member_group_data !== this.props.member_group_data) {
        //     if (nextProps.member_group_data.data.responseCode === 200) {
        //         Swal.close();
        //         userMemberArry = [];
        //         let data = nextProps.member_group_data.data.data;
        //         Object.keys(data).map((item, i) => {
        //             return userMemberArry.push({
        //                 'label': data[item].group_name,
        //                 'value': data[item].Id,
        //                 'key': data[item].Id
        //             });
        //         })
        //     }
        // }

        if (nextProps.type_data !== this.props.type_data) {
            if (nextProps.type_data.data.responseCode === 200) {
                const { t } = this.props;
                Swal.close();
                typeArry = [];
                let data = nextProps.type_data.data.data;
                Object.keys(data).map((item, i) => {
                    return typeArry.push(
                        {
                            'label': t('promotion-type.' + data[item].slug),
                            'value': data[item].Id,
                            'key': data[item].Id,
                        });
                })
            }
        }

        if (nextProps.category_data !== this.props.category_data) {
            if (nextProps.category_data.data.responseCode === 200) {
                const { t } = this.props;
                Swal.close();
                categoryArry = [];
                let data = nextProps.category_data.data.data;
                Object.keys(data).map((item, i) => {
                    return categoryArry.push({
                        'label': t('promotion-type.' + data[item].title),
                        'value': data[item].Id,
                        'key': data[item].Id,
                    });
                })
            }
        }

        return true;
    }

    updatePromotionHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                //const currentGroupName = this.state.group_name;
                const currentCategory = this.state.category;
                const title = this.state.title;
                const content = this.state.content;
                const subContent = this.state.sub_content;

                if (this.state.image.length > 0) {
                    const image = this.state.image;
                    image.forEach((val, idx) => {
                        formData.append("image[" + idx + "]", val);
                    });
                }

                if (this.state.featured_image.length > 0) {
                    const featuredImage = this.state.featured_image;
                    featuredImage.forEach((val, idx) => {
                        formData.append("featured_image[" + idx + "]", val);
                    });
                }

                if (this.state.promo_details_image.length > 0) {
                    const promoDetailsImage = this.state.promo_details_image;
                    promoDetailsImage.forEach((val, idx) => {
                        formData.append("promo_details_image[" + idx + "]", val);
                    });
                }

                if (this.state.promo_banner_image.length > 0) {
                    const promoBannerImage = this.state.promo_banner_image;
                    promoBannerImage.forEach((val, idx) => {
                        formData.append("promo_banner_image[" + idx + "]", val);
                    });
                }

                if (this.state.mobile_image.length > 0) {
                    const mobileImage = this.state.mobile_image;
                    mobileImage.forEach((val, idx) => {
                        formData.append("mobile_image[" + idx + "]", val);
                    });
                }

                if (this.state.mobile_banner_image.length > 0) {
                    const mobileBannerImage = this.state.mobile_banner_image;
                    mobileBannerImage.forEach((val, idx) => {
                        formData.append("mobile_banner_image[" + idx + "]", val);
                    });
                }
                // if(currentGroupName!==null)
                // {
                //     currentGroupName.forEach(itemGroupName => {
                //         formData.append("member_group[]", itemGroupName.value);
                //     });
                // }
                if (currentCategory !== "" && currentCategory !== null) {
                    currentCategory.forEach(itemGroupName => {
                        formData.append("category[]", itemGroupName.value);
                    });
                }

                title.forEach((val, idx) => {
                    formData.append("title[" + idx + "]", val);
                });
                content.forEach((val, idx) => {
                    formData.append("content[" + idx + "]", val);
                });
                subContent.forEach((val, idx) => {
                    formData.append("sub_content[" + idx + "]", val);
                });

                // formData.append("extra",this.state.extra);
                formData.append("percentage", this.state.percentage);
                formData.append("entitle_rebate", this.state.entitle_rebate);
                formData.append("turnover_multiply", this.state.turnover_multiply);
                formData.append("valid_period", this.state.valid_period);
                formData.append("min_deposit", this.state.min_deposit);
                formData.append("max_payout", this.state.max_payout);
                formData.append("type", this.state.type);
                formData.append("sequence", this.state.sequence);
                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("show", this.state.currentShow);
                formData.append("country_id", this.props.currentCountryId);
                formData.append("id", this.props.show_promotion_id);
                formData.append("events_redirect_url", this.state.events_redirect_url);
                // for (var pair of formData.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }
                this.props.updatePromotion(formData);
            }
        })

    }

    getImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { image_preview, image } = this.state;

        reader.onloadend = () => {

            image_preview[langId] = reader.result;
            image[langId] = file;

            this.setState({
                image_preview
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            image_preview[langId] = "";
            this.setState({
                image_preview
            })
        }
    };

    getFeaturedImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { featured_image_preview, featured_image } = this.state;

        reader.onloadend = () => {

            featured_image_preview[langId] = reader.result;
            featured_image[langId] = file;

            this.setState({
                featured_image_preview,
                featured_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            featured_image_preview[langId] = "";
            this.setState({
                featured_image_preview
            })
        }

    };

    // getPromoDetailsImageHandler = (event, langId) => {
    //     let reader = new FileReader();
    //     let file = event[0];

    //     const {promo_details_image_preview, promo_details_image} = this.state;

    //     reader.onloadend = () => {

    //         promo_details_image_preview[langId] = reader.result;
    //         promo_details_image[langId] = file;

    //         this.setState({
    //             promo_details_image_preview,
    //             promo_details_image
    //         });
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     } else {
    //         promo_details_image_preview[langId] = "";
    //         this.setState({
    //             promo_details_image_preview
    //         })
    //     }
    // }

    getPromoBannerImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { promo_banner_image_preview, promo_banner_image } = this.state;

        reader.onloadend = () => {

            promo_banner_image_preview[langId] = reader.result;
            promo_banner_image[langId] = file;

            this.setState({
                promo_banner_image_preview,
                promo_banner_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            promo_banner_image_preview[langId] = "";
            this.setState({
                promo_banner_image_preview
            })
        }
    }

    getPromoMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_image_preview, mobile_image } = this.state;

        reader.onloadend = () => {

            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({
                mobile_image_preview,
                mobile_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview
            })
        }
    }

    getPromoMobileBannerImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_banner_image_preview, mobile_banner_image } = this.state;

        reader.onloadend = () => {

            mobile_banner_image_preview[langId] = reader.result;
            mobile_banner_image[langId] = file;

            this.setState({
                mobile_banner_image_preview,
                mobile_banner_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_banner_image_preview[langId] = "";
            this.setState({
                mobile_banner_image_preview
            })
        }
    }

    getTitleHandler = (event, langId) => {

        const { title } = this.state;

        title[langId] = event.target.value;

        this.setState({
            title
        });

        // this.props.formData.append("title["+langId+"]", event.target.value);
    }

    // getContentHandler = (event, langId) => {

    //     const {content} = this.state;

    //     content[langId] = event.target.value;

    //     this.setState({
    //         content
    //     });

    //     // this.props.formData.append("content["+langId+"]", event.target.value);
    // };

    getSubContentHandler = (event, langId) => {

        const { sub_content } = this.state;

        sub_content[langId] = event.target.value;

        this.setState({
            sub_content
        });

        // this.props.formData.append("sub_content["+langId+"]", event.target.value);
    };

    getTargetHandler = (event) => {
        this.setState({
            target: event.target.value
        });

    }

    getSequenceHandler = (event) => {
        this.setState({
            sequence: event.target.value
        });

    }

    getTypeHandler = (event) => {
        this.setState({ type: event.target.value });
    }

    getCategoryHandler = (event) => {
        // this.setState({category: event.target.value});
        this.setState({ category: event });
    }


    getExtraHandler = (event) => {
        this.setState({
            extra: event.target.value
        });

    }

    getTurnoverMultiplyHandler = (event) => {
        this.setState({
            turnover_multiply: event.target.value
        });

    }

    getPercentageHandler = (event) => {
        this.setState({
            percentage: event.target.value
        });

    }

    getMinDepositHandler = (event) => {
        this.setState({
            min_deposit: event.target.value
        });

    }

    getMaxDepositHandler = (event) => {
        this.setState({
            max_payout: event.target.value
        });
    }

    getMemberGroupHandler = (event) => {
        this.setState({ group_name: event });
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    }

    changeShowHandler = (event) => {
        this.setState({ currentShow: event.target.value });
    }

    changeEntitleRebateHandler = (event) => {
        this.setState({ entitle_rebate: event.target.value });
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        this.setState({
            [event.target.id]: event.target.value,
        });
    }

    removeImageHandler = (event, langId) => {
        let { image_preview, image } = this.state;

        // this.props.formData.delete("image[" + langId + "]");

        image_preview[langId] = '';
        image[langId] = '';

        this.setState({
            image_preview,
            image
        });
    }

    removeFeaturedImageHandler = (event, langId) => {
        let { featured_image_preview, featured_image } = this.state;

        // this.props.formData.delete("image[" + langId + "]");

        featured_image_preview[langId] = '';
        featured_image[langId] = '';

        this.setState({
            featured_image_preview,
            featured_image
        });
    }

    // removePromoDetailsImageHandler = (event, langId) => {
    //     let {promo_details_image_preview, promo_details_image} = this.state;

    //     // this.props.formData.delete("image[" + langId + "]");

    //     promo_details_image_preview[langId] = '';
    //     promo_details_image[langId] = '';

    //     this.setState({
    //         promo_details_image_preview,
    //         promo_details_image
    //     });
    // }

    getEventsRedirectUrl = (event) => {
        this.setState({ events_redirect_url: event.target.value });
    }

    render() {
        // console.log(this.state.image_preview);
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        // let showArry = [
        //     {
        //         label: t("global.show"),
        //         value: 1,
        //         selected: true
        //     },
        //     {
        //         label: t("global.hide"),
        //         value: 0,
        //     }
        // ];

        let entitleRebateArry = [
            {
                label: t("global._yes"),
                value: 1,
                selected: true
            },
            {
                label: t("global._no"),
                value: 0,
            }
        ];


        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className='row'>
                    <div className="col-md-4 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.image')}</label>
                            <ImageUpload
                                id="image"
                                name={`image[${item.langId}]`}
                                onChange={(event) => this.getImageHandler(event, item.langId)}
                                defaultImage={this.state.image_preview[item.langId] && [this.state.image_preview[item.langId]]}
                            />
                            {/* <div className='preview-image'>
                                {this.state.image_preview[item.langId] &&
                                    <>
                                        //need to command the btn below if u want after uncommanded
                                        <Button
                                            typeButton="button"
                                            classValue="clearImage"
                                            buttonName="x"
                                            clicked={(event) => this.removeImageHandler(event, item.langId)}
                                        >
                                        </Button>

                                        <img
                                            alt=""
                                            className="banner"
                                            src={this.state.image_preview[item.langId]}
                                            onClick={() => this.setState({ isOpen: true, currentImage: this.state.image_preview[item.langId] })}
                                            style={{ "cursor": "pointer" }}
                                        />
                                    </>
                                }
                            </div> */}
                            {/* <div className="d-flex align-items-center flex-column">
                            <div className="col-md-12">
                                <ImageUpload
                                    id="image"
                                    name={`image[${item.langId}]`}
                                    onChange={(event) => this.getImageHandler(event, item.langId)}
                                />
                            </div>
                            <div className="col-md-6 col-12">
                                {this.state.image_preview[item.langId] &&
                                    <div className="imgPreview image-preview-image">
                                        <div className="uploadPictureContainer"> */}
                            {/* //need to command the btn below if u want after uncommanded */}
                            {/* <Button
                                                typeButton="button"
                                                classValue="clearImage"
                                                buttonName="x"
                                                clicked={(event) => this.removeImageHandler(event, item.langId)}
                                            >
                                            </Button>

                                            <img
                                                alt=""
                                                className="banner"
                                                src={this.state.image_preview[item.langId]}
                                                onClick={() => this.setState({isOpen: true, currentImage: this.state.image_preview[item.langId]})}
                                                style={{"cursor": "pointer"}}
                                            />
                                        </div>
                                    </div>
                                }

                                {(this.state.image_preview.length === 0 || !this.state.image_preview[item.langId]) &&
                                    <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                                }
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.featured-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`featured_image[${item.langId}]`}
                                onChange={(event) => this.getFeaturedImageHandler(event, item.langId)}
                                defaultImage={this.state.featured_image_preview[item.langId] && [this.state.featured_image_preview[item.langId]]}
                            />
                            {/* <div className='preview-image'>
                                {this.state.featured_image_preview[item.langId] &&

                                    <>
                                        //need to command the btn below if u want after uncommanded
                                        <Button
                                            typeButton="button"
                                            classValue="clearImage"
                                            buttonName="x"
                                            clicked={(event) => this.removeFeaturedImageHandler(event, item.langId)}
                                        >
                                        </Button>

                                        <img
                                            alt=""
                                            className="banner"
                                            src={this.state.featured_image_preview[item.langId]}
                                            onClick={() => this.setState({ isOpen: true, currentImage: this.state.featured_image_preview[item.langId] })}
                                            style={{ "cursor": "pointer" }}
                                        />
                                    </>

                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.promo-banner-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`promo_banner_image[${item.langId}]`}
                                onChange={(event) => this.getPromoBannerImageHandler(event, item.langId)}
                                defaultImage={this.state.promo_banner_image_preview[item.langId] && [this.state.promo_banner_image_preview[item.langId]]}
                            />

                            <input
                                type="hidden"
                                name={`promo_banner_image[${item.langId}]`}
                            />

                            {/* {
                                this.state.promo_banner_image_preview[item.langId] &&
                                <div className="preview-image">
                                    <Button
                                        typeButton="button"
                                        classValue="clearImage"
                                        buttonName="x"
                                        clicked={(event) => this.removeFeaturedImageHandler(event, item.langId)}
                                    >
                                    </Button>
                                    <img
                                        className="banner"
                                        src={this.state.promo_banner_image_preview[item.langId]}
                                        alt=""
                                        onClick={() => this.setState({ isOpen: true, currentPromoBannerImage: this.state.promo_banner_image_preview[item.langId] })}
                                        style={{ "cursor": "pointer" }}
                                    />
                                </div>
                            } */}

                            {/* {this.state.promo_banner_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.mobile-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`mobile_image[${item.langId}]`}
                                onChange={(event) => this.getPromoMobileImageHandler(event, item.langId)}
                                defaultImage={this.state.mobile_image_preview[item.langId] && [this.state.mobile_image_preview[item.langId]]}
                            />

                            <input
                                type="hidden"
                                name={`mobile_image[${item.langId}]`}
                            />
                            {/* {
                                this.state.mobile_image_preview[item.langId] &&
                                <div className="preview-image">
                                    <Button
                                        typeButton="button"
                                        classValue="clearImage"
                                        buttonName="x"
                                        clicked={(event) => this.removeFeaturedImageHandler(event, item.langId)}
                                    >
                                    </Button>
                                    <img
                                        className="banner"
                                        src={this.state.mobile_image_preview[item.langId]}
                                        alt=""
                                        onClick={() => this.setState({ isOpen: true, currentMobileImage: this.state.mobile_image_preview[item.langId] })}
                                        style={{ "cursor": "pointer" }}
                                    />
                                </div>
                            } */}

                            {/* {this.state.mobile_image_preview.length === 0 &&
                            <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        } */}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.mobile-banner-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`mobile_banner_image[${item.langId}]`}
                                onChange={(event) => this.getPromoMobileBannerImageHandler(event, item.langId)}
                                defaultImage={this.state.mobile_banner_image_preview[item.langId] && [this.state.mobile_banner_image_preview[item.langId]]}
                            />

                            <input
                                type="hidden"
                                name={`mobile_banner_image[${item.langId}]`}
                            />
                            {/* {
                                this.state.mobile_banner_image_preview[item.langId] &&
                                <div className="preview-image">
                                    <Button
                                        typeButton="button"
                                        classValue="clearImage"
                                        buttonName="x"
                                        clicked={(event) => this.removeFeaturedImageHandler(event, item.langId)}
                                    >
                                    </Button>
                                    <img
                                        className="banner"
                                        src={this.state.mobile_banner_image_preview[item.langId]}
                                        alt=""
                                        onClick={() => this.setState({ isOpen: true, currentMobileBannerImage: this.state.mobile_banner_image_preview[item.langId] })}
                                        style={{ "cursor": "pointer" }}
                                    />
                                </div>
                            } */}
                            {/* 
                            {this.state.mobile_banner_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label>{t('promotion.title')}</label>
                            <StringInput
                                onChange={(event) => this.getTitleHandler(event, item.langId)}
                                id="title"
                                name={`title[${item.langId}]`}
                                value={this.state.title[item.langId] || ''}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label>{t('promotion.sub-content')}</label>
                            <StringInput
                                className="form-control"
                                onChange={(event) => this.getSubContentHandler(event, item.langId)}
                                id="sub_content"
                                name={`sub_content[${item.langId}]`}
                                value={this.state.sub_content[item.langId] || ''}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.promo-details-image')}</label>
                            <JoditEditor
                                value={this.state.promo_details_image[item.langId] || ''}
                                tabIndex={item.langId} // tabIndex of textarea
                                onBlur={newContent => {
                                    const { promo_details_image } = this.state;
                                    promo_details_image[item.langId] = newContent;
                                    this.setState({
                                        promo_details_image
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label>{t('promotion.content')}</label>
                            <JoditEditor
                                value={this.state.content[item.langId] || ''}
                                tabIndex={item.langId} // tabIndex of textarea
                                onBlur={newContent => {
                                    const { content } = this.state;
                                    content[item.langId] = newContent;
                                    this.setState({
                                        content
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>


            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border" />;
        // console.log(this.state.category)
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('promotion.promotion')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('promotion.edit-promotion')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4} /> :
                                    <form method="post" id="promotion_form" className="form-horizontal"
                                        onSubmit={this.updatePromotionHandler} noValidate>
                                        <ValidationError formName="promotion_form" touched={this.state.touched}
                                            message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.start-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                                                    </span> */}
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.end-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                                            </span> */}
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                        value={this.state.currentStatus}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.show')}</label>
                                                    <Select
                                                        id="show"
                                                        name="show"
                                                        className="form-control"
                                                        options={showArry}
                                                        onChange={this.changeShowHandler}
                                                        value={this.state.currentShow}
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.extra')}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.getExtraHandler(event)}
                                                        id="extra"
                                                        name="extra"
                                                        value={this.state.extra}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.valid-period')}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id="valid_period"
                                                        name="valid_period"
                                                        value={this.state.valid_period}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.type')}</label>
                                                    <Select
                                                        id="type"
                                                        name="type"
                                                        className="form-control"
                                                        options={typeArry}
                                                        value={this.state.type}
                                                        onChange={this.getTypeHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.category')}</label>
                                                    <Select2
                                                        options={categoryArry}
                                                        name="category"
                                                        onChange={this.getCategoryHandler}
                                                        value={this.state.category}
                                                        placeholder={t('global.select')}
                                                        menuPlacement="top"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.sequence')}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.getSequenceHandler(event)}
                                                        id="sequence"
                                                        name="sequence"
                                                        value={this.state.sequence}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <label htmlFor="group">{t('promotion.member-group')}</label>
                                                <Select2
                                                    options={userMemberArry}
                                                    name="member_group"
                                                    onChange={this.getMemberGroupHandler}
                                                    value={this.state.group_name}
                                                />
                                            </div> */}

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.show')}</label>
                                                    <Select
                                                        id="entitle_rebate"
                                                        name="entitle_rebate"
                                                        className="form-control"
                                                        options={entitleRebateArry}
                                                        onChange={this.changeEntitleRebateHandler}
                                                        value={this.state.entitle_rebate}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.turnover-multiply')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getTurnoverMultiplyHandler(event)}
                                                                id="turnover_multiply"
                                                                name="turnover_multiply"
                                                                value={this.state.turnover_multiply}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.min-deposit')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getMinDepositHandler(event)}
                                                                id="min_deposit"
                                                                name="min_deposit"
                                                                value={this.state.min_deposit}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.max-payout')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getMaxDepositHandler(event)}
                                                                id="max_payout"
                                                                name="max_payout"
                                                                value={this.state.max_payout}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.percentage')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getPercentageHandler(event)}
                                                                id="percentage"
                                                                name="percentage"
                                                                value={this.state.percentage}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="group">{t('promotion.events-redirect-url')}</label>
                                                    <StringInput
                                                        onChange={this.getEventsRedirectUrl}
                                                        id="events_redirect_url"
                                                        name="events_redirect_url"
                                                        value={this.state.events_redirect_url}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { home, promotion, currentUser } = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        update_promotion_result: promotion.update_promotion_result,
        // member_group_data: promotion.member_group_data,
        category_data: promotion.category_data,
        type_data: promotion.type_data,
        show_promotion_data: promotion.show_promotion_data,
        currentCountryId: currentUser.data.country_id
    }
};

const mapDispatchToProps = {
    getCountry,
    updatePromotion,
    getCategory,
    getType,
    getMemberGroup,
    showPromotion
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PromotionEdit));