import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { filterErrorHandler } from '../../hoc/Shared/utility';
import PermissionListing from './PermissionListing';
import PermissionEdit from './PermissionEdit';
import PermissionStore from './PermissionStore';

import * as actions from '../../store/ducks/permission/actions';

class Permission extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            module: [],

            /* api result array */
            data: [],

            /* details id */
            selectId: null,

            /* skeleton loading */
            loading: true,
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        this.props.funcGetList(); // get datatables result
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        // console.log('shouldComponentUpdate :', nextProps.rs);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    module: nextProps.rs.data.module,
                    loading: false,
                });
            }
            else
            {
                this.setState({
                    module: [],
                    loading: false   
                }, () => {
                    filterErrorHandler(nextProps.rs.message);
                });
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        this.setState({
            filter: newFilter
        });
    }

    detailsHandler = (e, page, id) =>
    {
        // console.log('detailsHandler :'+page+' | '+id);
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            module: [],
        }, ()=> {
            this.props.funcGetList();
        });
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <PermissionListing
                        /* setstate function */
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}

                        /* datatables data */
                        data={this.state.module}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <PermissionEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='create' &&
                    <PermissionStore
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.permission.rs,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: () => dispatch(actions.getList()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Permission));