import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import {formValidation, clearFormValidation} from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import Select from "../../components/Input/Select/Select";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import Tab from "../../components/Tab/Tab";

import * as actions from '../../store/ducks/luckySpinPrize/actions';

let setting = [];
setting[1] = []; // gift
setting[2] = ['vendor','gameType','percent']; // rebate
setting[3] = ['percent','turnover']; // mb
setting[4] = ['amount','turnover']; // bonus

class LuckySpinPrizeEdit extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            formFilter: {},
            loading: true,
            langArr: [],
            errMsg: [],
            touched: false,
            dropDown:
            {
                category: [],
                vendor: [],
                gameType: [],
            },
            setting_list: [],
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
    }

    componentDidMount()
    {
        // console.log(this.props.selectId);
        formValidation('lucky_spin_prize_form');
        if(this.props.selectId)
        {
            this.props.funcGetDetails(this.props.selectId);
        }
        this.props.funcGetDropDown();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                let categoryArr = nextProps.dropDown.data.category.map((val, idx) =>
                {
                    return {...val, category: t(`lucky-spin-prize.${val.slug}`)};
                });

                let vendorArr = nextProps.dropDown.data.vendor.map((val, idx) =>
                {
                    return {...val, vendor: t(`vendor.${val.slug}`)};
                });

                let gameTypeArr = nextProps.dropDown.data.gameType.map((val, idx) =>
                {
                    return {...val, gameType: t(`game-type.${val.slug}`)};
                });

                this.setState({
                    loading: false,
                    langArr: nextProps.dropDown.data.language,
                    dropDown:
                    {
                        category: categoryArr,
                        vendor: vendorArr,
                        gameType: gameTypeArr,
                    },
                });
            }
        }

        if (nextProps.details !== this.props.details && nextProps.details!==undefined)
        {
            if(nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success')
            {
                let tabArr = [];
                let newInput = {};

                for (const key in nextProps.details.data.result) 
                {
                    newInput[key] = nextProps.details.data.result[key];
                }
                
                if(nextProps.details.data.result.prize_details)
                {
                    nextProps.details.data.result.prize_details.forEach((val) => 
                    {
                        tabArr[val.lang_id] = val;
                    });
                }

                newInput['prize_details'] = tabArr;
                
                // console.log(newInput);
                this.setState({
                    formFilter: newInput,
                    langArr: nextProps.details.data.language,
                    loading: false,
                    setting_list: (newInput['prize_setitng']) ? JSON.parse(newInput['prize_setitng'].data) : [],
                });
            }
            // Swal.close();
        }

        if (nextProps.update !== this.props.update && nextProps.update!==undefined)
        {
            // console.log('shouldComponentUpdate :', nextProps.update);
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => 
                {
                    clearFormValidation('lucky_spin_prize_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }
        return true;
    }

    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        let setting_list = Object.assign([], this.state.setting_list);
        // if(langId && langId > 0)
        // {
        //     if(newInput.prize_details[langId])
        //     {
        //         newInput.prize_details[langId][event.target.id] = event.target.value;
        //     }
        //     else
        //     {
        //         let newLangArr = {[event.target.id]: event.target.value};
        //         newInput.prize_details[langId] = newLangArr;
        //     }
        // }
        // else
        // {
            if(event.target.id === 'category_id')
            {
                setting_list = [];
            }
            newInput[event.target.id] = event.target.value;
        // }
        
        this.setState({
            formFilter: newInput,
            setting_list,
        });
    }
    
    handleSettingListChange = (event, key) => 
    {
        // console.log(event.target.id + ' | '+ event.target.value + ' | ' + key)
        let setting_list = Object.assign({}, this.state.setting_list);
        setting_list[key] = event.target.value;
        // console.log(setting_list);
        this.setState({ setting_list });
    }

    submitHandler = (e) =>
    {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'), 
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                // const details = this.state.formFilter.prize_details;
                // details.forEach((val, idx) => 
                // {
                //     for (const key in val)
                //     {
                //         // console.log(val[key]);
                //         if(key !== 'image' && key !== 'lang_id')
                //         {
                //             formData.append(`${key}[${idx}]`, val[key]);
                //         }
                //     }
                // });
                
                formData.append('id',this.props.selectId);
                formData.append('title',this.state.formFilter.title);
                formData.append('slug',this.state.formFilter.slug);
                formData.append('sequence',this.state.formFilter.sequence);
                formData.append('setting', (Array.isArray(this.state.setting_list)) ? '' : JSON.stringify(this.state.setting_list));
                formData.append('category_id',this.state.formFilter.category_id);
                formData.append('status',this.state.formFilter.status);
                formData.append('country_id',this.props.currentUser.country_id);

                // for (var pair of formData.entries()) 
                // {
                //      console.log(pair[0]+ ' = ' + pair[1]);
                // }
                this.props.funcUpdate(formData);
            }
        });
    }

    render()
    {
        const { t } = this.props;
        // let tabMessage = null;
        // let tabTitleMessage = [];
        // let tabContentMessage = [];
        let statusArr = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];
        // let languages = this.state.langArr;
        // let tabArr = (this.state.formFilter.prize_details || []);

        // console.log(this.state.image);
        
        // tabTitleMessage = languages.map((language) => language.lang);
        // tabContentMessage = languages.map((item) => (
        //     <Auxiliary>
        //         <div className="col-md-12">
        //             <div className="form-group">
        //                 <label>{t('lucky-spin-prize.title')}</label>
        //                 <StringInput
        //                     onChange={(event) => this.onInputChange(event, item.langId)}
        //                     id="title"
        //                     name={`title[${item.langId}]`}
        //                     value={tabArr[item.langId] && tabArr[item.langId].title || ''}
        //                 />
        //             </div>
        //         </div>
        //     </Auxiliary>
        // ));
        // tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage}/>;
        
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("side-bar.lucky_spin_prize")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('lucky-spin-prize.edit-prize')}</h4>
                            </div>
                            <div className="card-body pt-0"> 
                                <form method="post" id="lucky_spin_prize_form" className="form-horizontal" encType="multipart/form-data" onSubmit={this.submitHandler} noValidate>    
                                    <ValidationError formName='lucky_spin_prize_form' touched={this.state.touched} message={this.state.errMsg}/>
                                    {this.state.loading
                                    ? 
                                    <Skeleton count={4}/> 
                                    :
                                    <>
                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('lucky-spin-prize.title')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id='title'
                                                        name='title'
                                                        value={this.state.formFilter.title}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('lucky-spin-prize.slug')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id='slug'
                                                        name='slug'
                                                        value={this.state.formFilter.slug}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('lucky-spin-prize.sequence')}</label>
                                                    <NumberInput
                                                        id='sequence'
                                                        name='sequence'
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.sequence}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('global.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArr}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.status}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('lucky-spin-prize.category')}</label>
                                                    <Select  
                                                        id='category_id'  
                                                        name='category_id'
                                                        className='form-control'
                                                        getOptionValue='id'
                                                        getOptionLabel='category'
                                                        options={this.state.dropDown.category}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.category_id}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="pt-4" />

                                        {setting[this.state.formFilter.category_id] && setting[this.state.formFilter.category_id].length > 0 &&
                                        (<div className="row">
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <h3 className="card-title col-8">{t('system-settings.setting-value')}</h3>
                                                </div>
                                                <div className="table-responsive" style={{overflowY:'auto'}}>
                                                    <table className="table" id="setting_table" style={{minWidth:500}}>
                                                        <thead className="text-center bg-primary">
                                                            <tr>
                                                                <th className="text-white p-2">{t("system-settings.key")}</th>
                                                                <th className="text-white p-2">{t("system-settings.setting-value")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {setting[this.state.formFilter.category_id].map((item, idx) => 
                                                            {
                                                                let input = null;
                                                                switch (item) {
                                                                    case "vendor":
                                                                        input = <Select  
                                                                                    id={`key-${idx}`}
                                                                                    name={`key-${idx}`}
                                                                                    className='form-control'
                                                                                    getOptionValue='slug'
                                                                                    getOptionLabel='vendor'
                                                                                    emptyValue={true}
                                                                                    options={this.state.dropDown.vendor}
                                                                                    onChange={(event) => this.handleSettingListChange(event, item)}
                                                                                    value={this.state.setting_list[item] || ''}
                                                                                    required
                                                                                />;
                                                                    break;
                                                                    case "gameType":
                                                                        input = <Select  
                                                                                    id={`key-${idx}`}
                                                                                    name={`key-${idx}`}
                                                                                    className='form-control'
                                                                                    getOptionValue='slug'
                                                                                    getOptionLabel='gameType'
                                                                                    emptyValue={true}
                                                                                    options={this.state.dropDown.gameType}
                                                                                    onChange={(event) => this.handleSettingListChange(event, item)}
                                                                                    value={this.state.setting_list[item] || ''}
                                                                                    required
                                                                                />;
                                                                    break;
                                                                    case "percent":
                                                                    case "turnover":
                                                                    case "amount":
                                                                        input = <NumberInput
                                                                                    id={`value-${idx}`}
                                                                                    name={`value-${idx}`}
                                                                                    max={(item === 'percent') ? 100 : null}
                                                                                    onChange={(event) => this.handleSettingListChange(event, item)}
                                                                                    value={this.state.setting_list[item] || ''}
                                                                                    required
                                                                                />;
                                                                    break;
                                                                    default:
                                                                        input = null;
                                                                    break;
                                                                }

                                                                return (
                                                                    <tr className="text-center">
                                                                        <td key={`key-`}>
                                                                            <input
                                                                                id="key"
                                                                                name="key"
                                                                                className="form-control"
                                                                                placeholder={t("system-settings.key")}
                                                                                value={item}
                                                                                autoComplete="off"
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td key={`value`}>
                                                                            {input}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>)}


                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>
                                    </>}
                                </form>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        details: state.luckySpinPrize.details,
        dropDown: state.luckySpinPrize.dropDown,
        update: state.luckySpinPrize.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(LuckySpinPrizeEdit));