import React, { Component } from "react";
import { connect } from "react-redux";
import { editReferralSettings, updateReferralSettings } from "../../store/ducks/referralSettings/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select2 from "../../components/Input/Select2/Select2";
// import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";

let vendorArray = [];

class ReferralSettingsEdit extends Component {
    constructor(props) {
        super(props);
        // console.log(this.props);
        this.state = {
            id: null,
            slug: "",
            deposit_requirement: null,
            bonus_reward: null,
            no_day_expire: null,
            turnover_multiply: null,
            minimum_payout: null,
            commission_percentage: null,
            vendor: [],
            loading: true,

            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        formValidation("referral_settings_form");
        // console.log("componentDidMount", this.props);
        this.props.editReferralSettings(this.props.referralEditId);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("componentDidUpdate",prevProps, prevState);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[ReferralSettingsEdit] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.referralSettingsEditData !== undefined && nextProps.referralSettingsEditData !== this.props.referralSettingsEditData && nextState.loading === true) {
            let referralSettingsData = nextProps.referralSettingsEditData.data.referralSetting[0];
            let referralVendor = nextProps.referralSettingsEditData.data.referralVendor;
            let vendor = nextProps.referralSettingsEditData.data.vendor;

            vendorArray = [];
            let selectedVendor = [];
            vendor.forEach((item, i) => {
                vendorArray.push({
                    label: item.title,
                    value: item.gameVendorId,
                    key: item.gameVendorId,
                });
                if (referralVendor.includes(item.gameVendorId)) {
                    selectedVendor.push({
                        label: item.title,
                        value: item.gameVendorId,
                    });
                }
            });

            // let selectedVendor = vendorArray.filter(x => x.gameVendorId === )

            this.setState({
                id: referralSettingsData.referralSettingsId,
                slug: referralSettingsData.slug,
                deposit_requirement: referralSettingsData.depositRequirement,
                bonus_reward: referralSettingsData.bonusReward,
                no_day_expire: referralSettingsData.noDayExpire,
                turnover_multiply: referralSettingsData.turnoverMultiply,
                minimum_payout: referralSettingsData.minPayout,
                commission_percentage: referralSettingsData.commPercentage,
                vendor: selectedVendor,
                loading: false,
            });
        }

        if (nextProps.updateReferralSettingsResult !== this.props.updateReferralSettingsResult) {
            if (nextProps.updateReferralSettingsResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateReferralSettingsResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateReferralSettingsResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateReferralSettingsResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateReferralSettingsHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let vendor = this.state.vendor.map((item) => item.value);

                let updateReferralSettingsData = {};
                updateReferralSettingsData.edit_id = this.state.id;
                // updateReferralSettingsData.slug = this.state.slug;
                updateReferralSettingsData.deposit_requirement = this.state.deposit_requirement;
                updateReferralSettingsData.bonus_reward = this.state.bonus_reward;
                updateReferralSettingsData.no_day_expire = this.state.no_day_expire;
                updateReferralSettingsData.turnover_multiply = this.state.turnover_multiply;
                updateReferralSettingsData.game_vendor = vendor;
                updateReferralSettingsData.minimum_payout = this.state.minimum_payout;
                updateReferralSettingsData.commission_percentage = this.state.commission_percentage;

                // console.log(updateReferralSettingsData);
                this.props.updateReferralSettings(updateReferralSettingsData);
            }
        });
    };

    handleChange = (event) => {
        let name = event.target.name;
        switch (name) {
            case "deposit_requirement":
            case "bonus_reward":
            case "no_day_expire":
            case "turnover_multiply":
            case "minimum_payout":
            case "commission_percentage":
                if (event.target.value.match(/^\d+(\.\d+)?$/)) {
                    if (event.target.value.charAt(0) === "0" || event.target.value.charAt(0) === ".") {
                        if (event.target.value.charAt(1) === ".") {
                            this.setState({ [event.target.name]: event.target.value });
                        } else if (event.target.value.charAt(0) === "0") {
                            if (event.target.value.substring(1) !== "") {
                                this.setState({ [event.target.name]: event.target.value.substring(1) });
                            } else {
                                this.setState({ [event.target.name]: 0.0 });
                            }
                        } else {
                            this.setState({ [event.target.name]: event.target.value });
                        }
                    } else {
                        this.setState({ [event.target.name]: event.target.value });
                    }
                } else {
                    this.setState({ [event.target.name]: 0.0 });
                }
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
        }
    };

    handleVendorChange = (event) => {
        this.setState({
            vendor: event,
        });
    };

    handleVendorUnSelectAll = () => {
        this.setState({
            vendor: [],
        });
    };

    handleVendorSelectAll = () => {
        this.setState({
            vendor: vendorArray,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("referral-settings.edit-referral-settings")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="referral_settings_form" className="form-horizontal" onSubmit={this.updateReferralSettingsHandler} noValidate>
                                    <ValidationError formName="referral_settings_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="slug">{t("referral-settings.slug")}</label>
                                                <StringInput
                                                    id="slug"
                                                    name="slug"
                                                    className="form-control"
                                                    placeholder={t("referral-settings.slug")}
                                                    value={this.state.slug}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="deposit_requirement">{t("referral-settings.deposit-requirement")}</label>
                                                <NumberInput
                                                    id="deposit_requirement"
                                                    name="deposit_requirement"
                                                    min={1}
                                                    placeholder={t("referral-settings.deposit-requirement")}
                                                    value={this.state.deposit_requirement}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="bonus_reward">{t("referral-settings.bonus-reward")}</label>
                                                <NumberInput
                                                    id="bonus_reward"
                                                    name="bonus_reward"
                                                    min={1}
                                                    placeholder={t("referral-settings.bonus-reward")}
                                                    value={this.state.bonus_reward}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="no_day_expire">{t("referral-settings.no-day-expire")}</label>
                                                <NumberInput
                                                    id="no_day_expire"
                                                    name="no_day_expire"
                                                    min={1}
                                                    placeholder={t("referral-settings.no-day-expire")}
                                                    value={this.state.no_day_expire}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="turnover_multiply">{t("referral-settings.turnover-multiply")}</label>
                                                <NumberInput
                                                    id="turnover_multiply"
                                                    name="turnover_multiply"
                                                    min={1}
                                                    placeholder={t("referral-settings.turnover-multiply")}
                                                    value={this.state.turnover_multiply}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="minimum_payout">{t("referral-settings.minimum-payout")}</label>
                                                <NumberInput
                                                    id="minimum_payout"
                                                    name="minimum_payout"
                                                    min={0}
                                                    placeholder={t("referral-settings.minimum-payout")}
                                                    value={this.state.minimum_payout}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="commission_percentage">{t("referral-settings.commission-percentage")}</label>
                                                <NumberInput
                                                    id="commission_percentage"
                                                    name="commission_percentage"
                                                    min={0}
                                                    max={100}
                                                    placeholder={t("referral-settings.commission-percentage")}
                                                    value={this.state.commission_percentage}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="vendor">{t("referral-settings.vendor")}</label>
                                                <div>
                                                    <Select2 options={vendorArray} name="vendor" onChange={this.handleVendorChange} value={this.state.vendor} />
                                                    <div className="d-flex justify-content-end mt-3">
                                                        {/* <button type="button" className="btn btn-blue" id="selectbtn-vendor" onClick={this.handleVendorSelectAll}>
                                                            {t("global.select-all")}
                                                        </button>
                                                        <button type="button" className="btn btn-blue mr-0" id="unselectbtn-vendor" onClick={this.handleVendorUnSelectAll}>
                                                            {t("global.unselect-all")}
                                                        </button> */}
                                                        <div className="d-flex align-items-center">
                                                            <input type="radio" id="select_all" name="vendor" value="select_all" onClick={this.handleVendorSelectAll} />
                                                            <label className="mb-0 ml-2" for="select_all">{t("global.select-all")}</label>
                                                            <input className="ml-3" type="radio" id="unselect_all" name="vendor" value="unselect_all" onClick={this.handleVendorUnSelectAll} />
                                                            <label className="mb-0 ml-2" for="unselect_all">{t("global.unselect-all")}</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("referral-settings.edit-referral-settings")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("referral-settings.referral-settings")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { referralSettings } = state;

    return {
        referralSettingsEditData: referralSettings.referral_settings_edit_data,
        updateReferralSettingsResult: referralSettings.referral_settings_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editReferralSettings: (id) => dispatch(editReferralSettings(id)),
        updateReferralSettings: (data) => dispatch(updateReferralSettings(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ReferralSettingsEdit));
