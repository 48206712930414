import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Monthrangepicker from "react-bootstrap-monthrangepicker";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
// import BootstrapTable from "react-bootstrap-table-next";
// import { AsyncPaginate } from 'react-select-async-paginate';

import Select from "../../../components/Input/Select/Select";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Excel from "../../../assets/images/excel.svg";


class MemberPayoutReportListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            showCard: false
        };
    }

    getFilterHandler = (event) => {
        this.setState({ filter: event.target.value });
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;

        let filterSelection = [
            {
                label: t("company-performance-by-day.select-filter"),
                value: "",
                selected: true,
            },
            {
                label: t("company-performance-by-day.daily"),
                value: "daily",
            },
            {
                label: t("company-performance-by-day.monthly"),
                value: "monthly",
            },
            {
                label: t("company-performance-by-day.yearly"),
                value: "yearly",
            },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("report.member-payout-report")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            {this.props.loading ? (
                                <Skeleton count={2} />
                            ) : (
                                <div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">{t("company-performance-by-day.select-filter")}</label>
                                            <Select
                                                options={filterSelection}
                                                id="filter"
                                                name="filter"
                                                className="form-control filter-input"
                                                onChange={this.getFilterHandler}
                                                required={false}
                                                value={this.state.filter}
                                            />
                                        </div>
                                    </div>

                                    {this.state.filter === "daily" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.date")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="date"
                                                        name="date"
                                                        className="form-control filter-input"
                                                        range={false}
                                                        timePicker={false}
                                                        value={this.props.filter.date}
                                                        onChange={this.props.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.filter === "monthly" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.monthly")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <Monthrangepicker
                                                        monthOrQuarter={0}
                                                        containerClass="monthly w-100"
                                                        onEvent={this.props.onInputChange}
                                                    >
                                                        <button className="form-control filter-input">
                                                            {(this.props.filter.start_month && this.props.filter.end_month) 
                                                            ?
                                                                `${this.props.filter.start_month} ${t('global.to')} ${this.props.filter.end_month}`
                                                            : 
                                                                ''
                                                            }
                                                        </button>
                                                    </Monthrangepicker>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.filter === "yearly" && (
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.yearly")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <Monthrangepicker
                                                        monthOrQuarter={2}
                                                        containerClass="yearly w-100"
                                                        onEvent={this.props.onInputChange}
                                                    >
                                                        <button className="form-control filter-input">
                                                            {(this.props.filter.start_year && this.props.filter.end_year) 
                                                            ?
                                                                `${this.props.filter.start_year} ${t('global.to')} ${this.props.filter.end_year}`
                                                            : 
                                                                ''
                                                            }
                                                        </button>
                                                    </Monthrangepicker>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="username" className="font-weight-bolder">
                                                {t("report.username")}
                                            </label>
                                            <input
                                                className='form-control filter-input'
                                                id='username'
                                                value={this.props.filter.username}
                                                onChange={this.props.onInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col mt-auto">
                                        {this.props.loading ? (
                                            <Skeleton count={2} />
                                        ) : (
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.searchHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                                &nbsp;
                                                &nbsp;
                                                {(() => {
                                                    if (this.props.totalRecords > 0) {
                                                        return (
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-blue mr-2"
                                                                clicked={() => this.props.exportExcelHandler()}
                                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                                buttonName={t("report.export-excel")}
                                                            />
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <DataTable
                                        keyField="row_num"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={this.props.pageChangeHandler}
                                        sorted={this.props.sorted}
                                        sizePerPageHandler={this.props.sizePerPageHandler}
                                        loading={this.props.loading}
                                        classValue="dataTable table-bordered text-justify text-center"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    // return state
    return {}
};

// Connect redux
export default connect(mapStateToProps, null)(withNamespaces('translation')(MemberPayoutReportListing));
