import React, { Component } from "react";
import { connect } from "react-redux";
import { showShipmentDetails } from "../../store/ducks/shipments/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
// import DomPurify from "../../components/Sanitize/DomPurify";
import { filterErrorHandler } from "../../hoc/Shared/utility";


class ShipmentPaymentShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            trackingData: [],
            data: [],
            parcel_data: [
                {
                    charged_weight_unit: "",
                    charged_weight: 0,
                    volume_weight: 0
                }
            ],
            courier: "",
            freight: ""
        };
    }

    componentDidMount() {
        // console.log(this.props)
        this.props.showShipmentDetails(this.props.orderCode, this.props.cusId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContentShow] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.show_shipment_details !== undefined && nextProps.show_shipment_details !== this.props.show_shipment_details) {
            if (nextProps.show_shipment_details.data.responseCode === 200 && nextProps.show_shipment_details.data.msgType === 'success') {
                let data = [];
                let parcelData = nextProps.show_shipment_details.data.data.parcel_data;
                let parcelValue = parcelData && parcelData.parcel_data ? JSON.parse(parcelData.parcel_data) : [];
                let parcelCourier = parcelData && parcelData.courier ? parcelData.courier : [];
                let parcelFreight = parcelData && parcelData.freight ? parcelData.freight : [];

                if(nextProps.show_shipment_details.data.data.data['QueryOrderDetail'][0]['SentKdBillCode']){
                    let SentKdBillCodeData = nextProps.show_shipment_details.data.data.data['QueryOrderDetail'][0]['SentKdBillCode'].split(',');

    
                    nextProps.show_shipment_details.data.data.data['QueryOrderDetail'][0]['SentKdBillCode'] = SentKdBillCodeData;
                    SentKdBillCodeData.forEach((item) => {
                        data[item] = JSON.parse(nextProps.show_shipment_details.data.data.track[item])                        
                    })
                }
                
                let parcelDataUpdate = parcelValue.length > 0
                    ? {
                        charged_weight_unit: parcelValue[0].charged_weight_unit,
                        charged_weight: parcelValue[0].charged_weight,
                        volume_weight: parcelValue[0].volume_weight
                    }
                    : {
                        charged_weight_unit: "",
                        charged_weight: 0,
                        volume_weight: 0
                    }       
                // let data = nextProps.show_shipment_details.data.data.track[nextProps.show_shipment_details.data.data.data['QueryOrderDetail'][0]['SentKdBillCode']]
                this.setState({
                    loading: false,
                    trackingData: data,
                    data: nextProps.show_shipment_details.data.data.data,
                    parcel_data: [{ ...this.state.parcel_data[0], ...parcelDataUpdate }],
                    courier: parcelCourier,
                    freight: parcelFreight
                })             
            }else{
                this.setState({
                    loading: true,
                }, () => 
                {
                    filterErrorHandler(nextProps.show_shipment_details.data.message);
                });
            }               
        }
        return true;
    }

    filterAddress = (address) => {
        const mixedRegex = /[A-Za-z]+[\u4e00-\u9fff]+|[\u4e00-\u9fff]+[A-Za-z]+/g;
        return address.replace(mixedRegex, '').trim().replace(/,\s*,/g, ',').replace(/\s\s+/g, ' ').replace(/,\s*$/, '').trim();
    };

    calculateTotals = () => {
        let totalKG = 0;
        let totalCBM = 0;
        if (this.state.data.QueryOrderToList) {
            this.state.data.QueryOrderToList.forEach(parcel => {
                const [length, width, height] = parcel.Size.split('*').map(Number) || [];
                totalCBM += (length * width * height) / 1000000;
                totalKG += parcel.Weight || 0;
            });
        }
        return {
            totalKG: totalKG.toFixed(2),
            totalCBM: totalCBM.toFixed(5),
        };
    };

    render() {        
        const { t } = this.props;
        const { totalKG, totalCBM } = this.calculateTotals();
        
        let display = null;
        if (!this.state.loading && this.state.data) {
            // let emailContentData = this.props.show_shipment_details.data.emailContent[0];
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.shipment-payment-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.data['QueryOrderDetail'][0]['SentKdBillCode'] && this.state.data['QueryOrderDetail'][0]['SentKdBillCode'].map((sentKdBillCodeItem, index) => (
                                     <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>{t("shipment-payment.shipment-no")}</th>
                                                <td field-key="type">{sentKdBillCodeItem}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("shipment-payment.tracking-details")}</th>
                                                <td>
                                                {this.state.trackingData[sentKdBillCodeItem].map((item, index) => (
                                                    <p key={index}>
                                                        {item.scan_time}
                                                        {item.scan_memo}
                                                    </p>
                                                ))}
                                                </td>
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                ))}

                                {/* <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("shipment-payment.shipment-no")}</th>
                                            <td field-key="type">{this.state.data['QueryOrderDetail'][0]['SentKdBillCode']}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("shipment-payment.tracking-details")}</th>
                                            <td>
                                            {this.state.trackingData.map((item, index) => (
                                                <p key={index}>
                                                    {item.scan_time}
                                                    {item.scan_memo}
                                                </p>
                                            ))}
                                             </td>
                                        </tr>                                        
                                    </tbody>
                                </table> */}
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.shipment-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("shipment-payment.warehouse")}</th>
                                            <td field-key="type">{t(`shipment-payment.${this.state.data['QueryOrderToList'][0]['WaveHouse'].trim()}`)}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("shipment-payment.courier")}</th>
                                            <td field-key="type">{t(`shipment-payment.${this.state.freight}`)}({t(`shipment-payment.${this.state.courier.toUpperCase()}`)})</td>
                                        </tr>
                                        <tr>
                                            <th>{t("shipment-payment.remark")}</th>
                                            <td field-key="type">{this.state.data['QueryOrderDetail'][0]['OrderMemo']}</td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.delivery-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("shipment-payment.recipient")}</th>
                                            <td field-key="type">{this.state.data['QueryOrderDetail'][0]['CName']}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("shipment-payment.address")}</th>
                                            <td field-key="type">{this.filterAddress(this.state.data['QueryOrderDetail'][0]['Address'])}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("shipment-payment.contact")}</th>
                                            <td field-key="type">{this.state.data['QueryOrderDetail'][0]['Mobile']}</td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.parcel-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("shipment-payment.tracking-no")}</th>
                                            <th>{t("shipment-payment.weight")}</th>
                                            <th>{t("shipment-payment.size")}</th>                                           
                                            <th>{t("shipment-payment.price")}</th>
                                            <th>{t("shipment-payment.remark")}</th>
                                        </tr>
                                        {(this.state.data.length !== 0) && this.state.data['QueryOrderToList']
                                        .map((parcel, index) => (
                                        <tr key={index}>
                                            <td><span>{parcel.kdBillcode} ({t(`shipment-payment.${parcel.GoodsType}`)})</span></td>
                                            <td>{parcel.Weight}</td>
                                            <td>{parcel.Size}</td>
                                            {/* <Td>CNY {parcel.price.toFixed(2)}</Td> */}
                                            <td>-</td>
                                            <td>
                                                {parcel.Rem === null
                                                    ? '-'
                                                    : parcel.Rem}
                                            </td>
                                        </tr>
                                      
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.shipping-cost-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("shipment-payment.total-packed")}</th>
                                            <th>{t("shipment-payment.total-parcel")}</th>
                                            <th>{t("shipment-payment.total-weight")}</th>
                                            <th>{t("shipment-payment.total-cbm")}</th>
                                            <th>{t("shipment-payment.volume-weight")}</th>
                                            <th>{t("shipment-payment.charged-weight")}</th>
                                            <th>{t("shipment-payment.discount-amount")}</th>
                                            <th>{t("shipment-payment.total-cost")}</th>
                                        </tr>
                                        <td>1</td>
                                        <td>{this.state.data['QueryOrderToList'].length}</td>
                                        <td>
                                            {totalKG} KG
                                        </td>
                                        <td>
                                            {totalCBM} M³
                                        </td>                                        
                                        <td>{this.state.parcel_data[0].volume_weight.toFixed(2)} KG</td>
                                        <td>
                                            {this.state.parcel_data[0].charged_weight_unit === "kg"
                                                ? this.state.parcel_data[0].charged_weight.toFixed(2)
                                                : this.state.parcel_data[0].charged_weight.toFixed(3)
                                            } {''}
                                            {this.state.parcel_data[0].charged_weight_unit === "cbm"
                                                ? "M³"
                                                : this.state.parcel_data[0].charged_weight_unit.toUpperCase()
                                            }                                     
                                        </td>
                                        <td>
                                           MYR {this.props.discountAmount}
                                        </td>
                                        <td>
                                           MYR {this.props.totalCost}
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("shipment-payment.shipment-payment-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={(event) => this.props.changePageHandler(event, "listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("shipment-payment.shipment-payment")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { shipment } = state;

    return {
        show_shipment_details: shipment.show_shipment_details,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showShipmentDetails: (orderCode, cusId) => dispatch(showShipmentDetails(orderCode, cusId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ShipmentPaymentShow));
