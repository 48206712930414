import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import Skeleton from "react-loading-skeleton";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import StringInput from "../../../components/Input/StringInput/StringInput";
class QNABetListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        
        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("event_qna_bet.no"),
                dataField: "no",
                formatter: this.props.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("event_qna_bet.date"),
                dataField: "created_at",
                sort: true,
                onSort: this.props.onSort
            },
            {
                text: t("event_qna_bet.member"),
                dataField: "username",
                sort: false,
            },
            {
                text: t("event_qna_bet.bet_details"),
                dataField: "bet_data",
                formatter: (cell, row) => {
                    if (!!(row.bet_data)) {
                        let betDataJSON = JSON.parse(row.bet_data);
                        let betData = [];
    
                        Object.keys(betDataJSON).map((key) => {
                            let answer = '';
                            Object.keys(betDataJSON[key]).map((key2) => {
                                if (!!(answer)) {
                                    answer = answer + ', ' + t('world_cup.'+betDataJSON[key][key2].toUpperCase())
                                } else {
                                    answer = t('world_cup.'+betDataJSON[key][key2].toUpperCase())
                                }
                                return null;
                            });
                            
                            betData.push(
                                <><span>{key}. {answer}</span><br/></>
                            );
                            return null;
                        });
                        
                        return betData;
                    } else {
                        return null;
                    }
                },
                sort: false,
            }
        ];
        
        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                return {
                    no: idx+1,
                    created_at: val.created_at,
                    username: val.username,
                    bet_data: val.bet_data
                };
            });
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("event_qna_bet.qna_bet")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                            <div
                            className={`form-horizontal filter-function row
                            ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("event_qna_bet.date")}
                                        </label>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-calendar-check" />
                                            </span>
                                            <DateTimePicker
                                                id="date"
                                                name="date"
                                                className="form-control filter-input"
                                                range={true}
                                                timePicker={true}
                                                value={this.props.filter.date}
                                                onChange={this.props.onInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("event_qna_bet.member")}
                                        </label>
                                        <StringInput
                                            id="username"
                                            name="username"
                                            value={this.props.filter.username}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col mt-auto">
                                    <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="filter"
                                        buttonName={t("global.filter")}
                                        clicked={() =>
                                            this.props.refreshHandler()
                                        }
                                    />
                                    &nbsp;
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary"
                                        idValue="reset"
                                        buttonName={t("global.reset")}
                                        clicked={() =>
                                            this.props.clearHandler()
                                        }
                                    />
                                    </div>
                                </div>

                            </div>
                                    )}
                                </div>

                            <div className="show-btn"
                            onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("event_qna_bet.qna_bet_listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(QNABetListing);
