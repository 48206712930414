import axios from "../../../common/config/axios";
import * as actionType from './types';

export const getList = (props) => dispatch => 
{
    axios.get('rescue_bonus_setting/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LISTING,
            value: response.data
        });
    })
    .catch(err => 
    {
        
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('rescue_bonus_setting/drop_down')
    .then(function (response)
    {
        dispatch (
        {
            type: actionType.DROPDOWN,
            value: response.data
        });
    })
    .catch(err =>
    {

    });
};

export const store = (data) => (dispatch, getState) =>
{
    // console.log(data);
    data.country_id = getState().currentUser.data.country_id;
    axios.post('rescue_bonus_setting/store', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.STORE,
            value: response.data
        });
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (id) => dispatch =>
{   
    if(id > 0)
    {
        axios.get('rescue_bonus_setting/details',{
            params: {
                "id": id,
            }
        })
        .then(function (response) 
        {
            dispatch (
            {
                type: actionType.DETAILS,
                value: response.data
            });
        })
        .catch(function (error) 
        {
        
        });
    }
};

export const update = (data) => (dispatch, getState) =>
{
    // console.log(data);
    data.country_id = getState().currentUser.data.country_id;
    axios.post('rescue_bonus_setting/update', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.UPDATE,
            value: response.data
        });
    })
    .catch(err => 
    {
        
    });
};