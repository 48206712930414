import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faScroll, faCashRegister } from "@fortawesome/free-solid-svg-icons";
// import { AiFillPlusCircle } from "react-icons/ai";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import PaymentGatewayListing from "./PaymentGatewayListing";
// import PaymentTypeCreate from "./PaymentTypeCreate";
import PaymentTypeEdit from "./PaymentTypeEdit";
import { filterErrorHandler } from "../../hoc/Shared/utility";

import { getPaymentTypeList, updatePaymentTypeWithdrawalStatus, updatePaymentTypeDepositStatus } from "../../store/ducks/paymentGateway/actions";
import Select from "../../components/Input/Select/Select";
import PaymentTypeLedger from "./PaymentTypeLedger";

class PaymentGateway extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',

        data: [],
        dataPaymentGateway: [],

        currentPaymentTypeId: '',
        loading: true,

        permission: []
    }

    fetchData() {
        const paymentGatewayTypeData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }

        paymentGatewayTypeData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        paymentGatewayTypeData.limit = this.state.limit;
        paymentGatewayTypeData.order = JSON.stringify(this.state.sorted);
        paymentGatewayTypeData.country_id = this.props.currentCountryId;
        paymentGatewayTypeData.search = this.state.searchValue;
        paymentGatewayTypeData.transaction_option_id = this.props.show_payment_gateway_id;

        this.props.getPaymentTypeList(paymentGatewayTypeData);
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let paymentGatewayPermission = this.props.currentPermission.filter((item) => item.includes("payment_gateway_"));
            this.setState({ permission: paymentGatewayPermission });
        }

        if (nextProps.payment_type_data !== this.props.payment_type_data && nextProps.payment_type_data !== undefined) {
            if (nextProps.payment_type_data.responseCode === 200 && nextProps.payment_type_data.msgType === 'success') {
                this.setState({
                    loading: false,
                    data: nextProps.payment_type_data.data,
                    totalRecords: nextProps.payment_type_data.totalRecords,
                    totalPages: nextProps.payment_type_data.totalPages,
                })
            } else {
                this.setState({
                    totalPages: 0,
                    activePage: 1,
                    totalRecords: 0,
                    data: [],
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.payment_type_data.message);
                });
            }
        }

        // if (nextProps.delete_payment_gateway_result !== this.props.delete_payment_gateway_result) {
        //     if (nextProps.delete_payment_gateway_result.responseCode === 200) {
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_payment_gateway_result.message,
        //             icon: nextProps.delete_payment_gateway_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.fetchData();
        //             }
        //         });
        //     } else {
        //         this.setState({
        //             loading: false,
        //         }, () => 
        //         {
        //             filterErrorHandler(nextProps.delete_payment_gateway_result.message);
        //         });
        //     }
        // }

        if (nextProps.update_payment_type_deposit_status_result !== this.props.update_payment_type_deposit_status_result && nextProps.update_payment_type_deposit_status_result !== undefined) {
            if (nextProps.update_payment_type_deposit_status_result.responseCode === 200 && nextProps.update_payment_type_deposit_status_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_payment_type_deposit_status_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.update_payment_type_deposit_status_result.message);
                });
            }
        }

        if (nextProps.update_payment_type_withdrawal_status_result !== this.props.update_payment_type_withdrawal_status_result && nextProps.update_payment_type_withdrawal_status_result !== undefined) {
            if (nextProps.update_payment_type_withdrawal_status_result.responseCode === 200 && nextProps.update_payment_type_withdrawal_status_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_payment_type_withdrawal_status_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.update_payment_type_withdrawal_status_result.message);
                });
            }
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    updateStatusPaymentGatewayHandler = (event, paymentGatewayId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updatePaymentGatewayStatusData = {};
                updatePaymentGatewayStatusData.id = paymentGatewayId;
                updatePaymentGatewayStatusData.status = Number(event.target.value);

                this.props.updatePaymentGatewayStatus(updatePaymentGatewayStatusData);
            }
        });
    }

    updateDepositStatusHandler = (event, paymentGatewayId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updatePaymentGatewayStatusData = {};
                updatePaymentGatewayStatusData.id = paymentGatewayId;
                updatePaymentGatewayStatusData.status = Number(event.target.value);
                updatePaymentGatewayStatusData.country_id = this.props.currentCountryId;

                this.props.updatePaymentTypeDepositStatus(updatePaymentGatewayStatusData);
            }
        });
    }

    updateWithdrawalStatusHandler = (event, paymentGatewayId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updatePaymentGatewayStatusData = {};
                updatePaymentGatewayStatusData.id = paymentGatewayId;
                updatePaymentGatewayStatusData.status = Number(event.target.value);
                updatePaymentGatewayStatusData.country_id = this.props.currentCountryId;

                this.props.updatePaymentTypeWithdrawalStatus(updatePaymentGatewayStatusData);
            }
        });
    }

    // deletePaymentGatewayHandler = (e, payment_gateway_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deletePaymentGatewayData = {};
    //             deletePaymentGatewayData.id = payment_gateway_id;

    //             this.props.deletePaymentGateway(deletePaymentGatewayData);
    //         }
    //     });
    // }

    withdrawalStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("payment_gateway_change_status")) {


            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.withdrawalStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="withdrawalStatus"
                    name="withdrawalStatus"
                    options={statusArray}
                    defaultValue={row.withdrawalStatus}
                    key={`paymentTypeWithdrawalStatus-${row.paymentTypeId}`}
                    onChange={(event) => {
                        this.updateWithdrawalStatusHandler(event, row.paymentTypeId)
                    }}
                    className={dtClassName}
                />
            )
        } else {
            let badgeClassName, status;
            if (row.withdrawalStatus === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }


    depositStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("payment_gateway_change_status")) {


            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.depositStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="depositStatus"
                    name="depositStatus"
                    options={statusArray}
                    defaultValue={row.depositStatus}
                    key={`paymentTypeDepsotStatus-${row.paymentTypeId}`}
                    onChange={(event) => {
                        this.updateDepositStatusHandler(event, row.paymentTypeId)
                    }}
                    className={dtClassName}
                />
            )
        } else {
            let badgeClassName, status;
            if (row.depositStatus === 0 || null) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }

    changePageHandler = (page, paymentGatewayId) => {
        this.setState({
            pageShow: page,
            currentPaymentGatewayId: paymentGatewayId
        });
    }

    currentPage = () => {
        let page = "listing";
      
        this.setState({
            pageShow: page,
        });

    }

    refreshHandler = () => {
        this.setState({ loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    }

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    }

    bankNameFormatter = (cell, row) => {
        let bankNameSplit = row.bankName.split(',');
        return (<div>
            {bankNameSplit.map((item, index) => (
                <span className="badge badge-primary mr-2" key={`banks-name-${index}`}>{item}</span>
            ))}
        </div>);
    }

    categoryPaymentGatewayFormatter = (cell, row) => {
        let data = this.state.dataPaymentGateway;
        let displayData = [];

        data.forEach((items, idx) => {
            if (row.paymentGatewayId === items.transaction_option_id) {
                var temp = (<div key={idx}>{items.slug}</div>);
                displayData.push(temp);
            } else {
                return null;
            }
        })

        return displayData;
    }

    actionFormatter = (cell, row) => {
        let editButton = '';
        let ledgerButton = '';
        let output = [];

        if (this.state.permission.includes("payment_gateway_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.paymentTypeId}`}
                    clicked={() => this.changePageHandler("edit", row.paymentTypeId)}
                />
            );
        }

        const showLedgerID = [2, 3, 4]; // quickpay & e-wallet
        if (this.state.permission.includes("payment_gateway_ledger") && (showLedgerID.includes(this.props.show_payment_gateway_id) || row.paymentSlug === 'secret_pay_offline')) {
            ledgerButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-warning"
                    buttonName={<FontAwesomeIcon icon={faScroll} />}
                    key={`edit-${row.paymentTypeId}`}
                    clicked={() => this.changePageHandler("show", row.paymentTypeId)}
                />
            );
        }

        output = [editButton, ledgerButton];
        return output;
    };


    render() {
        const { t } = this.props;
        let columns = [];
        if(this.props.show_payment_gateway_name === "Crypto"){
             columns = [
                {
                    text: t('global.no'),
                    dataField: "",
                    keyField: "no",
                    formatter: (cell, row, rowIndex) => {
                        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit));
                        return <span>{rowNumber}</span>;
                    },
                    headerStyle: () => {
                        return { width: '40px' };
                    }
                },
                {
                    text: "Payment Type Id",
                    dataField: "paymentTypeId",
                    keyField: 'paymentTypeId',
                    hidden: true
                },
                {
                    text: t('payment-gateway.payment-type-name'),
                    dataField: "paymentTypeName",
                    keyField: "paymentTypeName",
                    sort: true,
                    onSort: this.onSort,
                },
                {
                    text: t('payment-gateway.sequence'),
                    dataField: "sequence",
                    keyField: "sequence"
                },
                {
                    text: t('payment-gateway.fees'),
                    dataField: "fees",
                    keyField: "fees"
                },
                {
                    text: t('payment-gateway.minimum-payment-amount'),
                    dataField: "minimumPaymentAmt",
                    keyField: "minimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.transaction-minimum-payment-amount'),
                    dataField: "transactionMinimumPaymentAmt",
                    keyField: "transactionMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.transaction-maximum-payment-amount'),
                    dataField: "transactionMaximumPaymentAmt",
                    keyField: "transactionMaximumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-fees'),
                    dataField: "withdrawalFees",
                    keyField: "withdrawalFees"
                },
                {
                    text: t('payment-gateway.withdrawal-minimum-payment-amount'),
                    dataField: "withdrawalMinimumPaymentAmt",
                    keyField: "withdrawalMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-transaction-minimum-payment-amount'),
                    dataField: "withdrawalTransactionMinimumPaymentAmt",
                    keyField: "withdrawalTransactionMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-transaction-maximum-payment-amount'),
                    dataField: "withdrawalTransactionMaximumPaymentAmt",
                    keyField: "withdrawalTransactionMaximumPaymentAmt"
                },
                // {
                //     text: t('payment-gateway.amount'),
                //     dataField: "totalAmount",
                //     keyField: "totalAmount"
                // },
                {
                    text: t('payment-gateway.transaction-fees'),
                    dataField: "totalFees",
                    keyField: "totalFees"
                },
                {
                    text: t('payment-gateway.usdt-balance'),
                    dataField: "totalUSDTAmount",
                    keyField: "totalUSDTAmount"
                },
                {
                    text: t('payment-gateway.usdt-fees'),
                    dataField: "cryptoFees",
                    keyField: "cryptoFees"
                },
                {
                    text: t('payment-gateway.crypto-balance'),
                    dataField: "balance",
                    keyField: "balance"
                },
                {
                    text: t('payment-gateway.deposit-est-time'),
                    dataField: "deposit_est_time",
                    keyField: "deposit_est_time"
                },
                {
                    text: t('payment-gateway.withdrawal-est-time'),
                    dataField: "withdrawal_est_time",
                    keyField: "withdrawal_est_time"
                },
                {
                    text: t('payment-gateway.deposit-status'),
                    dataField: "depositStatus",
                    keyField: "depositStatus",
                    formatter: this.depositStatusFormatter,
                    headerStyle: { width: "90px" },
                },
                {
                    text: t('payment-gateway.withdrawal-status'),
                    dataField: "withdrawalStatus",
                    keyField: "withdrawalStatus",
                    formatter: this.withdrawalStatusFormatter,
                    headerStyle: { width: "90px" },
                },
                {
                    text: t("global.action"),
                    dataField: "#",
                    keyField: "action",
                    formatter: this.actionFormatter,
                    headerStyle: { width: "115px" },
                }]
        }else{
             columns = [
                {
                    text: t('global.no'),
                    dataField: "",
                    keyField: "no",
                    formatter: (cell, row, rowIndex) => {
                        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit));
                        return <span>{rowNumber}</span>;
                    },
                    headerStyle: () => {
                        return { width: '40px' };
                    }
                },
                {
                    text: "Payment Type Id",
                    dataField: "paymentTypeId",
                    keyField: 'paymentTypeId',
                    hidden: true
                },
                {
                    text: t('payment-gateway.payment-type-name'),
                    dataField: "paymentTypeName",
                    keyField: "paymentTypeName",
                    sort: true,
                    onSort: this.onSort,
                },
                {
                    text: t('payment-gateway.sequence'),
                    dataField: "sequence",
                    keyField: "sequence"
                },
                {
                    text: t('payment-gateway.fees'),
                    dataField: "fees",
                    keyField: "fees"
                },
                {
                    text: t('payment-gateway.minimum-payment-amount'),
                    dataField: "minimumPaymentAmt",
                    keyField: "minimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.transaction-minimum-payment-amount'),
                    dataField: "transactionMinimumPaymentAmt",
                    keyField: "transactionMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.transaction-maximum-payment-amount'),
                    dataField: "transactionMaximumPaymentAmt",
                    keyField: "transactionMaximumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-fees'),
                    dataField: "withdrawalFees",
                    keyField: "withdrawalFees"
                },
                {
                    text: t('payment-gateway.withdrawal-minimum-payment-amount'),
                    dataField: "withdrawalMinimumPaymentAmt",
                    keyField: "withdrawalMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-transaction-minimum-payment-amount'),
                    dataField: "withdrawalTransactionMinimumPaymentAmt",
                    keyField: "withdrawalTransactionMinimumPaymentAmt"
                },
                {
                    text: t('payment-gateway.withdrawal-transaction-maximum-payment-amount'),
                    dataField: "withdrawalTransactionMaximumPaymentAmt",
                    keyField: "withdrawalTransactionMaximumPaymentAmt"
                },
                // {
                //     text: t('payment-gateway.amount'),
                //     dataField: "totalAmount",
                //     keyField: "totalAmount"
                // },
                {
                    text: t('payment-gateway.transaction-fees'),
                    dataField: "totalFees",
                    keyField: "totalFees"
                },
                {
                    text: t('payment-gateway.balance'),
                    dataField: "balance",
                    keyField: "balance"
                },
                {
                    text: t('payment-gateway.deposit-est-time'),
                    dataField: "deposit_est_time",
                    keyField: "deposit_est_time"
                },
                {
                    text: t('payment-gateway.withdrawal-est-time'),
                    dataField: "withdrawal_est_time",
                    keyField: "withdrawal_est_time"
                },
                {
                    text: t('payment-gateway.deposit-status'),
                    dataField: "depositStatus",
                    keyField: "depositStatus",
                    formatter: this.depositStatusFormatter,
                    headerStyle: { width: "90px" },
                },
                {
                    text: t('payment-gateway.withdrawal-status'),
                    dataField: "withdrawalStatus",
                    keyField: "withdrawalStatus",
                    formatter: this.withdrawalStatusFormatter,
                    headerStyle: { width: "90px" },
                },
                {
                    text: t("global.action"),
                    dataField: "#",
                    keyField: "action",
                    formatter: this.actionFormatter,
                    headerStyle: { width: "115px" },
                }]
        }

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <div>
                        <div className="breadcrumb-header">
                            {/* <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('payment-gateway.payment-type')}</h4>
                            </div>
                        </div> */}
                            <div className="d-flex">
                                <div className="my-auto">
                                    <div className="btn-group">
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-back-outline"
                                            idValue="back"
                                            buttonName={<i className="angle fe fe-chevron-left"></i>}
                                            clicked={() => this.props.changePageHandler("listing")}
                                        />
                                        <div>
                                            <div className='member-name'>
                                                <FontAwesomeIcon icon={faCashRegister} /> {this.props.show_payment_gateway_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <PaymentGatewayListing
                            filterShow={false}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            searchValue={this.state.searchValue}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            searchChangeHandler={this.searchChangeHandler}
                            postSearchHandler={this.postSearchHandler}
                            refreshHandler={() => this.refreshHandler()}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            payment_type={true}
                        />
                    </div>
                }


                {/* {this.state.pageShow === 'create' &&
                    <PaymentTypeCreate
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        payment_gateway_id={this.props.show_payment_gateway_id}
                    />
                } */}

                {this.state.pageShow === 'edit' &&
                    <PaymentTypeEdit
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        show_payment_type_id={this.state.currentPaymentGatewayId}
                    />
                }

                {this.state.pageShow === 'show' &&
                    <PaymentTypeLedger
                        changePageHandler={(page) => this.changePageHandler(page)}
                        currentPage={() => this.currentPage()}
                        transaction_channel_id={this.props.show_payment_gateway_id}
                        payment_gateway_id={this.state.currentPaymentGatewayId}
                        permission={this.state.permission}
                        {...this.state}
                        {...this.props}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const { paymentGateway, currentUser } = state;
    return {
        payment_type_data: paymentGateway.payment_type_data,
        // delete_payment_gateway_result: paymentGateway.delete_payment_gateway_result,
        update_payment_type_deposit_status_result: paymentGateway.update_payment_type_deposit_status_result,
        update_payment_type_withdrawal_status_result: paymentGateway.update_payment_type_withdrawal_status_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission
    }
};

const mapDispatchToProps = {
    getPaymentTypeList,
    // deletePaymentGateway,
    updatePaymentTypeWithdrawalStatus,
    updatePaymentTypeDepositStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PaymentGateway));