import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import UpdownArrow from "../../assets/images/updown-arrow.png";


class WhitelistIPListing extends Component {
	/* ============ component lifecycle [START] ============ */
	constructor(props) {
		super(props);
		this.state = {
			showCard: false,
		};
	}
	/* ============ component lifecycle [END] ============ */

	/* ============ function [START] ============ */
	noFormatter = (cell, row, rowIndex) => {
		let rowNumber =
			//(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
			(this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
		return <span>{rowNumber}</span>;
	};

	toggleCard = () => {
		this.setState({
			showCard: !this.state.showCard,
		});
	};
	/* ============ function [END] ============ */

	render() {
		const { t } = this.props;
		let rsListing = [];
		let permission = this.props.currentUser.permission
			? this.props.currentUser.permission
			: [];

		/* ======================== datatables data assign [START] ======================== */
		const columns = [
			{
				text: t("global.no"),
				dataField: "no",
				formatter: this.noFormatter,
				headerStyle: { width: "60px", textAlign: "center" },
			},
			{
				text: t("ip.ip-address"),
				dataField: "ip",
				sort: true,
				onSort: this.props.onSort,
			},
			{
				text: t("global.status"),
				dataField: "status",
			},
			{ text: t("global.action"), dataField: "action", headerStyle: { width: "115px" }, },
		];

		if (
			!permission.includes("whitelist_ip_update") &&
			!permission.includes("whitelist_ip_delete")
		) {
			columns.splice(3, 1);
		}

		if (this.props.data) {
			rsListing = this.props.data.map((val, idx) => {
				let actionVal = permission.includes("whitelist_ip_update") ? (
					<div>
						<a
							href="/#"
							onClick={(event) =>
								this.props.detailsHandler(event, 'edit', val)
							}
							className="btn btn-sm btn-white"
							data-toggle="tooltip"
							title="edit"
						>
							<FontAwesomeIcon icon={faPen} />
						</a>
						<a
							href="/#"
							onClick={(event) =>
								this.props.deleteHandler(event, val.ip)
							}
							className="btn btn-sm btn-white delete"
							data-toggle="tooltip"
							title="delete"
						>
							<FontAwesomeIcon icon={faTrash} />
						</a>
					</div>
				) : (
					""
				);

				return {
					no: idx,
					ip: val.ip,
					status: val.status[0].toUpperCase() + val.status.slice(1),
					action: actionVal
				};
			});
		}
		/* ======================== datatables data assign [END] ======================== */

		return (
			<React.Fragment>
				<div className="breadcrumb-header">
					<div className="my-auto">
						<div className="d-flex">
							<h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
								{t("whitelist-ip.title")}
							</h4>
						</div>
					</div>
				</div>
				<div className="row row-sm">
					<div className="col-xl-12">
						<div className="card card-body card-primary">
							<div className="">
								{this.props.loading ? (
									<Skeleton count={2} />
								) : (

									<div
										className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
											} filter-wrapper-2`}
									>
										<div className="col-md-4 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("ip.ip-address")}
												</label>
												<StringInput
													id="ip"
													name="ip"
													value={this.props.filter.ip}
													onChange={this.props.onInputChange}
													className="filter-input"
													onSearchPress={(evt) =>
														evt.key === "Enter" &&
														this.props.refreshHandler()
													}
												/>
											</div>
										</div>
										<div className="col-md-4 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("global.status")}
												</label>
												<div className="select-input">
													<select
														className="form-control filter-input"
														id="status"
														name="status"
														onChange={this.props.onInputChange}
														value={this.props.filter.status}
													>
														<option value="" selected></option>
														<option value="allowed">{t('whitelist-ip.allowed')}</option>
														<option value="banned">{t('whitelist-ip.banned')}</option>
													</select>
													<img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
												</div>

											</div>
										</div>

										<div className="col mt-auto">
											<div>
												<Button
													typeButton="button"
													classValue="btn btn-blue"
													idValue="filter"
													buttonName={t("global.filter")}
													clicked={() =>
														this.props.refreshHandler()
													}
												/>
												<Button
													typeButton="button"
													classValue="btn btn-secondary"
													idValue="reset"
													buttonName={t("global.reset")}
													clicked={() =>
														this.props.clearHandler()
													}
												/>
											</div>
										</div>
									</div>
								)}
							</div>

							<div
								className="show-btn"
								onClick={() => this.toggleCard()}
							>
								{this.state.showCard ? (
									<AiFillMinusCircle />
								) : (
									<AiFillPlusCircle />
								)}
							</div>
						</div>
						<div className="card">
							<div className="d-flex align-items-end">
								<div className="card-header pb-0">
									<div className="d-flex justify-content-between">
										<h4 className="card-title mg-b-0">
											{t("whitelist-ip.listing")}
										</h4>
									</div>
								</div>
								<div className="d-flex">
									<div>
										<div className="btn-group">
											{permission.includes(
												"whitelist_ip_create"
											) && (
													<>
														<AiFillPlusCircle />
														<Button
															typeButton="button"
															classValue="btn btn-underline"
															idValue="create"
															buttonName={t(
																"role.whitelist_ip_create"
															)}
															clicked={() =>
																this.props.changePageHandler(
																	"create"
																)
															}
														/>

													</>
												)}
										</div>
									</div>
								</div>
							</div>

							<div className="card-body">
								<div className="table-responsive table-hover datatable">
									<DataTable
										keyField="id"
										data={rsListing}
										columns={columns}
										activePage={this.props.activePage}
										limit={this.props.limit}
										totalItems={this.props.totalRecords}
										paginationHandler={(val) =>
											this.props.datatablesChangeHandler(
												val,
												"activePage"
											)
										}
										sizePerPageHandler={(val) =>
											this.props.datatablesChangeHandler(
												val,
												"limit"
											)
										}
										searchChangeHandler={
											this.props.onInputChange
										}
										searchValue={this.props.searchValue}
										classValue="dataTable table-bordered text-justify text-center"
										loading={this.props.loading}
										searchPressSubmitHandler={
											this.props.searchKeyPressHandler
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withNamespaces('translation')(WhitelistIPListing);
