import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {withNamespaces} from 'react-i18next';

import AdminsListing from "./AdminsListing";
import AdminsCreate from "./AdminsCreate";
import AdminsEdit from "./AdminsEdit";
import AdminsShow from "./AdminsShow";
import Button from "../../components/Button/Button";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { filterErrorHandler } from '../../hoc/Shared/utility';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen} from '@fortawesome/free-solid-svg-icons'

import {getDropDown, deleteAdmin, getAdmins ,updateAdminStatus} from "../../store/ducks/admins/actions";
import Select from "../../components/Input/Select/Select";

const initialInputState =
{
    /* form input value */
    username: '',
    group_id: ''
};

let adminData = {};
class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageShow: (this.props.location.state === undefined) ? "listing" : this.props.location.state.pageShow,

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,

            loading: true,

            data: [],
            
            dropDown: [],

            filter: initialInputState,

            isOpen: false,
            currentAdminId: '',

            permission: []

        }
    }

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        adminData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        adminData.limit = this.state.limit;
        adminData.order = JSON.stringify(this.state.order);
        adminData.username = this.state.filter.username
        adminData.group_id = this.state.filter.group_id
        adminData.lang_id = this.props.currentLanguageId;
        adminData.country_id = this.props.currentCountryId;

        this.props.getAdmins(adminData);
        this.props.getDropDown()
    }

    componentDidMount() {
        if(this.state.pageShow!=='show')
        {
            this.fetchData();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let adminPermission = this.props.currentPermission.filter((item) => item.includes("admin_"));
            this.setState({permission: this.props.currentPermission});
        }

        if (nextProps.admin_data !== this.props.admin_data && nextProps.admin_data !== undefined) {
            if (nextProps.admin_data.data.responseCode === 200 && nextProps.admin_data.data.msgType === 'success') {
                this.setState({
                    loading: false,
                    data: nextProps.admin_data.data.data,
                    totalRecords: nextProps.admin_data.data.totalRecords,
                    totalPages: nextProps.admin_data.data.totalPages,
                })
            }
            else {
                this.setState({
                    loading: false,
                    data: [],
                    totalRecords: 0,
                    totalPages: 0,
                    activePage: 1
                }, () => {
                    filterErrorHandler(nextProps.admin_data.data.message);
                });
            }
        }

        if(nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
          if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
            this.setState({
                dropDown: nextProps.dropDown.data,
            })
          } else {
            this.setState({loading: false}, () => {
                filterErrorHandler(nextProps.dropDown.message);
            });
          }
        }

        // if (nextProps.delete_admin_result !== this.props.delete_admin_result) {
        //     const { t } = this.props;
        //     Swal.fire({
        //         title: t("global.record-deleted"),
        //         icon: nextProps.delete_admin_result.msgType,
        //         confirmButtonText: t("global.ok"),
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.fetchData();
        //         }
        //     });
        // }

        if (nextProps.update_status_admin_result !== this.props.update_status_admin_result && nextProps.update_status_admin_result !== undefined) {
            if (nextProps.update_status_admin_result.responseCode === 200 && nextProps.update_status_admin_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_status_admin_result.message[0],
                    icon: nextProps.update_status_admin_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
                this.fetchData();
            }
            else {
                filterErrorHandler(nextProps.update_status_admin_result.message);
            }
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({order: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    statusFormatter = (cell, row) => {

        const { t } = this.props;
        if (this.state.permission.includes("admin_update")) {

            let statusArray, dtClassName;

        statusArray = [
            {
                label: t("global.active"),
                value: 1,
                selected: true,
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];
        if (row.status === 0) {
            dtClassName = "form-control datatable-select-inactive";
        } else {
            dtClassName = "form-control datatable-select-active";
        }

        return (
            <Select
                id="adminStatus"
                name="adminStatus"
                options={statusArray}
                defaultValue={row.status}
                key={`status-${row.Id}`}
                onChange={(event) => {
                    this.updateStatusAdminHandler(event, row.Id)
                }}
                className={dtClassName}
            />
        )
    }else{
        let badgeClassName, status;
        if (row.status === 0) {
            badgeClassName = "badge badge-pill badge-danger";
            status = t("global.inactive");
        } else {
            badgeClassName = "badge badge-pill badge-success";
            status = t("global.active");
        }
        return (
            <span className={badgeClassName}>{status}</span>
        )
    }
}


    updateStatusAdminHandler = (event, Id) => {
        event.preventDefault();
        Swal.fire({
            title: '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        });

        const updateAdminStatusData = {};
        updateAdminStatusData.id = Id;
        updateAdminStatusData.status = Number(event.target.value);

        this.props.updateAdminStatus(updateAdminStatusData);
}

    // deleteAdminHandler = (e, admin_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deleteadminData = {};
    //             deleteadminData.id = admin_id;

    //             this.props.deleteAdmin(deleteadminData);
    //         }
    //     });
    // }


    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;

        if (this.state.permission.includes("admin_show")) {
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.Id}`}
                clicked={() => this.changePageHandler('show', row.Id)}
            />;
        }

        if (this.state.permission.includes("admin_update")) {
        editButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                buttonName={<FontAwesomeIcon icon={faPen}/>}
                key={`edit-${row.Id}`}
                clicked={() => this.changePageHandler('edit', row.Id)}
            />;
        }

        // if (this.state.permission.includes("admin_delete")) {
        // deleteButton =
        //     <Button
        //         typeButton="button"
        //         classValue="btn btn-sm btn-danger mr-2"
        //         clicked={(event) => this.deleteAdminHandler(event, row.Id)}
        //         key={`delete-${row.Id}`}
        //         buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //     />;
        // }
        output = [viewButton, editButton];
        return output;
    }

    onInputChange(event)
    {
      let newFilter = Object.assign({}, this.state.filter);
      newFilter[event.target.id] = event.target.value;

      this.setState({
        filter: newFilter
      })
    }

    refreshHandler = () => 
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        }, ()=>
        {
            this.fetchData();
        });
    }

    clearHandler = () => 
    {
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            filter: initialInputState,
            loading: true,
            data: [],
        }, ()=>
        
        {
          this.fetchData();
        });
    }

    changePageHandler = (page, Id) => {
        this.setState({
            pageShow: page,
            currentAdminId: Id
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit))
        return <span>{rowNumber}</span>;
    };

    

    render() {
        const {t} = this.props;
        let rsListing = [];
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: this.noFormatter,
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: "Id",
                dataField: "Id",
                keyField: "Admin_Id",
                hidden: true,
            },

            {
                text: t('admin.username'),
                keyField: "Admin_username",
                dataField: "username",
                sort: true,
                onSort: this.onSort
            },

            

            // {
            //     text: t('admin.email'),
            //     keyField: "Admin_email",
            //     dataField: "email",
            //     sort: true,
            //     onSort: this.onSort
            // },

            {
                text: t('admin.group'),
                keyField: "Admin_group_id",
                dataField: "group_id",
                sort: true,
                onSort: this.onSort
            },

            {
                text: t('global.status'),
                dataField: "status",
                keyField: "Status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return {width: '115px'};
                }
            },

            {
                text: t('global.action'),
                keyField: "action",
                dataField: "#",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '115px'};
                }
            },
        ];

        if (this.state.data) {
          rsListing = this.state.data.map((val, idx) => {
              
              return {
                  no: idx,
                  Id: val.Id,
                  username: val.username,
                  group_id: val.group_id,
                  status: val.status,
                  action: this.actionFormatter
              };
          });
      }

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    <div className="breadcrumb-header">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('admin.admin')}</h4>
                            </div>
                        </div>
                    </div>
                    <AdminsListing
                        username={this.state.filter.username}
                        group_id={this.state.filter.group_id}
                        onInputChange={(event) => this.onInputChange(event)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        data={rsListing}
                        order={this.state.order}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}

                        /**filter drop down*/
                        ddRole={this.state.dropDown.roles}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <AdminsCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <AdminsShow
                    show_admin_id={this.state.currentAdminId || this.props.location.state.show_admin_id}
                    profile={this.props.location.state && this.props.location.state.show_admin_id ? true : false}
                    changePageHandler={(page, id) => this.changePageHandler(page, id)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <AdminsEdit
                    show_admin_id={this.state.currentAdminId}
                    refreshHandler={() => this.refreshHandler()}
                    profile={this.props.location.state && this.props.location.state.show_admin_id ? true : false}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {admin,currentUser} = state;

    return {
        admin_data: admin.admin_data,
        update_status_admin_result: admin.update_status_admin_result,
        delete_admin_result: admin.delete_admin_result,
        currentPermission: currentUser.data.permission,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        dropDown: admin.dropDown
    }
};

const mapDispatchToProps = {
    updateAdminStatus,
    getDropDown,
    getAdmins,
    deleteAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Admin));