import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import MemberStakingsListing from './MemberStakingsListing';
import MemberStakingsHistory from './MemberStakingsHistory';
import MemberStakingsDetails from './MemberStakingsDetails';
// import MemberRebateRolloverDetails from './MemberRebateRolloverDetails';
import TransactionDetails from '../Transaction/TransactionDetails';
import {clearFormValidation} from '../../hoc/Shared/utility';

// import * as actions from '../../store/ducks/memberRebate/actions';
import * as actions from '../../store/ducks/memberStaking/actions';

const initialTabState =
{
    /* datatables / pagination */
    activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
    limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    order: '',
    totalRecords: 0,
    searchValue: '',

    /* api result array */
    data: [],

    /* filter input */
    filterInput:
    {
        date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        date_range: moment().subtract(7, 'days').format('YYYY-MM-DD')+' - '+moment().subtract(1, 'days').format('YYYY-MM-DD'),
        username: '',
        member_group: '',
        confirm_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },

    /* form input */
    formInput: {},

    /* skeleton loading */
    loading: true,

    /* validation error */
    errMsg: [],
    touched: false,
};

class MemberStakings extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'calc_stakings',

            /* both tab value */
            tab1: initialTabState,
            tab2: initialTabState,

            /* both tab drop down value */
            dropDown: {
                memberGroup: [],
            },

            /* transction details parameter */
            detailsParam: {},


            /* to store rollover detail page param, mid, date, id */
            stakingsParam: {},

            /* checkbox check all */
            checkAll: false,
        }
        // console.log('constructor :', this.state);
        this.onInputChange = this.onInputChange.bind(this);
        this.checkAllHandler = this.checkAllHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount()
    {
        let listingArr = this.getFilterArray('tab1'); // get params
        this.props.funcGetList(listingArr); // get calculate rebate list
        this.props.funcGetDropDown(); // get drop down select
        listingArr = this.getFilterArray('tab2');
        this.props.funcGetHistory(listingArr); // get stakings history
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;
        // console.log('shouldComponentUpdate :', nextProps.dropDown);
        /* ====================== drop down list for both tab [START] ====================== */
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown: {
                        memberGroup: nextProps.dropDown.data.member_group,
                    }
                });
            }
            // Swal.close();
        }
        /* ====================== drop down list for both tab [END] ====================== */

        /* ====================== tab1 (calculate rebate) [START] ====================== */
        if (nextProps.member_staking_data !== this.props.member_staking_data && nextProps.member_staking_data!==undefined)
        {
            if(nextProps.member_staking_data.data.responseCode === 200 && nextProps.member_staking_data.data.msgType === 'success')
            {
                // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
                let newTab = Object.assign({}, this.state.tab1);
                newTab['data'] = nextProps.member_staking_data.data.data.result.row;
                newTab['totalRecords'] = nextProps.member_staking_data.data.data.result.pagination.total_record;
                newTab['loading'] = false;
                nextProps.member_staking_data.data.data.result.row.forEach((val, idx) =>
                {
                    if(val.total_payout > 0 && val.total_member_balance > 0)
                    {
                        let stakingsAmountVal = (val.total_payout > 0) ? val.total_payout : 0;
                        // newTab['formInput'][`rebate_tbg_${val.mid}`] = new Intl.NumberFormat('en-US', num_format).format(rebateAmountVal);
                        newTab['formInput'][`stakings_tbg_${val.member_id}`] = parseFloat(stakingsAmountVal).toFixed(2);
                        newTab['formInput'][`cb_${val.member_id}`] = false;
                        newTab['formInput'][`remark_${val.member_id}`] = '';
                        newTab['formInput'][`stakings_lrd_${val.member_id}`] = val.created_at;
                    }
                });
                newTab.filterInput.confirm_date = newTab.filterInput.date;
                
                this.setState({
                    tab1: newTab
                });
                // Swal.close();
            }
            else
            {
                let newTab = Object.assign({}, this.state.tab1);
                newTab['loading'] = false;
                this.setState({
                    tab1: newTab
                });
                // this.filterErrorHandler(nextProps.member_staking_data.message);
            }
        }
        /* ====================== tab1 (calculate rebate) [END] ====================== */

        /* ====================== tab2 (rebate history) [START] ====================== */
        if (nextProps.history !== this.props.history && nextProps.history!==undefined)
        {
            if(nextProps.history.responseCode === 200 && nextProps.history.msgType === 'success')
            {
                // console.log('shouldComponentUpdate :', nextProps.history.data);
                let newTab = Object.assign({}, this.state.tab2);
                newTab['data'] = nextProps.history.data.result.row;
                newTab['totalRecords'] = nextProps.history.data.result.pagination.total_record;
                newTab['loading'] = false;
                // console.log(newTab);
                this.setState({
                    tab2: newTab,
                });
                // Swal.close();
            }
            else
            {
                let newTab = Object.assign({}, this.state.tab2);
                newTab['loading'] = false;
                this.setState({
                    tab2: newTab
                });
                this.filterErrorHandler(nextProps.rs.message);
            }
        }
        /* ====================== tab2 (rebate history) [END] ====================== */

        /* ====================== submit rebate [START] ====================== */
        if (nextProps.store !== this.props.store && nextProps.store!==undefined)
        {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if(nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler('tab1');
                    clearFormValidation('member_stakings_form');
                });
            }
            else
            {
                Swal.close();
            }
            let newTab = Object.assign({}, this.state.tab1);
            newTab['formInput'] = {};
            this.setState({
                tab1: newTab,
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.store.message
            });
        }
        /* ====================== submit rebate [END] ====================== */

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event, tab, inputType) =>
    {
        // console.log(event.target.id+' | '+event.target.value+ ' | '+ tab+' | '+inputType);
        let newTab = Object.assign({}, this.state[tab]);
        let newFilter = Object.assign({}, this.state[tab][inputType]);

        if(event.target.id.includes('cb_'))
        {
            let checkVal = (event.target.checked) ? true : false;
            newFilter[event.target.id] = checkVal;
        }
        else
        {
            newFilter[event.target.id] = event.target.value;
        }

        if(event.target.id.includes('stakings_tbg_'))
        {
            const splitedValue = event.target.value.split('.')
            if(splitedValue[1] && splitedValue[1].length > 2)
            {
                let val = parseFloat(event.target.value).toFixed(2);
                newFilter[event.target.id] = val;
            }
        }
        newTab[inputType] = newFilter;
        this.setState({
            [tab]: newTab
        });
    }

    changeTabHandler = (e) =>
    {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            pageShow: e.target.id
        });
    }

    searchHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        // newFilter['activePage'] = 1;
        newFilter['formInput'] = {};
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    clearHandler = (tab) =>
    {
        this.setState({
            [tab]: initialTabState,
            checkAll: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });
    }

    filterErrorHandler = (message) =>
    {
        const { t } = this.props;
        let msg = null;
        msg = '<ul>';
        message.forEach((val) => {
            msg += '<li>'+val+'</li>';
        });
        msg += '</ul>';

        Swal.fire({
            icon: 'error',
            title: t('global.error'),
            html: msg,
            showConfirmButton: true
        });
    }

    stakingPayoutDetailsHandler = (e, mid, date, id) =>
    {
        // console.log('turnoverDetailsHandler :'+mid+' | '+date+' | '+id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'stakingsDetails',
            stakingsParam :
            {
                mid: mid,
                date: moment(date).format('YYYY-MM-DD'), 
                id: id,
                returnPage: (id > 0) ? 'stakings_history' : 'calc_stakings'
            }
        });
    }

    orderDetailsHandler = (e, id) =>
    {
        // console.log('detailsHandler : ', id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    refreshHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['formInput'] = {};
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    getFilterArray = (tab) =>
    {
        // this.loadingHandler();
        const listingArr = {};

        let availablePage = this.state[tab].activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state[tab].activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state[tab].activePage >= availablePage) ? availablePage : this.state[tab].activePage;

        listingArr.page = this.state[tab].activePage;
        listingArr.limit = this.state[tab].limit;
        listingArr.search = this.state[tab].searchValue;
        listingArr.order = JSON.stringify(this.state[tab].order);
        listingArr.username = this.state[tab].filterInput.username;
        listingArr.member_group = this.state[tab].filterInput.member_group;
        listingArr.date = this.state[tab].filterInput.date;
        listingArr.date_range = this.state[tab].filterInput.date_range;
        listingArr.country_id = this.props.currentUser.country_id;
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            checkAll: false,
        });
    }

    checkAllHandler = (event, tab, inputType) =>
    {
        if(this.props.currentUser.permission.includes('member_stakings_submit'))
        {
            let checkVal = (event.target.checked) ? true : false;
            let newTab = Object.assign({}, this.state[tab]);
            let newFilter = Object.assign({}, this.state[tab][inputType]);
            // console.log(newFilter);
            for (const key in newFilter)
            {
                // console.log(key);
                if(key.includes('cb_'))
                {
                    var myEle = document.getElementById(key);
                    // console.log(myEle);
                    newFilter[key] = false;
                    if(myEle)
                    {
                        newFilter[key] = checkVal;
                    }
                }
            }
            newTab[inputType] = newFilter;
            this.setState({
                [tab]: newTab,
                checkAll: checkVal,
            });
        }
    }

    submitHandler = (e, tab) =>
    {
        e.preventDefault();
        let newFilter = Object.assign({}, this.state[tab].formInput);

        let data = {};
        let length = 0;

        for (const key in newFilter)
        {
            if(key.includes('cb_'))
            {
                if(newFilter[key]===true)
                {
                    let mid = key.split('_');
                    data[`stakings_tbg_${mid[1]}`] = newFilter[`stakings_tbg_${mid[1]}`];
                    data[`remark_${mid[1]}`] = newFilter[`remark_${mid[1]}`];
                    data[`stakings_lrd_${mid[1]}`] = newFilter[`stakings_lrd_${mid[1]}`];
                    length++;
                }
            }
        }
        if (length > 0)
        {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You wont be able to revert this!',
                icon: 'warning',
                confirmButtonText: 'Save',
                showDenyButton: true,
                denyButtonText: 'Cancel',
            }).then((result) =>
            {
                if (result.isConfirmed)
                {
                    this.loadingHandler();
                    data['country_id'] = this.props.currentUser.country_id;
                    data['date'] = this.state[tab].filterInput.confirm_date;
                    this.props.funcStore(data);
                }
            });
        }
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, tab, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+tab+' | '+act);
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter[act] = (act==='searchValue') ? val.target.value : val;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }

    onSort = (field, order, tab) =>
    {
        // console.log(field+' | '+order+' | '+tab);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['order'] = orderArry;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            checkAll: false,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetHistory(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        // console.log(this.state);
        const { t } = this.props;
        let tabId = this.state.pageShow;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                {(this.state.pageShow==='calc_stakings' || this.state.pageShow==='stakings_history') &&
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='panel panel-primary tabs-style-2 mt-3'>
                                    <div className='tab-menu-heading'>
                                        <div className='tabs-menu1'>
                                            <ul className='nav panel-tabs main-nav-line'>
                                                <li>
                                                    <a href='/#' id='calc_stakings' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'calc_stakings') ? 'active' : ''}`}>{t('member-stakings.calculate-stakings')}</a></li>
                                                {permission.includes('member_stakings_history') && <li><a href='/#' id='stakings_history' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'stakings_history') ? 'active' : ''}`}>{t('member-stakings.stakings-history')}</a></li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>
                }

                {this.state.pageShow==='calc_stakings' &&
                    <MemberStakingsListing
                        tabName='tab1'
                        data={this.state.tab1}
                        checkAll={this.state.checkAll}
                        ddMemberGroup={this.state.dropDown.memberGroup}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        checkAllHandler={(event, tab, inputType) => this.checkAllHandler(event, tab, inputType)}
                        submitHandler={(event, tab) => this.submitHandler(event, tab)}
                        stakingPayoutDetailsHandler={(event, mid, date, id) => this.stakingPayoutDetailsHandler(event, mid, date, id)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        touched={this.state.touched}
                        errMsg={this.state.errMsg}
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='stakings_history' &&
                    <MemberStakingsHistory
                        tabName='tab2'
                        data={this.state.tab2}
                        ddMemberGroup={this.state.dropDown.memberGroup}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        stakingPayoutDetailsHandler={(event, mid, date, id) => this.stakingPayoutDetailsHandler(event, mid, date, id)}
                        orderDetailsHandler={(event, id) => this.orderDetailsHandler(event, id)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='stakings_history'
                    />
                }

                {this.state.pageShow==='stakingsDetails' &&
                    <MemberStakingsDetails
                        stakingsParam={this.state.stakingsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        dropDown: state.memberStakings.dropDown,
        member_staking_data: state.memberStakings.member_staking_data,
        history: state.memberStakings.history,
        store: state.memberStakings.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcGetList: (filter) => dispatch(actions.getMemberStakings(filter)),
        funcGetHistory: (filter) => dispatch(actions.getHistory(filter)),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(MemberStakings));