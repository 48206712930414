import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';
import Swal from "sweetalert2";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DataTable from "../../components/Datatable/DataTable";
import { showLeaderboard, showWinner } from "../../store/ducks/leaderboard/actions";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import "./leaderboard.css";

//Initialize the language array first
let langArr = [];

class LeaderboardShow extends Component {
    state = {
        game_vendor: [],
        min_win: [],
        prize1: [],
        prize2: [],
        prize3: [],
        prize4: [],
        prize5: [],
        prize6: [],
        prize7: [],
        prize8: [],
        prize9: [],
        prize10: [],
        prize11_20: [],
        prize21_30: [],
        start_time: "",
        end_time: "",
        paicai: 0,
        verified: 0,
        status: "",
        winners: [],
        loading: true
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;

        this.props.getCountry(countryData);

        this.props.showLeaderboard({
            leaderboard_id: this.props.show_leaderboard_id
        });

        this.props.showWinner({
            leaderboard_id: this.props.show_leaderboard_id
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.show_leaderboard !== this.props.show_leaderboard) {
            if (nextProps.show_leaderboard.data.responseCode === 200) {
                let prizes = JSON.parse(nextProps.show_leaderboard.data.data.prize);

                this.setState({
                    loading: false,
                    game_vendor: nextProps.show_leaderboard.data.data.game_vendor,
                    min_win: nextProps.show_leaderboard.data.data.min_win,
                    prize1: prizes.prize1,
                    prize2: prizes.prize2,
                    prize3: prizes.prize3,
                    prize4: prizes.prize4,
                    prize5: prizes.prize5,
                    prize6: prizes.prize6,
                    prize7: prizes.prize7,
                    prize8: prizes.prize8,
                    prize9: prizes.prize9,
                    prize10: prizes.prize10,
                    prize11_20: prizes.prize11_20,
                    prize21_30: prizes.prize21_30,
                    start_time: nextProps.show_leaderboard.data.data.start_date,
                    end_time: nextProps.show_leaderboard.data.data.end_date,
                    paicai: nextProps.show_leaderboard.data.data.paicai === 0 ? 'No' : 'Yes',
                    verified: nextProps.show_leaderboard.data.data.verified === 0 ? 'No' : 'Yes',
                });
            }
            else {
                Swal.fire({
                    title: nextProps.show_leaderboard.data.message[0],
                    icon: nextProps.show_leaderboard.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
        }

        if (nextProps.show_winner !== this.props.show_winner) {
            if (nextProps.show_winner.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    winners: nextProps.show_winner.data.data
                });
            }
            else {
                Swal.fire({
                    title: nextProps.show_winner.data.message[0],
                    icon: nextProps.show_winner.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({ loading: false });
            // clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    'langId': data[item].languageId,
                    'langCode': data[item].languageCode,
                    'langName': data[item].languageName
                });
            });
        }

        return true;
    }

    render() {
        // const { t } = this.props;

        const columns = [
            // {
            //     text: t('global.no'),
            //     dataField: "",
            //     keyField: "no",
            //     formatter: (cell, row, rowIndex) => {
            //         let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
            //         return <span>{rowNumber}</span>;
            //     },
            //     headerStyle: () => {
            //         return {width: '50px'};
            //     }
            // },
            {
                text: "Rank",
                dataField: "rank",
                keyField: 'rank',
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: "Username",
                dataField: "username",
                keyField: 'username',
                // headerStyle: () => {
                //     return {width: '165px'};
                // }
            },
            {
                text: "Total Win/Lose",
                dataField: "total_win",
                keyField: "totalWin",
            },
            {
                text: "Type",
                dataField: "type",
                keyField: "type",
            }
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">Leaderboard</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">Show Leaderboard</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>Game</th>
                                                    <td field-key='game_vendor_id'>{this.state.game_vendor}</td>
                                                </tr>
                                                <tr>
                                                    <th>Min Win</th>
                                                    <td field-key='min_win'>{this.state.min_win}</td>
                                                </tr>
                                                <tr>
                                                    <th>Prizes</th>
                                                    <td field-key='prizes'>
                                                        <p>1st: {this.state.prize1}</p>
                                                        <p>2nd: {this.state.prize2}</p>
                                                        <p>3rd: {this.state.prize3}</p>
                                                        <p>4th: {this.state.prize4}</p>
                                                        <p>5th: {this.state.prize5}</p>
                                                        <p>6th: {this.state.prize6}</p>
                                                        <p>7th: {this.state.prize7}</p>
                                                        <p>8th: {this.state.prize8}</p>
                                                        <p>9th: {this.state.prize9}</p>
                                                        <p>10th: {this.state.prize10}</p>
                                                        <p>11th ~ 20th: {this.state.prize11_20}</p>
                                                        <p>21th ~ 30th: {this.state.prize21_30}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td field-key='start_date'>{this.state.start_time}</td>
                                                </tr>
                                                <tr>
                                                    <th>End Date</th>
                                                    <td field-key='end_date'>{this.state.end_time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payout</th>
                                                    <td field-key='end_date'>{this.state.paicai}</td>
                                                </tr>
                                                <tr>
                                                    <th>Verified</th>
                                                    <td field-key='end_date'>{this.state.verified}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="leaderboard__winner card-body">
                                            <div className="">
                                                <DataTable
                                                    keyField="leaderboard_id"
                                                    data={this.state.winners}
                                                    columns={columns}
                                                    classValue="dataTable table-bordered text-justify text-center"
                                                    limit={100}
                                                    totalItems={this.state.winners.length}
                                                    hidePagination={true}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const { home, leaderboard } = state;

    return {
        country_data: home.country_data,
        show_leaderboard: leaderboard.show_leaderboard,
        show_winner: leaderboard.show_winner,
    }
};

const mapDispatchToProps = {
    showLeaderboard,
    showWinner,
    getCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(LeaderboardShow));
