import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

class LuckySpinHistoryListing extends Component 
{
    /* ============ component lifecycle [START] ============ */
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            isOpen: false,
            currentImage: null,
        };
    }
    /* ============ component lifecycle [END] ============ */

    render() 
    {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        let stCls = {
            0: "primary",
            1: "success",
            2: "danger",
            3: "secondary",
            4: "danger",
        };

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: t('lucky-spin-history.member'),
                dataField: "username",
                sort: true,
                onSort: this.props.onSort,
                // hidden: true
            },
            {
                text: t("wallet-adjustment.order-id"),
                dataField: "order_id",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    let orderIdVal = "";
                    orderIdVal = permission.includes(
                        "transaction_details_access"
                    ) && row.order_id ? (
                        <b>
                            <a
                                href="/#"
                                onClick={(event) =>
                                    this.props.detailsHandler(event, 'details', row.transaction_id)
                                }
                            >
                                {row.order_id}
                                <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                            </a>
                        </b>
                    ) : (
                        (row.order_id || '-')
                    );

                    return orderIdVal;
                }
            },
            {
                text: t('lucky-spin-history.prize'),
                dataField: "prize",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    return t(`lucky-spin-prize.${row.prize}`);
                }
            },
            {
                text: t('lucky-spin-history.category'),
                dataField: "category",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    return t(`lucky-spin-prize.${row.category}`);
                }
            },
            {
                text: t('lucky-spin-history.credit'),
                dataField: "credit",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    return (row.credit || '-');
                }
            },
            {
                text: t('lucky-spin-history.reference-code'),
                dataField: "reference_code",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    return (row.reference_code || '-');
                }
            },
            {
                text: t("global.status"),
                dataField: "status",
                sort: true,
                onSort: this.props.onSort,
                formatter: (cell, row) => 
                { 
                    return (
                    <span className={`badge-${stCls[row.status]}`}>
                        {t(`lucky-spin-history.status-desc.${row.status}`)}
                    </span>);
                }
            },
            {
                text: t('lucky-spin-history.date'),
                dataField: "create_date",
                sort: true,
                onSort: this.props.onSort,
            },
            { 
                text: t("global.action"), 
                dataField: "action",
                formatter: (cell, row) => 
                {
                    let actionVal =
                    
                    (permission.includes("lucky_spin_history_update") && row.category === 'gift' && (row.status === 1 || row.status === 3)) 
                    ? 
                    (
                        <div className="btn-group">
                            {permission.includes("lucky_spin_history_update") && (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,"edit",row.id)}
                                    className="btn btn-sm btn-white rounded"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                            )}
                        </div>
                    ) : (
                        ""
                    );
                    return actionVal;
                },
            },
        ];
        /* ======================== datatables data assign [END] ======================== */
        
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("side-bar.lucky_spin_history")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                           <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row filter-wrapper-2`}>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("lucky-spin-history.date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check" />
                                                    </span>
                                                    <DateTimePicker
                                                        id="date"
                                                        name="date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format="YYYY/MM/DD"
                                                        value={this.props.filter.date}
                                                        onChange={this.props.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("lucky-spin-history.prize")}
                                                </label>
                                                <StringInput
                                                    id="prize"
                                                    name="prize"
                                                    value={this.props.filter.prize}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("lucky-spin-history.category")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="category"
                                                    name="category"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.category}
                                                >
                                                    <option value=""></option>
                                                    {this.props.ddCategory &&
                                                        this.props.ddCategory.map(
                                                            (val, idx) => {
                                                                return (
                                                                    <option key={val.id} value={val.id}>{t(`lucky-spin-prize.${val.slug}`)}</option>
                                                                );
                                                            }
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("lucky-spin-history.member")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div>
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() =>
                                                    this.props.refreshHandler()
                                                }
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() =>
                                                    this.props.clearHandler()
                                                }
                                            />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("lucky-spin-history.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val,"activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val,"limit")}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(LuckySpinHistoryListing);
