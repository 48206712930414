import React, { Component } from "react";
import { connect } from "react-redux";
import { getDayDepositReport } from "../../../store/ducks/report/actions";
import { getDropDown } from "../../../store/ducks/memberGroup/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import DepositDayReportListing from "./DepositDayReportListing";
import Swal from "sweetalert2";
import Button from "../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import axios from "../../../common/config/axios";
import * as moment from "moment";
import { withNamespaces } from "react-i18next";

const initialInputState = {
    number_of_days: "",
    min_deposit: "",
    username: "",
    filter_register_date: "",
    filter_transaction_date: ""
};

class DepositDayReport extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",
            activePage: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalRecords: 0,
            filter: initialInputState,
            data: [],
            popupLoading: false,
            // loading: false,
            loading: true,
            dataLoading: false,
            permission: [],
            member_group_option: []
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getDayDepositReport(listingArr);
        this.props.getDropDown();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_deposit_day_"));
            this.setState({ permission: reportPermission });
        }

        if (nextProps.reportData !== this.props.reportData && nextProps.reportData !== undefined) {
            if (nextProps.reportData.status === 200 && nextProps.reportData.data.msgType === "success") {
                this.setState({
                    data: nextProps.reportData.data.data.result.row,
                    totalRecords: nextProps.reportData.data.data.result.pagination.total_record,
                    totalPages: nextProps.reportData.data.data.result.pagination.total_page,
                    loading: false,
                });
                Swal.close();
            } else {
                if (nextProps.reportData !== undefined && nextProps.reportData.hasOwnProperty("message")) {
                    const { t } = this.props;
                    let msg = null;
                    msg = "<ul>";
                    nextProps.reportData.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";

                    Swal.fire({
                        icon: "error",
                        title: t("global.error"),
                        html: msg,
                        showConfirmButton: true,
                    });
                }
            }
        }

        if (nextProps.memberGroupData !== this.props.memberGroupData && nextProps.memberGroupData !== undefined) {
            this.setState({
                member_group_option: nextProps.memberGroupData.data.member_group
            })
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                dataLoading: true,
                data: [],
            },
            () => {
                if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getDayDepositReport(listingArr);
                }
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                totalRecords: 0,
                loading: false,
                dataLoading: false,
                filter: newFilter,
                data: [],
            },
            () => {
                // if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getDayDepositReport(listingArr);
                // }
            }
        );
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };

    exportExcelHandler = () => {
        const data = {};
        data.number_of_days = this.state.filter.number_of_days;
        data.min_deposit = this.state.filter.min_deposit;
        data.filter_member_group = this.state.filter.filter_member_group;
        data.filter_deposit_status = this.state.filter.filter_deposit_status;
        data.filter_transaction_date = this.state.filter.filter_transaction_date;
        data.filter_register_date = this.state.filter.filter_register_date;
        data.order = JSON.stringify(this.state.order);
        data.username = this.state.filter.username;

        axios
            .get("get_day_deposit_report/export_excel", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - MemberDeposit " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - MemberDeposit Error", err);
            });
    };

    emailHandler = (e, email) => {
        e.preventDefault();
        window.location.href = `mailto:${email}`;
    };

    phoneHandler = (e, contactNo) => {
        e.preventDefault();
        // window.location.href = `tel:${contactNo}`;
        window.location.href = `callto:${contactNo}`; // Default Skype
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getDayDepositReport(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.getFilterArray();
            this.props.getDayDepositReport(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let emailButton, phoneButton, output;
        emailButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white mr-2"
                key={`email-${row.memberId}`}
                idValue={row.memberId === undefined ? 0 : row.memberId}
                clicked={(event) => this.emailHandler(event, row.email)}
                buttonName={<FontAwesomeIcon icon={faEnvelope} />}
            />
        );

        phoneButton = (
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-white"
                key={`phone-${row.memberId}`}
                idValue={row.memberId === undefined ? 0 : row.memberId}
                clicked={(event) => this.phoneHandler(event, row.contactNo)}
                buttonName={<FontAwesomeIcon icon={faPhone} />}
            />
        );

        output = [emailButton, phoneButton];
        return output;
    };
    /* ============ Datatables Function [END] ============ */

    vendorFormatter = (cell, row) => {
        // const { t } = this.props;
        if(row.vendor) {
            let vendorValue = Object.entries(row.vendor);
            return vendorValue.map((item, idx) => (
                <span className="badge badge-primary" key={idx}>{item[1]}</span>
            ));
        }
        
    }

    categoryFormatter = (cell, row) => {
        const { t } = this.props;
        if(row.game_category){
            let categoryValue = Object.entries(row.game_category);
            return categoryValue.map((item, idx) => (
                <span className="badge badge-primary" key={idx}>{t('game-type.' + item[1])} </span>
            ));
        }
        
    }

    render() {
        const { t } = this.props;
        let ddMemberGroup = [];
        let dataListing = [];

        const columns = [
            // { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("member.register-date"), dataField: "registration_date", sort: true, onSort: this.onSort },
            { text: t("member.username"), dataField: "memberUsername", sort: true, onSort: this.onSort },
            { text: t("member.name"), dataField: "memberFullname", sort: true, onSort: this.onSort },
            { text: t("member.contact-no"), dataField: "contactNo", sort: true, onSort: this.onSort },
            { text: t("member.email"), dataField: "email", sort: true, onSort: this.onSort },
            { text: t("global.status"), dataField: "status", sort: true, onSort: this.onSort },
            { text: t("report.last-login"), dataField: "memberLastLogin", sort: true, onSort: this.onSort },
            { text: t("report.last-deposit-date"), dataField: "lastDepositDate", sort: true, onSort: this.onSort },
            { text: t("report.last-betting-date"), dataField: "lastBettingDateTime", sort: true, onSort: this.onSort },
            { text: t("report.days-diff"), dataField: "daysDiff", sort: true, onSort: this.onSort },
            { text: t("report.deposit-count"), dataField: "depositCount", sort: true, onSort: this.onSort },
            { text: t("member.deposit-status"), dataField: "depositStatus", sort: true, onSort: this.onSort },
            { text: t("report.total-deposit"), dataField: "totalDepositAmount", sort: true, onSort: this.onSort },
            { text: t("report.member-wallet-balance"), dataField: "walletBalance", sort: true, onSort: this.onSort },
            { text: t("report.vendor"), dataField: "vendor", formatter: this.vendorFormatter },
            { text: t("game-category.title"), dataField: "game_category", formatter: this.categoryFormatter },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "115px" } },
        ];

        if (this.state.pageShow === "listing") {
            let statusVal = "";
            let depositStatusVal = "";
            let statusClass = { 1: "success", 0: "danger" };
            let statusLabel = { 1: "active", 0: "inactive" };
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

            if (this.state.data.length > 0) {
                dataListing = this.state.data.map((val, idx) => {
                    statusVal = <span className={`badge badge-pill badge-${statusClass[val.status]}`}>{t(`global.${statusLabel[val.status]}`)}</span>;
                    depositStatusVal = <span className={`badge badge-pill badge-${(val.depositStatus === "Deposit" ? "success" : "danger")}`}>{val.depositStatus}</span>;
                    return {
                        registration_date: val.registration_date,
                        memberId: val.memberId,
                        memberUsername: val.memberUsername,
                        memberFullname: val.memberFullname,
                        contactNo: val.contactNo,
                        email: val.email,
                        status: statusVal,
                        memberLastLogin: val.memberLastLogin,
                        lastDepositDate: val.lastDepositDate,
                        lastBettingDateTime: val.lastBettingDateTime,
                        daysDiff: val.daysDiff,
                        depositCount: val.depositCount,
                        depositStatus: depositStatusVal,
                        vendor: val.vendor,
                        game_category: val.game_category,
                        totalDepositAmount: new Intl.NumberFormat("en-US", num_format).format(val.totalDepositAmount),
                        walletBalance: new Intl.NumberFormat("en-US", num_format).format(val.walletBalance),
                    };
                });
            }
        }

        if (this.state.member_group_option) {
            ddMemberGroup = this.state.member_group_option.map((val, idx) => {
                return (
                    <option key={val.id} value={val.id}>
                        {val.group_name}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <DepositDayReportListing
                        filter={this.state.filter}
                        refreshHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        exportExcelHandler={() => this.exportExcelHandler()}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        ddMemberGroup={ddMemberGroup}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_day_deposit_report_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
        memberGroupData: state.memberGroup.dropDown
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDayDepositReport: (filter) => dispatch(getDayDepositReport(filter)),
        getDropDown: () => dispatch(getDropDown())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DepositDayReport));
