import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showPromotion } from "../../store/ducks/promotion/actions";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import DomPurify from "../../components/Sanitize/DomPurify";
import Lightbox from 'react-image-lightbox';

let langArr = [];

class PromotionShow extends Component {
    state = {
        image_preview: [],
        featured_image_preview: [],
        promo_details_image: [],
        promo_banner_image_preview: [],
        mobile_image_preview: [],
        mobile_banner_image_preview: [],
        title: [],
        content: [],
        sub_content: [],
        sequence: '',
        type: '',
        category: [],
        extra: '',
        min_deposit: '',
        max_payout: '',
        turnover_multiply: '',
        valid_period: '',
        show: '',
        status: '',
        entitle_rebate: '',
        start_time: '',
        end_time: '',
        group_name: '',
        country_id: '',
        created_at: '',
        updated_at: '',
        member_group: [],
        loading: true,
        events_redirect_url: ''
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        const showPromotionData = {};
        showPromotionData.promotion_id = this.props.show_promotion_id;

        this.props.showPromotion(showPromotionData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_promotion_data !== this.props.show_promotion_data) {
            if (nextProps.show_promotion_data.data.responseCode === 200) {
                const {
                    image_preview,
                    featured_image_preview,
                    promo_details_image,
                    promo_banner_image_preview,
                    mobile_image_preview,
                    mobile_banner_image_preview,
                    title,
                    content,
                    sub_content,
                }
                    = this.state;

                nextProps.show_promotion_data.data.data.map((item, i) => {
                    image_preview[item.lang_id] = item.image;
                    featured_image_preview[item.lang_id] = item.featured_image;
                    promo_details_image[item.lang_id] = item.promo_details_image;
                    promo_banner_image_preview[item.lang_id] = item.promo_banner_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    mobile_banner_image_preview[item.lang_id] = item.mobile_banner_image;
                    title[item.lang_id] = item.title;
                    content[item.lang_id] = item.content;
                    sub_content[item.lang_id] = item.sub_content;
                    return item;
                })

                // console.log(nextProps.show_promotion_data.data[0]);

                this.setState({
                    loading: false,
                    image_preview,
                    featured_image_preview,
                    promo_details_image,
                    promo_banner_image_preview,
                    mobile_image_preview,
                    mobile_banner_image_preview,
                    title,
                    content,
                    sub_content,
                    sequence: nextProps.show_promotion_data.data.data[0].sequence,
                    type: nextProps.show_promotion_data.data.data[0].type,
                    extra: nextProps.show_promotion_data.data.data[0].extra,
                    category: nextProps.show_promotion_data.data.data[0].category,
                    min_deposit: nextProps.show_promotion_data.data.data[0].min_deposit,
                    max_payout: nextProps.show_promotion_data.data.data[0].max_payout,
                    member_group: nextProps.show_promotion_data.data.data[0].member_group,
                    turnover_multiply: nextProps.show_promotion_data.data.data[0].turnover_multiply,
                    valid_period: nextProps.show_promotion_data.data.data[0].valid_period,
                    show: nextProps.show_promotion_data.data.data[0].show,
                    status: nextProps.show_promotion_data.data.data[0].status,
                    entitle_rebate: nextProps.show_promotion_data.data.data[0].entitle_rebate,
                    start_time: nextProps.show_promotion_data.data.data[0].start_time,
                    end_time: nextProps.show_promotion_data.data.data[0].end_time,
                    group_name: nextProps.show_promotion_data.data.data[0].group_name,
                    country_id: nextProps.show_promotion_data.data.data[0].country_id,
                    created_at: nextProps.show_promotion_data.data.data[0].created_at,
                    updated_at: nextProps.show_promotion_data.data.data[0].updated_at,
                    details: nextProps.show_promotion_data.data.data[0],
                    events_redirect_url: nextProps.show_promotion_data.data.data[0].events_redirect_url
                })
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_promotion_data.data.message,
                    icon: nextProps.show_promotion_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }
        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;


        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={this.state.image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.featured-image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={this.state.featured_image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.featured_image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.promo-details-image')}:</strong></label>
                        <DomPurify text={this.state.promo_details_image[item.langId]} />
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.promo-banner-image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={this.state.promo_banner_image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.promo_banner_image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.mobile-image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={this.state.mobile_image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.mobile_image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label><strong>{t('promotion.mobile-banner-image')}:</strong></label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-promotion-size"
                                src={this.state.mobile_banner_image_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.mobile_banner_image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col-md-12">
                    <div className="form-group">
                        <label><strong>{t('promotion.title')}:</strong></label>
                        <p>{this.state.title[item.langId]}</p>
                    </div>
                </div>
                <hr />
                <div className="col-md-12">
                    <div className="form-group">
                        <label><strong>{t('promotion.sub-content')}:</strong></label>
                        <p>{this.state.sub_content[item.langId]}</p>
                    </div>
                </div>
                <hr />
                <div className="col-md-12">
                    <div className="form-group">
                        <label><strong>{t('promotion.content')}:</strong></label>
                        {/* <p><strong>{this.state.content[item.langId]}</strong></p> */}
                        <DomPurify text={this.state.content[item.langId]} />
                        {/* <span dangerouslySetInnerHTML={{ __html: this.state.content[item.langId] }} /> */}
                    </div>
                </div>

            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="bg-white" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('promotion.promotion')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('promotion.show-promotion')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr className="show-promotion-white">
                                                <th>{t('promotion.promotion-details')}:</th>
                                                <td field-key='promotion_details'>
                                                    {tabMessage}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.sequence')}:</th>
                                                <td field-key='sequence'>{this.state.sequence}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.type')}:</th>
                                                <td field-key='type'>{t('promotion-type.' + this.state.type.toLowerCase())}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.category')}:</th>
                                                <td field-key='type'>
                                                    {
                                                        this.state.category.map((val, idx) => {
                                                            return (<span className="badge-primary mr-2 mb-1 mt-1" key={idx}>{t('promotion-type.' + val)}</span>);
                                                        })
                                                    }
                                                </td>
                                            </tr>

                                            {/* <tr>
                                    <th>{t('promotion.extra')}:</th>
                                    <td field-key='extra'>{this.state.extra}</td>
                                </tr> */}

                                            <tr>
                                                <th>{t('promotion.min-deposit')}:</th>
                                                <td field-key='min_deposit'>{this.state.min_deposit}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.max-payout')}:</th>
                                                <td field-key='max_payout'>{this.state.max_payout}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.turnover-multiply')}:</th>
                                                <td field-key='turnover_multiply'>{this.state.turnover_multiply}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.valid-period')}:</th>
                                                <td field-key='turnover_multiply'>{this.state.valid_period}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.show')}:</th>
                                                <td field-key='show'>{this.state.show === 1 ? t("global.show") : t("global.hide")}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.status')}:</th>
                                                <td field-key='status'>{this.state.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.entitle-rebate')}:</th>
                                                <td field-key='entitle_rebate'>{this.state.entitle_rebate === 1 ? t('global._yes') : t('global._no')}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.events-redirect-url')}:</th>
                                                <td field-key='events_redirect_url'>{this.state.events_redirect_url}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.start-time')}:</th>
                                                <td field-key='start_time'>{this.state.start_time}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('promotion.end-time')}:</th>
                                                <td field-key='end_time'>{this.state.end_time}</td>
                                            </tr>

                                            {/* <tr>
                                    <th>{t('promotion.member-group')}</th>
                                    <td field-key='member_group'>
                                        {
                                            this.state.member_group.map((val, idx) =>
                                            {
                                                return (<span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>{val}</span>);
                                            })
                                        }
                                    </td>
                                </tr> */}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const { home, promotion, currentUser } = state;

    return {
        country_data: home.country_data,
        show_promotion_data: promotion.show_promotion_data,
        currentCountryId: currentUser.data.country_id

    }
};

const mapDispatchToProps = {
    showPromotion,
    getCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PromotionShow));