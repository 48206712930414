import React, { Component } from "react";
import { connect } from "react-redux";
// import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import { getItemCategoryList, getDropDown } from "../../store/ducks/itemCategory/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import ItemCategoryListing from "./ItemCategoryListing";
import ItemCategoryCreate from "./ItemCategoryCreate";

const initialInputState = {
	item_name : '',
	item_type: ''
};

let itemCategoryData = {};

class ItemCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageShow: "listing",
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			totalPages: 0,
			totalRecords: 0,
			data: [],
			dropDown: [],
			filter: initialInputState,
			currentShipmentId: "",
			loading: true,
			permission: [],
		};
		this.onInputChange = this.onInputChange.bind(this);
  	}

	fetchData() {
		let availablePage = this.state.activePage;
		if (this.state.totalRecords !== 0) {
			availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
			this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
		}

		itemCategoryData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
		itemCategoryData.limit = this.state.limit;
		itemCategoryData.order = JSON.stringify(this.state.order);

		Object.keys(this.state.filter).forEach((val) => {
            itemCategoryData[val] = this.state.filter[val];
        });
		this.props.getItemCategoryList(itemCategoryData);
  	}

	componentDidMount() {
		this.fetchData();
		this.props.getDropDown();
	}

  	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
			let shipmentPermission = this.props.currentPermission.filter((item) => item.includes("item_category_"));
			this.setState({ permission: shipmentPermission });
		}
		
		if (nextProps.item_category_list !== this.props.item_category_list && nextProps.item_category_list !== undefined) {
			if (nextProps.item_category_list.data.responseCode === 200 && nextProps.item_category_list.data.msgType === 'success') {                
				this.setState({
					data: nextProps.item_category_list.data.data.result.row,
					totalRecords: nextProps.item_category_list.data.data.result.pagination.total_record,
					totalPages: nextProps.item_category_list.data.data.result.pagination.total_page,
					loading: false,
				});
				// Swal.close();
			} else {
				this.setState({
                    loading: true,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.item_category_list.data.message);
                });
			}
		}

		if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {			
			if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
				this.setState({
					dropDown: nextProps.dropDown.data,
				});				
			} else {
				this.setState({
                    loading: true,
                    dropDown: []
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
			}
		}

		return true;
	}
	
	onInputChange(event) {
		let newFilter = Object.assign({}, this.state.filter);
		newFilter[event.target.id] = event.target.value;
		
		this.setState({
			filter: newFilter,
		});
	}

  	refreshHandler = () => {
    	this.setState({
			loading: true,
			data: [],
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null
      	}, () => {
			this.fetchData();
		});
	};

  	clearHandler = () => {
    	let newFilter = initialInputState;
    	this.setState({
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			searchValue: "",
			loading: true,
			filter: newFilter,
			data: [],
		}, () => {
			this.fetchData();
		});
	};

	changePageHandler = (page) => {
		this.setState({
			pageShow: page,
		});
	};

	datatablesChangeHandler = (val, act) => {
		this.setState({
			[act]: act === "searchValue" ? val.target.value : val,
			loading: true,
			data: [],
      	}, () => {
			this.fetchData();

      	});
  	};

  	onSort = (field, order) => {
    	const orderArry = [{}];
    	let orderSequence = order === "asc" ? true : false;

		orderArry[0]["id"] = field;
		orderArry[0]["desc"] = orderSequence;

    	this.setState(
      	{
			order: orderArry,
			loading: false,
			data: [],
			// errMsg: [],
			// touched: false,
      	}, () => {
			this.fetchData();
      	});
  	};
	
	render() {
		const { t } = this.props;
    	let dataListing = [];
    	const columns = [
			{
				text: t("global.no"),
				dataField: "",
				keyField: "no",
				formatter: (cell, row, rowIndex) => {
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
			},
			{
				text: t("item-category.item-name"),
				dataField: "itemName",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("item-category.slug"),
				dataField: "itemSlug",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("item-category.item-type"),
				dataField: "itemTypeSlug",
				sort: true,
				onSort: this.onSort,
			}
		];
		
		if (this.state.pageShow === "listing") {
			if (this.state.data.length > 0) {
				dataListing = this.state.data.map((val, idx) => {
					return {
						itemId: val.itemId,
						itemName: t(`item-category.${val.itemName}`, val.itemName), 
						itemSlug: val.itemSlug,
						itemTypeSlug: t(`item-category.${val.itemTypeSlug}`)
          			};
        		});
      		}
    	}
		
		return (
			<Auxiliary>
				{this.state.pageShow === "listing" && (
					<ItemCategoryListing
						filter={this.state.filter}
						clearHandler={() => this.clearHandler()}
						refreshHandler={() => this.refreshHandler()}
						onInputChange={(event) => this.onInputChange(event)}
						datatablesChangeHandler={(val, act) =>
						this.datatablesChangeHandler(val, act)
						}
						columns={columns}
						activePage={this.state.activePage}
						dataListing={dataListing}
						limit={this.state.limit}
						totalRecords={this.state.totalRecords}
						loading={this.state.loading}
						permission={this.state.permission}
						changePageHandler={this.changePageHandler}
						ddItemType={this.state.dropDown.item_type}
					/>
        		)}

				{this.state.permission.includes("item_category_create") && this.state.pageShow === "create" && (
					<ItemCategoryCreate
						refreshHandler={() => this.refreshHandler()}
						changePageHandler={(page) => this.changePageHandler(page)}
					/>
				)}
      		</Auxiliary>
    	);
  	}
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
	const { currentUser, itemCategory } = state;	
			
	//Get the state from reducers current limit, current activePage and search value
	return {
		currentPermission: currentUser.data.permission,
		item_category_list: itemCategory.item_category_list,
		dropDown: itemCategory.dropDown
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getItemCategoryList: (filter) => dispatch(getItemCategoryList(filter)),
		getDropDown: () => dispatch(getDropDown())
  	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ItemCategory));
