export const GET_SHIPMENT_LIST = "GET_SHIPMENT_LIST";
export const DROPDOWN = "SHIPMENT_DROPDOWN";
export const GET_AREA_DROPDOWN = "SHIPMENT_GET_AREA_DROPDOWN";
export const POST_SHIPMENT = "POST_SHIPMENT";
export const SHOW_SHIPMENT = "SHOW_SHIPMENT";
export const UPDATE_DETAIL_SHIPMENT = "UPDATE_DETAIL_SHIPMENT";
export const SHIPMENT_EDIT_DETAILS = "SHIPMENT_EDIT_DETAILS";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const SHIPMENT_PAYMENT = "SHIPMENT_PAYMENT";
export const SHOW_SHIPMENT_DETAILS = "SHOW_SHIPMENT_DETAILS";
export const SHIPMENT_PAYMENT_DROP_DOWN = "SHIPMENT_PAYMENT_DROP_DOWN";