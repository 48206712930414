import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

import IPAddressListing from "./IPAddressListing";
import LoginIPAddressShow from "./LoginIPAddressShow";
import RegisterIPAddressShow from "./RegisterIPAddressShow";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import {getLoginIPAddress, getRegisterIPAddress} from "../../store/ducks/ip/actions";
import Button from "../../components/Button/Button";


class IPAddress extends Component {
    state = {
        pageShow: "listing",
        tabShow: "login-ip-address",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',

        data: [],

        currentIpAddress: '',
        loading: true
    }

    fetchLoginIPAddressData() {
        const loginIPAddressData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        loginIPAddressData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        loginIPAddressData.limit = this.state.limit;
        loginIPAddressData.order = JSON.stringify(this.state.sorted);
        loginIPAddressData.search = this.state.searchValue;

        this.props.getLoginIPAddress(loginIPAddressData);
    }

    fetchRegisterIPAddressData() {
        const registerIPAddressData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        registerIPAddressData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        registerIPAddressData.limit = this.state.limit;
        registerIPAddressData.order = JSON.stringify(this.state.sorted);
        registerIPAddressData.search = this.state.searchValue;

        this.props.getRegisterIPAddress(registerIPAddressData);
    }

    componentDidMount() {
        this.fetchLoginIPAddressData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.login_ip_address_data !== this.props.login_ip_address_data) {
            if (nextProps.login_ip_address_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.login_ip_address_data.data.data,
                    totalRecords: nextProps.login_ip_address_data.data.totalRecords,
                    totalPages: nextProps.login_ip_address_data.data.totalPages,
                })
            } else {
                this.setState({loading: false});
                Swal.fire({
                    title: nextProps.login_ip_address_data.data.message[0],
                    icon: nextProps.login_ip_address_data.data.msgType,
                    confirmButtonText: `OK`,
                })
            }
        }

        if (nextProps.register_ip_address_data !== this.props.register_ip_address_data) {
            if (nextProps.register_ip_address_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.register_ip_address_data.data.data,
                    totalRecords: nextProps.register_ip_address_data.data.totalRecords,
                    totalPages: nextProps.register_ip_address_data.data.totalPages,
                })
            } else {
                this.setState({loading: false});
                Swal.fire({
                    title: nextProps.register_ip_address_data.data.message[0],
                    icon: nextProps.register_ip_address_data.data.msgType,
                    confirmButtonText: `OK`,
                })
            }
        }

        return true;
    }

    refreshHandler = () => {
        this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            if (this.state.tabShow === 'register-ip-address') {
                this.fetchRegisterIPAddressData();
            } else {
                this.fetchLoginIPAddressData();
            }
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            if (this.state.tabShow === 'register-ip-address') {
                this.fetchRegisterIPAddressData();
            } else {
                this.fetchLoginIPAddressData();
            }
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            if (this.state.tabShow === 'register-ip-address') {
                this.fetchRegisterIPAddressData();
            } else {
                this.fetchLoginIPAddressData();
            }
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                if (this.state.tabShow === 'register-ip-address') {
                    this.fetchRegisterIPAddressData();
                } else {
                    this.fetchLoginIPAddressData();
                }
            })
        }
    }

    changeTabHandler = (event) => {
        event.preventDefault();

        this.setState({
            pageShow: "listing",
            tabShow: event.target.id,
            loading: true,
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            sorted: null,
            searchValue: ''
        }, () => {
            if (event.target.id === 'register-ip-address') {
                this.fetchRegisterIPAddressData();
            } else {
                this.fetchLoginIPAddressData();
            }
        });


    }

    loginActionFormatter = (cell, row) => {
        let viewButton, output;
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-primary mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.ipId}`}
                clicked={() => this.changePageHandler('show', row.loginIpAddress)}
            />;
        output = [viewButton];
        return output;
    }

    registerActionFormatter = (cell, row) => {
        let viewButton, output;
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-primary mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.ipId}`}
                clicked={() => this.changePageHandler('show', row.registerIpAddress)}
            />;
        output = [viewButton];
        return output;
    }

    changePageHandler = (page, ipAddress) => {
        this.setState({
            pageShow: page,
            currentIpAddress: ipAddress
        });
    }

    render() {
        const {t} = this.props;

        let display = null;
        let tabId = this.state.tabShow;
        let pageShow = this.state.pageShow;

        const loginIPColumns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "IP Id",
                dataField: "ipId",
                keyField: "ipId",
                hidden: true
            },
            {
                text: t('ip.login-ip-address'),
                dataField: "loginIpAddress",
                keyField: "loginIpAddress"
            },
            {
                text: t('ip.count'),
                dataField: "loginCount",
                keyField: "loginCount"
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.loginActionFormatter
            }
        ];

        const registerIPColumns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * 10 + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "IP Id",
                dataField: "ipId",
                keyField: "ipId",
                hidden: true
            },
            {
                text: t('ip.register-ip-address'),
                dataField: "registerIpAddress",
                keyField: "registerIpAddress"
            },
            {
                text: t('ip.count'),
                dataField: "registerCount",
                keyField: "registerCount"
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.registerActionFormatter
            }
        ];

        display = (
            <>

                {(pageShow === "listing") && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-primary tabs-style-2 mt-3">
                                <div className="tab-menu-heading">
                                    <div className="tabs-menu1">
                                        <ul className="nav panel-tabs main-nav-line">
                                            <li>
                                                <button
                                                    id="login-ip-address"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{borderTopWidth: 0}}
                                                    className={`nav-link bg-white ${tabId === "login-ip-address" ? "active" : ""}`}>
                                                    {t("ip.login-ip-address")}
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    id="register-ip-address"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{borderTopWidth: 0}}
                                                    className={`nav-link bg-white ${tabId === "register-ip-address" ? "active" : ""}`}>
                                                    {t("ip.register-ip-address")}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(tabId === "login-ip-address" && pageShow === "listing") &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("ip.ip-address")}</h4>
                            </div>
                        </div>
                    </div>
                    <IPAddressListing
                        columns={loginIPColumns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                    />
                </div>
                }

                {(tabId === "register-ip-address" && pageShow === "listing") &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("ip.ip-address")}</h4>
                            </div>
                        </div>
                    </div>
                    <IPAddressListing
                        columns={registerIPColumns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                    />
                </div>
                }

                {tabId === "login-ip-address" && this.state.pageShow === 'show' &&
                <LoginIPAddressShow
                    show_ip_address={this.state.currentIpAddress}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {tabId === "register-ip-address" && this.state.pageShow === 'show' &&
                <RegisterIPAddressShow
                    show_ip_address={this.state.currentIpAddress}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }


            </>
        );

        return (
            <Auxiliary>
                {display}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {ipAddress} = state;

    //Get the state from reducers current limit, current page and search value
    return {
        login_ip_address_data: ipAddress.login_ip_address_data,
        register_ip_address_data: ipAddress.register_ip_address_data
    }
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getLoginIPAddress,
    getRegisterIPAddress
};

export default connect(mapStateToProps,
    mapDispatchToProps)(withNamespaces('translation')(IPAddress));