import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getAnnouncements, updateAnnouncementStatus} from "../../store/ducks/announcement/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import AnnouncementListing from './AnnouncementListing';
import AnnouncementCreate from './AnnouncementCreate';
import AnnouncementShow from './AnnouncementShow';
import AnnouncementEdit from './AnnouncementEdit';
import Swal from 'sweetalert2';
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from 'react-i18next';
import { filterErrorHandler } from '../../hoc/Shared/utility';

const initialInputState =
{
    /* form input value */
    title: '',
    status: '',
    show: '',
    announce_time: '',
    start_time: '',
    end_time: '',
};

let listingArr = {};
class Announcement extends Component
{
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props)
    {
        super(props);
        this.state =
        {
            pageShow: 'listing',

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,

            filter: initialInputState,

            data: [],

            popupLoading: false,

            announcementShowId: null,
            announcementEditId: null,
            loading: true,

            permission: []
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        const listingArr = this.fetchData();
        this.props.getAnnouncements(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let announcementPermission = this.props.currentPermission.filter((item) => item.includes("announcement_"));
            // console.log(announcementPermission);
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.announcementData !== this.props.announcementData && nextProps.announcementData !== undefined)
        {

            if(nextProps.announcementData.data.responseCode === 200 && nextProps.announcementData.data.msgType === 'success'){
                this.setState({
                    data: nextProps.announcementData.data.data.result.row,
                    totalRecords: nextProps.announcementData.data.data.result.pagination.total_record,
                    totalPages: nextProps.announcementData.data.data.result.pagination.total_page,
                    loading: false
                });
            }else{
                this.setState({
                    loading: false,
                    totalPages: 0,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.announcementData.data.message);
                });
            }
           
        }

        if (nextProps.announcementUpdateStatusResult !== this.props.announcementUpdateStatusResult && nextProps.announcementUpdateStatusResult !== undefined){
            if(nextProps.announcementUpdateStatusResult.responseCode === 200 && nextProps.announcementUpdateStatusResult.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    const listingArr = this.fetchData();
                    this.props.getAnnouncements(listingArr);
                });
            }else{
                this.setState({
                    loading: false
                }, () => 
                {
                    filterErrorHandler(nextProps.announcementUpdateStatusResult.message);
                });
            }   
        }

        if (nextProps.announcementUpdateLoginPermissionResult !== this.props.announcementUpdateLoginPermissionResult){
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    /* ============ function [START] ============ */
    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id ==='announce_time')
        {
            const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
            newFilter['start_time'] = (date) ? date[0] : '';
            newFilter['end_time'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    refreshHandler = () => 
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        }, ()=>
        {
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    showHandler = (e, id) =>
    {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'show',
            announcementShowId: id
        });
    }

    editHandler = (e, id) =>
    {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'edit',
            announcementEditId: id
        });
    }

    fetchData = () =>
    {
        
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.title = this.state.filter.title
        listingArr.status = this.state.filter.status
        listingArr.show = this.state.filter.show
        listingArr.start_time = this.state.filter.start_time
        listingArr.end_time = this.state.filter.end_time
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    }
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) =>
    {
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            // errMsg: [],
            // touched: false,
        }, () =>
        {
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        });
    }

    noFormatter = (cell, row, rowIndex) =>
    {
        //let rowNumber = ((this.state.activePage - 1) * this.state.limit) + (rowIndex + 1);
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit))
        return <span>{rowNumber}</span>;
    }

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("announcement_update_status")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="status"
                    name="status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateAnnouncementStatusHandler(event, row.announcementId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    };

    updateAnnouncementStatusHandler = (event, announcementId) => {
        event.preventDefault();

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        
        this.setState({[event.target.name]: event.target.value});
        const updateAnnouncementStatusData = {};
        updateAnnouncementStatusData.id = announcementId;
        updateAnnouncementStatusData.status = Number(event.target.value);
        this.props.updateAnnouncementStatus(updateAnnouncementStatusData);
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;
        if (this.state.permission.includes("announcement_view")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    key={`view-${row.announcementId}`}
                    idValue={row.announcementId === undefined ? 0 : row.announcementId}
                    clicked={(event) => this.showHandler(event, row.announcementId)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }

        if (this.state.permission.includes("announcement_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.announcementId}`}
                    idValue={row.announcementId === undefined ? 0 : row.announcementId}
                    clicked={(event) => this.editHandler(event, row.announcementId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [viewButton, editButton];
        return output;
    };

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.fetchData();
            this.props.getAnnouncements(listingArr);
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.fetchData();
                this.props.getAnnouncements(listingArr);
            });
        }
    }

    /* ============ Datatables Function [END] ============ */

    render()
    {
        const { t } = this.props;

        // console.log('render (announcement): ', this.state);
        let dataListing = [];

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('announcement.title'), dataField: "title", sort: true, onSort: this.onSort},
            {text: t('announcement.sequence'), dataField: "sequence"},
            {text: t('global.status'), dataField: "status", formatter: this.statusFormatter, headerStyle: { width: "115px" },},
            {text: t('announcement.show'), dataField: "show", headerStyle: { width: "115px" }},
            {text: t('announcement.start-time'), dataField: "startTime", sort: true, onSort: this.onSort},
            {text: t('announcement.end-time'), dataField: "endTime", sort: true, onSort: this.onSort},
            {text: t('global.action'), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: '115px' }},
        ];

        if(this.state.pageShow === "listing" && this.state.data.length > 0)
        {
            let announcement_val = '';
            let announcement_cls = {1:'primary', 2:'info'};
            let announcement_type = {1:t('announcement.news'), 2:t('announcement.member')};
            dataListing = this.state.data.map((val, idx) =>
            {
                announcement_val = <span className={`badge-pill badge-${announcement_cls[val.show]}`}>{announcement_type[val.show]}</span>;

                return {
                    announcementId: val.announcementId,
                    title: val.title,
                    sequence: val.sequence,
                    status: val.status,
                    show: announcement_val,
                    startTime: val.startTime,
                    endTime: val.endTime,
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <AnnouncementListing
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                }

                {this.state.permission.includes("announcement_create") && this.state.pageShow==='create' &&
                    <AnnouncementCreate
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.permission.includes("announcement_view") && this.state.pageShow==='show' &&
                    <AnnouncementShow
                        announcementShowId={this.state.announcementShowId}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.permission.includes("announcement_update") && this.state.pageShow==='edit' &&
                    <AnnouncementEdit
                        announcementEditId={this.state.announcementEditId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = state =>
{
    const {announcement, currentUser} = state;

    return {
        announcementData: announcement.announcement_data,
        announcementUpdateStatusResult: announcement.announcement_update_status_result,
        currentPermission: currentUser.data.permission,
    }
};

const mapDispatchToProps = dispatch =>
{
    return {
        getAnnouncements: (data) => dispatch(getAnnouncements(data)),
        updateAnnouncementStatus: (data) => dispatch(updateAnnouncementStatus(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Announcement));