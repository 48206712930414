import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';

import * as actions from '../../store/ducks/coinSetting/actions';

class CoinSettingEdit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: {},
            transactionType: [],
            loading: true,
            errMsg: [],
            touched: false,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('coin_setting_form');
        if (this.props.selectId) {
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newInput = {};
                for (const key in nextProps.details.data.result) {
                    newInput[key] = nextProps.details.data.result[key];
                }

                this.setState({
                    formFilter: newInput,
                    transactionType: nextProps.details.data.transaction_type,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.update);
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('coin_setting_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    submitHandler = (e) => {
        const { t } = this.props;
        e.preventDefault();
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            confirmButtonText: t("global.update"),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                let data = {};
                data.id = this.state.formFilter.id;
                data.coin_reward = this.state.formFilter.coin_reward;
                data.min_bet = this.state.formFilter.min_bet;
                // console.log(data);
                this.props.funcUpdate(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('side-bar.coin_setting')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.coin_setting_update')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='coin_setting_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='coin_setting_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-md-4 col-12 form-group'>
                                                    <label htmlFor='min_bet'>{t('coin-setting.slug')}</label>
                                                    <StringInput
                                                        id='slug'
                                                        name='slug'
                                                        className='form-control'
                                                        onChange={() => { }}
                                                        // placeholder={t('coin-setting.slug')}
                                                        value={this.state.formFilter.slug || ''}
                                                        disabled
                                                    />
                                                </div>
                                                <div className='col-md-4 col-12 form-group'>
                                                    <label htmlFor='min_bet'>{t('coin-setting.min-bet')}</label>
                                                    <NumberInput
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id='min_bet'
                                                        name='min_bet'
                                                        min={1}
                                                        // max={45000}
                                                        placeholder={t('coin-setting.min-bet')}
                                                        value={this.state.formFilter.min_bet || ''}
                                                        required
                                                    />
                                                </div>
                                                <div className='col-md-4 col-12 form-group'>
                                                    <label htmlFor='coin'>{t('coin-setting.coin')}</label>
                                                    <NumberInput
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id='coin_reward'
                                                        name='coin_reward'
                                                        // min={0.001}
                                                        // max={100}
                                                        placeholder={t('coin-setting.coin')}
                                                        value={this.state.formFilter.coin_reward || ''}
                                                        required
                                                    />
                                                </div>
                                                {/* <div className='col-md-4 col-12 form-group'>
                                                <label htmlFor='turnover_multiply'>{t('coin-setting.turnover-multiply')}</label>
                                                <NumberInput
                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                    onChange={(event) => this.onInputChange(event)}
                                                    id='turnover_multiply'
                                                    name='turnover_multiply'
                                                    value={this.state.formFilter.turnover_multiply || ''}
                                                    // required
                                                />
                                            </div> */}
                                                <div className='col-md-12 form-group mb-0 justify-content-end'>
                                                    <div>
                                                        {(permission.includes('coin_setting_update')) &&
                                                            <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.coinSetting.details,
        update: state.coinSetting.update,
        currentUser: state.currentUser.data,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(CoinSettingEdit));