import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { updateScore } from "../../../store/ducks/event/match/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import ValidationError from "../../../components/ValidationError/ValidationError";

class EventMatchUpdateScore extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            error_message: [],
            touched: false,
            team_a_score: "",
            team_b_score: "",
            team_a_extra_score: "",
            team_b_extra_score: "",
            extraStatusOption: [
                {
                    label: t('event_match.extra_time'),
                    value: 1
                },
                {
                    label: t('event_match.penalty'),
                    value: 2
                }
            ],
            extra_status: "",
            is_extra: false 
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation("match_form");

        if (this.props.rowData.status === 2) {
            this.setState({
                team_a_score: this.props.rowData.team_a_score,
                team_b_score: this.props.rowData.team_b_score
            });

            if (this.props.rowData.is_extra > 0) {
                this.setState({
                    is_extra: true,
                    extra_status: this.props.rowData.is_extra,
                    team_a_extra_score: this.props.rowData.team_a_extra_score,
                    team_b_extra_score: this.props.rowData.team_b_extra_score
                });
    
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_score !== this.props.update_score) {
            if (nextProps.update_score.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.update_score.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.update_score.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.update_score.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    updateScoreHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });     

                let insertData = {};
                insertData.matchID = this.props.rowData.id;
                insertData.teamAScore = this.state.team_a_score;
                insertData.teamBScore = this.state.team_b_score;;
                insertData.teamAExtraScore = this.state.team_a_extra_score;
                insertData.teamBExtraScore = this.state.team_b_extra_score;
                insertData.extraStatus = this.state.extra_status;

                this.props.updateScore(insertData);
            }
        });
    };

    onInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        if (this.state.team_a_score !== this.state.team_b_score) {
            this.setState({
                team_a_extra_score: null,
                team_b_extra_score: null,
                is_extra: false,
                extra_status: null
            });
        }

        if (event.target.id === 'extra_status') {
            if (!!(event.target.value) && event.target.value > 0) {
                this.setState({
                    is_extra: true
                });
            } else {
                this.setState({
                    is_extra: false
                });
            }
        }
    }

    render() {
        const { t } = this.props;

        let isEnded = false;
        if (this.props.rowData.status === 2) {
            isEnded = true;
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("event_match.event_match")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                        <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_match.match_details")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("event_match.match_date")}</label>
                                            <StringInput
                                                id="match_date"
                                                name="match_date"
                                                className="form-control"
                                                value={this.props.rowData.match_date}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="league">{t("event_match.league")}</label>
                                            <StringInput
                                                id="league"
                                                name="league"
                                                className="form-control"
                                                value={t('world_cup.'+this.props.rowData.league_slug)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="slug">{t("event_match.slug")}</label>
                                            <StringInput
                                                id="slug"
                                                name="slug"
                                                className="form-control"
                                                value={t('world_cup.'+this.props.rowData.slug)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="team_a">{t("event_match.team_a")}</label>
                                            <StringInput
                                                id="team_a"
                                                name="team_a"
                                                className="form-control"
                                                value={t('world_cup.'+this.props.rowData.team_a_slug)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="team_b">{t("event_match.team_b")}</label>
                                            <StringInput
                                                id="team_b"
                                                name="team_b"
                                                className="form-control"
                                                value={t('world_cup.'+this.props.rowData.team_b_slug)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_match.update_score")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="match_form" className="form-horizontal" onSubmit={this.updateScoreHandler} noValidate>
                                    <ValidationError formName="match_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_a_score">{t("event_match.team_a_score")}</label>
                                                <StringInput
                                                    id="team_a_score"
                                                    name="team_a_score"
                                                    type='number'
                                                    className="form-control"
                                                    placeholder={t("event_match.team_a_score")}
                                                    value={this.state.team_a_score}
                                                    onChange={this.onInputChange}
                                                    required
                                                    readOnly={isEnded}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_b_score">{t("event_match.team_b_score")}</label>
                                                <StringInput
                                                    id="team_b_score"
                                                    name="team_b_score"
                                                    type='number'
                                                    className="form-control"
                                                    placeholder={t("event_match.team_b_score")}
                                                    value={this.state.team_b_score}
                                                    onChange={this.onInputChange}                                                    
                                                    required
                                                    readOnly={isEnded}
                                                />
                                            </div>
                                        </div>
                                        {this.state.team_a_score !== '' && this.state.team_b_score !== '' && this.state.team_a_score === this.state.team_b_score && (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="extra_status">{t("event_match.extra_status")}</label>
                                                    <Select
                                                        id="extra_status"
                                                        name="extra_status"
                                                        className="form-control"
                                                        onChange={this.onInputChange}
                                                        options={this.state.extraStatusOption}
                                                        emptyValue={true}
                                                        value={this.state.extra_status}
                                                    />
                                                </div>
                                            </div>
                                        )} 
                                        {this.state.is_extra === true && (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="team_a_extra_score">{t("event_match.team_a_extra_score")}</label>
                                                    <StringInput
                                                        id="team_a_extra_score"
                                                        name="team_a_extra_score"
                                                        type='number'
                                                        className="form-control"
                                                        placeholder={t("event_match.team_a_extra_score")}
                                                        value={this.state.team_a_extra_score}
                                                        onChange={this.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.is_extra === true && (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="team_b_extra_score">{t("event_match.team_b_extra_score")}</label>
                                                    <StringInput
                                                        id="team_b_extra_score"
                                                        name="team_b_extra_score"
                                                        type='number'
                                                        className="form-control"
                                                        placeholder={t("event_match.team_b_extra_score")}
                                                        value={this.state.team_b_extra_score}
                                                        onChange={this.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        update_score: state.eventMatch.update_score,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateScore: (data) => dispatch(updateScore(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EventMatchUpdateScore));
