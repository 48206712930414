import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getFloatImage = (data) => dispatch => {
    axios.get('floatimage/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "lang_id": data['lang_id'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_FLOATING_IMAGE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const postFloatImage = (data) => dispatch => {
    axios.post('floatimage/store',data)
        .then( (response) => {
            dispatch ({
                type: type.POST_FLOATING_IMAGE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showFloatImage = (data) => dispatch => {
    axios.get('floatimage/show', {
        params: {
            "floating_id": data['floating_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_FLOATING_IMAGE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateFloatImage = (data) => dispatch => {
    axios.post('floatimage/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_FLOATING_IMAGE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateFloatingImageStatus = (data) => dispatch => {
    axios.post('floatimage/changeStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_FLOATING_IMAGE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteFloatingImage = (data) => dispatch => {
    axios.post('floatimage/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_FLOATING_IMAGE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};