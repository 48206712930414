import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import PaymentGatewayListing from "./PaymentGatewayListing";
import PaymentGatewayCreate from "./PaymentGatewayCreate";
import PaymentGatewayEdit from "./PaymentGatewayEdit";
import PaymentType from "./PaymentType";
import { filterErrorHandler } from "../../hoc/Shared/utility";

import { getPaymentGateway, getDropDown, deletePaymentGateway, updateDepositStatus, updateWithdrawalStatus } from "../../store/ducks/paymentGateway/actions";
import Select from "../../components/Input/Select/Select";

const initialInputState = {
    /* form input value */
    paymentGatewayName: "",
    depositStatus: "",
    withdrawalStatus: "",
};

let paymentGatewayListingData = {};
class PaymentGateway extends Component {
    state = {
        pageShow: "listing",

        activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        totalRecords: 0,

        filter: initialInputState,

        data: [],
        dataPaymentGateway: [],

        dropDown: [],

        currentPaymentGatewayId: "",
        currentPaymentGatewayName: "",
        loading: true,

        permission: [],
    };

    fetchData() {
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        paymentGatewayListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        paymentGatewayListingData.limit = this.state.limit;
        paymentGatewayListingData.order = JSON.stringify(this.state.sorted);
        paymentGatewayListingData.country_id = this.props.currentCountryId;
        paymentGatewayListingData.paymentGatewayName = this.state.filter.paymentGatewayName;
        paymentGatewayListingData.depositStatus = this.state.filter.depositStatus;
        paymentGatewayListingData.withdrawalStatus = this.state.filter.withdrawalStatus;

        this.props.getPaymentGateway(paymentGatewayListingData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let paymentGatewayPermission = this.props.currentPermission.filter((item) => item.includes("payment_gateway_"));
            this.setState({ permission: paymentGatewayPermission });
        }

        if (nextProps.payment_gateway_data !== this.props.payment_gateway_data && nextProps.payment_gateway_data !== undefined) {
            if (nextProps.payment_gateway_data.data.responseCode === 200 && nextProps.payment_gateway_data.data.msgType === 'success') {
                this.setState({
                    loading: false,
                    data: nextProps.payment_gateway_data.data.data,
                    dataPaymentGateway: nextProps.payment_gateway_data.data.paymentGateway,
                    totalRecords: nextProps.payment_gateway_data.data.totalRecords,
                    totalPages: nextProps.payment_gateway_data.data.totalPages,
                });
            } else {
                this.setState({
                    totalPages: 0,
                    activePage: 1,
                    totalRecords: 0,
                    data: [],
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.payment_gateway_data.data.message);
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                });
            } else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }

        // if (nextProps.delete_payment_gateway_result !== this.props.delete_payment_gateway_result){
        //     if(nextProps.delete_payment_gateway_result.responseCode === 200) {
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_payment_gateway_result.message,
        //             icon: nextProps.delete_payment_gateway_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.fetchData();
        //             }
        //         });
        //     }else{
        //         this.setState({ loading: false });
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_payment_gateway_result.message[0],
        //             icon: nextProps.delete_payment_gateway_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         })
        //     }
        // }

        if (nextProps.update_deposit_status_payment_gateway_result !== this.props.update_deposit_status_payment_gateway_result && nextProps.update_deposit_status_payment_gateway_result !== undefined) {
            if (nextProps.update_deposit_status_payment_gateway_result.responseCode === 200 && nextProps.update_deposit_status_payment_gateway_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_deposit_status_payment_gateway_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.update_deposit_status_payment_gateway_result.message);
                });
            }
        }

        if (nextProps.update_withdrawal_status_payment_gateway_result !== this.props.update_withdrawal_status_payment_gateway_result && nextProps.update_withdrawal_status_payment_gateway_result !== undefined) {
            if (nextProps.update_withdrawal_status_payment_gateway_result.responseCode === 200 && nextProps.update_withdrawal_status_payment_gateway_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_withdrawal_status_payment_gateway_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.update_withdrawal_status_payment_gateway_result.message);
                });
            }
        }

        return true;
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                sorted: null
            },
            () => {
                this.fetchData();
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                filter: newFilter,
                loading: true,
                data: [],
                sorted: null
            },
            () => {
                this.fetchData();
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    updateStatusPaymentGatewayHandler = (event, paymentGatewayId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updatePaymentGatewayStatusData = {};
        updatePaymentGatewayStatusData.id = paymentGatewayId;
        updatePaymentGatewayStatusData.status = Number(event.target.value);

        this.props.updatePaymentGatewayStatus(updatePaymentGatewayStatusData);
    };

    updateDepositStatusHandler = (event, paymentGatewayId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updatePaymentGatewayStatusData = {};
        updatePaymentGatewayStatusData.id = paymentGatewayId;
        updatePaymentGatewayStatusData.status = Number(event.target.value);
        updatePaymentGatewayStatusData.country_id = this.props.currentCountryId;

        this.props.updateDepositStatus(updatePaymentGatewayStatusData);
    };

    updateWithdrawalStatusHandler = (event, paymentGatewayId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updatePaymentGatewayStatusData = {};
        updatePaymentGatewayStatusData.id = paymentGatewayId;
        updatePaymentGatewayStatusData.status = Number(event.target.value);
        updatePaymentGatewayStatusData.country_id = this.props.currentCountryId;

        this.props.updateWithdrawalStatus(updatePaymentGatewayStatusData);
    };

    // deletePaymentGatewayHandler = (e,payment_gateway_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deletePaymentGatewayData = {};
    //             deletePaymentGatewayData.id = payment_gateway_id;

    //             this.props.deletePaymentGateway(deletePaymentGatewayData);
    //         }
    //     });
    // }

    withdrawalStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("payment_gateway_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.withdrawalStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="withdrawalStatus"
                    name="withdrawalStatus"
                    options={statusArray}
                    defaultValue={row.withdrawalStatus}
                    key={`status-${row.paymentGatewayId}`}
                    onChange={(event) => {
                        this.updateWithdrawalStatusHandler(event, row.paymentGatewayId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.withdrawalStatus === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    depositStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("payment_gateway_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.depositStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="depositStatus"
                    name="depositStatus"
                    options={statusArray}
                    defaultValue={row.depositStatus}
                    key={`status-${row.paymentGatewayId}`}
                    onChange={(event) => {
                        this.updateDepositStatusHandler(event, row.paymentGatewayId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.depositStatus === 0 || null) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    actionFormatter = (cell, row) => {
        let editButton, viewButton, output;

        if (this.state.permission.includes("payment_gateway_show")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    key={`view-${row.memberId}`}
                    idValue={row.memberId === undefined ? 0 : row.memberId}
                    clicked={(event) => this.changePageHandler("show", row.paymentGatewayId, row.paymentGatewayName)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }

        if (this.state.permission.includes("payment_gateway_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.paymentGatewayId}`}
                    clicked={() => this.changePageHandler("edit", row.paymentGatewayId)}
                />
            );
        }

        // if (this.state.permission.includes("payment_gateway_delete")) {
        //     deleteButton =
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-danger mr-2"
        //             clicked={(event) => this.deletePaymentGatewayHandler(event, row.paymentGatewayId)}
        //             key={`delete-${row.paymentGatewayId}`}
        //             buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //         />;
        // }
        output = [viewButton, editButton];
        return output;
    };

    changePageHandler = (page, paymentGatewayId, paymentGatewayName=null) => {
        this.setState({
            pageShow: page,
            currentPaymentGatewayId: paymentGatewayId,
            currentPaymentGatewayName: paymentGatewayName
        });
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    bankNameFormatter = (cell, row) => {
        let bankNameSplit = row.bankName.split(",");
        return (
            <div>
                {bankNameSplit.map((item, index) => (
                    <span className="badge badge-primary mr-2" key={`banks-name-${index}`}>
                        {item}
                    </span>
                ))}
            </div>
        );
    };

    categoryPaymentGatewayFormatter = (cell, row) => {
        let data = this.state.dataPaymentGateway;
        let displayData = [];
        
        data.map((items) => {
            if (row.paymentGatewayId === items.transaction_option_id) {
                var temp = <div>{items.slug}</div>;
                return displayData.push(temp);
            }
            return null;
        });
        return displayData;
    };

    render() {
        const { t } = this.props;

        let rsListing = [];
        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let rowNumber = this.state.data.length - rowIndex - (this.state.activePage - 1) * this.state.limit;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: "Payment Gateway Id",
                dataField: "paymentGatewayId",
                keyField: "paymentGatewayId",
                hidden: true,
            },
            {
                text: t("payment-gateway.payment-gateway-name"),
                dataField: "paymentGatewayName",
                keyField: "paymentGatewayName",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("payment-gateway.deposit-status"),
                dataField: "depositStatus",
                keyField: "depositStatus",
                formatter: this.depositStatusFormatter,
                headerStyle: { width: "115px" },
            },
            {
                text: t("payment-gateway.withdrawal-status"),
                dataField: "withdrawalStatus",
                keyField: "withdrawalStatus",
                formatter: this.withdrawalStatusFormatter,
                headerStyle: { width: "115px" },
            },
            {
                text: t("payment-gateway.sequence"),
                dataField: "sequence",
                keyField: "sequence",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("payment-gateway.deposit-est-time"),
                dataField: "deposit_est_time",
                keyField: "deposit_est_time",
                sort: false
            },
            {
                text: t("payment-gateway.withdrawal-est-time"),
                dataField: "withdrawal_est_time",
                keyField: "withdrawal_est_time",
                sort: false
            },
            {
                text: t("global.action"),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
                headerStyle: () => {
                    return { width: "115px" };
                },
            },
        ];

        if (this.state.data) {
          rsListing = this.state.data.map((val, idx) => {
              
              return {
                  no: idx,
                  // id: val.id,
                  paymentGatewayId: val.paymentGatewayId,
                  username: val.username,
                  paymentGatewayName: t(
                      `transaction-channel.${val.paymentGatewaySlug}`
                  ),
                  depositStatus: val.depositStatus,
                  withdrawalStatus: val.withdrawalStatus,
                  sequence: val.sequence,
                  deposit_est_time: val.deposit_est_time,
                  withdrawal_est_time: val.withdrawal_est_time,
                  action: this.actionFormatter
              };
          });
      }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("payment-gateway.payment-gateway")}</h4>
                                </div>
                            </div>
                        </div>
                        <PaymentGatewayListing
                            paymentGatewayName={this.state.filter.paymentGatewayName}
                            depositStatus={this.state.filter.depositStatus}
                            withdrawalStatus={this.state.filter.withdrawalStatus}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={rsListing}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            payment_type={false}
                            /**filter drop down */
                            ddtransactionChannel={this.state.dropDown.transaction_channel}
                            changePageHandler={this.changePageHandler}
                            pageShow={this.state.pageShow}
                        />
                    </div>
                )}

                {this.state.pageShow === "show" && (
                    <PaymentType
                        show_payment_gateway_id={this.state.currentPaymentGatewayId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        show_payment_gateway_name={this.state.currentPaymentGatewayName}
                        permission={this.state.permission}
                    />
                )}

                {this.state.pageShow === "create" && (
                    <PaymentGatewayCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.pageShow === "edit" && (
                    <PaymentGatewayEdit
                        show_payment_gateway_id={this.state.currentPaymentGatewayId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { paymentGateway, currentUser } = state;

    return {
        payment_gateway_data: paymentGateway.payment_gateway_data,
        delete_payment_gateway_result: paymentGateway.delete_payment_gateway_result,
        update_deposit_status_payment_gateway_result: paymentGateway.update_deposit_status_payment_gateway_result,
        update_withdrawal_status_payment_gateway_result: paymentGateway.update_withdrawal_status_payment_gateway_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission,
        dropDown: paymentGateway.dropDown,
    };
};

const mapDispatchToProps = {
    getPaymentGateway,
    getDropDown,
    deletePaymentGateway,
    updateDepositStatus,
    updateWithdrawalStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PaymentGateway));
