import * as type from './types';
import axios from '../../../common/config/axios';

export const list = (props) => dispatch => {
	axios.get('leaderboard/list', {params: props})
		.then(function (response) {
			dispatch ({
				type: type.LEADERBOARD_LIST,
				payload: response.data
			})
		})
		.catch(err => {});
};

export const create = (data) => dispatch => {
	axios.post('leaderboard/create', data)
		.then(function (response) {
			dispatch ({
				type: type.CREATE,
				payload: response.data
			})
		})
		.catch(err => {});
};

export const showLeaderboard = (data) => dispatch => {
    axios.get('leaderboard/show', {
        params: {
            "leaderboard_id": data['leaderboard_id']
        }
    }).then((response) => {
        dispatch({
            type: type.SHOW_LEADERBOARD,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    });
};

export const showWinner = (data) => dispatch => {
	axios.post('leaderboard/winner', data)
		.then(function (response) {
			dispatch ({
				type: type.SHOW_WINNER,
				payload: response
			})
		}).catch(err => {});
};

export const update = (data) => dispatch => {
	axios.post('leaderboard/update', data)
		.then(function (response) {
			dispatch ({
				type: type.UPDATE,
				payload: response.data
			})
		})
		.catch(err => {});
};

export const deleteLeaderboard = (data) => dispatch => {
	axios.post('leaderboard/delete', data)
		.then(function (response) {
			dispatch ({
				type: type.DELETE,
				payload: response.data
			})
		})
		.catch(err => {});
};
