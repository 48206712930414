import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from '../../components/Button/Button';
import DataTable from "../../components/Datatable/DataTable";

import { getReferralPayoutDetail } from "../../store/ducks/memberReferral/actions";

const base_currency = {
    1: "MYR",
    4: "IDR"
};
class MemberReferralPayoutDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            member: [],
            setting: [],
            comm_percentage: [],
            loading: true,
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            order: null,
            totalRecords: 0,
            searchValue: ""
        }
    }

    componentDidMount() {
        const data = this.getFilterList();
        this.props.getReferralPayoutDetail(data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.member_referral_payout_detail !== undefined && nextProps.member_referral_payout_detail !== this.props.member_referral_payout_detail) {
            if (nextProps.member_referral_payout_detail.status === 200 && nextProps.member_referral_payout_detail.data.responseCode === 200 && nextProps.member_referral_payout_detail.data.msgType === "success") {
                let data = nextProps.member_referral_payout_detail.data;
                this.setState({
                    loading: false,
                    member: data.data.member,
                    setting: data.data.setting,
                    comm_percentage: data.data.comm_percentage,
                    data: data.data.result.row,
                    totalPages: data.data.result.pagination.total_page,
                    totalRecords: data.data.result.pagination.total_record,
                });
            }
        }
        return true;
    }

    getFilterList = () => {
        const listingArr = {};
        const param = this.props.payoutParam;
        let availablePage = this.state.activePage;
        if (this.state.totalRecords > 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.member_id = param.mid;
        listingArr.date = param.date;
        listingArr.base_myr_to_idr = param.rate.base_myr_to_idr;
        listingArr.base_idr_to_myr = param.rate.base_idr_to_myr;
        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);

        this.setState({
            loading: true,
            data: []
        });
        return listingArr;
    }

    onSort = (field, order) => {
        // console.log(field+' | '+order);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayoutDetail(data);
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        let search = "";
        search = (act === 'searchValue') ? val.target.value : val;

        this.setState({
            [act]: search,
            loading: true,
            data: []
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayoutDetail(data);
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    render() {
        const { t } = this.props;
        const params = this.props.payoutParam;
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        const columns = [
            { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
            { text: t("member-referral.referee-username"), dataField: 'username', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.referee-name"), dataField: 'name', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.base-currency"), dataField: 'country_id', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.rebate-eligible-turnover"), dataField: 'eligible_turnover', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.referral-commission"), dataField: 'referral_commission', sort: false },
            { text: t("member-referral.converted-currency") + " (" + base_currency[this.state.member.country_id] + ")", dataField: 'converted_referral_commission', sort: false }
        ];
        let data = this.state.data ? this.state.data.map((val, idx) => {
            let exchange_rate_text = "";
            if(this.state.member.country_id !== val.downline_country){
                if(this.state.member.country_id === 1){
                    if(val.downline_country === 4 && params.rate){ // base idr change to myr rate
                        exchange_rate_text = <small>{`(${new Intl.NumberFormat("en-US", num_format).format(val.referral_commission)} * 1000) * ${params.rate.base_idr_to_myr}`}</small>;
                    }
                }
                else if(this.state.member.country_id === 4){
                    if(val.downline_country === 1 && params.rate){ // base myr change to idr rate
                        exchange_rate_text = <small>{`(${new Intl.NumberFormat("en-US", num_format).format(val.referral_commission)} * ${params.rate.base_myr_to_idr}) / 1000`}</small>;
                    }
                }
            }
            let ref_comm_text = <div>
                <b>{new Intl.NumberFormat("en-US", num_format).format(val.referral_commission)}</b><br />
                {this.state.comm_percentage[val.downline_country] 
                    ? <small>{new Intl.NumberFormat('en-US', num_format).format(val.eligible_turnover) + ' * ' + this.state.comm_percentage[val.downline_country] + "%"}</small> 
                    : ""}
            </div>;
            let convert_ref_comm_text = <div>
                <b>{this.state.member.country_id !== val.downline_country ? 
                    new Intl.NumberFormat("en-US", num_format).format(val.converted_referral_commission || 0) 
                    : new Intl.NumberFormat("en-US", num_format).format(val.referral_commission)}</b><br />
                {exchange_rate_text}
            </div>;
            return {
                no: idx,
                username: val.username,
                name: val.name,
                country_id: base_currency[val.downline_country],
                eligible_turnover: new Intl.NumberFormat('en-US', num_format).format(val.eligible_turnover),
                referral_commission: ref_comm_text,
                converted_referral_commission: convert_ref_comm_text
            }
        }) : [];
        let date = params.date;
        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div>
                            <div className="btn-group">
                                <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler(params.returnPage)} />
                                <div>
                                    <div className="member-name">
                                        <FontAwesomeIcon icon={faUser} /> {this.state.member.username}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0'>{t('member-referral.member-referral')}</h4>
                        </div>
                    </div> */}
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('member-referral.member-details')}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <table className='table table-bordered table-hover'>
                                        <tbody>
                                            <tr>
                                                <th>{t('member-referral.date')}</th>
                                                <td>{date}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('member-referral.member-username')}</th>
                                                <td>{this.state.member.username}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('member-referral.member-name')}</th>
                                                <td>{this.state.member.name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('member-referral.base-currency')}</th>
                                                <td>{base_currency[this.state.member.country_id]}</td>
                                            </tr>
                                        </tbody>
                                    </table>}
                            </div>
                        </div>

                        {/* show each referee payout details */}
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t(`member-referral.referee-details`)}</h4>
                                {params.rate && params.rate.base_myr_to_idr ? (<><small style={{color: '#8B0000'}}><b>*1 MYR = {params.rate.base_myr_to_idr} IDR</b></small><br /></>) : ''}
                                {params.rate && params.rate.base_idr_to_myr ? (<><small style={{color: '#8B0000'}}><b>*1 IDR = {params.rate.base_idr_to_myr} MYR</b></small><br /></>) : ''}
                            </div>
                            <div className='card-body'>
                                <DataTable
                                    keyField='no'
                                    data={data}
                                    columns={columns}
                                    activePage={this.state.activePage}
                                    limit={this.state.limit}
                                    totalItems={this.state.totalRecords}
                                    paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                    sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                    searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                    searchValue={this.state.searchValue}
                                    classValue='dataTable table-bordered text-justify text-center'
                                    loading={this.state.loading}
                                    hideSearchBar={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        member_referral_payout_detail: state.memberReferral.member_referral_payout_detail
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReferralPayoutDetail: (data) => dispatch(getReferralPayoutDetail(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberReferralPayoutDetail));