import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Swal from 'sweetalert2';
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import GameBetSlipListing from './GameBetSlipListing';

import * as actions from "../../store/ducks/gameBetSlip/actions";

const initialInputState =
{
    /* form input value */
    start_date: moment().format('yyyy/MM/DD')+" 12:00:00 AM",
    end_date: moment().format('yyyy/MM/DD')+" 11:59:59 PM",
    date: moment().format('yyyy/MM/DD')+" 12:00:00 AM - "+moment().format('yyyy/MM/DD')+" 11:59:59 PM",
    vendor: '',
    ref_id: '',
    bet_no: '',
    username: '',
    category: '',
    totalBetAmt: 0,
    totalPayoutAmt: 0,
    totalTurnoverAmt: 0,
};

class GameBetSlip extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],

            /* skeleton loading */
            loading: true,
            subLoading: true,

            /* redirect member profile */
            redirectParam: {},
            
            dropDown: {
                gameTypeOption: [],
                vendorOption: [],
            },
            subTotalBetAmt: 0,
            subTotalPayoutAmt: 0,
            subTotalWinLoseAmt: 0,
            subTotalTurnoverAmt: 0,
            totalBetAmt: 0,
            totalPayoutAmt: 0,
            totalWinLoseAmt: 0,
            totalTurnoverAmt: 0,
            
            vendor: '',
            openDetailsModal: false,
            details: {},
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
        this.props.getFilterData(); 
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.rs !== this.props.rs && (nextProps.rs!==undefined && Object.keys(nextProps.rs).length > 0))
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                    subTotalBetAmt: nextProps.rs.data.subTotalBetAmt,
                    subTotalPayoutAmt: nextProps.rs.data.subTotalPayoutAmt,
                    subTotalWinLoseAmt: nextProps.rs.data.subTotalWinLoseAmt,
                    subTotalTurnoverAmt: nextProps.rs.data.subTotalTurnoverAmt,
                    totalBetAmt: nextProps.rs.data.totalBetAmt,
                    totalPayoutAmt: nextProps.rs.data.totalPayoutAmt,
                    totalWinLoseAmt: nextProps.rs.data.totalWinLoseAmt,
                    totalTurnoverAmt: nextProps.rs.data.totalTurnoverAmt,
                });
            }
            else
            {
                const { t } = this.props;
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) =>
                {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t("global.error"),
                    html: msg,
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        activePage: 1,
                        data: [],
                        totalRecords: 0,
                        loading: false,
                    });
                });
            }
        }
        
        if (nextProps.gameBetSlipFilterData !== this.props.gameBetSlipFilterData && nextProps.gameBetSlipFilterData !== undefined) {
            if (nextProps.gameBetSlipFilterData.data && nextProps.gameBetSlipFilterData.responseCode === 200 && nextProps.gameBetSlipFilterData.msgType === "success") {
                this.setState({
                    dropDown: {
                        gameTypeOption: nextProps.gameBetSlipFilterData.data.gameType,
                        vendorOption: nextProps.gameBetSlipFilterData.data.vendor,
                    },
                });
            }
            // Swal.close();
        }

        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                // console.log(nextProps.details.data.result);
                this.setState({
                    details: {
                        result: nextProps.details.data.result,
                        url: nextProps.details.data.url,
                    },
                    subLoading: false
                });
                // Swal.close();
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            // limit: 10,
            searchValue: '',
            // order: [{'id':'id', 'desc':'asc'}],
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
            this.props.getFilterData();
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
            this.props.getFilterData();
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.language = this.props.currentUser.language_code;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        return listingArr;
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
            this.props.getFilterData();
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
            this.props.getFilterData();
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect to member profile
    redirectHandler = (e, id, name)=>{
        e.preventDefault();
        this.setState({
            redirectParam: {
                id: id,
                name: name
            }
        },
        ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
            this.props.getFilterData();
        });
    }

    detailsHandler = (e, vendor, rid) => {
        e.preventDefault();

        // Swal.fire({
        //     allowOutsideClick: true,
        //     allowEscapeKey: true,
        //     allowEnterKey: true,
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // });

        this.setState({
            vendor,
        }, () => {
            const detailsParam = {};
            detailsParam.vendor = vendor;
            detailsParam.bet_id = rid;
            // console.log('detailsHandler: ', detailsParam);
            this.props.funcGetBetslipDetails(detailsParam);
        }, this.closeModalHandler());
    }

    closeModalHandler = () => {
        this.setState({
            details: {},
            subLoading: true,
            openDetailsModal: !this.state.openDetailsModal,
        })
    }

    render()
    {
        const { t } = this.props;
        let ddVendor = this.state.dropDown.vendorOption.map((val, idx) => {
            return (
                <option key={val.vendorId} value={val.vendorId}>
                    {t(`vendor.${val.vendorSlug}`)}
                </option>
            );
        });

        let ddGameType = this.state.dropDown.gameTypeOption.map((val, idx) => {
            return (
                <option key={val.gameTypeId} value={val.gameTypeId}>
                    {t(`game-type.${val.gameTypeSlug}`)}
                </option>
            );
        });

        return (
            <Auxiliary>
                <GameBetSlipListing
                    filter={this.state.filter}
                    changePageHandler={(page) => this.changePageHandler(page)}
                    clearHandler={() => this.clearHandler()}
                    refreshHandler={() => this.refreshHandler()}
                    onInputChange={(event) => this.onInputChange(event)}
                    datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                    onSort={(field, order) => this.onSort(field, order)}
                    redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}
                    detailsHandler={(event,vendor,id) => this.detailsHandler(event,vendor,id)}
                    closeModalHandler={this.closeModalHandler}
                    data={this.state.data}
                    activePage={this.state.activePage}
                    limit={this.state.limit}
                    totalRecords={this.state.totalRecords}
                    searchValue={this.state.searchValue}
                    loading={this.state.loading}
                    subLoading={this.state.subLoading}
                    permission={this.props.currentUser.permission ?? []}
                    ddVendor={ddVendor}
                    ddGameType={ddGameType}
                    subTotalBetAmt={this.state.subTotalBetAmt}
                    subTotalPayoutAmt={this.state.subTotalPayoutAmt}
                    subTotalWinLoseAmt={this.state.subTotalWinLoseAmt}
                    subTotalTurnoverAmt={this.state.subTotalTurnoverAmt}
                    totalBetAmt={this.state.totalBetAmt}
                    totalPayoutAmt={this.state.totalPayoutAmt}
                    totalWinLoseAmt={this.state.totalWinLoseAmt}
                    totalTurnoverAmt={this.state.totalTurnoverAmt}                    
                    {...this.state}
                />
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.gameBetSlip.rs,
        gameBetSlipFilterData: state.gameBetSlip.game_bet_slip_filter_data,
        currentUser: state.currentUser.data,
        details: state.gameBetSlip.details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (params) => dispatch(actions.getList(params)),
        getFilterData: () => dispatch(actions.getFilterData()),
        funcGetBetslipDetails: (params) => dispatch(actions.getBetslipDetails(params)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameBetSlip));
