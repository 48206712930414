import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';

import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';

import * as actions from '../../store/ducks/memberStaking/actions';

class MemberStakingDetails extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            activePage: 1,
            limit: 10,
            totalRecords: 0,
            searchValue: '',
            order: '',

            data: [],
            memberDetails: [],
            loading: true,
            loadOnce: true,
        };
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getMemberStakingDetail(listingArr);

        // console.log(this.props.stakingsParam);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps.rolloverDetails);
        if (nextProps.member_staking_payout_detail !== this.props.member_staking_payout_detail && nextProps.member_staking_payout_detail !== undefined) {
            if (nextProps.member_staking_payout_detail.data.responseCode === 200 && nextProps.member_staking_payout_detail.data.msgType === 'success') {
                this.setState({
                    memberDetails: nextProps.member_staking_payout_detail.data.data.member,
                    data: nextProps.member_staking_payout_detail.data.data.result.row,
                    totalRecords: nextProps.member_staking_payout_detail.data.data.result.pagination.total_record,
                    loading: false,
                    loadOnce: false,
                });
            }
            Swal.close();
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    refreshHandler = () => {
        // this.loadingHandler();
        const listingArr = this.getFilterArray();
        this.props.getMemberStakingDetail(listingArr);
    }

    // loadingHandler = () =>
    // {
    //     Swal.fire({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });
    // }

    getFilterArray = () => {
        // this.loadingHandler();
        const listingArr = {};
        const param = this.props.stakingsParam;

        listingArr.page = this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.member_id = param.mid;
        listingArr.date = param.date;
        listingArr.id = param.id;
        this.setState({
            loading: true,
            data: [],
        });
        return listingArr;
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = ((this.state.activePage - 1) * this.state.limit) + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({ order: orderArry }, () => {
            this.refreshHandler();
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val
        }, () => {
            this.refreshHandler();
        });
    }
    /* ============ datatables function [END] ============ */

    render() {
        // console.log(this.props.rolloverParam);
        const { t } = this.props;
        let display = null;
        let total_payout = this.state.memberDetails.total_payout;

        if (this.state.memberDetails) {
            let rsData = [];
            let date = this.props.stakingsParam.date.split(' ');

            const columns = [
                { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
                // {text: 'trans.id', dataField: 'id'},
                { text: t('member-stakings.member'), dataField: 'username', sort: true, onSort: this.onSort },
                { text: t('member-stakings.member-group'), dataField: 'group_id', sort: true, onSort: this.onSort },
                { text: t('member-stakings.total-member-balance'), dataField: 'total_member_balance', sort: true, onSort: this.onSort },
                { text: t('member-stakings.payout'), dataField: 'total_payout', sort: true, onSort: this.onSort },
                // {text: t('member-rebate.bet-amount'), dataField: 'bet_amount', sort: true, onSort: this.onSort},
                // {text: t('member-rebate.turnover'), dataField: 'turnover', sort: true, onSort: this.onSort},
                // {text: t('member-rebate.valid-turnover'), dataField: 'valid_turnover', sort: true, onSort: this.onSort},
                // {text: t('member-rebate.rebate-amount'), dataField: 'rebate_amt', sort: true, onSort: this.onSort},
            ];

            // rsDetails = this.state.details[0];
            if (this.state.data) {

                // console.log('render :', this.state.data);
                rsData = this.state.data.map((val, idx) => {


                    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
                    total_payout -= new Intl.NumberFormat('en-US', num_format).format(val.total_member_balance * (this.state.memberDetails.referee_interest_percentage / 100) / 365);

                    return {
                        no: idx,
                        username: val.username,
                        group_id: `VIP ` + val.level,
                        total_member_balance: new Intl.NumberFormat('en-US', num_format).format(val.total_member_balance),
                        total_payout: new Intl.NumberFormat('en-US', num_format).format(val.total_member_balance * (this.state.memberDetails.referee_interest_percentage / 100) / 365),
                        // bet_amount: new Intl.NumberFormat('en-US', num_format).format(val.bet_amount),
                        // turnover: new Intl.NumberFormat('en-US', num_format).format(val.turnover),
                        // valid_turnover: new Intl.NumberFormat('en-US', num_format).format(val.valid_turnover),
                        // rebate_amt: new Intl.NumberFormat('en-US', num_format).format(val.rebate_amt),
                    };
                });
            }

            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

            display =
                <Auxiliary>
                    <div className='breadcrumb-header'>
                        <div className='d-flex'>
                            <div>
                                <div className='btn-group'>
                                    <Button
                                        typeButton='button'
                                        classValue='btn btn-back-outline'
                                        idValue='back'
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler(this.props.stakingsParam.returnPage)}
                                    />
                                    <div>
                                        <div className="member-name">
                                            <FontAwesomeIcon icon={faUser} /> {this.state.memberDetails.username}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row row-sm'>
                        <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mg-b-0'>{t('member-stakings.member-stakings-details')}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {(this.state.loading && this.state.loadOnce)
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <table className='table table-bordered table-hover'>
                                            <tbody>
                                                <tr>
                                                    <th>{t('member-rebate.date')}</th>
                                                    <td>{date[0]}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('member-rebate.member')}</th>
                                                    <td>{this.state.memberDetails.username}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('member-rebate.member-group')}</th>
                                                    <td>VIP {this.state.memberDetails.level}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('member-stakings.member-total-average-balance')}</th>
                                                    <td>{new Intl.NumberFormat('en-US', num_format).format(this.state.memberDetails.total_member_balance / 8)}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('member-stakings.payout')}</th>
                                                    <td>{new Intl.NumberFormat('en-US', num_format).format(total_payout)}</td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    {this.state.loading
                                        ?
                                        <Skeleton count={1} />
                                        :
                                        <div className="mb-0 mt-3 justify-content-end d-flex my-xl-auto right-content">
                                            <Button typeButton='button' classValue='btn btn-blue' idValue='refresh' buttonName={t('global.refresh')} clicked={() => this.refreshHandler()} />
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className='card box-shadow-0'>
                                <div className='card-header'>
                                    <h4 className='card-title mb-1'>{t(`member-stakings.referee-stakings-details`)}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive table-hover datatable'>
                                        <DataTable
                                            keyField='no'
                                            data={rsData}
                                            columns={columns}
                                            activePage={this.state.activePage}
                                            limit={this.state.limit}
                                            totalItems={this.state.totalRecords}
                                            paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                            sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                            searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                            searchValue={this.state.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Auxiliary>;
        }
        return (
            <Auxiliary>
                {display}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        member_staking_payout_detail: state.memberStakings.member_staking_payout_detail,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        getMemberStakingDetail: (mid, date, id) => dispatch(actions.getMemberStakingDetail(mid, date, id)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberStakingDetails));