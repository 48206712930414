import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const gameMaintenanceReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.SHOW]: (state, action) => ({
        ...state,
        show: action.value,
    }),
    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.value,
    }),
    [type.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),
    [type.UPDATE]: (state, action) => ({
        ...state,
        updateSetting: action.value,
    }),
});

export default gameMaintenanceReducer;