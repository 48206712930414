import React, { Component } from "react";
import { connect } from "react-redux";
// import { getSettings, deleteSetting, updateStatus, getDropdown } from "../../store/ducks/goldDigger/actions";
import { getSocialMediaBlastList } from "../../store/ducks/socialMediaBlast/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import SocialMediaBlastListing from "./SocialMediaBlastListing";
import SocialMediaBlastCreate from "./SocialMediaBlastCreate";
import SocialMediaBlastEdit from "./SocialMediaBlastEdit";
import SocialMediaBlastShow from "./SocialMediaBlastShow";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

class SocialMediaBlast extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            socialMediaBlastId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getSocialMediaBlastList(listingArr);
        // this.props.getDropdown();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
            this.setState({
                data: nextProps.rs.data.data.result.row,
                totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                totalPages: nextProps.rs.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getSocialMediaBlastList(listingArr);
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            socialMediaBlastId: id,
            pageShow: "edit",
        });
    };

    showHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            socialMediaBlastId: id,
            pageShow: "show",
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSocialMediaBlastList(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSocialMediaBlastList(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton,viewButton, output;

        if (this.state.permission.includes("social_media_blast_view") && row.status === "Done") {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    key={`view-${row.socialMediaBlastId}`}
                    idValue={row.id === undefined ? 0 : row.socialMediaBlastId}
                    clicked={(event) => this.showHandler(event, row.socialMediaBlastId)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }

        if (this.state.permission.includes("social_media_blast_update") && row.status === "Pending") {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.socialMediaBlastId}`}
                    idValue={row.id === undefined ? 0 : row.socialMediaBlastId}
                    clicked={(event) => this.editHandler(event, row.socialMediaBlastId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [viewButton, editButton];
        return output;
    };

    settingValueFormatter = (cell, row) => {
        const { t } = this.props;
        let settingValue = Object.entries(row.data);
        
        if(row.slug === 'auto-rebate') {
            if(settingValue[0][0] === 'active' && (settingValue[0][1] === '0' || settingValue[0][1] === '1')) {
                let status = settingValue[0][1];
                let stCls = status === '1' ? "active" : "inactive";
                return <select
                        className={`form-control datatable-select-${stCls}`}
                        id={row.settingID}
                        name={row.settingID}
                        onChange={(event) =>
                            this.updateStatusHandler(event)
                        }
                        value={status === null ? 0 : status}
                    >
                        <option value="1">{t("global.active")}</option>
                        <option value="0">{t("global.inactive")}</option>
                    </select>
            }
        }
        return settingValue.map((item, idx) => (
            <span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>
                {item[0]} : {item[1]}
            </span>
        ));
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.fetchData();
            this.props.getSocialMediaBlastList(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.fetchData();
                this.props.getSocialMediaBlastList(listingArr);
            });
        }
    };

    updateStatusHandler = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let data = {};
        data.id = event.target.id;
        data.key = ['active'];
        data.value = [event.target.value];
        // console.log(data);
        this.props.updateStatus(data);
    }

    /* ============ Datatables Function [END] ============ */

    render() {
        // console.log('render (agent): ', this.state);`
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('social-media-blast.start-time'), dataField: "start_time", onSort: this.onSort, sort: true},
            {text: t('global.status'), dataField: "status", sort: false},
            {text: t('global.action'), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: '65px' }},
        ];


        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    socialMediaBlastId: val.socialMediaBlastId,
                    start_time: val.start_time,
                    status: val.status
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && this.state.permission.includes("social_media_blast_access") && (
                    <SocialMediaBlastListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("social_media_blast_create") && this.state.pageShow === "create" && (
                    <SocialMediaBlastCreate
                        dropdown={this.state.dropdown}
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("social_media_blast_update") && this.state.pageShow === "edit" && (
                    <SocialMediaBlastEdit
                        dropdown={this.state.dropdown}                    
                        socialMediaBlastId={this.state.socialMediaBlastId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("social_media_blast_view") && this.state.pageShow === "show" && (
                    <SocialMediaBlastShow
                        dropdown={this.state.dropdown}                    
                        socialMediaBlastId={this.state.socialMediaBlastId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.socialMediaBlast.rs,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getSocialMediaBlastList: (data) => dispatch(getSocialMediaBlastList(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SocialMediaBlast));
