import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { withNamespaces } from "react-i18next";
import TimeField from 'react-simple-timefield';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import Select from "../../components/Input/Select/Select";

import { showPaymentGateway, updatePaymentGateway } from "../../store/ducks/paymentGateway/actions";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";

class PaymentGatewayEdit extends Component {
    state = {
        title: "",
        sequence: "",
        deposit_est_time: "00:00",
        withdrawal_est_time: "00:00",
        loading: true,
        currentDepositStatus: 1,
        currentWithdrawalStatus: 1
    };

    componentDidMount() {
        formValidation("edit_bank_form");

        const showPaymentGatewayData = {};
        showPaymentGatewayData.payment_gateway_id = this.props.show_payment_gateway_id;
        showPaymentGatewayData.country_id = this.props.currentCountryId;
        this.props.showPaymentGateway(showPaymentGatewayData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_payment_gateway_data !== this.props.show_payment_gateway_data && nextProps.show_payment_gateway_data !== undefined) {
            if (nextProps.show_payment_gateway_data.data.responseCode === 200 && nextProps.show_payment_gateway_data.data.msgType === 'success') {
                this.setState({
                    loading: false,
                    title: nextProps.show_payment_gateway_data.data.data[0].paymentGatewayName,
                    sequence: nextProps.show_payment_gateway_data.data.data[0].sequence,
                    deposit_est_time: nextProps.show_payment_gateway_data.data.data[0].deposit_est_time,
                    withdrawal_est_time: nextProps.show_payment_gateway_data.data.data[0].withdrawal_est_time,
                    currentDepositStatus: nextProps.show_payment_gateway_data.data.data[0].depositStatus,
                    currentWithdrawalStatus: nextProps.show_payment_gateway_data.data.data[0].withdrawalStatus
                });
            } else {
                this.setState({
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.show_payment_gateway_data.data.message);
                });
            } 
        }

        if (nextProps.update_payment_gateway_result !== this.props.update_payment_gateway_result && nextProps.update_payment_gateway_result !== undefined) {
            if (nextProps.update_payment_gateway_result.responseCode === 200 && nextProps.update_payment_gateway_result.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_payment_gateway_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_payment_gateway_result.message,
                });
                Swal.close();
            } 
        }

        return true;
    }

    getPaymentGatewayName = (event) => {
        this.setState({ title: event.target.value });
    };

    getPaymentGatewaySequence = (event) => {
        this.setState({ sequence: event.target.value });
    };

    getDepositStatusHandler = (event) => {
        this.setState({
            currentDepositStatus: event.target.value,
        });
    };

    getWithdrawalStatusHandler = (event) => {
        this.setState({
            currentWithdrawalStatus: event.target.value
        })
    }

    getPaymentGatewayDepositEstTime = (event) => {
        this.setState({ deposit_est_time: event.target.value });
    };

    getPaymentGatewayWithdrawalEstTime = (event) => {
        this.setState({ withdrawal_est_time: event.target.value });
    };

    updatePaymentGatewayHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};

                data.title = this.state.title;
                data.sequence = this.state.sequence;
                data.deposit_est_time = this.state.deposit_est_time;
                data.withdrawal_est_time = this.state.withdrawal_est_time;
                data.id = this.props.show_payment_gateway_id;
                data.dp_status = Number(this.state.currentDepositStatus);
                data.wd_status = Number(this.state.currentWithdrawalStatus);
                this.props.updatePaymentGateway(data);
            }
        });
    };

    render() {
        const { t } = this.props;

        let statusArray = [
            {
                label: t("global.active"),
                value: 1,
                selected: true,
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("payment-gateway.payment-gateway")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("payment-gateway.edit-payment-gateway")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <form method="post" id="edit_payment_gateway_form" className="form-horizontal" onSubmit={this.updatePaymentGatewayHandler} noValidate>
                                    <ValidationError formName="edit_payment_gateway_form" touched={this.state.touched} message={this.state.error_message} />
                                    {this.state.loading === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="title">{t("payment-gateway.payment-gateway-name")}</label>

                                                <StringInput
                                                    onChange={this.getPaymentGatewayName}
                                                    id="title"
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.payment-gateway-name")}
                                                    value={this.state.title}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.sequence")}</label>

                                                <NumberInput
                                                    onChange={this.getPaymentGatewaySequence}
                                                    id="sequence"
                                                    name="sequence"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.sequence")}
                                                    value={this.state.sequence}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.deposit-est-time")}</label>

                                                <TimeField
                                                    id='deposit_est_time'
                                                    value={this.state.deposit_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                    onChange={this.getPaymentGatewayDepositEstTime} // {Function} required
                                                    input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                    colon=":"                          // {String}   default: ":"
                                                    showSeconds                        // {Boolean}  default: false
                                                    //style={{ width: '48%', display: 'inline' }}
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.withdrawal-est-time")}</label>

                                                <TimeField
                                                    id='withdrawal_est_time'
                                                    value={this.state.withdrawal_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                    onChange={this.getPaymentGatewayWithdrawalEstTime} // {Function} required
                                                    input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                    colon=":"                          // {String}   default: ":"
                                                    showSeconds                        // {Boolean}  default: false
                                                    //style={{ width: '48%', display: 'inline' }}
                                                />
                                            </div>

                                            <div className="col-md-4 col-12 form-group">
                                                <label htmlFor="depositStatus">{t("payment-gateway.deposit-status")}</label>
                                                <Select
                                                    id="depositStatus"
                                                    options={statusArray}
                                                    className="form-control"
                                                    name="depositStatus"
                                                    onChange={this.getDepositStatusHandler}
                                                    value={this.state.currentDepositStatus}
                                                />
                                            </div>

                                            <div className="col-md-4 col-12 form-group">
                                                <label htmlFor="withdrawalStatus">{t("payment-gateway.withdrawal-status")}</label>
                                                <Select
                                                    id="withdrawalStatus"
                                                    options={statusArray}
                                                    className="form-control"
                                                    name="withdrawalStatus"
                                                    onChange={this.getWithdrawalStatusHandler}
                                                    value={this.state.currentWithdrawalStatus}
                                                />
                                            </div>

                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <div>
                                                    <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { paymentGateway, currentUser } = state;

    //Getting the state from the reducers
    return {
        show_payment_gateway_data: paymentGateway.show_payment_gateway_data,
        update_payment_gateway_result: paymentGateway.update_payment_gateway_result,
        currentUsername: currentUser.data.username,
        currentCountryId: currentUser.data.country_id
    };
};

const mapDispatchToProps = {
    showPaymentGateway,
    updatePaymentGateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PaymentGatewayEdit));
