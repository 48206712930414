import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../assets/images/updown-arrow.png'

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Button from "../../components/Button/Button";

class MemberStakingsHistory extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            (this.props.data.activePage - 1) * this.props.data.limit +
            (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsData = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" }, },
            // {text: 'trans.id', dataField: 'id'},
            { text: t("member-rebate.order-id"), dataField: "order_id", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName), headerStyle: { width: "115px" } },
            { text: t("member-rebate.member"), dataField: "username", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-rebate.member-group"), dataField: "group_name", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-stakings.total-member-balance"), dataField: "total_member_balance", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-stakings.payout"), dataField: "total_payout", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            // {text: t("member-rebate.rebate-amount"), dataField: "rebate_amt", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName)},
            // {text: t("member-rebate.rebate-tbg"), dataField: "rtbg", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName)},
            // {text: 'STATUS', dataField: 'status', sort: true, onSort: (field, order, tab)=>this.props.onSort(field, order, this.props.tabName)},
            { text: t("member-rebate.transaction-date"), dataField: "transaction_date", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-rebate.create-date"), dataField: "create_date", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-rebate.update-date"), dataField: "update_date", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-rebate.update-by"), dataField: "update_by", sort: true, onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-rebate.remark"), dataField: "remark" },
        ];

        if (this.props.data.data) {
            rsData = this.props.data.data.map((val, idx) => {
                const num_format = {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                };
                let orderIdVal = permission.includes(
                    "transaction_details_access"
                ) ? (
                    <b>
                        <a href="/#" onClick={(event) => this.props.orderDetailsHandler(event, val.id)}>
                            {val.order_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                );
                // let turnoverVal = permission.includes(
                //     "member_rebate_rollover_details"
                // ) ? (
                //     <b>
                //         <a href="/#" onClick={(event) => this.props.turnoverDetailsHandler(event, val.mid, /*this.props.data.date,*/ val.transaction_date, val.id)}>
                //             {new Intl.NumberFormat("en-US", num_format).format(val.turnover)}<i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                //         </a>
                //     </b>
                // ) : (
                //     new Intl.NumberFormat("en-US", num_format).format(val.turnover)
                // );
                let payoutVal = permission.includes(
                    'member_stakings_details_access'
                ) ? (
                    <b>
                        <a href='/#' onClick={(event) => this.props.stakingPayoutDetailsHandler(event, val.mid, val.transaction_date, val.mid)}>
                            {new Intl.NumberFormat('en-US', num_format).format(val.total_payout)}
                            <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                        </a>
                    </b>
                ) : (
                    new Intl.NumberFormat('en-US', num_format).format(val.total_payout)
                );

                // let st_val = <span className={`badge badge-pill badge-${st_cls[val.status]}`}>{t(`transaction.status-desc.${val.status}`)}</span>;

                return {
                    no: idx,
                    order_id: orderIdVal,
                    username: val.username,
                    group_name: val.group_name,
                    total_member_balance: new Intl.NumberFormat("en-US", num_format).format(val.total_member_balance / 8),
                    total_payout: payoutVal,
                    // rebate_amt: new Intl.NumberFormat("en-US", num_format).format(val.rebate_amt),
                    // last_rebate_date: val.last_rebate_date,
                    // rtbg: new Intl.NumberFormat("en-US", num_format).format(val.rtbg),
                    // status: st_val,
                    transaction_date: val.transaction_date,
                    create_date: val.create_date,
                    update_date: val.update_date,
                    update_by: val.update_by,
                    remark: val.remark,
                };
            });
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.data.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="filter_form"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-2`}
                                    >
                                        {/* <div className='row'> */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "member-rebate.transaction-date"
                                                    )}
                                                </label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check"></i>
                                                        </span>
                                                    </div>
                                                    {/* <input className='form-control filter-input' id='filter_date' name='filter_date' type='text' required /> */}
                                                    <DateTimePicker
                                                        id="date_range"
                                                        name="date_range"
                                                        className="form-control filter-input"
                                                        format="YYYY-MM-DD"
                                                        // singleDatePicker={true}
                                                        // autoUpdateInput={true}
                                                        range={true}
                                                        // timePicker={true}
                                                        value={this.props.data.filterInput.date_range}
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                        maxDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member-rebate.member")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={
                                                        this.props.data.filterInput
                                                            .username
                                                    }
                                                    onChange={(event) =>
                                                        this.props.onInputChange(
                                                            event,
                                                            this.props.tabName,
                                                            "filterInput"
                                                        )
                                                    }
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler(
                                                            this.props.tabName
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member-rebate.member-group")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="member_group"
                                                        name="member_group"
                                                        onChange={(event) =>
                                                            this.props.onInputChange(
                                                                event,
                                                                this.props.tabName,
                                                                "filterInput"
                                                            )
                                                        }
                                                        value={
                                                            this.props.data.filterInput
                                                                .member_group
                                                        }
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddMemberGroup &&
                                                            this.props.ddMemberGroup.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={idx}
                                                                            value={val.id}
                                                                        >
                                                                            {val.group_name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler(
                                                            this.props.tabName
                                                        )
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler(
                                                            this.props.tabName
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("member-rebate.rebate-history")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsData}
                                        columns={columns}
                                        activePage={this.props.data.activePage}
                                        limit={this.props.data.limit}
                                        totalItems={
                                            this.props.data.totalRecords
                                        }
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                this.props.tabName,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                this.props.tabName,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                this.props.tabName,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={
                                            this.props.data.searchValue
                                        }
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.data.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberStakingsHistory);
