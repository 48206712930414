import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const voucherReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.STORE]: (state, action) => ({
        ...state,
        store: action.payload,
    }),

    [type.EDIT]: (state, action) => ({
        ...state,
        edit: action.payload
    }),

    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.payload
    }),

    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.payload,
    }),

    [type.ADD_VOUCHER_MEMBER]: (state, action) => ({
        ...state,
        addVoucherMember: action.payload
    }),

    [type.GET_VOUCHER_MEMBER]: (state, action) => ({
        ...state,
        getVoucherMember: action.payload
    }),

    [type.SHOW_VOUCHER]: (state, action) => ({
        ...state,
        showVoucherData: action.payload
    }),

    [type.DELETE_VOUCHER_MEMBER]: (state, action) => ({
        ...state,
        delete_voucher_member: action.payload,
    }),

    [type.ADD_SPECIFIC_VOUCHER_MEMBER]: (state, action) => ({
        ...state,
        add_specific_voucher_member: action.payload,
    })
});

export default voucherReducer;