import React, { Component } from "react";
import { connect } from "react-redux";
import { showEmailBlast } from "../../store/ducks/emailBlast/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import DomPurify from "../../components/Sanitize/DomPurify";

class EmailBlastShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            members: []
        };
    }

    componentDidMount() {
        this.props.showEmailBlast(this.props.emailBlastId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContentShow] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.show !== undefined && nextProps.show !== this.props.show && nextState.loading === true) {
            let memberData = JSON.parse(nextProps.show.data.member_id);
            this.setState({
                loading: false,
                members: memberData
            });
        }
        return true;
    }

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            let emailBlastData = this.props.show.data;
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-blast.email-blast-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("email-blast.start-time")}</th>
                                            <td field-key="start_time">{emailBlastData.start_time}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("email-blast.title")}</th>
                                            <td field-key="title">{emailBlastData.title}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("email-blast.details")}</th>
                                            <td field-key="content" className="email-content">
                                                {/* <textarea className="form-control" defaultValue={emailContentData.content} cols="120" rows="20" disabled></textarea> */}
                                                {/* <span dangerouslySetInnerHTML={{ __html: emailContentData.content }} /> */}
                                                <DomPurify text={emailBlastData.details} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("email-blast.members")}</th>
                                            <td field-key="content" className="email-content">
                                                
                                               {this.state.members.map(function(val, index) {
                                                    return <li>{val.label}</li>
                                               })}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.email-content-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-content.email-content")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { emailBlast } = state;

    return {
        show: emailBlast.show,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showEmailBlast: (id) => dispatch(showEmailBlast(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailBlastShow));
