import axios from "../../../common/config/axios";
import * as type from "./types";

export const getListing = (params) => (dispatch, getState) => {
    axios.get("mobile_apps/get_list", {params:params})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Get Mobile Apps Listing Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const upload = (data) => (dispatch, getState) => {
    axios.post("mobile_apps/upload", data)
        .then((res) => {
            dispatch({
                type: type.UPLOAD,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Mobile Apps Error\n", err);
            dispatch({
                type: type.UPLOAD,
                payload: err.data,
            });
        });
};

export const updateStatus = (data) => (dispatch, getState) => {
    axios.post("mobile_apps/update_status", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Mobile Apps Update Status Error\n", err);
            dispatch({
                type: type.UPDATE_STATUS,
                payload: err.data,
            });
        });
};