import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const seoReducer = createReducer(initialState) ({
    [type.META_TAG_LIST]: (state, action) => ({
        ...state,
        meta_tag_data: action.payload
    }),

    [type.META_TAG_SHOW]: (state, action) => ({
        ...state,
        meta_tag_show_data: action.payload
    }),

    [type.META_TAG_CREATE]: (state, action) => ({
        ...state,
        meta_tag_create_data: action.payload
    }),

    [type.META_TAG_STORE]: (state, action) => ({
        ...state,
        meta_tag_store_result: action.payload
    }),

    [type.META_TAG_EDIT]: (state, action) => ({
        ...state,
        meta_tag_edit_data: action.payload
    }),

    [type.META_TAG_UPDATE]: (state, action) => ({
        ...state,
        meta_tag_update_result: action.payload
    }),

    [type.META_TAG_UPDATE_STATUS]: (state, action) => ({
        ...state,
        meta_tag_update_status_result: action.payload
    }),

    [type.META_TAG_DELETE]: (state, action) => ({
        ...state,
        meta_tag_delete_result: action.payload
    }),

    [type.XML_SITEMAP]: (state, action) => ({
        ...state,
        xml_sitemap_data: action.payload
    }),

    [type.XML_SITEMAP_GENERATE]: (state, action) => ({
        ...state,
        xml_sitemap_generate_data: action.payload
    }),

    [type.ROBOTS_TXT_LIST]: (state, action) => ({
        ...state,
        robots_txt_data: action.payload
    }),

    [type.ROBOTS_TXT_EDIT]: (state, action) => ({
        ...state,
        robots_txt_edit_data: action.payload
    }),

    [type.ROBOTS_TXT_STORE]: (state, action) => ({
        ...state,
        robots_txt_store_result: action.payload
    }),

    [type.ROBOTS_TXT_UPDATE]: (state, action) => ({
        ...state,
        robots_txt_update_result: action.payload
    }),

    [type.ROBOTS_TXT_UPDATE_RULE]: (state, action) => ({
        ...state,
        robots_txt_update_rule_result: action.payload
    }),

    [type.ROBOTS_TXT_DELETE]: (state, action) => ({
        ...state,
        robots_txt_delete_result: action.payload
    }),

    [type.WEB_MASTER_LIST]: (state, action) => ({
        ...state,
        web_master_data: action.payload
    }),

    [type.WEB_MASTER_UPDATE]: (state, action) => ({
        ...state,
        web_master_update_result: action.payload
    }),

    [type.OPEN_GRAPH_LIST]: (state, action) => ({
        ...state,
        open_graph_data: action.payload
    }),

    [type.OPEN_GRAPH_UPDATE]: (state, action) => ({
        ...state,
        open_graph_update_result: action.payload
    }),

    [type.DEMO_SEO]: (state, action) => ({
        ...state,
        seo_result: action.payload
    }),

    [type.STATIC_SEO]: (state, action) => ({
        ...state,
        static_seo_result: action.payload
    }),

    [type.DYNAMIC_SEO]: (state, action) => ({
        ...state,
        dynamic_seo_result: action.payload
    }),
});

export default seoReducer;