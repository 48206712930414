import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberTransactionHistory, getTransactionHistoryFilterData, setMemberShowLocalState, setMemberShowInitialLoading } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import MemberTransactionHistoryListing from "./MemberTransactionHistoryListing";
import TransactionDetails from "../../Transaction/TransactionDetails";
import { filterErrorHandler } from "../../../hoc/Shared/utility";

const initialInputState = {
    transaction_date: "",
    status: "",
    transaction_type: "",
};

class MemberTransactionHistory extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "transaction_history");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                currentPage: "transaction_history",
                pageShow: "member_transaction_listing",
                loading: true,
                reload: false,
                show_id: null,
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                totalRecords: 0,
                searchValue: "",
                filter: initialInputState,
                data: [],
                dropDown: {
                    status: [],
                    transactionType: [],
                },
                detailsParam: {},
            };
            this.props.setMemberShowLocalState(this.state);
        } else {
            this.state = this.props.memberTransactionHistoryLocalState;
        }

        this.onInputChange = this.onInputChange.bind(this);
        // console.log("[MemberTransactionHistory] constructor()", "\nprops: ",props, "\nstate: ", this.state);
    }

    componentDidMount() {
        // console.log("[MemberTransactionHistory] componentDidMount()");
        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "transaction_history");
        const initialLoading = initLoad[0].loading;

        if(initialLoading) {
            const listingArr = this.getFilterArray();
            this.props.getMemberTransactionHistory(listingArr);
            this.props.getTransactionHistoryFilterData();

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.memberTransactionHistoryData !== undefined) {
                if (this.props.memberTransactionHistoryData.data && this.props.memberTransactionHistoryData.data.data 
                    && this.props.memberTransactionHistoryData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberTransactionHistoryData.data.data.memberId)) {
                    const listingArr = this.getFilterArray();
                    this.props.getMemberTransactionHistory(listingArr);
                    this.props.getTransactionHistoryFilterData();
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                const listingArr = this.getFilterArray();
                this.props.getMemberTransactionHistory(listingArr);
                this.props.getTransactionHistoryFilterData();
            }
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberTransactionHistory] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.memberShowId !== undefined && nextProps.memberTransactionHistoryData !== undefined) {
            if(nextProps.memberTransactionHistoryData !== this.props.memberTransactionHistoryData) {
                if (nextProps.memberTransactionHistoryData.data.responseCode === 200 && nextProps.memberTransactionHistoryData.data.msgType === "success") {
                    Swal.close();
                    this.setState({
                        show_id: nextProps.memberShowId,
                        data: nextProps.memberTransactionHistoryData.data.data.transaction.row,
                        totalRecords: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_record,
                        totalPages: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_page,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        show_id: null,
                        data: [],
                        totalRecords: 0,
                        totalPages: 1,
                        loading: false
                    }, () => {
                        filterErrorHandler(nextProps.memberTransactionHistoryData.data.message)
                    })
                }
            }
        }

        // if (
        //     !nextState.reload &&
        //     nextProps.memberShowId !== undefined &&
        //     nextProps.memberTransactionHistoryData !== undefined &&
        //     nextProps.memberTransactionHistoryData !== this.props.memberTransactionHistoryData
        // ) {
        //     if (nextProps.memberTransactionHistoryData.status === 200 && nextProps.memberTransactionHistoryData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberTransactionHistoryData.data.data.transaction.row,
        //             totalRecords: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_record,
        //             totalPages: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberTransactionHistoryData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";

        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // } else if (
        //     nextState.reload &&
        //     nextProps.memberTransactionHistoryData.data.data.transaction.row !== nextState.data &&
        //     nextProps.memberTransactionHistoryData !== undefined
        // ) {
        //     if (nextProps.memberTransactionHistoryData.status === 200 && nextProps.memberTransactionHistoryData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberTransactionHistoryData.data.data.transaction.row,
        //             totalRecords: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_record,
        //             totalPages: nextProps.memberTransactionHistoryData.data.data.transaction.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberTransactionHistoryData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";
        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // }

        if (nextProps.memberTransactionHistoryFilterData !== undefined && nextState.dropDown.status.length === 0) {
            if(nextProps.memberTransactionHistoryFilterData !== this.props.memberTransactionHistoryFilterData) {
                if (nextProps.memberTransactionHistoryFilterData.data.responseCode === 200 && nextProps.memberTransactionHistoryFilterData.data.msgType === "success") {
                    this.setState({
                        dropDown: {
                            status: nextProps.memberTransactionHistoryFilterData.data.data.status,
                            transactionType: nextProps.memberTransactionHistoryFilterData.data.data.transaction_type,
                        },
                    });
                    Swal.close();
                }
                else {
                    filterErrorHandler(nextProps.memberTransactionHistoryFilterData.data.message);
                }  
            }
        }
        return true;
    }

    componentWillUnmount() {
        // console.log("[MemberTransactionHistory] componentWillUnmount()", "\nstate: ", this.state);
        this.props.setMemberShowLocalState(this.state);
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: "member_transaction_details",
            detailsParam: {
                id: id,
            },
        });
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: ""
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberTransactionHistory(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                searchValue: "",
                order: "",
                filter: newFilter,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberTransactionHistory(listingArr);
            }
        );
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        listingArr.member_id = this.props.memberShowId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberTransactionHistory(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            // errMsg: [],
            // touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberTransactionHistory(listingArr);
        });
    };

    render() {
        // const { t } = this.props;
        return (
            <Auxiliary>
                {this.state.pageShow === "member_transaction_listing" && (
                    <MemberTransactionHistoryListing
                        filter={this.state.filter}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        ddStatus={this.state.dropDown.status}
                        ddTransactionType={this.state.dropDown.transactionType}
                        loading={this.state.loading}
                    />
                )}

                {this.state.pageShow === "member_transaction_details" && (
                    <TransactionDetails detailsParam={this.state.detailsParam} changePageHandler={(page) => this.changePageHandler(page)} returnPage="member_transaction_listing" />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberTransactionHistoryLocalState: member.member_transaction_history_local_state,

        memberTransactionHistoryData: member.member_transaction_history_data,
        memberTransactionHistoryFilterData: member.member_transaction_history_filter_data,
        currentCountryId: state.currentUser.data.country_id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),

        getMemberTransactionHistory: (data) => dispatch(getMemberTransactionHistory(data)),
        getTransactionHistoryFilterData: () => dispatch(getTransactionHistoryFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberTransactionHistory));
