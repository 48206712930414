import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MemberInfo from "./MemberInfo";
import MemberTransactionHistory from "./MemberTransactionHistory";
// import MemberBettingHistory from "./MemberBettingHistory";
import MemberLoginInfo from "./MemberLoginInfo";
import MemberCryptoAddress from "./MemberCryptoAddress";
import MemberLevelTracker from "./MemberLevelTracker";
import MemberWalletTracker from "./MemberWalletTracker";
import MemberCoinTracker from "./MemberCoinTracker";
import MemberAddress from "./MemberAddress";
import MemberParcel from "./MemberParcel";

class MemberShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // General
            currentPage: "memberShow",
            pageShow: "member_info",
            memberShowId: null,
            display: true,
            initialLoading: [
                { id: "member_info", loading: true },
                { id: "transaction_history", loading: true },
                // { id: "betting_history", loading: true },
                { id: "login_info", loading: true },
                { id: "crypto_address", loading: true },
                { id: "level_tracker", loading: true },
                { id: "wallet_tracker", loading: true },
                { id: "coin_tracker", loading: true },
                { id: "member_address", loading: true },
                { id: "member_parcel", loading: true }
            ],
        };
        this.props.initMemberShowInitialLoading(this.state.initialLoading);
    }

    componentDidMount() {
        // console.log("[MemberShow] componentDidMount()", this.props);
        this.setState({
            memberShowId: this.props.memberShowId,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberShow] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let tabId = this.state.pageShow;

        return (
            <Auxiliary>
                {(tabId === "member_info" ||
                    tabId === "transaction_history" ||
                    // tabId === "betting_history" ||
                    tabId === "login_info" ||
                    tabId === "crypto_address" ||
                    tabId === "level_tracker" ||
                    tabId === "wallet_tracker" ||
                    tabId === "coin_tracker" || 
                    tabId === "member_address" ||
                    tabId === "member_parcel") && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary tabs-style-2 mt-3">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav panel-tabs main-nav-line">
                                                {this.props.permission.includes("member_view") && (
                                                    <li>
                                                        <button
                                                            id="member_info"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "member_info" ? "active" : ""}`}>
                                                            {t("member.member-info")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("transaction_access") && (
                                                    <li>
                                                        <button
                                                            id="transaction_history"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "transaction_history" ? "active" : ""}`}>
                                                            {t("member.transaction-history")}
                                                        </button>
                                                    </li>
                                                )}

                                                {/* {this.props.permission.includes("member_betting_history") && (
                                                    <li>
                                                        <button
                                                            id="betting_history"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "betting_history" ? "active" : ""}`}>
                                                            {t("member.betting-history")}
                                                        </button>
                                                    </li>
                                                )} */}

                                                {this.props.permission.includes("member_login_info") && (
                                                    <li>
                                                        <button
                                                            id="login_info"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "login_info" ? "active" : ""}`}>
                                                            {t("member.login-info")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_crypto_access") && (
                                                    <li>
                                                        <button
                                                            id="crypto_address"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "crypto_address" ? "active" : ""}`}>
                                                            {t("member.crypto-address")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_profile_level_tracker") && (
                                                    <li>
                                                        <button
                                                            id="level_tracker"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "level_tracker" ? "active" : ""}`}>
                                                            {t("member.level-tracker")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_wallet_tracker") && (
                                                    <li>
                                                        <button
                                                            id="wallet_tracker"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "wallet_tracker" ? "active" : "'"}`}>
                                                            {t("member.wallet-tracker")}
                                                        </button>
                                                    </li>
                                                )}

                                                {/* {this.props.permission.includes("member_coin_tracker") && (
                                                    <li>
                                                        <button
                                                            id="coin_tracker"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0}}
                                                            className={`nav-link bg-white ${tabId === "coin_tracker" ? "active" : ""}`}>
                                                            {t("member.coin-tracker")}
                                                        </button>
                                                    </li>
                                                )} */}

                                                {this.props.permission.includes("member_addresses_access") && (
                                                    <li>
                                                        <button
                                                            id="member_address"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0}}
                                                            className={`nav-link bg-white ${tabId === "member_address" ? "active" : ""}`}>
                                                            {t("member-address.title")}
                                                        </button>
                                                    </li>
                                                )}

                                                {this.props.permission.includes("member_parcel_access") && (
                                                    <li>
                                                        <button
                                                            id="member_parcel"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0}}
                                                            className={`nav-link bg-white ${tabId === "member_parcel" ? "active" : ""}`}>
                                                            {t("member-parcel.title")}
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler("listing")}
                                />
                                <div>
                                    <div className="member-name">
                                        <FontAwesomeIcon icon={faUser} /> {this.props.memberShowUsername}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.permission.includes("member_update") && tabId === "member_info" && (
                    <MemberInfo
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("transaction_access") && tabId === "transaction_history" && (
                    <MemberTransactionHistory
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {/* {this.props.permission.includes("member_betting_history") && tabId === "betting_history" && (
                    <MemberBettingHistory
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )} */}

                {this.props.permission.includes("member_login_info") && tabId === "login_info" && (
                    <MemberLoginInfo
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_crypto_access") && tabId === "crypto_address" && (
                    <MemberCryptoAddress
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_profile_level_tracker") && tabId === "level_tracker" && (
                    <MemberLevelTracker
                        memberShowId={this.props.memberShowId}
                        memberShowCount={this.props.memberShowCount}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_wallet_tracker") && tabId === "wallet_tracker" && (
                    <MemberWalletTracker
                        memberShowId={this.props.memberShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_coin_tracker") && tabId === "coin_tracker" && (
                    <MemberCoinTracker 
                        memberShowId={this.props.memberShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_addresses_access") && tabId === "member_address" && (
                    <MemberAddress 
                        memberShowId={this.props.memberShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {this.props.permission.includes("member_parcel_access") && tabId === "member_parcel" && (
                    <MemberParcel
                        memberShowId={this.props.memberShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member, currentUser } = state;

    return {
        memberShowData: member.member_show_data,

        // memberTransactionHistoryData: member.member_transaction_history_data,
        // memberTransactionHistoryFilterData: member.member_transaction_history_filter_data,
        // memberBettingHistoryData: member.member_betting_history_data,
        // memberBettingHistoryFilterData: member.member_betting_history_filter_data,
        // memberLoginInfoData: member.member_login_info_data,
        // memberDepositCryptoData: member.member_deposit_crypto_data,
        // memberWithdrawalCryptoData: member.member_withdrawal_crypto_data,
        // memberLevelTrackerData: member.member_level_tracker_data,
        // memberLevelTrackerHistoryData: member.member_level_tracker_history_data,

        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMember: (id) => dispatch(actions.showMember(id)),
        initMemberShowInitialLoading: (data) => dispatch(actions.initMemberShowInitialLoading(data)),

        // getMemberTransactionHistory: (data) => dispatch(actions.getMemberTransactionHistory(data)),
        // getTransactionHistoryFilterData: () => dispatch(actions.getTransactionHistoryFilterData()),
        // getMemberBettingHistory: (data) => dispatch(actions.getMemberBettingHistory(data)),
        // getBettingHistoryFilterData: () => dispatch(actions.getBettingHistoryFilterData()),
        // getMemberLoginInfo: (data) => dispatch(actions.getMemberLoginInfo(data)),
        // getMemberDepositCrypto: (data) => dispatch(actions.getMemberDepositCrypto(data)),
        // getMemberWithdrawalCrypto: (data) => dispatch(actions.getMemberWithdrawalCrypto(data)),
        // getMemberLevelTracker: (data) => dispatch(actions.getMemberLevelTracker(data)),
        // getMemberLevelTrackerHistory: (data) => dispatch(actions.getMemberLevelTrackerHistory(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberShow));
