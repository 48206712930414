import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../../components/Input/StringInput/StringInput";
// import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import Button from "../../../components/Button/Button";
// import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
// import Skeleton from "react-loading-skeleton";
// import StringInput from "../../../components/Input/StringInput/StringInput";
// import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

class MemberCryptoDepositListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = this.props.totalRecords - rowIndex - (this.props.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    render() {
        const { t } = this.props;
        let dataListing = [];
        let st = { 0: t("global.inactive"), 1: t("global.active") };
        let st_cls = { 0: "danger", 1: "success" };

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("member.crypto"), dataField: "crypto", sort: true, onSort: this.props.onSort },
            { text: t("member.network"), dataField: "network", sort: true, onSort: this.props.onSort },
            { text: t("member.address"), dataField: "address", sort: true, onSort: this.props.onSort },
            { text: t("global.status"), dataField: "status", sort: true, onSort: this.props.onSort },
            { text: t("global.created-time"), dataField: "createdDate", sort: true, onSort: this.props.onSort },
        ];

        if (this.props.data) {
            dataListing = this.props.data.map((val, idx) => {
                let st_val = "";
                st_val = <span className={`badge badge-pill badge-${st_cls[val.status]}`}>{st[val.status]}</span>;
                return {
                    no: idx,
                    crypto: val.crypto,
                    network: val.network,
                    address: val.address,
                    status: st_val,
                    createdDate: val.createdDate,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member.withdrawal-crypto-address")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={dataListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberCryptoDepositListing);
