import * as types from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const coinSettingReducer = createReducer(initialState) ({
    [types.LISTING]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [types.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [types.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),
    [types.UPDATE]: (state, action) => ({
        ...state,
        update: action.value,
    }),
});

export default coinSettingReducer;