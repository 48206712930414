import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPen } from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import PromotionListing from "./PromotionListing";
import PromotionCreate from "./PromotionCreate";
import PromotionShow from "./PromotionShow";
import PromotionEdit from "./PromotionEdit";
import { deletePromotion, getPromotion, getDropDown, updatePromotionStatus, updatePromotionShow } from "../../store/ducks/promotion/actions";
import Select from "../../components/Input/Select/Select";


const initialInputState =
{
    /* form input value */
    title: '',
    status: '',
    category: ''
};

let promotionData = {};
class Promotion extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        dropDown: [],

        filter: initialInputState,

        currentPromotionId: '',
        loading: true,
        currentImage: '',

        permission: [],
    };

    fetchData() {
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }

        promotionData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        promotionData.limit = this.state.limit;
        promotionData.order = JSON.stringify(this.state.sorted);
        promotionData.lang_id = this.props.currentLanguageId;
        promotionData.title = this.state.filter.title
        promotionData.status = this.state.filter.status
        promotionData.category = this.state.filter.category
        promotionData.country_id = this.props.currentCountryId;

        this.props.getPromotion(promotionData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let promotionPermission = this.props.currentPermission.filter((item) => item.includes("promotion_"));
            this.setState({ permission: promotionPermission });
        }

        if (nextProps.promotion_data !== this.props.promotion_data) {
            // console.log(nextProps.promotion_data.data.data);

            if (nextProps.promotion_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.promotion_data.data.data,
                    totalRecords: nextProps.promotion_data.data.totalRecords,
                    totalPages: nextProps.promotion_data.data.totalPages,
                })
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.promotion_data.data.message[0],
                    icon: nextProps.promotion_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }


        }

        if (nextProps.dropDown !== this.props.dropDown) {
            if (nextProps.dropDown.responseCode === 200) {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                })
            } else {
                this.setState({ loading: false });
            }
        }

        //     if (nextProps.delete_promotion_result !== this.props.delete_promotion_result){
        //         if(nextProps.delete_promotion_result.responseCode === 200) {
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_promotion_result.message,
        //             icon: nextProps.delete_promotion_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.fetchData();
        //             }
        //         });
        //     }else{
        //         this.setState({ loading: false });
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_promotion_result.message[0],
        //             icon: nextProps.delete_promotion_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         })
        //     }
        // }

        if (nextProps.update_status_promotion_result !== this.props.update_status_promotion_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_promotion_result.msgType,
                confirmButtonText: t("global.ok"),
            })
            // console.log(nextProps.update_status_promotion_result);
            this.fetchData();
            // this.setState({ loading: false });
        }

        if (nextProps.update_show_promotion_result !== this.props.update_show_promotion_result) {
            this.setState({ loading: false });
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_show_promotion_result.msgType,
                confirmButtonText: t("global.ok"),
            })
            // console.log(nextProps.update_show_promotion_result);
            this.fetchData();
            // this.setState({ loading: false });
        }


        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    updateStatusPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        });

        const updatePromotionStatusData = {};
        updatePromotionStatusData.promotion_id = promotionId;
        updatePromotionStatusData.status = Number(event.target.value);

        this.props.updatePromotionStatus(updatePromotionStatusData);
    }

    updateShowPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        });
        const updatePromotionShowData = {};
        updatePromotionShowData.promotion_id = promotionId;
        updatePromotionShowData.show = Number(event.target.value);

        this.props.updatePromotionShow(updatePromotionShowData);
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter
        })
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, () => {
            const payload = {
                ...promotionData,
                ...this.state.filter
            }
            this.props.getPromotion(payload)
        });
    }

    clearHandler = () => {
        this.setState({
            activePage: 1,
            filter: initialInputState,
            loading: true,
            data: [],
        }, () => {
            let payload = {
                ...promotionData,
                page: 1,
                ...this.state.filter
            }
            this.props.getPromotion(payload)
        });
    }

    // deletePromotionHandler = (e, promotion_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deletePromotionData = {};
    //             deletePromotionData.id = promotion_id;

    //             this.props.deletePromotion(deletePromotionData);
    //         }
    //     });
    // }

    statusFormatter = (cell, row) => {

        const { t } = this.props;

        if (this.state.permission.includes("promotion_change_status")) {

            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="promotionStatus"
                    name="promotionStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.promotion_id}`}
                    onChange={(event) => {
                        this.updateStatusPromotionHandler(event, row.promotion_id)
                    }}
                    className={dtClassName}
                />
            )
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }

    showFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("promotion_show_hide")) {

            let showArray, dtClassName;

            showArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.show === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="promotionShow"
                    name="promotionShow"
                    options={showArray}
                    defaultValue={row.show}
                    key={`show-${row.promotion_id}`}
                    onChange={(event) => {
                        this.updateShowPromotionHandler(event, row.promotion_id)
                    }}
                    className={dtClassName}
                />
            )
        } else {
            let badgeClassName, show;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                show = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                show = t("global.active");
            }
            return (
                <span className={badgeClassName}>{show}</span>
            )
        }
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;

        if (this.state.permission.includes("promotion_view")) {
            viewButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                    key={`view-${row.promotion_id}`}
                    clicked={() => this.changePageHandler('show', row.promotion_id)}
                />;
        }

        if (this.state.permission.includes("promotion_edit")) {
            editButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.promotion_id}`}
                    clicked={() => this.changePageHandler('edit', row.promotion_id)}
                />;
        }

        // if (this.state.permission.includes("promotion_delete")) {
        // deleteButton =
        //     <Button
        //         typeButton="button"
        //         classValue="btn btn-sm btn-danger mr-2"
        //         clicked={(event) => this.deletePromotionHandler(event, row.promotion_id)}
        //         key={`delete-${row.promotion_id}`}
        //         buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //     />;
        // }

        output = [viewButton, editButton];
        return output;
    }

    categoryFormatter = (cell, row) => {
        const { t } = this.props;
        let categoryValue = Object.entries(row.category);
        return categoryValue.map((item, idx) => (
            <span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>{t('promotion-type.' + item[1])} </span>
        ));
    }

    changePageHandler = (page, promotionId) => {
        this.setState({
            pageShow: page,
            currentPromotionId: promotionId,
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    }

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    }

    imageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                className='tb-img'
                src={cell}
                onClick={() => this.setState({ isOpen: true, currentImage: cell })}
                key={`desktop-image-${row.promotion_id}`}
            />
        );
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "promotion_id",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let reverseRowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
                    return <span>{reverseRowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: '60px' };
                }
            },
            {
                text: t('promotion.promotion-id'),
                dataField: "promotion_id",
                keyField: "promotionId",
                sort: true,
                onSort: this.onSort
                // hidden: true
            },
            {
                text: t('promotion.sequence'),
                dataField: "sequence",
                keyField: "sequence",
                sort: true,
                onSort: this.onSort
                // hidden: true
            },
            {
                text: t('promotion.promotion-id'),
                dataField: "promotion_id",
                keyField: "promotionId",
                sort: true,
                onSort: this.onSort
                // hidden: true
            },
            {
                text: t('promotion.image'),
                dataField: "image",
                keyField: "promotion-image",
                formatter: this.imageFormatter,
                headerStyle: () => {
                    return { width: '195px' };
                }
            },
            {
                text: t('promotion.title'),
                dataField: "title",
                keyField: "promotion-title",
                // formatter: (cell, row, rowIndex) => {
                //     console.log(row);
                //     return row.image[0].title;
                // },
                sort: true,
                onSort: this.onSort
            },
            {
                text: t('promotion.sub-content'),
                dataField: "sub_content",
                keyField: "promotion-content",
                // formatter: (cell, row, rowIndex) => {
                //     return row.image[0].sub_content;
                // },
                headerStyle: () => {
                    return { width: '195px' };
                }
            },
            {
                text: t('global.status'),
                dataField: "status",
                keyField: "Status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return { width: '115px' };
                }
            },
            // {
            //     text:t('promotion.show'),
            //     dataField: "show",
            //     keyField: "Show",
            //     formatter: this.showFormatter,
            //     headerStyle: () => {
            //         return {width: '115px'};
            //     }
            // },
            {
                text: t('promotion.category'),
                // dataField: "category",
                // keyField: "Category",
                formatter: this.categoryFormatter
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <div>
                        {this.state.isOpen && (
                            <Lightbox
                                mainSrc={this.state.currentImage}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                            />
                        )}
                        <div className="breadcrumb-header">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('promotion.promotion')}</h4>
                                </div>
                            </div>
                        </div>
                        <PromotionListing
                            title={this.state.filter.title}
                            status={this.state.filter.status}
                            category={this.state.filter.category}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}


                            /**filter drop down*/
                            ddPromotionCategory={this.state.dropDown.promotion_category}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                }


                {this.state.pageShow === 'create' &&
                    <PromotionCreate
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow === 'show' &&
                    <PromotionShow
                        show_promotion_id={this.state.currentPromotionId}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow === 'edit' &&
                    <PromotionEdit
                        show_promotion_id={this.state.currentPromotionId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const { promotion, currentUser } = state;

    return {
        promotion_data: promotion.promotion_data,
        update_status_promotion_result: promotion.update_status_promotion_result,
        update_show_promotion_result: promotion.update_show_promotion_result,
        delete_promotion_result: promotion.delete_promotion_result,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        currentPermission: currentUser.data.permission,
        dropDown: promotion.dropDown
    }
};

const mapDispatchToProps = {
    updatePromotionStatus,
    updatePromotionShow,
    getPromotion,
    getDropDown,
    deletePromotion
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Promotion));