import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {showTournament} from "../../store/ducks/tournaments/actions";
import {getCountry} from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import DomPurify from "../../components/Sanitize/DomPurify";

//Initialize the language array first
let langArr = [];

class TournamentShow extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        title:[],
        content:[],
        desktop_image_name: [],
        mobile_image_name: [],
        desktop_image_link: [],
        mobile_image_link: [],
        type_id:[],
        member_group:[],
        target:[],
        sequence:[],
        min_bet:[],
        max_bet:[],
        start_time: '',
        end_time: '',
        status: '',
        loading: true
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;

        const showTournamentData = {};
        showTournamentData.tournament_id = this.props.show_tournament_id;

        this.props.showTournament(showTournamentData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_tournament_data !== this.props.show_tournament_data) {
            if (nextProps.show_tournament_data.data.responseCode === 200) {
                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    title,
                    content,
                    desktop_image_name,
                    mobile_image_name,
                    desktop_image_link,
                    mobile_image_link,
                }
                = this.state;

                nextProps.show_tournament_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    title[item.lang_id] = item.title;
                    content[item.lang_id] = item.content;
                    desktop_image_name[item.lang_id] = item.desktop_image_name;
                    mobile_image_name[item.lang_id] = item.mobile_image_name;
                    desktop_image_link[item.lang_id] = item.desktop_image_link;
                    mobile_image_link[item.lang_id] = item.mobile_image_link;

                    return item;
                })

                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    title,
                    content,
                    desktop_image_name,
                    mobile_image_name,
                    desktop_image_link,
                    mobile_image_link,
                    type_id: nextProps.show_tournament_data.data.data[0].type_id,
                    member_group:nextProps.show_tournament_data.data.data[0].member_group,
                    target: nextProps.show_tournament_data.data.data[0].target,
                    sequence: nextProps.show_tournament_data.data.data[0].sequence,
                    min_bet: nextProps.show_tournament_data.data.data[0].min_bet,
                    max_bet: nextProps.show_tournament_data.data.data[0].max_bet,
                    start_time: nextProps.show_tournament_data.data.data[0].start_time,
                    end_time: nextProps.show_tournament_data.data.data[0].end_time,
                    status: nextProps.show_tournament_data.data.data[0].status
                })
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({loading: false});
            //Clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    'langId': data[item].languageId,
                    'langCode': data[item].languageCode,
                    'langName': data[item].languageName
                });
            })
        }

        return true;
    }

    render() {
        const { t } = this.props;

        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;


        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t('tournament.desktop-image')}</label>

                        <div className="imgPreview tournament-preview-image">
                            <img
                                alt=""
                                className="show-tournament-size"
                                src={this.state.desktop_image_preview[item.langId]}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t('tournament.mobile-image')}</label>

                        <div className="imgPreview tournament-preview-image">
                            <img
                                alt=""
                                className="show-tournament-size"
                                src={this.state.mobile_image_preview[item.langId]}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.title')}</label>
                        <p><strong>{this.state.title[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.content')}</label>
                        {/* <p dangerouslySetInnerHTML={{ __html: this.state.content[item.langId] }}></p> */}
                        <DomPurify text={this.state.content[item.langId]} />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.desktop-image-name')}</label>
                        <p><strong>{this.state.desktop_image_name[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.mobile-image-name')}</label>
                        <p><strong>{this.state.mobile_image_name[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.desktop-image-link')}</label>
                        <p><strong>{this.state.desktop_image_link[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.mobile-image-link')}</label>
                        <p><strong>{this.state.mobile_image_link[item.langId]}</strong></p>
                    </div>
                </div>

            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('tournament.tournament')}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-secondary"
                                idValue="back"
                                buttonName={t('global.back-to-list')}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('tournament.show-tournament')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr className="show-tournament-white">
                                            <th>{t('tournament.tournament-details')}</th>
                                            <td field-key='tournament_details'>
                                                {tabMessage}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.type')}</th>
                                            <td field-key='type_id'>{this.state.type_id}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.member-group')}</th>
                                            <td field-key='member_group'>
                                                {
                                                    this.state.member_group.map((val, idx) =>
                                                    {
                                                        return (<span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>{val}</span>);
                                                    })
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.target-setting')}</th>
                                            <td field-key='target'>{this.state.target}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.sequence')}</th>
                                            <td field-key='sequence'>{this.state.sequence}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.min-bet')}</th>
                                            <td field-key='min_bet'>{this.state.min_bet}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.max-bet')}</th>
                                            <td field-key='max_bet'>{this.state.max_bet}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.start-time')}</th>
                                            <td field-key='start_time'>{this.state.start_time}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.end-time')}</th>
                                            <td field-key='end_time'>
                                                {this.state.end_time}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.status')}</th>
                                            <td field-key='status'>{this.state.status === 1 ? "ACTIVE" : "INACTIVE"}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const {home, tournament} = state;

    return {
        country_data: home.country_data,
        show_tournament_data: tournament.show_tournament_data
    }
};

const mapDispatchToProps = {
    showTournament,
    getCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(TournamentShow));