import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
// import i18n from '../../assets/locale/i18n';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';

import * as actions from '../../store/ducks/agent/actions';

class AgentSettingEdit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            input: null,
            tabId: null,
            gameType: [],
            loading: true,
            errMsg: [],
            touched: false,

        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation('agent_setting_form');
        if (this.props.selectId) {
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log(nextProps.details.data.game[1]);
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newInput = Object.assign({}, this.state.input);
                newInput['agent_id'] = this.props.selectId;
                nextProps.details.data.game_type.forEach((val, idx) => {
                    let percent = (nextProps.details.data.game_percentage[val.id] !== undefined) ? nextProps.details.data.game_percentage[val.id]['percent'] : 0.00;
                    newInput[`game_type_id_${val.id}`] = percent;
                });


                this.setState({
                    tabId: nextProps.details.data.game_type[0].id,
                    gameType: nextProps.details.data.game_type,
                    input: newInput,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.updateSetting !== this.props.updateSetting && nextProps.updateSetting !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.updateSetting);
            let success = (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') ? true : false;
            if (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('agent_setting_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.updateSetting.message
            });
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.id] = event.target.value;
        this.setState({
            input: newInput
        });
    }

    changeTabHandler = (e) => {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            tabId: e.target.id
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const inputArr = {};
                Object.keys(this.state.input).forEach((val) => {
                    inputArr[val] = this.state.input[val];
                });
                // console.log(inputArr);
                this.props.funcUpdateSetting(inputArr);
            }
        });
    }

    render() {
        // console.log(this.state);
        const { t } = this.props;
        let inputArr = (this.state.input) ? this.state.input : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('agent.agent-setting')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-body pt-0'>
                                <br className="pt-4" />
                                <form method='post' id='agent_setting_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='agent_setting_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div>
                                                        <h4 className="card-title mb-3">{t('agent.game-percentage')}</h4>
                                                    </div>
                                                    <div className="form-row">
                                                        {
                                                            this.state.gameType.map((val, idx) => {
                                                                return (
                                                                    <>
                                                                        <div className='col-md-4' key={idx}>
                                                                            <div className='form-group'>
                                                                                <label>{t(`game-type.${val.slug}`)}</label>
                                                                                <NumberInput
                                                                                    id={`game_type_id_${val.id}`}
                                                                                    name={`game_type_id_${val.id}`}
                                                                                    max={100}
                                                                                    value={inputArr[`game_type_id_${val.id}`] || 0.00}
                                                                                    onChange={(event) => this.onInputChange(event)}
                                                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </div>

                                                    <div className="form-group mb-0 justify-content-end">
                                                        <div>
                                                            <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName='Save' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.agent.setting_details,
        updateSetting: state.agent.update_setting,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdateSetting: (data) => dispatch(actions.updateSetting(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(AgentSettingEdit));