import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MemberReferralListing from "./MemberReferralListing";
import MemberReferralDetail from "./MemberReferralDetail";

import { getMemberReferral } from "../../store/ducks/memberReferral/actions";

const initialState = {
    username: ''
}

class MemberReferral extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: [],
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            order: null,
            totalRecords: 0,
            searchValue: "",
            pageShow: "listing",
            errMsg: [],
            touched: false,
            filterInput: initialState,
            detailsParam: {}
        }
    }

    componentDidMount(){
        let data = this.getFilterList();
        this.props.getMemberReferral(data);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.member_referral_data !== undefined && nextProps.member_referral_data !== this.props.member_referral_data){
            if(nextProps.member_referral_data.data.responseCode === 200 && nextProps.member_referral_data.data.msgType === "success"){
                this.setState({
                    loading: false,
                    data: nextProps.member_referral_data.data.data.result.row,
                    totalPages: nextProps.member_referral_data.data.data.result.pagination.total_page,
                    totalRecords: nextProps.member_referral_data.data.data.result.pagination.total_record,
                });
            }
        }

        return true;
    }

    getFilterList = () => {
        const data = {}
        let availablePage = this.state.activePage;
        if(this.state.totalRecords > 0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        data.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        data.limit = this.state.limit;
        data.search = this.state.searchValue;
        data.order = JSON.stringify(this.state.order);
        data.username = this.state.filterInput.username;
        data.country_id = this.props.currentUser.country_id;
        return data;
    }

    refreshHandler = () => {
        this.setState({
            errMsg: [],
            touched: false,
            loading: true,
            data: []
        }, ()=> {
            const data = this.getFilterList();
            this.props.getMemberReferral(data);
        });
    }

    clearHandler = () => {
        this.setState({
            errMsg: [],
            touched: false,
            loading: true,
            data: [],
            filterInput: initialState
        }, ()=> {
            const data = this.getFilterList();
            this.props.getMemberReferral(data);
        });
    }

    onInputChange = (event, inputType) => {
        let newFilter = Object.assign({}, this.state.filterInput);
        newFilter[event.target.id] = event.target.value;
        
        this.setState({
            filterInput: newFilter
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        let search = "";
        search = (act==='searchValue') ? val.target.value : val;

        this.setState({
            [act]: search,
            loading: true,
            data: [],
            errMsg: [],
            touched: false
        }, () => {
            const data = this.getFilterList();
            this.props.getMemberReferral(data);
        });
    }

    onSort = (field, order) => {
        // console.log(field+' | '+order);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const data = this.getFilterList();
            this.props.getMemberReferral(data);
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    }

    detailsHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam : {
                memberId: id,
                returnPage: 'listing'
            }
        });
    }

    render(){
        const { t } = this.props;
        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("member-referral.member-username"), dataField: "username", sort: true, onSort: this.onSort },
            { text: t("member-referral.total-referees"), dataField: "total_referee", sort: true, onSort: this.onSort },
            { text: t("member-referral.last-referred"), dataField: "last_referred", sort: true, onSort: this.onSort }
        ];

        let data = this.state.data.map((val, idx) => {
            let total_referee = (<b>
                <a href="/#" onClick={(event) => this.detailsHandler(event, val.id)}>
                    {val.total_referee}
                    <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                </a>
            </b>);
            return {
                no: idx,
                username: val.username,
                total_referee: total_referee,
                last_referred: val.last_referred
            }
        });

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && 
                <MemberReferralListing 
                    refreshHandler={() => this.refreshHandler()}
                    clearHandler={() => this.clearHandler()}
                    onInputChange={(event, inputType) => this.onInputChange(event, inputType)}
                    datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                    onSort={(field, order) => this.onSort(field, order)}

                    data={data}
                    columns={columns}
                    touched={this.state.touched}
                    errMsg={this.state.errMsg}
                    currentUser={this.props.currentUser}
                    loading={this.state.loading}
                    filterInput={this.state.filterInput}
                    activePage={this.state.activePage}
                    limit={this.state.limit}
                    totalRecords={this.state.totalRecords}
                    searchValue={this.state.searchValue}
                />
                }

                {this.state.pageShow === "details" &&
                <MemberReferralDetail 
                    detailsParam={this.state.detailsParam}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        member_referral_data: state.memberReferral.member_referral_data,
        currentUser: state.currentUser.data
    }
}

const mapDispatchToProps = {
    getMemberReferral
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberReferral));