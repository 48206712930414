import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle } from "react-icons/ai";

import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Lightbox from 'react-image-lightbox';

class RewardItemListing extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            isOpen: false,
            currentImage: null,
        };
    }
    /* ============ component lifecycle [END] ============ */

    render()
    {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                formatter: (cell, row, rowIndex) =>
                {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: t('global.id'),
                dataField: "id",
                sort: true,
                onSort: this.props.onSort,
                // hidden: true
            },
            {
                text: t('reward-item.title'),
                dataField: "title",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t('reward-item.image'),
                dataField: "image",
                formatter: (cell, row, rowIndex) =>
                {
                    return (
                        <img
                            alt=""
                            style={{"width": "50%", "cursor": "pointer"}}
                            src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                            key={`desktop-image-${row.id}`}
                        />
                    );
                },
                headerStyle: () => {
                    return {width: '195px'};
                }
            },
            {
                text: t('global.status'),
                dataField: "status",
                keyField: "Status",
                formatter: (cell, row) =>
                {
                    const { t } = this.props;
                    let stCls = row.status === 1 ? "active" : "inactive";
                    let stVal = permission.includes("reward_item_update_status") ? (
                        <select
                            className={`form-control datatable-select-${stCls}`}
                            id={row.id}
                            name={row.id}
                            onChange={(event) =>
                                this.props.updateStatusHandler(event)
                            }
                            value={row.status === null ? 0 : row.status}
                        >
                            <option value="1">{t("global.active")}</option>
                            <option value="0">{t("global.inactive")}</option>
                        </select>
                    ) : (
                        <span className={`badge-pill badge-${row.status === 1 ? "success" : "danger"}`}>
                            {row.status && row.status === 1 ? t("global.active") : t("global.inactive")}
                        </span>
                    );
                    return stVal;
                },
                headerStyle: () => {
                    return {width: '115px'};
                }
            },
            {
                text: t('reward-item.category'),
                dataField: "category",
                formatter: (cell, row) =>
                {
                    return t(`reward-category.${row.category}`);
                }
            },
            {
                text: t('reward-item.coin'),
                dataField: "coin",
                formatter: (cell, row) =>
                {
                    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(cell);
                }
            },
            {
                text: t('reward-item.qty'),
                dataField: "qty"
            },
            {
                text: t('reward-item.limit'),
                dataField: "limit",
                formatter: (cell, row) =>
                {
                    return cell === 0 ? t('global.no') : cell;
                }
            },
            {
                text: t('reward-item.deposit-amount'),
                dataField: "deposit",
                formatter: (cell, row) =>
                {
                    return cell === 0 ? t('global.no') : new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(cell);
                }
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: (cell, row) =>
                {
                    let actionVal =
                    permission.includes("reward_item_access") ||
                    permission.includes("reward_item_update") ? (
                        <div className="btn-group">
                            {permission.includes("reward_item_access") && (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,"details",row.id)}
                                    className="btn btn-sm btn-blue mr-2 rounded"
                                    data-toggle="tooltip"
                                    title={t("global.view")}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </a>
                            )}
                            {permission.includes("reward_item_update") && (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,"edit",row.id)}
                                    className="btn btn-sm btn-white rounded"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                            )}
                        </div>
                    ) : (
                        ""
                    );
                    return actionVal;
                },
                headerStyle: () => {
                    return {width: '115px'};
                }
            }
        ];
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("side-bar.reward_item")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                           <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row filter-wrapper-2`}>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("reward-item.title")}
                                                </label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.filter.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("reward-item.category")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="category"
                                                    name="category"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.category}
                                                >
                                                    <option value=""></option>
                                                    {this.props.ddCategory &&
                                                        this.props.ddCategory.map(
                                                            (val, idx) => {
                                                                return (
                                                                    <option key={val.id} value={val.id}>{t(`reward-category.${val.slug}`)}</option>
                                                                );
                                                            }
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <select
                                                    className="form-control filter-input"
                                                    id="status"
                                                    name="status"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.status}
                                                >
                                                    <option value=""></option>
                                                    <option value="1">{t("global.active")}</option>
                                                    <option value="0">{t("global.inactive")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("reward-item.coin")}
                                                </label>
                                                <div className="input-group">
                                                    <NumberInput
                                                        id="min_coin"
                                                        name="min_coin"
                                                        value={this.props.filter.min_coin}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.min")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />{" "}
                                                    <span style={{ margin: "auto 5px" }}>
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <NumberInput
                                                        id="max_coin"
                                                        name="max_coin"
                                                        value={this.props.filter.max_coin}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.max")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div>
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() =>
                                                    this.props.refreshHandler()
                                                }
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() =>
                                                    this.props.clearHandler()
                                                }
                                            />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("reward-item.listing")}
                                        </h4>
                                    </div>
                                </div>
                                {permission.includes("reward_item_create") && (
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            <AiFillPlusCircle />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-underline"
                                                idValue="create"
                                                buttonName={t("reward-item.add-reward")}
                                                clicked={() => this.props.changePageHandler("create")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val,"activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val,"limit")}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(RewardItemListing);
