import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const voucherReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    })
});

export default voucherReducer;