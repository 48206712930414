import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getPopups = (data) => dispatch => {
    axios.get('popup/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "lang_id": data['lang_id'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_POPUP_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const createPopup = () => (dispatch) => {
    axios.get("popup/create", {
        params: {
            country_shortcode: "MY",
            country_id: 1
        }
    })
    .then((res) => {
        dispatch({
            type: type.CREATE_POPUP,
            payload: res,
        });
    })
    .catch((err) => {
        console.log("Popup Error" + err)
        dispatch({
            type: type.CREATE_POPUP,
            payload: [],
        });
    });
}

export const postPopup = (data) => dispatch => {
    axios.post('popup/store',data)
    .then( (response) => {
        dispatch ({
            type: type.POST_POPUP,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const showPopup = (data) => dispatch => {
    axios.get('popup/show', {
        params: {
            "popup_id": data['popup_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_POPUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updatePopup = (data) => dispatch => {
    axios.post('popup/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_POPUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deletePopup = (data) => dispatch => {
    axios.post('popup/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_POPUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePopupStatus = (data) => dispatch => {
    axios.post('popup/changeStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_POPUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};