import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const translationReducer = createReducer(initialState) ({
    [type.TRANSLATION_ADMIN_LIST]: (state, action) => ({
        ...state,
        translation_admin_data: action.payload
    }),

    [type.TRANSLATION_MEMBER_LIST]: (state, action) => ({
        ...state,
        translation_member_data: action.payload
    }),

    [type.TRANSLATION_GET_LANGAUGES]: (state, action) => ({
        ...state,
        translation_languages_data: action.payload
    }),
});

export default translationReducer;