import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getInbox = (data) => dispatch => {
    axios.get('inbox/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_INBOX,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => dispatch =>
{
    axios.get('inbox/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postInbox = (data) => dispatch => {
    axios.post('inbox/store',data)
        .then( (response) => {
            dispatch ({
                type: type.POST_INBOX,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showInbox = (data) => dispatch => {
    axios.get('inbox/show', {
        params: {
            "id": data['id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_INBOX,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateInbox = (data) => dispatch => {
    axios.post('inbox/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_INBOX,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteInbox = (data) => dispatch => {
    axios.post('inbox/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_INBOX,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getMemberGroup = (data) => (dispatch, getState) => {
    const params = {};
    params.country_id = getState().currentUser.data.country_id;
    axios.get('inbox/member_group', {
        params: params
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getCategory = (data) => dispatch => {
    axios.get('inbox/category', {
        
    }).then((response) => {
        dispatch({
            type: type.GET_CATEGORY,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};