import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class GameListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ Component Lifecycle [END] ============ */

    render() {
        const { t } = this.props;

        let status_option = [
            { label: "", value: "", selected: true },
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        let fav_option = [
            { label: "", value: "", selected: true },
            { label: t("global._yes"), value: 1 },
            { label: t("global._no"), value: 0 },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("game-list.game-list")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="game_title"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
                                        {/* <div className="row"> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="game_title" className="font-weight-bolder">
                                                    {t("game-list.game-title")}
                                                </label>
                                                <StringInput
                                                    id="game_title"
                                                    name="game_title"
                                                    type="text"
                                                    value={this.props.filter.game_title}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="game_code" className="font-weight-bolder">
                                                    {t("game-list.game-code")}
                                                </label>
                                                <StringInput
                                                    id="game_code"
                                                    name="game_code"
                                                    type="text"
                                                    value={this.props.filter.game_code}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="game_type" className="font-weight-bolder">
                                                    {t("game-list.game-type")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="game_type"
                                                        name="game_type"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.game_type}>
                                                        <option value=""></option>
                                                        {this.props.ddGameType}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="game_category" className="font-weight-bolder">
                                                    {t("game-list.game-category")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="game_category"
                                                        name="game_category"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.game_category}>
                                                        <option value=""></option>
                                                        {this.props.ddGameCategory}
                                                    </select>
                                                    <img className="updown-icon"src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="status" className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    options={status_option}
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.status}
                                                    className="form-control filter-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="fav" className="font-weight-bolder">
                                                    {t("game-list.hot")}
                                                </label>
                                                <Select
                                                    id="fav"
                                                    name="fav"
                                                    options={fav_option}
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.fav}
                                                    className="form-control filter-input"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="vendor" className="font-weight-bolder">
                                                    {t("game-list.vendor")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="vendor"
                                                        name="vendor"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.vendor}>
                                                        <option value=""></option>
                                                        {this.props.ddVendor}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("game-list.game-listing")}</h4>
                                    </div>
                                </div>
                                {this.props.permission.includes("game_list_create") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    idValue="game-list"
                                                    classValue="btn btn-underline"
                                                    clicked={() => this.props.changePageHandler("create")}
                                                    buttonName={t("game-list.add-game-list")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="gameId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(GameListing);
