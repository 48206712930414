import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import Swal from 'sweetalert2';
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import { default as ReactSelect } from "react-select";
import { formValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';

import * as actions from '../../store/ducks/businessAccount/actions';

//Initialize the bank name array first
let memberArry = [
    {
        label: "Please Select Members",
        value: "",
        selected: true,
    },
];

let bankArry = [
    {
        label: "Please Select Banks",
        value: "",
        selected: true,
    },
];

class BusinessAccountCreate extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            bankId: "",
            memberId: "",
            errMsg: [],
            remark: null,

            bankName: "",
            bankAccNo: "",
            companyName: "",
            companyRegNo: "",
            ssmImage: "",
            ssmImagePreview: "",
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('wallet_adjustment_form');

        this.props.funcGetMember();
        this.props.funcGetBank();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log('shouldComponentUpdate :',nextProps.dropDown);
        if (nextProps.memberBalc !== this.props.memberBalc && nextProps.memberBalc !== undefined) {
            if (nextProps.memberBalc.responseCode === 200 && nextProps.memberBalc.msgType === 'success') {
                this.setState({
                    confirmMember: this.state.member,
                    avBalc: nextProps.memberBalc.data.balance,
                    validMember: true,
                }, () => {
                    this.calculateBalance();
                });
            }
            Swal.close();
        }

        if (nextProps.store_business_account_result !== this.props.store_business_account_result && nextProps.store_business_account_result !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store_business_account_result.responseCode === 200 && nextProps.store_business_account_result.msgType === 'success') ? true : false;
            if (nextProps.store_business_account_result.responseCode === 200 && nextProps.store_business_account_result.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    // console.log('shouldComponentUpdate :', nextState);
                    this.setState({
                        bankId: "",
                        memberId: "",
                        remark: null,
            
                        bankName: "",
                        bankAccNo: "",
                        companyName: "",
                        companyRegNo: "",
                        ssmImage: "",
                        ssmImagePreview: "",
                        touched: (success) ? false : true,
                        errMsg: (success) ? [] : nextProps.store.message
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store_business_account_result.message
                });
                Swal.close();
            }

        }

        if (nextProps.get_business_members !== this.props.get_business_members) {
            if (nextProps.get_business_members.data.responseCode === 200) {
                let data = nextProps.get_business_members.data.data;

                Object.keys(data).map((item, i) => {
                    return memberArry.push({
                        label: data[item].username,
                        value: data[item].id,
                    });
                });

                this.setState({ loading: false });
            } else if (nextProps.get_business_members.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.get_business_members.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.get_business_members.message[0],
                    icon: nextProps.get_business_members.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.get_business_banks !== this.props.get_business_banks) {
            if (nextProps.get_business_banks.data.responseCode === 200) {
                let data = nextProps.get_business_banks.data.data;

                Object.keys(data).map((item, i) => {
                    return bankArry.push({
                        label: data[item].bank_name,
                        value: data[item].id,
                    });
                });

                this.setState({ loading: false });
            } else if (nextProps.get_business_banks.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.get_business_banks.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.get_business_banks.message[0],
                    icon: nextProps.get_business_banks.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) => {
        // console.log('onInputChange :', event.target.id, event.target.value);
        let newId = (event.id) ? id : event.target.id;
        let newVal = (event.id) ? event.id : event.target.value;

        this.setState({
            [newId]: newVal,
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', e);
        const { t } = this.props;
        e.preventDefault();
        
            Swal.fire({
                title: t('validation.confirmation'),
                text: t('validation.confirmation_check'),
                icon: 'warning',
                confirmButtonText: t('global.save'),
                showDenyButton: true,
                denyButtonText: t('global.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    let formData = new FormData();
                    
                    formData.append("member_id", this.state.memberId);
                    formData.append("company_name", this.state.companyName);
                    formData.append("company_reg_no", this.state.companyRegNo);
                    formData.append("bank_id", this.state.bankId);
                    formData.append("country_id", this.props.currentUser.country_id);
                    formData.append("bank_account_number", this.state.bankAccNo);
                    formData.append("ssm_image", this.state.ssmImage);
                    formData.append("remark", this.state.remark);
                    this.props.funcStore(formData);
                }
            });
    }

    getSSMImageHandler = (event) => {
        let reader = new FileReader();
        let file = event[0];

        let { ssmImagePreview, ssmImage } = this.state;

        reader.onloadend = () => {

            ssmImagePreview = reader.result;
            ssmImage = file;

            this.setState({
                ssmImagePreview,
                ssmImage
            });
        };


        if (file) {
            reader.readAsDataURL(file);
        } else {
            ssmImagePreview = "";

            this.setState({
                ssmImagePreview
            })
        }

    };

    handleMemberChange = (event) => {
        this.setState({
            memberId: event.value,
        });
    };

    handleBankChange = (event) => {
        this.setState({
            bankId: event.value,
            bankName: event.label
        })
    }

    render() {
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton='button'
                                classValue='btn btn-back-outline'
                                idValue='back'
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('business-account.business-account')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.business_account_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='wallet_adjustment_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='business_account_form' touched={this.state.touched} message={this.state.errMsg} />
                                    <div className='col-12 p-0'>
                                        {/* <div className='col-md-12 col-6'> */}
                                        <div className="row">
                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.username')}</label>
                                                <div className="position-relative">
                                                <ReactSelect
                                                        options={memberArry}
                                                        isSearchable={true}
                                                        name="member"
                                                        onChange={this.handleMemberChange}
                                                        placeholder={t("global.select")}
                                                />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.bank-name')}</label>
                                                <div className="position-relative">
                                                    <ReactSelect
                                                        options={bankArry}
                                                        isSearchable={true}
                                                        name="banks"
                                                        onChange={this.handleBankChange}
                                                        placeholder={t("global.select")}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.bank-account-number')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <StringInput
                                                        id="bankAccNo"
                                                        name="bankAccNo"
                                                        className="form-control"
                                                        placeholder={t("business-account.bank-account-number")}
                                                        value={this.state.bankAccNo}
                                                        onChange={this.onInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.company-name')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <StringInput
                                                        id="companyName"
                                                        name="companyName"
                                                        className="form-control"
                                                        placeholder={t("business-account.company-name")}
                                                        value={this.state.companyName}
                                                        onChange={this.onInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.company-reg-no')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <StringInput
                                                        id="companyRegNo"
                                                        name="companyRegNo"
                                                        className="form-control"
                                                        placeholder={t("business-account.company-reg-no")}
                                                        value={this.state.companyRegNo}
                                                        onChange={this.onInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('business-account.ssm-image')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                <ImageUpload
                                                    id="ssm_image"
                                                    name={`ssm_image`}
                                                    onChange={(event) => this.getSSMImageHandler(event)}
                                                />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('wallet-adjustment.remark')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="remark"
                                                        id="remark"
                                                        maxLength="255"
                                                        rows="3"
                                                        placeholder={t("wallet-adjustment.remark")}
                                                        value={this.state.remark || ""}
                                                        onChange={this.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-group mb-0 justify-content-end'>
                                            <div>
                                                {(permission.includes('business_account_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                }
                                            </div>
                                        </div>

                                        {/* </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        currentUser: state.currentUser.data,
        get_business_members: state.businessAccount.get_business_members,
        get_business_banks: state.businessAccount.get_business_banks,
        store_business_account_result: state.businessAccount.store_business_account_result
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetMember: () => dispatch(actions.getMembers()),
        funcGetBank: () => dispatch(actions.getBanks()),
        funcStore: (data) => dispatch(actions.storeBusinessAccount(data))
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BusinessAccountCreate));