import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Lightbox from 'react-image-lightbox';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import TournamentListing from "./TournamentListing";
import TournamentCreate from "./TournamentCreate";
import TournamentShow from "./TournamentShow";
import TournamentEdit from "./TournamentEdit";
import WinnerListing from "./WinnerListing";
import Select from "../../components/Input/Select/Select";
import {getTournament,updateTournamentStatus,deleteTournament} from "../../store/ducks/tournaments/actions";

class Tournament extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,

        data: [],

        currentTournamentId: '',
        loading: true,
        currentImage : '',
        permission: []

    }

    fetchData(){
        const tournamentData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        tournamentData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        tournamentData.limit = this.state.limit;
        tournamentData.order = JSON.stringify(this.state.sorted);
        tournamentData.lang_id = this.props.currentLanguageId;
        tournamentData.country_id = this.props.currentCountryId;

        this.props.getTournament(tournamentData);
    }

    componentDidMount() {
       this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let tournamentPermission = this.props.currentPermission.filter((item) => item.includes("tournament_"));
            this.setState({permission: tournamentPermission});
        }

        if (nextProps.tournament_data !== this.props.tournament_data) {
            this.setState({
                loading: false,
                data: nextProps.tournament_data.data.data,
                totalRecords: nextProps.tournament_data.data.totalRecords,
                totalPages: nextProps.tournament_data.data.totalPages,
            })
        }

        if (nextProps.delete_tournament_result !== this.props.delete_tournament_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: nextProps.delete_tournament_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                   this.fetchData();
                }
            });
        }

        if (nextProps.update_status_tournament_result !== this.props.update_status_tournament_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_tournament_result.msgType,
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                   this.fetchData();
                }
            });
        }


        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;


        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    updateStatusTournamentHandler = (event, tournament_id) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updateTournamentStatusData = {};
                updateTournamentStatusData.tournament_id = tournament_id;
                updateTournamentStatusData.status = Number(event.target.value);

                this.props.updateTournamentStatus(updateTournamentStatusData);
            }
        });
    }

    deleteTournamentHandler = (e, tournament_id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deleteTournamentData = {};
                deleteTournamentData.id = tournament_id;

                this.props.deleteTournament(deleteTournamentData);
            }
        });
    }

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("tournament_update_status")) {

            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="tournamentStatus"
                    name="Status"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.tournament_id}`}
                    onChange={(event) => {
                        this.updateStatusTournamentHandler(event, row.tournament_id)
                    }}
                    className={dtClassName}
                />
            )
        }else{
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }


    // statusFormatter = (cell, row) => {
    //     let statusArray, dtClassName;

    //     statusArray = [
    //         {
    //             label: "Active",
    //             value: 1,
    //             selected: true,
    //         },
    //         {
    //             label: "Inactive",
    //             value: 0,
    //         },
    //     ];
    //     if (row.status === 0) {
    //         dtClassName = "form-control datatable-select-inactive";
    //     } else {
    //         dtClassName = "form-control datatable-select-active";
    //     }

    //     return (
    //         <Select
    //             id="tournamentStatus"
    //             name="Status"
    //             options={statusArray}
    //             defaultValue={row.status}
    //             key={`status-${row.tournament_id}`}
    //             onChange={(event) => {
    //                 this.updateStatusTournamentHandler(event, row.tournament_id)
    //             }}
    //             className={dtClassName}
    //         />
    //     )
    // }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton,output;

        if (this.state.permission.includes("tournament_view")) {
        viewButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-primary mr-2"
                buttonName={<FontAwesomeIcon icon={faSearch}/>}
                key={`view-${row.tournament_id}`}
                clicked={() => this.changePageHandler('show', row.tournament_id)}
            />;
        }

        if (this.state.permission.includes("tournament_edit")) {
        editButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-info mr-2"
                buttonName={<FontAwesomeIcon icon={faPen}/>}
                key={`edit-${row.tournament_id}`}
                clicked={() => this.changePageHandler('edit', row.tournament_id)}
            />;
        }

        if (this.state.permission.includes("tournament_delete")) {
        deleteButton =
            <Button
                typeButton="button"
                classValue="btn btn-sm btn-danger mr-2"
                clicked={(event) => this.deleteTournamentHandler(event, row.tournament_id)}
                key={`delete-${row.tournament_id}`}
                buttonName={<FontAwesomeIcon icon={faTrash}/>}
            />;
        }

        output = [viewButton, editButton, deleteButton];
        return output;
    }

    winnerFormatter = (cell, row) => {
        let winnerButton,output;

        if (this.state.permission.includes("tournament_view_winner_list")) {
        winnerButton =
        <Button
            typeButton="button"
            classValue="btn btn-sm btn-primary mr-2"
            buttonName={<FontAwesomeIcon icon={faSearch}/>}
            key={`winner-${row.tournament_id}`}
            clicked={() => this.changePageHandler('winner', row.tournament_id)}
        />
        }
        output = [winnerButton];
        return output;
    }

    changePageHandler = (page, tournamentId) => {
        this.setState({
            pageShow: page,
            currentTournamentId: tournamentId
        });
    }

    refreshHandler = () => {
       this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    desktopImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`desktop-image-${row.tournament_id}`}
            />
        );
    }

    mobileImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                style={{"width": "50%", "cursor": "pointer"}}
                src={cell} onClick={() => this.setState({isOpen: true, currentImage: cell})}
                key={`mobile-image-${row.tournament_id}`}
            />
        );
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Tournament Id",
                dataField: "tournament_id",
                keyField: 'tournamentId',
                hidden: true
            },
            {
                text: t('tournament.desktop-image'),
                dataField: "desktop_image",
                keyField: "desktopImage",
                formatter: this.desktopImageFormatter
            },
            {
                text: t('tournament.mobile-image'),
                dataField: "mobile_image",
                keyField: "mobileImage",
                formatter: this.mobileImageFormatter
            },
            {
                text: t('tournament.title'),
                dataField: "tournament_title",
                keyField: "title",
            },
            {
                text: t('tournament.type'),
                dataField: "Type",
                keyField: "type_id",
            },
            // {
            //     text: "Start Time",
            //     dataField: "start_time",
            //     keyField: "startTime"
            // },
            // {
            //     text: "End Time",
            //     dataField: "end_time",
            //     keyField: "endTime"
            // },
            // {
            //     text: "Sequence",
            //     dataField: "sequence",
            //     keyField: "sequence",
            // },
            {
                text: t('tournament.status'),
                dataField: "Status",
                keyField: "status",
                formatter: this.statusFormatter
            },
            {
                text: t('tournament.view-winner-list'),
                dataField: "@",
                keyField: "winnerlist",
                formatter: this.winnerFormatter
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return {width: '165px'};
                }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    {this.state.isOpen && (
                        <Lightbox
                            mainSrc={this.state.currentImage}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )}
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('tournament.tournament')}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div className="mb-3 mb-xl-0">
                                <div className="btn-group">
                                {this.state.permission.includes('tournament_create') &&
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-primary"
                                        idValue="btnCreateTournament"
                                        buttonName={t('tournament.add-tournament')}
                                        clicked={() => this.changePageHandler('create')}/>
                                }
                                </div>
                            </div>

                        </div>
                    </div>
                    <TournamentListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                    />
                </div>
                }


                {this.state.pageShow === 'create' &&
                <TournamentCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <TournamentShow
                    show_tournament_id={this.state.currentTournamentId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <TournamentEdit
                    show_tournament_id={this.state.currentTournamentId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'winner' &&
                <WinnerListing
                    show_tournament_id={this.state.currentTournamentId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {tournament,currentUser} = state;

    return {
        tournament_data: tournament.tournament_data,
        update_status_tournament_result: tournament.update_status_tournament_result,
        delete_tournament_result: tournament.delete_tournament_result,
        currentPermission: currentUser.data.permission,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
    }
};

const mapDispatchToProps = {
    updateTournamentStatus,
    getTournament,
    deleteTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Tournament));