import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { withNamespaces } from "react-i18next";

import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select2 from "../../components/Input/Select2/Select2";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { getMemberGroup, getType, postTournament } from "../../store/ducks/tournaments/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

//Initialize the language array first
let langArr = [];
let userMemberArry = [];
let typeArry = [];
class TournamentCreate extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        title: [],
        tournament_content: [],
        image_desktop_name: [],
        image_mobile_name: [],
        image_link_desktop_image: [],
        image_link_mobile_image: [],
        target_settings: [],
        type: [],
        sequence: [],
        min_bet: [],
        max_bet: [],
        start_time: "",
        end_time: "",
        currentGroupName: [],
        currentStatus: 1,
        loading: true,
        error_message: [],
        touched: false,
        show: false,
        desktop_image: [],
        mobile_image: [],
    };

    componentDidMount() {
        formValidation("tournament_form");
        this.props.getMemberGroup();
        this.props.getType();
        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_tournament_result !== this.props.post_tournament_result) {
            if (nextProps.post_tournament_result.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_tournament_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.post_tournament_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_tournament_result.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_tournament_result.message[0],
                    icon: nextProps.post_tournament_result.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        langId: data[item].languageId,
                        langCode: data[item].languageCode,
                        langName: data[item].languageName,
                    });
                });
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.member_group_data !== this.props.member_group_data) {
            if (nextProps.member_group_data.data.responseCode === 200) {
                Swal.close();
                userMemberArry = [];
                let data = nextProps.member_group_data.data.data;
                Object.keys(data).map((item, i) => {
                    return userMemberArry.push({
                        label: data[item].group_name,
                        value: data[item].Id,
                    });
                });
            }
        }

        if (nextProps.type_data !== this.props.type_data) {
            if (nextProps.type_data.data.responseCode === 200) {
                Swal.close();
                typeArry = [];
                let data = nextProps.type_data.data.data;
                Object.keys(data).map((item, i) => {
                    return typeArry.push({
                        label: data[item].type,
                        value: data[item].Id,
                    });
                });
            }
        }
        return true;
    }

    postTournamentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let formData = new FormData();

                const desktop_image = this.state.desktop_image;
                const mobile_image = this.state.mobile_image;
                const title = this.state.title;
                const tournament_content = this.state.tournament_content;
                const image_desktop_name = this.state.image_desktop_name;
                const image_mobile_name = this.state.image_mobile_name;
                const image_link_desktop_image = this.state.image_link_desktop_image;
                const image_link_mobile_image = this.state.image_link_mobile_image;

                const currentGroupName = this.state.currentGroupName;
                currentGroupName.map((itemGroupName) => {
                    return formData.append("member_group[]", itemGroupName.value);
                });

                desktop_image.forEach((val, idx) => {
                    formData.append("desktop_image[" + idx + "]", val);
                });

                mobile_image.forEach((val, idx) => {
                    formData.append("mobile_image[" + idx + "]", val);
                });

                title.forEach((val, idx) => {
                    formData.append("tournament_title[" + idx + "]", val);
                });

                tournament_content.forEach((val, idx) => {
                    formData.append("tournament_content[" + idx + "]", val);
                });

                image_desktop_name.forEach((val, idx) => {
                    formData.append("image_name_desktop_image[" + idx + "]", val);
                });

                image_mobile_name.forEach((val, idx) => {
                    formData.append("image_name_mobile_image[" + idx + "]", val);
                });

                image_link_desktop_image.forEach((val, idx) => {
                    formData.append("image_link_desktop_image[" + idx + "]", val);
                });

                image_link_mobile_image.forEach((val, idx) => {
                    formData.append("image_link_mobile_image[" + idx + "]", val);
                });

                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("target_settings", this.state.target_settings);
                formData.append("type", this.state.type);
                formData.append("sequence", this.state.sequence);
                formData.append("min_bet", this.state.min_bet);
                formData.append("max_bet", this.state.max_bet);
                formData.append("country_id", this.props.currentCountryId);

                this.props.postTournament(formData);
            }
        });
    };

    getDesktopImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { desktop_image_preview, desktop_image } = this.state;

        reader.onloadend = () => {
            desktop_image_preview[langId] = reader.result;
            desktop_image[langId] = file;

            this.setState({
                desktop_image_preview,
                desktop_image,
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            desktop_image_preview[langId] = "";
            this.setState({
                desktop_image_preview,
            });
        }
    };

    getMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_image_preview, mobile_image } = this.state;

        reader.onloadend = () => {
            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({ mobile_image_preview, mobile_image });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview,
            });
        }
    };

    getTitleHandler = (event, langId) => {
        const { title } = this.state;
        title[langId] = event.target.value;

        this.setState({
            title,
        });
    };

    getDesktopImageNameHandler = (event, langId) => {
        const { image_desktop_name } = this.state;
        image_desktop_name[langId] = event.target.value;

        this.setState({
            image_desktop_name,
        });
    };

    getMobileImageNameHandler = (event, langId) => {
        const { image_mobile_name } = this.state;
        image_mobile_name[langId] = event.target.value;

        this.setState({
            image_mobile_name,
        });
    };

    getDesktopImageLinkHandler = (event, langId) => {
        const { image_link_desktop_image } = this.state;
        image_link_desktop_image[langId] = event.target.value;

        this.setState({
            image_link_desktop_image,
        });
    };

    getMobileImageLinkHandler = (event, langId) => {
        const { image_link_mobile_image } = this.state;
        image_link_mobile_image[langId] = event.target.value;

        this.setState({
            image_link_mobile_image,
        });
    };

    getTypeHandler = (event) => {
        this.setState({ type: event.target.value });
    };

    getTargetHandler = (event) => {
        this.setState({ target_settings: event.target.value });
    };

    getMinBetHandler = (event) => {
        this.setState({ min_bet: event.target.value });
    };

    getMaxBetHandler = (event) => {
        this.setState({ max_bet: event.target.value });
    };

    getSequenceHandler = (event) => {
        this.setState({ sequence: event.target.value });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    };

    getMemberGroupHandler = (event) => {
        this.setState({ currentGroupName: event });
    };

    amount() {
        const type_select = this.state.type;
        if (type_select > 0) {
            return (
                <div className="form-group">
                    <label>Amount</label>
                    <NumberInput onChange={(event) => this.getSequenceHandler(event)} id="amount" name="amount" value={this.state.sequence} />
                </div>
            );
        }
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true,
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        let defaultType = [
            {
                label: "--- Select Type ---",
                value: "",
                selected: true,
            },
        ];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t("tournament.desktop-image")}</label>
                        <ImageUpload id="desktop_image" name={`desktop_image[${item.langId}]`} onChange={(event) => this.getDesktopImageHandler(event, item.langId)} />

                        {this.state.desktop_image_preview[item.langId] && (
                            <div className="imgPreview tournament-preview-image">
                                <div className="uploadPictureContainer">
                                    <img alt="" className="tournament" src={this.state.desktop_image_preview[item.langId]} />
                                </div>
                            </div>
                        )}

                        {(this.state.desktop_image_preview.length === 0 || !this.state.desktop_image_preview[item.langId]) && (
                            <span className="text-muted">
                                <strong>*jpg, png, gif</strong>
                            </span>
                        )}
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t("tournament.mobile-image")}</label>
                        <ImageUpload id="mobile_image" name={`mobile_image[${item.langId}]`} onChange={(event) => this.getMobileImageHandler(event, item.langId)} />

                        {this.state.mobile_image_preview[item.langId] && (
                            <div className="imgPreview tournament-preview-image">
                                <div className="uploadPictureContainer">
                                    <img alt="" className="tournament" src={this.state.mobile_image_preview[item.langId]} />
                                </div>
                            </div>
                        )}

                        {(this.state.mobile_image_preview.length === 0 || !this.state.mobile_image_preview[item.langId]) && (
                            <span className="text-muted">
                                <strong>*jpg, png, gif</strong>
                            </span>
                        )}
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.title")}</label>
                        <StringInput
                            onChange={(event) => this.getTitleHandler(event, item.langId)}
                            id="title"
                            name={`title[${item.langId}]`}
                            value={this.state.title[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.content")}</label>
                        <JoditEditor
                            value={this.state.tournament_content[item.langId] || ''}
                            tabIndex={item.langId} // tabIndex of textarea
                            onBlur={newContent => {
                                const {tournament_content} = this.state;
                                tournament_content[item.langId] = newContent;
                                this.setState({
                                    tournament_content
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.desktop-image-name")}</label>
                        <StringInput
                            onChange={(event) => this.getDesktopImageNameHandler(event, item.langId)}
                            id="image_desktop_name"
                            name={`image_desktop_name[${item.langId}]`}
                            value={this.state.image_desktop_name[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.mobile-image-name")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageNameHandler(event, item.langId);
                            }}
                            id="image_mobile_name"
                            name={`image_mobile_name[${item.langId}]`}
                            value={this.state.image_mobile_name[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.desktop-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getDesktopImageLinkHandler(event, item.langId);
                            }}
                            id="image_link_desktop_image"
                            name={`image_link_desktop_image[${item.langId}]`}
                            value={this.state.image_link_desktop_image[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("tournament.mobile-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageLinkHandler(event, item.langId);
                            }}
                            id="image_link_mobile_image"
                            name={`image_link_mobile_image[${item.langId}]`}
                            value={this.state.image_link_mobile_image[item.langId]}
                        />
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("tournament.tournament")}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("tournament.add-tournament")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="tournament_form" className="form-horizontal" onSubmit={this.postTournamentHandler} noValidate>
                                        <ValidationError formName="tournament_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.start-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.end-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <label htmlFor="group">{t("tournament.member-group")}</label>
                                                <Select2 className="form-control" options={userMemberArry} name="member_group" onChange={this.getMemberGroupHandler} />
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.type")}</label>
                                                    <Select id="type" name="type" className="form-control" options={defaultType.concat(typeArry)} onChange={this.getTypeHandler} />
                                                    {/* {this.amount()} */}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.target-setting")}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.getTargetHandler(event)}
                                                        id="target_settings"
                                                        name="target_settings"
                                                        value={this.state.target}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.min-bet")}</label>
                                                    <NumberInput onChange={(event) => this.getMinBetHandler(event)} id="min_bet" name="min_bet" value={this.state.min_bet} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.max-bet")}</label>
                                                    <NumberInput onChange={(event) => this.getMaxBetHandler(event)} id="max_bet" name="max_bet" value={this.state.max_bet} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("tournament.sequence")}</label>
                                                    <NumberInput onChange={(event) => this.getSequenceHandler(event)} id="sequence" name="sequence" value={this.state.sequence} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("global.status")}</label>
                                                    <Select id="status" name="status" className="form-control" options={statusArry} onChange={this.changeStatusHandler} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button id="submit" classValue="btn btn-primary" typeButton="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, tournament, currentUser } = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        member_group_data: tournament.member_group_data,
        type_data: tournament.type_data,
        post_tournament_result: tournament.post_tournament_result,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    getCountry,
    getMemberGroup,
    getType,
    postTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(TournamentCreate));
