import React, { Component } from "react";
import { connect } from "react-redux";
// import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import { getShipments, getDropDown, deleteShipment } from "../../store/ducks/shipments/actions";
import ShipmentsListing from "./ShipmentsListing";
import ShipmentsCreate from "./ShipmentsCreate";
import ShipmentsEdit from "./ShipmentsEdit";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";

const initialInputState = {
	shipment_type: "",
	courier_name: "",
	warehouse: "",
	area: "",
	state: "",
	parcel_type: ""
};

let shipmentListingData = {};
class Shipments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageShow: "listing",
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			totalPages: 0,
			totalRecords: 0,
			data: [],
			dropDown: [],
			filter: initialInputState,
			currentShipmentId: "",
			loading: true,
			permission: [],
		};
		this.onInputChange = this.onInputChange.bind(this);
  	}

	fetchData() {
		let availablePage = this.state.activePage;
		if (this.state.totalRecords !== 0) {
			availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
			this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
		}

		shipmentListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
		shipmentListingData.limit = this.state.limit;
		shipmentListingData.order = JSON.stringify(this.state.order);

		Object.keys(this.state.filter).forEach((val) => {
            shipmentListingData[val] = this.state.filter[val];
        });
		this.props.getShipments(shipmentListingData);
  	}

	componentDidMount() {
		this.fetchData();
		this.props.getDropDown();
	}

  	shouldComponentUpdate(nextProps, nextState) {
    	const { t } = this.props;

		if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
			let shipmentPermission = this.props.currentPermission.filter((item) => item.includes("shipment_"));
			this.setState({ permission: shipmentPermission });
		}
		
		if (nextProps.shipment !== this.props.shipment && nextProps.shipment !== undefined) {
			if (nextProps.shipment.data.responseCode === 200 && nextProps.shipment.data.msgType === 'success') {
				this.setState({
					data: nextProps.shipment.data.data.result.row,
					totalRecords: nextProps.shipment.data.data.result.pagination.total_record,
					totalPages: nextProps.shipment.data.data.result.pagination.total_page,
					loading: false,
				});
				// Swal.close();
			} else {
				this.setState({
                    loading: true,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.shipment.data.message);
                });
			}
		}
		
		if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
			if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
				this.setState({
					dropDown: nextProps.dropDown.data,
				});
			} else {
				this.setState({
                    loading: true,
                    dropDown: []
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
			}
		}

		if (nextProps.delete !== this.props.delete && nextProps.delete !== undefined) {
			if (nextProps.delete.responseCode === 200 && nextProps.delete.msgType === 'success') {
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.delete.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
				filterErrorHandler(nextProps.delete.message);
            } 
		}
		return true;
	}
	
	onInputChange(event) {
		let newFilter = Object.assign({}, this.state.filter);
		newFilter[event.target.id] = event.target.value;
		
		this.setState({
			filter: newFilter,
		});
	}

  	refreshHandler = () => {
    	this.setState({
			loading: true,
			data: [],
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null
      	}, () => {
			this.fetchData();
		});
	};

  	clearHandler = () => {
    	let newFilter = initialInputState;
    	this.setState({
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			searchValue: "",
			loading: true,
			filter: newFilter,
			data: [],
		}, () => {
			this.fetchData();
		});
	};

  	pageChangeHandler = (activePage) => {
    	this.setState(
			{ 
				page: activePage, 
				activePage: activePage,
				loading: true, 
				data: [] 
			}, () => {
				this.fetchData();
			}
		);
	};
	
	changePageHandler = (page, shipmentId) => {
		this.setState({
			pageShow: page,
			currentShipmentId: shipmentId,
		});
	};
	
	datatablesChangeHandler = (val, act) => {
		this.setState({
			[act]: act === "searchValue" ? val.target.value : val,
			loading: true,
			data: [],
      	}, () => {
			this.fetchData();

      	});
  	};

	deletePriceSettingHandler = (e, shipmentId) => {
		e.preventDefault();
        const { t } = this.props;

		Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
			icon: 'warning',
            confirmButtonText: t("global.delete"),
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: '',
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					onOpen: () => {
						Swal.showLoading()
					}
				});

				const deletePriceSetting = {};
				deletePriceSetting.id = shipmentId;

				this.props.deleteShipment(deletePriceSetting);
			}
		});
	}

  	onSort = (field, order) => {
    	const orderArry = [{}];
    	let orderSequence = order === "asc" ? true : false;

		orderArry[0]["id"] = field;
		orderArry[0]["desc"] = orderSequence;

    	this.setState(
      	{
			order: orderArry,
			loading: false,
			data: [],
			// errMsg: [],
			// touched: false,
      	}, () => {
			this.fetchData();
      	});
  	};

  	actionFormatter = (cell, row) => {
    	let editButton, deleteButton, output;

    	if (this.state.permission.includes("shipment_update")) {
      		editButton = (
				<Button
					typeButton="button"
					classValue="btn btn-sm btn-white mr-2"
					key={`edit-${row.shipmentId}`}
					clicked={() => this.changePageHandler("edit", row.shipmentId)}
					buttonName={<FontAwesomeIcon icon={faPen} />}
				/>
      		);
    	}		

		if (this.state.permission.includes('shipment_delete')) {
			deleteButton = (
				<Button
					typeButton="button"
					classValue="btn btn-sm btn-danger mr-2"
					key={`delete-${row.shipmentId}`}
					clicked={(event) => this.deletePriceSettingHandler(event, row.shipmentId)}
					buttonName={<FontAwesomeIcon icon={faTrash} />}
				/>
			);
		}
		output = [editButton, deleteButton];
		return output;
  	};
	
	render() {
		const { t } = this.props;
    	let dataListing = [];
    	const columns = [
			{
				text: t("global.no"),
				dataField: "",
				keyField: "no",
				formatter: (cell, row, rowIndex) => {
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
			},
			{
				text: t("price-list.shipment_type"),
				dataField: "shipment_type",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.courier_name"),
				dataField: "courier_name",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.warehouse"),
				dataField: "warehouse",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.state"),
				dataField: "state",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.area"),
				dataField: "area",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("price-list.parcel_type"),
				dataField: "parcel_type",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("global.action"),
				dataField: "#",
				formatter: this.actionFormatter,
				headerStyle: { width: "115px" },
			},
		];
		
		if (this.state.pageShow === "listing") {
			if (this.state.data.length > 0) {
				dataListing = this.state.data.map((val, idx) => {
					return {
						shipmentId: val.shipmentId,
						shipment_type: t(`price-list.${val.shipment_type}`), 
						courier_name: t(`price-list.${val.courier_name}`),
						warehouse: t(`price-list.${val.warehouse}`),
						state: t(`price-list.${val.state}`),
						area: val.area !== null ? t(`price-list.${val.area}`) : '-',
						parcel_type: t(`price-list.${val.parcel_type}`),
          			};
        		});
      		}
    	}
		
		return (
			<Auxiliary>
				{this.state.pageShow === "listing" && (
					<ShipmentsListing
						filter={this.state.filter}
						clearHandler={() => this.clearHandler()}
						refreshHandler={() => this.refreshHandler()}
						onInputChange={(event) => this.onInputChange(event)}
						datatablesChangeHandler={(val, act) =>
						this.datatablesChangeHandler(val, act)
						}
						columns={columns}
						activePage={this.state.activePage}
						dataListing={dataListing}
						limit={this.state.limit}
						totalRecords={this.state.totalRecords}
						loading={this.state.loading}
						permission={this.state.permission}
						pageChangeHandler={this.pageChangeHandler}
						ddTransportType={this.state.dropDown.shipment_transport}
						ddCourierName={this.state.dropDown.shipment_courier}
						ddWarehouse={this.state.dropDown.shipment_warehouse}
						ddState={this.state.dropDown.shipment_state}
						ddParcelType={this.state.dropDown.shipment_parcel_type}
						ddArea={this.state.dropDown.shipment_area}
						changePageHandler={this.changePageHandler}
					/>
        		)}

				{this.state.permission.includes("shipment_store") && this.state.pageShow === "create" && (
					<ShipmentsCreate
						refreshHandler={() => this.refreshHandler()}
						changePageHandler={(page) => this.changePageHandler(page)}
					/>
				)}

				{this.state.permission.includes("shipment_update") && this.state.pageShow === "edit" && (
					<ShipmentsEdit
						show_shipment_id={this.state.currentShipmentId}
						refreshHandler={() => this.refreshHandler()}
						changePageHandler={(page) => this.changePageHandler(page)}
					/>
				)}
      		</Auxiliary>
    	);
  	}
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
	const { currentUser, shipment } = state;
	//Get the state from reducers current limit, current activePage and search value
	return {
		currentPermission: currentUser.data.permission,
		shipment: shipment.shipment_data,
		dropDown: shipment.dropDown,
		delete: shipment.delete_shipment,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getShipments: (filter) => dispatch(getShipments(filter)),
		getDropDown: () => dispatch(getDropDown()),
		deleteShipment: (data) => dispatch(deleteShipment(data)),
  	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Shipments));
