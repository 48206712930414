import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getSensitiveGoodReturn, deleteSensitiveGoodReturn } from "../../store/ducks/sensitiveGoodReturn/actions";
import SensitiveGoodReturnListing from "./SensitiveGoodReturnListing";
import SensitiveGoodReturnCreate from "./SensitiveGoodReturnCreate";
import SensitiveGoodReturnEdit from "./SensitiveGoodReturnEdit";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import moment from 'moment';
import Swal from "sweetalert2";

const initialInputState = {
    username: '',
    tracking_no: '',
    refer_id: '',
	status: ''
};

let shipmentListingData = {};
class SensitiveGoodReturn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageShow: "listing",
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			totalPages: 0,
			totalRecords: 0,
			data: [],
			status: [
                {
                    "value": 0,
                    // "label": "Pending"
                },
                {
                    "value": 1,
                    // "label": "Success"
                }
            ],
			filter: initialInputState,
			currentSenstiveGoodReturnId: "",
			loading: true,
			permission: [],
			currentOrderCode: "",
			currentCusId: "",
			totalCost: 0
		};
		this.onInputChange = this.onInputChange.bind(this);
  	}

	fetchData() {
		let availablePage = this.state.activePage;
		if (this.state.totalRecords !== 0) {
			availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
			this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
		}

		shipmentListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
		shipmentListingData.limit = this.state.limit;
		shipmentListingData.order = JSON.stringify(this.state.order);
		shipmentListingData.totalRecord = this.state.totalRecords;
		Object.keys(this.state.filter).forEach((val) =>
		{
			// listingArr[val] = this.state.filter[val];
			shipmentListingData[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
		});
		this.props.getSensitiveGoodReturn(shipmentListingData);
  	}

	componentDidMount() {
		this.fetchData();
	}

  	shouldComponentUpdate(nextProps, nextState) {
    	const { t } = this.props;

		if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
			let shipmentPermission = this.props.currentPermission.filter((item) => item.includes("sensitive_good_"));
			this.setState({ permission: shipmentPermission });
		}
		
		if (nextProps.sensitive_good_return !== this.props.sensitive_good_return && nextProps.sensitive_good_return !== undefined) {
			if (nextProps.sensitive_good_return.data.responseCode === 200 && nextProps.sensitive_good_return.data.msgType === 'success') {
                this.setState({
					data: nextProps.sensitive_good_return.data.data,
					totalRecords: nextProps.sensitive_good_return.data.totalRecords,
					totalPages: nextProps.sensitive_good_return.data.totalPages,
					loading: false,
				});
				// Swal.close();
			} else {
				this.setState({
                    loading: true,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.sensitive_good_return.data.message);
                });
			}
		}

        if (nextProps.delete_sensitive_good_return !== this.props.delete_sensitive_good_return && nextProps.delete_sensitive_good_return !== undefined) {
			if (nextProps.delete_sensitive_good_return.data.responseCode === 200 && nextProps.delete_sensitive_good_return.data.msgType === 'success') {
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.delete_sensitive_good_return.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
				filterErrorHandler(nextProps.delete_sensitive_good_return.data.message);
            } 
		}
		
		return true;
	}
	
	onInputChange(event) {
		let newFilter = Object.assign({}, this.state.filter);
		newFilter[event.target.id] = event.target.value;

		if(event.target.id==='transaction_date')
		{
			const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
			newFilter['start_date'] = (date) ? date[0] : '';
			newFilter['end_date'] = (date) ? date[1] : '';
		}
		this.setState({
			filter: newFilter,
		});
	}

  	refreshHandler = () => {
    	this.setState({
			loading: true,
			data: [],
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null
      	}, () => {
			this.fetchData();
		});
	};

  	clearHandler = () => {
    	let newFilter = initialInputState;
    	this.setState({
			activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			searchValue: "",
			loading: true,
			filter: newFilter,
			data: [],
		}, () => {
			this.fetchData();
		});
	};

  	pageChangeHandler = (activePage) => {
    	this.setState(
			{ 
				page: activePage, 
				activePage: activePage, 
				loading: true, 
				data: [] 
			}, () => {
				this.fetchData();
			}
		);
	};
	
    changePageHandler = (page, sensitiveGoodReturnId) => {
		this.setState({
			pageShow: page,
			currentSenstiveGoodReturnId: sensitiveGoodReturnId,
		});
	};
	
	datatablesChangeHandler = (val, act) => {
		this.setState({
			[act]: act === "searchValue" ? val.target.value : val,
			loading: true,
			data: [],
      	}, () => {
			this.fetchData();
      	});
  	};

  	onSort = (field, order) => {
    	const orderArry = [{}];
    	let orderSequence = order === "asc" ? true : false;

		orderArry[0]["id"] = field;
		orderArry[0]["desc"] = orderSequence;

    	this.setState(
      	{
			order: orderArry,
			loading: false,
			data: [],
			// errMsg: [],
			// touched: false,
      	}, () => {
			this.fetchData();
      	});
  	};

  	actionFormatter = (cell, row) => {
    	let editButton, deleteButton, output;

    	if (this.state.permission.includes("sensitive_good_return_update")) {
      		editButton = (
				<Button
					typeButton="button"
					classValue="btn btn-sm btn-white mr-2"
					key={`edit-${row.id}`}
					clicked={() => this.changePageHandler("edit", row.id)}
					buttonName={<FontAwesomeIcon icon={faPen} />}
				/>
      		);
    	}		

		// if (this.state.permission.includes('sensitive_good_return_delete')) {
		// 	deleteButton = (
		// 		<Button
		// 			typeButton="button"
		// 			classValue="btn btn-sm btn-danger mr-2"
		// 			key={`delete-${row.id}`}
		// 			clicked={(event) => this.deleteSensitiveGoodReturnHandler(event, row.id)}
		// 			buttonName={<FontAwesomeIcon icon={faTrash} />}
		// 		/>
		// 	);
		// }
		output = [editButton, deleteButton];
		return output;
  	};

    deleteSensitiveGoodReturnHandler = (e, sensitiveGoodReturnId) => {
		e.preventDefault();
        const { t } = this.props;

		Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
			icon: 'warning',
            confirmButtonText: t("global.delete"),
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: '',
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					onOpen: () => {
						Swal.showLoading()
					}
				});

				const deleteSensitiveGoodReturn = {};
				deleteSensitiveGoodReturn.id = sensitiveGoodReturnId;

				this.props.deleteSensitiveGoodReturn(deleteSensitiveGoodReturn);
			}
		});
	}
	
	render() {
		const { t } = this.props;
        let stCls = {
            0: "primary",
            1: "success",
        };
    	let dataListing = [];
    	const columns = [
			{
				text: t("global.no"),
				dataField: "",
				keyField: "no",
				formatter: (cell, row, rowIndex) => {
                    let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
			},
			{
				text: t("sensitive-good-return.username"),
				dataField: "username",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("sensitive-good-return.tracking_no"),
				dataField: "tracking_no",
				sort: true,
				onSort: this.onSort,
			},
			{
				text: t("sensitive-good-return.price"),
				dataField: "price",
				sort: true,
				onSort: this.onSort
			},
            {
				text: t("sensitive-good-return.refer_id"),
				dataField: "refer_id",
				sort: true,
				onSort: this.onSort,
			},
            {
				text: t("sensitive-good-return.status"),
				dataField: "status",
				sort: true,
				onSort: this.onSort,
			},
            {
				text: t("global.action"),
				dataField: "#",
				formatter: this.actionFormatter,
				headerStyle: { width: "115px" },
			},

		];
		
		if (this.state.pageShow === "listing") {
			if (this.state.data.length > 0) {
				dataListing = this.state.data.map((val, idx) => {
                    let stVal = "";
                    stVal = (
                        <span
                            className={`badge-${stCls[val.status]
                                }`}
                        >
                            {t(`transaction.status-desc.${val.status}`)}
                        </span>
                    );

					return {
                        id: val.id,
						username: val.username,
						tracking_no: val.tracking_no,
						price: val.price,
                        refer_id: val.refer_id,
                        status: stVal
          			};
        		});
      		}
    	}
		
		return (
			<Auxiliary>
				{this.state.pageShow === "listing" && (
					<SensitiveGoodReturnListing
						filter={this.state.filter}
						clearHandler={() => this.clearHandler()}
						refreshHandler={() => this.refreshHandler()}
						onInputChange={(event) => this.onInputChange(event)}
						datatablesChangeHandler={(val, act) =>
						this.datatablesChangeHandler(val, act)
						}
						columns={columns}
						activePage={this.state.activePage}
						dataListing={dataListing}
						limit={this.state.limit}
						totalRecords={this.state.totalRecords}
						loading={this.state.loading}
						permission={this.state.permission}
						pageChangeHandler={this.pageChangeHandler}
						ddStatus={this.state.status}
						changePageHandler={this.changePageHandler}
					/>
        		)}

				{this.state.pageShow === "create" && (
					<SensitiveGoodReturnCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
				)}

                {this.state.pageShow === "edit" && (
					<SensitiveGoodReturnEdit 
                        refreshHandler={() => this.refreshHandler()} 
                        changePageHandler={(page) => this.changePageHandler(page)}
                        currentSenstiveGoodReturnId={this.state.currentSenstiveGoodReturnId} 
                    />
				)}
      		</Auxiliary>
    	);
  	}
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
	const { currentUser, sensitiveGoodReturn } = state;
	//Get the state from reducers current limit, current activePage and search value
	return {
		currentPermission: currentUser.data.permission,
		sensitive_good_return: sensitiveGoodReturn.sensitive_good_return_data,
        delete_sensitive_good_return: sensitiveGoodReturn.delete_sensitive_good_return
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSensitiveGoodReturn: (filter) => dispatch(getSensitiveGoodReturn(filter)),
        deleteSensitiveGoodReturn: (data) => dispatch(deleteSensitiveGoodReturn(data))
  	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SensitiveGoodReturn));
