import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import BanksLevelListing from "./BanksLevelListing";
import BanksLevelCreate from "./BanksLevelCreate";
import BanksLevelShow from "./BanksLevelShow";
import BanksLevelEdit from "./BanksLevelEdit";
import {getBanksLevel, updateBanksLevelStatus, deleteBanksLevel} from "../../store/ducks/banksLevel/actions";
import Select from "../../components/Input/Select/Select";

class BanksLevel extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',

        data: [],

        currentBankLevelId: '',
        loading: true,

        permission: []
    }

    fetchData(){
        const bankLevelListingData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        bankLevelListingData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        bankLevelListingData.limit = this.state.limit;
        bankLevelListingData.order = JSON.stringify(this.state.sorted);
        bankLevelListingData.country_id = this.props.currentCountryId;
        bankLevelListingData.search = this.state.searchValue;

        this.props.getBanksLevel(bankLevelListingData);
    }

    componentDidMount() {
      this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let bankLevelPermission = this.props.currentPermission.filter((item) => item.includes("bank_level_"));
            this.setState({permission: bankLevelPermission});
        }

        if (nextProps.bank_level !== this.props.bank_level) {
            if(nextProps.bank_level.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.bank_level.data.data,
                    totalRecords: nextProps.bank_level.data.totalRecords,
                    totalPages: nextProps.bank_level.data.totalPages,
                })
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.bank_level.data.message[0],
                    icon: nextProps.bank_level.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.delete_bank_level_result !== this.props.delete_bank_level_result){
            if(nextProps.delete_bank_level_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.delete_bank_level_result.message,
                    icon: nextProps.delete_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.delete_bank_level_result.message[0],
                    icon: nextProps.delete_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.update_status_bank_level_result !== this.props.update_status_bank_level_result){
            if(nextProps.update_status_bank_level_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_status_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }else{
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_status_bank_level_result.message[0],
                    icon: nextProps.update_status_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    updateStatusBankLevelHandler = (event, bankLevelId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updateBankLevelStatusData = {};
                updateBankLevelStatusData.id = bankLevelId;
                updateBankLevelStatusData.status = Number(event.target.value);

                this.props.updateBanksLevelStatus(updateBankLevelStatusData);
            }
        });
    }

    deleteBankLevelHandler = (e,bank_level_id) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const deleteBankLevelData = {};
                deleteBankLevelData.id = bank_level_id;

                this.props.deleteBanksLevel(deleteBankLevelData);
            }
        });
    }


    statusFormatter = (cell, row) => {
        const {t} = this.props;

        if (this.state.permission.includes("bank_level_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="bankLevelStatus"
                    name="bankLevelStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.bankLevelId}`}
                    onChange={(event) => {
                        this.updateStatusBankLevelHandler(event, row.banklevelId)
                    }}
                    className={dtClassName}
                />
            )
        }else{
            let badgeClassName, status;
            if (row.maintainanceStatus === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        if (this.state.permission.includes("bank_level_show")) {
            viewButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faSearch}/>}
                    key={`view-${row.banklevelId}`}
                    clicked={() => this.changePageHandler('show', row.banklevelId)}
                />;
        }

        if (this.state.permission.includes("bank_level_update")) {
            editButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    buttonName={<FontAwesomeIcon icon={faPen}/>}
                    key={`edit-${row.banklevelId}`}
                    clicked={() => this.changePageHandler('edit', row.banklevelId)}
                />;
        }

        if (this.state.permission.includes("bank_level_delete")) {
            deleteButton =
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white delete"
                    clicked={(event) => this.deleteBankLevelHandler(event, row.banklevelId)}
                    key={`delete-${row.banklevelId}`}
                    buttonName={<FontAwesomeIcon icon={faTrash}/>}
                />;
        }
        output = [viewButton, editButton, deleteButton];
        return output;
    }

    bankAccountFormatter = (cell, row) => {
        let bankAccountSplit = row.bankAccount.split(',');
        return (<div>
            {bankAccountSplit.map((item, index) => (
                <span className="badge badge-primary mr-2" key={`banks-account-${item}`}>{item}</span>
            ))}
        </div>);
    }

    memberGroupFormatter = (cell, row) => {
        let memberGroupSplit = row.memberGroup.split(',');
        return (<div>
            {memberGroupSplit.map((item, index) => (
                <span className="badge badge-primary mr-2" key={`member-group-${item}`}>{item}</span>
            ))}
        </div>);
    }

    changePageHandler = (page, bankLevelId) => {
        this.setState({
            pageShow: page,
            currentBankLevelId: bankLevelId
        });
    }

    refreshHandler = () =>
    {
        this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '50px'};
                }
            },
            {
                text: "Bank Level Id",
                dataField: "banklevelId",
                keyField: 'bankLevelId',
                hidden: true
            },
            {
               text: t('bank-level.bank-level-name'),
               dataField: "banklevelName",
               keyField: "bankLevelName",
               sort: true,
               onSort: this.onSort,
            },
            {
                text: t('bank-level.deposit-amount'),
                dataField: "depositAmount",
                keyField: "depositAmount",
                sort: true,
                onSort: this.onSort
            },
            {
               text: t('bank-level.group'),
               dataField: "memberGroup",
               keyField: "group",
               formatter: this.memberGroupFormatter
            },
            {
               text: t('bank-level.bank-account'),
               dataField: "bankAccount",
               keyField: "bankAccount",
               formatter: this.bankAccountFormatter
            },
            {
                text: t('bank-level.created-by'),
                dataField: "createdBy",
                keyField: "createdBy",
                sort: true,
                onSort: this.onSort
            },
            {
                text: t('global.status'),
                dataField: "status",
                keyField: "status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('bank-level.bank-level')}</h4>
                            </div>
                        </div>
                    </div>
                    <BanksLevelListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
                }

                {this.state.pageShow === 'create' &&
                <BanksLevelCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <BanksLevelShow
                    show_bank_level_id={this.state.currentBankLevelId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'edit' &&
                <BanksLevelEdit
                    show_bank_level_id={this.state.currentBankLevelId}
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {bankLevel, currentUser} = state;

    return {
        bank_level: bankLevel.bank_level_data,
        update_status_bank_level_result: bankLevel.update_status_bank_level_result,
        delete_bank_level_result: bankLevel.delete_bank_level_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission
    }
};

const mapDispatchToProps = {
    getBanksLevel,
    updateBanksLevelStatus,
    deleteBanksLevel
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BanksLevel));