import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Select2 from "../../components/Input/Select2/Select2";
import Button from "../../components/Button/Button";
import { getMemberGroupName, getBanksAccountName, postBanksLevel } from "../../store/ducks/banksLevel/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import { withNamespaces } from "react-i18next";

//Initialize the member group name array first
let memberGroupNameArry = [];

//Initialize the banks account name array first
let banksAccountNameArry = [];

class BanksLevelCreate extends Component {
    state = {
        bank_level_name: '',
        deposit_amount: 0,
        currentGroupName: [],
        currentBanksAccountName: [],
        error_message: [],
        touched: false,
        loading: true
    };

    componentDidMount() {
        formValidation('bank_level_form');

        const getBankLevelData = {};
        getBankLevelData.country_id = this.props.currentCountryId;

        banksAccountNameArry = [];
        banksAccountNameArry = [];

        this.props.getMemberGroupName(getBankLevelData);
        this.props.getBanksAccountName(getBankLevelData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_bank_level_result !== this.props.post_bank_level_result) {
            if (nextProps.post_bank_level_result.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false, loading: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.post_bank_level_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_bank_level_result.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_bank_level_result.message[0],
                    icon: nextProps.post_bank_level_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }

        }

        if (nextProps.bank_level_member_group_data !== this.props.bank_level_member_group_data) {
            if (nextProps.bank_level_member_group_data.data.responseCode === 200) {
                let data = nextProps.bank_level_member_group_data.data.data;
                memberGroupNameArry = [];
                Object.keys(data).map((item, i) => {
                    memberGroupNameArry.push({
                        'label': data[item].memberGroupName,
                        'value': data[item].memberGroupName,
                        'key': data[item].memberGroupName
                    });
                    return null;
                })
                this.setState({ loading: false });
            } else if (nextProps.bank_level_member_group_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.bank_level_member_group_data.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.bank_level_member_group_data.message[0],
                    icon: nextProps.bank_level_member_group_data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.bank_level_banks_account_data !== this.props.bank_level_banks_account_data) {
            if (nextProps.bank_level_banks_account_data.data.responseCode === 200) {
                let data = nextProps.bank_level_banks_account_data.data.data;
                Object.keys(data).map((item, i) => {
                    return banksAccountNameArry.push({
                        'label': data[item].bankName,
                        'options': [
                            { 'label': data[item].bankAccName, value: data[item].bankAccName }
                        ],
                        'key': data[item].bankName
                    });
                })

                this.setState({ loading: false });
            } else if (nextProps.bank_level_banks_account_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.bank_level_banks_account_data.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.bank_level_banks_account_data.message[0],
                    icon: nextProps.bank_level_banks_account_data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    getBankLevelNameHandler = (event) => {
        this.setState({ bank_level_name: event.target.value });
    }

    getDepositAmountHandler = (event) => {
        this.setState({ deposit_amount: event.target.value });
    }

    getMemberGroupNameHandler = (event) => {
        this.setState({
            currentGroupName: event
        });
    }

    getBanksAccountNameHandler = (event) => {
        this.setState({
            currentBanksAccountName: event
        });
    }

    postBankLevelHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const currentGroupName = this.state.currentGroupName;
                const currentBanksAccount = this.state.currentBanksAccountName;

                const commaSepGroupName = currentGroupName.map(itemGroupName => itemGroupName.value).join(',');
                const commaSepBanksAccountName = currentBanksAccount.map(itemBanksAccountName => itemBanksAccountName.value).join(',');

                let data = {};

                data.bank_level_name = this.state.bank_level_name;
                data.deposit_amount = this.state.deposit_amount;
                data.member_group = commaSepGroupName;
                data.bank_account = commaSepBanksAccountName;
                data.created_by = this.props.currentUsername;
                data.country_id = this.props.currentCountryId;
                data.status = 1;

                this.props.postBanksLevel(data);
            }
        })

    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('bank-level.bank-level')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('bank-level.add-bank-level')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="bank_level_form" className="form-horizontal"
                                    onSubmit={this.postBankLevelHandler} noValidate>
                                    <ValidationError formName="bank_level_form" touched={this.state.touched}
                                        message={this.state.error_message} />
                                    {this.state.loading === true ? <Skeleton count={4} /> :
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="bank_level_name">
                                                        {t('bank-level.bank-level-name')}
                                                    </label>

                                                    <StringInput
                                                        onChange={this.getBankLevelNameHandler}
                                                        id="bank_level_name"
                                                        name="bank_level_name"
                                                        className="form-control"
                                                        placeholder={t('bank-level.bank-level-name')}
                                                        value={this.state.bank_level_name}
                                                        required
                                                    />

                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="deposit_amount">
                                                        {t('bank-level.deposit-amount')}
                                                    </label>

                                                    <NumberInput
                                                        onChange={this.getDepositAmountHandler}
                                                        id="deposit_amount"
                                                        name="deposit_amount"
                                                        className="form-control"
                                                        placeholder={t('bank-level.deposit-amount')}
                                                        value={this.state.deposit_amount}
                                                        required
                                                    />

                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="group">
                                                        {t('bank-level.group')}
                                                    </label>
                                                    <Select2
                                                        options={memberGroupNameArry}
                                                        name="member-group"
                                                        onChange={this.getMemberGroupNameHandler}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="bank_account">
                                                        {t('bank-level.bank-account')}
                                                    </label>
                                                    <Select2
                                                        options={banksAccountNameArry}
                                                        name="banks-account"
                                                        onChange={this.getBanksAccountNameHandler}
                                                    />
                                                </div>

                                                <div className="form-group mb-0 mt-3 justify-content-end">
                                                    <div>
                                                        <Button
                                                            typeButton="submit"
                                                            classValue="btn btn-blue"
                                                            idValue="submit"
                                                            buttonName={t("global.save")}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { bankLevel, currentUser } = state;

    //Getting the state from the reducers
    return {
        bank_level_member_group_data: bankLevel.bank_level_member_group_data,
        bank_level_banks_account_data: bankLevel.bank_level_banks_account_data,
        post_bank_level_result: bankLevel.post_bank_level_result,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username
    }
};

const mapDispatchToProps = {
    getMemberGroupName,
    getBanksAccountName,
    postBanksLevel
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksLevelCreate));