import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import '../../assets/css/simple-line-icons.scss';
import '../../assets/css/treeview.css';

import { formValidation, clearFormValidation, filterErrorHandler } from '../../hoc/Shared/utility';
import StringInput from '../../components/Input/StringInput/StringInput';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import Select from '../../components/Input/Select/Select';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/permission/actions';

const initialFormInputState =
{
    title: '',
    link: '',
    permission: '',
    status: 1,
    is_main_menu: 1,
    is_menu: 0,
    idx: '',
    pid: 0,
};

class PermissionStore extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            input: initialFormInputState,
            module: [],
            loading: true,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation('permission_form');
        this.props.funcGetDetails();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            // console.log(nextProps.details);
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                this.setState({
                    module: nextProps.details.data.module,
                    loading: false
                });
            } else {
                this.setState({
                    module: []
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('permission_form');
                    this.setState({
                        input: initialFormInputState,
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.store.message
                });
                Swal.close();
            }
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.id] = event.target.value;
        // console.log(newInput);
        this.setState({
            input: newInput
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};
                data.title = this.state.input.title;
                data.permission = this.state.input.permission;
                data.link = this.state.input.link;
                data.pid = (this.state.input.is_main_menu === '1') ? 0 : this.state.input.pid;
                data.idx = this.state.input.idx;
                data.is_menu = (this.state.input.is_main_menu === '1' || this.state.input.is_menu === '1' || data.pid === 0) ? 1 : 0;
                data.status = this.state.input.status;
                // console.log(data);
                this.props.funcStore(data);
            }
        });
    }

    render() {
        // console.log(menuType);
        const { t } = this.props;
        let module = this.state.module;
        let mainModule = [];
        let subModule = [];
        let tmp = '';
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        Object.keys(module).forEach((key) => {
            module[key].forEach((val) => {
                tmp = (key === 'mainModule') ? mainModule : subModule;
                tmp.push({
                    'id': val.id,
                    'title': t(`side-bar.${val.title}`)
                });
            });
        });

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('permission.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('permission.add-permission')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='permission_form' className='form-horizontal row' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='permission_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            {/* <div className='row'> */}
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>{t('permission._title')}</label>
                                                    <StringInput
                                                        id='title'
                                                        name='title'
                                                        placeholder={t('permission._title')}
                                                        value={this.state.input.title}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>{t('permission.link')}</label>
                                                    <StringInput
                                                        id='link'
                                                        name='link'
                                                        placeholder={t('permission.link')}
                                                        value={this.state.input.link}
                                                        onChange={(event) => this.onInputChange(event)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>{t('permission.permission')}</label>
                                                    <StringInput
                                                        id='permission'
                                                        name='permission'
                                                        placeholder={t('permission.permission')}
                                                        value={this.state.input.permission}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className='row'> */}
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>{t('permission.sequence')}</label>
                                                    <NumberInput
                                                        id='idx'
                                                        name='idx'
                                                        placeholder={t('permission.sequence')}
                                                        min={1}
                                                        max={100}
                                                        value={this.state.input.idx}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>{t('permission.status')}</label>
                                                    <div className='select-input'>
                                                        <select className='form-control datatable-select' id='status' name='status' value={this.state.input.status} onChange={(event) => this.onInputChange(event)}>
                                                            <option value={1}>{t('global.active')}</option>
                                                            <option value={0}>{t('global.inactive')}</option>
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4 form-group'>
                                                <label>{t('permission.is-main-menu')}</label>
                                                <div className='select-input'>
                                                    <select className='form-control datatable-select' id='is_main_menu' name='is_main_menu' value={this.state.input.is_main_menu} onChange={(event) => this.onInputChange(event)}>
                                                        <option value='0'>{t('global._no')}</option>
                                                        <option value='1'>{t('global._yes')}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                            {
                                                (Number(this.state.input.is_main_menu) === 0)
                                                    ?
                                                    <div className='col-md-4'>
                                                        <label>{t('permission.is-sub-menu')}</label>
                                                        <select className='form-control' id='is_menu' name='is_menu' value={this.state.input.is_menu} onChange={(event) => this.onInputChange(event)}>
                                                            <option value='0'>{t('global._no')}</option>
                                                            <option value='1'>{t('global._yes')}</option>
                                                        </select>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {/* </div> */}
                                            {
                                                (Number(this.state.input.is_main_menu) === 0)
                                                    ?
                                                    <div className='col-md-4 form-group'>
                                                        <label>{t('permission.under-permission')}</label>
                                                        <Select
                                                            id='pid'
                                                            name='pid'
                                                            className='form-control'
                                                            getOptionLabel='title'
                                                            getOptionValue='id'
                                                            options={(Number(this.state.input.is_menu) === 0) ? subModule : mainModule}
                                                            onChange={this.onInputChange}
                                                            value={this.state.input.pid}
                                                            emptyValue={true}
                                                            required
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className='col-12 form-group mb-0 justify-content-end'>
                                                <div>
                                                    {(permission.includes('permission_store')) &&
                                                        <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t("global.save")} />
                                                    }
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.permission.details,
        store: state.permission.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: () => dispatch(actions.getDetails()),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PermissionStore));