import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import DataTable from "../../../components/Datatable/DataTable";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Input/Select/Select";
import Excel from "../../../assets/images/excel.svg";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

class DepositStatusReportListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    openCollapse = () => {
        var myCollapsible = document.getElementById('myCollapsible')
        myCollapsible.addEventListener('hidden.bs.collapse', function () {
            // do something...
        })
    }

    render() {
        const { t } = this.props;

        let deposit_status_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("member.have-deposit"), value: "yes" },
            { label: t("member.no-deposit"), value: "no" },
        ];
        return (
            <div className="row row-sm">
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("report.member-deposit-status-report")}</h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card card-body card-primary">
                        <div>
                            {this.props.loading ? (
                                <Skeleton count={2} />
                            ) : (
                                <form
                                    method="post"
                                    id="filter_deposit_status_form"
                                    className={`form-horizontal filter-function row
                        ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                    {/* <div className="row"> */}
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="filter_register_date" className="font-weight-bolder">
                                                {t("member.account-created-date")}
                                            </label>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-calendar-check"></i>
                                                </span>
                                                <DateTimePicker
                                                    id="filter_register_date"
                                                    name="filter_register_date"
                                                    className="form-control filter-input"
                                                    range={true}
                                                    timePicker={true}
                                                    // format="YYYY/MM/DD"
                                                    value={this.props.filter.filter_register_date}
                                                    onChange={this.props.onInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="filter_deposit_status" className="font-weight-bolder">
                                                {t("member.deposit-status")}
                                            </label>
                                            <Select
                                                id="filter_deposit_status"
                                                name="filter_deposit_status"
                                                options={deposit_status_option}
                                                onChange={this.props.onInputChange}
                                                value={this.props.filter.filter_deposit_status}
                                                className="form-control filter-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="filter_member_group" className="font-weight-bolder">
                                                {t("member.member-group")}
                                            </label>
                                            <div className="select-input">
                                                <select
                                                    className="form-control filter-input"
                                                    id="filter_member_group"
                                                    name="filter_member_group"
                                                    onChange={this.props.onInputChange}
                                                    value={this.props.filter.filter_member_group}>
                                                    <option value=""></option>
                                                    {this.props.ddMemberGroup}
                                                </select>
                                                <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}

                                    <div className="col mt-auto">
                                        <div className="d-flex">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                            &nbsp;
                                            &nbsp;
                                            {(() => {
                                                if (this.props.totalRecords > 0) {
                                                    return (
                                                        <Button
                                                            typeButton="button"
                                                            classValue="btn btn-blue mr-2"
                                                            clicked={() => this.props.exportExcelHandler()}
                                                            img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                            buttonName={t("report.export-excel")}
                                                        />
                                                    );
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>

                        <div className="show-btn" onClick={() => this.toggleCard()}>
                            {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                        </div>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="card-title mg-b-0">{t("report.member-deposit-status-report")}</h4>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover datatable">
                                <DataTable
                                    keyField="id"
                                    data={this.props.data}
                                    columns={this.props.columns}
                                    activePage={this.props.activePage}
                                    limit={this.props.limit}
                                    totalItems={this.props.totalRecords}
                                    paginationHandler={this.props.pageChangeHandler}
                                    sorted={this.props.sorted}
                                    sizePerPageHandler={this.props.sizePerPageHandler}
                                    hideSearchBar={true}
                                    loading={this.props.loading}
                                    classValue="dataTable table-bordered text-justify text-center"
                                    expandRow="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces("translation")(DepositStatusReportListing);
