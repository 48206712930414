import React, { Component } from "react";
import { connect } from "react-redux";
import { showAgent, setAgentShowLocalState, setAgentShowInitialLoading } from "../../../store/ducks/agent/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

class AgentInfo extends Component {
    constructor(props) {
        super(props);
        const initLoad = this.props.agentShowInitialLoading.filter(item => item.id === "agent_info");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                loading: true,
                currentPage: 'agent_info',
                initialLoading: [
                    { id: "agent_info", loading: true },
                    { id: "transaction_history", loading: true },
                    { id: "wallet_tracker", loading: true }
                ],
            };
            this.props.setAgentShowLocalState(this.state);
        } else {
            this.state = this.props.agentInfoLocalState;
        }
    }

    componentDidMount() {
        const initLoad = this.props.agentShowInitialLoading.filter(item => item.id === "agent_info");
        const initialLoading = initLoad[0].loading;
        if (initialLoading) {
            this.props.showAgent(this.props.agentShowId);

            this.props.setAgentShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.agentShowData !== undefined) {
                if (this.props.agentShowData.data.agent.agentId !== this.props.agentShowId) {
                    this.props.showAgent(this.props.agentShowId);
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                this.props.showAgent(this.props.agentShowId);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.agentShowData !== undefined && nextProps.agentShowData !== nextState.agentShowData && nextState.loading === true) {
            this.setState({
                loading: false,
            });
        }
        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        // let tabId = this.state.pageShow;
        // const tabArray = ['agent_info','transaction_history','wallet_tracker'];

        if (!this.state.loading) {
            let agentData = this.props.agentShowData.data.agent[0];
            let transactionDetails = this.props.agentShowData.data.transactionDetails;

            display = (
                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.agent-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("agent.username")}</th>
                                            <td field-key="username">{agentData.username}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.name")}</th>
                                            <td field-key="name">{agentData.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.referral-code")}</th>
                                            <td field-key="referral_code">{agentData.referral_code}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.contact-no")}</th>
                                            <td field-key="contact_no">{agentData.contact_no}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.email")}</th>
                                            <td field-key="email">{agentData.email}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.status")}</th>
                                            <td field-key="status">{agentData.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                        </tr>
                                        {/* <tr>
                                            <th>{t("agent.agent-type")}</th>
                                            <td field-key="agent_type">{agentData.agent_type === 1 ? t("agent.cash-mode") : t("agent.credit-mode")}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.fixed-cost")}</th>
                                            <td field-key="fixed_cost">{agentData.fixed_cost}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.pt")}</th>
                                            <td field-key="pt">{agentData.pt}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.cost")}</th>
                                            <td field-key="cost">{agentData.cost}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.login-permission")}</th>
                                            <td field-key="login_permission">{agentData.loginPermission === 1 ? t("agent.granted") : t("agent.denied")}</td>
                                        </tr> */}
                                        <tr>
                                            <th>{t("agent.last-login")}</th>
                                            <td field-key="last_login">{agentData.last_login}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.created-time")}</th>
                                            <td field-key="created_date">{agentData.created_at}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.updated-time")}</th>
                                            <td field-key="updated_date">{agentData.updated_at}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.agent-transaction-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("agent.wallet-balance")}</th>
                                            <td field-key="wallet_balance">{transactionDetails.wallet_balance}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.total-deposit")}</th>
                                            <td field-key="total_deposit">{transactionDetails.deposit}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("agent.total-withdrawal")}</th>
                                            <td field-key="total_withdrawal">{transactionDetails.withdrawal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent.agent-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { agent } = state;

    return {
        agentShowData: agent.agent_show_data,
        agentShowInitialLoading: agent.agent_show_initial_loading,
        agentInfoLocalState: agent.agent_info_local_state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAgent: (id) => dispatch(showAgent(id)),
        setAgentShowLocalState: (data) => dispatch(setAgentShowLocalState(data)),
        setAgentShowInitialLoading: (data) => dispatch(setAgentShowInitialLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentInfo));
