import React, { Component } from "react";
import { connect } from "react-redux";
import { showMember, setMemberShowLocalState, setMemberShowInitialLoading } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Select from "../../../components/Input/Select/Select";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
// import moment from "moment";
import { filterErrorHandler } from "../../../hoc/Shared/utility";

class MemberInfo extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "member_info");
        const initialLoading = initLoad[0].loading;
        if (initialLoading) {
            this.state = {
                currentPage: "member_info",
                show_id: null,
                loading: true,
                reload: false,
                bank_option: 0,
                // crypto_option: 0,
                // withdrawal_crypto_option: 0,
                // withdrawal_network_option: 0,
            };
            this.props.setMemberShowLocalState(this.state);
        } else {
            this.state = this.props.memberInfoLocalState;
        }
        // console.log("[MemberInfo] constructor()", "\nprops: ",props, "\nstate: ", this.state);
    }

    componentDidMount() {
        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "member_info");
        const initialLoading = initLoad[0].loading;
        if (initialLoading) {
            this.props.showMember(this.props.memberShowId);

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.memberShowData !== undefined) {
                if (this.props.memberShowData.data && this.props.memberShowData.data.length > 0 
                    && this.props.memberShowData.data.member.memberId !== this.props.memberShowId) {
                        this.props.showMember(this.props.memberShowId);
                } else {
                    // this.setState({
                    //     loading: false,
                    //     reload: true
                    // });
                }
            } else {
                this.props.showMember(this.props.memberShowId);
            }
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberInfo] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.memberShowId !== undefined && nextProps.memberShowId !== nextState.show_id && nextProps.memberShowData !== undefined) {
            if(nextProps.memberShowData !== this.props.memberShowData) {
                if (nextProps.memberShowData.responseCode === 200 && nextProps.memberShowData.msgType === "success") {
                    Swal.close();

                    this.setState({
                        show_id: nextProps.memberShowId,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        show_id: null
                    }, () => {
                        filterErrorHandler(nextProps.memberShowData.message);
                    })
                }
            }
        }
        return true;
    }

    componentWillUnmount() {
        // console.log("[MemberInfo] componentWillUnmount()", "\nstate: ", this.state);
        this.props.setMemberShowLocalState(this.state);
    }

    handleBankIndexChange = (event) => {
        this.setState({
            [event.target.name]: Number(event.target.value),
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            // console.log(this.props.memberShowData);

            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let memberData = this.props.memberShowData.data.member;
            let memberTransactionData = this.props.memberShowData.data.memberTransaction;
            let memberReferralData = this.props.memberShowData.data.memberReferral;
            let memberBankData = this.props.memberShowData.data.memberBank;
            let memberBankOption = memberBankData.map((item, idx) => {
                return { label: item.bankName, value: idx };
            });
            // let memberBusinessAccountData = this.props.memberShowData.data.memberBusinessAccount;
            // let memberCryptoData = this.props.memberShowData.data.memberCrypto;
            // let memberCryptoOption = memberCryptoData.map((item, idx) => {
            //     return { label: item.crypto, value: idx };
            // });

            // let memberWithdrawalCryptoData = this.props.memberShowData.data.memberWithdrawalCrypto;

            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.member-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>{t("member.username")}</th>
                                                <td field-key="username">{memberData.username}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.nickname")}</th>
                                                <td field-key="nickname">{memberData.nickname}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.name")}</th>
                                                <td field-key="name">{memberData.name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.contact-no")}</th>
                                                <td field-key="contact_no">{memberData.contactNo}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.email")}</th>
                                                <td field-key="email">{memberData.email}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.cusID")}</th>
                                                <td field-key="cusID">{memberData.cus_id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.date-of-birth")}</th>
                                                <td field-key="date_of_birth">{memberData.dateOfBirth === null ? "-" : memberData.dateOfBirth}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.referral-code")}</th>
                                                <td field-key="referral_code">{memberData.referralCode}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.status")}</th>
                                                <td field-key="status">{memberData.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>{t("member.pause-bet")}</th>
                                                <td field-key="pause_bet">{memberData.pauseBet === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr> */}
                                            <tr>
                                                <th>{t("member.account-type")}</th>
                                                <td field-key="account_type">{memberData.accountType === 1 ? t("member.real") : t("member.tester")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.have-deposit")}</th>
                                                <td field-key="deposit_status">{memberData.depositStatus === 1 ? t("global._yes") : t("global._no")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.member-group")}</th>
                                                <td field-key="member_group">{t(`global.level.${memberData.level}`)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.email-verified")}</th>
                                                <td field-key="email_verified">{(memberData.emailVerified === "0" || memberData.emailVerified === "") ? t("global._no") : t("global._yes")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.contact-verified")}</th>
                                                <td field-key="member_group">{(memberData.contactVerified === "0" || memberData.contactVerified === "") ? t("global._no") : t("global._yes")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.google-account")}</th>
                                                <td field-key="google_account">{(memberData.googleAccount === "0" || memberData.googleAccount === "") ? t("global.inactive") : t("global.active")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.telegram-account")}</th>
                                                <td field-key="telegram_account">{(memberData.telegramAccount === "0" || memberData.telegramAccount === "") ? t("global.inactive") : t("global.active")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.register-from")}</th>
                                                <td field-key="register_from">{memberData.registrationSite === "" || memberData.registrationSite === null ? "-" : memberData.registrationSite}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.register-time")}</th>
                                                <td field-key="register_details">{memberData.createdTime}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.last-login")}</th>
                                                <td field-key="last_login">{memberData.lastLogin}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.updated-by")}</th>
                                                <td field-key="updated_by">{memberData.updatedBy}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            {/* {memberData.agentCode !== null ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.agent-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.agent-code")}</th>
                                                    <td field-key="agent_code">{memberData.agentCode}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.agent-username")}</th>
                                                    <td field-key="agent_username">{memberData.agentUsername}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.agent-name")}</th>
                                                    <td field-key="agent_name">{memberData.agentName}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.agent-email")}</th>
                                                    <td field-key="agent_email">{memberData.agentEmail}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null} */}

                            {memberReferralData !== null ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.referral-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.referral-username")}</th>
                                                    <td field-key="referral_username">{memberReferralData.username}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.referral-name")}</th>
                                                    <td field-key="referral_name">{memberReferralData.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.referral-email")}</th>
                                                    <td field-key="referral_email">{memberReferralData.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {memberBankData.length > 0 ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.member-bank-account-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.bank")}</th>
                                                    <td field-key="bank">
                                                        <Select
                                                            id="bank_option"
                                                            name="bank_option"
                                                            options={memberBankOption}
                                                            onChange={this.handleBankIndexChange}
                                                            className="form-control"
                                                            value={this.state.bank_option}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.bank-account-name")}</th>
                                                    <td id="bank_acc_name" field-key="bank_acc_name">
                                                        {/* {memberBankData[this.state.bank_option].bankAccName} */}
                                                        {memberBankData[this.state.bank_option].companyName ? memberBankData[this.state.bank_option].companyName : memberData.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.bank-account-no")}</th>
                                                    <td id="bank_acc_number" field-key="bank_acc_number">
                                                        {memberBankData[this.state.bank_option].bankAccNumber}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {memberTransactionData ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.member-transaction-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.wallet-balance")}</th>
                                                    <td id="wallet_balance" field-key="wallet_balance">{memberData.member_wallet_balance !== null ? new Intl.NumberFormat("en-US", num_format).format(memberData.member_wallet_balance) : 0.00}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>{t("member.coin")}</th>
                                                    <td id="coin" field-key="coin">{memberData.coin !== null ? new Intl.NumberFormat("en-US", num_format).format(memberData.coin) : 0.00}</td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("member.total-deposit")}</th>
                                                    <td id="total_deposit" field-key="total_deposit">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalDeposit)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-withdrawal")}</th>
                                                    <td id="total_withdrawal" field-key="total_withdrawal">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalWithdrawal)}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <th>{t("member.total-bet-amount")}</th>
                                                    <td id="total_bet_amount" field-key="total_bet_amount">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalBetAmount)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-valid-turnover")}</th>
                                                    <td id="total_valid_turnover" field-key="total_valid_turnover">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalValidTurnover)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-rebate")}</th>
                                                    <td id="total_rebate" field-key="total_rebate">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalRebate)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-bonus")}</th>
                                                    <td id="total_bonus" field-key="total_bonus">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalBonus)}
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("member.last-transaction-time")}</th>
                                                    <td id="last_transaction_time" field-key="last_transaction_time">
                                                        {memberTransactionData.lastTransactionTime}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {/* {memberBusinessAccountData ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.member-business-account-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("business-account.date")}</th>
                                                    <td id="date" field-key="date">
                                                        {moment(memberBusinessAccountData.updated_at).format("YYYY-MM-DD hh:mm:ss A")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("business-account.company-name")}</th>
                                                    <td id="company_name" field-key="company_name">
                                                        {memberBusinessAccountData.company_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("business-account.company-reg-no")}</th>
                                                    <td id="company_reg_no" field-key="company_reg_no">
                                                        {memberBusinessAccountData.company_reg_no}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.bank")}</th>
                                                    <td field-key="bank">
                                                        {memberBusinessAccountData.bankName}
                                                    </td>
                                                </tr>
                                               
                                                <tr>
                                                    <th>{t("member.bank-account-no")}</th>
                                                    <td id="bank_acc_number" field-key="bank_acc_number">
                                                        {memberBusinessAccountData.bank_account_number}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("business-account.ssm-image")}</th>
                                                    <td id="ssm_image" field-key="ssm_image">
                                                        <img src={memberBusinessAccountData.ssm_image} width="500px" height="auto" alt="" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null} */}

                            {/* {memberCryptoData.length > 0 || memberWithdrawalCryptoData.length > 0 ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.crypto-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr className="table-secondary">
                                                    <th className="table-secondary text-center" colSpan={2}>
                                                        {t("member.deposit-address")}
                                                    </th>
                                                </tr>
                                                {memberCryptoData.length > 0 ? (
                                                    <>
                                                        <tr>
                                                            <th>{t("member.crypto")}</th>
                                                            <td field-key="crypto_option">
                                                                <Select
                                                                    id="crypto_option"
                                                                    name="crypto_option"
                                                                    options={memberCryptoOption}
                                                                    onChange={this.handleBankIndexChange}
                                                                    className="form-control"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>{t("member.crypto-address")}</th>
                                                            <td id="address" field-key="address">
                                                                {memberCryptoData[this.state.crypto_option].address}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <th className="text-center" colSpan={2}>
                                                            {t("member.records-not-found")}
                                                        </th>
                                                    </tr>
                                                )}

                                                <tr>
                                                    <th className="table-secondary text-center" colSpan={2}>
                                                        {t("member.withdrawal-address")}
                                                    </th>
                                                </tr>
                                                {memberWithdrawalCryptoData.length > 0 ? (
                                                    <></>
                                                ) : (
                                                    <tr>
                                                        <th className="text-center" colSpan={2}>
                                                            {t("member.records-not-found")}
                                                        </th>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null} */}
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.member-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.member-transaction-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.agent-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.referral-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.member-bank-account-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            {/* <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.crypto-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </>
            );
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberInfoLocalState: member.member_info_local_state,

        memberShowData: member.member_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),

        showMember: (id) => dispatch(showMember(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberInfo));
