import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withNamespaces } from "react-i18next";
import Skeleton from 'react-loading-skeleton';
// import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import {showBank} from "../../store/ducks/banks/actions";
import { filterErrorHandler } from '../../hoc/Shared/utility';

class BanksShow extends Component {
    state = {
        bank_name: '',
        swift_code: '',
        bb_code: '',
        payment_gateway: false,
        status: '',
        loading: true
    };

    componentDidMount() {
        const showBankData = {};
        showBankData.bank_id = this.props.show_bank_id;
        this.props.showBank(showBankData);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.show_bank_data !== this.props.show_bank_data) {
            if (nextProps.show_bank_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    bank_name: nextProps.show_bank_data.data.data[0].name,
                    swift_code: nextProps.show_bank_data.data.data[0].bankSwiftCode,
                    payment_gateway: nextProps.show_bank_data.data.data[0].enablePaymentGateway,
                    status: nextProps.show_bank_data.data.data[0].status
                })
            } else{
                this.setState({
                    loading: true,
                    bank_name: '',
                    swift_code: '',
                    payment_gateway: false,
                    status: '',
                }, () => {
                    filterErrorHandler(nextProps.show_bank_data.data.message);
                });
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("bank.banks")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("bank.show-banks")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <th>{t("bank.bank_name")}</th>
                                            <td field-key='bank_name'>{this.state.bank_name}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('bank.swift_code')}</th>
                                            <td field-key='swift_code'>{this.state.swift_code}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('global.status')}</th>
                                            <td field-key='status'>{this.state.status === 1 ? 'Active' : 'Inactive'}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('bank.payment_gateway')}</th>
                                            <td field-key='payment_gateway'>{this.state.payment_gateway === 1 ? 'Enabled' : 'Disabled'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {bank} = state;

    //Getting the state from the reducers
    return {
        show_bank_data: bank.show_bank_data
    }
};

const mapDispatchToProps = {
    showBank
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksShow));