import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { AiFillPlusCircle } from "react-icons/ai";

import Button from '../../components/Button/Button';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import ValidationError from '../../components/ValidationError/ValidationError';

class PermissionListing extends Component {
    render() {
        const { t } = this.props;
        let mainModule = (this.props.data.mainModule) ? this.props.data.mainModule : [];
        let subModule = (this.props.data.subModule) ? this.props.data.subModule : [];
        let sonModule = (this.props.data.sonModule) ? this.props.data.sonModule : [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0'>{t('permission.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm justify-content-center'>
                    <div className='col-xl-8'>
                        <ValidationError formName='product_maintenance_form' touched={this.props.touched} message={this.props.errMsg} />
                        <div className='card'>
                            <div className="d-flex align-items-end px-3 pt-3">
                                <h4 className='card-title mg-b-0'>{t('permission.listing')}</h4>
                                <div className='d-flext ml-3'>
                                    <div className='my-auto'>
                                        <div className='btn-group'>
                                            {(permission.includes('permission_store')) &&
                                                <>
                                                    <AiFillPlusCircle />
                                                    <Button typeButton='button' classValue='btn btn-underline' idValue='create' buttonName={t('permission.add-permission')} clicked={() => this.props.changePageHandler('create')} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    <hr />
                                    {this.props.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <ul id='tree1' className='tree'>
                                                {
                                                    mainModule.map((main, idx) => {
                                                        // let menuContent = '';
                                                        let subMenuContentUl = [];
                                                        let subMenuContentLi = [];
                                                        let mainTitleVal = (permission.includes('permission_update')) ? <a href='/#' onClick={(event) => this.props.detailsHandler(event, 'edit', main.id)}>{t(`side-bar.${main.title}`)}</a> : t(`side-bar.${main.title}`);

                                                        if (subModule[main.id]) {
                                                            subModule[main.id].forEach((sub, idx1) => {
                                                                let sonMenuContentLi = [];
                                                                let sonMenuContentUl = [];
                                                                let subTitleVal = (permission.includes('permission_update')) ? <a href='/#' onClick={(event) => this.props.detailsHandler(event, 'edit', sub.id)}>{t(`side-bar.${sub.title}`)}</a> : t(`side-bar.${sub.title}`);

                                                                if (sonModule[sub.id]) {
                                                                    let count = 0;
                                                                    let br = '';
                                                                    sonModule[sub.id].forEach((son, idx2) => {
                                                                        let sonTitleVal = (permission.includes('permission_update')) ? <a href='/#' onClick={(event) => this.props.detailsHandler(event, 'edit', son.id)}>{t(`role.${son.title}`)}</a> : t(`role.${son.title}`);
                                                                        br = (count >= 5) ? <br /> : '';
                                                                        sonMenuContentLi.push(<span key={idx2}><i className='fas fa-angle-right' />{sonTitleVal}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{br}</span>);
                                                                        count = (count >= 5) ? 0 : count + 1;
                                                                    });
                                                                    sonMenuContentUl.push(
                                                                        <ul key={idx1}>
                                                                            <li key={idx1}>
                                                                                {sonMenuContentLi}
                                                                            </li>
                                                                        </ul>
                                                                    );
                                                                }
                                                                subMenuContentLi.push(
                                                                    <li key={idx1}>
                                                                        <i className='fas fa-angle-down' />{subTitleVal}&nbsp;
                                                                        {/* <i className='far fa-edit' style={{color: 'green'}} /> */}
                                                                        {/* <i className='fas fa-trash-alt' style={{color: 'red'}} /> */}
                                                                        {/* <i className='fas fa-angle-down' /> */}
                                                                        {sonMenuContentUl}
                                                                    </li>
                                                                );
                                                            });
                                                            subMenuContentUl.push(<ul key={idx}>{subMenuContentLi}</ul>);
                                                        }
                                                        return (
                                                            <li key={idx}>
                                                                <i className='fas fa-angle-down' />{mainTitleVal}&nbsp;
                                                                {/* <i className='far fa-edit' style={{color: 'green'}} /> */}
                                                                {/* <i className='fas fa-trash-alt' style={{color: 'red'}} /> */}
                                                                {subMenuContentUl}
                                                                <hr />
                                                            </li>

                                                        );
                                                    })
                                                }
                                            </ul>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(PermissionListing);