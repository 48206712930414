import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withNamespaces} from 'react-i18next';
import Swal from 'sweetalert2';

// import LoginImage from '../../../assets/images/auth/login.png';
// import LogoImage from '../../../assets/images/logo/x33-blue.png';
// import Logo from '../../../assets/images/logo/favicon.png';
// import { AiOutlineUser } from 'react-icons/';

import StringInput from "../../../components/Input/StringInput/StringInput";
import PasswordInput from "../../../components/Input/PasswordInput/PasswordInput";
// import Checkbox from "../../../components/Input/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import ValidationError from '../../../components/ValidationError/ValidationError';

import { postLogin, postIsAuthorized } from "../../../store/ducks/auth/actions";
import { getUserData } from "../../../store/ducks/currentUser/actions";

import {formValidation} from "../../../hoc/Shared/utility";

class Login extends Component {
    state = {
        username: '',
        password: '',
        rememberMe: false,
        checkAuthorized: true,
        touched: false,
        errMsg: [],
    };

    componentDidMount() {
        formValidation('sigin-form');
        this.props.postIsAuthorized();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.login_data !== this.props.login_data){
            let success = (nextProps.login_data.responseCode === 200 && nextProps.login_data.msgType === 'success') ? true : false;
            if(nextProps.login_data.responseCode === 200){
                let loginData = nextProps.login_data.data;
                Swal.close();
                localStorage.setItem("accessToken", loginData.access_token);
                localStorage.setItem("lang", 'en');

                // Redux-Persist
                const loggedData = {};
                loggedData.access_token = loginData.access_token;
                loggedData.id = loginData.id;
                loggedData.username = loginData.username;
                loggedData.user_group = loginData.user_group;
                loggedData.country_id = loginData.country_id;
                loggedData.country_code = loginData.country_code;
                loggedData.language_id = loginData.language_id;
                loggedData.language_code = loginData.language_code;
                loggedData.group_id = loginData.group_id;
                loggedData.system_setting = loginData.system_setting;
                loggedData.currency = loginData.currency;
                this.props.getUserData(loggedData);

                this.props.i18n.changeLanguage('en');

                this.props.history.push('/home');
            }else if(nextProps.login_data.responseCode === 422){
                // const { t } = this.props;

                // Swal.fire({
                //     title: nextProps.login_data.message,
                //     icon: nextProps.login_data.msgType,
                //     confirmButtonText: t("sweetalert.ok"),
                // })
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.login_data.message,
                });
                Swal.close();
            }else if(nextProps.login_data.data.data === "First Time Login"){
                Swal.fire({
                    title: nextProps.login_data.data.message[0],
                    icon: nextProps.login_data.data.msgType,
                    confirmButtonText: `OK`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push({
                            pathname: '/change_password',
                            state: {username: this.state.username}
                        });
                    }
                });

                return false;
            }

        }

        if(nextProps.authorized_data!==undefined && nextProps.authorized_data!==this.props.authorized_data){
            this.setState({
                checkAuthorized: false
            });
        }
        return true;
    }

    getUsernameValueHandler = (event) => {
        this.setState({
            username: event.target.value
        });
    }

    getPasswordValueHandler = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    getRememberValueHandler = (event) => {
        this.setState({
            rememberMe: !this.state.rememberMe
        })
    }

    signInClickedHandler = (e) => {
        e.preventDefault();
        const data = {};

        data.username = this.state.username;
        data.password = this.state.password;

        //Call the login api
        this.props.postLogin(data);

        // Swal.showLoading();
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    render() {
        // console.log(this.state.checkAuthorized);
        return (
            <>
            <form id="sigin-form" onSubmit={this.signInClickedHandler} noValidate>
            {!this.state.checkAuthorized && (
            <div className="container-fluid">
                <div className="row no-gutter bg-white">
                    <div className="col-12 p-0 login__background">
                        <div className="login d-flex align-items-center">
                            <div className="container p-0">
                                {/* <div className="d-flex justify-content-center">
                                    <img src={LogoImage}/>
                                </div> */}
                                <div className="row justify-content-center">
                                    <div className="col-10 col-md-6 bg-white login__container p-0 rounded">
                                        <div className="card-sigin ">
                                            {/* <div className="mb-5 d-flex"><a href="/"><img
                                                src={Logo} className="sign-favicon ht-40" alt="logo"/></a><h1
                                                className="main-logo1 ml-1 mr-0 my-auto tx-28">Va<span>le</span>x</h1>
                                            </div> */}
                                            <ValidationError formName='login_form' touched={this.state.touched} message={this.state.errMsg}/>
                                            <div className="card-sigin p-0">
                                                <div className="main-signup-header">

                                                    {/* <div className="d-flex justify-content-center py-3">
                                                        <img src={LogoImage}/>
                                                    </div> */}
                                                    <h2>Welcome back!</h2>
                                                    <h5 className="font-weight-semibold mb-4">Please sign in to continue.</h5>
                                                    
                                                        <div className="form-group">
                                                            <label>Username</label>
                                                            <StringInput
                                                                onChange={this.getUsernameValueHandler}
                                                                className="rounded"
                                                                id="username"
                                                                name="username"
                                                                placeholder="Enter your username"
                                                                value={this.state.username}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Password</label>
                                                            <PasswordInput
                                                                onChange={this.getPasswordValueHandler}
                                                                className="rounded"
                                                                id="password"
                                                                name="password"
                                                                placeholder="Enter your password"
                                                                value={this.state.password}
                                                                required
                                                                minLength="6"
                                                            />

                                                        </div>
                                                        <Button
                                                            typeButton="submit"
                                                            classValue="btn btn-main-primary btn-block rounded"
                                                            idValue="submit"
                                                            buttonName="Sign In"
                                                            clicked={() => {}}
                                                        />
                                                    
                                                    {/* <div className="main-signin-footer mt-4">
                                                        <Checkbox
                                                            onChange={this.getRememberValueHandler}
                                                            name="remember"
                                                            id="remember"
                                                            value={this.state.rememberMe}
                                                        />
                                                        <label className="form-check-label"><strong>Remember
                                                            Me?</strong></label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
            </form>
            </>
        )
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const { auth } = state;

    return {
        login_data : auth.login_data,
        authorized_data: auth.authorized_data
        // classIsShowing: modal.classIsShowing,
        // verifyTwoFactorData: twoFactorAuthentication.verifyTwoFactorData,
        // resendAccountActivationData: resendAccountActivation.data
    }
};

//Using the redux calling api methods
const mapDispatchToProps = {
    postLogin, getUserData, postIsAuthorized
};
// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Login));