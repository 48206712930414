import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Skeleton from "react-loading-skeleton";
// import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

// import DataTable from "../../../components/Datatable/DataTable";
import Button from "../../../components/Button/Button";
// import Select from "../../../components/Input/Select/Select";
// import Excel from "../../../assets/images/excel.svg";
// import UpdownArrow from '../../../assets/images/updown-arrow.png'

class MemberLoginHistoryReportListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    openCollapse = () => {
        var myCollapsible = document.getElementById('myCollapsible')
        myCollapsible.addEventListener('hidden.bs.collapse', function () {
            // do something...
        })
    }

    render() {
        const { t } = this.props;

        // let deposit_status_option = [
        //     { label: t("global.select"), value: "", selected: true },
        //     { label: t("member.have-deposit"), value: "yes" },
        //     { label: t("member.no-deposit"), value: "no" },
        // ];
        return (
            <div className="row row-sm">
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("report.member-login-history-report")}</h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card card-body card-primary">
                        <div>
                            {this.props.loading ? (
                                <Skeleton count={2} />
                            ) : (
                                <form
                                    method="post"
                                    id="filter_deposit_status_form"
                                    className={`form-horizontal filter-function row
                        ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                    {/* <div className="row"> */}
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label htmlFor="filter_date" className="font-weight-bolder">
                                                {t("report.date")}
                                            </label>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-calendar-check"></i>
                                                </span>
                                                <DateTimePicker
                                                    id="filter_date"
                                                    name="filter_date"
                                                    className="form-control filter-input"
                                                    range={true}
                                                    timePicker={true}
                                                    // format="YYYY/MM/DD"
                                                    value={this.props.filter.filter_date}
                                                    onChange={this.props.onInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col mt-auto">
                                        <div className="d-flex">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="card-title mg-b-0">{t("report.member-login-history-report")}</h4>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover datatable">
                                <table>
                               <tr>
                                    <th>{t("member-login-report.desktop-user")}</th>
                                    <th>{t("member-login-report.mobile-view-user")}</th>
                                    <th>{t("member-login-report.mobile-application-user")}</th>
                               </tr>

                               <tr>
                                    <td>{this.props.totalDesktopUser}</td>
                                    <td>{this.props.totalMobileViewUser}</td>
                                    <td>{this.props.totalMobileAppsUser}</td>
                               </tr>
                               </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces("translation")(MemberLoginHistoryReportListing);
