import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";

// import MemberReferral from "./MemberReferral";
// import ReferralHistory from "./ReferralHistory";
import MemberReferralPayout from "./MemberReferralPayout";
import MemberReferralPayoutHistory from "./MemberReferralPayoutHistory";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // pageShow: "member_referral",
            pageShow: "member_referral_payout",
            loading: true,
            reload: true,
            permission: [],
        };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[Index] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined && nextProps.currentPermission.length !== 0) {
            // let memberReferralPermission = this.props.currentPermission.filter((item) => item.includes("member_referral_"));
            this.setState({ permission: this.props.currentPermission });
        }

        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let tabId = this.state.pageShow;
        if (!this.state.loading && this.state.permission.length !== 0) {
            display = (
                <>
                    {(tabId === "member_referral" || tabId === "member_referral_history" || tabId === "member_referral_payout" || tabId === "member_referral_payout_history") && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary tabs-style-2 mt-3">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav panel-tabs main-nav-line">
                                                {this.state.permission.includes("member_referral_access") && (
                                                    <>
                                                    <li>
                                                         <button
                                                            id="member_referral_payout"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "member_referral_payout" ? "active" : ""}`}>
                                                            {t("member-referral.referral-payout")}
                                                        </button>
                                                    </li>
                                                    </>
                                                )}
                                                {this.state.permission.includes("member_referral_payout_history") && (
                                                    <>
                                                    <li>
                                                        <button
                                                            id="member_referral_payout_history"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "member_referral_payout_history" ? "active" : ""}`}>
                                                            {t("member-referral.referral-payout-history")}
                                                        </button>
                                                    </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.permission.includes("member_referral_payout_access") && tabId === "member_referral_payout" && <MemberReferralPayout permission={this.state.permission} />}
                    {this.state.permission.includes("member_referral_payout_history") && tabId === "member_referral_payout_history" && <MemberReferralPayoutHistory permission={this.state.permission} />}
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member-referral.member-referral")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { sideBar, currentUser } = state;
    let permission = [];
    if (sideBar.hasOwnProperty('rs')) {
        permission = sideBar.rs.data.permission;
    }
    return {
        currentPermission: currentUser.data.permission === undefined ? permission : currentUser.data.permission,
    };
};

export default connect(mapStateToProps)(withNamespaces("translation")(Index));
