//Call the api for example call the login api
import axios from "../../../common/config/axios";
import * as type from "../banksLevel/types";

export const getBanksLevel = (data) => dispatch => {
    axios.get('banklevel/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANKS_LEVEL_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getMemberGroupName = (data) => dispatch => {
    axios.get('banklevel/member_group', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_GROUP_NAME,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getBanksAccountName = (data) => dispatch => {
    axios.get('banklevel/banks_account', {
        params: {
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANKS_ACCOUNT_NAME,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const postBanksLevel = (data) => dispatch => {
    axios.post('banklevel/store', data)
        .then( (response) => {
            dispatch ({
                type: type.CREATE_BANKS_LEVEL,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateBanksLevelStatus = (data) => dispatch => {
    axios.post('banklevel/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_BANKS_LEVEL,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showBanksLevel = (data) => dispatch => {
    axios.get('banklevel/show', {
        params: {
            "id": data['bank_level_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_BANKS_LEVEL,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBanksLevel = (data) => dispatch => {
    axios.post('banklevel/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_BANKS_LEVEL,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteBanksLevel = (data) => dispatch => {
    axios.post('banklevel/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_BANKS_LEVEL,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

