import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { updatePopup, showPopup } from "../../store/ducks/popups/actions";
import { formValidation } from "../../hoc/Shared/utility";
import { withNamespaces } from "react-i18next";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

//Initialize the language array first
let langArr = [];
class PopupsEdit extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        popup_title: [],
        popup_content: [],
        image_desktop_name: [],
        image_mobile_name: [],
        image_link_desktop_image: [],
        image_link_mobile_image: [],
        statusArry: [
            {
                label: "Active",
                value: 1,
                selected: true,
            },
            {
                label: "Inactive",
                value: 0,
            },
        ],
        start_time: "",
        end_time: "",
        currentStatus: 1,
        loading: true,
        desktop_image: [],
        mobile_image: [],
    };

    componentDidMount() {
        formValidation("popup_form");

        const countryData = {};
        countryData.country_id = sessionStorage.getItem("countryId") || this.state.currentCountryId;

        const showPopupData = {};
        showPopupData.popup_id = this.props.show_popup_id;
        this.props.showPopup(showPopupData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_popup_result !== this.props.update_popup_result) {
            if (nextProps.update_popup_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_popup_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                return false;
            }
        }

        if (nextProps.show_popup_data !== this.props.show_popup_data) {
            if (nextProps.show_popup_data.data.responseCode === 200) {
                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    popup_title,
                    popup_content,
                    image_desktop_name,
                    image_mobile_name,
                    image_link_desktop_image,
                    image_link_mobile_image,
                } = this.state;

                nextProps.show_popup_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    popup_title[item.lang_id] = item.title;
                    popup_content[item.lang_id] = item.content;
                    image_desktop_name[item.lang_id] = item.desktop_image_name;
                    image_mobile_name[item.lang_id] = item.mobile_image_name;
                    image_link_desktop_image[item.lang_id] = item.desktop_image_link;
                    image_link_mobile_image[item.lang_id] = item.mobile_image_link;

                    return item;
                });

                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    popup_title,
                    popup_content,
                    image_desktop_name,
                    image_mobile_name,
                    image_link_desktop_image,
                    image_link_mobile_image,
                    start_time: new Date(nextProps.show_popup_data.data.data[0].start_time),
                    end_time: new Date(nextProps.show_popup_data.data.data[0].end_time),
                    currentStatus: nextProps.show_popup_data.data.data[0].status,
                });
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({ loading: false });
            //Clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    langId: data[item].languageId,
                    langCode: data[item].languageCode,
                    langName: data[item].languageName,
                });
            });
        }

        return true;
    }

    updatePopupHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let formData = new FormData();

                const popup_title = this.state.popup_title;
                const popup_content = this.state.popup_content;
                const image_desktop_name = this.state.image_desktop_name;
                const image_mobile_name = this.state.image_mobile_name;
                const image_link_desktop_image = this.state.image_link_desktop_image;
                const image_link_mobile_image = this.state.image_link_mobile_image;

                if (this.state.desktop_image.length > 0) {
                    const desktop_image = this.state.desktop_image;
                    desktop_image.forEach((val, idx) => {
                        formData.append("desktop_image[" + idx + "]", val);
                    });
                }

                if (this.state.mobile_image.length > 0) {
                    const mobile_image = this.state.mobile_image;
                    mobile_image.forEach((val, idx) => {
                        formData.append("mobile_image[" + idx + "]", val);
                    });
                }

                popup_title.forEach((val, idx) => {
                    formData.append("popup_title[" + idx + "]", val);
                });

                popup_content.forEach((val, idx) => {
                    formData.append("popup_content[" + idx + "]", val);
                });

                image_desktop_name.forEach((val, idx) => {
                    formData.append("image_name_desktop_image[" + idx + "]", val);
                });

                image_mobile_name.forEach((val, idx) => {
                    formData.append("image_name_mobile_image[" + idx + "]", val);
                });

                image_link_desktop_image.forEach((val, idx) => {
                    formData.append("image_link_desktop_image[" + idx + "]", val);
                });

                image_link_mobile_image.forEach((val, idx) => {
                    formData.append("image_link_mobile_image[" + idx + "]", val);
                });

                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("country_id", sessionStorage.getItem("countryId"));
                formData.append("id", this.props.show_popup_id);

                this.props.updatePopup(formData);
            }
        });
    };

    getDesktopImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { desktop_image_preview, desktop_image } = this.state;

        reader.onloadend = () => {
            desktop_image_preview[langId] = reader.result;
            desktop_image[langId] = file;

            this.setState({
                desktop_image_preview,
                desktop_image,
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            desktop_image_preview[langId] = "";
            this.setState({
                desktop_image_preview,
            });
        }
    };

    getMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_image_preview, mobile_image } = this.state;

        reader.onloadend = () => {
            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({ mobile_image_preview, mobile_image });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview,
            });
        }
    };

    getPopupTitleHandler = (event, langId) => {
        const { popup_title } = this.state;

        popup_title[langId] = event.target.value;

        this.setState({
            popup_title,
        });
    };

    getDesktopImageNameHandler = (event, langId) => {
        const { image_desktop_name } = this.state;

        image_desktop_name[langId] = event.target.value;

        this.setState({
            image_desktop_name,
        });
    };

    getMobileImageNameHandler = (event, langId) => {
        const { image_mobile_name } = this.state;

        image_mobile_name[langId] = event.target.value;

        this.setState({
            image_mobile_name,
        });
    };

    getDesktopImageLinkHandler = (event, langId) => {
        const { image_link_desktop_image } = this.state;

        image_link_desktop_image[langId] = event.target.value;

        this.setState({
            image_link_desktop_image,
        });
    };

    getMobileImageLinkHandler = (event, langId) => {
        const { image_link_mobile_image } = this.state;

        image_link_mobile_image[langId] = event.target.value;

        this.setState({
            image_link_mobile_image,
        });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    };

    render() {
        const { t } = this.props;

        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t("popup.desktop-image")}</label>
                        <ImageUpload id="desktop_image" name={`desktop_image[${item.langId}]`} onChange={(event) => this.getDesktopImageHandler(event, item.langId)} />

                        <div className="imgPreview popup-preview-image">
                            <img alt="" className="popup" src={this.state.desktop_image_preview[item.langId]} />
                        </div>

                        {this.state.desktop_image_preview.length === 0 && (
                            <span className="text-muted">
                                <strong>*jpg, png, gif</strong>
                            </span>
                        )}
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t("popup.mobile-image")}</label>
                        <ImageUpload id="mobile_image" name={`mobile_image[${item.langId}]`} onChange={(event) => this.getMobileImageHandler(event, item.langId)} />

                        <div className="imgPreview popup-preview-image">
                            <img alt="" className="popup" src={this.state.mobile_image_preview[item.langId]} />
                        </div>

                        {this.state.mobile_image_preview.length === 0 && (
                            <span className="text-muted">
                                <strong>*jpg, png, gif</strong>
                            </span>
                        )}
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.title")}</label>
                        <StringInput
                            onChange={(event) => this.getPopupTitleHandler(event, item.langId)}
                            id="popup_title"
                            name={`popup_title[${item.langId}]`}
                            value={this.state.popup_title[item.langId] || ""}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.content")}</label>
                        <JoditEditor
                            value={this.state.popup_content[item.langId] || ''}
                            tabIndex={item.langId} // tabIndex of textarea
                            onBlur={newContent => {
                                const {popup_content} = this.state;
                                popup_content[item.langId] = newContent;
                                this.setState({
                                    popup_content
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.desktop-image-name")}</label>
                        <StringInput
                            onChange={(event) => this.getDesktopImageNameHandler(event, item.langId)}
                            id="image_desktop_name"
                            name={`image_desktop_name[${item.langId}]`}
                            value={this.state.image_desktop_name[item.langId] || ""}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.mobile-image-name")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageNameHandler(event, item.langId);
                            }}
                            id="image_mobile_name"
                            name={`image_mobile_name[${item.langId}]`}
                            value={this.state.image_mobile_name[item.langId] || ""}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.desktop-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getDesktopImageLinkHandler(event, item.langId);
                            }}
                            id="image_link_desktop_image"
                            name={`image_link_desktop_image[${item.langId}]`}
                            value={this.state.image_link_desktop_image[item.langId] || ""}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("popup.mobile-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageLinkHandler(event, item.langId);
                            }}
                            id="image_link_mobile_image"
                            name={`image_link_mobile_image[${item.langId}]`}
                            value={this.state.image_link_mobile_image[item.langId] || ""}
                        />
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("popup.popup")}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("popup.edit-popup")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="popup_form" className="form-horizontal" onSubmit={this.updatePopupHandler} noValidate>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("popup.start-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("popup.end-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("popup.status")}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={this.state.statusArry}
                                                        onChange={this.changeStatusHandler}
                                                        value={this.state.currentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button id="submit" classValue="btn btn-blue" typeButton="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, popup } = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        update_popup_result: popup.update_popup_result,
        show_popup_data: popup.show_popup_data,
    };
};

const mapDispatchToProps = {
    getCountry,
    updatePopup,
    showPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PopupsEdit));
