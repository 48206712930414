import {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/ducks/member/actions';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import {withNamespaces} from 'react-i18next';
import Swal from 'sweetalert2';
import MemberWalletTrackerListing from './MemberWalletTrackerListing';
import TransactionDetails from '../../Transaction/TransactionDetails';
import { filterErrorHandler } from '../../../hoc/Shared/utility';

const initialInputState = {
    transaction_date: "",
    status: "",
    transaction_type: ""
};

class MemberWalletTracker extends Component{
    constructor(props){
        super(props);
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'wallet_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            this.state = {
                currentPage: "wallet_tracker",
                pageShow: "wallet_tracker_listing",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: '',
                totalRecords: 0,
                searchValue: '',
                data: [],
                loading: true,
                reload: false,
                show_id: null,
                filter: initialInputState,
                detailsParam: {}
            }
            this.props.setMemberShowLocalState(this.state);
        }
        else{
            this.state = this.props.memberWalletTrackerLocalState;
        }
    }

    componentDidMount(){
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'wallet_tracker');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            const listingArr = this.getFilterArray();
            this.props.getMemberWalletTracker(listingArr);
            this.props.setMemberShowInitialLoading(this.state.currentPage);
        }
        else{
            if(this.props.memberWalletTrackerData !== undefined){
                if(this.props.memberWalletTrackerData.data && this.props.memberWalletTrackerData.data.data 
                    && this.props.memberWalletTrackerData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberWalletTrackerData.data.data.memberId)){
                    const listingArr = this.getFilterArray();
                    this.props.getMemberWalletTracker(listingArr);
                }
                else{
                    this.setState({
                        loading: false,
                        reload: true
                    });
                }
            }
            else{
                const listingArr = this.getFilterArray();
                this.props.getMemberWalletTracker(listingArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log(nextProps);
        if(nextProps.memberShowId !== undefined && nextProps.memberWalletTrackerData !== undefined){
            if(nextProps.memberWalletTrackerData !== this.props.memberWalletTrackerData){
                if(nextProps.memberWalletTrackerData.data.responseCode === 200 && nextProps.memberWalletTrackerData.data.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        loading: false,
                        data: nextProps.memberWalletTrackerData.data.data.wallet.row,
                        totalRecords: nextProps.memberWalletTrackerData.data.data.wallet.pagination.total_record,
                        show_id: nextProps.memberShowId
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        data: [],
                        totalRecords: 0,
                        show_id: null
                    }, () => {
                        filterErrorHandler(nextProps.memberWalletTrackerData.data.message)
                    })
                }
            }
        }

        return true;
    }

    componentWillUnmount(){
        this.props.setMemberShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        listingArr.member_id = this.props.memberShowId;
        if(this.state.totalRecords!==0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit)+1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberWalletTracker(listingArr);
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberWalletTracker(listingArr);
            }
        );
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: ''
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberWalletTracker(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                searchValue: "",
                filter: newFilter,
                loading: true,
                data: [],
                order: ''
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberWalletTracker(listingArr);
            }
        );
    };

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "transaction_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    detailsHandler = (e,id) => {
        e.preventDefault();
        this.setState({
            pageShow: "details",
            detailsParam: {
                id: id
            }
        });
    }

    changePageHandler = page => {
        this.setState({
            pageShow: page
        });
    }
    
    render(){
        return(
            <Auxiliary>
                {this.state.pageShow==='wallet_tracker_listing' && 
                    <MemberWalletTrackerListing 
                        filter={this.state.filter}
                        onSort={(field,order)=>{this.onSort(field,order)}}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        datatablesChangeHandler={(val,act)=>{this.datatablesChangeHandler(val,act)}}
                        searchValue={this.state.searchValue}
                        loading={this.state.loading}
                        refreshHandler={()=>{this.refreshHandler()}}
                        clearHandler={()=>{this.clearHandler()}}
                        onInputChange={(event)=>{this.onInputChange(event)}}
                        detailsHandler={(event,id)=>{this.detailsHandler(event,id)}}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }
                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='wallet_tracker_listing'
                        currentUser={this.props.currentUser} 
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const {member,currentUser} = state;
    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberWalletTrackerLocalState: member.member_wallet_tracker_local_state,
        memberWalletTrackerData: member.member_wallet_tracker_data,
        currentCountryId: state.currentUser.data.country_id,
        currentUser: currentUser.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => {dispatch(actions.setMemberShowLocalState(data))},
        setMemberShowInitialLoading: (data) => {dispatch(actions.setMemberShowInitialLoading(data))},
        getMemberWalletTracker: (data) => {dispatch(actions.getMemberWalletTracker(data))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberWalletTracker));