import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Dropzone from "react-dropzone";

import * as moment from "moment";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { postPromotedVideo } from "../../store/ducks/promotedVideo/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import { withNamespaces } from "react-i18next";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";

//Initialize the language array first
let langArr = [];
// let userMemberArry = [];

// let formData = new FormData();
let dt = new Date();

class PromotedVideoCreate extends Component {
    state = {
        video_preview: [],
        video: [],
        title: [],
        description: [],
        currentStatus: 1,

        video_thumbnail_preview: [],
        video_thumbnail: [],

        start_time: dt.setHours(dt.getHours() + 2, 0),
        end_time: dt.setHours(dt.getHours() + 3, 0),
        sequence: "",
        loading: true,

        files: [],
    };

    componentDidMount() {
        formValidation("promotion_form");

        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_promoted_video_result !== this.props.post_promoted_video_result) {
            if (nextProps.post_promoted_video_result.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_promoted_video_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.post_promoted_video_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_promoted_video_result.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_promoted_video_result.message[0],
                    icon: nextProps.post_promoted_video_result.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        langId: data[item].languageId,
                        langCode: data[item].languageCode,
                        langName: data[item].languageName,
                    });
                });
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    postPromotionHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let formData = new FormData();

                // const currentGroupName = this.state.currentGroupName;
                const video = this.state.video;
                const title = this.state.title;
                const content = this.state.description;
                const video_thumbnail = this.state.video_thumbnail;

                video.forEach((val, idx) => {
                    formData.append("video[" + idx + "]", val);
                });
                title.forEach((val, idx) => {
                    formData.append("title[" + idx + "]", val);
                });
                content.forEach((val, idx) => {
                    formData.append("description[" + idx + "]", val);
                });
                video_thumbnail.forEach((val, idx) => {
                    formData.append("video_thumbnail_images[" + idx + "]", val);
                });

                formData.append("sequence", this.state.sequence);
                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("country_id", this.props.currentCountryId);

                this.props.postPromotedVideo(formData);
            }
        });
    };

    getPromotionTitleHandler = (event, langId) => {
        const { title } = this.state;
        title[langId] = event.target.value;
        this.setState({
            title,
        });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date,
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    };

    getSequenceHandler = (event) => {
        this.setState({ sequence: event.target.value });
    };

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        this.setState({
            [event.target.id]: event.target.value,
        });
    }

    onDrop = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { video_preview, video } = this.state;

        reader.onloadend = () => {
            video_preview[langId] = reader.result;
            video[langId] = file;

            this.setState({
                video_preview,
                video,
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            video_preview[langId] = "";
            this.setState({
                video_preview,
            });
        }
    };

    onDropVideoThumbnail = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { video_thumbnail_preview, video_thumbnail } = this.state;

        reader.onloadend = () => {
            video_thumbnail_preview[langId] = reader.result;
            video_thumbnail[langId] = file;

            this.setState({
                video_thumbnail_preview,
                video_thumbnail,
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            video_thumbnail_preview[langId] = "";
            this.setState({
                video_thumbnail_preview,
            });
        }
    };

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];
        let languages = langArr;

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true,
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t("promoted-video.video")}</label>
                            <div className="video-drop">
                                <Dropzone onDrop={(event) => this.onDrop(event, item.langId)} name={`video[${item.langId}]`}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="container">
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input {...getInputProps()} />
                                                <p className={this.state.video_preview[item.langId] ? "" : "with-bg"}>*{t("promoted-video.upload-video-remark")}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                                <input type="hidden" name={`video[${item.langId}]`} />

                                {this.state.video_preview.length > 0 && (
                                    <div className="preview-image">
                                        <video src={this.state.video_preview[item.langId]} controls></video>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t("promoted-video.video-thumbnail-image")}</label>
                            <ImageUpload
                                id="video_thumbnail_images"
                                name={`video_thumbnail_images[${item.langId}]`}
                                onChange={(event) => this.onDropVideoThumbnail(event, item.langId)}
                            />

                            {/* {this.state.video_thumbnail_preview[item.langId] && (
                                <div className="imgPreview banner-preview-image">
                                    <div className="uploadPictureContainer">
                                        <img
                                            alt=""
                                            className="banner"
                                            src={this.state.video_thumbnail_preview[item.langId]}
                                            onClick={() => this.setState({ isOpen: true, currentImage: this.state.video_thumbnail_preview[item.langId] })}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>{t("promotion.title")}</label>
                            <StringInput
                                onChange={(event) => this.getPromotionTitleHandler(event, item.langId)}
                                id="promotion_title"
                                name={`title[${item.langId}]`}
                                value={this.state.title[item.langId]}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>{t("promoted-video.description")}</label>
                            <JoditEditor
                                value={this.state.description[item.langId] || ""}
                                tabIndex={item.langId} // tabIndex of textarea
                                onBlur={(newContent) => {
                                    const { description } = this.state;
                                    description[item.langId] = newContent;
                                    this.setState({
                                        description,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>

            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("promoted-video.promoted-video")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("promoted-video.add-promoted-video")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="promotion_form" className="form-horizontal" onSubmit={this.postPromotionHandler} noValidate>
                                        <ValidationError formName="promotion_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("promotion.start-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                                                    </span> */}
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("promotion.end-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                                            </span> */}

                                                            <DatePicker
                                                                name="end_time"
                                                                className="form-control"
                                                                selected={this.state.end_time}
                                                                onChange={this.handleEndTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("promotion.status")}</label>
                                                    <Select id="status" name="status" className="form-control" options={statusArry} onChange={this.changeStatusHandler} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("promotion.sequence")}</label>
                                                    <NumberInput onChange={(event) => this.getSequenceHandler(event)} id="sequence" name="sequence" value={this.state.sequence} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button id="submit" classValue="btn btn-blue" typeButton="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, promotedVideo, currentUser } = state;
    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        post_promoted_video_result: promotedVideo.post_promoted_video_result,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    getCountry,
    postPromotedVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PromotedVideoCreate));
