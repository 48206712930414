import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberTranslations } from "../../store/ducks/translation/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import TranslationMemberListing from "./TranslationMemberListing";
// import Swal from "sweetalert2";
// import Select from "../../components/Input/Select/Select";
// import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
class TranslationMember extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            popupLoading: false,

            agentShowId: null,
            agentEditId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getMemberTranslations(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (nextProps.translationMemberData !== this.props.translationMemberData && nextProps.translationMemberData !== undefined && nextProps.translationMemberData.status === 200 && this.props.currentCountryId !== nextState.country_id)
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let translationPermission = this.props.currentPermission.filter((item) => item.includes("translation_"));
            // console.log(agentPermission);
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.translationMemberData !== this.props.translationMemberData && nextProps.translationMemberData !== undefined && nextProps.translationMemberData.status === 200) {
            this.setState({
                data: nextProps.translationMemberData.data.data.result.row,
                totalRecords: nextProps.translationMemberData.data.data.result.pagination.total_record,
                totalPages: nextProps.translationMemberData.data.data.result.pagination.total_page,
                loading: false,
            });
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        // listingArr.country_id = this.props.currentCountryId;
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getMemberTranslations(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            // errMsg: [],
            // touched: false,
        }, () => {
            const listingArr = this.fetchData();
            this.props.getMemberTranslations(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.fetchData();
            this.props.getMemberTranslations(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.fetchData();
                this.props.getMemberTranslations(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("translation.key"), dataField: "key", sort: true, onSort: this.onSort },
            { text: t("translation.value"), dataField: "value", sort: true, onSort: this.onSort },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    translationId: val.translationId,
                    key: t(`${val.key}`),
                    value: t(`${val.key}`)
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <TranslationMemberListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        languages={this.props.languages}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        translationMemberData: state.translation.translation_member_data,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getMemberTranslations: (data) => dispatch(getMemberTranslations(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(TranslationMember));
