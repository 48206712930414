import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import BanksListing from "./BanksListing";
import BanksCreate from "./BanksCreate";
import BanksShow from "./BanksShow";
import BanksEdit from "./BanksEdit";
import Button from "../../components/Button/Button";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { deleteBank, getBanks, getDropDown, updateBankStatus, updateMaintenanceStatus } from "../../store/ducks/banks/actions";

import Select from "../../components/Input/Select/Select";
import { filterErrorHandler } from "../../hoc/Shared/utility";

const initialInputState = {
    /* form input value */
    name: "",
    status: "",
    maintenance_status: "",
};

let bankListingData = {};
class Banks extends Component {
    // state = {
    //     pageShow: "listing",

    //     page: Number(process.env.REACT_APP_DATATABLE_PAGE),
    //     limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    //     sorted: null,
    //     totalPages: 0,
    //     activePage: 1,
    //     totalRecords: 0,
    //     searchValue: '',

    //     data: [],
    //     dropDown:
    //     {
    //         bankName: []
    //     },

    //     currentBankId: '',
    //     loading: true,

    //     permission: []
    // }

    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            sorted: null,
            totalPages: 0,
            totalRecords: 0,

            data: [],

            dropDown: [],

            filter: initialInputState,

            currentBankId: "",
            loading: true,

            permission: [],
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        bankListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        bankListingData.limit = this.state.limit;
        bankListingData.order = JSON.stringify(this.state.sorted);
        bankListingData.name = this.state.filter.name;
        bankListingData.status = this.state.filter.status;
        bankListingData.country_id = this.props.currentCountryId;
        bankListingData.totalRecord = this.state.totalRecords;
        this.props.getBanks(bankListingData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }
    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let bankPermission = this.props.currentPermission.filter((item) => item.includes("bank_"));
            this.setState({ permission: bankPermission });
        }
        if (nextProps.bank !== this.props.bank) {
            if (nextProps.bank.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.bank.data.data,
                    totalRecords: nextProps.bank.data.totalRecords,
                    totalPages: nextProps.bank.data.totalPages,
                });
            } else {
                this.setState({
                    loading: false,
                    data: [],
                    totalRecords: 0,
                    totalPages: 0
                }, () => {
                    filterErrorHandler(nextProps.bank.data.message);
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown) {
            if (nextProps.dropDown.responseCode === 200) {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                });
            } else {
                this.setState({ loading: false }, () => {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }

        if (nextProps.delete_bank_result !== this.props.delete_bank_result) {
            if (nextProps.delete_bank_result.responseCode === 200) {
                Swal.fire({
                    title: nextProps.delete_bank_result.message,
                    icon: nextProps.delete_bank_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            } else {
                filterErrorHandler(nextProps.delete_bank_result.message);
            }
        }
        if (nextProps.update_status_bank_result !== this.props.update_status_bank_result) {
            if (nextProps.update_status_bank_result.responseCode === 200 && nextProps.update_status_bank_result.msgType === 'success') {
                Swal.fire({
                    title: t('global.record-updated'),
                    icon: nextProps.update_status_bank_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }
            else {
                filterErrorHandler(nextProps.update_status_bank_result.message);
            }
        }

        if (nextProps.update_maintenance_status_result !== this.props.update_maintenance_status_result) {
            if (nextProps.update_maintenance_status_result.responseCode === 200 && nextProps.update_maintenance_status_result.msgType === 'success') {
                Swal.fire({
                    title: t('global.record-updated'),
                    icon: nextProps.update_maintenance_status_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }
            else {
                filterErrorHandler(nextProps.update_maintenance_status_result.message);
            }
        }
        return true;
    }

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                sorted: null
            },
            () => {
                this.fetchData();
            }
        );
    };

    clearHandler = () => {
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                filter: initialInputState,
                loading: true,
                data: [],
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                sorted: null,
            },
            () => {
                this.fetchData();
            }
        );
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    // deleteBankHandler = (e, bank_id) => {
    //     e.preventDefault();

    //     Swal.fire({
    //         title: "Are you sure?",
    //         text: "You wont be able to revert this!",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: `Delete`,
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: "",
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading();
    //                 },
    //             });

    //             const deleteBankData = {};
    //             deleteBankData.id = bank_id;

    //             this.props.deleteBank(deleteBankData);
    //         }
    //     });
    // };

    updateStatusBankHandler = (event, bankId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updateBankStatusData = {};
        updateBankStatusData.id = bankId;
        updateBankStatusData.status = Number(event.target.value);

        this.props.updateBankStatus(updateBankStatusData);
    };

    updateMaintenanceStatusHandler = (event, bankId) => {
        event.preventDefault();

        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updateMaintenanceStatusData = {};
        updateMaintenanceStatusData.id = bankId;
        updateMaintenanceStatusData.maintenance_status = Number(event.target.value);

        this.props.updateMaintenanceStatus(updateMaintenanceStatusData);
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("bank_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="bankStatus"
                    name="bankStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateStatusBankHandler(event, row.bankId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    maintenanceStatusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("bank_change_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.maintenanceStatus === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="maintenanceStatus"
                    name="maintenanceStatus"
                    options={statusArray}
                    defaultValue={row.maintenanceStatus}
                    onChange={(event) => {
                        this.updateMaintenanceStatusHandler(event, row.bankId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.maintenanceStatus === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        // if (this.state.permission.includes("bank_show")) {
        //     viewButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-primary mr-2"
        //             buttonName={<FontAwesomeIcon icon={faSearch}/>}
        //             key={`view-${row.bankId}`}
        //             clicked={() => this.changePageHandler('show', row.bankId)}
        //         />
        //     );
        // }

        if (this.state.permission.includes("bank_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.bankId}`}
                    clicked={() => this.changePageHandler("edit", row.bankId)}
                />
            );
        }

        // if (this.state.permission.includes("bank_delete")) {
        //     deleteButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-white delete"
        //             clicked={(event) => this.deleteBankHandler(event, row.bankId)}
        //             key={`delete-${row.bankId}`}
        //             buttonName={<FontAwesomeIcon icon={faTrash} />}
        //         />
        //     );
        // }

        output = [viewButton, editButton, deleteButton];
        return output;
    };

    changePageHandler = (page, bankId) => {
        this.setState({
            pageShow: page,
            currentBankId: bankId,
        });
    };

    render() {
        const { t } = this.props;

        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let reverseRowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
                    return <span>{reverseRowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: "Bank Id",
                dataField: "bankId",
                keyField: "id",
                hidden: true,
            },
            {
                text: t("bank.bank_name"),
                dataField: "name",
                keyField: "name",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("bank.swift_code"),
                dataField: "bankSwiftCode",
                keyField: "swiftCode",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("bank.start-time"),
                dataField: "maintenanceStartTime",
                keyField: "maintenanceStartTime",
            },
            {
                text: t("bank.end-time"),
                dataField: "maintenanceEndTime",
                keyField: "maintenanceEndTime",
            },
            {
                text: t("global.status"),
                dataField: "status",
                keyField: "status",
                formatter: this.statusFormatter,
                headerStyle: { width: "115px" },
            },
            {
                text: t("bank.maintenanceStatus"),
                dataField: "maintenanceStatus",
                keyField: "maintenanceStatus",
                formatter: this.maintenanceStatusFormatter,
                headerStyle: { width: "115px" },
            },
            {
                text: t("bank.created_by"),
                dataField: "createdBy",
                keyField: "createdBy",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("global.action"),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
                // headerStyle: { width: "65px" },
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("bank.banks")}</h4>
                                </div>
                            </div>
                            {/* <div className="d-flex my-xl-auto right-content">
                                <div>
                                    <div className="btn-group">
                                        {this.state.permission.includes("bank_store") && (
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="btnCreateBank"
                                                buttonName={t("bank.add-banks")}
                                                clicked={() => this.changePageHandler("create")}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <BanksListing
                            name={this.state.filter.name}
                            status={this.state.filter.status}
                            maintenance_status={this.state.filter.maintenance_status}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            /**filter drop down*/
                            ddBankName={this.state.dropDown.bank_name}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                )}

                {this.state.pageShow === "create" && <BanksCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.pageShow === "show" && <BanksShow show_bank_id={this.state.currentBankId} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.pageShow === "edit" && (
                    <BanksEdit show_bank_id={this.state.currentBankId} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { bank, currentUser } = state;
    //Get the state from reducers current limit, current page and search value
    return {
        bank: bank.bank_data,
        delete_bank_result: bank.delete_bank_result,
        update_status_bank_result: bank.update_status_bank_result,
        update_maintenance_status_result: bank.update_maintenance_status_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission,
        dropDown: bank.dropDown,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getBanks,
    getDropDown,
    deleteBank,
    updateBankStatus,
    updateMaintenanceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Banks));
