import axios from '../../../common/config/axios';
import * as type from "../ip/types";

//Call the api for example call the login api
export const getLoginIPAddress = (data) => dispatch => {
    axios.get('ip-address/login-ip', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_LOGIN_IP_ADDRESS_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getRegisterIPAddress = (data) => dispatch => {
    axios.get('ip-address/register-ip', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_REGISTER_IP_ADDRESS_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const showLoginIPAddress = (data) => dispatch => {
    axios.get('ip-address/login-ip-details', {
        params: {
            "ip": data['ip_address']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_LOGIN_IP_ADDRESS_DETAILS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const showRegisterIPAddress = (data) => dispatch => {
    axios.get('ip-address/register-ip-details', {
        params: {
            "ip": data['ip_address']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_REGISTER_IP_ADDRESS_DETAILS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};