import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { updateBanner, showBanner, getCategory } from "../../store/ducks/banners/actions";
import { formValidation } from "../../hoc/Shared/utility";
import { withNamespaces } from 'react-i18next';
import Lightbox from 'react-image-lightbox';

//Initialize the language array first
let langArr = [];
let categoryArry = [];

// let formData = new FormData();

class BannersEdit extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        // image_desktop_name: [],
        // image_mobile_name: [],
        image_link_desktop_image: '',
        redirect_url: [],
        desktop_image: [],
        mobile_image: [],
        start_time: '',
        end_time: '',
        sequence: '',
        category: [],
        currentStatus: 1,
        loading: true
    };

    componentDidMount() {
        formValidation('banner_form');

        const countryData = {};
        countryData.country_id = this.props.currentUser.country_id;

        const showBannerData = {};
        showBannerData.banner_id = this.props.show_banner_id;
        this.props.getCategory();
        this.props.showBanner(showBannerData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_banner_result !== this.props.update_banner_result) {
            if (nextProps.update_banner_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_banner_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else {
                return false;
            }

        }

        if (nextProps.show_banner_data !== this.props.show_banner_data) {
            if (nextProps.show_banner_data.data.responseCode === 200) {
                // const bannerData =  nextProps.show_banner_data.data.data[0];

                // const getCurrentCategoryName = bannerData.category;
                // let categoryArry = [];

                // if (getCurrentCategoryName !== "") {
                //     const getCurrentCategoryNameArry = getCurrentCategoryName;

                //     Object.keys(getCurrentCategoryNameArry).map((item, i) => {
                //         return categoryArry.push({
                //             'label': getCurrentCategoryNameArry[item],
                //             'value': getCurrentCategoryNameArry[item],
                //             'key': getCurrentCategoryNameArry[item],
                //         });
                //     });
                // }

                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    // image_desktop_name,
                    // image_mobile_name,
                    // image_link_desktop_image,
                    // image_link_mobile_image,
                }
                    = this.state;

                nextProps.show_banner_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    // image_desktop_name[item.lang_id] = item.desktop_image_name;
                    // image_mobile_name[item.lang_id] = item.mobile_image_name;
                    // image_link_mobile_image[item.lang_id] = item.mobile_image_link;

                    return item;
                })
                let image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link;

                if (image_link_desktop_image === null) {
                    image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link;
                }
                else {
                    image_link_desktop_image = nextProps.show_banner_data.data.data[0].desktop_image_link.replace('promos/', '');
                }
                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    // image_desktop_name,
                    // image_mobile_name,
                    image_link_desktop_image,
                    // image_link_mobile_image,
                    start_time: new Date(nextProps.show_banner_data.data.data[0].start_time),
                    end_time: new Date(nextProps.show_banner_data.data.data[0].end_time),
                    sequence: nextProps.show_banner_data.data.data[0].sequence,
                    category: (nextProps.show_banner_data.data.data[0].category ? nextProps.show_banner_data.data.data[0].category.toString().toLowerCase() : ''),
                    currentStatus: nextProps.show_banner_data.data.data[0].status,
                    redirect_url: nextProps.show_banner_data.data.data[0].redirect_url
                })
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({ loading: false });
            //Clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    'langId': data[item].languageId,
                    'langCode': data[item].languageCode,
                    'langName': data[item].languageName
                });
            })
        }

        if (nextProps.category_data !== this.props.category_data) {
            if (nextProps.category_data.data.responseCode === 200) {
                const { t } = this.props;
                Swal.close();
                categoryArry = [];
                let data = nextProps.category_data.data.data;
                Object.keys(data).map((item, i) => {
                    return categoryArry.push({
                        'label': t('game-type.' + data[item].slug),
                        'value': data[item].slug,
                        'id': data[item].Id,
                    });
                })
            }
        }

        return true;
    }

    updateBannerHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                if (this.state.desktop_image.length > 0) {
                    const desktop_image = this.state.desktop_image;
                    desktop_image.forEach((val, idx) => {
                        formData.append("desktop_image[" + idx + "]", val);
                    });
                }

                if (this.state.mobile_image.length > 0) {
                    const mobile_image = this.state.mobile_image;
                    mobile_image.forEach((val, idx) => {
                        formData.append("mobile_image[" + idx + "]", val);
                    });
                }

                const prefix = 'promos/';
                // const image_desktop_name = this.state.image_desktop_name;
                // const image_mobile_name = this.state.image_mobile_name;
                // const image_link_desktop_image = this.state.image_link_desktop_image;
                // const image_link_mobile_image = this.state.image_link_mobile_image;


                // image_desktop_name.forEach((val, idx) => {
                //     formData.append("image_name_desktop_image["+idx+"]", val);
                // });

                // image_mobile_name.forEach((val, idx) => {
                //     formData.append("image_name_mobile_image["+idx+"]", val);
                // });

                // image_link_desktop_image.forEach((val, idx) => {
                //     formData.append("image_link_desktop_image["+idx+"]", prefix + val);
                // });

                // image_link_mobile_image.forEach((val, idx) => {
                //     formData.append("image_link_mobile_image["+idx+"]", val);
                // });

                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("sequence", this.state.sequence);
                formData.append("country_id", sessionStorage.getItem('countryId'));
                formData.append("id", this.props.show_banner_id);
                formData.append("category", this.state.category);
                formData.append("image_link_desktop_image", prefix + this.state.image_link_desktop_image);
                if(this.state.redirect_url !== null){
                    formData.append("redirect_url", this.state.redirect_url);
                }


                this.props.updateBanner(formData);
            }
        })

    }

    getDesktopImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { desktop_image_preview, desktop_image } = this.state;

        reader.onloadend = () => {

            desktop_image_preview[langId] = reader.result;
            desktop_image[langId] = file;

            this.setState({
                desktop_image_preview,
                desktop_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            desktop_image_preview[langId] = "";
            this.setState({
                desktop_image_preview
            })
        }
    };

    getMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_image_preview, mobile_image } = this.state;

        reader.onloadend = () => {
            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({ mobile_image_preview, mobile_image });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview
            })
        }
    }

    // getDesktopImageNameHandler = (event, langId) => {
    //     const {image_desktop_name} = this.state;
    //     image_desktop_name[langId] = event.target.value;

    //     this.setState({
    //         image_desktop_name
    //     });
    // }

    // getMobileImageNameHandler = (event, langId) => {
    //     const {image_mobile_name} = this.state;

    //     image_mobile_name[langId] = event.target.value;

    //     this.setState({
    //         image_mobile_name
    //     });
    // }

    getDesktopImageLinkHandler = (event, langId) => {
        const { image_link_desktop_image } = this.state;
        image_link_desktop_image[langId] = event.target.value;

        this.setState({
            image_link_desktop_image
        });

    }

    // getMobileImageLinkHandler = (event, langId) => {
    //     const {image_link_mobile_image} = this.state;
    //     image_link_mobile_image[langId] = event.target.value;

    //     this.setState({
    //         image_link_mobile_image
    //     });
    // }

    getCategoryHandler = (event) => {
        this.setState({ category: event.target.value });
    }

    getImageLinkHandler = (event) => {
        this.setState({ image_link_desktop_image: event.target.value });
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date
        });
    };

    getSequenceHandler = (event) => {
        this.setState({ sequence: event.target.value });
    }

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    }

    getRedirectUrlHandler = (event) => {
        this.setState({ redirect_url: event.target.value });
    }

    render() {
        const { t } = this.props;

        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let languages = langArr;

        // let home = [
        //     {
        //         label: t("banner.home"),
        //         value: 'home',
        //     },
        // ];

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className='row'>
                    <div className="col-md-6 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="desktop_image">{t("banner.desktop-image")}</label>
                            <ImageUpload
                                id="desktop_image"
                                name={`desktop_image[${item.langId}]`}
                                onChange={(event) => this.getDesktopImageHandler(event, item.langId)}
                                defaultImage={this.state.desktop_image_preview[item.langId] && [this.state.desktop_image_preview[item.langId]]}
                            />

                            {/* <div className="imgPreview banner-preview-image">
                                <img
                                    alt=""
                                    className="banner"
                                    src={this.state.desktop_image_preview[item.langId]}
                                    onClick={() => this.setState({ isOpen: true, currentImage: this.state.desktop_image_preview[item.langId] })}
                                    style={{ "cursor": "pointer" }}
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-md-6 col-12">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="mobile_image">{t("banner.mobile-image")}</label>
                            <ImageUpload
                                id="mobile_image"
                                name={`mobile_image[${item.langId}]`}
                                onChange={(event) => this.getMobileImageHandler(event, item.langId)}
                                defaultImage={this.state.mobile_image_preview[item.langId] && [this.state.mobile_image_preview[item.langId]]}
                            />

                            {/* <div className="imgPreview banner-preview-image">
                                <img
                                    alt=""
                                    className="banner"
                                    src={this.state.mobile_image_preview[item.langId]}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>


                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.desktop-image-name")}</label>
                        <StringInput
                            onChange={(event) => this.getDesktopImageNameHandler(event, item.langId)}
                            id="image_desktop_name"
                            name={`image_desktop_name[${item.langId}]`}
                            value={this.state.image_desktop_name[item.langId] || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.mobile-image-name")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageNameHandler(event, item.langId)
                            }}
                            id="image_mobile_name"
                            name={`image_mobile_name[${item.langId}]`}
                            value={this.state.image_mobile_name[item.langId] || ''}
                        />
                    </div>
                </div> */}

                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.desktop-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getDesktopImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_desktop_image"
                            name={`image_link_desktop_image[${item.langId}]`}
                            value={this.state.image_link_desktop_image[item.langId] || ''}
                            onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                        />
                    </div>
                </div> */}

                {/* <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("banner.mobile-image-link")}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_mobile_image"
                            name={`image_link_mobile_image[${item.langId}]`}
                            value={this.state.image_link_mobile_image[item.langId] || ''}
                        />
                    </div>
                </div> */}


            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("banner.banner")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("banner.edit-banner")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4} /> :
                                    <form method="post" id="banner_form" className="form-horizontal"
                                        onSubmit={this.updateBannerHandler} noValidate>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>{t("banner.desktop-image-link")}</label>
                                                <StringInput
                                                    onChange={this.getImageLinkHandler}
                                                    name="image_link_desktop_image"
                                                    id="image_link_desktop_image"
                                                    value={this.state.image_link_desktop_image}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>{t("banner.redirect-url")}</label>
                                                <StringInput
                                                    onChange={this.getRedirectUrlHandler}
                                                    name="redirect_url"
                                                    id="redirect_url"
                                                    value={this.state.redirect_url}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("banner.start-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("banner.end-time")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                            </span>

                                                            <DatePicker
                                                                name="end_time"
                                                                className="form-control"
                                                                selected={this.state.end_time}
                                                                onChange={this.handleEndTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('banner.category')}</label>
                                                    <Select
                                                        // options={home.concat(categoryArry)}
                                                        options={(categoryArry)}
                                                        id='category'
                                                        name="category"
                                                        className="form-control"
                                                        onChange={this.getCategoryHandler}
                                                        value={this.state.category || ''}
                                                    />
                                                </div>
                                            </div>



                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("banner.sequence")}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.getSequenceHandler(event)}
                                                        id="sequence"
                                                        name="sequence"
                                                        value={this.state.sequence}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("banner.status")}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                        value={this.state.currentStatus}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { home, banner } = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        category_data: banner.category_data,
        update_banner_result: banner.update_banner_result,
        show_banner_data: banner.show_banner_data,
        currentUser: state.currentUser.data
    }
};

const mapDispatchToProps = {
    getCountry,
    getCategory,
    updateBanner,
    showBanner
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BannersEdit));