import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {withNamespaces} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faPen} from '@fortawesome/free-solid-svg-icons'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import MemberGroupListing from "./MemberGroupListing";
import MemberGroupCreate from "./MemberGroupCreate";
import MemberGroupShow from "./MemberGroupShow";
import MemberGroupEdit from "./MemberGroupEdit";
import RebateSettingEdit from "../RebateSetting/RebateSettingEdit";
import {getMemberGroup, getDropDown, updateMemberGroupStatus, deleteMemberGroup} from "../../store/ducks/memberGroup/actions";
import Select from "../../components/Input/Select/Select";
// import { FaRegistered } from "react-icons/fa";
import { filterErrorHandler } from '../../hoc/Shared/utility';

const initialInputState =
{
    /* form input value */
    memberGroupName: '',
    status: ''
};

let memberGroupListingData = {};
class MemberGroup extends Component {
    state = {
        pageShow: "listing",

        activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        totalRecords: 0,

        data: [],

        dropDown: [],

        filter: initialInputState,

        currentMemberGroupId: '',
        currentMemberGroupSettingId: '',
        loading: true,

        permission: []
    }

    fetchData() {
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        memberGroupListingData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        memberGroupListingData.limit = this.state.limit;
        memberGroupListingData.order = JSON.stringify(this.state.sorted);
        memberGroupListingData.country_id = this.props.currentCountryId;
        memberGroupListingData.memberGroupName = this.state.filter.memberGroupName;
        memberGroupListingData.status = this.state.filter.status;

        this.props.getMemberGroup(memberGroupListingData);
        this.props.getDropDown();
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let memberGroupPermission = this.props.currentPermission.filter((item) => item.includes("member_group_"));
            let memberGroupPermission = this.props.currentPermission.filter((item) => item.includes("member_group_") || item.includes("rebate_setting_"));
            this.setState({permission: memberGroupPermission});
        }

        if (nextProps.member_group !== this.props.member_group) {
            if (nextProps.member_group.data.responseCode === 200 && nextProps.member_group.data.msgType === 'success') {
                this.setState({
                    loading: false,
                    data: nextProps.member_group.data.data,
                    totalRecords: nextProps.member_group.data.totalRecords,
                    totalPages: nextProps.member_group.data.totalPages,
                })
            } else {
                this.setState({
                    loading: false,
                    data: [],
                    totalRecords: 0,
                    totalPages: 1
                }, () => {
                    filterErrorHandler(nextProps.member_group.data.message);
                })
            }
        }

        if(nextProps.dropDown !== this.props.dropDown) {
          if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
            this.setState({
                dropDown: nextProps.dropDown.data,
            })
          } else {
            this.setState({
                loading: false,
                dropDown: []
            }, () => {
                filterErrorHandler(nextProps.dropDown.message);
            })
          }
        }

        if (nextProps.update_status_member_group_result !== this.props.update_status_member_group_result) {
            if (nextProps.update_status_member_group_result.responseCode === 200 && nextProps.update_status_member_group_result.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_status_member_group_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.fetchData();
                    }
                });
            }
            else {
                this.setState({
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.update_status_member_group_result.message);
                })
            }
        }

        // if (nextProps.delete_member_group_result !== this.props.delete_member_group_result) {
        //     if (nextProps.delete_member_group_result.responseCode === 200) {
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_member_group_result.message,
        //             icon: nextProps.delete_member_group_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 this.fetchData();
        //             }
        //         });
        //     } else {
        //         this.setState({loading: false});
        //         const { t } = this.props;
        //         Swal.fire({
        //             title: nextProps.delete_member_group_result.message[0],
        //             icon: nextProps.delete_member_group_result.msgType,
        //             confirmButtonText: t("global.ok"),
        //         })
        //     }
        // }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    statusFormatter = (cell, row) => {
        const {t} = this.props;

        if (this.state.permission.includes("member_group_change_status")) {

            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id={`status[${row.memberGroupId}]`}
                    name={`status[${row.memberGroupId}]`}
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.memberGroupId}`}
                    onChange={(event) => {this.updateStatusMemberGroupHandler(event, row.memberGroupId)}}
                    className={dtClassName}
                />
            )
        }else{
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    }

    actionFormatter = (cell, row) => {
        let viewButton, editButton, rebateButton, output;

        if (this.state.permission.includes("member_group_show")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faSearch}/>}
                    key={`view-${row.memberGroupId}`}
                    clicked={() => this.changePageHandler('show', row.memberGroupId, row.settingId)}
                />
            );
        }

        if (this.state.permission.includes('member_group_update')) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faPen}/>}
                    key={`edit-${row.memberGroupId}`}
                    clicked={() => this.changePageHandler('edit', row.memberGroupId, row.settingId)}
                />
            );
        }

        // if (this.state.permission.includes('rebate_setting_update_setting')) {
        //     rebateButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-rebate"
        //             buttonName={<FaRegistered />}
        //             key={`rebate-${row.memberGroupId}`}
        //             clicked={() => this.changePageHandler('rebate', row.memberGroupId, row.settingId)}
        //         />
        //     );
        // }

        // if (this.state.permission.includes('member_group_delete')) {
        //     deleteButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-danger mr-2"
        //             clicked={(event) => this.deleteMemberGroupHandler(event, row.memberGroupId)}
        //             key={`delete-${row.memberGroupId}`}
        //             buttonName={<FontAwesomeIcon icon={faTrash}/>}
        //         />
        //     );
        // }

        output = [viewButton, editButton, rebateButton];
        return output;
    }

    
    onInputChange(event)
    {
      let newFilter = Object.assign({}, this.state.filter);
      newFilter[event.target.id] = event.target.value;

      this.setState({
        filter: newFilter
      })
    }

    refreshHandler = () => 
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            sorted: null
        }, ()=>
        {
          this.fetchData();
        });
    }

    clearHandler = () => {
      this.setState({
        activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        filter: initialInputState,
        loading: true,
        data: [],
        sorted: null
      }, ()=>
      {
        this.fetchData();
      });      
    }

    changePageHandler = (page, memberGroupId, memberGroupSettingId) => {
        this.setState({
            pageShow: page,
            currentMemberGroupId: memberGroupId,
            currentMemberGroupSettingId: memberGroupSettingId
        });
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            event.preventDefault();
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    updateStatusMemberGroupHandler = (event, memberGroupId) => {
        event.preventDefault();
        // const { t } = this.props;
        // Swal.fire({
        //     title: t("global.confirmation"),
        //     text: t("global.are-you-sure"),
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: t("global.update"),
        //     cancelButtonText: t("global.cancel"),
        // }).then((result) => {
        //     if (result.isConfirmed) {
                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                const updateMemberGroupStatusData = {};
                updateMemberGroupStatusData.id = memberGroupId;
                updateMemberGroupStatusData.status = Number(event.target.value);

                this.props.updateMemberGroupStatus(updateMemberGroupStatusData);
        //     }
        // });
    }

    // deleteMemberGroupHandler = (e, member_group_id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t("global._yes"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: '',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 onOpen: () => {
    //                     Swal.showLoading()
    //                 }
    //             });

    //             const deleteMemberGroupData = {};
    //             deleteMemberGroupData.id = member_group_id;

    //             this.props.deleteMemberGroup(deleteMemberGroupData);
    //         }
    //     });
    // }

    render() {
        const {t} = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit))
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: "Member Group Id",
                dataField: "memberGroupId",
                keyField: 'memberGroupId',
                hidden: true
            },
            {
                text: t('member-group.names'),
                dataField: 'memberGroupLevel',
                keyField: "memberGroupLevel",
                formatter: (cell, row, rowIndex) => {
                    let memberGroupLevel = t(`global.level.${row.memberGroupLevel}`);
                    return memberGroupLevel;
                },
                sort: true,
                onSort: this.onSort
            },
            // {
            //     text: t('global.status'),
            //     dataField: "status",
            //     keyField: "status",
            //     formatter: this.statusFormatter,
            //     headerStyle: { width: "115px" },
            // },
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,
                headerStyle: { width: "155px" }
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                        <div className="breadcrumb-header">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('member-group.member-group')}</h4>
                                </div>
                            </div>
                        </div>

                        <MemberGroupListing
                            memberGroupName={this.state.filter.memberGroupName}
                            status={this.state.filter.status}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            changePageHandler={this.changePageHandler}
                            // permission={this.state.permission}

                            /**filter drop down */
                            ddMemberGroup={this.state.dropDown.member_group}
                        />

                        <input type="hidden" name="country_id" value={this.props.currentCountryId} />
                </div>
                }

                {this.state.pageShow === 'create' &&
                <MemberGroupCreate
                    refreshHandler={() => this.refreshHandler()}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }

                {this.state.pageShow === 'show' &&
                <MemberGroupShow
                    show_member_group_id={this.state.currentMemberGroupId}
                    show_member_group_setting_id={this.state.currentMemberGroupSettingId}
                    changePageHandler={(page) => this.changePageHandler(page)}
                />
                }


                {this.state.pageShow === 'edit' &&
                    <MemberGroupEdit
                        show_member_group_id={this.state.currentMemberGroupId}
                        show_member_group_setting_id={this.state.currentMemberGroupSettingId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='rebate' &&
                    <RebateSettingEdit
                        selectId={this.state.currentMemberGroupId}
                        show_member_group_setting_id={this.state.currentMemberGroupSettingId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {memberGroup, currentUser} = state;

    return {
        member_group: memberGroup.member_group_data,
        update_status_member_group_result: memberGroup.update_status_member_group_result,
        delete_member_group_result: memberGroup.delete_member_group_result,
        currentCountryId: currentUser.data.country_id,
        currentPermission: currentUser.data.permission,
        dropDown: memberGroup.dropDown
    }
};

const mapDispatchToProps = {
    getMemberGroup,
    getDropDown,
    updateMemberGroupStatus,
    deleteMemberGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberGroup));