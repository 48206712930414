import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const businessAccountReducer = createReducer(initialState) ({
    [type.GET_BUSINESS_ACCOUNT]: (state = initialState, action) => ({
        ...state,
        business_account_data: action.payload
    }),
    [type.SHOW_BUSINESS_ACCOUNT]: (state = initialState, action) => ({
        ...state,
        show_business_account_data: action.payload
    }),
    [type.UPDATE_BUSINESS_ACCOUNT]: (state = initialState, action) => ({
        ...state,
        update_business_account_result: action.payload
    }),
    [type.GET_BUSINESS_MEMBERS]: (state = initialState, action) => ({
        ...state,
        get_business_members: action.payload
    }),
    [type.GET_BUSINESS_BANKS]: (state = initialState, action) => ({
        ...state,
        get_business_banks: action.payload
    }),
    [type.STORE_BUSINESS_ACCOUNT]: (state = initialState, action) => ({
        ...state,
        store_business_account_result: action.payload
    })
});

export default businessAccountReducer;