import * as types from './types';
import axios from '../../../common/config/axios';

export const getItemCategoryList = (data) => (dispatch, getState) => 
{    
    data.language_code = getState().currentUser.data.language_code;    
    axios.get('item_category/list', {params: data})
    .then((res) =>
    {
        dispatch (
        {
            type: types.ITEM_CATEGORY_LIST,
            payload: res
        })
    })
    .catch(err => 
    {});
};


export const getDropDown = () => (dispatch) => 
{
    axios.get('item_category/drop_down')
    .then((res) =>
    {
        dispatch (
        {
            type: types.DROPDOWN,
            value: res.data
        })
    })
    .catch(err => 
    {});
};

export const storeItemCategory = (data) => (dispatch) => 
    {
        axios.post('item_category/store', data)
        .then((res) =>
        {
            dispatch (
            {
                type: types.ITEM_CATEGORY_STORE,
                payload: res.data
            })
        })
        .catch(err => 
        {});
    };


