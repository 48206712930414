import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import Select from "../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import UpdownArrow from "../../assets/images/updown-arrow.png";

class MemberLevelTrackerListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ Component Lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.props.data.activePage - 1) * this.props.data.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard
        })
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsData = [];
        let level_status_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("up-level-bonus.level-up"), value: 1 },
            { label: t("up-level-bonus.level-down"), value: 2 },
        ];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns = [
            // { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px' } },
            { text: t("global.no"), dataField: "memberLevelTrackerId", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("member.username"), dataField: "memberUsername", sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            {
                text: t("member-level-tracker.level-from"),
                dataField: "levelFromGroupName",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
            },
            { text: t("member-level-tracker.level-to"), dataField: "levelToGroupName", sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-level-tracker.bonus-amount"), dataField: "bonusAmount", sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
            { text: t("member-level-tracker.level-status"), dataField: "levelStatus", sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName), headerStyle: { width: "115px" }, },
            {
                text: t("member-level-tracker.accumulated-deposit"),
                dataField: "accumulatedDeposit",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
            },
            {
                text: t("member-level-tracker.accumulated-turnover"),
                dataField: "accumulatedTurnover",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),
            },
            { text: t("global.created-time"), dataField: "createdDate", sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName) },
        ];

        if (this.props.data.data) {
            let stVal = "";
            let st = { 1: t("member-level-tracker.level-up"), 2: t("member-level-tracker.level-down") };
            let stCls = { 1: "success", 2: "danger" };

            rsData = this.props.data.data.map((val, idx) => {
                stVal = <span className={`badge-pill badge-${stCls[val.levelStatus]}`}>{st[val.levelStatus]}</span>;

                return {
                    memberLevelTrackerId: val.memberLevelTrackerId,
                    memberUsername: val.memberUsername,
                    levelFromGroupName: val.levelFromGroupName,
                    levelToGroupName: val.levelToGroupName,
                    bonusAmount: val.bonusAmount,
                    levelStatus: stVal,
                    accumulatedDeposit: new Intl.NumberFormat('en-US', num_format).format(val.accumulatedDeposit),
                    accumulatedTurnover: new Intl.NumberFormat('en-US', num_format).format(val.accumulatedTurnover),
                    createdDate: val.createdDate,
                };
            });
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div>
                                {this.props.data.loading ? (
                                    <Skeleton count={2} />
                                ) : (

                                    <div method="post" id="filter_form"
                                        className={`form-horizontal filter-function row
                            ${this.state.showCard ? 'open' : 'close'} filter-wrapper-1`}>
                                        {/* <div className="row"> */}
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="username" className="font-weight-bolder">
                                                    {t("member.username")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.data.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member-level-tracker.level-from")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="level_from"
                                                        name="level_from"
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                        value={this.props.data.filterInput.level_from}>
                                                        <option value=""></option>
                                                        {this.props.ddMemberGroup &&
                                                            this.props.ddMemberGroup.map((val, idx) => {
                                                                return (
                                                                    <option key={idx} value={val.memberGroupId}>
                                                                        {val.groupName}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member-level-tracker.level-to")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="level_to"
                                                        name="level_to"
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                        value={this.props.data.filterInput.level_to}>
                                                        <option value=""></option>
                                                        {this.props.ddMemberGroup &&
                                                            this.props.ddMemberGroup.map((val, idx) => {
                                                                return (
                                                                    <option key={idx} value={val.memberGroupId}>
                                                                        {val.groupName}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="level_status" className="font-weight-bolder">
                                                    {t("global.status")}
                                                </label>
                                                <Select
                                                    id="level_status"
                                                    name="level_status"
                                                    options={level_status_option}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                    value={this.props.data.filterInput.level_status}
                                                    className="form-control filter-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="level_up_date" className="font-weight-bolder">
                                                    {t("member-level-tracker.level-up-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DatePicker
                                                        id="level_up_date"
                                                        name="level_up_date"
                                                        className="form-control filter-input"
                                                        wrapperClassName="wrapperDatePicker"
                                                        selected={this.props.data.filterInput.level_up_date}
                                                        onChange={this.props.handleMonthPickerChange}
                                                        popperContainer={DatePickerContainer}
                                                        dateFormat="MM/yyyy"
                                                        autoComplete="off"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler(this.props.tabName)}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div className="show-btn"
                                onClick={() => this.toggleCard()}>
                                {
                                    this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member-level-tracker.member-level-tracker-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="memberLevelTrackerId"
                                        data={rsData}
                                        columns={columns}
                                        activePage={this.props.data.activePage}
                                        limit={this.props.data.limit}
                                        totalItems={this.props.data.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "searchValue")}
                                        searchValue={this.props.data.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.data.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberLevelTrackerListing);
