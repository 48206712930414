import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';

import * as actions from '../../store/ducks/rebateSetting/actions';

class RebateSettingDetails extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            tabId: null,
            gameType: [],
            game: [],
            rebateSettingDetail: [],
            rebateSetting: null,
            loading: true,
        };
    }

    componentDidMount() {
        if (this.props.selectId) {
            // console.log(this.props.selectId);
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps.details.data.game[1]);
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                this.setState({
                    tabId: nextProps.details.data.game_type[0].id,
                    gameType: nextProps.details.data.game_type,
                    game: nextProps.details.data.game,
                    rebateSettingDetail: nextProps.details.data.rebate_setting_detail,
                    rebateSetting: nextProps.details.data.rebate_setting,
                    loading: false
                });
            }
            Swal.close();
        }
        return true;
    }

    changeTabHandler = (e) => {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            tabId: e.target.id
        });
    }

    render() {
        const { t } = this.props;
        let rsDetails = (this.state.rebateSetting) ? this.state.rebateSetting : [];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        let tabId = this.state.tabId;

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('rebate-setting.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('rebate-setting.rebate-setting-details')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <table className='table table-bordered table-hover table-striped'>
                                        <tbody>
                                            <tr>
                                                <th>{t('rebate-setting.cashback-min')}</th>
                                                <td>{(new Intl.NumberFormat('en-US', num_format).format((rsDetails.cashback_min) || '0.00'))}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('rebate-setting.cashback-max')}</th>
                                                <td>{(new Intl.NumberFormat('en-US', num_format).format((rsDetails.cashback_max) || '0.00'))}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('rebate-setting.min-bet')}</th>
                                                <td>{(new Intl.NumberFormat('en-US', num_format).format((rsDetails.min_bet) || '0.00'))}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('rebate-setting.max-bet')}</th>
                                                <td>{(new Intl.NumberFormat('en-US', num_format).format((rsDetails.max_bet) || '0.00'))}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('global.status')}</th>
                                                <td>
                                                    <span className={`badge-pill badge-${(rsDetails.status === 1) ? 'success' : 'danger'}`}>{(rsDetails.status && rsDetails.status === 1) ? t('global.active') : t('global.inactive')}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('rebate-setting.common-rebate')}</th>
                                                <td>{(new Intl.NumberFormat('en-US', num_format).format((rsDetails.common_percent) || '0.00'))}</td>
                                            </tr>
                                            <tr style={{ background: 'white' }}>
                                                <th>{t('rebate-setting.game-percentage')}</th>
                                                <td>
                                                    <div className='panel panel-primary tabs-style-2'>
                                                        <div className='tab-menu-heading'>
                                                            <div className='tabs-menu1'>
                                                                <ul className='nav panel-tabs main-nav-line'>
                                                                    {
                                                                        this.state.gameType.map((val, idx) => {
                                                                            let tabActive = (Number(tabId) === val.id) ? 'active' : '';
                                                                            // return (<li key={idx}><a href='/#' id={val.id} onClick={(event) => this.changeTabHandler(event)} className={`nav-link email-tab ${(tabId == val.id) ? 'active' : ''}`} value='' data-toggle='tab'>{val.title}</a></li>);
                                                                            return (<li key={idx}><a href='/#' id={val.id} onClick={(event) => this.changeTabHandler(event)} style={{ borderTopWidth: 0 }} className={`nav-link email-tab ${tabActive}`}>{t(`game-type.${val.slug}`)}</a></li>);
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tab-content'>
                                                        {
                                                            this.state.gameType.map((val, idx) => {
                                                                let tabContent = 'No game exist in this category';
                                                                if (this.state.game[val.id] !== undefined) {
                                                                    tabContent = [];
                                                                    for (const key in this.state.game[val.id]) {
                                                                        let percent = (this.state.rebateSettingDetail[this.state.game[val.id][key]['id']] !== undefined) ? this.state.rebateSettingDetail[this.state.game[val.id][key]['id']]['percent'] : 0.00;
                                                                        tabContent.push(
                                                                            <div className='col-md-3' key={key}>
                                                                                <div className='form-group'>
                                                                                    <label>{t(`game.${key}`)}: <span className="tx-primary">{percent}%</span></label>
                                                                                    {/* <div className="progress">
                                                                                    <div className="progress-bar" role="progressbar" style={{width:`${percent}%`}} aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                                                                                </div> */}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                                let tabActive = (Number(tabId) === val.id) ? 'active' : '';
                                                                return (
                                                                    <div key={idx} className={`panel-body tabs-menu-body main-content-body-right border tab-pane ${tabActive}`}>
                                                                        <div className='row'>
                                                                            {tabContent}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.rebateSetting.details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RebateSettingDetails));