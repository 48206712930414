import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class MemberPromotionCancel extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
        return <span>{rowNumber}</span>;
    };

    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        let st = [0, 1, 2, 4];
        let stCls =
        {
            0: "secondary",
            1: "success",
            2: "danger",
            4: "danger",
        };

        /* ======================== datatables data assign [START] ======================== */
        const columns =
            [
                { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
                { text: t("member-promotion.order-id"), dataField: "order_id", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.p-order-id"), dataField: "p_order_id", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.member"), dataField: "username", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.promotion"), dataField: "title", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.deposit-amount"), dataField: "deposit_amount", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.bonus-amount"), dataField: "bonus_amount", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.target-amount"), dataField: "target_amount", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.status"), dataField: "status", sort: true, onSort: this.props.onSort },
                // { text: t("member-promotion.last-bet-date"), dataField: "last_bet_date", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.start-date"), dataField: "start_date", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.end-date"), dataField: "end_date", sort: true, onSort: this.props.onSort },
                { text: t("member-promotion.complete-date"), dataField: "complete_date", sort: true, onSort: this.props.onSort },
                // {text: t("member-promotion.create-date"), dataField: "create_date", sort: true, onSort: this.props.onSort},
                // { text: t("member-promotion.valid-to-cancel"), dataField: "valid_cancel", sort: true, onSort: this.props.onSort },
                { text: t("global.action"), dataField: "action", headerStyle: { width: "115px" } },
            ];

        if (!permission.includes("member_promotion_update")) {
            columns.splice(13, 1);
        }

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stVal = (
                    <span className={`badge-${stCls[val.status]}`}>
                        {t(`member-promotion.status-desc.${val.status}`)}
                    </span>
                );

                let orderIdVal = permission.includes("transaction_details_access")
                    ? (<b>
                        <a href="/#" onClick={(event) => this.props.detailsHandler(event, val.tid)}>
                            {val.order_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>)
                    : (val.order_id);

                let pOrderIdVal = '';
                pOrderIdVal = permission.includes("transaction_details_access")
                    ? (<b>
                        <a href="/#" onClick={(event) => this.props.detailsHandler(event, val.pid)}>
                            {val.p_order_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>)
                    : (val.p_order_id);

                // let vtcCls = { 0: "danger", 1: "success" };
                // let vtcVal = (<span className={`badge-${vtcCls[val.valid_cancel]}`}>{t(`member-promotion.valid-to-cancel-desc.${val.valid_cancel}`)}</span>);

                // let actVal =
                //     (val.valid_cancel === 1)
                //         ?
                //         (<div>
                //             {permission.includes("member_promotion_update") && (
                //                 <a
                //                     href="/#"
                //                     onClick={(event) => this.props.cancelHandler(event, val.id)}
                //                     className="btn btn-md btn-white delete"
                //                     data-toggle="tooltip"
                //                     title={t('global.cancel')}
                //                 >
                //                     <FontAwesomeIcon icon={faBan} />
                //                 </a>
                //             )}
                //         </div>)
                //         : '';
                let actVal = <div>
                    {permission.includes("member_promotion_access") && (
                        <a
                            href="/#"
                            onClick={(event) => this.props.promoDetailsHandler(event, val.id, val.order_id, val.p_order_id, val.user_id, val.title)}
                            className="btn btn-md btn-white"
                            data-toggle="tooltip"
                            title={t('global.view')}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </a>
                    )}
                </div>

                return {
                    no: idx,
                    order_id: orderIdVal,
                    p_order_id: pOrderIdVal,
                    username: val.username,
                    title: val.title,
                    deposit_amount: val.deposit_amount,
                    bonus_amount: val.bonus_amount,
                    target_amount: val.target_amount,
                    status: stVal,
                    start_date: val.start_date,
                    end_date: val.end_date,
                    create_date: val.create_date,
                    complete_date: val.complete_date,
                    // last_bet_date: (val.last_bet_date) ? val.last_bet_date : '-',
                    // valid_cancel: vtcVal,
                    action: actVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                {/* <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("member-promotion.title")}
                            </h4>
                        </div>
                    </div>
                </div> */}
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}                                    >
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member-promotion.member")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("member-promotion.status")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        {st.map((val) => {
                                                            return (
                                                                <option key={val} value={val}>{t(`member-promotion.status-desc.${val}`)}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="updown" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("member-promotion.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberPromotionCancel);
