import React, { Component } from "react";
import { connect } from "react-redux";
import { editMemberAccountDetails, editMemberBankDetails, updateAccountDetails, addBankDetails, updateMemberBankStatus } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { formValidation, filterErrorHandler } from "../../../hoc/Shared/utility";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Input/Select/Select";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";
import Swal from "sweetalert2";
import DataTable from "../../../components/Datatable/DataTable";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../../components/ValidationError/ValidationError";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faMinus } from "@fortawesome/free-solid-svg-icons";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

let memberBankArray = [];
let memberGroupArray = [];

class MemberAccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_bank: true,
            reload: false, // Indicates is same data or not, if same data need rerun the shouldComponentUpdate
            loading_add_bank: false,
            username: "",
            nickname: "",
            name: "",
            identification_no: "",
            dialcode: "",
            contact_no: "",
            date_of_birth: null,
            set_dob: true,
            memberGroupLevel: "",
            status: "",
            pause_bet: "",
            edit_id: null,
            bank_edit_id: null,
            member_bank_data: [],
            activePage: 1,
            limit: 20,
            order: null,
            totalRecords: 0,
            totalPages: 0,
            searchValue: "",

            member_bank: "",
            member_bank_account_name: "",
            member_bank_no: "",

            account_details_error_message: [],
            account_details_touched: false,
            member_bank_error_message: [],
            member_bank_touched: false,

            security_pin_status: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDateOfBirthChange = this.handleDateOfBirthChange.bind(this);
        this.updateMemberAccountHandler = this.updateMemberAccountHandler.bind(this);
        this.addMemberBankAccountHandler = this.addMemberBankAccountHandler.bind(this);
    }

    async componentDidMount() {
        // console.log("[MemberAccountDetails] componentDidMount()");
        formValidation("edit_member_form");

        const initLoad = this.props.initialLoading.filter((item) => item.id === "account_details");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            const listingArr = this.getFilterArray();
            await Promise.all([this.props.editMemberAccountDetails(this.props.memberEditId), this.props.editMemberBankDetails(listingArr)]);
        } else {
            if (this.props.memberEditAccountData !== undefined && this.props.memberEditBankData !== undefined) {
                if (this.props.memberEditAccountData.responseCode === 200 && this.props.memberEditAccountData.msgType === "success" && 
                    this.props.memberEditBankData.responseCode === 200 && this.props.memberEditBankData.msgType === 'success') {
                    if (this.props.memberEditAccountData.data.member.memberId !== this.props.memberEditId) {
                        const listingArr = this.getFilterArray();
                        await Promise.all([this.props.editMemberAccountDetails(this.props.memberEditId), this.props.editMemberBankDetails(listingArr)]);
                    } else {
                        this.setState({
                            loading: false,
                            reload: true,
                        });
                    }
                }
            } else {
                const listingArr = this.getFilterArray();
                await Promise.all([this.props.editMemberAccountDetails(this.props.memberEditId), this.props.editMemberBankDetails(listingArr)]);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log("[MemberAccountDetails] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        // Member Edit Account Details
        if (nextProps.memberEditId !== undefined && nextProps.memberEditId !== nextState.edit_id && nextProps.memberEditAccountData !== undefined) {
            if (nextProps.memberEditAccountData.responseCode === 200 && nextProps.memberEditAccountData.msgType === 'success') {
                let memberData = nextProps.memberEditAccountData.data.member;
                let phoneNumberData = nextProps.memberEditAccountData.data.phoneNumber;
                let memberGroupData = nextProps.memberEditAccountData.data.memberGroupData;
                let dateOfBirth = null;
                if (memberData.dateOfBirth) {
                    dateOfBirth = moment(memberData.dateOfBirth).toDate();
                }
                let contactNo = memberData.contactNo;

                this.setState({
                    loading: false, // If got permission need to check which should set state to false
                    edit_id: nextProps.memberEditId,
                    username: memberData.username,
                    nickname: memberData.nickname,
                    name: memberData.name,
                    identification_no: memberData.identification_no,
                    dialcode: phoneNumberData.dialcode,
                    contact_no: contactNo.substring(2),
                    date_of_birth: dateOfBirth,
                    set_dob: dateOfBirth === null ? true : false,
                    memberGroupLevel: memberData.memberGroupLevel,
                    status: memberData.status,
                    pause_bet: memberData.pauseBet
                });
                memberGroupArray = [];
                Object.keys(memberGroupData).map((item, i) => {
                    return memberGroupArray.push({
                        label: t(`global.level.${memberGroupData[item].level}`),
                        value: memberGroupData[item].id,
                    });
                });
            }
            else {
                filterErrorHandler(nextProps.memberEditAccountData.message);
            }
        } else {
            if (nextProps.memberEditAccountData !== undefined && this.props.memberEditAccountData !== nextProps.memberEditAccountData) {
                let memberData = nextProps.memberEditAccountData.data.member;
                let phoneNumberData = nextProps.memberEditAccountData.data.phoneNumber;
                let memberGroupData = nextProps.memberEditAccountData.data.memberGroupData;
                let dateOfBirth = null;
                let securityPinStatus = nextProps.memberEditAccountData.data.securityPin;
                if (memberData.dateOfBirth) {
                    dateOfBirth = moment(memberData.dateOfBirth).toDate();
                }
                let contactNo = memberData.contactNo;
                
                this.setState({
                    loading: false, // If got permission need to check which should set state to false
                    edit_id: nextProps.memberEditId,
                    username: memberData.username,
                    nickname: memberData.nickname,
                    name: memberData.name,
                    identification_no: memberData.identification_no,
                    dialcode: phoneNumberData.dialcode,
                    contact_no: contactNo.substring(2),
                    date_of_birth: dateOfBirth,
                    set_dob: dateOfBirth === null ? true : false,
                    memberGroupLevel: memberData.memberGroupLevel,
                    status: memberData.status,
                    pause_bet: memberData.pauseBet,
                    memberSecurityPinStatus: securityPinStatus
                });
                memberGroupArray = [];
                Object.keys(memberGroupData).map((item, i) => {
                    return memberGroupArray.push({
                        label: t(`global.level.${memberGroupData[item].level}`),
                        value: memberGroupData[item].id,
                    });
                });
            }
        }        

        // Member Edit Bank Details
        if (nextProps.memberEditId !== undefined && nextProps.memberEditBankData !== undefined && nextProps.memberEditBankData !== this.props.memberEditBankData) {
            if (nextProps.memberEditBankData.responseCode === 200 && nextProps.memberEditBankData.msgType === 'success') {
                let bankData = nextProps.memberEditBankData.data.bank;
                let memberBankData = nextProps.memberEditBankData.data.memberBank;
                this.setState({
                    loading_bank: false,
                    bank_edit_id: nextProps.memberEditId,
                    member_bank_data: memberBankData.row,
                    totalRecords: memberBankData.pagination.total_record,
                    totalPages: memberBankData.pagination.total_page,
                });
                memberBankArray = [];
                Object.keys(bankData).map((item, i) => {
                    return memberBankArray.push({
                        label: bankData[item].bankName,
                        value: bankData[item].bankId,
                    });
                });
                // Swal.close();
            }
            else {
                filterErrorHandler(nextProps.memberEditBankData.message);
            }
        } else if (this.state.reload && nextProps.memberEditId !== nextState.bank_edit_id && nextProps.memberEditBankData !== undefined) {
            let bankData = nextProps.memberEditBankData.data.bank;
            let memberBankData = nextProps.memberEditBankData.data.memberBank;
            this.setState({
                loading_bank: false,
                bank_edit_id: nextProps.memberEditId,
                member_bank_data: memberBankData.row,
                totalRecords: memberBankData.pagination.total_record,
                totalPages: memberBankData.pagination.total_page,
            });
            memberBankArray = [];
            Object.keys(bankData).map((item, i) => {
                return memberBankArray.push({
                    label: bankData[item].bankName,
                    value: bankData[item].bankId,
                });
            });
            // Swal.close();
        }

        // Store and Update Result
        if (nextProps.memberUpdateAccountResult !== this.props.memberUpdateAccountResult) {
            if (nextProps.memberUpdateAccountResult.responseCode === 200 && nextProps.memberUpdateAccountResult.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.memberUpdateAccountResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (this.state.date_of_birth !== null && this.state.set_dob) {
                            this.setState({
                                set_dob: false,
                            });
                        }
                        this.props.editMemberAccountDetails(this.props.memberEditId);
                        this.setState({
                            account_details_touched: false,
                        });
                    }
                });
            } else  {
                this.setState({
                    account_details_touched: true,
                    account_details_error_message: nextProps.memberUpdateAccountResult.message,
                });
                Swal.close();
            } 
        }

        if (nextProps.memberAddBankResult !== this.props.memberAddBankResult) {
            if (nextProps.memberAddBankResult.responseCode === 200 && nextProps.memberAddBankResult.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.memberAddBankResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.refreshHandler();
                        this.setState({
                            member_bank: "",
                            member_bank_account_name: "",
                            member_bank_no: "",
                            member_bank_touched: false,
                            loading_add_bank: false,
                        });
                    }
                });
            } else{
                this.setState({
                    member_bank_touched: true,
                    member_bank_error_message: nextProps.memberAddBankResult.message,
                });
                Swal.close();
            } 
        }

        if (nextProps.memberUpdateMemberBankStatusResult !== this.props.memberUpdateMemberBankStatusResult) {
            if (nextProps.memberUpdateMemberBankStatusResult.responseCode === 200 && nextProps.memberUpdateMemberBankStatusResult.msgType === 'success') {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.memberUpdateMemberBankStatusResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.refreshHandler();
                    }
                });
            } else {
                filterErrorHandler(nextProps.memberUpdateMemberBankStatusResult.message)
            }
        }

        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleDateOfBirthChange = (date) => {
        this.setState({
            date_of_birth: date,
        });
    };

    handleMemberBankChange = (event) => {
        this.setState({
            member_bank: event,
        });
    };

    updateMemberAccountHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateMemberAccountData = {};
                let birthDate = null;
                updateMemberAccountData.id = this.state.edit_id;
                updateMemberAccountData.nickname = this.state.nickname;
                updateMemberAccountData.name = this.state.name;
                if (this.props.currentUserGroup && (this.props.currentUserGroup.toLowerCase().replace(' ', '') === "superadmin" || this.props.currentUserGroup.toLowerCase().replace(' ', '') === "admin"))
                {
                    updateMemberAccountData.contact_no = this.state.dialcode.replace(/^\+/, "") + this.state.contact_no;
                }
                if (this.state.date_of_birth !== null) {
                    birthDate = moment(this.state.date_of_birth).format("yyyy-MM-DD");
                }
                updateMemberAccountData.date_of_birth = birthDate;
                updateMemberAccountData.group_id = this.state.memberGroupLevel;
                updateMemberAccountData.status = this.state.status;
                updateMemberAccountData.pause_bet = this.state.pause_bet;
                updateMemberAccountData.security_pin_status = this.state.security_pin_status;
                updateMemberAccountData.identification_no = this.state.identification_no;
                // console.log("Member Account Details Form Submit:", updateMemberAccountData);
                this.props.updateAccountDetails(updateMemberAccountData);
            }
        });
    };

    addMemberBankAccountHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let addMemberBankAccountData = {};
                addMemberBankAccountData.member_id = this.state.edit_id;
                addMemberBankAccountData.bank_name = this.state.member_bank.value;
                addMemberBankAccountData.bank_acc_name = this.state.member_bank_account_name;
                addMemberBankAccountData.bank_acc_number = this.state.member_bank_no;
                // console.log("Form Submit:", addMemberBankAccountData);
                this.props.addBankDetails(addMemberBankAccountData);
            }
        });
    };

    /* ======================== Datatables Function [START] ======================== */
    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState(
            {
                data: [],
                loading: false
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.editMemberBankDetails(listingArr);
            }
        );

    };

    getFilterArray = () => {
        // this.loadingHandler(); // Comment this
        const listingArr = {};
        let availablePage = this.state.activePage;

        listingArr.id = this.props.memberEditId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);

        return listingArr;
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.editMemberBankDetails(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.getFilterArray();
            this.props.editMemberBankDetails(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.props.permission.includes("member_update_bank")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1 },
                { label: t("global.inactive"), value: 0 },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="member_bank_status"
                    name="member_bank_status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateMemberBankStatusHandler(event, row.memberBankAccId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    updateMemberBankStatusHandler = (event, memberBankAccId) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                const updateMemberBankStatusData = {};
                updateMemberBankStatusData.member_bank_id = memberBankAccId;
                updateMemberBankStatusData.member_bank_status = Number(event.target.value);
                this.props.updateMemberBankStatus(updateMemberBankStatusData);
            }
        });
    };

    handleDisplayAddBank = () => {
        this.setState({
            loading_add_bank: !this.state.loading_add_bank,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let status_option = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        let dataListing = [];
        const columns = [
            { text: t("member.bank"), dataField: "bankName", sort: true, onSort: this.onSort },
            { text: t("member.bank-account-name"), dataField: "memberBankAccName", sort: true, onSort: this.onSort },
            { text: t("member.bank-account-no"), dataField: "memberBankAccNumber", sort: true, onSort: this.onSort },
            { text: t("global.status"), dataField: "status", formatter: this.statusFormatter, headerStyle: { width: "115px" } },
        ];

        if (this.state.member_bank_data.length > 0 && this.state.bank_edit_id === this.props.memberEditId) {
            dataListing = this.state.member_bank_data.map((val, idx) => {
                return {
                    memberBankAccId: val.memberBankAccId,
                    bankName: val.bankName,
                    memberBankAccName: (this.state.name || val.memberBankAccName),
                    memberBankAccNumber: val.memberBankAccNumber,
                    status: val.status,
                };
            });
        }

        if ((this.state.loading_bank || this.state.loading) && this.props.permission.includes("member_update_bank")) {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member.edit-bank-details")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member.account-details")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="edit_member_form" className="form-horizontal" onSubmit={this.updateMemberAccountHandler} noValidate>
                                    <ValidationError formName="edit_member_form" touched={this.state.account_details_touched} message={this.state.account_details_error_message} />

                                    {this.state.loading === true || this.state.loading_bank === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="nickname">{t("global.nickname")}</label>
                                                    <StringInput
                                                        id="nickname"
                                                        name="nickname"
                                                        className="form-control"
                                                        placeholder={t("global.nickname")}
                                                        value={this.state.nickname || ''}                                                        
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="name">{t("member.name")}</label>
                                                    <StringInput
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={t("member.name")}
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {this.props.currentUserGroup && (this.props.currentUserGroup.toLowerCase().replace(' ', '') === "superadmin" || this.props.currentUserGroup.toLowerCase().replace(' ', '') === "admin") &&
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="contact_no">{t("member.contact-no")}</label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">{this.state.dialcode}</span>
                                                        <NumberInput
                                                            id="contact_no"
                                                            name="contact_no"
                                                            className="form-control"
                                                            placeholder={t("member.contact-no")}
                                                            value={this.state.contact_no}
                                                            onChange={this.handleChange}
                                                            onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+' || evt.key === '.') && evt.preventDefault())}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>}

                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label>{t("member.date-of-birth")}</label>
                                                    {/* {this.state.set_dob ? ( */}
                                                        <DatePicker
                                                            name="date_of_birth"
                                                            className="form-control"
                                                            wrapperClassName="wrapperDatePicker"
                                                            selected={this.state.date_of_birth}
                                                            onChange={this.handleDateOfBirthChange}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="yyyy-MM-dd"
                                                            popperContainer={DatePickerContainer}
                                                            autoComplete="off"
                                                            // minDate={new Date(year - 118, month, day)}
                                                            // maxDate={new Date(year - 18, month, day)}
                                                        />
                                                    {/* ) : (
                                                        <input
                                                            type="text"
                                                            id="date_of_birth"
                                                            className="form-control"
                                                            placeholder={t("member.date-of-birth")}
                                                            value={moment(this.state.date_of_birth).format("yyyy-MM-DD")}
                                                            readOnly
                                                        />
                                                    )} */}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="identification_no">{t("member.identification-no")}</label>
                                                    <StringInput
                                                        id="identification_no"
                                                        name="identification_no"
                                                        className="form-control"
                                                        placeholder={t("member.identification-no")}
                                                        value={this.state.identification_no}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="memberGroupLevel">{t("member-group.member-group")}</label>
                                                    <Select
                                                        id="memberGroupLevel"
                                                        name="memberGroupLevel"
                                                        options={memberGroupArray}
                                                        value={this.state.memberGroupLevel}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="status">{t("global.status")}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        options={status_option}
                                                        value={this.state.status}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="pause_bet">{t("member.pause-bet")}</label>
                                                    <Select
                                                        id="pause_bet"
                                                        name="pause_bet"
                                                        options={status_option}
                                                        value={this.state.pause_bet}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="pause_bet">{t("member.security-pin-status")}</label>
                                                    <Select
                                                        id="security_pin_status"
                                                        name="security_pin_status"
                                                        options={status_option}
                                                        value={this.state.memberSecurityPinStatus}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    {(!this.state.loading_bank && !this.state.loading) && this.props.permission.includes("member_update_bank") && (
                        <>
                            <div className="col-12">
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <h4 className="card-title mb-1">
                                                    <strong>{t("member.edit-bank-details")}</strong>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mx-3">
                                        <div className="card-header row justify-content-between mx-0">
                                            <h4 className="card-title mb-1">
                                                <strong>{t("member.add-bank-details")}</strong>
                                            </h4>
                                            <div className="col-md-3">
                                                {/* <div className="btn-group float-right">
                                                        <Button
                                                            typeButton="button"
                                                            idValue="add_bank"
                                                            classValue={`btn ${this.state.loading_add_bank?"btn-danger":"btn-primary"}`}
                                                            clicked={this.handleDisplayAddBank}
                                                            buttonName={this.state.loading_add_bank?<FontAwesomeIcon icon={faMinus}/>:<FontAwesomeIcon icon={faPlus}/> }
                                                        />
                                                    </div> */}
                                            </div>
                                        </div>

                                        {/* <div className={`card-body pt-0 ${this.state.loading_add_bank?"d-block":"d-none"}`}> */}
                                        <div className="card-body pt-0">
                                            <form
                                                method="post"
                                                id="member_bank_form row"
                                                className={`form-horizontal filter-function ${this.state.loading_add_bank ? "open" : "close"}`}
                                                onSubmit={this.addMemberBankAccountHandler}
                                                noValidate>
                                                <ValidationError
                                                    formName="member_bank_form"
                                                    touched={this.state.member_bank_touched}
                                                    message={this.state.member_bank_error_message}
                                                />
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>{t("member.bank")}</label>
                                                            <ReactSelect
                                                                options={memberBankArray}
                                                                isSearchable={true}
                                                                name="member_bank"
                                                                value={this.state.member_bank}
                                                                onChange={this.handleMemberBankChange}
                                                                placeholder={t("global.select")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>{t("member.bank-account-name")}</label>
                                                            <StringInput
                                                                id="member_bank_account_name"
                                                                name="member_bank_account_name"
                                                                className="form-control"
                                                                placeholder={t("member.bank-account-name")}
                                                                value={this.state.member_bank_account_name}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>{t("member.bank-account-no")}</label>
                                                            <input
                                                                type="number"
                                                                id="member_bank_no"
                                                                name="member_bank_no"
                                                                className="form-control"
                                                                placeholder={t("member.bank-account-no")}
                                                                value={this.state.member_bank_no}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 form-group mb-0 justify-content-end">
                                                        <Button typeButton="submit" classValue="btn btn-blue" idValue="add_bank" buttonName={t("global.insert")} />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="show-btn" onClick={() => this.handleDisplayAddBank()}>
                                                {this.state.loading_add_bank ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="table-responsive table-hover">
                                            <DataTable
                                                keyField="memberBankAccId"
                                                data={dataListing}
                                                columns={columns}
                                                activePage={this.state.activePage}
                                                limit={this.state.limit}
                                                totalItems={this.state.totalRecords}
                                                paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                                                sorted={this.state.order}
                                                sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                                searchChangeHandler={(val) => this.datatablesChangeHandler(val, "searchValue")}
                                                searchValue={this.props.searchValue}
                                                classValue="dataTable table-bordered text-justify text-center"
                                                hideSearchBar={true}
                                                hidePagination={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberEditAccountData: member.member_edit_account_data,
        memberEditBankData: member.member_edit_bank_data,
        memberUpdateAccountResult: member.member_update_account_result,
        memberAddBankResult: member.member_add_bank_result,
        memberUpdateMemberBankStatusResult: member.member_update_bank_status_result,
        // memberDeleteBankResult: member.member_delete_bank_result,
        currentCountryId: state.currentUser.data.country_id,
        currentUserGroup: state.currentUser.data.user_group,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editMemberAccountDetails: (id) => dispatch(editMemberAccountDetails(id)),
        editMemberBankDetails: (data) => dispatch(editMemberBankDetails(data)),
        updateAccountDetails: (data) => dispatch(updateAccountDetails(data)),
        addBankDetails: (data) => dispatch(addBankDetails(data)),
        updateMemberBankStatus: (data) => dispatch(updateMemberBankStatus(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberAccountDetails));
