import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
// import moment from "moment";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class AnnouncementListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("announcement.announcement")}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-1`}
                                    >
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t('announcement.title')}
                                                </label>
                                                <StringInput
                                                    id="title"
                                                    name="title"
                                                    value={this.props.filter.title}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t('global.status')}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        value={this.props.filter.status}
                                                        onChange={this.props.onInputChange}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">{t("global.active")}</option>
                                                        <option value="0">{t("global.inactive")}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t('announcement.show')}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="show"
                                                        name="show"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.show}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">{t('announcement.news')}</option>
                                                        <option value="2">{t('announcement.member')}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t('announcement.start-time')}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon
                                                            icon={faCalendarCheck}
                                                        />
                                                    </span>
                                                    <DateTimePicker
                                                        id="announce_time"
                                                        name="announce_time"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format='YYYY/MM/DD'
                                                        value={this.props.filter.announce_time}
                                                        onChange={this.props.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto">
                                            <div>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("announcement.announcement-listing")}</h4>
                                    </div>
                                </div>
                                {this.props.permission.includes("announcement_create") && (
                                    <div className="d-flex">
                                        <div>
                                            <div className="btn-group">
                                                <AiFillPlusCircle />
                                                <Button
                                                    typeButton="button"
                                                    idValue="agent"
                                                    classValue="btn btn-underline"
                                                    clicked={() => this.props.changePageHandler("create")}
                                                    buttonName={t("announcement.add-announcement")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="announcementId"
                                        data={this.props.dataListing}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sorted={this.state.order}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchValue={this.props.searchValue}
                                        searchChangeHandler={this.props.searchChangeHandler}
                                        searchSubmitHandler={this.props.postSearchHandler}
                                        searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(AnnouncementListing);
