import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const whitelistIPReducer = createReducer(initialState) ({
    // Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.value,
    }),
    [type.DELETE]: (state, action) => ({
        ...state,
        delete: action.value,
    }),
});

export default whitelistIPReducer;
