import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import DataTable from '../../../components/Datatable/DataTable';
import Skeleton from 'react-loading-skeleton';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import Button from "../../../components/Button/Button";

import UpdownArrow from '../../../assets/images/updown-arrow.png'

// const status_option = ['debit','credit','draw','win','lose'];
const type_option = ['deposit', 'withdrawal', 'wallet adjustment', 'pay'];
class MemberWalletTrackerListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t, i18n } = this.props;
        let data = [];
        let st_cls = {
            'debit': 'danger',
            'credit': 'success',
            'draw': 'null',
            'win': 'success',
            'lose': 'danger',
            'bet': 'primary'
        };
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        const columns = [
            { text: t('transaction.transaction-date'), dataField: 'transaction_date', sort: true, onSort: this.props.onSort },
            { text: t('transaction.order-id'), dataField: 'order_id', sort: true, onSort: this.props.onSort },
            { text: t('transaction.transaction-type'), dataField: 'transaction_type', sort: true, onSort: this.props.onSort },
            // {text: t('transaction.status'), dataField: 'status', sort: true, onSort: this.props.onSort},
            { text: t('transaction.amount'), dataField: 'amount', sort: true, onSort: this.props.onSort },
            { text: t('transaction.balance'), dataField: 'balance', sort: true, onSort: this.props.onSort }
        ]
        const show_details = ["deposit", "withdrawal", "adjustment-in", "adjustment-out"];
        if (this.props.data) {
            data = this.props.data.map((val, index) => {
                let orderIdVal = "";
                orderIdVal = this.props.permission.includes("transaction_details_access") ? (
                    (show_details.includes(val.transaction_type)) ? (
                        <b>
                            <a
                                href="/#"
                                onClick={(event) =>
                                    this.props.detailsHandler(event, val.trx_id)
                                }
                            >
                                {val.order_id}
                                <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                            </a>
                        </b>
                    ) : (val.order_id)
                ) : (
                    val.order_id
                );
                let className = `badge badge-pill badge-${st_cls[val.status]}`;
                switch (val.status) {
                    case "draw":
                        className = null;
                        break;
                    default:
                        break;
                }
                let amountVal = (
                    <span className={className}>
                        {new Intl.NumberFormat('en-US', num_format).format(val.amount)}
                    </span>
                );
                let transaction_type = i18n.exists(`transaction-type.${val.transaction_type}`) ? (val.transaction_type === 'withdrawal' ? t(`transaction-type.${val.transaction_type}`) + ' (' + t(`transaction.status-desc.${val.trx_status}`) + ')' : t(`transaction-type.${val.transaction_type}`) ) : val.transaction_type;
                return {
                    transaction_date: val.transaction_date,
                    order_id: orderIdVal,
                    transaction_type: transaction_type, //i18n.exists(`transaction-type.${val.transaction_type}`) ? t(`transaction-type.${val.transaction_type}`) : val.transaction_type,
                    // status: val.status,
                    amount: amountVal,
                    balance: new Intl.NumberFormat('en-US', num_format).format(val.balance)
                };
            });
        }

        return (
            <Auxiliary>
                <div className='row col-sm'>
                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div>
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method='post'
                                        id="wallet_tracker_form"
                                        className={`form-horizontal filter-function row filter-wrapper-1`}
                                    >
                                        <div className='col-md-3 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('transaction.transaction-date')}</label>
                                                <div className='input-group-prepend'>
                                                    <span className='input-group-text'>
                                                        <i className='fas fa-calendar-check'></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.transaction_date}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-3 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('transaction.status')}</label>
                                                <select
                                                    className='form-control filter-input'
                                                    id="status"
                                                    name="status"
                                                    value={this.props.filter.status}
                                                    onChange={this.props.onInputChange}>
                                                        <option value=""></option>
                                                        {status_option && 
                                                            status_option.map((val,idx)=>{
                                                                return (
                                                                    <option key={idx} value={val}>
                                                                        {t(`transaction.custom-status-desc.${idx}`)}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className='col-md-3 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('transaction.transaction-type')}</label>
                                                <div className='select-input'>
                                                    <select
                                                        className='form-control filter-input'
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        value={this.props.filter.transaction_type}
                                                        onChange={this.props.onInputChange}>
                                                        <option value=""></option>
                                                        {type_option &&
                                                            type_option.map((val, idx) => {
                                                                return (
                                                                    <option key={idx} value={val}>
                                                                        {t(`transaction-type.${val}`)}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col mt-auto'>
                                            <div className='d-flex'>
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-blue mr-2'
                                                    idValue="filter"
                                                    buttonName={t('global.filter')}
                                                    clicked={() => { this.props.refreshHandler() }}
                                                />
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-secondary'
                                                    idValue="reset"
                                                    buttonName={t('global.reset')}
                                                    clicked={() => { this.props.clearHandler() }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>{t('member.wallet-tracker')}</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover database'>
                                    <DataTable
                                        keyField="order_id"
                                        data={data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center member__transaction"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(MemberWalletTrackerListing);