import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const emailBlastReducer = createReducer(initialState) ({
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.payload
    }),

    [type.GET_MEMBERS]: (state, action) => ({
        ...state,
        get_members: action.payload
    }),

    [type.STORE]: (state, action) => ({
        ...state,
        store: action.payload
    }),

    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.payload
    }),

    [type.SHOW]: (state, action) => ({
        ...state,
        show: action.payload
    }),

    [type.EXCEL]: (state, action) => ({
        ...state,
        excel: action.payload
    })
});

export default emailBlastReducer;