import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from "../../assets/images/updown-arrow.png";
import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";

import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import ValidationError from '../../components/ValidationError/ValidationError';

class RebateSettingListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
        // console.log('constructor (listing):', this.state);
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("rebate-setting.member-group-name"),
                dataField: "group_name",
                sort: true,
                onSort: this.props.onSort,
            },
            { text: t("global.status"), dataField: "status", headerStyle: { width: "115px" }, },
            { text: t("global.action"), dataField: "action", headerStyle: { width: "115px" }, },
        ];
        if (
            !permission.includes("rebate_setting_show") &&
            !permission.includes("rebate_setting_update_setting")
        ) {
            columns.splice(3, 1);
        }

        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                let stCls = val.status === 1 ? "active" : "inactive";
                let actionVal =
                    permission.includes("rebate_setting_show") ||
                        permission.includes("rebate_setting_update_setting") ? (
                        <div>
                            {permission.includes("rebate_setting_show") && (
                                <a
                                    href="/#"
                                    onClick={(event) =>
                                        this.props.detailsHandler(
                                            event,
                                            "details",
                                            val.mgid
                                        )
                                    }
                                    className="btn btn-sm btn-white mr-2"
                                    data-toggle="tooltip"
                                    title="view"
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </a>
                            )}
                            {permission.includes(
                                "rebate_setting_update_setting"
                            ) && (
                                    <a
                                        href="/#"
                                        onClick={(event) =>
                                            this.props.detailsHandler(
                                                event,
                                                "edit",
                                                val.mgid
                                            )
                                        }
                                        className="btn btn-sm btn-white"
                                        data-toggle="tooltip"
                                        title="edit"
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </a>
                                )}
                        </div>
                    ) : (
                        ""
                    );
                let stVal = permission.includes(
                    "rebate_setting_update_status"
                ) ? (
                    val.status === null ? (
                        t("global.incomplete-setup")
                    ) : (
                        <div className="select-input">
                            <select
                                className={`form-control datatable-select datatable-select-${stCls}`}
                                id={val.rsid}
                                name={val.rsid}
                                onChange={(event) =>
                                    this.props.updateStatusHandler(event)
                                }
                                value={val.status === null ? 0 : val.status}
                            >
                                <option value="1">{t("global.active")}</option>
                                <option value="0">{t("global.inactive")}</option>
                            </select>
                            <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                        </div>

                    )
                ) : val.status === null ? (
                    t("global.incomplete-setup")
                ) : (
                    <span
                        className={`badge-pill badge-${val.status === 1 ? "success" : "danger"
                            }`}
                    >
                        {val.status && val.status === 1
                            ? t("global.active")
                            : t("global.inactive")}
                    </span>
                );
                return {
                    no: idx,
                    group_name: val.group_name,
                    status: stVal,
                    action: actionVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("rebate-setting.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (

                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-2`}
                                    >
                                        {/* <div className='row'>  */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "rebate-setting.member-group-name"
                                                    )}
                                                </label>
                                                <StringInput
                                                    id="group_name"
                                                    name="group_name"
                                                    value={this.props.filter.group_name}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(`global.status`)}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">
                                                            {t("global.active")}
                                                        </option>
                                                        <option value="0">
                                                            {t("global.inactive")}
                                                        </option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div>  */}

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                {/* &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-success"
                                                idValue="refresh"
                                                buttonName={t("global.refresh")}
                                                clicked={() =>
                                                    this.props.refreshHandler()
                                                }
                                            /> */}
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("rebate-setting.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={
                                            this.props.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(RebateSettingListing);
