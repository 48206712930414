import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
// import i18n from '../../assets/locale/i18n';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";

import * as actions from '../../store/ducks/gameMaintenance/actions';
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: {},
            loading: true,
            errMsg: [],
            touched: false,

            vendorSlug: props.vendorSlug,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.selectId);
        formValidation('game_maintenance_form');
        if (this.props.selectId) {
            // console.log(this.props.selectId);
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newInput = {};
                for (const key in nextProps.details.data.result) {
                    newInput[key] = nextProps.details.data.result[key];
                }

                this.setState({
                    formFilter: newInput,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.updateSetting !== this.props.updateSetting && nextProps.updateSetting !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.updateSetting);
            const { t } = this.props;
            let success = (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') ? true : false;
            if (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('game_maintenance_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('show', this.props.id, this.props.vendorSlug);
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.updateSetting.message
            });
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    changeTabHandler = (e) => {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            tabId: e.target.id
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You wont be able to revert this!',
            icon: 'warning',
            confirmButtonText: 'Save',
            showDenyButton: true,
            denyButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                let data = {};
                data.id = this.state.formFilter.id;
                data.status = (this.state.formFilter.status || 0);
                data.start_time = moment(this.state.formFilter.start_time).format('YYYY-MM-DD HH:mm:ss');
                data.end_time = moment(this.state.formFilter.end_time).format('YYYY-MM-DD HH:mm:ss');
                // data.slug = this.state.formFilter.slug;
                // console.log(data);
                this.props.funcUpdateSetting(data);
            }
        });
    }

    render() {
        // console.log(this.state.formFilter);
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('show', this.props.id, this.props.vendorSlug)} />
                        <div className='my-auto'>
                            <div className='member-name'>
                                <FontAwesomeIcon icon={faDice} /> {t(`game.${this.props.gameSlug}`)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('game-maintenance.details')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='game_maintenance_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='game_maintenance_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor='start_time'>{t('game-maintenance.start-time')}</label>
                                                        <div className='input-group'>
                                                            <div className='input-group-prepend'>
                                                                <span className='input-group-text'><i className='fas fa-calendar-check'></i></span>
                                                            </div>
                                                            <DateTimePicker
                                                                id='start_time'
                                                                name='start_time'
                                                                className='form-control filter-input'
                                                                format='YYYY-MM-DD'
                                                                singleDatePicker={true}
                                                                timePicker={true}
                                                                value={(this.state.formFilter.start_time) ? moment(this.state.formFilter.start_time).format('YYYY-MM-DD h:mm A') : ''}
                                                                onChange={this.onInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor='end_time'>{t('game-maintenance.end-time')}</label>
                                                        <div className='input-group'>
                                                            <div className='input-group-prepend'>
                                                                <span className='input-group-text'><i className='fas fa-calendar-check'></i></span>
                                                            </div>
                                                            <DateTimePicker
                                                                id='end_time'
                                                                name='end_time'
                                                                className='form-control filter-input'
                                                                format='YYYY-MM-DD'
                                                                singleDatePicker={true}
                                                                timePicker={true}
                                                                value={(this.state.formFilter.end_time) ? moment(this.state.formFilter.end_time).format('YYYY-MM-DD h:mm A') : ''}
                                                                onChange={this.onInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor='status'>{t('global.status')}</label>
                                                        <div className="select-input">
                                                            <select className={`form-control datatable-select ${this.state.formFilter.status === 1 ? "datatable-select-active" : "datatable-select-inactive"}`} id='status' name='status' value={(this.state.formFilter.status || '')} onChange={(event) => this.onInputChange(event)}>
                                                                <option value='0' >{t('global.inactive')}</option>
                                                                <option value='1' >{t('global.active')}</option>
                                                            </select>
                                                            <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='col-12 form-group mb-0 justify-content-end'>
                                                    <div>
                                                        {(permission.includes('game_maintenance_update')) &&
                                                            <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.gameMaintenance.details,
        updateSetting: state.gameMaintenance.updateSetting,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdateSetting: (data) => dispatch(actions.updateSetting(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Edit));