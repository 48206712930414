import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('agent_payout/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        })
    })
    .catch(err => 
    {
    
    });
};

export const getBetDetails = (props) => dispatch =>
{
    axios.get('agent_payout/bet_details', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.BET_DETAILS,
            value: response.data
        })
    })
    .catch(function (error) 
    {
      
    });
};

export const getHistory = (props) => dispatch => 
{
    axios.get('agent_payout/payout_history', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.HISTORY,
            value: response.data
        })
    })
    .catch(err => 
    {
    
    });
};

export const store = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    axios.post('agent_payout/store', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.STORE,
            value: response.data
        })
    })
    .catch(err => 
    {
      
    });
};

export const getPayoutHistoryDetails = (props) => dispatch =>
{
    axios.get('agent_payout/payout_history_details', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.HISTORY_DETAILS,
            value: response.data
        })
    })
    .catch(function (error) 
    {
      
    });
};