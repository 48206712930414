import React, { Component } from "react";
import { connect } from "react-redux";
import { getAgentWalletBalance, storeWalletAdjustment } from "../../../store/ducks/agent/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { formValidation } from "../../../hoc/Shared/utility";
import Select from "../../../components/Input/Select/Select";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../../components/ValidationError/ValidationError";

class AgentWalletAdjustment extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            loading: true,
            edit_id: null,
            transaction_type: 0,
            amount: "",
            available_balance: 0,
            adjustment_balance: 0,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() 
    {
        formValidation("agent_wallet_adjustment_form");
        this.props.getAgentWalletBalance(this.props.agentEditId);
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        if (nextProps.agentEditId !== undefined && nextProps.agentEditId !== nextState.edit_id) 
        {
            if (nextProps.agentWalletBalanceData !== undefined && nextProps.agentWalletBalanceData !== this.props.agentWalletBalanceData) 
            {
                if (nextProps.agentWalletBalanceData.responseCode === 200) 
                {
                    this.setState({
                        loading: false,
                        edit_id: nextProps.agentEditId,
                        available_balance: nextProps.agentWalletBalanceData.data.balance,
                    }, () => 
                    {
                        this.calculateBalance();
                    });
                }
            }
            Swal.close();
        }

        if (nextProps.agentStoreAdjustmentWalletResult !== this.props.agentStoreAdjustmentWalletResult) 
        {
            if (nextProps.agentStoreAdjustmentWalletResult.responseCode === 200) 
            {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.agentStoreAdjustmentWalletResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => 
                {
                    if (result.isConfirmed) 
                    {
                        this.setState({
                            amount: "",
                            available_balance: this.state.adjustment_balance,
                        }, () => 
                        {
                            this.props.refreshHandler();
                        });
                        this.props.getAgentWalletBalance(this.props.agentEditId);
                    }
                });
            }
            else if (nextProps.agentStoreAdjustmentWalletResult.responseCode === 422) 
            {
                this.setState({
                    touched: true,
                    error_message: nextProps.agentStoreAdjustmentWalletResult.message,
                });

                Swal.close();
            }
            else 
            {
                return false;
            }
        }
        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    calculateBalance = () => {
        let amt = this.state.amount === null ? 0 : this.state.amount;
        let balc = this.state.available_balance;
        let available_balance = balc !== undefined && balc !== "" ? balc : 0;
        // var balance = Number(this.state.transaction_type) === 0 ? Number(available_balance) - Number(amt) : Number(available_balance) + Number(amt);
        var balance = (Number(this.state.transaction_type) === 1) ? (Number(available_balance)-Number(amt)) : (Number(available_balance)+Number(amt));

        // console.log("STATE amount:",this.state.amount, "\nSTATE available_balance", this.state.available_balance);
        // console.log("AVAILABLE BALANCE",available_balance,"\nBALANCE",balance);

        this.setState({
            available_balance: available_balance,
            adjustment_balance: balance,
        });
    };

    handleChange = (event) => 
    {
        this.setState({
                amount: event.target.name === "amount" ? event.target.value : this.state.amount,
                transaction_type: event.target.name === "transaction_type" ? event.target.value : this.state.transaction_type,
        }, () => {
            if (this.props.agentEditId > 0) 
            {
                this.calculateBalance();
            }
        });
    };

    updateWalletBalanceHandler = (event) => 
    {
        event.preventDefault();
        const { t } = this.props;
        if (this.state.adjustment_balance < 0) {
            Swal.fire({
                title: t("wallet-adjustment.insufficient-balance"),
                icon: "error",
                showConfirmButton: true,
            });
        } else {
            Swal.fire({
                title: t("global.confirmation"),
                text: t("global.are-you-sure"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.save"),
                cancelButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.agent_id = this.props.agentEditId;
                    data.amount = this.state.amount;
                    data.transaction_type = this.state.transaction_type;
                    this.props.storeWalletAdjustment(data);
                }
            });
        }
    };

    render() 
    {
        const { t } = this.props;
        let display = null;
        let transaction_type_option = [
            { label: t("wallet-adjustment.credit"), value: 0 },
            { label: t("wallet-adjustment.debit"), value: 1 },
        ];

        if (!this.state.loading) 
        {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("wallet-adjustment.title")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="agent_wallet_adjustment_form" className="form-horizontal" onSubmit={this.updateWalletBalanceHandler} noValidate>
                                        <ValidationError formName="agent_wallet_adjustment_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className=" col-md-6 col-12 form-group">
                                                <label htmlFor="transaction_type">{t("wallet-adjustment.transaction-type")}</label>
                                                <Select
                                                    id="transaction_type"
                                                    name="transaction_type"
                                                    options={transaction_type_option}
                                                    value={this.state.transaction_type}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="amount">{t("wallet-adjustment.amount")}</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="amount"
                                                    id="amount"
                                                    value={this.state.amount}
                                                    placeholder={t("wallet-adjustment.amount")}
                                                    onChange={this.handleChange}
                                                    onKeyDown={(event) => event.key === "e" && event.preventDefault()}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 form-group">
                                            <div className="card card-img-holder overflow-hidden sales-card bg-primary-gradient mb-0">
                                                    <div className="card-body list-icons">
                                                        <div className="float-right  mt-2">
                                                            <span className="text-primary">
                                                                <i className="si si-credit-card tx-50 text-white"></i>
                                                            </span>
                                                        </div>
                                                        <div className="float-left text-white">
                                                            <label htmlFor="available_balance">{t("wallet-adjustment.available-balance")}</label>
                                                            <h2 id="available_balance">
                                                                {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                    this.state.available_balance
                                                                )}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <div className="card card-img-holder overflow-hidden sales-card bg-danger-gradient mb-0">
                                                    <div className="card-body list-icons">
                                                        <div className="float-right  mt-2">
                                                            <span className="text-primary">
                                                                <i className="si si-credit-card tx-50 text-white"></i>
                                                            </span>
                                                        </div>
                                                        <div className="float-left text-white">
                                                            <label htmlFor="after_adjustment_balance">{t("wallet-adjustment.after-adjustment-balance")}</label>
                                                            <h2 id="after_adjustment_balance">
                                                                {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                    this.state.adjustment_balance
                                                                )}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="update_wallet_adjustment" buttonName={t("global.update")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else 
        {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("wallet-adjustment.title")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => 
{
    const { agent } = state;

    return {
        agentWalletBalanceData: agent.agent_wallet_balance_data,
        agentStoreAdjustmentWalletResult: agent.agent_store_wallet_adjustment_result,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        getAgentWalletBalance: (id) => dispatch(getAgentWalletBalance(id)),
        storeWalletAdjustment: (data) => dispatch(storeWalletAdjustment(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentWalletAdjustment));
