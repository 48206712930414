import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { showVoucher } from "../../store/ducks/voucher/actions";
import Excel from "../../assets/images/excel.svg";
import axios from "../../common/config/axios";
import { filterErrorHandler } from "../../hoc/Shared/utility";
import DataTable from "../../components/Datatable/DataTable";

class VoucherShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            voucherMemberData: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            totalRecords: 0,
            order: null,
            loading: true
        }
    }

    componentDidMount() {
        const showVoucherData = this.fetchData();
        this.props.showVoucher(showVoucherData);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.showVoucherData !== this.props.showVoucherData && nextProps.showVoucherData !== undefined) {
            if (nextProps.showVoucherData.responseCode === 200 && nextProps.showVoucherData.msgType === 'success') {
                this.setState({
                    loading: false,
                    data: nextProps.showVoucherData.data.data.row,
                    voucherMemberData: nextProps.showVoucherData.data.voucher_member,
                    totalRecords: nextProps.showVoucherData.data.data.pagination.total_record,
                    totalPages: nextProps.showVoucherData.data.data.pagination.total_page
                });
            } else {
                this.setState({
                    loading: false,
                    voucherMemberData: [],
                    data: [],
                    totalRecords: 0,
                    totalPages: 1
                }, () => 
                {
                    filterErrorHandler(nextProps.showVoucherData.message);
                });
            }
        }

        return true;
    }

    exportExcelHandler = () => {
        const data = {};
        data.id = this.props.show_voucher_id;

        axios
            .get("voucher/export_excel", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = `100Express - VoucherDetails ${timestamp}`;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - VoucherDetails Error", err);
            });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.id = this.props.show_voucher_id;
        
        return listingArr;
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.showVoucher(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.showVoucher(listingArr);
            }
        );
    };

    render() {
        const { t } = this.props;
        
        const dataTableProps = {
            paginationHandler: (val) => this.datatablesChangeHandler(val, "activePage"),
            limit: this.state.limit,
            totalItems: this.state.totalRecords,
            activePage: this.state.activePage,
            sorted: this.state.order,
            sizePerPageHandler: (val) => this.datatablesChangeHandler(val, "limit"),
            loading: this.state.loading,
            classValue:"dataTable table-bordered text-justify text-center"
        };

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("voucher.voucher")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("voucher.show-voucher")}</h4>

                                {(() => {
                                    return (
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-blue mt-2"
                                            clicked={() => this.exportExcelHandler()}
                                            img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                            buttonName={t("report.export-excel")}
                                        />
                                    );
                                })()}
                            </div>

                            <div className="card-body pt-0 mt-2">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <>
                                        {this.state.voucherMemberData.length === 0 ? (
                                            <>
                                               <div className="table-responsive table-hover datatable">
                                                    <DataTable
                                                        keyField="code"
                                                        columns={[
                                                            {
                                                                dataField: "code",
                                                                text: t("voucher.code"),
                                                                formatter: (cell, row) => `${row.code} (${t(`global.${row.status}`)})`
                                                            },
                                                            {
                                                                dataField: "claim",
                                                                text: t("voucher.status"),
                                                                formatter: (cell) => cell !== null ? cell : t("voucher.not-claimable")
                                                            },
                                                            {
                                                                dataField: "email",
                                                                text: t("voucher.email")
                                                            },
                                                            {
                                                                dataField: "order_id",
                                                                text: t("voucher.order_id")
                                                            },
                                                            {
                                                                dataField: "discount_amount",
                                                                text: t("voucher.discount_amount")
                                                            },
                                                            {
                                                                dataField: "debit",
                                                                text: t("voucher.amount")
                                                            },                                                            
                                                            {
                                                                dataField: "created_at",
                                                                text: t("voucher.date"),
                                                                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ""
                                                            }
                                                        ]}
                                                        data={this.state.data}
                                                        {...dataTableProps}                                                       
                                                    />
                                               </div>
                                            </>                                            
                                        ) : (
                                            <>
                                                <table className="table table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th>{t("voucher.code")}:</th>
                                                        </tr>

                                                        {this.state.data.map((val, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <td>
                                                                        <span>{val.code}</span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>                                            
                                        )}
                                        {this.state.voucherMemberData.length > 0 && (
                                            <>
                                                <div className="table-responsive table-hover datatable">
                                                    <DataTable
                                                        keyField="email"
                                                        columns={[                                                                                                                     
                                                            {
                                                                dataField: "email",
                                                                text: t("voucher.email")
                                                            },
                                                            {
                                                                dataField: "claim",
                                                                text: t("voucher.status"),
                                                                formatter: (cell) => cell === true ? t("voucher.claimable") : t("voucher.not-claimable")
                                                            },
                                                            {
                                                                dataField: "order_id",
                                                                text: t("voucher.order_id")
                                                            },
                                                            {
                                                                dataField: "discount_amount",
                                                                text: t("voucher.discount_amount")
                                                            },
                                                            {
                                                                dataField: "debit",
                                                                text: t("voucher.amount")
                                                            },                                                           
                                                            {
                                                                dataField: "created_at",
                                                                text: t("voucher.date"),
                                                                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ""
                                                            }
                                                        ]}
                                                        data={this.state.voucherMemberData}
                                                        {...dataTableProps}                                                       
                                                    />
                                                </div>
                                            </>                                            
                                        )}                                                                                                                        
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    //Getting the state from the reducers
    return {
        showVoucherData: state.voucher.showVoucherData,
    };
};

const mapDispatchToProps = {
    showVoucher,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VoucherShow));
