import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('agent_deposit/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (props) => dispatch =>
{   
    axios.get('agent_deposit/details',{params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DETAILS,
        value: response.data
      })
    })
    .catch(function (error) 
    {
      
    });
};

export const updateTran = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    // console.log(data);
    axios.post('agent_deposit/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};