import React, { Component } from "react";
import { connect } from "react-redux";
import { dynamicSEO } from "../../../../store/ducks/seo/actions";
import { Helmet } from "react-helmet";

// In componentDidMount() call the SEO function, which also check URL is have match the gs_seo_meta_tags table's url column or not,
// If match, pass data to SEO component.
// Else, use default meta tags set by developer.

let default_tag, meta_tag, result;

class DynamicSEO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.dynamicSEO();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.seoData !== this.props.seoData) {
            if (nextProps.seoData.responseCode === 200) {
                this.setState({ loading: false });
            }
        }
        return true;
    }

    render() {
        if (this.state.loading) {
            default_tag = (
                <Helmet>
                    <title>{this.props.pageSEO.title}</title>
                    <meta name="description" content={this.props.pageSEO.description} />
                    <meta name="keywords" content={this.props.pageSEO.keywords} />
                    <meta name="robots" content={this.props.pageSEO.robots} />
                </Helmet>
            );
        } else {
            const seo = this.props.seoData.data;
            // console.log(seo);
            // If meta_tag null mean gs_seo_meta_tag does not match the URL and language ID.
            if (seo.meta_tag !== null) {
                meta_tag = (
                    <Helmet>
                        {seo.meta_tag.title && <title>{seo.meta_tag.title}</title>}
                        {seo.meta_tag.description && <meta name="description" content={seo.meta_tag.description} />}
                        {seo.meta_tag.keywords && <meta name="keywords" content={seo.meta_tag.keywords} data-react-helmet="true" />}
                        {seo.seo_robot && <meta name="robots" content={seo.seo_robot.split(" ").join(", ")} />}
                    </Helmet>
                );
            }
        }
        result = [
            <div key="dynamic_seo">
                {default_tag} {meta_tag}
            </div>,
        ];
        // result = [<div key="d">{default_tag}</div>, <div key="m">{meta_tag}</div>, <div key="w">{web_master}</div>, <div key="o">{open_graph}</div>];
        return result;
    }
}

const mapStateToProps = (state) => {
    return {
        seoData: state.seo.dynamic_seo_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dynamicSEO: () => dispatch(dynamicSEO()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicSEO);
