import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('parcel/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('parcel/drop_down')
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.DROPDOWN,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const getDetails = (id) => dispatch =>
{
    axios.get('parcel/details',{params: {
        "id": id,
    }})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.DETAILS,
            value: response.data
        })
    })
    .catch(function (error) 
    {
      
    });
};

export const update = (data) => dispatch =>
{
    axios.post('parcel/update', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.UPDATE,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};
