import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Skeleton from "react-loading-skeleton";
import UpdownArrow from "../../assets/images/updown-arrow.png";
import StringInput from "../../components/Input/StringInput/StringInput";

class ItemCategoryListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCard: false,
		};
	}
	
	toggleCard = () => {
		this.setState({
			showCard: !this.state.showCard,
		});
	};
	
	render() {
		const { t } = this.props;
		
		return (
			<Auxiliary>
				<div className="row row-sm">
					<div className="breadcrumb-header justify-content-between">
						<div className="my-auto">
							<div className="d-flex">
								<h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
									{t("item-category.item-category-list")}
								</h4>
							</div>
						</div>
					</div>
					<div className="col-xl-12">
						<div className="card card-body card-primary">
							<div>
								{this.props.loading ? (
									<Skeleton count={2} />
								) : (
									<div className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
										<div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("item-category.item-name")}
                                                </label>
                                                <StringInput
                                                    id="item_name"
                                                    name="item_name"
                                                    value={this.props.filter.item_name}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

										<div className="col-md-3 col-6">
											<div className="form-group">
												<label className="font-weight-bolder">
													{t("item-category.item-type")}
												</label>
												<div className="select-input">
													<select
														className="form-control filter-input"
														id="item_type"
														name="item_type"
														onChange={this.props.onInputChange}
														value={this.props.filter.item_type}
                          							>
														<option value=""></option>
														{this.props.ddItemType && this.props.ddItemType.map((val, idx) => {
															return (
																<option key={idx} value={val.vendor_id}>
																	{t(`item-category.${val.slug}`)}
																</option>
															);
														})}
													</select>
													<img
														className="updown-icon"
														src={UpdownArrow}
														alt="Up Down Arrow"
													/>
												</div>
											</div>
										</div>
										
                    					<div className="col mt-auto">
                      						<div className="d">
												<Button
													typeButton="button"
													classValue="btn btn-blue"
													idValue="filter"
													buttonName={t("global.filter")}
													clicked={() => this.props.refreshHandler()}
												/>
                        						&nbsp;
												<Button
													typeButton="button"
													classValue="btn btn-secondary"
													idValue="reset"
													buttonName={t("global.reset")}
													clicked={() => this.props.clearHandler()}
												/>
											</div>
										</div>
									</div>
								)}
              				</div>
              				<div className="show-btn" onClick={() => this.toggleCard()}>
                				{this.state.showCard ? <AiFillMinusCircle /> : ( <AiFillPlusCircle />)}
							</div>
						</div>
					</div>

					<div className="col-xl-12">
						<div className="card">
							<div className="d-flex align-items-end">
								<div className="card-header pb-0 ps-0">
									<div className="d-flex justify-content-between">
										<h4 className="card-title mg-b-0">
											{t("item-category.item-category-listing")}
										</h4>
									</div>
								</div>

							<div className="d-flex">
									<div>
										<div className="btn-group">
											{this.props.permission.includes("item_category_create") && (
												<>
													<AiFillPlusCircle />
													<Button
														typeButton="button"
														classValue="btn btn-underline"
														idValue="btnCreateShipment"
														buttonName={t("item-category.add-item-category")}
														clicked={() => this.props.changePageHandler("create")}
													/>
												</>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="card-body">
								<div className="table-responsive table-hover datatable">
									<DataTable
										keyField="parcelCategoryId"
										data={this.props.dataListing}
										columns={this.props.columns}
										activePage={this.props.activePage}
										limit={this.props.limit}
										totalItems={this.props.totalRecords}
										paginationHandler={(val) =>
											this.props.datatablesChangeHandler(val, "activePage")
										}
										sizePerPageHandler={(val) =>
											this.props.datatablesChangeHandler(val, "limit")
										}
										classValue="dataTable table-bordered text-justify text-center"
										loading={this.props.loading}
										hideSearchBar={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Auxiliary>
		);
  	}
}

export default withNamespaces("translation")(ItemCategoryListing);
