import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import Select from "../../components/Input/Select/Select";

import { postBank } from "../../store/ducks/banks/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import { withNamespaces } from "react-i18next";
class BanksCreate extends Component {
    constructor(props) {
        super(props);
        //var dt = new Date();
        this.state = {
            bank_name: "",
            swift_code: "",
            error_message: [],
            maintenance_start_time: "",
            maintenance_end_time: "",
            currentMaintenanceStatus: 0,
            setMaintenanceTime: false,
            touched: false,
            currentStatus: 1,
        };
    }

    componentDidMount() {
        formValidation("bank_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_bank_data !== this.props.post_bank_data) {
            if (nextProps.post_bank_data.responseCode === 200) {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_bank_data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.post_bank_data.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    getBankNameHandler = (event) => {
        this.setState({ bank_name: event.target.value });
    };

    getSwiftCodeHandler = (event) => {
        this.setState({ swift_code: event.target.value });
    };

    getMaintenanceStartTime = (date) => {
        this.setState({
            maintenance_start_time: date,
        });
    };

    getMaintenanceEndTime = (date) => {
        this.setState({
            maintenance_end_time: date,
        });
    };

    handleSetMaintenanceTime = (event) => {
        this.setState({
            setMaintenanceTime: true,
        });
    };

    changeMaintenanceStatusHandler = (event) => {
        this.setState({
            currentMaintenanceStatus: event.target.value,
        });
    };

    changeStatusHandler = (event) => {
        this.setState({
            currentStatus: event.target.value
        })
    };

    postBankHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};

                if (this.state.maintenance_start_time !== "" || this.state.maintenance_end_time !== "") {
                    let start_time = moment(this.state.maintenance_start_time).format("yyyy-MM-DD HH:mm");
                    let end_time = moment(this.state.maintenance_end_time).format("yyyy-MM-DD HH:mm");

                    data.maintenance_start_time = start_time;
                    data.maintenance_end_time = end_time;
                }

                data.bank_name = this.state.bank_name;
                data.swift_code = this.state.swift_code;
                data.country_id = this.props.currentCountryId;
                data.maintenance_status = this.state.currentMaintenanceStatus;
                data.status = this.state.currentStatus;

                this.props.postBank(data);
            }
        });
    };

    render() {
        const { t } = this.props;

        let maintenanceStatusArry = [
            {
                label: t("global.inactive"),
                value: 0,
                selected: true
            },
            {
                label: t("global.active"),
                value: 1,
            },
        ]

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("bank.banks")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("bank.add-banks")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="bank_form" className="form-horizontal" onSubmit={this.postBankHandler} noValidate>
                                    <ValidationError formName="bank_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        {/* <div className="col-md-12"> */}
                                        <div className="col-md-6 col-12 form-group">
                                            <label htmlFor="bank_name">{t("bank.bank_name")}</label>

                                            <StringInput
                                                onChange={this.getBankNameHandler}
                                                id="bank_name"
                                                name="bank_name"
                                                className="form-control"
                                                placeholder={t("bank.bank_name")}
                                                value={this.state.bank_name}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label htmlFor="swift_code">{t("bank.swift_code")}</label>

                                            <StringInput
                                                onChange={this.getSwiftCodeHandler}
                                                id="swift_code"
                                                name="swift_code"
                                                className="form-control"
                                                placeholder={t("bank.swift_code")}
                                                value={this.state.swift_code}
                                                required
                                            />
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label>{t("bank.status")}</label>
                                            <Select
                                                id="status"
                                                name="status"
                                                className="form-control"
                                                options={statusArry}
                                                value={this.state.currentStatus}
                                                onChange={this.changeStatusHandler}
                                            />
                                        </div>


                                        <div className="col-md-6 col-12 form-group">
                                            <label>{t("bank.maintenanceStatus")}</label>
                                            <Select
                                                id="maintenanceStatus"
                                                name="maintenanceStatus"
                                                className="form-control"
                                                options={maintenanceStatusArry}
                                                value={this.state.currentMaintenanceStatus}
                                                onChange={this.changeMaintenanceStatusHandler}
                                            />
                                        </div>
                                        {this.state.setMaintenanceTime && (
                                            <div className="col-12 col-md-6 form-group">
                                                <label htmlFor="maintenance_time">{t("bank.set-maintenance-time")}</label>
                                                <br />
                                                <div className="col row align-items-center bank__datePicker p-0 m-0">
                                                    <DatePicker
                                                        name="maintenance_start_time"
                                                        className="form-control"
                                                        wrapperClassName="col wrapperDatePicker pl-0"
                                                        selected={this.state.maintenance_start_time}
                                                        onChange={this.getMaintenanceStartTime}
                                                        minDate={new Date()}
                                                        showTimeSelect
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption={t("global.time")}
                                                        popperContainer={DatePickerContainer}
                                                    />

                                                    <span className="p-2"> - </span>

                                                    <DatePicker
                                                        name="maintenance_end_time"
                                                        className="form-control"
                                                        wrapperClassName="col wrapperDatePicker pr-0"
                                                        selected={this.state.maintenance_end_time}
                                                        onChange={this.getMaintenanceEndTime}
                                                        minDate={new Date()}
                                                        showTimeSelect
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption={t("global.time")}
                                                        popperContainer={DatePickerContainer}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            {this.state.setMaintenanceTime === false && (
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="setMaintenanceTime"
                                                    buttonName={t("bank.set-maintenance-time")}
                                                    clicked={this.handleSetMaintenanceTime}
                                                />
                                            )}
                                            &nbsp;
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>


                                        {/* </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { bank, currentUser } = state;

    //Getting the state from the reducers
    return {
        post_bank_data: bank.post_bank_result,
        currentCountryId: currentUser.data.country_id,
        currentUsername: currentUser.data.username,
    };
};

const mapDispatchToProps = {
    postBank,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BanksCreate));
