import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { AiFillPlusCircle } from "react-icons/ai";

import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class LuckySpinSettingListing extends Component 
{
    /* ============ component lifecycle [START] ============ */
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            isOpen: false,
            currentImage: null,
        };
    }
    /* ============ component lifecycle [END] ============ */

    render() 
    {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: t('lucky-spin-setting.title'), 
                dataField: "title", 
                sort: true, 
                onSort: this.onSort
            },
            {
                text: t('lucky-spin-setting.setting-value'),
                dataField: "data", 
                formatter: (cell, row) => 
                {
                    let settingValue = JSON.parse(row.data);
                    let settingValueArr = [];

                    Object.keys(settingValue).map((item, i) => 
                    {
                        settingValueArr.push(
                            <><span className="badge badge-primary mr-2 mb-1 mt-1">{item} : {settingValue[item]}</span><br/></>
                        );
                        return null;
                    });
                    return settingValueArr;
                },
                headerStyle: { width: '250px' }
            },
            {
                text: t('global.action'),
                // dataField: "#",
                keyField: "action",
                formatter: (cell, row) => 
                {
                    let actionVal =
                    
                    permission.includes("lucky_spin_setting_update") ? (
                        <div className="btn-group">
                            {permission.includes("lucky_spin_setting_update") && (
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,"edit",row.id)}
                                    className="btn btn-sm btn-white rounded"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                            )}
                        </div>
                    ) : (
                        ""
                    );
                    return actionVal;
                },
                headerStyle: () => {
                    return {width: '115px'};
                }
            },
        ];
        /* ======================== datatables data assign [END] ======================== */
        
        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("side-bar.lucky_spin_setting")}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">{t("lucky-spin-setting.listing")}</h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            <AiFillPlusCircle />
                                            <Button
                                                typeButton="button"
                                                idValue="system_settings"
                                                classValue="btn btn-underline"
                                                clicked={() => this.props.changePageHandler("create")}
                                                buttonName={t("lucky-spin-setting.add-setting")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val,"activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val,"limit")}
                                        searchChangeHandler={this.props.onInputChange}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        searchPressSubmitHandler={this.props.searchKeyPressHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(LuckySpinSettingListing);
