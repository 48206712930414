import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import MemberPromotionCancel from './MemberPromotionCancel';
import MemberPromotionApply from './MemberPromotionApply';
import TransactionDetails from '../Transaction/TransactionDetails';
import MemberPromotionCancelDetail from './MemberPromotionCancelDetail';

import * as actions from '../../store/ducks/memberPromotion/actions';

const initialInputState =
{
    /* form input value */
    username: '',
    status: '',
};

class MemberPromotion extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'promotion_cancel',

            /* datatables / pagination */
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                transactionType: [],
            },

            /* details id */
            selectId: null,

            /* skeleton loading */
            loading: true,
            promoDetailsParam: {}
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
        // this.props.funcGetDropDown(); // get filter drop down
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) 
        {
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                    this.changePageHandler('promotion_cancel');
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.update.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        if(event.target.id==='search')
        {
            this.setState({
                searchValue: event.target.value
            });
        }
        else
        {
            let newFilter = Object.assign({}, this.state.filter);
            newFilter[event.target.id] = event.target.value;
            this.setState({
                filter: newFilter
            });
        }
    }

    searchKeyPressHandler = (event) =>
    {
        //Checking press the enter only perform search action
        if(event.key === 'Enter')
        {
            this.refreshHandler();
        }
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            // limit: 10,
            searchValue: '',
            // order: [{'id':'id', 'desc':'asc'}],
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, ()=> 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    detailsHandler = (e, id) =>
    {
        // console.log('detailsHandler (transaction): ', id);
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            errMsg: [],
            touched: false,
        });
    }

    promoDetailsHandler = (e, id, order_id, promo_order_id, user_id, promo_title) => {
        e.preventDefault();
        this.setState({
            pageShow: 'promoDetails',
            promoDetailsParam: {
                id: id,
                order_id: order_id,
                promo_order_id: promo_order_id,
                user_id: user_id,
                promo_title: promo_title
            },
            returnPage: 'promotion_cancel'
        });
    }

    cancelHandler = (e, id) =>
    {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You wont be able to revert this!',
            icon: 'warning',
            confirmButtonText: 'Save',
            showDenyButton: true,
            denyButtonText: 'Cancel',
        }).then((result) =>
        {
            if (result.isConfirmed)
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                this.loadingHandler();
                let data = {};
                data.id = id;
                this.props.funcUpdate(data);
            }
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => 
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    changeTabHandler = (e) =>
    {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            pageShow: e.target.id
        });
    }

    render()
    {
        const { t } = this.props;
        let tabId = this.state.pageShow;

        return (
            <Auxiliary>
                {(this.state.pageShow==='promotion_cancel' || this.state.pageShow==='promotion_apply') &&
                <>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='panel panel-primary tabs-style-2 mt-3'>
                                <div className='tab-menu-heading'>
                                    <div className='tabs-menu1'>
                                        <ul className='nav panel-tabs main-nav-line'>
                                            <li><a href='/#' id='promotion_cancel' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'promotion_cancel') ? 'active' : ''}`}>{t('member-promotion.to-cancel-promotion')}</a></li>
                                            {/* <li><a href='/#' id='promotion_apply' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'promotion_apply') ? 'active' : ''}`}>{t('member-promotion.to-apply-promotion')}</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </>}

                {this.state.pageShow==='promotion_cancel' &&
                    <MemberPromotionCancel
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        promoDetailsHandler={(event, id, order_id, promo_order_id, user_id, promo_title) => this.promoDetailsHandler(event, id, order_id, promo_order_id, user_id, promo_title)}
                        changePageHandler={(event, page, id) => this.changePageHandler(event, page, id)}
                        onSort={(field, order) => this.onSort(field, order)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}
                        cancelHandler={(event, id) => this.cancelHandler(event, id)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        ddTransactionType={this.state.dropDown.transactionType}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='promotion_cancel'
                    />
                }

                {this.state.pageShow==='promotion_apply' &&
                    <MemberPromotionApply />
                }

                {this.state.pageShow==='promoDetails' && 
                    <MemberPromotionCancelDetail 
                        promoDetailsParam={this.state.promoDetailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        cancelHandler={(event, id) => this.cancelHandler(event, id)}
                        returnPage='promotion_cancel'

                        currentUser={this.props.currentUser}
                    />
                }

                {/* {this.state.pageShow==='create' &&
                    <RejectReasonCreate
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <RejectReasonEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                } */}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.memberPromotion.rs,
        update: state.memberPromotion.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(MemberPromotion));