import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';

import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";

import * as actions from '../../store/ducks/gameMaintenance/actions';

const initialInputState =
{
    /* form input value */
    // vendor: '',
    title: '',
    status: '',
};

class Show extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'show',

            /* datatables / pagination */
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],

            /* details id */
            // selectId: null,

            /* skeleton loading */
            loading: true,

            permission: [],
            vendorSlug: props.vendorSlug,
            showCard: false
        }
        // this.onInputChange = this.onInputChange.bind(this);
        // this.updateStatusHandler = this.updateStatusHandler.bind(this);
        // this.state = {
        //     showCard: false,
        // };
        // console.log('constructor (listing):', this.state);
    }


    componentDidMount() {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetGame(listingArr); // get datatables result
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        // console.log(nextProps.show.data.result.pagination.total_record);

        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let gameCategoryPermission = this.props.currentPermission.filter((item) => item.includes("game_vendor_"));
            // console.log(gameCategoryPermission);
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.show !== this.props.show && nextProps.show !== undefined) {
            if (nextProps.show.responseCode === 200 && nextProps.show.msgType === 'success') {
                this.setState({
                    data: nextProps.show.data.result.row,
                    totalRecords: nextProps.show.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else {
                let msg = null;
                msg = '<ul>';
                nextProps.show.message.forEach((val) => {
                    msg += '<li>' + val + '</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        if (nextProps.updateStatus !== this.props.updateStatus && nextProps.updateStatus !== undefined) {
            if (nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    this.refreshHandler();
                });
            }
            else {
                let msg = null;
                msg = '<ul>';
                nextProps.updateStatus.message.forEach((val) => {
                    msg += '<li>' + val + '</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    getFilterArray = () => {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        listingArr.slug = this.state.vendorSlug; // To show selected Vendor data only

        return listingArr;
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetGame(listingArr);
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            //(this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
            (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    updateStatusHandler = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let data = {};
        data.id = event.target.id;
        data.status = event.target.value;

        // console.log(data);
        this.props.funcUpdateStatus(data);
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    searchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === 'Enter') {
            this.refreshHandler();
        }
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetGame(listingArr);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.funcGetGame(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = this.props.currentUser.permission
            ? this.props.currentUser.permission
            : [];

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("game-maintenance.game-name"),
                dataField: "slug",
                sort: true,
                onSort: this.onSort,
            },
            { text: t("global.status"), dataField: "status", headerStyle: { width: "115px" }, },
            {
                text: t("game-maintenance.start-time"),
                dataField: "start_time",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("game-maintenance.end-time"),
                dataField: "end_time",
                sort: true,
                onSort: this.onSort,
            },
            { text: t("global.action"), dataField: "action", headerStyle: { width: "65px" }, },
        ];
        if (!permission.includes("game_maintenance_update")) {
            columns.splice(5, 1);
        }

        if (this.state.data) {
            rsListing = this.state.data.map((val, idx) => {
                let stCls = val.status === 1 ? "active" : "inactive";
                let actionVal = permission.includes(
                    "game_maintenance_update"
                ) ? (
                    <a
                        href="/#"
                        onClick={(event) =>
                            this.props.detailsHandler(event, "edit", val.id, val.slug, this.props.vendorSlug)
                        }
                        className="btn btn-sm btn-white"
                        data-toggle="tooltip"
                        title="edit"
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </a>
                ) : (
                    ""
                );
                let stVal = permission.includes(
                    "game_maintenance_update_status"
                ) ? (
                    val.status === null ? (
                        t("global.incomplete-setup")
                    ) : (
                        <div className={`select-input datatable-select-${stCls}`}>
                            <select
                                className={`form-control datatable-select datatable-select-${stCls}`}
                                id={val.id}
                                name={val.id}
                                onChange={(event) =>
                                    this.updateStatusHandler(event)
                                }
                                value={val.status === null ? 0 : val.status}
                            >
                                <option value="1">{t("global.active")}</option>
                                <option value="0">{t("global.inactive")}</option>
                            </select>
                            <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down Arrow" />
                        </div>

                    )
                ) : val.status === null ? (
                    t("global.incomplete-setup")
                ) : (
                    <span
                        className={`badge-pill badge-${val.status === 1 ? "success" : "danger"
                            }`}
                    >
                        {val.status && val.status === 1
                            ? t("global.active")
                            : t("global.inactive")}
                    </span>
                );
                return {
                    no: idx,
                    slug: t(`game.${val.slug}`),
                    status: stVal,
                    start_time: val.start_time,
                    end_time: val.end_time,
                    action: actionVal,
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    {/* <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("game-maintenance.title")}
                            </h4>
                        </div>
                    </div> */}

                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <Button
                                    typeButton="button"
                                    classValue="btn btn-back-outline"
                                    idValue="back"
                                    buttonName={<i className="angle fe fe-chevron-left"></i>}
                                    clicked={() => this.props.changePageHandler("listing", null, null)}
                                />
                                <div>
                                    <div className="member-name">
                                        <FontAwesomeIcon icon={faDice} /> {t(`vendor.${this.props.vendorSlug}`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("game-maintenance.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.state.activePage}
                                        limit={this.state.limit}
                                        totalItems={this.state.totalRecords}
                                        paginationHandler={(val) =>
                                            this.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        // searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, 'searchValue')}
                                        searchChangeHandler={
                                            this.props.onInputChange
                                        }
                                        searchValue={this.state.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.state.loading}
                                        searchPressSubmitHandler={
                                            this.searchKeyPressHandler
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        show: state.gameMaintenance.show,
        updateStatus: state.gameMaintenance.updateStatus,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetGame: (filter) => dispatch(actions.getGame(filter)),
        funcUpdateStatus: (data) => dispatch(actions.updateStatus(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Show));