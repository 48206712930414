import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const memberReducer = createReducer(initialState) ({
    [type.MEMBER_LIST]: (state, action) => ({
        ...state,
        member_data: action.payload
    }),

    [type.MEMBER_GET_FILTER_DATA]: (state, action) => ({
        ...state,
        member_filter_data: action.payload
    }),

    // Member Show
    [type.MEMBER_SHOW]: (state, action) => ({
        ...state,
        member_show_data: action.payload
    }),

    [type.MEMBER_TRANSACTION_HISTORY]: (state, action) => ({
        ...state,
        member_transaction_history_data: action.payload
    }),

    [type.MEMBER_TRANSACTION_HISTORY_FILTER_DATA]: (state, action) => ({
        ...state,
        member_transaction_history_filter_data: action.payload
    }),

    [type.MEMBER_BETTING_HISTORY]: (state, action) => ({
        ...state,
        member_betting_history_data: action.payload
    }),

    [type.MEMBER_BETTING_HISTORY_FILTER_DATA]: (state, action) => ({
        ...state,
        member_betting_history_filter_data: action.payload
    }),

    [type.MEMBER_LOGIN_INFO]: (state, action) => ({
        ...state,
        member_login_info_data: action.payload
    }),

    [type.MEMBER_DEPOSIT_CRYPTO]: (state, action) => ({
        ...state,
        member_deposit_crypto_data: action.payload
    }),

    [type.MEMBER_WITHDRAWAL_CRYPTO]: (state, action) => ({
        ...state,
        member_withdrawal_crypto_data: action.payload
    }),

    [type.MEMBER_LEVEL_TRACKER]: (state, action) => ({
        ...state,
        member_level_tracker_data: action.payload
    }),

    [type.MEMBER_LEVEL_TRACKER_HISTORY]: (state, action) => ({
        ...state,
        member_level_tracker_history_data: action.payload
    }),
    [type.MEMBER_WALLET_TRACKER]: (state, action) => ({
        ...state,
        member_wallet_tracker_data: action.payload
    }),
    [type.MEMBER_COIN_TRACKER]: (state, action) => ({
        ...state,
        member_coin_tracker_data: action.payload
    }),
    [type.MEMBER_ADDRESS]: (state, action) => ({
        ...state,
        member_address_data: action.payload
    }),
    [type.MEMBER_PARCEL]: (state, action) => ({
        ...state,
        member_parcel_data: action.payload
    }),
    [type.MEMBER_BILLING_ADDRESS]: (state, action) => ({
        ...state,
        member_billing_address_data: action.payload
    }),

    // Member View Loading
    [type.INIT_MEMBER_SHOW_INITIAL_LOADING]: (state, action) => ({
        ...state,
        member_show_initial_loading: action.payload
    }),
    [type.SET_MEMBER_SHOW_INITIAL_LOADING]: (state, action) => ({
        ...state,
        member_show_initial_loading: [...state.member_show_initial_loading.map((item) => (item.id === action.page) ? {...item, loading: false} : item)]
        // member_show_initial_loading: {
        //     ...state.member_show_initial_loading,
        //     member_show_initial_loading: [...state.member_show_initial_loading.map((item) => (item.id === action.page) ? {...item, loading: false} : item)],
        // }
    }),

    // Member View Local State
    [type.SET_MEMBER_INFO_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_info_local_state: action.payload
    }),
    [type.SET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_transaction_history_local_state: action.payload
    }),
    [type.SET_MEMBER_BETTING_HISTORY_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_betting_history_local_state: action.payload
    }),
    [type.SET_MEMBER_LOGIN_INFO_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_login_info_local_state: action.payload
    }),
    [type.SET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_crypto_address_local_state: action.payload
    }),
    [type.SET_MEMBER_LEVEL_TRACKER_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_level_tracker_local_state: action.payload
    }),
    [type.SET_MEMBER_WALLET_TRACKER_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_wallet_tracker_local_state: action.payload
    }),
    [type.SET_MEMBER_COIN_TRACKER_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_coin_tracker_local_state: action.payload
    }),
    [type.SET_MEMBER_ADDRESS_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_address_local_state: action.payload
    }),
    [type.SET_MEMBER_PARCEL_LOCAL_STATE]: (state, action) => ({
        ...state,
        member_parcel_local_state: action.payload
    }),

    // Member Store
    [type.MEMBER_CREATE]: (state, action) => ({
        ...state,
        member_create_data: action.payload
    }),
    [type.MEMBER_STORE]: (state, action) => ({
        ...state,
        member_store_result: action.payload,
    }),

    // Member Edit
    [type.MEMBER_EDIT]: (state, action) => ({
        ...state,
        member_edit_data: action.payload
    }),

    [type.MEMBER_EDIT_ACCOUNT_DETAILS]: (state, action) => ({
        ...state,
        member_edit_account_data: action.payload
    }),

    [type.MEMBER_EDIT_BANK_DETAILS]: (state, action) => ({
        ...state,
        member_edit_bank_data: action.payload
    }),

    [type.MEMBER_WALLET_BALANCE]: (state, action) => ({
        ...state,
        member_wallet_balance_data: action.payload
    }),

    [type.MEMBER_UPDATE_ACCOUNT_DETAILS]: (state, action) => ({
        ...state,
        member_update_account_result: action.payload,
    }),

    [type.MEMBER_ADD_BANK_DETAILS]: (state, action) => ({
        ...state,
        member_add_bank_result: action.payload,
    }),

    [type.MEMBER_UPDATE_MEMBER_BANK_STATUS]: (state, action) => ({
        ...state,
        member_update_bank_status_result: action.payload,
    }),

    // [type.MEMBER_DELETE_BANK_DETAILS]: (state, action) => ({
    //     ...state,
    //     member_delete_bank_result: action.payload,
    // }),

    [type.MEMBER_STORE_WALLET_ADJUSTMENT]: (state, action) => ({
        ...state,
        member_store_wallet_adjustment_result: action.payload,
    }),

    [type.MEMBER_UPDATE_RESET_PASSWORD]: (state, action) => ({
        ...state,
        member_update_reset_password_result: action.payload,
    }),

    [type.CLEAR_MEMBER_STATE]: (state, action) => ({
        ...initialState
    }),

    [type.MEMBER_GAME_ACCESS]: (state, action) => ({
        ...state,
        member_game_access: action.payload
    }),

    [type.MEMBER_UPDATE_GAME_ACCESS]: (state, action) => ({
        ...state,
        member_update_game_access: action.payload
    })
});

export default memberReducer;