export const MEMBER_REFERRAL_LISTING = "MEMBER_REFERRAL_LISTING";
export const MEMBER_REFERRAL_EDIT = "MEMBER_REFERRAL_EDIT";
export const MEMBER_REFERRAL_PENDING = "MEMBER_REFERRAL_PENDING";
export const MEMBER_REFERRAL_SHOW = "MEMBER_REFERRAL_SHOW";

export const REFERRAL_HISTORY_LISTING = "REFERRAL_HISTORY_LISTING";

export const MEMBER_REFERRAL_PAYOUT = "MEMBER_REFERRAL_PAYOUT";
export const MEMBER_REFERRAL_PAYOUT_EDIT = "MEMBER_REFERRAL_PAYOUT_EDIT";
export const MEMBER_REFERRAL_PAYOUT_DETAIL = "MEMBER_REFERRAL_PAYOUT_DETAIL";
export const MEMBER_REFERRAL_PAYOUT_HISTORY = "MEMBER_REFERRAL_PAYOUT_HISTORY";