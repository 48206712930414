import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withNamespaces } from 'react-i18next';

import ActivityLogListing from "./ActivityLogListing";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

import {getLogs} from "../../../store/ducks/log/actions";

class ActivityLog extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',

        data: [],

        currentBankId: '',
        loading: true
    }

    fetchData() {
        const logListingData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        logListingData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        logListingData.limit = this.state.limit;
        logListingData.search = this.state.searchValue;
        logListingData.order = JSON.stringify(this.state.sorted);
        logListingData.country_id = this.props.currentCountryId;

        this.props.getLogs(logListingData);
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.log_data !== this.props.log_data) {
            this.setState({
                loading: false,
                data: nextProps.log_data.data.data,
                totalRecords: nextProps.log_data.data.totalRecords,
                totalPages: nextProps.log_data.data.totalPages,
            })
        }

        return true;
    }

    refreshHandler = () =>
    {
        this.fetchData();
    }

    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    searchChangeHandler = (event) => {
        this.setState({searchValue: event.target.value});
    }

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
            this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if(event.key === "Enter"){
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            })
        }
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true
        } else {
            orderSequence = false
        }

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({sorted: orderArry, loading: true, data: []}, () => {
            this.fetchData();
        })
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            {
                text: "Log Id",
                dataField: "logId",
                keyField: "id",
                hidden: true
            },
            {
                text: t('log.date'),
                dataField: "createdTime",
                keyField: "createdTime",
                sort: true,
                onSort: this.onSort,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.username'),
                dataField: "userId",
                keyField: "userId",
                sort: true,
                onSort: this.onSort,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.ip'),
                dataField: "ip",
                keyField: "ip",
                sort: true,
                onSort: this.onSort,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.modules'),
                dataField: "logName",
                keyField: "logName",
                sort: true,
                onSort: this.onSort,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.models'),
                dataField: "models",
                keyField: "models",
                sort: true,
                onSort: this.onSort,
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.description'),
                dataField: "description",
                keyField: "description",
                headerStyle: () => {
                    return {width: '100px'};
                }
            },
            {
                text: t('log.attributes'),
                dataField: "properties",
                keyField: "properties"
            }
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('log.log')}</h4>
                            </div>
                        </div>
                    </div>

                    <ActivityLogListing
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        refreshHandler={() => this.refreshHandler()}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                    />
                </div>
                }

            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const {log, currentUser} = state;

    //Get the state from reducers current limit, current page and search value
    return {
        log_data: log.log_data,
        currentCountryId: currentUser.data.country_id
    }
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getLogs
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(ActivityLog));