import React, { Component } from 'react';
import jwt from "jsonwebtoken";

// import { text } from "../public-key";
import { Redirect } from "react-router-dom";

const authPublicPermission = (ComposedComponent) => {
    class AuthenticationPublic extends Component {
        state = {
            isAuthenticated: false,
            isLoading: false
        }

        checkAuth = () => {
            let publicKey = process.env[`REACT_APP_${process.env.REACT_APP_ENV.toUpperCase()}_PUBLIC_KEY`];
            return jwt.verify(localStorage.getItem("accessToken"), publicKey, {algorithm: 'RS256'}, function (err, decoded_token) {
                console.log(err);
                if (err === null) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        UNSAFE_componentWillMount() {
            if(localStorage.getItem("accessToken") != null) {
                if(this.checkAuth()) {
                    this.setState({isAuthenticated: true});
                }else{
                    this.setState({isAuthenticated: false});
                }
            }else{
                this.setState({isAuthenticated: false});
            }
        }

        componentDidMount() {
            if(!this.state.isAuthenticated) {
                localStorage.clear();
            }
        }

        render() {
            return (this.state.isAuthenticated === false) ? <ComposedComponent {...this.props}/> : <Redirect to='/home' />;
        }
    }

    return AuthenticationPublic;
}

export default authPublicPermission;