import React from "react";
import PropTypes from "prop-types";
import dompurify from 'dompurify';

const DomPurify = (props) => {
    let result;
    const sanitizer = dompurify.sanitize;

    if (props.text) {
        result = (
            <div dangerouslySetInnerHTML={{__html: sanitizer(props.text)}} />
        )
    } else {
        result = null;
    }
    return result;
};

export default DomPurify;

DomPurify.propTypes = {
    text: PropTypes.string,
};
