import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const upLevelBonusReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.MEMBER_LEVEL_TRACKER_LIST]: (state, action) => ({
        ...state,
        trackerLeveListing: action.value,
    }),
});

export default upLevelBonusReducer;