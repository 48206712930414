import React, { Component } from "react";
import { connect } from "react-redux";
import { getMetaTags, updateMetaTagStatus, deleteMetaTag } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import MetaTagListing from "./MetaTagListing";
import MetaTagCreate from "./MetaTagCreate";
import MetaTagShow from "./MetaTagShow";
import MetaTagEdit from "./MetaTagEdit";
import Swal from "sweetalert2";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

class MetaTag extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            metaTagShowId: null,
            metaTagEditId: null,
            loading: true,
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getMetaTags(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.metaTagData !== this.props.metaTagData && nextProps.metaTagData !== undefined && nextProps.metaTagData.status === 200) {
            this.setState({
                data: nextProps.metaTagData.data.data.result.row,
                totalRecords: nextProps.metaTagData.data.data.result.pagination.total_record,
                totalPages: nextProps.metaTagData.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        if (nextProps.metaTagUpdateStatusResult !== this.props.metaTagUpdateStatusResult) {
            const listingArr = this.fetchData();
            this.props.getMetaTags(listingArr);
        }

        if (nextProps.metaTagDeleteResult !== this.props.metaTagDeleteResult) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: "success",
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    const listingArr = this.fetchData();
                    this.props.getMetaTags(listingArr);
                }
            });
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);

        return listingArr;
    };

    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getMetaTags(listingArr);
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getMetaTags(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.fetchData();
            this.props.getMetaTags(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.props.permission.includes("seo_meta_tags_update_status")) {
            let statusArray, dtClassName;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="status"
                    name="status"
                    options={statusArray}
                    defaultValue={row.status}
                    onChange={(event) => {
                        this.updateMetaTagStatusHandler(event, row.metaTagId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return (
                <span className={badgeClassName}>{status}</span>
            )
        }
    };

    updateMetaTagStatusHandler = (event, id) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
        const updateMetaTagStatusData = {};
        updateMetaTagStatusData.id = id;
        updateMetaTagStatusData.status = Number(event.target.value);
        this.props.updateMetaTagStatus(updateMetaTagStatusData);
    };

    showHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            metaTagShowId: id,
            pageShow: "show",
        });
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            metaTagEditId: id,
            pageShow: "edit",
        });
    };

    deleteHandler = (e, id) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.delete"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const deleteMetaTagData = {};
                deleteMetaTagData.id = id;
                this.props.deleteMetaTag(deleteMetaTagData);
            }
        });
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, deleteButton, output;

        if(this.props.permission.includes("seo_meta_tags_view")){
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-blue mr-2"
                    key={`view-${row.metaTagId}`}
                    idValue={row.metaTagId === undefined ? 0 : row.metaTagId}
                    clicked={(event) => this.showHandler(event, row.metaTagId)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }
        if(this.props.permission.includes("seo_meta_tags_update")){
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-info mr-2"
                    key={`edit-${row.metaTagId}`}
                    idValue={row.metaTagId === undefined ? 0 : row.metaTagId}
                    clicked={(event) => this.editHandler(event, row.metaTagId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }
        if(this.props.permission.includes("seo_meta_tags_delete")){
            deleteButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-danger"
                    key={`delete-${row.metaTagId}`}
                    idValue={row.metaTagId === undefined ? 0 : row.metaTagId}
                    clicked={(event) => this.deleteHandler(event, row.metaTagId)}
                    buttonName={<FontAwesomeIcon icon={faTrash} />}
                />
            );
        }
        output = [viewButton, editButton, deleteButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.fetchData();
            this.props.getMetaTags(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.fetchData();
                this.props.getMetaTags(listingArr);
            });
        }
    };

    render() {
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("meta-tags.name"), dataField: "name", sort: true, onSort: this.onSort },
            { text: t("meta-tags.title"), dataField: "title", sort: true, onSort: this.onSort },
            { text: t("meta-tags.description"), dataField: "description", sort: true, onSort: this.onSort },
            { text: t("meta-tags.keywords"), dataField: "keywords", sort: true, onSort: this.onSort },
            { text: t("meta-tags.url"), dataField: "url", sort: true, onSort: this.onSort },
            { text: t("global.status"), dataField: "status", formatter: this.statusFormatter },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "150px" } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    metaTagId: val.metaTagId,
                    name: val.name,
                    title: val.title,
                    description: val.description,
                    keywords: val.keywords,
                    url: val.url,
                    status: val.status,
                };
            });
        }
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MetaTagListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.props.permission}
                    />
                )}

                {this.props.permission.includes("seo_meta_tags_create") && this.state.pageShow === "create" && <MetaTagCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.props.permission.includes("seo_meta_tags_view") && this.state.pageShow === "show" && <MetaTagShow metaTagShowId={this.state.metaTagShowId} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.props.permission.includes("seo_meta_tags_update") && this.state.pageShow === "edit" && (
                    <MetaTagEdit metaTagEditId={this.state.metaTagEditId} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        metaTagData: state.seo.meta_tag_data,
        metaTagUpdateStatusResult: state.seo.meta_tag_update_status_result,
        metaTagDeleteResult: state.seo.meta_tag_delete_result,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getMetaTags: (data) => dispatch(getMetaTags(data)),
        updateMetaTagStatus: (data) => dispatch(updateMetaTagStatus(data)),
        deleteMetaTag: (data) => dispatch(deleteMetaTag(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MetaTag));
