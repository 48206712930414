import React, { Component } from "react";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Button from "../../components/Button/Button";
import StringInput from "../../components/Input/StringInput/StringInput";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

class MemberReferralListing extends Component {
    /* ============ Component Lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {
            showCard:false,
        };
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    /* ============ Component Lifecycle [END] ============ */

    toggleCard = () =>
    {
        this.setState({
            showCard:!this.state.showCard
        })
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member-referral.member-referral")}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (<Skeleton count={2} /> ) : (
                                <div className={`form-horizontal filter-function row ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                    <div className="col-md-4 col-6">
                                        <div className="form-group">
                                            <label className="font-weight-bolder">
                                                {t("member-referral.member")}
                                            </label>
                                            <StringInput
                                                id="username"
                                                name="username"
                                                value={this.props.filterInput.username}
                                                onChange={this.props.onInputChange}
                                                className="filter-input"
                                                onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col mt-auto">
                                        <div>
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler()}
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler()}
                                            />
                                        </div>
                                    </div>
                                </div> 
                                )}
                            </div>
                            <div className="show-btn" onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member-referral.member-referral-listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={this.props.columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberReferralListing);
