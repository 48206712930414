import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import {withNamespaces} from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import {getCountry} from "../../store/ducks/home/actions";
import {postFloatImage} from "../../store/ducks/floatImage/actions";
import {formValidation} from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";

//Initialize the language array first
let langArr = [];
class FloatImageCreate extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        image_desktop_name: [],
        image_mobile_name: [],
        image_link_desktop_image: [],
        image_link_mobile_image: [],
        desktop_image_display: [],
        mobile_image_display: [],
        desktop_image: [],
        mobile_image: [],
        start_time: '',
        end_time: '',
        currentStatus: 1,
        loading: true,
        error_message: [],
        touched: false
    };

    componentDidMount() {
        formValidation('floating_image_form');

        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_floating_image_result !== this.props.post_floating_image_result) {
            if (nextProps.post_floating_image_result.responseCode === 200) {
                this.setState({touched: false});
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.post_floating_image_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_floating_image_result.message
                });
                Swal.close();
            } else {
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_floating_image_result.message[0],
                    icon: nextProps.post_floating_image_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }

        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({loading: false});
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message
                });
                Swal.close();
            } else {
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        return true;
    }

    postFloatingImageHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                const desktop_image = this.state.desktop_image;
                const mobile_image = this.state.mobile_image;
                const image_desktop_name = this.state.image_desktop_name;
                const image_mobile_name = this.state.image_mobile_name;
                const image_link_desktop_image = this.state.image_link_desktop_image;
                const image_link_mobile_image = this.state.image_link_mobile_image;
                const desktop_image_display = this.state.desktop_image_display;
                const mobile_image_display = this.state.mobile_image_display;
                
                desktop_image.forEach((val, idx) => {
                    formData.append("desktop_image["+idx+"]", val);
                });

                mobile_image.forEach((val, idx) => {
                    formData.append("mobile_image["+idx+"]", val);
                });

                image_desktop_name.forEach((val, idx) => {
                    formData.append("image_name_desktop_image["+idx+"]", val);
                });

                image_mobile_name.forEach((val, idx) => {
                    formData.append("image_name_mobile_image["+idx+"]", val);
                });

                image_link_desktop_image.forEach((val, idx) => {
                    formData.append("image_link_desktop_image["+idx+"]", val);
                });

                image_link_mobile_image.forEach((val, idx) => {
                    formData.append("image_link_mobile_image["+idx+"]", val);
                });

                desktop_image_display.forEach((val, idx) => {
                    formData.append("desktop_image_display["+idx+"]", val);
                });

                mobile_image_display.forEach((val, idx) => {
                    formData.append("mobile_image_display["+idx+"]", val);
                });


                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("country_id", this.props.currentCountryId);

                this.props.postFloatImage(formData);
            }
        })

    }

    getDesktopImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const {desktop_image_preview, desktop_image} = this.state;

        reader.onloadend = () => {

            desktop_image_preview[langId] = reader.result;
            desktop_image[langId] = file;

            this.setState({
                desktop_image_preview,
                desktop_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            desktop_image_preview[langId] = "";
            this.setState({
                desktop_image_preview
            })
        }
    };

    getMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const {mobile_image_preview, mobile_image} = this.state;

        reader.onloadend = () => {
            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({mobile_image_preview, mobile_image});
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview
            })
        }
    
    }

    getDesktopImageNameHandler = (event, langId) => {
        const {image_desktop_name} = this.state;
        image_desktop_name[langId] = event.target.value;

        this.setState({
            image_desktop_name
        });
    }

    getMobileImageNameHandler = (event, langId) => {
        const {image_mobile_name} = this.state;
        image_mobile_name[langId] = event.target.value;

        this.setState({
            image_mobile_name
        });
    }

    getDesktopImageLinkHandler = (event, langId) => {
        const {image_link_desktop_image} = this.state;
        image_link_desktop_image[langId] = event.target.value;

        this.setState({
            image_link_desktop_image
        });
    }

    getMobileImageLinkHandler = (event, langId) => {
        const {image_link_mobile_image} = this.state;
        image_link_mobile_image[langId] = event.target.value;

        this.setState({
            image_link_mobile_image
        });
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date
        });
    };

    desktopImageDisplayTypeHandler = (event, langId) => {
        const {desktop_image_display} = this.state;
        desktop_image_display[langId] = event.target.value;

        this.setState({
            desktop_image_display
        });
    }

    mobileImageDisplayTypeHandler = (event, langId) => {
        const {mobile_image_display} = this.state;
        mobile_image_display[langId] = event.target.value;

        this.setState({
            mobile_image_display
        });
    }

    changeStatusHandler = (event) => {
        this.setState({currentStatus: event.target.value});
    }

    render() {
        const {t} = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let desktop_image_display_type = [
            {
                label: t('float-image.please-select'),
                value: '',
                selected: true
            },
            {
                label: t('float-image.top-left'),
                value: 'Top-Left'
            },
            {
                label: t('float-image.top-right'),
                value: 'Top-Right'
            },
            {
                label: t('float-image.bottom-left'),
                value: 'Bottom-Left'
            },
            {
                label: t('float-image.bottom-right'),
                value: 'Bottom-Right'
            }
        ];

        let mobile_image_display_type = [
            {
                label: t('float-image.please-select'),
                value: '',
                selected: true
            },
            {
                label: t('float-image.top-left'),
                value: 'Top-Left'
            },
            {
                label: t('float-image.top-right'),
                value: 'Top-Right'
            },
            {
                label: t('float-image.bottom-left'),
                value: 'Bottom-Left'
            },
            {
                label: t('float-image.bottom-right'),
                value: 'Bottom-Right'
            }
        ];

        let statusArry = [
            {
                label: t('global.active'),
                value: 1,
                selected: true
            },
            {
                label: t('global.inactive'),
                value: 0,
            }
        ];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t('float-image.desktop-image')}</label>
                        <ImageUpload
                            id="desktop_image"
                            name={`desktop_image[${item.langId}]`}
                            onChange={(event) => this.getDesktopImageHandler(event, item.langId)}
                        />

                        {this.state.desktop_image_preview[item.langId] &&
                        <div className="imgPreview floating-image-preview-image">
                            <div className="uploadPictureContainer">
                        
                                <img
                                    alt=""
                                    className="floating-image"
                                    src={this.state.desktop_image_preview[item.langId]}
                                />
                            </div>
                        </div>
                        }


                        {(this.state.desktop_image_preview.length === 0 || !this.state.desktop_image_preview[item.langId]) &&
                            <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t('float-image.mobile-image')}</label>
                        <ImageUpload
                            id="mobile_image"
                            name={`mobile_image[${item.langId}]`}
                            onChange={(event) => this.getMobileImageHandler(event, item.langId)}
                        />

                        {this.state.mobile_image_preview[item.langId] &&
                            <div className="imgPreview floating-image-preview-image">
                                <div className="uploadPictureContainer">
                                    <img
                                        alt=""
                                        className="floating-image"
                                        src={this.state.mobile_image_preview[item.langId]}
                                    />
                                </div>
                            </div>
                        }


                        {(this.state.mobile_image_preview.length === 0 || !this.state.mobile_image_preview[item.langId]) &&
                            <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.desktop-image-name')}</label>
                        <StringInput
                            onChange={(event) => this.getDesktopImageNameHandler(event, item.langId)}
                            id="image_desktop_name"
                            name={`image_desktop_name[${item.langId}]`}
                            value={this.state.image_desktop_name[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.mobile-image-name')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageNameHandler(event, item.langId)
                            }}
                            id="image_mobile_name"
                            name={`image_mobile_name[${item.langId}]`}
                            value={this.state.image_mobile_name[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.desktop-image-link')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getDesktopImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_desktop_image"
                            name={`image_link_desktop_image[${item.langId}]`}
                            value={this.state.image_link_desktop_image[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.mobile-image-link')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_mobile_image"
                            name={`image_link_mobile_image[${item.langId}]`}
                            value={this.state.image_link_mobile_image[item.langId]}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.desktop-image-display-type')}</label>
                        <Select
                            id="desktop_image_display_type"
                            className="form-control"
                            name={`desktop_image_display[${item.langId}`}
                            options={desktop_image_display_type}
                            onChange={(event) => this.desktopImageDisplayTypeHandler(event, item.langId)}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('float-image.mobile-image-display-type')}</label>
                        <Select
                            id="mobile_image_display_type"
                            className="form-control"
                            name={`mobile_display[${item.langId}`}
                            options={mobile_image_display_type}
                            onChange={(event) => this.mobileImageDisplayTypeHandler(event, item.langId)}
                        />
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage =
            <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage}/>
        ;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('float-image.float-image')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('float-image.add-float-image')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4}/> :
                                    <form method="post" id="floating_image_form" className="form-horizontal"
                                          onSubmit={this.postFloatingImageHandler} noValidate>
                                        <ValidationError formName="floating_image_form" touched={this.state.touched}
                                                         message={this.state.error_message}/>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('float-image.start-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                                                    </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('float-image.end-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('global.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {home, floatImage, currentUser} = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        post_floating_image_result: floatImage.post_floating_image_result,
        currentCountryId: currentUser.data.country_id
    }
};

const mapDispatchToProps = {
    getCountry,
    postFloatImage
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(FloatImageCreate));