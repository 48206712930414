import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('wallet_adjustment/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LISTING,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('wallet_adjustment/drop_down')
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getMemberBalc = (email) => (dispatch, getState) =>
{
  // console.log('getMemberBalc :', email, wt);
  axios.get('wallet_adjustment/balance', {
      params: {
          "email": email,
          "country_id": getState().currentUser.data.country_id,
          // "wallet_code": wt,          
      }
  }).then(function (response) 
  {
    // console.log(response);
    dispatch (
    {
      type: actionType.BALANCE,
      value: response.data
    })
  })
  .catch(err => 
  {
    
  });
};

export const store = (data) => (dispatch, getState) =>
{
  data.country_id = getState().currentUser.data.country_id;
  // console.log(data);
  axios.post('wallet_adjustment/store', data)
  .then(function (response) 
  {
    // console.log(response);
    dispatch (
    {
      type: actionType.STORE,
      value: response.data
    })
  })
  .catch(err => 
  {
    
  });
};

export const getMemberList = async (props) =>
{
  // console.log(props);
  let response;
  try 
  {
    response = await axios.get('wallet_adjustment/get_member_list', {params: props});
  } 
  catch (e) 
  {
    throw new Error(e.message)
  }

  return (response.data) ? response.data : null;
};