import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// import { AsyncPaginate } from 'react-select-async-paginate';

import Swal from 'sweetalert2';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import StringInput from '../../components/Input/StringInput/StringInput';
import { formValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as actions from '../../store/ducks/walletAdjustment/actions';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

class WalletAdjustmentCreate extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            member: '',
            confirmMember: '',
            transaction_type: 0,
            amount: '',
            avBalc: 0,
            adjBalc: 0,
            validMember: false,
            errMsg: [],
            touched: false,
            remark: null,
            prevEmail: ''
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount (create):', this.state);
        formValidation('wallet_adjustment_form');
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log('shouldComponentUpdate :',nextProps.dropDown);
        if (nextProps.memberBalc !== this.props.memberBalc && nextProps.memberBalc !== undefined) {
            if (nextProps.memberBalc.responseCode === 200 && nextProps.memberBalc.msgType === 'success') {
                this.setState({
                    confirmMember: this.state.member,
                    avBalc: nextProps.memberBalc.data.balance,
                    validMember: true,
                }, () => {
                    this.calculateBalance();
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.memberBalc.message
                })
            }
            Swal.close();
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        member: '',
                        confirmMember: '',
                        transaction_type: nextState.transaction_type,
                        amount: '',
                        value: '',
                        avBalc: 0,
                        adjBalc: 0,
                    });
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.store.message
                });
                Swal.close();
            }

        }
        return true;
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) => {
        let newId = (event.id) ? id : event.target.id;
        let newVal = (event.id) ? event.id : event.target.value;
                
        this.setState((prevState) => ({
            [newId]: newVal,
            validMember: newId === 'member' ? false : prevState.validMember
        }), () => {
            const memberEmail = this.state.member;
            if (newId === 'member') {
                if (memberEmail !== '') {
                } else {
                    this.setState({
                        avBalc: 0,
                        adjBalc: 0,
                        confirmMember: '',
                        errMsg: [],
                        touched: false
                    });
                }
            } else {
                if (memberEmail !== '') {
                    this.calculateBalance();
                }
            }
        });
    }

    onSearchHandler = () => {
        const memberEmail = this.state.member;
        if (memberEmail !== '') {
            this.setState({
                validMember: false,
                avBalc: 0,
                adjBalc: 0,
                touched: false
            }, () => {
                // this.loadingHandler();
                this.props.funcGetMemberBalc(memberEmail);
                // this.calculateBalance();
            });
        }
    }

    onMaxHandler = () => {
        // console.log(this.state.avBalc);
        let avBalc = this.state.avBalc
        this.setState({
            amount: avBalc,
        }, () => {
            this.calculateBalance();
        });
    }

    calculateBalance = () => {
        let amt = (this.state.amount === null) ? 0 : this.state.amount;
        let balc = this.state.avBalc;
        let avBalc = (balc !== undefined && balc !== '') ? balc : 0;
        var bal = (Number(this.state.transaction_type) === 1) ? (Number(avBalc) - Number(amt)) : (Number(avBalc) + Number(amt));
        // console.log('calculateBalance : '+avBalc+' | '+bal+' | '+avBalc+' | '+amt+' | '+this.state.transaction_type);
        this.setState({
            avBalc: avBalc,
            adjBalc: bal,
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', e);
        const { t } = this.props;
        e.preventDefault();
        if (this.state.adjBalc < 0) {
            Swal.fire({
                title: t('validation.insufficient_balance'),
                icon: 'error',
                showConfirmButton: true,
            })
        }
        else if (!this.state.validMember) {
            Swal.fire({
                title: t('validation.invalid_member'),
                icon: 'error',
                showConfirmButton: true,
            })
        }
        else {
            Swal.fire({
                title: t('validation.confirmation'),
                text: t('validation.confirmation_check'),
                icon: 'warning',
                confirmButtonText: t('global.save'),
                showDenyButton: true,
                denyButtonText: t('global.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    });

                    let data = {};
                    data.email = this.state.confirmMember;
                    data.amount = this.state.amount;
                    data.transaction_type = this.state.transaction_type;
                    data.country_id = this.props.currentUser.country_id;
                    data.remark = this.state.remark;
                    this.props.funcStore(data);
                }
            });
        }
    }

    onSearchPress = (evt) => {
        const { member, prevEmail } = this.state;

        if (evt.key === "Enter" && member !== prevEmail) {
            this.onSearchHandler();
            this.setState({ 
                prevEmail: member
            });
        }
    }

    onBlurHandler = () => {
        const { member, prevEmail } = this.state;
        
        if (member !== '' && member !== prevEmail) {
            this.onSearchHandler();
            this.setState({ prevEmail: member });
        }
    }

    render() {        
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton='button'
                                classValue='btn btn-back-outline'
                                idValue='back'
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('wallet-adjustment.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.wallet_adjustment_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='wallet_adjustment_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='wallet_adjustment_form' touched={this.state.touched} message={this.state.errMsg} />
                                    <div className='col-12 p-0'>
                                        {/* <div className='col-md-12 col-6'> */}
                                        <div className="row">
                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('wallet-adjustment.member')}</label>
                                                <div className="position-relative">
                                                    <StringInput
                                                        id='member'
                                                        name='member'
                                                        placeholder={t('member.email')}
                                                        value={this.state.member}
                                                        onChange={this.onInputChange}
                                                        onBlur={this.onBlurHandler}
                                                        onSearchPress={this.onSearchPress}                                                                                                    
                                                        iconName={
                                                            this.state.member !== '' ? (
                                                                this.state.validMember ? (
                                                                    <FontAwesomeIcon icon={faCheck} className='text-success' />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faTimes} className='text-danger' />
                                                                )
                                                            ) : null
                                                        }                                                        
                                                        // required
                                                    />                                                    
                                                    {/* <Button
                                                        typeButton="button"
                                                        classValue="btn btn-blue input-side-btn"
                                                        clicked={this.onSearchHandler}
                                                        buttonName={<FontAwesomeIcon icon={faSearch} />}

                                                    /> */}
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('wallet-adjustment.transaction-type')}</label>
                                                <div className='select-input'>
                                                    <select className='form-control filter-input' id='transaction_type' name='transaction_type' onChange={this.onInputChange} value={this.state.transaction_type}>
                                                        <option value='0'>{t('wallet-adjustment.credit')}</option>
                                                        <option value='1'>{t('wallet-adjustment.debit')}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('wallet-adjustment.amount')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <NumberInput
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={this.onInputChange}
                                                        id='amount'
                                                        name='amount'
                                                        min={0.01}
                                                        // max={45000}
                                                        placeholder={t('wallet-adjustment.enter-amount')}
                                                        value={this.state.amount}
                                                        required
                                                    />
                                                    <Button
                                                        typeButton="button"
                                                        clicked={this.onMaxHandler}
                                                        classValue="btn btn-blue input-side-btn"
                                                        buttonName={t("transaction.max")}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-4 col-12 form-group'>
                                                <label>{t('wallet-adjustment.remark')}</label>
                                                {/* <input className='form-control' type='number' name='amount' id='amount' value={this.state.amount} placeholder='Enter Amount' onChange={this.onInputChange} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} required /> */}
                                                <div className="position-relative">
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="remark"
                                                        id="remark"
                                                        maxLength="255"
                                                        rows="3"
                                                        placeholder={t("wallet-adjustment.remark")}
                                                        value={this.state.remark || ""}
                                                        onChange={this.onInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            {/* <div className='col-md-6 col-12 form-group'>
                                                <label>{t('wallet-adjustment.available-balance')}</label>
                                                <h2 id='available_balance'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.avBalc)}</h2>
                                            </div>

                                            <div className='col-md-6 col-12 form-group'>
                                                <label>{t('wallet-adjustment.after-adjustment-balance')}</label>
                                                <h2 id='after_adjustment_balance'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.adjBalc)}</h2>
                                            </div> */}
                                            <div className="col-lg-6 col-12">
                                                <div className="card card-img-holder overflow-hidden sales-card bg-primary-gradient">
                                                    <div className="card-body list-icons">
                                                        <div className="clearfix">
                                                            <div className="float-right  mt-2">
                                                                <span className="text-primary">
                                                                    <i className="si si-credit-card tx-50 text-white"></i>
                                                                </span>
                                                            </div>
                                                            <div className="float-left">
                                                                <p className="card-text mb-1 text-white">{t('wallet-adjustment.available-balance')}</p>
                                                                <h3 id='available_balance' className="text-white">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.avBalc)}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12">
                                                <div className="card card-img-holder overflow-hidden sales-card bg-danger-gradient">
                                                    <div className="card-body list-icons">
                                                        <div className="clearfix">
                                                            <div className="float-right  mt-2">
                                                                <span className="text-primary">
                                                                    <i className="si si-credit-card tx-50 text-white"></i>
                                                                </span>
                                                            </div>
                                                            <div className="float-left">
                                                                <p className="card-text mb-1 text-white">{t('wallet-adjustment.after-adjustment-balance')}</p>
                                                                <h3 id='after_adjustment_balance' className="text-white">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.adjBalc)}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='form-group mb-0 justify-content-end'>
                                            <div>
                                                {(permission.includes('wallet_adjustment_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} disabled={!this.state.validMember} />
                                                }
                                            </div>
                                        </div>

                                        {/* </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        memberBalc: state.walletAdjustment.memberBalc,
        store: state.walletAdjustment.store,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetMemberBalc: (email) => dispatch(actions.getMemberBalc(email)),
        funcStore: (data) => dispatch(actions.store(data)),
        // funcGetMemberList: (data) => actions.getMemberList(data),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(WalletAdjustmentCreate));