import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getCrypto, getCryptoList } from "../../store/ducks/crypto/actions";
import CryptoListing from "./CryptoListing";
import Button from "../../components/Button/Button";
import Skeleton from "react-loading-skeleton";

import UpdownArrow from "../../assets/images/updown-arrow.png";

class Crypto extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",
        isOpen: false,

        data: [],
        cryptoListData: [],

        currentCryptoId: "",
        loading: true,
        currentImage: "",

        permission: [],
        cryptoFilterValue: "",
    };

    fetchData() {
        const cryptoData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(
                this.state.totalRecords / this.state.limit + 1
            );
            this.state.activePage >= availablePage &&
                this.setState({ activePage: availablePage });
        }

        cryptoData.page =
            this.state.activePage >= availablePage
                ? availablePage
                : this.state.activePage;
        cryptoData.limit = this.state.limit;
        cryptoData.order = JSON.stringify(this.state.sorted);
        cryptoData.search = this.state.searchValue;
        cryptoData.searchFilter = this.state.cryptoFilterValue;

        this.props.getCrypto(cryptoData);
    }

    componentDidMount() {
        this.props.getCryptoList();
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.permission.length === 0 &&
            this.props.currentPermission !== undefined
        ) {
            let cryptoPermission = this.props.currentPermission.filter((item) =>
                item.includes("crypto_")
            );
            this.setState({ permission: cryptoPermission });
        }

        if (nextProps.crypto_data !== this.props.crypto_data) {
            this.setState({
                loading: false,
                data: nextProps.crypto_data.data.data,
                totalRecords: nextProps.crypto_data.data.totalRecords,
                totalPages: nextProps.crypto_data.data.totalPages,
            });
        }

        if (nextProps.crypto_list_data !== this.props.crypto_list_data) {
            this.setState({
                loading: false,
                cryptoListData: nextProps.crypto_list_data.data.data,
            });
        }

        return true;
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    };

    refreshHandler = () => {
        this.fetchData();
    };

    pageChangeHandler = (activePage) => {
        this.setState(
            {
                page: activePage,
                activePage: activePage,
                loading: true,
                data: [],
            },
            () => {
                this.fetchData();
            }
        );
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    filterCryptoHandler = (event) => {
        this.setState({ cryptoFilterValue: event.target.value });
    };

    filterSearchHandler = () => {
        this.setState(
            { loading: true, data: [], activePage: 1, page: 1 },
            () => {
                this.fetchData();
            }
        );
    };

    render() {
        const { t } = this.props;
        let crypto = [];

        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber =
                        (this.state.activePage - 1) * this.state.limit +
                        (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "50px" };
                },
            },
            {
                text: "Crypto Id",
                dataField: "cryptoId",
                keyField: "cryptoId",
                hidden: true,
            },
            {
                text: t("crypto.username"),
                dataField: "memberUsername",
                keyField: "memberUsername",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("crypto.crypto"),
                dataField: "cryptoDetails",
                keyField: "cryptoDetails",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("crypto.address"),
                dataField: "address",
                keyField: "cryptoAddress",
                sort: true,
                onSort: this.onSort,
            },
        ];

        if (this.state.pageShow === "listing" && this.state.data) {
            crypto = this.state.cryptoListData.map((val, idx) => {
                return (
                    <option key={val.id} value={val.slug}>
                        {val.slug_details}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div className="row row-sm">
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">
                                        {t("crypto.members-crypto-address")}
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12">
                            <div className="card card-body card-primary">
                                <div className="">
                                    {this.props.loading ? (
                                        <Skeleton count={2} />
                                    ) : (
                                        <form
                                            method="post"
                                            id="crypto"
                                            className="form-horizontal filter-function row"
                                        >
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="crypto"
                                                        className="font-weight-bolder"
                                                    >
                                                        {t("crypto.crypto")}
                                                    </label>
                                                    <div className="select-input">
                                                        <select
                                                            className="form-control filter-input"
                                                            id="crypto"
                                                            name="crypto"
                                                            onChange={
                                                                this.filterCryptoHandler
                                                            }
                                                            value={
                                                                this.state.cryptoFilterValue
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {crypto}
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col mt-auto">
                                                <div>
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-blue"
                                                        idValue="filter"
                                                        buttonName={t(
                                                            "global.filter"
                                                        )}
                                                        clicked={() =>
                                                            this.filterSearchHandler()
                                                        }
                                                    />
                                                </div>
                                            </div>

                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>

                        <CryptoListing
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            searchValue={this.state.searchValue}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            searchChangeHandler={this.searchChangeHandler}
                            postSearchHandler={this.postSearchHandler}
                            refreshHandler={() => this.refreshHandler()}
                            searchPressSubmitHandler={(event) =>
                                this.onSearchKeyPressHandler(event)
                            }
                            loading={this.state.loading}
                            permission={this.state.permission}
                        />
                    </div>
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { crypto, currentUser } = state;

    return {
        crypto_data: crypto.crypto_data,
        currentPermission: currentUser.data.permission,
        crypto_list_data: crypto.crypto_list_data,
    };
};

const mapDispatchToProps = {
    getCrypto,
    getCryptoList,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces("translation")(Crypto));
