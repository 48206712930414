import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Button from "../../components/Button/Button";

class AgentPayoutHistory extends Component 
{
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        const num_format = {maximumFractionDigits: 2, minimumFractionDigits: 2};

        const columns = [
            {
                text: t("global.no"), 
                dataField: "no", 
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.props.data.activePage - 1) * this.props.data.limit + (rowIndex + 1);
                    return <span>{rowNumber}</span>;
                }, 
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("agent-payout.order-id"), 
                dataField: "order_id", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName), 
                headerStyle: { width: "115px" },
            },
            {
                text: t("agent-payout.agent"), 
                dataField: "agent", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
            },
            {
                text: `${t('agent-payout.total-bet')} (${t('global.currency.usd')})`,
                dataField: "total_bet", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
                formatter: (cell, row, rowIndex) => 
                {
                    let val = permission.includes(
                        'agent_payout_details'
                    ) ? (
                        <b>
                            <a href='/#' onClick={(event) => this.props.betDetailsHandler(event,row.aid,this.props.data.filterInput.confirm_date,row.order_id)}>
                                {new Intl.NumberFormat('en-US', num_format).format(row.total_bet)}
                                <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                            </a>
                        </b>
                    ) : (
                        new Intl.NumberFormat('en-US', num_format).format(row.total_bet)
                    );
                    
                    return val;
                },
            },
            {
                text: t("agent-payout.payout"), 
                dataField: "payout", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
                formatter: (cell, row, rowIndex) => 
                {
                    return new Intl.NumberFormat("en-US", num_format).format(row.payout);
                },
            },
            {
                text: t("agent-payout.transaction-date"), 
                dataField: "transaction_date", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
            },
            {
                text: t("transaction.create-date"), 
                dataField: "create_date", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
            },
            {
                text: t("transaction.update-by"), 
                dataField: "update_by", 
                sort: true, 
                onSort: (field, order, tab) => this.props.onSort(field, order, this.props.tabName),
            },
        ];

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.data.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form method="post" id="filter_form" className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-2`}>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.create-date")}
                                                </label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check"></i>
                                                        </span>
                                                    </div>
                                                    <DateTimePicker
                                                        id="date_range"
                                                        name="date_range"
                                                        className="form-control filter-input"
                                                        format="YYYY-MM-DD"
                                                        range={true}
                                                        value={this.props.data.filterInput.date_range}
                                                        onChange={(event) => this.props.onInputChange(event,this.props.tabName,"filterInput")}
                                                        maxDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("agent-payout.agent")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.data.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, "filterInput")}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="filter"
                                                buttonName={t("global.filter")}
                                                clicked={() => this.props.refreshHandler(this.props.tabName)}
                                            />
                                            &nbsp;
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="reset"
                                                buttonName={t("global.reset")}
                                                clicked={() => this.props.clearHandler(this.props.tabName)}
                                            />
                                            </div>
                                        </div>

                                    </form>
                                    )}
                                </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("agent-payout.payout-history")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data.data}
                                        columns={columns}
                                        activePage={this.props.data.activePage}
                                        limit={this.props.data.limit}
                                        totalItems={this.props.data.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, "searchValue")}
                                        searchValue={this.props.data.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.data.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(AgentPayoutHistory);
