import axios from "../../../common/config/axios";
import * as type from "./types";

export const getGameList = (data) => (dispatch) => {
    axios
        .get("game_list/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.GAME_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Game List Error" + err);
            dispatch({
                type: type.GAME_LIST,
                payload: [],
            });
        });
};

export const getFilterData = (data) => (dispatch) => {
    axios
        .get("game_list/get_filter", {params: data})
        .then((res) => {
            dispatch({
                type: type.GAME_GET_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Game List Get Filter Data Error" + err);
            dispatch({
                type: type.GAME_GET_FILTER_DATA,
                payload: [],
            });
        });
};

export const createGameList = () => (dispatch) => {
    axios
        .get("game_list/create")
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_CREATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Create Game List Error" + err);
            dispatch({
                type: type.GAME_LIST_CREATE,
                payload: [],
            });
        });
};

export const storeGameList = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("game_list/store", data)
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Game List Error\n", err);
        });
};

export const editGameList = (id) => (dispatch) => {
    axios
        .get("game_list/edit", {
            params: {
                id: id
            },
        })
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Game List Error" + err);
            dispatch({
                type: type.GAME_LIST_EDIT,
                payload: [],
            });
        });
};

export const updateGameList = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("game_list/update", data)
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Game List Error\n", err);
        });
};

export const updateGameListStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("game_list/update_status", data)
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_UPDATE_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Game List Status Error", err);
        });
};

export const updateGameListFav = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("game_list/update_fav", data)
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_UPDATE_FAV,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Game List Fav Error", err);
        });
};

export const updateGameListDemo = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("game_list/update_demo", data)
        .then((res) => {
            dispatch({
                type: type.GAME_LIST_UPDATE_DEMO,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Game List Demo Error", err);
        });
};