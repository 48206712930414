import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => {
	axios.get('whitelist-ip', {params: props})
		.then(function (response) {
			dispatch ({
				type: actionType.LIST,
				value: response.data
			})
		})
		.catch(err => {});
};

export const store = (data) => dispatch => {
	axios.post('whitelist-ip/create', data)
		.then(function (response) {
			dispatch ({
				type: actionType.STORE,
				value: response.data
			})
		})
		.catch(err => {});
};

export const update = (data) => dispatch => {
	axios.post('whitelist-ip/update', data)
		.then(function (response) {
			dispatch ({
				type: actionType.UPDATE,
				value: response.data
			})
		})
		.catch(err => {});
};

export const deleteData = (data) => dispatch => {
	axios.post('whitelist-ip/delete', data)
		.then(function (response) {
			dispatch ({
				type: actionType.DELETE,
				value: response.data
			})
		})
		.catch(err => {});
};
