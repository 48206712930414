import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('withdrawal/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('withdrawal/drop_down')
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (props) => dispatch =>
{   
    axios.get('withdrawal/details',{params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DETAILS,
        value: response.data
      })
    })
    .catch(function (error) 
    {
      
    });
};

export const updateTran = (data) => dispatch =>
{
    // console.log(data);
    axios.post('withdrawal/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const sysAlert = (data) => dispatch =>
{
    // console.log(props);
    axios.post('system_alert', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.SYSALERT,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};