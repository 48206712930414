import React, { Component } from "react";
import { connect } from "react-redux";
import { editMetaTag, updateMetaTag } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Textarea from "../../../components/Input/Textarea/Textarea";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import MultipleCheckbox from "../../../components/Input/Checkbox/MultipleCheckbox";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import { default as ReactSelect } from "react-select";

let languageArray = [];
class MetaTagEdit extends Component {
    constructor() {
        super();
        this.state = {
            constant_name: "",
            id: null,
            name: "",
            url: "",
            status: 1,
            languages: null,
            title: "",
            description: "",
            keywords: "",
            h1: "",
            loading: true,
            robot: [
                { robotTagId: 1, name: "Follow", isChecked: false },
                { robotTagId: 2, name: "Index", isChecked: false },
                { robotTagId: 3, name: "No Follow", isChecked: false },
                { robotTagId: 4, name: "No Index", isChecked: false },
                { robotTagId: 5, name: "No Snippet", isChecked: false },
                { robotTagId: 6, name: "No Translate", isChecked: false },
                { robotTagId: 7, name: "No Image Index", isChecked: false },
            ],

            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
    }

    componentDidMount() {
        this.props.editMetaTag(this.props.metaTagEditId);
        formValidation("meta_tag_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MetaTagEdit] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);

        if (nextProps.metaTagEditData !== this.props.metaTagEditData) {
            if (nextProps.metaTagEditData.responseCode === 200) {
                let metaTagData = nextProps.metaTagEditData.data.metaTag[0];
                let robotData = nextState.robot;
                let robotEditData = metaTagData.robot;
                let languageData = nextProps.metaTagEditData.data.language;
                if(robotEditData !== null) {
                    robotEditData.split(",").forEach((item) => {
                        robotData[item - 1].isChecked = true;
                    });
                }

                languageArray = [];
                Object.keys(languageData).map((item, i) => {
                    return languageArray.push({
                        label: languageData[item].title,
                        value: languageData[item].langId,
                    });
                });

                this.setState({
                    id: metaTagData.metaTagId,
                    name: metaTagData.name,
                    constant_name: metaTagData.name,
                    url: metaTagData.url,
                    status: metaTagData.status,
                    languages: metaTagData.langId,
                    title: metaTagData.title,
                    description: metaTagData.description,
                    keywords: metaTagData.keywords,
                    h1: metaTagData.h1,
                    robot: robotData,
                    loading: false,
                });
            } else {
                return false;
            }
        }

        if (nextProps.updateMetaTagResult !== this.props.updateMetaTagResult) {
            if (nextProps.updateMetaTagResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateMetaTagResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.updateMetaTagResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateMetaTagResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }
        return true;
    }

    updateMetaTagHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let robotData = this.state.robot.filter((item) => item.isChecked === true).map((item) => item.robotTagId);
                let updateMetaTagData = {};
                updateMetaTagData.id = this.state.id;
                updateMetaTagData.name = this.state.name;
                updateMetaTagData.url = this.state.url;
                updateMetaTagData.status = this.state.status;
                updateMetaTagData.languages = this.state.languages.value === undefined ? this.state.languages : this.state.languages.value;
                updateMetaTagData.title = this.state.title;
                updateMetaTagData.description = this.state.description;
                updateMetaTagData.keywords = this.state.keywords;
                updateMetaTagData.h1 = this.state.h1;
                updateMetaTagData.robot = robotData;
                // console.log("Form Submit:", updateMetaTagData);
                this.props.updateMetaTag(updateMetaTagData);
            }
        });
    };

    handleChange = (event) => {
        // console.log(event);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleStatusChange = (event) => {
        this.setState({
            status: Number(event.target.value),
        });
    };

    handleLanguageChange = (event) => {
        this.setState({
            languages: event,
        });
    };

    handleCheckChieldElement = (event) => {
        let robotTags = this.state.robot;
        if ([1, 3].includes(Number(event.target.value))) {
            if (Number(event.target.value) === 1) {
                robotTags[2].isChecked = false;
            }

            if (Number(event.target.value) === 3) {
                robotTags[0].isChecked = false;
            }
        }

        if ([2, 4].includes(Number(event.target.value))) {
            if (Number(event.target.value) === 2) {
                robotTags[3].isChecked = false;
            }

            if (Number(event.target.value) === 4) {
                robotTags[1].isChecked = false;
            }
        }

        robotTags.forEach((rt) => {
            if (Number(rt.robotTagId) === Number(event.target.value)) {
                rt.isChecked = event.target.checked;
            }
        });
        this.setState({ robot: robotTags });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let status = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        let robotTag = this.state.robot.map((val, idx) => (
            <label className="ckbox mg-l-20" key={idx}>
                <MultipleCheckbox id={val.robotTagId} value={val.robotTagId} label={val.name} isChecked={val.isChecked} handleCheckChieldElement={this.handleCheckChieldElement} />
            </label>
        ));

        if (!this.state.loading) {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        {t("meta-tags.edit-meta-tag")} ({t("meta-tags.name")}: {this.state.constant_name})
                                    </h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="meta_tag_form" className="form-horizontal" onSubmit={this.updateMetaTagHandler} noValidate>
                                        <ValidationError formName="meta_tag_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="name">{t("meta-tags.name")}</label>
                                                    <StringInput
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.name")}
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="url">{t("meta-tags.url")}</label>
                                                    <StringInput
                                                        id="url"
                                                        name="url"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.url")}
                                                        value={this.state.url}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="status">{t("global.status")}</label>
                                                    <Select id="status" name="status" options={status} onChange={this.handleStatusChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="name">{t("global.languages")}</label>
                                                    <ReactSelect
                                                        options={languageArray}
                                                        isSearchable={true}
                                                        name="agent"
                                                        value={languageArray.find((option) => option.value === this.state.languages)}
                                                        onChange={this.handleLanguageChange}
                                                        placeholder={t("global.select")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="title">{t("meta-tags.title")}</label>
                                                    <Textarea
                                                        id="title"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.title")}
                                                        value={this.state.title}
                                                        onChange={this.handleChange}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="description">{t("meta-tags.description")}</label>
                                                    <Textarea
                                                        id="description"
                                                        name="description"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.description")}
                                                        value={this.state.description}
                                                        onChange={this.handleChange}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password">
                                                        {t("meta-tags.keywords")} <small>({t("meta-tags.seperate")})</small>
                                                    </label>
                                                    <Textarea
                                                        id="keywords"
                                                        name="keywords"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.keywords")}
                                                        value={this.state.keywords}
                                                        onChange={this.handleChange}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="h1">{t("meta-tags.h1")}</label>
                                                    <Textarea
                                                        id="h1"
                                                        name="h1"
                                                        className="form-control"
                                                        placeholder={t("meta-tags.h1")}
                                                        value={this.state.h1}
                                                        onChange={this.handleChange}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="mg-l-20" htmlFor="robot">
                                                        {t("meta-tags.robot-tags")}
                                                    </label>
                                                    <div className="form-check form-check-inline">{robotTag}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-primary" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("meta-tags.edit-meta-tag")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        metaTagEditData: state.seo.meta_tag_edit_data,
        updateMetaTagResult: state.seo.meta_tag_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editMetaTag: (id) => dispatch(editMetaTag(id)),
        updateMetaTag: (updateMetaTagData) => dispatch(updateMetaTag(updateMetaTagData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MetaTagEdit));
