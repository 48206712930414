import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const companyPerformanceByDayReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
});

export default companyPerformanceByDayReducer;