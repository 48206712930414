export const META_TAG_LIST = "META_TAG_LIST";
export const META_TAG_SHOW = "META_TAG_SHOW";
export const META_TAG_CREATE = "META_TAG_CREATE";
export const META_TAG_STORE = "META_TAG_STORE";
export const META_TAG_EDIT = "META_TAG_EDIT";
export const META_TAG_UPDATE = "META_TAG_UPDATE";
export const META_TAG_UPDATE_STATUS = "META_TAG_UPDATE_STATUS";
export const META_TAG_DELETE = "META_TAG_DELETE";
export const XML_SITEMAP = "XML_SITEMAP";
export const XML_SITEMAP_GENERATE = "XML_SITEMAP_GENERATE";
export const ROBOTS_TXT_LIST = "ROBOTS_TXT_LIST";
export const ROBOTS_TXT_EDIT = "ROBOTS_TXT_EDIT";
export const ROBOTS_TXT_STORE = "ROBOTS_TXT_STORE";
export const ROBOTS_TXT_UPDATE = "ROBOTS_TXT_UPDATE";
export const ROBOTS_TXT_UPDATE_RULE = "ROBOTS_TXT_UPDATE_RULE";
export const ROBOTS_TXT_DELETE = "ROBOTS_TXT_DELETE";
export const WEB_MASTER_LIST = "WEB_MASTER_LIST";
export const WEB_MASTER_UPDATE = "WEB_MASTER_UPDATE";
export const OPEN_GRAPH_LIST = "OPEN_GRAPH_LIST";
export const OPEN_GRAPH_UPDATE = "OPEN_GRAPH_UPDATE";

export const DEMO_SEO = "DEMO_SEO";
export const STATIC_SEO = "STATIC_SEO";
export const DYNAMIC_SEO = "DYNAMIC_SEO";

