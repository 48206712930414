import React, { Component } from "react";
import { connect } from "react-redux";
import { showEmailContent } from "../../store/ducks/emailContent/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import DomPurify from "../../components/Sanitize/DomPurify";
import { filterErrorHandler } from "../../hoc/Shared/utility";

class EmailContentShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.showEmailContent(this.props.emailContentShowId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContentShow] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.emailContentShowData !== this.props.emailContentShowData && nextProps.emailContentShowData !== undefined) {
            if (nextProps.emailContentShowData.responseCode === 200 && nextProps.emailContentShowData.msgType === 'success') {
                this.setState({
                    loading: false,
                });
            }else{
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.emailContentShowData.message);
                });
            }   
        }
        return true;
    }

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            let emailContentData = this.props.emailContentShowData.data.emailContent[0];
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.email-content-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{t("email-content.email-type")}</th>
                                            <td field-key="type">{emailContentData.type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("email-content.email-subject")}</th>
                                            <td field-key="subject">{emailContentData.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("email-content.email-content")}</th>
                                            <td field-key="content" className="email-content">
                                                {/* <textarea className="form-control" defaultValue={emailContentData.content} cols="120" rows="20" disabled></textarea> */}
                                                {/* <span dangerouslySetInnerHTML={{ __html: emailContentData.content }} /> */}
                                                <DomPurify text={emailContentData.content} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.languages")}</th>
                                            <td field-key="languages">{emailContentData.language}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.created-time")}</th>
                                            <td field-key="created_date">{emailContentData.createdTime}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("global.updated-time")}</th>
                                            <td field-key="updated_date">{emailContentData.updatedTime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.email-content-details")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-content.email-content")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { emailContent } = state;

    return {
        emailContentShowData: emailContent.email_content_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showEmailContent: (id) => dispatch(showEmailContent(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailContentShow));
