import React from "react";
// import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import Skeleton from "react-loading-skeleton";
import paginationFactory, { SizePerPageDropdownStandalone, PaginationProvider, PaginationListStandalone, PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
// import StringInput from "../Input/StringInput/StringInput";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";
import "../../assets/css/_datatable.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from "prop-types";

const DataTable = (props) => {
    const sizePerPageRenderer = ({ options, onSizePerPageChange }) => (
        <div className="dataTables_length d-flex flex-direction-row align-items-center">
            <span className="mr-1 d-none d-sm-flex">{props.t("global.rows-per-page")} </span>
            <select className="select__show" onChange={(event) => onSizePerPageChange(event.target.value)} value={props.limit}>
                {options.map((option) => (
                    <option
                        key={option.text}
                        value={option.text}
                        // selected={props.limit === Number(option.text)}
                    >
                        {option.text}
                    </option>
                ))}
            </select>
        </div>
    );

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total pb-3" style={{fontSize:12}}>
            {props.t("global.showing")} {from} {props.t("global.to")} {to} {props.t("global.of")} {size} {props.t("global.entries")}
        </span>
    );

    // const [activatedPage, setActivePage] = useState(1);

    // useEffect(() => {
    //     console.log(props);
    //     if (props.totalItems !== 0) {
    //         const availablePage = parseInt((props.totalItems / props.limit) + 1);
    //         console.log("Available Page: " + availablePage);
    //         if(props.activePage <= availablePage) {
    //             setActivePage(props.activePage);
    //         } else {
    //             setActivePage(availablePage);
    //         }
    //         console.log("Current Active Page: " + activatedPage);
    //     }
    //   });

    //Pagination & Sorting Column Configuration
    const options = {
        page: props.activePage,
        // page: activatedPage,
        sizePerPage: props.limit,
        //Show total result
        showTotal: true,
        paginationTotalRenderer: customTotal,
        totalSize: props.totalItems,
        //Custom Pagination & Limit Dropdown
        custom: true,
        //Pagination Function handler
        onPageChange: props.paginationHandler,
        //Sorting Function handler
        onSizePerPageChange: props.sizePerPageHandler,
        alwaysShowAllBtns: true,
        firstPageText: props.t("global.first"),
        prePageText: props.t("global.back"),
        nextPageText: props.t("global.next"),
        lastPageText: props.t("global.last"),
        //Limit Dropdown list
        sizePerPageList: [10, 20, 30, 40, 50],
        sizePerPageRenderer,
    };

    // const SearchInput = () => {
    //   if(props.searchable === false) {
    //     return null
    //   } else {
    //     return(
    //       <StringInput
    //         onChange={props.searchChangeHandler}
    //         id="search"
    //         name="search"
    //         value={props.searchValue}
    //         className="form-control form-control-sm"
    //         placeholder={props.t("global.search")}
    //         iconName={<FontAwesomeIcon icon={faSearch} />}
    //         onSearchChange={props.searchSubmitHandler}
    //         onSearchPress={props.searchPressSubmitHandler}
    //       />
    //     )
    //   }
    // }

    //START OF EXPAND ROW
    // const products = [
    //     {id:1, vendor:"Asia Gaming", ctg:"", gameName:"", turnOver:"10000", winLost:"-5000"}, 
    //     {id:2, vendor:"Interactive", ctg:"", gameName:"", turnOver:"12000", winLost:"0"},
    //     {id:3, vendor:"Saba", ctg:"", gameName:"", turnOver:"28000", winLost:"-5000"},
    // ];
    // const products2 = [
    //     {id:1, vendor:"Asia Gaming", ctg:"Casino", gameName:"Barracat", turnOver:"7000", winLost:"-7000"}, 
    //     {id:2, vendor:"Asia Gaming", ctg:"Slot", gameName:"Fruit slots", turnOver:"7000", winLost:"-7000"}
    // ];

    // const products3 = [
    //     {id:1, vendor:"Interactive", ctg:"Casino", gameName:"Barracat", turnOver:"10000", winLost:"-7000"}, 
    //     {id:2, vendor:"Interactive", ctg:"Slot", gameName:"Lantern Festival", turnOver:"2000", winLost:"-7000"}
    // ];

    // const products4 = [
    //     {id:1, vendor:"Saba", ctg:"Casino", gameName:"Sport", turnOver:"20000", winLost:"5000"}, 
    //     {id:2, vendor:"Saba", ctg:"Casino", gameName:"Sport", turnOver:"5000", winLost:"0"},
    //     {id:3, vendor:"Saba", ctg:"Casino", gameName:"Virtual", turnOver:"3000", winLost:"500"},
    // ];

    // const columns = [
    //     {
    //         dataField: 'vendor',
    //         text: 'Asia Gaming',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     }, 
    //     {
    //         dataField: 'ctg',
    //         text: '',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     }, 
    //     {
    //         dataField: 'gameName',
    //         text: '',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    //     {
    //         dataField: 'turnOver',
    //         text: '10000',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    //     {
    //         dataField: 'winLost',
    //         text: '-5000',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    // ];

    // const columns2 = [
    //     {
    //         text: "",
    //         dataField: "",
    //         keyField: "no",
    //         headerAttrs: {
    //             hidden: false
    //           },
    //         headerStyle: () => {
    //         return { width: "60px" };
    //         },
    //     },
    //     {
    //         dataField: 'vendor',
    //         text: 'Asia Gaming',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     }, 
    //     {
    //         dataField: 'ctg',
    //         text: '',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     }, 
    //     {
    //         dataField: 'gameName',
    //         text: '',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    //     {
    //         dataField: 'turnOver',
    //         text: '10000',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    //     {
    //         dataField: 'winLost',
    //         text: '-5000',
    //         headerAttrs: {
    //             hidden: true
    //         }
    //     },
    // ]
    
    // const expandRow = {
    //     showExpandColumn: true,
    //     onlyOneExpanding: true,
    //     nonExpandable: [223, 222],
    //     renderer: row => (
    //       <div className="font-weight-bold first-expand-row">
    //         <BootstrapTable keyField='id' data={ products } columns={ columns } expandRow={ secondExpandRow }/>
    //       </div>
    //     ),
    //     expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    //         if (isAnyExpands) {
    //           return (
    //             <b className="row-expand-btn active">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
    //                 </svg>
    //             </b>
    //           );
    //         }
    //         return (
    //             <b className="row-expand-btn">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    //                 </svg>
    //             </b>
    //         );
    //       },
    //     expandColumnRenderer: ({ expanded }) => {
    //         if (expanded) {
    //             return (
    //             <b className="row-expand-btn active">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
    //                 </svg>
    //             </b>
    //             );
    //         }
    //         return (
    //             <b className="row-expand-btn">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    //                 </svg>
    //             </b>
    //         );
    //     }
    // };

    // const secondExpandRow = {
    //     showExpandColumn: true,
    //     onlyOneExpanding: true,
    //     renderer: row => (
    //       <div className="font-weight-normal second-expand-row">
    //           {
    //               row.id === 1? <BootstrapTable keyField='id' data={ products2 } columns={ columns2 }/> : 
    //               row.id === 2? <BootstrapTable keyField='id' data={ products3 } columns={ columns2 }/> : 
    //               row.id === 3 ? <BootstrapTable keyField='id' data={ products4 } columns={ columns2 }/> : ""
    //           }
    //       </div>
    //     ),
    //     expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    //         if (isAnyExpands) {
    //           return <b></b>
    //         }
    //         return <b></b>
    //       },
    //     expandColumnRenderer: ({ expanded }) => {
    //         if (expanded) {
    //             return (
    //             <b className="row-expand-btn active">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
    //                 </svg>
    //             </b>
    //             );
    //         }
    //         return (
    //             <b className="row-expand-btn">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
    //                 </svg>
    //             </b>
    //         );
    //     }
    // }
//END OF EXPAND ROW


    return (
        <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
                <div className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">

                    {!props.hideSearchBar && (
                            <div className="col-6 col-md-6">
                                <div className="dataTables_filter">
                                    {/* {props.loading === true ? (
                                        <Skeleton />
                                    ) : (
                                        <SearchInput />
                                    )} */}
                                </div>
                            </div>
                        )}

                        <div className={`${!props.hideSearchBar?"col-6":"col-12"} d-flex justify-content-end mb-3`}>
                            {(() => {
                                if (props.loading === true) {
                                    return <Skeleton />;
                                } else {
                                    if (props.hideSizePerPage !== undefined && props.hideSizePerPage === true) {
                                        return null;
                                    } else {
                                        return <SizePerPageDropdownStandalone {...paginationProps} />;
                                    }
                                }
                            })()}
                        </div>

                        {/* <div className="col-sm-12 col-md-6 mb-3">{props.loading === true ? <Skeleton /> : <SizePerPageDropdownStandalone {...paginationProps} />}</div> */}
                    </div>
                    <div className="row" style={{overflowX:'auto'}}>
                        <div className="col-md-12">
                            <BootstrapTable
                                responsive="md"
                                bootstrap4
                                remote={true}
                                classes={props.classValue === undefined ? "text-md-nowrap dataTable no-footer" : props.classValue}
                                bordered={false}
                                keyField={props.keyField}
                                data={props.data}
                                columns={props.columns}
                                // expandRow={ props.expandRow? expandRow: {} }//dropdown row
                                expandRow={ props.expandRow ? props.expandRow: {} }//dropdown row
                                {...paginationTableProps}
                                loading={props.loading === true ? <Skeleton /> : false}
                                onTableChange={() => {}}
                            />
                        </div>
                    </div>
                    <div className="pagination__container">

                        {(() => {
                            if (props.loading === true) {
                                return <Skeleton />;
                            } else {
                                if (props.hideTotalRecords !== undefined && props.hideTotalRecords === true) {
                                    return null;
                                } else {
                                    return <PaginationTotalStandalone {...paginationProps} />;
                                }
                            }
                        })()}

                        {/* {props.loading === true ? <Skeleton /> : <PaginationTotalStandalone {...paginationProps} />} */}

                        {(() => {
                            if (props.loading === true) {
                                return <Skeleton />;
                            } else {
                                if (props.hidePagination !== undefined && props.hidePagination === true) {
                                    return null;
                                } else {
                                    return <PaginationListStandalone {...paginationProps} />;
                                }
                            }
                        })()}

                    </div>

                    {/* {props.loading === true ? <Skeleton /> : <PaginationListStandalone {...paginationProps} />} */}
                </div>
            )}
        </PaginationProvider>
    );
};
export default withNamespaces("translation")(DataTable);

DataTable.propTypes = {
    activePage: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    paginationHandler: PropTypes.func.isRequired,
    sizePerPageHandler: PropTypes.func.isRequired,
    searchChangeHandler: PropTypes.func,
    searchValue: PropTypes.string,
    searchSubmitHandler: PropTypes.func,
    searchPressSubmitHandler: PropTypes.func,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    hidePagination: PropTypes.bool,
    hideSearchBar: PropTypes.bool
};
