import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import '../../assets/css/simple-line-icons.scss';
import '../../assets/css/treeview.css';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { filterErrorHandler } from '../../hoc/Shared/utility';
import Button from '../../components/Button/Button';

import * as actions from '../../store/ducks/role/actions';

class RoleDetails extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            loading: true,
            role: {},
            module: [],
        };
    }

    componentDidMount() {
        // console.log(this.props.selectId);
        this.props.funcGetDetails(this.props.selectId);
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                this.setState({
                    role: nextProps.details.data.role,
                    module: nextProps.details.data.module,
                    loading: false
                });
            }
            else {
                this.setState({
                    role: {},
                    module: [],
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }
        return true;
    }

    render() {
        // console.log(this.state.permission);
        const { t } = this.props;
        let rsRole = (this.state.role[0]) ? this.state.role[0] : [];
        let mainModule = (this.state.module.mainModule) ? this.state.module.mainModule : [];
        let subModule = (this.state.module.subModule) ? this.state.module.subModule : [];
        let sonModule = (this.state.module.sonModule) ? this.state.module.sonModule : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('role.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('role.role-details')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <table className='table table-bordered table-hover table-striped'>
                                        <tbody>
                                            <tr>
                                                <th style={{ width: '150px' }}>{t('role.id')}</th>
                                                <td>{rsRole.id}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('role.title')}</th>
                                                <td>{t(`role-type.${rsRole.slug}`)}</td>
                                                {/* <td>(rsRole.title) && t(`role.${rsRole.title}`)</td> */}
                                            </tr>
                                            <tr>
                                                <th>{t('role.status')}</th>
                                                <td><span className={`badge-pill badge-${(rsRole.status === 1) ? 'success' : 'danger'}`}>{(rsRole.status && rsRole.status === 1) ? t('global.active') : t('global.inactive')}</span></td>
                                            </tr>
                                            {/* <tr>
                                            <th>{t('role.description')}</th>
                                            <td>{rsRole.description}</td>
                                        </tr> */}
                                            <tr>
                                                <th>{t('role.create-date')}</th>
                                                <td>{rsRole.created_at}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('role.update-date')}</th>
                                                <td>{rsRole.updated_at}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('role.update-by')}</th>
                                                <td>{rsRole.update_by}</td>
                                            </tr>
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-12 col-xl-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('role.permission')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                {this.state.loading
                                    ?
                                    <Skeleton count={3} />
                                    :
                                    <>
                                        <ul id='tree1' className='tree'>
                                            {
                                                mainModule.map((main, idx) => {
                                                    // let menuContent = '';
                                                    let subMenuContentUl = [];
                                                    let subMenuContentLi = [];

                                                    if (subModule[main.id]) {
                                                        subModule[main.id].forEach((sub, idx1) => {
                                                            let sonMenuContentLi = [];
                                                            let sonMenuContentUl = [];
                                                            if (sonModule[sub.id]) {
                                                                let count = 0;
                                                                let br = '';
                                                                sonModule[sub.id].forEach((son, idx2) => {
                                                                    br = (count >= 5) ? <br /> : '';
                                                                    // sonMenuContentLi.push(<li key={idx2}><input type='checkbox' checked={(son.role_id) ? true : false} /> {t(`role.${son.title}`)}&nbsp;</li>);
                                                                    // sonMenuContentLi.push(<span key={idx2}><input type='checkbox' checked={(son.role_id) ? true : false} onChange={()=>{}}/> {t(`role.${son.title}`)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{br}</span>);
                                                                    sonMenuContentLi.push(
                                                                        <span key={idx2}>
                                                                            <div className="yl-checkbox-container">
                                                                                {(son.role_id) ? <><input type='checkbox' name={`${son.title}`} checked={(son.role_id) ? true : false} onChange={() => { }} />&nbsp;</> : <i className="fas fa-times" style={{ color: "red" }}></i>}
                                                                                <label className="margin-r" for={`${son.title}`}>{t(`role.${son.title}`)}</label>
                                                                            </div>

                                                                        </span>
                                                                    );
                                                                    count = (count >= 5) ? 0 : count + 1;
                                                                });
                                                                // sonMenuContentUl.push(<ul key={idx1}>{sonMenuContentLi}</ul>);
                                                                sonMenuContentUl.push(<ul key={idx1}><li key={idx1}>{sonMenuContentLi}</li></ul>);
                                                            }
                                                            subMenuContentLi.push(<li key={idx1}><i className='fas fa-angle-down'></i> {t(`side-bar.${sub.title}`)}{sonMenuContentUl}</li>);
                                                        });
                                                        subMenuContentUl.push(<ul key={idx}>{subMenuContentLi}</ul>);
                                                    }
                                                    return (
                                                        <li key={idx}>
                                                            <i className='fas fa-angle-down'></i> {t(`side-bar.${main.title}`)}
                                                            {subMenuContentUl}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.role.details,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RoleDetails));