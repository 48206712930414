import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const floatImageReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_FLOATING_IMAGE]: (state = initialState, action) => ({
        ...state,
        floating_image_data: action.payload
    }),

    [type.POST_FLOATING_IMAGE]: (state, action) => ({
        ...state,
        post_floating_image_result: action.payload,
    }),

    [type.SHOW_FLOATING_IMAGE]: (state, action) => ({
        ...state,
        show_floating_image_data: action.payload,
    }),

    [type.UPDATE_FLOATING_IMAGE]: (state, action) => ({
        ...state,
        update_floating_image_result: action.payload,
    }),

    [type.UPDATE_STATUS_FLOATING_IMAGE]: (state, action) => ({
        ...state,
        update_status_floating_image_result: action.payload,
    }),

    [type.DELETE_FLOATING_IMAGE]: (state, action) => ({
        ...state,
        delete_floating_image_result: action.payload,
    }),
});

export default floatImageReducer;