import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// import Swal from 'sweetalert2';
import moment from 'moment';

import { filterErrorHandler } from "../../hoc/Shared/utility";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import WithdrawalListing from './WithdrawalListing';
import WithdrawalEdit from './WithdrawalEdit';

import * as actions from '../../store/ducks/withdrawal/actions';
// import * as actions_cty from '../../store/ducks/country/actions';
import MemberShow from '../Members/MemberShow/MemberShow';

const initialInputState =
{
    /* form input value */
    order_id: '',
    email: '',
    status: '',
    bank_acc_no: '',
    min_amount: '',
    max_amount: '',
    transaction_date: '',
    start_date: moment().subtract(6, 'days').format('YYYY/MM/DD')+" 12:00:00 AM",
    end_date: moment().format('yyyy/MM/DD')+" 11:59:59 PM",
    member_country: '',
};

initialInputState.transaction_date = initialInputState.start_date + " - " + initialInputState.end_date;

class Withdrawal extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                status: [],
            },

            // countryDropDown:
            // {
            //     country: [],
            // },

            /* transction details parameter */
            detailsParam: {},

            /* skeleton loading */
            loading: true,

            /* detect new transaction */
            // intervalTime: this.props.currentUser.system_setting.withdrawal_refresh_time || 20,
            
            // redirect member profile
            redirectParam: {}
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
        this.props.funcGetDropDown(); // get filter drop down
        // this.props.funcGetCountryDropDown();
        // this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
    }

    componentWillUnmount() 
    {
        // clearInterval(this.intervalId);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        // console.log(nextProps.rs.data.result.row);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                this.setState({
                    activePage: 1,
                    data: [],
                    totalRecords: 0,
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.rs.message);
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        status: nextProps.dropDown.data.status,
                        transactionChannel: nextProps.dropDown.data.transactionChannel
                    }
                });
            }
            else
            {
                this.setState({
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.dropDown.message);
                });
            }
        }
        
        // if (nextProps.countryDropDown !== this.props.countryDropDown && nextProps.countryDropDown!==undefined)
        // {
        //     if(nextProps.countryDropDown.responseCode === 200 && nextProps.countryDropDown.msgType === 'success')
        //     {   
        //         this.setState({
        //             countryDropDown:
        //             {
        //                 country: nextProps.countryDropDown.data.country_dropdown,
        //             }
        //         });
        //     }
        //     else
        //     {
        //         this.setState({
        //             loading: false,
        //         }, () => 
        //         {
        //             filterErrorHandler(nextProps.countryDropDown.message);
        //         });
        //     }
        // }
        
        // if (nextProps.sysAlert !== this.props.sysAlert && nextProps.sysAlert!==undefined)
        // {
        //      if(nextProps.sysAlert.responseCode === 200 && nextProps.sysAlert.msgType === 'success')
        //      {
        //         if(nextProps.sysAlert.data.alert)
        //         {
        //             const { t } = this.props;
        //             Swal.fire({
        //                 icon: 'warning',
        //                 text: t('transaction.new-order'),
        //                 showConfirmButton: true
        //             }).then(() =>
        //             {
        //                 this.refreshHandler();
        //             });
        //         }
        //      }
        //      else
        //      {
        //          this.setState({
        //              loading: false,
        //          }, () => 
        //          {
        //              filterErrorHandler(nextProps.sysAlert.message);
        //          });
        //      }
        // }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    // sysAlert = () =>
    // {
    //     const listingArr = {};
    //     listingArr.name = 'withdrawal';
    //     this.props.funcSysAlert(listingArr);
    // }

    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='transaction_date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),            
            filter: newFilter,
            loading: true,
            data: [],
            order: ''
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    detailsHandler = (e, id) =>
    {
        // console.log('detailsHandler (transaction): ', id);
        e.preventDefault();
        // clearInterval(this.intervalId);
        this.setState({
            pageShow: 'edit',
            detailsParam :
            {
                id: id,
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: ''
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        // listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        // if(page==='listing')
        // {
        //     this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
        // }
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect member profile
    redirectHandler = (e, id, name) => {
        const filteredRows = this.props.rs.data.result.row.filter(row => row.email === name);
        e.preventDefault();
        this.setState({
            pageShow: "redirect",
            redirectParam: {
                id: id,
                name: name, // parse email when redirect
                shortcode: filteredRows[0]['shortcode'],
            }
        });
    }
   
    render()
    { 
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <WithdrawalListing
                        /* filter input */
                        filter={this.state.filter}
       
                        /* setstate function */
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        
                        /* filter drop down */
                        ddStatus={this.state.dropDown.status}
                        // ddCountry={this.state.countryDropDown.country}
                        ddTransactionChannel={this.state.dropDown.transactionChannel}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        // currentUser={this.props.currentUser}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <WithdrawalEdit
                        detailsParam={this.state.detailsParam}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='redirect' &&
                    <MemberShow
                        memberShowId={this.state.redirectParam.id}
                        memberShowUsername={this.state.redirectParam.name}
                        memberShowCountry= {this.state.redirectParam.shortcode}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.props.currentUser.permission ?? []}
                        memberShowCount={0}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.withdrawal.rs,
        dropDown: state.withdrawal.dropDown,
        // countryDropDown: state.country.country_dropdown,
        // sysAlert: state.withdrawal.sysAlert,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        // funcGetCountryDropDown: () => dispatch(actions_cty.getCountryDropdown()),
        // funcSysAlert: (filter) => dispatch(actions.sysAlert(filter)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Withdrawal));