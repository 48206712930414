import axios from 'axios';
import Swal from 'sweetalert2';

//Set the axios default url based on your development machines url
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DEVELOPMENT_URL + 'api/admin/'
    // baseURL: 'http://localhost:8000/api/'
});

instance.defaults.headers.common['X-Authorization'] = process.env.REACT_APP_X_AUTHORIZATION_KEY;
instance.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem('accessToken');

instance.interceptors.request.use(request => {
    request.headers.Authorization = 'Bearer '+ localStorage.getItem('accessToken');
    request.headers.lang = (localStorage.getItem('lang') || 'en');
    // console.log('interceptors request: ',request);
    return request;
}, error => {
    alert(error.request.data.message);
});

instance.interceptors.response.use(response => {
    // console.log('interceptors response: ',response);
    return response;
}, error => {
    // If the response api got returned error message just take a error message , if not take the return data error message
    //console.log(error.request.response);
    let forbidden = JSON.parse(error.request.response);
    if(forbidden.responseCode === 403){
        window.location.href = "/forbidden";
        return Promise.reject(error.response);
    }
    
    let responseMsg = '';
    const currentLang = localStorage.getItem('lang') || 'en';
    const translationTitle = { "en": "Error", "zh_cn": "网络", "ms": "Kesilapan", "th": "ข้อผิดพลาด", "id": "Kesalahan", "vi": "lỗi", "us": "Error"};
    const translationText = { "en": "Network Error", "zh_cn": "网络错误", "ms": "Rangkaian Kesilapan", "th": "ข้อผิดพลาดของเครือข่าย", "id": "Kesalahan Jaringan", "vi": "lỗi mạng", "us": "Network Error"};
    const translationButton = { "en": "OK", "zh_cn": "好的", "ms": "Okey", "th": "ตกลง", "id": "Oke", "vi": "VÂNG", "us": "OK"};
    if(error.request.response)
    {
        let msg = JSON.parse(error.request.response);
        responseMsg = msg.message[0];
    }
    else
    {
        responseMsg = translationText[currentLang];
        // responseMsg = error;
    }
    if(error.response &&
        (error.response.data.message[0] === "Token is Incompatible" ||
        error.response.data.message[0] === "Token is Expired" ||
        error.response.data.message[0] === "Token is Invalid"))
    {
        localStorage.clear();
        window.location.href= "/login";
    }
    else
    {
        Swal.fire({
            title: translationTitle[currentLang],
            text: responseMsg,
            icon: 'error',
            confirmButtonText: translationButton[currentLang]
        });
    }
    return Promise.reject(error.response);
});

export default instance;