import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getDropDown = () => (dispatch, getState) =>
{
  let data = {};
  data.country_id = getState().currentUser.data.country_id;
    axios.get('member_rebate/drop_down', {params: data})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getList = (props) => dispatch => 
{
    axios.get('member_rebate/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getHistory = (props) => dispatch => 
{
    axios.get('member_rebate/rebate_history?', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.HISTORY,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getRollover = (props) => dispatch =>
{
    axios.get('member_rebate/rollover_details', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.ROLLOVER_DETAILS,
        value: response.data
      })
    })
    .catch(function (error) 
    {
      
    });
};

export const store = (data) => dispatch =>
{
    axios.post('member_rebate/submit_rebate', data)
    .then(function (response) 
    {
      // console.log(response);
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};