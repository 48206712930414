import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import { formValidation } from "../../hoc/Shared/utility";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import StringInput from '../../components/Input/StringInput/StringInput';

import { getCategoryDropdown, getTeamsDropdown, showEventLeague, updateEventLeague } from "../../store/ducks/eventLeague/actions";
import Skeleton from "react-loading-skeleton";

class EventLeagueEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            categoryLoading: true,
            teamsLoading: true,
            title: "",
            slug: "",
            category: [],
            teams: [],
            touched: false,
            error_message: [],
            existingTeams: [],
            selectedCategory: null,
            checkboxList: {},
            checkAll: false
        }
    }

    componentDidMount(){
        formValidation("event_league_form");
        this.props.getCategoryDropdown();

        if(this.props.editId){
            const data = {};
            data.id = this.props.editId;
            this.props.showEventLeague(data);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.categoryDropdown !== undefined && nextProps.categoryDropdown !== this.props.categoryDropdown){
            if(nextProps.categoryDropdown.data.responseCode === 200 && nextProps.categoryDropdown.data.msgType === "success"){
                this.setState({
                    categoryLoading: false,
                    category: nextProps.categoryDropdown.data.data.category
                });
            }
        }

        if(nextProps.teamsDropdown !== undefined && nextProps.teamsDropdown !== this.props.teamsDropdown){
            if(nextProps.teamsDropdown.data.responseCode === 200 && nextProps.teamsDropdown.data.msgType === "success"){
                let tmp = [];
                nextProps.teamsDropdown.data.data.teams.forEach((val) => {
                    tmp[`cb_${val.id}`] = false;

                    if(this.state.existingTeams.length > 0){
                        if(this.state.existingTeams.includes(val.id)){
                            tmp[`cb_${val.id}`] = true;
                        }
                    }
                });

                this.setState({
                    teamsLoading: false,
                    teams: nextProps.teamsDropdown.data.data.teams,
                    checkboxList: tmp
                });
            }
        }

        if(nextProps.retrievedEventLeague !== undefined && nextProps.retrievedEventLeague !== this.props.retrievedEventLeague){
            if(nextProps.retrievedEventLeague.data.responseCode === 200 && nextProps.retrievedEventLeague.data.msgType === "success"){
                this.setState({
                    loading: false,
                    title: nextProps.retrievedEventLeague.data.data.league.title,
                    slug: nextProps.retrievedEventLeague.data.data.league.slug,
                    selectedCategory: nextProps.retrievedEventLeague.data.data.category,
                    existingTeams: nextProps.retrievedEventLeague.data.data.teams,
                    teamsLoading: true
                }, () => {
                    this.fetchData(this.state.selectedCategory);
                });
            }
        }

        if(nextProps.updatedEventLeague !== this.props.updatedEventLeague){
            if(nextProps.updatedEventLeague.data.responseCode === 200){
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.updatedEventLeague.data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            }
            else if(nextProps.updatedEventLeague.data.responseCode === 422){
                this.setState({
                    touched: true,
                    error_message: nextProps.updatedEventLeague.data.message
                });
                Swal.close();
            }
            else{
                return false;
            }
        }

        return true;
    }

    fetchData = (id) => {
        const data = {};
        data.event_team_category_id = id;
        this.props.getTeamsDropdown(data);
    }

    changeInputHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeSelectHandler = (e) => {
        this.setState({
            selectedCategory: e.target.value,
            teamsLoading: true,
            checkboxList: {},
            checkAll: false
        });

        this.fetchData(e.target.value);
    }

    changeCheckboxHandler = (event, id) => {
        let checkBoxes = Object.assign({}, this.state.checkboxList);

        for (const key in checkBoxes) {
            if(key.includes('cb_')) {
                let tmp = key.split('_');
                if(tmp[1] === id.toString()) {
                    checkBoxes[`cb_${id}`] = event.target.checked;
                }
            }
        }

        this.setState({
            checkboxList: checkBoxes
        });
    }

    checkAllHandler = (event) => {
        let checkVal = (event.target.checked) ? true : false;
        let checkBoxes = Object.assign({}, this.state.checkboxList);

        for (const key in checkBoxes) {
            if(key.includes('cb_')) {
                checkBoxes[key] = checkVal;
            }
        }

        this.setState({
            checkboxList: checkBoxes,
            checkAll: checkVal
        });
    }

    submitHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let checkBoxes = Object.assign({}, this.state.checkboxList);
                let submitCheckBoxes = [];

                for (const key in checkBoxes){
                    if(key.includes('cb_')) {
                        let tmp = key.split('_');
                        if(checkBoxes[`cb_${tmp[1]}`] === true){
                            if(!submitCheckBoxes.includes(tmp[1])){
                                submitCheckBoxes.push(tmp[1]);
                            }
                        }
                    }
                }
                
                let data = {};
                data.id = this.props.editId;
                data.title = this.state.title;
                data.slug = this.state.slug;
                data.event_team_category_id = this.state.selectedCategory;
                data.event_team_arr = submitCheckBoxes;
                data.type = "update";

                // console.log("Form Submit:", data);
                this.props.updateEventLeague(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        let checkList = Object.assign({}, this.state.checkboxList);
        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('event-league.header')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('event-league.edit-event-league')}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='event_league_form' className='form-horizontal row' onSubmit={this.submitHandler} noValidate>
                                    <div className="col-md-12">
                                        <ValidationError formName='event_league_form' touched={this.state.touched} message={this.state.error_message}/>
                                    </div>
                                    {this.state.loading ? 
                                        <Skeleton count={3} />
                                    :
                                    <>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label htmlFor='title'>{t('event-league.title')}</label>
                                                <StringInput
                                                    id='title'
                                                    name='title'
                                                    placeholder={t('event-league.title')}
                                                    value={this.state.title}
                                                    onChange={(event) => this.changeInputHandler(event)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label htmlFor='title'>{t('event-league.slug')}</label>
                                                <StringInput
                                                    id='slug'
                                                    name='slug'
                                                    placeholder={t('event-league.slug')}
                                                    value={this.state.slug}
                                                    onChange={(event) => this.changeInputHandler(event)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label htmlFor='status'>{t('event-league.category')}</label>
                                                <select 
                                                    className='form-control' 
                                                    id='event_team_category_id' 
                                                    name='event_team_category_id' 
                                                    value={this.state.selectedCategory} 
                                                    onChange={(event) => this.changeSelectHandler(event)}>
                                                        <option>{t("global.please-select")}</option>
                                                        {!this.state.categoryLoading && 
                                                            <>
                                                                {this.state.category.map((val) => {
                                                                    return (
                                                                        <option value={val.id}>{val.title}</option>
                                                                    );
                                                                })}
                                                            </>
                                                        }
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div>
                                                <label htmlFor='check_all' style={{cursor: "pointer"}}>{t('event-league.choose-all')}&nbsp;<input type='checkbox' id='check_all' name='check_all' checked={this.state.checkAll} onClick={(event) => this.checkAllHandler(event)}/></label>
                                            </div>
                                            <div className='col-md-12 form-group border rounded'>
                                                <ul id='tree1' className='tree' style={{columns: 4, WebkitColumnCount: 4, MozColumnCount: 4}}>
                                                    {!this.state.teamsLoading && (
                                                        <>
                                                            {this.state.teams.map((val, idx) => {
                                                                return (
                                                                    <li key={idx}>
                                                                        <label htmlFor={`cb_${val.id}`} style={{cursor: "pointer"}}>
                                                                            <input
                                                                                id={`cb_${val.id}`}
                                                                                name={`cb_${val.id}`}
                                                                                type='checkbox'
                                                                                className='child'
                                                                                checked={(checkList[`cb_${val.id}`]) ? checkList[`cb_${val.id}`] : false}
                                                                                onChange={(event) => this.changeCheckboxHandler(event, val.id)}
                                                                            /> {val.title}
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        </label>
                                                                    </li>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='col-12 form-group mb-0 justify-content-end'>
                                            <div>
                                                {(this.props.permission.includes('league_event_update')) &&
                                                <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                            </div>
                                        </div>
                                    </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        categoryDropdown: state.eventLeague.event_category_dropdown,
        teamsDropdown: state.eventLeague.event_teams_dropdown,
        retrievedEventLeague: state.eventLeague.event_league_show,
        updatedEventLeague: state.eventLeague.event_league_update
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryDropdown: () => dispatch(getCategoryDropdown()),
        getTeamsDropdown: (data) => dispatch(getTeamsDropdown(data)),
        showEventLeague: (data) => dispatch(showEventLeague(data)),
        updateEventLeague: (data) => dispatch(updateEventLeague(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EventLeagueEdit));