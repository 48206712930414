import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";
// import Skeleton from "react-loading-skeleton";

import * as actions from '../../store/ducks/agentSetting/actions';

class AgentSettingCreate extends Component 
{
    constructor() 
    {
        super();
        this.state = 
        {
            setting_list: [{listIndex: Math.random(), percentage: "", min: "", max: ""}],
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() 
    {
        formValidation("agent_setting_form");
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.store !== this.props.store && nextProps.store!==undefined)
        {
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if(nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store.message
                });
                Swal.close();
            }
        }

        return true;
    }

    submitHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
                });
                let key_array = [];
                let value_array = [];

                this.state.setting_list.forEach((item, idx) => 
                {
                    key_array.push(item.percentage);
                    value_array.push(`${item.min}:${item.max}`);
                })

                let updateSystemSettingData = {};
                updateSystemSettingData.key = key_array;
                updateSystemSettingData.value = value_array;
                // console.log("Form Submit:", updateSystemSettingData);
                this.props.funcStore(updateSystemSettingData);
            }
        });
    };

    addRecord = () => 
    {
        if(this.state.setting_list.length < 20) 
        {
            this.setState((prevState) => ({
                setting_list: [...prevState.setting_list, {listIndex: Math.random(), percentage: "", min: "", max: ""}],
            }));
        }
    };

    removeRecord = (i) => 
    {
        this.setState({
            setting_list: this.state.setting_list.filter((item, idx) => i !== idx)
        })
    }

    handleSettingListChange = (event) => 
    {
        let setting_list = [...this.state.setting_list];
        setting_list[event.target.dataset.id][event.target.name] = event.target.value;
        this.setState({ setting_list});
    }

    render() 
    {
        const { t } = this.props;
        
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("side-bar.agent_setting")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("agent-setting.add-setting")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="agent_setting_form" className="form-horizontal" onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName="agent_setting_form" touched={this.state.touched} message={this.state.error_message} />

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h3 className="card-title col-8">&nbsp;</h3>
                                                <div className="row col-4 justify-content-end mb-2 pr-0">
                                                    <button type="button" className="btn btn-info" onClick={(e) => this.addRecord(e)}>
                                                        <i className="fa fa-plus"></i> {t('agent-setting.add')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{overflowY:'auto'}}>
                                                <table className="table" id="setting_table" style={{minWidth:500}}>
                                                    <thead className="text-center bg-primary">
                                                        <tr>
                                                            <th className="text-white p-2">{t("global.no")}</th>
                                                            <th className="text-white p-2">{t("agent-setting.percentage")}</th>
                                                            <th className="text-white p-2">{t("agent-setting.min")}</th>
                                                            <th className="text-white p-2">{t("agent-setting.max")}</th>
                                                            <th style={{ width: "5%" }}>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.setting_list.map((item, idx) => {
                                                            return (
                                                                <tr key={idx} className="text-center">
                                                                    <td>{idx + 1}</td>
                                                                    <td key={`percentage-${idx}`}>
                                                                        <input
                                                                            id="percentage"
                                                                            name="percentage"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("agent-setting.percentage")}
                                                                            value={item.percentage}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td key={`min-${idx}`}>
                                                                        <input
                                                                            id="min"
                                                                            name="min"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("agent-setting.min")}
                                                                            value={item.min}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td key={`max-${idx}`}>
                                                                        <input
                                                                            id="max"
                                                                            name="max"
                                                                            data-id={idx}
                                                                            className="form-control"
                                                                            placeholder={t("agent-setting.max")}
                                                                            value={item.max}
                                                                            onChange={this.handleSettingListChange}
                                                                            autoComplete="off"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <i className="fa fa-trash" style={{color:'red', cursor:'pointer'}} onClick={() => this.removeRecord(idx)}></i>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => 
{
    return {
        store: state.agentSetting.store,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        funcStore: (data) => dispatch(actions.store(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentSettingCreate));
