import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
// import moment from "moment";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../assets/images/updown-arrow.png'

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";

class ParcelListing extends Component 
{
    /* ============ component lifecycle [START] ============ */
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            showCard: false,
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    
    toggleCard = () => 
    {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() 
    {
        const { t } = this.props;
        let permission = this.props.permission ? this.props.permission : [];
        // let stCls = {
        //     0: "primary",
        //     1: "success",
        //     2: "danger",
        //     3: "secondary",
        //     4: "danger",
        // };

        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            { 
                text: t('transaction.no'), 
                dataField: 'no', 
                formatter: (cell, row, rowIndex) => 
                {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
                    return <span>{rowNumber}</span>;
                }, 
                headerStyle: { width: '60px', textAlign: 'center' },
            },
            { 
                text: t('transaction.order-id'), 
                dataField: 'id', 
                sort: true, 
                onSort: this.props.onSort, 
                headerStyle: { width: "115px" },
                formatter: (cell, row, rowIndex) => 
                {
                    let orderIdVal = permission.includes("parcel_access") ? (
                        <b>
                            <a
                                href="/#"
                                onClick={(event) =>
                                    this.props.detailsHandler(event, 'details', row.id)
                                }
                            >
                                {row.id}
                                <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                            </a>
                        </b>
                    ) : (
                        row.order_id
                    );

                    return orderIdVal;
                },
            },
            { 
                text: t('transaction.member'), 
                dataField: 'username', 
                sort: true, 
                onSort: this.props.onSort ,
                formatter: (cell, row, rowIndex) => 
                {
                    return <b><a href="/#" onClick={(event) => this.props.redirectHandler(event, row.member_id, row.username)}>{row.username}</a></b>;
                },
            },
            {
                text: t("parcel.package-information"),
                dataField: "name",
                formatter: (cell, row, rowIndex) => 
                {
                    return (
                        <div>
                            <div >
                                <div >Tracking No：<span >{row.tracking_no}</span></div> 
                                <div >Size(cm)：<span >{row.size}</span></div>
                                <div >Actual weight：<span >{row.actual_weight}</span></div>
                                <div >Volume  weight：<span >{row.volume_weight}</span></div>
                                <div >Goods Value：<span >{row.price}</span></div>
                                <div >Time Inbound：<span >{row.createad_at}</span></div>
                                <div >Time Added：<span >{row.inbound_at}</span></div>
                            </div>
                        </div>);
                },
            },
            { 
                text: 'Type', 
                dataField: 'parcel_type', 
                sort: true, 
                onSort: this.props.onSort, 
                headerStyle: { width: '120px' },
                formatter: (cell, row, rowIndex) => 
                {
                    return <span>{t(`game-type.${row.parcel_type}`)}</span>;
                },
            },
            {text: 'Product', dataField: 'product', sort: true, onSort: this.props.onSort},
            {text: 'Quantity', dataField: 'quantity', sort: true, onSort: this.props.onSort},
            {
                text: t('global.action'),
                dataField: "#",
                keyField: "action",
                formatter: (cell, row) => 
                {
                    let actionVal =
                    permission.includes("parcel_edit") ? (
                        <div className="btn-group">
                            
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,'details',row.id)}
                                    className="btn btn-sm btn-white rounded"
                                    data-toggle="tooltip"
                                    title={t("global.refresh")}
                                >
                                    <FontAwesomeIcon icon={faCompressArrowsAlt} />
                                </a>
                            
                                <a
                                    href="/#"
                                    onClick={(event) => this.props.detailsHandler(event,'edit',row.id)}
                                    className="btn btn-sm btn-white rounded"
                                    data-toggle="tooltip"
                                    title={t("global.edit")}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </a>
                        </div>
                    ) : (
                        ""
                    );
                    return actionVal;
                },
                headerStyle: () => {
                    return {width: '115px'};
                }
            }


            // { text: t('transaction.transaction-channel'), dataField: 'transaction_channel', sort: true, onSort: this.props.onSort },
            // { text: t('transaction.bank-details'), dataField: 'bank_name', sort: true, onSort: this.props.onSort },
            // { text: t('transaction.reference-code'), dataField: 'reference_code', sort: true, onSort: this.props.onSort, headerStyle: { width: '120px' } },
            // { text: t('transaction.amount'), dataField: 'credit', sort: true, onSort: this.props.onSort, headerStyle: { width: '120px' } },
            // { text: t('transaction.fees'), dataField: "fees", sort: true, onSort: this.props.onSort, headerStyle: { width: '120px' } },
            // { text: t('transaction.status'), dataField: 'status', sort: true, onSort: this.props.onSort, headerStyle: { width: "96px" }, },
            // { text: t('transaction.approval-time'), dataField: 'timediff', sort: true, onSort: this.props.onSort },
            // { text: t('transaction.create-date'), dataField: 'create_date', sort: true, onSort: this.props.onSort },
            // {text: t('transaction.update-date'), dataField: 'update_date', sort: true, onSort: this.props.onSort},
            // {
            //     text: t("transaction.update-by"),
            //     dataField: "update_by",
            //     sort: true,
            //     onSort: this.props.onSort,
            // },
            // { text: t("global.action"), dataField: "action" },
        ];
        
        // if (!this.props.permission.includes("deposit_approve")) {
        //     columns.splice(13, 1);
        // }

        // if (this.props.data) {
        //     rsListing = this.props.data.map((val, idx) => {
        //         let stVal = "";
        //         let orderIdVal = "";
        //         let bankDetails = "";
        //         let userNameVal = "";
        //         const num_format = {
        //             maximumFractionDigits: 2,
        //             minimumFractionDigits: 2,
        //         };

        //         let bankDetailsStr = (val.bank_name !== null) ? `${val.swift_code}: ${val.bank_acc_name}` : '-';
        //         if (val.transaction_channel === 'quick-pay' || val.transaction_channel === 'e-wallet') {
        //             bankDetailsStr = val.payment_type;
        //         }

        //         stVal = (
        //             <span className={`badge-${stCls[val.status]}`}>
        //                 {/* &nbsp;{approvalTimeCls} */}
        //                 {t(`transaction.status-desc.${val.status}`)}{" "}
        //             </span>
        //         );
        //         orderIdVal = this.props.permission.includes("deposit_details_access") ? (
        //             <b>
        //                 <a
        //                     href="/#"
        //                     onClick={(event) =>
        //                         this.props.editHandler(event, val.id)
        //                     }
        //                 >
        //                     {val.id}
        //                     <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
        //                 </a>
        //             </b>
        //         ) : (
        //             val.order_id
        //         );

        //         bankDetails = bankDetailsStr !== null && bankDetailsStr.length > 15 ? bankDetailsStr.substring(0, 15) + "..." : bankDetailsStr;
        //         const status01 = this.props.permission.includes("deposit_approve") && val.transaction_channel === 'bank-transfer'
        //         let actionVal = (status01 || val.transaction_channel === "quick-pay" || val.transaction_channel === "crypto" || val.transaction_channel === "e-wallet") ? (
        //             val.status === 0 || val.status === 3 ? (
        //                 <div className="btn-group">
        //                     {this.props.permission.includes("deposit_approve") && (
        //                         <a
        //                             href="/#"
        //                             onClick={(event) =>
        //                                 this.props.depositApproveHandler(
        //                                     event,
        //                                     val.id,
        //                                     val.credit,
        //                                     val.fees,
        //                                     val.bank_balance,
        //                                     val.transaction_channel
        //                                 )
        //                             }
        //                             className="btn btn-md success-btn"
        //                             data-toggle="tooltip"
        //                             title="approve"
        //                         >
        //                             <FontAwesomeIcon icon={faCheck} />
        //                         </a>
        //                     )}
        //                 </div>
        //             ) : (
        //                 ""
        //             )
        //         ) : (
        //             ""
        //         );
        //         let amtVal =
        //             (val.promotion_transaction_id > 0)
        //                 ?
        //                 <a href="/#" onClick={(event) => this.props.detailsHandler(event, val.promotion_transaction_id)}>
        //                     <span className={`badge-success`}>{new Intl.NumberFormat("en-US", num_format).format(val.credit)}</span>
        //                 </a>
        //                 : new Intl.NumberFormat("en-US", num_format).format(val.credit);
        //         userNameVal = this.props.permission.includes("member_view") ? (
        //             <b>
        //                 <a href="/#" onClick={(event) => this.props.redirectHandler(event, val.member_id, val.username)}>
        //                     {val.username}
        //                 </a>
        //             </b>
        //         ) : (
        //             val.username
        //         );


        //         return {
        //             no: idx,
        //             id: orderIdVal,
        //             username: userNameVal,
        //             // name:
        //             //     (val.name && val.name.length > 15)
        //             //         ? val.name.substring(0, 15) + "..."
        //             //         : val.name,
        //             transaction_channel: t(
        //                 `transaction-channel.${val.transaction_channel}`
        //             ),
        //             bank_name: bankDetails,
        //             credit: amtVal,
        //             status: stVal,
        //             reference_code: val.reference_code,
        //             timediff: val.timediff,
        //             create_date: val.create_date,
        //             // update_date: val.update_date,
        //             update_by: val.update_by,
        //             action: actionVal,
        //             fees: new Intl.NumberFormat('en-US', num_format).format(val.fees),
        //         };
        //     });
        // }
        /* ======================== datatables data assign [END] ======================== */
        // console.log(rsListing);
        
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                Parcel
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"
                                            } filter-wrapper-1`}
                                    >
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.create-date")}
                                                </label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon
                                                            icon={faCalendarCheck}
                                                        />
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        // format='YYYY/MM/DD'
                                                        value={
                                                            this.props.filter
                                                                .transaction_date
                                                        }
                                                        onChange={
                                                            this.props.onInputChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.member")}
                                                </label>
                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    value={this.props.filter.username}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.order-id")}
                                                </label>
                                                <StringInput
                                                    id="order_id"
                                                    name="order_id"
                                                    value={this.props.filter.order_id}
                                                    onChange={this.props.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.status")}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddStatus &&
                                                            this.props.ddStatus.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction.${val.desc}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className='col-6'> */}
                                        <div className="col-md-3 col-6 ">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t(
                                                        "transaction.transaction-channel"
                                                    )}
                                                </label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_channel"
                                                        name="transaction_channel"
                                                        onChange={this.props.onInputChange}
                                                        value={
                                                            this.props.filter
                                                                .transaction_channel
                                                        }
                                                    >
                                                        <option value=""></option>
                                                        {this.props.ddTransactionChannel &&
                                                            this.props.ddTransactionChannel.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option
                                                                            key={val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {t(
                                                                                `transaction-channel.${val.slug}`
                                                                            )}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">
                                                    {t("transaction.amount")}
                                                </label>
                                                <div className="input-group">
                                                    <NumberInput
                                                        id="min_amount"
                                                        name="min_amount"
                                                        value={this.props.filter.min_amount}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.min")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />{" "}
                                                    <span style={{ margin: "auto 5px" }}>
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <NumberInput
                                                        id="max_amount"
                                                        name="max_amount"
                                                        value={this.props.filter.max_amount}
                                                        onChange={this.props.onInputChange}
                                                        placeholder={t("transaction.max")}
                                                        className="filter-input"
                                                        onKeyPress={(evt) =>
                                                            (evt.key === "e" &&
                                                                evt.preventDefault()) ||
                                                            (evt.key === "Enter" &&
                                                                this.props.refreshHandler())
                                                        }
                                                    />
                                                    {/* <input className='form-control filter-input' type='number' name='min_amount' id='min_amount' placeholder={t('transaction.min')} value={this.props.filter.min_amount} onChange={this.props.onInputChange} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }/>
                                                <input className='form-control filter-input' type='number' name='max_amount' id='max_amount' placeholder={t('transaction.max')} value={this.props.filter.max_amount} onChange={this.props.onInputChange} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }/> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className="col mt-auto d-block">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() =>
                                                        this.props.refreshHandler()
                                                    }
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() =>
                                                        this.props.clearHandler()
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className="show-btn"
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="">
                                    <div className="d-flex pb-3">
                                        <h4 className="card-title mg-b-0">
                                            Parcel Listing
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center deposit__table"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(ParcelListing);
