import React from "react";
import '../../assets/css/simple.datepicker.css';
import { Portal } from 'react-overlays'
//import "react-datepicker/dist/react-datepicker.css";

// Official Documentation: https://reactdatepicker.com/

const DatePickerContainer = ({children}) => {
    return (
        <Portal container={document.getElementById('calendar-portal')}>
            {children}
        </Portal>
    )
}

export default DatePickerContainer;