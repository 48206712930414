import React, {Component, Fragment} from 'react';
import {required, validateDisplay} from "../../../hoc/Shared/utility";
import Button from "../../Button/Button";
import PropTypes from 'prop-types';

class StringInput extends Component {
    constructor (props) {
        super (props);

        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage)
        };
    }

    // shouldComponentUpdate (nextProps, nextState, nextContext) {
    //     return nextProps.value !== this.props.value;
    // }


    static getDerivedStateFromProps (nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ){
            let payload = {
                value: nextProps.value,
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if(nextProps.required){
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            return payload;
        }

        return null;
    }

    render() {

        const {id, name, onChange, className, defaultValue, disabled, required, placeholder, minLength, maxLength, onBlur, valid_email, autoComplete, iconName, onSearchChange} = this.props;

        const {value, requiredRules} = this.state;

        //Export the validation classes and message
        const [ requiredClass, requiredMsg ] = validateDisplay(requiredRules);

        return (
            <Fragment>
                <input
                    type="text"
                    id={id}
                    value={value}
                    className={`form-control ${requiredClass} ${className}`}
                    name={name}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    valid_email={valid_email}
                    autoComplete={autoComplete}
                    readOnly
                />

                {iconName &&
                    <div className="searchIcon">
                        <Button
                            typeButton="button"
                            classValue="btn btn-sm"
                            buttonName={iconName}
                            clicked={onSearchChange}
                        />
                    </div>
                }

                {/*Display the message*/}
                <div className="invalid-feedback">
                    {requiredClass !== 'is-valid' && requiredMsg}
                </div>
            </Fragment>
        )
    }
}

export default StringInput;

StringInput.propTypes = {
    id:         PropTypes.string.isRequired,
    name:       PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    defaultValue:   PropTypes.string,
    value:          PropTypes.string,
    className:      PropTypes.string,
    disabled:       PropTypes.bool,
    required:       PropTypes.bool,
    placeholder:    PropTypes.string,
    minLength:      PropTypes.number,
    maxLength:      PropTypes.string,
    onBlur:         PropTypes.func,
    // valid_email:    PropTypes.string,
    // emailMsg:       PropTypes.string,
    requiredMessage: PropTypes.string,
    minLengthMessage: PropTypes.string,
    autoComplete:   PropTypes.string,
    iconName: PropTypes.any
};

StringInput.defaultProps = {
    className: ''
};