import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Skeleton from 'react-loading-skeleton';
// import Swal from 'sweetalert2';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { showMemberGroup } from "../../store/ducks/memberGroup/actions";
import { filterErrorHandler } from '../../hoc/Shared/utility';

class MemberGroupShow extends Component {
    state = {
        group_name: '',
        privilege: '',
        min_payment: 0,
        max_payment: 0,
        up_level_rebate_percentage: 0,
        level: 0,
        created_by: '',
        status: '',
        loading: true
    };

    componentDidMount() {
        console.log(this.props)
        const showMemberGroupData = {};
        showMemberGroupData.member_group_id = this.props.show_member_group_id;
        showMemberGroupData.member_group_setting_id = this.props.show_member_group_setting_id
        this.props.showMemberGroup(showMemberGroupData);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.show_member_group_data !== this.props.show_member_group_data) {
            if (nextProps.show_member_group_data.data.responseCode === 200 && nextProps.show_member_group_data.data.msgType === 'success') {
                let memberGroupData = nextProps.show_member_group_data.data.data;
                let memberGroupSettingData = nextProps.show_member_group_data.data.data.setting;

                this.setState({
                    loading: false,
                    group_name: memberGroupData.memberGroupLevel,
                    privilege: memberGroupData.memberGroupPrivilege,
                    min_payment: memberGroupSettingData !== null ? memberGroupSettingData.minPayment : '',
                    max_payment: memberGroupSettingData !== null ? memberGroupSettingData.maxPayment : '',
                    level: memberGroupData.memberGroupLevel,
                    created_by: memberGroupSettingData !== null ? memberGroupSettingData.createdBy : '',
                    status: memberGroupSettingData !== null ? memberGroupSettingData.status : '',
                    up_level_rebate_percentage: memberGroupSettingData !== null ? memberGroupSettingData.upLevelRebatePercentage : 0
                })
            } else {
                this.setState({
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.show_member_group_data.data.message);
                })
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("member-group.member-group")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member-group.show-member-group")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>{t("member-group.names")}</th>
                                                <td field-key='name'>{t(`global.level.${this.state.group_name}`)}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('member-group.lv')}</th>
                                                <td field-key='lv'>{this.state.level}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('member-group.privilege')}</th>
                                                <td field-key='privilege'>{this.state.privilege}</td>
                                            </tr>

                                            {/* <tr>
                                            <th>{t('member-group.min-turnover')}</th>
                                            <td field-key='min_turnover'>{this.state.min_turnover}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('member-group.max-turnover')}</th>
                                            <td field-key='max_turnover'>{this.state.max_turnover}</td>
                                        </tr> */}

                                            <tr>
                                                <th>{t('member-group.min-shipment-payment')}</th>
                                                <td field-key='min_payment'>{this.state.min_payment}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('member-group.max-shipment-payment')}</th>
                                                <td field-key='max_payment'>{this.state.max_payment}</td>
                                            </tr>

                                            {/* <tr>
                                            <th>{t('member-group.next-level-amount')}</th>
                                            <td field-key='next_level_amount'>{this.state.next_level_amount}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('member-group.maint-level-amount')}</th>
                                            <td field-key='maint_level_amount'>{this.state.maintain_level_amount}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t('member-group.daily-withdrawal-limitation')}</th>
                                            <td field-key='daily-withdrawal-limitation'>{this.state.daily_withdrawal_limitation}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t('member-group.max-withdrawal')}</th>
                                            <td field-key='max-withdrawal'>{this.state.max_withdrawal}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t('member-group.monthly-bonus')}</th>
                                            <td field-key='monthly-bonus'>{this.state.monthly_bonus}</td>
                                        </tr>

                                        */}

                                            <tr>
                                                <th>{t('member-group.up-level-rebate-percentage')}</th>
                                                <td field-key='up-level-rebate-percentage'>{this.state.up_level_rebate_percentage}</td>
                                            </tr>


                                            {/* <tr>
                                            <th>{t('member-group.reward-point')}</th>
                                            <td field-key='reward-point'>{this.state.reward_point}</td>
                                        </tr>

                                        <tr>
                                            <th>{t('member-group.deposit-amount-point')}</th>
                                            <td field-key='deposit-amount-point'>{this.state.deposit_amount_per_point}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t('member-group.designed-account-manager')}</th>
                                            <td field-key='designed-account-manager'>{this.state.designed_account_manager === 1 ? 'YES' : 'NO'}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t('member-group.special-birthday-gift')}</th>
                                            <td field-key='special-birthday-gift'>{this.state.special_birthday_gift === 1 ? 'YES' : 'NO'}</td>
                                        </tr> */}

                                            {/* <tr>
                                            <th>{t("member-group.turnover-weekly-multiplier-title")}</th>
                                            <td field-key="turnover-weekly-multiplier">{this.state.turnover_weekly_multiplier}</td>
                                        </tr> */}

                                            <tr>
                                                <th>{t('member-group.created-by')}</th>
                                                <td field-key='created-by'>{this.state.created_by}</td>
                                            </tr>

                                            {/* <tr>
                                                <th>{t('global.status')}</th>
                                                <td field-key='status'>{this.state.status === 1 ? 'Active' : 'Inactive'}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { memberGroup } = state;

    //Getting the state from the reducers
    return {
        show_member_group_data: memberGroup.show_member_group_data
    }
};

const mapDispatchToProps = {
    showMemberGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberGroupShow));