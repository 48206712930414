import React, { Component } from "react";
import { connect } from "react-redux";
import { showMember } from "../../store/ducks/member/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Select from "../../components/Input/Select/Select";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { filterErrorHandler } from "../../hoc/Shared/utility";

class MemberShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bankIdx: 0,
        };
    }

    componentDidMount() {
        this.props.showMember(this.props.memberShowId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberShow] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.memberShowData !== this.props.memberShowData) {
            if (nextProps.memberShowData.responseCode === 200) {
                Swal.close();
                this.setState({
                    loading: false,
                });
            } else {
                filterErrorHandler(nextProps.memberShowData.message);
            }
        }
        return true;
    }

    handleBankChange = (event) => {
        this.setState({
            bankIdx: Number(event.target.value),
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            // console.log(this.props.showData);
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let memberData = this.props.memberShowData.data.member;
            let memberReferralData = this.props.memberShowData.data.memberReferral;
            let memberBankData = this.props.memberShowData.data.memberBank;
            let memberTransactionData = this.props.memberShowData.data.memberTransaction;
            let memberBankOption = memberBankData.map((item, idx) => {
                return { label: item.bankName, value: idx };
            });
            display = (
                <>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member.member-details")}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div>
                                <div className="btn-group">
                                    <div>
                                        {/* <Button
                                            typeButton="button"
                                            classValue="btn btn-secondary ml-1"
                                            idValue="back"
                                            buttonName={t("global.back-to-list")}
                                            clicked={() => this.props.changePageHandler("listing")}
                                        /> */}
                                        {this.props.fromDisplay === undefined && (
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-secondary"
                                                idValue="back"
                                                buttonName={t("global.back-to-list")}
                                                clicked={() => this.props.changePageHandler("listing")}
                                            />
                                        )}
                                        {this.props.fromDisplay === "edit" && (
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue"
                                                idValue="back"
                                                buttonName={<FontAwesomeIcon icon={faBackward} />}
                                                clicked={() => this.props.changePageHandler(this.props.returnPage)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-sm">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.member-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>{t("member.username")}</th>
                                                <td field-key="username">{memberData.username}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.nickname")}</th>
                                                <td field-key="nickname">{memberData.nickname}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.name")}</th>
                                                <td field-key="name">{memberData.name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.contact-no")}</th>
                                                <td field-key="contact_no">{memberData.contactNo}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.email")}</th>
                                                <td field-key="email">{memberData.email}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.date-of-birth")}</th>
                                                <td field-key="date_of_birth">{memberData.dateOfBirth}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.referral-code")}</th>
                                                <td field-key="referral_code">{memberData.referralCode}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.status")}</th>
                                                <td field-key="status">{memberData.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>{t("member.pause-bet")}</th>
                                                <td field-key="pause_bet">{memberData.pauseBet === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr> */}
                                            <tr>
                                                <th>{t("member.account-type")}</th>
                                                <td field-key="account_type">{memberData.accountType === 1 ? t("member.real") : t("member.tester")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.have-deposit")}</th>
                                                <td field-key="deposit_status">{memberData.depositStatus === 1 ? t("global._yes") : t("global._no")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.member-group")}</th>
                                                <td field-key="member_group">{memberData.memberGroupName}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.email-verified")}</th>
                                                <td field-key="email_verified">{(memberData.emailVerified === "0" || memberData.emailVerified === "") ? t("global._no") : t("global._yes")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.contact-verified")}</th>
                                                <td field-key="member_group">{(memberData.contactVerified === "0" || memberData.contactVerified === "") ? t("global._no") : t("global._yes")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.google-account")}</th>
                                                <td field-key="google_account">{(memberData.googleAccount === "0" || memberData.googleAccount === "") ? t("global.inactive") : t("global.active")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.telegram-account")}</th>
                                                <td field-key="telegram_account">{(memberData.telegramAccount === "0" || memberData.telegramAccount === "") ? t("global.inactive") : t("global.active")}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.register-time")}</th>
                                                <td field-key="register_details">{memberData.createdTime}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("member.last-login")}</th>
                                                <td field-key="last_login">{memberData.lastLogin}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("global.updated-by")}</th>
                                                <td field-key="updated_by">{memberData.updatedBy}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            {memberData.agentCode !== null ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.agent-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.agent-code")}</th>
                                                    <td field-key="agent_code">{memberData.agentCode}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.agent-username")}</th>
                                                    <td field-key="agent_username">{memberData.agentUsername}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.agent-name")}</th>
                                                    <td field-key="agent_name">{memberData.agentName}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.agent-email")}</th>
                                                    <td field-key="agent_email">{memberData.agentEmail}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {memberReferralData !== null ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.referral-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.referral-username")}</th>
                                                    <td field-key="referral_username">{memberReferralData.username}</td>
                                                </tr>

                                                <tr>
                                                    <th>{t("member.referral-name")}</th>
                                                    <td field-key="referral_name">{memberReferralData.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.referral-email")}</th>
                                                    <td field-key="referral_email">{memberReferralData.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {memberBankData.length > 0 ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.member-bank-account-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>{t("member.bank")}</th>
                                                    <td field-key="bank">
                                                        <Select id="status" name="status" options={memberBankOption} onChange={this.handleBankChange} className="form-control" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.bank-account-name")}</th>
                                                    <td id="bank_acc_name" field-key="bank_acc_name">
                                                        {memberBankData[this.state.bankIdx].bankAccName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.bank-account-no")}</th>
                                                    <td id="bank_acc_number" field-key="bank_acc_number">
                                                        {memberBankData[this.state.bankIdx].bankAccNumber}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}

                            {memberTransactionData ? (
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">
                                            <strong>{t("member.member-transaction-details")}</strong>
                                        </h4>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table table-bordered table-hover">
                                            <tbody>
                                            <tr>
                                                    <th>{t("member.total-deposit")}</th>
                                                    <td id="total_deposit" field-key="total_deposit">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalDeposit)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-withdrawal")}</th>
                                                    <td id="total_withdrawal" field-key="total_withdrawal">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalWithdrawal)}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <th>{t("member.total-rebate")}</th>
                                                    <td id="total_rebate" field-key="total_rebate">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalRebate)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("member.total-bonus")}</th>
                                                    <td id="total_bonus" field-key="total_bonus">
                                                        {new Intl.NumberFormat("en-US", num_format).format(memberTransactionData.totalBonus)}
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("member.last-transaction-time")}</th>
                                                    <td id="last_transaction_time" field-key="last_transaction_time">
                                                        {memberTransactionData.lastTransactionTime}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("member.member-details")}</h4>
                            </div>
                        </div>
                        <div className="d-flex my-xl-auto right-content">
                            <div>
                                <div className="btn-group">
                                    <div>
                                        <Skeleton count={1} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-sm">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.member-details")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.agent-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.referral-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.member-bank-account-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>

                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">
                                        <strong>{t("member.member-transaction-details")}</strong>
                                    </h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowData: member.member_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMember: (id) => dispatch(showMember(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberShow));
