import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const adminReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_ADMIN_LIST]: (state = initialState, action) => ({
        ...state,
        admin_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.GET_USER_GROUP]: (state = initialState, action) => ({
        ...state,
        user_group_data: action.payload
    }),


    [type.POST_ADMIN]: (state, action) => ({
        ...state,
        post_admin_result: action.payload,
    }),

    [type.SHOW_ADMIN]: (state, action) => ({
        ...state,
        show_admin_data: action.payload,
    }),

    [type.UPDATE_ADMIN]: (state, action) => ({
        ...state,
        update_admin_result: action.payload,
    }),

    [type.DELETE_ADMIN]: (state, action) => ({
        ...state,
        delete_admin_result: action.payload,
    }),

    [type.UPDATE_STATUS_ADMIN]: (state, action) => ({
        ...state,
        update_status_admin_result: action.payload,
    })

});

export default adminReducer;