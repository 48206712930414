import { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import DataTable from '../../../components/Datatable/DataTable';
import Button from "../../../components/Button/Button";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Skeleton from "react-loading-skeleton";
// import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

class MemberParcelListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;
        let data = [];
        const columns = [
            { text: t("member-parcel.tracking-no"), dataField: 'FullBillCode', sort: false },
            { text: t("member-parcel.item-name"), dataField: 'item_name', sort: false },
            { text: t("member-parcel.weight"), dataField: 'Weight', sort: false },
            { text: t("member-parcel.size"), dataField: 'Size', sort: false },
            { text: t("member-parcel.update-time"), dataField: 'inplaceTime', sort: false },
            { text: t("member-parcel.status"), dataField: 'status', sort: false }
        ]
        if (this.props.data) {
            data = this.props.data.map((val, index) => {
                return {
                    FullBillCode: val.FullBillCode,
                    item_name: val.item_name,
                    Weight: (val.Weight) ? val.Weight.toFixed(2) : "",
                    Size: (val.Size === null ? "-" : val.Size),
                    inplaceTime: (val.createtime === null ? val.inplaceTime : val.createtime),
                    status: (val.is_inplace) ? (<span className='badge-success'>{t('member-parcel.in-storage')}</span>) : (<span className='text-warning'>{t('member-parcel.not-in-storage')}</span>)
                };
            });
        }

        return (
            <Auxiliary>
                <div className='row col-sm'>
                <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="transaction_form"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("member-parcel.parcel-type")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="parcel_type"
                                                        name="parcel_type"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.parcel_type}>
                                                        <option value="0">{t('member-parcel.all')}</option>
                                                        <option value="1">{t('member-parcel.not-in-storage')}</option>
                                                        <option value="4">{t('member-parcel.in-storage')}</option>
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label htmlFor="contact" className="font-weight-bolder">
                                                    {t("member-parcel.tracking-no")}
                                                </label>
                                                <StringInput
                                                    id="tracking_no"
                                                    name="tracking_no"
                                                    type="text"
                                                    value={this.props.filter.tracking_no}
                                                    onChange={this.props.onInputChange}
                                                    className="form-control filter-input"
                                                    onSearchPress={(evt) => evt.key === "Enter" && this.props.refreshHandler()}
                                                />
                                            </div>
                                        </div>

                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </div>

                            {/* <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div> */}
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>{t('member-parcel.subtitle')}</h4>
                                </div>
                            </div>
                            
                            <div className='card-body'>
                                <div className='table-responsive table-hover database'>
                                    <DataTable
                                        keyField="id"
                                        data={data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center member__transaction"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(MemberParcelListing);