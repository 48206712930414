import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import { getCountry } from "../../store/ducks/home/actions";
import { getType, getMemberGroup, getCategory } from "../../store/ducks/promotion/actions";
import { postPromotion } from "../../store/ducks/promotion/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import Select2 from "../../components/Input/Select2/Select2";
import { withNamespaces } from 'react-i18next';
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
// import Lightbox from 'react-image-lightbox';

//Initialize the language array first
let langArr = [];
let typeArry = [];
let categoryArry = [];
// let userMemberArry = [];

// let formData = new FormData();
let dt = new Date();

class PromotionCreate extends Component {
    state = {
        image_preview: [],
        featured_image_preview: [],
        // promo_details_image_preview: [],
        promo_banner_image_preview: [],
        mobile_image_preview: [],
        mobile_banner_image_preview: [],

        image: [],
        title: [],
        content: [],
        sub_content: [],
        featured_image: [],
        promo_details_image: [],
        promo_banner_image: [],
        mobile_image: [],
        mobile_banner_image: [],


        start_time: dt.setHours(dt.getHours() + 2, 0),
        end_time: dt.setHours(dt.getHours() + 3, 0),
        sequence: '',
        // extra: '',
        type: [],
        category: [],
        min_deposit: '',
        max_payout: '',
        percentage: '',
        turnover_multiply: '',
        valid_period: '',
        entitle_rebate: 0,
        currentStatus: 1,
        currentShow: 1,
        currentGroupName: [],
        loading: true,
        events_redirect_url: ''

    };

    componentDidMount() {
        formValidation('promotion_form');

        const countryData = {};
        this.props.getMemberGroup();
        this.props.getType();
        this.props.getCategory();
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_promotion_result !== this.props.post_promotion_result) {
            if (nextProps.post_promotion_result.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_promotion_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.post_promotion_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_promotion_result.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.post_promotion_result.message[0],
                    icon: nextProps.post_promotion_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            } else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        // if(nextProps.member_group_data !== this.props.member_group_data) {
        //     if (nextProps.member_group_data.data.responseCode === 200) {
        //         Swal.close();
        //         userMemberArry = [];
        //         let data = nextProps.member_group_data.data.data;
        //         Object.keys(data).map((item, i) => {
        //             return userMemberArry.push({
        //                 'label': data[item].group_name,
        //                 'value': data[item].Id,
        //                 'key': data[item].Id,
        //             });
        //         })
        //     }
        // }

        if (nextProps.type_data !== this.props.type_data) {
            if (nextProps.type_data.data.responseCode === 200) {
                const { t } = this.props;
                Swal.close();
                typeArry = [];
                let data = nextProps.type_data.data.data;
                Object.keys(data).map((item, i) => {
                    return typeArry.push(
                        {
                            'label': t('promotion-type.' + data[item].slug),
                            'value': data[item].Id,
                            'key': data[item].Id,
                        });
                })
            }
        }

        if (nextProps.category_data !== this.props.category_data) {
            if (nextProps.category_data.data.responseCode === 200) {
                const { t } = this.props;
                Swal.close();
                categoryArry = [];
                let data = nextProps.category_data.data.data;
                Object.keys(data).map((item, i) => {
                    return categoryArry.push({
                        // 'label': data[item].title,
                        'label': t('promotion-type.' + data[item].title),
                        'value': data[item].Id,
                        'key': data[item].Id,
                    });
                })
            }
        }
        return true;
    }


    postPromotionHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                // const currentGroupName = this.state.currentGroupName;
                const currentCategory = this.state.category;
                const image = this.state.image;
                const title = this.state.title;
                const content = this.state.content;
                const subContent = this.state.sub_content;
                const featuredImage = this.state.featured_image;
                const promoDetailsImage = this.state.promo_details_image;
                const promoBannerImage = this.state.promo_banner_image;
                const mobileImage = this.state.mobile_image;
                const mobileBannerImage = this.state.mobile_banner_image;

                // currentGroupName.forEach(itemGroupName => {
                //     formData.append("member_group[]", itemGroupName.value);
                // });
                if (currentCategory !== "" && currentCategory !== null) {
                    currentCategory.forEach(itemGroupName => {
                        formData.append("category[]", itemGroupName.value);
                    });
                }

                image.forEach((val, idx) => {
                    formData.append("image[" + idx + "]", val);
                });
                featuredImage.forEach((val, idx) => {
                    formData.append("featured_image[" + idx + "]", val);
                });
                promoDetailsImage.forEach((val, idx) => {
                    formData.append("promo_details_image[" + idx + "]", val);
                });
                title.forEach((val, idx) => {
                    formData.append("title[" + idx + "]", val);
                });
                content.forEach((val, idx) => {
                    formData.append("content[" + idx + "]", val);
                });
                subContent.forEach((val, idx) => {
                    formData.append("sub_content[" + idx + "]", val);
                });
                promoBannerImage.forEach((val, idx) => {
                    formData.append("promo_banner_image[" + idx + "]", val);
                });
                mobileImage.forEach((val, idx) => {
                    formData.append("mobile_image[" + idx + "]", val);
                });
                mobileBannerImage.forEach((val, idx) => {
                    formData.append("mobile_banner_image[" + idx + "]", val);
                });

                // formData.append("extra",this.state.extra);
                formData.append("percentage", this.state.percentage);
                formData.append("entitle_rebate", this.state.entitle_rebate);
                formData.append("turnover_multiply", this.state.turnover_multiply);
                formData.append("valid_period", this.state.valid_period);
                formData.append("min_deposit", this.state.min_deposit);
                formData.append("max_payout", this.state.max_payout);
                formData.append("type", this.state.type);
                formData.append("sequence", this.state.sequence);
                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("show", this.state.currentShow);
                formData.append("country_id", this.props.currentCountryId);
                formData.append("events_redirect_url", this.state.events_redirect_url);
                // for (var pair of formData.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }
                this.props.postPromotion(formData);
            }
        })

    }

    getImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { image_preview, image } = this.state;

        reader.onloadend = () => {

            image_preview[langId] = reader.result;
            image[langId] = file;

            this.setState({
                image_preview,
                image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            image_preview[langId] = "";
            this.setState({
                image_preview
            })
        }

    };

    getFeaturedImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { featured_image_preview, featured_image } = this.state;

        reader.onloadend = () => {

            featured_image_preview[langId] = reader.result;
            featured_image[langId] = file;

            this.setState({
                featured_image_preview,
                featured_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            featured_image_preview[langId] = "";
            this.setState({
                featured_image_preview
            })
        }

    };

    // getPromoDetailsImageHandler = (event, langId) => {
    //     let reader = new FileReader();
    //     let file = event[0];

    //     const {promo_details_image_preview, promo_details_image} = this.state;

    //     reader.onloadend = () => {

    //         promo_details_image_preview[langId] = reader.result;
    //         promo_details_image[langId] = file;

    //         this.setState({
    //             promo_details_image_preview,
    //             promo_details_image
    //         });
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     } else {
    //         promo_details_image_preview[langId] = "";
    //         this.setState({
    //             promo_details_image_preview
    //         })
    //     }
    // }

    getPromoBannerImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { promo_banner_image_preview, promo_banner_image } = this.state;

        reader.onloadend = () => {

            promo_banner_image_preview[langId] = reader.result;
            promo_banner_image[langId] = file;

            this.setState({
                promo_banner_image_preview,
                promo_banner_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            promo_banner_image_preview[langId] = "";
            this.setState({
                promo_banner_image_preview
            })
        }
    }

    getPromoMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_image_preview, mobile_image } = this.state;

        reader.onloadend = () => {

            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({
                mobile_image_preview,
                mobile_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview
            })
        }
    }

    getPromoMobileBannerImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const { mobile_banner_image_preview, mobile_banner_image } = this.state;

        reader.onloadend = () => {

            mobile_banner_image_preview[langId] = reader.result;
            mobile_banner_image[langId] = file;

            this.setState({
                mobile_banner_image_preview,
                mobile_banner_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_banner_image_preview[langId] = "";
            this.setState({
                mobile_banner_image_preview
            })
        }
    }

    getPromotionTitleHandler = (event, langId) => {
        const { title } = this.state;
        title[langId] = event.target.value;
        this.setState({
            title
        });
    }

    // getPromotionContentHandler = (event, langId) => {
    //     const {content} = this.state;
    //     content[langId] = event.target.value;
    //     this.setState({
    //         content
    //     });
    // };

    getPromotionSubContentHandler = (event, langId) => {
        const { sub_content } = this.state;
        sub_content[langId] = event.target.value;
        this.setState({
            sub_content
        });
    };

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    }

    changeShowHandler = (event) => {
        this.setState({ currentShow: event.target.value });
    }

    getSequenceHandler = (event) => {
        this.setState({ sequence: event.target.value });
    }

    // getExtraHandler = (event) => {
    //     this.setState({extra: event.target.value});
    // }

    getTypeHandler = (event) => {
        this.setState({ type: event.target.value });
    }

    getCategoryHandler = (event) => {
        this.setState({ category: event });
    }

    getMemberGroupHandler = (event) => {
        this.setState({ currentGroupName: event });
    }

    getMinDepositHandler = (event) => {
        this.setState({ min_deposit: event.target.value });
    }

    getMaxDepositHandler = (event) => {
        this.setState({ max_payout: event.target.value });
    }

    getPercentageHandler = (event) => {
        this.setState({ percentage: event.target.value });
    }

    getTurnoverMultiplyHandler = (event) => {
        this.setState({ turnover_multiply: event.target.value });
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        this.setState({
            [event.target.id]: event.target.value,
        });
    }

    getEntitleRebateHandler = (event) => {
        this.setState({ entitle_rebate: event.target.value });
    }

    getEventsRedirectUrl = (event) => {
        this.setState({ events_redirect_url: event.target.value });
    }

    render() {
        // console.log(this.state.currentShow);
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];
        let languages = langArr;
        let defaultType = [
            {
                label: t("promotion.select-type"),
                value: '',
                selected: true
            },
        ];

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];

        // let showArry = [
        //     {
        //         label: t("global.show"),
        //         value: 1,
        //         selected: true
        //     },
        //     {
        //         label: t("global.hide"),
        //         value: 0,
        //     }
        // ];

        let entitleRebateArry = [
            {
                label: t("global._yes"),
                value: 1,
            },
            {
                label: t("global._no"),
                value: 0,
                selected: true,
            }
        ];

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {/* {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )} */}

                <div className='row'>
                    <div className="col-12 col-md-4">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.desktop-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`image[${item.langId}]`}
                                onChange={(event) => this.getImageHandler(event, item.langId)}
                            />

                            <input
                                type="hidden"
                                name={`image[${item.langId}]`}
                            />

                            {/* <div className="preview-image">
                            <img
                                className="banner"
                                src={this.state.image_preview[item.langId]}
                                alt=""
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.image_preview[item.langId] })}
                                style={{ "cursor": "pointer" }}
                            />
                        </div> */}

                            {/* {this.state.image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif, webp</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.featured-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`featured_image[${item.langId}]`}
                                onChange={(event) => this.getFeaturedImageHandler(event, item.langId)}
                            />

                            <input
                                type="hidden"
                                name={`featured_image[${item.langId}]`}
                            />

                            {/* <div className="preview-image">
                                <img
                                    className="banner"
                                    src={this.state.featured_image_preview[item.langId]}
                                    alt=""
                                    onClick={() => this.setState({ isOpen: true, currentFeaturedImage: this.state.featured_image_preview[item.langId] })}
                                    style={{ "cursor": "pointer" }}
                                />
                            </div> */}
                            {/* 
                            {this.state.featured_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.promo-banner-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`promo_banner_image[${item.langId}]`}
                                onChange={(event) => this.getPromoBannerImageHandler(event, item.langId)}
                            />

                            <input
                                type="hidden"
                                name={`promo_banner_image[${item.langId}]`}
                            />

                            {/* <div className="preview-image">
                                <img
                                    className="banner"
                                    src={this.state.promo_banner_image_preview[item.langId]}
                                    alt=""
                                    onClick={() => this.setState({ isOpen: true, currentPromoBannerImage: this.state.promo_banner_image_preview[item.langId] })}
                                    style={{ "cursor": "pointer" }}
                                />
                            </div>

                            {this.state.promo_banner_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.mobile-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`mobile_image[${item.langId}]`}
                                onChange={(event) => this.getPromoMobileImageHandler(event, item.langId)}
                            />

                            <input
                                type="hidden"
                                name={`mobile_image[${item.langId}]`}
                            />

                            {/* <div className="preview-image">
                                <img
                                    className="banner"
                                    src={this.state.mobile_image_preview[item.langId]}
                                    alt=""
                                    onClick={() => this.setState({ isOpen: true, currentMobileImage: this.state.mobile_image_preview[item.langId] })}
                                    style={{ "cursor": "pointer" }}
                                />
                            </div>

                            {this.state.mobile_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.mobile-banner-image')}</label>
                            <ImageUpload
                                id="image"
                                name={`mobile_banner_image[${item.langId}]`}
                                onChange={(event) => this.getPromoMobileBannerImageHandler(event, item.langId)}
                            />

                            <input
                                type="hidden"
                                name={`mobile_banner_image[${item.langId}]`}
                            />

                            {/* <div className="preview-image">
                                <img
                                    className="banner"
                                    src={this.state.mobile_banner_image_preview[item.langId]}
                                    alt=""
                                    onClick={() => this.setState({ isOpen: true, currentMobileBannerImage: this.state.mobile_banner_image_preview[item.langId] })}
                                    style={{ "cursor": "pointer" }}
                                />
                            </div>

                            {this.state.mobile_banner_image_preview.length === 0 &&
                                <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                            } */}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>{t('promotion.title')}</label>
                            <StringInput
                                onChange={(event) => this.getPromotionTitleHandler(event, item.langId)}
                                id="promotion_title"
                                name={`title[${item.langId}]`}
                                value={this.state.title[item.langId]}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>{t('promotion.sub-content')}</label>
                            <StringInput
                                className="form-control"
                                onChange={(event) => this.getPromotionSubContentHandler(event, item.langId)}
                                id="promotion_sub_content"
                                name={`sub_content[${item.langId}]`}
                                value={this.state.sub_content[item.langId]}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label>{t('promotion.content')}</label>
                            {/* <textarea
                            className="form-control"
                            onChange={(event) => this.getPromotionContentHandler(event, item.langId)}
                            id="promotion_content"
                            name={`content[${item.langId}]`}
                            value={this.state.content[item.langId]}
                        /> */}
                            <JoditEditor
                                value={this.state.content[item.langId] || ''}
                                tabIndex={item.langId} // tabIndex of textarea
                                onBlur={newContent => {
                                    const { content } = this.state;
                                    content[item.langId] = newContent;
                                    this.setState({
                                        content
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group" key={item.langId}>
                            <label htmlFor="image">{t('promotion.promo-details-image')}</label>
                            <JoditEditor
                                value={this.state.promo_details_image[item.langId] || ''}
                                tabIndex={item.langId} // tabIndex of textarea
                                onBlur={newContent => {
                                    const { promo_details_image } = this.state;
                                    promo_details_image[item.langId] = newContent;
                                    this.setState({
                                        promo_details_image
                                    });
                                }}
                            />
                            {/* <ImageUpload
                            id="image"
                            name={`promo_details_image[${item.langId}]`}
                            onChange={(event) => this.getPromoDetailsImageHandler(event, item.langId)}
                        /> */}

                            {/* <input
                            type="hidden"
                            name={`promo_details_image[${item.langId}]`}
                        />

                        <div className="preview-image">
                            <img
                                className="banner"
                                src={this.state.promo_details_image_preview[item.langId]}
                                alt=""
                                onClick={() => this.setState({isOpen: true, currentPromoDetailsImage: this.state.promo_details_image_preview[item.langId]})}
                                style={{"cursor": "pointer"}}
                            />
                        </div>

                        {this.state.promo_details_image_preview.length === 0 &&
                        <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        } */}
                        </div>
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="border" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('promotion.promotion')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('promotion.add-promotion')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4} /> :
                                    <form method="post" id="promotion_form" className="form-horizontal" onSubmit={this.postPromotionHandler} noValidate>
                                        <ValidationError formName="promotion_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.start-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                                                    </span> */}
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.end-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend w-100">
                                                            {/* <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                                            </span> */}
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.show')}</label>
                                                    <Select
                                                        id="show"
                                                        name="show"
                                                        className="form-control"
                                                        options={showArry}
                                                        onChange={this.changeShowHandler}
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.extra')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.getExtraHandler(event)}
                                                        id="extra"
                                                        name="extra"
                                                        value={this.state.extra}
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.valid-period')}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.onInputChange(event)}
                                                        id="valid_period"
                                                        name="valid_period"
                                                        value={this.state.valid_period}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.type')}</label>
                                                    <Select
                                                        id="type"
                                                        name="type"
                                                        className="form-control"
                                                        options={defaultType.concat(typeArry)}
                                                        onChange={this.getTypeHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('promotion.category')}</label>
                                                    <Select2
                                                        options={categoryArry}
                                                        name="category"
                                                        onChange={this.getCategoryHandler}
                                                        value={this.state.category}
                                                        menuPlacement="top"
                                                        placeholder={t('global.please-select')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('promotion.sequence')}</label>
                                                    <NumberInput
                                                        onChange={(event) => this.getSequenceHandler(event)}
                                                        id="sequence"
                                                        name="sequence"
                                                        value={this.state.sequence}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="group">{t('promotion.show')}</label>
                                                    <Select
                                                        id="entitle_rebate"
                                                        name="entitle_rebate"
                                                        className="form-control"
                                                        options={entitleRebateArry}
                                                        onChange={this.getEntitleRebateHandler}
                                                        value={this.state.entitle_rebate}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <label htmlFor="group">{t('promotion.member-group')}</label>
                                                <Select2
                                                    className="form-control"
                                                    options={userMemberArry}
                                                    name="member_group"
                                                    onChange={console.log}
                                                />
                                            </div> */}


                                            {/* <div className="col-md-4">
                                                <label htmlFor="group">{t('promotion.member-group')}</label>
                                                <Select2
                                                    className="form-control"
                                                    options={userMemberArry}
                                                    name="member_group"
                                                    onChange={this.getMemberGroupHandler}
                                                />
                                            </div> */}

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.turnover-multiply')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getTurnoverMultiplyHandler(event)}
                                                                id="turnover_multiply"
                                                                name="turnover_multiply"
                                                                value={this.state.turnover_multiply}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.min-deposit')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getMinDepositHandler(event)}
                                                                id="min_deposit"
                                                                name="min_deposit"
                                                                value={this.state.min_deposit}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.max-payout')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getMaxDepositHandler(event)}
                                                                id="max_payout"
                                                                name="max_payout"
                                                                value={this.state.max_payout}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                (Number(this.state.entitle_rebate) === 1)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{t('promotion.percentage')}</label>
                                                            <NumberInput
                                                                onChange={(event) => this.getPercentageHandler(event)}
                                                                id="percentage"
                                                                name="percentage"
                                                                value={this.state.percentage}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="group">{t('promotion.events-redirect-url')}</label>
                                                    <StringInput
                                                        onChange={this.getEventsRedirectUrl}
                                                        id="events_redirect_url"
                                                        name="events_redirect_url"
                                                        value={this.state.events_redirect_url}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { home, promotion, currentUser } = state;
    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        member_group_data: promotion.member_group_data,
        category_data: promotion.category_data,
        type_data: promotion.type_data,
        post_promotion_result: promotion.post_promotion_result,
        currentCountryId: currentUser.data.country_id

    }
};

const mapDispatchToProps = {
    getCountry,
    getType,
    getMemberGroup,
    getCategory,
    postPromotion
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PromotionCreate));