import React, {Component} from 'react';
import PasswordInput from "../../../components/Input/PasswordInput/PasswordInput";
// import Checkbox from "../../../components/Input/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';

import { postChangePassword } from "../../../store/ducks/auth/actions";

import { connect } from 'react-redux';

class ChangePassword extends Component {
    state = {
        password: '',
    };

    componentDidMount(){
        if(!this.props.location.state){
            this.props.history.push('/login');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.change_password_data !== this.props.change_password_data){
            if(nextProps.change_password_data.responseCode === 200){
                Swal.fire({
                    title: nextProps.change_password_data.message[0],
                    icon: nextProps.change_password_data.msgType,
                    confirmButtonText: `OK`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/login')
                    }
                });

            }else if(nextProps.change_password_data.responseCode === 422){
                const { t } = this.props;

                Swal.fire({
                    title: nextProps.change_password_data.message,
                    icon: nextProps.change_password_data.msgType,
                    confirmButtonText: t("sweetalert.ok"),
                })
            }

        }
        return true;
    }

    getPasswordValueHandler = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    changePasswordHandler = (e) => {
        e.preventDefault();

        const { t } = this.props;
        
        Swal.fire({
            title: t('validation.confirmation'), 
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.update'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading()
                    }
                });
                const data = {};

                data.username = this.props.location.state.username;
                data.password = this.state.password;
        
                //Call the login api
                this.props.postChangePassword(data);
            }
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row no-gutter bg-white">
                    <div className="col-12 p-0 login__background">
                        <div className="login d-flex align-items-center">
                            <div className="container p-0">
                                {/* <div className="d-flex justify-content-center">
                                    <img src={LogoImage}/>
                                </div> */}
                                <div className="row justify-content-center">
                                    <div className="col-10 col-md-6 bg-white login__container p-0 rounded">
                                        <div className="card-sigin ">
                                            {/* <div className="mb-5 d-flex"><a href="/"><img
                                                src={Logo} className="sign-favicon ht-40" alt="logo"/></a><h1
                                                className="main-logo1 ml-1 mr-0 my-auto tx-28">Va<span>le</span>x</h1>
                                            </div> */}
                                            <div className="card-sigin p-0">
                                                <div className="main-signup-header">
                                                    <h2>Change Password</h2>
                                                    {/* <div className="d-flex justify-content-center py-3">
                                                        <img src={LogoImage}/>
                                                    </div> */}
                                                    <h5 className="font-weight-semibold mb-4">Please change the password to login.</h5>
                                                    <form id="sigin-form" onSubmit={this.changePasswordHandler} noValidate>
                                                        <div className="form-group">
                                                            <label>New Password</label>
                                                            <PasswordInput
                                                                onChange={this.getPasswordValueHandler}
                                                                className="rounded"
                                                                id="password"
                                                                name="password"
                                                                placeholder="Enter your password"
                                                                value={this.state.password}
                                                                required
                                                                minLength="6"
                                                            />

                                                        </div>
                                                        <Button
                                                            typeButton="submit"
                                                            classValue="btn btn-main-primary btn-block rounded"
                                                            idValue="submit"
                                                            buttonName="Update"
                                                            clicked={() => {}}
                                                        />
                                                    </form>
                                                    {/* <div className="main-signin-footer mt-4">
                                                        <Checkbox
                                                            onChange={this.getRememberValueHandler}
                                                            name="remember"
                                                            id="remember"
                                                            value={this.state.rememberMe}
                                                        />
                                                        <label className="form-check-label"><strong>Remember
                                                            Me?</strong></label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    const { auth } = state;

    return {
        change_password_data : auth.change_password_data,
        // classIsShowing: modal.classIsShowing,
        // verifyTwoFactorData: twoFactorAuthentication.verifyTwoFactorData,
        // resendAccountActivationData: resendAccountActivation.data
    }
};

//Using the redux calling api methods
const mapDispatchToProps = {
    postChangePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(ChangePassword));