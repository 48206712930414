//Call the api for example call the login api
import axios from "../../../common/config/axios";
import * as type from "../paymentGateway/types";

export const getPaymentGateway = (data) => dispatch => {
    axios.get('payment_gateway/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_PAYMENT_GATEWAY_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getDropDown = () => dispatch =>
{
    axios.get('payment_gateway/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postPaymentGateway = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/store', data)
        .then( (response) => {
            dispatch ({
                type: type.CREATE_PAYMENT_GATEWAY,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showPaymentGateway = (data) => dispatch => {
    axios.get('payment_gateway/show', {
        params: {
            "id": data['payment_gateway_id'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_PAYMENT_GATEWAY,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updatePaymentGateway = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_PAYMENT_GATEWAY,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deletePaymentGateway = (data) => dispatch => {
    axios.post('payment_gateway/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_PAYMENT_GATEWAY,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateDepositStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/change_deposit_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_DEPOSIT_STATUS_PAYMENT_GATEWAY,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateWithdrawalStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/change_withdrawal_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_WITHDRAWAL_STATUS_PAYMENT_GATEWAY,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getPaymentTypeList = (data) => dispatch => {
    axios.get('payment_gateway/payment_type_list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "country_id": data['country_id'],
            "transaction_option_id": data['transaction_option_id']
        }
        }).then( (response) => {
            dispatch ({
                type: type.GET_PAYMENT_TYPE_LIST,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePaymentTypeDepositStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/payment_type_deposit_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_DEPOSIT_STATUS_PAYMENT_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePaymentTypeWithdrawalStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/payment_type_withdrawal_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_WITHDRAWAL_STATUS_PAYMENT_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const postPaymentType = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/store_payment_type', data)
        .then( (response) => {
            dispatch ({
                type: type.STORE_PAYMENT_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showPaymentType = (data) => dispatch => {
    axios.get('payment_gateway/show_payment_type', {
        params: {
            "payment_type_id": data['payment_type_id'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_PAYMENT_TYPE,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updatePaymentType = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('payment_gateway/update_payment_type', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_PAYMENT_TYPE,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const getPaymentTypeLedger = (data) => dispatch => {
    axios.get('payment_gateway/ledger', {params: data})
    .then((response)=>{
        dispatch({
            type: type.PAYMENT_TYPE_LEDGER,
            payload: response.data
        });
    })
    .catch(err => {
        console.warn(err);
    });
};