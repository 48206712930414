import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import '../../assets/css/simple-line-icons.scss';
import '../../assets/css/treeview.css';

import { formValidation, clearFormValidation, filterErrorHandler } from '../../hoc/Shared/utility';
import StringInput from '../../components/Input/StringInput/StringInput';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/permission/actions';

class PermissionEdit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            input: [],
            module: [],
            loading: true,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation('permission_form');
        if (this.props.selectId) {
            // console.log(this.props.selectId);
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                this.setState({
                    input: nextProps.details.data.result[0],
                    module: nextProps.details.data.module,
                    loading: false
                });
            }
            else {
                this.setState({
                    input: [],
                    module: []
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
            if (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('permission_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.update.message
                });
                Swal.close();
            }
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.id] = event.target.value;
        // console.log(newInput);
        this.setState({
            input: newInput
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });

                let data = {};
                data.id = this.props.selectId;
                data.title = this.state.input.title;
                data.permission = this.state.input.permission;
                data.link = this.state.input.link;
                data.pid = this.state.input.pid;
                data.idx = this.state.input.idx;
                data.is_menu = (this.state.input.pid === 0) ? 1 : this.state.input.is_menu;
                data.status = this.state.input.status;
                // console.log(data);
                this.props.funcUpdate(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        let inputArr = (this.state.input) ? this.state.input : [];
        let mainModule = (this.state.module.mainModule) ? this.state.module.mainModule : [];
        let subModule = (this.state.module.subModule) ? this.state.module.subModule : [];
        let menuType = (inputArr.pid === 0) ? t('permission.main-menu') : (((inputArr.is_menu) === 1) ? t('permission.sub-menu') : t('permission.son-menu'));
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];
        // console.log(menuType);

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('permission.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-8'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('permission.edit-permission')} {inputArr.id && (`(${menuType})`)}</h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='permission_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='permission_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>{t('permission._title')}</label>
                                                        <StringInput
                                                            id='title'
                                                            name='title'
                                                            placeholder={t('permission._title')}
                                                            value={inputArr.title || ''}
                                                            onChange={(event) => this.onInputChange(event)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>{t('permission.link')}</label>
                                                        <StringInput
                                                            id='link'
                                                            name='link'
                                                            placeholder={t('permission.link')}
                                                            value={(inputArr.link) || ''}
                                                            onChange={(event) => this.onInputChange(event)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>{t('permission.permission')}</label>
                                                        <StringInput
                                                            id='permission'
                                                            name='permission'
                                                            placeholder={t('permission.permission')}
                                                            value={(inputArr.permission) || ''}
                                                            onChange={(event) => this.onInputChange(event)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>{t('permission.sequence')}</label>
                                                        <NumberInput
                                                            id='idx'
                                                            name='idx'
                                                            placeholder={t('permission.sequence')}
                                                            min={1}
                                                            max={100}
                                                            value={(inputArr.idx) || ''}
                                                            onChange={(event) => this.onInputChange(event)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>{t('permission.status')}</label>
                                                        <div className='select-input'>
                                                            <select className='form-control datatable-select' id='status' name='status' value={inputArr.status} onChange={(event) => this.onInputChange(event)}>
                                                                <option value={1}>{t('global.active')}</option>
                                                                <option value={0}>{t('global.inactive')}</option>
                                                            </select>
                                                            <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                        </div>

                                                    </div>
                                                </div>
                                                {
                                                    (Number(inputArr.pid) === 0)
                                                        ?
                                                        null
                                                        :
                                                        (Number(inputArr.pid) !== 0 &&
                                                            <div className='col-md-4'>
                                                                <div className='form-group'>
                                                                    <label>{t('permission.under-permission')}</label>
                                                                    <div className='select-input'>
                                                                        <select className='form-control datatable-select' id='pid' name='pid' value={inputArr.pid} onChange={(event) => this.onInputChange(event)}>
                                                                            {
                                                                                (Number(inputArr.is_menu) === 0)
                                                                                    ?
                                                                                    subModule.map((sub, idx) => {
                                                                                        return (<option key={idx} value={sub.id}>{t(`side-bar.${sub.title}`)}</option>)
                                                                                    })
                                                                                    :
                                                                                    mainModule.map((main, idx) => {
                                                                                        return (<option key={idx} value={main.id}>{t(`side-bar.${main.title}`)}</option>)
                                                                                    })
                                                                            }
                                                                        </select>
                                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                                    </div>

                                                                </div>
                                                            </div>)
                                                }
                                            </div>
                                            <div className='form-group mb-0 mt-3 justify-content-end'>
                                                <div>
                                                    {(permission.includes('permission_update')) &&
                                                        <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />}
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.permission.details,
        update: state.permission.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PermissionEdit));