import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import DailyMissionListing from "./DailyMissionListing";
import TransactionDetails from "../Transaction/TransactionDetails";

import { getDailyMission } from "../../store/ducks/dailyMission/actions";

const initialFilteringState = {
    start_date: "",
    end_date: "",
    member: "",
    date: ""
};

class DailyMission extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            pageShow: "listing",
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',
            data: [],
            filterInput: initialFilteringState,
            detailsParam: {},
            permission: []
        }
    }

    componentDidMount(){
        const data = this.fetchData();
        this.props.getDailyMission(data);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextState.permission.length===0 && this.props.currentPermission!==undefined){
            this.setState({ permission: this.props.currentPermission });
        }

        if(nextProps.daily_mission !== undefined && nextProps.daily_mission !== this.props.daily_mission){
            if(nextProps.daily_mission.data.responseCode === 200 && nextProps.daily_mission.data.msgType === "success"){
                this.setState({
                    data: nextProps.daily_mission.data.data.result.row,
                    totalRecords: nextProps.daily_mission.data.data.result.pagination.total_record,
                    loading: false
                });
            }
        }
        return true;
    }

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        Object.keys(this.state.filterInput).forEach((val) =>
        {
            // console.log(val);
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filterInput[val]!=='') ? moment(this.state.filterInput[val]).format("YYYY-MM-DD") : this.state.filterInput[val];
        });
        return listingArr;
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filterInput);
        if(event.target.id==='transaction_date'){
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
            newFilter['date'] = event.target.value;
        }
        else{
            newFilter[event.target.id] = event.target.value;
        }
        this.setState({
            filterInput: newFilter
        });
    }

    clearHandler = () => {
        let newFilter = initialFilteringState;
        this.setState({
            activePage: 1,
            limit: 10,
            searchValue: '',
            order: '',
            filterInput: newFilter,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getDailyMission(listingArr);
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getDailyMission(listingArr);
        });
    }

    // go to transaction details
    detailsHandler = (e, id) => {
        // console.log('detailsHandler (transaction): ', id);
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam : {
                id: id
            }
        });
    }

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getDailyMission(listingArr);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.fetchData();
            this.props.getDailyMission(listingArr);
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    render(){
        const { t } = this.props;
        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("daily-mission.order-id"), dataField: "order_id", sort: true, onSort: this.onSort, headerStyle: { width: "115px" } },
            { text: t("daily-mission.username"), dataField: "username", sort: true, onSort: this.onSort },
            { text: t("daily-mission.name"), dataField: "name", sort: true, onSort: this.onSort },
            { text: t("daily-mission.claim-date"), dataField: "claim_date", sort: true, onSort: this.onSort },
            { text: t("daily-mission.claim-turnover"), dataField: "achieved_target_turnover", sort: false },
            { text: t("daily-mission.claim-amount"), dataField: "amount", sort: false },
            { text: t("daily-mission.is-extra-bonus"), dataField: "is_extra_bonus", sort: false },
            { text: t('daily-mission.created-at'), dataField: "created_at", sort: true, onSort: this.onSort }
        ];

        let data = this.state.data.map((val, idx) => {
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let order_id = this.state.permission.includes(
                "transaction_details_access"
            ) ? (
                <b>
                    <a href="/#" onClick={(event) => this.detailsHandler(event, val.transaction_id)}>
                        {val.order_id}
                        <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                    </a>
                </b>
            ) : (
                val.order_id
            );
            let bonus = val.is_extra_bonus ? '-' : new Intl.NumberFormat("en-US", num_format).format(val.claim_amount);
            let extra_bonus = val.is_extra_bonus ? new Intl.NumberFormat("en-US", num_format).format(val.claim_amount) : '-';
            return {
                no: idx,
                order_id: order_id,
                username: val.username,
                name: val.name,
                claim_date: val.claim_date,
                amount: bonus,
                is_extra_bonus: extra_bonus,
                created_at: val.created_at,
                achieved_target_turnover: val.claim_turnover
            }
        });

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <DailyMissionListing 
                        filterInput={this.state.filterInput}

                        changePageHandler={(page)=>this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}

                        data={data}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        loading={this.state.loading}
                    />
                )}

                {this.state.pageShow === "details" && (
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentPermission: state.currentUser.data.permission,
        daily_mission: state.dailyMission.daily_mission
    }   
}

const mapDispatchToProps = dispatch => {
    return {
        getDailyMission: (data) => dispatch(getDailyMission(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DailyMission));