import React, { Component } from "react";
import { connect } from "react-redux";
import { getMembers, getFilterData, clearMemberState } from "../../store/ducks/member/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MemberListing from "./MemberListing";
import MemberCreate from "./MemberCreate";
import MemberShow from "./MemberShow/MemberShow";
import MemberEdit from "./MemberEdit/MemberEdit";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler } from "../../hoc/Shared/utility";

const initialInputState = {
    customer_id: "",
    name: "",
    created_date: "",
    email: "",
    contact: "",
    gender: "",
    status: "",
    member_group: "",
    last_login: "",
    parcel_number: ""
    // crypto_address: ""
};

class Member extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: (this.props.location.state === undefined) ? "listing" : this.props.location.state.pageShow,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalRecords: 0,
            filter: initialInputState,
            data: [],
            dropDown: {
                member_group_option: [],
            },
            popupLoading: false,
            memberShowId: null,
            memberShowUsername: (this.props.location.state === undefined) ? null : this.props.location.state.memberShowUsername,
            memberShowCount: 0,
            memberEditId: null,
            memberEditUsername: null,
            memberEditCount: 0,
            loading: true,
            permission: [],
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.currentUserGroup)
        // if(this.props.currentUserGroup && this.props.currentUserGroup.toLowerCase().replace(' ', '') === process.env.REACT_APP_SUPER_ADMIN_GROUP)
        // {
            const listingArr = this.getFilterArray();
            this.props.getMembers(listingArr);
        // }
        this.props.getFilterData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.memberData !== this.props.memberData && nextProps.memberData !== undefined) {
            if (nextProps.memberData.data.responseCode === 200 && nextProps.memberData.data.msgType === "success") {
                this.setState({
                    data: nextProps.memberData.data.data.result.row,
                    totalRecords: nextProps.memberData.data.data.result.pagination.total_record,
                    totalPages: nextProps.memberData.data.data.result.pagination.total_page,
                    loading: false,
                });
                Swal.close();
            } else {
                this.setState({
                    data: [],
                    totalRecords: 0,
                    totalPages: 0,
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.memberData.data.message);
                });
            }
        }

        if (nextProps.memberFilterData !== this.props.memberFilterData && nextProps.memberFilterData !== undefined) {
            if (nextProps.memberFilterData.data && nextProps.memberFilterData.data.responseCode === 200 && nextProps.memberFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        member_group_option: nextProps.memberFilterData.data.data.memberGroup
                    },
                    loading: false
                });
            }
            else {
                this.setState({
                    dropDown: {
                        member_group_option: [],
                    },
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.memberFilterData.data.message);
                });
            }
            // Swal.close();
        }

        return true;
    }

    componentWillUnmount() {
        this.props.clearMemberState();
    }

    /* ======================== Component Lifecycle [END] ======================== */

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        // console.log(event);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "created_date") {
            let datePickerElement = document.getElementById(event.target.id);
            // console.log(datePickerElement);
            datePickerElement.addEventListener("keydown", function (event) {
                // console.log("test2");
            });
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
        {
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        },
        () => {
            const listingArr = this.getFilterArray();
            this.props.getMembers(listingArr);
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: null,
                searchValue: "",
                loading: false,
                totalRecords: 0,
                filter: newFilter,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMembers(listingArr);
            }
        );
    };

    showHandler = (e, id, email) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                pageShow: "show",
                memberShowId: id,
                memberShowUsername: email,
                memberShowCount: prevState.memberShowCount + 1,
            };
        });
    };

    editHandler = (e, id, email) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                pageShow: "edit",
                memberEditId: id,
                memberEditUsername: email,
                memberEditCount: prevState.memberEditCount + 1,
            };
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMembers(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]['id'] = field === "cusID" ? "cus_id" : field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMembers(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;
        if (this.state.permission.includes("member_view")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-1"
                    key={`view-${row.memberId}`}
                    idValue={row.memberId === undefined ? 0 : row.memberId}
                    clicked={(event) => this.showHandler(event, row.memberId, row.email)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }

        if (this.state.permission.includes("member_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.memberId}`}
                    idValue={row.memberId === undefined ? 0 : row.memberId}
                    clicked={(event) => this.editHandler(event, row.memberId, row.email)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [viewButton, editButton];
        return output;
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];
        let ddMemberGroup = [];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns = [
            // { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("global.no"), dataField: "memberId", headerStyle: { width: "60px" } },
            { text: t("member.email"), dataField: "email", sort: true, onSort: this.onSort },
            { text: t("member.name"), dataField: "name", sort: true, onSort: this.onSort },
            { text: t("member.cusID"), dataField: "cusID", sort: true, onSort: this.onSort },
            { text: t("member.contact-no"), dataField: "memberContactNo", sort: true, onSort: this.onSort },
            { text: t("member.group"), dataField: "memberGroupName", sort: true, onSort: this.onSort },
            { text: t("member.register-time"), dataField: "createdTime", sort: true, onSort: this.onSort },
            { text: t("member.last-login"), dataField: "lastLogin", sort: true, onSort: this.onSort },
            { text: t("member.wallet-balance"), dataField: "walletBalance", sort: true, onSort: this.onSort },
            // { text: t("member.coin"), dataField: "coin", sort: true, onSort: this.onSort },
            // { text: t("business-account.status"), dataField: "businessAccountStatus", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "115px" } },
        ];

        if (this.state.pageShow === "listing") {
            if (this.state.data.length > 0) {
                dataListing = this.state.data.map((val, idx) => {
                    return {
                        memberId: val.memberId,
                        name: val.name,
                        email: val.email,
                        cusID: val.cus_id,
                        memberContactNo: val.memberContactNo,
                        memberGroupName: t(`global.level.${val.memberGroupLevel}`),
                        createdTime: val.createdTime,
                        lastLogin: val.lastLogin,
                        walletBalance: new Intl.NumberFormat("en-US", num_format).format(val.walletBalance),
                        coin: new Intl.NumberFormat("en-US", num_format).format(val.coin),
                        businessAccountStatus: val.businessAccountStatus,
                    };
                });
            }

            if (this.state.dropDown.member_group_option) {
                ddMemberGroup = this.state.dropDown.member_group_option.map((val, idx) => {
                    return (
                        <option key={val.memberGroupId} value={val.memberGroupId}>
                            {t(`global.level.${val.groupName}`)}
                        </option>
                    );
                });
            }
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MemberListing
                        filter={this.state.filter}
                        searchHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        ddMemberGroup={ddMemberGroup}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("member_create") && this.state.pageShow === "create" && (
                    <MemberCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.permission.includes("member_view") && this.state.pageShow === "show" && (
                    <MemberShow
                        memberShowId={this.state.memberShowId || this.props.location.state.show_user_id}
                        memberShowUsername={this.state.memberShowUsername}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.state.permission}
                        memberShowCount={this.state.memberShowCount}
                    />
                )}

                {this.state.permission.includes("member_update") && this.state.pageShow === "edit" && (
                    <MemberEdit
                        memberEditId={this.state.memberEditId}
                        memberEditUsername={this.state.memberEditUsername}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.state.permission}
                        memberEditCount={this.state.memberEditCount}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberData: state.member.member_data,
        memberFilterData: state.member.member_filter_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
        currentUserGroup: state.currentUser.data.user_group,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMembers: (filter) => dispatch(getMembers(filter)),
        getFilterData: () => dispatch(getFilterData()),
        clearMemberState: () => dispatch(clearMemberState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Member));
