import React, { Component } from "react";
import { connect } from "react-redux";
import { getWebMaster, updateWebMaster } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";

class WebMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            google_web_master: "",
            bing_web_master: "",
            baidu_web_master: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.getWebMaster();
        formValidation("web_master_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[WebMaster] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.webMasterData !== this.props.webMasterData) {
            if (nextProps.webMasterData.responseCode === 200) {
                let webMasterData = nextProps.webMasterData.data.webMaster[0];

                this.setState({
                    google_web_master: webMasterData.googleWebMaster === null ? "" : webMasterData.googleWebMaster,
                    bing_web_master: webMasterData.bingWebMaster === null ? "" : webMasterData.bingWebMaster,
                    baidu_web_master: webMasterData.baiduWebMaster === null ? "" : webMasterData.baiduWebMaster,
                    loading: false,
                });
            }
        }

        if (nextProps.webMasterUpdateResult !== this.props.webMasterUpdateResult) {
            if (nextProps.webMasterUpdateResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.webMasterUpdateResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.getWebMaster();
                    }
                });
            } else if (nextProps.webMasterUpdateResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.webMasterUpdateResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    updateWebMasterHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateWebMasterData = {};
                updateWebMasterData.google_web_master = this.state.google_web_master;
                updateWebMasterData.bing_web_master = this.state.bing_web_master;
                updateWebMasterData.baidu_web_master = this.state.baidu_web_master;
                // console.log("Form Submit:", updateWebMasterData);
                this.props.updateWebMaster(updateWebMasterData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("seo.web-master")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="web_master_form" className="form-horizontal" onSubmit={this.updateWebMasterHandler} noValidate>
                                        <ValidationError formName="web_master_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="pd-30 pd-sm-40 bg-gray-100">
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="google_web_master" className="form-label mg-b-0">
                                                        {t("web-master.google-search-console")}
                                                    </label>
                                                </div>
                                                <div className="col-md-8 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="google_web_master"
                                                        name="google_web_master"
                                                        className="form-control"
                                                        placeholder={t("web-master.google-search-console")}
                                                        value={this.state.google_web_master}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="google_web_master" className="form-label mg-b-0">
                                                        {t("web-master.bing-web-master-tools")}
                                                    </label>
                                                </div>
                                                <div className="col-md-8 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="bing_web_master"
                                                        name="bing_web_master"
                                                        className="form-control"
                                                        placeholder={t("web-master.bing-web-master-tools")}
                                                        value={this.state.bing_web_master}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="google_web_master" className="form-label mg-b-0">
                                                        {t("web-master.baidu-web-master-tools")}
                                                    </label>
                                                </div>
                                                <div className="col-md-8 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="baidu_web_master"
                                                        name="baidu_web_master"
                                                        className="form-control"
                                                        placeholder={t("web-master.baidu-web-master-tools")}
                                                        value={this.state.baidu_web_master}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button typeButton="submit" classValue="btn btn-primary" idValue="submit" buttonName={t("global.save")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("seo.web-master")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        webMasterData: state.seo.web_master_data,
        webMasterUpdateResult: state.seo.web_master_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWebMaster: () => dispatch(getWebMaster()),
        updateWebMaster: (data) => dispatch(updateWebMaster(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(WebMaster));
