import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import ParcelListing from './ParcelListing';
import ParcelEdit from './ParcelEdit';
import TransactionDetails from '../Transaction/TransactionDetails';
import MemberShow from '../Members/MemberShow/MemberShow';

import * as actions from '../../store/ducks/parcel/actions';

const initialInputState =
{
    /* form input value */
    order_id: '',
    username: '',
    status: '',
    transaction_channel: '',
    min_amount: '',
    max_amount: '',
    transaction_date: '',
    start_date: '',
    end_date: '',
};
const currency = {
    1: "MYR", 4: "IDR"
};

class Parcel extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                status: [],
                transactionChannel: [],
            },

            /* transction details parameter */
            selectId: null,

            /* skeleton loading */
            loading: true,

            // redirect member profile
            redirectParam: {}
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
        this.props.funcGetDropDown(); // get filter drop down
        // this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
    }

    componentWillUnmount() 
    {
        // clearInterval(this.intervalId);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;
        // console.log(nextProps.rs.data.result.row);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) =>
                {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        activePage: 1,
                        data: [],
                        totalRecords: 0,
                        loading: false,
                    });
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        status: nextProps.dropDown.data.status,
                        transactionChannel: nextProps.dropDown.data.transaction_channel,
                        bankList: nextProps.dropDown.data.bank_list,
                        bankAccountList: nextProps.dropDown.data.bank_account_list,
                    }
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) 
        {
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.update.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }
        
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */

    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='transaction_date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    detailsHandler = (e, page, id) =>
    {
        e.preventDefault();
        this.setState({
            pageShow: page,
            selectId: id,
        });
    }

    depositApproveHandler = (e, id, credit, fees, curBal, trxChannel) =>
    {
        // console.log(e,id,credit,fees,curBal,trxChannel);
        const { t } = this.props;
        let finalBal = 0;
        let htmlText = `<span>${t('validation.confirmation_check')}</span>`;
        const num_format = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };
        if(trxChannel==='bank-transfer'){
            finalBal = (parseFloat(curBal)+parseFloat(credit))-parseFloat(fees);
            if(isNaN(finalBal) || finalBal===undefined){
                finalBal = 0;
            }
            htmlText = `<span>${t('parcel.new-balance')}: ${currency[this.props.currentUser.country_id]} ${new Intl.NumberFormat("en-US",num_format).format(finalBal)}</span><br><span>${t('validation.confirmation_check')}</span>`;
        }

        e.preventDefault();
        Swal.fire({
            title: t('validation.confirmation'),
            // text: t('validation.confirmation_check'),
            html: htmlText,
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                let data = {};
                data.id = id;
                data.act = 'approve';
                data.lang_id = this.props.currentUser.language_id;
                // console.log(data);
                // this.props.funcUpdateTran(data);
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        // if(page==='listing')
        // {
        //     this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
        // }
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect to member profile
    redirectHandler = (e, id, name) => {
        e.preventDefault();
        this.setState({
            pageShow: "redirect",
            redirectParam: {
                id: id,
                name: name
            }
        });
    }

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <ParcelListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        depositApproveHandler={(event, id, credit, fees, curBal, trxChannel) => this.depositApproveHandler(event, id, credit, fees, curBal, trxChannel)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        ddStatus={this.state.dropDown.status}
                        ddTransactionChannel={this.state.dropDown.transactionChannel}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        // currentUser={this.props.currentUser}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <ParcelEdit
                        selectId={this.state.selectId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        selectId={this.state.selectId}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='redirect' &&
                    <MemberShow 
                        memberShowId={this.state.redirectParam.id}
                        memberShowUsername={this.state.redirectParam.name}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.props.currentUser.permission ?? []}
                        memberShowCount={0}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.parcel.rs,
        dropDown: state.parcel.dropDown,
        update: state.parcel.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        // funcUpdateTran: (data) => dispatch(actions.updateTran(data)),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Parcel));