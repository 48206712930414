import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from 'moment';
import Swal from "sweetalert2";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MemberReferralPayoutDetail from "./MemberReferralPayoutDetail";
import MemberReferralPayoutListing from "./MemberReferralPayoutListing";
import { clearFormValidation } from "../../hoc/Shared/utility";

import {getReferralPayout, submitReferralPayout} from "../../store/ducks/memberReferral/actions";

const initialFiltering = {
    date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    username: '',
    confirm_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
};
class MemberReferralPayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            setting: [],
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            order: null,
            totalRecords: 0,
            searchValue: "",
            pageShow: "listing",
            errMsg: [],
            touched: false,
            checkAll: false,
            filterInput: initialFiltering,
            formInput: {},
            inputType: {},
            payoutParam: {}
        }
    }

    componentDidMount() {
        let data = this.getFilterList();
        this.props.getReferralPayout(data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.member_referral_payout !== undefined && nextProps.member_referral_payout !== this.props.member_referral_payout){
            if (nextProps.member_referral_payout.status === 200 && nextProps.member_referral_payout.data.responseCode === 200 && nextProps.member_referral_payout.data.msgType === "success"){
                let memberReferralPayout = nextProps.member_referral_payout.data;
                let formInput = Object.assign({}, this.state.formInput);
                let filterInput = Object.assign({}, this.state.filterInput);

                // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
                memberReferralPayout.data.result.row.forEach((val, idx) => {
                    if(val.referral_commission > 0 && memberReferralPayout.data.referralSettings.minPayout > 0 && val.referral_commission >= memberReferralPayout.data.referralSettings.minPayout){
                        let commission = (val.referral_commission > 0) ? val.referral_commission : 0;
                        formInput[`commission_tbg_${val.mid}`] = commission; //new Intl.NumberFormat('en-US', num_format).format(commission);
                        formInput[`cb_${val.mid}`] = false;
                        formInput[`remark_${val.mid}`] = '';
                        formInput[`base_myr_to_idr_${val.mid}`] = val.base_myr_to_idr;
                        formInput[`base_idr_to_myr_${val.mid}`] = val.base_idr_to_myr;
                    }
                });
            
                filterInput.confirm_date = filterInput.date;

                this.setState({
                    loading: false,
                    data: memberReferralPayout.data.result.row,
                    setting: memberReferralPayout.data.referralSettings,
                    totalPages: memberReferralPayout.data.result.pagination.total_page,
                    totalRecords: memberReferralPayout.data.result.pagination.total_record,
                    formInput: formInput,
                    filterInput: filterInput
                });
            }
            else{
                this.setState({
                    loading: false
                });
                this.filterErrorHandler(nextProps.member_referral_payout.data.message);
            }
        }

        if(nextProps.member_referral_payout_edit !== undefined && nextProps.member_referral_payout_edit !== this.props.member_referral_payout_edit){
            if(nextProps.member_referral_payout_edit.status === 200 && nextProps.member_referral_payout_edit.data.responseCode === 200 && nextProps.member_referral_payout_edit.data.msgType === "success"){
                const { t } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    this.refreshHandler();
                    clearFormValidation('member_rebate_form');
                });
            }
        }
        return true;
    }

    getFilterList = () => {
        const data = {}
        let availablePage = this.state.activePage;
        if(this.state.totalRecords > 0){
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        data.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        data.limit = this.state.limit;
        data.search = this.state.searchValue;
        data.order = JSON.stringify(this.state.order);
        data.username = this.state.filterInput.username;
        data.date = this.state.filterInput.date;
        data.country_id = this.props.currentUser.country_id;
        // console.log(data);
        return data;
    }

    filterErrorHandler = (message) => {
        const { t } = this.props;
        let msg = null;
        msg = '<ul>';
        message.forEach((val) => {
            msg += '<li>'+val+'</li>';
        });
        msg += '</ul>';

        Swal.fire({
            icon: 'error',
            title: t('global.error'),
            html: msg,
            showConfirmButton: true
        });
    }

    refreshHandler = () => {
        this.setState({
            checkAll: false,
            errMsg: [],
            touched: false,
            loading: true,
            data: [],
            formInput: {}
        }, ()=> {
            const data = this.getFilterList();
            this.props.getReferralPayout(data);
        });
    }

    clearHandler = () => {
        this.setState({
            checkAll: false,
            errMsg: [],
            touched: false,
            loading: true,
            data: [],
            formInput: {},
            filterInput: initialFiltering
        }, ()=> {
            const data = this.getFilterList();
            this.props.getReferralPayout(data);
        });
    }

    onInputChange = (event, inputType) => {
        // console.log(event.target, inputType, this.state.inputType);
        let formFilter = Object.assign({}, this.state.formInput);
        let newFilter = Object.assign({}, this.state.filterInput);
        if(event.target.id.includes('cb_')) {
            let checkVal = (event.target.checked) ? true : false;
            formFilter[event.target.id] = checkVal;
        }
        else if(event.target.id.includes('remark_') || event.target.id.includes('commission_tbg_') || event.target.id.includes('base_idr_to_myr_') || event.target.id.includes('base_myr_to_idr_')) {
            formFilter[event.target.id] = event.target.value;
        }
        else {
            newFilter[event.target.id] = event.target.value;
        }
        // console.log(formFilter, newFilter);
        this.setState({
            formInput: formFilter,
            filterInput: newFilter
        });
    }

    checkAllHandler = (event, inputType) => {
        // console.log(event.target, inputType);
        if(this.props.currentUser.permission.includes('member_referral_payout_submit')) {
            let checkVal = (event.target.checked) ? true : false;
            let formFilter = Object.assign({}, this.state.formInput);
            for (const key in formFilter) {
                if(key.includes('cb_')) {
                    var myEle = document.getElementById(key);
                    formFilter[key] = false;
                    if(myEle) {
                        formFilter[key] = checkVal;
                    }
                }
            }
            this.setState({
                formInput: formFilter,
                checkAll: checkVal,
            });
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        let newFilter = Object.assign({}, this.state.formInput);
        let data = {};
        let length = 0;

        for (const key in newFilter) {
            if(key.includes('cb_')) {
                if(newFilter[key]===true) {
                    let ids = key.split('_');
                    data[`commission_tbg_${ids[1]}`] = newFilter[`commission_tbg_${ids[1]}`];
                    data[`remark_${ids[1]}`] = newFilter[`remark_${ids[1]}`];
                    data[`base_myr_to_idr_${ids[1]}`] = newFilter[`base_myr_to_idr_${ids[1]}`];
                    data[`base_idr_to_myr_${ids[1]}`] = newFilter[`base_idr_to_myr_${ids[1]}`];
                    length++;
                }
            }
        }

        const { t } = this.props;
        if (length > 0) {
            Swal.fire({
                title: t("validation.confirmation"),
                text: t("validation.confirmation_check"),
                icon: 'warning',
                confirmButtonText: t("global.save"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loadingHandler();
                    data['country_id'] = this.props.currentUser.country_id;
                    data['date'] = this.state.filterInput.confirm_date;
                    // console.log(data);
                    this.props.submitReferralPayout(data);
                }
            });
        }
    }

    detailsHandler = (e, mid, date, rate) => {
        // console.log('detailsHandler :'+mid+' | '+date+' | '+id);
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            payoutParam : {
                mid: mid,
                date: moment(date).format('YYYY-MM-DD'), 
                returnPage: 'listing',
                rate: rate
            }
        });
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | ' +act);
        let search = "";
        search = (act==='searchValue') ? val.target.value : val;
        
        this.setState({
            [act]: search,
            checkAll: false,
            errMsg: [],
            touched: false,
            data: [],
            loading: true
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayout(data);
        });
    }

    onSort = (field, order) => {
        // console.log(field+' | '+order);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        switch(field){
            case "username":
                orderArry[0]['id'] = "referral_username";
                break;
            case "name":
                orderArry[0]['id'] = "referral_name";
                break;
            default:
                orderArry[0]['id'] = field;
                break;
        }
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            checkAll: false,
            errMsg: [],
            touched: false,
            data: [],
            loading: true
        }, () => {
            const data = this.getFilterList();
            this.props.getReferralPayout(data);
        });
    }

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            checkAll: false,
        });
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });
    }

    render() {
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && 
                    <MemberReferralPayoutListing
                        refreshHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        onInputChange={(event, inputType) => this.onInputChange(event, inputType)}
                        checkAllHandler={(event, inputType) => this.checkAllHandler(event, inputType)}
                        submitHandler={(event) => this.submitHandler(event)}
                        detailsHandler={(event, mid, date, rate) => this.detailsHandler(event, mid, date, rate)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}

                        data={this.state.data}
                        setting={this.state.setting}
                        checkAll={this.state.checkAll}
                        touched={this.state.touched}
                        errMsg={this.state.errMsg}
                        currentUser={this.props.currentUser}
                        loading={this.state.loading}
                        filterInput={this.state.filterInput}
                        totalRecords={this.state.totalRecords}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        searchValue={this.state.searchValue}
                        formInput={this.state.formInput}
                    />
                }

                {this.state.pageShow === "details" && 
                    <MemberReferralPayoutDetail 
                        payoutParam={this.state.payoutParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    // console.log(state);
    return {
        currentUser: state.currentUser.data,
        member_referral_payout: state.memberReferral.member_referral_payout,
        member_referral_payout_edit: state.memberReferral.member_referral_payout_edit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReferralPayout: (data) => dispatch(getReferralPayout(data)),
        submitReferralPayout: (data) => dispatch(submitReferralPayout(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberReferralPayout));