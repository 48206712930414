import { Component } from 'react';
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import VIPBonusCreate from "./VIPBonusCreate";
import VIPBonusEdit from "./VIPBonusEdit";
import VIPBonusListing from "./VIPBonusListing";

import { getBonusSetting } from '../../store/ducks/vipBonus/actions';

class VIPBonus extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageShow: "listing",
            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            /* API result array */
            data: [],
            popupLoading: false,
            editId: null,
            loading: true,
            permission: []
        }
    }

    componentDidMount(){
        const listingArr = this.fetchData();
        this.props.getBonusSetting(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log("[shouldComponentUpdate]",nextProps);
        if(nextState.permission.length===0 && this.props.currentPermission!==undefined){
            this.setState({ permission: this.props.currentPermission });
        }
        
        if((nextProps.member_bonus_settings!==undefined && Object.keys(nextProps.member_bonus_settings).length) && nextProps.member_bonus_settings!==this.props.member_bonus_settings){
            if(nextProps.member_bonus_settings.data.responseCode===200 && nextProps.member_bonus_settings.data.msgType==="success"){
                this.setState({
                    loading: false,
                    data: nextProps.member_bonus_settings.data.data.result.row,
                    totalRecords: nextProps.member_bonus_settings.data.data.result.pagination.total_record,
                    totalPages: nextProps.member_bonus_settings.data.data.result.pagination.total_page
                });
            }
        }
        return true;
    }

    /* ======================== Component Lifecycle [END] ======================== */
    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getBonusSetting(listingArr);
    };
    
    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            editId: id,
            pageShow: "edit",
        });
    };
    
    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        return listingArr;
    };
    
    changePageHandler = (page) => {
        this.setState({
            pageShow: page
        });
    };
    /* ======================== Function [END] ======================== */
    
    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getBonusSetting(listingArr);
            }
        );
    };
    
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };
    
    actionFormatter = (cell, row) => {
        let editButton, output;

        if (this.state.permission.includes("vip_bonus_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.Id}`}
                    idValue={row.Id === undefined ? 0 : row.Id}
                    clicked={(event) => this.editHandler(event, row.Id)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [editButton];
        return output;
    };

    render(){
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("member-bonus-setting.slug"), dataField: "slug" },
            { text: t("member-bonus-setting.start-time"), dataField: "startTime" },
            { text: t("member-bonus-setting.end-time"), dataField: "endTime" },
            { text: t("member-bonus-setting.no-day-expire"), dataField: "noDayExpire" },
            // { text: t("member-bonus-setting.no-claim"), dataField: "noClaim" },
            { text: t("member-bonus-setting.updated-at"), dataField: "updatedAt" },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "65px" } },
        ];

        if(this.state.pageShow==="listing" && this.state.data.length>0){
            dataListing = this.state.data.map((val,idx)=>{
                return {
                    Id: val.Id,
                    slug: val.slug,
                    startTime: val.startTime,
                    endTime: val.endTime,
                    noDayExpire: val.noDayExpire,
                    noClaim: val.noClaim,
                    updatedAt: val.updatedAt
                }
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow==='listing' && (
                    <VIPBonusListing
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("vip_bonus_create") && this.state.pageShow==="create" && (
                    <VIPBonusCreate
                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                    />
                )}

                {this.state.permission.includes("vip_bonus_update") && this.state.pageShow==="edit" && (
                    <VIPBonusEdit 
                        changePageHandler={(page) => this.changePageHandler(page)}
                        refreshHandler={() => this.refreshHandler()}
                        editId={this.state.editId}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentPermission: state.currentUser.data.permission,
        member_bonus_settings: state.memberBonusSettings.member_bonus_settings
    }
}

const mapDispatchToProps = {
    getBonusSetting
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces("translation")(VIPBonus));