import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
// import i18n from '../../assets/locale/i18n';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from '../../assets/images/updown-arrow.png'

import * as actions from '../../store/ducks/rebateSetting/actions';

class RebateSettingEdit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            input:
            {
                status: 0,
            },
            tabId: null,
            gameType: [],
            game: [],
            rebateSettingDetail: [],
            rebateSetting: null,
            groupName: null,
            loading: true,
            errMsg: [],
            touched: false,

        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation('rebate_setting_form');
        if (this.props.selectId) {
            // console.log(this.props.selectId);
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log(nextProps.details.data.game[1]);
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newInput = Object.assign({}, this.state.input);
                nextProps.details.data.game_type.forEach((val, idx) => {
                    if (nextProps.details.data.game[val.id] !== undefined) {
                        newInput['member_group_id'] = nextProps.details.data.member_group.id;
                        newInput['common_percent'] = nextProps.details.data.common_percent || 0;

                        for (const key in nextProps.details.data.rebate_setting) {
                            newInput[key] = nextProps.details.data.rebate_setting[key];
                        }

                        for (const key in nextProps.details.data.game[val.id]) {
                            let gameId = nextProps.details.data.game[val.id][key]['id'];
                            let gameSlug = nextProps.details.data.game[val.id][key]['slug'];
                            let percent = (nextProps.details.data.rebate_setting_detail[nextProps.details.data.game[val.id][key]['id']] !== undefined) ? nextProps.details.data.rebate_setting_detail[nextProps.details.data.game[val.id][key]['id']]['percent'] : 0.00;
                            newInput[`gameid_${gameId}_${gameSlug}`] = percent;
                        }
                    }
                });
                this.setState({
                    tabId: nextProps.details.data.game_type[0].id,
                    gameType: nextProps.details.data.game_type,
                    game: nextProps.details.data.game,
                    rebateSettingDetail: nextProps.details.data.rebate_setting_detail,
                    rebateSetting: nextProps.details.data.rebate_setting,
                    input: newInput,
                    groupName: nextProps.details.data.member_group.group_name,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.updateSetting !== this.props.updateSetting && nextProps.updateSetting !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.updateSetting);
            let success = (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') ? true : false;
            if (nextProps.updateSetting.responseCode === 200 && nextProps.updateSetting.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('rebate_setting_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.updateSetting.message
            });
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.id] = event.target.value;
        this.setState({
            input: newInput
        });
    }

    changeTabHandler = (e) => {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            tabId: e.target.id
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const inputArr = {};
                Object.keys(this.state.input).forEach((val) => {
                    inputArr[val] = this.state.input[val];
                });
                // console.log(inputArr);
                this.props.funcUpdateSetting(inputArr);
            }
        });
    }

    render() {
        // console.log(this.state);
        const { t } = this.props;
        let tabId = this.state.tabId;
        let inputArr = (this.state.input) ? this.state.input : [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton='button'
                                classValue='btn btn-back-outline'
                                idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('rebate-setting.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>
                                    {t('member-group.names')}:&nbsp;{this.state.loading ? <Skeleton width={50} count={1} /> : this.state.groupName}
                                </h4>
                            </div>
                            <div className='card-body pt-0'>
                                <form method='post' id='rebate_setting_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='rebate_setting_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <hr className="pt-4" />
                                                    <div>
                                                        <h4 className="card-title mb-3">{t('rebate-setting.rebate-setting-details')}</h4>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className='form-group col-md-4'>
                                                            <label htmlFor='cashback_min'>{t('rebate-setting.cashback-min')} ({t('rebate-setting.per-day')})</label>
                                                            <NumberInput
                                                                id='cashback_min'
                                                                name='cashback_min'
                                                                // min={1}
                                                                placeholder={t('rebate-setting.cashback-min')}
                                                                value={inputArr.cashback_min || ''}
                                                                onChange={(event) => this.onInputChange(event)}
                                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label htmlFor='cashback_max'>{t('rebate-setting.cashback-max')} ({t('rebate-setting.per-day')})</label>
                                                                {/* <input className='form-control' type='number' id='cashback_max' name='cashback_max' placeholder='cashback-max' value={inputArr.cashback_max} onChange={(event) => this.onInputChange(event)} /> */}
                                                                <NumberInput
                                                                    id='cashback_max'
                                                                    name='cashback_max'
                                                                    min={Number((inputArr.cashback_min !== undefined) ? inputArr.cashback_min : 1)}
                                                                    placeholder={t('rebate-setting.cashback-max')}
                                                                    value={(inputArr.cashback_max) || ''}
                                                                    onChange={(event) => this.onInputChange(event)}
                                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label htmlFor='status'>{t('global.status')}</label>
                                                                <div className='select-input'>
                                                                    <select className='form-control filter-input' id='status' name='status' value={inputArr.status} onChange={(event) => this.onInputChange(event)}>
                                                                        <option value='0' >{t('global.inactive')}</option>
                                                                        <option value='1' >{t('global.active')}</option>
                                                                    </select>
                                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label htmlFor='min_bet'>{t('rebate-setting.min-bet')} ({t('rebate-setting.per-bet')})</label>
                                                                {/* <input className='form-control' type='number' id='min_bet' name='min_bet' placeholder='min-bet' value={inputArr.min_bet} onChange={(event) => this.onInputChange(event)} /> */}
                                                                <NumberInput
                                                                    id='min_bet'
                                                                    name='min_bet'
                                                                    // min={1}
                                                                    placeholder={t('rebate-setting.min-bet')}
                                                                    value={(inputArr.min_bet) || ''}
                                                                    onChange={(event) => this.onInputChange(event)}
                                                                    onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label htmlFor='max_bet'>{t('rebate-setting.max-bet')}</label>
                                                            <NumberInput
                                                                id='max_bet'
                                                                name='max_bet'
                                                                min={Number((inputArr.min_bet!==undefined) ? inputArr.min_bet : 1)}
                                                                placeholder={t('rebate-setting.max-bet')}
                                                                value={(inputArr.max_bet) || ''}
                                                                onChange={(event) => this.onInputChange(event)}
                                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label htmlFor='common_percent'>{t('rebate-setting.common-rebate')} (%)</label>
                                                            <NumberInput
                                                                id='common_percent'
                                                                name='common_percent'
                                                                min={Number((inputArr.common_percent!==undefined) ? inputArr.common_percent : 1)}
                                                                placeholder={t('rebate-setting.common-rebate-percent')}
                                                                value={(inputArr.common_percent || 0)}
                                                                onChange={(event) => this.onInputChange(event)}
                                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                required
                                                            />
                                                        </div>
                                                    </div> */}
                                                    </div>

                                                    <hr className="pt-4" />

                                                    <div>
                                                        <h4 className="card-title mb-3">{t('rebate-setting.game-percentage')}</h4>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <div className='panel panel-primary tabs-style-2'>
                                                                <div className='tab-menu-heading'>
                                                                    <div className='tabs-menu1'>
                                                                        <ul className='nav panel-tabs main-nav-line'>
                                                                            {
                                                                                this.state.gameType.map((val, idx) => {
                                                                                    let tabActive = (Number(tabId) === val.id) ? 'active' : '';
                                                                                    // return (<li key={idx}><a href='/#' id={val.id} onClick={(event) => this.changeTabHandler(event)} className={`nav-link email-tab ${tabActive}`}>{val.title}</a></li>);
                                                                                    return (<li key={idx}><a href='/#' id={val.id} onClick={(event) => this.changeTabHandler(event)} style={{ borderTopWidth: 0 }} className={`nav-link email-tab ${tabActive}`}>{t(`game-type.${val.slug}`)}</a></li>);
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tab-content'>
                                                                {
                                                                    this.state.gameType.map((val, idx) => {
                                                                        let tabContent = 'No game exist in this category';
                                                                        if (this.state.game[val.id] !== undefined) {
                                                                            tabContent = [];
                                                                            for (const key in this.state.game[val.id]) {
                                                                                let gameId = this.state.game[val.id][key]['id'];
                                                                                let gameSlug = this.state.game[val.id][key]['slug'];
                                                                                tabContent.push(
                                                                                    <div className='col-md-4' key={key}>
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor={`gameid_${gameId}_${gameSlug}`}>{t(`game.${key}`)} (%)</label>
                                                                                            {/* <input 
                                                                                            className='form-control' 
                                                                                            type='number' 
                                                                                            id={`gameid_${gameId}_${gameSlug}`} 
                                                                                            name={`gameid_${gameId}_${gameSlug}`}
                                                                                            max={100} 
                                                                                            value={inputArr[`gameid_${gameId}_${gameSlug}`]}
                                                                                            onChange={(event) => this.onInputChange(event)}
                                                                                            required 
                                                                                        /> */}
                                                                                            <NumberInput
                                                                                                id={`gameid_${gameId}_${gameSlug}`}
                                                                                                name={`gameid_${gameId}_${gameSlug}`}
                                                                                                max={100}
                                                                                                value={inputArr[`gameid_${gameId}_${gameSlug}`]}
                                                                                                onChange={(event) => this.onInputChange(event)}
                                                                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                            // required
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        }
                                                                        let tabActive = (Number(tabId) === val.id) ? 'active' : '';
                                                                        return (
                                                                            <div key={idx} className={`panel-body tabs-menu-body main-content-body-right border tab-pane ${tabActive}`}>
                                                                                <div className='row'>
                                                                                    {tabContent}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0 justify-content-end">
                                                        <div>
                                                            {(permission.includes('rebate_setting_update_setting')) &&
                                                                <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName='Save' />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.rebateSetting.details,
        updateSetting: state.rebateSetting.updateSetting,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdateSetting: (data) => dispatch(actions.updateSetting(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RebateSettingEdit));