import axios from "../../../common/config/axios";
import * as type from "./types";

export const getMemberLevelTracker = (data) => (dispatch) => {
    axios
        .get("member_level_tracker/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Level Tracker Error" + err);
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_LIST,
                payload: [],
            });
        });
};

export const getFilterData = () => (dispatch, getState) => {
    axios
        .get("member_level_tracker/get_filter_data", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Level Tracker Get Filter Error" + err);
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_FILTER_DATA,
                payload: [],
            });
        });
};