import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import Select from "../../components/Input/Select/Select";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Monthrangepicker from "react-bootstrap-monthrangepicker";
import "bootstrap-monthrangepicker/daterangepicker.css";

import Excel from "../../assets/images/excel.svg";

class CompanyPerformaceByDayListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };
    /* ============ function [END] ============ */

    getFilterHandler = (event) => {
        this.setState({ filter: event.target.value });
    };

    render() {
        const { t } = this.props;
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        let rsListing = [];
        let total_new_member_deposit_count = 0;
        let total_new_member_count = 0;
        let total_new_member_deposit_percentage = 0;
        let total_deposit_amt = 0;
        let total_deposit_avg = 0;
        let total_deposit_count = 0;
        let total_withdrawal_amt = 0;
        let total_withdrawal_count = 0;
        let total_withdrawal_avg = 0;
        let total_accumulated_receipt = 0;
        let total_profit_percentage = 0;
        let total_payment_gateway_fees = 0;
        let total_bank_transfer_fees = 0;
        let total_member_balance = 0;
        let total_win_lose_amt = 0;

        let filterSelection = [
            {
                label: t("company-performance-by-day.select-filter"),
                value: "",
                selected: true,
            },
            {
                label: t("company-performance-by-day.daily"),
                value: "daily",
            },
            {
                label: t("company-performance-by-day.monthly"),
                value: "monthly",
            },
            {
                label: t("company-performance-by-day.yearly"),
                value: "yearly",
            },
        ];

        // var Locale = {
        //     format: "YYYY-MM-DD H:mm:ss",
        //     separator: " - ",
        //     applyLabel: "确定",
        //     cancelLabel: "取消",
        //     fromLabel: "从",
        //     toLabel: "至",
        //     customRangeLabel: "自定义",
        //     monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        //     quarterNames: ["一季度", "二季度", "三季度", "四季度"],
        //     firstDay: 1,
        // };

        const data = this.props.data;

        data.map((val) => {
            // Calculated the total accumulated receipt
            total_new_member_count += val.new_member;
            total_new_member_deposit_count += val.new_member_deposit_count;
            // total_new_member_deposit_percentage += parseFloat(val.new_member_deposit_percentage);
            total_deposit_amt = parseFloat(total_deposit_amt) + parseFloat(val.deposit_amt);
            total_deposit_count += parseFloat(val.deposit_count);
            
            total_withdrawal_amt +=  parseFloat(val.withdrawal_amt);
            total_withdrawal_count += parseFloat(val.withdrawal_count);
            
            total_payment_gateway_fees += parseFloat(val.payment_gateway_fees);
            total_bank_transfer_fees += parseFloat(val.bank_transfer_fees);
            // total_accumulated_receipt += parseFloat(val.profit);
            if(val.profit > 0){
                total_accumulated_receipt = parseFloat(val.profit);
            }
            if(val.member_balance){
                total_member_balance = parseFloat(val.member_balance);
            }
            // total_member_balance += parseFloat(val.member_balance);
            total_win_lose_amt += parseFloat(val.win_lost_amt);

            // total_profit_percentage += parseFloat(val.profit_percentage);
            return null;
        });

        if(!isNaN(parseFloat(total_win_lose_amt) / parseFloat(total_deposit_amt))){
            total_profit_percentage = (parseFloat(total_win_lose_amt) / parseFloat(total_deposit_amt)) * 100;
        }   

        total_deposit_avg = total_deposit_amt / total_deposit_count;
        total_withdrawal_avg = total_withdrawal_amt / total_withdrawal_count;
        
        data.map((val)=>{
            total_new_member_deposit_percentage = (parseFloat(total_new_member_deposit_count) / parseFloat(total_new_member_count)) * 100;
            if(isNaN(parseFloat(total_new_member_deposit_percentage))){
                total_new_member_deposit_percentage = 0;
            }
            return null;
        })   
        
        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            { text: t("company-performance-by-day.date"), dataField: "date", footer: t("company-performance-by-day.total"), sort: true, onSort: this.props.onSort },
            {
                text: t("company-performance-by-day.login-member"),
                dataField: "total_member_active",
                footer: (totalMemberActiveData) => totalMemberActiveData.reduce((total_member_active, item) => parseFloat(total_member_active) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.total-active-member"),
                dataField: "total_betting_member",
                footer: (totalMemberBettingData) => totalMemberBettingData.reduce((total_betting_member, item) => parseFloat(total_betting_member) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.new-member"),
                dataField: "new_member",
                footer: (newMemberData) => newMemberData.reduce((new_member, item) => parseFloat(new_member) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.new-member-count"),
                dataField: "new_member_deposit_count",
                footer: (newMemberDepositData) => newMemberDepositData.reduce((new_member_deposit, item) => parseFloat(new_member_deposit) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.new-member-deposit-percentage"),
                dataField: "new_member_deposit_percentage",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_new_member_deposit_percentage).toString() + "%",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.deposit-amount"),
                dataField: "deposit_amt",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_deposit_amt).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.deposit-count"),
                dataField: "deposit_count",
                footer: (depositCount) => depositCount.reduce((deposit_count, item) => parseFloat(deposit_count) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.deposit-average"),
                dataField: "deposit_avg",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_deposit_avg).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.withdrawal-amount"),
                dataField: "withdrawal_amt",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_withdrawal_amt).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.withdrawal-count"),
                dataField: "withdrawal_count",
                footer: (withdrawalCount) => withdrawalCount.reduce((withdrawal_count, item) => parseFloat(withdrawal_count) + parseFloat(item), 0),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.withdrawal-average"),
                dataField: "withdrawal_avg",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_withdrawal_avg).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.pg-fees"),
                dataField: "payment_gateway_fees",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_payment_gateway_fees).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.transfer-fees"),
                dataField: "bank_transfer_fees",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_bank_transfer_fees).toString(),
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.accumulated-receipt"),
                dataField: "profit",
                sort: true,
                footer: (total_accumulated_receipt > 0 ) ? new Intl.NumberFormat("en-US", num_format).format(total_accumulated_receipt).toString() : "(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(total_accumulated_receipt)) + ")",
                onSort: this.props.onSort,
            },
            {   
                text: t("company-performance-by-day.member-balance"), 
                dataField: "member_balance", 
                footer: (total_member_balance > 0) ? new Intl.NumberFormat("en-US", num_format).format(total_member_balance).toString() : "(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(total_member_balance)) + ")",
                sort: true, 
                onSort: this.props.onSort 
            },
            {
                text: t("company-performance-by-day.win-lose"),
                dataField: "win_lose_amt",
                footer: (total_win_lose_amt > 0 ) ? new Intl.NumberFormat("en-US", num_format).format(total_win_lose_amt).toString() : "(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(total_win_lose_amt)) + ")",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("company-performance-by-day.profit-percentage"),
                dataField: "profit_percentage",
                footer: new Intl.NumberFormat("en-US", num_format).format(total_profit_percentage).toString() + "%",
                sort: true,
                onSort: this.props.onSort,
            },
        ];

        if (this.props.data) {
            rsListing = this.props.data.map((val) => {
                const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
                return {
                    date: val.date,
                    total_member_active: val.total_member_active,
                    total_betting_member: val.total_betting_member,
                    new_member: val.new_member,
                    new_member_deposit_count: val.new_member_deposit_count,
                    new_member_deposit_percentage: val.new_member_deposit_percentage,
                    deposit_amt: new Intl.NumberFormat("en-US", num_format).format(val.deposit_amt),
                    deposit_count: val.deposit_count,
                    deposit_avg: new Intl.NumberFormat("en-US", num_format).format(val.deposit_avg),
                    withdrawal_amt: new Intl.NumberFormat("en-US", num_format).format(val.withdrawal_amt),
                    withdrawal_count: val.withdrawal_count,
                    withdrawal_avg: new Intl.NumberFormat("en-US", num_format).format(val.withdrawal_avg),
                    member_balance: (val.member_balance >=0 ) ? new Intl.NumberFormat("en-us", num_format).format(val.member_balance) : <span className="text-danger">{"(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(val.member_balance)) + ")"}</span>,
                    profit: (val.profit >=0 ) ? new Intl.NumberFormat("en-us", num_format).format(val.profit) : <span className="text-danger">{"(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(val.profit)) + ")"}</span>,
                    win_lose_amt: (val.win_lost_amt >=0 ) ? new Intl.NumberFormat("en-us", num_format).format(val.win_lost_amt) : <span className="text-danger">{"(" + new Intl.NumberFormat("en-us", num_format).format(Math.abs(val.win_lost_amt)) + ")"}</span>,
                    profit_percentage: val.profit_percentage,
                    payment_gateway_fees: new Intl.NumberFormat("en-us", num_format).format(val.payment_gateway_fees),
                    bank_transfer_fees: new Intl.NumberFormat("en-us", num_format).format(val.bank_transfer_fees)
                };
            });
        }

        // console.log(this.props.dateRange, this.props.start_time, this.props.end_time)
        /* ======================== datatables data assign [END] ======================== */
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("company-performance-by-day._title")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div>
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div className="form-horizontal filter-function row">
                                        {/* <div className='row'> */}
                                        <div className="col-md-4 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("company-performance-by-day.select-filter")}</label>
                                                <Select
                                                    options={filterSelection}
                                                    name="filter"
                                                    className="form-control filter-input"
                                                    onChange={this.getFilterHandler}
                                                    value={this.state.filter}
                                                />
                                            </div>
                                        </div>

                                        {this.state.filter === "daily" && (
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">{t("company-performance-by-day.date")}</label>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check" />
                                                        </span>
                                                        <DateTimePicker
                                                            id="date"
                                                            name="date"
                                                            className="form-control filter-input"
                                                            range={false}
                                                            timePicker={false}
                                                            value={this.props.filter.date}
                                                            onChange={this.props.onInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* </div> */}

                                        {this.state.filter === "monthly" && (
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">{t("company-performance-by-day.monthly")}</label>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check" />
                                                        </span>
                                                        <Monthrangepicker
                                                            monthOrQuarter={0}
                                                            containerClass="monthly w-100"
                                                            onEvent={this.props.onInputChange}
                                                        >
                                                            <button className="form-control filter-input">
                                                                {(this.props.filter.start_month && this.props.filter.end_month) 
                                                                ?
                                                                    `${this.props.filter.start_month} ${t('global.to')} ${this.props.filter.end_month}` 
                                                                : 
                                                                    ''
                                                                }
                                                            </button>
                                                        </Monthrangepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {this.state.filter === "yearly" && (
                                            <div className="col-md-4 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">{t("company-performance-by-day.yearly")}</label>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check" />
                                                        </span>
                                                        <Monthrangepicker
                                                            monthOrQuarter={2}
                                                            containerClass="yearly w-100"
                                                            onEvent={this.props.onInputChange}
                                                        >
                                                            <button className="form-control filter-input">
                                                                {(this.props.filter.start_year && this.props.filter.end_year) 
                                                                ?
                                                                    `${this.props.filter.start_year} ${t('global.to')} ${this.props.filter.end_year}` 
                                                                : 
                                                                    ''
                                                                }
                                                            </button>
                                                        </Monthrangepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        
                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                                {(() => {
                                                    if (this.props.data) {
                                                        return (
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-blue"
                                                                clicked={() => this.props.exportExcelHandler()}
                                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                                buttonName={t("report.export-excel")}
                                                            />
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("company-performance-by-day.listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="date"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(CompanyPerformaceByDayListing);
