import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import GameCategoryListing from './GameCategoryListing';
import GameCategoryEdit from "./GameCategoryEdit";
import * as actions from '../../store/ducks/gameCategory/actions';

const initialInputState =
{
    /* form input value */
    // vendor: '',
    title: '',
    status: '',
};
class GameCategory extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            page: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                vendor: [],
            },

            /* details id */
            selectId: null,

            /* skeleton loading */
            loading: true,

            permission: [],
            gameCategoryEditId: null,
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.updateStatusHandler = this.updateStatusHandler.bind(this);
    }

    componentDidMount()
    {
        const listingArr = this.getFilterArray();
        this.props.funcGetList(listingArr);
        this.props.funcGetDropDown(); // get filter drop down
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { t } = this.props;

        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let gameCategoryPermission = this.props.currentPermission.filter((item) => item.includes("game_category_"));
            this.setState({ permission: gameCategoryPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        if (nextProps.updateStatus !== this.props.updateStatus && nextProps.updateStatus!==undefined)
        {
            if(nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    this.refreshHandler();
                });
            }
            else
            {
                let msg = null;
                msg = '<ul>';
                nextProps.updateStatus.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        vendor: nextProps.dropDown.data.vendor,
                    }
                });
            }
        }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        if(event.target.id==='search')
        {
            this.setState({
                searchValue: event.target.value
            });
        }
        else
        {
            let newFilter = Object.assign({}, this.state.filter);
            newFilter[event.target.id] = event.target.value;
            this.setState({
                filter: newFilter
            });
        }
    }

    searchKeyPressHandler = (event) =>
    {
        //Checking press the enter only perform search action
        if(event.key === 'Enter')
        {
            this.refreshHandler();
        }
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            // limit: 10,
            searchValue: '',
            // order: [{'id':'id', 'desc':'asc'}],
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            gameCategoryEditId: id,
            pageShow: "edit",
        });
    };

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        // this.loadingHandler();
        const listingArr = {};
        let availablePage = this.state.activePage;
        let cat = this.props.location.pathname;
        let cat_id = cat.replace("/game_category/", "");
        console.log(cat_id)
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.vendor = cat_id;

        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
            errMsg: [],
            touched: false,
        });
    }

    updateStatusHandler = (event, type) =>
    {
        // console.log(event.target.name+' | '+event.target.value+' | '+type);
        this.loadingHandler();
        let data = {};
        data.game_id = event.target.id;
        data.country_id = this.props.currentUser.country_id;
        data.status = event.target.value;
        data.type = type;

        // console.log(data);
        this.props.funcUpdateStatus(data);
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    /* ============ datatables function [END] ============ */

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <GameCategoryListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        detailsHandler={(event, page, id) => this.detailsHandler(event, page, id)}
                        onSort={(field, order) => this.onSort(field, order)}
                        updateStatusHandler={(event, type) => this.updateStatusHandler(event, type)}
                        editHandler={(event, id) => this.editHandler(event, id)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        /* filter drop down */
                        ddVendor={this.state.dropDown.vendor}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        currentUser={this.props.currentUser}
                        permission={this.state.permission}
                    />
                }

                {this.state.permission.includes("game_category_edit") && this.state.pageShow === "edit" && (
                    <GameCategoryEdit
                        gameCategoryEditId={this.state.gameCategoryEditId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.gameCategory.rs,
        dropDown: state.gameCategory.dropDown,
        updateStatus: state.gameCategory.updateStatus,
        currentUser: state.currentUser.data,
        currentPermission: state.currentUser.data.permission,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcUpdateStatus: (data) => dispatch(actions.updateStatus(data)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(GameCategory));