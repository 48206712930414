import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = () => dispatch => 
{
    axios.get('permission/list')
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (id) => dispatch =>
{   
    // if(id > 0)
    // {
      axios.get('permission/show',{
        params: {
            "id": id,
        }
      })
      .then(function (response) 
      {
        dispatch (
        {
          type: actionType.DETAILS,
          value: response.data
        })
      })
      .catch(function (error) 
      {
        
      });
    // }
};

export const update = (data) => (dispatch, getState) =>
{
    // console.log(data);
    data.country_id = getState().currentUser.data.country_id;
    axios.post('permission/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const store = (data) => (dispatch, getState) =>
{
    // console.log(data);
    data.country_id = getState().currentUser.data.country_id;
    axios.post('permission/store', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.STORE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};



