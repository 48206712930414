import React, { Component } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showFloatImage } from "../../store/ducks/floatImage/actions";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";

//Initialize the language array first
let langArr = [];

class FloatImageShow extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        desktop_image_name: [],
        mobile_image_name: [],
        desktop_image_link: [],
        mobile_image_link: [],
        desktop_image_display_type: [],
        mobile_image_display_type: [],
        start_time: "",
        end_time: "",
        status: "",
        loading: true,
    };

    componentDidMount() {
        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        const showFloatingImageData = {};
        showFloatingImageData.floating_id = this.props.show_floating_image_id;

        this.props.showFloatImage(showFloatingImageData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_floating_image_data !== this.props.show_floating_image_data) {
            if (nextProps.show_floating_image_data.data.responseCode === 200) {
                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    desktop_image_name,
                    mobile_image_name,
                    desktop_image_link,
                    mobile_image_link,
                    desktop_image_display_type,
                    mobile_image_display_type,
                } = this.state;

                nextProps.show_floating_image_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    desktop_image_name[item.lang_id] = item.desktop_image_name;
                    mobile_image_name[item.lang_id] = item.mobile_image_name;
                    desktop_image_link[item.lang_id] = item.desktop_image_link;
                    mobile_image_link[item.lang_id] = item.mobile_image_link;
                    desktop_image_display_type[item.lang_id] = item.desktop_image_display;
                    mobile_image_display_type[item.lang_id] = item.mobile_image_display;

                    return item;
                });

                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    desktop_image_name,
                    mobile_image_name,
                    desktop_image_link,
                    mobile_image_link,
                    desktop_image_display_type,
                    mobile_image_display_type,
                    start_time: nextProps.show_floating_image_data.data.data[0].start_time,
                    end_time: nextProps.show_floating_image_data.data.data[0].end_time,
                    status: nextProps.show_floating_image_data.data.data[0].status,
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_floating_image_data.data.message,
                    icon: nextProps.show_floating_image_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        langId: data[item].languageId,
                        langCode: data[item].languageCode,
                        langName: data[item].languageName,
                    });
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t("float-image.desktop-image")}</label>

                        <div className="imgPreview floating-image-preview-image">
                            <img alt="" className="show-floating-image-size" src={this.state.desktop_image_preview[item.langId]} />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t("float-image.mobile-image")}</label>

                        <div className="imgPreview floating-image-preview-image">
                            <img alt="" className="show-floating-image-size" src={this.state.mobile_image_preview[item.langId]} />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.desktop-image-name")}</label>
                        <p>
                            <strong>{this.state.desktop_image_name[item.langId]}</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.mobile-image-name")}</label>
                        <p>
                            <strong>{this.state.mobile_image_name[item.langId]}</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.desktop-image-link")}</label>
                        <p>
                            <strong>{this.state.desktop_image_link[item.langId]}</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.mobile-image-link")}</label>
                        <p>
                            <strong>{this.state.mobile_image_link[item.langId]}</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.desktop-image-display-type")}</label>
                        <p>
                            <strong>{this.state.desktop_image_display_type[item.langId]}</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t("float-image.mobile-image-display-type")}</label>
                        <p>
                            <strong>{this.state.mobile_image_display_type[item.langId]}</strong>
                        </p>
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('float-image.float-image')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-secondary"
                                idValue="back"
                                buttonName={t("global.back-to-list")}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("float-image.show-float-image")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr className="show-floating-image-white">
                                                <th>{t("float-image.float-image-details")}</th>
                                                <td field-key="float_image_details">{tabMessage}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("float-image.start-time")}</th>
                                                <td field-key="start_time">{this.state.start_time}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("float-image.end-time")}</th>
                                                <td field-key="end_time">{this.state.end_time}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("float-image.status")}</th>
                                                <td field-key="status">{this.state.status === 1 ? "ACTIVE" : "INACTIVE"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, floatImage, currentUser } = state;

    return {
        country_data: home.country_data,
        show_floating_image_data: floatImage.show_floating_image_data,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    showFloatImage,
    getCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(FloatImageShow));
