import React, { Component } from "react";
import { connect } from "react-redux";
import { editEmailContent, updateEmailContent } from "../../store/ducks/emailContent/actions";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

class EmailContentEdit extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            type: "",
            subject: "",
            content: "",
            loading: true,
            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {
        this.props.editEmailContent(this.props.emailContentEditId);
        formValidation("email_content_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContentEdit] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);
        if (nextProps.emailContentEditData !== this.props.emailContentEditData && nextProps.emailContentEditData !== undefined) {
            if (nextProps.emailContentEditData.responseCode === 200 && nextProps.emailContentEditData.msgType === 'success') {
                let emailContentData = nextProps.emailContentEditData.data.emailContent[0];
                this.setState({
                    id: emailContentData.emailContentId,
                    type: emailContentData.type,
                    subject: emailContentData.subject,
                    content: emailContentData.content,
                    loading: false,
                });
            }else{
                this.setState({
                    loading: true,
                }, () => {
                    filterErrorHandler(nextProps.emailContentEditData.message);
                });
                // Swal.close();
            }
        }

        if (nextProps.updateEmailContentResult !== this.props.updateEmailContentResult && nextProps.updateEmailContentResult !== undefined) {
            if (nextProps.updateEmailContentResult.responseCode === 200 && nextProps.updateEmailContentResult.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateEmailContentResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateEmailContentResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    updateEmailContentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let updateEmailContentData = {};
                updateEmailContentData.id = this.state.id;
                updateEmailContentData.type = this.state.type;
                updateEmailContentData.subject = this.state.subject;
                updateEmailContentData.language = this.state.language;
                updateEmailContentData.content = this.state.content;
                this.props.updateEmailContent(updateEmailContentData);
            }
        });
    };

    render() {
        const { t } = this.props;
        let display = null;

        if (!this.state.loading) {
            display = (
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.edit-email-content")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="email_content_form" className="form-horizontal" onSubmit={this.updateEmailContentHandler} noValidate>
                                    <ValidationError formName="email_content_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email_type">{t("email-content.email-type")}</label>
                                                <StringInput
                                                    id="type"
                                                    name="type"
                                                    className="form-control"
                                                    placeholder={t("email-content.email-type")}
                                                    value={this.state.type}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email_subject">{t("email-content.email-subject")}</label>
                                                <StringInput
                                                    id="subject"
                                                    name="subject"
                                                    className="form-control"
                                                    placeholder={t("email-content.email-subject")}
                                                    value={this.state.subject}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="content">{t("email-content.email-content")}</label>
                                            <JoditEditor
                                                value={this.state.content}
                                                onBlur={(newContent) => {
                                                    this.setState({
                                                        content: newContent,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("email-content.edit-email-content")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton count={4} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("email-content.email-content")}</h4>
                        </div>
                    </div>
                </div>
                {display}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emailContentEditData: state.emailContent.email_content_edit_data,
        updateEmailContentResult: state.emailContent.email_content_update_result,
        currentLanguageCode: state.currentUser.data.language_code
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editEmailContent: (id) => dispatch(editEmailContent(id)),
        updateEmailContent: (updateEmailContentData) => dispatch(updateEmailContent(updateEmailContentData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailContentEdit));
