import React, {Component} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import { withNamespaces } from 'react-i18next';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select2 from "../../components/Input/Select2/Select2";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";
import DatePicker from "react-datepicker";
import Tab from "../../components/Tab/Tab";
import {getCountry} from "../../store/ducks/home/actions";
import {getMemberGroup,getType,updateTournament, showTournament} from "../../store/ducks/tournaments/actions";
import {formValidation} from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";

//Initialize the language array first
let langArr = [];
let userMemberArry = [];
let typeArry = [];
class TournamentEdit extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        title:[],
        tournament_content:[],
        image_desktop_name: [],
        image_mobile_name: [],
        image_link_desktop_image: [],
        image_link_mobile_image: [],
        target:[],
        sequence:[],
        min_bet:[],
        max_bet:[],
        start_time: '',
        end_time: '',
        member_group:[],
        currentStatus: 1,
        type:1,
        loading: true,
        group_name: [],
        error_message: [],
        touched: false,
        desktop_image: [],
        mobile_image: []
    };

    componentDidMount() {
        formValidation('tournament_form');
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;
        const getTournamentData = {};

        const showTournamentData = {};
        showTournamentData.tournament_id = this.props.show_tournament_id;
        this.props.getMemberGroup(getTournamentData);
        this.props.getType(getTournamentData);
        this.props.showTournament(showTournamentData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
       if(nextProps.update_tournament_result !== this.props.update_tournament_result){
            if(nextProps.update_tournament_result.responseCode === 200){

                this.setState({touched: false});
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_tournament_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler('listing');
                    }
                })
            } else if (nextProps.update_tournament_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_tournament_result.message
                });
                Swal.close();
            } else {
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_tournament_result.message[0],
                    icon: nextProps.update_tournament_result.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }

        }



        if (nextProps.show_tournament_data !== this.props.show_tournament_data) {
            if (nextProps.show_tournament_data.data.responseCode === 200) {
                const getCurrentMemberGroupName = nextProps.show_tournament_data.data.data[0].member_group;
                const getCurrentMemberGroupID = nextProps.show_tournament_data.data.data[0].member_group_id;
                let userMemberArry = [];

                if (getCurrentMemberGroupName !== "") {
                    const getCurrentMemberGroupNameArry = getCurrentMemberGroupName;
                    const getCurrentMemberGroupIDArry = getCurrentMemberGroupID;
                    console.log(getCurrentMemberGroupNameArry,getCurrentMemberGroupIDArry);
                    Object.keys(getCurrentMemberGroupNameArry).map((item, i) => {
                        return userMemberArry.push({
                            'label': getCurrentMemberGroupNameArry[item],
                            'value': getCurrentMemberGroupIDArry[item]
                        });
                    });
                }

                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    title,
                    tournament_content,
                    image_desktop_name,
                    image_mobile_name,
                    image_link_desktop_image,
                    image_link_mobile_image,
                }
                    = this.state;

                nextProps.show_tournament_data.data.data.map((item, i) => {
                        desktop_image_preview[item.lang_id] = item.desktop_image;
                        mobile_image_preview[item.lang_id] = item.mobile_image;
                        title[item.lang_id] = item.title;
                        tournament_content[item.lang_id] = item.content;
                        image_desktop_name[item.lang_id] = item.desktop_image_name;
                        image_mobile_name[item.lang_id] = item.mobile_image_name;
                        image_link_desktop_image[item.lang_id] = item.desktop_image_link;
                        image_link_mobile_image[item.lang_id] = item.mobile_image_link;

                        return item;
                })

                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    title,
                    tournament_content,
                    image_desktop_name,
                    image_mobile_name,
                    image_link_desktop_image,
                    image_link_mobile_image,
                    target: nextProps.show_tournament_data.data.data[0].target,
                    sequence: nextProps.show_tournament_data.data.data[0].sequence,
                    group_name: userMemberArry.length > 0 ? userMemberArry : '',
                    type: nextProps.show_tournament_data.data.data[0].type,
                    min_bet: nextProps.show_tournament_data.data.data[0].min_bet,
                    max_bet: nextProps.show_tournament_data.data.data[0].max_bet,
                    start_time: new Date(nextProps.show_tournament_data.data.data[0].start_time),
                    end_time: new Date(nextProps.show_tournament_data.data.data[0].end_time),
                    currentStatus: nextProps.show_tournament_data.data.data[0].status
                })
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({loading: false});
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            }else if (nextProps.country_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message
                });
                Swal.close();
            } else {
                this.setState({loading: false});
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                })
            }
        }

        if(nextProps.member_group_data !== this.props.member_group_data) {
            if (nextProps.member_group_data.data.responseCode === 200) {
                Swal.close();
                userMemberArry = [];
                let data = nextProps.member_group_data.data.data;
                Object.keys(data).map((item, i) => {
                    return userMemberArry.push({
                        'label': data[item].group_name,
                        'value': data[item].Id
                    });
                })
            }
        }

        if(nextProps.type_data !== this.props.type_data) {
            if (nextProps.type_data.data.responseCode === 200) {
                Swal.close();
                typeArry = [];
                let data = nextProps.type_data.data.data;
                Object.keys(data).map((item, i) => {
                    return typeArry.push({
                        'label': data[item].type,
                        'value': data[item].Id
                    });
                })
            }
        }

        return true;
    }

    updateTournamentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: '',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                const currentGroupName = this.state.group_name;
                const title = this.state.title;
                const tournament_content = this.state.tournament_content;
                const image_desktop_name = this.state.image_desktop_name;
                const image_mobile_name = this.state.image_mobile_name;
                const image_link_desktop_image = this.state.image_link_desktop_image;
                const image_link_mobile_image = this.state.image_link_mobile_image;

                currentGroupName.map(itemGroupName => {
                    return formData.append("member_group[]", itemGroupName.value);
                });

                if(this.state.desktop_image.length > 0)
                {
                    const desktop_image = this.state.desktop_image;
                    desktop_image.forEach((val, idx) => {
                        formData.append("desktop_image["+idx+"]", val);
                    });
                }

                if(this.state.mobile_image.length > 0)
                {
                    const mobile_image = this.state.mobile_image;
                    mobile_image.forEach((val, idx) => {
                        formData.append("mobile_image["+idx+"]", val);
                    });
                }

                title.forEach((val, idx) => {
                    formData.append("tournament_title[" + idx + "]", val);
                });

                tournament_content.forEach((val, idx) => {
                    formData.append("tournament_content[" + idx + "]", val);
                });

                image_desktop_name.forEach((val, idx) => {
                    formData.append("image_name_desktop_image[" + idx + "]", val);
                });

                image_mobile_name.forEach((val, idx) => {
                    formData.append("image_name_mobile_image[" + idx + "]", val);
                });

                image_link_desktop_image.forEach((val, idx) => {
                    formData.append("image_link_desktop_image[" + idx + "]", val);
                });

                image_link_mobile_image.forEach((val, idx) => {
                    formData.append("image_link_mobile_image[" + idx + "]", val);
                });

                formData.append("start_time", moment(this.state.start_time).format("yyyy-MM-DD HH:mm"));
                formData.append("end_time", moment(this.state.end_time).format("yyyy-MM-DD HH:mm"));
                formData.append("status", this.state.currentStatus);
                formData.append("target_settings", this.state.target);
                formData.append("type", this.state.type);
                formData.append("sequence", this.state.sequence);
                formData.append("min_bet", this.state.min_bet);
                formData.append("max_bet", this.state.max_bet);
                formData.append("country_id", sessionStorage.getItem('countryId'));
                formData.append("id", this.props.show_tournament_id);

                this.props.updateTournament(formData);
            }
        })

    }

    getDesktopImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const {desktop_image_preview, desktop_image} = this.state;

        reader.onloadend = () => {

            desktop_image_preview[langId] = reader.result;
            desktop_image[langId] = file;

            this.setState({
                desktop_image_preview,
                desktop_image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            desktop_image_preview[langId] = "";
            this.setState({
                desktop_image_preview,
            });
        }
    };

    getMobileImageHandler = (event, langId) => {
        let reader = new FileReader();
        let file = event[0];

        const {mobile_image_preview, mobile_image} = this.state;

        reader.onloadend = () => {
            mobile_image_preview[langId] = reader.result;
            mobile_image[langId] = file;

            this.setState({mobile_image_preview, mobile_image});
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            mobile_image_preview[langId] = "";
            this.setState({
                mobile_image_preview,
            });
        }
    }

    getTournamentTitleHandler = (event, langId) => {

        const {title} = this.state;

        title[langId] = event.target.value;

        this.setState({
            title
        });
    }

    getDesktopImageNameHandler = (event, langId) => {
        const {image_desktop_name} = this.state;

        image_desktop_name[langId] = event.target.value;

        this.setState({
            image_desktop_name
        });
    }

    getMobileImageNameHandler = (event, langId) => {
        const {image_mobile_name} = this.state;

        image_mobile_name[langId] = event.target.value;

        this.setState({
            image_mobile_name
        });
    }

    getDesktopImageLinkHandler = (event, langId) => {
        const {image_link_desktop_image} = this.state;

        image_link_desktop_image[langId] = event.target.value;

        this.setState({
            image_link_desktop_image
        });
    }

    getMobileImageLinkHandler = (event, langId) => {
        const {image_link_mobile_image} = this.state;

        image_link_mobile_image[langId] = event.target.value;

        this.setState({
            image_link_mobile_image
        });
    }

    getTargetHandler = (event) => {
        this.setState({
            target:event.target.value
        });

    }

    getSequenceHandler = (event) => {
        this.setState({
            sequence:event.target.value
        });

    }

    getMinBetHandler = (event) => {
        this.setState({
            min_bet:event.target.value
        });

    }

    getMaxBetHandler = (event) => {
        this.setState({
            max_bet:event.target.value
        });
    }

    getTypeHandler = (event) => {
        this.setState({type: event.target.value});
    }

    getMemberGroupHandler = (event) => {
        this.setState({group_name:event});
    }

    handleStartTimeChange = (date) => {
        this.setState({
            start_time: date,
        });
    };

    handleEndTimeChange = (date) => {
        this.setState({
            end_time: date
        });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value});
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = null;
        let tabContentMessage = [];

        let statusArry = [
            {
                label: t('global.active'),
                value: 1,
                selected: true
            },
            {
                label: t('global.inactive'),
                value: 0,
            }
        ];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t('tournament.desktop-image')}</label>
                        <ImageUpload
                            id="desktop_image"
                            name={`desktop_image[${item.langId}]`}
                            onChange={(event) => this.getDesktopImageHandler(event, item.langId)}
                        />

                        <div className="imgPreview tournament-preview-image">
                            <img
                                alt=""
                                className="tournament"
                                src={this.state.desktop_image_preview[item.langId]}
                            />
                        </div>

                        {this.state.desktop_image_preview.length === 0 &&
                        <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t('tournament.mobile-image')}</label>
                        <ImageUpload
                            id="mobile_image"
                            name={`mobile_image[${item.langId}]`}
                            onChange={(event) => this.getMobileImageHandler(event, item.langId)}
                        />

                        <div className="imgPreview tournament-preview-image">
                            <img
                                alt=""
                                className="tournament"
                                src={this.state.mobile_image_preview[item.langId]}
                            />
                        </div>

                        {this.state.mobile_image_preview.length === 0 &&
                        <span className="text-muted"><strong>*jpg, png, gif</strong></span>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.title')}</label>
                        <StringInput
                            onChange={(event) => this.getTournamentTitleHandler(event, item.langId)}
                            id="tournament_title"
                            name={`tournament_title[${item.langId}]`}
                            value={this.state.title[item.langId] || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.content')}</label>
                        <JoditEditor
                            value={this.state.tournament_content[item.langId] || ''}
                            tabIndex={item.langId} // tabIndex of textarea
                            onBlur={newContent => {
                                const {tournament_content} = this.state;
                                tournament_content[item.langId] = newContent;
                                this.setState({
                                    tournament_content
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.desktop-image-name')}</label>
                        <StringInput
                            onChange={(event) => this.getDesktopImageNameHandler(event, item.langId)}
                            id="image_desktop_name"
                            name={`image_desktop_name[${item.langId}]`}
                            value={this.state.image_desktop_name[item.langId] || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.mobile-image-name')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageNameHandler(event, item.langId)
                            }}
                            id="image_mobile_name"
                            name={`image_mobile_name[${item.langId}]`}
                            value={this.state.image_mobile_name[item.langId] || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.desktop-image-link')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getDesktopImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_desktop_image"
                            name={`image_link_desktop_image[${item.langId}]`}
                            value={this.state.image_link_desktop_image[item.langId] || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('tournament.mobile-image-link')}</label>
                        <StringInput
                            onChange={(event) => {
                                this.getMobileImageLinkHandler(event, item.langId)
                            }}
                            id="image_link_mobile_image"
                            name={`image_link_mobile_image[${item.langId}]`}
                            value={this.state.image_link_mobile_image[item.langId] || ''}
                        />
                    </div>
                </div>

            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage}/>;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('tournament.tournament')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('tournament.edit-tournament')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4}/> :
                                    <form method="post" id="tournament_form" className="form-horizontal"
                                          onSubmit={this.updateTournamentHandler} noValidate>
                                        <ValidationError formName="tournament_form" touched={this.state.touched}
                                                         message={this.state.error_message}/>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.start-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                                                    </span>
                                                            <DatePicker
                                                                name="start_time"
                                                                className="form-control"
                                                                selected={this.state.start_time}
                                                                onChange={this.handleStartTimeChange}
                                                                minDate={new Date()}
                                                                showTimeSelect
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.end-time')}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                                            </span>
                                                        </div>
                                                        <DatePicker
                                                            name="end_time"
                                                            className="form-control"
                                                            selected={this.state.end_time}
                                                            onChange={this.handleEndTimeChange}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="group">{t('tournament.member-group')}</label>
                                                <Select2
                                                    options={userMemberArry}
                                                    name="member_group"
                                                    onChange={this.getMemberGroupHandler}
                                                    value={this.state.group_name}
                                                />
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.type')}</label>
                                                    <Select
                                                        id="type"
                                                        name="type"
                                                        className="form-control"
                                                        options={typeArry}
                                                        onChange={this.getTypeHandler}
                                                        value={this.state.type}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.target-setting')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.getTargetHandler(event)}
                                                        id="target"
                                                        name="target"
                                                        value={this.state.target}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.min-bet')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.getMinBetHandler(event)}
                                                        id="min_bet"
                                                        name="min_bet"
                                                        value={this.state.min_bet}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.max-bet')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.getMaxBetHandler(event)}
                                                        id="max_bet"
                                                        name="max_bet"
                                                        value={this.state.max_bet}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.sequence')}</label>
                                                    <StringInput
                                                        onChange={(event) => this.getSequenceHandler(event)}
                                                        id="sequence"
                                                        name="sequence"
                                                        value={this.state.sequence}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('tournament.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                        value={this.state.currentStatus}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-group mb-0 mt-3 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-primary"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const {home, tournament} = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        member_group_data: tournament.member_group_data,
        type_data: tournament.type_data,
        update_tournament_result: tournament.update_tournament_result,
        show_tournament_data: tournament.show_tournament_data
    }
};

const mapDispatchToProps = {
    getCountry,
    getMemberGroup,
    getType,
    updateTournament,
    showTournament
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(TournamentEdit));