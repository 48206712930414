import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const rewardItemReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.DETAILS]: (state, action) => ({
        ...state,
        details: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    [type.UPDATE]: (state, action) => ({
        ...state,
        update: action.value,
    }),
    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.value,
    }),
    [type.HISTORY]: (state, action) => ({
        ...state,
        history: action.value
    }),
    [type.HISTORY_DETAILS]: (state, action) => ({
        ...state,
        history_details: action.value
    }),
    [type.HISTORY_UPDATE]: (state, action) => ({
        ...state,
        history_update: action.value
    }),
    [type.HISTORY_REFUND]: (state, action) => ({
        ...state,
        history_refund: action.value
    })
});

export default rewardItemReducer;