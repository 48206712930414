import React from "react";
import PropTypes from "prop-types";

export const MultipleCheckBox = (props) => {
    return (
        // <>
        //     <input key={props.id} type="checkbox" onChange={props.handleCheckChildElement} checked={props.isChecked} value={props.value} className={props.className} /> <span>{props.label}</span>
        // </>
        <div className={`yl-checkbox-container ${props.className}`}>
            <input
                key={props.id}
                type="checkbox"
                onChange={props.handleCheckChildElement}
                checked={props.isChecked}
                value={props.value}
                id={props.value}
            />
            <label className={`${props.label ? "margin-r" : ""}`} for={props.value}>{props.label}</label>
        </div>
    );
};

export default MultipleCheckBox;


MultipleCheckBox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleCheckChildElement: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string
};
