import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Line, Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import Skeleton from "react-loading-skeleton";
import { Dropdown } from 'react-bootstrap';
import { MdToday} from 'react-icons/md';
// import { Sparklines, SparklinesLine } from 'react-sparklines';
// import ProfileIcon from "../../assets/images/profile/profileIcon.jpg";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getTotalRecords,getBankTransferRecords,getFpayRecords,getWithdrawRecords, getDData, getDashboardData } from "../../store/ducks/dashboard/actions";
import { getCurrentPermission } from "../../store/ducks/currentUser/actions";

import depositIcon from "../../assets/images/deposit.png";
import withdrawIcon from "../../assets/images/withdraw.png"

const currency = {
    1: "MYR", 4: "IDR"
};
class Dashboard extends Component {
    constructor (props){
        super(props);
        this.state = {
            run: false,
            permission: [],
            permissionSet: true,
            date: "Today",
            total_deposit_amount: "",
            total_deposit_time: "0",
            total_withdrawal_amount: "0.00",
            total_withdrawal_time: "0",
            total_earning_amount:"",
            total_member: "0",
            total_last_week_deposit_amount: "",
            total_last_week_withdrawal_amount: "",
            total_last_week_member: "0",
            openDateSelection: false,
            loading: true,
            chart1Lables: [],
            bank_transfer_data : [],
            fpay_data : [],
            withdraw_data : [],
            ddata : [],

        };
   }


    componentDidMount() {
        // this.fetchData();
        this.waitPermission();
        this.calMinPerHours();
    }

    shouldComponentUpdate(nextProps, nextState) {
        //const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined && nextState.permissionSet === true) {
            const availablePermission = ["dashboard_access"];
            let dashboardPermission = this.props.currentPermission.filter((item) => availablePermission.includes(item));
            if (dashboardPermission.includes("dashboard_access")) {
                this.fetchData();
            }
            this.setState({ permission: dashboardPermission, permissionSet: false, loading: false });
        }

        if (nextProps.getDashboardRecord !== this.props.getDashboardRecord) {
            if (nextProps.getDashboardRecord.data.responseCode === 200) {
                this.setState(
                    {
                        total_deposit_amount: nextProps.getDashboardRecord.data.data.total_deposit_amount,
                        total_deposit_time: nextProps.getDashboardRecord.data.data.total_deposit_time,
                        total_withdrawal_amount2: nextProps.getDashboardRecord.data.data.total_withdrawal_amount,
                        total_withdrawal_time: nextProps.getDashboardRecord.data.data.total_withdrawal_time,
                        total_earning_amount: nextProps.getDashboardRecord.data.data.dashboard_chart_data.total_earnings,
                        // total_earning_amount:nextProps.getDashboardRecord.data.data.total_deposit_amount - nextProps.getDashboardRecord.data.data.total_deposit_amount,
                        total_member: nextProps.getDashboardRecord.data.data.total_member,
                        total_last_week_deposit_amount: nextProps.getDashboardRecord.data.data.total_last_week_deposit_amount,
                        total_last_week_withdrawal_amount: nextProps.getDashboardRecord.data.data.total_last_week_withdrawal_amount,
                        total_last_week_member: nextProps.getDashboardRecord.data.data.total_last_week_member,
                        bank_transfer_data: nextProps.getDashboardRecord.data.data.total_deposit_average_bank_transfer_count,
                        fpay_data: nextProps.getDashboardRecord.data.data.total_deposit_average_payment_gateway_count,
                        withdraw_data: nextProps.getDashboardRecord.data.data.total_withdraw_average_bank_transfer_count,
                        ddata: nextProps.getDashboardRecord.data.data.dashboard_chart_data
                    },
                    () => {
                        this.setState({
                            loading: false, 
                        });
                    }
                );
            } else {
                this.setState({
                    loading: false,
                });
            }
        }
        if (nextProps.bankTransferRecord !== this.props.bankTransferRecord) {
            if (nextProps.bankTransferRecord.data.responseCode === 200) {
                this.setState(
                    {
                        bank_transfer_data: nextProps.bankTransferRecord.data.data,
                    },
                    () => {
                        this.setState({
                            loading: false,
                        });
                    }

                );
            } else {
                this.setState({
                    loading: false,
                });
            }
        }

        if (nextProps.FpayRecord !== this.props.FpayRecord) {
            if (nextProps.FpayRecord.data.responseCode === 200) {
                this.setState(
                    {
                        fpay_data: nextProps.FpayRecord.data.data,
                    },
                    () => {
                        this.setState({
                            loading: false,
                        });
                    }
                );
            } else {
                this.setState({
                    loading: false,
                });
            }
        }

        if (nextProps.withdrawRecord !== this.props.withdrawRecord) {
            if (nextProps.withdrawRecord.data.responseCode === 200) {
                this.setState(
                    {
                        withdraw_data: nextProps.withdrawRecord.data.data,
                    },
                    () => {
                        this.setState({
                            loading: false,
                        });
                    }
                );
            } else {
                this.setState({
                    loading: false,
                });
            }

        }

        if (nextProps.DData !== this.props.DData) {
            if (nextProps.DData.data.responseCode === 200) {
                this.setState(
                    {
                        ddata: nextProps.DData.data.data,
                    },
                    () => {
                        this.setState({
                            loading: false,
                        });
                    }
                );
            } else {
                this.setState({
                    loading: false,
                });
            }
        }

        return true;
    }

    waitPermission = () => {
        setTimeout(() => {
            if(this.props.currentPermission !== undefined) {
                this.setState({run: true});
                return null;
            } else {
                this.waitPermission();
            }
        }, 300)
    }

    fetchData() {
        const payload = {
            date: this.state.date,
        };
        this.props.getDashboardData(payload);
        // this.props.getTotalRecords(payload);
        // this.props.getBankTransferRecords(payload);
        // this.props.getFpayRecords(payload);
        // this.props.getWithdrawRecords(payload);
        // this.props.getDData(payload);
       
    }

    setSelectedDate = (selectedDate) => {
        this.setState(
            {
                date: selectedDate,
                loading: true,
            },
            () => {
                const payload = {
                    date: this.state.date,
                };
                this.props.getDashboardData(payload);
                // this.props.getDData(payload);
            }
        );
    };

    toggleDateSelection = () => {
        this.setState({
            openDateSelection: !this.state.openDateSelection,
        });
    };

    calMinPerHours = () => {
        var x = 10; //minutes interval
        var times = []; // time array
        var tt = 0; // start time
        // var ap = ['AM', 'PM']; // AM-PM

        //loop to increment the time and push results in array
        for (var i=0;tt<24*60; i++) {
        var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        var mm = (tt%60); // getting minutes of the hour in 0-55 format
        times[i] = ("0" + (hh %24)).slice(-2) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
        }

        this.setState({
            chart1Lables: times,
        });
    }

    render() {        
        //set Line chart Y axes
       
        const { t } = this.props;
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        let bank_count = [];
        let bank_time_diff = [];
        let fpay_count = []
        let fpay_time_diff = [];
        let withdraw_count = []
        let withdraw_time_diff = [];
        let compareText = "";

        //Change the compared word based on the date choosen
        switch(this.state.date){
            case 'Today':
                compareText = t('dashboard.compared-to-yesterday');
                break;
            case 'Yesterday':
                compareText = t('dashboard.compared-to-yesterday-yesterday');
                break;
            case 'Last 7 days':
                compareText = t('dashboard.compared-to-last-7day');
                break;
            case 'Last 30 days':
                compareText = t('dashboard.compared-to-last-30day');
                break;
            case 'Last Month':
                compareText = t('dashboard.compared-to-last-month');
                break;
            case 'Last Quater':
                compareText = t('dashboard.compared-to-last-quater');
                break;
            case 'Last Year':
                compareText = t('dashboard.compared-to-last-year');
                break;                        
            default: break;
        }

            if(this.state.bank_transfer_data.length !== 0 )
            {
                bank_count = Object.values(this.state.bank_transfer_data.result).map((item, i) => {
                    return (
                        item.bank_transfer_count
                    );
                    });
                
                bank_time_diff = Object.values(this.state.bank_transfer_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });
            }
        

            if(this.state.fpay_data.length !== 0 )
            {
                fpay_count = Object.values(this.state.fpay_data.result).map((item, i) => {
                    return (
                        item.fpay_count
                    );
                    });
                
                fpay_time_diff = Object.values(this.state.fpay_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });
            }


            if(this.state.withdraw_data.length !== 0 )
            {
                withdraw_count = Object.values(this.state.withdraw_data.result).map((item, i) => {
                    return (
                        item.withdraw_count
                    );
                    });

                withdraw_time_diff = Object.values(this.state.withdraw_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });

            }
        //Calculated the last week total deposit
        let total_last_week_deposit_amount;

        total_last_week_deposit_amount = this.state.total_deposit_amount - this.state.total_last_week_deposit_amount;

        //Calculated the last week total withdrawal
        let total_last_week_withdrawal_amount;
        
        total_last_week_withdrawal_amount = this.state.total_withdrawal_amount2 - this.state.total_last_week_withdrawal_amount;

        //Calculated the last week total earnings
        let total_last_week_earning;

        // total_last_week_earning = this.state.total_deposit_amount - this.state.total_withdrawal_amount - this.state.total_last_week_deposit_amount - this.state.total_last_week_withdrawal_amount;
        total_last_week_earning = this.state.ddata.compared_total_earnings;
        
        //Calculated the last week total members
        let total_last_week_member;

        total_last_week_member = this.state.total_member - this.state.total_last_week_member;

        const option = {
            title: {
                display: true,
            },
            scales: {
                xAxes: [{
                  ticks: {
                     maxTicksLimit: 24,
                     maxRotation:0,
                   },
                   scaleLabel: {
                    display: true,
                    labelString: t('dashboard.time'),
                    
                  }
                }],
                yAxes: [
                    {
                        type:'linear',
                        min: 0,
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('dashboard.process-time-minute'),
                            
                          }
                    },
                ],
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function(tooltipItems, data ) { 
                       var multistringText = [];
                           multistringText.push(t('dashboard.bank-transfer-count')+'  :  ' + bank_count[tooltipItems.index]);
                           multistringText.push(t('dashboard.bank-transfer-process-time')+'  :  ' + bank_time_diff[tooltipItems.index] + " " + t('dashboard.minute'));
                           multistringText.push(t('dashboard.quick-pay-count')+'  :  ' + fpay_count[tooltipItems.index] );
                           multistringText.push(t('dashboard.quick-pay-process-time')+'  :  ' + fpay_time_diff[tooltipItems.index] + " " + t('dashboard.minute'));
                        return multistringText;
                    }
                }
            },
            maintainAspectRatio: false
        };

        const option2 = {
            title: {
                display: true,
                //  text: 'Line Chart'
            },
            scales: {
                xAxes: [{
                    ticks: {
                       maxTicksLimit: 24,
                       maxRotation:0,
                     },
                     scaleLabel: {
                        display: true,
                        labelString: t('dashboard.time'),
                        
                      }
                  }],
                yAxes: [
                    {
                        type:'linear',
                        min: 0,
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('dashboard.process-time-minute'),
                            
                          }

                    },
                ],
            },

            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function(tooltipItems, data ) { 
                       var multistringText = [];
                           multistringText.push(t('dashboard.bank-transfer-count')+'  :  ' + withdraw_count[tooltipItems.index]);
                           multistringText.push(t('dashboard.bank-transfer-process-time')+'  :  ' + withdraw_time_diff[tooltipItems.index] + " " + t('dashboard.minute') );
                        return multistringText;
                    }
                }
            },
        };

        const option3 = {
            layout:{
                padding:{
                    left:-10
                }
            },
            legend:{
                position:"left",
                labels:{
                    boxWidth:12,
                    fontSize:10,
                },
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                responsive:true,
                
                callbacks: {
                    title: function(tooltipItem, data) {
                      return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function(tooltipItem, data) {
                      return data['datasets'][0]['data'][tooltipItem['index']];
                    },
                    afterLabel: function(tooltipItem, data) {
                      var dataset = data['datasets'][0];
                      var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]]['total']) * 100)
                      return '(' + percent + '%)';
                    }
                  },
            },
        };

        const dataLine1 = (canvas) => {
            const ctx = canvas.getContext("2d");
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 250); //red
            gradient1.addColorStop(0, "rgba(255,204,203,1)");
            gradient1.addColorStop(1, "rgba(255,255,255,1)");
            const gradient2 = ctx.createLinearGradient(0, 0, 0, 250); //blue
            gradient2.addColorStop(0, "rgba(74, 137, 252,1)");
            gradient2.addColorStop(1, "rgba(255,255,255,1)");
            
            
            
            
            let process_time = [];
            if(this.state.bank_transfer_data.length !== 0 )
            {
                process_time = Object.values(this.state.bank_transfer_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });
            }

            let fpay_process_time = [];
            if(this.state.fpay_data.length !== 0 )
            {
                fpay_process_time = Object.values(this.state.fpay_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });
            }

            return {
                labels: this.state.chart1Lables,
                datasets: [
                    {
                        label: t('dashboard.bank-transfer'),
                        fill: true,
                        lineTension: 0.1,
                        backgroundColor: gradient2,
                        borderColor: "rgb(74, 137, 252)",
                        borderCapStyle: "butt",
                        borderWidth: 1,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(74, 137, 252)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 5,
                        pointHoverRadius: 6,
                        pointHoverBackgroundColor: "rgb(74, 137, 252)",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        data: process_time
                    },
                    {
                        label: t('dashboard.quick-pay'),
                        fill: true,
                        lineTension: 0.1,
                        backgroundColor: gradient1,
                        borderColor: "rgb(205, 130, 158)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderWidth: 1,
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(205, 130, 158)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 5,
                        pointHoverRadius: 6,
                        pointHoverBackgroundColor: "rgb(205, 130, 158)",
                        pointHoverBorderColor: "rgba(220, 220, 220, 1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        data: fpay_process_time,
                    },
                ],
            };
        };
        const dataLine2 = (canvas) => {
            const ctx = canvas.getContext("2d");
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 250); //red
            gradient1.addColorStop(0, "rgba(255,204,203,1)");
            gradient1.addColorStop(1, "rgba(255,255,255,1)");
            const gradient2 = ctx.createLinearGradient(0, 0, 0, 250); //blue
            gradient2.addColorStop(0, "rgba(74, 137, 252,1)");
            gradient2.addColorStop(1, "rgba(255,255,255,1)");

            let withdraw_process_time = [];
            if(this.state.withdraw_data.length !== 0 )
            {
                withdraw_process_time = Object.values(this.state.withdraw_data.result).map((item, i) => {
                    return (
                        item.timediff
                    );
                    });
            }
            
            return {
                labels: this.state.chart1Lables,
                datasets: [
                    {
                        label: t('dashboard.bank-transfer'),
                        fill: true,
                        lineTension: 0.1,
                        backgroundColor: gradient2,
                        borderColor: "rgb(74, 137, 252)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderWidth: 1,
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(74, 137, 252)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 5,
                        pointHoverRadius: 6,
                        pointHoverBackgroundColor: "rgb(74, 137, 252)",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        data: withdraw_process_time,
                    },
                    
                ],
            };
        };


        const dataDoughnut1 = (canvas) => {
            const ctx = canvas.getContext("2d");
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 250); //green
            gradient1.addColorStop(0, "rgba(72,214,168,1)");
            gradient1.addColorStop(1, "rgba(2,150,102,1)");
            const gradient2 = ctx.createLinearGradient(0, 0, 0, 250); //blue
            gradient2.addColorStop(0, "rgba(13, 178, 222,1)");
            gradient2.addColorStop(1, "rgba(0, 91, 234,1)");
  
            return  {
                labels: [t('dashboard.non-deposit'), t('dashboard.with-deposit')],
                datasets: [
                    {
                        data: [this.state.ddata.member_non_deposit,this.state.ddata.member_deposit],
                        backgroundColor: [
                            gradient1, gradient2
                        ],
                    },
                ],
                
            }
        }

        // const dataDoughnut2 = (canvas) => {
        //    const ctx = canvas.getContext("2d");
        //     const gradient1 = ctx.createLinearGradient(0, 0, 0, 250); //green
        //     gradient1.addColorStop(0, "rgba(72,214,168,1)");
        //     gradient1.addColorStop(1, "rgba(2,150,102,1)");
        //     const gradient2 = ctx.createLinearGradient(0, 0, 0, 250); //blue
        //     gradient2.addColorStop(0, "rgba(13, 178, 222,1)");
        //     gradient2.addColorStop(1, "rgba(0, 91, 234,1)");
            
        //     return  {
        //         labels: [t('dashboard.inactive-member'), t('dashboard.active-member')],
        //         datasets: [
        //             {
        //                 data: [this.state.ddata.all_member,this.state.ddata.active_member],
        //                 backgroundColor: [
        //                     gradient1, gradient2
        //                 ],
        //             },
        //         ],
        //     }
        // }


        const dataDoughnut3 = (canvas) => {
            const ctx = canvas.getContext("2d");
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 250); //org
            gradient1.addColorStop(0, "rgba(239,166,95,1)");
            gradient1.addColorStop(1, "rgba(247,106,45,1)");
            const gradient2 = ctx.createLinearGradient(0, 0, 0, 250); //red
            gradient2.addColorStop(0, "rgba(247, 119, 140,1)");
            gradient2.addColorStop(1, "rgba(249, 58, 90,1)");
            return  {
                labels: [t('dashboard.non-deposit'), t('dashboard.with-deposit')],
                datasets: [
                    {
                        data: [this.state.ddata.total_non_depo,this.state.ddata.total_depo],
                        backgroundColor: [
                            gradient1, gradient2
                        ],
                    },
                ],
            }
        }


        let display = null;
        let dropdownDisplay = null;

        if (this.state.permission.includes("dashboard_access")) {
            dropdownDisplay = (
                <>
                    <div className="main-dashboard-header-right align-items-center">
                        <div>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="btn-white">
                                    <MdToday size={22} />
                                    {this.state.date}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Today")}>{t("dashboard.today")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Yesterday")}>{t("dashboard.yesterday")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Last 7 days")}>{t("dashboard.last-7-days")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Last 30 days")}>{t("dashboard.last-30-days")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Last Month")}>{t("dashboard.last-month")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Last Quater")}>{t("dashboard.last-quater")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSelectedDate("Last Year")}>{t("dashboard.last-year")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </>
            );
            display = (
                <>
                <div className="row row-sm">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                        <div className="card overflow-hidden sales-card bg-primary-gradient position-relative" style={{height:'88%'}}>
                            {/* <i className="fas fa-wallet position-absolute text-white op-4" style={{fontSize:85, top:'0px', right:'4px'}}/> */}
                            <img className="position-absolute op-6" src={depositIcon} style={{width:90, top:'-10px', right:'-10px'}} alt=""/>
                            
                            <div className="pl-3 pt-3 pr-3 pb-2 pt-0">
                                {this.state.loading ? ( <Skeleton count={3} /> ) : (
                                    <div>
                                        <h6 className="mb-3 tx-12 text-white">{t("dashboard.total-deposit")}</h6>
                                        <h4 className="tx-24 font-weight-500 mb-1 text-white">{currency[this.props.currentUser.country_id]}{" "}{this.state.total_deposit_amount? new Intl.NumberFormat("en-US", num_format).format(this.state.total_deposit_amount): "0.00"}</h4>
                                        <div className="d-flex">
                                            <div className="mr-4">
                                                <p className="mb-0 tx-12 text-white op-7">{compareText}</p>
                                                <span className="mt-auto ml-auto">
                                                    {total_last_week_deposit_amount > 0 ? (
                                                        <i className="fas fa-arrow-circle-up text-white"></i>
                                                    ) : <i className="fas fa-arrow-circle-down text-white"></i>
                                                    }
                                                    <span className="text-white tx-12"> {total_last_week_deposit_amount? new Intl.NumberFormat("en-US", num_format).format(total_last_week_deposit_amount): 0}</span>
                                                </span>
                                            </div>
                                            <div>
                                                <p className="mb-0 tx-12 text-white op-7">{t("dashboard.count")}:</p>
                                                <span className="tx-12 text-white"> {this.state.total_deposit_time ? this.state.total_deposit_time : 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="card overflow-hidden sales-card bg-success-gradient position-relative" style={{height:'88%'}}>
                                {/* <i className="fas fa-credit-card position-absolute text-white op-4" style={{fontSize:80, top:'0px', right:'4px'}}/> */}
                                <img className="position-absolute op-6" src={withdrawIcon} style={{width:80, top:'0px', right:'-3px'}} alt=""/>
                                <div className="pl-3 pt-3 pr-3 pb-2 pt-0">
                                    {this.state.loading ? ( <Skeleton count={3} /> ) : (
                                        <div>
                                            <h6 className="mb-3 tx-12 text-white">{t("dashboard.total-withdraw")}</h6>
                                            <h4 className="tx-24 font-weight-500 mb-1 text-white">{currency[this.props.currentUser.country_id]}{" "}{this.state.total_withdrawal_amount2? new Intl.NumberFormat("en-US", num_format).format(this.state.total_withdrawal_amount2): "0.00"}</h4>
                                            <div className="d-flex">
                                                <div className="mr-4">
                                                    <p className="mb-0 tx-12 text-white op-7">{compareText}</p>
                                                    <span className="mt-auto ml-auto">
                                                        {total_last_week_withdrawal_amount > 0 ? (
                                                            <i className="fas fa-arrow-circle-up text-white"></i>
                                                        ) : <i className="fas fa-arrow-circle-down text-white"></i>
                                                        }
                                                        <span className="text-white tx-12"> {total_last_week_withdrawal_amount ?new Intl.NumberFormat("en-US", num_format).format(total_last_week_withdrawal_amount): 0}</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <p className="mb-0 tx-12 text-white op-7">{t("dashboard.count")}:</p>
                                                    <span className="tx-12 text-white"> {this.state.total_withdrawal_time ? this.state.total_withdrawal_time : 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="card overflow-hidden sales-card bg-warning-gradient" style={{height:'88%'}}>
                                <i className="fas fa-dollar-sign position-absolute text-white op-4" style={{fontSize:80, top:'-5px', right:'0px'}}/>
                                <div className="pl-3 pt-3 pr-3 pb-2 pt-0">
                                    {this.state.loading ? ( <Skeleton count={3} /> ) : (
                                        <div>
                                            <h6 className="mb-3 tx-12 text-white">{t("dashboard.total-earning")}</h6>
                                            <h4 className="tx-24 font-weight-500 mb-1 text-white">{currency[this.props.currentUser.country_id]}    {new Intl.NumberFormat("en-US", num_format).format(this.state.total_earning_amount?this.state.total_earning_amount:0 )}</h4>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="mb-0 tx-12 text-white op-7">{compareText}</p>
                                                    <span className="mt-auto ml-auto">
                                                        {total_last_week_earning > 0 ? (
                                                            <i className="fas fa-arrow-circle-up text-white"></i>
                                                        ) : <i className="fas fa-arrow-circle-down text-white"></i>
                                                        }
                                                        <span className="text-white tx-12"> {total_last_week_earning?new Intl.NumberFormat("en-US", num_format).format(total_last_week_earning):0}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="card overflow-hidden sales-card bg-danger-gradient" style={{height:'88%'}}>
                                    <i className="fas fa-user-friends position-absolute text-white op-4" style={{fontSize:85, top:'0px', right:'-31px'}}/>
                                    <div className="pl-3 pt-3 pr-3 pb-2 pt-0">
                                        {this.state.loading ? ( <Skeleton count={3} /> ) : (
                                            <div>
                                                <h6 className="mb-3 tx-12 text-white">{t("dashboard.total-new-member")}</h6>
                                                <h4 className="tx-24 font-weight-500 mb-1 text-white">{this.state.total_member ? this.state.total_member : 0}</h4>
                                                <div className="d-flex">
                                                    <div>
                                                        <p className="mb-0 tx-12 text-white op-7">
                                                            {compareText}
                                                        </p>
                                                        <span className="mt-auto ml-auto">
                                                            {total_last_week_member > 0 ? (
                                                                <i className="fas fa-arrow-circle-up text-white"></i>
                                                            ) : <i className="fas fa-arrow-circle-down text-white"></i>
                                                            }
                                                            <span className="text-white tx-12"> {total_last_week_member? total_last_week_member :0}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row row-sm">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="card mg-b-20 dashboard-card-small">
                                    <div className="card-body">
                                        <div className="main-content-label mg-b-5"> {t('dashboard.new-member')}</div>
                                        {/* <p className="mg-b-20">Member of deposite Percentage</p> */}
                                        <MDBContainer>
                                            <Doughnut data={dataDoughnut1} options={option3} />
                                        </MDBContainer>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="card mg-b-20 dashboard-card-small">
                                    <div className="card-body">
                                        <div className="main-content-label mg-b-5"> {t('dashboard.active-member')} </div>
                                        <MDBContainer>
                                            <Doughnut data={dataDoughnut2} options={option3} />
                                        </MDBContainer>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12"> */}
                                <div className="card mg-b-20 dashboard-card-small">
                                    <div className="card-body">
                                        <div className="main-content-label mg-b-5"> {t('dashboard.total-member')} </div>
                                        {/* <p className="mg-b-20">Member of deposite Percentage</p> */}
                                        <MDBContainer>
                                            <Doughnut data={dataDoughnut3} options={option3} />
                                        </MDBContainer>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="card mg-b-20 dashboard-card-small">
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                        <div>
                                            <div className="dashboard-card">
                                                <div className="card-icon-container bg-danger-gradient">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                                                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
                                                    </svg>
                                                </div>
                                                <div className="card-content">
                                                    <h4> {t('dashboard.total-bank-balance')} </h4>
                                                    <p> {currency[this.props.currentUser.country_id]}{" "}{this.state.ddata.total_bank_balance? new Intl.NumberFormat("en-US", num_format).format(this.state.ddata.total_bank_balance): "0.00"}</p>
                                                </div>
                                            </div>

                                            <div className="dashboard-card">
                                                <div className="card-icon-container bg-primary-gradient">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
                                                        <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
                                                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
                                                    </svg>  
                                                </div>
                                                <div className="card-content">
                                                    <h4> {t('dashboard.payment-gateway-balance')} </h4>
                                                    <p> {currency[this.props.currentUser.country_id]}{" "}{this.state.ddata.total_payment_gateway_balance? new Intl.NumberFormat("en-US", num_format).format(this.state.ddata.total_payment_gateway_balance): "0.00"}</p>
                                                </div>
                                            </div>
                                            <div className="dashboard-card">
                                                <div className="card-icon-container bg-warning-gradient">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                                <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"/>
                                                </svg>
                                                </div>
                                                <div className="card-content">
                                                    <h4> {t('dashboard.member-wallet-balance')} </h4>
                                                    <p> {currency[this.props.currentUser.country_id]}{" "}{this.state.ddata.member_wallet_balance? new Intl.NumberFormat("en-US", num_format).format(this.state.ddata.member_wallet_balance): "0.00"}</p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card mg-b-20">
                                    <div className="card-body">
                                        <div className="main-content-label mg-b-5"> {t('dashboard.deposit-average-time')} </div>
                                        {/* <p className="mg-b-20">Deposit Average Time</p> */}
                                        <MDBContainer>
                                            <Line data={dataLine1} options={option} height={350}/>
                                        </MDBContainer>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card mg-b-20">
                                    <div className="card-body">
                                        <div className="main-content-label mg-b-5"> {t('dashboard.withdraw-average-time')}</div>
                                        {/* <p className="mg-b-20">Withdraw Average Time.</p> */}
                                        <MDBContainer>
                                            <Line data={dataLine2} options={option2} height={100}/>
                                        </MDBContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                </>

            );
        } else {
            dropdownDisplay = null;
            display = null;
        }
        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="left-content">
                        <div>
                            <h2 className="main-content-title tx-24 mg-b-1 mg-b-lg-1">{t("dashboard.title")}</h2>
                        </div>
                    </div>

                    {dropdownDisplay}
                </div>
                
                {this.state.loading ? ( <Skeleton count={4} /> ) : display }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { dashboard } = state;
    //Get the state from reducers
    return {
        totalRecord: dashboard.data.data,
        bankTransferRecord: dashboard.get_bank_transfer_record,
        FpayRecord: dashboard.get_fpay_record,
        withdrawRecord: dashboard.get_withdraw_record,
        DData: dashboard.get_ddata,
        currentPermission: state.currentUser.data.permission,
        getDashboardRecord: dashboard.get_dashboard_data,
        currentUser: state.currentUser.data
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getTotalRecords,
    getBankTransferRecords,
    getFpayRecords,
    getWithdrawRecords,
    getDData,
    getCurrentPermission,
    getDashboardData
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Dashboard));
