import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import UpdownArrow from "../../assets/images/updown-arrow.png";

class GameBetSlipListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state =
        {

        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
            (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    totalFormatter(column, colIndex, { text }) {
        return (
            <div>
                <p className="sub-total">{column["footer"][0]}</p>
                <p>( {column["footer"][1]} )</p>
            </div>
        );
    }

    totalBetAmountFormatter(column, colIndex, { text }) {
        return (
            <div>
                <p className="sub-total">{column["footer"][0]}</p>
                <p>( {column["footer"][1]} )</p>
            </div>
        );
    }

    totalWinLoseAmountFormatter(column, colIndex, { text }) {
        return (
            <div>
                <p className="sub-total">{column["footer"][0]}</p>
                <p>( {column["footer"][1]} )</p>
            </div>
        );
    }
    /* ============ function [END] ============ */

    render() {
        const { t, i18n } = this.props;
        const num_format = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        };

        let rsListing = [];
        // let betAmtSubTotal = 0;
        let payoutSubTotal = 0;
        let turnoverSubTotal = 0;

        let gameName = "";

        /* ======================== datatables data assign [START] ======================== */
        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                // if (i18n.exists(`games:${val.vendor_slug}.${val.table_id}`)) {
                //     gameName = t(`games:${val.vendor_slug}.${val.table_id}`);
                // } else {
                //     gameName = val.table_id;
                // }
                if(val.gameTitle === null && i18n.exists(`games:${val.vendor_slug}.${val.table_id}`)){
                    gameName = t(`games:${val.vendor_slug}.${val.table_id}`);
                }
                else if(val.gameTitle === null && !i18n.exists(`games:${val.vendor_slug}.${val.table_id}`)){
                    gameName = val.table_id;
                }
                else{
                    gameName = val.gameTitle;
                }

                const category = val.game_slug.substring(val.game_slug.indexOf('_') + 1);

                payoutSubTotal = payoutSubTotal - (parseFloat(val.bet_amount) - parseFloat(val.payout_amount));
                turnoverSubTotal = turnoverSubTotal + parseFloat(val.turnover);

                let refId = (['dg','pp','ea','sa'].indexOf(val.vendor_slug) > -1) ? <b><a href="/#" onClick={(event) => this.props.detailsHandler(event, val.vendor_slug, val.ref_id)}>{val.ref_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i></a></b> : val.ref_id ;

                return {
                    no: idx,
                    date: val.update_date,
                    username: val.username,
                    vendor: t(`vendor.${val.vendor_slug}`),
                    category: t(`game-type.${category}`),
                    game_name: gameName,
                    ref_id: refId,
                    bet_no: val.bet_no,
                    bet_amount: new Intl.NumberFormat("en-US", num_format).format(val.bet_amount),
                    payout: <span className={`${parseFloat(val.payout_amount) > 0 ? `text-success` : parseFloat(val.payout_amount) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(val.payout_amount)))}</span>,
                    win_lose: <span className={`${parseFloat(val.win_lose_amount) > 0 ? `text-success` : parseFloat(val.win_lose_amount) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(val.win_lose_amount)))}</span>,
                    turnover: new Intl.NumberFormat("en-US", num_format).format(val.turnover),
                    result: val.result ? t(`game-result.${val.result}`) : '-',
                };
            });
        }

        const columns = [
            {
                text: t("game-betslip.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "120px", textAlign: "center" },
                footer: [t("global.sub-total"), t("global.total")],
                footerFormatter: this.totalFormatter, footerClasses: 'table-footer',
                // sort: true,
                // onSort: this.props.onSort,
            },
            {
                text: t("game-betslip.date"),
                dataField: "date",
                // sort: true,
                // onSort: this.props.onSort,
                footer: ""
            },
            {
                text: t("game-betslip.member"),
                dataField: "username",
                footer: ""
            },
            {
                text: t("game-betslip.vendor"),
                dataField: "vendor",
                footer: ""
            },
            {
                text: t("game-betslip.category"),
                dataField: "category",
                footer: ""
            },
            {
                text: t("game-betslip.game-name"),
                dataField: "game_name",
                footer: ""
            },
            {
                text: t("game-betslip.ref-id"),
                dataField: "ref_id",
                footer: ""
            },
            {
                text: t("game-betslip.bet-no"),
                dataField: "bet_no",
                footer: ""
            },
            {
                text: t("game-betslip.bet-amount"),
                dataField: "bet_amount",
                headerStyle: { width: "120px" },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(this.props.subTotalBetAmt).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.props.totalBetAmt).toString()
                ],
                footerFormatter: this.totalBetAmountFormatter,
                footerClasses: 'table-footer',
            },
            {
                text: t("game-betslip.payout"),
                dataField: "payout",
                headerStyle: { width: "120px" },
                footer: [
                    <span className={`${parseFloat(this.props.subTotalPayoutAmt) > 0 ? `text-success` : parseFloat(this.props.subTotalPayoutAmt) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(this.props.subTotalPayoutAmt)))}</span>,
                    <span className={`${parseFloat(this.props.totalPayoutAmt) > 0 ? `text-success` : parseFloat(this.props.totalPayoutAmt) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(this.props.totalPayoutAmt)))}</span>
                ],
                footerFormatter: this.totalWinLoseAmountFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("game-betslip.win-lose"),
                dataField: "win_lose",
                headerStyle: { width: "120px" },
                footer: [
                    <span className={`${parseFloat(this.props.subTotalWinLoseAmt) > 0 ? `text-success` : parseFloat(this.props.subTotalWinLoseAmt) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(this.props.subTotalWinLoseAmt)))}</span>,
                    <span className={`${parseFloat(this.props.totalWinLoseAmt) > 0 ? `text-success` : parseFloat(this.props.totalWinLoseAmt) < 0 ? `text-danger` : `badge badge-pill badge-primary`}`}>{new Intl.NumberFormat("en-US", num_format).format(Math.abs(parseFloat(this.props.totalWinLoseAmt)))}</span>
                ],
                footerFormatter: this.totalWinLoseAmountFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("game-betslip.valid-turnover"),
                dataField: "turnover",
                headerStyle: { width: "120px" },
                footer: [
                    new Intl.NumberFormat("en-US", num_format).format(this.props.subTotalTurnoverAmt).toString(),
                    new Intl.NumberFormat("en-US", num_format).format(this.props.totalTurnoverAmt).toString()
                ],
                footerFormatter: this.totalBetAmountFormatter,
                footerClasses: 'table-footer'
            },
            {
                text: t("game-betslip.result"),
                dataField: "result",
                footer: ""
            },
        ];
        /* ======================== datatables data assign [END] ======================== */
        
        let url = null;
        let scrolling = 'yes';
        let height = '300px';
        switch (this.props.vendor) {
            case 'dg':
                url = `${process.env.REACT_APP_API_DEVELOPMENT_URL}/betslip/details.html`;
                height = '300px';
                scrolling = 'yes';
            break;
            case 'pp':
                url = this.props.details.url;
                height = '500px';
                scrolling = 'yes';
            break;
            case 'ea':
                url = `${process.env.REACT_APP_API_DEVELOPMENT_URL}/betslip/details.html`;
                height = '250px';
                scrolling = 'yes';
            break;
            case 'sa':
                url = this.props.details.url;
                height = '650px';
                scrolling = 'yes';
            break;
            default:
                url = null;
            break;
        }
        // console.log(url, height, scrolling);
        return (
            <Auxiliary>
                <Modal 
                    isOpen={this.props.openDetailsModal}
                    className="modal-dialog modal-xl modal-dialog-centered modal-xl modal-dialog-scrollable modal-xl" 
                    ariaHideApp={false}
                    customStyles={
                        {content: {
                            top: '60%',
                            height: 'auto',
                            width: 'auto'
                        }}
                    } 
                    onRequestClose={() => this.props.closeModalHandler()}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="text-center">{t("game-betslip.betslip-details")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.closeModalHandler()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-4 p-4">
                            {this.props.subLoading ? <Skeleton count={2} /> :
                            <iframe id="myFrame" title="myFrame" src={url} width="100%" scrolling={scrolling} frameBorder={0} style={{overflow: "auto", height: height,}}/>
                            }
                        </div>
                    </div>
                </Modal>

                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("game-betslip.title")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading
                                    ? (<Skeleton count={2} />)
                                    : (
                                        <div
                                            className={`form-horizontal filter-function row filter-wrapper-1`}>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">
                                                        {t("game-betslip.date")}
                                                    </label>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-calendar-check" />
                                                        </span>
                                                        <DateTimePicker
                                                            id="date"
                                                            name="date"
                                                            className="form-control filter-input"
                                                            range={true}
                                                            timePicker={true}
                                                            // format="YYYY/MM/DD"
                                                            value={
                                                                this.props.filter.date
                                                            }
                                                            onChange={
                                                                this.props.onInputChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="vendor" className="font-weight-bolder">
                                                        {t("game-betslip.vendor")}
                                                    </label>
                                                    <div className="select-input">
                                                        <select
                                                            className="form-control filter-input"
                                                            id="vendor"
                                                            name="vendor"
                                                            onChange={this.props.onInputChange}
                                                            value={this.props.filter.vendor}>
                                                            <option value=""></option>
                                                            {this.props.ddVendor}
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">
                                                        {t("game-betslip.ref-id")}
                                                    </label>
                                                    <StringInput
                                                        id="ref_id"
                                                        name="ref_id"
                                                        value={this.props.filter.ref_id}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">
                                                        {t("game-betslip.bet-no")}
                                                    </label>
                                                    <StringInput
                                                        id="bet_no"
                                                        name="bet_no"
                                                        value={this.props.filter.bet_no}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label className="font-weight-bolder">
                                                        {t("game-betslip.member")}
                                                    </label>
                                                    <StringInput
                                                        id="username"
                                                        name="username"
                                                        value={this.props.filter.username}
                                                        onChange={this.props.onInputChange}
                                                        className="filter-input"
                                                        onSearchPress={(evt) =>
                                                            evt.key === "Enter" &&
                                                            this.props.refreshHandler()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="vendor" className="font-weight-bolder">
                                                        {t("game-betslip.category")}
                                                    </label>
                                                    <div className="select-input">
                                                        <select
                                                            className="form-control filter-input"
                                                            id="category"
                                                            name="category"
                                                            onChange={this.props.onInputChange}
                                                            value={this.props.filter.category}>
                                                            <option value=""></option>
                                                            {this.props.ddGameType}
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <div className="form-group">
                                                    <label htmlFor="vendor" className="font-weight-bolder">
                                                        {t("game-betslip.group-ref-id")}
                                                    </label>
                                                    <div className="select-input">
                                                        <select
                                                            className="form-control filter-input"
                                                            id="group_ref_id"
                                                            name="group_ref_id"
                                                            onChange={this.props.onInputChange}
                                                            value={this.props.filter.group_ref_id}>
                                                            <option value="2">{t("game-betslip._no")}</option>
                                                            <option value="1">{t("game-betslip._yes")}</option>
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col mt-auto">
                                                <div className="d-flex">
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-blue"
                                                        idValue="filter"
                                                        buttonName={t("global.filter")}
                                                        clicked={() =>
                                                            this.props.refreshHandler()
                                                        }
                                                    />
                                                    &nbsp;
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-secondary"
                                                        idValue="reset"
                                                        buttonName={t("global.reset")}
                                                        clicked={() =>
                                                            this.props.clearHandler()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("game-betslip.listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(GameBetSlipListing);
