import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Swal from 'sweetalert2';
import axios from "../../common/config/axios";
import moment from "moment";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import AgentTransactionListing from './AgentTransactionListing';
import AgentTransactionDetails from './AgentTransactionDetails';

import * as actions from "../../store/ducks/agentTransaction/actions";
import MemberShow from '../Members/MemberShow/MemberShow';

const initialInputState =
{
    /* form input value */
    order_id: '',
    username: '',
    status: '',
    transaction_type: '',
    transaction_channel: '',
    min_debit: '',
    max_debit: '',
    min_credit: '',
    max_credit: '',
    transaction_date: '',
    start_date: '',
    end_date: '',
    exportTransactionType: '',
};

class AgentTransaction extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                status: [],
                transactionType: [],
                transactionChannel: [],
            },

            /* transction details parameter */
            detailsParam: {},

            /* skeleton loading */
            loading: true,

            /* redirect member profile */
            redirectParam: {}
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
        this.props.funcGetDropDown(); // get filter drop down
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        // console.log(nextProps.rs.data.result.row);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                const { t } = this.props;
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) =>
                {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t("global.error"),
                    html: msg,
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        activePage: 1,
                        data: [],
                        totalRecords: 0,
                        loading: false,
                    });
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        status: nextProps.dropDown.data.status,
                        transactionType: nextProps.dropDown.data.transaction_type,
                        transactionChannel: nextProps.dropDown.data.transaction_channel,
                    }
                });
            }
        }
        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        if (event.target.id === 'transaction_type') {
            var index = event.nativeEvent.target.selectedIndex;
            this.setState({
                exportTransactionType: event.nativeEvent.target[index].text 
            });
        }
               
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='transaction_date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    // searchHandler = () =>
    // {
    //     this.setState({
    //         activePage: 1,
    //         loading: true,
    //         data: [],
    //     }, ()=>
    //     {
    //         const listingArr = this.getFilterArray();
    //         this.props.funcGetList(listingArr);
    //     });
    // }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            // limit: 10,
            searchValue: '',
            // order: [{'id':'id', 'desc':'asc'}],
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    detailsHandler = (e, id) =>
    {
        // console.log('detailsHandler (transaction): ', id);
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }

    exportExcelHandler = () => 
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => 
            {
                Swal.showLoading();
            }
        });

        const data = {};
        data.start_date = this.state.filter.start_date ? moment(this.state.filter.start_date).format("YYYY-MM-DD HH:mm:ss") : '';
        data.end_date = this.state.filter.end_date ? moment(this.state.filter.end_date).format("YYYY-MM-DD HH:mm:ss") : '';
        data.username = this.state.filter.username;
        data.order_id = this.state.filter.order_id;
        data.status = this.state.filter.status;
        data.transaction_channel = this.state.filter.transaction_channel;
        data.transaction_type = this.state.filter.transaction_type;
        data.min_debit = this.state.filter.min_debit;
        data.max_debit = this.state.filter.max_debit;
        data.min_credit = this.state.filter.min_credit;
        data.max_credit = this.state.filter.max_credit;
        // data.order = JSON.stringify(this.state.order);

        axios
            .get("agent_transaction/export_excel", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - Agent Transaction - " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
                Swal.close();
            })
            .catch((err) => {
                console.log("Excel - Transaction Error", err);
            });
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect to member profile
    redirectHandler = (e, id, name)=>{
        e.preventDefault();
        this.setState({
            pageShow: "redirect",
            redirectParam: {
                id: id,
                name: name
            }
        });
    }

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <AgentTransactionListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}
                        exportExcelHandler={() => this.exportExcelHandler()}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        ddStatus={this.state.dropDown.status}
                        ddTransactionType={this.state.dropDown.transactionType}
                        ddTransactionChannel={this.state.dropDown.transactionChannel}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        // currentUser={this.props.currentUser}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }

                {this.state.pageShow==='details' &&
                    <AgentTransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                }

                {this.state.pageShow==='redirect' &&
                    <MemberShow 
                        memberShowId={this.state.redirectParam.id}
                        memberShowUsername={this.state.redirectParam.name}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.props.currentUser.permission ?? []}
                        memberShowCount={0}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.agentTransaction.rs,
        dropDown: state.agentTransaction.dropDown,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentTransaction));
