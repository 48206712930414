import * as type from './types';
import axios from '../../../common/config/axios';

export const postPromotedVideo = (data) => dispatch => {
    axios.post('promoted-video/create', data)
        .then( (response) => {
            dispatch ({
                type: type.POST_PROMOTED_VIDEO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
  };

  export const getPromotedVideo = (data) => dispatch => {
    axios.get('promoted-video/list', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_PROMOTED_VIDEO,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const showPromotedVideo = (data) => dispatch => {
    axios.get('promoted-video/show', {
        params: {
            "video_id": data['video_id']
        }
    }).then((response) => {
        dispatch({
            type: type.SHOW_PROMOTED_VIDEO,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updatePromotedVideo = (data) => dispatch => {
    axios.post('promoted-video/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_PROMOTED_VIDEO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updatePromotedVideoStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('promoted-video/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_PROMOTED_VIDEO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};