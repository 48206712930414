import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
// import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle} from "react-icons/ai";

// import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class CoinSettingListing extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { t } = this.props;
        // let rsListing = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2, };

        const columns = [
            {
                text: t("global.no"),
                dataField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("coin-setting.slug"),
                dataField: "slug",
                // sort: true,
                // onSort: this.props.onSort,
            },
            {
                text: t("coin-setting.min-bet"),
                dataField: "min_bet",
                formatter: (cell, row, rowIndex) => {
                    return new Intl.NumberFormat("en-US", num_format).format(row.min_bet);
                },
            },
            {
                text: t("coin-setting.coin"),
                dataField: "coin_reward",
            },
            // {
            //     text: t("coin-setting.turnover-multiply"),
            //     dataField: "turnover_multiply",
            // },
            {
                text: t("global.action"),
                dataField: "action",
                formatter: (cell, row, rowIndex) => {
                    return permission.includes("coin_setting_update") ? (

                        <a
                            href="/#"
                            onClick={(event) =>
                                this.props.detailsHandler(event, "edit", row.id)
                            }
                            className="btn btn-sm btn-white"
                            data-toggle="tooltip"
                            title="edit"
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </a>

                    ) : (
                        ""
                    );
                },
                headerStyle: { width: "115px" },
            },
        ];

        if (!permission.includes("coin_setting_update")) {
            columns.splice(5, 1);
        }

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("side-bar.coin_setting")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="d-flex align-items-end">
                                <div className="card-header pb-0">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mg-b-0">
                                            {t("coin-setting.listing")}
                                        </h4>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="btn-group">
                                            {this.props.data.length === 0 && permission.includes("coin_setting_create") && !this.props.loading && (
                                                <>
                                                    <AiFillPlusCircle />
                                                    <Button
                                                        typeButton="button"
                                                        classValue="btn btn-underline"
                                                        idValue="create"
                                                        buttonName={t(
                                                            "role.coin_setting_create"
                                                        )}
                                                        clicked={() =>
                                                            this.props.changePageHandler(
                                                                "create"
                                                            )
                                                        }
                                                    />
                                                </>

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="id"
                                        data={this.props.data}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        // searchChangeHandler={
                                        //     this.props.onInputChange
                                        // }
                                        // searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    // searchPressSubmitHandler={
                                    //     this.props.searchKeyPressHandler
                                    // }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(CoinSettingListing);