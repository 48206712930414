import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";

import { getMemberHistoryReport } from "../../../store/ducks/report/actions";
// import { getFilterData } from "../../../store/ducks/member/actions";

import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import MemberLoginHistoryReportListing from "./MemberLoginHistoryReportListing";

const initialInputState = {
    filter_date: "",
};

class MemberLoginHistoryReport extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",

        data: [],
        totalDesktopUser: 0,
        totalMobileViewUser: 0,
        totalMobileAppsUser: 0,

        currentMemberId: "",
        loading: true,

        filter: initialInputState,

        dropDown: {
            member_group_option: [],
        },

        permission: [],
    };

    fetchData() {
        const reportMemberLoginHistorysListingData = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        // reportMemberDepositStatusListingData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        // reportMemberDepositStatusListingData.limit = this.state.limit;
        // reportMemberLoginHistorysListingData.order = JSON.stringify(this.state.sorted);
        reportMemberLoginHistorysListingData.country_id = this.props.currentCountryId;
        reportMemberLoginHistorysListingData.search = this.state.searchValue;

        Object.keys(this.state.filter).forEach((val) => {
            reportMemberLoginHistorysListingData[val] = this.state.filter[val];
        });

        this.props.getMemberHistoryReport(reportMemberLoginHistorysListingData);
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_"));
            this.setState({ permission: reportPermission });
        }

        if (nextProps.reportData !== this.props.reportData) {
            if (nextProps.reportData.data.responseCode === 200) {
                this.setState({
                    totalRecords: nextProps.reportData.data.data.total_records,
                    totalDesktopUser: nextProps.reportData.data.data.desktop_user,
                    totalMobileViewUser: nextProps.reportData.data.data.mobile_view_user,
                    totalMobileAppsUser: nextProps.reportData.data.data.mobile_application_user,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: nextProps.reportData.data.message[0],
                    icon: nextProps.reportData.data.msgType,
                    confirmButtonText: `OK`,
                });
            }
        }

        return true;
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                dataLoading: true,
                data: [],
            },
            () => {
                if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getMemberHistoryReport(listingArr);
                }
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                totalRecords: 0,
                loading: false,
                dataLoading: false,
                filter: newFilter,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberHistoryReport(listingArr);
            }
        );
    };

    render() {
        // const { t } = this.props;
        let ddMemberGroup = [];
        const columns = [];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MemberLoginHistoryReportListing
                        exportExcelHandler={() => this.exportExcelHandler()}
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        data={this.state.data}
                        totalDesktopUser={this.state.totalDesktopUser}
                        totalMobileViewUser={this.state.totalMobileViewUser}
                        totalMobileAppsUser={this.state.totalMobileAppsUser}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        ddMemberGroup={ddMemberGroup}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_member_history_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberHistoryReport: (filter) => dispatch(getMemberHistoryReport(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberLoginHistoryReport));
