import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/ducks/member/actions';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import MemberAddressListing from './MemberAddressListing';
import { filterErrorHandler } from '../../../hoc/Shared/utility';
import MemberBillingAddressListing from './MemberBillingAddressListing';

class MemberAddress extends Component{
    constructor(props){
        super(props);
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'member_address');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            this.state = {
                currentPage: "member_address",
                pageShow: "member_address_listing",
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: '',
                totalRecords: 0,
                data: [],
                loading: true,

                // Member Billing Address
                baLoading: true,
                baActivePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                baLimit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                baOrder: '',
                baTotalRecords: 0,
                baData: []
            }
            this.props.setMemberShowLocalState(this.state);
        }
        else{
            this.state = this.props.memberAddressLocalState;
        }
    }

    componentDidMount(){
        const initLoading = this.props.memberShowInitialLoading.filter(item => item.id === 'member_address');
        const initLoadingStatus = initLoading[0].loading;
        if(initLoadingStatus){
            const listingArr = this.getFilterArray();
            this.props.getMemberAddress(listingArr);
            const listingBillingAddressArr = this.getBillingAddressFilterArray();
            this.props.getMemberBillingAddress(listingBillingAddressArr);

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        }
        else{
            if(this.props.memberAddressData !== undefined){
                if(this.props.memberAddressData.data && this.props.memberAddressData.data.data
                    && this.props.memberAddressData.data.data.length > 0
                    && Number(this.props.memberShowId) !== Number(this.props.memberAddressData.data.data.memberId)){
                    const listingArr = this.getFilterArray();
                    this.props.getMemberAddress(listingArr);
                }
                else{
                    this.setState({
                        loading: false
                    });
                }
            }
            else{
                const listingArr = this.getFilterArray();
                this.props.getMemberAddress(listingArr);
            }

            if(this.props.memberBillingAddressData !== undefined){
                if(this.props.memberBillingAddressData.data && this.props.memberBillingAddressData.data.data
                    && this.props.memberBillingAddressData.data.data.length > 0
                    && Number(this.props.memberShowId) !== Number(this.props.memberBillingAddressData.data.data.memberId)){
                    const listingBillingAddressArr = this.getBillingAddressFilterArray();
                    this.props.getMemberBillingAddress(listingBillingAddressArr);
                }
                else{
                    this.setState({
                        baLoading: false
                    });
                }
            }
            else{
                const listingBillingAddressArr = this.getBillingAddressFilterArray();
                this.props.getMemberBillingAddress(listingBillingAddressArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log(nextProps);
        if(nextProps.memberShowId !== undefined && nextProps.memberAddressData !== undefined){
            if(nextProps.memberAddressData !== this.props.memberAddressData){
                if(nextProps.memberAddressData.data.responseCode === 200 && nextProps.memberAddressData.data.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        loading: false,
                        data: nextProps.memberAddressData.data.data.result.row,
                        totalRecords: nextProps.memberAddressData.data.data.result.pagination.total_record
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        data: [],
                        totalRecords: 0
                    }, () => {
                        filterErrorHandler(nextProps.memberAddressData.data.message);
                    });
                }
            }
        }

        if(nextProps.memberShowId !== undefined && nextProps.memberBillingAddressData !== undefined){
            if(nextProps.memberBillingAddressData !== this.props.memberBillingAddressData){
                if(nextProps.memberBillingAddressData.data.responseCode === 200 && nextProps.memberBillingAddressData.data.msgType === 'success'){
                    Swal.close();
                    this.setState({
                        baLoading: false,
                        baData: nextProps.memberBillingAddressData.data.data.result.row,
                        baTotalRecords: nextProps.memberBillingAddressData.data.data.result.pagination.total_record
                    });
                }
                else {
                    this.setState({
                        baLoading: false,
                        baData: [],
                        baTotalRecords: 0
                    }, () => {
                        filterErrorHandler(nextProps.memberBillingAddressData.data.message);
                    });
                }
            }
        }

        return true;
    }

    componentWillUnmount(){
        this.props.setMemberShowLocalState(this.state);
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        listingArr.member_id = this.props.memberShowId;
        if(this.state.totalRecords!==0){
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }
        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        // listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;
        return listingArr;
    }

    getBillingAddressFilterArray = () => {
        const listingBillingAddressArr = {};
        let availablePage = this.state.baActivePage;
        listingBillingAddressArr.member_id = this.props.memberShowId;
        if(this.state.baTotalRecords!==0){
            availablePage = parseInt(this.state.baTotalRecords / this.state.baLimit + 1);
            (this.state.baActivePage >= availablePage) && this.setState({ baActivePage: availablePage});
        }
        listingBillingAddressArr.page = (this.state.baActivePage >= availablePage) ? availablePage : this.state.baActivePage;
        listingBillingAddressArr.limit = this.state.baLimit;
        listingBillingAddressArr.order = JSON.stringify(this.state.baOrder);
        listingBillingAddressArr.country_id = this.props.currentCountryId;
        return listingBillingAddressArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMemberAddress(listingArr);
        });
    }

    billingAddressOnSort = (field, order) => {        
        console.log(field);
        
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            baOrder: orderArry,
            baLoading: true,
            baData: []
        }, () => {
            const listingBillingAddressArr = this.getBillingAddressFilterArray();
            this.props.getMemberBillingAddress(listingBillingAddressArr);
        });
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberAddress(listingArr);
            }
        );
    }

    datatablesBillingAddressChangeHandler = (val, act) => {        
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                baLoading: true,
                baData: [],
            },
            () => {
                const listingBillingAddressArr = this.getBillingAddressFilterArray();
                this.props.getMemberBillingAddress(listingBillingAddressArr);
            }
        );
    }

    render(){
        return(
            <Auxiliary>
                {this.state.pageShow==='member_address_listing' &&
                    <>
                        <MemberAddressListing 
                            onSort={(field,order)=>{this.onSort(field,order)}}
                            data={this.state.data}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            datatablesChangeHandler={(val,act)=>{this.datatablesChangeHandler(val,act)}}
                            loading={this.state.loading}
                        />

                        {/* {this.state.baData.length > 0 && ( */}
                            <MemberBillingAddressListing 
                                onSort={(field,order)=>{this.billingAddressOnSort(field,order)}}
                                data={this.state.baData}
                                activePage={this.state.baActivePage}
                                limit={this.state.baLimit}
                                totalRecords={this.state.baTotalRecords}
                                datatablesChangeHandler={(val,act)=>{this.datatablesBillingAddressChangeHandler(val,act)}}
                                loading={this.state.baLoading}
                            />
                        {/* )}            */}
                    </>                   
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member, currentUser } = state;
    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberAddressLocalState: member.member_address_local_state,
        memberAddressData: member.member_address_data,
        memberBillingAddressData: member.member_billing_address_data,
        currentCountryId: currentUser.data.country_id,
        currentUser: currentUser.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => {dispatch(actions.setMemberShowLocalState(data))},
        setMemberShowInitialLoading: (data) => {dispatch(actions.setMemberShowInitialLoading(data))},
        getMemberAddress: (data) => {dispatch(actions.getMemberAddress(data))},
        getMemberBillingAddress: (data) => {dispatch(actions.getMemberBillingAddress(data))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(MemberAddress));