import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberDepositCrypto, getMemberWithdrawalCrypto, setMemberShowLocalState, setMemberShowInitialLoading  } from "../../../store/ducks/member/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import MemberCryptoDepositListing from "./MemberCryptoDepositListing";
import MemberCryptoWithdrawalListing from "./MemberCryptoWithdrawalListing";
import { filterErrorHandler } from "../../../hoc/Shared/utility";

class MemberCryptoAddress extends Component {
    constructor(props) {
        super(props);

        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "crypto_address");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            this.state = {
                currentPage: "crypto_address",
                pageShow: "member_crypto_listing",

                // Deposit
                loading: true,
                reload: false,
                activePage: 1,
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                show_id: null,
                totalRecords: 0,
                data: [],

                // Withdrawal
                wLoading: true,
                wReload: false,
                wActivePage: 1,
                wLimit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                wOrder: "",
                wShow_id: null,
                wTotalRecords: 0,
                wData: [],
            };
            this.props.setMemberShowLocalState(this.state);
        } else {
            this.state = this.props.memberCryptoAddressLocalState;
        }

    }

    componentDidMount() {
        // console.log("[MemberCryptoAddress] componentDidMount()");
        const initLoad = this.props.memberShowInitialLoading.filter(item => item.id === "crypto_address");
        const initialLoading = initLoad[0].loading;

        if (initialLoading) {
            const listingDepositArr = this.getDepositFilterArray();
            this.props.getMemberDepositCrypto(listingDepositArr);
            const listingWithdrawalArr = this.getWithdrawalFilterArray();
            this.props.getMemberWithdrawalCrypto(listingWithdrawalArr);

            this.props.setMemberShowInitialLoading(this.state.currentPage);
        } else {
            if (this.props.memberDepositCryptoData !== undefined) {
                if (this.props.memberDepositCryptoData.data && this.props.memberDepositCryptoData.data.data 
                    && this.props.memberDepositCryptoData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberDepositCryptoData.data.data.memberId)) {
                    const listingDepositArr = this.getDepositFilterArray();
                    this.props.getMemberDepositCrypto(listingDepositArr);
                } else {
                    this.setState({
                        loading: false,
                        reload: true,
                    });
                }
            } else {
                const listingDepositArr = this.getDepositFilterArray();
                this.props.getMemberDepositCrypto(listingDepositArr);
            }

            if (this.props.memberWithdrawalCryptoData !== undefined) {
                if (this.props.memberWithdrawalCryptoData.data && this.props.memberWithdrawalCryptoData.data.data 
                    && this.props.memberWithdrawalCryptoData.data.data.length > 0 
                    && Number(this.props.memberShowId) !== Number(this.props.memberWithdrawalCryptoData.data.data.memberId)) {
                    const listingWithdrawalArr = this.getWithdrawalFilterArray();
                    this.props.getMemberWithdrawalCrypto(listingWithdrawalArr);
                } else {
                    this.setState({
                        wLoading: false,
                        wReload: true,
                    });
                }
            } else {
                const listingWithdrawalArr = this.getWithdrawalFilterArray();
                this.props.getMemberWithdrawalCrypto(listingWithdrawalArr);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[MemberCryptoAddress] shouldComponentUpdate()", "\nnextProps: ",nextProps, "\nnextState: ",nextState);

        if (nextProps.memberShowId !== undefined && nextProps.memberDepositCryptoData !== undefined) {
            if(nextProps.memberDepositCryptoData !== this.props.memberDepositCryptoData) {
                if (nextProps.memberDepositCryptoData.data.responseCode === 200 && nextProps.memberDepositCryptoData.data.msgType === "success") {
                    Swal.close();
                    this.setState({
                        show_id: nextProps.memberShowId,
                        data: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.row,
                        totalRecords: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_record,
                        totalPages: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_page,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        show_id: null,
                        data: [],
                        totalRecords: 0,
                        totalPages: 1,
                        loading: false
                    }, () => {
                        filterErrorHandler(nextProps.memberDepositCryptoData.data.message)
                    });
                }
            }
        }

        if (nextProps.memberShowId !== undefined && nextProps.memberWithdrawalCryptoData !== undefined) {
            if(nextProps.memberWithdrawalCryptoData !== this.props.memberWithdrawalCryptoData) {
                if (nextProps.memberWithdrawalCryptoData.data.responseCode === 200 && nextProps.memberWithdrawalCryptoData.data.msgType === "success") {
                    Swal.close();
                    this.setState({
                        wShow_id: nextProps.memberShowId,
                        wData: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.row,
                        wTotalRecords: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_record,
                        wTotalPages: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_page,
                        wLoading: false,
                    });
                }
                else {
                    this.setState({
                        wShow_id: null,
                        wData: [],
                        wTotalRecords: 0,
                        wTotalPages: 1,
                        wLoading: false
                    }, () => {
                        filterErrorHandler(nextProps.memberWithdrawalCryptoData.data.message)
                    });
                }
            }
        }

        // if (
        //     !nextState.reload &&
        //     nextProps.memberShowId !== undefined &&
        //     nextProps.memberDepositCryptoData !== undefined &&
        //     nextProps.memberDepositCryptoData !== this.props.memberDepositCryptoData
        // ) {
        //     if (nextProps.memberDepositCryptoData.status === 200 && nextProps.memberDepositCryptoData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.row,
        //             totalRecords: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_record,
        //             totalPages: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberDepositCryptoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";

        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // } else if (nextState.reload && nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.row !== nextState.data && nextProps.memberDepositCryptoData !== undefined) {
        //     if (nextProps.memberDepositCryptoData.status === 200 && nextProps.memberDepositCryptoData.data.msgType === "success") {
        //         this.setState({
        //             show_id: nextProps.memberShowId,
        //             data: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.row,
        //             totalRecords: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_record,
        //             totalPages: nextProps.memberDepositCryptoData.data.data.cryptoDepositAddress.pagination.total_page,
        //             loading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberDepositCryptoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";
        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // }


        // if (
        //     !nextState.wReload &&
        //     nextProps.memberShowId !== undefined &&
        //     nextProps.memberWithdrawalCryptoData !== undefined &&
        //     nextProps.memberWithdrawalCryptoData !== this.props.memberWithdrawalCryptoData
        // ) {
        //     if (nextProps.memberWithdrawalCryptoData.status === 200 && nextProps.memberWithdrawalCryptoData.data.msgType === "success") {
        //         this.setState({
        //             wShow_id: nextProps.memberShowId,
        //             wData: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.row,
        //             wTotalRecords: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_record,
        //             wTotalPages: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_page,
        //             wLoading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberWithdrawalCryptoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";

        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // } else if (nextState.wReload && nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.row !== nextState.wData && nextProps.memberWithdrawalCryptoData !== undefined) {
        //     if (nextProps.memberWithdrawalCryptoData.status === 200 && nextProps.memberWithdrawalCryptoData.data.msgType === "success") {
        //         this.setState({
        //             wShow_id: nextProps.memberShowId,
        //             wData: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.row,
        //             wTotalRecords: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_record,
        //             wTotalPages: nextProps.memberWithdrawalCryptoData.data.data.cryptoWithdrawalAddress.pagination.total_page,
        //             wLoading: false,
        //         });
        //         Swal.close();
        //     } else {
        //         let msg = null;
        //         msg = "<ul>";
        //         nextProps.memberWithdrawalCryptoData.message.forEach((val) => {
        //             msg += "<li>" + val + "</li>";
        //         });
        //         msg += "</ul>";
        //         Swal.fire({
        //             icon: "error",
        //             title: "error",
        //             html: msg,
        //             showConfirmButton: true,
        //         });
        //     }
        // }
        return true;
    }

    componentWillUnmount() {
        // console.log("[MemberCryptoAddress] componentWillUnmount()", "\nstate: ", this.state);
        this.props.setMemberShowLocalState(this.state);
    }

    getDepositFilterArray = () => {
        const listingDepositArr = {};
        let availablePage = this.state.activePage;

        listingDepositArr.member_id = this.props.memberShowId;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingDepositArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingDepositArr.limit = this.state.limit;
        listingDepositArr.order = JSON.stringify(this.state.order);
        listingDepositArr.country_id = this.props.currentCountryId;
        return listingDepositArr;
    };

    getWithdrawalFilterArray = () => {
        const listingWithdrawalArr = {};
        let availablePage = this.state.activePage;

        listingWithdrawalArr.member_id = this.props.memberShowId;
        if (this.state.wTotalRecords !== 0) {
            availablePage = parseInt(this.state.wTotalRecords / this.state.wLimit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingWithdrawalArr.page = this.state.wActivePage >= availablePage ? availablePage : this.state.wActivePage;
        listingWithdrawalArr.limit = this.state.wLimit;
        listingWithdrawalArr.order = JSON.stringify(this.state.wOrder);
        listingWithdrawalArr.country_id = this.props.currentCountryId;
        return listingWithdrawalArr;
    };
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    // Deposit
    datatablesDepositChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: (act==='searchValue') ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingDepositArr = this.getDepositFilterArray();
                this.props.getMemberDepositCrypto(listingDepositArr);
            }
        );
    };

    depositOnSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
            // errMsg: [],
            // touched: false,
        }, () => {
            const listingDepositArr = this.getDepositFilterArray();
            this.props.getMemberDepositCrypto(listingDepositArr);
        });
    };

    // Withdrawal
    datatablesWithdrawalChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: (act==='searchValue') ? val.target.value : val,
                wLoading: true,
                wData: [],
            },
            () => {
                const listingWithdrawalArr = this.getWithdrawalFilterArray();
                this.props.getMemberWithdrawalCrypto(listingWithdrawalArr);
            }
        );
    };

    withdrawalOnSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({
            wOrder: orderArry,
            wLoading: true,
            wData: [],
            // errMsg: [],
            // touched: false,
        }, () => {
            const listingWithdrawalArr = this.getWithdrawalFilterArray();
            this.props.getMemberWithdrawalCrypto(listingWithdrawalArr);
        });
    };

    render() {
        // const { t } = this.props;
        return (
            <Auxiliary>
                {this.state.pageShow === "member_crypto_listing" && (
                    <>
                        <MemberCryptoDepositListing
                            datatablesChangeHandler={(val, act) => this.datatablesDepositChangeHandler(val, act)}
                            onSort={(field, order) => this.depositOnSort(field, order)}
                            data={this.state.data}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            loading={this.state.loading}
                        />

                        <MemberCryptoWithdrawalListing
                            datatablesChangeHandler={(val, act) => this.datatablesWithdrawalChangeHandler(val, act)}
                            onSort={(field, order) => this.withdrawalOnSort(field, order)}
                            data={this.state.wData}
                            activePage={this.state.wActivePage}
                            limit={this.state.wLimit}
                            totalRecords={this.state.wTotalRecords}
                            loading={this.state.wLoading}
                        />
                    </>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberShowInitialLoading: member.member_show_initial_loading,
        memberCryptoAddressLocalState: member.member_crypto_address_local_state,

        memberDepositCryptoData: member.member_deposit_crypto_data,
        memberWithdrawalCryptoData: member.member_withdrawal_crypto_data,
        currentCountryId: state.currentUser.data.country_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMemberShowLocalState: (data) => dispatch(setMemberShowLocalState(data)),
        setMemberShowInitialLoading: (data) => dispatch(setMemberShowInitialLoading(data)),

        getMemberDepositCrypto: (data) => dispatch(getMemberDepositCrypto(data)),
        getMemberWithdrawalCrypto: (data) => dispatch(getMemberWithdrawalCrypto(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberCryptoAddress));
