import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

// import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import { formValidation, filterErrorHandler } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import MemberShow from "../Members/MemberShow";

import * as actions from "../../store/ducks/withdrawal/actions";
import { getBankBalc } from "../../store/ducks/bankBalanceAdjustment/actions";

class WithdrawalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "details",
            fees: 0.0,
            remark: null,
            bank_acc: "",
            avBalc: 0,
            adjBalc: 0,
            details: {},
            id: null,
            loading: true,
            errMsg: [],
            touched: false,
            transaction_channel: null,
            crypto: null,
            rejectReason: [],
            bankAccount: [],
            act: "",
            transaction_hash: "",

            activePage: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalRecords: 0,
            memberData: [],
            // conflictData: [],
            memberShowId: null,
            wd_channel: 'bank-transfer',
            wd_payment_type: '',
            wdPaymentTypeDropdown: [],
            pendingThirdPartyVerification: false,
            wdCryptoDetails: []
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.funcGetDetails(listingArr);
        formValidation("withdrawal_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate :', nextProps.details);
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === "success") {
                let newDetails = nextProps.details.data.result[0];
                if (
                    nextProps.details.data.result[0] &&
                    newDetails.update_by !== null &&
                    newDetails.update_by !== this.props.currentUser.username &&
                    (newDetails.status === 0 || newDetails.status === 3)
                ) {
                    Swal.fire({
                        title: t("global.oops"),
                        text: t("global.transaction-occupied"),
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                }
                if (nextProps.details.data.result[0]) {
                    this.setState({
                        fees: newDetails.fees,
                        remark: this.state.remark !== null ? this.state.remark : newDetails.remark,
                        details: nextProps.details.data.result,
                        id: newDetails.id,
                        transaction_channel: newDetails.transaction_channel,
                        crypto: (newDetails.transaction_channel === "crypto") ? 'direpay' : newDetails.slug,
                        loading: false,
                        rejectReason: nextProps.details.data.reject_reason,
                        bankAccount: (this.state.wd_payment_type === "") ? [] : nextProps.details.data.bank_account,

                        memberData: nextProps.details.data.loginInfo.row,
                        totalRecords: nextProps.details.data.loginInfo.pagination.total_record,
                        totalPages: nextProps.details.data.loginInfo.pagination.total_page,
                        // conflictData: nextProps.details.data.collidedMember,
                    });

                    if (newDetails.status === 3 && newDetails.transaction_channel !== 'bank-transfer' && newDetails.reference_code !== '' && newDetails.transaction_channel !== 'crypto') {
                        this.setState({
                            pendingThirdPartyVerification: true
                        });
                    }
                }

                transactionChannels = nextProps.details.data.transaction_channels;
                let wdChannel = "bank-transfer";

                if (nextProps.details.data.result[0]['transaction_channel'] === "crypto") {
                    wdCryptoDetails = nextProps.details.data.withdrawal_crypto_details[0];
                    wdChannel = "crypto";
                }
                paymentTypes = nextProps.details.data.payment_types;

                bankAccoutDropdown = nextProps.details.data.bank_account;
                this.setState({
                    wdPaymentTypeDropdown: paymentTypeFilter.byPaymentChannel(wdChannel)
                });
            }
            else
            {
                this.setState({
                    details: {},
                    loading: false,
                }, () => 
                {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps);
            let success = nextProps.update.responseCode === 200 && nextProps.update.msgType === "success" ? true : false;
            if (nextProps.update.responseCode === 200 && nextProps.update.msgType === "success") {
                Swal.fire({
                    icon: "success",
                    title: t("global.success"),
                    html: t("global.record-updated"),
                    showConfirmButton: true,
                }).then(() => {
                    this.props.refreshHandler();
                    const listingArr = this.getFilterArray();
                    this.state.act === "confirm" ? this.props.changePageHandler("listing") : this.props.funcGetDetails(listingArr);
                });
            } else {
                Swal.close();
            }
            this.setState({
                touched: success ? false : true,
                errMsg: success ? [] : nextProps.update.message,
            });
        }

        if (nextProps.bankBalc !== this.props.bankBalc && nextProps.bankBalc !== undefined) {
            if (nextProps.bankBalc.responseCode === 200) {
                this.setState(
                    {
                        avBalc: nextProps.bankBalc.data.balance,
                        // adjBalc: (Number(nextProps.bankBalc.data.balance)-Number(this.state.details[0].debit)),
                    },
                    () => {
                        this.calculateBalance();
                    }
                );
            }
        }

        return true;
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                memberData: [],
                // conflictData: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.funcGetDetails(listingArr);
            }
        );
    };

    showHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            memberShowId: id,
            pageShow: "show",
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.id = this.props.detailsParam.id;
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    };

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        this.setState(
            {
                [event.target.id]: event.target.value,
            },
            () => {
                let bid = this.state.bank_acc;
                if (event.target.id === "bank_acc") {
                    this.setState({
                        avBalc: 0,
                        adjBalc: 0,
                    });
                    if (event.target.value !== "") {
                        let bank_acc = event.target.value;
                        let transaction_channel = this.state.transaction_channel;
                        let payment_type = this.state.crypto;
                        this.props.funcGetBankBalc(bank_acc, transaction_channel, payment_type);
                    }
                } else {
                    if (bid > 0) {
                        this.calculateBalance();
                    }
                }

                if (event.target.id === 'wd_channel') {
                    this.setState({
                        wdPaymentTypeDropdown: paymentTypeFilter.byPaymentChannel(event.target.value, paymentTypes),
                        bankAccount: [],
                        transaction_channel: '',
                        crypto: '',
                        fees: 0,
                        bank_acc: '',
                        avBalc: 0,
                        adjBalc: 0,
                    });
                }

                if (event.target.id === 'wd_payment_type') {
                
                    let selected = paymentTypeFilter.byPaymentType(event.target.value);
                    
                    if(event.target.value === ""){
                        this.setState({
                            bankAccount: []
                        });
                    }

                    if(event.target.value === "direpay"){
                        this.setState({
                            transaction_hash: ""
                        });
                    }

                    if (selected.channel === 'bank-transfer') {
                        this.setState({
                            bankAccount: bankAccoutDropdown,
                            transaction_channel: selected.channel,
                            crypto: selected.slug,
                            fees: selected.wd_fees,
                            bank_acc: '',
                            avBalc: 0,
                            adjBalc: 0,
                        });
                    }

                    if (selected.channel === 'quick-pay' || selected.channel === 'crypto') {
                        this.setState({
                            bankAccount: [{
                                id: selected.id,
                                swift_code: 'QuickPay',
                                bank_acc_name: selected.title,
                            }],
                            transaction_channel: selected.channel,
                            crypto: selected.slug,
                            fees: toPrecision(this.state.details[0].debit * selected.wd_fees / 100),
                            bank_acc: '',
                            avBalc: 0,
                            adjBalc: 0
                        });
                    }
                }
            }
        );
    };

    calculateBalance = () => {
        // console.log('calculateBalance : '+this.state.avBalc+' | '+this.state.details[0].debit+' | '+this.state.fees);
        var bal = Number(this.state.avBalc) - Number(this.state.details[0].debit) - Number(this.state.fees);
        this.setState({
            adjBalc: bal,
        });
    };

    submitHandler = (e, act) => {
        // console.log('submitHandler :', act);
        e.preventDefault();
        const { t } = this.props;
        if (act === "confirm" && this.state.adjBalc < 0) {
            Swal.fire({
                title: t("validation.insufficient_balance"),
                icon: "error",
                showConfirmButton: true,
            });
        } else {
            let rejectReason = {};
            this.state.rejectReason.forEach((val) => {
                rejectReason[val.id] = val.title;
            });

            Swal.fire({
                title: act === "reject" ? t("validation.reject-reason") : t("validation.confirmation"),
                text: act === "reject" ? "" : t("validation.confirmation_check"),
                input: act === "reject" ? "select" : "",
                inputOptions: rejectReason,
                preConfirm: (value) => {
                    if (rejectReason[value]) {
                        this.setState({
                            remark: rejectReason[value],
                        });
                    }
                },
                icon: "warning",
                confirmButtonText: t("global.save"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        act: act,
                    });
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.id = this.state.id;
                    data.fees = this.state.fees;
                    data.remark = this.state.remark;
                    data.act = act;
                    data.transaction_channel = this.state.transaction_channel;
                    data.crypto = this.state.crypto;
                    data.bank_acc = this.state.bank_acc;
                    data.lang_id = this.props.currentUser.language_id;

                    if (this.state.transaction_channel === "crypto") {
                        if(this.state.wd_payment_type === "direpay"){
                            this.setState({
                                pendingThirdPartyVerification: true
                            })
                        }
                        data.act = this.state.pendingThirdPartyVerification === true && this.state.details[0].status !== 0 ? "verify" : act;
                        data.currency = this.props.currentUser.currency;
                        data.amount = this.state.details[0].debit;
                        data.crypto_amount = wdCryptoDetails['crypto_amount'];
                        data.crypto_name = wdCryptoDetails['crypto'];
                        data.payment_address = wdCryptoDetails['payment_address'];
                        data.transaction_hash = this.state.transaction_hash;
                    }
                    this.props.funcUpdateTran(data);
                }
            });
        }
    };

    copyToClipboardHanlder = (e, str) => {
        const el = document.createElement("textarea");
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    };

    renderTooltip(props) {
        const { t } = this.props;
        return <Tooltip {...props}>{t("global.copied")}!</Tooltip>;
    }

    onTransactionHashHandle = (e) => {
        this.setState({
            transaction_hash: e.target.value
        });
    }

    render() {
        const { t } = this.props;
        let rsDetails = this.state.details[0] ? this.state.details[0] : [];
        let stCls = { 0: "primary", 1: "success", 2: "danger", 3: "secondary", 4: "danger" };
        let btnPrimary = { 0: t("global.acknowledge"), 1: t("global.save"), 2: t("global.save"), 3: this.state.pendingThirdPartyVerification ? t("global.verify") : t("global.confirm"), 4: t("global.save") };
        let actVal = { 0: "acknowledge", 1: "save", 2: "save", 3: "confirm", 4: "save" };
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        // let collidedDataListing = [];

        // const collidedColumns = [
        //     { text: t("global.no"), dataField: "collidedNo", headerStyle: { width: "60px", textAlign: "center" } },
        //     { text: t("member.ip-address"), dataField: "ipAddress" },
        //     { text: t("member.device-type"), dataField: "deviceType" },
        //     { text: t("member.login-time"), dataField: "loginTime" },
        //     { text: t("member.ip-collided-member"), dataField: "collided_member" },
        // ];

        // if (this.state.memberData) {
        //     let memberData = this.state.memberData;
        //     let conflictData = this.state.conflictData;
        //     // console.log(conflictData);

        //     const uniqueIPArray = [...new Map(memberData.map((item) => [item["ipAddress"], item])).values()];

        //     uniqueIPArray.forEach((val, idx) => {
        //         uniqueIPArray[idx]["collidedMember"] = [];
        //         conflictData.forEach((cVal) => {
        //             // if (val.ipAddress !== cVal.collidedIPAddress) {
        //             uniqueIPArray[idx]["collidedMember"].push({ collidedId: cVal.collidedId, collidedUsername: cVal.collidedUsername });
        //             // }
        //         });
        //     });

        //     let uniqueIPArrayLength = uniqueIPArray.length;
        //     collidedDataListing = uniqueIPArray.map((val, idx) => {
        //         let collided_val = val.collidedMember.map((val, idx) => {
        //             return (
        //                 <span key={idx} onClick={(event) => this.showHandler(event, val.collidedId)} className="badge badge-pill badge-primary ml-1 mb-1 badgeBtn">
        //                     {val.collidedUsername}
        //                 </span>
        //             );
        //         });

        //         let rowNo = uniqueIPArrayLength - idx;
        //         return {
        //             collidedNo: rowNo,
        //             ipAddress: val.ipAddress,
        //             deviceType: val.deviceType,
        //             loginTime: val.loginTime,
        //             collided_member: collided_val,
        //         };
        //     });
        // }

        return (
            <Auxiliary>
                {this.state.pageShow === "details" && (
                    <>
                        <div className="breadcrumb-header">
                            <div className="d-flex">
                                <div className="my-auto">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="content-title mb-0 my-auto ml-2">{t("withdrawal.title")}</h4>
                                </div>
                            </div>
                        </div>
                        <div id="transaction_details">
                            <form
                                method="post"
                                id="withdrawal_form"
                                className="form-horizontal"
                                onSubmit={(event) => this.submitHandler(event, actVal[rsDetails.status])}
                                noValidate
                            >
                                <ValidationError formName="withdrawal_form" touched={this.state.touched} message={this.state.errMsg} />
                                <div className="row row-sm">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <div className="card card-primary ">
                                            <div className="card-header pb-0">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="card-title mg-b-0">{t("withdrawal.withdrawal-details")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                {this.state.loading ? (
                                                    <Skeleton count={3} />
                                                ) : (
                                                    <>
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.order-id")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="order_id"
                                                                    id="order_id"
                                                                    value={rsDetails.order_id || ""}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 pl-5">
                                                                <label>{t("transaction.member")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="member_id"
                                                                    id="member_id"
                                                                    value={rsDetails.email || ""}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.transaction-date")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="transaction_date"
                                                                    id="transaction_date"
                                                                    value={rsDetails.create_date || ""}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 pl-5">
                                                                <label>{t("transaction.amount")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="amount"
                                                                    id="amount"
                                                                    value={new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                        rsDetails.debit || 0.0
                                                                    )}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.transaction-channel")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="transaction_channel"
                                                                    id="transaction_channel"
                                                                    value={(rsDetails.transaction_channel) ? (t(`transaction-channel.${rsDetails.transaction_channel}`) || "") : ''}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 pl-5">
                                                                <label>{t("transaction.status")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className={`form-control bg-${stCls[rsDetails.status]} text-white`}
                                                                    type="text"
                                                                    name="status"
                                                                    id="status"
                                                                    value={(rsDetails.status >= 0) ? t(`transaction.status-desc.${rsDetails.status}`) : ''}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        {rsDetails.status === 1 && rsDetails.transaction_channel === "bank-transfer" ? (
                                                            <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                <div className="col-md-2">
                                                                    <label>{t("transaction.fees")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                            rsDetails.fees || 0.0
                                                                        )}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 pl-5">
                                                                    <label>{t("transaction.bank-account-name")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    {/* <input className="form-control" type="text" value={rsDetails.company_bank_acc_name || ""} disabled={true} /> */}
                                                                    <input className="form-control" type="text" value={rsDetails.company_bank_acc_name ? (rsDetails.company_bank_swift_code ? rsDetails.company_bank_swift_code + " - " + rsDetails.company_bank_acc_name : rsDetails.company_bank_acc_name) : ""} disabled={true} />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.country")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="country"
                                                                    id="country"
                                                                    value={(rsDetails.shortcode) ? (t(`global.country_shortcode.${rsDetails.shortcode}`) || "") : ''}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {rsDetails.transaction_channel === "crypto" ? (
                                            <>
                                                <div className="card card-primary ">
                                                    <div className="card-header pb-0">
                                                        <div className="d-flex justify-content-between">
                                                            <h4 className="card-title mg-b-0">{t("transaction.crypto-details")}</h4>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        {this.state.loading ? (
                                                            <Skeleton count={3} />
                                                        ) : (
                                                            <>
                                                                <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.withdrawal-address")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="withdrawal_address"
                                                                            id="withdrawal_address"
                                                                            value={wdCryptoDetails['payment_address'] || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.crypto-type")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="crypto_type"
                                                                            id="crypto_type"
                                                                            value={wdCryptoDetails['crypto'] || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.transaction-hash")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="tx_hash"
                                                                            id="tx_hash"
                                                                            value={wdCryptoDetails['tx_hash'] || this.state.transaction_hash}
                                                                            onChange={this.onTransactionHashHandle}
                                                                            disabled={wdCryptoDetails['tx_hash'] || rsDetails.status === 0 || this.state.wd_payment_type === "direpay"  ? true : false}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.crypto-amount")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="crypto_amount"
                                                                            id="crypto_amount"
                                                                            value={wdCryptoDetails['crypto_amount'] || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="card card-primary ">
                                                    <div className="card-header pb-0">
                                                        <div className="d-flex justify-content-between">
                                                            <h4 className="card-title mg-b-0">{t("transaction.bank-details")}</h4>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        {this.state.loading ? (
                                                            <Skeleton count={3} />
                                                        ) : (
                                                            <>
                                                                <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.bank-name")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="bank_name"
                                                                            id="bank_name"
                                                                            value={rsDetails.bank_name || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2 pl-5">
                                                                        <label>{t("transaction.bank-account-name")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="bank_account_name"
                                                                            id="bank_account_name"
                                                                            value={(rsDetails.companyName ? rsDetails.companyName : rsDetails.member_bank_acc_name) || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.bank-account-no")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="bank_account_no"
                                                                            id="bank_account_no"
                                                                            value={rsDetails.member_bank_acc_number || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2 pl-5">
                                                                        <label>{t("transaction.reference-code")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0 copy-input">
                                                                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={(props) => this.renderTooltip(props)}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16.5"
                                                                                height="16.5"
                                                                                viewBox="0 0 16.5 16.5"
                                                                                onClick={(e) =>
                                                                                    this.copyToClipboardHanlder(e, rsDetails.reference_code ? rsDetails.reference_code : "")
                                                                                }
                                                                            >
                                                                                <g id="Icon_feather-copy" data-name="Icon feather-copy" transform="translate(0.75 0.75)">
                                                                                    <path
                                                                                        id="Path_15371"
                                                                                        data-name="Path 15371"
                                                                                        d="M15,13.5h6.75a1.5,1.5,0,0,1,1.5,1.5v6.75a1.5,1.5,0,0,1-1.5,1.5H15a1.5,1.5,0,0,1-1.5-1.5V15A1.5,1.5,0,0,1,15,13.5Z"
                                                                                        transform="translate(-8.25 -8.25)"
                                                                                        fill="none"
                                                                                        stroke="#878ea6"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        strokeWidth="1.5"
                                                                                    />
                                                                                    <path
                                                                                        id="Path_15372"
                                                                                        data-name="Path 15372"
                                                                                        d="M5.25,12.75H4.5A1.5,1.5,0,0,1,3,11.25V4.5A1.5,1.5,0,0,1,4.5,3h6.75a1.5,1.5,0,0,1,1.5,1.5v.75"
                                                                                        transform="translate(-3 -3)"
                                                                                        fill="none"
                                                                                        stroke="#878ea6"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        strokeWidth="1.5"
                                                                                    />
                                                                                </g>
                                                                            </svg>
                                                                        </OverlayTrigger>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="reference_code"
                                                                            id="reference_code"
                                                                            value={rsDetails.reference_code || ""}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="card card-primary ">
                                            <div className="card-header pb-0">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="card-title mg-b-0">{t("transaction.audit-details")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                {this.state.loading ? (
                                                    <Skeleton count={3} />
                                                ) : (
                                                    <>
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.update-date")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="update_date"
                                                                    id="update_date"
                                                                    value={rsDetails.update_date || ""}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 pl-5">
                                                                <label>{t("transaction.update-by")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="update_by"
                                                                    id="update_by"
                                                                    value={rsDetails.update_by || ""}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {actVal[rsDetails.status] === "confirm" && !this.state.pendingThirdPartyVerification && (
                                            <div className="card card-primary ">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="card-title mg-b-0">{t('payment-gateway.payment-gateway')}</h4>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="card-body">
                                                    {this.state.loading ? (
                                                        <Skeleton count={3} />
                                                    ) : (
                                                        <>
                                                            <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                <div className="col-md-2">
                                                                    <label>{t('transaction.transaction-channel')}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <select
                                                                        className="form-control filter-input"
                                                                        id="wd_channel"
                                                                        name="wd_channel"
                                                                        onChange={this.onInputChange}
                                                                        value={this.state.wd_channel}
                                                                        required
                                                                    >
                                                                        {transactionChannels.length &&
                                                                            transactionChannels.map((val, idx) => {
                                                                                return (
                                                                                    <option key={val.slug} value={val.slug}>
                                                                                        {val.title}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                            )}
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-2 pl-5">
                                                                    <label>{t('payment-gateway.payment-type')}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <select
                                                                        className="form-control filter-input"
                                                                        id="wd_payment_type"
                                                                        name="wd_payment_type"
                                                                        onChange={this.onInputChange}
                                                                        value={this.state.wd_payment_type}
                                                                        required
                                                                    >
                                                                        <option value="">{t('global.please-select')}</option>

                                                                        {this.state.wdPaymentTypeDropdown.length &&
                                                                            this.state.wdPaymentTypeDropdown.map((val, idx) => {
                                                                                return (
                                                                                    <option key={val.id} value={val.slug}>
                                                                                        {val.title}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {rsDetails.status === 1 && rsDetails.transaction_channel === "bank-transfer" ? (
                                                                <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                    <div className="col-md-2">
                                                                        <label>{t("transaction.fees")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                                rsDetails.fees || 0.0
                                                                            )}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2 pl-5">
                                                                        <label>{t("transaction.bank-account-name")}</label>
                                                                    </div>
                                                                    <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                        {/* <input className="form-control" type="text" value={rsDetails.company_bank_acc_name || ""} disabled={true} /> */}
                                                                        <input className="form-control" type="text" value={rsDetails.company_bank_acc_name ? (rsDetails.company_bank_swift_code ? rsDetails.company_bank_swift_code + " - " + rsDetails.company_bank_acc_name : rsDetails.company_bank_acc_name) : ""} disabled={true} />
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {actVal[rsDetails.status] === "confirm" && ['bank-transfer', 'quick-pay', 'crypto'].indexOf(rsDetails.transaction_channel) > -1 && !this.state.pendingThirdPartyVerification && this.state.crypto !== 'direpay' && (
                                            <div className="card card-primary ">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="card-title mg-b-0">{t("bank-balance-adjustment.from-bank-account")}</h4>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="card-body">
                                                    {this.state.loading ? (
                                                        <Skeleton count={3} />
                                                    ) : (
                                                        <>
                                                            <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                <div className="col-md-2">
                                                                    <label>{t("transaction.bank-account-name")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <select
                                                                        className="form-control filter-input"
                                                                        id="bank_acc"
                                                                        name="bank_acc"
                                                                        onChange={this.onInputChange}
                                                                        value={this.state.bank_acc}
                                                                        required
                                                                    >
                                                                        <option value=""></option>
                                                                        {this.state.bankAccount &&
                                                                            this.state.bankAccount.map((val, idx) => {
                                                                                return (
                                                                                    <option key={val.id} value={val.id}>
                                                                                        {val.swift_code} - {val.bank_acc_name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-2 pl-5">
                                                                    <label>{t("transaction.fees")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="fees"
                                                                        id="fees"
                                                                        placeholder="0.00"
                                                                        value={this.state.fees > 0 ? this.state.fees : ""}
                                                                        onChange={this.onInputChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                                <div className="col-md-2">
                                                                    <label>{t("transaction.available-balance")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <h2>
                                                                        {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                            this.state.avBalc
                                                                        )}
                                                                    </h2>
                                                                </div>
                                                                <div className="col-md-2 pl-5">
                                                                    <label>{t("transaction.after-witdrawal-balance")}</label>
                                                                </div>
                                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                    <h2>
                                                                        {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                            this.state.adjBalc
                                                                        )}
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {/* {this.state.conflictData.length > 0 && ( */}
                                        {/*<div className="card card-primary">
                                            <div className="card-header pb-0">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="card-title mg-b-0">{t("member.ip-collided-member")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                <div className="table-responsive table-hover datatable">
                                                    <DataTable
                                                        keyField="collidedNo"
                                                        data={collidedDataListing}
                                                        columns={collidedColumns}
                                                        activePage={this.state.activePage}
                                                        limit={this.state.limit}
                                                        totalItems={this.state.totalRecords}
                                                        paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                                                        sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                                        classValue="dataTable table-bordered text-justify text-center"
                                                        loading={this.state.loading}
                                                        hideSearchBar={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>*/}
                                        {/* )} */}

                                        <div className="card card-primary ">
                                            <div className="card-header pb-0">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="card-title mg-b-0">{t("transaction.others")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                {this.state.loading ? (
                                                    <Skeleton count={3} />
                                                ) : (
                                                    <>
                                                        <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                            <div className="col-md-2">
                                                                <label>{t("transaction.remark")}</label>
                                                            </div>
                                                            <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="remark"
                                                                    id="remark"
                                                                    maxLength={255}
                                                                    rows="3"
                                                                    placeholder={t("transaction.remark")}
                                                                    value={this.state.remark || ""}
                                                                    onChange={this.onInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row row-xs text-center align-items-center mg-b-20">
                                            <div className="col-md-12">
                                                {this.state.loading ? (
                                                    <Skeleton count={3} />
                                                ) : (
                                                    <>
                                                        {permission.includes("withdrawal_edit") && (
                                                            <>
                                                                <Button
                                                                    typeButton="submit"
                                                                    classValue="btn btn-main-primary"
                                                                    idValue="save"
                                                                    buttonName={btnPrimary[rsDetails.status]}
                                                                // clicked={() => this.submitHandler(actVal[rsDetails.status])}
                                                                />
                                                                &nbsp;
                                                                {((rsDetails.status === 0 || rsDetails.status === 3) && !this.state.pendingThirdPartyVerification) && (
                                                                    <>
                                                                        <Button
                                                                            typeButton="button"
                                                                            classValue="btn btn-danger"
                                                                            idValue="reject"
                                                                            buttonName={t("global.reject")}
                                                                            clicked={(event) => this.submitHandler(event, "reject")}
                                                                        />
                                                                        &nbsp;
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}

                {this.state.pageShow === "show" && (
                    <MemberShow memberShowId={this.state.memberShowId} changePageHandler={(page) => this.changePageHandler(page)} returnPage="details" fromDisplay="edit" />
                )}
            </Auxiliary>
        );
    }
}

let transactionChannels = [];
let paymentTypes = [];
let bankAccoutDropdown = [];
let wdCryptoDetails = [];

const paymentTypeFilter = {
    byPaymentChannel: (channel) => {
        let filtered = [];

        paymentTypes.filter(function (list) {
            if (list.channel.indexOf(channel) > -1) {
                filtered.push(list);
            }
            return null;
        });

        return filtered;
    },

    byPaymentType: (type) => {
        let filtered = {};

        paymentTypes.filter(function(list) {
            if(type === ""){
                return null;
            }
            else if (list.slug.indexOf(type) > -1) {
                filtered = list;
            }
            return null;
        });

        return filtered;
    }
};

const toPrecision = function (number, precision = 2) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (precision || -1) + '})?');
    return number.toString().match(re)[0];
};

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        update: state.withdrawal.update,
        details: state.withdrawal.details,
        bankBalc: state.bankBalanceAdjustment.bankBalc,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcUpdateTran: (data) => dispatch(actions.updateTran(data)),
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcGetBankBalc: (id, tc, pt) => dispatch(getBankBalc(id, tc, pt)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(WithdrawalEdit));
