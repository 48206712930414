import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import TotalDepositTurnover from './TotalDepositTurnover';
import MemberLevelTrackerListing from './MemberLevelTrackerListing';

import * as actions from '../../store/ducks/upLevelBonus/actions';

const initialTabState =
{
    /* datatables / pagination */
    activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
    limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
    order: '',
    totalRecords: 0,
    searchValue: '',

    /* api result array */
    data: [],

    /* filter input */
    filterInput:{
        date: moment().format('YYYY-MM-DD'),
        username: '',
        status: '',
        level_from: '',
        level_to: '',
        level_status: '',
        level_up_date: null
    },

    /* skeleton loading */
    loading: true,

    /* validation error */
    errMsg: [],
    touched: false,
};

class UpLevelBonus extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'total_deposit_turnover',

            /* both tab value */
            tab1: initialTabState,
            tab2: initialTabState,

            /* both tab drop down value */
            dropDown: {
                memberGroup: [],
            },
        }
        // console.log('constructor :', this.state);
        this.onInputChange = this.onInputChange.bind(this);
    }

    /* ============ component lifecycle [START] ============ */
    componentDidMount()
    {
        let listingArr = this.getFilterArray('tab1');
        this.props.funcGetList(listingArr);
        this.props.funcGetDropDown();
        listingArr = this.getFilterArray('tab2');
        this.props.funcGetMemberLevelTracker(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        /* ====================== tab1 (total deposit & turnover) [START] ====================== */
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                // console.log(nextProps.rs);
                let newTab = Object.assign({}, this.state.tab1);
                newTab['data'] = nextProps.rs.data.result.row;
                newTab['totalRecords'] = nextProps.rs.data.result.pagination.total_record;
                newTab['loading'] = false;

                this.setState({
                    tab1: newTab
                });
                Swal.close();
            }
            else
            {
                this.filterErrorHandler(nextProps.rs.message);
            }
        }
        /* ====================== tab1 (total deposit & turnover) [END] ====================== */

        /* ====================== tab2 (up level listing) [START] ====================== */
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        memberGroup: nextProps.dropDown.data.memberGroup,
                    }
                });
            }
        }

        if (nextProps.trackerLeveListing !== this.props.trackerLeveListing && nextProps.trackerLeveListing!==undefined)
        {
            if(nextProps.trackerLeveListing.responseCode === 200 && nextProps.trackerLeveListing.msgType === 'success')
            {
                let newTab = Object.assign({}, this.state.tab2);
                newTab['data'] = nextProps.trackerLeveListing.data.result.row;
                newTab['totalRecords'] = nextProps.trackerLeveListing.data.result.pagination.total_record;
                newTab['loading'] = false;
                this.setState({
                    tab2: newTab
                });
                Swal.close();
            }
            else
            {
                this.filterErrorHandler(nextProps.trackerLeveListing.message);
            }
        }
        /* ====================== tab2 (up level listing) [END] ====================== */

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    onInputChange = (event, tab, inputType) =>
    {
        // console.log(event.target.id+' | '+event.target.value+ ' | '+ tab+' | '+inputType);
        let newTab = Object.assign({}, this.state[tab]);
        let newFilter = Object.assign({}, this.state[tab][inputType]);

        newFilter[event.target.id] = event.target.value;
        newTab[inputType] = newFilter;

        this.setState({
            [tab]: newTab
        });
    }

    handleMonthPickerChange = (date) => {
        let tab = 'tab2';
        let newTab = Object.assign({}, this.state[tab]);
        let newFilter = Object.assign({}, this.state[tab]['filterInput']);
        newFilter['level_up_date'] = date;
        newTab['filterInput'] = newFilter;

        this.setState({
            [tab]: newTab,
        });
    };

    changeTabHandler = (e) =>
    {
        // console.log(e.target.id);
        e.preventDefault();
        this.setState({
            pageShow: e.target.id
        });
    }

    searchHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['activePage'] = 1;
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetMemberLevelTracker(listingArr);
        });
    }

    clearHandler = (tab) =>
    {
        this.setState({
            [tab]: initialTabState
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetMemberLevelTracker(listingArr);
        });
    }

    loadingHandler = () =>
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () =>
            {
                Swal.showLoading();
            }
        });
    }

    filterErrorHandler = (message) =>
    {
        const { t } = this.props;
        let msg = null;
        msg = '<ul>';
        message.forEach((val) => {
            msg += '<li>'+val+'</li>';
        });
        msg += '</ul>';

        Swal.fire({
            icon: 'error',
            title: t('global.error'),
            html: msg,
            showConfirmButton: true
        });
    }

    refreshHandler = (tab) =>
    {
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['data'] = [];
        newFilter['loading'] = true;

        this.setState({
            [tab]: newFilter,
            errMsg: [],
            touched: false,
        }, ()=> {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetMemberLevelTracker(listingArr);
        });
    }

    getFilterArray = (tab) =>
    {
        // this.loadingHandler();
        const listingArr = {};

        let availablePage = this.state[tab].activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state[tab].activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        let levelUpDate = null;

        if (this.state[tab].filterInput.level_up_date !== null) {
            levelUpDate = moment(this.state[tab].filterInput.level_up_date).format('YYYY-MM');
        }

        listingArr.page = (this.state[tab].activePage >= availablePage) ? availablePage : this.state[tab].activePage;
        listingArr.limit = this.state[tab].limit;
        listingArr.search = this.state[tab].searchValue;
        listingArr.order = JSON.stringify(this.state[tab].order);
        listingArr.username = this.state[tab].filterInput.username;
        listingArr.status = this.state[tab].filterInput.status;
        listingArr.date = this.state[tab].filterInput.date;
        listingArr.level_from = this.state[tab].filterInput.level_from;
        listingArr.level_to = this.state[tab].filterInput.level_to;
        listingArr.level_status = this.state[tab].filterInput.level_status;
        // listingArr.level_up_date = this.state[tab].filterInput.level_up_date;
        listingArr.level_up_date = levelUpDate
        listingArr.country_id = this.props.currentUser.country_id;
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, tab, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+tab+' | '+act);
        let newFilter = Object.assign({}, this.state[tab]);
        newFilter[act] = (act==='searchValue') ? val.target.value : val;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetMemberLevelTracker(listingArr);
        });
    }

    onSort = (field, order, tab) =>
    {
        // console.log(field+' | '+order+' | '+tab);
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        let newFilter = Object.assign({}, this.state[tab]);
        newFilter['order'] = orderArry;
        newFilter['data'] = [];
        newFilter['loading'] = true;
        this.setState({
            [tab]: newFilter,
            errMsg: [],
            touched: false,
        }, () => {
            const listingArr = this.getFilterArray(tab);
            (tab==='tab1') ? this.props.funcGetList(listingArr) : this.props.funcGetMemberLevelTracker(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    render()
    {
        const { t } = this.props;
        let tabId = this.state.pageShow;

        return (
            <Auxiliary>
                {(this.state.pageShow==='total_deposit_turnover' || this.state.pageShow==='level_tracker_listing') &&
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='panel panel-primary tabs-style-2 mt-3'>
                                    <div className='tab-menu-heading'>
                                        <div className='tabs-menu1'>
                                            <ul className='nav panel-tabs main-nav-line'>
                                                <li><a href='/#' id='total_deposit_turnover' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'total_deposit_turnover') ? 'active' : ''}`}>{t('up-level-bonus.total-deposit-and-turnover')}</a></li>
                                                <li><a href='/#' id='level_tracker_listing' onClick={(event) => this.changeTabHandler(event)} style={{borderTopWidth: 0}} className={`nav-link bg-white ${(tabId === 'level_tracker_listing') ? 'active' : ''}`}>{t('up-level-bonus.member-level-tracker-listing')}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>
                }

                {this.state.pageShow==='total_deposit_turnover' &&
                    <TotalDepositTurnover
                        tabName='tab1'
                        data={this.state.tab1}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        touched={this.state.touched}
                        errMsg={this.state.errMsg}
                        currentUser={this.props.currentUser}
                    />
                }

                {this.state.pageShow==='level_tracker_listing' &&
                    <MemberLevelTrackerListing
                        tabName='tab2'
                        data={this.state.tab2}
                        ddMemberGroup={this.state.dropDown.memberGroup}
                        refreshHandler={(tab) => this.refreshHandler(tab)}
                        clearHandler={(tab) => this.clearHandler(tab)}
                        onInputChange={(event, tab, inputType) => this.onInputChange(event, tab, inputType)}
                        handleMonthPickerChange ={(date) => this.handleMonthPickerChange (date)}
                        datatablesChangeHandler={(val, tab, act) => this.datatablesChangeHandler(val, tab, act)}
                        onSort={(field, order, tab) => this.onSort(field, order, tab)}
                        touched={this.state.touched}
                        errMsg={this.state.errMsg}
                        currentUser={this.props.currentUser}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.upLevelBonus.rs,
        trackerLeveListing: state.upLevelBonus.trackerLeveListing,
        dropDown: state.upLevelBonus.dropDown,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetMemberLevelTracker: (filter) => dispatch(actions.getMemberLevelTracker(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(UpLevelBonus));