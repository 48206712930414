import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showInbox } from "../../store/ducks/inbox/actions";
import Button from "../../components/Button/Button";
import { withNamespaces } from 'react-i18next';
import DomPurify from "../../components/Sanitize/DomPurify";
import { filterErrorHandler } from "../../hoc/Shared/utility";

//Initialize the language array first
class InboxShow extends Component {
    state = {
        title: '',
        content: '',
        category: '',
        member_group: '',
        start_date: '',
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;

        const showInboxData = {};
        showInboxData.id = this.props.show_inbox_id;

        this.props.showInbox(showInboxData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_inbox_data !== this.props.show_inbox_data && nextProps.show_inbox_data !== undefined) {
            if (nextProps.show_inbox_data.data.responseCode === 200 && nextProps.show_inbox_data.data.msgType === 'success') {

                this.setState({
                    loading: false,
                    title: nextProps.show_inbox_data.data.data[0].title,
                    content: nextProps.show_inbox_data.data.data[0].content,
                    category: nextProps.show_inbox_data.data.data[0].category,
                    member_group: nextProps.show_inbox_data.data.data[0].member_group,
                    start_date: nextProps.show_inbox_data.data.data[0].start_date,
                })
            } else {
                this.setState({
                    loading: false
                }, () => 
                {
                    filterErrorHandler(nextProps.show_inbox_data.data.message);
                });
            }
        }


        return true;
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="content-title mb-0 my-auto ml-2">{t("inbox.add-inbox")}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("inbox.show-inbox")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4} />
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <th>{t("inbox.title")}</th>
                                                <td field-key='title'>{this.state.title}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("inbox.content")}</th>
                                                <td><DomPurify text={this.state.content} className="text-center" /></td>
                                                {/* <td field-key='content'><span dangerouslySetInnerHTML={{ __html: this.state.content }} /></td> */}
                                            </tr>

                                            <tr>
                                                <th>{t("inbox.category")}</th>
                                                <td field-key='category'>{t(`inbox-type.${this.state.category}`)}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("inbox.member-group")}</th>
                                                <td field-key='member_group'>{this.state.member_group}</td>
                                            </tr>

                                            <tr>
                                                <th>{t('inbox.date-range')}:</th>
                                                <td field-key='start_date'>{(this.state.start_date) ? `${this.state.start_date}` : '-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const { inbox } = state;

    return {
        show_inbox_data: inbox.show_inbox_data
    }
};

const mapDispatchToProps = {
    showInbox,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(InboxShow));