import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const walletTypeReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_WALLET_TYPE_LIST]: (state = initialState, action) => ({
        ...state,
        wallet_type_data: action.payload
    }),

    [type.CREATE_WALLET_TYPE]: (state, action) => ({
        ...state,
        post_wallet_type_result: action.payload,
    }),

    [type.SHOW_WALLET_TYPE]: (state, action) => ({
        ...state,
        show_wallet_type_data: action.payload,
    }),

    [type.UPDATE_WALLET_TYPE]: (state, action) => ({
        ...state,
        update_wallet_type_result: action.payload,
    }),

    [type.DELETE_WALLET_TYPE]: (state, action) => ({
        ...state,
        delete_wallet_type_result: action.payload,
    }),

    [type.UPDATE_STATUS_WALLET_TYPE]: (state, action) => ({
        ...state,
        update_status_wallet_type_result: action.payload,
    })

});

export default walletTypeReducer;