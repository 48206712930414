import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import Swal from "sweetalert2";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import { showShipment, updateShipment, getDropDown } from "../../store/ducks/shipments/actions";
import Skeleton from "react-loading-skeleton";
import ReactSelect from "react-select";

class ShipmentsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipment_type: "",
            courier: "",
            warehouse: "",
            state: "",
            area: "",
            parcel_type: "",
            price_setting: [{listIndex: Math.random(), firstPrice: "", nextPrice: "", secondPrice: "", nextSecondPrice: ""}],

            error_message: [],
            touched: false,
            loading: true,
        };
        this.handleSettingListChange = this.handleSettingListChange.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.removeRecord = this.removeRecord.bind(this);
    }

    componentDidMount() {
        formValidation("edit_shipment_form");
    
        const showShipmentData = {};
        showShipmentData.shipment_id = this.props.show_shipment_id;
        this.props.showShipment(showShipmentData);
		this.props.getDropDown();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.show_shipment_data !== this.props.show_shipment_data && nextProps.show_shipment_data !== undefined) {
            if (nextProps.show_shipment_data.data.responseCode === 200 && nextProps.show_shipment_data.data.msgType === 'success') {
                let FeeData = nextProps.show_shipment_data.data.data.FeeData;
                let settingValue = JSON.parse(FeeData.data);

                if (settingValue) {
                    let settingList = [];

                    Object.keys(settingValue).map((key) => {
                        settingList.push({listIndex: Math.random(), ...settingValue[key]});
                        return null;
                    });

                    this.setState({
                        loading: false,
                        shipment_type: FeeData.shipment_type,
                        courier: FeeData.courier_id,
                        warehouse: FeeData.warehouse,
                        state: FeeData.state,
                        area: FeeData.area,
                        parcel_type: FeeData.parcel_type,
                        price_setting: settingList
                    });
                } else {
                    this.setState({
                        loading: false,
                        shipment_type: FeeData.shipment_type,
                        courier: FeeData.courier_id,
                        warehouse: FeeData.warehouse,
                        state: FeeData.state,
                        area: FeeData.area,
                        parcel_type: FeeData.parcel_type,
                        price_setting: []
                    })
                }
            } else {
                this.setState({
                    loading: true,
                }, () => 
                {
                    filterErrorHandler(nextProps.show_shipment_data.data.message);
                });
            }         
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
			if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success') {
				this.setState({
					// loading: false,
					// shipment_type_options: this.mapOptions(nextProps.dropDown.data.shipment_transport, 'type'),
					courier_options: this.mapOptions(nextProps.dropDown.data.shipment_courier, 'courier_name'),
					// warehouse_options: this.mapOptions(nextProps.dropDown.data.shipment_warehouse, 'warehouse'),
					// state_options: this.mapOptions(nextProps.dropDown.data.shipment_state, 'state'),
					// parcel_type_options: this.mapOptions(nextProps.dropDown.data.shipment_parcel_type, 'title'),
				});
			} else {
				this.setState({
					loading: true
				}, () => {
					filterErrorHandler(nextProps.dropDown.message);
				})
			}
		}

        if (nextProps.update_shipment_data !== this.props.update_shipment_data && nextProps.update_shipment_data !== undefined) {
            if (nextProps.update_shipment_data.responseCode === 200 && nextProps.update_shipment_data.msgType === 'success') {
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_shipment_data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_shipment_data.message,
                });
                Swal.close();
            } 
        }
        return true;
    };

    mapOptions = (data, labelField) => {
		return data.map(item => ({
			label: this.props.t(`price-list.${item[labelField]}`),
			value: item.id,
		}));
	};

    handleDropdownChange = (name) => (selectedOption) => {
		this.setState({
			[name]: selectedOption.value,
		})
	};

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value,
        });
    };

    updateShipmentHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
    
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
    
                let value_array = [];

                this.state.price_setting.forEach((item, idx) => {
                    let priceData  = {};

                    if (this.state.shipment_type === 'Air Freight') {
                        priceData = {
                            firstPrice: item.firstPrice,
                            nextPrice: item.nextPrice
                        };
                    } else {
                        priceData = {
                            firstPrice: item.firstPrice,
                            nextPrice: item.nextPrice,
                            secondPrice: item.secondPrice,
                            nextSecondPrice: item.nextSecondPrice
                        };
                    }

                    value_array.push(priceData);
                })

                let updateShipmentData = {};
                updateShipmentData.id = this.props.show_shipment_id;
                updateShipmentData.country_id = this.props.currentUser.country_id;
                updateShipmentData.courier = this.state.courier;
                updateShipmentData.data = value_array;
    
                this.props.updateShipment(updateShipmentData);
            }
        });
    };

    addRecord = () => 
    {
        if(this.state.price_setting.length < 20) 
        {
            this.setState((prevState) => ({
                price_setting: [...prevState.price_setting, {listIndex: Math.random(), firstPrice: "", nextPrice: "", secondPrice: "", nextSecondPrice: ""}],
            }));
        }
    };

    removeRecord = (i) => 
    {
        this.setState({
            price_setting: this.state.price_setting.filter((item, idx) => i !== idx)
        })
    }

    handleSettingListChange = (event, key) => 
    {
        let price_setting = [...this.state.price_setting];
        price_setting[key][event.target.id] = event.target.value;
        this.setState({price_setting});
    }
    
    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">
                                {t("price-list.shipments_pricelist")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">
                                    {t("price-list.edit-shipment-price")}
                                </h4>
                            </div>
                            <div className="card-body pt-0">
                                <form
                                    method="post"
                                    id="shipment_form"
                                    className="form-horizontal"
                                    onSubmit={this.updateShipmentHandler}
                                    noValidate
                                >
                                    <div className="col-md-12">
                                        <ValidationError
                                            formName="shipment_form"
                                            touched={this.state.touched}
                                            message={this.state.error_message}
                                        />
        
                                        {this.state.loading === true ? (
                                            <Skeleton count={4} />
                                        ) : (
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="shipment_type">
                                                            {t("price-list.shipment_type")}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="shipment_type"
                                                            id="shipment_type"
                                                            value={t(`price-list.${this.state.shipment_type}`)}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="courier">
                                                            {t("price-list.courier_name")}
                                                        </label>
                                                        <ReactSelect 
                                                            name="courier"
															options={this.state.courier_options}
                                                            onChange={this.handleDropdownChange('courier')}
                                                            // value={t(`price-list.${this.state.courier}`)}
                                                            value={this.state.courier_options && this.state.courier_options.find(option => option.value === this.state.courier)}
                                                        />
                                                        {/* <input
                                                            className="form-control"
                                                            type="text"
                                                            name="courier"
                                                            id="courier"
                                                            value={t(`price-list.${this.state.courier}`)}
                                                            disabled={true}
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="warehouse">
                                                            {t("price-list.warehouse")}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="warehouse"
                                                            id="warehouse"
                                                            value={t(`price-list.${this.state.warehouse}`)}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="state">
                                                            {t("price-list.state")}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="state"
                                                            id="state"
                                                            value={t(`price-list.${this.state.state}`)}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="area">
                                                            {t("price-list.area")}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="area"
                                                            id="area"
                                                            value={this.state.area ? t(`price-list.${this.state.area}`) : '-'}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="parcel_type">
                                                            {t("price-list.parcel_type")}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="parcel_type"
                                                            id="parcel_type"
                                                            value={t(`price-list.${this.state.parcel_type}`)}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 text-right mb-3">
                                                    <button type="button" className="btn btn-info" onClick={(e) => this.addRecord(e)}>
                                                        <i className="fa fa-plus"></i> {t('price-list.add')}
                                                    </button>
                                                </div>
                                                <div className="table-responsive" style={{overflowY: 'auto'}}>
                                                    <table className="table" id="setting_table" style={{minWidth:500}}>
                                                        <thead className="text-center bg-primary">
                                                            <tr>
                                                                <th className="text-white p-2">{t("global.no")}</th>
                                                                {this.state.shipment_type === 'Air Freight' && (
                                                                    <>
                                                                        <th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.next_0_5kg_fee")}</th>
                                                                    </>
                                                                )}
                                                                {this.state.shipment_type === 'Sea Freight' && this.state.state === 'West Malaysia' && (
                                                                    <>
                                                                        <th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.next_1kg_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.first_0_3m³_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.next_0_01m³_fee")}</th>
                                                                    </>
                                                                )}
                                                                {this.state.shipment_type === 'Sea Freight' && (this.state.state === 'Sabah' || this.state.state === 'Sarawak') && (
                                                                    <>
                                                                        <th className="text-white p-2">{t("price-list.first_kg_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.next_1kg_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.first_0_5m³_fee")}</th>
                                                                        <th className="text-white p-2">{t("price-list.next_0_01m³_fee")}</th>
                                                                    </>
                                                                )}
                                                                <th style={{ width: "5%" }}>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.price_setting.map((item, idx) => {
                                                                return (
                                                                    <tr key={idx} className="text-center">
                                                                        <td>{idx + 1 }</td>
                                                                        {this.state.shipment_type === "Air Freight" &&
                                                                            <>
                                                                                <td key={`firstPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='firstPrice'
                                                                                        name='firstPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.first_kg_fee")}
                                                                                        value={item.firstPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`nextPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='nextPrice'
                                                                                        name='nextPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.next_0_5kg_fee")}
                                                                                        value={item.nextPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                            </>
                                                                        }

                                                                        {this.state.shipment_type === 'Sea Freight' && this.state.state === 'West Malaysia' &&
                                                                            <>
                                                                                <td key={`firstPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='firstPrice'
                                                                                        name='firstPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.first_kg_fee")}
                                                                                        value={item.firstPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`nextPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='nextPrice'
                                                                                        name='nextPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.next_1kg_fee")}
                                                                                        value={item.nextPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`secondPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='secondPrice'
                                                                                        name='secondPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.first_0_3m³_fee")}
                                                                                        value={item.secondPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`nextSecondPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='nextSecondPrice'
                                                                                        name='nextSecondPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.next_0_01m³_fee")}
                                                                                        value={item.nextSecondPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                            </>
                                                                        }

                                                                        {this.state.shipment_type === 'Sea Freight' && (this.state.state === 'Sabah' || this.state.state === 'Sarawak') && 
                                                                            <>
                                                                                <td key={`firstPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='firstPrice'
                                                                                        name='firstPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.first_kg_fee")}
                                                                                        value={item.firstPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`nextPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='nextPrice'
                                                                                        name='nextPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.next_1kg_fee")}
                                                                                        value={item.nextPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`secondPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='secondPrice'
                                                                                        name='secondPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.first_0_5m³_fee")}
                                                                                        value={item.secondPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td key={`nextSecondPrice-${idx}`}>
                                                                                    <NumberInput
                                                                                        id='nextSecondPrice'
                                                                                        name='nextSecondPrice'
                                                                                        data-id={idx}
                                                                                        placeholder={t("price-list.next_0_01m³_fee")}
                                                                                        value={item.nextSecondPrice}
                                                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                                                        onChange={(event) => this.handleSettingListChange(event, idx)}
                                                                                        className='form-control'
                                                                                        autoComplete="off"
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                            </>
                                                                        }
                                                                        
                                                                        <td>
                                                                            <i className="fa fa-trash" style={{color:'red', cursor:'pointer'}} onClick={() => this.removeRecord(idx)}></i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-12 form-group mb-0 justify-content-end">
                                                    <Button
                                                        typeButton="submit"
                                                        classValue="btn btn-blue"
                                                        idValue="submit"
                                                        buttonName={t("global.update")}
                                                    />
                                                </div>        
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = (state) => {
    const { shipment } = state;
    return {
		dropDown: shipment.dropDown,
        update_shipment_data: shipment.update_shipment_result,
        show_shipment_data: shipment.show_shipment_data,
        currentUser: state.currentUser.data,
    };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
		getDropDown: () => dispatch(getDropDown()),
        showShipment: (data) => dispatch(showShipment(data)),
        updateShipment: (data) => dispatch(updateShipment(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ShipmentsEdit));
