import React, { Component } from "react";
import { connect } from "react-redux";
import { createMember, storeMember } from "../../store/ducks/member/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import { default as CustomSelect } from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { formValidation, filterErrorHandler } from "../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";
// import { AsyncPaginate } from "react-select-async-paginate";
import Skeleton from "react-loading-skeleton";
import ValidationError from "../../components/ValidationError/ValidationError";
import DatePicker from "react-datepicker";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import * as moment from "moment";

let memberGroupArray = [];
class MemberCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nickname: "",
            name: "",
            email: "",
            dialcode: "",
            contact_no: "",
            id_num: "",
            // gender: "",
            date_of_birth: "",
            password: "",
            confirm_password: "",
            status: "",
            ber_status: "",
            account_type: "",
            // agent: "",
            member_group: "",
            // member_referral: "",
            agent_referral: "",
            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleAgentUsernameChange = this.handleAgentUsernameChange.bind(this);
        this.handleMemberGroupChange = this.handleMemberGroupChange.bind(this);
        this.handleDateOfBirthChange = this.handleDateOfBirthChange.bind(this);
        this.storeMemberHandler = this.storeMemberHandler.bind(this);
    }

    componentDidMount() {
        formValidation("member_form");
        this.props.createMember();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        // console.log("[MemberCreate] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.memberCreateData !== this.props.memberCreateData) {
            if (nextProps.memberCreateData.responseCode === 200) {
                let memberGroupData = nextProps.memberCreateData.data.memberGroup;

                memberGroupArray = [];
                Object.keys(memberGroupData).map((item, i) => {
                    return memberGroupArray.push({
                        label: t(`global.level.${memberGroupData[item].memberGroupName}`),
                        value: memberGroupData[item].memberGroupId,
                    });
                });
                this.setState({
                    dialcode: nextProps.memberCreateData.data.phoneNumber.dialcode,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: true,
                    dialcode: ""
                }, () => {
                    filterErrorHandler(nextProps.memberCreateData.message);
                });
            }
        }

        if (nextProps.storeMemberResult !== this.props.storeMemberResult) {
            if (nextProps.storeMemberResult.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.storeMemberResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.storeMemberResult.message,
                });
                Swal.close();
            }
        }
        return true;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    // handleAgentUsernameChange = (event) => {
    //     this.setState({
    //         agent: event,
    //     });
    // };

    handleMemberGroupChange = (event) => {
        this.setState({
            member_group: event,
        });
    };

    handleDateOfBirthChange = (date) => {
        this.setState({
            date_of_birth: date,
        });
    };

    // loadAgentDropDown = async (search, prevOptions, { page }) => {
    //     const options = [];
    //     let totalPage = 0;
    //     const limit = Number(process.env.REACT_APP_DATATABLE_LIMIT);

    //     /* ============ get member list api [START] ============*/
    //     let data = {};
    //     data.page = page;
    //     data.limit = limit;
    //     data.search = search;
    //     data.country_id = this.props.currentCountryId;
    //     let response = await this.props.getAgentDropdown(data);
    //     /* ============ get member list api [END] ============*/
    //     if (response.data.agent.row.length > 0) {
    //         response.data.agent.row.forEach((val) => {
    //             options.push({
    //                 id: val.id,
    //                 username: val.username,
    //             });
    //         });
    //         totalPage = response.data.agent.pagination.total_page;
    //     }
    //     const hasMore = totalPage > page;
    //     return {
    //         options: options,
    //         hasMore: hasMore,
    //         additional: {
    //             page: page + 1,
    //         },
    //     };
    // };

    storeMemberHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let birthDate = null;
                if (this.state.date_of_birth !== "") {
                    birthDate = moment(this.state.date_of_birth).format("yyyy-MM-DD");
                }

                let storeMemberData = {};
                storeMemberData.nickname = this.state.nickname;
                storeMemberData.name = this.state.name;
                storeMemberData.email = this.state.email;
                storeMemberData.contact_no = this.state.dialcode.replace(/^\+/, "") + this.state.contact_no;
                storeMemberData.date_of_birth = birthDate;
                storeMemberData.password = this.state.password;
                storeMemberData.confirm_password = this.state.confirm_password;
                storeMemberData.status = this.state.status;
                storeMemberData.account_type = this.state.account_type;
                storeMemberData.member_group = this.state.member_group.value;
                storeMemberData.agent_referral = this.state.agent_referral;
                storeMemberData.identification_no = this.state.id_num;
                // console.log("Form Submit:", storeMemberData);
                this.props.storeMember(storeMemberData);
            }
        });
    };

    render() {
        const { t } = this.props;
        let status_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];

        let account_type_option = [
            { label: t("global.select"), value: "", selected: true },
            { label: t("member.real"), value: 1 },
            { label: t("member.tester"), value: 0 },
        ]

        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div>
                            <div className="btn-group">
                                <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("member.member")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("member.add-member")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="member_form" className="form-horizontal" onSubmit={this.storeMemberHandler} noValidate>
                                    <div className="col-md-12">
                                        <ValidationError formName="member_form" touched={this.state.touched} message={this.state.error_message} />
                                        {this.state.loading === true ? (
                                            <Skeleton count={4} />
                                        ) : (
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="nickname">{t("global.nickname")} *</label>
                                                        <StringInput
                                                            id="nickname"
                                                            name="nickname"
                                                            className="form-control"
                                                            placeholder={t("global.nickname")}
                                                            value={this.state.nickname}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="name">{t("member.name")} *</label>
                                                        <StringInput
                                                            id="name"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder={t("member.name")}
                                                            value={this.state.name}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="email">{t("member.email")} *</label>
                                                        <StringInput
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder={t("member.email")}
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="contact_no">{t("member.contact-no")} *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">{this.state.dialcode}</span>
                                                            <StringInput
                                                                id="contact_no"
                                                                name="contact_no"
                                                                className="form-control"
                                                                placeholder={t("member.contact-no")}
                                                                value={this.state.contact_no}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label>{t("member.date-of-birth")}</label>
                                                        <DatePicker
                                                            name="date_of_birth"
                                                            className="form-control"
                                                            wrapperClassName="wrapperDatePicker"
                                                            selected={this.state.date_of_birth}
                                                            onChange={this.handleDateOfBirthChange}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="yyyy-MM-dd"
                                                            popperContainer={DatePickerContainer}
                                                            autoComplete="off"
                                                            minDate={new Date(year - 118, month, day)}
                                                            maxDate={new Date(year - 18, month, day)}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="id_num">{t("member.identification-no")}</label>
                                                        <StringInput
                                                            id="id_num"
                                                            name="id_num"
                                                            className="form-control"
                                                            placeholder={t("member.identification-no")}
                                                            value={this.state.id_num}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="status">{t("global.status")} *</label>
                                                        <CustomSelect id="status" name="status" options={status_option} onChange={this.handleChange} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="account_type">{t("member.account-type")} *</label>
                                                        <CustomSelect id="account_type" name="account_type" options={account_type_option} onChange={this.handleChange} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="password">{t("member.password")} *</label>
                                                        <PasswordInput
                                                            onChange={this.handleChange}
                                                            id="password"
                                                            name="password"
                                                            placeholder={t("member.password")}
                                                            value={this.state.password}
                                                            required
                                                            minLength="6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="confirm_password">{t("member.confirm-password")} *</label>
                                                        <PasswordInput
                                                            onChange={this.handleChange}
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            placeholder={t("member.confirm-password")}
                                                            value={this.state.confirm_password}
                                                            required
                                                            minLength="6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="member_group">{t("member.member-group")} *</label>
                                                        <ReactSelect
                                                            options={memberGroupArray}
                                                            isSearchable={true}
                                                            name="member_group"
                                                            onChange={this.handleMemberGroupChange}
                                                            placeholder={t("global.select")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="agent_referral">{t("member.agent-referral")}</label>
                                                        <StringInput
                                                            id="agent_referral"
                                                            name="agent_referral"
                                                            className="form-control"
                                                            placeholder={t("member.agent-referral")}
                                                            value={this.state.agent_referral}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group mb-0 justify-content-end">
                                                    <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { member } = state;

    return {
        memberCreateData: member.member_create_data,
        storeMemberResult: member.member_store_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createMember: () => dispatch(createMember()),
        storeMember: (data) => dispatch(storeMember(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberCreate));
