import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";

import { showBusinessAccount, updateBusinessAccount } from "../../store/ducks/businessAccount/actions";
import { formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
import "jodit/build/jodit.min.css";

class BusinessAccountEdit extends Component {
    state = {
        bankName: "",
        bankAccNo: "",
        companyName: "",
        companyRegNo: "",
        ssmImage: "",
        username: "",
        currentStatus: 1,
        remark: null,
        memberId: "",
        action: "",
        loading: true,
    };

    componentDidMount() {
        formValidation("promotion_form");

        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        const showBusinessAccountData = {};
        showBusinessAccountData.business_account_id = this.props.show_business_account_id;
        // console.log(this.props);
        this.props.showBusinessAccount(showBusinessAccountData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.update_business_account_result !== this.props.update_business_account_result) {
            if (nextProps.update_business_account_result.responseCode === 200) {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_business_account_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.update_business_account_result.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_business_account_result.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.update_business_account_result.message[0],
                    icon: nextProps.update_business_account_result.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.show_business_account_data !== this.props.show_business_account_data) {
            if (nextProps.show_business_account_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    username: nextProps.show_business_account_data.data.data[0].username,
                    bankName: nextProps.show_business_account_data.data.data[0].bankName,
                    bankAccNo: nextProps.show_business_account_data.data.data[0].bank_account_number,
                    companyName: nextProps.show_business_account_data.data.data[0].company_name,
                    companyRegNo: nextProps.show_business_account_data.data.data[0].company_reg_no,
                    ssmImage: nextProps.show_business_account_data.data.data[0].ssm_image,
                    remark: nextProps.show_business_account_data.data.data[0].business_account_remark,
                    memberId: nextProps.show_business_account_data.data.data[0].member_id,
                    currentStatus: nextProps.show_business_account_data.data.data[0].status
                });
            } else if (nextProps.show_promotion_data.data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.show_business_account_data.data.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_business_account_data.data.message,
                    icon: nextProps.show_business_account_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    updateBusinessAccountHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.member_id = this.state.memberId;
                data.status = event.target.id;
                data.business_account_id = this.props.show_business_account_id;
                data.remark = this.state.remark

                this.props.updateBusinessAccount(data);
            }
        });
    };

    onRemarkHandler = (event) => {
        this.setState({
            remark: event.target.value
        });
    }

    handleStatusHandler = (event) => {
        console.log(event.target);
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("business-account.business-account")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("business-account.edit-business-account")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="business_account_form" className="form-horizontal" noValidate>
                                        <ValidationError formName="business_account_form" touched={this.state.touched} message={this.state.error_message} />

                                        <div className="row">
                                            <div className="col-md-12 col-12 form-group">
                                                <label htmlFor="username">{t("business-account.username")}</label>

                                                <StringInput
                                                    id="username"
                                                    name="username"
                                                    className="form-control"
                                                    placeholder={t("business-account.username")}
                                                    value={this.state.username}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="company_name">{t("business-account.company-name")}</label>

                                                <StringInput
                                                    id="company_name"
                                                    name="company_name"
                                                    className="form-control"
                                                    placeholder={t("business-account.company-name")}
                                                    value={this.state.companyName}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="company_reg_no">{t("business-account.company-reg-no")}</label>

                                                <StringInput
                                                    id="company_reg_no"
                                                    name="company_reg_no"
                                                    className="form-control"
                                                    placeholder={t("business-account.company-reg-no")}
                                                    value={this.state.companyRegNo}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("business-account.bank-name")}</label>

                                                <StringInput
                                                    id="bank_name"
                                                    name="bank_name"
                                                    className="form-control"
                                                    placeholder={t("business-account.bank-name")}
                                                    value={this.state.bankName}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_account_number">{t("business-account.bank-account-number")}</label>

                                                <StringInput
                                                    id="bank_account_number"
                                                    name="bank_account_number"
                                                    className="form-control"
                                                    placeholder={t("business-account.bank-account-number")}
                                                    value={this.state.bankAccNo}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="ssm_image">{t("business-account.ssm-image")}</label>
                                                <br />

                                                <img src={this.state.ssmImage} width="500px" height="auto" alt="" />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="remark">{t("business-account.remark")}</label>
                                                <br />

                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    name="remark"
                                                    id="remark"
                                                    maxLength={255}
                                                    rows="3"
                                                    placeholder={t("transaction.remark")}
                                                    value={this.state.remark || ""}
                                                    onChange={this.onRemarkHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="text-center align-items-center mg-b-20">
                                            {/* <div> */}
                                            {this.state.currentStatus !== 1 && this.state.currentStatus !== 2 && (
                                                <>
                                                    <Button idValue="approve" classValue="btn btn-success" typeButton="button" buttonName={t("global.approve")} clicked={(e) => this.updateBusinessAccountHandler(e)} />
                                                    &nbsp;
                                                    <Button idValue="reject" classValue="btn btn-danger" typeButton="button" buttonName={t("global.reject")} clicked={(e) => this.updateBusinessAccountHandler(e)} />
                                                </>
                                            )}

                                            {/* </div> */}
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { businessAccount, currentUser } = state;

    //Getting the state from the reducers
    return {
        show_business_account_data: businessAccount.show_business_account_data,
        update_business_account_result: businessAccount.update_business_account_result,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    showBusinessAccount,
    updateBusinessAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BusinessAccountEdit));
