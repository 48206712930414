import React, { Component } from "react";
import { connect } from "react-redux";
import { updateResetPassword } from "../../../store/ducks/agent/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import PasswordInput from "../../../components/Input/PasswordInput/PasswordInput";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import ValidationError from "../../../components/ValidationError/ValidationError";

class AgentResetPassword extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            id: 0,
            isShow: true,
            password: "",
            confirm_password: "",
            error_message: [],
            touched: false,
        };
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) 
    {
        if (nextProps.updateResetPassword !== this.props.updateResetPassword) 
        {
            if (nextProps.updateResetPassword.responseCode === 200) 
            {
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.updateResetPassword.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => 
                {
                    if (result.isConfirmed) 
                    {
                        this.setState({
                            password: "",
                            confirm_password: "",
                        });
                    }
                });
            }
            else if (nextProps.updateResetPassword.responseCode === 422) 
            {
                this.setState({
                    touched: true,
                    error_message: nextProps.updateResetPassword.message,
                });
                Swal.close();
            }
            else 
            {
                return false;
            }
        }
        return true;
    }

    handleChange = (event) => 
    {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    resetPassswordHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
                });

                let resetPassswordData = {};
                resetPassswordData.agent_id = this.props.agentEditId;
                resetPassswordData.password = this.state.password;
                resetPassswordData.confirm_password = this.state.confirm_password;
                console.log("Form Submit:", resetPassswordData);
                this.props.updateResetPassword(resetPassswordData);
            }
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        if (this.state.isShow) {
            display = (
                <>
                    <div className="row row-sm">
                        <div className="col-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("member.reset-password")}</h4>
                                </div>

                                <div className="card-body pt-0">
                                    <form method="post" id="agent_reset_password_form" className="form-horizontal" onSubmit={this.resetPassswordHandler} noValidate>
                                        <ValidationError formName="agent_reset_password_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group">
                                                    <label htmlFor="password">{t("agent.password")}</label>
                                                    <PasswordInput
                                                        onChange={this.handleChange}
                                                        id="password"
                                                        name="password"
                                                        placeholder={t("agent.password")}
                                                        value={this.state.password}
                                                        required
                                                        minLength="8"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group">
                                                    <label htmlFor="confirm_password">{t("agent.confirm-password")}</label>
                                                    <PasswordInput
                                                        onChange={this.handleChange}
                                                        id="confirm_password"
                                                        name="confirm_password"
                                                        placeholder={t("agent.confirm-password")}
                                                        value={this.state.confirm_password}
                                                        required
                                                        minLength="8"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <Button typeButton="submit" classValue="btn btn-blue" idValue="reset_password" buttonName={t("global.reset")} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            display = null;
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => 
{
    const { agent } = state;
    // console.log(agent);
    return {
        updateResetPassword: agent.update_reset_password,
    };
};

const mapDispatchToProps = (dispatch) => 
{
    return {
        updateResetPassword: (data) => dispatch(updateResetPassword(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentResetPassword));
