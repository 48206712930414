import React, { Component } from "react";
import { connect } from "react-redux";
import { getRobotsTxt, updateRobotsTxtRule, deleteRobotsTxt } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import RobotsTxtListing from "./RobotsTxtListing";
import RobotsTxtCreate from "./RobotsTxtCreate";
import RobotsTxtEdit from "./RobotsTxtEdit";
// import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

class RobotsTxt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: "",
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],
            robot_file: "",

            /* popup box */
            popupLoading: false,

            robotsTxtEditId: null,
            loading: true,
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getRobotsTxt(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.robotsTxtData !== this.props.robotsTxtData && nextProps.robotsTxtData !== undefined && nextProps.robotsTxtData.status === 200) {
            this.setState({
                data: nextProps.robotsTxtData.data.data.result.row,
                robot_file: nextProps.robotsTxtData.data.data.robot_txt,
                totalRecords: nextProps.robotsTxtData.data.data.result.pagination.total_record,
                totalPages: nextProps.robotsTxtData.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        if (nextProps.robotsTxtUpdateRuleResult !== this.props.robotsTxtUpdateRuleResult) {
            const listingArr = this.fetchData();
            this.props.getRobotsTxt(listingArr);
        }

        if (nextProps.robotsTxtDeleteResult !== this.props.robotsTxtDeleteResult) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-deleted"),
                icon: "success",
                confirmButtonText: t("global.ok"),
            }).then((result) => {
                if (result.isConfirmed) {
                    const listingArr = this.fetchData();
                    this.props.getRobotsTxt(listingArr);
                }
            });
        }

        return true;
    }

    /* ======================== Component Lifecycle [END] ======================== */
    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order === "" ? [{"id":"userAgent","desc":true}] : this.state.order);

        return listingArr;
    };

    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getRobotsTxt(listingArr);
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getRobotsTxt(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.fetchData();
            this.props.getRobotsTxt(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    ruleFormatter = (cell, row) => {
        const { t } = this.props;
        if (this.props.permission.includes("seo_meta_tags_update_status")) {
            let ruleArray, dtClassName;
            ruleArray = [
                { label: t("robots-txt.allow"), value: "Allow"},
                { label: t("robots-txt.disallow"), value: "Disallow" },
            ];
            if (row.rule === "Disallow") {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }
            return (
                <Select
                    id="rule"
                    name="rule"
                    options={ruleArray}
                    defaultValue={row.rule}
                    onChange={(event) => {
                        this.updateRobotsTxtRuleHandler(event, row.robotsTxtId);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, rule;
            if (row.rule === "Disallow") {
                badgeClassName = "badge badge-pill badge-danger";
                rule = t("robots-txt.disallow");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                rule = t("robots-txt.allow");
            }
            return (
                <span className={badgeClassName}>{rule}</span>
            )
        }

    };

    updateRobotsTxtRuleHandler = (event, id) => {
        event.preventDefault();
        this.setState({ [event.target.name]: String(event.target.value) });
        const updateRobotsTxtRuleData = {};
        updateRobotsTxtRuleData.id = id;
        updateRobotsTxtRuleData.rule = String(event.target.value);
        this.props.updateRobotsTxtRule(updateRobotsTxtRuleData);
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            robotsTxtEditId: id,
            pageShow: "edit",
        });
    };

    deleteHandler = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: "You wont be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Save`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const deleteRobotsTxtData = {};
                deleteRobotsTxtData.id = id;
                this.props.deleteRobotsTxt(deleteRobotsTxtData);
            }
        });
    };

    actionFormatter = (cell, row) => {
        let editButton, deleteButton, output;
        if(this.props.permission.includes("seo_robots_txt_edit")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-info mr-2"
                    key={`edit-${row.robotsTxtId}`}
                    idValue={row.robotsTxtId === undefined ? 0 : row.robotsTxtId}
                    clicked={(event) => this.editHandler(event, row.robotsTxtId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }
        if(this.props.permission.includes("seo_robots_txt_delete")) {
            deleteButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-danger"
                    key={`delete-${row.robotsTxtId}`}
                    idValue={row.robotsTxtId === undefined ? 0 : row.robotsTxtId}
                    clicked={(event) => this.deleteHandler(event, row.robotsTxtId)}
                    buttonName={<FontAwesomeIcon icon={faTrash} />}
                />
            );
        }
        output = [editButton, deleteButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.fetchData();
            this.props.getRobotsTxt(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.fetchData();
                this.props.getRobotsTxt(listingArr);
            });
        }
    };

    render() {
        const { t } = this.props;
        let dataListing = [];
        let robotFile = null;

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("robots-txt.user-agent"), dataField: "userAgent", sort: true, onSort: this.onSort },
            { text: t("robots-txt.rule"), dataField: "rule", formatter: this.ruleFormatter },
            { text: t("robots-txt.directory-path"), dataField: "directoryPath", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "150px" } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    robotsTxtId: val.robotsTxtId,
                    userAgent: val.userAgent,
                    rule: val.rule,
                    directoryPath: val.directoryPath,
                };
            });

            robotFile = this.state.robot_file;
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <RobotsTxtListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        robotFile={robotFile}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.props.permission}
                    />
                )}

                {this.state.pageShow === "create" && <RobotsTxtCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.pageShow === "edit" && (
                    <RobotsTxtEdit robotsTxtEditId={this.state.robotsTxtEditId} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        robotsTxtData: state.seo.robots_txt_data,
        robotsTxtUpdateRuleResult: state.seo.robots_txt_update_rule_result,
        robotsTxtDeleteResult: state.seo.robots_txt_delete_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRobotsTxt: (data) => dispatch(getRobotsTxt(data)),
        updateRobotsTxtRule: (data) => dispatch(updateRobotsTxtRule(data)),
        deleteRobotsTxt: (data) => dispatch(deleteRobotsTxt(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RobotsTxt));
