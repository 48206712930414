import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings, deleteSystemSetting, updateStatus } from "../../store/ducks/systemSettings/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import SystemSettingListing from "./SystemSettingListing";
import SystemSettingCreate from "./SystemSettingCreate";
import SystemSettingEdit from "./SystemSettingEdit";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";

import UpdownArrowWhite from "../../assets/images/updown-arrow-white.png";
class SystemSetting extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            systemSettingEditId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getSystemSettings(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let systemSettingPermission = this.props.currentPermission.filter((item) => item.includes("system_settings_"));
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.systemSettingData !== this.props.systemSettingData && nextProps.systemSettingData !== undefined && nextProps.systemSettingData.status === 200) {
            this.setState({
                data: nextProps.systemSettingData.data.data.result.row,
                totalRecords: nextProps.systemSettingData.data.data.result.pagination.total_record,
                totalPages: nextProps.systemSettingData.data.data.result.pagination.total_page,
                loading: false,
            });
        }

        // if (nextProps.systemSettingDeleteResult !== this.props.systemSettingDeleteResult) {
        //     const { t } = this.props;
        //     Swal.fire({
        //         title: t("global.record-deleted"),
        //         icon: "success",
        //         confirmButtonText: t("global.ok"),
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             const listingArr = this.fetchData();
        //             this.props.getSystemSettings(listingArr);
        //         }
        //     });
        // }
        if (nextProps.updateStatus !== this.props.updateStatus && nextProps.updateStatus !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.updateStatus);
            let success = (nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success') ? true : false;
            if (nextProps.updateStatus.responseCode === 200 && nextProps.updateStatus.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    this.refreshHandler();
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.updateStatus.message
            });
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        const listingArr = this.fetchData();
        this.props.getSystemSettings(listingArr);
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            systemSettingEditId: id,
            pageShow: "edit",
        });
    };

    // deleteHandler = (e, id) => {
    //     e.preventDefault();
    //     const { t } = this.props;
    //     Swal.fire({
    //         title: t("global.confirmation"),
    //         text: t("global.are-you-sure"),
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: t("global.delete"),
    //         cancelButtonText: t("global.cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             Swal.fire({
    //                 title: "",
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 didOpen: () => {
    //                     Swal.showLoading();
    //                 },
    //             });
    //             const data = {};
    //             data.id = id;
    //             this.props.deleteSystemSetting(data);
    //         }
    //     });
    // };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSystemSettings(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getSystemSettings(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, output;
        if (this.state.permission.includes("system_settings_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.systemSettingsId}`}
                    idValue={row.systemSettingsId === undefined ? 0 : row.systemSettingsId}
                    clicked={(event) => this.editHandler(event, row.systemSettingsId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        // if(this.state.permission.includes("system_settings_delete")) {
        //     deleteButton = (
        //         <Button
        //             typeButton="button"
        //             classValue="btn btn-sm btn-danger"
        //             key={`delete-${row.systemSettingsId}`}
        //             idValue={row.systemSettingsId === undefined ? 0 : row.systemSettingsId}
        //             clicked={(event) => this.deleteHandler(event, row.systemSettingsId)}
        //             buttonName={<FontAwesomeIcon icon={faTrash} />}
        //         />
        //     );
        // }

        output = [editButton];
        return output;
    };

    settingValueFormatter = (cell, row) => {
        const { t } = this.props;
        let settingValue = Object.entries(row.data);

        if (row.slug === 'auto-rebate' || row.slug === 'auto-stakings') {
            if (settingValue[0][0] === 'active' && (settingValue[0][1] === '0' || settingValue[0][1] === '1')) {
                let status = settingValue[0][1];
                let stCls = status === '1' ? "active" : "inactive";
                return (
                    <div className="select-input">
                        <select
                            className={`form-control datatable-select datatable-select-${stCls}`}
                            id={row.systemSettingsId}
                            name={row.systemSettingsId}
                            onChange={(event) =>
                                this.updateStatusHandler(event)
                            }
                            value={status === null ? 0 : status}
                        >
                            <option value="1">{t("global.active")}</option>
                            <option value="0">{t("global.inactive")}</option>
                        </select>
                        <img className="updown-icon" src={UpdownArrowWhite} alt="Up Down White Arrow" />
                    </div>
                )
            }
        }
        return settingValue.map((item, idx) => (
            <span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>
                {item[0]} : {item[1]}
            </span>
        ));
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.fetchData();
            this.props.getSystemSettings(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.fetchData();
                this.props.getSystemSettings(listingArr);
            });
        }
    };

    updateStatusHandler = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        this.loadingHandler();
        let data = {};
        data.id = event.target.id;
        data.key = ['active'];
        data.value = [event.target.value];
        // console.log(data);
        this.props.funcUpdateStatus(data);
    }

    /* ============ Datatables Function [END] ============ */

    render() {
        // console.log('render (agent): ', this.state);
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            { text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' } },
            { text: t('system-settings.setting-name'), dataField: "name", sort: true, onSort: this.onSort },
            { text: t('system-settings.setting-value'), dataField: "data", formatter: this.settingValueFormatter, headerStyle: { width: '250px' } },
            { text: t('global.action'), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: '65px' } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    systemSettingsId: val.systemSettingsId,
                    slug: val.slug,
                    name: val.name,
                    data: val.data,
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <SystemSettingListing
                        onInputchange={(event) => this.onInputchange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("system_settings_create") && this.state.pageShow === "create" && (
                    <SystemSettingCreate
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}

                {this.state.permission.includes("system_settings_update") && this.state.pageShow === "edit" && (
                    <SystemSettingEdit
                        systemSettingEditId={this.state.systemSettingEditId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        systemSettingData: state.systemSettings.system_setting_data,
        systemSettingDeleteResult: state.systemSettings.system_setting_delete_result,
        updateStatus: state.systemSettings.updateStatus,
        currentPermission: state.currentUser.data.permission,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getSystemSettings: (data) => dispatch(getSystemSettings(data)),
        deleteSystemSetting: (data) => dispatch(deleteSystemSetting(data)),
        funcUpdateStatus: (data) => dispatch(updateStatus(data)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SystemSetting));
