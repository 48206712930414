import axios from "../../../common/config/axios";
import * as type from "./types";

export const getSettings = (data) => (dispatch, getState) => {
    axios.get("angpau_setting/list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
                country_id: getState().currentUser.data.country_id
            },
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};


export const storeSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("angpau_setting/store", data)
        .then((res) => {
            dispatch({
                type: type.STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.STORE,
                payload: err.data,
            });
        });
};

export const getSettingByID = (data) => (dispatch) => {
    axios.get("angpau_setting/details", {params: data})
        .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res.data,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: err.data,
            });
        });
};

export const updateSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("angpau_setting/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};