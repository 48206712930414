import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import Button from "../../components/Button/Button";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import Select from "../../components/Input/Select/Select";

class GoldDiggerListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard:false
        };
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        let rowNumber =
        (this.props.totalRecords - rowIndex - ((this.props.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard:!this.state.showCard
        })
    }
    /* ============ function [END] ============ */

    render() {
        const { t } = this.props;
        let rsListing = [];
        
        /* ======================== datatables data assign [START] ======================== */
        const columns = [
            {
                text: t("gold_digger.no"),
                dataField: "no",
                formatter: this.noFormatter,
                headerStyle: { width: "60px", textAlign: "center" },
            },
            {
                text: t("gold_digger.date"),
                dataField: "date",
                sort: true,
                onSort: this.props.onSort
            },
            {
                text: t("gold_digger.order_id"),
                dataField: "order_id",
                sort: false,
            },
            {
                text: t("gold_digger.username"),
                dataField: "username",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                text: t("gold_digger.game_type"),
                dataField: "game_type",
                sort: false,
            },
            {
                text: t("gold_digger.valid_bet"),
                dataField: "valid_bet",
                sort: false,
            },
            {
                text: t("gold_digger.bonus_amount"),
                dataField: "bonus_amount",
                sort: false,
            }
        ];
        
        if (this.props.data) {
            rsListing = this.props.data.map((val, idx) => {
                return {
                    no: idx,
                    date: val.created_at,
                    order_id: val.order_id,
                    username: val.username,
                    game_type: t('game-type.'+val.game_type),
                    valid_bet: val.valid_bet,
                    bonus_amount: val.bonus_amt
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">
                                {t("gold_digger.gold_digger_listing")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body">
                            <div className="col mt-auto">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                            <div
                            className={`form-horizontal filter-function row
                            ${this.state.showCard?'open':'close'} filter-wrapper-1`}>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("gold_digger.date")}
                                        </label>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-calendar-check" />
                                            </span>
                                            <DateTimePicker
                                                id="date"
                                                name="date"
                                                className="form-control filter-input"
                                                range={true}
                                                timePicker={true}
                                                value={
                                                    this.props.filter.date
                                                }
                                                onChange={
                                                    this.props.onInputChange
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("gold_digger.username")}
                                        </label>
                                        <StringInput
                                            id="username"
                                            name="username"
                                            value={this.props.filter.username}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("gold_digger.order_id")}
                                        </label>
                                        <StringInput
                                            id="order_id"
                                            name="order_id"
                                            value={this.props.filter.order_id}
                                            onChange={this.props.onInputChange}
                                            className="filter-input"
                                            onSearchPress={(evt) =>
                                                evt.key === "Enter" &&
                                                this.props.refreshHandler()
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("gold_digger.status")}
                                        </label>
                                        <Select
                                            id="status"
                                            name="status"
                                            className="form-control filter-input"
                                            onChange={this.props.onInputChange}
                                            options={this.props.dropdown.status}
                                            emptyValue={true}
                                            defaultValue={this.props.filter.status}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-md-3 col-6">
                                    <div className="form-group">
                                        <label className="font-weight-bolder">
                                            {t("gold_digger.game_type")}
                                        </label>
                                        <Select
                                            id="game_type"
                                            name="game_type"
                                            className="form-control filter-input"
                                            onChange={this.props.onInputChange}
                                            options={this.props.dropdown.gameType}
                                            emptyValue={true}
                                            defaultValue={this.props.filter.game_type}                                            
                                        />
                                    </div>
                                </div>
                                <div className="col mt-auto">
                                    <div>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-blue"
                                        idValue="filter"
                                        buttonName={t("global.filter")}
                                        clicked={() =>
                                            this.props.refreshHandler()
                                        }
                                    />
                                    &nbsp;
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary"
                                        idValue="reset"
                                        buttonName={t("global.reset")}
                                        clicked={() =>
                                            this.props.clearHandler()
                                        }
                                    />
                                    </div>
                                </div>

                            </div>
                                    )}
                                </div>

                            <div className="show-btn"
                            onClick={()=>this.toggleCard()}>
                                {
                                    this.state.showCard? <AiFillMinusCircle/> : <AiFillPlusCircle/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">
                                        {t("gold_digger.gold_digger_listing")}
                                    </h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={rsListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "activePage"
                                            )
                                        }
                                        sizePerPageHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "limit"
                                            )
                                        }
                                        searchChangeHandler={(val) =>
                                            this.props.datatablesChangeHandler(
                                                val,
                                                "searchValue"
                                            )
                                        }
                                        searchValue={this.props.searchValue}
                                        hideSearchBar={true}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(GoldDiggerListing);
