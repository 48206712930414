import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const systemSettingReducer = createReducer(initialState) ({
    [type.SYSTEM_SETTING_LIST]: (state, action) => ({
        ...state,
        system_setting_data: action.payload
    }),

    [type.SYSTEM_SETTING_STORE]: (state, action) => ({
        ...state,
        system_setting_store_result: action.payload,
    }),

    [type.SYSTEM_SETTING_EDIT]: (state, action) => ({
        ...state,
        system_setting_edit_data: action.payload
    }),

    [type.SYSTEM_SETTING_UPDATE]: (state, action) => ({
        ...state,
        system_setting_update_result: action.payload
    }),

    [type.SYSTEM_SETTING_DELETE]: (state, action) => ({
        ...state,
        system_setting_delete_result: action.payload
    }),

    [type.SYSTEM_SETTING_UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.value,
    }),
});

export default systemSettingReducer;