import axios from "../../../common/config/axios";
import * as types from "./types";

export const getDailyMissionSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("daily_mission_settings/list", {params: data})
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_LIST,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_LIST,
            payload: []
        });
    });
};

export const createDailyMissionSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("daily_mission_settings/create", data)
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_CREATE,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_CREATE,
            payload: []
        });
    });
}

export const showDailyMissionSetting = (data) => (dispatch) => {
    axios.get("daily_mission_settings/show", {params: data})
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_SHOW,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_SHOW,
            payload: []
        });
    });
}

export const updateDailyMissionSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("daily_mission_settings/update", data)
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_UPDATE,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_UPDATE,
            payload: []
        });
    });
}

export const statusDailyMissionSetting = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("daily_mission_settings/status", data)
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_STATUS,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_SETTING_STATUS,
            payload: []
        });
    });
}

export const getDailyMission = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("daily_mission/list", {params: data})
    .then((res) => {
        dispatch({
            type: types.DAILY_MISSION_LIST,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: types.DAILY_MISSION_LIST,
            payload: []
        });
    });
}