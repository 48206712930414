import React, {Component} from 'react';
import {connect} from 'react-redux';
// import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import axios from "../../../common/config/axios";
import * as moment from "moment";
import { getYearlyVendorStatusReport } from "../../../store/ducks/report/actions";

import YearlyVendorStatusReportListing from "./YearlyVendorStatusReportListing";


const initialInputState =
{
    /* form input value */
    year: '',
    date: '',

};
// let yearlyvendorStatusData = {};

class YearlyVendorStatusReport extends Component {


    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: '',
        isOpen: false,
        filter: initialInputState,
        data: [],

        loading: false,

        permission: []
    }

    fetchData(){
        const yearlyvendorStatusData = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        yearlyvendorStatusData.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        yearlyvendorStatusData.limit = this.state.limit;
        yearlyvendorStatusData.order = JSON.stringify(this.state.sorted);
        yearlyvendorStatusData.lang_id = this.props.currentLanguageId;
        yearlyvendorStatusData.country_id = this.props.currentCountryId;
        this.props.getYearlyVendorStatusReport(yearlyvendorStatusData);
    }

    componentDidMount() {
        // const listingArr = this.getFilterArray();
        // this.props.getYearlyVendorStatusReport(listingArr);
        // this.fetchData();

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            // let reportPermission = this.props.currentPermission.filter((item) => item.includes("report_"));
            this.setState({permission: this.props.currentPermission});
        }

        if (nextProps.reportData !== this.props.reportData) {
            if (nextProps.reportData.data && nextProps.reportData.data.responseCode === 200) {
                this.setState({
                    data: nextProps.reportData.data.data.result.row,
                    totalRecords: nextProps.reportData.data.data.result.pagination.total_record,
                    totalPages: nextProps.reportData.data.data.result.pagination.total_page,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                // Swal.fire({
                //     title: nextProps.reportData.data.message[0],
                //     icon: nextProps.reportData.data.msgType,
                //     confirmButtonText: `OK`,
                // });
            }
        }

        return true;
    }

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.getFilterArray();
            this.props.getYearlyVendorStatusReport(listingArr);
        });
    }


    pageChangeHandler = (activePage) => {
        this.setState({page: activePage, activePage: activePage, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    sizePerPageHandler = (limit) => {
        this.setState({limit: limit, loading: true, data: []}, () => {
            this.fetchData();
        });
    }

    refreshHandler = () =>
    {
        this.setState(
            {
                loading: true,
                dataLoading: true,
                data: [],
            },
            () => {
                if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getYearlyVendorStatusReport(listingArr);
                }
            }
        );
    }

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                totalRecords: 0,
                loading: false,
                dataLoading: false,
                filter: newFilter,
                data: [],
            },
            () => {
                if (this.state.dataLoading) {
                    const listingArr = this.getFilterArray();
                    this.props.getYearlyVendorStatusReport(listingArr)
                }
            }
        );
    };

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getYearlyVendorStatusReport(listingArr);
            }
        );
    };

    postSearchHandler = () => {
        this.setState({loading: true, data: [], activePage: 1}, () => {
           this.fetchData();
        });
    }

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({loading: true, data: [], activePage: 1}, () => {
                this.fetchData();
            });
        }
    }

    exportExcelHandler = () => {
        const data = {};
        data.year = this.state.year;
        data.date = this.state.date;
        data.total_bet_amount = this.state.total_bet_amount;
        data.total_win_lose = this.state.total_win_lose;

        axios
            .get("report/export_yearly_vendor_status_report", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = "100Express Report - Yearly Vendor Total Win Lose " + timestamp;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - MemberDepositStatus Error", err);
            });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t('global.no'),
                dataField: "",
                keyField: "no",
                formatter: (cell, row, rowIndex) => {
                    let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10));
                    return <span>{rowNumber}</span>;
                },
                headerStyle: () => {
                    return {width: '60px'};
                }
            },
            // {
            //     text: t("report.year"),
            //     dataField: "year",
            //     keyField: "Year",
            //     sort: true,
            //     onSort: this.onSort
            // },
            {
                text: t("report.date"),
                dataField: "date",
                keyField: "Date",

            },
            {
                text: t("report.total-bet-amount"),
                dataField: "total_bet_amount",
                keyField: "Total bet amount",
            },
            {
                text: t("report.total-win-lose"),
                dataField: "total_win_lose",
                keyField: "Total win / lose",
            },

        ];

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                <div>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('report.yearly-vendor-win-lose-report')}</h4>
                            </div>
                        </div>
                    </div>
                    <YearlyVendorStatusReportListing
                        exportExcelHandler={(() => this.exportExcelHandler())}
                        filter={this.state.filter}
                        clearHandler={() => this.clearHandler()}
                        columns={columns}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        data={this.state.data}
                        order={this.state.sorted}
                        pageChangeHandler={this.pageChangeHandler}
                        sizePerPageHandler={this.sizePerPageHandler}
                        searchHandler={this.refreshHandler}
                        refreshHandler={() => this.refreshHandler()}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}

                    />
                </div>
                }

            </Auxiliary>
        )
    }
};

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        reportData: state.report.get_yearly_vendor_status_report_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getYearlyVendorStatusReport: (filter) => dispatch(getYearlyVendorStatusReport(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(YearlyVendorStatusReport));