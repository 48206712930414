import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import DataTable from "../../components/Datatable/DataTable";

import { showMemberReferral } from "../../store/ducks/memberReferral/actions";

class MemberReferralDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            member: [],
            loading: true,
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalPages: 0,
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            order: null,
            totalRecords: 0,
            searchValue: ""
        }
    }

    componentDidMount(){
        const data = this.getFilterList();
        this.props.showMemberReferral(data);
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.member_referral_show !== undefined && nextProps.member_referral_show !== this.props.member_referral_show){
            if(nextProps.member_referral_show.data.responseCode === 200 && nextProps.member_referral_show.data.msgType === "success"){
                this.setState({
                    loading: false,
                    member: nextProps.member_referral_show.data.data.member,
                    data: nextProps.member_referral_show.data.data.result.row,
                    totalPages: nextProps.member_referral_show.data.data.result.pagination.total_page,
                    totalRecords: nextProps.member_referral_show.data.data.result.pagination.total_record
                });
            }
        }

        return true;
    }

    getFilterList = () => {
        const listingArr = {};

        let availablePage = this.state.activePage;
        if (this.state.totalRecords > 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.member_id = this.props.detailsParam.memberId;
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);

        this.setState({
            loading: true,
            data: []
        });
        return listingArr;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: []
        }, () => {
            const data = this.getFilterList();
            this.props.showMemberReferral(data);
        });
    }

    datatablesChangeHandler = (val, act) => {
        let search = "";
        search = (act === 'searchValue') ? val.target.value : val;

        this.setState({
            [act]: search,
            loading: true,
            data: []
        }, () => {
            const data = this.getFilterList();
            this.props.showMemberReferral(data);
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    render(){
        const { t } = this.props;
        const columns = [
            { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
            { text: t("member-referral.referee-username"), dataField: 'username', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.referee-name"), dataField: 'name', sort: true, onSort: (field, order) => this.onSort(field, order) },
            { text: t("member-referral.country"), dataField: 'shortcode', sort: true, onSort: (field, order) => this.onSort(field, order) }
        ];
        let data = this.state.data ? this.state.data.map((val, idx) => {
            return {
                no: idx,
                username: val.username,
                name: val.name,
                shortcode: t(`global.country_shortcode.${val.shortcode}`)
            }
        }) : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div>
                            <div className="btn-group">
                                <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler(this.props.detailsParam.returnPage)} />
                                <div>
                                    <div className="member-name">
                                        <FontAwesomeIcon icon={faUser} /> {this.state.member.username}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12'>
                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mg-b-0'>{t('member-referral.member-details')}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.loading ? <Skeleton count={3} /> :
                                <table className='table table-bordered table-hover'>
                                    <tbody>
                                        <tr>
                                            <th>{t('member-referral.member-username')}</th>
                                            <td>{this.state.member.username}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('member-referral.member-name')}</th>
                                            <td>{this.state.member.name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>

                        <div className='card box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t(`member-referral.referee-details`)}</h4>
                            </div>
                            <div className='card-body'>
                                <DataTable
                                    keyField='no'
                                    data={data}
                                    columns={columns}
                                    activePage={this.state.activePage}
                                    limit={this.state.limit}
                                    totalItems={this.state.totalRecords}
                                    paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                    sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                    searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                    searchValue={this.state.searchValue}
                                    classValue='dataTable table-bordered text-justify text-center'
                                    loading={this.state.loading}
                                    hideSearchBar={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        member_referral_show: state.memberReferral.member_referral_show,
        currentUser: state.currentUser.data
    }
}

const mapDispatchToProps = {
    showMemberReferral
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberReferralDetail));