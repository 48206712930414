import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { getMembers, storeSensitiveGoodReturn } from "../../store/ducks/sensitiveGoodReturn/actions";
import { formValidation } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Swal from "sweetalert2";
import StringInput from '../../components/Input/StringInput/StringInput';

class SensitiveGoodReturnCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			member: "",
			tracking_no: "",
			price: "",
			refer_id: "",

			error_message: [],
			loading: false,
			touched: false
		}
	}

	componentDidMount() {
		formValidation("shipment_form");
	}
	
	shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.get_members !== this.props.get_members && nextProps.get_members !== undefined) {
            if (nextProps.get_members.responseCode === 200 && nextProps.get_members.msgType === 'success') {
                this.setState({
                    confirmMember: this.state.member,
                    // avBalc: nextProps.memberBalc.data.balance,
                    validMember: true,
                });
            }
            else {
                this.setState({
                    touched: true,
                    errMsg: nextProps.get_members.message
                })
            }
            Swal.close();
        }

		if (nextProps.store_sensitive_good_return !== this.props.store_sensitive_good_return && nextProps.store_sensitive_good_return !== undefined) {
			if (nextProps.store_sensitive_good_return.responseCode === 200 && nextProps.store_sensitive_good_return.msgType === 'success') {
				const { t } = this.props;
				Swal.fire({
					title: t("global.record-saved"),
					icon: nextProps.store_sensitive_good_return.msgType,
					confirmButtonText: t("global.ok"),
				}).then((result) => {
					if (result.isConfirmed) {
					this.props.refreshHandler();
					this.props.changePageHandler("listing");
					}
				});
			} else {
				this.setState({
					touched: true,
					errMsg: nextProps.store_sensitive_good_return.message,
				});
				Swal.close();
			} 
		}
		return true;
	}

	submitHandler = (e) => {
		e.preventDefault();

		const { t } = this.props;

		Swal.fire({
			title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "",
					allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading();
                    },
				});

				let storeData = {};
				storeData.email = this.state.member;
				storeData.tracking_no = this.state.tracking_no;
                storeData.price = this.state.price;
                storeData.refer_id = this.state.refer_id;

				this.props.store(storeData);
			}
		})
	}

    onInputChange = (event, id) => {
        // console.log('onInputChange :', event.target.id, event.target.value);
        let newId = (event.id) ? id : event.target.id;
        let newVal = (event.id) ? event.id : event.target.value;

        this.setState({
            [newId]: newVal,
        });
    }
    
    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

	mapOptions = (data, labelField) => {
		return data.map(item => ({
			label: this.props.t(`price-list.${item[labelField]}`),
			value: item.id,
		}));
	};
	
	handleDropdownChange = (name) => (selectedOption) => {
		this.setState({
			[name]: selectedOption.value,
		}, () => {
			const { state, shipment_type } = this.state;
			
			if (shipment_type === 2 && (state === 2 || state === 3)) {
				this.props.getAreaDropDown(state);
			}
		});
	};

	render() {
		const { t } = this.props;

		return (
			<Auxiliary>
				<div className="breadcrumb-header">
					<div className="d-flex">
						<div className="my-auto">
							<Button 
								typeButton="button"
								classValue="btn btn-back-outline"
								idValue="back"
								buttonName={<i className="angle fe fe-chevron-left"></i>}
								clicked={() => this.props.changePageHandler("listing")}
							/>
						</div>
					</div>
					<div className="my-auto">
						<div className="d-flex">
							<h4 className="content-title mb-0 my-auto ml-2">
								{t("sensitive-good-return.sensitive-good-return")}
							</h4>
						</div>
					</div>
				</div>

				<div className="row row-sm">
					<div className="col-12">
						<div className="card box-shadow-0">
							<div className="card-header">
								<h4 className="card-title mb-1">
									{t("sensitive-good-return.add-sensitive-good-return")}
								</h4>
							</div>
							<div className="card-body pt-0">
								<form
									method="post"
									id="shipment_form"
									className="form-horizontal"
									onSubmit={this.submitHandler}
									noValidate
								>
									<div className="col-md-12">
										<ValidationError
											formName="shipment_form"
											touched={this.state.touched}
											message={this.state.errMsg}
										/>
										{this.state.loading === true ? (
											<Skeleton count={4} />
										) : (
											<div className="row">
											    <div className='col-md-4 col-12 form-group'>
                                                    <label>{t('wallet-adjustment.member')}</label>
                                                    <div className="position-relative">
                                                        <StringInput
                                                            id='member'
                                                            name='member'
                                                            placeholder={t('member.email')}
                                                            value={this.state.member}
                                                            onChange={this.onInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
												<div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="courier">
															{t("sensitive-good-return.tracking_no")}
														</label>

                                                        <StringInput
                                                            id="tracking_no"
                                                            name="tracking_no"
                                                            className="form-control"
                                                            placeholder={t("sensitive-good-return.tracking_no")}
                                                            value={this.state.tracking_no}
                                                            onChange={this.onInputChange}
                                                            required
                                                        />
													</div>
												</div>
                                                
                                                <div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="courier">
															{t("sensitive-good-return.price")}
														</label>

                                                        <NumberInput
                                                            id="price"
                                                            name="price"
                                                            className="form-control"
                                                            placeholder={t("sensitive-good-return.price")}
                                                            value={this.state.price}
                                                            onChange={this.onInputChange}
                                                            required
                                                        />
													</div>
												</div>

                                                <div className="col-md-4 col-12">
													<div className="form-group">
														<label htmlFor="courier">
															{t("sensitive-good-return.refer_id")}
														</label>

                                                        <StringInput
                                                            id="refer_id"
                                                            name="refer_id"
                                                            className="form-control"
                                                            placeholder={t("sensitive-good-return.refer_id")}
                                                            value={this.state.refer_id}
                                                            onChange={this.onInputChange}
                                                            required
                                                        />
													</div>
												</div>
                                                																						

												<div className="col-12 form-group mb-0 justify-content-end">
													<Button
														typeButton="submit"
														classValue="btn btn-blue"
														idValue="submit"
														buttonName={t("global.save")}
													/>
												</div>
											</div>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Auxiliary>
		)
	}
}

const mapStateToProps = (state) => {
	const { sensitiveGoodReturn } = state;
	return {
		get_members: sensitiveGoodReturn.get_members,
        store_sensitive_good_return: sensitiveGoodReturn.store_sensitive_good_return
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		getMembers: (data) => dispatch(getMembers(data)),
        store: (data) => dispatch(storeSensitiveGoodReturn(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SensitiveGoodReturnCreate));
  