import React, { Component } from "react";
import { connect } from "react-redux";
import { getList } from "../../store/ducks/voucherClaim/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import VoucherClaimListing from "./VoucherClaimListing";
import TransactionDetails from '../Transaction/TransactionDetails';
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import axios from "../../common/config/axios";
import { filterErrorHandler } from "../../hoc/Shared/utility";

const initialInputState =
{
    /* form input value */
    code: '',
    email: ''
};

class VoucherClaim extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            loading: true,

            permission: [],
            groupData: [],

            filter: initialInputState,
        };
        
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.fetchData();
        this.props.getList(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.data.responseCode === 200 && nextProps.rs.data.msgType === 'success') {
                let groupData = [];
                if (!!(nextProps.rs.data.data.groupData) && nextProps.rs.data.data.groupData !== undefined && Object.values(nextProps.rs.data.data.groupData).length > 0) {
                    Object.values(nextProps.rs.data.data.groupData).map((groupRes) => {
                        let groupID = '';
                        let email = '';

                        groupRes.map((groupVal) => {
                            groupID = groupVal.group_id;
                            email = email === '' ? groupVal.email : email+', '+groupVal.email;
                            return null;
                        });

                        groupData.push({groupID: groupID,email: email});
                        return null;
                    });
                }

                this.setState({
                    data: nextProps.rs.data.data.result.row,
                    totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                    totalPages: nextProps.rs.data.data.result.pagination.total_page,
                    groupData: groupData,
                    loading: false,
                });
            }
            else {
                this.setState({
                    data: [],
                    totalRecords: 0,
                    totalPages: 1,
                    groupData: [],
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.rs.data.message);
                })
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getList(listingArr);
        });
    };

    fetchData = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    onInputChange(event) { 
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date') {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
    
        this.setState({
            filter: newFilter
        });
    }
    
    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        // console.log(listingArr);
        return listingArr;
    }

    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * this.state.limit;
        return <span>{rowNumber}</span>;
    };

    detailsHandler = (e, id) =>
    {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            detailsParam :
            {
                id: id
            },
            xPosition: 0,
            yPosition: 0
        });
    }
    
    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getList(listingArr);
        });
    }

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.fetchData();
            this.props.getList(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.fetchData();
                this.props.getList(listingArr);
            });
        }
    };
    
    /* ============ Datatables Function [END] ============ */

    exportExcelHandler = () =>
    {
        const data = {};
        data.code = this.state.filter.code;
        data.email = this.state.filter.email;
        data.order = JSON.stringify(this.state.order);

        axios
            .get("voucher_claim/export_excel", {
                params: data,
                responseType: "blob",
            })
            .then((res) => {
                const currentDate = new Date();
                const timestamp = moment(currentDate).format("YYMMDD HHmmss");
                let fileName = `100Express - VoucherClaimList ${timestamp}`;
                const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
                const aElement = document.createElement("a");
                const href = window.URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = fileName;
                document.body.appendChild(aElement);
                aElement.click();
                document.body.removeChild(aElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.log("Excel - VoucherClaimList Error", err);
            });
    }

    render() {
        // console.log('render (agent): ', this.state);`
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            {text: t('global.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px' }},
            {text: t('voucher.code'), dataField: "code"},
            {text: t('voucher.email'), dataField: "email"},
            {text: t('voucher.order_id'), dataField: "order_id"},
            {text: t('voucher.deposit_order_id'), dataField: "deposit_order_id"},
            {text: t('voucher.updated_at'), dataField: "updated_at"},
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                let orderIdVal = !!(val.order_id) ? this.props.currentUser.permission.includes("transaction_details_access") ? (
                    <b>
                        <a
                            href="/#"
                            onClick={(event) =>
                                this.detailsHandler(event, val.transaction_id)
                            }
                        >
                            {val.order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.order_id
                ) : '-';

                let depositOrderIdVal = !!(val.deposit_order_id) ? this.props.currentUser.permission.includes("transaction_details_access") ? (
                    <b>
                        <a
                            href="/#"
                            onClick={(event) =>
                                this.detailsHandler(event, val.deposit_transaction_id)
                            }
                        >
                            {val.deposit_order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                ) : (
                    val.deposit_order_id
                ) : '-';

                return {
                    code: val.code,
                    email: val.email,
                    transaction_id: val.transaction_id,
                    order_id: orderIdVal,
                    deposit_order_id: depositOrderIdVal,
                    updated_at: val.updated_at,
                };
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <VoucherClaimListing
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        filter={this.state.filter}
                        exportExcelHandler={() => this.exportExcelHandler()}
                    />
                )}

                {this.state.pageShow==='details' &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page,xPosition,yPosition) => this.changePageHandler(page,xPosition,yPosition)}
                        returnPage='listing'
                        xPosition={window.pageXOffset}
                        yPosition={window.pageYOffset}
                    />
                }
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.voucher.rs,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getList: (data) => dispatch(getList(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VoucherClaim));
