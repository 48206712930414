import React, { Component } from "react";
import { connect } from "react-redux";
import { getMemberLevelTracker, getFilterData } from "../../store/ducks/memberLevelTracker/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MemberLevelTrackerListing from "./MemberLevelTrackerListing";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

const initialInputState = {
    username: "",
    level_from: "",
    level_to: "",
    level_status: "",
    level_up_date: "",
};

class MemberLevelTracker extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",
            activePage: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalRecords: 0,
            filter: initialInputState,
            data: [],
            dropDown: {
                member_group_option: [],
            },
            popupLoading: false,
            loading: true,
            permission: [],
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getMemberLevelTracker(listingArr);
        this.props.getFilterData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let memberLevelTrackerPermission = this.props.currentPermission.filter((item) => item.includes("member_level_tracker"));
            this.setState({ permission: memberLevelTrackerPermission });
        }

        if (nextProps.memberLevelTrackerData !== this.props.memberLevelTrackerData && nextProps.memberLevelTrackerData !== undefined) {
            if (nextProps.memberLevelTrackerData.status === 200 && nextProps.memberLevelTrackerData.data.msgType === "success") {
                this.setState({
                    data: nextProps.memberLevelTrackerData.data.data.result.row,
                    totalRecords: nextProps.memberLevelTrackerData.data.data.result.pagination.total_record,
                    totalPages: nextProps.memberLevelTrackerData.data.data.result.pagination.total_page,
                    loading: false,
                });
                Swal.close();
            } else {
                if (nextProps.memberLevelTrackerData !== undefined && nextProps.memberLevelTrackerData.hasOwnProperty("message")) {
                    const { t } = this.props;
                    let msg = null;
                    msg = "<ul>";
                    nextProps.memberLevelTrackerData.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";

                    Swal.fire({
                        icon: "error",
                        title: t("global.error"),
                        html: msg,
                        showConfirmButton: true,
                    });
                }
            }
        }

        if (nextProps.memberFilterData !== this.props.memberFilterData && nextProps.memberFilterData !== undefined) {
            if (nextProps.memberFilterData.data.responseCode === 200 && nextProps.memberFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        member_group_option: nextProps.memberFilterData.data.data.memberGroup,
                    },
                });
            }
            Swal.close();
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    onInputChange(event) {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if (event.target.id === "filter_created_date") {
            const date = event.target.value !== "" ? event.target.value.split(" - ") : null;
            newFilter["start_date"] = date ? date[0] : "";
            newFilter["end_date"] = date ? date[1] : "";
        }
        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTracker(listingArr);
            }
        );
    };

    searchHandler = () => {
        this.setState(
            {
                activePage: 1,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTracker(listingArr);
            }
        );
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: 1,
                // limit: 10,
                searchValue: "",
                loading: true,
                // order: [{ id: "memberId", desc: "asc" }],
                filter: newFilter,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTracker(listingArr);
            }
        );
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        // console.log(page);
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTracker(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMemberLevelTracker(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];
        let ddMemberGroup = [];

        const columns = [
            // { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("member.username"), dataField: "username", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.level-from"), dataField: "levelFromGroupName", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.level-to"), dataField: "levelToGroupName", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.level-status"), dataField: "levelStatus", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.accumulated-deposit"), dataField: "accumulatedDeposit", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.accumulated-turnover"), dataField: "accumulatedTurnover", sort: true, onSort: this.onSort },
            { text: t("global.created-time"), dataField: "createdDate", sort: true, onSort: this.onSort },
        ];

        if (this.state.pageShow === "listing") {
            if (this.state.data.length > 0) {
                let st_val = "";

                let st = { 0: t("member-level-tracker.maintain"), 1: t("member-level-tracker.level-up"), 2: t("member-level-tracker.level-down") };
                let st_cls = { 0: "primary", 1: "success", 2: "danger" };
                dataListing = this.state.data.map((val, idx) => {
                    st_val = <span className={`badge badge-pill badge-${st_cls[val.levelStatus]}`}>{st[val.levelStatus]}</span>;

                    return {
                        memberLevelTrackerId: val.memberLevelTrackerId,
                        username: val.username,
                        levelFromGroupName: val.levelFromGroupName,
                        levelToGroupName: val.levelToGroupName,
                        levelStatus: st_val,
                        accumulatedDeposit: val.accumulatedDeposit,
                        accumulatedTurnover: val.accumulatedTurnover,
                        createdDate: val.createdDate,
                    };
                });
            }

            if (this.state.dropDown.member_group_option) {
                ddMemberGroup = this.state.dropDown.member_group_option.map((val, idx) => {
                    return (
                        <option key={val.memberGroupId} value={val.memberGroupId}>
                            {val.groupName}
                        </option>
                    );
                });
            }
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <MemberLevelTrackerListing
                        filter={this.state.filter}
                        searchHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        ddMemberGroup={ddMemberGroup}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberLevelTrackerData: state.memberLevelTracker.member_level_tracker_data,
        memberFilterData: state.memberLevelTracker.member_level_tracker_filter_data,
        currentCountryId: state.currentUser.data.country_id,
        currentPermission: state.currentUser.data.permission,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberLevelTracker: (filter) => dispatch(getMemberLevelTracker(filter)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MemberLevelTracker));
