import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (params) => dispatch => {
    axios.get('game_bet_slip/list', {params: params})
    .then(function (res) {
        dispatch ({
            type: actionType.LIST,
            value: res.data
        });
    }).catch((err) => {
        console.log("Game Bet Slip List Error" + err);
        dispatch({
            type: actionType.LIST,
            value: [],
        });
    });
};

export const getFilterData = () => dispatch => {
    axios.get('game_bet_slip/get_filter')
    .then(function (res) {
        dispatch ({
            type: actionType.GAME_BET_SLIP_FILTER_DATA,
            value: res.data
        });
    }).catch((err) => {
        console.log("Game Bet Slip Filter Data Error" + err);
        dispatch({
            type: actionType.GAME_BET_SLIP_FILTER_DATA,
            value: [],
        });
    });
};

export const getBetslipDetails = (params) => dispatch => {
    axios.get('game_bet_slip/details', {params: params})
    .then(function (res) {
        dispatch ({
            type: actionType.GAME_BET_SLIP_BET_DETAILS,
            value: res.data
        });
    }).catch((err) => {
        console.log("Game Bet Slip Filter Data Error" + err);
        dispatch({
            type: actionType.GAME_BET_SLIP_BET_DETAILS,
            value: [],
        });
    });
};