import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import StringInput from "../../../components/Input/StringInput/StringInput";
// import NumberInput from "../../components/Input/NumberInput/NumberInput";
// import Button from "../../../components/Button/Button";
// import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
// import Skeleton from "react-loading-skeleton";
// import StringInput from "../../../components/Input/StringInput/StringInput";
// import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

class MemberCryptoDepositListing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = this.props.totalRecords - rowIndex - (this.props.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    render() {
        const { t } = this.props;
        let dataListing = [];

        const columns = [
            // { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("member-level-tracker.level-from"), dataField: "levelFromGroupName", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.level-to"), dataField: "levelToGroupName", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.level-status"), dataField: "levelStatus", sort: true, onSort: this.onSort },
            // { text: t("member-level-tracker.accumulated-deposit"), dataField: "accumulatedDeposit", sort: true, onSort: this.onSort },
            // { text: t("member-level-tracker.accumulated-turnover"), dataField: "accumulatedTurnover", sort: true, onSort: this.onSort },
            { text: t("member-level-tracker.accumulated-payment"), dataField: "accumaltedPayment", sort: true, onSort: this.onSort },
            { text: t("global.created-time"), dataField: "createdDate", sort: true, onSort: this.onSort },
        ];

        if (this.props.data) {
            let st_val = "";

            let st = { 
                        0: t("member-level-tracker.maintain"), 
                        1: t("member-level-tracker.level-up"), 
                        // 2: t("member-level-tracker.level-down") 
                    };
            let st_cls = { 
                            0: "primary", 
                            1: "success", 
                            // 2: "danger" 
                        };
            dataListing = this.props.data.map((val) => {
                st_val = <span className={`badge badge-pill badge-${st_cls[val.levelStatus]}`}>{st[val.levelStatus]}</span>;

                return {
                    memberLevelTrackerId: val.memberLevelTrackerId,
                    levelFromGroupName: val.levelFromGroupName,
                    levelToGroupName: val.levelToGroupName,
                    levelStatus: st_val,
                    // accumulatedDeposit: val.accumulatedDeposit,
                    // accumulatedTurnover: val.accumulatedTurnover,
                    accumaltedPayment: val.accumaltedPayment,
                    createdDate: val.createdDate
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("member.level-tracker-history")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="memberLevelTrackerId"
                                        data={dataListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MemberCryptoDepositListing);
