import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Lightbox from "react-image-lightbox";

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import ValidationError from "../../components/ValidationError/ValidationError";
import MemberShow from "../Members/MemberShow";
import Select from '../../components/Input/Select/Select';
import { filterErrorHandler } from "../../hoc/Shared/utility";

import * as actions from "../../store/ducks/deposit/actions";

const initialFormInputState =
{
    /* form input value */
    bankID: '',
    bankAccID: '',
    bankAccNumber: '',
};

class DepositEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageShow: "details",
            fees: 0.0,
            remark: null,
            details: {},
            id: null,
            act: "",
            loading: true,
            errMsg: [],
            touched: false,
            rejectReason: [],
            currentImage: "",
            isOpen: false,

            activePage: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            totalRecords: 0,
            memberData: [],
            conflictData: [],
            memberShowId: null,

            bankAccList: [],
            formFilter: initialFormInputState,
        };
        this.onInputChange = this.onInputChange.bind(this);
        // console.log('constructor :', this.state);
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.funcGetDetails(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate :', nextProps.details);
        const { t } = this.props;

        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === "success") {
                let newDetails = nextProps.details.data.result[0];
                if (
                    nextProps.details.data.result[0] &&
                    newDetails.update_by !== null &&
                    newDetails.update_by !== this.props.currentUser.username &&
                    (newDetails.status === 0 || newDetails.status === 3)
                ) {
                    Swal.fire({
                        title: t("global.oops"),
                        text: t("global.transaction-occupied"),
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                }
                // console.log(nextProps.details.data);
                if (nextProps.details.data.result[0]) {
                    let bankAccList = [];
                    if (this.props.bankAccountList[nextProps.details.data.result[0].bank_id]) {
                        this.props.bankAccountList[nextProps.details.data.result[0].bank_id].forEach((val) => {
                            bankAccList[val.id] = val;
                        });
                    }

                    this.setState({
                        // fees: (this.state.fees!==null) ? this.state.fees : nextProps.details.fees,
                        fees: newDetails.fees,
                        remark: this.state.remark !== null ? this.state.remark : newDetails.remark,
                        details: nextProps.details.data.result,
                        id: newDetails.id,
                        loading: false,
                        rejectReason: nextProps.details.data.reject_reason,
                        bankAccList: bankAccList,
                        formFilter: {
                            bankID: nextProps.details.data.result[0].bank_id,
                            bankAccID: nextProps.details.data.result[0].bank_acc_id,
                            bankAccNumber: nextProps.details.data.result[0].bank_acc_number,
                        },

                        memberData: nextProps.details.data.loginInfo.row,
                        totalRecords: nextProps.details.data.loginInfo.pagination.total_record,
                        totalPages: nextProps.details.data.loginInfo.pagination.total_page,
                        conflictData: nextProps.details.data.collidedMember,
                    });
                }
            }
            else {
                this.setState({
                    loading: true,
                    fees: 0.0,
                    remark: null,
                    details: {},
                    id: null,
                    rejectReason: [],
                    bankAccList: [],
                    formFilter: initialFormInputState,
                    memberData: [],
                    totalRecords: 0,
                    totalPages: 0,
                    conflictData: [],
                }, () => {
                    filterErrorHandler(nextProps.details.message);
                });
            }
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
            // console.log('shouldComponentUpdate (update):', this.state.act);
            let success = nextProps.update.responseCode === 200 && nextProps.update.msgType === "success" ? true : false;
            if (nextProps.update.responseCode === 200 && nextProps.update.msgType === "success") {
                Swal.fire({
                    icon: "success",
                    title: t("global.success"),
                    html: t("global.record-updated"),
                    showConfirmButton: true,
                }).then(() => {
                    this.props.refreshHandler();
                    const listingArr = this.getFilterArray();
                    this.state.act === "confirm" || this.state.act === "approve" ? this.props.changePageHandler("listing") : this.props.funcGetDetails(listingArr);
                });
            } else {
                Swal.close();
            }
            this.setState({
                touched: success ? false : true,
                errMsg: success ? [] : nextProps.update.message,
            });
        }

        return true;
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                memberData: [],
                conflictData: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.funcGetDetails(listingArr);
            }
        );
    };

    showHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            memberShowId: id,
            pageShow: "show",
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        listingArr.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.id = this.props.detailsParam.id;
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    };

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter['bankID'] = (event.target.id === 'bank_name') ? event.target.value : this.state.formFilter.bankID;
        newFilter['bankAccID'] = this.state.formFilter.bankAccID;
        newFilter['bankAccNumber'] = this.state.formFilter.bankAccNumber;

        if (event.target.id === 'bank_name') {
            newFilter['bankAccID'] = this.props.bankAccountList[newFilter['bankID']] ? this.props.bankAccountList[newFilter['bankID']][0].id : '';
            newFilter['bankAccNumber'] = this.props.bankAccountList[newFilter['bankID']] ? this.props.bankAccountList[newFilter['bankID']][0].bank_acc_number : '';

            let bankAccList = [];

            if (this.props.bankAccountList[newFilter['bankID']]) {
                this.props.bankAccountList[newFilter['bankID']].forEach((val) => {
                    bankAccList[val.id] = val;
                });
            }

            this.setState({
                bankAccList: bankAccList,
            });
        } else if (event.target.id === 'bank_acc_name') {
            newFilter['bankAccNumber'] = this.state.bankAccList ? this.state.bankAccList[event.target.value].bank_acc_number : '';
            newFilter['bankAccID'] = event.target.value;
        }

        this.setState({
            [event.target.id]: event.target.value,
            formFilter: newFilter
        });
    };

    submitHandler = (act) => {
        // console.log('submitHandler :', act);
        const { t } = this.props;
        let rejectReason = {};
        this.state.rejectReason.forEach((val) => {
            rejectReason[val.id] = val.title;
        });

        if (!this.state.formFilter.bankAccID || this.state.formFilter.bankAccID === "") {
            Swal.fire({
                title: t('global.error'),
                icon: 'warning',
                text: t('deposit.please-select-bank-account')
            });
        } else {
            Swal.fire({
                title: act === "reject" ? t("validation.reject-reason") : t("validation.confirmation"),
                text: act === "reject" ? "" : t("validation.confirmation_check"),
                input: act === "reject" ? "select" : "",
                inputOptions: rejectReason,
                bankAccID: this.state.formFilter.bankAccID,
                preConfirm: (value) => {
                    if (rejectReason[value]) {
                        this.setState({
                            remark: rejectReason[value] || "",
                        });
                    }
                },
                icon: "warning",
                confirmButtonText: t("global.save"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        act: act,
                    });
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.id = this.state.id;
                    data.fees = this.state.fees;
                    data.remark = this.state.remark;
                    data.act = act;
                    data.bankAccID = this.state.formFilter.bankAccID;
                    data.lang_id = this.props.currentUser.language_id;
                    // console.log(data);
                    this.props.funcUpdateTran(data);
                }
            });
        }
    };

    render() {
        // console.log('render :', this.state.conflictData);
        const { t } = this.props;
        let rsDetails = this.state.details[0] ? this.state.details[0] : [];
        let stCls = { 0: "primary", 1: "success", 2: "danger", 3: "secondary" };
        let btnPrimary = { 0: t("global.acknowledge"), 1: t("global.save"), 2: t("global.save"), 3: t("global.confirm") };
        let actVal = { 0: "acknowledge", 1: "save", 2: "save", 3: "confirm" };
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];
        let collidedDataListing = [];

        let bankArr = [];
        if (this.props.bankList) {
            this.props.bankList.forEach((val, idx) => {
                bankArr.push({
                    id: val.id,
                    bankName: val.bank_name,
                });
            })
        }

        const collidedColumns = [
            { text: t("global.no"), dataField: "collidedNo", headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("member.ip-address"), dataField: "ipAddress" },
            { text: t("member.device-type"), dataField: "deviceType" },
            { text: t("member.login-time"), dataField: "loginTime" },
            { text: t("member.ip-collided-member"), dataField: "collided_member" },
        ];

        if (this.state.memberData) {
            let memberData = this.state.memberData;
            let conflictData = this.state.conflictData;

            const uniqueIPArray = [...new Map(memberData.map((item) => [item["ipAddress"], item])).values()];

            uniqueIPArray.forEach((val, idx) => {
                uniqueIPArray[idx]["collidedMember"] = [];
                conflictData.forEach((cVal) => {
                    // if (val.ipAddress !== cVal.collidedIPAddress) {
                    uniqueIPArray[idx]["collidedMember"].push({ collidedId: cVal.collidedId, collidedEmail: cVal.collidedEmail });
                    // }
                });
            });

            let uniqueIPArrayLength = uniqueIPArray.length;
            collidedDataListing = uniqueIPArray.map((val, idx) => {
                let collided_val = val.collidedMember.map((val, idx) => {
                    return (
                        <span key={idx} onClick={(event) => this.showHandler(event, val.collidedId)} className="badge badge-pill badge-primary ml-1 mb-1 badgeBtn">
                            {val.collidedEmail}
                        </span>
                    );
                });

                let rowNo = uniqueIPArrayLength - idx;

                return {
                    collidedNo: rowNo,
                    ipAddress: val.ipAddress,
                    deviceType: val.deviceType,
                    loginTime: val.loginTime,
                    collided_member: collided_val,
                };
            });
        }

        let bankNameOpt, bankAccOpt = '';
        if (rsDetails.status === 0) { // if status = pending
            bankNameOpt = <Select
                className="form-control"
                id="bank_name"
                getOptionValue='id'
                getOptionLabel='bank_name'
                options={this.props.bankList}
                onChange={this.onInputChange}
                value={this.state.formFilter.bankID}
            />;

            bankAccOpt = <Select
                className="form-control"
                id="bank_acc_name"
                getOptionValue='id'
                getOptionLabel='bank_acc_name'
                options={this.state.bankAccList}
                onChange={this.onInputChange}
                value={this.state.formFilter.bankAccID}
            />;
        } else {
            bankNameOpt = <input
                className="form-control"
                type="text"
                name="bank_name"
                id="bank_name"
                value={rsDetails.bank_name || ""}
                disabled={true}
            />;

            bankAccOpt = <input
                className="form-control"
                type="text"
                name="bank_account_name"
                id="bank_account_name"
                value={rsDetails.bank_acc_name || ""}
                disabled={true}
            />;
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "details" && (
                    <>
                        {this.state.isOpen && <Lightbox mainSrc={this.state.currentImage} onCloseRequest={() => this.setState({ isOpen: false })} />}
                        <div className="breadcrumb-header">
                            <div className="d-flex">
                                <div className="my-auto">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-back-outline"
                                        idValue="back"
                                        buttonName={<i className="angle fe fe-chevron-left"></i>}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="content-title mb-0 my-auto ml-2">{t("deposit.title")}</h4>
                                </div>
                            </div>
                        </div>
                        <div id="transaction_details">
                            <ValidationError formName="deposit_form" touched={this.state.touched} message={this.state.errMsg} />
                            <div className="row row-sm">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="card card-primary">
                                        <div className="card-header pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title mg-b-0">{t("deposit.deposit-details")}</h4>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="card-body">
                                            {this.state.loading ? (
                                                <Skeleton count={3} />
                                            ) : (
                                                <>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.order-id")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="order_id"
                                                                id="order_id"
                                                                value={rsDetails.order_id || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.member")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="member_id"
                                                                id="member_id"
                                                                value={rsDetails.email || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.transaction-date")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="transaction_date"
                                                                id="transaction_date"
                                                                value={rsDetails.create_date || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.amount")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="amount"
                                                                id="amount"
                                                                value={new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                    rsDetails.credit || 0
                                                                )}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.transaction-channel")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="transaction_channel"
                                                                id="transaction_channel"
                                                                value={t(`transaction-channel.${rsDetails.transaction_channel}`) || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.status")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className={`form-control bg-${stCls[rsDetails.status]} text-white`}
                                                                type="text"
                                                                name="status"
                                                                id="status"
                                                                value={rsDetails.status >= 0 && t(`transaction.status-desc.${rsDetails.status}`)}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="card card-primary">
                                        <div className="card-header pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title mg-b-0">{rsDetails.transaction_channel && t(`transaction.${rsDetails.transaction_channel}-details`)}</h4>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="card-body">
                                            {this.state.loading ? (
                                                <Skeleton count={3} />
                                            ) : rsDetails.transaction_channel === "crypto" ? (
                                                <>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        {/* <div className="col-md-2">
                                                            <label>{t("transaction.from-address")}</label>
                                                        </div> */}
                                                        {/* <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="from_address"
                                                                id="from_address"
                                                                value={rsDetails.from_addr || ""}
                                                                disabled={true}
                                                            />
                                                        </div> */}
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.to-address")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="to_address"
                                                                id="to_address"
                                                                value={rsDetails.payment_address || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.transaction-hash")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="tx_hash"
                                                                id="tx_hash"
                                                                value={rsDetails.tx_hash || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.crypto-type")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="crypto_type"
                                                                id="crypto_type"
                                                                value={rsDetails.crypto || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : rsDetails.transaction_channel === "bank-transfer" && rsDetails.reference_code === null ? (
                                                <>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.bank-name")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            {bankNameOpt}
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.bank-account-name")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            {bankAccOpt}
                                                        </div>
                                                    </div>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.bank-account-no")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="bank_account_no"
                                                                value={this.state.formFilter.bankAccNumber || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.upload-receipt")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <img
                                                                alt=""
                                                                style={{ width: "20%", cursor: "pointer" }}
                                                                src={rsDetails.image}
                                                                onClick={() => this.setState({ isOpen: true, currentImage: rsDetails.image })}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="card card-primary">
                                        <div className="card-header pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title mg-b-0">{t("transaction.audit-details")}</h4>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="card-body">
                                            {this.state.loading ? (
                                                <Skeleton count={3} />
                                            ) : (
                                                <>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.update-date")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="update_date"
                                                                id="update_date"
                                                                value={rsDetails.update_date || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.update-by")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="update_by"
                                                                id="update_by"
                                                                value={rsDetails.update_by || ""}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.conflictData.length > 0 && (
                                        <div className="card card-primary">
                                            <div className="card-header pb-0">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="card-title mg-b-0">{t("member.ip-collided-member")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                <div className="table-responsive table-hover datatable">
                                                    <DataTable
                                                        keyField="collidedNo"
                                                        data={collidedDataListing}
                                                        columns={collidedColumns}
                                                        activePage={this.state.activePage}
                                                        limit={this.state.limit}
                                                        totalItems={this.state.totalRecords}
                                                        paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
                                                        sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
                                                        // searchChangeHandler={(val) => this.datatablesChangeHandler(val, "searchValue")}
                                                        // searchValue={this.props.searchValue}
                                                        classValue="dataTable table-bordered text-justify text-center"
                                                        loading={this.state.loading}
                                                        hideSearchBar={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="card card-primary">
                                        <div className="card-header pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title mg-b-0">{t("transaction.others")}</h4>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="card-body">
                                            {this.state.loading ? (
                                                <Skeleton count={3} />
                                            ) : (
                                                <>
                                                    <div className="row row-xs align-items-center mg-b-10 pl-2">
                                                        <div className="col-md-2">
                                                            <label>{t("transaction.fees")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="fees"
                                                                id="fees"
                                                                placeholder="0.00"
                                                                value={this.state.fees > 0 ? this.state.fees : ""}
                                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 pl-5">
                                                            <label>{t("transaction.remark")}</label>
                                                        </div>
                                                        <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                            <textarea
                                                                className="form-control"
                                                                type="text"
                                                                name="remark"
                                                                id="remark"
                                                                maxLength="255"
                                                                rows="3"
                                                                placeholder={t("transaction.remark")}
                                                                value={this.state.remark || ""}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row row-xs text-center align-items-center mg-b-20">
                                        <div className="col-md-12">
                                            {this.state.loading ? (
                                                <Skeleton count={2} />
                                            ) : (
                                                <>
                                                    {permission.includes("deposit_edit") && rsDetails.transaction_channel === "bank-transfer" && rsDetails.reference_code === null && (
                                                        <>
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-main-primary"
                                                                idValue="save"
                                                                buttonName={btnPrimary[rsDetails.status]}
                                                                clicked={() => this.submitHandler(actVal[rsDetails.status])}
                                                            />
                                                            &nbsp;
                                                            {(rsDetails.status === 0 || rsDetails.status === 3) && (
                                                                <Button
                                                                    typeButton="button"
                                                                    classValue="btn btn-danger"
                                                                    idValue="reject"
                                                                    buttonName={t("global.reject")}
                                                                    clicked={() => this.submitHandler("reject")}
                                                                />
                                                            )}
                                                        </>
                                                    )}

                                                    {permission.includes("deposit_edit") && rsDetails.transaction_channel === "quick-pay" && (
                                                        <>
                                                            <Button
                                                                typeButton="button"
                                                                classValue="btn btn-main-primary"
                                                                idValue="save"
                                                                buttonName={rsDetails.status === 0 || rsDetails.status === 3 ? t("global.confirm") : t("global.save")}
                                                                clicked={() => this.submitHandler(rsDetails.status === 0 || rsDetails.status === 3 ? "approve" : "save")}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {this.state.pageShow === "show" && (
                    <MemberShow memberShowId={this.state.memberShowId} changePageHandler={(page) => this.changePageHandler(page)} returnPage="details" fromDisplay="edit" />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        update: state.deposit.update,
        details: state.deposit.details,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcUpdateTran: (data) => dispatch(actions.updateTran(data)),
        funcGetDetails: (filter) => dispatch(actions.getDetails(filter)),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(DepositEdit));
