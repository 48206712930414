import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { update } from "../../../store/ducks/event/qnaQuestion/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import Select from "../../../components/Input/Select/Select";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import ValidationError from "../../../components/ValidationError/ValidationError";

class QNAQuestionEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error_message: [],
            touched: false,
            dropdown: props.dropdown,
            filter: {
                question_id: props.rowData.id,
                en: props.rowData.en,
                cn: props.rowData.cn,
                ms: props.rowData.ms,
                status: props.rowData.status
            }
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        formValidation("question_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.rs !== this.props.rs) {
            if (nextProps.rs.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.rs.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else if (nextProps.rs.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.rs.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    editQuestionHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });     

                let updateData = {};
                updateData.question_id = this.state.filter.question_id;
                updateData.en = this.state.filter.en;
                updateData.cn = this.state.filter.cn;
                updateData.ms = this.state.filter.ms;
                updateData.status = this.state.filter.status;

                this.props.update(updateData);
            }
        });
    };

    onInputChange(event) {        
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("event_qna_question.qna_question")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("event_qna_question.edit_question")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                <form method="post" id="question_form" className="form-horizontal" onSubmit={this.editQuestionHandler} noValidate>
                                    <ValidationError formName="question_form" touched={this.state.touched} message={this.state.error_message} />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("event_qna_question.en")}</label>
                                                <StringInput
                                                    id="en"
                                                    name="en"
                                                    value={this.state.filter.en}
                                                    onChange={this.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("event_qna_question.cn")}</label>
                                                <StringInput
                                                    id="cn"
                                                    name="cn"
                                                    value={this.state.filter.cn}
                                                    onChange={this.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                    required={true}                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">{t("event_qna_question.ms")}</label>
                                                <StringInput
                                                    id="ms"
                                                    name="ms"
                                                    value={this.state.filter.ms}
                                                    onChange={this.onInputChange}
                                                    className="filter-input"
                                                    onSearchPress={(evt) =>
                                                        evt.key === "Enter" &&
                                                        this.props.refreshHandler()
                                                    }
                                                    required={true}                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="team_b">{t("event_qna_question.status")}</label>
                                                <Select
                                                    id="status"
                                                    name="status"
                                                    className="form-control"
                                                    onChange={this.onInputChange}
                                                    options={this.state.dropdown.status}
                                                    emptyValue={true}
                                                    value={this.state.filter.status}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 mt-3 justify-content-end">
                                        <div>
                                            <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rs: state.eventQNAQuestion.update,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (data) => dispatch(update(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(QNAQuestionEdit));
