import React, { Component } from "react";
import { connect } from "react-redux";
import { getOpenGraph, updateOpenGraph } from "../../../store/ducks/seo/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../../components/Input/StringInput/StringInput";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Select from "../../../components/Input/Select/Select";
import Textarea from "../../../components/Input/Textarea/Textarea";
import Button from "../../../components/Button/Button";
import { formValidation } from "../../../hoc/Shared/utility";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../../components/ValidationError/ValidationError";
import Skeleton from "react-loading-skeleton";
// import ImageUpload from "../../../components/Input/ImageUpload/ImageUpload";
import ImageUploader from "react-images-upload";
// import Lightbox from 'react-image-lightbox';

let formData = new FormData();
const getImageUrl = process.env.REACT_APP_API_DEVELOPMENT_URL+"images/seo/og/";

class OpenGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            home_title: "",
            home_site_name: "",
            home_description: "",
            default_image_width: "",
            default_image_height: "",
            facebook_admin_id: "",
            facebook_app_id: "",
            twitter_url: "",
            twitter_card: "",
            twitter_title: "",
            twitter_description: "",
            home_title_chars_left: 80,
            home_description_chars_left: 200,
            twitter_title_chars_left: 80,
            twitter_description_chars_left: 200,

            home_image: [],
            default_image: [],
            twitter_image: [],
            home_image_url: [],
            default_image_url: [],
            twitter_image_url: [],

            loading: true,
            error_message: [],
            touched: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.getOpenGraph();
        formValidation("open_graph_form");
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[OpenGraph] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);

        if (nextProps.openGraphData !== this.props.openGraphData) {
            if (nextProps.openGraphData.responseCode === 200) {
                const openGraphData = nextProps.openGraphData.data.openGraph;

                // let reader = new FileReader();
                // let homeFile = reader.readAsDataURL(getImageUrl+openGraphData.home_image);
                // console.log(homeFile);
                //this.convertImgToBase64(getImageUrl+openGraphData.home_image, (myBase64) => {console.log(myBase64)});
                const homeTitleCharLeft = 80 - (openGraphData.home_title.length === null ? 0 : openGraphData.home_title.length);
                const homeDescriptionCharLeft = 200 - (openGraphData.home_description.length === null ? 0 : openGraphData.home_description.length);
                const twitterTitleCharLeft = 80 - (openGraphData.twitter_title.length === null ? 0 : openGraphData.twitter_title.length);
                const twitterDescriptionCharLeft = 200 - (openGraphData.twitter_description.length === null ? 0 : openGraphData.twitter_description.length);

                this.setState({
                    // home_image: openGraphData.home_image === null ? "" : openGraphData.home_image,
                    home_title: openGraphData.home_title === null ? "" : openGraphData.home_title,
                    home_site_name: openGraphData.home_site_name === null ? "" : openGraphData.home_site_name,
                    home_description: openGraphData.home_description === null ? "" : openGraphData.home_description,
                    // default_image: openGraphData.default_image === null ? "" : openGraphData.default_image,
                    default_image_width: openGraphData.default_image_width === null ? "" : openGraphData.default_image_width,
                    default_image_height: openGraphData.default_image_height === null ? "" : openGraphData.default_image_height,
                    facebook_admin_id: openGraphData.facebook_admin_id === null ? "" : openGraphData.facebook_admin_id,
                    facebook_app_id: openGraphData.facebook_app_id === null ? "" : openGraphData.facebook_app_id,
                    twitter_url: openGraphData.twitter_url === null ? "" : openGraphData.twitter_url,
                    twitter_card: openGraphData.twitter_card === null ? "" : openGraphData.twitter_card,
                    // twitter_image: openGraphData.twitter_image === null ? "" : openGraphData.twitter_image,
                    twitter_title: openGraphData.twitter_title === null ? "" : openGraphData.twitter_title,
                    twitter_description: openGraphData.twitter_description === null ? "" : openGraphData.twitter_description,
                    home_title_chars_left: homeTitleCharLeft,
                    home_description_chars_left: homeDescriptionCharLeft,
                    twitter_title_chars_left: twitterTitleCharLeft,
                    twitter_description_chars_left: twitterDescriptionCharLeft,
                    home_image_url: openGraphData.home_image !== null ? [getImageUrl+openGraphData.home_image] : [],
                    default_image_url: openGraphData.default_image !== null ? [getImageUrl+openGraphData.default_image] : [],
                    twitter_image_url: openGraphData.twitter_image !== null ? [getImageUrl+openGraphData.twitter_image] : [],
                    loading: false,
                });
            }
        }

        if (nextProps.openGraphUpdateResult !== this.props.openGraphUpdateResult) {
            if (nextProps.openGraphUpdateResult.responseCode === 200) {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.openGraphUpdateResult.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.getOpenGraph();
                    }
                });
            } else if (nextProps.openGraphUpdateResult.responseCode === 422) {
                this.setState({
                    touched: true,
                    error_message: nextProps.openGraphUpdateResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        return true;
    }

    // convertImgToBase64 = (url, callback) => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onload = () => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             callback(reader.result);
    //         };
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // }

    updateOpenGraphHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                formData.append("home_image", this.state.home_image);
                formData.append("home_title", this.state.home_title);
                formData.append("home_site_name", this.state.home_site_name);
                formData.append("home_description", this.state.home_description);
                formData.append("default_image", this.state.default_image);
                formData.append("default_image_width", this.state.default_image_width);
                formData.append("default_image_height", this.state.default_image_height);
                formData.append("facebook_app_id", this.state.facebook_app_id);
                formData.append("facebook_admin_id", this.state.facebook_admin_id);
                formData.append("twitter_url", this.state.twitter_url);
                formData.append("twitter_card", this.state.twitter_card);
                formData.append("twitter_image", this.state.twitter_image);
                formData.append("twitter_title", this.state.twitter_title);
                formData.append("twitter_description", this.state.twitter_description);
                formData.append("home_image_url", this.state.home_image_url);
                formData.append("default_image_url", this.state.default_image_url);
                formData.append("twitter_image_url", this.state.twitter_image_url);
                // console.log("Form Submit:", formData.get("home_image"));

                this.props.updateOpenGraph(formData);
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleHomeTitleChange = (event) => {
        let char_left = 80;
        this.setState({
            home_title: event.target.value,
            home_title_chars_left: char_left - event.target.value.length,
        });
    };

    handleHomeDescriptionChange = (event) => {
        let char_left = 200;
        this.setState({
            home_description: event.target.value,
            home_description_chars_left: char_left - event.target.value.length,
        });
    };

    handleTwitterTitleChange = (event) => {
        let char_left = 80;
        this.setState({
            twitter_title: event.target.value,
            twitter_title_chars_left: char_left - event.target.value.length,
        });
    };

    handleTwitterDescriptionChange = (event) => {
        let char_left = 200;
        this.setState({
            twitter_description: event.target.value,
            twitter_description_chars_left: char_left - event.target.value.length,
        });
    };

    handleHomeImageChange = (event, pictureDataURLs) => {
        const file = event[0];
        console.log(file);
        if (file) {
            this.setState({
                home_image: file,
                home_image_url: [getImageUrl+file.name],
            });
        } else {
            this.setState({
                home_image: [],
                home_image_url: [],
            });
        }
    };

    handleDefaultImageChange = (event, pictureDataURLs) => {
        const file = event[0];
        if (file) {
            this.setState({
                default_image: file,
                default_image_url: [getImageUrl+file.name],
            });
        } else {
            this.setState({
                default_image: [],
                default_image_url: [],
            });
        }
    };

    handleTwitterImageChange = (event, pictureDataURLs) => {
        const file = event[0];
        if (file) {
            this.setState({
                twitter_image: file,
                twitter_image_url: [getImageUrl+file.name],
            });
        } else {
            this.setState({
                twitter_image: [],
                twitter_image_url: [],
            });
        }
    };

    // handleHomeImageChange = (event, pictureDataURLs) => {
    //     // const reader = new FileReader();
    //     const file = event[0];

    //     // reader.onloadend = () => {
    //     //     this.setState({ home_image: this.state.home_image.concat(reader.result)});
    //     // }
    //     if(file) {
    //         // reader.readAsDataURL(file);
    //         //formData.append("home_image", pictureDataURLs[0]);
    //         this.setState({
    //             home_image: pictureDataURLs[0],
    //             home_image_url: frontEndUrl+file.name
    //         });
    //     } else {
    //         this.setState({home_image_url: ""});
    //     }
    //     // console.log(this.state.home_image);
    //     // console.log(file);
    //     // console.log(pictureDataURLs);
    //     //console.log(event.target.name, image, image.name)
    //     // formData.append("home_image", pictureDataURLs[0]);
    //     // formData.append("home_image_url", pictureFiles[0].name);
    // };

    // homeImageOnDrop(pictureFiles, pictureDataURLs) {
    //     console.log(pictureFiles,pictureDataURLs);
    //     var home_image = this.state.home_image;
    //     console.log(home_image);
    //     home_image = home_image.concat(pictureFiles);
    //     this.setState({
    //          home_image
    //     });
    // }

    render() {
        const { t } = this.props;
        let display = null;
        if (!this.state.loading) {
            let twiiter_default_card = [
                { label: "Summary", value: "summary" },
                { label: "Summary Large Image", value: "summary_large_image" },
            ];
            display = (
                <>
                    <form method="post" id="open_graph_form" className="form-horizontal" onSubmit={this.updateOpenGraphHandler} noValidate>
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                                </div>
                            </div>
                            <div className="d-flex my-xl-auto right-content">
                                <div className="mb-3 mb-xl-0">
                                    <div className="btn-group">
                                        <Button typeButton="submit" classValue="btn btn-primary" idValue="submit" buttonName={t("global.update")} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row row-sm">
                            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                <div className="card box-shadow-0">
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">{t("seo.open-graph")}</h4>
                                    </div>

                                    <div className="card-body pt-0">
                                        <ValidationError formName="open_graph_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="bd bd-gray-200 bd-2 rounded-10 p-3">
                                            <h6 className="mb-3">{t("open-graph.home-page-settings")}</h6>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="home_site_name">{t("open-graph.site-name")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="home_site_name"
                                                        name="home_site_name"
                                                        className="form-control"
                                                        value={this.state.home_site_name}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="home_title">{t("open-graph.home-title")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="home_title"
                                                        name="home_title"
                                                        className="form-control"
                                                        value={this.state.home_title}
                                                        onChange={this.handleHomeTitleChange}
                                                        maxLength={80}
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <div className="tags">
                                                        <span className="tag tag-blue">
                                                            {this.state.home_title_chars_left} {t("open-graph.characters-left")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="home_description">{t("open-graph.home-description")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <Textarea
                                                        id="home_description"
                                                        name="home_description"
                                                        className="form-control"
                                                        value={this.state.home_description}
                                                        onChange={this.handleHomeDescriptionChange}
                                                        maxLength={200}
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <div className="tags">
                                                        <span className="tag tag-blue">
                                                            {this.state.home_description_chars_left} {t("open-graph.characters-left")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="home_image">{t("open-graph.home-image")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <ImageUploader
                                                        name="home_image"
                                                        withIcon={true}
                                                        buttonText="Choose Images"
                                                        onChange={this.handleHomeImageChange}
                                                        imgExtension={[".jpg", ".gif", ".png"]}
                                                        maxFileSize={5242880}
                                                        singleImage={true}
                                                        withPreview={true}
                                                        defaultImages={this.state.home_image_url}
                                                        className="openGraphImageContainer"
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <label htmlFor="home_image_url">{t("open-graph.image-url")}</label>
                                                    <StringInput
                                                        id="home_image_url"
                                                        name="home_image_url"
                                                        className="form-control"
                                                        value={String(this.state.home_image_url)}
                                                        onChange={this.handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bd bd-gray-200 bd-2 rounded-10 p-3 mt-3">
                                            <h6 className="mb-3">{t("open-graph.image-settings")}</h6>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="default_image">{t("open-graph.default-image")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <ImageUploader
                                                        name="default_image"
                                                        withIcon={true}
                                                        buttonText="Choose Images"
                                                        onChange={this.handleDefaultImageChange}
                                                        imgExtension={[".jpg", ".gif", ".png"]}
                                                        maxFileSize={5242880}
                                                        singleImage={true}
                                                        withPreview={true}
                                                        defaultImages={this.state.default_image_url}
                                                        className="openGraphImageContainer"
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <label htmlFor="default_image_url">{t("open-graph.image-url")}</label>
                                                    <StringInput
                                                        id="default_image_url"
                                                        name="default_image_url"
                                                        className="form-control"
                                                        value={String(this.state.default_image_url)}
                                                        onChange={this.handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="default_image_width">{t("open-graph.default-image-width")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <NumberInput
                                                        id="default_image_width"
                                                        name="default_image_width"
                                                        className="form-control"
                                                        value={this.state.default_image_width}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="default_image_height">{t("open-graph.default-image-height")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <NumberInput
                                                        id="default_image_height"
                                                        name="default_image_height"
                                                        className="form-control"
                                                        value={this.state.default_image_height}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bd bd-gray-200 bd-2 rounded-10 p-3 mt-3">
                                            <h6 className="mb-3">{t("open-graph.facebook-settings")}</h6>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="facebook_admin_id">{t("open-graph.facebook-admin-id")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="facebook_admin_id"
                                                        name="facebook_admin_id"
                                                        className="form-control"
                                                        value={this.state.facebook_admin_id}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="facebook_app_id">{t("open-graph.facebook-app-id")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="facebook_app_id"
                                                        name="facebook_app_id"
                                                        className="form-control"
                                                        value={this.state.facebook_app_id}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bd bd-gray-200 bd-2 rounded-10 p-3 mt-3">
                                            <h6 className="mb-3">{t("open-graph.twitter-settings")}</h6>
                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="twitter_card">{t("open-graph.default-twitter-card")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <Select
                                                        id="twitter_card"
                                                        name="twitter_card"
                                                        options={twiiter_default_card}
                                                        value={this.state.twitter_card}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="twitter_title">{t("open-graph.twitter-title")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="twitter_title"
                                                        name="twitter_title"
                                                        className="form-control"
                                                        value={this.state.twitter_title}
                                                        onChange={this.handleTwitterTitleChange}
                                                        maxLength={80}
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <div className="tags">
                                                        <span className="tag tag-blue">
                                                            {this.state.twitter_title_chars_left} {t("open-graph.characters-left")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="twitter_description">{t("open-graph.twitter-description")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <Textarea
                                                        id="twitter_description"
                                                        name="twitter_description"
                                                        className="form-control"
                                                        value={this.state.twitter_description}
                                                        onChange={this.handleTwitterDescriptionChange}
                                                        maxLength={200}
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <div className="tags">
                                                        <span className="tag tag-blue">
                                                            {this.state.twitter_description_chars_left} {t("open-graph.characters-left")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="twitter_url">{t("open-graph.twitter-url")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <StringInput
                                                        id="twitter_url"
                                                        name="twitter_url"
                                                        className="form-control"
                                                        value={this.state.twitter_url}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row row-xs align-items-center mg-b-20">
                                                <div className="col-md-2">
                                                    <label htmlFor="twitter_image">{t("open-graph.twitter-image")}</label>
                                                </div>
                                                <div className="col-md-5 mg-t-5 mg-md-t-0">
                                                    <ImageUploader
                                                        name="twitter_image"
                                                        withIcon={true}
                                                        buttonText="Choose Images"
                                                        onChange={this.handleTwitterImageChange}
                                                        imgExtension={[".jpg", ".gif", ".png"]}
                                                        maxFileSize={5242880}
                                                        singleImage={true}
                                                        withPreview={true}
                                                        defaultImages={this.state.twitter_image_url}
                                                        className="openGraphImageContainer"
                                                    />
                                                </div>
                                                <div className="col-md-4 mg-t-5 mg-md-t-0">
                                                    <label htmlFor="twitter_image_url">{t("open-graph.image-url")}</label>
                                                    <StringInput
                                                        id="twitter_image_url"
                                                        name="twitter_image_url"
                                                        className="form-control"
                                                        value={String(this.state.twitter_image_url)}
                                                        onChange={this.handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            );
        } else {
            display = (
                <>
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div className="card box-shadow-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-1">{t("seo.open-graph")}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Skeleton count={4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    return {
        openGraphData: state.seo.open_graph_data,
        openGraphUpdateResult: state.seo.open_graph_update_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOpenGraph: () => dispatch(getOpenGraph()),
        updateOpenGraph: (data) => dispatch(updateOpenGraph(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(OpenGraph));
