import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import { getMatchListing, getOptionData, updateStatus } from "../../../store/ducks/event/match/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import EventMatchListing from "./EventMatchListing";
import EventMatchCreate from "./EventMatchCreate";
import EventMatchEdit from "./EventMatchEdit";
import EventMatchUpdateScore from "./EventMatchUpdateScore";
import Swal from "sweetalert2";
import moment from "moment";
import Button from "../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faScroll } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../components/Input/Select/Select";

const initialInputState =
{
    /* form input value */
    league: '',
    team: '',
    match_date: ''
};

class EventMatch extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        // const { t } = this.props;

        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            page: 1,
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: null,
            totalPages: 0,
            activePage: 1,
            totalRecords: 0,
            searchValue: "",
            search: "",

            /* API result array */
            data: [],

            /* popup box */
            popupLoading: false,

            settingID: null,
            loading: true,

            permission: [],
            filter: initialInputState,
            dropdown: {
                league: [],
                team: [],
                leagueTeam: [],
                status: [],
                matchSlug: []
            }
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getMatchListing(listingArr);
        this.props.getOptionData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            this.setState({ permission: this.props.currentPermission });
        }

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined && nextProps.rs.status === 200) {
            let rowDataArray = [];
            nextProps.rs.data.data.result.row.map((val) => {
                rowDataArray[val.id] = val;
                return null;
            });

            this.setState({
                data: nextProps.rs.data.data.result.row,
                totalRecords: nextProps.rs.data.data.result.pagination.total_record,
                totalPages: nextProps.rs.data.data.result.pagination.total_page,
                rowDataArray: rowDataArray,
                loading: false
            });
        }
        
        if (nextProps.dropdown !== this.props.dropdown && nextProps.dropdown !== undefined && nextProps.dropdown.status === 200) {
            let leagueOption = [];
            let teamOption = [];
            let leagueTeamOption = [];
            let matchSlugOption = [];

            if (nextProps.dropdown.data.data.league !== undefined) {
                nextProps.dropdown.data.data.league.map((val) => {
                    leagueOption.push({
                        label: t('world_cup.'+val),
                        value: val
                    });
                    return null;
                });
            }
            
            if (nextProps.dropdown.data.data.team !== undefined) {
                nextProps.dropdown.data.data.team.map((val) => {
                    teamOption.push({
                        label: t('world_cup.'+val),
                        value: val
                    });
                    return null;
                });
            }

            if (nextProps.dropdown.data.data.leagueTeam !== undefined) {
                nextProps.dropdown.data.data.leagueTeam.map((val) => {
                    const league = val.league;

                    if (leagueTeamOption[league] === undefined) {
                        leagueTeamOption[league] = [];
                    }

                    leagueTeamOption[league].push({
                        label: t('world_cup.'+val.team),
                        value: val.team
                    });
                    return null;
                });
            }

            if (nextProps.dropdown.data.data.matchSlug !== undefined) {
                nextProps.dropdown.data.data.matchSlug.map((val) => {
                    const matchLeague = val.league

                    if (matchSlugOption[matchLeague] === undefined) {
                        matchSlugOption[matchLeague] = [];
                    }

                    val.match.map((slug) => {
                        matchSlugOption[matchLeague].push({
                            label: t('world_cup.'+slug),
                            value: slug
                        });
                        return null;
                    });
                    return null;
                });
            }
            
            let statusDropdown = [];
            statusDropdown.push({label: t('global.active'),value: "1"});
            statusDropdown.push({label: t('global.inactive'),value: "0"});

            this.setState({
                dropdown: {
                    league: leagueOption,
                    team: teamOption,
                    leagueTeam: leagueTeamOption,
                    status: statusDropdown,
                    matchSlug: matchSlugOption
                },
                loading: false,
            });
            
        }

        if (nextProps.update_status !== this.props.update_status && nextProps.update_status !== undefined) {
            if (nextProps.update_status.responseCode === 200 && nextProps.update_status.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    const listingArr = this.getFilterArray();
                    this.props.getMatchListing(listingArr);
                });
            } else {
                let msg = null;
                msg = '<ul>';
                nextProps.update_status.message.forEach((val) => {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                });
            }
        }

        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */
    onInputChange(event) {
        // const { t } = this.props;
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }

        if (event.target.name === 'league') {
            let newDropdown = Object.assign({}, this.state.dropdown);
            if (event.target.value !== '') {
                newDropdown['team'] = this.state.dropdown.leagueTeam[event.target.value];
            } else {
                newDropdown['team'] = [];
                newFilter['team'] = '';
            }

            this.setState({
                dropdown: newDropdown
            });
        }

        this.setState({
            filter: newFilter
        });
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            searchValue: '',
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getMatchListing(listingArr);
        });
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
        }, ()=> {
            const listingArr = this.getFilterArray();
            this.props.getMatchListing(listingArr);
        });
    }

    editHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            rowData: this.state.rowDataArray[id],
            pageShow: "edit",
        });
    };

    updateScoreHandler = (e, id) => {
        e.preventDefault();
        this.setState({
            rowData: this.state.rowDataArray[id],
            pageShow: "updateScore",
        });
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });

        return listingArr;
    }

    updateStatusHandler = (event, matchID) => {
        event.preventDefault();

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        this.setState({ [event.target.name]: event.target.value });
        const data = {};
        data.matchID = matchID;
        data.status = Number(event.target.value);
        this.props.updateStatus(data);
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                [act]: act === "searchValue" ? val.target.value : val,
                loading: true,
                data: [],
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMatchListing(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState(
            {
                order: orderArry,
                loading: true,
                data: [],
                // errMsg: [],
                // touched: false,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getMatchListing(listingArr);
            }
        );
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, updateScoreButton, output;
        if (this.state.permission.includes("match_event_update") && row.status < 2) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.match_id}`}
                    idValue={row.match_id === undefined ? 0 : row.match_id}
                    clicked={(event) => this.editHandler(event, row.match_id)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        if (this.state.permission.includes("match_event_update_score") && row.status === 1) {
            updateScoreButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.match_id}`}
                    idValue={row.match_id === undefined ? 0 : row.match_id}
                    clicked={(event) => this.updateScoreHandler(event, row.match_id)}
                    buttonName={<FontAwesomeIcon icon={faScroll} />}
                />
            );
        }

        output = [editButton, updateScoreButton];
        return output;
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("match_event_update")) {
            let statusArray, dtClassName;
            // let disabled = false;
            statusArray = [
                { label: t("global.active"), value: 1, selected: true },
                { label: t("global.inactive"), value: 0 },
            ];

            if (row.status === 2) {
                return(
                    <span>{t("event_match.ended")}</span>
                );
            } else {
                if (row.status === 0) {
                    dtClassName = "form-control datatable-select-inactive";
                } else {
                    dtClassName = "form-control datatable-select-active";
                }
    
                return (
                    <Select
                        id="status"
                        name="status"
                        options={statusArray}
                        defaultValue={row.status}
                        onChange={(event) => {
                            this.updateStatusHandler(event, row.match_id);
                        }}
                        className={dtClassName}
                    />
                );
            }
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            const listingArr = this.getFilterArray();
            this.props.getMatchListing(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                const listingArr = this.getFilterArray();
                this.props.getMatchListing(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <EventMatchListing
                        filter={this.state.filter}
                        onInputChange={(event) => this.onInputChange(event)}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={this.clearHandler}
                        refreshHandler={this.refreshHandler}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                        dropdown={this.state.dropdown}
                        onSort={this.onSort}

                        noFormatter={this.noFormatter}
                        actionFormatter={this.actionFormatter}
                        statusFormatter={this.statusFormatter}
                    />
                )}

                {this.state.permission.includes("match_event_create") && this.state.pageShow === "create" && (
                    <EventMatchCreate
                        filter={this.state.filter}                    
                        dropdown={this.state.dropdown}
                        popupLoading={this.state.popupLoading}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                    />
                )}

                {this.state.permission.includes("match_event_update") && this.state.pageShow === "edit" && (
                    <EventMatchEdit
                        filter={this.state.filter}                        
                        dropdown={this.state.dropdown}
                        rowData={this.state.rowData}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                    />
                )}

                {this.state.permission.includes("match_event_update_score") && this.state.pageShow === "updateScore" && (
                    <EventMatchUpdateScore
                        filter={this.state.filter}
                        rowData={this.state.rowData}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                    />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        rs: state.eventMatch.rs,
        dropdown: state.eventMatch.option_data,
        update_status: state.eventMatch.update_status,
        currentPermission: state.currentUser.data.permission,
        currentUser: state.currentUser.data,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getMatchListing: (data) => dispatch(getMatchListing(data)),
        getOptionData: (data) => dispatch(getOptionData(data)),
        updateStatus: (data) => dispatch(updateStatus(data))
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EventMatch));
