import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Lightbox from "react-image-lightbox";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import BusinessAccountCreate from "./BusinessAccountCreate";
import BusinessAccountListing from "./BusinessAccountListing";
import BusinessAccountEdit from "./BusinessAccountEdit";
// import PromotedVideoShow from "./PromotedVideoShow";

import { getBusinessAccount } from "../../store/ducks/businessAccount/actions";
import { getDropDown } from "../../store/ducks/banks/actions";

const initialInputState = {
    /* form input value */
    username: "",
    status: "",
    companyName: "",
    companyRegNo: "",
    bankAccNumber: "",
    bankName: "",
    business_account_date: '',
    start_date: '',
    end_date: '',
};

let businessAccountData = {};
class BusinessAccount extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",
        isOpen: false,

        data: [],

        dropDown: [],

        filter: initialInputState,

        currentBusinessAccountId: "",
        loading: true,
        currentImage: "",

        permission: [],
    };

    fetchData() {
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            businessAccountData[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format("YYYY-MM-DD HH:mm:ss") : this.state.filter[val];
        });
        businessAccountData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        businessAccountData.limit = this.state.limit;
        businessAccountData.order = JSON.stringify(this.state.sorted);
        businessAccountData.lang_id = this.props.currentLanguageId;
        businessAccountData.title = this.state.filter.title;
        businessAccountData.status = this.state.filter.status;
        businessAccountData.country_id = this.props.currentCountryId;

        this.props.getBusinessAccount(businessAccountData);
    }

    componentDidMount() {
        this.props.getDropDown();
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let promotionPermission = this.props.currentPermission.filter((item) => item.includes("business_account_"));
            this.setState({ permission: promotionPermission });
        }

        if (nextProps.business_account_data !== this.props.business_account_data) {
            // console.log(nextProps.promotion_data.data.data);

            if (nextProps.business_account_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.business_account_data.data.data,
                    totalRecords: nextProps.business_account_data.data.totalRecords,
                    totalPages: nextProps.business_account_data.data.totalPages,
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.business_account_data.data.message[0],
                    icon: nextProps.business_account_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown) {
            if (nextProps.dropDown.responseCode === 200) {
                this.setState({
                    dropDown: nextProps.dropDown.data,
                });
            } else {
                this.setState({ loading: false });
            }
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    updateStatusPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updatePromotionStatusData = {};
        updatePromotionStatusData.video_id = promotionId;
        updatePromotionStatusData.status = Number(event.target.value);

        this.props.updatePromotedVideoStatus(updatePromotionStatusData);
    };

    updateShowPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });
        const updatePromotionShowData = {};
        updatePromotionShowData.promotion_id = promotionId;
        updatePromotionShowData.show = Number(event.target.value);

        this.props.updatePromotionShow(updatePromotionShowData);
    };

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        if(event.target.id==='business_account_date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(" - ") : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const payload = {
                    ...businessAccountData,
                    ...this.state.filter,
                };
                this.fetchData(payload);
            }
        );
    };

    clearHandler = () => {
        this.setState(
            {
                activePage: 1,
                filter: initialInputState,
                loading: true,
                data: [],
            },
            () => {
                let payload = {
                    ...businessAccountData,
                    page: 1,
                    ...this.state.filter,
                };
                this.props.getBusinessAccount(payload);
            }
        );
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        let badgeClassName, status;

        if (row.status === 2) {
            badgeClassName = "badge badge-pill badge-danger";
            status = t("transaction.rejected");
        } if (row.status === 1) {
            badgeClassName = "badge badge-pill badge-success";
            status = t("global.approve");
        }else if(row.status === 0) {
            badgeClassName = "badge badge-pill badge-primary";
            status = t("transaction.new-request");
        }
        return <span className={badgeClassName}>{status}</span>;
    };

    actionFormatter = (cell, row) => {
        let editButton, output;

        if (this.state.permission.includes("business_account_edit")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.business_account_id}`}
                    clicked={() => this.changePageHandler("edit", row.business_account_id)}
                />
            );
        }

        output = [editButton];
        return output;
    };

    changePageHandler = (page, businessAccountId) => {
        this.setState({
            pageShow: page,
            currentBusinessAccountId: businessAccountId,
        });
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    videoFormatter = (cell, row) => {
        return <video src={cell} controls width="400" key={`desktop-image-${row.video_id}`}></video>;
    };

    businessAccountImageFormatter = (cell, row) => {
        return (
            <img
                alt=""
                // style={{ width: "170px", cursor: "pointer" }}
                className="tb-img"
                src={cell}
                onClick={() => this.setState({ isOpen: true, currentImage: cell })}
                key={`desktop-image-${row.video_id}`}
            />
        );
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "business_account_id",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let reverseRowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
                    return <span>{reverseRowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: t("business-account.date"),
                dataField: "updated_at",
                keyField: "date",
                sort: true,
                onSort: this.onSort,
                // hidden: true
            },
            {
                text: t("business-account.username"),
                dataField: "username",
                keyField: "username",
                sort: true,
                onSort: this.onSort,
                // hidden: true
            },
            {
                text: t("business-account.company-name"),
                dataField: "company_name",
                keyField: "companyName",
                sort: true,
                onSort: this.onSort,
                // hidden: true
            },
            {
                text: t("business-account.company-reg-no"),
                dataField: "company_reg_no",
                keyField: "companyRegNo",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("business-account.bank-name"),
                dataField: "bank_name",
                keyField: "bankName",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("business-account.bank-account-number"),
                dataField: "bank_account_number",
                keyField: "bankAccountNumber",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("business-account.ssm-image"),
                dataField: "ssm_image",
                keyField: "ssm-image",
                formatter: this.businessAccountImageFormatter,
            },
            {
                text: t("global.status"),
                dataField: "status",
                keyField: "Status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return { width: "115px" };
                },
            },
            {
                text: t("global.action"),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return { width: "115px" };
                },
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        {this.state.isOpen && <Lightbox mainSrc={this.state.currentImage} onCloseRequest={() => this.setState({ isOpen: false })} />}
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("business-account.business-account")}</h4>
                                </div>
                            </div>
                        </div>

                        <BusinessAccountListing
                            bankName={this.state.filter.bankName}
                            title={this.state.filter.title}
                            status={this.state.filter.status}
                            username={this.state.filter.username}
                            companyName={this.state.filter.companyName}
                            companyRegNo={this.state.filter.companyRegNo}
                            bankAccNumber={this.state.filter.bankAccNumber}
                            business_account_date={this.state.filter.business_account_date}
                            category={this.state.filter.category}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            ddBankName={this.state.dropDown.bank_name}
                            changePageHandler={(page) => this.changePageHandler(page)}
                        />
                    </div>
                )}

                {this.state.pageShow === "create" && (
                    <BusinessAccountCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {/* {this.state.pageShow === "show" && (
                    <PromotedVideoShow show_video_id={this.state.currentPromotedVideoId} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
                 */}

                {this.state.pageShow === "edit" && (
                    <BusinessAccountEdit
                        show_business_account_id={this.state.currentBusinessAccountId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )} 
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { currentUser, businessAccount, bank } = state;

    return {
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        currentPermission: currentUser.data.permission,
        business_account_data: businessAccount.business_account_data,
        dropDown: bank.dropDown,
    };
};

const mapDispatchToProps = {
    getBusinessAccount,
    getDropDown
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BusinessAccount));
