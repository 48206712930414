import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getMemberGroup = (data) => dispatch => {
    axios.get('member_group/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => (dispatch, getState) =>
{
    let data = {};
    data.country_id = getState().currentUser.data.country_id;
    axios.get('member_group/drop_down', {params: data})
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const updateMemberGroup = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('member_group/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_MEMBER_GROUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const postMemberGroup = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('member_group/store', data)
        .then( (response) => {
            dispatch ({
                type: type.CREATE_MEMBER_GROUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showMemberGroup = (data) => (dispatch, getState) => {
    axios.get('member_group/show', {
        params: {
            "id": data['member_group_id'],
            "setting_id": data['member_group_setting_id'],
            "country_id": getState().currentUser.data.country_id
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_MEMBER_GROUP,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
}

export const updateMemberGroupStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('member_group/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_MEMBER_GROUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteMemberGroup = (data) => dispatch => {
    axios.post('member_group/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_MEMBER_GROUP,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};