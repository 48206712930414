import axios from "../../../common/config/axios";
import * as type from "./types";



export const getEmailContents = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("email_content/list?" + params)
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Email Content Error" + err);
            dispatch({
                type: type.EMAIL_CONTENT_LIST,
                payload: [],
            });
        });
};

export const getFilterData = () => (dispatch, getState) => {
    axios
        .get("email_content/get_filter_data", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.EMAIl_CONTENT_GET_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Error" + err);
            dispatch({
                type: type.EMAIl_CONTENT_GET_FILTER_DATA,
                payload: [],
            });
        });
};

export const createEmailContent = () => (dispatch, getState) => {
    axios
        .get("email_content/create", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_CREATE,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Create Email Content Error" + err);
            dispatch({
                type: type.EMAIL_CONTENT_CREATE,
                payload: [],
            });
        });
};

export const storeEmailContent = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("email_content/store", data)
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Email Content Error: " + err);
        });
};

export const showEmailContent = (id) => (dispatch, getState) => {
    axios
        .get("email_content/show", {
            params: {
                id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_SHOW,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Email Content Error" + err);
            dispatch({
                type: type.EMAIL_CONTENT_SHOW,
                payload: err.response,
            });
        });
};

export const editEmailContent = (id) => (dispatch, getState) => {
    axios
        .get("email_content/edit", {
            params: {
                id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Email Content Error" + err);
            dispatch({
                type: type.EMAIL_CONTENT_EDIT,
                payload: err.response,
            });
        });
};

export const updateEmailContent = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("email_content/update", data)
        .then((res) => {
            dispatch({
                type: type.EMAIL_CONTENT_UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Email Content Error" + err);
        });
};
