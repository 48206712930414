import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const agentReducer = createReducer(initialState) ({
    [type.AGENT_LIST]: (state, action) => ({
        ...state,
        agent_data: action.value
    }),

    [type.AGENT_STORE]: (state, action) => ({
        ...state,
        agent_store_result: action.value,
    }),

    [type.AGENT_SHOW]: (state, action) => ({
        ...state,
        agent_show_data: action.value
    }),

    [type.AGENT_EDIT]: (state, action) => ({
        ...state,
        agent_edit_data: action.value
    }),

    [type.AGENT_UPDATE]: (state, action) => ({
        ...state,
        agent_update_result: action.value
    }),

    [type.AGENT_UPDATE_STATUS]: (state, action) => ({
        ...state,
        agent_update_status_result: action.value,
    }),

    [type.AGENT_UPDATE_LOGIN_PERMISSION]: (state, action) => ({
        ...state,
        agent_update_login_permission_result: action.value,
    }),

    [type.AGENT_SETTING_DETAILS]: (state, action) => ({
        ...state,
        setting_details: action.value,
    }),

    [type.AGENT_UPDATE_SETTING]: (state, action) => ({
        ...state,
        update_setting: action.value,
    }),

    [type.AGENT_DOWNLINE]: (state, action) => ({
        ...state,
        downline: action.value,
    }),

    [type.AGENT_UPDATE_RESET_PASSWORD]: (state, action) => ({
        ...state,
        update_reset_password: action.value
    }),

    [type.AGENT_WALLET_BALANCE]: (state, action) => ({
        ...state,
        agent_wallet_balance_data: action.payload
    }),

    [type.AGENT_STORE_WALLET_ADJUSTMENT]: (state, action) => ({
        ...state,
        agent_store_wallet_adjustment_result: action.payload,
    }),

    [type.INIT_AGENT_SHOW_INITIAL_LOADING]: (state, action) => ({
        ...state,
        agent_show_initial_loading: action.payload
    }),

    [type.SET_AGENT_SHOW_INITIAL_LOADING]: (state, action) => ({
        ...state,
        agent_show_initial_loading: [...state.agent_show_initial_loading.map((item) => (item.id === action.page) ? {...item, loading: false} : item)]
    }),

    [type.SET_AGENT_INFO_LOCAL_STATE]: (state, action) => ({
        ...state,
        agent_info_local_state: action.payload
    }),

    [type.SET_AGENT_TRANSACTION_HISTORY_LOCAL_STATE]: (state, action) => ({
        ...state,
        agent_transaction_history_local_state: action.payload
    }),
    
    [type.SET_AGENT_WALLET_TRACKER_LOCAL_STATE]: (state, action) => ({
        ...state,
        agent_wallet_tracker_local_state: action.payload
    }),

    [type.AGENT_TRANSACTION_HISTORY]: (state, action) => ({
        ...state,
        agent_transaction_history: action.value,
    }),

    [type.AGENT_FILTER_DATA]: (state, action) => ({
        ...state,
        agent_filter_data: action.value,
    }),

    [type.AGENT_WALLET_TRACKER]: (state, action) => ({
        ...state,
        agent_wallet_tracker: action.value,
    })
});

export default agentReducer;
