import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import ValidationError from '../../components/ValidationError/ValidationError';
import NumberInput from '../../components/Input/NumberInput/NumberInput';
import Button from '../../components/Button/Button';
import Select from "../../components/Input/Select/Select";
import Select2 from "../../components/Input/Select2/Select2";

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import { formValidation } from '../../hoc/Shared/utility';

import * as actions from '../../store/ducks/rescueBonusSetting/actions';

class RescueBonusSettingCreate extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state =
        {
            formFilter: {},
            gameVendor: [],
            vendor: [],
            gameType: [],
            loading: true,
            errMsg: [],
            touched: false,
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() 
    {
        this.props.funcGetDropDown();
        // console.log('componentDidMount (create):', this.state);
        formValidation('rescue_bonus_setting_form');
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                let gameVendor = [];
                let vendor = nextProps.dropDown.data.game_vendor;

                vendor.map((item, idx) => 
                {
                    gameVendor.push({
                        'label': t(`vendor.${item.slug}`),
                        'value': item.id,
                    });
                    return null;
                });

                let gameType = nextProps.dropDown.data.game_type.map((val, idx) =>
                {
                    return {...val, category: t(`game-type.${val.slug}`)};
                });

                // console.log(gameType);

                this.setState({
                    gameVendor,
                    gameType,
                    loading: false,
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) 
        {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            let success = (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') ? true : false;
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') 
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-saved'),
                    showConfirmButton: true
                }).then(() => {
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else 
            {
                this.setState({
                    touched: (success) ? false : true,
                    errMsg: (success) ? [] : nextProps.store.message
                });
                Swal.close();
            }
        }

        return true;
    }

    loadingHandler = () => 
    {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    onInputChange = (event, id) => 
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    onVendorChange = (event) => 
    {
        this.setState({
            vendor: event,
        });
    }

    submitHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => 
                    {
                        Swal.showLoading()
                    }
                });

                let data = this.state.formFilter;
                data.vendor = this.state.vendor.map((item) => item.value);
                // console.log(data);
                this.props.funcStore(data);
            }
        });
    }

    render() 
    {
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('side-bar.rescue_bonus_setting')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.rescue_bonus_setting_create')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='rescue_bonus_setting_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='rescue_bonus_setting_form' touched={this.state.touched} message={this.state.errMsg} />
                                    <div className='row'>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.min-lose')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={(event) => this.onInputChange(event)}
                                                id='min_lose'
                                                name='min_lose'
                                                min={1}
                                                placeholder={t('rescue-bonus-setting.min-lose')}
                                                value={this.state.formFilter.min_lose || ''}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.max-claim')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={(event) => this.onInputChange(event)}
                                                id='max_claim'
                                                name='max_claim'
                                                min={1}
                                                // max={45000}
                                                placeholder={t('rescue-bonus-setting.max-claim')}
                                                value={this.state.formFilter.max_claim || ''}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.percent')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={(event) => this.onInputChange(event)}
                                                id='percent'
                                                name='percent'
                                                // min={0.001}
                                                // max={100}
                                                placeholder={t('rescue-bonus-setting.percent')}
                                                value={this.state.formFilter.percent || ''}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.turnover')}</label>
                                            <NumberInput
                                                onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                onChange={(event) => this.onInputChange(event)}
                                                id='turnover'
                                                name='turnover'
                                                // min={0.001}
                                                // max={100}
                                                placeholder={t('rescue-bonus-setting.turnover')}
                                                value={this.state.formFilter.turnover || ''}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.game-type')}</label>
                                            <Select  
                                                id='game_type_id'  
                                                name='game_type_id'
                                                className='form-control'
                                                getOptionValue='id'
                                                getOptionLabel='category'
                                                emptyValue={true}
                                                options={this.state.gameType}
                                                onChange={(event) => this.onInputChange(event)}
                                                value={this.state.formFilter.game_type_id || ''}
                                                required={true}
                                            />
                                        </div>
                                        <div className='col-md-4 col-12 form-group'>
                                            <label>{t('global.status')}</label>
                                            <Select
                                                id="status"
                                                name="status"
                                                className="form-control"
                                                options={this.props.statusArr}
                                                onChange={(event) => this.onInputChange(event)}
                                                value={this.state.formFilter.status || ''}
                                                emptyValue={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className='col-md-8 col-12 form-group'>
                                            <label>{t('rescue-bonus-setting.exclude-vendor')}</label>
                                            <Select2
                                                options={this.state.gameVendor}
                                                name="data"
                                                onChange={(event) => this.onVendorChange(event)}
                                                value={this.state.vendor}
                                                placeholder={t('global.select')}
                                                // menuPlacement="bottom"
                                            />
                                        </div>
                                        <div className='col-12 form-group mb-0 justify-content-end'>
                                            <div>
                                                {(permission.includes('rescue_bonus_setting_create')) &&
                                                    <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => 
{
    return {
        store: state.rescueBonusSetting.store,
        dropDown: state.rescueBonusSetting.dropDown,
        currentUser: state.currentUser.data,
    }
};

const mapDispatchToProps = dispatch => 
{
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RescueBonusSettingCreate));