import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import { formValidation, clearFormValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import UpdownArrow from "../../assets/images/updown-arrow.png";

import * as actions from '../../store/ducks/rejectReason/actions';

class RejectReasonEdit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            formFilter: {},
            transactionType: [],
            loading: true,
            errMsg: [],
            touched: false,
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.selectId);
        formValidation('reject_reason_form');
        if (this.props.selectId) {
            this.props.funcGetDetails(this.props.selectId);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.details !== this.props.details && nextProps.details !== undefined) {
            if (nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success') {
                let newInput = {};
                for (const key in nextProps.details.data.result) {
                    newInput[key] = nextProps.details.data.result[key];
                }

                this.setState({
                    formFilter: newInput,
                    transactionType: nextProps.details.data.transaction_type,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.update !== this.props.update && nextProps.update !== undefined) {
            // console.log('shouldComponentUpdate :', nextProps.update);
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() => {
                    clearFormValidation('reject_reason_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }
        return true;
    }

    onInputChange = (event) => {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        newInput[event.target.id] = event.target.value;
        this.setState({
            formFilter: newInput
        });
    }

    submitHandler = (e) => {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You wont be able to revert this!',
            icon: 'warning',
            confirmButtonText: 'Save',
            showDenyButton: true,
            denyButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                let data = {};
                data.id = this.state.formFilter.id;
                data.title = this.state.formFilter.title;
                data.transaction_type = this.state.formFilter.transaction_type;
                // console.log(data);
                this.props.funcUpdate(data);
            }
        });
    }

    render() {
        const { t } = this.props;
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];
        // console.log(this.state.transactionType);

        return (
            <Auxiliary>
                <div className='breadcrumb-header'>
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button typeButton='button' classValue='btn btn-back-outline' idValue='back' buttonName={<i className="angle fe fe-chevron-left"></i>} clicked={() => this.props.changePageHandler('listing')} />
                        </div>
                    </div>
                    <div className='my-auto'>
                        <div className='d-flex'>
                            <h4 className='content-title mb-0 my-auto ml-2'>{t('reject-reason.title')}</h4>
                        </div>
                    </div>
                </div>
                <div className='row row-sm'>
                    <div className='col-12'>
                        <div className='card  box-shadow-0'>
                            <div className='card-header'>
                                <h4 className='card-title mb-1'>{t('role.reject_reason_update')}</h4>
                            </div>

                            <div className='card-body pt-0'>
                                <form method='post' id='reject_reason_form' className='form-horizontal' onSubmit={this.submitHandler} noValidate>
                                    <ValidationError formName='reject_reason_form' touched={this.state.touched} message={this.state.errMsg} />
                                    {this.state.loading
                                        ?
                                        <Skeleton count={3} />
                                        :
                                        <>
                                            <div className='row'>
                                                {/* <div className='col-md-6'> */}
                                                <div className=' col-md-6 form-group'>
                                                    <label htmlFor='title'>{t('reject-reason._title')}</label>
                                                    <StringInput
                                                        id='title'
                                                        name='title'
                                                        placeholder={t('reject-reason._title')}
                                                        value={this.state.formFilter.title || ''}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>

                                                <div className='col-md-6 form-group'>
                                                    <label>{t('reject-reason.transaction-type')}</label>
                                                    <div className='select-input'>
                                                        <select className='form-control filter-input' id='transaction_type' name='transaction_type' value={this.state.formFilter.transaction_type} onChange={(event) => this.onInputChange(event)}>
                                                            {(this.state.transactionType) &&
                                                                this.state.transactionType.map((val, idx) => {
                                                                    return (<option key={val.id} value={val.id}>{t(`transaction-type.${val.slug}`)}</option>);
                                                                })
                                                            }
                                                        </select>
                                                        <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                    </div>

                                                </div>

                                                <div className='col-md-12 form-group mb-0 justify-content-end'>
                                                    <div>
                                                        {(permission.includes('reject_reason_edit')) &&
                                                            <Button typeButton='submit' classValue='btn btn-blue' idValue='save' buttonName={t('global.save')} />
                                                        }
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
    return {
        details: state.rejectReason.details,
        update: state.rejectReason.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(RejectReasonEdit));