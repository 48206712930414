import React, { Component } from 'react';
import { required, validateDisplay } from "../../../hoc/Shared/utility";
import PropTypes from 'prop-types';

import UpdownArrowWhite from "../../../assets/images/updown-arrow-white.png";
import UpdownArrow from "../../../assets/images/updown-arrow.png";

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage),
            disabled: !!(this.props.disabled) ? this.props.disabled : false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            (nextProps.value !== prevState.value)

        ) {
            let payload = {
                value: nextProps.value,
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if (nextProps.required) {
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }

            return payload;
        }

        return null;
    }

    render() {
        const { id, name, className, onChange, defaultValue, required, getOptionLabel, getOptionValue, emptyValue, containerClass } = this.props;

        const { value, requiredRules, disabled } = this.state;

        //Export the validation classes and message
        const [requiredClass, requiredMsg] = validateDisplay(requiredRules);

        return (
            <div className={`${containerClass}`}>
                <div className="select-input">
                    <select
                        className={`datatable-select ${className}`}
                        id={id}
                        name={name}
                        onChange={onChange}
                        value={value}
                        required={required}
                        defaultValue={defaultValue}
                        disabled={disabled}
                    >
                        {(emptyValue) && <option value=""></option>}
                        {this.props.options.map((option) =>
                            <option key={option[getOptionValue] || option.value} value={option[getOptionValue] || option.value}
                                defaultValue={option.selected}>{option[(getOptionLabel)] || option.label}</option>
                        )}
                    </select>
                    {
                        className === "form-control datatable-select-active" || className === "form-control datatable-select-inactive" ? (
                            <img className="updown-icon" for={id} src={UpdownArrowWhite} alt="Up Down Arrow"/>
                        ) : (
                            <img className="updown-icon" for={id} src={UpdownArrow} alt="Up Down Arrow"/>
                        )
                    }

                </div>

                <div className="invalid-feedback">
                    {requiredClass !== 'is-valid' && requiredMsg}
                </div>
            </div>
        )
    }
}

export default Select;

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,

    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    required: PropTypes.bool
};

Select.defaultProps = {
    className: ''
};
