import React, { Component } from "react";
import { connect } from "react-redux";
import { showAgent, initAgentShowInitialLoading } from "../../../store/ducks/agent/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import AgentInfo from "./AgentInfo";
import AgentTransactionHistory from "./AgentTransactionHistory";
import AgentWalletTracker from "./AgentWalletTracker";


class AgentShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pageShow: 'agent_info',
            initialLoading: [
                { id: "agent_info", loading: true },
                { id: "transaction_history", loading: true },
                { id: "wallet_tracker", loading: true }
            ],
        };
        this.props.initAgentShowInitialLoading(this.state.initialLoading);
    }

    componentDidMount() {
        this.props.showAgent(this.props.agentShowId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.agentShowData !== undefined && nextProps.agentShowData !== nextState.agentShowData && nextState.loading === true) {
            this.setState({
                loading: false,
            });
        }
        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let tabId = this.state.pageShow;
        const tabArray = ['agent_info','transaction_history','wallet_tracker'];

        return (
            <Auxiliary>
                {(tabArray.indexOf(tabId) > -1) && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-primary tabs-style-2 mt-3">
                                <div className="tab-menu-heading">
                                    <div className="tabs-menu1">
                                        <ul className="nav panel-tabs main-nav-line">
                                            <li>
                                                <button
                                                    id="agent_info"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "agent_info" ? "active" : ""}`}>
                                                    {t("agent.agent-info")}
                                                </button>
                                            </li>

                                            <li>
                                                <button
                                                    id="transaction_history"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "transaction_history" ? "active" : ""}`}>
                                                    {t("agent.transaction-history")}
                                                </button>
                                            </li>
                                            
                                            <li>
                                                <button
                                                    id="wallet_tracker"
                                                    onClick={(event) => this.changeTabHandler(event)}
                                                    style={{ borderTopWidth: 0 }}
                                                    className={`nav-link bg-white ${tabId === "wallet_tracker" ? "active" : "'"}`}>
                                                    {t("agent.wallet-tracker")}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("side-bar.agent")}</h4>
                        </div>
                    </div>
                </div>
                
                {tabId === "agent_info" && (
                    <AgentInfo
                        agentShowId={this.props.agentShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {tabId === "transaction_history" && (
                    <AgentTransactionHistory
                        agentShowId={this.props.agentShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}

                {tabId === "wallet_tracker" && (
                    <AgentWalletTracker
                        agentShowId={this.props.agentShowId}
                        initialLoading={this.state.initialLoading}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { agent } = state;

    return {
        agentShowData: agent.agent_show_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAgent: (id) => dispatch(showAgent(id)),
        initAgentShowInitialLoading: (data) => dispatch(initAgentShowInitialLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AgentShow));
