import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

import DataTable from "../../../components/Datatable/DataTable";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Button from "../../../components/Button/Button";
import DateTimePicker from "../../../components/DateTimePicker/DateTimePicker";
import Skeleton from "react-loading-skeleton";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import UpdownArrow from '../../../assets/images/updown-arrow.png'

class AgentTransactionHistoryListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
    }

    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber = (this.props.activePage - 1) * this.props.limit + (rowIndex + 1);
        let rowNumber = this.props.totalRecords - rowIndex - (this.props.activePage - 1) * 10;
        return <span>{rowNumber}</span>;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };

    render() {
        const { t } = this.props;
        let dataListing = [];
        // let st = { 0: "New Request", 1: "Confirm", 2: "Reject", 3: "Processing", 4: "Cancel" };
        let st_cls = { 0: "primary", 1: "success", 2: "danger", 3: "secondary", 4: "danger" };
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns = [
            { text: t("transaction.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px", textAlign: "center" } },
            { text: t("transaction.order-id"), dataField: "order_id", sort: true, onSort: this.props.onSort, headerStyle: { width: "115px" }, },
            { text: t("transaction.transaction-type"), dataField: "transaction_type", sort: true, onSort: this.props.onSort },
            { text: t("transaction.status"), dataField: "status", sort: true, onSort: this.props.onSort },
            { text: t("transaction.transaction-date"), dataField: "create_date", sort: true, onSort: this.props.onSort },
            { text: t("transaction.update-date"), dataField: "update_date", sort: true, onSort: this.props.onSort },
            { text: t("transaction.update-by"), dataField: "update_by", sort: true, onSort: this.props.onSort },
            { text: t("transaction.debit"), dataField: "debit", sort: true, onSort: this.props.onSort, headerStyle: { width: "120px" } },
            { text: t("transaction.credit"), dataField: "credit", sort: true, onSort: this.props.onSort, headerStyle: { width: "120px" } },
            { text: t("transaction.fees"), dataField: "fees", sort: true, onSort: this.props.onSort, headerStyle: { width: "120px" } },
        ];

        if (this.props.data) {
            dataListing = this.props.data.map((val, idx) => {
                let st_val = "";
                let order_id_val = "";
                // let bank_acc_val = '';
                st_val = <span className={`badge badge-pill badge-${st_cls[val.status]}`}>{t(`transaction.status-desc.${val.status}`)}</span>;

                order_id_val = (
                    <b>
                        <a href="/#" onClick={(event) => this.props.detailsHandler(event, val.id)}>
                            {val.order_id}
                            <i className="fas fa-external-link-square-alt text-primary ml-1"></i>
                        </a>
                    </b>
                );
                // bank_acc_val = (val.transactionTypeId !== 1 && val.transactionTypeId !== 2) ? '-' : (val.transactionTypeId === 1 ? val.depositBankAccNumber : val.withdrawalBankAccNumber);
                return {
                    no: idx,
                    order_id: order_id_val,
                    transaction_type: t(`transaction-type.${val.transaction_type}`),
                    status: st_val,
                    create_date: val.create_date,
                    update_date: val.update_date,
                    update_by: val.update_by,
                    debit: new Intl.NumberFormat("en-US", num_format).format(val.debit),
                    credit: new Intl.NumberFormat("en-US", num_format).format(val.credit),
                    fees: new Intl.NumberFormat("en-US", num_format).format(val.fees),
                };
            });
        }
        /* ======================== datatables data assign [END] ======================== */

        return (
            <Auxiliary>
                <div className="row row-sm">
                    <div className="col-xl-12">
                        <div className="card card-body card-primary">
                            <div className="">
                                {this.props.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <form
                                        method="post"
                                        id="transaction_form"
                                        className={`form-horizontal filter-function row ${this.state.showCard ? "open" : "close"} filter-wrapper-1`}>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("transaction.transaction-date")}</label>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-calendar-check"></i>
                                                    </span>
                                                    <DateTimePicker
                                                        id="transaction_date"
                                                        name="transaction_date"
                                                        className="form-control filter-input"
                                                        range={true}
                                                        timePicker={true}
                                                        value={this.props.filter.transaction_date}
                                                        onChange={this.props.onInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("transaction.status")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="status"
                                                        name="status"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.status}>
                                                        <option value=""></option>
                                                        {this.props.ddStatus &&
                                                            this.props.ddStatus.map((val, idx) => {
                                                                return (
                                                                    <option key={val.id} value={val.id}>
                                                                        {t(`transaction.status-desc.${val.id}`)}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="form-group">
                                                <label className="font-weight-bolder">{t("transaction.transaction-type")}</label>
                                                <div className="select-input">
                                                    <select
                                                        className="form-control filter-input"
                                                        id="transaction_type"
                                                        name="transaction_type"
                                                        onChange={this.props.onInputChange}
                                                        value={this.props.filter.transaction_type}>
                                                        <option value=""></option>
                                                        {/* {this.props.ddTransactionType} */}
                                                        {this.props.ddTransactionType &&
                                                            this.props.ddTransactionType.map((val, idx) => {
                                                                return (
                                                                    <option key={val.id} value={val.id}>
                                                                        {t(`transaction-type.${val.slug}`)}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div className="d-flex">
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-blue mr-2"
                                                    idValue="filter"
                                                    buttonName={t("global.filter")}
                                                    clicked={() => this.props.refreshHandler()}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-secondary"
                                                    idValue="reset"
                                                    buttonName={t("global.reset")}
                                                    clicked={() => this.props.clearHandler()}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </div>

                            <div className="show-btn" onClick={() => this.toggleCard()}>
                                {this.state.showCard ? <AiFillMinusCircle /> : <AiFillPlusCircle />}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("transaction.listing")}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-hover datatable">
                                    <DataTable
                                        keyField="no"
                                        data={dataListing}
                                        columns={columns}
                                        activePage={this.props.activePage}
                                        limit={this.props.limit}
                                        totalItems={this.props.totalRecords}
                                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, "activePage")}
                                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, "limit")}
                                        searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, "searchValue")}
                                        searchValue={this.props.searchValue}
                                        classValue="dataTable table-bordered text-justify text-center member__transaction"
                                        loading={this.props.loading}
                                        hideSearchBar={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(AgentTransactionHistoryListing);
