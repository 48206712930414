import axios from "../../../../common/config/axios";
import * as type from "./types";

export const getListing = (data) => (dispatch, getState) => {
    axios.get("event_qna_bet/get_listing", {params: data})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event QNA Bet Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};