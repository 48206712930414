import * as type from "./types";
import axios from "../../../common/config/axios";

export const getTotalRecords = (data) => (dispatch) => {
    axios.get('dashboard/total_record', {
      params: data
    }).then((response) => {
        dispatch({
            type: type.GET_TOTAL_RECORD,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getBankTransferRecords = (data) => (dispatch) => {
    axios.get('dashboard/bank_transfer_record', {
      params: data
    }).then((response) => {
        dispatch({
            type: type.GET_BANK_TRANSFER_RECORD,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getFpayRecords = (data) => (dispatch) => {
    axios.get('dashboard/fpay_record', {
      params: data
    }).then((response) => {
        dispatch({
            type: type.GET_FPAY_RECORD,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getWithdrawRecords = (data) => (dispatch) => {
    axios.get('dashboard/withdraw_record', {
      params: data
    }).then((response) => {
        dispatch({
            type: type.GET_WITHDRAW_RECORD,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getDData = (data) => (dispatch) => {
    axios.get('dashboard/ddata', {
      params: data
    }).then((response) => {
        dispatch({
            type: type.GET_DDATA,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const getDashboardData = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get('dashboard/dashboard_data', {
        params: data
      }).then((response) => {
          dispatch({
              type: type.GET_DASHBOARD_DATA,
              payload: response
          })
      }).catch(err => {
          console.warn(err);
      })
}

