import React, { Component } from "react";
import { connect } from "react-redux"; // Connect is a function, a function which returns aw Higher Order Component
import CounterControl from "./CounterControl/CounterControl";
import CounterOutput from "./CounterOutput/CounterOutput";
import * as actionTypes from "../../../store/ducks/demo/types";
import UserContext from "../../../context/User/UserContext";
class Counter extends Component {
    state = {
        counter: 0,
    };

    counterChangedHandler = (action, value) => {
        switch (action) {
            case "inc":
                this.setState((prevState) => {
                    return { counter: prevState.counter + 1 };
                });
                break;
            case "dec":
                this.setState((prevState) => {
                    return { counter: prevState.counter - 1 };
                });
                break;
            case "add":
                this.setState((prevState) => {
                    return { counter: prevState.counter + value };
                });
                break;
            case "sub":
                this.setState((prevState) => {
                    return { counter: prevState.counter - value };
                });
                break;
            default:
                this.setState((prevState) => {
                    return { counter: prevState.counter + 1 };
                });
        }
    };

    render() {
        return (
            <UserContext.Consumer>
                {(context) => (
                    <div>
                        <h2>Redux Country ID: {this.props.countryId}</h2>
                        <h2>Context Country ID: {context.currentCountryId}</h2>
                        <CounterOutput value={this.props.ctr} />
                        <CounterControl
                            label="Increment"
                            clicked={this.props.onIncrementCounter}
                        />
                        <CounterControl label="Decrement" clicked={this.props.onDecrementCounter} />
                        <CounterControl label="Add 5" clicked={this.props.onAddCounter} />
                        <CounterControl label="Subtract 5" clicked={this.props.onSubtractCounter} />
                        <hr />
                        <button onClick={() => this.props.onStoreResult(this.props.ctr)} className="btn btn-success">
                            Store Result
                        </button>
                        <ul>
                            {this.props.storedResults.map((strResult) => (
                                <li key={strResult.id} onClick={() => this.props.onDeleteResult(strResult.id)}>
                                    {strResult.value}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }
}

////////////////////////////// REDUX //////////////////////////////

// In Bigger Application, may have thousands of actions dispatched from all over the application but a given individual container.
// In an individual of containers, may only dispatch a couple of these actions
// The actions we want to dispatch and the state we want to get.
// Store Instruction about how the state managed by Redux should be mapped to props we can use in this container.
// The state managed Redux is not received as state here because state is the thing we change internally from within a component (Those Time is Over) (Not Include in this Chapter)

// Redux is now the place where we manage and change the state, So, we dont want to get anything which we can change internally and props are changed internally.
// That is why we map the Redux state to props.
// mapStateToProps
// It actually stores a function which expects the state stored in Redux as the inpit and returns a JavaScript object which is a map of prop name and Slice of the state stored in Redux

// Get State
const mapStateToProps = (state) => {
    return {
        ctr: state.demo.counter,
        storedResults: state.demo.results,
        countryId: state.currentUser.data.country_id,
    };
};

// Dispatch Action
// The React Redux package gives us helper function which will call dispatch on the store behind the scenes
// This property "onIncrementCounter" now holds a value and that value should be an anonymous function,
// Example of Anonymous Function -->  () => dispatch({type: 'INCREMENT'})
const mapDispatchToProps = (dispatch) => {
    return {
        onIncrementCounter: () => dispatch({ type: actionTypes.INCREMENT }),
        onDecrementCounter: () => dispatch({ type: actionTypes.DECREMENT }),
        onAddCounter: () => dispatch({ type: actionTypes.ADD, val: 5 }),
        onSubtractCounter: () => dispatch({ type: actionTypes.SUBTRACT, val: 5 }),
        onStoreResult: (r) => dispatch({ type: actionTypes.STORE_RESULT, result: r }),
        onDeleteResult: (id) => dispatch({ type: actionTypes.DELETE_RESULT, resultElId: id }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);

// If container which only needs to dispatch action but does not need a slice of state
// export default connect(null, mapDispatchToProps)(Counter);
