import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import Lightbox from 'react-image-lightbox';
import JoditEditor from "jodit-react";

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';
import StringInput from '../../components/Input/StringInput/StringInput';
import {formValidation, clearFormValidation} from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';
import Select from "../../components/Input/Select/Select";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Tab from "../../components/Tab/Tab";
import ImageUpload from "../../components/Input/ImageUpload/ImageUpload";

import * as actions from '../../store/ducks/rewardItem/actions';

class RewardItemEdit extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            formFilter: {},
            loading: true,
            langArr: [],
            errMsg: [],
            touched: false,
            dropDown:
            {
                category: [],
                vendor: [],
            },
            image: [],

        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onImageUpload = this.onImageUpload.bind(this);
    }

    componentDidMount()
    {
        // console.log(this.props.selectId);
        formValidation('reward_item_form');
        if(this.props.selectId)
        {
            this.props.funcGetDetails(this.props.selectId);
        }
        this.props.funcGetDropDown();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
    	const { t } = this.props;
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                let categoryArr = nextProps.dropDown.data.category.map((val, idx) =>
                {
                    return {...val, category: t(`reward-category.${val.slug}`)};
                });

                let vendorArr = nextProps.dropDown.data.vendor.map((val, idx) =>
                {
                    return {...val, slug: t(`vendor.${val.slug}`)};
                });

                this.setState({
                    dropDown:
                    {
                        category: categoryArr,
                        vendor: vendorArr,
                    }
                });
            }
        }

        if (nextProps.details !== this.props.details && nextProps.details!==undefined)
        {
            if(nextProps.details.responseCode === 200 && nextProps.details.msgType === 'success')
            {
                let tabArr = [];
                let newInput = {};
                newInput['valid_period_1'] = 0;
                newInput['valid_period_2'] = 'day';

                for (const key in nextProps.details.data.result)
                {
                    newInput[key] = nextProps.details.data.result[key];

                    if(key === 'valid_period')
                    {
                        if(nextProps.details.data.result[key] !== null)
                        {
                            const validPeriod = nextProps.details.data.result[key].split(" ");
                            newInput['valid_period_1'] = validPeriod[0];
                            newInput['valid_period_2'] = validPeriod[1];
                        }
                    }
                }

                if(nextProps.details.data.result.reward_details)
                {
                    nextProps.details.data.result.reward_details.forEach((val) =>
                    {
                        tabArr[val.lang_id] = val;
                    });
                }
                newInput['reward_details'] = tabArr;
                // console.log(newInput);
                this.setState({
                    formFilter: newInput,
                    langArr: nextProps.details.data.language,
                    loading: false,
                });
            }
            // Swal.close();
        }

        if (nextProps.update !== this.props.update && nextProps.update!==undefined)
        {
            // console.log('shouldComponentUpdate :', nextProps.update);
            let success = (nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success') ? true : false;
            if(nextProps.update.responseCode === 200 && nextProps.update.msgType === 'success')
            {
                Swal.fire({
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.record-updated'),
                    showConfirmButton: true
                }).then(() =>
                {
                    clearFormValidation('reward_item_form');
                    this.props.refreshHandler();
                    this.props.changePageHandler('listing');
                });
            }
            else
            {
                Swal.close();
            }
            this.setState({
                touched: (success) ? false : true,
                errMsg: (success) ? [] : nextProps.update.message
            });
        }
        return true;
    }

    onInputChange = (event, langId) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        if(langId && langId > 0)
        {
            if(newInput.reward_details[langId])
            {
                newInput.reward_details[langId][event.target.id] = event.target.value;
            }
            else
            {
                let newLangArr = {[event.target.id]: event.target.value};
                newInput.reward_details[langId] = newLangArr;
            }
        }
        else
        {
            newInput[event.target.id] = event.target.value;
        }

        this.setState({
            formFilter: newInput,
        });
    }

    onImageUpload = (event, langId) =>
    {
        let newInput = Object.assign({}, this.state.formFilter);
        let reader = new FileReader();
        let file = event[0];

        const {image} = this.state;

        reader.onloadend = () =>
        {
            if(newInput.reward_details[langId])
            {
                newInput.reward_details[langId].image = reader.result;
            }
            else
            {
                let newLangArr = {'image': reader.result};
                newInput.reward_details[langId] = newLangArr;
            }
            image[langId] = file;

            this.setState({
                formFilter: newInput,
                image
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            newInput.reward_details[langId].image = '';
            image[langId] = '';
            this.setState({
                formFilter: newInput,
                image
            });
        }
    }

    onImageRemove = (event, langId) =>
    {
        let newInput = Object.assign({}, this.state.formFilter);
        let {image} = this.state;

        newInput.reward_details[langId].image = '';
        image[langId] = '';

        this.setState({
            formFilter: newInput,
            image,
        });
    }

    submitHandler = (e) =>
    {
        // console.log('submitHandler :', this.state);
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t('validation.confirmation'),
            text: t('validation.confirmation_check'),
            icon: 'warning',
            confirmButtonText: t('global.save'),
            showDenyButton: true,
            denyButtonText: t('global.cancel'),
        }).then((result) =>
        {
            if (result.isConfirmed)
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () =>
                    {
                        Swal.showLoading()
                    }
                });

                let formData = new FormData();

                const details = this.state.formFilter.reward_details;
                details.forEach((val, idx) =>
                {
                    for (const key in val)
                    {
                        // console.log(val[key]);
                        if(key !== 'image' && key !== 'lang_id')
                        {
                            formData.append(`${key}[${idx}]`, val[key]);
                        }
                    }
                });

                if(this.state.image.length > 0)
                {
                    const image = this.state.image;
                    image.forEach((val, idx) => {
                        formData.append("image["+idx+"]", val);
                    });
                }

                formData.append('id',this.props.selectId);
                formData.append('coin',this.state.formFilter.coin);
                formData.append('qty',this.state.formFilter.qty);
                formData.append('limit',this.state.formFilter.limit);
                formData.append('deposit',this.state.formFilter.deposit);
                formData.append('round',(Number(this.state.formFilter.category_id) !== 2) ? 0 : this.state.formFilter.round);
                formData.append('bet_scale',(Number(this.state.formFilter.category_id) !== 2) ? 0 : this.state.formFilter.bet_scale);
                formData.append('valid_period',(Number(this.state.formFilter.category_id) !== 2) ? 0 : `${this.state.formFilter.valid_period_1} ${this.state.formFilter.valid_period_2}`);
                formData.append('vendor_id',(Number(this.state.formFilter.category_id) !== 2) ? 0 : (Number(this.state.formFilter.vendor_id) === 0 && Number(this.state.formFilter.category_id) === 2) ? 1 : this.state.formFilter.vendor_id);
                formData.append('category_id',this.state.formFilter.category_id);
                formData.append('status',this.state.formFilter.status);
                formData.append('country_id',this.props.currentUser.country_id);

                // for (var pair of formData.entries())
                // {
                //      console.log(pair[0]+ ' = ' + pair[1]);
                // }
                this.props.funcUpdate(formData);
            }
        });
    }

    render()
    {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];
        let statusArr = [
            { label: t("global.active"), value: 1 },
            { label: t("global.inactive"), value: 0 },
        ];
        let validPeriodArr = [
            { label: t("global.unit_of_time.day"), value: 'day' },
            { label: t("global.unit_of_time.week"), value: 'week' },
            { label: t("global.unit_of_time.month"), value: 'month' },
            { label: t("global.unit_of_time.year"), value: 'year' },
        ];
        let languages = this.state.langArr;
        let tabArr = (this.state.formFilter.reward_details || []);

        // console.log(this.state.image);

        tabTitleMessage = languages.map((language) => language.lang);
        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.currentImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="image">{t('reward-item.image')}</label>
                        <ImageUpload
                            id="image"
                            name={`image[${item.langId}]`}
                            onChange={(event) => this.onImageUpload(event, item.langId)}
                        />
                        {tabArr[item.langId] && tabArr[item.langId].image &&
                        <>
                            <div className='preview-image'>
                                <Button
                                    typeButton="button"
                                    classValue="clearImage"
                                    buttonName="x"
                                    clicked={(event) => this.onImageRemove(event, item.langId)}
                                >
                                </Button>

                                <img
                                    alt=""
                                    className="banner"
                                    src={tabArr[item.langId] && tabArr[item.langId].image}
                                    onClick={() => this.setState({isOpen: true, currentImage: tabArr[item.langId].image})}
                                    style={{"cursor": "pointer"}}
                                />
                            </div>
                        </>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('reward-item.title')}</label>
                        <StringInput
                            onChange={(event) => this.onInputChange(event, item.langId)}
                            id="title"
                            name={`title[${item.langId}]`}
                            value={(tabArr[item.langId] && tabArr[item.langId].title) || ''}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('reward-item.content')}</label>
                        <JoditEditor
                            value={(tabArr[item.langId] && tabArr[item.langId].content) || ''}
                            tabIndex={item.langId} // tabIndex of textarea
                            onBlur={newContent =>
                            {
                                let newInput = Object.assign({}, this.state.formFilter);
                                if(newInput.reward_details[item.langId])
                                {
                                    newInput.reward_details[item.langId].content = newContent;
                                }
                                else
                                {
                                    let newLangArr = {'content': newContent};
                                    newInput.reward_details[item.langId] = newLangArr;
                                }
                                newInput.reward_details[item.langId].content = newContent;

                                this.setState({
                                    formFilter: newInput
                                });
                            }}
                        />
                    </div>
                </div>

            </Auxiliary>
        ));
        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage}/>;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className='d-flex'>
                        <div className='my-auto'>
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t('reward-item.edit-reward')}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('reward-item.edit-reward')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? <Skeleton count={4}/> :
                                    <form method="post" id="reward_item_form" className="form-horizontal" encType="multipart/form-data" onSubmit={this.submitHandler} noValidate>
                                        <ValidationError formName='reward_item_form' touched={this.state.touched} message={this.state.errMsg}/>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">{tabMessage}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t('global.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArr}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.status}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.category')}</label>
                                                    <Select
                                                        id='category_id'
                                                        name='category_id'
                                                        className='form-control'
                                                        getOptionValue='id'
                                                        getOptionLabel='category'
                                                        options={this.state.dropDown.category}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.category_id}
                                                        // emptyValue={true}
                                                        // required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.coin')}</label>
                                                    <NumberInput
                                                        id='coin'
                                                        name='coin'
                                                        // min={0.01}
                                                        placeholder={t('reward-item.coin')}
                                                        value={this.state.formFilter.coin}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        // required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.qty')}</label>
                                                    <NumberInput
                                                        id='qty'
                                                        name='qty'
                                                        placeholder={t('reward-item.qty')}
                                                        value={this.state.formFilter.qty}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.limit')}</label>
                                                    <NumberInput
                                                        id='limit'
                                                        name='limit'
                                                        placeholder={t('reward-item.limit')}
                                                        value={this.state.formFilter.limit}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                    <small>{t('reward-item.hint-no-limit')}</small>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.deposit-amount')}</label>
                                                    <NumberInput
                                                        id='deposit'
                                                        name='deposit'
                                                        placeholder={t('reward-item.deposit-amount')}
                                                        value={this.state.formFilter.deposit}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        required
                                                    />
                                                    <small>{t('reward-item.hint-not-required')}</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {Number(this.state.formFilter.category_id) === 2 && 
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.free-round')}</label>
                                                    <NumberInput
                                                        id='round'
                                                        name='round'
                                                        placeholder={t('reward-item.free-round')}
                                                        value={this.state.formFilter.round}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                    />
                                                </div>
                                            </div>}

                                            {Number(this.state.formFilter.category_id) === 2 && 
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.bet-scale')}</label>
                                                    <NumberInput
                                                        id='bet_scale'
                                                        name='bet_scale'
                                                        placeholder={t('reward-item.bet-scale')}
                                                        value={this.state.formFilter.bet_scale}
                                                        onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                        onChange={(event) => this.onInputChange(event)}
                                                    />
                                                </div>
                                            </div>}

                                            {Number(this.state.formFilter.category_id) === 2 && 
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.valid-period')}</label>
                                                    <div className="input-group">
                                                        <NumberInput
                                                            id='valid_period_1'
                                                            name='valid_period_1'
                                                            placeholder={t('reward-item.valid-period')}
                                                            value={this.state.formFilter.valid_period_1}
                                                            onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                                            onChange={(event) => this.onInputChange(event)}
                                                            // style={{ width: '50px', }}
                                                        />
                                                        <span style={{ margin: "auto 10px", }}>&nbsp;</span>
                                                        <Select
                                                            id="valid_period_2"
                                                            name="valid_period_2"
                                                            className="form-control"
                                                            options={validPeriodArr}
                                                            onChange={(event) => this.onInputChange(event)}
                                                            value={this.state.formFilter.valid_period_2}
                                                        />
                                                        <span style={{ margin: "auto 50px", width: '30px', }}>&nbsp;</span>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="row">
                                            {Number(this.state.formFilter.category_id) === 2 && 
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label >{t('reward-item.vendor')}</label>
                                                    <Select
                                                        id='vendor_id'
                                                        name='vendor_id'
                                                        className='form-control'
                                                        getOptionValue='id'
                                                        getOptionLabel='slug'
                                                        options={this.state.dropDown.vendor}
                                                        onChange={(event) => this.onInputChange(event)}
                                                        value={this.state.formFilter.vendor_id}
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="form-group mb-0 justify-content-end">
                                            <div>
                                                <Button
                                                    id="submit"
                                                    classValue="btn btn-blue"
                                                    typeButton="submit"
                                                    buttonName={t('global.save')}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        details: state.rewardItem.details,
        dropDown: state.rewardItem.dropDown,
        update: state.rewardItem.update,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetDetails: (id) => dispatch(actions.getDetails(id)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcUpdate: (data) => dispatch(actions.update(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(RewardItemEdit));