export const GET_DAY_DEPOSIT_REPORT = "GET_DAY_DEPOSIT_REPORT";
export const GET_MEMBER_DEPOSIT_STATUS_REPORT = "GET_MEMBER_DEPOSIT_STATUS_REPORT";
export const GET_VENDOR_STATUS_REPORT = "GET_VENDOR_STATUS_REPORT";
export const GET_VENDOR_SUB_STATUS_REPORT = "GET_VENDOR_SUB_STATUS_REPORT";
export const GET_YEARLY_VENDOR_STATUS_REPORT = "GET_YEARLY_VENDOR_STATUS_REPORT";
export const MEMBER_GET_FILTER_DATA = "DEPOSIT_STATUS_REPORT_MEMBER_GET_FILTER_DATA";
export const DROPDOWN = "DROPDOWN";
export const GET_MEMBER_STATUS_REPORT = "GET_MEMBER_STATUS_REPORT";
export const GET_MEMBER_SUB_STATUS_REPORT = "GET_MEMBER_SUB_STATUS_REPORT";
export const GET_MEMBER_PAYOUT_REPORT = "GET_MEMBER_PAYOUT_REPORT";
export const GET_MEMBER_HISTORY_REPORT = "GET_MEMBER_HISTORY_REPORT";
