import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = 
{
    data: [],
};

const memberRebateReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.HISTORY]: (state, action) => ({
        ...state,
        history: action.value,
    }),
    [type.ROLLOVER_DETAILS]: (state, action) => ({
        ...state,
        rolloverDetails: action.value,
    }),
    [type.STORE]: (state, action) => ({
        ...state,
        store: action.value,
    }),
    // [type.UPDATE_STATUS]: (state, action) => ({
    //     ...state,
    //     updateStatus: action.value,
    // }),
    // [type.DETAILS]: (state, action) => ({
    //     ...state,
    //     details: action.value,
    // }),
    // [type.UPDATE_SETTING]: (state, action) => ({
    //     ...state,
    //     updateSetting: action.value,
    // }),
});

export default memberRebateReducer;