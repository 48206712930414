import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const popupReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_POPUP_LIST]: (state = initialState, action) => ({
        ...state,
        popup_data: action.payload
    }),

    [type.POST_POPUP]: (state, action) => ({
        ...state,
        post_popup_result: action.payload,
    }),

    [type.CREATE_POPUP]: (state, action) => ({
        ...state,
        create_popup_data: action.payload,
    }),

    [type.SHOW_POPUP]: (state, action) => ({
        ...state,
        show_popup_data: action.payload,
    }),

    [type.UPDATE_POPUP]: (state, action) => ({
        ...state,
        update_popup_result: action.payload,
    }),

    [type.DELETE_POPUP]: (state, action) => ({
        ...state,
        delete_popup_result: action.payload,
    }),

    [type.UPDATE_STATUS_POPUP]: (state, action) => ({
        ...state,
        update_status_popup_result: action.payload,
    })

});

export default popupReducer;