import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch =>
{
    axios.get('game_category/list', {params: props})
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('game_category/drop_down')
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.DROPDOWN,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const updateStatus = (data) => dispatch =>
{
    // console.log(data);
    axios.post('game_category/update_status', data)
    .then(function (response)
    {
      dispatch (
      {
        type: actionType.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err =>
    {

    });
};

export const editGameCategory = (id) => (dispatch) => {
  axios
      .get("game_category/edit", {
          params: {
              id: id,
          },
      })
      .then((res) => {
          dispatch({
              type: actionType.EDIT_GAME_CATEGORY,
              value: res.data,
          });
      })
      .catch((err) => {
          // console.log("Edit Game Category Error" + err);
          dispatch({
              type: actionType.EDIT_GAME_CATEGORY,
              value: [],
          });
      });
};

export const updateGameCategory = (data) => (dispatch) => {
  axios
      .post("game_category/update", data)
      .then((res) => {
          dispatch({
              type: actionType.UPDATE_GAME_CATEGORY,
              value: res.data,
          });
      })
      .catch((err) => {
          // console.log("Update Game List Error\n", err);
      });
};
