import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import moment from 'moment';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import AgentWithdrawalListing from './AgentWithdrawalListing';
import AgentWithdrawalEdit from './AgentWithdrawalEdit';

import * as actions from '../../store/ducks/agentWithdrawal/actions';
import AgentShow from '../Agents/AgentShow/AgentShow';

const initialInputState =
{
    /* form input value */
    order_id: '',
    username: '',
    status: '',
    bank_acc_no: '',
    min_amount: '',
    max_amount: '',
    transaction_date: '',
    start_date: '',
    end_date: '',
};

class Withdrawal extends Component
{
    /* ============ component lifecycle [START] ============ */
    constructor(props)
    {
        super(props);
        this.state =
        {
            /* component change */
            pageShow: 'listing',

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            /* form input value */
            filter: initialInputState,

            /* api result array */
            data: [],
            dropDown:
            {
                status: [],
            },

            /* transction details parameter */
            detailsParam: {},

            /* skeleton loading */
            loading: true,

            /* detect new transaction */
            // intervalTime: this.props.currentUser.system_setting.withdrawal_refresh_time || 20,
            
            // redirect agent profile
            redirectParam: {}
        }
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        // console.log('componentDidMount');
        const listingArr = this.getFilterArray(); // get input params
        this.props.funcGetList(listingArr); // get datatables result
        this.props.funcGetDropDown(); // get filter drop down
        // this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
    }

    componentWillUnmount() 
    {
        // clearInterval(this.intervalId);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                this.setState({
                    data: nextProps.rs.data.result.row,
                    totalRecords: nextProps.rs.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else
            {
                const { t } = this.props;
                let msg = null;
                msg = '<ul>';
                nextProps.rs.message.forEach((val) =>
                {
                    msg += '<li>'+val+'</li>';
                });
                msg += '</ul>';

                Swal.fire({
                    icon: 'error',
                    title: t('global.error'),
                    html: msg,
                    showConfirmButton: true
                }).then(() => {
                    this.setState({
                        activePage: 1,
                        data: [],
                        totalRecords: 0,
                        loading: false,
                    });
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown!==undefined)
        {
            if(nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === 'success')
            {
                this.setState({
                    dropDown:
                    {
                        status: nextProps.dropDown.data.status,
                        transactionChannel: nextProps.dropDown.data.transactionChannel
                    }
                });
            }
        }

        // if (nextProps.sysAlert !== this.props.sysAlert && nextProps.sysAlert!==undefined)
        // {
        //     if(nextProps.sysAlert.responseCode === 200 && nextProps.sysAlert.msgType === 'success')
        //     {
        //         if(nextProps.sysAlert.data.alert)
        //         {
        //             const { t } = this.props;
        //             Swal.fire({
        //                 icon: 'warning',
        //                 text: t('transaction.new-order'),
        //                 showConfirmButton: true
        //             }).then(() =>
        //             {
        //                 this.refreshHandler();
        //             });
        //         }
        //     }
        // }

        return true;
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    // sysAlert = () =>
    // {
    //     const listingArr = {};
    //     listingArr.name = 'withdrawal';
    //     this.props.funcSysAlert(listingArr);
    // }

    onInputChange(event)
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        if(event.target.id==='transaction_date')
        {
            const date = (event.target.value!=='') ? event.target.value.split(' - ') : null;
            newFilter['start_date'] = (date) ? date[0] : '';
            newFilter['end_date'] = (date) ? date[1] : '';
        }
        this.setState({
            filter: newFilter
        });
    }

    clearHandler = () =>
    {
        let newFilter = initialInputState;
        this.setState({
            activePage: 1,
            filter: newFilter,
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    detailsHandler = (e, id) =>
    {
        // console.log('detailsHandler (transaction): ', id);
        e.preventDefault();
        // clearInterval(this.intervalId);
        this.setState({
            pageShow: 'edit',
            detailsParam :
            {
                id: id
            }
        });
    }

    refreshHandler = () =>
    {
        this.setState({
            loading: true,
            data: [],
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    getFilterArray = () =>
    {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        Object.keys(this.state.filter).forEach((val) =>
        {
            // listingArr[val] = this.state.filter[val];
            listingArr[val] = ((val==='start_date' || val==='end_date') && this.state.filter[val]!=='') ? moment(this.state.filter[val]).format('YYYY-MM-DD HH:mm:ss') : this.state.filter[val];
        });
        return listingArr;
    }

    changePageHandler = (page) =>
    {
        // console.log(page);
        // if(page==='listing')
        // {
        //     this.intervalId = setInterval(() => {this.sysAlert();}, this.state.intervalTime*1000);
        // }
        this.setState({
            pageShow: page,
        });
    }
    /* ============ function [END] ============ */

    /* ============ datatables function [START] ============ */
    datatablesChangeHandler = (val, act) =>
    {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act==='searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }

    onSort = (field, order) =>
    {
        const orderArry = [{}];
        let orderSequence = (order === 'asc') ? true : false ;

        orderArry[0]['id'] = field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () =>
        {
            const listingArr = this.getFilterArray();
            this.props.funcGetList(listingArr);
        });
    }
    /* ============ datatables function [END] ============ */

    // redirect agent profile
    redirectHandler = (e, id, name) => {
        e.preventDefault();
        this.setState({
            pageShow: "redirect",
            redirectParam: {
                id: id,
                name: name
            }
        });
    }

    render()
    {
        return (
            <Auxiliary>
                {this.state.pageShow==='listing' &&
                    <AgentWithdrawalListing
                        /* filter input */
                        filter={this.state.filter}

                        /* setstate function */
                        changePageHandler={(page) => this.changePageHandler(page)}
                        clearHandler={() => this.clearHandler()}
                        refreshHandler={() => this.refreshHandler()}
                        detailsHandler={(event, id) => this.detailsHandler(event, id)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        onSort={(field, order) => this.onSort(field, order)}
                        redirectHandler={(event,id,name)=>this.redirectHandler(event,id,name)}

                        /* datatables data */
                        data={this.state.data}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}

                        /* filter drop down */
                        ddStatus={this.state.dropDown.status}
                        ddTransactionChannel={this.state.dropDown.transactionChannel}

                        /* skeleton loading */
                        loading={this.state.loading}

                        /* user session */
                        // currentUser={this.props.currentUser}
                        permission={this.props.currentUser.permission ?? []}
                    />
                }

                {this.state.pageShow==='edit' &&
                    <AgentWithdrawalEdit
                        detailsParam={this.state.detailsParam}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                }

                {this.state.pageShow==='redirect' &&
                    <AgentShow
                        agentShowId={this.state.redirectParam.id}
                        agentShowUsername={this.state.redirectParam.name}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        permission={this.props.currentUser.permission ?? []}
                        agentShowCount={0}
                    />
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.agentWithdrawal.rs,
        dropDown: state.agentWithdrawal.dropDown,
        currentUser: state.currentUser.data,
    }
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch =>
{
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
    }
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(Withdrawal));