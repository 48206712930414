import React, { Component } from "react";
import { connect } from "react-redux";
import { getEmailContents, getFilterData } from "../../store/ducks/emailContent/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import EmailContentListing from "./EmailContentListing";
import EmailContentCreate from "./EmailContentCreate";
import EmailContentShow from "./EmailContentShow";
import EmailContentEdit from "./EmailContentEdit";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen } from "@fortawesome/free-solid-svg-icons";
import { withNamespaces } from "react-i18next";
import { filterErrorHandler } from "../../hoc/Shared/utility";


const initialInputState = {
    filter_language: "",
};

class EmailContent extends Component {
    /* ======================== Component Lifecycle [START] ======================== */
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "listing",

            /* datatables / pagination */
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: "",
            totalPages: 0,
            totalRecords: 0,
            searchValue: "",
            search: "",
            filter: initialInputState,

            /* API result array */
            data: [],
            dropDown: {
                language_option: [],
            },
            /* popup box */
            popupLoading: false,

            emailContentShowId: null,
            emailContentEditId: null,
            loading: true,

            permission: [],
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getEmailContents(listingArr);
        this.props.getFilterData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[EmailContent] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let emailContentPermission = this.props.currentPermission.filter((item) => item.includes("email_content_"));
            this.setState({ permission: emailContentPermission });
        }

        if (nextProps.emailContentData !== this.props.emailContentData && nextProps.emailContentData !== undefined) {
            if (nextProps.emailContentData.data.responseCode === 200 && nextProps.emailContentData.data.msgType === 'success') {
                this.setState({
                    data: nextProps.emailContentData.data.data.result.row,
                    totalRecords: nextProps.emailContentData.data.data.result.pagination.total_record,
                    totalPages: nextProps.emailContentData.data.data.result.pagination.total_page,
                    loading: false,
                });
            } else{
                this.setState({
                    loading: false,
                    totalPages: 0,
                    activePage: 1,
                    totalRecords: 0,
                    data: []
                }, () => 
                {
                    filterErrorHandler(nextProps.emailContentData.data.message);
                });
            }
        }

        if (nextProps.emailContentFilterData !== this.props.emailContentFilterData && (nextProps.emailContentFilterData !== undefined && Object.keys(nextProps.emailContentFilterData).length > 0)) {
            if (nextProps.emailContentFilterData.data.responseCode === 200 && nextProps.emailContentFilterData.data.msgType === "success") {
                this.setState({
                    dropDown: {
                        language_option: nextProps.emailContentFilterData.data.data.language,
                    },
                });
            } else {
                this.setState({
                    loading: false
                }, () => 
                {
                    filterErrorHandler(nextProps.emailContentFilterData.data.message);
                });

                Swal.close();
            }
        }
        return true;
    }
    /* ======================== Component Lifecycle [END] ======================== */

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;
        this.setState({
            filter: newFilter,
        });
    }

    loadingHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };

    refreshHandler = () => {
        this.setState({
            loading: true,
            data: [],
            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: ""
        }, ()=>
        {
            const listingArr = this.getFilterArray();
            this.props.getEmailContents(listingArr);
        });
    };

    clearHandler = () => {
        let newFilter = initialInputState;
        this.setState(
            {
                activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
                limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
                order: "",
                searchValue: "",
                filter: newFilter,
                loading: true,
                data: []
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getEmailContents(listingArr);
            }
        );
    };

    showHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            emailContentShowId: id,
            pageShow: "show",
        });
    };

    editHandler = (e, id) => {
        // this.loadingHandler();
        e.preventDefault();
        this.setState({
            emailContentEditId: id,
            pageShow: "edit",
        });
    };

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.order = JSON.stringify(this.state.order === "" ? [{"id":"updatedTime", "desc":false}] : this.state.order);

        listingArr.country_id = this.props.currentCountryId;

        Object.keys(this.state.filter).forEach((val) => {
            listingArr[val] = this.state.filter[val];
        });
        return listingArr;
    };

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
            popupLoading: true,
        });
    };
    /* ======================== Function [END] ======================== */

    /* ======================== Datatables Function [START] ======================== */
    datatablesChangeHandler = (val, act) => {
        this.setState(
            {
                loading: true,
                data: [],
                [act]: act === "searchValue" ? val.target.value : val,
            },
            () => {
                const listingArr = this.getFilterArray();
                this.props.getEmailContents(listingArr);
            }
        );
    };

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = order === "asc" ? true : false;

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ order: orderArry }, () => {
            const listingArr = this.getFilterArray();
            this.props.getEmailContents(listingArr);
        });
    };

    noFormatter = (cell, row, rowIndex) => {
        //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
        let rowNumber = (this.state.totalRecords - rowIndex - ((this.state.activePage - 1) * this.state.limit))
        return <span>{rowNumber}</span>;
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;
        if (this.state.permission.includes("email_content_view")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    key={`view-${row.emailContentId}`}
                    idValue={row.emailContentId === undefined ? 0 : row.emailContentId}
                    clicked={(event) => this.showHandler(event, row.emailContentId)}
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                />
            );
        }

        if (this.state.permission.includes("email_content_update")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    key={`edit-${row.emailContentId}`}
                    idValue={row.emailContentId === undefined ? 0 : row.emailContentId}
                    clicked={(event) => this.editHandler(event, row.emailContentId)}
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                />
            );
        }

        output = [viewButton, editButton];
        return output;
    };

    searchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1}, () => {
            const listingArr = this.getFilterArray();
            this.props.getEmailContents(listingArr);
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1}, () => {
                const listingArr = this.getFilterArray();
                this.props.getEmailContents(listingArr);
            });
        }
    };

    /* ============ Datatables Function [END] ============ */

    render() {
        const { t } = this.props;
        let dataListing = [];
        let ddLanguage = [];

        const columns = [
            { text: t("global.no"), dataField: "no", formatter: this.noFormatter, headerStyle: { width: "60px" } },
            { text: t("email-content.email-type"), dataField: "type", sort: true, onSort: this.onSort },
            { text: t("email-content.email-subject"), dataField: "subject", sort: true, onSort: this.onSort },
            { text: t("global.updated-time"), dataField: "updatedTime", sort: true, onSort: this.onSort },
            { text: t("global.action"), dataField: "#", formatter: this.actionFormatter, headerStyle: { width: "115px" } },
        ];

        if (this.state.pageShow === "listing" && this.state.data.length > 0) {
            dataListing = this.state.data.map((val, idx) => {
                return {
                    emailContentId: val.emailContentId,
                    type: val.type,
                    subject: val.subject,
                    updatedTime: val.updatedTime,
                };
            });
        }

        if (this.state.dropDown.language_option) {
            ddLanguage = this.state.dropDown.language_option.map((val, idx) => {
                return (
                    <option key={val.languageId} value={val.languageId}>
                        {val.language}
                    </option>
                );
            });
        }

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <EmailContentListing
                        filter={this.state.filter}
                        refreshHandler={() => this.refreshHandler()}
                        clearHandler={() => this.clearHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        onInputChange={(event) => this.onInputChange(event)}
                        datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
                        columns={columns}
                        dataListing={dataListing}
                        ddLanguage={ddLanguage}
                        activePage={this.state.activePage}
                        limit={this.state.limit}
                        totalRecords={this.state.totalRecords}
                        searchValue={this.state.searchValue}
                        searchChangeHandler={this.searchChangeHandler}
                        postSearchHandler={this.postSearchHandler}
                        searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                        loading={this.state.loading}
                        permission={this.state.permission}
                    />
                )}

                {this.state.permission.includes("email_content_create") && this.state.pageShow === "create" && (
                    <EmailContentCreate popupLoading={this.state.popupLoading} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.permission.includes("email_content_view") && this.state.pageShow === "show" && <EmailContentShow emailContentShowId={this.state.emailContentShowId} changePageHandler={(page) => this.changePageHandler(page)} />}

                {this.state.permission.includes("email_content_update") && this.state.pageShow === "edit" && (
                    <EmailContentEdit emailContentEditId={this.state.emailContentEditId} refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}
            </Auxiliary>
        );
    }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        emailContentData: state.emailContent.email_content_data,
        emailContentFilterData: state.emailContent.email_content_filter_data,
        currentPermission: state.currentUser.data.permission,
        currentCountryId: state.currentUser.data.country_id,
    };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getEmailContents: (data) => dispatch(getEmailContents(data)),
        getFilterData: () => dispatch(getFilterData()),
    };
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EmailContent));
