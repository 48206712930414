import * as type from './types';
import { createReducer } from "../../util/index";

const initialState =
{
    data: [],
};

const gameCategoryReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.LIST]: (state, action) => ({
        ...state,
        rs: action.value,
    }),
    [type.DROPDOWN]: (state, action) => ({
        ...state,
        dropDown: action.value,
    }),
    [type.UPDATE_STATUS]: (state, action) => ({
        ...state,
        updateStatus: action.value,
    }),
    [type.EDIT_GAME_CATEGORY]: (state, action) => ({
        ...state,
        editGameCategory: action.value,
    }),
    [type.UPDATE_GAME_CATEGORY]: (state, action) => ({
        ...state,
        updateGameCategory: action.value,
    }),
});

export default gameCategoryReducer;