import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getBanks = (data) => dispatch => {
    axios.get('bank/list', {
        params: data
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_BANK_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getDropDown = () => dispatch =>
{
    axios.get('bank/drop_down')
    .then((response) =>
    {
      dispatch (
      {
        type: type.DROPDOWN,
        value: response.data
      })
    })
    .catch(err => 
    {
      console.warn(err);
    });
};

export const postBank = (data) => dispatch => {
    axios.post('bank/store',data)
    .then( (response) => {
        dispatch ({
            type: type.POST_BANK,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const showBank = (data) => dispatch => {
    axios.get('bank/show', {
        params: {
            "id": data['bank_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_BANK,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateBank = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bank/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_BANK,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteBank = (data) => dispatch => {
    axios.post('bank/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_BANK,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateBankStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bank/changeStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_BANK,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const updateMaintenanceStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post('bank/changeMaintenanceStatus', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_MAINTENANCE_STATUS_BANK,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};
