import * as type from './types';
import axios from '../../../common/config/axios';


//Call the api for example call the login api
export const getCrypto = (data) => dispatch => {
    axios.get('crypto/list', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            'searchFilter': data['searchFilter']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_CRYPTO_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const getCryptoList = (data) => dispatch => {
    axios.get('crypto/get_crypto').then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_CRYPTO,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })

};

export const postCrypto = (data) => dispatch => {
    axios.post('crypto/store',data)
    .then( (response) => {
        dispatch ({
            type: type.POST_CRYPTO,
            payload: response.data
        })
    }).catch (err => {
        console.warn(err);
    })
};

export const updateCrypto = (data) => dispatch => {
    axios.post('crypto/update',data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_CRYPTO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const deleteCrypto = (data) => dispatch => {
    axios.post('crypto/delete',data)
        .then( (response) => {
            dispatch ({
                type: type.DELETE_CRYPTO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};

export const showCrypto = (data) => dispatch => {
    axios.get('crypto/show', {
        params: {
            "id": data['id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.SHOW_CRYPTO,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

export const updateCryptoStatus = (data) => dispatch => {
    axios.post('crypto/change_status', data)
        .then( (response) => {
            dispatch ({
                type: type.UPDATE_STATUS_CRYPTO,
                payload: response.data
            })
        }).catch (err => {
        console.warn(err);
    })
};