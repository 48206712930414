import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {showPopup} from "../../store/ducks/popups/actions";
import {getCountry} from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import { withNamespaces } from 'react-i18next';
import DomPurify from "../../components/Sanitize/DomPurify";

//Initialize the language array first
let langArr = [];

class PopupsShow extends Component {
    state = {
        desktop_image_preview: [],
        mobile_image_preview: [],
        title:[],
        content:[],
        desktop_image_name: [],
        mobile_image_name: [],
        desktop_image_link: [],
        mobile_image_link: [],
        start_time: '',
        end_time: '',
        status: '',
        loading: true
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;

        const showPopupData = {};
        showPopupData.popup_id = this.props.show_popup_id;
        this.props.showPopup(showPopupData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_popup_data !== this.props.show_popup_data) {
            if (nextProps.show_popup_data.data.responseCode === 200) {
                const {
                    desktop_image_preview,
                    mobile_image_preview,
                    title,
                    content,
                    desktop_image_name,
                    mobile_image_name,
                    desktop_image_link,
                    mobile_image_link,
                }
                = this.state;

                nextProps.show_popup_data.data.data.map((item, i) => {
                    desktop_image_preview[item.lang_id] = item.desktop_image;
                    mobile_image_preview[item.lang_id] = item.mobile_image;
                    title[item.lang_id] = item.title;
                    content[item.lang_id] = item.content;
                    desktop_image_name[item.lang_id] = item.desktop_image_name;
                    mobile_image_name[item.lang_id] = item.mobile_image_name;
                    desktop_image_link[item.lang_id] = item.desktop_image_link;
                    mobile_image_link[item.lang_id] = item.mobile_image_link;

                    return item;
                })

                this.setState({
                    loading: false,
                    desktop_image_preview,
                    mobile_image_preview,
                    desktop_image_name,
                    mobile_image_name,
                    title,
                    content,
                    desktop_image_link,
                    mobile_image_link,
                    start_time: nextProps.show_popup_data.data.data[0].start_time,
                    end_time: nextProps.show_popup_data.data.data[0].end_time,
                    status: nextProps.show_popup_data.data.data[0].status
                })
            } else {
                return false;
            }
        }

        if (nextProps.country_data !== this.props.country_data) {
            let data = nextProps.country_data.data.language;

            this.setState({loading: false});
            //Clear the languages array
            langArr = [];

            Object.keys(data).map((item, i) => {
                return langArr.push({
                    'langId': data[item].languageId,
                    'langCode': data[item].languageCode,
                    'langName': data[item].languageName
                });
            })
        }

        return true;
    }

    render() {
        const { t } = this.props;

        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;


        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="desktop_image">{t('popup.desktop-image')}</label>

                        <div className="imgPreview popup-preview-image">
                            <img
                                alt=""
                                className="show-popup-size"
                                src={this.state.desktop_image_preview[item.langId]}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group" key={item.langId}>
                        <label htmlFor="mobile_image">{t('popup.mobile-image')}</label>

                        <div className="imgPreview popup-preview-image">
                            <img
                                alt=""
                                className="show-popup-size"
                                src={this.state.mobile_image_preview[item.langId]}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.title')}</label>
                        <p><strong>{this.state.title[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.content')}</label>
                        <DomPurify text={this.state.content[item.langId]} />
                        {/* <p dangerouslySetInnerHTML={{ __html: this.state.content[item.langId] }}></p> */}
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.desktop-image-name')}</label>
                        <p><strong>{this.state.desktop_image_name[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.mobile-image-name')}</label>
                        <p><strong>{this.state.mobile_image_name[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.desktop-image-link')}</label>
                        <p><strong>{this.state.desktop_image_link[item.langId]}</strong></p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label>{t('popup.mobile-image-link')}</label>
                        <p><strong>{this.state.mobile_image_link[item.langId]}</strong></p>
                    </div>
                </div>


            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('popup.popup')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-xl-auto right-content">
                        <div className="mb-3 mb-xl-0">
                            <div className="btn-group">
                                <div className="pr-1 mb-3 mb-xl-0">
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-secondary ml-1"
                                        idValue="back"
                                        buttonName={t("global.back-to-list")}
                                        clicked={() => this.props.changePageHandler("listing")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('popup.show-popup')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr className="show-popup-white">
                                            <th>{t('popup.popup-details')}</th>
                                            <td field-key='popup_details'>
                                                {tabMessage}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('popup.start-time')}</th>
                                            <td field-key='start_time'>{this.state.start_time}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('popup.end-time')}</th>
                                            <td field-key='end_time'>
                                                {this.state.end_time}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('popup.status')}</th>
                                            <td field-key='status'>{this.state.status === 1 ? "ACTIVE" : "INACTIVE"}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const {home, popup} = state;

    return {
        country_data: home.country_data,
        show_popup_data: popup.show_popup_data
    }
};

const mapDispatchToProps = {
    showPopup,
    getCountry
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PopupsShow));