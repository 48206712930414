import axios from "../../../../common/config/axios";
import * as type from "./types";

export const getListing = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.get("event_qna_setting/get_listing", {params: data})
    .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event QNA Setting Error", err);
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getSettingByID = (data) => (dispatch, getState) => {
    axios.get("event_qna_setting/get_setting_by_id", {params: data})
    .then((res) => {
            dispatch({
                type: type.SETTING_DATA,
                payload: res,
            });
        }).catch((err) => {
            console.log("Event QNA Setting Error", err);
            dispatch({
                type: type.SETTING_DATA,
                payload: [],
            });
        });
};

export const create = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_setting/create", data)
        .then((res) => {
            dispatch({
                type: type.CREATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Create Event QNA Setting Error\n", err);
            dispatch({
                type: type.CREATE,
                payload: err.data,
            });
        });
};

export const update = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("event_qna_setting/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Event QNA Setting Error\n", err);
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};