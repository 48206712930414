import axios from '../../../common/config/axios';
import * as type from "../log/types";

//Call the api for example call the login api
export const getLogs = (data) => dispatch => {
    axios.get('logs/logs', {
        params: {
            "page": data['page'],
            "limit": data['limit'],
            "order": data['order'],
            "search": data['search'],
            "country_id": data['country_id']
        }
    }).then((response) => {
        //Dispatch the current page , current limit and search value to reducers
        dispatch({
            type: type.GET_LOG_LIST,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};

// Redux Loading For Log Module
export const setLoading = (data) => (dispatch) => {
    dispatch({
        type: type.SET_LOADING,
        payload: data
    })
}

// Redux Set Datatable Filter For Fetching API
export const setDataTableFilter = (data) => (dispatch) => {
    dispatch({
        type: type.SET_DATATABLE_FILTER,
        filterList: data,
    })
}

export const getTransactionLogs = (data) => (dispatch, getState) => {
    axios
        .get("logs/transaction_logs", {
            params: {
                ...data,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.TRANSACTION_LOG_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Transaction Log Error" + err);
            dispatch({
                type: type.TRANSACTION_LOG_LIST,
                payload: [],
            });
        });
};

export const getBankAccountLogs = (data) => (dispatch, getState) => {
    axios
        .get("logs/bank_account_logs", {
            params: {
                ...data,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.BANK_ACCOUNT_LOG_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Bank Account Log Error" + err);
            dispatch({
                type: type.BANK_ACCOUNT_LOG_LIST,
                payload: [],
            });
        });
};

export const getMemberLogs = (data) => (dispatch, getState) => {
    axios
        .get("logs/member_logs", {
            params: {
                ...data,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_LOG_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Log Error" + err);
            dispatch({
                type: type.MEMBER_LOG_LIST,
                payload: [],
            });
        });
};

export const getAdminLogs = (data) => (dispatch, getState) => {
    axios
        .get("logs/admin_logs", {
            params: {
                ...data,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.ADMIN_LOG_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Admin Log Error" + err);
            dispatch({
                type: type.ADMIN_LOG_LIST,
                payload: [],
            });
        });
};