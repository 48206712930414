import axios from "../../../common/config/axios";
import * as type from "./types";

export const getMembers = (data) => (dispatch) => {
    axios
        .get("member/list", {params: data})
        .then((res) => {
            dispatch({
                type: type.MEMBER_LIST,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Error" + err);
            dispatch({
                type: type.MEMBER_LIST,
                payload: [],
            });
        });
};

export const getFilterData = () => (dispatch, getState) => {
    axios
        .get("member/get_member_filter_data", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_GET_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Error" + err);
            dispatch({
                type: type.MEMBER_GET_FILTER_DATA,
                payload: [],
            });
        });
};

/***********************************************************************************************************************************************/
/****************************************************************** Member Show ******************************************************************/
/***********************************************************************************************************************************************/

export const showMember = (id) => (dispatch, getState) => {
    axios
        .get("member/show", {
            params: {
                member_id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_SHOW,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Member Error" + err);
            dispatch({
                type: type.MEMBER_SHOW,
                payload: err.response,
            });
        });
};


export const getMemberTransactionHistory = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        // console.log("Member Parameters \n",props);
        params = str.toString();
        // console.log("Member Parameters Processed \n",params);
    }
    axios
        .get("member/member_transaction_history?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_TRANSACTION_HISTORY,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Transaction History Error" + err);
            dispatch({
                type: type.MEMBER_TRANSACTION_HISTORY,
                payload: [],
            });
        });
};

export const getTransactionHistoryFilterData = () => (dispatch) => {
    axios
        .get("member/get_member_transaction_histroy_filter_data")
        .then((res) => {
            dispatch({
                type: type.MEMBER_TRANSACTION_HISTORY_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Transaction History Filter Error" + err);
            dispatch({
                type: type.MEMBER_TRANSACTION_HISTORY_FILTER_DATA,
                payload: [],
            });
        });
};

export const getMemberBettingHistory = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("member/member_betting_history?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_BETTING_HISTORY,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Betting History Error" + err);
            dispatch({
                type: type.MEMBER_BETTING_HISTORY,
                payload: [],
            });
        });
};

export const getBettingHistoryFilterData = () => (dispatch) => {
    axios
        .get("member/get_member_betting_histroy_filter_data")
        .then((res) => {
            dispatch({
                type: type.MEMBER_BETTING_HISTORY_FILTER_DATA,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Transaction History Filter Error" + err);
            dispatch({
                type: type.MEMBER_BETTING_HISTORY_FILTER_DATA,
                payload: [],
            });
        });
};

export const getMemberLoginInfo = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("member/get_member_login_info?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_LOGIN_INFO,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Login Info Error" + err);
            dispatch({
                type: type.MEMBER_LOGIN_INFO,
                payload: [],
            });
        });
};

export const getMemberDepositCrypto = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("member/get_member_deposit_crypto_address?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_DEPOSIT_CRYPTO,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Deposit Crypto Error" + err);
            dispatch({
                type: type.MEMBER_DEPOSIT_CRYPTO,
                payload: [],
            });
        });
};

export const getMemberWithdrawalCrypto = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("member/get_member_withdrawal_crypto_address?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_WITHDRAWAL_CRYPTO,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Deposit Crypto Error" + err);
            dispatch({
                type: type.MEMBER_WITHDRAWAL_CRYPTO,
                payload: [],
            });
        });
};

export const getMemberLevelTracker = (id) => (dispatch, getState) => {
    axios
        .get("member/get_member_level_tracker", {
            params: {
                member_id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Member Level Tracker Error" + err);
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER,
                payload: err.response,
            });
        });
};

export const getMemberLevelTrackerHistory = (props) => (dispatch) => {
    let params = "";

    if (props) {
        const str = new URLSearchParams(props);
        params = str.toString();
    }
    axios
        .get("member/get_member_level_tracker_history?" + params)
        .then((res) => {
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_HISTORY,
                payload: res,
            });
        })
        .catch((err) => {
            console.log("Member Level Tracker History Error" + err);
            dispatch({
                type: type.MEMBER_LEVEL_TRACKER_HISTORY,
                payload: [],
            });
        });
};

export const getMemberWalletTracker = (props) => (dispatch) => {
    let params = "";
    if(props){
        const str = new URLSearchParams(props);
        params = str.toString();
    }

    axios
    .get('member/member_wallet_tracker?' + params)
    .then((res)=>{
        dispatch({
            type: type.MEMBER_WALLET_TRACKER,
            payload: res
        });
    })
    .catch((err)=>{
        dispatch({
            type: type.MEMBER_WALLET_TRACKER,
            payload: []
        });
    });
}

export const getMemberCoinTracker = (props) => (dispatch) => {
    let params = "";
    if(props){
        const str = new URLSearchParams(props);
        params = str.toString();
    }

    axios.get("member/member_coin_tracker?" + params)
    .then((res) => {
        dispatch({
            type: type.MEMBER_COIN_TRACKER,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: type.MEMBER_COIN_TRACKER,
            payload: []
        })
    });
}

export const getMemberAddress = (props) => (dispatch) => {
    let params = "";
    if(props){
        const str = new URLSearchParams(props);
        params = str.toString();
    }

    axios.get("member/get_member_addresses?" + params)
    .then((res) => {
        dispatch({
            type: type.MEMBER_ADDRESS,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: type.MEMBER_ADDRESS,
            payload: []
        });
    });
}

export const getMemberBillingAddress = (props) => (dispatch) => {
    let params = "";
    if(props){
        const str = new URLSearchParams(props);
        params = str.toString();
    }

    axios.get("member/get_member_billing_addresses?" + params)
    .then((res) => {
        dispatch({
            type: type.MEMBER_BILLING_ADDRESS,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: type.MEMBER_BILLING_ADDRESS,
            payload: []
        });
    });
}

export const getMemberParcel = (props) => (dispatch) => {
    let params = "";
    if(props){
        const str = new URLSearchParams(props);
        params = str.toString();
    }

    axios.get("member/get_member_parcel?" + params)
    .then((res) => {
        dispatch({
            type: type.MEMBER_PARCEL,
            payload: res
        });
    })
    .catch((err) => {
        dispatch({
            type: type.MEMBER_PARCEL,
            payload: []
        });
    });
}

/********************************************************** Member Show Init Loading ***********************************************************/
/***********************************************************************************************************************************************/
export const initMemberShowInitialLoading = (data) => (dispatch) => {
    dispatch({
        type: type.INIT_MEMBER_SHOW_INITIAL_LOADING,
        payload: data,
    });
}

export const setMemberShowInitialLoading = (data) => (dispatch) => {
    dispatch({
        type: type.SET_MEMBER_SHOW_INITIAL_LOADING,
        page: data,
    });
}

/******************************************************** Member Show Set Local State **********************************************************/
/***********************************************************************************************************************************************/
export const setMemberShowLocalState = (data) => (dispatch) => {
    let currentPage = data.currentPage;
    switch (currentPage) {
        case "member_info":
            dispatch({
                type: type.SET_MEMBER_INFO_LOCAL_STATE,
                payload: data,
            });
            break;
        case "transaction_history":
            dispatch({
                type: type.SET_MEMBER_TRANSACTION_HISTORY_LOCAL_STATE,
                payload: data,
            });
            break;
        case "betting_history":
            dispatch({
                type: type.SET_MEMBER_BETTING_HISTORY_LOCAL_STATE,
                payload: data,
            });
            break;
        case "login_info":
            dispatch({
                type: type.SET_MEMBER_LOGIN_INFO_LOCAL_STATE,
                payload: data,
            });
            break;
        case "crypto_address":
            dispatch({
                type: type.SET_MEMBER_CRYPTO_ADDRESS_LOCAL_STATE,
                payload: data,
            });
            break;
        case "level_tracker":
            dispatch({
                type: type.SET_MEMBER_LEVEL_TRACKER_LOCAL_STATE,
                payload: data,
            });
            break;
        case "wallet_tracker":
            dispatch({
                type: type.SET_MEMBER_WALLET_TRACKER_LOCAL_STATE,
                payload: data
            });
            break;
        case "coin_tracker":
            dispatch({
                type: type.SET_MEMBER_COIN_TRACKER_LOCAL_STATE,
                payload: data
            });
            break;
        case "member_address":
            dispatch({
                type: type.SET_MEMBER_ADDRESS_LOCAL_STATE,
                payload: data
            });
            break;
        case "member_parcel":
            dispatch({
                type: type.SET_MEMBER_PARCEL_LOCAL_STATE,
                payload: data
            });
            break;
        default:
            console.log("Warning - Kindly added local state to redux");
            return true;
    }
}
/***********************************************************************************************************************************************/
/****************************************************************** Member Create ******************************************************************/
/***********************************************************************************************************************************************/

export const createMember = () => (dispatch, getState) => {
    axios
        .get("member/create", {
            params: {
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_CREATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Create Member Error" + err);
            dispatch({
                type: type.MEMBER_CREATE,
                payload: err.response,
            });
        });
};

export const storeMember = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member/store", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Member Error" + err);
        });
};


/***********************************************************************************************************************************************/
/****************************************************************** Member Edit ******************************************************************/
/***********************************************************************************************************************************************/

export const editMember = (id) => (dispatch, getState) => {
    axios
        .get("member/edit", {
            params: {
                member_id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_EDIT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Member Error" + err);
        });
};

export const editMemberAccountDetails = (id) => (dispatch, getState) => {
    axios
        .get("member/edit_account_details", {
            params: {
                member_id: id,
                country_id: getState().currentUser.data.country_id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_EDIT_ACCOUNT_DETAILS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Member Account Details Error" + err);
        });
};

export const editMemberBankDetails = (data) => (dispatch, getState) => {
    axios
        .get("member/edit_bank_details", {
            params: {
                member_id: data["id"],
                country_id: getState().currentUser.data.country_id,
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_EDIT_BANK_DETAILS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Edit Member Bank Error" + err);
        });
};

export const getMemberWalletBalance = (id) => (dispatch) => {
    axios
        .get("member/get_member_balance", {
            params: {
                member_id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: type.MEMBER_WALLET_BALANCE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Member Error" + err);
            dispatch({
                type: type.MEMBER_WALLET_BALANCE,
                payload: err.response,
            });
        });
};

export const updateAccountDetails = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member/update_account_details", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_UPDATE_ACCOUNT_DETAILS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Member Update Account Error" + err);
        });
};

export const addBankDetails = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member/add_member_bank_details", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_ADD_BANK_DETAILS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Member Error" + err);
        });
};

export const updateMemberBankStatus = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member/update_member_bank_status", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_UPDATE_MEMBER_BANK_STATUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Member Bank Error Error" + err);
        });
};

// export const deleteBankDetails = (data) => (dispatch) => {
//     axios
//         .post("member/delete_member_bank_details", data)
//         .then((res) => {
//             dispatch({
//                 type: type.MEMBER_DELETE_BANK_DETAILS,
//                 payload: res.data,
//             });
//         })
//         .catch((err) => {
//             console.log("Store Member Error" + err);
//             dispatch({
//                 type: type.SET_VALIDATION,
//                 payload: [],
//             });
//         });
// };

export const storeWalletAdjustment = (data) => (dispatch) => {
    axios
        .post("member/store_wallet_adjustment", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_STORE_WALLET_ADJUSTMENT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Member Error" + err);
        });
};

export const updateResetPassword = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("member/update_reset_password", data)
        .then((res) => {
            dispatch({
                type: type.MEMBER_UPDATE_RESET_PASSWORD,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Store Member Error" + err);
        });
};

// export const getAgentDropdown = async (data) => {
//     let response;
//     try {
//         response = await axios.get("member/get_agent_dropdown", { params: data });
//     } catch (e) {
//         throw new Error(e.message);
//     }

//     return response.data ? response.data : null;
// };

export const clearMemberState = (data) => (dispatch) => {
    dispatch({
        type: type.CLEAR_MEMBER_STATE,
        payload: null,
    });
};

export const getMemberGameAccess = (data) => (dispatch) => {
    axios.get("member/game_access_list", {params: data})
    .then((res) => {
        dispatch({
            type: type.MEMBER_GAME_ACCESS,
            payload: res.data
        });
    })
    .catch((err) => {
        console.log("[getMemberGameAccess]" + err);
        // dispatch({
        //     type: type.MEMBER_GAME_ACCESS,
        //     payload: err.response
        // });
    });
} 

export const updateMemberGameAccess = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("member/update_game_access", data)
    .then((res) => {
        dispatch({
            type: type.MEMBER_UPDATE_GAME_ACCESS,
            payload: res.data
        });
    })
    .catch((err) => {
        console.log("[updateMemberGameAccess]" + err);
        // dispatch({
        //     type: type.MEMBER_UPDATE_GAME_ACCESS,
        //     payload: err.response
        // });
    });
}