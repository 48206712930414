import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import './PageSidebar.css';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
// import Logo from '../../assets/images/logo/x33-blue.png';
// import LogoWhite from '../../assets/images/logo/logo-white.png';
// import Favicon from '../../assets/images/logo/favicon.png';
// import FaviconWhite from '../../assets/images/logo/favicon-white.png';
// import ProfileIcon from '../../assets/images/profile/profileIcon.jpg';
import { GrClose } from 'react-icons/gr';
import Avatar from 'react-avatar';

import { getMenu } from "../../store/ducks/sideBar/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";

class PageSidebar extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            collapseId: '',
            active: '',
            menu: [],
        }
    }

    componentDidMount()
    {
        if(localStorage.getItem('accessToken'))
        {
            this.props.getMenu(this.props.currentUser.group_id);
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        // console.log('menu :', nextProps.rs);
        if (nextProps.rs !== this.props.rs && nextProps.rs!==undefined)
        {
            if(nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success')
            {
                // console.log(this.props.currentUser.permission)
                const loggedData = this.props.currentUser;
                loggedData.permission = nextProps.rs.data.permission;
                this.props.getUserData(loggedData);

                let collapseArr = [];
                let mainMenuArr = null;
                let menu = {};
                nextProps.rs.data.menu.mainModule.forEach((main) =>
                {
                    if(main.title !== "general_management") {
                        if(nextProps.rs.data.menu.subModule[main.id])
                        {
                            nextProps.rs.data.menu.subModule[main.id].forEach((sub) =>
                            {
                                collapseArr[sub.title] = main.title
                            });
                        }
                    }
                });
                // console.log(nextProps.rs.data.menu);
                mainMenuArr = nextProps.rs.data.menu.mainModule.filter(item => { return item.title !== "general_management"});
                menu = {
                    mainModule: mainMenuArr,
                    subModule: nextProps.rs.data.menu.subModule
                }
                let path = window.location.pathname.replace("/", "").toString().toLowerCase();
                let collapseId = (this.state.collapseId==='') ? collapseArr[path] : '';
                let active = (this.state.active==='') ? path : '';
                this.setState({
                    // menu: nextProps.rs.data.menu,
                    menu: menu,
                    collapseId: collapseId,
                    active: active
                });
            }
        }
        return true;
    }

    toggleExpandCollapse = (collapseId) =>
    {
        this.setState({
            collapseId: this.state.collapseId !== collapseId ? collapseId : ""
        });
    }

    toggleActive = (active) =>
    {
        // console.log(active);
        this.setState({
            active: this.props.active !== active ? active : ""
        });
    }


    render()
    {
        const { t } = this.props;
        let mainModule = (this.state.menu.mainModule) ? this.state.menu.mainModule : [];
        let subModule = (this.state.menu.subModule) ? this.state.menu.subModule : [];
        let currentUser = (this.props.currentUser) ? this.props.currentUser : [];

        return (
            <Auxiliary>
                <div className="app-sidebar__overlay active"
                style={{width: this.props.sideBar? "":"0", left: this.props.sideBar? "":"0"}}
                onClick={this.props.toggleSideBar}></div>

                <aside className="app-sidebar sidebar-scroll" style={{overflowX : 'auto', left : this.props.sideBar ? "0":""}}>

                    {/* <div className="main-sidebar-header active">
                        <a className="desktop-logo logo-light active" href="/home">
                            <img src={Logo} className="main-logo" alt="logo"/></a>
                        <a className="desktop-logo logo-dark active" href="/home">
                            <img src={LogoWhite} className="main-logo dark-theme" alt="logo"/>
                        </a>
                        <a className="logo-icon mobile-logo icon-light active" href="/home"><img
                            src={Favicon} className="logo-icon" alt="logo"/></a>
                        <a className="logo-icon mobile-logo icon-dark active" href="/home"><img
                            src={FaviconWhite} className="logo-icon dark-theme" alt="logo"/></a>
                    </div> */}
                    <div className="d-md-none d-flex justify-content-end p-2">
                        <GrClose style={{width:20, height:20}} onClick={this.props.toggleSideBar}/>
                    </div>

                    <div className="main-sidemenu">
                        <div className="app-sidebar__user clearfix">
                            <div className="dropdown user-pro-body">
                                {/* <div className="">
                                    <a href="/profile" onClick={()=> this.props.toggleSideBar()}>
                                        <img alt="user-img" className="avatar avatar-xl brround"
                                            src={ProfileIcon} />
                                        <span
                                            className="avatar-status profile-status bg-green"></span></a>
                                </div> */}

                                <div className="d-flex justify-content-center">
                                    <a href="/profile" onClick={()=> this.props.toggleSideBar()}>
                                        <div className="avatar avatar-xl sidebar-user-img">
                                            <Avatar name={currentUser.username} round="100px" size="55" color="#0162e8"/>
                                        </div>
                                        <span className="avatar-status profile-status bg-green"></span>
                                    </a>
                                </div>
                                {/* <div className="user-info">
                                    <a href="/profile" onClick={()=> this.props.toggleSideBar()}><h4
                                        className="font-weight-semibold mt-3 mb-0">{currentUser.username}</h4></a>
                                    <span className="mb-0 text-muted">{currentUser.user_group}</span>
                                </div> */}
                            </div>
                        </div>
                        <ul className="side-menu">
                            <li className="slide active">
                                <Link className="side-menu__item" to={{ pathname: '/home' }} onClick={()=> this.props.toggleSideBar()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" viewBox="0 0 24 24">
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path d="M5 5h4v6H5zm10 8h4v6h-4zM5 17h4v2H5zM15 5h4v2h-4z" opacity=".3"/>
                                        <path d="M3 13h8V3H3v10zm2-8h4v6H5V5zm8 16h8V11h-8v10zm2-8h4v6h-4v-6zM13 3v6h8V3h-8zm6 4h-4V5h4v2zM3 21h8v-6H3v6zm2-4h4v2H5v-2z"/>
                                    </svg>
                                    <span className="side-menu__label">{t('side-bar.dashboard')}</span>
                                </Link>
                            </li>
                            {
                                // menu list
                                mainModule.map((main, idx) =>
                                {
                                    let menuContent = null;
                                    let subMenuContent = [];
                                    if(main.role_id)
                                    {
                                        if(subModule[main.id])
                                        {
                                            subMenuContent = [];
                                            subModule[main.id].forEach((sub, idx1) =>
                                            {
                                                if(sub.role_id)
                                                {
                                                    subMenuContent.push(
                                                        <li key={idx1}>
                                                            <Link className={`slide-item ${ this.state.active===sub.title ? "active" : "" }`} to={{pathname: sub.link}} onClick={() => {this.toggleActive(sub.title);this.props.toggleSideBar()}}>
                                                                {`${idx+1}`}.{Number(idx1)+1} {t(`side-bar.${sub.title}`)}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            })
                                        }
                                        if(main.title !== "general_management") {
                                            menuContent =
                                            <li className={ this.state.collapseId===main.title ? "slide is-expanded" : "slide" } key={idx}>
                                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse(main.title)}><span className="side-menu__label">{(idx+1)+'. '+t(`side-bar.${main.title}`)}</span><i className="angle fe fe-chevron-down"></i></div>
                                                <ul className="slide-menu">
                                                    {subMenuContent}
                                                </ul>
                                            </li>;
                                        }
                                    }
                                    return menuContent;
                                })
                            }
                            {/* <li className={ this.state.collapseId==="transaction" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('transaction')}><span className="side-menu__label">1. Transaction</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='transaction' ? "active" : "" }`} to={{pathname: '/transaction'}} onClick={() => this.toggleActive('transaction')}>
                                            1.1  Transaction
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='deposit' ? "active" : "" }`} to={{pathname: '/deposit'}} onClick={() => this.toggleActive('deposit')}>
                                            1.2  Deposit
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='withdrawal' ? "active" : "" }`} to={{pathname: '/withdrawal'}} onClick={() => this.toggleActive('withdrawal')}>
                                            1.3  Withdrawal
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='wallet_adjustment' ? "active" : "" }`} to={{pathname: '/wallet_adjustment'}} onClick={() => this.toggleActive('wallet_adjustment')}>
                                            1.4  Wallet Adjustment
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="bank_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('bank_management')}><span className="side-menu__label">2. Banks</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='banks' ? "active" : "" }`} to={{pathname: '/banks'}} onClick={() => this.toggleActive('banks')}>
                                            2.1  Bank
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='banks_account' ? "active" : "" }`} to={{pathname: '/banks_account'}} onClick={() => this.toggleActive('banks_account')}>
                                            2.2 Bank Account
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='payment_gateway' ? "active" : "" }`} to={{pathname: '/payment_gateway'}} onClick={() => this.toggleActive('payment_gateway')}>
                                            2.3 Payment Gateway
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='bank_balance_adjustment' ? "active" : "" }`} to={{pathname: '/bank_balance_adjustment'}} onClick={() => this.toggleActive('bank_balance_adjustment')}>
                                            2.4 Bank Balance Adjustment
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={ this.state.collapseId==="member_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('member_management')}><span className="side-menu__label">3. Member Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='member' ? "active" : "" }`} to={{pathname: '/member'}} onClick={() => this.toggleActive('member')}>
                                            3.1  Member Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='member_group' ? "active" : "" }`} to={{pathname: '/member_group'}} onClick={() => this.toggleActive('member_group')}>
                                            3.2  Member Group
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={ this.state.collapseId==="admin_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('admin_management')}><span className="side-menu__label">4. Admin Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='admin' ? "active" : "" }`} to={{pathname: '/admin'}} onClick={() => this.toggleActive('admin')}>
                                            4.1  Admin
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='permission' ? "active" : "" }`} to={{pathname: '/permission'}} onClick={() => this.toggleActive('permission')}>
                                            4.2  Permission
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='role' ? "active" : "" }`} to={{pathname: '/role'}} onClick={() => this.toggleActive('role')}>
                                            4.3  Role
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={ this.state.collapseId==="agent_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('agent_management')}><span className="side-menu__label">5. Agent Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='agent' ? "active" : "" }`} to={{pathname: '/agent'}} onClick={() => this.toggleActive('agent')}>
                                            5.1  Agent Profile
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="marketing_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('marketing_management')}><span className="side-menu__label">6. Marketing Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">

                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='banners' ? "active" : "" }`} to={{pathname: '/banners'}} onClick={() => this.toggleActive('banners')}>
                                            6.1  Banner Announcement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='popups' ? "active" : "" }`} to={{pathname: '/popups'}} onClick={() => this.toggleActive('popups')}>
                                            6.2  Popup Announcement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='seo_management' ? "active" : "" }`} to={{pathname: '/seo_management'}} onClick={() => this.toggleActive('seo_management')}>
                                            6.3  SEO Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='floatimage' ? "active" : "" }`} to={{pathname: '/floatimage'}} onClick={() => this.toggleActive('floatimage')}>
                                            6.4  Floating Image
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='tournament' ? "active" : "" }`} to={{pathname: '/tournament'}} onClick={() => this.toggleActive('tournament')}>
                                            6.5  Tournament
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="wallet_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('wallet_management')}><span className="side-menu__label">7. Wallet Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='wallet_type' ? "active" : "" }`} to={{pathname: '/wallet_type'}} onClick={() => this.toggleActive('wallet_type')}>
                                            7.1  Wallet Type
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="promotion_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('promotion_management')}><span className="side-menu__label">8. Rebate</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='promotion' ? "active" : "" }`} to={{pathname: '/promotion'}} onClick={() => this.toggleActive('promotion')}>
                                            8.1  Promotion Setting
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='rebate_setting' ? "active" : "" }`} to={{pathname: '/rebate_setting'}} onClick={() => this.toggleActive('rebate_setting')}>
                                            8.2  Rebate Setting
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='member_rebate' ? "active" : "" }`} to={{pathname: '/member_rebate'}} onClick={() => this.toggleActive('member_rebate')}>
                                            8.3  Member Rebate
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="settings" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('settings')}><span className="side-menu__label">9. Settings</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='system_settings' ? "active" : "" }`} to={{pathname: '/system_settings'}} onClick={() => this.toggleActive('system_settings')}>
                                            9.1  System Settings
                                        </Link>
                                    </li>

                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='ip-address' ? "active" : "" }`} to={{pathname: '/ip-address'}} onClick={() => this.toggleActive('ip-address')}>
                                            9.2  IP Address
                                        </Link>
                                    </li>

                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='translation' ? "active" : "" }`} to={{pathname: '/translation'}} onClick={() => this.toggleActive('translation')}>
                                            9.3  Translation
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="product_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('product_management')}><span className="side-menu__label">10. Product Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className={`slide-item ${ this.state.active==='product_maintenance' ? "active" : "" }`} to={{pathname: '/product_maintenance'}} onClick={() => this.toggleActive('product_maintenance')}>
                                            10.1  Product Maintenance
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="log_management" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('log_management')}><span className="side-menu__label">11. Log Management</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                <li>
                                        <Link className={`slide-item ${ this.state.active==='logs' ? "active" : "" }`} to={{pathname: '/logs'}} onClick={() => this.toggleActive('logs')}>
                                            11.1  Logs
                                        </Link>
                                </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="message_board" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('message_board')}><span className="side-menu__label">12. Message Board</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                <li>
                                        <Link className={`slide-item ${ this.state.active==='announcement' ? "active" : "" }`} to={{pathname: '/announcement'}} onClick={() => this.toggleActive('announcement')}>
                                            12.1  Announcement
                                        </Link>
                                </li>
                                <li>
                                        <Link className={`slide-item ${ this.state.active==='email_content' ? "active" : "" }`} to={{pathname: '/email_content'}} onClick={() => this.toggleActive('email_content')}>
                                            12.2  Email Content
                                        </Link>
                                </li>
                                <li>
                                        <Link className={`slide-item ${ this.state.active==='inbox' ? "active" : "" }`} to={{pathname: '/inboxs'}} onClick={() => this.toggleActive('inbox')}>
                                            12.3  Inbox
                                        </Link>
                                </li>
                                </ul>
                            </li>
                            <li className={ this.state.collapseId==="demo" ? "slide is-expanded" : "slide" }>
                                <div className="side-menu__item" data-toggle="slide" onClick={() => this.toggleExpandCollapse('demo')}><span className="side-menu__label">Demo</span><i className="angle fe fe-chevron-down"></i></div>
                                <ul className="slide-menu">
                                    <li>
                                        <Link className="slide-item" to={{pathname: '/demo/datetimerange'}}>
                                            Date Time Range Picker
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="slide-item" to={{pathname: '/demo/redux_persist'}}>
                                            Redux Persist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="slide-item" to={{pathname: '/demo/react_helmet'}}>
                                            React Helmet
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="slide-item" to={{pathname: '/demo/react_helmet_async'}}>
                                            React Helmet Async
                                        </Link>
                                    </li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </aside>
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = state =>
{
    return {
        rs: state.sideBar.rs,
        currentUser: state.currentUser.data
    }
};

//Using the redux calling api methods
const mapDispatchToProps = 
{
    getMenu, 
    getUserData
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(PageSidebar));