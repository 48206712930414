import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const authReducer = createReducer(initialState) ({

    [type.POST_IS_AUTHENTICATED]: (state, action) => ({
        ...state,
        data: action.payload,
    }),

    [type.POST_LOGIN]: (state, action) => ({
        ...state,
        login_data: action.payload,
    }),

    [type.POST_LOG_OUT]: (state, action) => ({
        ...state,
        logout_data: action.payload,
    }),

    [type.CHANGE_PASSWORD]: (state, action) => ({
        ...state,
        change_password_data: action.payload,
    }),
    // [type.SET_USER]: (state, action) => ({
    //     ...state,
    //     current_user: action.payload,
    // }),

    // [type.CHANGE_COUNTRY]: (state, action) => ({
    //     ...state,
    //     current_user: action.payload,
    // }),

    [type.POST_IS_AUTHORIZED]: (state, action) => ({
        ...state,
        authorized_data: action.payload 
    })
});

export default authReducer;