import React from "react";
import PropTypes from "prop-types";
import { Tab as TabFromLibrary, Tabs, TabList, TabPanel } from "react-tabs";
import classes from "./Tab.module.css";
// Documentation: https://reactcommunity.org/react-tabs/

// const styles = {
//     listStyleType: "none",
//     cursor: "pointer",
//     display: "flex",
//     flexDirection: "row",
//     borderTop: "0",
//     flexWrap: "wrap",
// }

const Tab = (props) => {
    // console.log("Tab Debug",props.tabNav,props.tabPanel);
    return (
        <div className={`panel panel-primary tabs-style-2 mb-3 ${props.className}`}>
            <div className="tab-menu-heading">
                <Tabs className="tabs-menu1" defaultIndex={0}>
                    <TabList className="nav panel-tabs main-nav-line pl-3" as="ul" onClick={props.onChange}>
                            {props.tabNav.map((tab, index) => {
                                return (
                                    <TabFromLibrary key={index} className={`${classes.tabList} nav-link`} selectedClassName="active">
                                        {tab}
                                    </TabFromLibrary>
                                );
                            })}
                    </TabList>
                    <div className="panel-body tabs-menu-body main-content-body-right">
                        <div className="tab-content">
                            {props.tabPanel.map((tab, index) => {
                                return (
                                    <TabPanel key={index} className="tab-pane" selectedClassName="active">
                                        {tab}
                                    </TabPanel>
                                );
                            })}
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
};
export default Tab;

Tab.propTypes = {
    className: PropTypes.string,
    tabNav: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    tabPanel: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func
};
