import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('lucky_spin_history/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const getDropDown = () => dispatch =>
{
    axios.get('lucky_spin_history/drop_down')
    .then(function (response)
    {
        dispatch (
        {
            type: actionType.DROPDOWN,
            value: response.data
        })
    })
    .catch(err =>
    {

    });
};

export const getDetails = (id) => dispatch =>
{   
    if(id > 0)
    {
        axios.get('lucky_spin_history/details',{
            params: {
                "id": id,
            }
        })
        .then(function (response) 
        {
            dispatch (
            {
                type: actionType.DETAILS,
                value: response.data
            })
        })
        .catch(function (error) 
        {
        
        });
    }
};

export const update = (data) => dispatch =>
{
    // console.log(data);
    axios.post('lucky_spin_history/update', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.UPDATE,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};