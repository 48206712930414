import axios from "../../../common/config/axios";
import * as type from "./types";

export const getSocialMediaBlastList = (data) => (dispatch, getState) => {
    axios.get("social_media_blast/list", {
            params: {
                page: data["page"],
                limit: data["limit"],
                order: data["order"],
                search: data["search"],
                country_id: getState().currentUser.data.country_id
            },
        }).then((res) => {
            dispatch({
                type: type.LIST,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const getMembers = (data) => (dispatch, getState) => {
    axios.get("social_media_blast/members", {
        }).then((res) => {
            dispatch({
                type: type.GET_MEMBERS,
                payload: res,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: [],
            });
        });
};

export const storeSocialMediaBlast = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios.post("social_media_blast/store", data)
        .then((res) => {
            dispatch({
                type: type.STORE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.STORE,
                payload: err.data,
            });
        });
};

export const getSocialMediaBlastByID = (data) => (dispatch) => {
    axios.get("social_media_blast/details", {params: data})
        .then((res) => {
            dispatch({
                type: type.LIST,
                payload: res.data,
            });
        }).catch((err) => {
            dispatch({
                type: type.LIST,
                payload: err.data,
            });
        });
};

export const updateSocialMediaBlast = (data) => (dispatch, getState) => {
    data.country_id = getState().currentUser.data.country_id;
    axios
        .post("social_media_blast/update", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: type.UPDATE,
                payload: err.data,
            });
        });
};

export const showSocialMediaBlast = (id) => (dispatch, getState) => {
    axios
        .get("social_media_blast/show", {
            params: {
                id: id
            },
        })
        .then((res) => {
            dispatch({
                type: type.SHOW,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Show Social Media Blast Error" + err);
            dispatch({
                type: type.SHOW,
                payload: err.response,
            });
        });
};

export const excelTemplate = (id) => (dispatch, getState) => {
    axios
        .get("social_media_blast/excel_template")
        .then((res) => {
            dispatch({
                type: type.EXCEL,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Excel Error" + err);
            dispatch({
                type: type.EXCEL,
                payload: err.response,
            });
        });
};