import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Select2 from "../../components/Input/Select2/Select2";
import Select from "../../components/Input/Select/Select";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import { postInbox } from "../../store/ducks/inbox/actions";
import { formValidation } from "../../hoc/Shared/utility";
import { getMemberGroup, getCategory } from "../../store/ducks/inbox/actions";
import { withNamespaces } from "react-i18next";
import JoditEditor from "jodit-react";
import ValidationError from "../../components/ValidationError/ValidationError";
import "jodit/build/jodit.min.css";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import moment from 'moment';
let langArr = [];

let categoryArry = [];
let userMemberArry = [];
class InboxCreate extends Component {
    state = {
        title: "",
        content: "",
        status: 0,
        category_id: [],
        currentGroupName: [],
        formFilter:
        {
            start_date: '',
        }
    };

    componentDidMount() {
        formValidation("inbox_form");
        const countryData = {};

        this.props.getMemberGroup();
        this.props.getCategory();
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_inbox_result !== this.props.post_inbox_result && nextProps.post_inbox_result !== undefined) {
            if (nextProps.post_inbox_result.responseCode === 200 && nextProps.post_inbox_result.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_inbox_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_inbox_result.message,
                });
                Swal.close();
            }
        }

        if (nextProps.member_group_data !== this.props.member_group_data && nextProps.member_group_data !== undefined) {
            if (nextProps.member_group_data.data.responseCode === 200 && nextProps.member_group_data.data.msgType === 'success') {
                userMemberArry = [];
                Swal.close();
                let data = nextProps.member_group_data.data.data;
                Object.keys(data).map((item, i) => {
                    return userMemberArry.push({
                        label: data[item].group_name,
                        value: data[item].Id,
                        key: data[item].Id,

                    });
                });
            }else{
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.member_group_data.data.message,
                });
                Swal.close();
            }
        }

        if (nextProps.category_data !== this.props.category_data && nextProps.category_data !== undefined) {
            if (nextProps.category_data.data.responseCode === 200 && nextProps.category_data.data.msgType === 'success') {
                categoryArry = [];
                const { t } = this.props;
                Swal.close();
                let data = nextProps.category_data.data.data;
                Object.keys(data).map((item, i) => {
                    return categoryArry.push({
                        label: t('inbox.' + data[item].title),
                        value: data[item].Id,
                        key: data[item].Id,

                    });
                });
            }else{
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.category_data.data.message,
                });
                Swal.close();
            }
        }

        if (nextProps.country_data !== this.props.country_data && nextProps.country_data !== undefined) {
            if (nextProps.country_data.data.responseCode === 200 && nextProps.country_data.data.msgType === 'success') {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        'langId': data[item].languageId,
                        'langCode': data[item].languageCode,
                        'langName': data[item].languageName
                    });
                })
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.country_data.data.message
                });
                Swal.close();
            }
        }

        return true;
    }

    onInputChange = (event, langId) =>
    {
        // console.log(event.target.id+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.formFilter);
        if(langId && langId > 0)
        {
            if(newInput.reward_details[langId])
            {
                newInput.reward_details[langId][event.target.id] = event.target.value;
            }
            else
            {
                let newLangArr = {[event.target.id]: event.target.value};
                newInput.reward_details[langId] = newLangArr;
            }
        }
        else
        {
            newInput[event.target.id] = event.target.value;
        }

        this.setState({
            formFilter: newInput,
        });
    }

    postInboxHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let formData = new FormData();

                const currentGroupName = this.state.currentGroupName;
                if (currentGroupName !== null && currentGroupName !== '') {
                    currentGroupName.forEach((itemGroupName) => {
                        formData.append("member_group[]", itemGroupName.value);
                    });
                }

                let startDate = this.state.formFilter.start_date ? moment(this.state.formFilter.start_date).format("yyyy-MM-DD HH:mm") : '';

                formData.append("title", this.state.title);
                formData.append("content", this.state.content);
                formData.append("status", 0);
                formData.append("category_id", this.state.category_id);
                formData.append("country_id", this.props.currentCountryId);
                formData.append('start_date',startDate);

                this.props.postInbox(formData);
            }
        });
    };

    getTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    };

    getMemberGroupHandler = (event) => {
        this.setState({ currentGroupName: event });
    };

    getCategoryHandler = (event) => {
        this.setState({ category_id: event.target.value });
    };

    render() {
        const { t } = this.props;

        let defaultType = [
            {
                label: t("inbox.please-select"),
                value: "",
                selected: true,
            },
        ];


        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("inbox.inbox")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("inbox.add-inbox")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="inbox_form" className="form-horizontal" onSubmit={this.postInboxHandler} noValidate>
                                        <ValidationError formName="inbox_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-12 form-group">
                                                <label htmlFor="title">{t("inbox.title")}</label>

                                                <StringInput onChange={(event) => this.getTitleHandler(event)} id="title" name="title" className="form-control" />
                                            </div>

                                            <div className="col-12 form-group">
                                                <label>{t("inbox.content")}</label>
                                                <JoditEditor
                                                    value={this.state.content}
                                                    onBlur={(newContent) => {
                                                        this.setState({
                                                            content: newContent,
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label>{t("inbox.category")}</label>
                                                <Select
                                                    id="category_id"
                                                    name="category_id"
                                                    className="form-control"
                                                    options={defaultType.concat(categoryArry)}
                                                    onChange={this.getCategoryHandler}
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="group">{t("inbox.member-group")}</label>
                                                <Select2
                                                    className="form-control"
                                                    options={userMemberArry}
                                                    name="member_group"
                                                    onChange={this.getMemberGroupHandler}
                                                    placeholder={t('global.please-select')}
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="group">{t("inbox.start-time")}</label>
                                                    <DateTimePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        className="form-control filter-input"
                                                        singleDatePicker={true}
                                                        timePicker={true}
                                                        value={this.state.formFilter.start_date}
                                                        onChange={this.onInputChange}
                                                    />
                                            </div>

                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <div>
                                                    <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, inbox, currentUser } = state;

    //Getting the state from the reducers
    return {
        country_data: home.country_data,
        post_inbox_result: inbox.post_inbox_result,
        category_data: inbox.category_data,
        member_group_data: inbox.member_group_data,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    getCountry,
    getMemberGroup,
    postInbox,
    getCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(InboxCreate));
