import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const bankReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_BANK_LIST]: (state = initialState, action) => ({
        ...state,
        bank_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.POST_BANK]: (state, action) => ({
        ...state,
        post_bank_result: action.payload,
    }),

    [type.SHOW_BANK]: (state, action) => ({
        ...state,
        show_bank_data: action.payload,
    }),

    [type.UPDATE_BANK]: (state, action) => ({
        ...state,
        update_bank_result: action.payload,
    }),

    [type.DELETE_BANK]: (state, action) => ({
        ...state,
        delete_bank_result: action.payload,
    }),

    [type.UPDATE_STATUS_BANK]: (state, action) => ({
        ...state,
        update_status_bank_result: action.payload,
    }),

    [type.UPDATE_MAINTENANCE_STATUS_BANK]: (state, action) => ({
        ...state,
        update_maintenance_status_result: action.payload,
    }),
});

export default bankReducer;