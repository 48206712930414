import React, {Component} from 'react';
import { withNamespaces } from "react-i18next";

import DataTable from "../../components/Datatable/DataTable";

class TournamentListing extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="row row-sm">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex justify-content-between">
                                <h4 className="card-title mg-b-0">{t("tournament.tournament")}</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="">
                                <button id="button" className="btn btn-danger mg-b-20 btn-multi-delete">
                                    {t("global.multi-delete")}
                                </button>

                                <DataTable
                                    keyField="tournament_id"
                                    data={this.props.data}
                                    columns={this.props.columns}
                                    activePage={this.props.activePage}
                                    limit={this.props.limit}
                                    totalItems={this.props.totalRecords}
                                    paginationHandler={this.props.pageChangeHandler}
                                    sorted={this.props.sorted}
                                    sizePerPageHandler={this.props.sizePerPageHandler}
                                    searchChangeHandler={this.props.searchChangeHandler}
                                    searchValue={this.props.searchValue}
                                    searchSubmitHandler={this.props.postSearchHandler}
                                    searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                    loading={this.props.loading}
                                    classValue="dataTable table-bordered text-justify text-center"
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withNamespaces("translation") (TournamentListing);