import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import { getUserGroup, postAdmin } from "../../store/ducks/admins/actions";
import { formValidation, filterErrorHandler } from "../../hoc/Shared/utility";
import { withNamespaces } from "react-i18next";
import ValidationError from "../../components/ValidationError/ValidationError";

let userGroupArry = [];

class AdminsCreate extends Component {
    state = {
        username: "",
        // email: "",
        password: "",
        user_group_id: "",
        loading: true,
        currentStatus: 1,
    };

    componentDidMount() {
        formValidation("admin_form");
        this.props.getUserGroup();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.post_admin_data !== this.props.post_admin_data && nextProps.post_admin_data !== undefined) {
            if (nextProps.post_admin_data.responseCode === 200 && nextProps.post_admin_data.msgType === 'success') {
                const { t } = this.props;
                this.setState({ touched: false });
                Swal.fire({
                    title: t("global.record-saved"),
                    icon: nextProps.post_admin_data.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    touched: true,
                    error_message: nextProps.post_admin_data.message,
                });
                Swal.close();
            }
        }

        if (nextProps.user_group_data !== this.props.user_group_data && nextProps.user_group_data !== undefined) {
            if (nextProps.user_group_data.data.responseCode === 200 && nextProps.user_group_data.data.msgType === 'success') {
                userGroupArry = [];
                let data = nextProps.user_group_data.data.data;
                Object.keys(data).map((item, i) => {
                    return userGroupArry.push({
                        label: data[item].title,
                        value: data[item].Id,
                        key: data[item].Id,
                    });
                });
                this.setState({
                    loading: false
                });
            }
            else {
                userGroupArry = [];
                filterErrorHandler(nextProps.user_group_data.data.message);
            }
        }
        return true;
    }

    getUserGroupHandler = (event) => {
        this.setState({ user_group_id: event.target.value });
    };

    getUserNameHandler = (event) => {
        this.setState({ username: event.target.value });
    };

    changeStatusHandler = (event) => {
        this.setState({ currentStatus: event.target.value });
    }

    // getEmailHandler = (event) => {
    //     this.setState({ email: event.target.value });
    // };

    getPasswordHandler = (event) => {
        this.setState({ password: event.target.value });
    };

    postAdminHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let storeAdminData = {};
                storeAdminData.username = this.state.username;
                // storeAdminData.email = this.state.email;
                storeAdminData.status = this.state.currentStatus;
                storeAdminData.password = this.state.password;
                storeAdminData.user_group_id = this.state.user_group_id;
                storeAdminData.country = this.props.currentUser.counrtry_id;

                this.props.postAdmin(storeAdminData);
            }
        });
    };

    render() {
        const { t } = this.props;
        let defaultGroup = [
            {
                label: `--- ${t("admin.select-group")} ---`,
                selected: true,
            },
        ];

        let statusArry = [
            {
                label: t("global.active"),
                value: 1,
                selected: true
            },
            {
                label: t("global.inactive"),
                value: 0,
            },
        ];
        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("admin.admin")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("admin.add-admin")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <form method="post" id="admin_form" className="form-horizontal" onSubmit={(event) => this.postAdminHandler(event)} noValidate>
                                        <ValidationError formName="admin_form" touched={this.state.touched} message={this.state.error_message} />
                                        <div className="row">
                                            <div className="col-md-4 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="username">{t("admin.username")}</label>
                                                    <StringInput
                                                        onChange={this.getUserNameHandler}
                                                        id="username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder={t("admin.username")}
                                                        value={this.state.username}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                    <label htmlFor="email">{t("admin.email")}</label>
                                                    <StringInput
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={t("admin.email")}
                                                        onChange={this.getEmailHandler}
                                                        value={this.state.email}
                                                        required
                                                    />
                                                </div> */}
                                            <div className="col-md-4 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="password">{t("admin.password")}</label>
                                                    <PasswordInput
                                                        id="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder={t("admin.password")}
                                                        onChange={this.getPasswordHandler}
                                                        value={this.state.password}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group">
                                                    <label htmlFor="user_group_id">{t("admin.group")}</label>
                                                    <Select
                                                        id="user_group_id"
                                                        name="user_group_id"
                                                        options={defaultGroup.concat(userGroupArry)}
                                                        className="form-control"
                                                        onChange={this.getUserGroupHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group">
                                                    <label>{t('promotion.status')}</label>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        className="form-control"
                                                        options={statusArry}
                                                        onChange={this.changeStatusHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mb-0 mt-3 justify-content-end">
                                                    <div>
                                                        <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.save")} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    //Getting the state from the reducers
    return {
        user_group_data: admin.user_group_data,
        post_admin_data: admin.post_admin_result,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps = {
    postAdmin,
    getUserGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AdminsCreate));
