import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withNamespaces } from 'react-i18next';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import UpdownArrow from '../../assets/images/updown-arrow.png'

import DataTable from '../../components/Datatable/DataTable';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import StringInput from '../../components/Input/StringInput/StringInput';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import Button from '../../components/Button/Button';
// import NumberInput from '../../components/Input/NumberInput/NumberInput';
import { formValidation } from '../../hoc/Shared/utility';
import ValidationError from '../../components/ValidationError/ValidationError';

class MemberStakingsListing extends Component {
    /* ============ component lifecycle [START] ============ */
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
        };
        // console.log('constructor (listing):', this.state);
    }

    componentDidMount() {
        formValidation('member_stakings_form');
    }
    /* ============ component lifecycle [END] ============ */

    /* ============ function [START] ============ */
    noFormatter = (cell, row, rowIndex) => {
        // let rowNumber =
        //     (this.props.data.activePage - 1) * this.props.data.limit +
        //     (rowIndex + 1);

        let rowNumber = (this.props.data.totalRecords - rowIndex - ((this.props.data.activePage - 1) * 10))
        return <span>{rowNumber}</span>;
    };

    checkboxColumn = (column, colIndex, permission) => {
        let val = permission.includes('member_stakings_submit') ? (
            <div className='yl-checkbox-container'>
                <input
                    type='checkbox'
                    id='check_all'
                    name='check_all'
                    onChange={(event) => this.props.checkAllHandler(event, this.props.tabName, 'formInput')}
                    checked={this.props.checkAll ? true : false}
                />
                <label for='check_all' />
            </div>

        ) : (
            ''
        );
        return val;
    };

    toggleCard = () => {
        this.setState({
            showCard: !this.state.showCard,
        });
    };
    /* ============ function [END] ============ */

    render() {
        // console.log(this.props.checkAll);
        const { t } = this.props;
        let rsData = [];
        let permission = this.props.currentUser.permission ? this.props.currentUser.permission : [];

        const columns =
            [
                { text: t('global.no'), dataField: 'no', formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' }, },
                // {text: 'trans.id', dataField: 'id'},
                { text: t('member-stakings.member'), dataField: 'username', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName), },
                { text: t('member-stakings.member-group'), dataField: 'group_id', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName), },
                { text: t('member-stakings.total-member-balance'), dataField: 'total_member_balance', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName), },
                // {text: t('member-rebate.last-rebate-date'), dataField: 'last_rebate_date', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName),},
                { text: t('member-stakings.payout'), dataField: 'total_payout', sort: true, onSort: (field, order) => this.props.onSort(field, order, this.props.tabName), },
                { text: t('member-stakings.remark'), dataField: 'remark', headerStyle: { width: '165px' }, },
                // {text: <input type='checkbox' id='check_all' name='check_all' onChange={(event) => this.props.checkAllHandler(event, this.props.tabName, 'formInput')} checked={(this.props.checkAll) ?  true : false} />, dataField: 'action', headerStyle: { width: '40px' }},
                { text: '', dataField: 'action', headerStyle: { width: '40px' }, headerFormatter: (column, colIndex) => this.checkboxColumn(column, colIndex, permission), },
            ];

        if (this.props.data.data) {
            rsData = this.props.data.data.map((val, idx) => {
                const num_format =
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                };
                let payoutVal = permission.includes(
                    'member_stakings_details_access'
                ) ? (
                    <b>
                        <a href='/#' onClick={(event) => this.props.stakingPayoutDetailsHandler(event, val.member_id, this.props.data.filterInput.confirm_date, 0)}>
                            {new Intl.NumberFormat('en-US', num_format).format(val.total_payout)}
                            <i className='fas fa-external-link-square-alt text-primary ml-1'></i>
                        </a>
                    </b>
                ) : (
                    new Intl.NumberFormat('en-US', num_format).format(val.total_payout)
                );

                let remarkVal = permission.includes('member_stakings_submit') ? (
                    (val.total_payout > 0 && val.total_member_balance >= 0) ? (
                        <textarea
                            id={`remark_${val.member_id}`}
                            name={`remark_${val.member_id}`}
                            value={this.props.data.formInput[`remark_${val.member_id}`]}
                            onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'formInput')}
                        ></textarea>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                );
                let actionVal = permission.includes('member_stakings_submit') ? (
                    (val.total_payout > 0 && val.total_member_balance >= 0) ? (
                        <div className='yl-checkbox-container'>
                            <input
                                type='checkbox'
                                className='editor-active'
                                id={`cb_${val.member_id}`}
                                name={`cb_${val.member_id}`}
                                checked={this.props.data.formInput[`cb_${val.member_id}`] ? true : false}
                                onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'formInput')}
                            />
                            <label for={`cb_${val.member_id}`} />
                        </div>

                    ) : (
                        ''
                    )
                ) : (
                    ''
                );

                return {
                    no: idx,
                    username: val.username,
                    group_id: `VIP ` + val.group_id,
                    total_member_balance: new Intl.NumberFormat('en-US', num_format).format(val.total_member_balance / 8),
                    // last_rebate_date: val.last_rebate_date,
                    // bet_amount: new Intl.NumberFormat('en-US', num_format).format(val.bet_amount),
                    total_payout: payoutVal,
                    // promo_turnover: new Intl.NumberFormat('en-US', num_format).format(val.promo_turnover),
                    // eligible_turnover: new Intl.NumberFormat('en-US', num_format).format(val.eligible_turnover),
                    // promo_target: new Intl.NumberFormat('en-US', num_format).format(val.promo_target),
                    // rebate_amt: new Intl.NumberFormat('en-US', num_format).format(val.rebate_amt),
                    // rebate_tbg: rebateTbgVal,
                    remark: remarkVal,
                    action: actionVal,
                };
            });
        }
        return (
            <Auxiliary>
                <ValidationError
                    // formName='member_rebate_form'
                    touched={this.props.touched}
                    message={this.props.errMsg}
                />
                <div className='row row-sm'>
                    <div className='col-xl-12'>
                        <div className='card card-body card-primary'>
                            <div className=''>
                                {this.props.data.loading ? (
                                    <Skeleton count={2} />
                                ) : (
                                    <div method='post' id='filter_form' className={`form-horizontal filter-function row ${this.state.showCard ? 'open' : 'close'} filter-wrapper-2`}>
                                        {/* <div className='row'> */}
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>{t('member-rebate.transaction-date')}</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text'>
                                                            <i className='fas fa-calendar-check'></i>
                                                        </span>
                                                    </div>
                                                    <DateTimePicker
                                                        id='date'
                                                        name='date'
                                                        className='form-control filter-input'
                                                        format='YYYY-MM-DD'
                                                        singleDatePicker={true}
                                                        autoUpdateInput={true}
                                                        value={this.props.data.filterInput.date}
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                        maxDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>
                                                    {t('member-rebate.member')}
                                                </label>
                                                <StringInput
                                                    id='username'
                                                    name='username'
                                                    value={this.props.data.filterInput.username}
                                                    onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                    className='form-control filter-input'
                                                    onSearchPress={(evt) => evt.key === 'Enter' && this.props.refreshHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='form-group'>
                                                <label className='font-weight-bolder'>
                                                    {t('member-rebate.member-group')}
                                                </label>
                                                <div className='select-input'>
                                                    <select
                                                        className='form-control filter-input'
                                                        id='member_group'
                                                        name='member_group'
                                                        onChange={(event) => this.props.onInputChange(event, this.props.tabName, 'filterInput')}
                                                        value={this.props.data.filterInput.member_group}
                                                    >
                                                        <option value=''></option>
                                                        {this.props.ddMemberGroup &&
                                                            this.props.ddMemberGroup.map(
                                                                (val, idx) => {
                                                                    return (
                                                                        <option key={idx} value={val.id}>
                                                                            {val.group_name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <img className="updown-icon" src={UpdownArrow} alt="Up Down Arrow" />
                                                </div>

                                            </div>
                                        </div>
                                        {/* </div> */}

                                        <div className='col mt-auto'>
                                            <div className='d-flex'>
                                                {permission.includes(
                                                    'member_stakings_submit'
                                                ) && (
                                                        <Button
                                                            typeButton='submit'
                                                            classValue='btn btn-warning'
                                                            idValue='submit'
                                                            buttonName={t('member-stakings.submit-stakings')}
                                                            formValue='member_stakings_form'
                                                        />
                                                    )}
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-blue'
                                                    idValue='filter'
                                                    buttonName={t('global.filter')}
                                                    clicked={() => this.props.refreshHandler(this.props.tabName)}
                                                />
                                                &nbsp;
                                                <Button
                                                    typeButton='button'
                                                    classValue='btn btn-secondary'
                                                    idValue='reset'
                                                    buttonName={t('global.reset')}
                                                    clicked={() => this.props.clearHandler(this.props.tabName)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className='show-btn'
                                onClick={() => this.toggleCard()}
                            >
                                {this.state.showCard ? (
                                    <AiFillMinusCircle />
                                ) : (
                                    <AiFillPlusCircle />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className='card-header pb-0'>
                                <div className='d-flex justify-content-between'>
                                    <h4 className='card-title mg-b-0'>
                                        {t('member-stakings.calculate-stakings')}
                                    </h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive table-hover datatable'>
                                    <form
                                        method='post'
                                        id='member_stakings_form'
                                        className='form-horizontal filter-function'
                                        onSubmit={(event) => this.props.submitHandler(event, this.props.tabName)}
                                        noValidate
                                    >
                                        <DataTable
                                            keyField='no'
                                            data={rsData}
                                            columns={columns}
                                            activePage={this.props.data.activePage}
                                            limit={this.props.data.limit}
                                            totalItems={this.props.data.totalRecords}
                                            paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'activePage')}
                                            sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'limit')}
                                            searchChangeHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'searchValue')}
                                            searchValue={this.props.data.searchValue}
                                            classValue='dataTable table-bordered text-justify text-center'
                                            loading={this.props.data.loading}
                                            hideSearchBar={true}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

export default withNamespaces('translation')(MemberStakingsListing);
