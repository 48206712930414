import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import RequiredPublicAuth from "./common/middleware/authPublicPermission";

import Login from "./containers/auth/Login/Login";
import ChangePassword from "./containers/auth/Login/ChangePassword";
import Forbidden from "./containers/Errors/Forbidden";

import Layout from "./containers/Layout/Layout";

// import './assets/css/bootstrap.css';
import './assets/css/styles.css';
import './assets/css/_width.scss';
import './assets/css/_background.scss';
import './assets/css/_custom-styles.scss';
import './assets/css/_sidemenu.scss';
import './assets/css/_header.scss';
import './assets/css/_dropdown.scss';
import './assets/css/icons.scss';
import './assets/css/custom.css';
import 'react-image-lightbox/style.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './assets/css/base.scss';
import './assets/css/_button.scss';



function App() {
    
    return (
        <Router>
            <Switch>
                <Route exact path="/login" name="Login" component={RequiredPublicAuth(Login)}/>
                <Route exact path="/change_password" name="Change Password" component={RequiredPublicAuth(ChangePassword)}/>
                <Route exact path="/forbidden" name="Forbidden" component={Forbidden} />
                <Route path="/" name="Home" render={props => <Layout {...props}/>}/>
                <Redirect from="/" to="/login"/>
            </Switch>
        </Router>
    );
}

export default App;
