import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';

import WhitelistIPListing from './WhitelistIPListing';
import WhitelistIPCreate from './WhitelistIPCreate';
import WhitelistIPEdit from './WhitelistIPEdit';

import * as actions from '../../store/ducks/whitelistIP/actions';

const initialInputState = {
	/* form input value */
	ip: '',
	status: ''
};

class WhitelistIP extends Component {

	/* ============ component lifecycle [START] ============ */
	constructor(props) {
		super(props);
		this.state = {
			/* component change */
			pageShow: 'listing',

			/* datatables / pagination */
			page: Number(process.env.REACT_APP_DATATABLE_PAGE),
			limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
			order: null,
			totalPages: 0,
			activePage: 1,
			totalRecords: 0,
			searchValue: '',

			/* form input value */
			filter: initialInputState,

			/* api result array */
			data: [],

			/* details id */
			selectId: null,

			/* skeleton loading */
			loading: true,
		}
		this.onInputChange = this.onInputChange.bind(this);
	}

	componentDidMount() {
		const listingArr = this.getFilterArray();
		this.props.funcGetList(listingArr);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { t } = this.props;
		if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
			if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') {
				this.setState({
					data: nextProps.rs.data,
					totalRecords: nextProps.rs.totalRecords,
					loading: false,
				});
			}
			else {
				let msg = null;
				msg = '<ul>';
				nextProps.rs.message.forEach((val) => {
					msg += '<li>'+val+'</li>';
				});
				msg += '</ul>';

				Swal.fire({
					icon: 'error',
					title: t('global.error'),
					html: msg,
					showConfirmButton: true
				});
			}
		}

		if (nextProps.delete !== this.props.delete && nextProps.delete !== undefined) {
			let success = (nextProps.delete.responseCode === 200 && nextProps.delete.msgType === 'success') ? true : false;

			if (nextProps.delete.responseCode === 200 && nextProps.delete.msgType === 'success') {
				Swal.fire({
					icon: 'success',
					title: t('global.success'),
					html: t('global.record-deleted'),
					showConfirmButton: true
				}).then(() => {
					this.refreshHandler();
				});
			}
			else {
				Swal.close();
			}
			this.setState({
				touched: (success) ? false : true,
				errMsg: (success) ? [] : nextProps.delete.message
			});
		}

		return true;
	}
	/* ============ component lifecycle [END] ============ */

	/* ============ function [START] ============ */
	onInputChange = (event) => {
		// console.log(event.target.id+' | '+event.target.value);
		if (event.target.id === 'search') {
			this.setState({
				searchValue: event.target.value
			});
		}
		else {
			let newFilter = Object.assign({}, this.state.filter);
			newFilter[event.target.id] = event.target.value;

			this.setState({
				filter: newFilter
			});
		}
	}

	searchKeyPressHandler = (event) => {
		// perform search only on enter
		if (event.key === 'Enter') {
			this.refreshHandler();
		}
	}

	clearHandler = () => {
		let newFilter = initialInputState;
		this.setState({
			activePage: 1,
			// limit: 10,
			searchValue: '',
			// order: [{'id':'id', 'desc':'asc'}],
			filter: newFilter,
			loading: true,
			data: [],
		}, ()=> {
			const listingArr = this.getFilterArray();
			this.props.funcGetList(listingArr);
		});
	}

	loadingHandler = () => {
		Swal.fire({
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			didOpen: () => {
				Swal.showLoading();
			}
		});
	}

	refreshHandler = () => {
		this.setState({
			loading: true,
			data: [],
			errMsg: [],
			touched: false,
		}, () => {
			const listingArr = this.getFilterArray();
			this.props.funcGetList(listingArr);
		});
	}

	getFilterArray = () => {
		// this.loadingHandler();
		const listingArr = {};
		let availablePage = this.state.activePage;

		if (this.state.totalRecords !== 0) {
			availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
			(this.state.activePage >= availablePage) && this.setState({ activePage: availablePage});
		}

		listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
		listingArr.limit = this.state.limit;
		listingArr.search = this.state.searchValue;
		listingArr.order = JSON.stringify(this.state.order);
		listingArr.country_id = this.props.currentUser.country_id;

		Object.keys(this.state.filter).forEach((val) => {
			listingArr[val] = this.state.filter[val];
		});

		return listingArr;
	}

	detailsHandler = (e, page, val) => {
		e.preventDefault();

		this.setState({
			pageShow: page,
			ip: val.ip,
			status: val.status
		});
	}

	changePageHandler = (page) => {
		// console.log(page);
		this.setState({
			pageShow: page,
			errMsg: [],
			touched: false
		});
	}

	deleteHandler = (e, ip) => {
		e.preventDefault();

		Swal.fire({
			title: 'Are you sure?',
			text: 'You wont be able to revert this!',
			icon: 'warning',
			confirmButtonText: 'Delete',
			showDenyButton: true,
			denyButtonText: 'Cancel',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					didOpen: () => {
						Swal.showLoading()
					}
				});
				this.loadingHandler();

				this.props.funcDeleteData({
					ip: ip
				});
			}
		});
	}
	/* ============ function [END] ============ */

	/* ============ datatables function [START] ============ */
	datatablesChangeHandler = (val, act) => {
		// console.log('datatablesChangeHandler :'+val + ' | '+act);
		this.setState({
			[act]: (act === 'searchValue') ? val.target.value : val,
			loading: true,
			data: [],
			errMsg: [],
			touched: false,
		}, () => {
			const listingArr = this.getFilterArray();
			this.props.funcGetList(listingArr);
		});
	}

	onSort = (field, order) => {
		const orderArry = [{}];
		let orderSequence = (order === 'asc') ? true : false ;

		orderArry[0]['id'] = field;
		orderArry[0]['desc'] = orderSequence;

		this.setState({
			order: orderArry,
			loading: true,
			data: [],
			errMsg: [],
			touched: false,
		}, () => {
			const listingArr = this.getFilterArray();
			this.props.funcGetList(listingArr);
		});
	}
	/* ============ datatables function [END] ============ */

	render() {
		return (
			<React.Fragment>
				{this.state.pageShow==='listing' &&
					<WhitelistIPListing
						/* filter input */
						filter = {this.state.filter}

						/* setstate function */
						onInputChange={(event) => this.onInputChange(event)}
						datatablesChangeHandler={(val, act) => this.datatablesChangeHandler(val, act)}
						detailsHandler={(event, page, ip) => this.detailsHandler(event, page, ip)}
						deleteHandler={(event, id) => this.deleteHandler(event, id)}
						changePageHandler={(event, page, id) => this.changePageHandler(event, page, id)}
						onSort={(field, order) => this.onSort(field, order)}
						clearHandler={() => this.clearHandler()}
						refreshHandler={() => this.refreshHandler()}
						searchKeyPressHandler={(event) => this.searchKeyPressHandler(event)}

						/* datatables data */
						data={this.state.data}
						activePage={this.state.activePage}
						limit={this.state.limit}
						totalRecords={this.state.totalRecords}
						searchValue={this.state.searchValue}

						/* skeleton loading */
						loading={this.state.loading}

						/* user session */
						currentUser={this.props.currentUser}
					/>
				}

				{this.state.pageShow === 'create' &&
					<WhitelistIPCreate
						selectId={this.state.selectId}
						refreshHandler={() => this.refreshHandler()}
						changePageHandler={(page) => this.changePageHandler(page)}
					/>
				}

				{this.state.pageShow === 'edit' &&
					<WhitelistIPEdit
						ip={this.state.ip}
						status={this.state.status}
						refreshHandler={() => this.refreshHandler()}
						changePageHandler={(page) => this.changePageHandler(page)}
					/>
				}
			</React.Fragment>
		);
	}
}

//Received the response from the redux calling the api response
const mapStateToProps = state => {
	return {
		rs: state.whitelistIP.rs,
		delete: state.whitelistIP.delete,
		currentUser: state.currentUser.data
	}
};

//Using the redux calling api methods
const mapDispatchToProps = dispatch => {
	return {
		funcGetList: (filter) => dispatch(actions.getList(filter)),
		funcDeleteData: (data) => dispatch(actions.deleteData(data))
	}
};

// Connect redux
export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces('translation')(WhitelistIP));
