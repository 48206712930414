import * as type from './types';
import axios from '../../../common/config/axios';

export const getMemberLoginStatus = (data) => dispatch => {
    axios.get('member/member_login_status', {
        params: data
    }).then((response) => {
        dispatch({
            type: type.GET_MEMBER_LOGIN_STATUS,
            payload: response
        })
    }).catch(err => {
        console.warn(err);
    })
};