import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const inboxReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_INBOX]: (state = initialState, action) => ({
        ...state,
        inbox_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.POST_INBOX]: (state, action) => ({
        ...state,
        post_inbox_result: action.payload,
    }),

    [type.SHOW_INBOX]: (state, action) => ({
        ...state,
        show_inbox_data: action.payload,
    }),

    [type.UPDATE_INBOX]: (state, action) => ({
        ...state,
        update_inbox_result: action.payload,
    }),

    [type.DELETE_INBOX]: (state, action) => ({
        ...state,
        delete_inbox_result: action.payload,
    }),

    [type.GET_MEMBER_GROUP]: (state = initialState, action) => ({
        ...state,
        member_group_data: action.payload
    }),

    [type.GET_CATEGORY]: (state = initialState, action) => ({
        ...state,
        category_data: action.payload
    }),
});

export default inboxReducer;