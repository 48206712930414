import React, { Component } from 'react';
import ImageUploader from "react-images-upload";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { file: '', imagePreviewUrl: '' };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    this.setState({
      file: this.state.file.concat(file)
    });
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }



  render() {


    return (
      <div className="previewComponent">
        {/*<form onSubmit={(e)=>this._handleSubmit(e)}>*/}
        {this.props.defaultImage ?
          (
            <ImageUploader
              type="file"
              withIcon={true}
              withPreview={true}
              onChange={this.props.onChange}
              imgExtension={['.jpg', '.gif', '.png', '.gif', '.webp']}
              buttonText="Choose images"
              maxFileSize={5242880}
              name={this.props.name}
              singleImage={true}
              defaultImages={this.props.defaultImage}
            />
          ) : (
            <ImageUploader
              type="file"
              withIcon={true}
              withPreview={true}
              onChange={this.props.onChange}
              imgExtension={['.jpg', '.gif', '.png', '.gif', '.webp']}
              buttonText="Choose images"
              maxFileSize={5242880}
              name={this.props.name}
              singleImage={true}
            />
          )

        }
        {/* <input className="dropify" 
              type="file" 
              onChange={this.props.onChange} /> */}
        {/* <button className="submitButton" 
              type="submit" 
              onClick={(e)=>this._handleSubmit(e)}>Upload Image</button> */}
        {/*</form>*/}

      </div>
    )
  }
}


export default ImageUpload;
