import React, { Component } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showPromotedVideo } from "../../store/ducks/promotedVideo/actions";
import { getCountry } from "../../store/ducks/home/actions";
import Button from "../../components/Button/Button";
import Tab from "../../components/Tab/Tab";
import DomPurify from "../../components/Sanitize/DomPurify";
import Lightbox from "react-image-lightbox";

let langArr = [];

class PromotedVideoShow extends Component {
    state = {
        video_preview: [],
        video_thumbnail_preview: [],
        title: [],
        description: [],
        sequence: "",
        status: "",
        start_time: "",
        end_time: "",
        country_id: "",
        created_at: "",
        updated_at: "",
        loading: true,
        isOpen: false,
    };

    componentDidMount() {
        const countryData = {};
        countryData.country_id = this.props.currentCountryId || this.state.currentCountryId;

        const showPromotionData = {};
        showPromotionData.video_id = this.props.show_video_id;

        this.props.showPromotedVideo(showPromotionData);
        this.props.getCountry(countryData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_promoted_video_data !== this.props.show_promoted_video_data) {
            if (nextProps.show_promoted_video_data.data.responseCode === 200) {
                const { video_preview, title, description, video_thumbnail_preview } = this.state;

                nextProps.show_promoted_video_data.data.data.map((item, i) => {
                    video_preview[item.lang_id] = item.video_url;
                    title[item.lang_id] = item.title;
                    description[item.lang_id] = item.description;
                    video_thumbnail_preview[item.lang_id] = item.video_thumbnail_url;
                    return item;
                });

                // console.log(nextProps.show_promotion_data.data[0]);

                this.setState({
                    loading: false,
                    video_preview,
                    video_thumbnail_preview,
                    title,
                    description,
                    sequence: nextProps.show_promoted_video_data.data.data[0].sequence,
                    status: nextProps.show_promoted_video_data.data.data[0].status,
                    start_time: nextProps.show_promoted_video_data.data.data[0].start_time,
                    end_time: nextProps.show_promoted_video_data.data.data[0].end_time,
                    country_id: nextProps.show_promoted_video_data.data.data[0].country_id,
                    created_at: nextProps.show_promoted_video_data.data.data[0].created_at,
                    updated_at: nextProps.show_promoted_video_data.data.data[0].updated_at,
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.show_promoted_video_data.data.message,
                    icon: nextProps.show_promoted_video_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }
        if (nextProps.country_data !== this.props.country_data) {
            if (nextProps.country_data.data.responseCode === 200) {
                let data = nextProps.country_data.data.language;

                this.setState({ loading: false });
                //Clear the languages array
                langArr = [];

                Object.keys(data).map((item, i) => {
                    return langArr.push({
                        langId: data[item].languageId,
                        langCode: data[item].languageCode,
                        langName: data[item].languageName,
                    });
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.country_data.data.message[0],
                    icon: nextProps.country_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        return true;
    }

    render() {
        const { t } = this.props;
        let tabMessage = null;
        let tabTitleMessage = [];
        let tabContentMessage = [];

        let languages = langArr;

        tabTitleMessage = languages.map((language) => language.langName);

        tabContentMessage = languages.map((item) => (
            <Auxiliary>
                {this.state.isOpen && <Lightbox mainSrc={this.state.currentImage} onCloseRequest={() => this.setState({ isOpen: false })} />}
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label>
                            <strong>{t("promoted-video.promoted-video")}:</strong>
                        </label>

                        <div className="imgPreview promotion-preview-image">
                            <video src={this.state.video_preview[item.langId]} controls></video>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col-md-5">
                    <div className="form-group" key={item.langId}>
                        <label>
                            <strong>{t("promoted-video.video-thumbnail-image")}:</strong>
                        </label>

                        <div className="imgPreview promotion-preview-image">
                            <img
                                alt=""
                                className="show-banner-size"
                                src={this.state.video_thumbnail_preview[item.langId]}
                                onClick={() => this.setState({ isOpen: true, currentImage: this.state.desktop_image_preview[item.langId] })}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col-md-12">
                    <div className="form-group">
                        <label>
                            <strong>{t("promotion.title")}:</strong>
                        </label>
                        <p>{this.state.title[item.langId]}</p>
                    </div>
                </div>
                <hr />
                <div className="col-md-12">
                    <div className="form-group">
                        <label>
                            <strong>{t("promoted-video.description")}:</strong>
                        </label>
                        {/* <p><strong>{this.state.content[item.langId]}</strong></p> */}
                        <DomPurify text={this.state.description[item.langId]} />
                        {/* <span dangerouslySetInnerHTML={{ __html: this.state.content[item.langId] }} /> */}
                    </div>
                </div>
            </Auxiliary>
        ));

        tabMessage = <Tab tabNav={tabTitleMessage} tabPanel={tabContentMessage} className="bg-white border" />;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("promoted-video.promoted-video")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("promoted-video.show-promoted-video")}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true ? (
                                    <Skeleton count={4} />
                                ) : (
                                    <table className="table table-bordered table-striped">
                                        <tbody>
                                            <tr className="show-promotion-white">
                                                <th>{t("promoted-video.promoted-video-details")}:</th>
                                                <td field-key="promotion_details">{tabMessage}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("promotion.sequence")}:</th>
                                                <td field-key="sequence">{this.state.sequence}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("promotion.status")}:</th>
                                                <td field-key="status">{this.state.status === 1 ? t("global.active") : t("global.inactive")}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("promotion.start-time")}:</th>
                                                <td field-key="start_time">{this.state.start_time}</td>
                                            </tr>

                                            <tr>
                                                <th>{t("promotion.end-time")}:</th>
                                                <td field-key="end_time">{this.state.end_time}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, promotedVideo, currentUser } = state;

    return {
        country_data: home.country_data,
        show_promoted_video_data: promotedVideo.show_promoted_video_data,
        currentCountryId: currentUser.data.country_id,
    };
};

const mapDispatchToProps = {
    showPromotedVideo,
    getCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PromotedVideoShow));
