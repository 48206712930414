import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Swal from 'sweetalert2';
import { withNamespaces } from 'react-i18next';
import axios from "../../common/config/axios";
import * as moment from "moment";

import DataTable from "../../components/Datatable/DataTable";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/Button/Button';

import TransactionDetails from '../Transaction/TransactionDetails';
import AgentTransactionDetails from '../AgentTransaction/AgentTransactionDetails';

import { getBanksAccountLedger } from '../../store/ducks/banksAccount/actions';
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Excel from "../../assets/images/excel.svg";
import { filterErrorHandler } from '../../hoc/Shared/utility';

class BanksAccountLedger extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            pageShow: 'listing',
            trxPage: 'member',

            activePage: Number(process.env.REACT_APP_DATATABLE_PAGE),
            limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
            order: '',
            totalRecords: 0,
            searchValue: '',

            data: [],
            loading: true,
            back_acc: '',
        };
    }

    componentDidMount() {
        const listingArr = this.getFilterArray();
        this.props.getBanksAccountLedger(listingArr);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.ledger !== this.props.ledger && nextProps.ledger !== undefined) {
            if (nextProps.ledger.responseCode === 200 && nextProps.ledger.msgType === 'success') {
                this.setState({
                    data: nextProps.ledger.data.result.row,
                    totalRecords: nextProps.ledger.data.result.pagination.total_record,
                    loading: false,
                });
            }
            else {
                this.setState({
                    loading: true,
                    data: [],
                    totalRecords: 0
                }, () => {
                    filterErrorHandler(nextProps.ledger.message);
                });
            }
        }

        return true;
    }

    datatablesChangeHandler = (val, act) => {
        // console.log('datatablesChangeHandler :'+val + ' | '+act);
        this.setState({
            [act]: (act === 'searchValue') ? val.target.value : val,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getBanksAccountLedger(listingArr);
        });
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence = (order === "asc") ? true : false;

        orderArry[0]['id'] = field === "date" ? "approve_date" : field;
        orderArry[0]['desc'] = orderSequence;

        this.setState({
            order: orderArry,
            loading: true,
            data: [],
        }, () => {
            const listingArr = this.getFilterArray();
            this.props.getBanksAccountLedger(listingArr);
        });
    }

    getFilterArray = () => {
        const listingArr = {};
        let availablePage = this.state.activePage;
        if (this.state.totalRecords !== 0) {
            availablePage = parseInt((this.state.totalRecords / this.state.limit) + 1);
            (this.state.activePage >= availablePage) && this.setState({ activePage: availablePage });
        }

        listingArr.page = (this.state.activePage >= availablePage) ? availablePage : this.state.activePage;
        listingArr.limit = this.state.limit;
        listingArr.search = this.state.searchValue;
        listingArr.order = JSON.stringify(this.state.order);
        listingArr.country_id = this.props.currentUser.country_id;
        listingArr.bank_acc = this.props.id;
        // console.log(listingArr);
        return listingArr;
    }

    detailsHandler = (e, id, trx_type) => {
        e.preventDefault();
        this.setState({
            pageShow: 'details',
            trxPage: trx_type,
            detailsParam:
            {
                id: id
            }
        });
    }

    changePageHandler = (page) => {
        this.setState({
            pageShow: page,
        });
    }

    noFormatter = (cell, row, rowIndex) => {
        let rowNumber = ((this.state.activePage - 1) * this.state.limit) + (rowIndex + 1);
        return <span>{rowNumber}</span>;
    }

    exportExcelHandler = () => {
        const data = {};
        data.country_id = this.props.currentUser.country_id;
        data.bank_acc = this.props.id;
        data.order = JSON.stringify(this.state.order);

        axios.get("bankaccount/export_ledger", {
            params: data,
            responseType: "blob",
        })
        .then((res) => {
            const currentDate = new Date();
            const timestamp = moment(currentDate).format("YYMMDD HHmmss");
            let fileName = "100Express Report - BankAccountLedger (" + this.props.swiftCode + ") " + timestamp;
            const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const aElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            aElement.href = href;
            aElement.download = fileName;
            document.body.appendChild(aElement);
            aElement.click();
            document.body.removeChild(aElement);
            window.URL.revokeObjectURL(href);
        })
        .catch((err) => {
            console.log("Excel - BankAccountLedger Error", err);
        });
    };

    render() {
        const { t } = this.props;
        let rsListing = [];
        let permission = (this.props.currentUser.permission) ? this.props.currentUser.permission : [];
        let stCls =
        {
            'transfer': 'success',
            'cash-in': 'success',
            'deposit': 'success',
            'withdrawal': 'danger',
            'cash-out': 'danger',
            'transfer-out': 'danger'
        };

        const columns = [
            { text: t('transaction.no'), dataField: "no", formatter: this.noFormatter, headerStyle: { width: '60px', textAlign: 'center' } },
            { text: t('transaction.order-id'), dataField: "order_id", sort: true, onSort: this.onSort, headerStyle: { width: "115px" }, },
            { text: t('transaction.transaction-type'), dataField: "transaction_type" },
            { text: 'Amount', dataField: "amount", headerStyle: { width: '120px' } },
            { text: 'Balance', dataField: "balance", headerStyle: { width: '120px' } },
            { text: t('log.date'), dataField: "date", sort: true, onSort: this.onSort },
        ];

        rsListing = this.state.data.map((val, idx) => {
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let orderIdVal = (permission.includes("transaction_details_access")) ? <b><a href="/#" onClick={(event) => this.detailsHandler(event, val.id, val.trx_type)}>{val.order_id}<i className="fas fa-external-link-square-alt text-primary ml-1"></i></a></b> : val.order_id;
            let transactionVal = (val.transaction_type === 'transfer') ? ((val.from_bank_acc_id === this.props.id) ? t('bank-account.transfer-out') : t('bank-account.transfer-in')) : t(`transaction-type.${val.transaction_type}`);
            let amountVal = (
                <span className={(val.transaction_type === 'transfer' && (val.from_bank_acc_id === this.props.id)) ? `badge-${stCls['transfer-out']}` : `badge-${stCls[val.transaction_type]}`}>
                    {new Intl.NumberFormat('en-US', num_format).format((val.transaction_type === 'withdrawal' || val.transaction_type === 'cash-out') ? val.debit : val.credit)}
                </span>
            );
            let balanceVal = ((val.transaction_type === 'transfer' && val.from_bank_acc_id === this.props.id) || val.transaction_type === 'withdrawal') ? val.from_balance : val.to_balance;

            return {
                no: idx,
                order_id: orderIdVal,
                transaction_type: transactionVal,
                date: val.approve_date,
                amount: amountVal,
                balance: new Intl.NumberFormat('en-US', num_format).format(balanceVal),
            };
        });

        return (
            <Auxiliary>
                {this.state.pageShow === 'listing' &&
                    <>
                        <div className='breadcrumb-header'>
                            {/* <div className='my-auto'>
                                <div className='d-flex'>
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('bank-account.banks-account')}</h4>
                                </div>
                            </div> */}
                            <div className='d-flex'>
                                <div>
                                    <div className="btn-group">
                                        <Button
                                            typeButton='button'
                                            classValue='btn btn-back-outline'
                                            idValue='back'
                                            buttonName={<i className="angle fe fe-chevron-left"></i>}
                                            clicked={() => this.props.changePageHandler('listing')} />
                                        <div>
                                            <div className='member-name'>
                                                <FontAwesomeIcon icon={faBalanceScale} />
                                                {this.props.swiftCode}
                                            </div>
                                        </div>
                                        {/* <button id="bank_ledger_show" style={{ borderTopWidth: 0 }} className="btn btn-info" disabled>
                                        <FontAwesomeIcon icon={faBalanceScale} /> {this.props.swiftCode}
                                    </button>                                         */}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row row-sm'>
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="card-title mg-b-0">{t('bank-account.ledger-listing')}</h4>
                                        </div>

                                        {permission.includes("bank_account_ledger") && (
                                        <div className="d-flex justify-content-between float-right">
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-blue mr-2"
                                                clicked={() => this.exportExcelHandler()}
                                                img={<img className="d-md-inline-block d-none" alt="img" src={Excel} />}
                                                buttonName={t("report.export-excel")}
                                            />
                                        </div>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-hover datatable">
                                            <DataTable
                                                keyField="no"
                                                data={rsListing}
                                                columns={columns}
                                                activePage={this.state.activePage}
                                                limit={this.state.limit}
                                                totalItems={this.state.totalRecords}
                                                paginationHandler={(val) => this.datatablesChangeHandler(val, 'activePage')}
                                                sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'limit')}
                                                searchChangeHandler={(val) => this.datatablesChangeHandler(val, 'searchValue')}
                                                searchValue={this.state.searchValue}
                                                hideSearchBar={true}
                                                classValue="dataTable table-bordered text-justify text-center"
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {(this.state.pageShow === 'details' && this.state.trxPage === 'member') &&
                    <TransactionDetails
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                        currentUser={this.props.currentUser}
                    />
                }

                {(this.state.pageShow === 'details' && this.state.trxPage === 'agent') &&
                    <AgentTransactionDetails 
                        detailsParam={this.state.detailsParam}
                        changePageHandler={(page) => this.changePageHandler(page)}
                        returnPage='listing'
                    />
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ledger: state.bankAccount.bank_account_ledger,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps =
{
    getBanksAccountLedger
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(BanksAccountLedger));
