import React, {Component} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import {showWinner} from "../../store/ducks/tournaments/actions";
import Button from "../../components/Button/Button";
import { withNamespaces } from 'react-i18next';

//Initialize the language array first
class WinnerListing extends Component {
    state = {
        username:[],
        amount:[],
        type_id:[],
        min_bet:[],
        max_bet:[],
        start_time:[],
        end_time:[],
        target:[],
        loading: true
    }

    componentDidMount() {
        const countryData = {};
        countryData.country_id = sessionStorage.getItem('countryId') || this.state.currentCountryId;

        const showWinnerData = {};
        showWinnerData.tournament_id = this.props.show_tournament_id;

        this.props.showWinner(showWinnerData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_winnerlist_data !== this.props.show_winnerlist_data) {
            if (nextProps.show_winnerlist_data.data.responseCode === 200) {
                if(nextProps.show_winnerlist_data.data.data[0] === undefined){
                    this.setState({
                        loading: false
                    });
                }else{
                    this.setState({
                        loading: false,
                        username: nextProps.show_winnerlist_data.data.data[0].username,
                        amount:nextProps.show_winnerlist_data.data.data[0].amount,
                        type_id:nextProps.show_winnerlist_data.data.data[0].type_id,
                        start_time:nextProps.show_winnerlist_data.data.data[0].start_time,
                        end_time:nextProps.show_winnerlist_data.data.data[0].end_time,
                        min_bet:nextProps.show_winnerlist_data.data.data[0].min_bet,
                        max_bet:nextProps.show_winnerlist_data.data.data[0].max_bet,
                        target:nextProps.show_winnerlist_data.data.data[0].target,
                    });
                }
        
            } else {
                return false;
            }
        }


        return true;
    }

    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header justify-content-between">
                    <div className="my-auto">
                        <div className="d-flex">
                            <div className="content-title mb-0 my-auto">
                                <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t('tournament.winner-list')}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex my-xl-auto right-content">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-secondary"
                                idValue="back"
                                buttonName={t('global.back-to-list')}
                                clicked={() => this.props.changePageHandler('listing')}
                            />
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t('tournament.show-winner-list')}</h4>
                            </div>

                            <div className="card-body pt-0">
                                {this.state.loading === true
                                    ? <Skeleton count={4}/>
                                    : <table className="table table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <th>{t('tournament.username')}</th>
                                            <td field-key='username'>
                                                {
                                                    this.state.username.map((val, idx) => 
                                                    {
                                                        return (<span className="badge badge-primary mr-2 mb-1 mt-1" key={idx}>{val}</span>);
                                                    })
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.amount')}</th>
                                            <td field-key='amount'>{this.state.amount}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.type')}</th>
                                            <td field-key='type_id'>{this.state.type_id}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.tournament-time')}</th>
                                            <td><span field-key='start_time'>{this.state.start_time}</span> - <span field-key='end_time'>{this.state.end_time}</span></td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.turn-over-bet-limit')}</th>
                                            <td><span field-key='min_bet'>{this.state.min_bet}</span> - <span field-key='max_bet'>{this.state.max_bet}</span></td>
                                        </tr>
                                        <tr>
                                            <th>{t('tournament.target')}</th>
                                            <td field-key='target'>{this.state.target}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
};

const mapStateToProps = state => {
    const {tournament} = state;

    return {
        show_winnerlist_data: tournament.show_winnerlist_data
    }
};

const mapDispatchToProps = {
    showWinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(WinnerListing));