import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { withNamespaces } from "react-i18next";
import TimeField from 'react-simple-timefield';

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import Button from "../../components/Button/Button";

import { showPaymentType, updatePaymentType } from "../../store/ducks/paymentGateway/actions";
import { filterErrorHandler, formValidation } from "../../hoc/Shared/utility";
import ValidationError from "../../components/ValidationError/ValidationError";
class PaymentTypeEdit extends Component {
    state = {
        title: "",
        sequence: "",
        deposit_est_time: "00:00",
        withdrawal_est_time: "00:00",
        loading: true,
        fees: '',
        minimumPaymentAmt: '',
        transactionMaximumPaymentAmt: '',
        transactionMinimumPaymentAmt: '',
        withdrawalFees: '',
        withdrawalMinimumPaymentAmt: '',
        withdrawalTransactionMaximumPaymentAmt: '',
        withdrawalTransactionMinimumPaymentAmt: ''
    };

    componentDidMount() {
        formValidation("edit_bank_form");

        const showPaymentGatewayData = {};
        showPaymentGatewayData.payment_type_id = this.props.show_payment_type_id;
        showPaymentGatewayData.country_id = this.props.currentCountryId;
        this.props.showPaymentType(showPaymentGatewayData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show_payment_type_data !== this.props.show_payment_type_data && nextProps.show_payment_type_data !== undefined) {
            if (nextProps.show_payment_type_data.data.responseCode === 200 && nextProps.show_payment_type_data.data.msgType === 'success') {
                this.setState({
                    loading: false,
                    fees: nextProps.show_payment_type_data.data.data[0].fees,
                    minimumPaymentAmt: nextProps.show_payment_type_data.data.data[0].minimumPaymentAmt,
                    transactionMaximumPaymentAmt: nextProps.show_payment_type_data.data.data[0].transactionMaximumPaymentAmt,
                    transactionMinimumPaymentAmt: nextProps.show_payment_type_data.data.data[0].transactionMinimumPaymentAmt,
                    sequence: (nextProps.show_payment_type_data.data.data[0].sequence) ? nextProps.show_payment_type_data.data.data[0].sequence : "",
                    deposit_est_time: (nextProps.show_payment_type_data.data.data[0].deposit_est_time) ? nextProps.show_payment_type_data.data.data[0].deposit_est_time : "",
                    withdrawal_est_time: (nextProps.show_payment_type_data.data.data[0].withdrawal_est_time) ? nextProps.show_payment_type_data.data.data[0].withdrawal_est_time : "",
                    withdrawalFees: nextProps.show_payment_type_data.data.data[0].withdrawalFees,
                    withdrawalMinimumPaymentAmt: nextProps.show_payment_type_data.data.data[0].withdrawalMinimumPaymentAmt,
                    withdrawalTransactionMaximumPaymentAmt: nextProps.show_payment_type_data.data.data[0].withdrawalTransactionMaximumPaymentAmt,
                    withdrawalTransactionMinimumPaymentAmt: nextProps.show_payment_type_data.data.data[0].withdrawalTransactionMinimumPaymentAmt
                });
            } else {
                this.setState({
                    loading: false
                }, () => {
                    filterErrorHandler(nextProps.show_payment_type_data.data.message);
                });
            } 
        }

        if (nextProps.update_payment_type_result !== this.props.update_payment_type_result && nextProps.update_payment_type_result !== undefined) {
            if (nextProps.update_payment_type_result.responseCode === 200 && nextProps.update_payment_type_result.msgType === 'success') {
                this.setState({ touched: false });
                const { t } = this.props;
                Swal.fire({
                    title: t("global.record-updated"),
                    icon: nextProps.update_payment_type_result.msgType,
                    confirmButtonText: t("global.ok"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.refreshHandler();
                        this.props.changePageHandler("listing");
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.update_payment_type_result.message,
                });
                Swal.close();
            }
        }

        return true;
    }

    getPaymentGatewayName = (event) => {
        this.setState({ title: event.target.value });
    };

    getPaymentGatewaySequence = (event) => {
        this.setState({ sequence: event.target.value });
    };

    getDepositStatusHandler = (event) => {
        this.setState({
            currentDepositStatus: event.target.value,
        });
    };

    getWithdrawalStatusHandler = (event) => {
        this.setState({
            currentWithdrawalStatus: event.target.value
        })
    }

    getPaymentGatewayDepositEstTime = (event) => {
        this.setState({ deposit_est_time: event.target.value });
    };

    getPaymentGatewayWithdrawalEstTime = (event) => {
        this.setState({ withdrawal_est_time: event.target.value });
    };

    updatePaymentTypeHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;
        Swal.fire({
            title: t("global.confirmation"),
            text: t("global.are-you-sure"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.update"),
            cancelButtonText: t("global.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};

                data.payment_type_id = this.props.show_payment_type_id;
                data.fees = this.state.fees;
                data.minimum_payment_amount = this.state.minimumPaymentAmt;
                data.transaction_maximum_payment_amount = this.state.transactionMaximumPaymentAmt;
                data.transaction_minimum_payment_amount = this.state.transactionMinimumPaymentAmt;
                data.withdrawal_fees = this.state.withdrawalFees;
                data.withdrawal_minimum_payment_amount = this.state.withdrawalMinimumPaymentAmt;
                data.withdrawal_transaction_maximum_payment_amount = this.state.withdrawalTransactionMaximumPaymentAmt;
                data.withdrawal_transaction_minimum_payment_amount = this.state.withdrawalTransactionMinimumPaymentAmt;
                data.country_id = this.props.currentCountryId;
                data.sequence = this.state.sequence;
                data.deposit_est_time = this.state.deposit_est_time;
                data.withdrawal_est_time = this.state.withdrawal_est_time;

                this.props.updatePaymentType(data);
            }
        });
    };

    getFeesChangeHandler = (event) => {
        this.setState({ fees: event.target.value });
    }

    getMinimumPaymentAmtHandler = (event) => {
        this.setState({ minimumPaymentAmt: event.target.value });
    }

    getTransactionMaximumPaymentAmtHandler = (event) => {
        this.setState({ transactionMaximumPaymentAmt: event.target.value });
    }

    getTransactionMinimumPaymentAmtHandler = (event) => {
        this.setState({ transactionMinimumPaymentAmt: event.target.value });
    }

    getWithdrawalFeesChangeHandler = (event) => {
        this.setState({ withdrawalFees: event.target.value });
    }

    getWithdrawalMinimumPaymentAmtHandler = (event) => {
        this.setState({ withdrawalMinimumPaymentAmt: event.target.value });
    }

    getWithdrawalTransactionMaximumPaymentAmtHandler = (event) => {
        this.setState({ withdrawalTransactionMaximumPaymentAmt: event.target.value });
    }

    getWithdrawalTransactionMinimumPaymentAmtHandler = (event) => {
        this.setState({ withdrawalTransactionMinimumPaymentAmt: event.target.value });
    }


    render() {
        const { t } = this.props;

        return (
            <Auxiliary>
                <div className="breadcrumb-header">
                    <div className="d-flex">
                        <div className="my-auto">
                            <Button
                                typeButton="button"
                                classValue="btn btn-back-outline"
                                idValue="back"
                                buttonName={<i className="angle fe fe-chevron-left"></i>}
                                clicked={() => this.props.changePageHandler("listing")}
                            />
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-flex">
                            <h4 className="content-title mb-0 my-auto ml-2">{t("payment-gateway.payment-gateway")}</h4>
                        </div>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-12">
                        <div className="card  box-shadow-0">
                            <div className="card-header">
                                <h4 className="card-title mb-1">{t("payment-gateway.edit-payment-gateway")}</h4>
                            </div>
                            <div className="card-body pt-0">
                                <form method="post" id="edit_payment_gateway_form" className="form-horizontal" onSubmit={this.updatePaymentTypeHandler} noValidate>
                                    <ValidationError formName="edit_payment_gateway_form" touched={this.state.touched} message={this.state.error_message} />
                                    {this.state.loading === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        <div className="row">
                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="sequence">{t("payment-gateway.sequence")}</label>

                                                <NumberInput
                                                    onChange={this.getPaymentGatewaySequence}
                                                    id="sequence"
                                                    name="sequence"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.sequence")}
                                                    value={this.state.sequence}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="title">{t("payment-gateway.fees")}</label>

                                                <NumberInput
                                                    onChange={this.getFeesChangeHandler}
                                                    id="fees"
                                                    name="fees"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.fees}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.minimum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getMinimumPaymentAmtHandler}
                                                    id="minimum-payment-amount"
                                                    name="minimum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.minimumPaymentAmt}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.transaction-minimum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getTransactionMinimumPaymentAmtHandler}
                                                    id="transaction-minimum-payment-amount"
                                                    name="transaction-minimum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.transactionMinimumPaymentAmt}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.transaction-maximum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getTransactionMaximumPaymentAmtHandler}
                                                    id="transaction-maximum-payment-amount"
                                                    name="transaction-maximum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.transactionMaximumPaymentAmt}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="title">{t("payment-gateway.withdrawal-fees")}</label>

                                                <NumberInput
                                                    onChange={this.getWithdrawalFeesChangeHandler}
                                                    id="withdrawal-fees"
                                                    name="withdrawal-fees"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.withdrawalFees}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.withdrawal-minimum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getWithdrawalMinimumPaymentAmtHandler}
                                                    id="withdrawal-minimum-payment-amount"
                                                    name="withdrawal-minimum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.withdrawalMinimumPaymentAmt}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.withdrawal-transaction-minimum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getWithdrawalTransactionMinimumPaymentAmtHandler}
                                                    id="withdrawal-transaction-minimum-payment-amount"
                                                    name="withdrawal-transaction-minimum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.withdrawalTransactionMinimumPaymentAmt}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.withdrawal-transaction-maximum-payment-amount")}</label>

                                                <NumberInput
                                                    onChange={this.getWithdrawalTransactionMaximumPaymentAmtHandler}
                                                    id="withdrawal-transaction-maximum-payment-amount"
                                                    name="withdrawal-transaction-maximum-payment-amount"
                                                    className="form-control"
                                                    placeholder={t("payment-gateway.enter-amount")}
                                                    value={this.state.withdrawalTransactionMaximumPaymentAmt}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.deposit-est-time")}</label>

                                                <TimeField
                                                    id='deposit_est_time'
                                                    value={this.state.deposit_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                    onChange={this.getPaymentGatewayDepositEstTime} // {Function} required
                                                    input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                    colon=":"                          // {String}   default: ":"
                                                    showSeconds                        // {Boolean}  default: false
                                                    //style={{ width: '48%', display: 'inline' }}
                                                />
                                            </div>

                                            <div className="col-md-6 col-12 form-group">
                                                <label htmlFor="bank_name">{t("payment-gateway.withdrawal-est-time")}</label>

                                                <TimeField
                                                    id='withdrawal_est_time'
                                                    value={this.state.withdrawal_est_time}                       // {String}   required, format '00:00' or '00:00:00'
                                                    onChange={this.getPaymentGatewayWithdrawalEstTime} // {Function} required
                                                    input={<input className="form-control" />}   // {Element}  default: <input type="text" />
                                                    colon=":"                          // {String}   default: ":"
                                                    showSeconds                        // {Boolean}  default: false
                                                    //style={{ width: '48%', display: 'inline' }}
                                             />
                                            </div>

                                            <div className="col-12 form-group mb-0 justify-content-end">
                                                <div>
                                                    <Button typeButton="submit" classValue="btn btn-blue" idValue="submit" buttonName={t("global.update")} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { paymentGateway, currentUser } = state;

    //Getting the state from the reducers
    return {
        show_payment_type_data: paymentGateway.show_payment_type_result,
        update_payment_type_result: paymentGateway.update_payment_type_result,
        currentUsername: currentUser.data.username,
        currentCountryId: currentUser.data.country_id
    };
};

const mapDispatchToProps = {
    showPaymentType,
    updatePaymentType,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PaymentTypeEdit));
