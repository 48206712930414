import React, {Component, Fragment} from 'react';
import {required, minValue, maxValue, validateDisplay} from "../../../hoc/Shared/utility";
import PropTypes from 'prop-types';

class NumberInput extends Component {
    constructor (props) {
        super (props);
        this.state = {
            value: '',
            //Set the validation rules by set the init to false without start the validation first
            requiredRules: required(false, this.props.value, this.props.requiredMessage),
            minValueRules: minValue(false, this.props.value, this.props.min, this.props.minValueMessage),
            maxValueRules: maxValue(false, this.props.value, this.props.max, this.props.maxValueMessage),
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        // if (
        //     (nextProps.value !== prevState.value)
        // ) {

            let payload = {
                value: nextProps.value
            };

            //Set to the payload when validation is true or not and set the init to true start validation
            if(nextProps.required){
                payload['requiredRules'] = required(true, nextProps.value, nextProps.requiredMessage);
            }
            if(nextProps.min){
                payload['minValueRules'] = minValue(true, nextProps.value, nextProps.min, nextProps.minValueMessage);
            }
            if(nextProps.max){
                payload['maxValueRules'] = maxValue(true, nextProps.value, nextProps.max, nextProps.maxValueMessage);
            }
            return payload;
        // }
        // return null;
    }

    render() {
        const { id, name, onChange, className, disabled, required, placeholder, min, max, onBlur, defaultValue, onKeyPress, autoComplete } = this.props;
        const { value, requiredRules, minValueRules, maxValueRules } = this.state;
        const [ requiredClass, requiredMsg ] = validateDisplay(requiredRules);
        const [ minValueClass, minValueMsg ] = validateDisplay(minValueRules);
        const [ maxValueClass, maxValueMsg ] = validateDisplay(maxValueRules);

        return (
            <Fragment>
                <input
                    // step="0.1"
                    step="0.01"
                    type="number"
                    id={id}
                    value={value}
                    className={`form-control ${className}`}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    onBlur={onBlur}
                    defaultValue={defaultValue}
                    onKeyPress={onKeyPress}
                    autoComplete={autoComplete}
                />
                {/*Display the message*/}
                <div className="invalid-feedback">
                    {
                        (requiredClass && requiredClass !== 'is-valid' && requiredMsg)
                        || (minValueClass && minValueClass !== 'is-valid' && minValueMsg)
                        || (maxValueClass && maxValueClass !== 'is-valid' && maxValueMsg)
                    }
                </div>
            </Fragment>
        )
    }
}

export default NumberInput;

NumberInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    onBlur: PropTypes.func,
    requiredMsg: PropTypes.string,
    defaultValue: PropTypes.number,
    onKeyPress: PropTypes.func,
    autoComplete: PropTypes.string
};

NumberInput.defaultProps = {
    className: ''
};