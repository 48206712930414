import * as type from "./types";
import axios from "../../../common/config/axios";

export const getSensitiveGoodReturn = (data) => (dispatch) => {
  axios
    .get("sensitive_good_return/list", {
      params: data,
    })
    .then((response) => {
      //Dispatch the current page , current limit and search value to reducers
      dispatch({
        type: type.GET_SENSITIVE_GOOD_RETURN_LIST,
        payload: response,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const getMembers = (email) => (dispatch) => {
  axios
    .get("sensitive_good_return/get_member_list",  {
        params: {
            "email": email
            // "wallet_code": wt,          
        }
    })
    .then((response) => {
      dispatch({
        type: type.GET_MEMBERS,
        value: response.data,
      });
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const storeSensitiveGoodReturn = (data) => (dispatch) => {
    axios
      .post("sensitive_good_return/store", data)
      .then((response) => {
        dispatch({
          type: type.POST_SENSITIVE_GOOD_RETURN,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
};

export const showSensitiveGoodReturn = (data) => (dispatch) => {
    axios
      .get("sensitive_good_return/show", {
        params: {
          id: data["sensitive_good_return_id"],
        },
      })
      .then((response) => {
        dispatch({
          type: type.SHOW_SENSITIVE_GOOD_RETURN,
          payload: response,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
};

export const updateSensitiveGoodReturn = (data) => (dispatch, getState) => {
    axios
      .post("sensitive_good_return/update", data)
      .then((response) => {
        dispatch({
          type: type.UPDATE_SENSITIVE_GOOD_RETURN,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  };

export const deleteSensitiveGoodReturn = (data) => (dispatch) => {
    axios
      .post("sensitive_good_return/delete", data)
      .then((response) => {
        dispatch({
          type: type.DELETE_SENSITIVE_GOOD_RETURN,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  };
