import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    loading: false,
    data: [],
};

const stakingSettingsReducer = createReducer(initialState) ({
    [type.STAKING_SETTINGS_LIST]: (state, action) => ({
        ...state,
        staking_settings_data: action.payload
    }),
    [type.STAKING_SETTINGS_EDIT]: (state, action) => ({
        ...state,
        staking_settings_edit_data: action.payload,
    }),

    [type.STAKING_SETTINGS_UPDATE]: (state, action) => ({
        ...state,
        staking_settings_update_result: action.payload,
    }),
});

export default stakingSettingsReducer;