import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('lucky_spin_gift_assign/list', {params: props})
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.LIST,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const store = (data) => dispatch =>
{
    // console.log(data);
    axios.post('lucky_spin_gift_assign/store', data)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.STORE,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};

export const updateStatus = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    axios.post('lucky_spin_gift_assign/update', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const deleteData = (data) => dispatch =>
{
    // console.log(data);
    axios.post('lucky_spin_gift_assign/delete', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.DELETE,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getMember = (m) => dispatch => 
{
    axios.get('lucky_spin_gift_assign/get_member', {
        params: {
            "username": m,
        }
    }).then(function (response) 
    {
        dispatch (
        {
            type: actionType.GET_MEMBER,
            value: response.data
        })
    })
    .catch(err => 
    {
        
    });
};