import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";

import Button from '../../components/Button/Button';
import { AiFillPlusCircle} from "react-icons/ai";
import DataTable from "../../components/Datatable/DataTable";


class BanksLevelListing extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="row row-sm">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="d-flex align-items-end">
                            <div className="card-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h4 className="card-title mg-b-0">{t("bank-level.bank-level-listing")}</h4>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="btn-group">
                                    {this.props.permission.includes('bank_level_store') &&
                                        <>
                                            <AiFillPlusCircle />
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-underline"
                                                idValue="btnCreateBankAccount"
                                                buttonName={t('bank-level.add-bank-level')}
                                                clicked={() => this.props.changePageHandler('create')} />
                                        </>

                                    }
                                </div>
                            </div>

                        </div>

                        <div className="card-body">
                            <div className="">
                                {/* {this.props.permission.includes('bank_level_multi_delete') &&
                                    <button id="button" className="btn btn-danger mg-b-20 btn-multi-delete">
                                        {t("global.multi-delete")}
                                    </button>
                                } */}

                                <DataTable
                                    keyField="banklevelId"
                                    data={this.props.data}
                                    columns={this.props.columns}
                                    activePage={this.props.activePage}
                                    limit={this.props.limit}
                                    totalItems={this.props.totalRecords}
                                    paginationHandler={this.props.pageChangeHandler}
                                    sorted={this.props.sorted}
                                    sizePerPageHandler={this.props.sizePerPageHandler}
                                    searchChangeHandler={this.props.searchChangeHandler}
                                    searchValue={this.props.searchValue}
                                    searchSubmitHandler={this.props.postSearchHandler}
                                    searchPressSubmitHandler={this.props.searchPressSubmitHandler}
                                    loading={this.props.loading}
                                    classValue="dataTable table-bordered text-justify text-center"
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withNamespaces("translation")(BanksLevelListing);