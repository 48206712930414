import * as actionType from './types';
import axios from '../../../common/config/axios';

export const getList = (props) => dispatch => 
{
    axios.get('rebate_setting/list', {params: props})
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.LIST,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const updateStatus = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    // console.log(data);
    axios.post('rebate_setting/update_status', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_STATUS,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};

export const getDetails = (id) => (dispatch, getState) =>
{   

    if(id > 0)
    {
      axios.get('rebate_setting/details',{
        params: {
            "id": id,
            "country_id": getState().currentUser.data.country_id,
        }
      })
      .then(function (response) 
      {
        dispatch (
        {
          type: actionType.DETAILS,
          value: response.data
        })
      })
      .catch(function (error) 
      {
        
      });
    }
};

export const updateSetting = (data) => (dispatch, getState) =>
{
    data.country_id = getState().currentUser.data.country_id;
    // console.log(data);
    axios.post('rebate_setting/update_setting', data)
    .then(function (response) 
    {
      dispatch (
      {
        type: actionType.UPDATE_SETTING,
        value: response.data
      })
    })
    .catch(err => 
    {
      
    });
};