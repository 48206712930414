import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import Button from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";

import MetaTag from "./MetaTags/MetaTag";
import XMLSitemap from "./XMLSitemap/XMLSitemap";
import RobotsTxt from "./RobotsTxt/RobotsTxt";
import WebMaster from "./WebMaster/WebMaster";
import OpenGraph from "./OpenGraph/OpenGraph";

class SEO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "meta_tags",
            showTest: false,
            loading: true,
            reload: true,
            permission: [],
        };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[SEO] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let seoPermission = this.props.currentPermission.filter((item) => item.includes("seo_"));
            // console.log(seoPermission);
            this.setState({ permission: seoPermission });
        }

        return true;
    }

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    render() {
        const { t } = this.props;
        let display = null;
        let tabId = this.state.pageShow;
        if (!this.state.loading && this.state.permission.length !== 0) {
            display = (
                <>
                    {(tabId === "meta_tags" || tabId === "xml_sitemap" || tabId === "robots_txt" || tabId === "web_master" || tabId === "open_graph") && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-primary tabs-style-2 mt-3">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav panel-tabs main-nav-line">
                                                {this.state.permission.includes("seo_meta_tags_access") && (
                                                    <li>
                                                        <button
                                                            id="meta_tags"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "meta_tags" ? "active" : ""}`}>
                                                            {t("seo.meta-tags")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.state.permission.includes("seo_xml_sitemap_access") && (
                                                    <li>
                                                        <button
                                                            id="xml_sitemap"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "xml_sitemap" ? "active" : ""}`}>
                                                            {t("seo.xml-sitemap")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.state.permission.includes("seo_robots_txt_access") && (
                                                    <li>
                                                        <button
                                                            id="robots_txt"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "robots_txt" ? "active" : ""}`}>
                                                            {t("seo.robots-txt")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.state.permission.includes("seo_web_master_access") && (
                                                    <li>
                                                        <button
                                                            id="web_master"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "web_master" ? "active" : ""}`}>
                                                            {t("seo.web-master")}
                                                        </button>
                                                    </li>
                                                )}
                                                {this.state.permission.includes("seo_open_graph_access") && (
                                                    <li>
                                                        <button
                                                            id="open_graph"
                                                            onClick={(event) => this.changeTabHandler(event)}
                                                            style={{ borderTopWidth: 0 }}
                                                            className={`nav-link bg-white ${tabId === "open_graph" ? "active" : ""}`}>
                                                            {t("seo.open-graph")}
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.permission.includes("seo_meta_tags_access") && tabId === "meta_tags" && <MetaTag permission={this.state.permission} />}
                    {this.state.permission.includes("seo_xml_sitemap_access") && tabId === "xml_sitemap" && <XMLSitemap permission={this.state.permission} />}
                    {this.state.permission.includes("seo_robots_txt_access") && tabId === "robots_txt" && <RobotsTxt permission={this.state.permission} />}
                    {this.state.permission.includes("seo_web_master_access") && tabId === "web_master" && <WebMaster permission={this.state.permission} />}
                    {this.state.permission.includes("seo_open_graph_access") && tabId === "open_graph" && <OpenGraph permission={this.state.permission} />}
                </>
            );
        } else {
            display = (
                <div className="row row-sm">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="my-auto">
                            <div className="d-flex">
                                <h4 className="content-title mb-0 my-auto text-muted mt-1 tx-13 ml-2 mb-0">{t("seo.seo")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <Auxiliary>{display}</Auxiliary>;
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state;

    return {
        currentPermission: currentUser.data.permission,
    };
};

export default connect(mapStateToProps)(withNamespaces("translation")(SEO));
