import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import Lightbox from "react-image-lightbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen } from "@fortawesome/free-solid-svg-icons";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import PromotedVideoCreate from "./PromotedVideoCreate";
import PromotedVideoListing from "./PromotedVideoListing";
import PromotedVideoEdit from "./PromotedVideoEdit";
import PromotedVideoShow from "./PromotedVideoShow";
import Select from "../../components/Input/Select/Select";
import { getPromotedVideo, updatePromotedVideoStatus } from "../../store/ducks/promotedVideo/actions";

const initialInputState = {
    /* form input value */
    title: "",
    status: "",
};

let promotionData = {};
class PromotedVideo extends Component {
    state = {
        pageShow: "listing",

        page: Number(process.env.REACT_APP_DATATABLE_PAGE),
        limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
        sorted: null,
        totalPages: 0,
        activePage: 1,
        totalRecords: 0,
        searchValue: "",
        isOpen: false,

        data: [],

        filter: initialInputState,

        currentPromotedVideoId: "",
        loading: true,
        currentImage: "",

        permission: [],
    };

    fetchData() {
        let availablePage = this.state.activePage;

        if (this.state.totalRecords !== 0) {
            availablePage = parseInt(this.state.totalRecords / this.state.limit + 1);
            this.state.activePage >= availablePage && this.setState({ activePage: availablePage });
        }

        promotionData.page = this.state.activePage >= availablePage ? availablePage : this.state.activePage;
        promotionData.limit = this.state.limit;
        promotionData.order = JSON.stringify(this.state.sorted);
        promotionData.lang_id = this.props.currentLanguageId;
        promotionData.title = this.state.filter.title;
        promotionData.status = this.state.filter.status;
        promotionData.country_id = this.props.currentCountryId;

        this.props.getPromotedVideo(promotionData);
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permission.length === 0 && this.props.currentPermission !== undefined) {
            let promotionPermission = this.props.currentPermission.filter((item) => item.includes("promoted_video_"));
            this.setState({ permission: promotionPermission });
        }

        if (nextProps.promoted_video_data !== this.props.promoted_video_data) {
            // console.log(nextProps.promotion_data.data.data);

            if (nextProps.promoted_video_data.data.responseCode === 200) {
                this.setState({
                    loading: false,
                    data: nextProps.promoted_video_data.data.data,
                    totalRecords: nextProps.promoted_video_data.data.totalRecords,
                    totalPages: nextProps.promoted_video_data.data.totalPages,
                });
            } else {
                this.setState({ loading: false });
                const { t } = this.props;
                Swal.fire({
                    title: nextProps.promoted_video_data.data.message[0],
                    icon: nextProps.promoted_video_data.data.msgType,
                    confirmButtonText: t("global.ok"),
                });
            }
        }

        if (nextProps.update_status_promoted_video_result !== this.props.update_status_promoted_video_result) {
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_status_promoted_video_result.msgType,
                confirmButtonText: t("global.ok"),
            });
            // console.log(nextProps.update_status_promotion_result);
            this.fetchData();
            // this.setState({ loading: false });
        }

        if (nextProps.update_show_promotion_result !== this.props.update_show_promotion_result) {
            this.setState({ loading: false });
            const { t } = this.props;
            Swal.fire({
                title: t("global.record-updated"),
                icon: nextProps.update_show_promotion_result.msgType,
                confirmButtonText: t("global.ok"),
            });
            // console.log(nextProps.update_show_promotion_result);
            this.fetchData();
            // this.setState({ loading: false });
        }

        return true;
    }

    onSort = (field, order) => {
        const orderArry = [{}];
        let orderSequence;

        if (order === "asc") {
            orderSequence = true;
        } else {
            orderSequence = false;
        }

        orderArry[0]["id"] = field;
        orderArry[0]["desc"] = orderSequence;

        this.setState({ sorted: orderArry, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    updateStatusPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });

        const updatePromotionStatusData = {};
        updatePromotionStatusData.video_id = promotionId;
        updatePromotionStatusData.status = Number(event.target.value);

        this.props.updatePromotedVideoStatus(updatePromotionStatusData);
    };

    updateShowPromotionHandler = (event, promotionId) => {
        event.preventDefault();
        Swal.fire({
            title: "",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading();
            },
        });
        const updatePromotionShowData = {};
        updatePromotionShowData.promotion_id = promotionId;
        updatePromotionShowData.show = Number(event.target.value);

        this.props.updatePromotionShow(updatePromotionShowData);
    };

    onInputChange(event) {
        let newFilter = Object.assign({}, this.state.filter);
        newFilter[event.target.id] = event.target.value;

        this.setState({
            filter: newFilter,
        });
    }

    refreshHandler = () => {
        this.setState(
            {
                loading: true,
                data: [],
            },
            () => {
                const payload = {
                    ...promotionData,
                    ...this.state.filter,
                };
                this.props.getPromotedVideo(payload);
            }
        );
    };

    clearHandler = () => {
        this.setState(
            {
                activePage: 1,
                filter: initialInputState,
                loading: true,
                data: [],
            },
            () => {
                let payload = {
                    ...promotionData,
                    page: 1,
                    ...this.state.filter,
                };
                this.props.getPromotedVideo(payload);
            }
        );
    };

    statusFormatter = (cell, row) => {
        const { t } = this.props;

        if (this.state.permission.includes("promoted_video_update_status")) {
            let statusArray, dtClassName;

            statusArray = [
                {
                    label: t("global.active"),
                    value: 1,
                    selected: true,
                },
                {
                    label: t("global.inactive"),
                    value: 0,
                },
            ];
            if (row.status === 0) {
                dtClassName = "form-control datatable-select-inactive";
            } else {
                dtClassName = "form-control datatable-select-active";
            }

            return (
                <Select
                    id="promotionStatus"
                    name="promotionStatus"
                    options={statusArray}
                    defaultValue={row.status}
                    key={`status-${row.video_id}`}
                    onChange={(event) => {
                        this.updateStatusPromotionHandler(event, row.video_id);
                    }}
                    className={dtClassName}
                />
            );
        } else {
            let badgeClassName, status;
            if (row.status === 0) {
                badgeClassName = "badge badge-pill badge-danger";
                status = t("global.inactive");
            } else {
                badgeClassName = "badge badge-pill badge-success";
                status = t("global.active");
            }
            return <span className={badgeClassName}>{status}</span>;
        }
    };

    actionFormatter = (cell, row) => {
        let viewButton, editButton, output;

        if (this.state.permission.includes("promoted_video_view")) {
            viewButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white mr-2"
                    buttonName={<FontAwesomeIcon icon={faSearch} />}
                    key={`view-${row.video_id}`}
                    clicked={() => this.changePageHandler("show", row.video_id)}
                />
            );
        }

        if (this.state.permission.includes("promoted_video_edit")) {
            editButton = (
                <Button
                    typeButton="button"
                    classValue="btn btn-sm btn-white"
                    buttonName={<FontAwesomeIcon icon={faPen} />}
                    key={`edit-${row.video_id}`}
                    clicked={() => this.changePageHandler("edit", row.video_id)}
                />
            );
        }

        output = [viewButton, editButton];
        return output;
    };

    changePageHandler = (page, promotionId) => {
        this.setState({
            pageShow: page,
            currentPromotedVideoId: promotionId,
        });
    };

    pageChangeHandler = (activePage) => {
        this.setState({ page: activePage, activePage: activePage, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    sizePerPageHandler = (limit) => {
        this.setState({ limit: limit, loading: true, data: [] }, () => {
            this.fetchData();
        });
    };

    postSearchHandler = () => {
        this.setState({ loading: true, data: [], activePage: 1 }, () => {
            this.fetchData();
        });
    };

    onSearchKeyPressHandler = (event) => {
        //Checking press the enter only perform search action
        if (event.key === "Enter") {
            this.setState({ loading: true, data: [], activePage: 1 }, () => {
                this.fetchData();
            });
        }
    };

    videoFormatter = (cell, row) => {
        return <video src={cell} controls width="400" key={`desktop-image-${row.video_id}`}></video>;
    };

    promotedVideoThumbnailsFormatter = (cell, row) => {
        return (
            <img
                alt=""
                className='tb-img'
                src={cell}
                onClick={() => this.setState({ isOpen: true, currentImage: cell })}
                key={`desktop-image-${row.video_id}`}
            />
        );
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                text: t("global.no"),
                dataField: "",
                keyField: "video_id",
                formatter: (cell, row, rowIndex) => {
                    //let rowNumber = (this.state.activePage - 1) * this.state.limit + (rowIndex + 1);
                    let reverseRowNumber = this.state.totalRecords - rowIndex - (this.state.activePage - 1) * 10;
                    return <span>{reverseRowNumber}</span>;
                },
                headerStyle: () => {
                    return { width: "60px" };
                },
            },
            {
                text: t("promoted-video.promoted-video-id"),
                dataField: "video_id",
                keyField: "videoId",
                sort: true,
                onSort: this.onSort,
                // hidden: true
            },
            {
                text: t("promoted-video.video"),
                dataField: "video",
                keyField: "promoted-video",
                formatter: this.videoFormatter,
                headerStyle: () => {
                    return { width: "195px" };
                },
            },
            {
                text: t("promoted-video.video-thumbnail-image"),
                dataField: "video_thumbnails",
                keyField: "promoted-video-thumbnails",
                formatter: this.promotedVideoThumbnailsFormatter,
            },
            {
                text: t("promoted-video.title"),
                dataField: "title",
                keyField: "title",
                sort: true,
                onSort: this.onSort,
            },
            {
                text: t("global.status"),
                dataField: "status",
                keyField: "Status",
                formatter: this.statusFormatter,
                headerStyle: () => {
                    return { width: "115px" };
                },
            },
            {
                text: t("global.action"),
                dataField: "#",
                keyField: "action",
                formatter: this.actionFormatter,

                headerStyle: () => {
                    return { width: "115px" };
                },
            },
        ];

        return (
            <Auxiliary>
                {this.state.pageShow === "listing" && (
                    <div>
                        {this.state.isOpen && <Lightbox mainSrc={this.state.currentImage} onCloseRequest={() => this.setState({ isOpen: false })} />}
                        <div className="breadcrumb-header justify-content-between">
                            <div className="my-auto">
                                <div className="d-flex">
                                    <h4 className="text-muted mt-1 tx-13 ml-2 mb-0">{t("promoted-video.promoted-video")}</h4>
                                </div>
                            </div>
                        </div>

                        <PromotedVideoListing
                            title={this.state.filter.title}
                            status={this.state.filter.status}
                            category={this.state.filter.category}
                            onInputChange={(event) => this.onInputChange(event)}
                            clearHandler={() => this.clearHandler()}
                            refreshHandler={() => this.refreshHandler()}
                            columns={columns}
                            activePage={this.state.activePage}
                            limit={this.state.limit}
                            totalRecords={this.state.totalRecords}
                            data={this.state.data}
                            order={this.state.sorted}
                            pageChangeHandler={this.pageChangeHandler}
                            sizePerPageHandler={this.sizePerPageHandler}
                            postSearchHandler={this.postSearchHandler}
                            searchPressSubmitHandler={(event) => this.onSearchKeyPressHandler(event)}
                            loading={this.state.loading}
                            permission={this.state.permission}
                            changePageHandler={this.changePageHandler}
                        />
                    </div>
                )}

                {this.state.pageShow === "create" && (
                    <PromotedVideoCreate refreshHandler={() => this.refreshHandler()} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.pageShow === "show" && (
                    <PromotedVideoShow show_video_id={this.state.currentPromotedVideoId} changePageHandler={(page) => this.changePageHandler(page)} />
                )}

                {this.state.pageShow === "edit" && (
                    <PromotedVideoEdit
                        show_video_id={this.state.currentPromotedVideoId}
                        refreshHandler={() => this.refreshHandler()}
                        changePageHandler={(page) => this.changePageHandler(page)}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { currentUser, promotedVideo } = state;

    return {
        promoted_video_data: promotedVideo.promoted_video_data,
        currentCountryId: currentUser.data.country_id,
        currentLanguageId: currentUser.data.language_id,
        currentPermission: currentUser.data.permission,
        update_status_promoted_video_result: promotedVideo.update_status_promoted_video_result,
    };
};

const mapDispatchToProps = {
    updatePromotedVideoStatus,
    getPromotedVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(PromotedVideo));
