import * as type from './types';
import { createReducer } from "../../util/index";

//Set the initial state for the current page , current limit and current search values
const initialState = {
    data: []
};

const paymentGatewayReducer = createReducer(initialState) ({
    //Export the response to the react.js to get the response data based on type name
    [type.GET_PAYMENT_GATEWAY_LIST]: (state = initialState, action) => ({
        ...state,
        payment_gateway_data: action.payload
    }),

    [type.DROPDOWN]: (state, action) => ({
      ...state,
      dropDown: action.value,
    }),

    [type.CREATE_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        post_payment_gateway_result: action.payload,
    }),

    [type.SHOW_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        show_payment_gateway_data: action.payload,
    }),

    [type.UPDATE_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        update_payment_gateway_result: action.payload,
    }),

    [type.DELETE_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        delete_payment_gateway_result: action.payload,
    }),

    [type.UPDATE_DEPOSIT_STATUS_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        update_deposit_status_payment_gateway_result: action.payload,
    }),

    [type.UPDATE_WITHDRAWAL_STATUS_PAYMENT_GATEWAY]: (state, action) => ({
        ...state,
        update_withdrawal_status_payment_gateway_result: action.payload,
    }),

    [type.GET_PAYMENT_TYPE_LIST]: (state, action) => ({
        ...state,
        payment_type_data: action.payload,
    }),

    [type.UPDATE_DEPOSIT_STATUS_PAYMENT_TYPE]: (state, action) => ({
        ...state,
        update_payment_type_deposit_status_result: action.payload,
    }),

    [type.UPDATE_WITHDRAWAL_STATUS_PAYMENT_TYPE]: (state, action) => ({
        ...state,
        update_payment_type_withdrawal_status_result: action.payload,
    }),

    [type.STORE_PAYMENT_TYPE]: (state, action) => ({
        ...state,
        post_payment_type_result: action.payload,
    }),

    [type.SHOW_PAYMENT_TYPE]: (state, action) => ({
        ...state,
        show_payment_type_result: action.payload,
    }),

    [type.UPDATE_PAYMENT_TYPE]: (state, action) => ({
        ...state,
        update_payment_type_result: action.payload,
    }),

    [type.PAYMENT_TYPE_LEDGER]: (state = initialState, action) => ({
        ...state,
        payment_type_ledger: action.payload
    }),
});

export default paymentGatewayReducer;